import * as constants from './../constants';
import { API_USER_SERVICE_PORT } from '../../config/env/env';

export const addStaffWork = ({ id, ...data }, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: `/org/transaction/${id}/work`,
        method: 'POST',
        data,
        success: (response) => {
            console.log('add work', response);
            return { type: constants.DO_NOTHING, payload: response };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
    },
});

export const editStaffWork = ({ id, workId, ...data }, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: `/org/transaction/${id}/work/${workId}`,
        method: 'PUT',
        data: { id, ...data },
        success: (response) => {
            return { type: constants.DO_NOTHING, payload: response };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
    },
});

export const updateFilterData = (data) => ({
    type: constants.SET_FILTER_UPDATE,
    payload: data,
});

// export const getAllWork = ({ id, ...data }, onSuccess, onError) => ({
//     type: constants.API,
//     payload: {
//         url: `/org/transaction/${id}/payment/summary`,
//         method: 'POST',
//         // data: data,
//         success: setWorkData,
//         postProcessSuccess: onSuccess,
//         postProcessError: onError,
//         port: API_USER_SERVICE_PORT,
//     },
// });

// const setWorkData = (data) => {
//     if (data && data.response) {
//         console.log('AuthActionCreator', 'the data is', data);
//         return { type: constants.SET_ALL_PAYMENTS, payload: data.response };
//     }
// };
