import {
    Box,
    Button,
    Card,
    Grid,
    makeStyles,
    Paper,
    Typography,
    IconButton,
    List,
    ListItem,
    ListItemSecondaryAction,
    Divider,
    TablePagination,
    Tooltip as MUITooltip,
    LinearProgress,
} from '@material-ui/core';
import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { Marker, Popup, useMap, Tooltip, useMapEvents } from 'react-leaflet';
import ReactLeafletDriftMarker from 'react-leaflet-drift-marker';
import { v4 as uuidv4 } from 'uuid';

import Map from '../../components/Map';
import * as L from 'leaflet';
import Searchbar from '../../components/Searchbar';
import MarkerIcon from '../../assets/svg/marker.svg';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import {
    BatteryIcon,
    LocateIcon,
    LocationMobileOffIcon,
    LocationMobileOnIcon,
    LocationPermissionOffIcon,
    LocationPermissionOnIcon,
    MobileIcon,
    NewTabIcon,
} from '../../assets/svg';
import { createCustomMarkerHtml } from '../../components/Map/BikeIcon';
import EmptyStates from '../../components/EmptyStates';
import {
    GetMapVechicleList,
    setTrackingData,
    getMapViewData,
} from '../../store/actions/mapViewActionCreator';
import { getAllStaffs } from '../../store/actions/staffActionCreator';
import { fetchAppConfig } from '../../store/actions/authActionCreator';
import moment from 'moment';
import { Spinner } from '../../components/UI';
import {
    isEmpty,
    vehicleTemp,
    getTimeAsDuration,
    getTimeDifference,
    findArrayObjectIndex,
    getActiveInitRoute,
    getPermissions,
} from '../../utils/helper';
import io from 'socket.io-client';
import CachedIcon from '@material-ui/icons/Cached';
import { LatLng } from 'leaflet';
import ReactDOMServer from 'react-dom/server';
import { CollectionsOutlined } from '@material-ui/icons';
import ListCard from './ListCard';
var socket;
let lastUpdateTime = new Date();

var spiderfyLayer;
var spiderfyOpen = false;

var pendingUpdate = [];
var pendingListUpdate = [];

let searchKey = '';
// let logger = () => {};

const useStyles = makeStyles((theme) => ({
    container: {
        '& .leaflet-container': {
            width: '100%',
            height: '85vh',
            flexGrow: 1,
            // borderRadius: 16,
            borderTopRightRadius: 16,
            borderBottomRightRadius: 16,
        },
    },
    card: {
        backgroundColor: '#f6f7f9',
        marginTop: 16,
        color: '#000',
        padding: 16,
    },

    listItem: {
        display: 'flex',
        flexDirection: 'column',
        flexDirection: 'column',
        alignItems: 'stretch',
        padding: '8px 16px',
        marginTop: '8px',
        color: '#000',
        backgroundColor: '#f6f7f9',
        listStyleType: 'none !important',
        ul: {
            listStyleType: 'none',
        },
    },
    progressRoot: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
}));

const myIcon = L.icon({
    iconUrl: MarkerIcon,
    iconRetinaUrl: MarkerIcon,
    iconSize: [40, 40],
    iconAnchor: [32, 64],
    popupAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
});

function MapView({
    allStaffVehicles,
    getAllStaffs,
    configs,
    dispatchGetMapVechicleList,
    trackingData,
    dispatchSetTrackingData,
    user,
    dispatchGetMapViewData,
    dispatchFetchAppConfig,
    ...props
}) {
    const classes = useStyles();
    const [vehicles, setVehicles] = useState([]);
    const [loading, setLoading] = useState(true);
    const [mapStateEmptyState, setMapStateEmptyState] = useState(true);
    const [totalCount, setTotalCount] = useState(0);
    const [mapViewPayload, setMapViewPayload] = useState({
        pageNo: props.location?.state?.payload?.pageNo ?? 1,
        pageSize: props?.location?.state?.payload?.pageSize ?? 10,
        searchText: props?.location?.state?.payload?.searchText ?? '',
    });

    const [defaultList, setDefaultList] = useState(vehicles);

    // const [finalVehicles, setFinalVehicles] = useState([]);
    const [searchValue, setSearchValue] = useState(
        props?.location?.state?.payload?.searchText ?? '',
    );

    const handleChangePage = (event, newPage) => {
        // setPage(newPage);
        setMapViewPayload({
            ...mapViewPayload,
            pageNo: newPage + 1,
        });
        // onPageChange(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        // setRowsPerPage(+event.target.value);
        // setPage(0);
        setMapViewPayload({
            ...mapViewPayload,
            pageSize: +event.target.value,
            pageNo: 1,
        });
        // if (+event.target.value * page < default?.length) {
        //     setPage(pageNo);
        //     // onPageChange(pageNo);
        // } else {
        //     setPage(0);
        //     // onPageChange(0);
        // }
    };
    useEffect(() => {
        // setDefaultList(vehicles);
        SocketInit();
        setInitialConfig(true);
        // console.log('final Vehicles', finalVehicles);
    }, [vehicles]);

    // useEffect(() => {
    //     searchKey = searchValue;
    //     // logger('serlistRefs', listRefs);
    // }, [searchValue]);
    // useEffect(() => {
    //     console.log('defaultList changed', defaultList, defaultList[50]);
    // }, [defaultList]);
    // useEffect(() => {
    //     logger = console.log;
    //     console.log = () => {};
    // }, []);

    // useEffect(() => {
    // dispatchGetMapViewData(
    //     {},
    //     (resp) => {
    //         console.log('MapView Empty SUCCESS');
    //         if (resp.response.lineItems.length > 0) {
    //             setMapStateEmptyState(false);
    //         }
    //     },
    //     () => {
    //         console.log('MapView Empty FAILED');
    //     },
    // );
    // }, []);

    // useEffect(() => {
    //     if (allStaffVehicles?.length > 0) {
    //         SocketInit();
    //     }
    // }, []);

    function SocketInit() {
        console.log('Socket init');
        const options = {
            forceNew: true,
            autoConnect: true,
            pingInterval: 6000,
            pingTimeout: 10000,
            // transports: ['websocket'],
            agent: false, // [2] Please don't set this to true
            query:
                'token=' +
                user?.config?.beeconWebSocketToken +
                // BEECON_TOKEN +
                '&deviceId=' +
                uuidv4(),
            // user?.config?.beeconOrgId,
        };
        if (socket) {
            console.log('Socket closed');
            socket.disconnect();
            socket.close();
        }
        let url = 'https://www.beecon.in';
        // if (
        //     window &&
        //     window.location &&
        //     window.location.protocol !== 'https:'
        // ) {
        //     url = 'http://www.beecon.in';
        // }
        socket = io.connect(url + '/webSocket', options);
        socket.on('connect', function (data) {
            console.log('ON WEBSOCKET');
            lastUpdateTime = new Date();
        });
        socket.on('error', function (data) {
            console.log('error', data);
        });
        socket.on('vehicletrackingEvent', function (data) {
            if (data) {
            }
        });
        socket.on('vehicleTelematicEvent', async (data) => {
            // console.log('vehicleTelematicEvent', data);
            if (data) {
                setDefaultList((prev) => {
                    return prev.map((item) => ({
                        ...item,
                        tracking:
                            item.beeconVehicleId === data.id
                                ? data
                                : item.tracking,
                    }));
                });

                onLiveFeedDataChange(data);
                // console.log('vvvv', vehicles);
            }
        });
    }

    function onLiveFeedDataChange(data) {
        // let existingIndex = findArrayObjectIndex('id', data.id, finalVehicles);
        let existingIndex = data.id;
        // console.log('mmmmmm', markerRefs);
        // console.log(existingIndex + data.id + 'Live Feed Data Change', data);
        if (
            data &&
            data.telematicData &&
            data.telematicData.gps &&
            data.telematicData.gps.location &&
            data.telematicData.gps.location.lat &&
            data.telematicData.gps.location.long
        ) {
            // console.log('markerRefs', markerRefs);
            // console.log('pendingListUpdate ' + pendingListUpdate);
            // console.log('searchValue ' + searchValue);
            // console.log('searchKey ' + searchKey);
            // console.log('loadinggg ' + loading);

            // logger(data.regNo, ' orginal listRefs', listRefs);

            // if (searchKey?.length <= 0 && !isEmpty(pendingListUpdate)) {
            //     logger(
            //         'pendingListUpdate not empty and search Empty',
            //         pendingListUpdate,
            //     );
            //     let clonePendingList = [...pendingListUpdate];
            //     pendingListUpdate = [];
            //     console.log('clonePendingList', clonePendingList);

            //     clonePendingList.forEach((listData) => {
            //         console.log('inside clonePendingList', listData);
            //         // console.log(
            //         //     'listRefs.current[data.id]',
            //         //     listRefs.current[data.id],
            //         // );

            //         if (listRefs.current[listData.id]) {
            //             let inner = vehicleInfo(listData);

            //             listRefs.current[listData.id].innerHTML =
            //                 ReactDOMServer.renderToString(inner);
            //         }
            //     });
            // }

            // if (listRefs.current[data.id] != null) {
            //     logger(
            //         data.regNo,
            //         ` before listRefs.current[${data.id}]`,
            //         listRefs.current,
            //     );
            //     let inner = vehicleInfo(data);
            //     listRefs.current[data.id].innerHTML =
            //         ReactDOMServer.renderToString(inner);

            //     logger(
            //         data.regNo,
            //         ` after listRefs.current[${data.id}]`,
            //         listRefs.current,
            //         listRefs.current[Object.keys(listRefs.current)[0]],
            //     );
            // } else {
            //     console.log('Pushing to Pending Update');
            //     if (!pendingListUpdate.find((item) => item.id === data.id)) {
            //         pendingListUpdate.push(data);
            //     }
            //     console.log('Pusheddd', pendingListUpdate);
            // }

            // logger(data.regNo, ' after listRefs', listRefs);

            if (spiderfyLayer) {
                console.log('unspiderfying...');
                spiderfyLayer.unspiderfy();
            }

            // console.log('markerRefs', markerRefs, existingIndex);
            if (markerRefs.current[existingIndex]) {
                if (!isEmpty(spiderfyLayer)) {
                    // console.log('spiderfy not empty', spiderfyLayer);
                    pendingUpdate.push({
                        i: existingIndex,
                        data: data,
                    });
                    if (
                        new Date() - lastUpdateTime > 10 * 1000 &&
                        !isEmpty(pendingUpdate)
                    ) {
                        if (!isEmpty(spiderfyLayer)) {
                            // console.log('unspiderfying...');
                            spiderfyLayer.unspiderfy();
                        }
                        spiderfyOpen = false;
                        const clonePendingUpdate = [...pendingUpdate];
                        pendingUpdate = [];
                        for (const key in clonePendingUpdate) {
                            if (clonePendingUpdate.hasOwnProperty(key)) {
                                const element = clonePendingUpdate[key];
                                if (
                                    !isEmpty(element.i) &&
                                    !isEmpty(element.data) &&
                                    element.data.telematicData.gps.location
                                        .lat &&
                                    element.data.telematicData.gps.location.long
                                ) {
                                    markerRefs.current[element.i].setLatLng(
                                        new LatLng(
                                            element.data.telematicData.gps.location.lat,
                                            element.data.telematicData.gps.location.long,
                                        ),
                                    );
                                    markerRefs.current[element.i].setIcon(
                                        createCustomMarkerHtml(
                                            element.data.telematicData.gps
                                                .angle,
                                            element.data.telematicData?.gps
                                                ?.speed > 1 &&
                                                (moment().valueOf() -
                                                    moment(
                                                        element.data
                                                            .telematicData
                                                            .staticStateChangeTime,
                                                    ).valueOf()) /
                                                    60000 <
                                                    30
                                                ? 'ON'
                                                : element.data.telematicData.gps
                                                      .speed < 1 &&
                                                  (moment().valueOf() -
                                                      moment(
                                                          element.data
                                                              .telematicData
                                                              .staticStateChangeTime,
                                                      ).valueOf()) /
                                                      60000 <
                                                      30
                                                ? 'IDLE'
                                                : (moment().valueOf() -
                                                      moment(
                                                          element.data
                                                              .telematicData
                                                              .staticStateChangeTime,
                                                      ).valueOf()) /
                                                      60000 >
                                                  30
                                                ? 'OFF'
                                                : 'IDLE',
                                            element.data.regNo,
                                        ),
                                    );

                                    // markerRefs.current[
                                    //     element.i
                                    // ].setRotationAngle(
                                    //     element.data.telematicData.gps.angle,
                                    // );
                                    //markerRefs.current[existingIndex].setRotationOrigin(markerRefs.current[existingIndex].options.rotationOrigin);

                                    markerRefs.current[
                                        element.i
                                    ]._popup._latlng = new LatLng(
                                        element.data.telematicData.gps.location.lat,
                                        element.data.telematicData.gps.location.long,
                                    );

                                    // if (!isEmpty(leafletMarkerCluster)) {
                                    //     leafletMarkerCluster.refreshClusters(
                                    //         markerRefs.current[element.i],
                                    //     );
                                    // }
                                }
                            }
                        }
                        lastUpdateTime = new Date();
                        spiderfyOpen = true;
                        spiderfyLayer = undefined;
                        // if (!isEmpty(leafletMarkerCluster)) {
                        //     leafletMarkerCluster.refreshClusters();
                        // }
                        // leafletMarkerCluster.refreshClusters();
                        // spiderfyLayer.spiderfy();
                    }
                } else {
                    if (
                        data.telematicData.gps.location.lat &&
                        data.telematicData.gps.location.long
                    ) {
                        // console.log('spiderfy empty', spiderfyLayer);
                        markerRefs.current[existingIndex].setLatLng(
                            new LatLng(
                                data.telematicData.gps.location.lat,
                                data.telematicData.gps.location.long,
                            ),
                        );

                        markerRefs.current[existingIndex].setIcon(
                            createCustomMarkerHtml(
                                data.telematicData.gps.angle,
                                data.telematicData?.gps?.speed > 1 &&
                                    (moment().valueOf() -
                                        moment(
                                            data.telematicData
                                                .staticStateChangeTime,
                                        ).valueOf()) /
                                        60000 <
                                        30
                                    ? 'ON'
                                    : data.telematicData.gps.speed < 1 &&
                                      (moment().valueOf() -
                                          moment(
                                              data.telematicData
                                                  .staticStateChangeTime,
                                          ).valueOf()) /
                                          60000 <
                                          30
                                    ? 'IDLE'
                                    : (moment().valueOf() -
                                          moment(
                                              data.telematicData
                                                  .staticStateChangeTime,
                                          ).valueOf()) /
                                          60000 >
                                      30
                                    ? 'OFF'
                                    : 'IDLE',
                                data.regNo,
                            ),
                        );

                        // markerRefs.current[existingIndex].setRotationAngle(
                        //     data.telematicData.gps.angle,
                        // );

                        markerRefs.current[existingIndex]._popup._latlng =
                            new LatLng(
                                data.telematicData.gps.location.lat,
                                data.telematicData.gps.location.long,
                            );

                        // if (!isEmpty(leafletMarkerCluster)) {
                        //     leafletMarkerCluster.refreshClusters(
                        //         markerRefs.current[existingIndex],
                        //     );
                        //     leafletMarkerCluster.refreshClusters();
                        // }
                    }
                }
            }
        }
    }

    function fetchFeMapData(configs) {
        dispatchGetMapViewData(
            mapViewPayload,
            (resp) => {
                // console.log('MapView Empty SUCCESS');
                if (resp.response.lineItems.length > 0) {
                    setTotalCount(resp.response?.totalCount);
                    setMapStateEmptyState(false);
                    if (
                        configs?.beeconWebSocketToken &&
                        configs?.beeconAPIToken &&
                        configs?.beeconOrgId
                    )
                        if (allStaffVehicles?.length > 0) {
                            // if (Object.keys(trackingData)?.length == 0) {
                            //     getTelimeticVehicles(
                            //         resp.response.lineItems,
                            //         configs,
                            //     );
                            // } else
                            console.log('mapState', mapStateEmptyState);
                            setMapStateEmptyState(false);
                            setLoading(false);

                            let vehicleList = [];
                            let finalVehicleData = [];
                            allStaffVehicles.map((staff) => {
                                let evVehicleId =
                                    staff?.evMapViewInfo?.beeconVehicleId;
                                if (trackingData?.[staff?.beeconVehicleId]) {
                                    // console.log('vehicle found');
                                    if (
                                        trackingData?.[staff?.beeconVehicleId]
                                            ?.telematicData?.gps?.location
                                            ?.lat &&
                                        trackingData?.[staff?.beeconVehicleId]
                                            ?.telematicData?.gps?.location?.long
                                    ) {
                                        vehicleList.push({
                                            ...staff,
                                            tracking: {
                                                ...trackingData?.[
                                                    staff?.beeconVehicleId
                                                ],
                                            },
                                        });

                                        finalVehicleData.push(
                                            trackingData?.[
                                                staff?.beeconVehicleId
                                            ],
                                        );
                                    }
                                }
                                if (
                                    !!evVehicleId &&
                                    trackingData?.[evVehicleId]
                                ) {
                                    // console.log('vehicle found');
                                    if (
                                        trackingData?.[evVehicleId]
                                            ?.telematicData?.gps?.location
                                            ?.lat &&
                                        trackingData?.[evVehicleId]
                                            ?.telematicData?.gps?.location?.long
                                    ) {
                                        vehicleList.push({
                                            ...staff,
                                            evVehicle: true,
                                            vehicleNumber:
                                                staff?.evMapViewInfo
                                                    ?.evNumber ?? '',
                                            tracking: {
                                                ...trackingData?.[evVehicleId],
                                            },
                                        });

                                        finalVehicleData.push(
                                            trackingData?.[evVehicleId],
                                        );
                                    }
                                }
                            });
                            // setFinalVehicles(finalVehicleData);
                            setVehicles(vehicleList);
                            setDefaultList(vehicleList);
                            getTelimeticVehicles(
                                resp.response.lineItems,
                                configs,
                            );
                        } else {
                            setMapStateEmptyState(true);
                            setLoading(false);
                        }
                } else {
                    setMapStateEmptyState(true);
                    setLoading(false);
                }
            },
            () => {
                setMapStateEmptyState(true);
                setLoading(false);
                // console.log('MapView Empty FAILED');
            },
        );
    }

    const [leafletMarkerCluster, setLeafletMarkerCluster] = useState();

    const markerRefs = React.useRef([]);
    const listRefs = React.useRef({});
    const [permissions, setPermissions] = useState(getPermissions('map_view'));

    useEffect(async () => {
        await setPermissions(getPermissions('map_view'));
        if (!permissions.read) {
            props.history.push(`/${getActiveInitRoute()}`);
        }
    }, [configs]);
    useEffect(async () => {
        if (!trackingData && !allStaffVehicles) {
            setMapStateEmptyState(true);
        } else {
            setMapStateEmptyState(false);
        }
    }, [trackingData, allStaffVehicles]);
    // useEffect(() => {
    //     if (vehicles.length > 0) {
    //         markerRefs.current = markerRefs.current.slice(0, vehicles.length);
    //         listRefs.current = listRefs.current.slice(0, vehicles.length);
    //     }
    // }, [vehicles]);

    useEffect(() => {
        if (!permissions.read) {
            props.history.push(`/${getActiveInitRoute()}`);
            return;
        }
        // console.log('final Vehicles', finalVehicles);
        // console.log('vehicles', vehicles);
        // console.log('defaultList', defaultList);

        // console.log('TRACKING_DATA', trackingData);
        // console.log('allStaffVehicles', allStaffVehicles);

        // console.log(
        //     'TRACKING_DATA L ',
        //     trackingData && Object.keys(trackingData).length === 0,
        // );
        // console.log('allStaffVehicles L ', allStaffVehicles?.length);
        setLoading(true);
        dispatchFetchAppConfig((response) => {
            fetchFeMapData(response?.response?.configs);
        });
        // return () => {
        //     if (socket) {
        //         console.log('Socket disconnectingg.....');
        //         socket.disconnect();
        //         socket.close();
        //     }
        // };
    }, []);

    useEffect(() => {
        fetchFeMapData(configs);
    }, [mapViewPayload]);

    // function vehicleInfo(data) {
    //     let existingIndex = findArrayObjectIndex(
    //         'beeconVehicleId',
    //         data.id,
    //         vehicles,
    //     );

    //     const vehc = vehicles.find((item) => item.beeconVehicleId === data.id);

    //     // logger('vehc', vehc, 'beeconVehicleId', data.id, vehicles);

    //     let row = { ...vehc, tracking: data };

    //     if (row) {
    //         return (
    //             <>
    //                 <div
    //                     // elevation={0}
    //                     // ref={(listItem) =>
    //                     //     (listRefs.current[row.beeconVehicleId] = listItem)
    //                     // }
    //                     // className={classes.listItem}
    //                     style={{
    //                         display: 'flex',
    //                         flexDirection: 'column',
    //                         flexDirection: 'column',
    //                         alignItems: 'stretch',
    //                         color: '#000',
    //                         backgroundColor: '#f6f7f9',
    //                         listStyleType: 'none !important',
    //                         // ul: {
    //                         //     listStyleType: 'none',
    //                         // },
    //                     }}
    //                     onClick={() => {
    //                         // alert('listItem' + JSON.stringify(row));
    //                         props.history.push(
    //                             `/map-view/detail/${row.contactNumber}`,
    //                             // { data: row },
    //                         );
    //                     }}
    //                 >
    //                     <div
    //                         style={{
    //                             display: 'flex',
    //                             flexDirection: 'row',
    //                             justifyContent: 'space-between',
    //                         }}
    //                     >
    //                         <div
    //                             // variant="body1"
    //                             // component="div"
    //                             style={{
    //                                 fontWeight: 'bold',
    //                                 fontSize: 16,
    //                             }}
    //                         >
    //                             {row.name}
    //                         </div>
    //                         <div
    //                             // variant="h6"
    //                             // component="div"
    //                             // style={{
    //                             //     fontWeight: 'bold',
    //                             //     fontSize: 14,
    //                             // }}

    //                             style={
    //                                 row?.tracking?.telematicData?.gps?.speed >
    //                                     1 &&
    //                                 (moment().valueOf() -
    //                                     moment(
    //                                         row?.tracking?.telematicData
    //                                             ?.staticStateChangeTime,
    //                                     ).valueOf()) /
    //                                     60000 <
    //                                     30
    //                                     ? {
    //                                           fontSize: 14,
    //                                           color: 'green',
    //                                           fontWeight: 600,
    //                                       }
    //                                     : row?.tracking?.telematicData?.gps
    //                                           ?.speed < 1 &&
    //                                       (moment().valueOf() -
    //                                           moment(
    //                                               row?.tracking?.telematicData
    //                                                   ?.staticStateChangeTime,
    //                                           ).valueOf()) /
    //                                           60000 <
    //                                           30
    //                                     ? {
    //                                           fontSize: 14,
    //                                           fontWeight: 600,
    //                                           color: 'red',
    //                                       }
    //                                     : {
    //                                           fontSize: 14,
    //                                           fontWeight: 600,
    //                                           color: 'black',
    //                                       }
    //                             }
    //                         >
    //                             {row?.tracking?.telematicData?.gps?.speed > 1 &&
    //                             (moment().valueOf() -
    //                                 moment(
    //                                     row?.tracking?.telematicData
    //                                         ?.staticStateChangeTime,
    //                                 ).valueOf()) /
    //                                 60000 <
    //                                 30
    //                                 ? 'MOVING'
    //                                 : row?.tracking?.telematicData?.gps?.speed <
    //                                       1 &&
    //                                   (moment().valueOf() -
    //                                       moment(
    //                                           row?.tracking?.telematicData
    //                                               ?.staticStateChangeTime,
    //                                       ).valueOf()) /
    //                                       60000 <
    //                                       30
    //                                 ? 'IDLE'
    //                                 : 'OFFLINE'}
    //                         </div>
    //                     </div>
    //                     <div
    //                         style={{
    //                             display: 'flex',
    //                             flexDirection: 'row',
    //                             justifyContent: 'space-between',
    //                         }}
    //                     >
    //                         <div
    //                             // gutterBottom
    //                             // variant="body2"
    //                             // component="div"
    //                             style={{
    //                                 fontSize: 14,
    //                                 fontWeight: 600,
    //                             }}
    //                         >
    //                             {row.hubName ?? '-'} |{' '}
    //                             {row.contactNumber ?? '-'}
    //                         </div>
    //                         <div
    //                             // gutterBottom
    //                             // variant="body2"
    //                             // component="div"
    //                             style={{
    //                                 fontSize: 14,
    //                             }}
    //                         >
    //                             {row?.vehicleNumber?.slice(
    //                                 0,
    //                                 row?.vehicleNumber.length - 4,
    //                             ) ?? ''}
    //                             <b>
    //                                 {row?.vehicleNumber?.slice(
    //                                     row?.vehicleNumber.length - 4,
    //                                 ) ?? ''}
    //                             </b>
    //                         </div>
    //                     </div>
    //                     <div
    //                         style={{
    //                             display: 'flex',
    //                             flexDirection: 'row',
    //                             justifyContent: 'space-between',
    //                         }}
    //                     >
    //                         <div
    //                             style={{
    //                                 marginBotton: 8,
    //                                 display: 'flex',
    //                                 flexDirection: 'row',
    //                                 alignItems: 'center',
    //                             }}
    //                         >
    //                             <div
    //                                 style={{
    //                                     display: 'flex',
    //                                     flexDirection: 'column',
    //                                     justifyContent: 'space-between',
    //                                 }}
    //                             >
    //                                 <div
    //                                     style={{
    //                                         fontSize: 12,
    //                                         marginRight: 1,
    //                                     }}
    //                                 >
    //                                     {`Speed`}
    //                                 </div>
    //                                 <div
    //                                     style={{
    //                                         fontSize: 12,
    //                                         marginRight: 1,
    //                                     }}
    //                                 >
    //                                     {`Updated`}
    //                                 </div>
    //                             </div>
    //                             <div
    //                                 style={{
    //                                     display: 'flex',
    //                                     flexDirection: 'column',
    //                                     justifyContent: 'space-between',
    //                                 }}
    //                             >
    //                                 <div
    //                                     style={{
    //                                         fontWeight: 600,
    //                                         fontSize: 12,
    //                                     }}
    //                                 >
    //                                     {` : ${row?.tracking?.telematicData?.gps?.speed}`}
    //                                     {` Km/Hr (${moment(
    //                                         row?.tracking?.telematicData
    //                                             ?.staticStateChangeTime,
    //                                     ).fromNow()})`}
    //                                 </div>

    //                                 <div
    //                                     style={{
    //                                         fontWeight: 600,
    //                                         fontSize: 12,
    //                                     }}
    //                                 >
    //                                     {` : ${moment(
    //                                         row?.tracking?.telematicData
    //                                             ?.unixTimeStamp,
    //                                     ).fromNow()}`}
    //                                 </div>
    //                             </div>
    //                         </div>
    //                         <div
    //                             style={{
    //                                 border: '2px solid red',
    //                                 // top: '5rem',
    //                             }}
    //                         >
    //                             <button
    //                                 style={
    //                                     {
    //                                         // position: 'absolute',
    //                                         // top: '12px',
    //                                         // right: '0px',
    //                                     }
    //                                 }
    //                                 onClick={(e) => {
    //                                     // alert(listRefs.current[row.beeconVehicleId]);
    //                                     e.stopPropagation();
    //                                     // console.log('GGGGGGGGGG', e);
    //                                     // console.log(
    //                                     //     'LatLONGGGGGGG',
    //                                     //     row?.tracking?.telematicData?.gps
    //                                     //         ?.location?.lat,
    //                                     //     row?.tracking?.telematicData?.gps
    //                                     //         ?.location?.long,
    //                                     // );
    //                                     if (
    //                                         row?.tracking?.telematicData?.gps
    //                                             ?.location?.lat &&
    //                                         row?.tracking?.telematicData?.gps
    //                                             ?.location?.long
    //                                     )
    //                                         setFocusLatLong({
    //                                             lat: row?.tracking
    //                                                 ?.telematicData?.gps
    //                                                 ?.location?.lat,
    //                                             long: row?.tracking
    //                                                 ?.telematicData?.gps
    //                                                 ?.location?.long,
    //                                         });

    //                                     setFocusMarker(true);
    //                                 }}
    //                             >
    //                                 <LocateIcon />
    //                             </button>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </>
    //         );
    //     }
    // }

    function vehicleCardList(data) {
        listRefs.current = {};
        const evs = data?.filter(
            (vehicle) => !!vehicle && !!vehicle?.evVehicle,
        );
        if (data)
            return data
                ?.filter((vehicle) => !!vehicle && !vehicle?.evVehicle)
                .map((row, index) => (
                    <div key={index}>
                        <ListItem
                            button
                            component="a"
                            key={index}
                            // elevation={0}
                            ref={(listItem) =>
                                (listRefs.current[row?.beeconVehicleId] =
                                    listItem)
                            }
                            // className={classes.listItem}
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'stretch',
                                padding: '8px 16px',
                                marginTop: '8px',
                                color: '#000',
                                backgroundColor: '#f6f7f9',
                                listStyleType: 'none !important',
                                borderRadius: 16,
                                // ul: {
                                //     listStyleType: 'none',
                                // },
                            }}
                            onClick={(e) => {
                                e.stopPropagation();
                                // alert('listItem' + JSON.stringify(row));
                                // window.open(`/map-view/detail/${row.id}`);
                                props.history.push(
                                    `/map-view/detail/${row.id}`,
                                    {
                                        data: row,
                                        payload: mapViewPayload,
                                    },
                                );
                            }}
                        >
                            <ListCard
                                row={row}
                                evs={evs}
                                setFocusLatLong={setFocusLatLong}
                                setFocusMarker={setFocusMarker}
                            />
                        </ListItem>
                    </div>
                ));
    }

    const getTelimeticVehicles = async (
        staffVehicles = allStaffVehicles,
        userConf = user.config,
    ) => {
        await setLoading(true);

        // await setFinalVehicles([]);
        await setDefaultList([]);
        await setVehicles([]);
        markerRefs.current = {};
        const payload = {
            action: 'getOrgTelematicVehicles',
            data: {
                type: 'profile',
                subtype: 'vehicle',
                org: {
                    id: userConf?.beeconOrgId,
                },
            },
            bo: { id: 'bo_trade_fleet_management' },
            resolveDeps: false,
        };
        dispatchGetMapVechicleList(
            { token: `Bearer ${userConf?.beeconAPIToken}`, data: payload },
            (resp) => {
                // console.log('response On Success', resp);
                // console.log('staffVehicles', staffVehicles);
                // console.log('allStaffVehicles', allStaffVehicles);
                // console.log('trackingData', trackingData);
                let vehicleList = [];
                let finalVehicleData = [];
                if (staffVehicles.length) {
                    staffVehicles.map((staff) => {
                        if (resp?.[staff?.beeconVehicleId]) {
                            // console.log('vehicle found');
                            if (
                                resp?.[staff?.beeconVehicleId]?.telematicData
                                    ?.gps?.location?.lat &&
                                resp?.[staff?.beeconVehicleId]?.telematicData
                                    ?.gps?.location?.long
                            ) {
                                vehicleList.push({
                                    ...staff,
                                    tracking: {
                                        ...resp?.[staff?.beeconVehicleId],
                                    },
                                });

                                finalVehicleData.push(
                                    resp?.[staff?.beeconVehicleId],
                                );
                            }
                        }
                        let evVehicleId = staff?.evMapViewInfo?.beeconVehicleId;
                        console.log('the staff found', resp);
                        if (evVehicleId && resp?.[evVehicleId]) {
                            // console.log('vehicle found');
                            if (
                                resp?.[evVehicleId]?.telematicData?.gps
                                    ?.location?.lat &&
                                resp?.[evVehicleId]?.telematicData?.gps
                                    ?.location?.long
                            ) {
                                vehicleList.push({
                                    ...staff,
                                    evVehicle: true,
                                    vehicleNumber:
                                        staff?.evMapViewInfo?.evNumber ?? '',
                                    tracking: {
                                        ...resp?.[evVehicleId],
                                    },
                                });

                                finalVehicleData.push(resp?.[evVehicleId]);
                            }
                        }
                    });
                    // setFinalVehicles(finalVehicleData);
                    setVehicles(vehicleList);
                    setDefaultList(vehicleList);
                }

                setLoading(false);
            },
            (resp) => {
                setLoading(false);
                console.log('dispatchGetMapVechicleList failed');
                console.log(resp);
            },
        );
    };

    const [focusMarker, setFocusMarker] = useState(false);
    const [initialConfig, setInitialConfig] = useState(false);

    const [focusLatLong, setFocusLatLong] = useState({
        lat: 13.034259,
        long: 80.24893,
    });

    function FocusMarker() {
        const map = useMap();
        map.setView({ lat: focusLatLong?.lat, lng: focusLatLong?.long }, 17);
        setFocusMarker(false);
        return null;
    }

    function ListItemLink(props) {
        return <ListItem button component="a" {...props} />;
    }

    function SetBounds() {
        let bounds = [];

        if (vehicles?.length) {
            vehicles?.map((vehicle, index) => {
                if (
                    vehicle?.tracking?.telematicData?.gps?.location?.lat &&
                    vehicle?.tracking?.telematicData?.gps?.location?.long
                ) {
                    bounds.push([
                        vehicle?.tracking?.telematicData?.gps?.location?.lat,
                        vehicle?.tracking?.telematicData?.gps?.location?.long,
                    ]);
                }
            });
        } else {
            bounds.push([13.034259, 80.24893]);
        }

        const map = useMap();
        map.fitBounds(bounds);

        // console.log('boundsss', bounds);
        setInitialConfig(false);
        return null;
    }

    function searchVehicle(data) {
        // if (!searchValue) {
        //     logger('checking', Object.keys(listRefs.current)?.length);
        //     logger(
        //         'profile_vehicle_KRMIOTC08232',
        //         Object.keys(listRefs.current)?.filter(
        //             (item) => item === 'profile_vehicle_KRMIOTC08232',
        //         ).length,
        //     );
        //     listRefs.current = {};
        // }
        return data.sort(function (a, b) {
            let isAMoving =
                a?.tracking?.telematicData?.gps?.speed > 1 &&
                (moment().valueOf() -
                    moment(
                        a?.tracking?.telematicData?.staticStateChangeTime,
                    ).valueOf()) /
                    60000 <
                    30
                    ? parseInt(
                          '0' +
                              (moment().valueOf() -
                                  moment(
                                      a?.tracking?.telematicData
                                          ?.staticStateChangeTime,
                                  ).valueOf()) /
                                  60000,
                      )
                    : a?.tracking?.telematicData?.gps?.speed < 1 &&
                      (moment().valueOf() -
                          moment(
                              a?.tracking?.telematicData?.staticStateChangeTime,
                          ).valueOf()) /
                          60000 <
                          30
                    ? parseInt(
                          '1' +
                              (moment().valueOf() -
                                  moment(
                                      a?.tracking?.telematicData
                                          ?.staticStateChangeTime,
                                  ).valueOf()) /
                                  60000,
                      )
                    : parseInt(
                          '2' +
                              (moment().valueOf() -
                                  moment(
                                      a?.tracking?.telematicData
                                          ?.staticStateChangeTime,
                                  ).valueOf()) /
                                  60000,
                      );
            let isBMoving =
                b?.tracking?.telematicData?.gps?.speed > 1 &&
                (moment().valueOf() -
                    moment(
                        b?.tracking?.telematicData?.staticStateChangeTime,
                    ).valueOf()) /
                    60000 <
                    30
                    ? parseInt(
                          '0' +
                              (moment().valueOf() -
                                  moment(
                                      b?.tracking?.telematicData
                                          ?.staticStateChangeTime,
                                  ).valueOf()) /
                                  60000,
                      )
                    : b?.tracking?.telematicData?.gps?.speed < 1 &&
                      (moment().valueOf() -
                          moment(
                              b?.tracking?.telematicData?.staticStateChangeTime,
                          ).valueOf()) /
                          60000 <
                          30
                    ? parseInt(
                          '1' +
                              (moment().valueOf() -
                                  moment(
                                      b?.tracking?.telematicData
                                          ?.staticStateChangeTime,
                                  ).valueOf()) /
                                  60000,
                      )
                    : parseInt(
                          '2' +
                              (moment().valueOf() -
                                  moment(
                                      b?.tracking?.telematicData
                                          ?.staticStateChangeTime,
                                  ).valueOf()) /
                                  60000,
                      );
            return isAMoving < isBMoving ? -1 : isAMoving > isBMoving ? 1 : 0;
        });
        // ?.filter(
        //     (item) =>
        //         item.name
        //             ?.toLowerCase()
        //             .includes(searchValue?.toLowerCase()) ||
        //         item.hubName
        //             ?.toLowerCase()
        //             ?.includes(searchValue?.toLowerCase()) ||
        //         item.contactNumber
        //             ?.toLowerCase()
        //             .includes(searchValue?.toLowerCase()) ||
        //         item.vehicleNumber
        //             ?.toLowerCase()
        //             .includes(searchValue?.toLowerCase()) ||
        //         item?.deviceBrand
        //             ?.toLowerCase()
        //             .includes(searchValue?.toLowerCase()) ||
        //         item?.appVersion
        //             ?.toLowerCase()
        //             .includes(searchValue?.toLowerCase()) ||
        //         item?.deviceModel
        //             ?.toLowerCase()
        //             .includes(searchValue?.toLowerCase()),
        // );
    }
    console.log('the defaultList', defaultList, allStaffVehicles);
    return (
        <Spinner loading={loading}>
            {!mapStateEmptyState ? (
                <Paper style={{ padding: '.1rem' }} elevation={0}>
                    <div className={classes.container}>
                        <Grid container spacing={1}>
                            <Grid item xs={4} style={{ padding: '18px 12px' }}>
                                <Box
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Searchbar
                                        placeHolderText="Search by staff details"
                                        // style={{
                                        //     color: '#14112d',
                                        //     height: '32px',
                                        //     fontSize: '14px',
                                        // }}
                                        onFilter={() => {
                                            // // console.log('search bar filtering');
                                        }}
                                        backendSearchbar={true}
                                        onBackendSearch={(searchText) => {
                                            setMapViewPayload({
                                                ...mapViewPayload,
                                                pageNo: 1,
                                                searchText: searchText,
                                            });
                                        }}
                                        value={searchValue}
                                        onChange={(value) => {
                                            console.log('blur', value);
                                            // setPage(0);
                                            setSearchValue(value);
                                            // setSearchValue(value);

                                            // console.log(
                                            //     'listRefsSearch',
                                            //     listRefs,
                                            // );
                                            // setDefaultList(
                                            //     vehicles?.filter(
                                            //         (item) =>
                                            //             item.name
                                            //                 ?.toLowerCase()
                                            //                 .includes(
                                            //                     value?.toLowerCase(),
                                            //                 ) ||
                                            //             item.hubName
                                            //                 ?.toLowerCase()
                                            //                 ?.includes(
                                            //                     value?.toLowerCase(),
                                            //                 ) ||
                                            //             item.contactNumber
                                            //                 ?.toLowerCase()
                                            //                 .includes(
                                            //                     value?.toLowerCase(),
                                            //                 ) ||
                                            //             item.vehicleNumber
                                            //                 ?.toLowerCase()
                                            //                 .includes(
                                            //                     value?.toLowerCase(),
                                            //                 ),
                                            //     ),
                                            // );
                                        }}
                                    />
                                    <Box
                                        style={{
                                            padding: '0 0 0 16px',
                                        }}
                                    >
                                        <MUITooltip
                                            title="Reload"
                                            placement="top"
                                        >
                                            <IconButton
                                                style={{
                                                    padding: '2px',
                                                }}
                                                onClick={async () => {
                                                    await getTelimeticVehicles();
                                                    SocketInit();
                                                }}
                                            >
                                                <CachedIcon />
                                            </IconButton>
                                        </MUITooltip>
                                    </Box>
                                </Box>
                                <List component="nav">
                                    <Box
                                        style={{
                                            height: '73vh',
                                            overflowY: 'auto',
                                        }}
                                    >
                                        {defaultList?.length > 0 &&
                                            vehicleCardList(
                                                // searchValue?.length > 0 ?
                                                searchVehicle(defaultList),
                                            )}
                                    </Box>
                                </List>
                                <TablePagination
                                    style={{
                                        minHeight: '10px',
                                        maxHeight: '30px',
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                    rowsPerPageOptions={[10, 25, 50]}
                                    component="div"
                                    count={totalCount}
                                    rowsPerPage={mapViewPayload.pageSize}
                                    page={mapViewPayload.pageNo - 1}
                                    onChangePage={handleChangePage}
                                    onChangeRowsPerPage={
                                        handleChangeRowsPerPage
                                    }
                                />
                            </Grid>
                            <Grid item xs={8}>
                                <Map
                                    // center={[13.034259, 80.24893]}
                                    // bounds={[
                                    //     [13.034259, 80.24893],
                                    //     [13.034259, 80.24893],
                                    // ]}
                                    className="markercluster-map"
                                    scrollWheelZoom={true}
                                    zoom={8}
                                >
                                    {/* {position && (
                                    <ChangeView
                                        position={position}
                                        markers={[position]}
                                    />
                                )} */}
                                    {defaultList?.length > 0 && (
                                        <MarkerClusterGroup
                                            showCoverageOnHover={false}
                                            spiderfyOnMaxZoom={false}
                                            disableClusteringAtZoom={16}
                                            // chunkedLoading={false}
                                            // maxClusterRadius={60}
                                            animate={false}
                                            ref={(cluster) => {
                                                setLeafletMarkerCluster(
                                                    cluster,
                                                );
                                            }}
                                            onMouseOver={(cluster) => {
                                                // console.log(
                                                //     'cluster- spiderfy opened',
                                                //     cluster.target._spiderfied,
                                                // );
                                                // lastUpdateTime = new Date();
                                                // spiderfyOpen = true;
                                                cluster.layer.spiderfy();

                                                spiderfyLayer =
                                                    cluster.target._spiderfied;
                                            }}
                                        >
                                            {defaultList?.length > 0 ? (
                                                defaultList?.map(
                                                    (vehicle, index) => {
                                                        let trackingData =
                                                            vehicle;

                                                        if (index == 0) {
                                                            // console.log(
                                                            //     'vehicleDATAAAA',
                                                            //     vehicle,
                                                            // );
                                                        }

                                                        if (
                                                            trackingData &&
                                                            trackingData?.tracking &&
                                                            trackingData
                                                                ?.tracking
                                                                ?.telematicData
                                                                ?.gps?.location
                                                                ?.lat &&
                                                            trackingData
                                                                ?.tracking
                                                                ?.telematicData
                                                                ?.gps?.location
                                                                ?.long
                                                        ) {
                                                            // console.log(
                                                            //     'Update marker ' +
                                                            //         trackingData[
                                                            //             vehicle
                                                            //                 ?.tracking
                                                            //                 ?.id
                                                            //         ] +
                                                            //         'only',
                                                            // );
                                                            return (
                                                                <Marker
                                                                    // position={this.state.latlng}
                                                                    key={
                                                                        vehicle
                                                                            ?.tracking
                                                                            ?.id
                                                                    }
                                                                    ref={(
                                                                        marker,
                                                                    ) =>
                                                                        (markerRefs.current[
                                                                            vehicle?.tracking?.id
                                                                        ] =
                                                                            marker)
                                                                    }
                                                                    icon={createCustomMarkerHtml(
                                                                        trackingData
                                                                            ?.tracking
                                                                            ?.telematicData
                                                                            ?.gps
                                                                            ?.angle,

                                                                        trackingData
                                                                            ?.tracking
                                                                            ?.telematicData
                                                                            ?.gps
                                                                            ?.speed >
                                                                            1 &&
                                                                            (moment().valueOf() -
                                                                                moment(
                                                                                    trackingData
                                                                                        ?.tracking
                                                                                        ?.telematicData
                                                                                        ?.staticStateChangeTime,
                                                                                ).valueOf()) /
                                                                                60000 <
                                                                                30
                                                                            ? 'ON'
                                                                            : trackingData
                                                                                  ?.tracking
                                                                                  ?.telematicData
                                                                                  ?.gps
                                                                                  ?.speed <
                                                                                  1 &&
                                                                              (moment().valueOf() -
                                                                                  moment(
                                                                                      trackingData
                                                                                          ?.tracking
                                                                                          ?.telematicData
                                                                                          ?.staticStateChangeTime,
                                                                                  ).valueOf()) /
                                                                                  60000 <
                                                                                  30
                                                                            ? 'IDLE'
                                                                            : (moment().valueOf() -
                                                                                  moment(
                                                                                      trackingData
                                                                                          ?.tracking
                                                                                          ?.telematicData
                                                                                          ?.staticStateChangeTime,
                                                                                  ).valueOf()) /
                                                                                  60000 >
                                                                              30
                                                                            ? 'OFF'
                                                                            : 'IDLE',

                                                                        trackingData
                                                                            ?.tracking
                                                                            ?.regNo ??
                                                                            'NA',
                                                                    )}
                                                                    position={
                                                                        trackingData
                                                                            ?.tracking
                                                                            ?.telematicData
                                                                            ?.gps
                                                                            ?.location
                                                                            ?.lat &&
                                                                        trackingData
                                                                            ?.tracking
                                                                            ?.telematicData
                                                                            ?.gps
                                                                            ?.location
                                                                            ?.long
                                                                            ? [
                                                                                  trackingData
                                                                                      ?.tracking
                                                                                      ?.telematicData
                                                                                      ?.gps
                                                                                      ?.location
                                                                                      ?.lat,
                                                                                  trackingData
                                                                                      ?.tracking
                                                                                      ?.telematicData
                                                                                      ?.gps
                                                                                      ?.location
                                                                                      ?.long,
                                                                              ]
                                                                            : null
                                                                    }
                                                                    // duration={1000}
                                                                    // icon={iconPerson}
                                                                >
                                                                    {/* <Popup
                                                            direction="bottom"
                                                            opacity={1}
                                                        >
                                                            {
                                                                trackingData?.tracking?.regNo
                                                            }
                                                        </Popup> */}

                                                                    {trackingData
                                                                        ?.tracking
                                                                        ?.telematicData
                                                                        ?.gps
                                                                        ?.location
                                                                        ?.lat &&
                                                                        trackingData
                                                                            ?.tracking
                                                                            ?.telematicData
                                                                            ?.gps
                                                                            ?.location
                                                                            ?.long && (
                                                                            <Popup
                                                                                key={`popup-vehicle-${index}`}
                                                                                position={[
                                                                                    trackingData
                                                                                        ?.tracking
                                                                                        ?.telematicData
                                                                                        ?.gps
                                                                                        ?.location
                                                                                        ?.lat,
                                                                                    trackingData
                                                                                        ?.tracking
                                                                                        ?.telematicData
                                                                                        ?.gps
                                                                                        ?.location
                                                                                        ?.long,
                                                                                ]}
                                                                                closeButton={
                                                                                    false
                                                                                }
                                                                                style={{
                                                                                    bottom: '10px',
                                                                                }}
                                                                            >
                                                                                <div
                                                                                    id="fleet-home-iw"
                                                                                    className="fleet-home-iw"
                                                                                >
                                                                                    <div id="regNo">
                                                                                        <label>
                                                                                            Reg
                                                                                            No
                                                                                            :&nbsp;
                                                                                        </label>
                                                                                        <strong>
                                                                                            <span>
                                                                                                <a
                                                                                                    onClick={() => {
                                                                                                        props.history.push(
                                                                                                            `/map-view/detail/${vehicle.id}`,
                                                                                                            {
                                                                                                                data: vehicle,
                                                                                                            },
                                                                                                        );
                                                                                                        // console.log(
                                                                                                        //         vehicle
                                                                                                        //             ?.tracking
                                                                                                        //             ?.id
                                                                                                        // );
                                                                                                    }}
                                                                                                >
                                                                                                    {
                                                                                                        vehicle
                                                                                                            ?.tracking
                                                                                                            ?.regNo
                                                                                                    }
                                                                                                </a>
                                                                                                &nbsp;
                                                                                            </span>
                                                                                        </strong>
                                                                                        <br />
                                                                                    </div>
                                                                                    <div id="name">
                                                                                        <label>
                                                                                            Name
                                                                                            :&nbsp;
                                                                                        </label>{' '}
                                                                                        <span
                                                                                            style={{
                                                                                                fontWeight: 600,
                                                                                            }}
                                                                                        >
                                                                                            {
                                                                                                vehicle?.name
                                                                                            }
                                                                                        </span>
                                                                                        <br />
                                                                                    </div>
                                                                                    <div id="hubName">
                                                                                        <label>
                                                                                            Hub
                                                                                            Name
                                                                                            :&nbsp;
                                                                                        </label>
                                                                                        <span
                                                                                            style={{
                                                                                                fontWeight: 600,
                                                                                            }}
                                                                                        >
                                                                                            {
                                                                                                vehicle?.hubName
                                                                                            }
                                                                                        </span>
                                                                                        <br />
                                                                                    </div>
                                                                                    <div id="telematicData_speed_mod">
                                                                                        <label>
                                                                                            Speed
                                                                                            :&nbsp;
                                                                                        </label>
                                                                                        <span
                                                                                            style={{
                                                                                                fontWeight: 600,
                                                                                            }}
                                                                                        >
                                                                                            {
                                                                                                // trackingData?.[
                                                                                                //     vehicle
                                                                                                //         ?.tracking
                                                                                                //         ?.id
                                                                                                // ]
                                                                                                //     ?.telematicData &&
                                                                                                // trackingData?.[
                                                                                                //     vehicle
                                                                                                //         ?.tracking
                                                                                                //         ?.id
                                                                                                // ]
                                                                                                //     ?.telematicData
                                                                                                //     ?.gps &&
                                                                                                // trackingData?.[
                                                                                                //     vehicle
                                                                                                //         ?.tracking
                                                                                                //         ?.id
                                                                                                // ]
                                                                                                //     ?.telematicData
                                                                                                //     ?.gps
                                                                                                //     ?.speed
                                                                                                //     ?
                                                                                                trackingData
                                                                                                    ?.tracking
                                                                                                    ?.telematicData
                                                                                                    ?.gps
                                                                                                    ?.speed ??
                                                                                                    0
                                                                                            }{' '}
                                                                                            Km/hr
                                                                                        </span>
                                                                                        <span
                                                                                            style={{
                                                                                                fontWeight: 600,
                                                                                            }}
                                                                                        >
                                                                                            {trackingData
                                                                                                ?.tracking
                                                                                                ?.telematicData
                                                                                                ?.staticStateChangeTime &&
                                                                                                ' ( ' +
                                                                                                    getTimeAsDuration(
                                                                                                        getTimeDifference(
                                                                                                            trackingData
                                                                                                                ?.tracking
                                                                                                                ?.telematicData
                                                                                                                ?.staticStateChangeTime,
                                                                                                        ),
                                                                                                        'small',
                                                                                                    ) +
                                                                                                    ' ) '}
                                                                                        </span>{' '}
                                                                                        <br />
                                                                                    </div>

                                                                                    <div id="telematicData_unixTimeStamp_mod">
                                                                                        <label>
                                                                                            Updated
                                                                                            :&nbsp;
                                                                                        </label>
                                                                                        <span
                                                                                            style={{
                                                                                                fontWeight: 600,
                                                                                            }}
                                                                                        >
                                                                                            {!isEmpty(
                                                                                                trackingData
                                                                                                    ?.tracking
                                                                                                    ?.statusChangeDate,
                                                                                            )
                                                                                                ? getTimeAsDuration(
                                                                                                      getTimeDifference(
                                                                                                          trackingData
                                                                                                              ?.tracking
                                                                                                              ?.statusChangeDate,
                                                                                                      ),
                                                                                                      'small',
                                                                                                  )
                                                                                                : getTimeAsDuration(
                                                                                                      getTimeDifference(
                                                                                                          trackingData
                                                                                                              ?.tracking
                                                                                                              ?.telematicData
                                                                                                              ?.unixTimeStamp,
                                                                                                      ),
                                                                                                      'small',
                                                                                                  )}{' '}
                                                                                            ago&nbsp;
                                                                                        </span>
                                                                                        <br />
                                                                                    </div>
                                                                                </div>
                                                                            </Popup>
                                                                        )}
                                                                </Marker>

                                                                // <Marker
                                                                //     key={index}
                                                                //     ref={(marker) =>
                                                                //         (markerRefs.current[index] =
                                                                //             marker)
                                                                //     }
                                                                //     icon={myIcon}
                                                                //     position={[
                                                                //         vehicle?.tracking
                                                                //             ?.telematicData?.gps
                                                                //             ?.location?.lat,
                                                                //         vehicle?.tracking
                                                                //             ?.telematicData?.gps
                                                                //             ?.location?.long,
                                                                //     ]}
                                                                // ></Marker>
                                                            );
                                                        }
                                                    },
                                                ) // <Marker
                                            ) : (
                                                <></>
                                            )}
                                        </MarkerClusterGroup>
                                    )}

                                    {focusMarker ? <FocusMarker /> : <></>}
                                    {initialConfig ? <SetBounds /> : <></>}
                                </Map>
                            </Grid>
                        </Grid>
                    </div>
                </Paper>
            ) : (
                <>
                    <Box
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <EmptyStates
                            type="MapView"
                            header1={
                                !!loading
                                    ? 'Loading the map data!'
                                    : 'Enable Track and Trace to Start Tracking Your Staff'
                            }
                        />
                    </Box>
                    {!!loading && (
                        <div className={classes.progressRoot}>
                            <LinearProgress />
                        </div>
                    )}
                </>
            )}
        </Spinner>
    );
}

const mapStateToProps = (state) => ({
    trackingData: state.mapview?.vehicles,
    allStaffVehicles: state.mapview?.allStaffVehicles,
    user: state?.user,
    configs: state.user.config,
});

const mapDispatchToProps = (dispatch) => ({
    getAllStaffs: (payload, onSuccess, onError) =>
        dispatch(getAllStaffs(payload, onSuccess, onError)),
    dispatchGetMapVechicleList: (payload, onSuccess, onError) =>
        dispatch(GetMapVechicleList(payload, onSuccess, onError)),
    dispatchSetTrackingData: (id, onSuccess, onError) =>
        dispatch(setTrackingData(id, onSuccess, onError)),
    dispatchGetMapViewData: (payload, onSuccess, onError) =>
        dispatch(getMapViewData(payload, onSuccess, onError)),

    dispatchFetchAppConfig: (onSuccess, onError) =>
        dispatch(fetchAppConfig(onSuccess, onError)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MapView);
