import React from 'react';
import {
    Box,
    Typography,
    Avatar,
    Button,
    IconButton,
    Slider,
} from '@material-ui/core';
import { Polyline, Marker, Popup, useMap } from 'react-leaflet';
import {
    redMarker,
    greenMarker,
    orangeFilledMarker,
    purpleMarker,
    blueGreenMarker,
} from '../../../../components/Map/MarkerIcons';
import moment from 'moment';
import { getTimeAsDuration, getTimeDifference } from '../../../../utils/helper';
import useSingleMapStyles from '../useSingleMapStyles';
import {
    Timeline,
    TimelineItem,
    TimelineSeparator,
    TimelineConnector,
    TimelineContent,
    TimelineDot,
    TimelineOppositeContent,
} from '@material-ui/lab';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import { eventStyleFlag } from '../helper';
import EventCard from './EventCard';
import LocationPointCard from './LocationPointCard';
interface EventsTimelineProps {
    latLongPoints?: any;
    trackData?: any;
    aggregatedMapData?: any;
    aggregatedTrackData?: any;
    events?: any;
    showCloseButton?: any;
    stepperSelectorHandler: any;
    aggregatedEvents?: any;
    startAddress?: any;
    endAddress?: any;
    onCloseClick?: any;
}

const EventsTimeline: React.FC<EventsTimelineProps> = ({
    latLongPoints,
    trackData,
    aggregatedTrackData,
    aggregatedMapData,
    events = [],
    aggregatedEvents,
    showCloseButton,
    stepperSelectorHandler,
    startAddress,
    endAddress,
    onCloseClick,
}) => {
    const classes = useSingleMapStyles();
    return (
        <Box
            boxShadow={showCloseButton ? 15 : 0}
            className={classes.scrollableBox}
        >
            {showCloseButton && (
                <Box
                    style={{
                        position: 'absolute',
                        left: '0px',
                    }}
                >
                    <IconButton
                        className={'inputbutton'}
                        onClick={(e) => {
                            onCloseClick();
                        }}
                        style={{
                            padding: '0px',
                            color: 'red',
                            // display: 'flex',
                            // justifyContent: 'flex-end',
                        }}
                    >
                        <CancelRoundedIcon
                            style={{
                                width: 30,
                                height: 30,
                            }}
                        />
                    </IconButton>
                </Box>
            )}
            {trackData?.locationPoints?.length > 0 ? (
                <>
                    <Timeline
                        align="left"
                        style={{
                            padding: 0,
                        }}
                    >
                        {trackData?.locationPoints?.length > 0 ? (
                            <TimelineItem>
                                <TimelineOppositeContent
                                    style={{
                                        flex: 0,
                                        padding: '6px',
                                    }}
                                ></TimelineOppositeContent>
                                <TimelineSeparator>
                                    <TimelineDot
                                        {...{
                                            style:
                                                trackData?.locationPoints[0]
                                                    ?.deviceTimeEpoch ==
                                                    aggregatedMapData?.selectedStartStoppagePoint ||
                                                trackData?.locationPoints[0]
                                                    ?.deviceTimeEpoch ==
                                                    aggregatedMapData?.selectedEndStoppagePoint
                                                    ? {
                                                          backgroundColor:
                                                              '#1bb55c',
                                                          border: '3px solid #000',
                                                          width: 20,
                                                          height: 20,
                                                          margin: 0,
                                                      }
                                                    : {
                                                          backgroundColor:
                                                              '#1bb55c',
                                                          border: '1px solid #fff',
                                                          width: 20,
                                                          height: 20,
                                                          margin: 0,
                                                      },
                                            onClick: () => {
                                                console.log(
                                                    'eeeee',
                                                    trackData?.locationPoints[0]
                                                        ?.deviceTimeEpoch,
                                                );
                                                stepperSelectorHandler(
                                                    trackData
                                                        ?.locationPoints[0],
                                                );
                                            },
                                        }}
                                    />
                                    <TimelineConnector
                                        style={{
                                            backgroundColor: '#1bb55c',
                                        }}
                                    />
                                </TimelineSeparator>
                                <TimelineContent
                                    style={{
                                        padding: 0,
                                        margin: '0 0 0 8px',
                                    }}
                                >
                                    {events?.length > 0 &&
                                        aggregatedEvents?.length > 0 &&
                                        events?.events
                                            ?.filter((event: any) => {
                                                return (
                                                    event?.time <=
                                                    trackData?.locationPoints[0]
                                                        ?.deviceTimeEpoch
                                                );
                                            })
                                            ?.map((event: any) => {
                                                console.log(
                                                    'stopppp first',
                                                    event,
                                                    event?.time,
                                                );
                                                return (
                                                    <EventCard
                                                        {...{
                                                            event,
                                                        }}
                                                    />
                                                );
                                            })}
                                    <LocationPointCard
                                        timeDiff={null}
                                        title={'Started'}
                                        dates={moment(
                                            parseInt(
                                                trackData?.locationPoints[0]
                                                    ?.deviceTimeEpoch,
                                            ),
                                        ).format(
                                            // 'hh:mm A',
                                            'D MMM YYYY hh:mm A',
                                        )}
                                        address={
                                            events?.startEventDescription
                                                ?.length > 0
                                                ? events?.startEventDescription
                                                : startAddress ?? ''
                                        }
                                    />
                                    {aggregatedMapData?.stoppageReportData
                                        ?.length > 0 ? (
                                        <>
                                            {aggregatedEvents
                                                ?.filter((event?: any) => {
                                                    if (
                                                        trackData
                                                            ?.locationPoints[0]
                                                            ?.deviceTimeEpoch >=
                                                            parseInt(
                                                                aggregatedMapData
                                                                    ?.stoppageReportData?.[0]
                                                                    ?.startTime ??
                                                                    aggregatedMapData
                                                                        ?.stoppageReportData?.[0]
                                                                        ?.time,
                                                            ) &&
                                                        trackData
                                                            ?.locationPoints?.[0]
                                                            ?.deviceTimeEpoch <
                                                            parseInt(
                                                                aggregatedMapData
                                                                    ?.stoppageReportData?.[0]
                                                                    ?.endTime ??
                                                                    aggregatedMapData
                                                                        ?.stoppageReportData?.[0]
                                                                        ?.time,
                                                            )
                                                    ) {
                                                        return (
                                                            event?.time >=
                                                                trackData
                                                                    ?.locationPoints[0]
                                                                    ?.deviceTimeEpoch &&
                                                            event?.time <=
                                                                parseInt(
                                                                    aggregatedMapData
                                                                        ?.stoppageReportData?.[0]
                                                                        ?.endTime ??
                                                                        aggregatedMapData
                                                                            ?.stoppageReportData?.[0]
                                                                            ?.time,
                                                                )
                                                        );
                                                    } else {
                                                        return (
                                                            event?.time >=
                                                                trackData
                                                                    ?.locationPoints[0]
                                                                    ?.deviceTimeEpoch &&
                                                            aggregatedMapData
                                                                ?.stoppageReportData[0] &&
                                                            event?.time <
                                                                parseInt(
                                                                    aggregatedMapData
                                                                        ?.stoppageReportData?.[0]
                                                                        ?.startTime ??
                                                                        aggregatedMapData
                                                                            ?.stoppageReportData?.[0]
                                                                            ?.time,
                                                                )
                                                        );
                                                    }
                                                })
                                                ?.map((event: any) => {
                                                    // console.log(
                                                    //     'ggggggg',
                                                    //     event,
                                                    //     event?.time,
                                                    // );
                                                    return (
                                                        <EventCard
                                                            {...{
                                                                event,
                                                            }}
                                                        />
                                                    );
                                                })}
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                </TimelineContent>
                            </TimelineItem>
                        ) : (
                            <></>
                        )}

                        <>
                            {aggregatedMapData?.stoppageReportData &&
                            aggregatedMapData?.stoppageReportData?.length >
                                0 ? (
                                aggregatedMapData?.stoppageReportData?.map(
                                    (idlePoint?: any, index?: any) => {
                                        return (
                                            <TimelineItem
                                                key={index}
                                                style={{
                                                    marginTop: '2px',

                                                    marginBottom: '2px',
                                                }}
                                            >
                                                <TimelineOppositeContent
                                                    style={{
                                                        flex: 0,
                                                        padding: '6px',
                                                    }}
                                                ></TimelineOppositeContent>
                                                <TimelineSeparator>
                                                    <TimelineDot
                                                        {...{
                                                            style: {
                                                                width: 20,
                                                                height: 20,
                                                                margin: 0,
                                                                backgroundColor:
                                                                    idlePoint?.type ===
                                                                    'ORDER_PICKED'
                                                                        ? '#43419e'
                                                                        : idlePoint?.type ===
                                                                          'ORDER_DELIVERED'
                                                                        ? '#05c792'
                                                                        : '#fb8a06',
                                                                ...(idlePoint?.time ===
                                                                    aggregatedEvents?.selectedStartStoppagePoint ||
                                                                idlePoint?.time ===
                                                                    aggregatedEvents?.selectedEndStoppagePoint
                                                                    ? {
                                                                          border: '3px solid #000',
                                                                      }
                                                                    : {
                                                                          border: '1px solid #fff',
                                                                      }),
                                                            },
                                                            onClick: () => {
                                                                console.log(
                                                                    'eeeee',
                                                                    idlePoint?.time,
                                                                );
                                                                stepperSelectorHandler(
                                                                    idlePoint,
                                                                );
                                                            },
                                                        }}
                                                    />
                                                    <TimelineConnector
                                                        style={{
                                                            backgroundColor:
                                                                '#fb8a06',
                                                        }}
                                                    />
                                                </TimelineSeparator>
                                                <TimelineContent
                                                    style={{
                                                        padding: 0,
                                                        margin: '0 0 0 8px',
                                                    }}
                                                >
                                                    {[
                                                        'ORDER_PICKED',
                                                        'ORDER_DELIVERED',
                                                    ]?.includes(
                                                        idlePoint?.type,
                                                    ) ? (
                                                        <LocationPointCard
                                                            timeDiff={null}
                                                            title={
                                                                idlePoint?.title
                                                            }
                                                            dates={`${moment(
                                                                parseInt(
                                                                    idlePoint?.time,
                                                                ),
                                                            ).format(
                                                                'hh:mm A',
                                                                // 'D MMM YYYY hh:mm A',
                                                            )}`}
                                                            address={
                                                                idlePoint
                                                                    ?.description
                                                                    ?.length >
                                                                    0 &&
                                                                isNaN(
                                                                    Number(
                                                                        idlePoint?.description,
                                                                    ),
                                                                )
                                                                    ? idlePoint?.description
                                                                          .split(
                                                                              '@!@',
                                                                          )
                                                                          .join(
                                                                              ',',
                                                                          )
                                                                    : 'No Location Available'
                                                            }
                                                        />
                                                    ) : (
                                                        <LocationPointCard
                                                            title={
                                                                idlePoint?.kmReading &&
                                                                `${parseFloat(
                                                                    idlePoint?.kmReading,
                                                                ).toFixed(
                                                                    2,
                                                                )} Km`
                                                            }
                                                            dates={`${moment(
                                                                parseInt(
                                                                    idlePoint?.startTime ??
                                                                        idlePoint?.time,
                                                                ),
                                                            ).format(
                                                                'hh:mm A',
                                                                // 'D MMM YYYY hh:mm A',
                                                            )} - ${moment(
                                                                parseInt(
                                                                    idlePoint?.endTime ??
                                                                        idlePoint?.time,
                                                                ),
                                                            ).format(
                                                                'hh:mm A',
                                                                // 'D MMM YYYY hh:mm A',
                                                            )}`}
                                                            address={
                                                                idlePoint
                                                                    ?.description
                                                                    ?.length >
                                                                    0 &&
                                                                isNaN(
                                                                    Number(
                                                                        idlePoint?.description,
                                                                    ),
                                                                )
                                                                    ? idlePoint?.description
                                                                          .split(
                                                                              '@!@',
                                                                          )
                                                                          .join(
                                                                              ',',
                                                                          )
                                                                    : 'No Location Available'
                                                            }
                                                            timeDiff={getTimeAsDuration(
                                                                getTimeDifference(
                                                                    parseInt(
                                                                        idlePoint?.endTime ??
                                                                            idlePoint?.time,
                                                                    ),
                                                                    parseInt(
                                                                        idlePoint?.startTime ??
                                                                            idlePoint?.time,
                                                                    ),
                                                                ),
                                                                'small',
                                                            )}
                                                        />
                                                    )}

                                                    {aggregatedEvents
                                                        ?.filter(
                                                            (event?: any) => {
                                                                if (
                                                                    index == 0
                                                                ) {
                                                                    if (
                                                                        trackData
                                                                            ?.locationPoints[0]
                                                                            ?.deviceTimeEpoch >=
                                                                            parseInt(
                                                                                aggregatedMapData
                                                                                    ?.stoppageReportData[0]
                                                                                    ?.startTime ??
                                                                                    aggregatedMapData
                                                                                        ?.stoppageReportData[0]
                                                                                        ?.time,
                                                                            ) &&
                                                                        trackData
                                                                            ?.locationPoints[0]
                                                                            ?.deviceTimeEpoch <
                                                                            parseInt(
                                                                                aggregatedMapData
                                                                                    ?.stoppageReportData[0]
                                                                                    ?.endTime ??
                                                                                    aggregatedMapData
                                                                                        ?.stoppageReportData[0]
                                                                                        ?.time,
                                                                            )
                                                                    ) {
                                                                        return (
                                                                            event?.time >=
                                                                                parseInt(
                                                                                    idlePoint[1],
                                                                                ) &&
                                                                            aggregatedMapData
                                                                                ?.stoppageReportData[
                                                                                index +
                                                                                    1
                                                                            ] &&
                                                                            event?.time <=
                                                                                parseInt(
                                                                                    aggregatedMapData
                                                                                        ?.stoppageReportData[
                                                                                        index +
                                                                                            1
                                                                                    ]
                                                                                        ?.startTime ??
                                                                                        aggregatedMapData
                                                                                            ?.stoppageReportData[
                                                                                            index +
                                                                                                1
                                                                                        ]
                                                                                            ?.time,
                                                                                )
                                                                        );
                                                                    }
                                                                }
                                                                return (
                                                                    event?.time >=
                                                                        parseInt(
                                                                            idlePoint?.startTime ??
                                                                                idlePoint?.time,
                                                                        ) &&
                                                                    aggregatedMapData
                                                                        ?.stoppageReportData[
                                                                        index +
                                                                            1
                                                                    ] &&
                                                                    event?.time <=
                                                                        parseInt(
                                                                            aggregatedMapData
                                                                                ?.stoppageReportData[
                                                                                index +
                                                                                    1
                                                                            ]
                                                                                ?.startTime ??
                                                                                aggregatedMapData
                                                                                    ?.stoppageReportData[
                                                                                    index +
                                                                                        1
                                                                                ]
                                                                                    ?.time,
                                                                        )
                                                                );
                                                            },
                                                        )
                                                        ?.map((event?: any) => {
                                                            console.log(
                                                                'stopppp',
                                                                event,
                                                                event?.time,
                                                            );
                                                            return (
                                                                <EventCard
                                                                    {...{
                                                                        event,
                                                                    }}
                                                                />
                                                            );
                                                        })}
                                                </TimelineContent>
                                            </TimelineItem>
                                        );
                                    },
                                )
                            ) : (
                                <></>
                            )}
                        </>

                        {trackData?.locationPoints?.length ? (
                            <TimelineItem
                                style={{
                                    marginTop: 8,
                                }}
                            >
                                <TimelineOppositeContent
                                    style={{
                                        flex: 0,
                                        padding: '6px',
                                    }}
                                ></TimelineOppositeContent>
                                <TimelineSeparator>
                                    <TimelineDot
                                        {...{
                                            style:
                                                trackData?.locationPoints?.[
                                                    trackData?.locationPoints
                                                        ?.length - 1
                                                ]?.deviceTimeEpoch ==
                                                    aggregatedEvents?.selectedStartStoppagePoint ||
                                                trackData?.locationPoints?.[
                                                    trackData?.locationPoints
                                                        ?.length - 1
                                                ]?.deviceTimeEpoch ==
                                                    aggregatedEvents?.selectedEndStoppagePoint
                                                    ? {
                                                          backgroundColor:
                                                              '#e74c3c',
                                                          border: '2px solid #000',
                                                          width: 20,
                                                          height: 20,
                                                          margin: 0,
                                                      }
                                                    : {
                                                          backgroundColor:
                                                              '#e74c3c',
                                                          border: '1px solid #fff',
                                                          width: 20,
                                                          height: 20,
                                                          margin: 0,
                                                      },
                                            onClick: () => {
                                                stepperSelectorHandler(
                                                    trackData?.locationPoints[
                                                        trackData
                                                            ?.locationPoints
                                                            ?.length - 1
                                                    ],
                                                );
                                            },
                                        }}
                                    />
                                </TimelineSeparator>
                                <TimelineContent
                                    style={{
                                        padding: 0,
                                        margin: '0 0 0 8px',
                                    }}
                                >
                                    {events?.events
                                        ?.filter((event?: any) => {
                                            if (aggregatedEvents?.length > 0) {
                                                return (
                                                    (aggregatedMapData
                                                        ?.stoppageReportData
                                                        ?.length > 0
                                                        ? event?.time >
                                                          parseInt(
                                                              aggregatedMapData
                                                                  ?.stoppageReportData[
                                                                  aggregatedMapData
                                                                      ?.stoppageReportData
                                                                      ?.length -
                                                                      1
                                                              ]?.startTime ??
                                                                  aggregatedMapData
                                                                      ?.stoppageReportData[
                                                                      aggregatedMapData
                                                                          ?.stoppageReportData
                                                                          ?.length -
                                                                          1
                                                                  ]?.time,
                                                          )
                                                        : event?.time >
                                                          trackData
                                                              ?.locationPoints[0]
                                                              ?.deviceTimeEpoch) &&
                                                    event?.time <=
                                                        trackData
                                                            ?.locationPoints[
                                                            trackData
                                                                ?.locationPoints
                                                                ?.length - 1
                                                        ]?.deviceTimeEpoch
                                                );
                                            } else {
                                                return (
                                                    event?.time >
                                                        trackData
                                                            ?.locationPoints[0]
                                                            ?.deviceTimeEpoch &&
                                                    event?.time <
                                                        trackData
                                                            ?.locationPoints[
                                                            trackData
                                                                ?.locationPoints
                                                                ?.length - 1
                                                        ]?.deviceTimeEpoch
                                                );
                                            }
                                        })
                                        ?.map((event?: any) => {
                                            console.log(
                                                'stopppp before last',
                                                event,
                                                event?.time,
                                            );
                                            return (
                                                <EventCard
                                                    {...{
                                                        event,
                                                    }}
                                                />
                                            );
                                        })}
                                    <Box
                                        style={{
                                            background: '#f6f7f9',
                                            padding: 6,
                                            borderRadius: 8,
                                        }}
                                    >
                                        <Box
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'space-between',
                                                marginBottom: 4,
                                            }}
                                        >
                                            <Typography
                                                style={{
                                                    fontSize: 12,
                                                    fontWeight: 700,
                                                }}
                                            >
                                                Stopped
                                            </Typography>
                                            <Typography
                                                style={{
                                                    fontSize: 12,
                                                    fontWeight: 700,
                                                    // margin: '4px 0',
                                                    // opacity: 0.8,
                                                }}
                                            >
                                                {moment(
                                                    parseInt(
                                                        trackData
                                                            ?.locationPoints[
                                                            trackData
                                                                ?.locationPoints
                                                                ?.length - 1
                                                        ]?.deviceTimeEpoch,
                                                    ),
                                                ).format(
                                                    // 'hh:mm A',
                                                    'D MMM YYYY hh:mm A',
                                                )}
                                            </Typography>
                                        </Box>
                                        <Typography
                                            style={{
                                                fontSize: 15,
                                                fontWeight: 600,
                                            }}
                                        >
                                            {endAddress ?? ''}
                                        </Typography>
                                    </Box>
                                    {events?.events
                                        ?.filter((event: any) => {
                                            if (aggregatedEvents?.length > 0) {
                                                return (
                                                    event?.time >=
                                                    trackData?.locationPoints[
                                                        trackData
                                                            ?.locationPoints
                                                            ?.length - 1
                                                    ]?.deviceTimeEpoch
                                                );
                                            } else {
                                                return (
                                                    event?.time >=
                                                    trackData?.locationPoints[
                                                        trackData
                                                            ?.locationPoints
                                                            ?.length - 1
                                                    ]?.deviceTimeEpoch
                                                );
                                            }
                                        })
                                        ?.map((event: any) => {
                                            console.log(
                                                'stopppp',
                                                event,
                                                event?.time,
                                            );
                                            return (
                                                <EventCard
                                                    {...{
                                                        event,
                                                    }}
                                                />
                                            );
                                        })}
                                </TimelineContent>
                            </TimelineItem>
                        ) : (
                            <></>
                        )}
                    </Timeline>
                </>
            ) : (
                <>
                    {events?.length > 0 && (
                        <Timeline
                            align="left"
                            style={{
                                padding: 0,
                            }}
                        >
                            {events?.map((event: any) => {
                                // console.log(
                                //     'ggggggg',
                                //     event,
                                //     event?.time,
                                // );
                                return (
                                    <Box
                                        style={{
                                            background: eventStyleFlag(event)
                                                ? '#fef7f6'
                                                : '#f2fdf6',
                                            padding: 6,
                                            marginTop: 2,
                                            marginBottom: 2,
                                            borderRadius: 8,
                                        }}
                                    >
                                        <Box
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'space-between',
                                            }}
                                        >
                                            <Typography
                                                style={{
                                                    fontSize: 15,
                                                    fontWeight: 600,
                                                    color: eventStyleFlag(event)
                                                        ? '#e74c3c'
                                                        : '#1bb55c',
                                                }}
                                            >
                                                {event?.description}
                                            </Typography>
                                            <Typography
                                                style={{
                                                    fontSize: 12,
                                                    fontWeight: 700,
                                                    // margin: '4px 0',
                                                    // opacity: 0.8,
                                                }}
                                            >
                                                {moment(
                                                    parseInt(event?.time),
                                                ).format(
                                                    'hh:mm A',
                                                    // 'D MMM YYYY hh:mm A',
                                                )}{' '}
                                            </Typography>
                                        </Box>
                                    </Box>
                                );
                            })}
                        </Timeline>
                    )}
                </>
            )}
        </Box>
    );
};

export default EventsTimeline;
