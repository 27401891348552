import axios from 'axios';
import * as constants from './constants';
import { refreshToken } from './actions/authActionCreator';
// import "react-toastify/dist/ReactToastify.css";
import {
    checkHttpStatus,
    getLoginUser,
    getUserToken,
    logout,
    getZappayAuthToken,
} from '../utils/helper';
import { API_URL, ZAPPAY_API_URL, ENV_TYPE } from '../config/env/env';
import {
    closeSnackbar,
    enqueueSnackbar,
} from './actions/notificationActionCreator';

import { sourceSystems } from '../utils/constants';

let lastApiCalled = {};

/**
 * Common middleware for all redux store actions
 * @param {*} param0
 * @returns
 */
export const apiMiddleware =
    ({ dispatch, getState }) =>
    (next) =>
    (action) => {
        // Applicable only for APIs
        if (action.type !== constants.API) return next(action);

        const {
            source = sourceSystems.StaffPay,
            url,
            method,
            success,
            data,
            postProcessSuccess,
            postProcessError,
            port,
            hideNotify,
            overwriteHeaders,
        } = action.payload;
        if (sourceSystems.Zappay === source) {
            const BASE_URL = ZAPPAY_API_URL;
            const AUTH_TOKEN_INFO = getZappayAuthToken();

            console.log(BASE_URL + url);
            console.log('ZAPPAY AUTH_TOKEN_INFO', AUTH_TOKEN_INFO);
            console.log('action', action);

            // if (action.payload.url !== '/auth/refresh-login')
            //     lastApiCalled = {
            //         ...action,
            //     };
            axios.defaults.headers.common['authtoken'] = AUTH_TOKEN_INFO;
            if (overwriteHeaders) {
                axios.defaults.headers.common = {
                    ...axios.defaults.headers.common,
                    ...overwriteHeaders,
                };
            }

            axios({
                method,
                url: BASE_URL + url,
                data: data ? data : null,
            })
                .then((response) => {
                    console.log('API Response : ', response);
                    if (success) {
                        let data = response.data;

                        // Backend error code to be 401 to force user logout
                        if (
                            data.status &&
                            data.status.code &&
                            data.status.code === 401
                        ) {
                            // dispatch(logoutUser());
                            logout();
                        }
                        if (
                            data.status &&
                            data.status.code &&
                            data.status.code !== 200
                        ) {
                            console.log('middleware', response.data);
                            if (!hideNotify)
                                dispatch(
                                    enqueueSnackbar({
                                        message: `${data?.status?.message}`,
                                        options: {
                                            key:
                                                new Date().getTime() +
                                                Math.random(),
                                            variant: 'error',
                                        },
                                    }),
                                );
                            if (postProcessError)
                                postProcessError(response.data);
                        } else {
                            dispatch(success(response.data));
                            console.log('data', response.data);
                            if (postProcessSuccess) {
                                postProcessSuccess(response.data);
                            }
                        }
                    }
                })
                .catch((err) => {
                    if (
                        err?.response?.data &&
                        err?.response?.data?.status &&
                        err?.response?.data?.status?.message
                    ) {
                        if (!hideNotify)
                            dispatch(
                                enqueueSnackbar({
                                    message: `${err?.response?.data?.status?.message}`,
                                    options: {
                                        key:
                                            new Date().getTime() +
                                            Math.random(),
                                        variant: 'error',
                                    },
                                }),
                            );
                        if (postProcessError)
                            postProcessError(
                                err?.response?.data?.error?.message,
                            );
                    } else {
                        dispatch(
                            enqueueSnackbar({
                                message: `Something went wrong`,
                                options: {
                                    key: new Date().getTime() + Math.random(),
                                    variant: 'error',
                                },
                            }),
                        );
                        if (postProcessError)
                            postProcessError('Unable to fetch Data');
                    }
                });
        } else {
            if (action.payload.thirdParty === true) {
                // if (overwriteHeaders) {
                //     axios.defaults.headers.common = {
                //         ...axios.defaults.headers.common,
                //         ...overwriteHeaders,
                //     };
                // }
                axios({
                    method,
                    url: url,
                    data: data ? data : null,
                    headers: overwriteHeaders,
                })
                    .then((response) => {
                        console.log('API Response : ', response);
                        if (success) {
                            let data = response.data;

                            if (
                                data.status &&
                                data.status.code &&
                                data.status.code !== 200
                            ) {
                                console.log('middleware', response.data);
                                postProcessError(response.data);
                                dispatch(
                                    enqueueSnackbar({
                                        message:
                                            data?.status?.message ??
                                            `Something went wrong`,
                                        options: {
                                            key:
                                                new Date().getTime() +
                                                Math.random(),
                                            variant: 'error',
                                        },
                                    }),
                                );
                                // toast.error(<ErrorToastMessage message={data.status.message} />,
                                //   {
                                //     // className: 'toastContainer',
                                //     closeOnClick: true,
                                //     closeButton: true,
                                //     pauseOnHover: false,
                                //   }
                                // );
                            } else {
                                dispatch(success(response));
                            }
                        }
                        if (postProcessSuccess)
                            postProcessSuccess(response.data);
                    })
                    .catch((err) => {
                        if (!err.response) console.warn(err);
                        else {
                            if (
                                err.response &&
                                (err.response.status === 403 ||
                                    err.response.status === 401)
                            ) {
                                dispatch(
                                    enqueueSnackbar({
                                        message: `token expired`,
                                        options: {
                                            key:
                                                new Date().getTime() +
                                                Math.random(),
                                            variant: 'error',
                                        },
                                    }),
                                );
                                // logout()
                                // dispatch(logoutUser());
                            }
                            if (
                                err.response.data &&
                                err.response.data.error &&
                                err.response.data.error.message
                            ) {
                                if (!hideNotify)
                                    dispatch(
                                        enqueueSnackbar({
                                            message: `${err.response.data.error.message}`,
                                            options: {
                                                key:
                                                    new Date().getTime() +
                                                    Math.random(),
                                                variant: 'error',
                                            },
                                        }),
                                    );
                                if (postProcessError)
                                    postProcessError(
                                        err.response.data.error.message,
                                    );
                            } else {
                                dispatch(
                                    enqueueSnackbar({
                                        message: `Something went wrong`,
                                        options: {
                                            key:
                                                new Date().getTime() +
                                                Math.random(),
                                            variant: 'error',
                                        },
                                    }),
                                );
                                if (postProcessError)
                                    postProcessError('Unable to fetch Data');
                            }
                        }
                    });
            } else {
                const BASE_URL = API_URL;
                const AUTH_TOKEN_INFO = getUserToken();
                if (AUTH_TOKEN_INFO) {
                    axios.defaults.headers.common['authtoken'] =
                        AUTH_TOKEN_INFO;
                }

                if (overwriteHeaders) {
                    axios.defaults.headers.common = {
                        ...axios.defaults.headers.common,
                        ...overwriteHeaders,
                    };
                }
                console.log(BASE_URL + url);
                console.log('AUTH_TOKEN_INFO', AUTH_TOKEN_INFO);
                console.log('action', action);

                if (action.payload.url !== '/auth/refresh-login')
                    lastApiCalled = {
                        ...action,
                    };
                if (action.payload.async) {
                    return new Promise((resolve, reject) => {
                        axios({
                            method,
                            url: BASE_URL + url,
                            data: data ? data : null,
                        })
                            .then((response) => {
                                console.log('API Response : ', response);
                                if (success) {
                                    let data = response.data;
                                    // Backend error code to be 401 to force user logout
                                    if (
                                        data.status &&
                                        data.status.code &&
                                        data.status.code === 401
                                    ) {
                                        reject(response.data);
                                        logout();
                                    }

                                    if (
                                        data.status &&
                                        data.status.code &&
                                        data.status.code === 501
                                    ) {
                                        reject(response.data);
                                    }

                                    if (
                                        data.status &&
                                        data.status.code &&
                                        data.status.code !== 200 &&
                                        !checkHttpStatus(
                                            data.status,
                                            action.payload.hideNotify,
                                        )
                                    ) {
                                        console.log(
                                            'middleware',
                                            response.data,
                                        );
                                        if (postProcessError)
                                            postProcessError(response.data);
                                        reject(response.data);
                                    } else {
                                        dispatch(success(response.data));
                                        console.log('data', response.data);
                                        if (response?.data?.response?.token) {
                                        }
                                        if (postProcessSuccess) {
                                            postProcessSuccess(response.data);
                                        }
                                        resolve(response.data);
                                    }
                                } else {
                                    dispatch(
                                        enqueueSnackbar({
                                            message: `Something went wrong`,
                                            options: {
                                                key:
                                                    new Date().getTime() +
                                                    Math.random(),
                                                variant: 'error',
                                            },
                                        }),
                                    );
                                    if (postProcessError)
                                        postProcessError(response.data);
                                    reject(response.data);
                                }
                            })
                            .catch((err) => {
                                if (!err?.response?.data) {
                                    console.warn(err);
                                    if (postProcessError)
                                        postProcessError(err.data);
                                    reject(err.data);
                                } else {
                                    if (
                                        err.response.data.status &&
                                        (err.response.data.status.code ===
                                            403 ||
                                            err.response.data.status.code ===
                                                401)
                                    ) {
                                        // dispatch(logoutUser());
                                        reject(err.data);
                                        logout();
                                        dispatch(
                                            enqueueSnackbar({
                                                message: `token expired`,
                                                options: {
                                                    key:
                                                        new Date().getTime() +
                                                        Math.random(),
                                                    variant: 'error',
                                                },
                                            }),
                                        );
                                    }
                                    if (
                                        err.response.data.status &&
                                        err.response.data.status.code &&
                                        err.response.data.status.code === 501
                                    ) {
                                        reject(err.data);
                                    }

                                    if (
                                        err.response.data &&
                                        err.response.data.status &&
                                        err.response.data.status.message
                                    ) {
                                        if (postProcessError)
                                            postProcessError(
                                                err?.response?.data?.error
                                                    ?.message,
                                            );
                                        reject(err);
                                    } else {
                                        if (postProcessError)
                                            postProcessError(
                                                'Unable to process. Contact Support',
                                            );
                                        reject(err);
                                    }
                                }
                            });
                    });
                } else {
                    axios({
                        method,
                        url: BASE_URL + url,
                        data: data ? data : null,
                    })
                        .then((response) => {
                            console.log('API Response : ', response);
                            if (success) {
                                let data = response.data;

                                // Backend error code to be 401 to force user logout
                                if (
                                    data.status &&
                                    data.status.code &&
                                    data.status.code === 401
                                ) {
                                    // dispatch(logoutUser());
                                    logout();
                                    // dispatch(
                                    //     enqueueSnackbar({
                                    //         message: `token expired`,
                                    //         options: {
                                    //             key:
                                    //                 new Date().getTime() +
                                    //                 Math.random(),
                                    //             variant: 'error',
                                    //         },
                                    //     }),
                                    // );
                                    console.log('401 err', data);
                                }

                                if (
                                    data.status &&
                                    data.status.code &&
                                    data.status.code === 501
                                ) {
                                    // dispatch(
                                    //     enqueueSnackbar({
                                    //         message: `Refresh login details`,
                                    //         options: {
                                    //             key:
                                    //                 new Date().getTime() +
                                    //                 Math.random(),
                                    //             variant: 'error',
                                    //         },
                                    //     }),
                                    // );
                                    dispatch(
                                        refreshToken((resp) => {
                                            console.log(
                                                'refresh token resp',
                                                resp,
                                            );
                                            dispatch({
                                                type: constants.TOGGLE_LOADER,
                                            });
                                            dispatch(lastApiCalled);
                                        }),
                                    );
                                }

                                if (
                                    data.status &&
                                    data.status.code &&
                                    data.status.code !== 200 &&
                                    !checkHttpStatus(
                                        data.status,
                                        action.payload.hideNotify,
                                    )
                                ) {
                                    console.log('middleware', response.data);
                                    if (postProcessError)
                                        postProcessError(response.data);

                                    // toast.error(<ErrorToastMessage message={data.status.message} />,
                                    //   {
                                    //     // className: 'toastContainer',
                                    //     closeOnClick: true,
                                    //     closeButton: true,
                                    //     pauseOnHover: false,
                                    //   }
                                    // );
                                } else {
                                    dispatch(success(response.data));
                                    console.log('data', response.data);
                                    if (response?.data?.response?.token) {
                                        // await AsyncStorage.setItem(
                                        //     'token',
                                        //     response.data.response.token,
                                        // );
                                    }
                                    // dispatch(
                                    //     enqueueSnackbar({
                                    //         message: `data fetched`,
                                    //         options: {
                                    //             key:
                                    //                 new Date().getTime() +
                                    //                 Math.random(),
                                    //             variant: 'success',
                                    //         },
                                    //     }),
                                    // );
                                    if (postProcessSuccess) {
                                        postProcessSuccess(response.data);
                                    }
                                }
                            } else {
                                dispatch(
                                    enqueueSnackbar({
                                        message: `Something went wrong`,
                                        options: {
                                            key:
                                                new Date().getTime() +
                                                Math.random(),
                                            variant: 'error',
                                        },
                                    }),
                                );
                                if (postProcessError)
                                    postProcessError(response.data);
                            }
                        })
                        .catch((err) => {
                            if (!err?.response?.data) {
                                console.warn(err);
                                if (postProcessError)
                                    postProcessError(err.data);
                            } else {
                                if (
                                    err.response.data.status &&
                                    (err.response.data.status.code === 403 ||
                                        err.response.data.status.code === 401)
                                ) {
                                    // dispatch(logoutUser());
                                    logout();
                                    dispatch(
                                        enqueueSnackbar({
                                            message: `token expired`,
                                            options: {
                                                key:
                                                    new Date().getTime() +
                                                    Math.random(),
                                                variant: 'error',
                                            },
                                        }),
                                    );
                                }
                                if (
                                    err.response.data.status &&
                                    err.response.data.status.code &&
                                    err.response.data.status.code === 501
                                ) {
                                    // dispatch(
                                    //     enqueueSnackbar({
                                    //         message: `Refresh login details`,
                                    //         options: {
                                    //             key:
                                    //                 new Date().getTime() +
                                    //                 Math.random(),
                                    //             variant: 'error',
                                    //         },
                                    //     }),
                                    // );
                                    dispatch(
                                        refreshToken((resp) => {
                                            console.log(
                                                'refresh token resp',
                                                resp,
                                            );
                                            dispatch({
                                                type: constants.TOGGLE_LOADER,
                                            });
                                            dispatch(lastApiCalled);
                                        }),
                                    );
                                }

                                if (
                                    err.response.data &&
                                    err.response.data.status &&
                                    err.response.data.status.message
                                ) {
                                    // if (!hideNotify)
                                    //     dispatch(
                                    //         enqueueSnackbar({
                                    //             message: `${err?.response?.data?.status?.message}`,
                                    //             options: {
                                    //                 key:
                                    //                     new Date().getTime() +
                                    //                     Math.random(),
                                    //                 variant: 'error',
                                    //             },
                                    //         }),
                                    //     );
                                    if (postProcessError)
                                        postProcessError(
                                            err?.response?.data?.error?.message,
                                        );
                                } else {
                                    if (postProcessError)
                                        postProcessError(
                                            'Unable to process. Contact Support',
                                        );
                                }
                            }
                        });
                }
            }
        }
    };
