import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
    Box,
    Button,
    MenuItem,
    Paper,
    makeStyles,
    Typography,
    Dialog,
    DialogTitle,
    IconButton,
    Slide,
    useMediaQuery,
    useTheme,
    Avatar,
    Divider,
    DialogContent,
    DialogActions,
    Tooltip,
    List,
    ListItem,
    ListItemSecondaryAction,
    Backdrop,
    ListItemText,
    TextField,
    Grid,
    Tab,
    Tabs,
    withStyles,
} from '@material-ui/core';
import EventIcon from '@material-ui/icons/Event';
import Modal from '../../../components/Modals/Modal';
import { connect, useDispatch, useSelector } from 'react-redux';
import { updateLeadStatus } from '../../../store/actions/leadActionCreator';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
    createStaff,
    fetchConvertStaffList,
    getStaffEvHistory,
    covertToStaff,
} from '../../../store/actions/staffActionCreator';
import moment from 'moment';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CloseIcon from '@material-ui/icons/Close';
import { Spinner } from '../../../components/UI';
import CircularProgress from '@material-ui/core/CircularProgress';
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';
import HeightIcon from '@material-ui/icons/Height';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import InfoIcon from '@material-ui/icons/Info';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { CustomTextField } from '../../../components/Textfield';
import { FileUploader, LightBoxImage } from '../../../components/UI';
import { CustomDatePicker } from '../../../components/DateTimePicker';
import { getAllEVUntagReason } from '../../../store/actions/settingsActionCreator';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    cardSubHeader: {
        fontSize: '22px',
        fontWeight: 700,
    },
    cardHeader: {
        fontSize: '16px',
        fontWeight: 600,
    },
    cardHeaderFail: {
        fontSize: '16px',
        fontWeight: 600,
        color: '#FB8A06',
    },
    cardSubHeaderFail: {
        fontSize: '22px',
        fontWeight: 700,
        color: '#FB8A06',
    },
    cardHeaderActive: {
        fontWeight: 700,
    },
    verticalCardContentsNoMargin: {
        margin: 0,
    },
    iconButton: {
        padding: '0px',
    },
    modalTitle: {
        fontSize: '20px',
        fontWeight: 'bold',
        color: '#14112d',
        marginLeft: '14px',
    },
    modalSubTitle: {
        fontSize: '14px',
        fontWeight: 'normal',
        color: '#14112d',
        fontWeight: 600,
        marginLeft: '14px',
    },
    renting: {
        fontSize: '18px',
        fontWeight: 'normal',
        color: '#14112d',
        fontWeight: 700,
        marginBottom: 8,
    },
    dialogContent: {
        paddingLeft: 0,
        paddingRight: 0,
    },
    dialogActions: {
        padding: '16px 24px',
    },
    name: {
        fontSize: 16,
        fontWeight: 700,
        color: '#14112D',
    },
    backDropRoot: {
        position: 'absolute',
        zIndex: theme.zIndex.drawer - 1,
        opacity: 0.5,
    },
    workLocation: {
        fontWeight: 600,
        fontSize: 16,
        color: ' #43419E',
    },
    minimizeButton: {
        color: '#43419E',
        background: 'rgba(67, 65, 158, 0.2)',
        borderRadius: 8,
        padding: '6px 8px',
        fontSize: 16,
        fontWeight: 600,
        cursor: 'pointer',
    },
    covertedTitle: {
        background: '#4CAF50',
        padding: '12px 14px',
        color: '#fff',
    },
    covertingTitle: {
        background: '#303030',
        padding: '12px 14px',
        color: '#fff',
    },
    scrollPaperMini: {
        alignItems: 'end !important',
        justifyContent: 'end !important',
    },
    modal: {
        width: 400,
        marginLeft: 'auto',
        position: 'sticky !important',
        '& .MuiBackdrop-root': {
            display: 'none',
        },
    },
    editBtn: {
        background: '#FFFFFF',
        border: '1px solid #EBEBFF',
        borderRadius: 8,
    },
    failureBox: {
        padding: '6px 16px',
        background: '#FCF2E6',
        border: '1px solid #FADEBE',
        borderRadius: 6,
        marginLeft: 'auto',
    },
    failureText: {
        color: 'red',
        fontSize: 12,
        fontWeight: 600,
    },
    warningText: {
        color: '#14112D',
        fontSize: 12,
        fontWeight: 600,
    },
    bottomBorder: {
        borderBottom: '1px solid #EAEDF3 !important',
    },
    disabled: {
        backgroundColor: 'rgba(244, 67, 54, 0.50) !important',
        color: '#FFFFFF',
    },
    untagCTa: {
        backgroundColor: '#F44336',
    },
}));
const AntTabs = withStyles({
    root: {
        borderBottom: '1px solid #e8e8e8',
        backgroundColor: '#F7F7FF',
    },
    indicator: {
        backgroundColor: '#43419E',
        height: 3,
    },
})(Tabs);
const AntTab = withStyles((theme) => ({
    root: {
        textTransform: 'none',
        minWidth: 72,
        fontWeight: theme.typography.fontWeightMedium,
        fontSize: 18,
        marginRight: theme.spacing(4),
        fontFamily: 'Nunito',
        color: '#A6AAAF',
        '&:hover': {
            color: '#43419E',
            opacity: 1,
        },
        '&$selected': {
            color: '#43419E',
            fontWeight: theme.typography.fontWeightBold,
        },
        '&:focus': {
            color: '#43419E',
        },
    },
    selected: {},
}))((props) => <Tab disableRipple {...props} />);
function StaffEvCompareModal({
    staffInfo,
    evInfo,
    open,
    onClose,
    onUnTag,
    defaultValue = {},
    orgList,
    preferredWorkLocationsList,
    selected,
    dispatchGetUntagReason,
    evUntagReasons,
    ...props
}) {
    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }
    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && <Box paddingTop={'40px'}>{children}</Box>}
            </div>
        );
    }
    const classes = useStyles();
    //const notifications = useSelector((store) => store.notifications || []);
    const [isOpen, toggleModal] = useState(open);
    const [loading, toggleLoading] = useState(false);
    const [reason, setReason] = useState(null);
    const [datePickerOpen, setDatePickerOpen] = useState(false);
    const [evPayload, setEvPayload] = useState({
        reasonId: '',
        remarks: '',
        evDocuments: {
            ...(evInfo?.evDetails?.untagEvDocuments ?? {}),
        },
    });
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const handleClose = () => {
        toggleModal(!isOpen);
        onClose && onClose();
    };
    const disableAction = () => {
        if (!!evPayload && !!evPayload?.reasonId && !!evPayload?.evDocuments) {
            let docs = evPayload?.evDocuments;
            if (
                !!docs?.evFrontImage &&
                !!docs?.evBackImage &&
                !!docs?.evLeftImage &&
                !!docs?.evRightImage &&
                !!docs?.evWithRider &&
                !!docs?.evVideo &&
                !!docs?.evLicense
            ) {
                return false;
            }
        }
        return true;
    };
    const docTabs = [
        {
            key: 'evFrontImage',
            label: 'Front',
        },
        {
            key: 'evBackImage',
            label: 'Back',
        },
        {
            key: 'evLeftImage',
            label: 'Left',
        },
        {
            key: 'evRightImage',
            label: 'Right',
        },
        {
            key: 'evWithRider',
            label: 'With Rider',
        } /** ,
        {
            key: 'evVideo',
            label: 'Video (360)',
        },*/,
        {
            key: 'evLicense',
            label: 'License',
        },
        {
            key: 'evChassis',
            label: 'Chassis',
        },
    ];
    const actions = [
        <Button
            key={1}
            variant="contained"
            color="secondary"
            size="small"
            className={`cancelButton`}
            onClick={handleClose}
            disabled={false}
            style={{
                boxShadow: 'none',
                width: '171px',
            }}
            disableElevation
        >
            Cancel
        </Button>,
        /**<Button
            key={2}
            variant="contained"
            color="primary"
            disabled={disableAction()}
            size="small"
            type="submit"
            onClick={() => {
                !!onUnTag && onUnTag(evPayload);
                handleClose();
            }}
            classes={{
                disabled: classes.disabled,
                root: classes.untagCTa,
            }}
            style={{
                boxShadow: 'none',
                //backgroundColor: '#F44336',
                width: '171px',
            }}
            disableElevation
        >
            Untag EV
        </Button>,*/
    ];
    useEffect(() => {
        dispatchGetUntagReason({});
    }, []);
    console.log('the ev payload', evPayload);
    return (
        <Dialog
            onClose={(event) => {
                handleClose();
            }}
            open={isOpen}
            disableBackdropClick={true}
            Transition={Transition}
            aria-labelledby="staff-ev-untag-dialog-slide-title"
            aria-describedby="staff-ev-untag-dialog-slide-description"
            disableEscapeKeyDown
            fullWidth={true}
            scroll={'paper'}
            maxWidth={'md'}
        >
            <DialogTitle
                id="staff-ev-untag-dialog-slide-title"
                classes={{
                    root: classes.bottomBorder,
                }}
            >
                <Box
                    width={'100%'}
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                >
                    <>
                        <Box
                            width={'45%'}
                            display={'flex'}
                            alignItems={'center'}
                        >
                            <IconButton
                                aria-label="close"
                                disabled={false}
                                className={classes.iconButton}
                                onClick={handleClose}
                            >
                                <ArrowBackIcon style={{ color: '#000' }} />
                            </IconButton>
                            <Box>
                                <Typography
                                    className={classes.modalTitle}
                                    variant="h6"
                                >
                                    {'Compare images'}
                                </Typography>
                                {!!staffInfo?.name && !!staffInfo?.name && (
                                    <Typography
                                        className={classes.modalSubTitle}
                                        variant="h6"
                                    >
                                        {`Vehicle no: ${evInfo?.evDetails?.evNumber}`}
                                    </Typography>
                                )}
                            </Box>
                        </Box>
                        <IconButton
                            aria-label="close"
                            disabled={false}
                            className={classes.iconButton}
                            onClick={handleClose}
                        >
                            <CloseIcon style={{ color: '#000' }} />
                        </IconButton>
                    </>
                </Box>
            </DialogTitle>
            <DialogContent>
                <Box width={'100%'} paddingTop={'40px'}>
                    {!!docTabs?.length && (
                        <div className={classes.root}>
                            <AntTabs
                                variant="fullWidth"
                                value={value}
                                onChange={handleChange}
                                aria-label="ant example"
                            >
                                {docTabs.map((doc, index) => {
                                    return (
                                        <AntTab
                                            label={doc?.label}
                                            {...a11yProps(index)}
                                        />
                                    );
                                })}
                            </AntTabs>
                            {docTabs.map((doc, index) => {
                                return (
                                    <TabPanel value={value} index={index}>
                                        <Typography
                                            className={classes.renting}
                                            variant="h6"
                                        >
                                            <Grid
                                                container
                                                spacing={2}
                                                direction="row"
                                            >
                                                <Grid item sm={6} xs={12}>
                                                    <Typography
                                                        className={
                                                            classes.renting
                                                        }
                                                        variant="h6"
                                                    >
                                                        {`Before Renting`}
                                                    </Typography>
                                                    <FileUploader
                                                        download={true}
                                                        label={''}
                                                        labelStyles={{
                                                            fontSize: '16px',
                                                            fontWeight: 600,
                                                        }}
                                                        disabled={true}
                                                        defaultValue={
                                                            evInfo?.evDetails
                                                                ?.evDocuments?.[
                                                                doc?.key
                                                            ]
                                                        }
                                                        placeholder={doc?.label}
                                                        onFileUploaded={(
                                                            e,
                                                        ) => {}}
                                                        onRemoved={(e) => {}}
                                                    />
                                                </Grid>
                                                <Grid item sm={6} xs={12}>
                                                    <Typography
                                                        className={
                                                            classes.renting
                                                        }
                                                        variant="h6"
                                                    >
                                                        {`After Renting`}
                                                    </Typography>
                                                    <FileUploader
                                                        download={true}
                                                        label={''}
                                                        labelStyles={{
                                                            fontSize: '16px',
                                                            fontWeight: 600,
                                                        }}
                                                        disabled={true}
                                                        defaultValue={
                                                            evInfo?.evDetails
                                                                ?.untagEvDocuments?.[
                                                                doc?.key
                                                            ]
                                                        }
                                                        placeholder={doc?.label}
                                                        onFileUploaded={(
                                                            e,
                                                        ) => {}}
                                                        onRemoved={(e) => {}}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Typography>
                                    </TabPanel>
                                );
                            })}
                        </div>
                    )}
                </Box>
            </DialogContent>
            <Divider />
            <DialogActions className={classes.dialogActions}>
                {actions.map((action, index) => (
                    <Box key={index}>{action}</Box>
                ))}
            </DialogActions>
            <Backdrop
                className={classes.backDropRoot}
                open={loading}
                onClick={null}
            >
                <CircularProgress color="primary" />
            </Backdrop>
            {datePickerOpen && (
                <CustomDatePicker
                    open={datePickerOpen}
                    onOpen={() => setDatePickerOpen(true)}
                    onClose={() => setDatePickerOpen(false)}
                    format="d MMM yyyy"
                    value={evPayload?.eventDateEpochMillis}
                    onChange={(e) => {
                        setEvPayload((prev) => {
                            return {
                                ...prev,
                                eventDateEpochMillis: moment(e).valueOf(),
                            };
                        });
                    }}
                />
            )}
        </Dialog>
    );
}
const mapStateToProps = (state) => ({
    evUntagReasons: state.settings?.evUntagReasons ?? [],
});

const mapDispatchToProps = (dispatch) => ({
    dispatchGetUntagReason: (payload, onSuccess, onError) =>
        dispatch(getAllEVUntagReason(payload, onSuccess, onError)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StaffEvCompareModal);
