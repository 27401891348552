import React, { useEffect } from 'react';
import './HireItem.style.scss';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    List,
    ListItem,
    ListItemSecondaryAction,
    Box,
    Typography,
    Tooltip,
    Checkbox,
    withStyles,
    Avatar,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ThumbnailPreview from '../../components/ThumbnailPreview';
import paidIcon from '../../assets/images/paid.png';
import notPaidIcon from '../../assets/images/notpaid.png';

import CustomDropdown from '../../components/Dropdown';

import {
    EmptyIcon,
    BikeIcon,
    FoodDeliveryIcon,
    EComDeliveryIcon,
    GroceryDeliveryIcon,
    CycleIcon,
    VanIcon,
    ArrowRightIcon,
    ExclaimIcon,
} from '../../assets/svg';
import LeadModal from '../../components/Modals/LeadModal';
import VerifiedHireModal from '../../components/Modals/VerifiedHireModal';
import InCompleteHireModal from '../../components/Modals/InCompleteHireModal';
import OnHoldHireModal from '../../components/Modals/OnHoldHireModal';
import RejectHireModal from '../../components/Modals/RejectHireModal';
import moment from 'moment';
import DeleteModal from '../../components/Modals/DeleteModal';
import Modal from '../../components/Modals/Modal';
import { updateLeadStatus } from '../../store/actions/leadActionCreator';
import ConvertLeadModal from '../../components/Modals/ConvertLeadModal';
import {
    DocumentsPreview,
    KYCDocumentsDisplayText,
} from '../../utils/constants';
import { isFunction } from '../../utils/helper';
import { SelectCheckBox } from '.';
import { showMessage } from '../../utils/helper';
const useStyles = makeStyles((theme) => ({
    listItem: {
        padding: '8px 16px 8px 16px !important',
        marginBottom: '8px !important',
        border: '1px solid #ebedf8 !important',
        borderRadius: '8px !important',
        // height: '56px !important',÷
    },
    listItemHeader: {
        border: '0px !important',
        marginBottom: '8px !important',
        color: '#525050 !important',
        fontWeight: 'bold !important',
    },
}));

function ListItemLink(props) {
    return <ListItem button component="a" {...props} />;
}
const CustomContainerComponent = React.forwardRef(
    function CustomContainerComponent(
        { children, extraSecondaryAction, ...other },
        ref,
    ) {
        return (
            <li ref={ref} {...other}>
                {children}
                {extraSecondaryAction}
            </li>
        );
    },
);

const HireTableItem = ({
    data,
    fetchAllHireInfo,
    permissions,
    leadListingPayload,
    // dispatchSetSingleStaffInfo,
    dispatchGetSingleStaff,
    dispatchSendAppLink,
    dispatchEnqueueSnackbar,
    dispatchUpdateLeadStatus,
    selected,
    onSelectItem,
    onSelectAll,
    disableSelect,
    count,
    ...props
}) => {
    const classes = useStyles();

    const [preferredLocModalOpen, setPreferredLocModalOpen] =
        React.useState(false);
    const [deleteLeadModalOpen, setDeleteLeadModalOpen] = React.useState(false);
    const [selectedItemData, setSelectedItemData] = React.useState({});
    const [leadModalOpen, setLeadModalOpen] = React.useState(false);
    const [verifiedHireModalOpen, setVerifiedHireModalOpen] =
        React.useState(false);
    const [convertLeadModalOpen, setConvertLeadModalOpen] =
        React.useState(false);
    const [incompleteModalOpen, setIncompleteModalOpen] = React.useState(false);
    React.useState(false);
    const [onHoldModalOpen, setOnHoldModalOpen] = React.useState(false);
    const [rejectHireModalOpen, setRejectireModalOpen] = React.useState(false);
    const [allSelected, setAllSelected] = React.useState(false);
    useEffect(() => {
        if (selected.length === 0) {
            setAllSelected(false);
        }
    }, [selected]);
    const DeliveryIcon = {
        'Food Delivery': (
            <Tooltip title="Food Delivery" placement="top">
                <FoodDeliveryIcon width={20} height={20} />
            </Tooltip>
        ),
        'E-com Delivery': (
            <Tooltip title="ECom Delivery" placement="top">
                <EComDeliveryIcon width={20} height={20} />
            </Tooltip>
        ),
        'Grocery Delivery': (
            <Tooltip title="Grocery Delivery" placement="top">
                <GroceryDeliveryIcon width={20} height={20} />
            </Tooltip>
        ),
    };
    const handleSelect = (e, item) => {
        //e.preventDefault();
        //e.stopPropagation();
        //event.target.checked
        setAllSelected(false);
        if (selected.length <= 50 && onSelectItem && isFunction(onSelectItem)) {
            onSelectItem(item);
        } else {
            showMessage('Maximum Selection Limit Reached', 'error');
        }
    };
    const handleAllSelect = (e) => {
        //e.preventDefault();
        //e.stopPropagation();
        //event.target.checked
        if (selected.length <= 50) {
            setAllSelected(e.target.checked);
            if (onSelectAll && isFunction(onSelectAll)) {
                onSelectAll(e.target.checked, data);
            }
        } else {
            showMessage('Maximum Selection Limit Reached', 'error');
        }
    };
    return (
        <Box className="hire-item">
            <List component="nav">
                <ListItem className={classes.listItemHeader}>
                    <Box
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '100%',
                        }}
                        alignItems={'center'}
                    >
                        {permissions &&
                            permissions.create &&
                            leadListingPayload &&
                            leadListingPayload.filterStatus &&
                            leadListingPayload.filterStatus === 'Verified' && (
                                <SelectCheckBox
                                    disabled={disableSelect}
                                    checked={allSelected}
                                    onChange={handleAllSelect}
                                    indeterminate={allSelected}
                                />
                            )}
                        <Box style={{ width: '15%' }}>Name</Box>
                        <Box style={{ width: '10%' }}></Box>
                        <Box style={{ width: '20%' }}>
                            City & Preferred Locations
                        </Box>
                        <Box style={{ width: '12%' }}>Phone Number</Box>
                        <Box style={{ width: '13%' }}>Status</Box>
                        <Box style={{ width: '10.5%', paddingLeft: '0' }}>
                            Face Match
                        </Box>
                        <Box style={{ width: '8%', paddingLeft: '0' }}>
                            Documents
                        </Box>
                        <Box style={{ width: '7.66%' }}></Box>
                    </Box>
                </ListItem>
                <Box>
                    {data?.length ? (
                        data?.map((val, index) => {
                            // console.log(key); // the name of the current key.
                            // console.log(val); // the value of the current key.
                            return (
                                // <div
                                //     style={{
                                //         display: 'flex',
                                //         flexDirection: 'row',
                                //     }}
                                // >
                                <ListItemLink
                                    // button
                                    className={classes.listItem}
                                    ContainerComponent={
                                        CustomContainerComponent
                                    }
                                    ContainerProps={{
                                        extraSecondaryAction: (
                                            <ListItemSecondaryAction
                                                style={{
                                                    left: '16px',
                                                    width: '3%',
                                                }}
                                            >
                                                {permissions &&
                                                    permissions.create &&
                                                    leadListingPayload &&
                                                    leadListingPayload.filterStatus &&
                                                    leadListingPayload.filterStatus ===
                                                        'Verified' && (
                                                        <SelectCheckBox
                                                            checked={
                                                                selected.indexOf(
                                                                    val.id,
                                                                ) !== -1
                                                            }
                                                            onChange={(e) => {
                                                                handleSelect(
                                                                    e,
                                                                    val,
                                                                );
                                                            }}
                                                            disabled={
                                                                disableSelect
                                                            }
                                                            key={val.id}
                                                        />
                                                    )}
                                            </ListItemSecondaryAction>
                                        ),
                                    }}
                                    onClick={() => {
                                        console.log(`dateilas`, val);
                                        //   dispatchSetSingleStaffInfo(val);
                                        // dispatchGetSingleStaff(
                                        //     val.id,
                                        //     (response) => {
                                        //         console.log(
                                        //             'Get single staff success',
                                        //         );
                                        //         props.history.push(
                                        //             `/staff/details/${val.id}`,
                                        //             {
                                        //                 searchVal:
                                        //                     leadListingPayload?.searchText,
                                        //                 page:
                                        //                     leadListingPayload?.pageNo -
                                        //                     1,
                                        //                 rowsPerPage:
                                        //                     leadListingPayload?.pageSize,
                                        //             },
                                        //         );
                                        //     },
                                        // () => {},
                                        // );
                                        setLeadModalOpen(true);
                                        setSelectedItemData({
                                            ...val,
                                        });
                                    }}
                                    // href={`/staff/details/${phoneNumber}`}
                                    // href="#simple"
                                    key={val.id}
                                    style={
                                        index % 2
                                            ? {
                                                  backgroundColor:
                                                      'rgba(241, 243, 246, 1)',
                                              }
                                            : {}
                                    }
                                >
                                    <Box
                                        style={
                                            permissions &&
                                            permissions.create &&
                                            leadListingPayload &&
                                            leadListingPayload.filterStatus &&
                                            leadListingPayload.filterStatus ===
                                                'Verified'
                                                ? {
                                                      width: '15%',
                                                      marginLeft: '3%',
                                                  }
                                                : {
                                                      width: '15%',
                                                  }
                                        }
                                        // className={classes.tableCell}
                                    >
                                        <Box
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                flexWrap: 'wrap',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Typography
                                                style={{
                                                    wordBreak: 'break-all',
                                                    fontWeight: 600,
                                                    marginRight: 5,
                                                }}
                                            >
                                                {val.name ?? '-'}
                                            </Typography>
                                        </Box>
                                        <Box
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                flexWrap: 'wrap',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Typography
                                                style={{
                                                    fontSize: '12px',
                                                    marginRight: '4px',
                                                }}
                                            >
                                                {!!val?.dateEpochMillis
                                                    ? moment(
                                                          val?.dateEpochMillis,
                                                      )?.format('DD-MMM-YYYY')
                                                    : '-'}
                                            </Typography>
                                            {!!val?.leadSourceName && (
                                                <Typography
                                                    className={`lead-type-chip ${
                                                        val?.leadSourceName ===
                                                        'Referral'
                                                            ? 'lead-type-chip-referral'
                                                            : ''
                                                    }`}
                                                >
                                                    {val?.leadSourceName}
                                                </Typography>
                                            )}
                                        </Box>
                                    </Box>
                                    <Box
                                        style={{
                                            width: '10%',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            marginTop: 10,
                                        }}
                                        // className={classes.tableCell}
                                    >
                                        <Box
                                            style={{
                                                width: '100%',
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'flex-start',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Box
                                                style={{
                                                    marginRight: '10px',
                                                }}
                                            >
                                                {(() => {
                                                    switch (val?.vehicleType) {
                                                        case 'Bike':
                                                            return (
                                                                <Tooltip
                                                                    title="Bike"
                                                                    placement="top"
                                                                >
                                                                    <BikeIcon
                                                                        width={
                                                                            20
                                                                        }
                                                                        height={
                                                                            20
                                                                        }
                                                                    />
                                                                </Tooltip>
                                                            );
                                                        case 'Van':
                                                            return (
                                                                <Tooltip
                                                                    title="Van"
                                                                    placement="top"
                                                                >
                                                                    <VanIcon
                                                                        width={
                                                                            20
                                                                        }
                                                                        height={
                                                                            20
                                                                        }
                                                                    />
                                                                </Tooltip>
                                                            );
                                                        case 'Cycle':
                                                            return (
                                                                <Tooltip
                                                                    title="Cycle"
                                                                    placement="top"
                                                                >
                                                                    <CycleIcon
                                                                        width={
                                                                            20
                                                                        }
                                                                        height={
                                                                            20
                                                                        }
                                                                    />
                                                                </Tooltip>
                                                            );

                                                        default:
                                                            return <></>;
                                                    }
                                                })()}
                                            </Box>
                                        </Box>
                                        <Box
                                            style={{
                                                width: '100%',
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'flex-start',
                                                alignItems: 'center',
                                            }}
                                        >
                                            {!!val?.deliveryPreferences
                                                ?.length &&
                                                val?.deliveryPreferences?.map(
                                                    (item) => (
                                                        <Box
                                                            style={{
                                                                marginRight:
                                                                    '10px',
                                                            }}
                                                        >
                                                            {DeliveryIcon[item]}
                                                        </Box>
                                                    ),
                                                )}
                                        </Box>
                                    </Box>
                                    <Box
                                        style={{
                                            width: '20%',
                                            display: 'flex',
                                            cursor: 'pointer',
                                            flexDirection: 'column',
                                        }}
                                    >
                                        <Box>
                                            <Typography
                                                style={{
                                                    wordBreak: 'break-all',
                                                    fontWeight: 600,
                                                }}
                                            >
                                                {val?.city ?? '-'}
                                            </Typography>
                                        </Box>

                                        {val?.preferredLocations?.length <=
                                        2 ? (
                                            <Typography
                                                style={{
                                                    fontWeight: 600,
                                                    opacity: 0.7,
                                                    fontSize: 14,
                                                }}
                                            >
                                                {val?.preferredLocations?.join(
                                                    ', ',
                                                )}
                                            </Typography>
                                        ) : val?.preferredLocations?.length ? (
                                            <Box
                                                style={{
                                                    display: 'flex',
                                                    cursor: 'pointer',
                                                    alignItems: 'center',
                                                }}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    setSelectedItemData(val);
                                                    setPreferredLocModalOpen(
                                                        true,
                                                    );
                                                }}
                                            >
                                                <Typography
                                                    style={{
                                                        fontWeight: 600,
                                                        color: '#43419e',
                                                        opacity: 0.7,
                                                    }}
                                                >
                                                    {
                                                        val?.preferredLocations
                                                            ?.length
                                                    }
                                                    {val?.preferredLocations
                                                        ?.length > 1
                                                        ? ' Locations'
                                                        : ' Location'}
                                                </Typography>
                                                <ArrowRightIcon />
                                            </Box>
                                        ) : (
                                            <Box>
                                                {/* <Typography
                                                    style={{
                                                        fontWeight: 600,
                                                        color: '#000',
                                                    }}
                                                >
                                                    -
                                                </Typography> */}
                                            </Box>
                                        )}
                                    </Box>
                                    <Box
                                        style={{
                                            width: '12%',
                                            alignItems: 'center',
                                        }}
                                        // className={classes.tableCell}
                                    >
                                        <Typography
                                            style={{
                                                wordBreak: 'break-all',
                                                fontWeight: 600,
                                            }}
                                        >
                                            {val?.phoneNumber}
                                        </Typography>
                                    </Box>

                                    <Box style={{ width: '13%' }}>
                                        <Typography
                                            style={{ width: '80%' }}
                                            className={GetLeadStatusTag(
                                                val.status,
                                            )}
                                        >
                                            {val.status ?? '-'}
                                        </Typography>
                                        <Box
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}
                                        >
                                            {!!val?.statusChangeEpochMillis && (
                                                <Typography
                                                    style={{
                                                        textAlign: 'center',
                                                        marginTop: 5,
                                                        fontSize: 14,
                                                    }}
                                                >
                                                    {!!val?.statusChangeEpochMillis
                                                        ? moment(
                                                              val?.statusChangeEpochMillis,
                                                          )?.format(
                                                              'DD-MMM-YYYY',
                                                          )
                                                        : '-'}
                                                </Typography>
                                            )}
                                            {(!!val.joiningFeePaid ||
                                                val.joiningFeeRequired) && (
                                                <Tooltip
                                                    title={
                                                        val.joiningFeePaid
                                                            ? 'Joining Fee Paid'
                                                            : 'Joining Fee Not Paid'
                                                    }
                                                    placement="center"
                                                >
                                                    <Avatar
                                                        style={{
                                                            marginLeft: 5,
                                                            borderRadius: 8,
                                                            width: 24,
                                                            height: 24,
                                                        }}
                                                        alt="Hold"
                                                        src={
                                                            val.joiningFeePaid
                                                                ? paidIcon
                                                                : notPaidIcon
                                                        }
                                                    />
                                                </Tooltip>
                                            )}
                                        </Box>
                                    </Box>

                                    <Box
                                        style={{
                                            width: '10%',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Box>
                                            <Typography
                                                style={
                                                    val?.faceMatchConfidence >
                                                    80
                                                        ? {
                                                              width: '100%',
                                                              textAlign: 'left',
                                                              color: '#4caf50',
                                                              fontWeight: 700,
                                                          }
                                                        : val?.faceMatchConfidence >
                                                          50
                                                        ? {
                                                              width: '100%',
                                                              textAlign: 'left',
                                                              color: '#FF6C36',
                                                              fontWeight: 700,
                                                          }
                                                        : val?.faceMatchConfidence >
                                                          0
                                                        ? {
                                                              width: '100%',
                                                              textAlign: 'left',
                                                              color: '#f61100',
                                                              fontWeight: 700,
                                                          }
                                                        : {
                                                              width: '100%',
                                                              textAlign: 'left',
                                                              color: '#999999',
                                                              fontWeight: 700,
                                                              fontSize: 14,
                                                          }
                                                }
                                            >
                                                {!!val?.faceMatchConfidence
                                                    ? `${val?.faceMatchConfidence}%`
                                                    : 'Manual KYC'}
                                            </Typography>
                                            {val?.faceMatchConfidence > 0 && (
                                                <Typography
                                                    style={{
                                                        width: '100%',
                                                        textAlign: 'center',
                                                        marginTop: 5,
                                                        fontSize: 14,
                                                    }}
                                                >
                                                    {
                                                        KYCDocumentsDisplayText?.[
                                                            val
                                                                ?.faceMatchDocument
                                                        ]
                                                    }
                                                </Typography>
                                            )}
                                        </Box>

                                        {val?.manualVerificationRequired && (
                                            <Box
                                                style={{
                                                    justifySelf: 'flex-end',
                                                }}
                                            >
                                                <ExclaimIcon
                                                    width={20}
                                                    height={20}
                                                />
                                            </Box>
                                        )}
                                    </Box>
                                    <ListItemSecondaryAction
                                        style={{ width: '20%' }}
                                    >
                                        <Box
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                            }}
                                        >
                                            <Box
                                                style={{
                                                    width: '60%',
                                                    // marginLeft:
                                                    //     val?.documents?.length >
                                                    //     4
                                                    //         ? 0
                                                    //         : 10,
                                                }}
                                            >
                                                {val?.documents?.length ? (
                                                    <Box
                                                        style={{
                                                            display: 'flex',
                                                            alignItems:
                                                                'center',
                                                            justifyContent:
                                                                'flex-end',
                                                        }}
                                                    >
                                                        <ThumbnailPreview
                                                            images={(function () {
                                                                var hello = [];
                                                                if (
                                                                    val
                                                                        ?.documents
                                                                        ?.length >
                                                                    0
                                                                ) {
                                                                    if (
                                                                        val.documents[0].hasOwnProperty(
                                                                            'documentType',
                                                                        )
                                                                    ) {
                                                                        val.documents.forEach(
                                                                            (
                                                                                x,
                                                                                index,
                                                                            ) => {
                                                                                var tempDoc =
                                                                                    DocumentsPreview.find(
                                                                                        (
                                                                                            y,
                                                                                        ) =>
                                                                                            y.type ===
                                                                                            x.documentType,
                                                                                    );
                                                                                if (
                                                                                    !tempDoc
                                                                                )
                                                                                    val.documents.splice(
                                                                                        index,
                                                                                        1,
                                                                                    );
                                                                                x.preview =
                                                                                    tempDoc
                                                                                        ? tempDoc.value
                                                                                        : x.documentValue;
                                                                                x.link =
                                                                                    x.documentValue;
                                                                            },
                                                                        );
                                                                    }
                                                                    return val?.documents;
                                                                } else {
                                                                    return [];
                                                                }
                                                            })()}
                                                        />
                                                    </Box>
                                                ) : (
                                                    <Box>
                                                        {/* <Typography
                                                            style={{
                                                                fontWeight: 600,
                                                                color: '#000',
                                                            }}
                                                        >
                                                            -
                                                        </Typography> */}
                                                    </Box>
                                                )}
                                            </Box>

                                            <Box
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <CustomDropdown
                                                    options={[
                                                        {
                                                            item: 'Edit',
                                                            onSuccess: () => {
                                                                setLeadModalOpen(
                                                                    true,
                                                                );
                                                                setSelectedItemData(
                                                                    val,
                                                                );
                                                            },
                                                            disabled:
                                                                val.status ===
                                                                    'Onboarded' ||
                                                                val.status ===
                                                                    'Converted' ||
                                                                val.status ===
                                                                    'Verified' ||
                                                                val.status ===
                                                                    'Rejected',
                                                        },

                                                        {
                                                            item: 'Mark Verified',
                                                            onSuccess: () => {
                                                                setSelectedItemData(
                                                                    val,
                                                                );
                                                                setVerifiedHireModalOpen(
                                                                    true,
                                                                );
                                                            },
                                                            disabled:
                                                                val.status ===
                                                                    'Rejected' ||
                                                                val.status ===
                                                                    'Onboarded' ||
                                                                val.status ===
                                                                    'Converted' ||
                                                                val.status ===
                                                                    'Verified',
                                                        },
                                                        {
                                                            item: 'Mark Incomplete',
                                                            onSuccess:
                                                                async () => {
                                                                    setSelectedItemData(
                                                                        val,
                                                                    );
                                                                    setIncompleteModalOpen(
                                                                        true,
                                                                    );
                                                                },
                                                            disabled:
                                                                val.status ===
                                                                    'Onboarded' ||
                                                                val.status ===
                                                                    'Converted' ||
                                                                val.status ===
                                                                    'Marked Incomplete' ||
                                                                val.status ===
                                                                    'Open',
                                                        },
                                                        {
                                                            item: 'Mark Reject',
                                                            onSuccess: () => {
                                                                setSelectedItemData(
                                                                    val,
                                                                );
                                                                setRejectireModalOpen(
                                                                    true,
                                                                );
                                                            },
                                                            disabled:
                                                                val.status ===
                                                                    'Rejected' ||
                                                                val.status ===
                                                                    'Onboarded' ||
                                                                val.status ===
                                                                    'Converted',
                                                        },
                                                        {
                                                            item: 'On Hold',
                                                            onSuccess:
                                                                async () => {
                                                                    setSelectedItemData(
                                                                        val,
                                                                    );
                                                                    setOnHoldModalOpen(
                                                                        true,
                                                                    );
                                                                },
                                                            disabled:
                                                                val.status ===
                                                                    'On Hold' ||
                                                                val.status ===
                                                                    'Onboarded' ||
                                                                val.status ===
                                                                    'Converted',
                                                        },
                                                        // {
                                                        //     item: 'Convert to Staff',
                                                        //     onSuccess: () => {
                                                        //         setSelectedItemData(
                                                        //             val,
                                                        //         );
                                                        //         setConvertLeadModalOpen(
                                                        //             true,
                                                        //         );
                                                        //     },
                                                        //     disabled:
                                                        //         val.status ===
                                                        //             'Rejected' ||
                                                        //         val.status ===
                                                        //             'Onboarded',
                                                        // },
                                                        {
                                                            item: 'Delete',
                                                            onSuccess: () => {
                                                                setSelectedItemData(
                                                                    val,
                                                                );
                                                                setDeleteLeadModalOpen(
                                                                    true,
                                                                );
                                                            },
                                                            disabled:
                                                                val.status ===
                                                                    'Onboarded' ||
                                                                val.status ===
                                                                    'Converted',
                                                        },
                                                    ]}
                                                />
                                            </Box>
                                        </Box>
                                    </ListItemSecondaryAction>
                                </ListItemLink>
                            );
                        })
                    ) : (
                        <Box
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <EmptyIcon />
                        </Box>
                    )}
                </Box>

                {preferredLocModalOpen && (
                    <Modal
                        onClose={() => {
                            setSelectedItemData({});
                            setPreferredLocModalOpen(false);
                        }}
                        open={preferredLocModalOpen}
                        title={'City & Preferred Location'}
                        subtitle={
                            selectedItemData?.name +
                            ' | ' +
                            selectedItemData?.phoneNumber
                        }
                        className={classes.modal}
                    >
                        <Typography
                            style={{
                                fontWeight: 600,
                                fontSize: 18,
                                margin: '8px 4px',
                            }}
                        >
                            {selectedItemData?.city}
                        </Typography>
                        {selectedItemData?.preferredLocations?.map(
                            (item, index) => (
                                <Box
                                    style={{
                                        border: '1px solid #caccd3',
                                        marginBottom: 8,
                                        borderRadius: 4,
                                    }}
                                >
                                    <Typography
                                        key={index}
                                        className="item"
                                        style={{
                                            padding: '12px',
                                            fontSize: '14px',
                                            fontWeight: 600,
                                        }}
                                    >
                                        {item}
                                    </Typography>
                                </Box>
                            ),
                        )}
                    </Modal>
                )}

                {deleteLeadModalOpen && (
                    <DeleteModal
                        open={deleteLeadModalOpen}
                        deleteType={selectedItemData.name}
                        data={selectedItemData}
                        onDeletion={(setLoader) => {
                            setLoader(true);
                            const payload = {
                                status: 'Deleted',
                                comments: '',
                            };
                            dispatchUpdateLeadStatus(
                                selectedItemData.id,
                                payload,
                                (resp) => {
                                    setLoader(false);
                                    setDeleteLeadModalOpen(false);
                                    fetchAllHireInfo && fetchAllHireInfo({});
                                },
                                (err) => {
                                    setLoader(false);
                                    setDeleteLeadModalOpen(false);
                                },
                            );
                            setDeleteLeadModalOpen(false);
                        }}
                        onClose={() => {
                            console.log('Closed');
                            fetchAllHireInfo && fetchAllHireInfo({});
                            setDeleteLeadModalOpen(false);
                        }}
                    />
                )}
                {leadModalOpen && (
                    <LeadModal
                        open={leadModalOpen}
                        leadInfo={selectedItemData}
                        shouldShowActionBar={
                            selectedItemData?.shouldShowActionBar ?? false
                        }
                        isEdit={true}
                        onClose={() => {
                            console.log('Closed');
                            fetchAllHireInfo && fetchAllHireInfo({});
                            setLeadModalOpen(false);
                        }}
                    />
                )}
                {verifiedHireModalOpen && (
                    <VerifiedHireModal
                        open={verifiedHireModalOpen}
                        leadInfo={selectedItemData}
                        onClose={() => {
                            console.log('Closed');
                            fetchAllHireInfo && fetchAllHireInfo({});
                            setVerifiedHireModalOpen(false);
                        }}
                    />
                )}
                {convertLeadModalOpen && (
                    <ConvertLeadModal
                        open={convertLeadModalOpen}
                        leadInfo={selectedItemData}
                        onClose={() => {
                            console.log('Closed');
                            fetchAllHireInfo && fetchAllHireInfo({});
                            setConvertLeadModalOpen(false);
                        }}
                    />
                )}
                {incompleteModalOpen && (
                    <InCompleteHireModal
                        open={incompleteModalOpen}
                        leadInfo={selectedItemData}
                        onClose={() => {
                            console.log('Closed');
                            fetchAllHireInfo && fetchAllHireInfo({});
                            setIncompleteModalOpen(false);
                        }}
                    />
                )}
                {onHoldModalOpen && (
                    <OnHoldHireModal
                        open={onHoldModalOpen}
                        leadInfo={selectedItemData}
                        onClose={() => {
                            console.log('Closed');
                            fetchAllHireInfo && fetchAllHireInfo({});
                            setOnHoldModalOpen(false);
                        }}
                    />
                )}
                {rejectHireModalOpen && (
                    <RejectHireModal
                        open={rejectHireModalOpen}
                        leadInfo={selectedItemData}
                        onClose={() => {
                            console.log('Closed');
                            fetchAllHireInfo && fetchAllHireInfo({});
                            setRejectireModalOpen(false);
                        }}
                    />
                )}
            </List>
        </Box>
    );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
    dispatchUpdateLeadStatus: (id, data, onSuccess, OnFailed) =>
        dispatch(updateLeadStatus(id, data, onSuccess, OnFailed)),
});

export const HireItem = withRouter(
    connect(mapStateToProps, mapDispatchToProps)(HireTableItem),
);

export const GetLeadStatusTag = (status) => {
    return status === 'Open'
        ? 'open-chip'
        : status === 'Completed'
        ? 'completed-chip'
        : status === 'Rejected'
        ? 'rejected-chip'
        : status === 'Incomplete'
        ? 'incomplete-chip'
        : status === 'Marked Incomplete'
        ? 'markedincomplete-chip'
        : status === 'Verified'
        ? 'verified-chip'
        : status === 'Onboarded'
        ? 'converted-chip'
        : status === 'Converted'
        ? 'converted-chip'
        : status === 'Resubmitted'
        ? 'completed-chip'
        : status === 'On Hold'
        ? 'onhold-chip'
        : null;
};
