import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    makeStyles,
    MenuItem,
    Slide,
    Typography,
    useMediaQuery,
    useTheme,
    withStyles,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { CustomTextField } from '../Textfield';
import { Spinner } from '../UI';
import {
    GetCities,
    createEvVehicle,
    editEvVehicle,
} from '../../store/actions/settingsActionCreator';
import { connect } from 'react-redux';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { EVVEHICLE_STATUS_LIST } from '../../pages/settings/EVVehicles';
import { useDebounce } from '../../hooks/useDebounce';
const validationSchema = yup.object({
    evNumber: yup
        .string('Invalid Number')
        .trim()
        //.min(14, 'must be at least 14 characters long')
        .matches(/^[a-zA-Z0-9]+$/, 'Must contain only letters and numbers')
        .required('Chassis Number cannot be empty'),
    evVehicleNumber: yup
        .string('Invalid Vehicle Number')
        .trim()
        .min(5, 'must be at least 5 characters long')
        .matches(/^[a-zA-Z0-9]+$/, 'Must contain only letters and numbers')
        .required('Vehicle Number cannot be empty'),
    captainId: yup
        .string()
        .trim()
        .required('Select a Captain')
        .nullable('Select a Captain'),
    vendorId: yup
        .string()
        .trim()
        .required('Select a Vendor')
        .nullable('Select a Vendor'),
    projectId: yup
        .string()
        .trim()
        .required('Select a Project')
        .nullable('Select a Project'),
    cityId: yup.string().required('Select a City').nullable('Select a City'),
    teamId: yup.string().required('Select a Team').nullable('Select a Team'),
    evVehicleStatusId: yup
        .string()
        .required('Select a status')
        .nullable('Select a status'),
});

const useStyles = makeStyles((theme) => ({
    modal: {
        '& .modal-head': {
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
        },
        '& .modal-title': {
            fontSize: '24px',
            fontWeight: 'bold',
            color: '#14112d',
        },
        '& .modal-subtitle': {
            fontSize: '14px',
            fontWeight: 'normal',
            color: '#14112d',
        },

        '& .cancelButton': {
            color: theme.palette.text.primary,
        },
        '& .leaflet-container': {
            width: '100%',
            height: '510px',
            // borderRadius: 16,
            borderTopRightRadius: 16,
            borderBottomRightRadius: 16,
        },
    },
    iconButton: {
        padding: '0px',
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const StyledMenuItem = withStyles((theme) => ({
    root: {
        margin: '0 8px',
        border: '0 !important',
        borderRadius: '8px',
        '&:hover': {
            backgroundColor: 'rgba(67, 65, 158, 0.08)',
            color: '#43419e',
            borderRadius: '8px',
        },
    },
}))(MenuItem);

function EVModal({
    open,
    onClose,
    maxWidth,
    defaultValue = {},
    statesList,
    cityList,
    vendorList,
    dispatchCreateEvVehicle,
    dispatchEditEvVehicle,
    dispatchGetCities,
    teamList,
    projectsList,
    captainList,
    ...props
}) {
    const { debounce, isBouncing } = useDebounce();

    let isEdit = Object.keys(defaultValue)?.length;
    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [isOpen, toggleModal] = useState(open);
    const [loading, toggleLoading] = useState(false);

    /**const [evVehicleStatusId, setEvVehicleStatusId] = useState(
        defaultValue?.evVehicleStatusId ?? 2,
    );*/

    useEffect(() => {
        toggleLoading(true);
        dispatchGetCities(
            {},
            () => {
                toggleLoading(false);
            },
            () => {
                toggleLoading(false);
            },
        );
    }, []);

    const formik = useFormik({
        initialValues: {
            // customerId: defaultValue?.id,
            evNumber: defaultValue?.evNumber ?? '',
            captainId: defaultValue?.captainId ?? '',
            vendor: defaultValue?.vendor ?? '',
            projectId: defaultValue?.projectId ?? '',
            stateId: defaultValue?.stateId ?? '',
            vendorId: defaultValue?.vendorId ?? '',
            teamId: defaultValue?.teamId ?? '',
            cityId: defaultValue?.cityId ?? '',
            evVehicleNumber: defaultValue?.evVehicleNumber ?? '',
            evVehicleStatusId: defaultValue?.evVehicleStatusId ?? 2,
            ...defaultValue,
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            debounce(() => {
                toggleLoading(true);
                console.log('ONSUBMIT', formik.values, '1243qewrdsfzcx', {
                    customerId: formik.values?.customerId,
                    evNumber: formik.values?.evNumber,
                });

                let status = EVVEHICLE_STATUS_LIST?.filter(
                    (item) => item?.id == formik.values?.evVehicleStatusId,
                )?.[0]?.status;

                if (isEdit) {
                    dispatchEditEvVehicle(
                        {
                            id: defaultValue?.id,
                            payload: {
                                evVehicleNumber: formik.values?.evVehicleNumber,
                                vendorId: formik.values?.vendorId,
                                teamId: formik.values?.teamId,
                                captainId: formik.values?.captainId,
                                projectId: formik.values?.projectId,
                                cityId: formik.values?.cityId,
                                // rentedDateEpochMillis:
                                //     payloadData?.rentedDateEpochMillis,
                                // returnedDateEpochMillis:
                                //     payloadData?.returnedDateEpochMillis,
                                status,
                            },
                        },
                        async (resp) => {
                            await toggleLoading(false);
                            handleClose();
                        },
                        async (resp) => {
                            await toggleLoading(false);
                            // handleClose();
                        },
                    );
                } else {
                    dispatchCreateEvVehicle(
                        {
                            evNumber: formik.values?.evNumber,
                            evVehicleNumber: formik.values?.evVehicleNumber,
                            projectId: formik.values?.projectId,
                            vendorId: formik.values?.vendorId,
                            teamId: formik.values?.teamId,
                            captainId: formik.values?.captainId,
                            cityId: formik.values?.cityId,
                            status,
                        },
                        async (resp) => {
                            await toggleLoading(false);
                            handleClose();
                        },
                        async (resp) => {
                            await toggleLoading(false);
                            // handleClose();
                        },
                    );
                }
            });
        },
    });

    const handleClose = () => {
        toggleModal(!isOpen);
        onClose && onClose();
    };

    const enableSubmitButton = isEdit
        ? !defaultValue?.evVehicleNumber
            ? formik.values?.evVehicleNumber?.length >= 5
            : true
        : formik.values?.evNumber?.length &&
          formik.values?.evNumber?.length >= 14 &&
          formik.values?.evVehicleNumber?.length >= 5 &&
          formik.values?.vendorId &&
          formik.values?.teamId &&
          formik.values?.projectId &&
          formik.values?.cityId &&
          formik.values?.evVehicleStatusId;

    const actions = [
        <Button
            key={1}
            variant="contained"
            color="default"
            className={`cancelButton`}
            onClick={handleClose}
            disableElevation
        >
            {'Cancel'}
        </Button>,
        <Button
            key={2}
            variant="contained"
            color="primary"
            className={`saveButton`}
            type="submit"
            disabled={isBouncing || !formik?.dirty}
            disableElevation
        >
            {isEdit ? 'Update EV Vehicle' : 'Add EV Vehicle'}
        </Button>,
    ];

    useEffect(() => {
        console.log('formik.values', formik.values);
    }, [formik.values]);
    const disabledStatus = ['UNTAGGED', 'RELEASED'];
    console.log('formik.values', formik);
    return (
        <Dialog
            open={isOpen}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            className={classes.modal}
            fullScreen={fullScreen}
            fullWidth={true}
            maxWidth={maxWidth ?? 'sm'}
            disableBackdropClick
        >
            <Spinner loading={loading}>
                <form onSubmit={formik.handleSubmit}>
                    <DialogTitle id="alert-dialog-slide-title">
                        <Box className={`modal-head`}>
                            <div>
                                <Typography
                                    className={`modal-title`}
                                    variant="h6"
                                >
                                    {isEdit
                                        ? 'Edit EV Vehicle'
                                        : 'Add EV Vehicle'}
                                </Typography>
                                <Typography
                                    className={`modal-subtitle`}
                                    variant="body2"
                                >
                                    {isEdit
                                        ? 'Edit EV Vehicle'
                                        : 'Add New EV Vehicle'}
                                </Typography>
                            </div>
                            <div
                                style={{
                                    alignSelf: 'center',
                                    justifySelf: 'flex-end',
                                }}
                            >
                                <IconButton
                                    aria-label="delete"
                                    className={classes.iconButton}
                                    onClick={handleClose}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </div>
                        </Box>
                    </DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <CustomTextField
                                    fullWidth={true}
                                    disabled={false}
                                    label="Vehicle No*"
                                    variant="outlined"
                                    style={{}}
                                    id="evVehicleNumber"
                                    name="evVehicleNumber"
                                    value={formik.values.evVehicleNumber}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.evVehicleNumber
                                            ? Boolean(
                                                  formik.errors.evVehicleNumber,
                                              )
                                            : false
                                    }
                                    helperText={
                                        formik.touched.evVehicleNumber
                                            ? formik?.errors?.evVehicleNumber
                                            : ''
                                    }
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <CustomTextField
                                    fullWidth={true}
                                    disabled={
                                        !!defaultValue?.evNumber && isEdit
                                    }
                                    label="Chassis No*"
                                    variant="outlined"
                                    style={{}}
                                    id="evNumber"
                                    name="evNumber"
                                    value={formik.values.evNumber}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.evNumber
                                            ? Boolean(formik?.errors?.evNumber)
                                            : false
                                    }
                                    helperText={
                                        formik.touched.evNumber
                                            ? formik.errors.evNumber
                                            : ''
                                    }
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <CustomTextField
                                    fullWidth={true}
                                    select
                                    label="Captain*"
                                    variant="outlined"
                                    style={{}}
                                    id="captainId"
                                    name="captainId"
                                    value={formik.values.captainId}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.captainId
                                            ? Boolean(formik.errors.captainId)
                                            : false
                                    }
                                    helperText={
                                        formik.touched.captainId &&
                                        formik.errors.captainId
                                    }
                                >
                                    {captainList?.length === 0 && (
                                        <MenuItem value={undefined}>
                                            No Data found
                                        </MenuItem>
                                    )}
                                    {captainList?.map((captain) => (
                                        <MenuItem value={captain.id}>
                                            {captain.name}
                                        </MenuItem>
                                    ))}
                                </CustomTextField>
                            </Grid>

                            <Grid item xs={6}>
                                <CustomTextField
                                    label="Vendor*"
                                    select
                                    id="vendorId"
                                    name="vendorId"
                                    value={formik.values.vendorId}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.vendorId
                                            ? Boolean(formik.errors.vendorId)
                                            : false
                                    }
                                    helperText={
                                        formik.touched.vendorId &&
                                        formik.errors.vendorId
                                    }
                                >
                                    {vendorList?.length === 0 && (
                                        <MenuItem value={undefined}>
                                            No Data found
                                        </MenuItem>
                                    )}
                                    {vendorList?.map((vendor) => (
                                        <MenuItem value={vendor.id}>
                                            {vendor.name}
                                        </MenuItem>
                                    ))}
                                </CustomTextField>
                            </Grid>
                            <Grid item xs={6}>
                                <CustomTextField
                                    label="Team*"
                                    select
                                    id="teamId"
                                    name="teamId"
                                    value={formik.values.teamId}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.teamId
                                            ? Boolean(formik.errors.teamId)
                                            : false
                                    }
                                    helperText={
                                        formik.touched.teamId &&
                                        formik.errors.teamId
                                    }
                                >
                                    {teamList?.length === 0 && (
                                        <MenuItem value={undefined}>
                                            No Data found
                                        </MenuItem>
                                    )}
                                    {teamList?.map((team) => (
                                        <MenuItem value={team.id}>
                                            {team.name}
                                        </MenuItem>
                                    ))}
                                </CustomTextField>
                            </Grid>

                            <Grid item xs={6}>
                                <CustomTextField
                                    fullWidth={true}
                                    select
                                    label="Project Name*"
                                    variant="outlined"
                                    style={{}}
                                    id="projectId"
                                    name="projectId"
                                    value={formik.values.projectId}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.projectId
                                            ? Boolean(formik.errors.projectId)
                                            : false
                                    }
                                    helperText={
                                        formik.touched.projectId &&
                                        formik.errors.projectId
                                    }
                                >
                                    {projectsList?.length === 0 && (
                                        <MenuItem value={undefined}>
                                            No Data found
                                        </MenuItem>
                                    )}
                                    {projectsList?.map((team) => (
                                        <MenuItem value={team.id}>
                                            {team.name}
                                        </MenuItem>
                                    ))}
                                </CustomTextField>
                            </Grid>

                            <Grid item xs={6}>
                                <CustomTextField
                                    label="City*"
                                    select
                                    // style={{ marginBottom: '16px' }}
                                    id="cityId"
                                    name="cityId"
                                    value={formik.values.cityId}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.cityId
                                            ? Boolean(formik.errors.cityId)
                                            : false
                                    }
                                    helperText={
                                        formik.touched.cityId &&
                                        formik.errors.cityId
                                    }
                                >
                                    {cityList?.length === 0 && (
                                        <MenuItem value={undefined}>
                                            No Data found
                                        </MenuItem>
                                    )}
                                    {cityList?.map((city) => (
                                        <MenuItem value={city.id}>
                                            {city.name}
                                        </MenuItem>
                                    ))}
                                </CustomTextField>
                            </Grid>

                            <Grid item xs={6}>
                                <CustomTextField
                                    label="Status*"
                                    select
                                    fullWidth={true}
                                    value={formik.values.evVehicleStatusId}
                                    onChange={formik.handleChange}
                                    defaultValue=""
                                    id="evVehicleStatusId"
                                    name="evVehicleStatusId"
                                    style={{
                                        marginBottom: 8,
                                        width: '100%',
                                        marginRight: 5,
                                    }}
                                    displayEmpty={true}
                                    error={
                                        formik.touched.evVehicleStatusId
                                            ? Boolean(
                                                  formik?.errors
                                                      ?.evVehicleStatusId,
                                              )
                                            : false
                                    }
                                    helperText={
                                        formik.touched.evVehicleStatusId
                                            ? formik.errors.evVehicleStatusId
                                            : ''
                                    }
                                >
                                    {EVVEHICLE_STATUS_LIST?.length &&
                                        EVVEHICLE_STATUS_LIST.map(
                                            (evStatus, index) => (
                                                <StyledMenuItem
                                                    key={index}
                                                    value={evStatus?.id}
                                                    disabled={
                                                        evStatus?.status ==
                                                        'TAGGED'
                                                            ? true
                                                            : evStatus?.status ==
                                                                  'UNTAGGED' &&
                                                              !!isEdit
                                                            ? true
                                                            : !isEdit &&
                                                              disabledStatus.includes(
                                                                  evStatus?.status,
                                                              )
                                                    }
                                                >
                                                    {evStatus?.label}
                                                </StyledMenuItem>
                                            ),
                                        )}
                                </CustomTextField>
                            </Grid>
                        </Grid>

                        <Divider style={{ marginTop: 8 }} />
                        {!!actions && (
                            <DialogActions>
                                {actions.map((action, index) => (
                                    <Box key={index}>{action}</Box>
                                ))}
                            </DialogActions>
                        )}
                    </DialogContent>
                </form>
            </Spinner>
        </Dialog>
    );
}

const mapStateToProps = (state) => ({
    statesList: state.settings?.statesList ?? [],
    cityList: state.settings?.cities ?? [],
    vendorList: state.settings?.evVendorList ?? [],
    teamList: state.settings?.evTeamList ?? [],
    projectsList: state.settings?.evProjectList ?? [],
    captainList: state.settings?.evCaptainList ?? [],
});

const mapDispatchToProps = (dispatch) => ({
    dispatchCreateEvVehicle: (payload, onSuccess, onError) =>
        dispatch(createEvVehicle(payload, onSuccess, onError)),
    dispatchEditEvVehicle: (payload, onSuccess, onError) =>
        dispatch(editEvVehicle(payload, onSuccess, onError)),
    dispatchGetCities: (payload, onSuccess, onError) =>
        dispatch(GetCities(payload, onSuccess, onError)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EVModal);
