import React, { useState, useEffect } from 'react';
import './styles.scss';
import { connect } from 'react-redux';
import {
    createStaff,
    getAllStaffs,
    getAllStaffsReport,
    getHubList,
    getShiftTimingsList,
    getV2AllStaffs,
    getV2AllStaffsReport,
    resetKycDetails,
} from '../../store/actions/staffActionCreator';

import {
    Button,
    Paper,
    Box,
    Typography,
    makeStyles,
    IconButton,
    Tooltip,
    TablePagination,
} from '@material-ui/core';
// import { makeStyles } from '@material-ui/core/styles';

import AllStaffListContainer from '../../components/Containers/AllStaffListContainer';
import Searchbar from '../../components/Searchbar';
import AddEditStaffModal from '../../components/Modals/AddEditStaffModal';
import UploadPayEarnModal from '../../components/Modals/UploadPayEarnModal';
import { Spinner } from '../../components/UI';
import EmptyStates from '../../components/EmptyStates';
import {
    formatCurrency,
    getActiveInitRoute,
    getPermissions,
} from '../../utils/helper';
import { EmptyIcon } from '../../assets/svg';
import GetAppIcon from '@material-ui/icons/GetApp';
import FilterModal, {
    FilteredState,
    FILTER_INPUT_TYPE,
} from '../../components/Modals/FilterModal';
import { HUB_TYPE_ENUM } from '../../utils/constants';
import { useDebounce } from '../../hooks/useDebounce';

const useStyles = makeStyles((theme) => ({
    cardSubHeader: {
        fontSize: '22px',
        fontWeight: 700,
    },
    cardHeader: {
        fontSize: '16px',
        fontWeight: 600,
    },
    verticalCardContents: {
        flex: '1 1 auto',
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        justifyContent: 'center',
        border: 'solid 1px #ebedf8',
        borderRadius: '3.8px',
        backgroundColor: '#ebedf8',
        paddingLeft: '16px',
        height: '88px',
        margin: '0 8px 0 0 ',
    },
    verticalCardContentsActive: {
        backgroundColor: '#d1d8ff',
    },
    verticalCardContentsNoMargin: {
        margin: 0,
    },
}));

const Staff = ({
    isEnabledXUtil,
    externalFiltered,
    renderHubFlow,
    staff,
    orgName,
    orgId,
    configs,
    getAllStaffs,
    getV2AllStaffs,
    dispatchResetKycDetails,
    dispatchGetAllStaffsReport,
    dispatchGetV2AllStaffsReport,
    dispatchGetShiftTimingsList,
    dispatchGetHubList,
    isPinCodeOrg,
    ...props
}) => {
    const { debounce, isBouncing } = useDebounce();
    const classes = useStyles();

    const [page, setPage] = React.useState(props.location?.state?.page ?? 0);
    const [rowsPerPage, setRowsPerPage] = React.useState(
        props?.location?.state?.rowsPerPage ?? 10,
    );

    const [firstRenderDone, setFirstRenderDone] = useState(false);

    const [activeCard, setActiveCard] = useState(0);
    const [staffModalOpen, setStaffModalOpen] = useState(false);

    const [paymentModalOpen, setPaymentModalOpen] = useState(false);
    const [earningsModalOpen, setEarningsModalOpen] = useState(false);
    const [bulkstaffUploadModalOpen, setBulkstaffUploadModalOpen] =
        useState(false);
    const [loading, setLoading] = useState(true);
    const [defaultList, setDefaultList] = useState(staff.lineItems);
    const [searchVal, setSearchVal] = useState(
        props.location?.state?.searchVal ?? '',
    );
    const [permissions, setPermissions] = useState(getPermissions('staff'));

    const [staffListingPayload, setStaffListingPayload] = React.useState({
        filterStatus:
            activeCard === 2
                ? 'PENDING PAYMENTS'
                : activeCard === 1
                ? 'KYC PENDING'
                : activeCard === 0
                ? ''
                : '',
        pageNo: page + 1,
        pageSize: rowsPerPage,
        searchText: searchVal,
        ...externalFiltered,
        hubIds: externalFiltered?.hubIds?.map((hub) => hub.value),
    });

    const [filteredValues, setFilteredValue] = useState({
        // ...externalFiltered,
    });

    useEffect(() => {
        setFirstRenderDone(true);
        if (!permissions.read) {
            props.history.push(`/${getActiveInitRoute()}`);
        }
        dispatchGetHubList(
            orgId,
            (resp) => {
                console.log('dispatchGetHubList success', resp);
            },
            (err) => {
                console.log('dispatchGetHubList error', err);
            },
        );
        // getAllStaffInfo(staffListingPayload);
        dispatchResetKycDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setDefaultList(staff?.lineItems);
    }, [staff?.lineItems]);

    useEffect(async () => {
        await setPermissions(getPermissions('staff'));
        if (!permissions.read) {
            props.history.push(`/${getActiveInitRoute()}`);
        }
    }, [configs]);

    const getAllStaffInfo = async (payload = {}) => {
        await setLoading(true);
        const fetchStaff = isEnabledXUtil ? getV2AllStaffs : getAllStaffs;
        fetchStaff(
            payload,
            (resp) => {
                setLoading(false);
                console.log('staff data', resp);
            },
            (resp) => {
                setLoading(false);
                console.log('staff data err', resp);
            },
        );
    };

    const handleChangePage = (event, newPage) => {
        console.log('SETPAGE ', newPage);
        setPage(newPage);
        setStaffListingPayload((prev) => {
            return {
                ...prev,
                pageNo: newPage + 1,
            };
        });
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
        setStaffListingPayload((prev) => {
            return {
                ...prev,
                pageNo: 1,
                pageSize: event.target.value,
            };
        });
    };

    const prepareFilterOptions = () => {
        let list = [
            {
                type: FILTER_INPUT_TYPE.MULTISELECT,
                label: 'Hubs',
                name: 'hubIds',
                value: filteredValues?.hubIds,
                options: staff?.hubs?.map(
                    (item) =>
                        ({
                            label: item.name,
                            value: item.id,
                        } ?? []),
                ),
                onChange: async (e) => {
                    setFilteredValue({
                        ...filteredValues,
                        hubIds: e?.target?.value,
                    });
                },
            },
            {
                type: FILTER_INPUT_TYPE.MULTISELECT,
                label: 'Shifts',
                name: 'shifts',
                value: filteredValues?.shifts,
                options: staff?.shifts?.map(
                    (item) =>
                        ({
                            label: item?.shortName,
                            value: item?.id,
                        } ?? []),
                ),
                onChange: (e) => {
                    setFilteredValue({
                        ...filteredValues,
                        shifts: e?.target?.value,
                    });
                },
            },
        ];
        if (!!renderHubFlow) {
            delete list[0];
        }
        return list;
    };

    const downloadReport = isEnabledXUtil
        ? dispatchGetV2AllStaffsReport
        : dispatchGetAllStaffsReport;

    React.useEffect(() => {
        console.log('staffListingPayload changed', staffListingPayload);
        getAllStaffInfo(staffListingPayload);
    }, [staffListingPayload]);

    React.useEffect(() => {
        console.log('filteredValues changed', filteredValues);
        let hubIds = filteredValues?.hubIds?.map((hub, index) => hub.value);
        dispatchGetShiftTimingsList(
            {
                hubIds: hubIds?.length > 0 ? hubIds : null,
            },
            (resp) => {
                console.log('resp', resp);
            },
            (err) => {
                console.log('err', err);
            },
        );
    }, [filteredValues?.hubIds]);

    React.useEffect(() => {
        console.log('getting listing', staffListingPayload);
        if (firstRenderDone)
            setStaffListingPayload((prev) => {
                return {
                    ...prev,
                    pageNo: 1,
                    searchText: searchVal,
                };
            });
    }, [searchVal]);

    const evaluateStaffDumpReport = () => {
        let enableDownload = true;
        if (!!isPinCodeOrg) {
            return true;
        }

        let filteredHubIds = filteredValues?.hubIds?.map((hbo) => hbo?.value);

        if (filteredValues?.hubIds?.length > 0) {
            staff?.hubs?.map((sh) => {
                if (
                    filteredHubIds?.includes(sh?.id) &&
                    sh?.primaryOperation == HUB_TYPE_ENUM.FOOD.value
                ) {
                    enableDownload = false;
                }
            });
        } else {
            staff?.hubs?.map((sh) => {
                if (sh?.primaryOperation == HUB_TYPE_ENUM.FOOD.value) {
                    enableDownload = false;
                }
            });
        }
        return enableDownload;
    };
    return (
        <div>
            {/* <TitleHeader title={'Manage Staff'} isBackButton={false} /> */}

            <Spinner loading={loading}>
                {!renderHubFlow && (
                    <Paper className="cardStyles bottom-mar" elevation={0}>
                        <Box className="horizontalCardContents">
                            <Box
                                className={`${classes.verticalCardContents} ${
                                    activeCard === 0 &&
                                    classes.verticalCardContentsActive
                                }`}
                                onClick={() => {
                                    setActiveCard(0);
                                    setPage(0);
                                    setStaffListingPayload((prev) => {
                                        return {
                                            ...prev,
                                            filterStatus: '',
                                            pageNo: 1,
                                            pageSize: rowsPerPage,
                                            searchText: searchVal,
                                        };
                                    });
                                }}
                            >
                                <Typography className={classes.cardHeader}>
                                    Total Staffs
                                </Typography>
                                <Typography className={classes.cardSubHeader}>
                                    {staff?.totalCount ?? 0}
                                </Typography>
                            </Box>
                            <Box
                                className={`${classes.verticalCardContents} ${
                                    activeCard === 1 &&
                                    classes.verticalCardContentsActive
                                }`}
                                onClick={() => {
                                    setActiveCard(1);
                                    setPage(0);
                                    setStaffListingPayload((prev) => {
                                        return {
                                            ...prev,
                                            filterStatus: 'KYC PENDING',
                                            pageNo: 1,
                                            pageSize: rowsPerPage,
                                            searchText: searchVal,
                                        };
                                    });
                                }}
                            >
                                <Typography className={classes.cardHeader}>
                                    KYC Pending
                                </Typography>
                                <Typography className={classes.cardSubHeader}>
                                    {staff?.kycPendingCount ?? 0}
                                </Typography>
                            </Box>
                            <div
                                className={`${classes.verticalCardContents} ${
                                    classes.verticalCardContentsNoMargin
                                } ${
                                    activeCard === 2 &&
                                    classes.verticalCardContentsActive
                                }`}
                                onClick={() => {
                                    setActiveCard(2);
                                    setPage(0);
                                    setStaffListingPayload((prev) => {
                                        return {
                                            ...prev,
                                            filterStatus: isEnabledXUtil
                                                ? 'VERIFIED'
                                                : 'Pending Payments',
                                            pageNo: 1,
                                            pageSize: rowsPerPage,
                                            searchText: searchVal,
                                        };
                                    });
                                }}
                            >
                                <Typography className={classes.cardHeader}>
                                    {isEnabledXUtil
                                        ? 'KYC Completed'
                                        : 'Pending Payments'}
                                </Typography>
                                <Typography className={classes.cardSubHeader}>
                                    {isEnabledXUtil
                                        ? staff?.kycCompletedCount ?? 0
                                        : formatCurrency(
                                              staff?.pendingPayments,
                                          ) ?? 0}
                                </Typography>
                            </div>
                        </Box>
                    </Paper>
                )}

                {staff?.totalCount ? (
                    <Paper style={{ padding: '24px 0 0 0' }} elevation={0}>
                        <Box
                            className="horiz-end"
                            style={{
                                margin: '24px 16px 16px 24px',
                                marginTop: !renderHubFlow && 0,
                            }}
                        >
                            <Box
                                className="horiz-end"
                                style={{
                                    width: !!renderHubFlow ? '50%' : '400px',
                                }}
                            >
                                <Searchbar
                                    placeHolderText="Search by Name, Hub Name, Contact No."
                                    // placeHolderStyles={{
                                    //     color: '#979797',
                                    // }}
                                    backendSearchbar={true}
                                    onBackendSearch={(searchText) => {
                                        setPage(0);
                                        console.log(
                                            'staffListingPayload',
                                            staffListingPayload,
                                        );
                                        setSearchVal(searchText);
                                    }}
                                    value={searchVal}
                                    keys={['name', 'hubName']}
                                    onFilter={async (filteredData) => {}}
                                    onChange={(value) => {
                                        console.log('blur', value);
                                        setSearchVal(value);
                                    }}
                                />
                                <FilterModal
                                    filterOptions={prepareFilterOptions()}
                                    filteredValues={filteredValues}
                                    onFilterApplied={() => {
                                        setStaffListingPayload((prev) => {
                                            return {
                                                ...prev,
                                                hubIds:
                                                    filteredValues?.hubIds?.map(
                                                        (hub) => hub?.value,
                                                    ) ?? null,
                                                shiftIds:
                                                    filteredValues?.shifts?.map(
                                                        (shift) => shift?.value,
                                                    ) ?? null,
                                            };
                                        });
                                    }}
                                />
                            </Box>

                            {!renderHubFlow && (
                                <Box>
                                    <Tooltip
                                        title="Download Report"
                                        placement="top"
                                    >
                                        <IconButton
                                            className={'inputbutton'}
                                            variant="outlined"
                                            style={{
                                                marginRight: 8,
                                                border: '1px solid #43419e',
                                                borderColor: true
                                                    ? 'lightgrey'
                                                    : '#43419e',
                                                borderRadius: 8,
                                                padding: 6,
                                            }}
                                            color="primary"
                                            onClick={(e) => {
                                                debounce(() => {
                                                    let enableDownload =
                                                        evaluateStaffDumpReport();
                                                    if (enableDownload) {
                                                        downloadReport(
                                                            {
                                                                hubIds:
                                                                    filteredValues?.hubIds?.map(
                                                                        (hub) =>
                                                                            hub?.value,
                                                                    ) ?? null,
                                                                shiftIds:
                                                                    filteredValues?.shifts?.map(
                                                                        (
                                                                            shift,
                                                                        ) =>
                                                                            shift?.value,
                                                                    ) ?? null,
                                                            },
                                                            (response) => {
                                                                console.log(
                                                                    'GetStaffReport Success',
                                                                    response,
                                                                );
                                                                if (
                                                                    response
                                                                        ?.response
                                                                        ?.s3FileUrl
                                                                ) {
                                                                    window.location.href =
                                                                        response?.response?.s3FileUrl;
                                                                }
                                                            },
                                                            (response) => {
                                                                console.log(
                                                                    'GetStaffReport FAILED',
                                                                    response,
                                                                );
                                                            },
                                                        );
                                                    }
                                                });
                                            }}
                                            disabled={
                                                isBouncing ||
                                                !evaluateStaffDumpReport()
                                            }
                                            // disabled={
                                            //     !defaultList?.length &&
                                            //     !permissions.read
                                            // }
                                        >
                                            <GetAppIcon size="small" />
                                        </IconButton>
                                    </Tooltip>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        className="btn-pos"
                                        onClick={() => {
                                            // toggleStaffModal();
                                            setEarningsModalOpen(true);
                                        }}
                                        disabled={!permissions.create}
                                        disableElevation
                                    >
                                        Upload Earnings
                                    </Button>
                                    {earningsModalOpen && (
                                        <UploadPayEarnModal
                                            enumType="earning"
                                            open={earningsModalOpen}
                                            onClose={() => {
                                                // console.log('Closed');
                                                setEarningsModalOpen(false);
                                            }}
                                        />
                                    )}
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        className="btn-pos"
                                        onClick={() => {
                                            // toggleStaffModal();
                                            setPaymentModalOpen(true);
                                        }}
                                        disabled={!permissions.create}
                                        disableElevation
                                    >
                                        Upload Payments
                                    </Button>
                                    {paymentModalOpen && (
                                        <UploadPayEarnModal
                                            enumType="payment"
                                            open={paymentModalOpen}
                                            onClose={() => {
                                                // console.log('Closed');
                                                setPaymentModalOpen(false);
                                            }}
                                        />
                                    )}
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        className="btn-pos"
                                        onClick={() => {
                                            setBulkstaffUploadModalOpen(true);
                                        }}
                                        disabled={!permissions.create}
                                        disableElevation
                                    >
                                        Upload Staffs
                                    </Button>
                                    {bulkstaffUploadModalOpen && (
                                        <UploadPayEarnModal
                                            enumType="staffs"
                                            open={bulkstaffUploadModalOpen}
                                            onClose={() => {
                                                getAllStaffInfo(
                                                    staffListingPayload,
                                                );
                                                setBulkstaffUploadModalOpen(
                                                    false,
                                                );
                                            }}
                                        />
                                    )}
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        className="btn-pos-end"
                                        onClick={() => {
                                            // toggleStaffModal();
                                            setStaffModalOpen(true);
                                        }}
                                        disabled={!permissions.create}
                                        disableElevation
                                    >
                                        Add Staff
                                    </Button>
                                    {staffModalOpen && (
                                        <AddEditStaffModal
                                            open={staffModalOpen}
                                            onClose={() => {
                                                console.log('Closed');
                                                // getAllStaffInfo();
                                                setStaffModalOpen(false);
                                                getAllStaffInfo(
                                                    staffListingPayload,
                                                );
                                            }}
                                        />
                                    )}
                                </Box>
                            )}
                        </Box>
                        <FilteredState
                            filterOptions={prepareFilterOptions()}
                            filteredValues={filteredValues}
                            onDelete={(filterKey) => {
                                let newFilteredValues = { ...filteredValues };
                                delete newFilteredValues?.[filterKey];
                                setFilteredValue(newFilteredValues);
                                setStaffListingPayload((prev) => {
                                    return {
                                        ...prev,
                                        hubIds:
                                            newFilteredValues?.hubIds?.map(
                                                (hub) => hub?.value,
                                            ) ?? null,
                                        shiftIds:
                                            newFilteredValues?.shifts?.map(
                                                (shift) => shift?.value,
                                            ) ?? null,
                                    };
                                });
                            }}
                        />
                        {staff?.totalCount == 0 ? (
                            <Box
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <EmptyIcon />
                            </Box>
                        ) : (
                            <Box style={{ margin: '0 24px 0 24px' }}>
                                <AllStaffListContainer
                                    getAllStaffInfo={() => {
                                        getAllStaffInfo(staffListingPayload);
                                    }}
                                    permissions={permissions}
                                    data={staff?.lineItems}
                                    staffListingPayload={staffListingPayload}
                                    renderHubFlow={renderHubFlow}
                                />
                                <TablePagination
                                    rowsPerPageOptions={[10, 50, 100]}
                                    component="div"
                                    count={staff?.totalFilteredCount}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onChangePage={handleChangePage}
                                    onChangeRowsPerPage={
                                        handleChangeRowsPerPage
                                    }
                                />
                            </Box>
                        )}
                    </Paper>
                ) : (
                    !loading && (
                        <Box
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <EmptyStates
                                type="Staff"
                                header1={'Add New Staff to Your Company'}
                                subheader1={
                                    'Click on Add Staff to onboard your first Employee.'
                                }
                                buttonDisplay={true}
                                // getAllStaffInfo={getAllStaffInfo}
                            />
                        </Box>
                    )
                )}
            </Spinner>
        </div>
    );
};

const mapStateToProps = (state) => ({
    staff: state.staff,
    user: state.user,
    configs: state.user.config,
    isEnabledXUtil: state?.user?.config?.isEnabledXUtil,
    orgName: state?.settings.basicDetails?.name,
    orgId: state?.settings?.basicDetails?.id,
    isPinCodeOrg:
        state?.settings?.basicDetails?.vendorName == 'PINCODE' ?? false,
});

const mapDispatchToProps = (dispatch) => ({
    dispatchCreateStaff: (payload, onSuccess, onError) =>
        dispatch(createStaff(payload, onSuccess, onError)),
    getAllStaffs: (payload, onSuccess, onError) =>
        dispatch(getAllStaffs(payload, onSuccess, onError)),
    getV2AllStaffs: (payload, onSuccess, onError) =>
        dispatch(getV2AllStaffs(payload, onSuccess, onError)),
    dispatchGetShiftTimingsList: (data, onSuccess, onError) =>
        dispatch(getShiftTimingsList(data, onSuccess, onError)),
    dispatchGetAllStaffsReport: (payload, onSuccess, onError) =>
        dispatch(getAllStaffsReport(payload, onSuccess, onError)),
    dispatchGetV2AllStaffsReport: (payload, onSuccess, onError) =>
        dispatch(getV2AllStaffsReport(payload, onSuccess, onError)),
    dispatchGetHubList: (id, onSuccess, onError) =>
        dispatch(getHubList(id, onSuccess, onError)),

    dispatchResetKycDetails: () => dispatch(resetKycDetails()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Staff);
