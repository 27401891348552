import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    makeStyles,
    MenuItem,
    Slide,
    Typography,
    useMediaQuery,
    useTheme,
    withStyles,
} from '@material-ui/core';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { CustomTextField } from '../Textfield';
import { Spinner, FileUploader } from '../UI';
import {
    GetCityByState,
    GetStates,
    createCustomer,
    editCustomer,
} from '../../store/actions/settingsActionCreator';
import { connect } from 'react-redux';
import { useFormik } from 'formik';
import * as yup from 'yup';

const validationSchema = yup.object({
    clientName: yup
        .string('Invalid Client Name')
        .trim()
        .required('Client Name cannot be empty'),
    contactName: yup.string('Invalid Name').trim().nullable(),
    contactNumber: yup
        .string('Invalid Number')
        .min(10, 'Must be exactly 10 digits')
        .max(10, 'Must be exactly 10 digits')
        .matches(/^[0-9]+$/, 'Must be only digits')
        .nullable(),
    // addressLine: yup.string('Invalid Address'),

    // cityId: yup.string('City is invalid').required('City is required'),
    // stateId: yup.string('State is invalid').required('State is required'),
});

const useStyles = makeStyles((theme) => ({
    modal: {
        '& .modal-head': {
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
        },
        '& .modal-title': {
            fontSize: '24px',
            fontWeight: 'bold',
            color: '#14112d',
        },
        '& .modal-subtitle': {
            fontSize: '14px',
            fontWeight: 'normal',
            color: '#14112d',
        },

        '& .cancelButton': {
            color: theme.palette.text.primary,
        },
        '& .leaflet-container': {
            width: '100%',
            height: '510px',
            // borderRadius: 16,
            borderTopRightRadius: 16,
            borderBottomRightRadius: 16,
        },
    },
    iconButton: {
        padding: '0px',
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const StyledMenuItem = withStyles((theme) => ({
    root: {
        margin: '0 8px',
        border: '0 !important',
        borderRadius: '8px',
        '&:hover': {
            backgroundColor: 'rgba(67, 65, 158, 0.08)',
            color: '#43419e',
            borderRadius: '8px',
        },
    },
}))(MenuItem);

function ClientModal({
    open,
    onClose,
    maxWidth,
    defaultValue = {},
    statesList,
    cityList,
    vendorList,
    dispatchCreateCustomer,
    dispatchEditCustomer,
    dispatchGetStates,
    dispatchGetCityByState,
    ...props
}) {
    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [isOpen, toggleModal] = useState(open);
    const [loading, toggleLoading] = useState(false);

    const [panCardDoc, setPanCardDoc] = useState('');
    const [contractDoc, setContractDoc] = useState('');

    useEffect(() => {
        console.log('defaultValue', defaultValue);
        setPanCardDoc(defaultValue?.panCardLink);
        setContractDoc(defaultValue?.contractLink);

        if (!statesList?.length) fetchStates();
        if (defaultValue?.stateId) fetchCities(defaultValue?.stateId);
    }, []);

    const fetchStates = async () => {
        await toggleLoading(true);
        dispatchGetStates(
            {},
            (resp) => {
                console.log('success', resp);
                toggleLoading(false);
            },
            (err) => {
                toggleLoading(false);
            },
        );
    };
    const fetchCities = async (stateid) => {
        await toggleLoading(true);
        dispatchGetCityByState(
            stateid,
            (resp) => {
                toggleLoading(false);
            },
            (err) => {
                toggleLoading(false);
            },
        );
    };

    const formik = useFormik({
        initialValues: {
            // customerId: defaultValue?.id,
            clientName: defaultValue?.name ?? '',
            contactName: defaultValue?.contactName ?? '',
            contactNumber: defaultValue?.contactNumber ?? '',
            addressLine: defaultValue?.addressLine ?? '',
            stateId: defaultValue?.stateId ?? '',
            cityId: defaultValue?.cityId ?? '',
            panCardNumber: defaultValue?.panCardNumber ?? '',
            gstNumber: defaultValue?.gstNumber ?? '',
            ...defaultValue,
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            await toggleLoading(true);
            console.log('ONSUBMIT', {
                customerId: formik.values?.customerId,
                clientName: formik.values?.clientName,
            });
            if (Object.keys(defaultValue)?.length) {
                dispatchEditCustomer(
                    {
                        customerId: formik.values?.customerId,
                        aliasName: formik.values?.clientName,
                        addressLine: formik.values?.addressLine,
                        stateId: formik.values?.stateId,
                        cityId: formik.values?.cityId,
                        contactName: formik.values?.contactName,
                        contactNumber: formik.values?.contactNumber,
                        panCardNumber: formik.values?.panCardNumber,
                        panCardLink: panCardDoc,
                        gstNumber: formik.values?.gstNumber,
                        contractLink: contractDoc,
                    },
                    async (resp) => {
                        await toggleLoading(false);
                        handleClose();
                    },
                    async (resp) => {
                        await toggleLoading(false);
                        // handleClose();
                    },
                );
            } else {
                dispatchCreateCustomer(
                    {
                        customerId: formik.values?.customerId,
                        aliasName: formik.values?.clientName,
                        addressLine: formik.values?.addressLine,
                        stateId: formik.values?.stateId,
                        cityId: formik.values?.cityId,
                        contactName: formik.values?.contactName,
                        contactNumber: formik.values?.contactNumber,
                        panCardNumber: formik.values?.panCardNumber,
                        panCardLink: panCardDoc,
                        gstNumber: formik.values?.gstNumber,
                        contractLink: contractDoc,
                    },
                    async (resp) => {
                        await toggleLoading(false);
                        handleClose();
                    },
                    async (resp) => {
                        await toggleLoading(false);
                        // handleClose();
                    },
                );
            }
        },
    });

    const handleClose = () => {
        toggleModal(!isOpen);
        onClose && onClose();
    };

    const actions = [
        <Button
            key={1}
            variant="contained"
            color="default"
            className={`cancelButton`}
            onClick={handleClose}
            disableElevation
        >
            {'Cancel'}
        </Button>,
        <Button
            key={2}
            variant="contained"
            color="primary"
            className={`saveButton`}
            type="submit"
            disableElevation
        >
            {Object.keys(defaultValue)?.length ? 'Update Client' : 'Add Client'}
        </Button>,
    ];

    useEffect(() => {
        console.log('formik.values', formik.values);
    }, [formik.values]);

    return (
        <Dialog
            open={isOpen}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            className={classes.modal}
            fullScreen={fullScreen}
            fullWidth={true}
            maxWidth={maxWidth ?? 'sm'}
            disableBackdropClick
        >
            <Spinner loading={loading}>
                <form onSubmit={formik.handleSubmit}>
                    {/* <Grid container> */}
                    {/* <Grid item xs={6}> */}
                    <DialogTitle id="alert-dialog-slide-title">
                        <Box className={`modal-head`}>
                            <div>
                                <Typography
                                    className={`modal-title`}
                                    variant="h6"
                                >
                                    {Object.keys(defaultValue)?.length
                                        ? 'Edit Client'
                                        : 'Add Client'}
                                </Typography>
                                <Typography
                                    className={`modal-subtitle`}
                                    variant="body2"
                                >
                                    {Object.keys(defaultValue)?.length
                                        ? 'Edit Client'
                                        : 'Add New Client'}
                                </Typography>
                            </div>
                            <div
                                style={{
                                    alignSelf: 'center',
                                    justifySelf: 'flex-end',
                                }}
                            >
                                <IconButton
                                    aria-label="delete"
                                    className={classes.iconButton}
                                    onClick={handleClose}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </div>
                        </Box>
                    </DialogTitle>
                    <DialogContent>
                        {/* {vendorList?.length > 0 ? (
                            <CustomTextField
                                label="Client Name"
                                select
                                fullWidth={true}
                                id="customerId"
                                name="customerId"
                                value={formik.values?.customerId ?? ''}
                                onChange={formik.handleChange}
                                style={{ marginBottom: '16px' }}
                                disabled={
                                    Object?.keys(defaultValue)?.length
                                        ? true
                                        : false
                                }
                            >
                                {vendorList?.map((vendor, index) => (
                                    <StyledMenuItem
                                        key={index}
                                        value={vendor?.customerId}
                                    >
                                        {vendor?.clientName}
                                    </StyledMenuItem>
                                ))}
                            </CustomTextField>
                        ) : (
                            <CustomTextField
                                label="Client Name"
                                select
                                fullWidth={true}
                                value={formik.values?.customerId ?? ''}
                                onChange={formik.handleChange}
                                style={{ marginBottom: '16px' }}
                            >
                                <StyledMenuItem key={1} value={'hub.customerId'}>
                                    No Data Found
                                </StyledMenuItem>
                            </CustomTextField>
                        )}
                        <CustomTextField
                            fullWidth={true}
                            label="Custom Client Name"
                            variant="outlined"
                            style={{ marginBottom: '16px' }}
                            id="clientName"
                            name="clientName"
                            value={formik.values.clientName}
                            onChange={formik.handleChange}
                            error={
                                formik.touched.clientName
                                    ? Boolean(formik.errors.clientName)
                                    : false
                            }
                            helperText={
                                formik.touched.clientName && formik.errors.clientName
                            }
                        /> */}

                        <Grid container>
                            <Grid item xs={12}>
                                <CustomTextField
                                    fullWidth={true}
                                    label="Client Name*"
                                    variant="outlined"
                                    style={{ marginBottom: '16px' }}
                                    id="clientName"
                                    name="clientName"
                                    value={formik.values.clientName}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.clientName
                                            ? Boolean(formik.errors.clientName)
                                            : false
                                    }
                                    helperText={
                                        formik.touched.clientName &&
                                        formik.errors.clientName
                                    }
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <CustomTextField
                                    fullWidth={true}
                                    label="Contact Name*"
                                    variant="outlined"
                                    style={{
                                        marginBottom: '16px',
                                        width: '98%',
                                        marginRight: 5,
                                    }}
                                    id="contactName"
                                    name="contactName"
                                    value={formik.values.contactName}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.contactName
                                            ? Boolean(formik.errors.contactName)
                                            : false
                                    }
                                    helperText={
                                        formik.touched.contactName &&
                                        formik.errors.contactName
                                    }
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <CustomTextField
                                    fullWidth={true}
                                    label="Contact Number*"
                                    variant="outlined"
                                    style={{ marginBottom: '16px' }}
                                    id="contactNumber"
                                    name="contactNumber"
                                    value={formik.values.contactNumber}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.contactNumber
                                            ? Boolean(
                                                  formik.errors.contactNumber,
                                              )
                                            : false
                                    }
                                    helperText={
                                        formik.touched.contactNumber &&
                                        formik.errors.contactNumber
                                    }
                                    inputProps={{ maxLength: 10 }}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <CustomTextField
                                    fullWidth={true}
                                    label="Address Line 1"
                                    variant="outlined"
                                    style={{ marginBottom: '16px' }}
                                    id="addressLine"
                                    name="addressLine"
                                    value={formik.values.addressLine}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.addressLine
                                            ? Boolean(formik.errors.addressLine)
                                            : false
                                    }
                                    helperText={
                                        formik.touched.addressLine &&
                                        formik.errors.addressLine
                                    }
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <CustomTextField
                                    label="State"
                                    select
                                    style={{
                                        marginBottom: '16px',
                                        width: '98%',
                                        marginRight: 5,
                                    }}
                                    id="stateId"
                                    name="stateId"
                                    value={formik.values.stateId}
                                    onChange={(e) => {
                                        formik.handleChange(e);
                                        fetchCities(e.target.value);
                                    }}
                                    error={
                                        formik.touched.stateId
                                            ? Boolean(formik.errors.stateId)
                                            : false
                                    }
                                    helperText={
                                        formik.touched.stateId &&
                                        formik.errors.stateId
                                    }
                                >
                                    {statesList?.length === 0 && (
                                        <MenuItem value={undefined}>
                                            No Data found
                                        </MenuItem>
                                    )}
                                    {statesList?.map((state) => (
                                        <MenuItem value={state.id}>
                                            {state.name}
                                        </MenuItem>
                                    ))}
                                </CustomTextField>
                            </Grid>
                            <Grid item xs={6}>
                                <CustomTextField
                                    label="City"
                                    select
                                    style={{ marginBottom: '16px' }}
                                    id="cityId"
                                    name="cityId"
                                    value={formik.values.cityId}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.cityId
                                            ? Boolean(formik.errors.cityId)
                                            : false
                                    }
                                    helperText={
                                        formik.touched.cityId &&
                                        formik.errors.cityId
                                    }
                                >
                                    {cityList?.length === 0 && (
                                        <MenuItem value={undefined}>
                                            No Data found
                                        </MenuItem>
                                    )}
                                    {cityList?.map((city) => (
                                        <MenuItem value={city.id}>
                                            {city.name}
                                        </MenuItem>
                                    ))}
                                </CustomTextField>
                            </Grid>

                            <Grid item xs={6}>
                                <CustomTextField
                                    fullWidth={true}
                                    label="Pan Card Number"
                                    variant="outlined"
                                    style={{
                                        marginBottom: '16px',
                                        width: '98%',
                                        marginRight: 5,
                                    }}
                                    id="panCardNumber"
                                    name="panCardNumber"
                                    value={formik.values.panCardNumber}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.panCardNumber
                                            ? Boolean(
                                                  formik.errors.panCardNumber,
                                              )
                                            : false
                                    }
                                    helperText={
                                        formik.touched.panCardNumber &&
                                        formik.errors.panCardNumber
                                    }
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <CustomTextField
                                    fullWidth={true}
                                    label="GST Number"
                                    variant="outlined"
                                    style={{ marginBottom: '16px' }}
                                    id="gstNumber"
                                    name="gstNumber"
                                    value={formik.values.gstNumber}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.gstNumber
                                            ? Boolean(formik.errors.gstNumber)
                                            : false
                                    }
                                    helperText={
                                        formik.touched.gstNumber &&
                                        formik.errors.gstNumber
                                    }
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Box
                                    style={{
                                        marginBottom: '16px',
                                        width: '98%',
                                        marginRight: 5,
                                    }}
                                >
                                    <FileUploader
                                        label={''}
                                        placeholder={'Upload Pan Card'}
                                        onFileUploaded={(url) => {
                                            console.log(url);
                                            setPanCardDoc(url);
                                            // formik.setFieldValue(
                                            //     'staffContractSign',
                                            //     url,
                                            //     true,
                                            // );
                                        }}
                                        onRemoved={() => {
                                            setPanCardDoc('');
                                        }}
                                        defaultValue={
                                            panCardDoc ? panCardDoc : ''
                                        }
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={6}>
                                <FileUploader
                                    label={''}
                                    placeholder={'Upload Contract'}
                                    onFileUploaded={(url) => {
                                        console.log(url);
                                        setContractDoc(url);
                                        // formik.setFieldValue(
                                        //     'staffContractSign',
                                        //     url,
                                        //     true,
                                        // );
                                    }}
                                    onRemoved={() => {
                                        setContractDoc('');
                                    }}
                                    defaultValue={
                                        contractDoc ? contractDoc : ''
                                    }
                                />
                            </Grid>
                        </Grid>

                        <Divider style={{ marginTop: 8 }} />
                        {!!actions && (
                            <DialogActions>
                                {actions.map((action, index) => (
                                    <Box key={index}>{action}</Box>
                                ))}
                            </DialogActions>
                        )}
                    </DialogContent>
                    {/* </Grid> */}
                    {/* </Grid> */}
                </form>
            </Spinner>
        </Dialog>
    );
}

const mapStateToProps = (state) => ({
    statesList: state.settings?.statesList ?? [],
    cityList: state.settings?.cities ?? [],
});

const mapDispatchToProps = (dispatch) => ({
    dispatchCreateCustomer: (payload, onSuccess, onError) =>
        dispatch(createCustomer(payload, onSuccess, onError)),
    dispatchEditCustomer: (payload, onSuccess, onError) =>
        dispatch(editCustomer(payload, onSuccess, onError)),
    dispatchGetStates: (payload, onSuccess, onError) =>
        dispatch(GetStates(payload, onSuccess, onError)),
    dispatchGetCityByState: (stateId, onSuccess, onError) =>
        dispatch(GetCityByState(stateId, onSuccess, onError)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ClientModal);
