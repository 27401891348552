// src/TrackPlayer.tsx
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import {
    IconButton,
    Slider,
    Select,
    MenuItem,
    Tooltip,
    Box,
    Typography,
} from '@material-ui/core';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import PauseIcon from '@material-ui/icons/Pause';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import StopIcon from '@material-ui/icons/Stop';
import ReplayIcon from '@material-ui/icons/Replay';
import FastRewindIcon from '@material-ui/icons/FastRewind';
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
import SkipNextIcon from '@material-ui/icons/SkipNext';
const useStyles = makeStyles((theme) => ({
    root: {
        margin: '8px 0',
        width: '100%',
        maxWidth: 425,
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: 25,
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.secondary,
        '& svg': {
            margin: 0,
        },
        '& hr': {
            margin: theme.spacing(0, 0.5),
        },
        '& .MuiIconButton-root': {
            padding: '4px',
        },
        '& .MuiSelect-outlined.MuiSelect-outlined': {
            padding: '0 8px',
        },
        '& .MuiOutlinedInput-root': {
            borderRadius: '25px !important',
        },
    },
}));
interface TrackPlayerProps {
    onPlay: () => void;
    onPause: () => void;
    onRestart: () => void;
    onStop: () => void;
    onForward: () => void;
    onRewind: () => void;
    onChangeSpeed: (speed: number) => void;
    onChangeSlider: (index: number, value: number) => void;
    sliderValue: any;
    speed: number;
    disablePlay: boolean;
    disablePause: boolean;
}

const TrackPlayer: React.FC<TrackPlayerProps> = ({
    onPlay,
    onPause,
    onRestart,
    onStop,
    onForward,
    onRewind,
    onChangeSpeed,
    onChangeSlider,
    sliderValue,
    speed,
    disablePause = false,
    disablePlay = false,
}) => {
    const classes = useStyles();

    return (
        <Box
            display={'flex'}
            flexDirection={'row'}
            alignItems={'center'}
            justifyContent={'center'}
        >
            <Grid
                container
                alignItems="center"
                justify="space-between"
                className={classes.root}
            >
                <Tooltip title="Rewind" placement="top">
                    <IconButton
                        color="primary"
                        disabled={false}
                        onClick={() => {
                            onRewind();
                        }}
                    >
                        <SkipPreviousIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Play" placement="top">
                    <IconButton
                        color="primary"
                        disabled={disablePlay}
                        onClick={() => {
                            onPlay();
                        }}
                    >
                        <PlayArrowIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Pause" placement="top">
                    <IconButton
                        color="primary"
                        disabled={disablePause}
                        onClick={() => {
                            onPause();
                        }}
                    >
                        <PauseIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Forward" placement="top">
                    <IconButton
                        color="primary"
                        disabled={false}
                        onClick={() => {
                            onForward();
                        }}
                    >
                        <SkipNextIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Stop" placement="top">
                    <IconButton
                        color="primary"
                        disabled={false}
                        onClick={() => {
                            onStop();
                        }}
                    >
                        <StopIcon />
                    </IconButton>
                </Tooltip>
                {/* <Tooltip title="Restart" placement="top">
                <IconButton
                    color="primary"
                    disabled={false}
                    onClick={() => {
                        onRestart();
                    }}
                >
                    <ReplayIcon />
                </IconButton>
            </Tooltip> */}
                <Tooltip title="Speed" placement="top">
                    <Select
                        style={{
                            width: '63px',
                            margin: '0 4px',
                            padding: 0,
                            height: '25px',
                        }}
                        variant="outlined"
                        value={speed}
                        onChange={(e) => onChangeSpeed(Number(e.target.value))}
                    >
                        <MenuItem value={0.5}>0.5x</MenuItem>
                        <MenuItem value={1}>1x</MenuItem>
                        <MenuItem value={2}>2x</MenuItem>
                        <MenuItem value={3}>3x</MenuItem>
                    </Select>
                </Tooltip>
            </Grid>
            <Box>
                {Array.isArray(sliderValue) &&
                    !!sliderValue?.length &&
                    sliderValue?.map((slider, index) => {
                        return (
                            <Box
                                display={'flex'}
                                flexDirection={'row'}
                                ml={'8px'}
                                key={`slider${index}`}
                            >
                                {sliderValue?.length > 1 && !!slider?.name && (
                                    <Typography
                                        variant="body2"
                                        component="span"
                                        style={{
                                            fontWeight: 'bold',
                                            fontSize: 10,
                                            minWidth: '40px',
                                            color:
                                                slider?.sliderNameColor ??
                                                '#000',
                                        }}
                                    >
                                        {`${slider?.name ?? ''}`}
                                    </Typography>
                                )}
                                <Tooltip
                                    title={`${slider?.name ?? ''} Slider`}
                                    placement="right"
                                >
                                    <Slider
                                        style={{
                                            width:
                                                sliderValue?.length < 2
                                                    ? '50px'
                                                    : '120px',
                                            margin: '0 4px',
                                            padding: '4px 0',
                                        }}
                                        max={slider?.max}
                                        min={0}
                                        value={slider?.value}
                                        onChange={(e: any, newValue) =>
                                            onChangeSlider(
                                                index,
                                                Number(newValue),
                                            )
                                        }
                                        aria-labelledby="continuous-slider"
                                    />
                                </Tooltip>
                            </Box>
                        );
                    })}
            </Box>
        </Box>
    );
};

export default TrackPlayer;
