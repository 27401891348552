import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import RouteWithTitle from '../../components/AppRoute/RouteWithTitle';
import Navbar from '../../components/Navbar';
import { Notifier } from '../../components/UI';
import { getLoginUser, logout } from '../../utils/helper';
import './styles.scss';
import store from '../../store/configureStore';

function MainLayout({ path, component, name, containerStyles, ...props }) {
    const user = getLoginUser();

    useEffect(() => {
        console.log('Main Layout:User updated', store.getState().user?.config);
    }, [store.getState().user?.config]);

    if (!user?.token) {
        logout();
        return false;
    }
    return (
        <div className="main-layout">
            {store.getState().user?.config && <Navbar />}
            <div className="container" style={containerStyles ?? {}}>
                {path && component && (
                    <RouteWithTitle
                        key={name}
                        exact={true}
                        path={path}
                        component={component}
                        name={name}
                    />
                )}
            </div>
            <Notifier />
        </div>
    );
}

export default withRouter(MainLayout);
