import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ENV_TYPE } from './config/env/env';
import { ConsoleAction } from './utils/helper';

if (ENV_TYPE === 'PROD') {
    // ConsoleAction(true);
    if (window.location.hostname === 'staffpay.in') {
        const clarityScript = document.createElement('script');
        clarityScript.type = 'text/javascript';
        clarityScript.innerHTML = `(function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
    })(window, document, "clarity", "script", "e2wzbfs7dc");`;
        window.document.head.appendChild(clarityScript);
    }
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
