import { makeStyles } from '@material-ui/core';
import { deepOrange } from '@material-ui/core/colors';

const useSingleEVStyles = makeStyles((theme) => ({
    container: {
        height: 'calc(100vh - 195px)',
        padding: '24px 28px',
        marginTop: '14px',
        overflowY: 'auto',
        paddingBottom: 0,
    },
    infoContainer: {},
    backIcon: {
        minWidth: '0',
        color: '#000000',
        cursor: 'pointer',
        marginRight: '16px',
        alignSelf: ' flex-start',
        marginTop: '6px',
    },
    evNumber: {
        fontSize: '1.5rem',
        fontWeight: 'bold',
        color: '#14112D',
        marginRight: '1.5rem',
    },
    updatedAt: {
        fontSize: '18px',
        fontWeight: 500,
        color: '#14112D',
    },
    batteryPer: {
        fontSize: '18px',
        fontWeight: 400,
        color: '#14112D',
    },
    locationCTA: {
        backgroundColor: '#000000',
        color: '#fff',
        minWidth: 180,
    },
    remoteCTA: {
        backgroundColor: '#fff',
        borderRadius: 8,
        color: '#4CAF50',
        border: '1px solid #4CAF50',
        minWidth: 180,
        fontWeight: 700,
        '&:hover': {
            backgroundColor: '#fff',
        },
    },
    remoteCTALabel: {
        justifyContent: 'space-between',
    },
    remoteOffCTA: {
        backgroundColor: '#fff',
        color: '#ED0000',
        border: '1px solid #ED0000',
    },
    remoteOnCTA: {
        backgroundColor: '#fff',
        color: '#4CAF50',
        border: '1px solid rgba(76, 175, 80, 1)',
    },
    editCTA: {
        //backgroundColor: '#fff',
        //color: '#fff',
    },
    tableContainer: {
        width: '100%',
        height: '100%',
        overflowY: 'auto',
        paddingRight: '8px',
        minHeight: 'calc(100vh - 507px)',
        maxHeight: 'calc(100vh - 507px)',
        '&::-webkit-scrollbar': {
            width: '8px',
        },
        '&::-webkit-scrollbar-track': {
            background: '#f1f1f1',
            borderRadius: '8px',
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#888',
            borderRadius: '8px',
        },
        '&::-webkit-scrollbar-thumb:hover': {
            background: '#555',
        },
    },
    mapContainer: {
        width: '100%',
        height: '100%',
        overflowY: 'auto',
        paddingRight: '8px',
        minHeight: 'calc(100vh - 402px)',
        maxHeight: 'calc(100vh - 402px)',
        '&::-webkit-scrollbar': {
            width: '8px',
        },
        '&::-webkit-scrollbar-track': {
            background: '#f1f1f1',
            borderRadius: '8px',
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#888',
            borderRadius: '8px',
        },
        '&::-webkit-scrollbar-thumb:hover': {
            background: '#555',
        },
    },
    tableValue: {
        fontSize: '16px',
        fontWeight: 600,
        color: '#14112D',
        lineHeight: '20px',
        marginBottom: 0.5,
    },
    tableSubValue: {
        fontSize: '16px',
        fontWeight: 500,
        color: '#14112D',
        opacity: 0.72,
    },
    performHeader: {
        fontSize: '16px',
        fontWeight: 700,
        color: '#000',
    },
    performBox: {
        backgroundColor: '#fff',
        boxShadow: '0px 1px 16px 0px rgba(0, 0, 0, 0.16)',
        borderRadius: '4px',
        cursor: 'pointer',
    },
    performBoxExtended: {
        backgroundColor: '#fff',
        boxShadow: '0px 1px 16px 0px rgba(0, 0, 0, 0.16)',
        borderRadius: '4px',
        cursor: 'pointer',
        padding: '16px',
        minWidth: '336px',
    },
    nameAvatar: {
        color: theme.palette.getContrastText(deepOrange[500]),
        backgroundColor: deepOrange[500],
    },
    columnPaper: {
        padding: 0,
        //textAlign: 'center',
        //color: theme.palette.text.secondary,
        borderRadius: 0,
        backgroundColor: '#F6F7F9',
    },
}));

export default useSingleEVStyles;
