import store from '../store/configureStore';

export const ScreentPermissionSet = Object.freeze({
    EV_SP: 'ev_sp',
    STAFF: 'staff',
    ATTENDANCE: 'attendance',
    MAP_VIEW: 'map_view',
    REPORT: 'report',
    PAY_ONLINE: 'pay_online',
    CONTACTS: 'contacts',
    SETTINGS: 'settings',
    EV: 'ev',
    CONTROL_CENTER: 'control_center',
    HIRE: 'hire',
});

export const ScreenActionSet = Object.freeze({
    READ: 'read',
    UPDATE: 'update',
    CREATE: 'create',
    DELETE: 'delete',
    AUTH: 'auth',
});

export const getPermissions = (screen) => {
    return {
        auth: canAllow(screen, ScreenActionSet.AUTH),
        read: canAllow(screen, ScreenActionSet.READ),
        update: canAllow(screen, ScreenActionSet.UPDATE),
        create: canAllow(screen, ScreenActionSet.CREATE),
        delete: canAllow(screen, ScreenActionSet.DELETE),
    };
};

export const canAllow = (screen, action) => {
    const configs = store.getState().user.config;
    const permission = configs.permission ?? {};
    const pScreen =
        ScreentPermissionSet[screen?.replace(' ', '_')?.toUpperCase()];

    return (
        permission.hasOwnProperty(pScreen) &&
        permission[pScreen]?.includes(action)
    );
};

export const getActiveInitRoute = () => {
    let routes = Object.values(ScreentPermissionSet);
    let activeRoute = '';
    for (let item of routes) {
        const permission = getPermissions(item);
        if (permission.read) {
            activeRoute = item.replace('_', '-');
            break;
        }
    }
    return activeRoute ?? '404';
};
