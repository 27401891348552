import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    makeStyles,
    MenuItem,
    Slide,
    Typography,
    useMediaQuery,
    useTheme,
} from '@material-ui/core';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { Marker, Circle, Tooltip, useMap } from 'react-leaflet';
import Map from '../Map';
import * as L from 'leaflet';
import { CustomTextField } from '../Textfield';
import { PlaceAutoComplete } from '../UI';
import { Spinner } from '../UI';
import {
    CreateHub,
    EditHub,
    GetchZones,
    GetCities,
    GetCityZones,
} from '../../store/actions/settingsActionCreator';
import CustomSwitch from '../Switch';
import { connect } from 'react-redux';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { HUB_TYPE_ENUM } from '../../utils/constants';
import { withStyles } from '@material-ui/styles';
import { Autocomplete } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
    modal: {
        '& .modal-head': {
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
        },
        '& .modal-title': {
            fontSize: '24px',
            fontWeight: 'bold',
            color: '#14112d',
        },
        '& .modal-subtitle': {
            fontSize: '14px',
            fontWeight: 'normal',
            color: '#14112d',
        },

        '& .cancelButton': {
            color: theme.palette.text.primary,
        },
        '& .leaflet-container': {
            width: '100%',
            minHeight: '600px',
            // height: '630px',
            // borderRadius: 16,
            borderTopRightRadius: 16,
            borderBottomRightRadius: 16,
        },
        // '& .xutil': {
        //     '& .leaflet-container': {
        //         width: '100%',
        //         // borderRadius: 16,
        //         borderTopRightRadius: 16,
        //         borderBottomRightRadius: 16,
        //     },
        // },
        '& [data-color="food"]': {
            '& .MuiOutlinedInput-input': {
                color: '#ec4e2b',
                fontWeight: 'bold',
            },
        },
        '& [data-color="ecom"]': {
            '& .MuiOutlinedInput-input': {
                color: '#43419e',
                fontWeight: 'bold',
            },
        },
        '& [data-color="store"]': {
            '& .MuiOutlinedInput-input': {
                color: '#579b5a',
                fontWeight: 'bold',
            },
        },
    },
    iconButton: {
        padding: '0px',
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const myIcon = L.icon({
    iconUrl:
        'http://www.pngall.com/wp-content/uploads/2017/05/Map-Marker-PNG-File.png',
    iconSize: [40, 40],
    iconAnchor: [32, 64],
    popupAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
});

const PlatformTypes = [
    { label: 'On Platform', value: true },
    { label: 'Off Platform', value: false },
];

function HubModal({
    isEnabledXUtil,
    open,
    onClose,
    maxWidth,
    defaultValue = {},
    settings,
    statesList,
    cityList,
    zonesList,
    dispatchCreateHub,
    dispatchEditHub,
    dispatchGetCities,
    dispatchGetCityZones,
    dispatchGetchZones,
    ...props
}) {
    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [isOpen, toggleModal] = useState(open);
    const [loading, toggleLoading] = useState(false);
    const [address, setAddress] = useState({});
    const markerRef = useRef(null);
    const [mapview] = useState({
        lat: 21.7679,
        lng: 78.8718,
    });
    const [position, setPosition] = useState();
    const eventHandlers = useMemo(
        () => ({
            dragend() {
                const marker = markerRef.current;
                if (marker != null) {
                    console.log(marker.getLatLng());
                    setPosition(marker.getLatLng());
                }
            },
        }),
        [],
    );

    useEffect(() => {
        // fetchCities();
        fetchCityZones({
            cityId: settings?.basicDetails?.operatingCityId,
            hubIds: !!defaultValue?.id ? [defaultValue?.id] : null,
            primaryOperation: isEnabledXUtil
                ? [HUB_TYPE_ENUM.FOOD.value]
                : null,
        });
        if (Object.keys(defaultValue)?.length) {
            formik.setFieldValue('address', defaultValue.address);
            setAddress({
                address: defaultValue.address,
                latLng: {
                    lat: defaultValue.latitude,
                    lng: defaultValue.longitude,
                },
            });
            setPosition({
                lat: defaultValue.latitude,
                lng: defaultValue.longitude,
            });
        }
    }, []);

    const formik = useFormik({
        initialValues: {
            name: '',
            ...(isEnabledXUtil
                ? { primaryOperation: HUB_TYPE_ENUM.FOOD.value }
                : {}),
            address: '',
            cityName: settings?.basicDetails?.cityName,
            stateName: settings?.basicDetails?.stateName,
            geofenceRadius: '',
            zoneId: '',
            orderOnPlatform: '',
            ...defaultValue,
        },
        validationSchema: yup.object({
            name: yup.string('Name is invalid').required('Name is required'),
            ...(isEnabledXUtil
                ? {
                      primaryOperation: yup
                          .string('Primary Operation is invalid')
                          .nullable(true)
                          .required('Primary Operation is required'),
                      orderOnPlatform: yup
                          .string('Platform Type is invalid')
                          .required('Platform Type is required'),
                  }
                : {}),
            cityName: yup
                .string('City is invalid')
                .required('City is required'),
            stateName: yup
                .string('state is invalid')
                .required('state is required'),
            zoneId: yup
                .string()
                .nullable()
                .when('primaryOperation', {
                    is: (primaryOperation) =>
                        primaryOperation === HUB_TYPE_ENUM.FOOD.value ||
                        primaryOperation === HUB_TYPE_ENUM.ECOM.value,
                    then: yup
                        .string('Zone is invalid')
                        .required('Zone is required'),
                }),
            address: yup.string().when('primaryOperation', {
                is: (primaryOperation) =>
                    primaryOperation !== HUB_TYPE_ENUM.FOOD.value,
                then: yup
                    .string('Address is invalid')
                    .required('Address is required'),
            }),
            geofenceRadius: yup.number().when('primaryOperation', {
                is: (primaryOperation) =>
                    primaryOperation !== HUB_TYPE_ENUM.FOOD.value,
                then: yup
                    .number('geofenceRadius is invalid')
                    .required('geofenceRadius is required'),
            }),
        }),
        onSubmit: async (values) => {
            await toggleLoading(true);
            if (Object.keys(defaultValue)?.length) {
                dispatchEditHub(
                    {
                        hubs: [
                            {
                                ...values,
                                latitude: address?.latLng?.lat,
                                longitude: address?.latLng?.lng,
                            },
                        ],
                    },
                    async (resp) => {
                        await toggleLoading(false);
                        handleClose();
                    },
                    async (resp) => {
                        await toggleLoading(false);
                        handleClose();
                    },
                );
            } else {
                dispatchCreateHub(
                    {
                        hubs: [
                            {
                                ...values,
                                organizationId: settings?.basicDetails?.id,
                                isActive: true,
                                latitude: address?.latLng?.lat,
                                longitude: address?.latLng?.lng,
                            },
                        ],
                    },
                    async (resp) => {
                        await toggleLoading(false);
                        handleClose();
                    },
                    async (resp) => {
                        await toggleLoading(false);
                        handleClose();
                    },
                );
            }
        },
    });

    const fetchCityZones = async (payload) => {
        if (!settings?.basicDetails?.operatingCityId) return 0;
        await toggleLoading(true);
        dispatchGetchZones(
            payload,
            (resp) => {
                toggleLoading(false);
            },
            (err) => {
                toggleLoading(false);
            },
        );
    };
    const fetchCities = async () => {
        await toggleLoading(true);
        dispatchGetCities(
            {},
            (resp) => {
                toggleLoading(false);
            },
            (err) => {
                toggleLoading(false);
            },
        );
    };

    const handleClose = () => {
        toggleModal(!isOpen);
        onClose && onClose();
    };

    const getBounds = () => {
        return position
            ? [
                  [position.lat, position.lng],
                  [position.lat, position.lng],
              ]
            : [
                  [mapview.lat, mapview.lng],
                  [mapview.lat, mapview.lng],
              ];
    };

    const actions = [
        <Button
            key={1}
            variant="contained"
            color="default"
            className={`cancelButton`}
            onClick={handleClose}
            disableElevation
        >
            {'Cancel'}
        </Button>,
        <Button
            key={2}
            variant="contained"
            color="primary"
            className={`saveButton`}
            type="submit"
            disableElevation
        >
            {Object.keys(defaultValue)?.length ? 'Update Hub' : 'Add Hub'}
        </Button>,
    ];

    // function MapEvents() {
    //     const map = useMapEvents({
    //         click: () => {
    //             map.locate();
    //         },
    //         locationfound: (e) => {
    //             console.log('map', map);
    //             console.log('location found:', e);
    //             setPosition({ lat: e.latitude, lng: e.longitude });
    //             map.flyTo({ lat: e.latitude, lng: e.longitude }, map.getZoom());
    //         },
    //     });
    //     return null;
    // }

    return (
        <Dialog
            open={isOpen}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            className={classes.modal}
            fullScreen={fullScreen}
            fullWidth={true}
            maxWidth={maxWidth ?? 'md'}
        >
            <Spinner loading={loading}>
                <form
                    onSubmit={formik.handleSubmit}
                    className={isEnabledXUtil ? 'xutil' : ''}
                >
                    <Grid container>
                        <Grid item xs={6}>
                            <DialogTitle id="alert-dialog-slide-title">
                                <Box className={`modal-head`}>
                                    <div>
                                        <Typography
                                            className={`modal-title`}
                                            variant="h6"
                                        >
                                            {Object.keys(defaultValue)?.length
                                                ? 'Edit Hub'
                                                : 'Add Hub'}
                                        </Typography>
                                        <Typography
                                            className={`modal-subtitle`}
                                            variant="body2"
                                        >
                                            {Object.keys(defaultValue)?.length
                                                ? 'Edit hub of your team list.'
                                                : 'Add New hub to your team list.'}
                                        </Typography>
                                    </div>
                                    <div
                                        style={{
                                            alignSelf: 'center',
                                            justifySelf: 'flex-end',
                                        }}
                                    >
                                        <IconButton
                                            aria-label="delete"
                                            className={classes.iconButton}
                                            onClick={handleClose}
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                    </div>
                                </Box>
                            </DialogTitle>
                            {!!isEnabledXUtil ? (
                                <DialogContent
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        flexWrap: 'wrap',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        marginBottom: '60px',
                                    }}
                                >
                                    <CustomTextField
                                        fullWidth={true}
                                        label="Name"
                                        variant="outlined"
                                        style={{
                                            marginBottom: '16px',
                                        }}
                                        id="name"
                                        name="name"
                                        value={formik.values.name}
                                        onChange={formik.handleChange}
                                        error={
                                            formik.touched.name
                                                ? Boolean(formik.errors.name)
                                                : false
                                        }
                                        helperText={
                                            formik.touched.name &&
                                            formik.errors.name
                                        }
                                        isRequired={true}
                                    />
                                    <CustomTextField
                                        label="Platform Type"
                                        select
                                        style={{
                                            marginBottom: '16px',
                                            width: '48%',
                                        }}
                                        id="orderOnPlatform"
                                        name="orderOnPlatform"
                                        value={formik.values.orderOnPlatform}
                                        onChange={formik.handleChange}
                                        error={
                                            formik.touched.orderOnPlatform
                                                ? Boolean(
                                                      formik.errors
                                                          .orderOnPlatform,
                                                  )
                                                : false
                                        }
                                        helperText={
                                            formik.touched.orderOnPlatform &&
                                            formik.errors.orderOnPlatform
                                        }
                                        isRequired={true}
                                    >
                                        {PlatformTypes.map((pt) => {
                                            return (
                                                <MenuItem value={pt.value}>
                                                    {pt.label}
                                                </MenuItem>
                                            );
                                        })}
                                    </CustomTextField>
                                    <Autocomplete
                                        multiple={false}
                                        options={[
                                            ...Object.keys(
                                                HUB_TYPE_ENUM,
                                            ).filter((i) => !!i),
                                        ]}
                                        getOptionLabel={(option) => {
                                            return HUB_TYPE_ENUM?.[option]
                                                ?.label;
                                        }}
                                        onChange={(event, newValue) => {
                                            formik.setFieldValue(
                                                'primaryOperation',
                                                newValue ?? '',
                                                true,
                                            );
                                            if (
                                                !Object.keys(defaultValue)
                                                    ?.length
                                            )
                                                fetchCityZones({
                                                    cityId: settings
                                                        ?.basicDetails
                                                        ?.operatingCityId,
                                                    hubIds: !!defaultValue?.id
                                                        ? [defaultValue?.id]
                                                        : null,
                                                    primaryOperation: !!newValue
                                                        ? [newValue]
                                                        : null,
                                                });
                                        }}
                                        size="medium"
                                        id="primaryOperation"
                                        name="primaryOperation"
                                        data-color={formik.values?.primaryOperation?.toLocaleLowerCase()}
                                        value={formik.values?.primaryOperation}
                                        style={{
                                            marginBottom: '16px',
                                            width: '48%',
                                        }}
                                        renderOption={(hub, { selected }) => {
                                            return (
                                                <MenuItem
                                                    value={
                                                        HUB_TYPE_ENUM[hub].value
                                                    }
                                                    style={{
                                                        color: HUB_TYPE_ENUM[
                                                            hub
                                                        ]?.color,
                                                        fontWeight: 'bold',
                                                        padding: 0,
                                                    }}
                                                >
                                                    {HUB_TYPE_ENUM[hub]?.label}
                                                </MenuItem>
                                            );
                                        }}
                                        renderInput={(params) => (
                                            <CustomTextField
                                                {...params}
                                                label="Business Type"
                                                id="primaryOperation"
                                                name="primaryOperation"
                                                data-color={formik.values?.primaryOperation?.toLocaleLowerCase()}
                                                error={
                                                    formik.touched
                                                        .primaryOperation
                                                        ? Boolean(
                                                              formik.errors
                                                                  .primaryOperation,
                                                          )
                                                        : false
                                                }
                                                helperText={
                                                    formik.touched
                                                        .primaryOperation &&
                                                    formik.errors
                                                        .primaryOperation
                                                }
                                                isRequired={true}
                                            />
                                        )}
                                    />
                                    <CustomTextField
                                        fullWidth={true}
                                        label="City"
                                        variant="outlined"
                                        style={{
                                            marginBottom: '16px',
                                            width: '48%',
                                        }}
                                        placeholder="City"
                                        inputProps={{
                                            readOnly: true,
                                        }}
                                        id="cityName"
                                        name="cityName"
                                        value={formik.values.cityName}
                                        onChange={formik.handleChange}
                                        error={
                                            formik.touched.cityName
                                                ? Boolean(
                                                      formik.errors.cityName,
                                                  )
                                                : false
                                        }
                                        helperText={
                                            formik.touched.cityName &&
                                            formik.errors.cityName
                                        }
                                        isRequired={true}
                                        disabled={true}
                                    />
                                    <CustomTextField
                                        label="Zone"
                                        select
                                        style={{
                                            marginBottom: '16px',
                                            width: '48%',
                                        }}
                                        id="zoneId"
                                        name="zoneId"
                                        value={formik.values.zoneId}
                                        onChange={formik.handleChange}
                                        error={
                                            formik.touched.zoneId
                                                ? Boolean(formik.errors.zoneId)
                                                : false
                                        }
                                        helperText={
                                            formik.touched.zoneId &&
                                            formik.errors.zoneId
                                        }
                                        isRequired={true}
                                    >
                                        {zonesList.map((zone) => {
                                            return (
                                                <MenuItem value={zone.id}>
                                                    {zone.zoneName}
                                                </MenuItem>
                                            );
                                        })}
                                    </CustomTextField>
                                    <CustomTextField
                                        fullWidth={true}
                                        label="State"
                                        variant="outlined"
                                        style={{ marginBottom: '16px' }}
                                        placeholder="State"
                                        inputProps={{
                                            readOnly: true,
                                        }}
                                        id="stateName"
                                        name="stateName"
                                        value={formik.values.stateName}
                                        onChange={formik.handleChange}
                                        error={
                                            formik.touched.stateName
                                                ? Boolean(
                                                      formik.errors.stateName,
                                                  )
                                                : false
                                        }
                                        helperText={
                                            formik.touched.stateName &&
                                            formik.errors.stateName
                                        }
                                        isRequired={true}
                                        disabled={true}
                                    />
                                    {/* <Autocomplete
                                        multiple={false}
                                        options={zonesList ?? []}
                                        getOptionLabel={(zone) => {
                                            return zone.zoneName;
                                        }}
                                        onChange={(event, newValue) => {
                                            formik.setFieldValue(
                                                'zoneId',
                                                newValue,
                                                true,
                                            );
                                        }}
                                        id="zoneId"
                                        name="zoneId"
                                        value={formik.values.zoneId}
                                        style={{ marginBottom: '16px' }}
                                        renderOption={(zone, { selected }) => {
                                            return (
                                                <MenuItem
                                                    style={{
                                                        padding: 0,
                                                    }}
                                                    value={zone.id}
                                                >
                                                    {zone.zoneName}
                                                </MenuItem>
                                            );
                                        }}
                                        renderInput={(params) => (
                                            <CustomTextField
                                                {...params}
                                                label="Zone"
                                                id="zoneId"
                                                name="zoneId"
                                                error={
                                                    formik.touched
                                                        .primaryOperation
                                                        ? Boolean(
                                                              formik.errors
                                                                  .primaryOperation,
                                                          )
                                                        : false
                                                }
                                                helperText={
                                                    formik.touched
                                                        .primaryOperation &&
                                                    formik.errors
                                                        .primaryOperation
                                                }
                                                isRequired={true}
                                            />
                                        )}
                                    /> */}
                                    {formik.values?.primaryOperation !==
                                        HUB_TYPE_ENUM.FOOD.value && (
                                        <>
                                            <div
                                                style={{
                                                    marginBottom: 16,
                                                    backgroundColor: '#eaedf3',
                                                    width: '100%',
                                                    height: 2,
                                                }}
                                            />
                                            <PlaceAutoComplete
                                                label="Address"
                                                onChange={(val) => {
                                                    setAddress(val);
                                                    const state =
                                                        val.results[0]?.address_components.find(
                                                            (add) =>
                                                                add.types.includes(
                                                                    'administrative_area_level_1',
                                                                ),
                                                        );
                                                    const city =
                                                        val.results[0]?.address_components.find(
                                                            (add) =>
                                                                add.types.includes(
                                                                    'administrative_area_level_2',
                                                                ),
                                                        );
                                                    // formik.setFieldValue(
                                                    //     'stateName',
                                                    //     state?.long_name,
                                                    //     true,
                                                    // );
                                                    // formik.setFieldValue(
                                                    //     'cityName',
                                                    //     city?.long_name ??
                                                    //         state?.long_name,
                                                    //     true,
                                                    // );
                                                    setPosition(val.latLng);
                                                    formik.setFieldValue(
                                                        'address',
                                                        val.address,
                                                        true,
                                                    );
                                                }}
                                                isRequired={true}
                                                defaultValue={address}
                                            />
                                            <CustomTextField
                                                fullWidth={true}
                                                label="Geofence Radius (m)"
                                                variant="outlined"
                                                inputProps={{
                                                    type: 'number',
                                                    min: 1,
                                                }}
                                                style={{ marginBottom: '16px' }}
                                                id="geofenceRadius"
                                                name="geofenceRadius"
                                                value={
                                                    formik.values.geofenceRadius
                                                }
                                                onChange={formik.handleChange}
                                                error={
                                                    formik.touched
                                                        .geofenceRadius
                                                        ? Boolean(
                                                              formik.errors
                                                                  .geofenceRadius,
                                                          )
                                                        : false
                                                }
                                                helperText={
                                                    formik.touched
                                                        .geofenceRadius &&
                                                    formik.errors.geofenceRadius
                                                }
                                                isRequired={true}
                                            />
                                        </>
                                    )}
                                    <div
                                        style={{
                                            position: 'absolute',
                                            bottom: 8,
                                            width: '45%',
                                        }}
                                    >
                                        <Divider style={{ marginTop: '0' }} />
                                        {!!actions && (
                                            <DialogActions>
                                                {actions.map(
                                                    (action, index) => (
                                                        <Box key={index}>
                                                            {action}
                                                        </Box>
                                                    ),
                                                )}
                                            </DialogActions>
                                        )}
                                    </div>
                                </DialogContent>
                            ) : (
                                <DialogContent>
                                    <CustomTextField
                                        fullWidth={true}
                                        label="Name"
                                        variant="outlined"
                                        style={{ marginBottom: '16px' }}
                                        id="name"
                                        name="name"
                                        value={formik.values.name}
                                        onChange={formik.handleChange}
                                        error={
                                            formik.touched.name
                                                ? Boolean(formik.errors.name)
                                                : false
                                        }
                                        helperText={
                                            formik.touched.name &&
                                            formik.errors.name
                                        }
                                    />
                                    <PlaceAutoComplete
                                        onChange={(val) => {
                                            setAddress(val);
                                            const state =
                                                val.results[0]?.address_components.find(
                                                    (add) =>
                                                        add.types.includes(
                                                            'administrative_area_level_1',
                                                        ),
                                                );
                                            const city =
                                                val.results[0]?.address_components.find(
                                                    (add) =>
                                                        add.types.includes(
                                                            'administrative_area_level_2',
                                                        ),
                                                );
                                            formik.setFieldValue(
                                                'stateName',
                                                state?.long_name,
                                                true,
                                            );
                                            formik.setFieldValue(
                                                'cityName',
                                                city?.long_name ??
                                                    state?.long_name,
                                                true,
                                            );
                                            setPosition(val.latLng);
                                            formik.setFieldValue(
                                                'address',
                                                val.address,
                                                true,
                                            );
                                        }}
                                        defaultValue={address}
                                    />
                                    {/* <CustomTextField
                                    label="City"
                                    select
                                    style={{ marginBottom: '16px' }}
                                    id="zoneId"
                                    name="zoneId"
                                    value={formik.values.zoneId}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.zoneId
                                            ? Boolean(formik.errors.zoneId)
                                            : false
                                    }
                                    helperText={
                                        formik.touched.zoneId &&
                                        formik.errors.zoneId
                                    }
                                >
                                    {cityList?.length === 0 && (
                                        <MenuItem value={undefined}>
                                            No Data found
                                        </MenuItem>
                                    )}
                                    {cityList?.map((city) => (
                                        <MenuItem value={city.id}>
                                            {city.name}
                                        </MenuItem>
                                    ))}
                                </CustomTextField> */}
                                    <CustomTextField
                                        fullWidth={true}
                                        label="City"
                                        variant="outlined"
                                        style={{ marginBottom: '16px' }}
                                        placeholder="City"
                                        inputProps={{
                                            readOnly: true,
                                        }}
                                        id="cityName"
                                        name="cityName"
                                        value={formik.values.cityName}
                                        onChange={formik.handleChange}
                                        error={
                                            formik.touched.cityName
                                                ? Boolean(
                                                      formik.errors.cityName,
                                                  )
                                                : false
                                        }
                                        helperText={
                                            formik.touched.cityName &&
                                            formik.errors.cityName
                                        }
                                    />
                                    <CustomTextField
                                        fullWidth={true}
                                        label="State"
                                        variant="outlined"
                                        style={{ marginBottom: '16px' }}
                                        placeholder="State"
                                        inputProps={{
                                            readOnly: true,
                                        }}
                                        id="stateName"
                                        name="stateName"
                                        value={formik.values.stateName}
                                        onChange={formik.handleChange}
                                        error={
                                            formik.touched.stateName
                                                ? Boolean(
                                                      formik.errors.stateName,
                                                  )
                                                : false
                                        }
                                        helperText={
                                            formik.touched.stateName &&
                                            formik.errors.stateName
                                        }
                                    />
                                    <Divider style={{ marginBottom: '15px' }} />
                                    <CustomTextField
                                        fullWidth={true}
                                        label="Geofence Radius (m)"
                                        variant="outlined"
                                        inputProps={{
                                            type: 'number',
                                            min: 1,
                                        }}
                                        style={{ marginBottom: '16px' }}
                                        id="geofenceRadius"
                                        name="geofenceRadius"
                                        value={formik.values.geofenceRadius}
                                        onChange={formik.handleChange}
                                        error={
                                            formik.touched.geofenceRadius
                                                ? Boolean(
                                                      formik.errors
                                                          .geofenceRadius,
                                                  )
                                                : false
                                        }
                                        helperText={
                                            formik.touched.geofenceRadius &&
                                            formik.errors.geofenceRadius
                                        }
                                    />
                                    <div
                                        style={{
                                            position: 'absolute',
                                            bottom: 8,
                                            width: '45%',
                                        }}
                                    >
                                        <Divider style={{ marginTop: '0' }} />
                                        {!!actions && (
                                            <DialogActions>
                                                {actions.map(
                                                    (action, index) => (
                                                        <Box key={index}>
                                                            {action}
                                                        </Box>
                                                    ),
                                                )}
                                            </DialogActions>
                                        )}
                                    </div>
                                </DialogContent>
                            )}
                        </Grid>
                        <Grid item xs={6}>
                            <Map
                                position={mapview}
                                bounds={getBounds()}
                                zoom={4}
                            >
                                {position && (
                                    <ChangeView
                                        position={position}
                                        markers={[position]}
                                    />
                                )}
                                {/* <MapEvents /> */}
                                {position && (
                                    <Marker
                                        draggable={true}
                                        eventHandlers={eventHandlers}
                                        icon={myIcon}
                                        ref={markerRef}
                                        position={position}
                                    >
                                        <Tooltip
                                            direction="top"
                                            offset={[-12, -60]}
                                            opacity={1}
                                        >
                                            you are here
                                        </Tooltip>
                                        {!!formik?.values?.geofenceRadius && (
                                            <Circle
                                                center={position}
                                                radius={
                                                    formik?.values
                                                        ?.geofenceRadius ?? 0
                                                }
                                                stroke={false}
                                                pathOptions={{
                                                    fillColor: 'blue',
                                                }}
                                            ></Circle>
                                        )}
                                    </Marker>
                                )}
                            </Map>
                        </Grid>
                    </Grid>
                </form>
            </Spinner>
        </Dialog>
    );
}

function ChangeView({ position, markers }) {
    const map = useMap();
    map.setView({ lng: position.lng, lat: position.lat }, 10);

    let markerBounds = L.latLngBounds([]);
    markers.forEach((marker) => {
        markerBounds.extend([marker.lat, marker.lng]);
    });
    markerBounds.isValid() && map.fitBounds(markerBounds);
    return null;
}

const mapStateToProps = (state) => ({
    settings: state.settings,
    statesList: state.settings?.statesList ?? [],
    cityList: state.settings?.cities ?? [],
    zonesList: state.settings?.zones ?? [],
    isEnabledXUtil: state?.user?.config?.isEnabledXUtil,
});

const mapDispatchToProps = (dispatch) => ({
    dispatchCreateHub: (payload, onSuccess, onError) =>
        dispatch(CreateHub(payload, onSuccess, onError)),
    dispatchEditHub: (payload, onSuccess, onError) =>
        dispatch(EditHub(payload, onSuccess, onError)),
    dispatchGetCities: (payload, onSuccess, onError) =>
        dispatch(GetCities(payload, onSuccess, onError)),
    dispatchGetCityZones: (payload, onSuccess, onError) =>
        dispatch(GetCityZones(payload, onSuccess, onError)),
    dispatchGetchZones: (payload, onSuccess, onError) =>
        dispatch(GetchZones(payload, onSuccess, onError)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HubModal);
