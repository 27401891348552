import React from 'react';
import { MenuItem, TextField, withStyles } from '@material-ui/core';

export const StyledMenuItem = withStyles((theme) => ({
    root: {
        margin: '0 8px',
        border: '0 !important',
        borderRadius: '8px',
        '&:hover': {
            backgroundColor: 'rgba(67, 65, 158, 0.08)',
            color: '#43419e',
            borderRadius: '8px',
        },
    },
}))(MenuItem);

export const CustomTextField = withStyles({
    root: {
        width: '100%',
        '& label.Mui-focused': {
            color: '#14112d',
        },
        // marginBottom: '16px',

        '& .MuiOutlinedInput-root': {
            // '& fieldset': {
            //     borderColor: 'red',
            // },
            '&:hover fieldset': {
                borderColor: '#1bb55c',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#1bb55c',
            },
        },
        '& .MuiOutlinedInput-input': {
            padding: '16px',
            color: '#14112d',
        },
        '& .MuiOutlinedInput-inputMultiline': {
            padding: 0,
        },
    },
})(({ classes, children, label, isRequired, ...props }) => {
    return (
        <TextField
            classes={{
                root: classes.root,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
            }}
            variant="outlined"
            error={false}
            label={
                isRequired ? (
                    <>
                        {label}
                        <span style={{ color: 'red' }}>*</span>
                    </>
                ) : (
                    label
                )
            }
            {...props}
        >
            {children}
        </TextField>
    );
});

export const CompactCustomTextField = withStyles({
    root: {
        width: '100%',
        '& label.Mui-focused': {
            color: '#14112d',
        },

        '& .MuiOutlinedInput-root': {
            padding: '0 4px',
            height: 40,
            '&:hover fieldset': {
                borderColor: '#1bb55c',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#1bb55c',
            },
        },
        '& .MuiOutlinedInput-input': {
            padding: '0 10px',
            color: '#14112d',
        },
    },
})(({ classes, children, ...props }) => {
    return (
        <TextField
            classes={{
                root: classes.root,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
            }}
            variant="outlined"
            error={false}
            {...props}
        >
            {children}
        </TextField>
    );
});
