import { Box, Button, Typography, makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import RateCard from '../RateCard';
import Modal from './Modal';

const useStyles = makeStyles((theme) => ({
    'rate-card-view': {
        '& .MuiDialog-paperScrollPaper': {
            backgroundColor: '#f1f3f6',
        },
    },
}));

function RateCardModal({ data, ...props }) {
    const classes = useStyles();
    const [isOpen, toggleModal] = useState(false);
    const [loading, toggleLoading] = useState(false);
    const handleClose = () => {
        // Setting form data to null before closing

        toggleModal(!isOpen);
    };

    const actions = [];
    return (
        <>
            <Button
                variant="contained"
                color="primary"
                className={`saveButton`}
                disableElevation
                onClick={(e) => {
                    toggleModal(true);
                }}
            >
                View Rate Card
            </Button>
            {isOpen && (
                <Modal
                    onClose={handleClose}
                    open={isOpen}
                    title={'Rate Card'}
                    loading={loading}
                    className={classes['rate-card-view']}
                >
                    <RateCard data={data} />
                </Modal>
            )}
        </>
    );
}

export default RateCardModal;
