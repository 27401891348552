import {
    Box,
    Button,
    Checkbox,
    Chip,
    debounce,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    makeStyles,
    Slide,
    Typography,
    useMediaQuery,
    useTheme,
    withStyles,
} from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { CustomTextField } from '../Textfield';
import { Spinner } from '../UI';
import { connect } from 'react-redux';
import { Autocomplete } from '@material-ui/lab';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import {
    AssignShiftToStaff,
    GetAllStaffList,
    GetShiftsByStaffHub,
} from '../../store/actions/settingsActionCreator';
import moment from 'moment';
import { HUB_TYPE_ENUM } from '../../utils/constants';
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles((theme) => ({
    modal: {
        '& .modal-head': {
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
        },
        '& .modal-title': {
            fontSize: '24px',
            fontWeight: 'bold',
            color: '#14112d',
        },
        '& .modal-subtitle': {
            fontSize: '14px',
            fontWeight: 'normal',
            color: '#14112d',
        },

        '& .cancelButton': {
            color: theme.palette.text.primary,
        },
        '& .leaflet-container': {
            width: '100%',
            height: '510px',
            // borderRadius: 16,
            borderTopRightRadius: 16,
            borderBottomRightRadius: 16,
        },
    },
    iconButton: {
        padding: '0px',
    },
}));

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const StyledAutocomplete = withStyles((theme) => ({
    root: {
        width: '100%',
        '& .MuiOutlinedInput-notchedOutline': {
            borderTop: 0,
            borderRadius: 0,
            borderWidth: 0,
            borderBottomLeftRadius: 8,
            borderBottomRightRadius: 8,
        },
        '& .MuiChip-root': {
            backgroundColor: '#EBEBEB',
            // border: '1px solid #e4e4e4',
        },
        '& .MuiChip-label': {
            fontWeight: 600,
        },
    },
    popper: {
        '& .MuiAutocomplete-option[aria-selected="true"]': {
            backgroundColor: '#fff',
        },
    },
}))(Autocomplete);

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function AssignStaffToHubModal({
    open,
    onClose,
    data = {},
    cityList,
    staff,
    dispatcGetAllStaffList,
    dispatcGetShiftsByStaffHub,
    dispatchAssignShiftToStaff,
    ...props
}) {
    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [isOpen, toggleModal] = useState(open);
    const [loading, toggleLoading] = useState(false);
    const [isSubmitDisabled, setSubmitDisabled] = useState(false);
    const [autoCompleteLoading, toggleAutoCompleteLoading] = useState(false);
    const [FEList, setFEList] = useState([]);
    const [assignPayload, setAssignPayload] = useState({
        staffId: '',
        hubId: '',
        shiftIds: [],
    });
    const [shifts, setShifts] = useState([]);
    const [hubShiftMappings, sethubShiftMappings] = useState([]);
    const [feListPayload, setFEListPayload] = useState({
        searchText: '',
        pageNo: 1,
        pageSize: 20,
    });

    const [overlapShifts, setOverlapShift] = useState([]);
    const feDebounceSearch = useRef(
        debounce((newInputValue) => {
            setFEListPayload({
                ...feListPayload,
                searchText: newInputValue,
            });
        }, 250),
    ).current;

    useEffect(() => {
        fetchFEList();
    }, [feListPayload]);

    useEffect(() => {
        let shiftIds = [];
        hubShiftMappings.forEach((hub) => {
            shiftIds = [...shiftIds, ...hub?.shifts.map((i) => i.id)];
        });
        if (!assignPayload?.staffId) {
            setSubmitDisabled(true);
        }
        if (!shiftIds.length) {
            setSubmitDisabled(true);
        } else {
            setSubmitDisabled(false);
        }
        checkoverlapping();
    }, [assignPayload?.staffId, hubShiftMappings]);

    const fetchFEList = (payload = feListPayload) => {
        toggleLoading(true);
        toggleAutoCompleteLoading(true);
        dispatcGetAllStaffList(
            payload,
            (resp) => {
                if (!!resp?.response?.lineItems?.length) {
                    setFEList(resp?.response?.lineItems ?? []);
                }
                toggleLoading(false);
                toggleAutoCompleteLoading(false);
            },
            (err) => {
                console.log(`fetchFEList Error:${err}`);
                toggleLoading(false);
                toggleAutoCompleteLoading(false);
            },
        );
    };
    const fetchShiftList = (staff) => {
        toggleLoading(true);
        dispatcGetShiftsByStaffHub(
            staff?.id,
            {
                selectedHubIds: [data?.id],
            },
            (resp) => {
                const hubShiftMappings = resp?.response?.hubShiftMappings;
                if (!!hubShiftMappings?.length) {
                    sethubShiftMappings(
                        hubShiftMappings?.map((hub) => ({
                            ...hub,
                            shifts: hub?.shiftLineItems?.filter(
                                (i) => !!i.mappedForStaff,
                            ),
                            shiftLineItems: hub?.shiftLineItems?.filter(
                                (h) => !h?.slotShift,
                            ),
                        })) ?? [],
                    );

                    setAssignPayload({
                        ...assignPayload,
                        hubId: data?.id,
                        staffId: staff,
                    });
                    let shiftIds = [];
                    hubShiftMappings.forEach((hub) => {
                        shiftIds = [
                            ...shiftIds,
                            ...hub?.shiftLineItems
                                ?.filter((i) => !!i.mappedForStaff)
                                .map((i) => i.id),
                        ];
                    });
                    setShifts(shiftIds);
                }
                toggleLoading(false);
            },
            (err) => {
                console.log(`fetchFEList Error:${err}`);
                toggleLoading(false);
            },
        );
    };
    const AssignShift = (payload = {}) => {
        toggleLoading(true);
        const { staffId, ...payloads } = { ...assignPayload, ...payload };
        dispatchAssignShiftToStaff(
            staffId,
            payloads,
            (resp) => {
                toggleLoading(false);
                handleClose();
            },
            (err) => {
                console.log(`fetchFEList Error:${err}`);
                toggleLoading(false);
            },
        );
    };

    const handleClose = () => {
        toggleModal(!isOpen);
        onClose && onClose();
    };

    function checkoverlapping() {
        let shiftIds = [];

        hubShiftMappings.forEach((hub) => {
            shiftIds = [...shiftIds, ...hub?.shifts];
        });
        const { overlapList, overlapping } = shiftOverlapValidation(shiftIds);
        setOverlapShift(overlapList);
        setSubmitDisabled(overlapping);
        return overlapping;
    }

    const actions = [
        <Button
            key={1}
            variant="contained"
            color="default"
            className={`cancelButton`}
            onClick={handleClose}
            disableElevation
        >
            {'Cancel'}
        </Button>,
        <Button
            key={2}
            variant="contained"
            color="primary"
            className={`saveButton`}
            disableElevation
            disabled={isSubmitDisabled}
            onClick={() => {
                if (!checkoverlapping()) {
                    let shiftIds = [];
                    hubShiftMappings.forEach((hub) => {
                        shiftIds = [
                            ...shiftIds,
                            ...hub.shifts.map((i) => i.id),
                        ];
                    });
                    AssignShift({
                        ...assignPayload,
                        staffId: assignPayload?.staffId?.id,
                        shiftIds,
                    });
                }
            }}
        >
            {'Assign'}
        </Button>,
    ];

    return (
        <Dialog
            open={isOpen}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            className={classes.modal}
            fullScreen={fullScreen}
            fullWidth={true}
            maxWidth={'xs'}
            disableBackdropClick
        >
            <Spinner loading={loading}>
                <DialogTitle id="alert-dialog-slide-title">
                    <Box className={`modal-head`}>
                        <div>
                            <Typography className={`modal-title`} variant="h6">
                                {'Assign Staff'}
                            </Typography>
                        </div>
                        <div
                            style={{
                                alignSelf: 'center',
                                justifySelf: 'flex-end',
                            }}
                        >
                            <IconButton
                                aria-label="delete"
                                className={classes.iconButton}
                                onClick={handleClose}
                            >
                                <CloseIcon />
                            </IconButton>
                        </div>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Autocomplete
                        options={FEList ?? []}
                        value={assignPayload?.staffId}
                        getOptionLabel={(option) =>
                            option?.name
                                ? `${option.name} - ${option.contactNumber}`
                                : ''
                        }
                        onChange={(e, value) => {
                            if (!!value) {
                                fetchShiftList(value);
                            }
                        }}
                        id="staffId"
                        name="staffId"
                        label="Select FE"
                        // getOptionSelected={(option) => {
                        //     return !!FEList?.find((i) => i.id === option.id);
                        // }}
                        onInputChange={(event, newInputValue) => {
                            feDebounceSearch(newInputValue);
                        }}
                        loading={autoCompleteLoading}
                        style={{ width: '100%', marginBottom: '16px' }}
                        renderInput={(params) => (
                            <CustomTextField
                                {...params}
                                fullWidth={true}
                                id="staffId"
                                name="staffId"
                                label="Select FE"
                                variant="outlined"
                                isRequired={true}
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'new-city',
                                }}
                            />
                        )}
                    />
                    {hubShiftMappings?.map((hub, index) => {
                        return (
                            <Box style={{ marginBottom: 8 }}>
                                <Box
                                    style={{
                                        backgroundColor:
                                            HUB_TYPE_ENUM[
                                                hub?.primaryOperation?.toUpperCase()
                                            ]?.bgColor,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        padding: '6px 12px',
                                        borderTopLeftRadius: 8,
                                        borderTopRightRadius: 8,
                                        border: 'solid 1px #f0e7e6',
                                        borderBottom: 'none',
                                    }}
                                >
                                    <Typography
                                        variant="inherit"
                                        style={{
                                            color: 'rgba(38, 50, 56, 0.8)',
                                            fontSize: 14,
                                        }}
                                    >
                                        Hub Name:{' '}
                                        <Typography
                                            variant="inherit"
                                            style={{
                                                color: '#263238',
                                                fontWeight: 600,
                                                fontSize: 14,
                                            }}
                                        >
                                            {hub?.hubName}
                                        </Typography>
                                    </Typography>
                                    <IconButton
                                        size="small"
                                        onClick={() => {
                                            sethubShiftMappings(
                                                hubShiftMappings.filter(
                                                    (_, inx) => inx !== index,
                                                ),
                                            );
                                        }}
                                    >
                                        <DeleteIcon
                                            color="error"
                                            fontSize="small"
                                        />
                                    </IconButton>
                                </Box>
                                <Box
                                    style={{
                                        borderBottomLeftRadius: 8,
                                        borderBottomRightRadius: 8,
                                        border: 'solid 1px rgba(38, 50, 56, 0.16)',
                                        borderTop: 'none',
                                        width: '100%',
                                        // padding: '16px 12px',
                                    }}
                                    // onClick={(event) => {
                                    //     setAnchorEl(event.currentTarget);
                                    // }}
                                >
                                    {
                                        <Box
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                            }}
                                        >
                                            <StyledAutocomplete
                                                multiple={true}
                                                disableCloseOnSelect={false}
                                                disableClearable
                                                limitTags={2}
                                                options={
                                                    hub?.shiftLineItems ?? []
                                                }
                                                getOptionLabel={(option) =>
                                                    `${moment(
                                                        option?.startTimeEpochMillis,
                                                    ).format('HH:mm')}-${moment(
                                                        option?.endTimeEpochMillis,
                                                    ).format('HH:mm')}`
                                                }
                                                filterSelectedOptions={false}
                                                value={hub?.shifts}
                                                onChange={(e, newValue) => {
                                                    let shifts = [];
                                                    const newHubs =
                                                        hubShiftMappings?.map(
                                                            (hub, idx) => {
                                                                shifts = [
                                                                    ...shifts,
                                                                    ...(idx ===
                                                                    index
                                                                        ? newValue
                                                                        : hub.shifts),
                                                                ];
                                                                return {
                                                                    ...hub,
                                                                    shifts:
                                                                        idx ===
                                                                        index
                                                                            ? newValue
                                                                            : hub.shifts,
                                                                };
                                                            },
                                                        );
                                                    setShifts(shifts);
                                                    sethubShiftMappings(
                                                        newHubs,
                                                    );
                                                }}
                                                includeInputInList={true}
                                                getLimitTagsText={(more) => {
                                                    let border = 'none';
                                                    hub.shifts.map(
                                                        (shift, indx) => {
                                                            if (indx > 1) {
                                                                border =
                                                                    overlapShifts.includes(
                                                                        shift.id,
                                                                    )
                                                                        ? '1px solid red'
                                                                        : 'none';
                                                            }
                                                            return shift;
                                                        },
                                                    );
                                                    return (
                                                        <Chip
                                                            style={{ border }}
                                                            label={`+${more}`}
                                                        />
                                                    );
                                                }}
                                                renderTags={(values) => {
                                                    return values.map(
                                                        (shift, index) => {
                                                            return (
                                                                <Chip
                                                                    key={index}
                                                                    onDelete={
                                                                        shift?.slotShift
                                                                            ? null
                                                                            : () => {
                                                                                  sethubShiftMappings(
                                                                                      hubShiftMappings.map(
                                                                                          (
                                                                                              hub,
                                                                                          ) => {
                                                                                              return {
                                                                                                  ...hub,
                                                                                                  shifts: hub.shifts.filter(
                                                                                                      (
                                                                                                          i,
                                                                                                      ) =>
                                                                                                          i.id !==
                                                                                                          shift.id,
                                                                                                  ),
                                                                                              };
                                                                                          },
                                                                                      ),
                                                                                  );
                                                                                  setShifts(
                                                                                      shifts.filter(
                                                                                          (
                                                                                              s,
                                                                                          ) =>
                                                                                              s.id !==
                                                                                              shift.id,
                                                                                      ),
                                                                                  );
                                                                              }
                                                                    }
                                                                    style={{
                                                                        marginRight: 4,
                                                                        border: overlapShifts.includes(
                                                                            shift.id,
                                                                        )
                                                                            ? '1px solid red'
                                                                            : 'none',
                                                                    }}
                                                                    label={`${moment(
                                                                        shift?.startTimeEpochMillis,
                                                                    ).format(
                                                                        'HH:mm',
                                                                    )}-${moment(
                                                                        shift?.endTimeEpochMillis,
                                                                    ).format(
                                                                        'HH:mm',
                                                                    )}`}
                                                                />
                                                            );
                                                        },
                                                    );
                                                }}
                                                renderOption={(
                                                    option,
                                                    { selected },
                                                ) => (
                                                    <Box
                                                        style={{
                                                            display: 'flex',
                                                            alignItems:
                                                                'center',
                                                            justifyContent:
                                                                'space-between',
                                                            width: '100%',
                                                            borderBottom:
                                                                '1px solid #EBEBEB',
                                                        }}
                                                    >
                                                        <Box>
                                                            <Typography
                                                                style={{
                                                                    fontSize:
                                                                        '12px',
                                                                    fontWeight: 600,
                                                                }}
                                                            >
                                                                {option.name}
                                                            </Typography>
                                                            <Typography
                                                                style={{
                                                                    fontSize:
                                                                        '10px',
                                                                    fontWeight: 600,
                                                                }}
                                                            >
                                                                {moment(
                                                                    option?.startTimeEpochMillis,
                                                                ).format(
                                                                    'HH:mm',
                                                                )}{' '}
                                                                -{' '}
                                                                {moment(
                                                                    option?.endTimeEpochMillis,
                                                                ).format(
                                                                    'HH:mm',
                                                                )}
                                                            </Typography>
                                                        </Box>
                                                        <Checkbox
                                                            icon={icon}
                                                            style={{
                                                                marginRight: 8,
                                                            }}
                                                            color={'primary'}
                                                            checked={selected}
                                                        />
                                                    </Box>
                                                )}
                                                style={{ width: '100%' }}
                                                renderInput={(params) => (
                                                    <CustomTextField
                                                        {...params}
                                                        id={'shift'}
                                                        name={'shift'}
                                                        placeholder={
                                                            'Select Shift'
                                                        }
                                                        fullWidth={true}
                                                    />
                                                )}
                                            />
                                        </Box>
                                    }
                                </Box>
                            </Box>
                        );
                    })}
                    {!!overlapShifts?.length && (
                        <Typography style={{ color: '#ec4e2b', fontSize: 12 }}>
                            <span style={{ fontWeight: 600 }}>
                                Shift Overlap:
                            </span>{' '}
                            Remove Overlapping Shift and adjust the shift
                            timing.
                        </Typography>
                    )}
                    <Divider style={{ margin: '16px 0' }} />
                    {!!actions && (
                        <DialogActions>
                            {actions.map((action, index) => (
                                <Box key={index}>{action}</Box>
                            ))}
                        </DialogActions>
                    )}
                </DialogContent>
            </Spinner>
        </Dialog>
    );
}

const mapStateToProps = (state) => ({
    staff: state.staff,
});

const mapDispatchToProps = (dispatch) => ({
    dispatchAssignShiftToStaff: (data, onSuccess, onError) =>
        dispatch(AssignShiftToStaff(data, onSuccess, onError)),
    dispatcGetShiftsByStaffHub: (staffId, data, onSuccess, onError) =>
        dispatch(GetShiftsByStaffHub(staffId, data, onSuccess, onError)),
    dispatcGetAllStaffList: (data, onSuccess, onError) =>
        dispatch(GetAllStaffList(data, onSuccess, onError)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AssignStaffToHubModal);

export function sortTime(obj) {
    obj.sort(function (a, b) {
        let KeyA = minuteValue(moment(a.startTimeEpochMillis).format('HH:mm'));
        let KeyB = minuteValue(moment(b.startTimeEpochMillis).format('HH:mm'));

        if (KeyA < KeyB) return -1;
        else if (KeyA > KeyB) return 1;
        else {
            a = minuteValue(moment(a.endTimeEpochMillis).format('HH:mm'));
            b = minuteValue(moment(b.endTimeEpochMillis).format('HH:mm'));
            if (a < b) return -1;
            if (a > b) return 1;
            return 0;
        }
    });
    return obj;
}

export function minuteValue(time) {
    time = time.split(':');
    return time[0] * 60 + time[1] * 1;
}

export function shiftOverlapValidation(shifts) {
    let overlapList = [];
    const timeSet = sortTime(shifts);
    let current,
        overlapping = false;
    for (let i = 0; i < timeSet.length; i++) {
        let previous = timeSet[i];
        const filteredTimeSet = sortTime(
            timeSet.filter((i) => previous.id !== i.id),
        );
        for (let j = 0; j < filteredTimeSet.length; j++) {
            current = filteredTimeSet[j];
            console.log(
                'current start',
                moment(current.startTimeEpochMillis).format('HH:mm'),
                'previous',
                moment(previous.startTimeEpochMillis).format('HH:mm'),
                moment(previous.endTimeEpochMillis).format('HH:mm'),
                moment(current.startTimeEpochMillis).isBetween(
                    previous.startTimeEpochMillis,
                    previous.endTimeEpochMillis,
                    'HH:mm',
                ),
            );
            console.log(
                'current end',
                moment(current.endTimeEpochMillis).format('HH:mm'),
                'previous',
                moment(previous.startTimeEpochMillis).format('HH:mm'),
                moment(previous.endTimeEpochMillis).format('HH:mm'),
                moment(current.endTimeEpochMillis).isBetween(
                    previous.startTimeEpochMillis,
                    previous.endTimeEpochMillis,
                    'HH:mm',
                ),
            );
            if (
                moment(current.startTimeEpochMillis).isBetween(
                    previous.startTimeEpochMillis,
                    previous.endTimeEpochMillis,
                    'HH:mm',
                ) ||
                moment(current.endTimeEpochMillis).isBetween(
                    previous.startTimeEpochMillis,
                    previous.endTimeEpochMillis,
                    'HH:mm',
                )
            ) {
                overlapList = [...overlapList, current.id, previous.id];
                overlapping = true;
            }
        }
    }
    return { overlapList, overlapping };
}
