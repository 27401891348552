// src/DynamicComponent.tsx
import { Box, Divider, Typography } from '@material-ui/core';
import React from 'react';

// Define the types for the dynamic props
type TabTitleProps = {
    [key: string]: [value: any];
};

// Functional component with dynamic props
const TabTitle: React.FC<TabTitleProps> = ({ title }) => {
    return (
        <Box
            width={'100%'}
            display={'flex'}
            flexDirection={'row'}
            alignItems={'center'}
            mb={2}
        >
            {!!title && (
                <Typography
                    style={{
                        fontSize: '20px',
                        fontWeight: 700,
                        color: '#14112d',
                        margin: 0,
                    }}
                    variant="h6"
                    component="span"
                    gutterBottom
                >
                    {title}
                </Typography>
            )}
            <Box component={'span'} flex={1} flexGrow={1}>
                <Divider style={{ marginLeft: 10 }} />
            </Box>
        </Box>
    );
};

export default TabTitle;
