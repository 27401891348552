import React from 'react';
// import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import moment from 'moment';
import { formatCurrency } from '../../../../utils/helper';
import { EmptyIcon } from '../../../../assets/svg';
import Currency from '../../../../components/UI/Currency/Currency';
import { Tooltip } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    tableRoot: {
        fontWeight: 600,
        padding: '1px 1px 0',
        borderRadius: '5px',
        border: '1px #ebedf8 solid',
        '& .MuiTableCell-sizeSmall': {
            padding: '0.25rem 0.45rem',
            fontSize: '14px',
            fontWeight: '600 !important',
        },
        '& .MuiTableRow-root:nth-child(2)': {
            backgroundColor: '#f3f5f7',
        },
    },

    rootMonthRow: {
        '& .MuiTableCell-sizeSmall': {
            padding: '0.5rem !important',
            fontSize: '14px !important',
            fontWeight: '700 !important',
        },
        '& > *': {
            borderBottom: 'unset',
        },
        '& > .MuiTableCell-body': {
            color: '#000',
            borderLeft: '1px #ebedf8 solid',
            '&:nth-child(1)': {
                // borderLeft: 'none',
            },
            '&:nth-child(2)': {
                // borderLeft: 'none',
            },
        },
        '& > .edit-icon': {
            color: theme.palette.text.primary,
            width: 1,
        },
        '& > .delete-icon': {
            color: theme.palette.text.error,
            width: 1,
        },
        '& > .month-title': {
            fontSize: 14,
            fontWeight: 600,
        },
        '& > .month-payment': {
            fontSize: 14,
            fontWeight: 600,
            color: theme.palette.text.primary,
            textAlign: 'right',
        },
        '& > .month-earning': {
            fontSize: 14,
            fontWeight: 600,
            color: '#4caf50',
            textAlign: 'right',
        },
    },
    rootMonthExpanedRow: {
        '& .table-container': {
            border: '1px #ebedf8 solid',
            borderRadius: 5,
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            borderRight: 'none',
            marginBottom: 10,
        },
    },
    rootPayRow: {
        '& > *': {
            borderBottom: 'unset',
        },
        '& > .MuiTableCell-body': {
            color: '#000',
            borderLeft: '1px #ebedf8 solid',
            borderRight: '1px #ebedf8 solid',
            '&:nth-child(1)': {
                // borderLeft: 'none',
            },
            '&:nth-child(2)': {
                // borderLeft: 'none',
            },
        },
        '& > .edit-icon': {
            color: theme.palette.text.primary,
            width: 1,
        },
        '& > .delete-icon': {
            color: theme.palette.text.error,
            width: 1,
        },
        '& > .pay-title': {
            fontSize: 14,
            fontWeight: 600,
        },
        '& > .pay-earning': {
            fontSize: 14,
            fontWeight: 600,
            color: '#4caf50',
            textAlign: 'right',
        },
        '& > .pay-payment': {
            fontSize: 14,
            fontWeight: 600,
            color: theme.palette.text.primary,
            textAlign: 'right',
        },
    },
    rootPayExpanedRow: {
        '& .table-container': {
            borderRadius: 0,
            border: '1px #ebedf8 solid',
            borderLeft: '1px #fff solid',
            // borderRight: 'unset',
            borderBottom: 'unset',
            marginBottom: 0,
        },
        // '& .MuiTableRow-root': {
        //     // backgroundColor: '#ebedf8',
        // },
        '& .MuiTableRow-root:nth-of-type(even)': {
            backgroundColor: '#e5e8fb',
        },
        '& .MuiTableRow-root:nth-of-type(odd)': {
            backgroundColor: '#ebedf8',
        },
    },
    rootPayDetailRow: {
        '& .MuiTableCell-sizeSmall': {
            padding: '0 0.45rem !important',
            fontSize: '12px !important',
            fontWeight: '600 !important',
        },

        '& > .MuiTableCell-body': {
            color: '#000',
            borderLeft: '1px #fff solid',
            borderRight: '1px #fff solid',
            '&:nth-child(1)': {
                // borderLeft: 'none',
            },
            '&:nth-child(2)': {
                // borderLeft: 'none',
            },
        },
        '& > .edit-icon': {
            color: theme.palette.text.primary,
            width: 1,
        },
        '& > .delete-icon': {
            color: theme.palette.text.error,
            width: 1,
        },
        '& > .pay-title': {
            fontSize: 14,
            fontWeight: 600,
        },
        '& > .pay-earning': {
            fontSize: 14,
            fontWeight: 600,
            color: '#4caf50',
            textAlign: 'right',
        },
        '& > .pay-payment': {
            fontSize: 14,
            fontWeight: 600,
            color: theme.palette.text.primary,
            textAlign: 'right',
        },
    },
}));

function MonthRow({ row, permissions, ...props }) {
    const [open, setOpen] = React.useState(false);
    const classes = useStyles();

    console.log('row', row);
    return (
        <React.Fragment>
            <TableRow
                className={`${classes.rootMonthRow}`}
                style={{
                    backgroundColor: row.index % 2 === 0 ? '#f3f5f7' : '#fff',
                }}
            >
                <TableCell style={{ width: '1px' }}>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" className="month-title" scope="row">
                    {row.displayName}
                </TableCell>
                <TableCell
                    style={{
                        width: '12.5%',
                        borderColor: open ? '#fff' : '#ebedf8',
                    }}
                    className="month-earning"
                    align="left"
                >
                    <Currency amount={row.monthTotalEarnings} />
                </TableCell>
                <TableCell
                    style={{
                        width: '12.5%',
                        borderColor: open ? '#fff' : '#ebedf8',
                    }}
                    className="month-payment"
                    align="left"
                >
                    <Currency amount={row.monthTotalPayments} />
                </TableCell>
                <TableCell
                    className="edit-icon"
                    style={{ borderColor: open ? '#fff' : '#ebedf8' }}
                    align="right"
                >
                    <IconButton
                        color="inherit"
                        style={{ visibility: 'hidden' }}
                    >
                        <EditOutlinedIcon />
                    </IconButton>
                </TableCell>
                <TableCell
                    className="delete-icon"
                    style={{ borderColor: open ? '#fff' : '#ebedf8' }}
                    align="right"
                >
                    <Tooltip title="Delete" placement="top">
                        <IconButton
                            color="inherit"
                            style={{ visibility: 'hidden' }}
                        >
                            <DeleteOutlinedIcon />
                        </IconButton>
                    </Tooltip>
                </TableCell>
            </TableRow>
            <TableRow
                className={classes.rootMonthExpanedRow}
                style={{
                    backgroundColor: row.index % 2 === 0 ? '#f3f5f7' : '#fff',
                }}
            >
                <TableCell
                    style={{
                        paddingBottom: 0,
                        paddingTop: 0,
                        padding: '0 0 0px 4%',
                    }}
                    colSpan={6}
                >
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <TableContainer className="table-container">
                            <Table size="small" aria-label="collapsible table">
                                <TableBody>
                                    {!!row?.earnings?.lineItems?.length && (
                                        <PayRow
                                            row={row.earnings}
                                            name={'Earnings'}
                                            permissions={permissions}
                                            {...props}
                                        />
                                    )}
                                    {!!row?.deductions?.lineItems?.length && (
                                        <PayRow
                                            row={row.deductions}
                                            name="Deductions"
                                            permissions={permissions}
                                            {...props}
                                        />
                                    )}
                                    {!!row?.allowances?.lineItems?.length && (
                                        <PayRow
                                            row={row.allowances}
                                            name="Allowances"
                                            permissions={permissions}
                                            {...props}
                                        />
                                    )}
                                    {!!row?.payments?.lineItems?.length && (
                                        <PayRow
                                            row={row.payments}
                                            name="Payments"
                                            permissions={permissions}
                                            {...props}
                                        />
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

function PayRow({ row, name, permissions, ...props }) {
    const [open, setOpen] = React.useState(false);
    const classes = useStyles();

    console.log('PayRow', row);
    return (
        <React.Fragment>
            <TableRow
                className={classes.rootPayRow}
                key={1}
                style={{
                    backgroundColor:
                        row.index % 2 === 0 ? '#f3f5f7' : '#ffffff',
                }}
            >
                <TableCell style={{ width: '1px' }}>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" className={'pay-title'} scope="row">
                    {name}
                </TableCell>
                <TableCell
                    style={{
                        width: '13.175%',
                        borderColor: '#ebedf8',
                        borderLeft: !['Payments'].includes(name) ? 'auto' : 0,
                        color: ['Deductions'].includes(name)
                            ? '#f44336'
                            : '#4caf50',
                    }}
                    className="pay-earning"
                    align="left"
                >
                    {!['Payments'].includes(name) && (
                        <Currency
                            isNegative={['Deductions'].includes(name)}
                            amount={row.totalAmount}
                        />
                    )}
                </TableCell>
                <TableCell
                    style={{
                        width: '13.175%',
                        borderColor: '#ebedf8',
                        borderLeft: ['Payments'].includes(name) ? 'auto' : 0,
                    }}
                    className="pay-payment"
                    align="left"
                >
                    {['Payments'].includes(name) && (
                        <Currency amount={row.totalAmount} />
                    )}
                </TableCell>
                <TableCell
                    className="edit-icon"
                    style={{ borderColor: '#ebedf8' }}
                    align="right"
                >
                    <IconButton
                        color="inherit"
                        style={{ visibility: 'hidden' }}
                    >
                        <EditOutlinedIcon />
                    </IconButton>
                </TableCell>
                <TableCell
                    className="delete-icon"
                    style={{ borderColor: '#ebedf8' }}
                    align="right"
                >
                    <Tooltip title="Delete" placement="top">
                        <IconButton
                            color="inherit"
                            style={{ visibility: 'hidden' }}
                        >
                            <DeleteOutlinedIcon />
                        </IconButton>
                    </Tooltip>
                </TableCell>
            </TableRow>
            <TableRow className={classes.rootPayExpanedRow}>
                <TableCell
                    style={{ paddingBottom: 0, paddingTop: 0, padding: 0 }}
                    colSpan={6}
                >
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <TableContainer className="table-container">
                            <Table
                                size="small"
                                aria-label="collapsible table"
                                className={classes.customSmallCells}
                            >
                                <TableBody>
                                    {row?.lineItems?.length ? (
                                        row?.lineItems?.map((data, index) => {
                                            switch (name) {
                                                case 'Earnings':
                                                    return (
                                                        <EarningRow
                                                            data={data}
                                                            index={index}
                                                            toggleWorkModal={
                                                                props.toggleWorkModal
                                                            }
                                                            deleteModal={
                                                                props.deleteModal
                                                            }
                                                            permissions={
                                                                permissions
                                                            }
                                                        />
                                                    );
                                                case 'Deductions':
                                                    return (
                                                        <DeductionRow
                                                            data={data}
                                                            index={index}
                                                            toggleDeductionModal={
                                                                props.toggleDeductionModal
                                                            }
                                                            deleteModal={
                                                                props.deleteModal
                                                            }
                                                            permissions={
                                                                permissions
                                                            }
                                                        />
                                                    );
                                                case 'Allowances':
                                                    return (
                                                        <AllowancesRow
                                                            data={data}
                                                            index={index}
                                                            toggleAllowanceModal={
                                                                props.toggleAllowanceModal
                                                            }
                                                            deleteModal={
                                                                props.deleteModal
                                                            }
                                                            permissions={
                                                                permissions
                                                            }
                                                        />
                                                    );
                                                case 'Payments':
                                                    return (
                                                        <PaymentRow
                                                            data={data}
                                                            index={index}
                                                            togglePaymentModal={
                                                                props.togglePaymentModal
                                                            }
                                                            deleteModal={
                                                                props.deleteModal
                                                            }
                                                            permissions={
                                                                permissions
                                                            }
                                                        />
                                                    );
                                                default:
                                                    return <EmptyRow />;
                                            }
                                        })
                                    ) : (
                                        <EmptyRow />
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

const PaymentRow = ({ data, permissions, ...props }) => {
    const classes = useStyles();
    console.log(props);
    return (
        <TableRow key={data.index} className={classes.rootPayDetailRow}>
            <TableCell style={{ width: '1px' }}>
                <IconButton
                    aria-label="expand row"
                    size="small"
                    style={{
                        visibility: 'hidden',
                    }}
                >
                    <ArrowDropDownIcon />
                </IconButton>
            </TableCell>
            {/* {props?.lineDisplay?.length &&
                props?.lineDisplay.map((item, index) => (
                    <TableCell
                        component="th"
                        className={'pay-title'}
                        scope="row"
                        style={{ borderLeft: 0 }}
                    >
                        {item}
                    </TableCell>
                ))} */}
            <TableCell
                component="th"
                className={'pay-title'}
                align="left"
                scope="row"
                style={{
                    width: '10rem',
                }}
            >
                {moment(data.dateEpochMillis).format('DD MMMM yyyy')}
            </TableCell>
            <TableCell
                component="th"
                className={'pay-title'}
                scope="row"
                style={{ borderLeft: 0, width: '22rem' }}
                align="left"
            >
                {data.notes ?? '-'}
            </TableCell>
            <TableCell
                component="th"
                className={'pay-title'}
                scope="row"
                style={{ borderLeft: 0 }}
                align="left"
            >
                {!!data.paymentData ? `Ref: ${data.paymentData}` : ''}
            </TableCell>

            <TableCell
                style={{ width: '13.175%', borderLeft: 0 }}
                className={'pay-earning'}
                align="left"
            ></TableCell>
            <TableCell
                style={{ width: '13.175%' }}
                className={'pay-payment'}
                align="left"
            >
                <Currency amount={data.amount} />
            </TableCell>
            <TableCell className="edit-icon" align="right">
                <Tooltip title="Edit" placement="top">
                    <IconButton
                        color="inherit"
                        onClick={(e) => props.togglePaymentModal(data)}
                        disabled={!permissions.update}
                    >
                        <EditOutlinedIcon />
                    </IconButton>
                </Tooltip>
            </TableCell>
            <TableCell className="delete-icon" align="right">
                <Tooltip title="Delete" placement="top">
                    <IconButton
                        color="inherit"
                        onClick={(e) => props.deleteModal('PAYMENT', data)}
                        disabled={!permissions.delete}
                    >
                        <DeleteOutlinedIcon />
                    </IconButton>
                </Tooltip>
            </TableCell>
        </TableRow>
    );
};
const EarningRow = ({ data, permissions, ...props }) => {
    const classes = useStyles();
    return (
        <TableRow key={data.index} className={classes.rootPayDetailRow}>
            <TableCell style={{ width: '1px' }}>
                <IconButton
                    aria-label="expand row"
                    size="small"
                    style={{
                        visibility: 'hidden',
                    }}
                >
                    <ArrowDropDownIcon />
                </IconButton>
            </TableCell>
            <TableCell
                component="th"
                className={'pay-title'}
                align="left"
                scope="row"
                style={{
                    width: '10rem',
                }}
            >
                {moment(data.dateEpochMillis).format('DD MMMM yyyy')}
            </TableCell>
            {data?.paymentData && (
                <TableCell
                    component="th"
                    className={'pay-title'}
                    scope="row"
                    style={{ borderLeft: 0 }}
                    align="left"
                >
                    {data.paymentData}
                </TableCell>
            )}
            <TableCell
                component="th"
                className={'pay-title'}
                scope="row"
                style={{ borderLeft: 0 }}
                align="left"
            >
                {data.notes ?? '-'}
            </TableCell>
            <TableCell
                style={{ width: '13.175%' }}
                className={'pay-earning'}
                align="left"
            >
                <Currency amount={data.amount} />
            </TableCell>
            <TableCell
                style={{ width: '13.175%', borderLeft: 0 }}
                className={'pay-payment'}
                align="left"
            ></TableCell>
            <TableCell className="edit-icon" align="right">
                <Tooltip title="Edit" placement="top">
                    <IconButton
                        color="inherit"
                        onClick={(e) => props.toggleWorkModal(data)}
                        disabled={!data?.id || !permissions.update}
                    >
                        <EditOutlinedIcon />
                    </IconButton>
                </Tooltip>
            </TableCell>
            <TableCell className="delete-icon" align="right">
                <Tooltip title="Delete" placement="top">
                    <IconButton
                        color="inherit"
                        onClick={(e) => props.deleteModal('EARNING', data)}
                        disabled={!data?.id || !permissions.delete}
                    >
                        <DeleteOutlinedIcon />
                    </IconButton>
                </Tooltip>
            </TableCell>
        </TableRow>
    );
};
const AllowancesRow = ({ data, permissions, ...props }) => {
    const classes = useStyles();
    return (
        <TableRow key={data.index} className={classes.rootPayDetailRow}>
            <TableCell style={{ width: '1px' }}>
                <IconButton
                    aria-label="expand row"
                    size="small"
                    style={{
                        visibility: 'hidden',
                    }}
                >
                    <ArrowDropDownIcon />
                </IconButton>
            </TableCell>
            <TableCell
                component="th"
                className={'pay-title'}
                align="left"
                scope="row"
                style={{
                    width: '10rem',
                }}
            >
                {moment(data.dateEpochMillis).format('DD MMMM yyyy')}
            </TableCell>
            {data?.paymentData && (
                <TableCell
                    component="th"
                    className={'pay-title'}
                    scope="row"
                    style={{ borderLeft: 0 }}
                    align="left"
                >
                    {data.paymentData}
                </TableCell>
            )}
            <TableCell
                component="th"
                className={'pay-title'}
                scope="row"
                style={{ borderLeft: 0 }}
                align="left"
            >
                {data.notes ?? '-'}
            </TableCell>
            <TableCell
                style={{ width: '13.175%' }}
                className={'pay-earning'}
                align="left"
            >
                <Currency amount={data.amount} />
            </TableCell>
            <TableCell
                style={{ width: '13.175%', borderLeft: 0 }}
                className={'pay-payment'}
                align="left"
            ></TableCell>
            <TableCell className="edit-icon" align="right">
                <Tooltip title="Edit" placement="top">
                    <IconButton
                        color="inherit"
                        onClick={(e) => props.toggleAllowanceModal(data)}
                        disabled={!permissions.update}
                    >
                        <EditOutlinedIcon />
                    </IconButton>
                </Tooltip>
            </TableCell>
            <TableCell className="delete-icon" align="right">
                <Tooltip title="Delete" placement="top">
                    <IconButton
                        color="inherit"
                        onClick={(e) => props.deleteModal('ALLOWANCE', data)}
                        disabled={!permissions.delete}
                    >
                        <DeleteOutlinedIcon />
                    </IconButton>
                </Tooltip>
            </TableCell>
        </TableRow>
    );
};
const DeductionRow = ({ data, permissions, ...props }) => {
    const classes = useStyles();
    return (
        <TableRow key={data.index} className={classes.rootPayDetailRow}>
            <TableCell style={{ width: '1px' }}>
                <IconButton
                    aria-label="expand row"
                    size="small"
                    style={{
                        visibility: 'hidden',
                    }}
                >
                    <ArrowDropDownIcon />
                </IconButton>
            </TableCell>
            <TableCell
                component="th"
                className={'pay-title'}
                align="left"
                scope="row"
                style={{
                    width: '10rem',
                }}
            >
                {moment(data.dateEpochMillis).format('DD MMMM yyyy')}
            </TableCell>
            {data?.paymentData && (
                <TableCell
                    component="th"
                    className={'pay-title'}
                    scope="row"
                    style={{ borderLeft: 0 }}
                    align="left"
                >
                    {data.paymentData}
                </TableCell>
            )}
            <TableCell
                component="th"
                className={'pay-title'}
                scope="row"
                style={{ borderLeft: 0 }}
                align="left"
            >
                {data.notes ?? '-'}
            </TableCell>
            <TableCell
                style={{ width: '13.175%', color: '#f44336' }}
                className={'pay-earning'}
                align="left"
            >
                <Currency isNegative={true} amount={data.amount} />
            </TableCell>
            <TableCell
                style={{ width: '13.175%', borderLeft: 0 }}
                className={'pay-payment'}
                align="left"
            ></TableCell>
            <TableCell className="edit-icon" align="right">
                <Tooltip title="Edit" placement="top">
                    <IconButton
                        color="inherit"
                        onClick={(e) => props.toggleDeductionModal(data)}
                        disabled={!permissions.update}
                    >
                        <EditOutlinedIcon />
                    </IconButton>
                </Tooltip>
            </TableCell>
            <TableCell className="delete-icon" align="right">
                <Tooltip title="Delete" placement="top">
                    <IconButton
                        color="inherit"
                        onClick={(e) => props.deleteModal('DEDUCTION', data)}
                        disabled={!permissions.delete}
                    >
                        <DeleteOutlinedIcon />
                    </IconButton>
                </Tooltip>
            </TableCell>
        </TableRow>
    );
};

const EmptyRow = (props) => {
    const classes = useStyles();
    return (
        <TableRow key={props.index} className={classes.rootPayDetailRow}>
            <TableCell
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <EmptyIcon />
            </TableCell>
        </TableRow>
    );
};

// Row.propTypes = {
//     row: PropTypes.shape({
//         calories: PropTypes.number.isRequired,
//         carbs: PropTypes.number.isRequired,
//         fat: PropTypes.number.isRequired,
//         history: PropTypes.arrayOf(
//             PropTypes.shape({
//                 amount: PropTypes.number.isRequired,
//                 customerId: PropTypes.string.isRequired,
//                 date: PropTypes.string.isRequired,
//             }),
//         ).isRequired,
//         name: PropTypes.string.isRequired,
//         price: PropTypes.number.isRequired,
//         protein: PropTypes.number.isRequired,
//     }).isRequired,
// };

// const rows = [
//     createData('May Payment', 151009),
//     createData('March Closing Payment', 237),
//     createData('April Closing Payment', 262),
// ];

export default function ExpanedableTable({
    columns,
    rows,
    permissions,
    ...props
}) {
    const classes = useStyles();
    return (
        <TableContainer className={classes.tableRoot} component={Box}>
            <Table size="small" aria-label="collapsible table">
                {!!columns && (
                    <TableHead>
                        <TableRow>
                            <TableCell />
                            {columns.map((col, index) => {
                                return (
                                    <TableCell key={index}>
                                        {col.title}
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    </TableHead>
                )}
                <TableRow
                    className={`${classes.rootMonthRow}`}
                    style={{
                        backgroundColor: '#ebedf8',
                    }}
                >
                    <TableCell style={{ width: '1px' }}>
                        <IconButton aria-label="expand row" size="small">
                            {/* {open ? <ArrowDropDownIcon /> : <ArrowRightIcon />} */}
                        </IconButton>
                    </TableCell>
                    <TableCell
                        component="th"
                        className="month-title"
                        scope="row"
                    ></TableCell>
                    <TableCell
                        style={{
                            width: '12.5%',
                            borderColor: '#ebedf8',
                            fontSize: 16,
                        }}
                        className="month-earning"
                        align="left"
                    >
                        Earnings
                    </TableCell>
                    <TableCell
                        style={{
                            width: '12.5%',
                            borderColor: '#ebedf8',
                            fontSize: 16,
                        }}
                        className="month-payment"
                        align="left"
                    >
                        Payments
                    </TableCell>
                    <TableCell
                        className="edit-icon"
                        style={{ borderColor: '#ebedf8' }}
                        align="right"
                    >
                        <EditOutlinedIcon style={{ visibility: 'hidden' }} />
                    </TableCell>
                    <TableCell
                        className="delete-icon"
                        style={{ borderColor: '#ebedf8' }}
                        align="right"
                    >
                        <DeleteOutlinedIcon style={{ visibility: 'hidden' }} />
                    </TableCell>
                </TableRow>

                <TableBody>
                    {rows?.map((row, index) => (
                        <MonthRow
                            key={row.displayName}
                            row={{ ...row, index }}
                            permissions={permissions}
                            {...props}
                        />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
