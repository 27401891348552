import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import './styles.scss';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexGrow: 1,
        justifyContent: 'space-between',
        '& .MuiTypography-body1': {
            fontFamily: 'Nunito',
            fontSize: '0.8rem',
            fontWeight: 'bold',
        },
    },
}));

function Tabbar({
    tabIndex,
    lastIndex,
    tabs,
    style,
    textStyle,
    boxStyle,
    onChange,
    ...props
}) {
    const classes = useStyles();

    useEffect(() => {
        return () => {};
    }, []);
    const handleChange = (newValue, isModal) => {
        onChange && onChange(newValue, isModal ?? false);
    };
    return (
        <Box className={`tabbar ${classes.root}`} style={!!style ? style : {}}>
            {tabs?.map((tab, index) => (
                <Box
                    onClick={(e) =>
                        !tab.disabled ? handleChange(index, tab.isModal) : {}
                    }
                    className={`tab ${index === tabIndex ? 'active' : ''} ${
                        tab.disabled ? 'disabled' : ''
                    }`}
                    style={!!boxStyle ? boxStyle : {}}
                >
                    {tab?.icon}
                    <Typography
                        className={`tab-label`}
                        variant="body1"
                        style={!!textStyle ? textStyle : {}}
                    >
                        {tab.label}
                    </Typography>
                </Box>
            ))}
        </Box>
    );
    // return (
    //     <Grid className={`tabbar ${classes.root}`} container spacing={2}>
    //         {tabs.map((tab, index) => (
    //             <Grid key={index} item md={2} sm={12} xs={12}>
    //                 <Box
    //                     onClick={(e) => handleChange(index)}
    //                     className={`tab ${index === tabIndex && 'active'}`}
    //                 >
    //                     {tab.icon}
    //                     <Typography
    //                         style={{
    //                             fontFamily: 'Nunito',
    //                             fontSize: '0.8rem',
    //                             fontWeight: 'bold',
    //                         }}
    //                         variant="body1"
    //                     >
    //                         {tab.label}
    //                     </Typography>
    //                 </Box>
    //                 {/* <Paper className={classes.paper}>
    //                 </Paper> */}
    //             </Grid>
    //         ))}
    //     </Grid>
    // );
}

export default Tabbar;
