import React, { useState, useEffect } from 'react';
import Modal from './Modal';
import { CustomTextField } from '../Textfield';
import { makeStyles } from '@material-ui/core/styles';
import { Button, MenuItem, withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { updateDistance } from '../../store/actions/attendanceActionCreator';
import { LightBoxImage } from '../UI';
import moment from 'moment';
import { getReasonMenuList } from '../../store/actions/attendanceActionCreator';
import { getPermissions } from '../../utils/helper';

const useStyles = makeStyles({
    inputDisabled: {
        fontWeight: 'bold',
        paddingRight: 0,
    },
    inputDisabledLabel: {
        fontWeight: 'bold',
    },
});

function UpdateDistanceModal({
    open,
    onClose,
    data,
    reasonMenu,
    attendanceReasons,
    dispatchUpdateDistance,
    dispatchGetReasonMenuList,
    ...props
}) {
    const classes = useStyles();

    const [isOpen, toggleModal] = useState(open);
    const [isloading, toggleloading] = useState(false);
    const handleClose = () => {
        // Setting form data to null before closing
        setState({
            distance: '',
            reason: '',
            notes: '',
            userId: '',
            dateEpochMillis: '',
        });
        setStateStatus({
            distance: {},
            reason: {},
            notes: {},
        });

        toggleModal(!isOpen);
        onClose && onClose();
    };
    useEffect(() => {
        toggleloading(true);
        dispatchGetReasonMenuList(
            {
                type: 'DISTANCE_REASON',
            },
            (success) => {
                console.log(`DISTANCE_REASON static values success:`);
                toggleloading(false);
            },
            (failed) => {
                console.log(`DISTANCE_REASON static values failed`);
                toggleloading(false);
            },
        );
        setState({
            distance: data?.correctedGpsReading
                ? data?.correctedGpsReading ?? ''
                : '',
            reason: data?.reason ?? '',
            notes: data?.notes ?? '',
            userId: data?.staffId,
            dateEpochMillis: data?.dateEpochMillis,
        });
    }, [data]);
    const [state, setState] = useState({
        distance: '',
        reason: '',
        notes: '',
        userId: data?.staffId,
        dateEpochMillis: data?.dateEpochMillis,
    });
    const [stateStatus, setStateStatus] = useState({
        distance: {},
        reason: {},
        notes: {},
        isValuesChanges: false,
    });
    const handleInputChange = (value, key) => {
        setState((state) => ({
            ...state,
            [key]: value,
        }));
    };

    const deleteCorrectedDistance = () => {
        invokeUpdateDistance({
            distance: null,
            reason: null,
            notes: null,
            userId: state?.userId,
            dateEpochMillis: state?.dateEpochMillis,
        });
    };

    const invokeUpdateDistance = async (request) => {
        await toggleloading(true);
        dispatchUpdateDistance(
            request,
            async () => {
                console.log('Update distance SUCCESS');
                await toggleloading(false);
                handleClose();
            },
            async () => {
                await toggleloading(false);
                console.log('Update distance FAILED');
            },
        );
    };

    const formValidator = {
        isEmptyOrSpaces: (str) => {
            return str === null || str.match(/^ *$/) !== null;
        },
        checkValuesChanged: (value, property) => {
            if (data) {
                if (
                    data?.correctedGpsReading == state.distance &&
                    (property !== 'reason' || data.reason === value) &&
                    data.notes === state.notes
                ) {
                    setStateStatus((prev) => ({
                        ...prev,
                        isValuesChanges: false,
                    }));
                } else {
                    setStateStatus((prev) => ({
                        ...prev,
                        isValuesChanges: true,
                    }));
                }
            } else {
                setStateStatus((prev) => ({
                    ...prev,
                    isValuesChanges: true,
                }));
            }
        },
        distance: (value) => {
            if (value && value.match(/^\d*\.?\d*$/)) {
                setStateStatus((prev) => ({
                    ...prev,
                    distance: {},
                }));
            } else {
                setStateStatus((prev) => ({
                    ...prev,
                    distance: {
                        status: true,
                        error: 'Invalid KM',
                    },
                }));
            }
            formValidator.checkValuesChanged();
        },
        reason: (value) => {
            if (value) {
                if (
                    value &&
                    (value + '').toLowerCase() === 'other issue' &&
                    formValidator.isEmptyOrSpaces(state.notes)
                ) {
                    setStateStatus((prev) => ({
                        ...prev,
                        notes: {
                            status: true,
                            error: 'Notes is mandatory when selected OTHER ISSUE',
                        },
                    }));
                } else {
                    setStateStatus((prev) => ({
                        ...prev,
                        notes: {},
                        reason: {},
                    }));
                }
            } else {
                setStateStatus((prev) => ({
                    ...prev,
                    reason: {
                        status: true,
                        error: 'Notes is mandatory when selected OTHER ISSUE',
                    },
                }));
            }
            formValidator.checkValuesChanged(value, 'reason');
        },
        notes: (value) => {
            if (
                state.reason &&
                (state.reason + '').toLowerCase() === 'other issue' &&
                formValidator.isEmptyOrSpaces(value)
            ) {
                setStateStatus((prev) => ({
                    ...prev,
                    notes: {
                        status: true,
                        error: 'Notes is mandatory when selected OTHER ISSUE',
                    },
                }));
            } else {
                setStateStatus((prev) => ({
                    ...prev,
                    notes: {},
                    reason: {},
                }));
            }
            formValidator.checkValuesChanged();
        },
    };

    const actions = [
        <Button
            key={1}
            variant="contained"
            className={`saveButton`}
            style={
                getPermissions('report')?.delete && data?.correctedGpsReading
                    ? {
                          backgroundColor: '#f44336',
                          color: '#fff',
                      }
                    : {}
            }
            disabled={!getPermissions('report')?.delete || !data?.reason}
            disableElevation
            onClick={async () => deleteCorrectedDistance()}
        >
            {'Delete'}
        </Button>,
        <Button
            key={2}
            variant="contained"
            color="primary"
            className={`updateButton`}
            disabled={
                !getPermissions('report')?.update ||
                stateStatus.distance.status ||
                !state.reason ||
                stateStatus.notes.status ||
                !state.userId ||
                !state.dateEpochMillis ||
                !stateStatus.isValuesChanges
            }
            disableElevation
            onClick={async () => invokeUpdateDistance(state)}
        >
            {'Update'}
        </Button>,
    ];

    const StyledMenuItem = withStyles((theme) => ({
        root: {
            margin: '0 8px',
            border: '0 !important',
            borderRadius: '8px',
            '&:hover': {
                backgroundColor: 'rgba(67, 65, 158, 0.08)',
                color: '#43419e',
                borderRadius: '8px',
            },
        },
    }))(MenuItem);

    return (
        <Modal
            open={isOpen}
            onClose={handleClose}
            title={'Update Distance'}
            subtitle={`${data?.name ?? ``} | ${data?.hubName ?? ``} | ${
                data?.dateEpochMillis
                    ? moment(data?.dateEpochMillis)?.format('DD-MMM-yyyy')
                    : ``
            } `}
            subtitleStyle={{
                fontSize: 14,
                fontWeight: 600,
            }}
            maxWidth={'sm'}
            actions={actions}
            loading={isloading}
        >
            <hr style={{ marginBottom: '20px' }} className="MuiDivider-root" />
            <CustomTextField
                disabled
                InputProps={{
                    classes: { disabled: classes.inputDisabledLabel },
                }}
                InputLabelProps={{
                    classes: { disabled: classes.inputDisabledLabel },
                    style: {
                        color: 'black',
                    },
                }}
                fullWidth={true}
                label="GPS KM Reading"
                defaultValue={data?.gpsReading ? `${data.gpsReading}KM` : `0KM`}
                style={{
                    marginBottom: '16px',
                    width: '48%',
                    marginRight: 16,
                    border: 'none',
                }}
            />
            <CustomTextField
                disabled
                InputProps={{
                    classes: { disabled: classes.inputDisabled },
                    endAdornment: (
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                            }}
                        >
                            {data?.punchIn?.kmS3ImageUrl && (
                                <LightBoxImage
                                    containerStyles={{
                                        width: 40,
                                        height: 40,
                                        marginRight: 5,
                                    }}
                                    imageStyles={{
                                        width: 40,
                                        height: 40,
                                        margin: 'auto',
                                        borderRadius: '8px',
                                        objectFit: 'contain',
                                    }}
                                    source={data?.punchIn?.kmS3ImageUrl}
                                />
                            )}
                            {data?.punchOut?.kmS3ImageUrl && (
                                <LightBoxImage
                                    containerStyles={{
                                        width: 40,
                                        height: 40,
                                        marginRight: 5,
                                    }}
                                    imageStyles={{
                                        width: 40,
                                        height: 40,
                                        margin: 'auto',
                                        borderRadius: '8px',
                                        objectFit: 'contain',
                                    }}
                                    source={data?.punchOut?.kmS3ImageUrl}
                                />
                            )}
                        </div>
                    ),
                }}
                InputLabelProps={{
                    classes: { disabled: classes.inputDisabledLabel },
                    style: {
                        color: 'black',
                    },
                }}
                fullWidth={true}
                label="Odometer Reading"
                defaultValue={
                    data?.punchIn?.kmReading && data?.punchOut?.kmReading
                        ? `${(
                              data.punchOut.kmReading - data.punchIn.kmReading
                          ).toFixed(2)}KM`
                        : `0KM`
                }
                style={{
                    marginBottom: '16px',
                    width: '49%',
                }}
            />
            <CustomTextField
                fullWidth={true}
                label="Manual KM Reading"
                value={state?.distance}
                onChange={(e) => handleInputChange(e.target.value, 'distance')}
                onBlur={(e) => formValidator.distance(e.target.value)}
                error={stateStatus?.distance?.status}
                helperText={stateStatus?.distance?.error}
                style={{ marginBottom: '16px' }}
            />
            <CustomTextField
                fullWidth={true}
                select
                label="Reason"
                value={state?.reason}
                onChange={(e) => {
                    handleInputChange(e.target.value, 'reason');
                    formValidator.reason(e.target.value);
                }}
                error={stateStatus?.reason?.status}
                helperText={stateStatus?.reason?.error}
                style={{ marginBottom: '16px' }}
            >
                {attendanceReasons?.staticValues?.length &&
                    attendanceReasons.staticValues.map((reason, index) => (
                        <StyledMenuItem key={index} value={reason.name}>
                            {reason.name}
                        </StyledMenuItem>
                    ))}
            </CustomTextField>
            <CustomTextField
                fullWidth={true}
                label="Notes"
                value={state?.notes}
                onChange={(e) => handleInputChange(e.target.value, 'notes')}
                onBlur={(e) => formValidator.notes(e.target.value)}
                error={stateStatus?.notes?.status}
                helperText={stateStatus?.notes?.error}
                style={{ marginBottom: '16px' }}
                InputProps={{
                    style: {
                        paddingRight: 10,
                        paddingLeft: 10,
                    },
                }}
                rows={2}
                multiline={true}
                variant="outlined"
            />
        </Modal>
    );
}

const mapStateToProps = (state) => ({
    attendanceReasons: state.attendance.attendanceReasons,
});

const mapDispatchToProps = (dispatch) => ({
    dispatchUpdateDistance: (payload, onSuccess, onError) =>
        dispatch(updateDistance(payload, onSuccess, onError)),

    dispatchGetReasonMenuList: (payload, onSuccess, onError) =>
        dispatch(getReasonMenuList(payload, onSuccess, onError)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(UpdateDistanceModal);
