import React from 'react';

export function BatteryIcon({ color, ...props }) {
    return (
        <svg
            width="24"
            height="24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                x="1.681"
                y="7.2"
                width="18"
                height="9.6"
                rx=".72"
                stroke={color}
                strokeWidth="1.44"
            />
            <rect
                x=".961"
                y="6.48"
                width={color === 'red' ? 5 : color === 'green' ? 17 : 10}
                height="11.04"
                rx="1.44"
                fill={color}
            />
            <rect
                x="18.961"
                y="8.641"
                width="2.64"
                height="6.72"
                rx=".48"
                fill={color}
            />
            <rect
                x="20.4"
                y="9.84"
                width="2.64"
                height="4.32"
                rx=".48"
                fill={color}
            />
        </svg>
    );
}
