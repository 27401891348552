import * as constants from './../constants';
import { API_USER_SERVICE_PORT } from '../../config/env/env';

export const addStaffPayment = ({ id, ...data }, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: `/org/transaction/${id}/payment`,
        method: 'POST',
        data,
        success: (response) => {
            console.log('add payment', response);
            return { type: constants.DO_NOTHING, payload: response };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
    },
});

export const editStaffPayment = (
    { id, paymentId, ...data },
    onSuccess,
    onError,
) => ({
    type: constants.API,
    payload: {
        url: `/org/transaction/${id}/payment/${paymentId}`,
        method: 'PUT',
        data: data,
        success: (response) => {
            return { type: constants.DO_NOTHING, payload: response };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
    },
});

export const deleteStaffPayment = (
    { id, paymentId, ...data },
    onSuccess,
    onError,
) => ({
    type: constants.API,
    payload: {
        url: `/org/transaction/${id}/payment/${paymentId}`,
        method: 'DELETE',
        success: (response) => {
            return { type: constants.DO_NOTHING, payload: response };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
    },
});

export const getAllPayment = ({ id, ...data }, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: `/org/transaction/${id}/payment/summary`,
        method: 'POST',
        // data: data,
        success: setPaymentData,
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
    },
});

const setPaymentData = (data) => {
    if (data && data.response) {
        console.log('AuthActionCreator', 'the data is', data);
        return { type: constants.SET_ALL_PAYMENTS, payload: data.response };
    }
};
