export const EV_FILTERS_TYPES = Object.freeze({
    TOTAL: 'TOTAL',
    ACTIVE: 'ACTIVE',
    GPS: 'GPS',
    GPS_ON: 'GPS_ON',
    GPS_OFF: 'GPS_OFF',
    GPS_R_OFF: 'GPS_R_OFF',
});
export const EV_FILTER_CARDS = [
    {
        name: 'Tagged EVs  /  Total no. of EVs',
        type: EV_FILTERS_TYPES.TOTAL,
        filterValue: 'TAGGED_FILTER',
        valueMappingKey: 'totalCount',
    },
    {
        name: 'Active EVs ',
        type: EV_FILTERS_TYPES.ACTIVE,
        filterValue: 'ACTIVE_FILTER',
        valueMappingKey: 'totalCount',
    },
    {
        name: 'GPS enabled EVs',
        type: EV_FILTERS_TYPES.GPS,
        filterValue: 'GPS_FILTER',
        valueMappingKey: 'totalCount',
        xs: 5,
        subFilters: [
            {
                name: 'On',
                type: EV_FILTERS_TYPES.GPS_ON,
                filterValue: '',
                valueMappingKey: 'totalCount',
            },
            {
                name: 'Off',
                type: EV_FILTERS_TYPES.GPS_OFF,
                filterValue: '',
                valueMappingKey: 'totalCount',
            },
            {
                name: 'ROff',
                type: EV_FILTERS_TYPES.GPS_R_OFF,
                filterValue: '',
                valueMappingKey: 'totalCount',
            },
        ],
    },
];
export const tableConfig = {
    columns: [
        {
            name: 'EV Details',
            mappingKey: 'evDetails',
        },
        {
            name: 'Tagged/ Used/ Goal',
            mappingKey: 'taggedUsedGoal',
        },
        {
            name: 'Rider details',
            mappingKey: 'riderDetails',
        },
        {
            name: 'City',
            mappingKey: 'city',
        },
        {
            name: 'LOB',
            mappingKey: 'lob',
        },
        {
            name: 'Order Status',
            mappingKey: 'orderStatus',
        },
        {
            name: 'EV Status',
            mappingKey: 'evStatus',
        },
        {
            name: '',
            mappingKey: 'action',
            actions: true,
        },
    ],
};
export const CHIPS_CLASS_TYPE_MAPPING: any = Object.freeze({
    VERIFIED: 'active',
    KYC_PENDING: 'pending',
    REJECTED: 'reject',
    INACTIVE: 'inactive',
    PENDING: 'pending',
    ACTIVE: 'active',
    KYC_REJECTED: 'reject',
    TO_CX: 'active',
    IN_STORE: 'pending',
    COMPLETED: 'inactive',
    ON_ORDER: 'active',
    NO_ORDER: 'none',
    ON_BREAK: 'none',
    TO_DX: 'pending',
    OPEN: 'pending',
    RESOLVED: 'active',
    NOT_RESOLVED: 'reject',
    ASSIGNED: 'inactive',
    LIVE: 'active',
    D_PENDING: 'pending',
    D_2: 'pending',
    D_3: 'pending',
    D_4: 'reject',
    D_REJECT: 'reject',
    TAGGED: 'activeWO_BG',
    UNTAGGED: 'rejectWO_BG',
    AVAILABLE: 'inactiveWO_BG',
    RELEASED: 'inactiveWO_BG',
    EV_BIKE_DEPLOYED: 'activeWO_BG',
    RIDER_UNTAG_REQUESTED: 'activeWO_BG',
    RIDER_UNTAG_REQUESTED_REJECTED: 'rejectWO_BG',
    RETURN_DATE_UNTAG_PENDING: 'pendingWO_G',
    RETURN_APPROVAL_PENDING: 'pendingWO_G',
    VEHICLE_RETURN_PENDING: 'pendingWO_G',
    VEHICLE_RETURN_REJECTED: 'rejectWO_BG',
    PROFILE_APPROVAL_PENDING: 'pendingWO_G',
    NOT_TAGGED: 'rejectWO_BG',
    EV_REQUESTED: 'pendingWO_G',
    EV_REQUEST_REJECTED: 'rejectWO_BG',
    'EV_REQUEST REJECTED': 'rejectWO_BG',
    EV_REQUEST_APPROVED: 'activeWO_BG',
    'EV_REQUEST APPROVED': 'activeWO_BG',
    EV_DETAILS_SAVED: 'pendingWO_G',
    'EV_DETAILS SAVED': 'pendingWO_G',
    TAG_REQUEST_REJECTED: 'rejectWO_BG',
    'TAG_REQUEST REJECTED': 'rejectWO_BG',
    TAG_REQUEST_APPROVED: 'activeWO_BG',
    'TAG_REQUEST APPROVED': 'activeWO_BG',
    EV_IN_POSSESSION: 'activeWO_BG',
    EV_IN_Possession: 'activeWO_BG',
    EV_RETURN_REQUESTED: 'pendingWO_G',
    'EV_RETURN REQUESTED': 'pendingWO_G',
    EV_RETURN_REJECTED: 'rejectWO_BG',
    'EV_RETURN REJECTED': 'rejectWO_BG',
    EV_RETURN_APPROVED: 'activeWO_BG',
    'EV_RETURN APPROVED': 'activeWO_BG',
    EV_RETURNED: 'inactiveWO_BG',
});

export function generateMockData(count: number) {
    const getRandomValue = (values: any) =>
        values[Math.floor(Math.random() * values.length)];
    const getRandomPercentage = () => Math.floor(Math.random() * 101);
    const getRandomDays = () => Math.floor(Math.random() * 61);
    const getRandomKm = () => Math.floor(Math.random() * 101);

    const mockData = [];

    for (let i = 0; i < count; i++) {
        const mockItem = {
            id: `SES${Math.random().toString(36).substring(7).toUpperCase()}`,
            evNumber: `SES${Math.random()
                .toString(36)
                .substring(7)
                .toUpperCase()}`,
            evVehicleNumber: `KA09${Math.random()
                .toString(36)
                .substring(2, 6)
                .toUpperCase()}`,
            vendor: getRandomValue(['eveez']),
            taggedProject: getRandomValue(['uber']),
            vehicleIgnitionStatus: getRandomValue(['ON', 'OFF', 'ROFF']),
            vehicleBattery: getRandomPercentage().toString(),
            trackingEnabled: Math.random() < 0.5,
            remoteControlEnabled: Math.random() < 0.5,
            evProductivity: {
                taggingDetails: {
                    taggedPercentage: getRandomPercentage(),
                    taggedDays: getRandomDays(),
                    totalNoOfDays: 60,
                },
                usageDetails: {
                    usedPercentage: getRandomPercentage(),
                    usedDays: getRandomDays(),
                    totalNoOfDays: 60,
                },
                goalDetails: {
                    goalPercentage: getRandomPercentage(),
                    goalAchievedDays: getRandomDays(),
                    totalNoOfDays: 60,
                },
                moonlightingDetails: {
                    moonlightingPercentage: getRandomPercentage(),
                    totalOrderKm: getRandomKm(),
                    totalEVKm: getRandomKm(),
                },
            },
            taggedRiderDetails: {
                name: getRandomValue([
                    'barjraj',
                    'ramdin verma',
                    'sharat chandran',
                    'birender mandal',
                    'amit',
                    'kushal',
                    'kasid',
                    'shiv prakash',
                    'vikram singh',
                    'sanjay',
                    'abhi',
                    'ram dutt gupta',
                    'khadak singh',
                    'gurmit singh',
                    'chanderpal',
                    'aman',
                    'khursid',
                    'rajeev',
                    'durgesh',
                    'nahar singh',
                    'Prathees',
                    'sunder paal',
                    'maansingh aswal',
                    'rohit',
                ]),
                contactNumber: `88${Math.floor(Math.random() * 1000000000)
                    .toString()
                    .padStart(8, '0')}`,
            },
            city: getRandomValue(['Bangalore']),
            evStatus: getRandomValue([
                'TAGGED',
                'UN_TAGGED',
                'AVAILABLE',
                'RELEASED',
            ]),
            orderStatus: getRandomValue([
                'live',
                'D-1',
                'D-2',
                'D-3',
                'D-4',
                'D-5',
            ]),
        };

        mockData.push(mockItem);
    }

    return mockData;
}
export const preProcessPayload = (payload: any) => {
    return {
        pageNo: payload?.pageNo,
        pageSize: payload?.pageSize,
        searchText: payload?.searchText ?? '',
        startDate: payload?.startDate ?? '',
        endDate: payload?.endDate ?? '',
        cityIds: payload?.cityIds?.map((item: any) => item?.value) ?? [] ?? [],
        captainsIds: payload?.captainsIds?.map((item: any) => item?.id) ?? [],
        projectIds: payload?.projectIds ?? [],
        vendorIds: payload?.vendorIds ?? [],
        telematicIds: payload?.telematicIds ?? [],
        filter: payload?.filter ?? 'TAGGED_FILTER',
        evStatus: payload?.evStatus ?? 'TAGGED',
    };
};
export const openGoogleMaps = (latitude: any, longitude: any) => {
    if (!!latitude && !!longitude) {
        const url = `https://www.google.com/maps?q=${latitude},${longitude}`;
        window.open(url, '_blank');
    }
};
type RemoveKeysFromObject = <T extends object>(
    obj: T,
    keysToRemove: (keyof T)[],
) => Partial<T>;

export const removeKeysFromObject: RemoveKeysFromObject = (
    obj,
    keysToRemove,
) => {
    if (keysToRemove.length === 0) {
        return obj; // Return the original object if no keys to remove
    }
    const newObj = { ...obj };
    keysToRemove.forEach((key) => {
        delete newObj?.[key];
    });
    return newObj;
};
