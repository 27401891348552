import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
    sendOTP,
    validateOTP,
    fetchAppConfig,
} from '../../store/actions/authActionCreator';
import {
    Box,
    makeStyles,
    Typography,
    Button,
    Paper,
    withStyles,
    Link,
    Fade,
} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import OtpInput from 'react-otp-input';
import {
    GetOrganisations,
    StoreOrganisation,
} from '../../store/actions/settingsActionCreator';
import { CustomTextField } from '../../components/Textfield';
import PhoneRoundedIcon from '@material-ui/icons/PhoneRounded';

import loginImage from '../../assets/images/loginImage.png';
import featureImage1 from '../../assets/images/featureImage1.png';
import featureImage2 from '../../assets/images/featureImage2.png';
import featureImage3 from '../../assets/images/featureImage3.png';
import featureImage4 from '../../assets/images/featureImage4.png';
import deAppImages from '../../assets/images/deAppImages.png';
import {
    getActiveInitRoute,
    getLoginUser,
    getUserToken,
} from '../../utils/helper';

import {
    OnlineOnboarding,
    LocationTracking,
    StaffWithApp,
    DigitalPayroll,
    SmartAttendance,
} from '../../assets/svg';

const FeaturesCard = withStyles({
    root: {
        display: 'flex',
        marginBottom: '124px',
        padding: '0 160px 0 160px',
    },
})(({ classes, style, title, points, image, imageOnLeft, ...props }) => {
    return (
        <Paper
            key={title}
            elevation={0}
            classes={{
                root: classes.root,
            }}
            style={style}
        >
            {imageOnLeft && (
                <Box
                    style={{
                        display: 'flex',
                        flex: '1 1 50%',
                        // border: '1px solid black',
                        justifyContent: 'flex-start',
                    }}
                >
                    <Box>
                        <img
                            src={image}
                            style={{
                                width: '548px',
                                height: '343px',
                                WebkitMaskImage:
                                    '-webkit-linear-gradient(rgba(0,0,0,1), rgba(0,0,0,1), transparent)',
                            }}
                            alt="left"
                        />
                    </Box>
                </Box>
            )}
            <Box
                style={{
                    display: 'flex',
                    flex: '1 1 50%',
                    // border: '1px solid black',
                    padding: '24px 0 0 32px ',
                }}
            >
                <Box>
                    <Box
                        style={{
                            padding: '0 0.5rem 0 1rem',
                            display: 'flex',
                            flexDirection: 'row',
                        }}
                    >
                        {title == 'Online Onboarding' ? (
                            <OnlineOnboarding />
                        ) : title === 'Digital Payroll' ? (
                            <DigitalPayroll />
                        ) : title === 'Smart Attendance' ? (
                            <SmartAttendance />
                        ) : title === 'Location Tracking' ? (
                            <LocationTracking />
                        ) : (
                            <></>
                        )}
                        <Typography
                            style={{
                                fontSize: '22px',
                                fontWeight: 700,
                                marginBottom: '8px',
                                paddingLeft: '8px',
                            }}
                        >
                            {title}
                        </Typography>
                    </Box>

                    <Box
                        style={{
                            paddingLeft: '54px',
                        }}
                    >
                        {points?.map((point, index) => (
                            <Typography key={index}>
                                <li
                                    style={{
                                        color: '#2b2b2b',
                                        fontSize: '18px',
                                    }}
                                >
                                    {point}
                                </li>
                            </Typography>
                        ))}
                    </Box>
                </Box>
            </Box>

            {!imageOnLeft && (
                <Box
                    style={{
                        display: 'flex',
                        flex: '1 1 50%',
                        // border: '1px solid black',
                    }}
                >
                    <img
                        src={image}
                        style={{
                            width: '548px',
                            height: '343px',

                            WebkitMaskImage:
                                '-webkit-linear-gradient(rgba(0,0,0,1), rgba(0,0,0,1), transparent)',
                        }}
                        alt="left"
                    />
                </Box>
            )}
        </Paper>
    );
});

const useStyles = makeStyles((theme) => ({
    topBar: {
        display: 'flex',
        flex: '1 1 15%',
        // border: '1px solid black',
    },

    otpInputStyle: {
        width: '48px !important',
        height: '48px !important',
        borderRadius: '8px',
        border: '1px solid rgba(38, 50, 56, 0.16)',
        fontSize: '24px',
        fontWeight: 600,
        fontFamily: 'Nunito',
    },
    error: {
        border: '1px solid red !important',
    },
}));

let interval = null;
const Login = ({
    dispatchfetchAppConfig,
    dispatchSendOtp,
    dispatchValidateOtp,
    dispatchGetOrganisations,
    dispatchStoreOrganisation,
    ...props
}) => {
    const classes = useStyles();

    const [mobileNumber, setMobileNumber] = useState('');
    const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(false);

    const [showOtpInput, setShowOtpInput] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [OTP, setOTP] = useState('');

    const [resendOtpStatus, setResendOtpStatus] = useState(false);
    const [disableMobileNoInput, setDisableMobileNoInput] = useState(false);

    const [timerCount, setTimer] = useState(60);
    const otpHandler = (otp) => {
        setOTP(otp);
        if (otp.length !== 4) {
            setHasError(false);
        }
    };

    const loginHandler = () => {
        console.log('otp is: ', OTP, OTP.length);
        if (OTP.length === 4) {
            dispatchValidateOtp(
                mobileNumber,
                OTP,
                {
                    //     osversion: DeviceInfo.getSystemVersion(),
                    //     appv: DeviceInfo.getVersion(),
                    //     model: DeviceInfo.getModel(),
                    //     manufacturer: DeviceInfo.getBrand(),
                    //     aaid: '',
                    //     imei: '',
                    //     deviceid: DeviceInfo.getUniqueId(),
                },
                'desktop',
                async (resp) => {
                    await dispatchfetchAppConfig(() => {
                        dispatchGetOrganisations(
                            getLoginUser().organizationId,
                            (resp) => {
                                console.log(
                                    'OTPScreen',
                                    'Validation SUCCESS',
                                    resp,
                                );
                                localStorage.setItem(
                                    'ORG_INFO',
                                    window.btoa(
                                        unescape(
                                            encodeURIComponent(
                                                JSON.stringify(resp),
                                            ),
                                        ),
                                    ),
                                );
                                localStorage.setItem('org', resp.name);
                                dispatchStoreOrganisation(resp);
                                setHasError(false);
                                props.history.push(`/${getActiveInitRoute()}`);

                                // props.history.push('/staff');
                            },
                            (resp) => {
                                console.log(
                                    'OTPScreen',
                                    'Validation SUCCESS',
                                    resp,
                                );
                                setHasError(false);
                                props.history.push(`/${getActiveInitRoute()}`);

                                // props.history.push('/staff');
                            },
                        );
                    });
                    // dispatchSetLoading();
                },
                () => {
                    // dispatchSetLoading();
                    console.log('OTPScreen', 'Validate OTP Failure');
                    setHasError(true);
                },
            );
        } else {
            setHasError(false);
        }
    };

    const handleInputChange = (key, value) => {
        if (key === 'phoneNumber') {
            if (value.match(/[1-9][0-9]{9}/) && value.length === 10) {
                setIsValidPhoneNumber(true);
            } else {
                setIsValidPhoneNumber(false);
            }
        }
    };

    const resetOtpTimer = () => {
        setTimer(60);
        setOTP('');
        setDisableMobileNoInput(true);
        interval = setInterval(() => {
            setTimer((lastTimerCount) => {
                if (lastTimerCount <= 1) {
                    clearInterval(interval);
                    setResendOtpStatus(true);
                    setDisableMobileNoInput(false);
                }
                return lastTimerCount - 1;
            });
        }, 1000);
        console.log(interval);
        return () => {
            clearInterval(interval);
        };
    };

    useEffect(() => {
        if (getUserToken()) {
            props.history.push(`/${getActiveInitRoute()}`);
        }
        return () => {
            clearInterval(interval);
        };
    }, []);

    useEffect(() => {
        console.log(timerCount, interval);
    }, [timerCount, interval]);

    return (
        <Box
            style={{
                backgroundColor: '#fff',
            }}
        >
            <Box
                style={{
                    height: '15%',
                    padding: '4px 0 4px 100px',
                    boxShadow: '0 1px 1px 0 rgba(0, 0, 0, 0.07)',
                }}
            >
                <Typography
                    style={{
                        fontSize: '32px',
                        fontWeight: 700,
                        color: '#43419e',
                    }}
                >
                    StaffPay
                </Typography>
            </Box>
            <Box
                style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                    margin: '0 auto',
                    width: '35%',
                }}
            >
                <Typography
                    style={{
                        fontSize: '28px',
                        color: '#43419e',
                        fontWeight: 700,
                        textAlign: 'center',
                    }}
                >
                    {`Intelligent Payroll Partner for Your Mobile Workforce`}
                </Typography>
            </Box>
            <Box
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginBottom: '0px',
                }}
            >
                <Box
                    style={{
                        flex: '0 0 60%',
                    }}
                >
                    <img
                        src={loginImage}
                        style={{
                            widht: '906px',
                            height: '540px',
                            // border: '1px solid black',
                        }}
                        alt="login"
                    />
                </Box>
                <Box
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        // border: '1px solid red',
                        margin: '160px auto',
                        padding: '0 120px 0 0',
                    }}
                >
                    <Typography
                        style={{
                            fontSize: '30px',
                            fontWeight: 600,
                            marginBottom: '16px',
                        }}
                    >
                        Welcome to StaffPay
                    </Typography>

                    <Box>
                        {/* <Typography
                            style={{
                                fontSize: '24px',
                                fontWeight: 600,
                                margin: '0 0 16px 0',
                            }}
                        >
                            Login
                        </Typography> */}
                        <CustomTextField
                            fullWidth={true}
                            variant="outlined"
                            label="Enter Your Mobile Number"
                            value={mobileNumber}
                            disabled={disableMobileNoInput}
                            onChange={(e) => {
                                clearInterval(interval);
                                setMobileNumber(e.target.value);
                                showOtpInput && setShowOtpInput(false);
                                handleInputChange(
                                    'phoneNumber',
                                    e.target.value,
                                );
                            }}
                            onBlur={(e) =>
                                handleInputChange('phoneNumber', e.target.value)
                            }
                            style={{
                                marginBottom: '16px',
                            }}
                            error={
                                mobileNumber.length > 0 && !isValidPhoneNumber
                            }
                            inputProps={{ maxLength: 10 }}
                        />
                        {showOtpInput && (
                            <Fade in={showOtpInput} timeout={1000}>
                                <Box>
                                    <OtpInput
                                        inputStyle={classes.otpInputStyle}
                                        numInputs={4}
                                        hasErrored={hasError}
                                        errorStyle={classes.error}
                                        onChange={otpHandler}
                                        separator={<span>&nbsp;</span>}
                                        isInputNum={true}
                                        shouldAutoFocus
                                        value={OTP}
                                    />

                                    <Typography
                                        style={{
                                            fontSize: '14px',
                                            fontWeight: 600,
                                            margin: '16px 0 24px 0',
                                        }}
                                    >
                                        {!resendOtpStatus &&
                                            `You can resend OTP in ${(
                                                '0000' + timerCount
                                            ).slice(-2)} seconds`}

                                        {resendOtpStatus && (
                                            <Link
                                                href="#"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    if (resendOtpStatus) {
                                                        dispatchSendOtp(
                                                            mobileNumber,
                                                            () => {
                                                                console.log(
                                                                    'LoginScreen',
                                                                    'Get OTP: Success',
                                                                );
                                                            },
                                                            () => {
                                                                console.log(
                                                                    'LoginScreen',
                                                                    'Get OTP:Failure',
                                                                );
                                                            },
                                                        );
                                                        setResendOtpStatus(
                                                            false,
                                                        );
                                                        resetOtpTimer();
                                                    }
                                                }}
                                            >
                                                Resend OTP
                                            </Link>
                                        )}
                                    </Typography>

                                    <Button
                                        variant="contained"
                                        color="primary"
                                        disabled={OTP.length !== 4}
                                        fullWidth={true}
                                        onClick={loginHandler}
                                        style={{
                                            height: '48px',
                                            fontSize: '18px',
                                        }}
                                        disableElevation
                                    >
                                        Login
                                    </Button>
                                </Box>
                            </Fade>
                        )}
                        {!showOtpInput && (
                            <Fade in={!showOtpInput} timeout={1000}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    fullWidth={true}
                                    disableElevation
                                    onClick={() => {
                                        if (isValidPhoneNumber) {
                                            dispatchSendOtp(
                                                mobileNumber,
                                                () => {
                                                    console.log(
                                                        'LoginScreen',
                                                        'Get OTP: Success',
                                                    );

                                                    setShowOtpInput(
                                                        (prev) => !prev,
                                                    );
                                                    resetOtpTimer();
                                                    setResendOtpStatus(false);
                                                },
                                                () => {
                                                    console.log(
                                                        'LoginScreen',
                                                        'Get OTP:Failure',
                                                    );
                                                },
                                            );
                                        }
                                    }}
                                    style={{
                                        margin: '8px 0 0 0 ',
                                        height: '48px',
                                    }}
                                    disabled={!isValidPhoneNumber}
                                >
                                    Login
                                </Button>
                            </Fade>
                        )}
                    </Box>
                </Box>
            </Box>
            <Box
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    margin: '0 100px',
                    justifyContent: 'space-evenly',
                }}
            >
                {[
                    { text: 'Online Onboarding' },
                    { text: 'Digital Payroll' },
                    { text: 'Smart Attendance' },
                    {
                        text: 'Location Tracking',
                    },
                    { text: 'Staff App' },
                ].map((data, index) => {
                    return (
                        <Box
                            key={index}
                            style={{
                                display: 'flex',
                                borderRadius: '8px',
                                boxShadow: '0 2px 14px 0 rgba(0, 0, 0, 0.12)',
                                backgroundColor: '#fff',
                                height: '5.625rem',
                                flex: '0 0 18%',
                                marginRight: '1rem',
                                alignItems: 'center',
                                // justifyContent: 'center',
                            }}
                        >
                            <Box style={{ padding: '0 0.5rem 0 1rem' }}>
                                {data?.text == 'Online Onboarding' ? (
                                    <OnlineOnboarding />
                                ) : data?.text === 'Digital Payroll' ? (
                                    <DigitalPayroll />
                                ) : data?.text === 'Smart Attendance' ? (
                                    <SmartAttendance />
                                ) : data?.text === 'Location Tracking' ? (
                                    <LocationTracking />
                                ) : data?.text === 'Staff App' ? (
                                    <StaffWithApp />
                                ) : (
                                    <></>
                                )}
                            </Box>
                            <Box>
                                <Typography
                                    style={{
                                        color: '#2b2b2b',
                                        fontWeight: 700,
                                        fontSize: '18px',
                                        // textAlign: 'center',
                                        paddingRight: '8px',
                                    }}
                                >
                                    {data?.text}
                                </Typography>
                            </Box>
                        </Box>
                    );
                })}
            </Box>
            <Box
                style={{
                    margin: '74px auto 100px auto',
                }}
            >
                <Box>
                    <FeaturesCard
                        title={'Online Onboarding'}
                        points={[
                            'Basic Info and KYC Verification',
                            'Contract Signing and ID Card Generation',
                            'Learning Videos',
                            'Grievance Management',
                        ]}
                        image={featureImage1}
                        imageOnLeft={false}
                    />
                    <FeaturesCard
                        title={'Digital Payroll'}
                        points={[
                            'Manage Earnings, Bonus,Expense and Deductions',
                            'Manage Advances and Payouts',
                            'Bulk Upload your Expenses and Payments',
                        ]}
                        image={featureImage2}
                        imageOnLeft={true}
                    />

                    <FeaturesCard
                        title={'Smart Attendance'}
                        points={[
                            'Selfie Based Approval',
                            'Delivery Hub Geofencing based Approval',
                            'Delivery Hub Geofencing based Auto-Approval',
                            'Manual Marking',
                        ]}
                        image={featureImage3}
                        imageOnLeft={false}
                    />
                    <FeaturesCard
                        title={'Location Tracking'}
                        points={[
                            'Realtime Staff Tracking using GPS',
                            'Distance Report',
                        ]}
                        image={featureImage4}
                        imageOnLeft={true}
                    />
                </Box>
                <Paper
                    elevation={0}
                    style={{
                        display: 'flex',
                        // marginBottom: '124px',
                        padding: '0 160px 0 160px',
                    }}
                >
                    <Box
                        style={{
                            display: 'flex',
                            flex: '1 1 50%',
                            // border: '1px solid black',
                            padding: '24px 0 0 12px ',
                        }}
                    >
                        <Box>
                            <Box
                                style={{
                                    padding: '0 0.5rem 0 1rem',
                                    display: 'flex',
                                    flexDirection: 'row',
                                }}
                            >
                                <StaffWithApp />
                                <Typography
                                    style={{
                                        fontSize: '22px',
                                        fontWeight: 700,
                                        marginBottom: '16px',
                                        paddingLeft: '8px',
                                    }}
                                >
                                    {'Staff App'}
                                </Typography>
                            </Box>

                            <Box
                                style={{
                                    paddingRight: '20px',
                                }}
                            >
                                <Typography
                                    style={{
                                        color: '#2b2b2b',
                                        fontSize: '18px',
                                        fontWeight: 600,
                                    }}
                                >
                                    {
                                        'Your staff can check their Earnings, Manage their KYC Documents and Mark Attendance'
                                    }
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                    {/*  Change this image here */}
                    <Box
                        style={{
                            display: 'flex',
                            flex: '1 1 50%',
                            // border: '1px solid black',
                        }}
                    >
                        <img
                            src={deAppImages}
                            style={{
                                width: '548px',
                                height: '343px',
                                paddingLeft: '12px',

                                WebkitMaskImage:
                                    '-webkit-linear-gradient(rgba(0,0,0,1), rgba(0,0,0,1), transparent)',
                            }}
                            alt="de-app"
                        />
                    </Box>
                </Paper>
            </Box>
            <Box
                style={{
                    padding: '80px 100px',
                    backgroundColor: '#ededf6',
                }}
            >
                <Box
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                    }}
                >
                    <Box
                        style={{
                            display: 'flex',
                            flex: '1 1 50%',

                            flexDirection: 'column',
                        }}
                    >
                        <Typography
                            style={{
                                fontSize: '32px',
                                fontWeight: 600,
                            }}
                        >
                            StaffPay
                        </Typography>
                        <Typography
                            style={{
                                fontSize: '20px',
                                paddingRight: '25%',
                            }}
                        >
                            {
                                '99, 3rd A Cross Rd, 2nd Stage, Naagarbhaavi, Bengaluru, Karnataka 560072'
                            }
                        </Typography>
                    </Box>
                    <Box
                        style={{
                            display: 'flex',
                            flex: '1 1 50%',
                            alignItems: 'flex-end',
                            justifyContent: 'flex-end',
                            flexDirection: 'column',
                        }}
                    >
                        <Box
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                            }}
                        >
                            <PhoneRoundedIcon />
                            <Typography
                                style={{ fontSize: '20px', marginLeft: '4px' }}
                            >
                                Phone: <strong>9632487593</strong>
                            </Typography>
                        </Box>
                        <Box
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                            }}
                        >
                            <PhoneRoundedIcon />
                            <Typography
                                style={{ fontSize: '20px', marginLeft: '4px' }}
                            >
                                E-mail: <strong>dev@staffpay.in</strong>
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box
                style={{
                    backgroundColor: '#fafaff',
                    padding: '30px 100px',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                }}
            >
                <Typography
                    style={{
                        fontSize: '14px',
                    }}
                >
                    Copyrighted @ 2021.
                </Typography>
                <Box
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItem: 'center',
                    }}
                >
                    <Typography
                        style={{
                            fontSize: '14px',
                            textDecoration: 'underline',
                            color: '#2777fa',
                        }}
                    >
                        <Link
                            href="#"
                            onClick={(e) => {
                                e.preventDefault();
                                props.history.push('/privacy');
                            }}
                        >
                            Privacy Policy
                        </Link>
                    </Typography>
                    <Typography
                        style={{
                            fontSize: '14px',
                            textDecoration: 'underline',
                            marginLeft: '8px',
                        }}
                    >
                        <Link
                            href="#"
                            onClick={(e) => {
                                e.preventDefault();
                                props.history.push('/terms');
                            }}
                        >
                            Terms and Conditions
                        </Link>
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
};

const mapDispatchToProps = (dispatch) => ({
    dispatchSendOtp: (mobileNumber, onSuccess, onError) =>
        dispatch(sendOTP(mobileNumber, onSuccess, onError)),

    dispatchValidateOtp: (
        mobileNumber,
        otp,
        overwriteHeaders,
        loginFrom,
        onSuccess,
        onError,
    ) =>
        dispatch(
            validateOTP(
                {
                    number: mobileNumber,
                    otp: otp,
                    overwriteHeaders,
                    loginFrom: loginFrom,
                },
                onSuccess,
                onError,
            ),
        ),

    dispatchfetchAppConfig: (payload, onSuccess, onError) =>
        dispatch(fetchAppConfig(payload, onSuccess, onError)),
    dispatchGetOrganisations: (id, onSuccess, onError) =>
        dispatch(GetOrganisations(id, onSuccess, onError)),
    dispatchStoreOrganisation: (data) => dispatch(StoreOrganisation(data)),
});

export default withRouter(connect(null, mapDispatchToProps)(Login));
