import { Box, Button, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import Modal from '../Modal';
import { CustomTextField } from '../../../components/Textfield';

function ApproveOrHoldModal({
    open,
    onClose,
    confirmationText,
    onAction,
    type,
    ...props
}) {
    const [state, setState] = useState({});
    const [isOpen, toggleModal] = useState(open);
    const [loading, toggleLoading] = useState(false);
    const handleClose = () => {
        // Setting form data to null before closing

        toggleModal(!isOpen);
        onClose && onClose();
    };

    const actions = [
        <Button
            key={1}
            variant="contained"
            color="default"
            className={`cancelButton`}
            onClick={handleClose}
            disableElevation
        >
            {'No'}
        </Button>,
        <Button
            key={2}
            variant="contained"
            className={`saveButton`}
            color="primary"
            onClick={async () => {
                // alert(`${isEdit ? 'Edit Clicked' : 'Add Clicked'}`);
                await toggleLoading(true);
                onAction(toggleLoading, state?.remarks);
            }}
            disableElevation
        >
            {type == 'Approve' ? 'Approve' : 'Hold'}
        </Button>,
    ];

    const handleInputChange = (value, key) => {
        setState((state) => ({
            ...state,
            [key]: value,
        }));
    };

    return (
        <Modal
            onClose={handleClose}
            open={isOpen}
            title={`${type == 'Approve' ? 'Approve' : 'Hold'} Payment`}
            actions={actions}
            loading={loading}
        >
            <Box>
                {/* <Typography
                    style={{
                        textAlign: 'center',
                    }}
                >
                    Do you want to {confirmationText}
                </Typography> */}
                <CustomTextField
                    fullWidth={true}
                    label="Remarks"
                    value={state?.remarks}
                    onChange={(e) =>
                        handleInputChange(e.target.value, 'remarks')
                    }
                    // style={{ marginBottom: '16px' }}
                />
            </Box>
        </Modal>
    );
}

export default ApproveOrHoldModal;
