import { Divider } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

function PDFViewer({ file, ...props }) {
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    /*To Prevent right click on screen*/
    // useEffect(() => {
    // document.addEventListener('contextmenu', (event) => {
    //     event.preventDefault();
    // });
    // return () => {
    //     document.removeEventListener('contextmenu', () => {});
    // };
    // }, []);

    /*When document gets loaded successfully*/
    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
        setPageNumber(1);
    }

    function changePage(offset) {
        setPageNumber((prevPageNumber) => prevPageNumber + offset);
    }

    function previousPage() {
        changePage(-1);
    }

    function nextPage() {
        changePage(1);
    }

    return (
        <>
            <div className="main">
                <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
                    {Array.from(new Array(numPages), (page, index) => (
                        <>
                            <Page
                                scale={1.2}
                                pageNumber={index + 1}
                                key={index}
                            />
                            <Divider
                                style={{ marginTop: 10, marginBottom: 10 }}
                            />
                        </>
                    ))}
                </Document>
                {/* <div>
                    <div className="pagec">
                        Page {pageNumber || (numPages ? 1 : '--')} of{' '}
                        {numPages || '--'}
                    </div>
                    <div className="buttonc">
                        <button
                            type="button"
                            disabled={pageNumber <= 1}
                            onClick={previousPage}
                            className="Pre"
                        >
                            Previous
                        </button>
                        <button
                            type="button"
                            disabled={pageNumber >= numPages}
                            onClick={nextPage}
                        >
                            Next
                        </button>
                    </div>
                </div> */}
            </div>
        </>
    );
}

export default PDFViewer;
