import { Button, makeStyles, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import Modal from './Modal';
import { CustomTextField } from '../Textfield';
import { connect } from 'react-redux';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { updateLeadStatus } from '../../store/actions/leadActionCreator';
const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiOutlinedInput-input': {
            padding: '0px',
            color: '#14112d',
        },
    },
}));

const validationSchema = yup.object({
    reason: yup.string('Reason is invalid').required('Reason is invalid'),
});

function RejectHireModal({
    leadInfo,
    open,
    onClose,
    defaultValue = {},
    dispatchUpdateLeadStatus,
    ...props
}) {
    const classes = useStyles();
    const [isOpen, toggleModal] = useState(open);
    const [loading, toggleLoading] = useState(false);

    const handleClose = (values) => {
        toggleModal(!isOpen);
        onClose && onClose(values);
    };

    const formik = useFormik({
        initialValues: {
            reason: undefined,
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            markRejected(values);
        },
    });

    const markRejected = (values) => {
        toggleLoading(true);
        const payload = {
            status: 'Rejected',
            comments: values.reason,
        };
        dispatchUpdateLeadStatus(
            leadInfo.id,
            payload,
            (resp) => {
                toggleLoading(false);
                handleClose('');
            },
            (err) => {
                toggleLoading(false);
                handleClose('');
            },
        );
    };

    const actions = [
        <Button
            key={1}
            variant="contained"
            color="default"
            className={`cancelButton`}
            onClick={() => handleClose(defaultValue)}
            style={{
                boxShadow: 'none',
            }}
            disableElevation
        >
            Cancel
        </Button>,
        <Button
            key={2}
            variant="contained"
            color="primary"
            className={`saveButton`}
            type="submit"
            style={{
                boxShadow: 'none',
            }}
            disableElevation
        >
            Reject
        </Button>,
    ];

    return (
        <Modal
            onClose={() => handleClose(defaultValue)}
            open={isOpen}
            title={'Mark Reject'}
            actions={actions}
            loading={loading}
            isForm={true}
            onSubmit={formik.handleSubmit}
            className={classes.root}
        >
            <CustomTextField
                multiline={true}
                rows={4}
                fullWidth={true}
                label="Reason"
                variant="outlined"
                id="reason"
                name="reason"
                value={formik.values.reason}
                onChange={formik.handleChange}
                error={
                    formik.touched.reason
                        ? Boolean(formik.errors.reason)
                        : false
                }
                helperText={formik.touched.reason && formik.errors.reason}
                style={{
                    marginBottom: '10px',
                }}
            />
        </Modal>
    );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
    dispatchUpdateLeadStatus: (id, data, onSuccess, OnFailed) =>
        dispatch(updateLeadStatus(id, data, onSuccess, OnFailed)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RejectHireModal);
