import React, { useState } from 'react';
import {
    Box,
    Typography,
    Tooltip,
    Divider,
    Tabs,
    Tab,
    withStyles,
    AppBar,
    makeStyles,
    useTheme,
} from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import {
    Timeline,
    TimelineItem,
    TimelineSeparator,
    TimelineConnector,
    TimelineContent,
    TimelineDot,
    TimelineOppositeContent,
} from '@material-ui/lab';
import { eventStyleFlag, getTabsProps } from '../helper';
import moment from 'moment';
import LocationPointCard from './LocationPointCard';
import EventCard from './EventCard';
import { getTimeAsDuration, getTimeDifference } from '../../../../utils/helper';

interface IdleTimelineItemProps {
    startPoint: any;
    endPoint: any;
    selectedPoint: any;
    events: any;
    aggUserEvents: any;
    onClick: Function;
    endAddress: any;
    stoppageReportData: any;
}

const useStyles = makeStyles((theme) => ({
    separator: {
        backgroundColor: '#e74c3c',
        border: '1px solid #fff',
        width: 20,
        height: 20,
        margin: 0,
    },
    separatorSelected: {
        backgroundColor: '#e74c3c',
        border: '2px solid #000',
        width: 20,
        height: 20,
        margin: 0,
    },
}));
const IdleTimelineItem: React.FC<IdleTimelineItemProps> = ({
    startPoint = {},
    endPoint = {},
    events = {},
    aggUserEvents,
    selectedPoint,
    onClick,
    endAddress,
    stoppageReportData,
}) => {
    const classes = useStyles();
    const theme = useTheme();
    const selected =
        endPoint?.deviceTimeEpoch ==
            selectedPoint?.selectedStartStoppagePoint ||
        endPoint?.deviceTimeEpoch == selectedPoint?.selectedEndStoppagePoint;
    return !!stoppageReportData && stoppageReportData?.length ? (
        <>
            {stoppageReportData && stoppageReportData?.length > 0 ? (
                stoppageReportData?.map((idlePoint: any, index: number) => {
                    return (
                        <TimelineItem
                            key={index}
                            style={
                                {
                                    //marginTop: '2px',
                                    //marginBottom: '2px',
                                }
                            }
                        >
                            <TimelineOppositeContent
                                style={{
                                    flex: 0,
                                    padding: '6px',
                                }}
                            ></TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineDot
                                    {...{
                                        style: {
                                            width: 20,
                                            height: 20,
                                            margin: 0,
                                            cursor: 'pointer',
                                            backgroundColor:
                                                idlePoint?.type ===
                                                'ORDER_PICKED'
                                                    ? '#43419e'
                                                    : idlePoint?.type ===
                                                      'ORDER_DELIVERED'
                                                    ? '#05c792'
                                                    : '#fb8a06',
                                            ...(idlePoint?.time ===
                                                selectedPoint?.selectedStartStoppagePoint ||
                                            idlePoint?.time ===
                                                selectedPoint?.selectedEndStoppagePoint
                                                ? {
                                                      border: '3px solid #000',
                                                  }
                                                : {
                                                      border: '1px solid #fff',
                                                  }),
                                        },
                                        onClick: () => {
                                            onClick(idlePoint);
                                        },
                                    }}
                                />
                                <TimelineConnector
                                    style={{
                                        backgroundColor: '#fb8a06',
                                    }}
                                />
                            </TimelineSeparator>
                            <TimelineContent
                                style={{
                                    padding: 0,
                                    margin: '8px',
                                }}
                            >
                                {['ORDER_PICKED', 'ORDER_DELIVERED']?.includes(
                                    idlePoint?.type,
                                ) ? (
                                    <LocationPointCard
                                        timeDiff={null}
                                        title={idlePoint?.title}
                                        dates={`${moment(
                                            parseInt(idlePoint?.time),
                                        ).format(
                                            'hh:mm A',
                                            // 'D MMM YYYY hh:mm A',
                                        )}`}
                                        address={
                                            idlePoint?.description?.length >
                                                0 &&
                                            isNaN(
                                                Number(idlePoint?.description),
                                            )
                                                ? idlePoint?.description
                                                      .split('@!@')
                                                      .join(',')
                                                : 'No Location Available'
                                        }
                                    />
                                ) : (
                                    <LocationPointCard
                                        title={
                                            idlePoint?.kmReading &&
                                            `${parseFloat(
                                                idlePoint?.kmReading,
                                            ).toFixed(2)} Km`
                                        }
                                        dates={`${moment(
                                            parseInt(
                                                idlePoint?.startTime ??
                                                    idlePoint?.time,
                                            ),
                                        ).format(
                                            'hh:mm A',
                                            // 'D MMM YYYY hh:mm A',
                                        )} - ${moment(
                                            parseInt(
                                                idlePoint?.endTime ??
                                                    idlePoint?.time,
                                            ),
                                        ).format(
                                            'hh:mm A',
                                            // 'D MMM YYYY hh:mm A',
                                        )}`}
                                        address={
                                            idlePoint?.description?.length >
                                                0 &&
                                            isNaN(
                                                Number(idlePoint?.description),
                                            )
                                                ? idlePoint?.description
                                                      .split('@!@')
                                                      .join(',')
                                                : 'No Location Available'
                                        }
                                        timeDiff={getTimeAsDuration(
                                            getTimeDifference(
                                                parseInt(
                                                    idlePoint?.endTime ??
                                                        idlePoint?.time,
                                                ),
                                                parseInt(
                                                    idlePoint?.startTime ??
                                                        idlePoint?.time,
                                                ),
                                            ),
                                            'small',
                                        )}
                                    />
                                )}
                                {aggUserEvents
                                    ?.filter((event: any) => {
                                        if (index == 0) {
                                            if (
                                                startPoint?.deviceTimeEpoch >=
                                                    parseInt(
                                                        stoppageReportData[0]
                                                            ?.startTime ??
                                                            stoppageReportData[0]
                                                                ?.time,
                                                    ) &&
                                                startPoint?.deviceTimeEpoch <
                                                    parseInt(
                                                        stoppageReportData[0]
                                                            ?.endTime ??
                                                            stoppageReportData[0]
                                                                ?.time,
                                                    )
                                            ) {
                                                return (
                                                    event?.time >=
                                                        parseInt(
                                                            idlePoint[1],
                                                        ) &&
                                                    stoppageReportData[
                                                        index + 1
                                                    ] &&
                                                    event?.time <=
                                                        parseInt(
                                                            stoppageReportData[
                                                                index + 1
                                                            ]?.startTime ??
                                                                stoppageReportData[
                                                                    index + 1
                                                                ]?.time,
                                                        )
                                                );
                                            }
                                        }
                                        return (
                                            event?.time >=
                                                parseInt(
                                                    idlePoint?.startTime ??
                                                        idlePoint?.time,
                                                ) &&
                                            stoppageReportData[index + 1] &&
                                            event?.time <=
                                                parseInt(
                                                    stoppageReportData[
                                                        index + 1
                                                    ]?.startTime ??
                                                        stoppageReportData[
                                                            index + 1
                                                        ]?.time,
                                                )
                                        );
                                    })
                                    ?.map((event: any) => {
                                        return (
                                            <EventCard
                                                {...{
                                                    event,
                                                }}
                                            />
                                        );
                                    })}
                            </TimelineContent>
                        </TimelineItem>
                    );
                })
            ) : (
                <></>
            )}
        </>
    ) : (
        <></>
    );
};

export default IdleTimelineItem;
