import { Button, makeStyles, Typography, Box } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Modal from '../../components/Modals/Modal';
import { fetchAppConfig } from '../../store/actions/authActionCreator';
import { getActiveInitRoute, getPermissions, logout } from '../../utils/helper';
import { BasicDetails, Vendors, Clients, ShiftManager, EVVehicles } from '.';
import HubAndShift from './HubAndShift';
import { DeliveryHub } from './DeliveryHub';

const useStyles = makeStyles((theme) => ({
    settings: {
        paddingTop: '1rem',
        '& button.tab-button': {
            justifyContent: 'flex-start',
            color: theme.palette.text.secondary,
            fontSize: '16px',
            fontWeight: 400,
        },
        '& button.active': {
            color: theme.palette.text.primary,
            fontWeight: 'bold',
            fontSize: '16px',
        },
        '& .inputbutton': {
            color: theme.palette.text.primary,
            fontWeight: 'bold',
            fontSize: '16px',
            padding: 5,
            border: `1px solid ${theme.palette.text.primary}`,
            borderRadius: 8,
        },
        '& .MuiGrid-container': {
            marginTop: 20,
        },
        '& .MuiGrid-spacing-xs-3 > .MuiGrid-item': {
            padding: `5px ${theme.spacing(2)}px !important`,
        },
        '& .switch-label': {
            '& .MuiTypography-body1': {
                fontSize: 16,
                fontWeight: 600,
            },
        },
    },
}));

export const HubSwitchKey = Object.freeze({
    HUB_VIEW: 'HUB_VIEW',
    HUB_DETAIL_VIEW: 'HUB_DETAIL_VIEW',
    HUB_CALENDAR_VIEW: 'HUB_CALENDAR_VIEW',
});

function Settings({
    isEnabledXUtil,
    configs,
    dispatchFetchAppConfig,
    ...props
}) {
    const classes = useStyles();
    const [activeIndex, setActiveIndex] = useState(0);
    const [permissions, setPermissions] = useState(getPermissions('settings'));

    const [isZappayUser, setIsZappayUser] = useState(false);

    const evPermission = getPermissions('ev');

    // const [_TAB, set_TAB] = useState();

    const [isOpen, toggleModal] = useState(false);
    const handleClose = () => {
        // Setting form data to null before closing

        toggleModal(false);
    };
    const actions = [
        <Button
            key={1}
            variant="contained"
            color="default"
            className={`cancelButton`}
            onClick={handleClose}
            disableElevation
        >
            {'No'}
        </Button>,
        <Button
            key={2}
            variant="contained"
            className={`saveButton`}
            color="primary"
            onClick={() => {
                logout();
            }}
            disableElevation
        >
            Yes
        </Button>,
    ];

    useEffect(() => {
        if (!permissions.read) {
            props.history.push(`/${getActiveInitRoute()}`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [permissions]);

    useEffect(() => {
        (async () => {
            await setPermissions(getPermissions('settings'));
            if (!permissions.read) {
                props.history.push(`/${getActiveInitRoute()}`);
            }
            if (
                !getPermissions('staff')?.read &&
                !getPermissions('attendance')?.read &&
                !getPermissions('map_view')?.read &&
                !getPermissions('report')?.read
            ) {
                setIsZappayUser(true);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [configs]);

    // useEffect(() => {
    //     if (isZappayUser) {
    //         set_TAB([
    //             {
    //                 label: 'Basic',
    //                 content: <BasicDetails permissions={permissions} />,
    //                 disabled: !permissions?.read,
    //             },
    //         ]);
    //     }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [isZappayUser]);

    console.log('evPermissionevPermission', evPermission);

    let _TAB = [
        {
            label: 'Basic',
            content: <BasicDetails permissions={permissions} />,
            disabled: !permissions?.read,
        },
        {
            label: 'Vendors',
            content: <Vendors permissions={permissions} />,
            disabled: !permissions?.read,
        },
        {
            label: 'Clients',
            content: <Clients permissions={permissions} />,
            disabled: !permissions?.read,
        },
    ];

    if (evPermission?.read) {
        _TAB = [
            ..._TAB,
            {
                label: 'EV Vehicles',
                content: <EVVehicles permissions={permissions} />,
            },
        ];
    }

    if (!!isEnabledXUtil) {
        _TAB = [
            ..._TAB,
            {
                label: 'Hubs & Shifts',
                content: (
                    <HubAndShift
                        {...{
                            permissions,
                        }}
                    />
                ),
                disabled: !permissions?.read,
            },
        ];
    } else {
        _TAB = [
            ..._TAB,
            {
                label: 'Delivery Hub',
                content: <DeliveryHub permissions={permissions} />,
                disabled: !permissions?.read,
            },
            {
                label: 'Shift Master',
                content: <ShiftManager permissions={permissions} />,
                disabled: !permissions?.read,
            },
        ];
    }

    if (isZappayUser) {
        _TAB = [
            {
                label: 'Basic',
                content: <BasicDetails permissions={permissions} />,
                disabled: !permissions?.read,
            },
        ];
    }

    return (
        <div className={`settings ${classes.settings}`}>
            {/* <Grid container direction="row" spacing={1}>
                <Grid item xs={2} style={{ padding: '30px' }}>
                    {_TAB.map((tab, index) => (
                        <Button
                            key={index}
                            className={`tab-button ${
                                index === activeIndex ? 'active' : ''
                            }`}
                            fullWidth
                            onClick={(e) => setActiveIndex(index)}
                        >
                            {tab.label}
                        </Button>
                    ))}
                    <Button
                        className="tab-button"
                        fullWidth
                        onClick={(e) => {
                            toggleModal(true);
                        }}
                    >
                        Log Out
                    </Button>
                </Grid>
                <Grid item xs={10}>
                    {_TAB[activeIndex].content}
                </Grid>
            </Grid> */}
            <Box
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                }}
            >
                <Box
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        flex: '0 0 13%',
                        marginTop: '12px',
                    }}
                >
                    <Box
                        style={{
                            width: '90%',
                        }}
                    >
                        {_TAB.map((tab, index) => (
                            <Button
                                key={index}
                                className={`tab-button ${
                                    index === activeIndex ? 'active' : ''
                                }`}
                                fullWidth
                                onClick={(e) => setActiveIndex(index)}
                                disableElevation
                            >
                                {tab.label}
                            </Button>
                        ))}
                        <Button
                            className="tab-button"
                            fullWidth
                            onClick={(e) => {
                                toggleModal(true);
                            }}
                            disableElevation
                        >
                            Log Out
                        </Button>
                    </Box>
                </Box>
                <Box
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        flexGrow: 1,
                        flexShrink: 1,
                        width: '80%',
                    }}
                >
                    {!_TAB[activeIndex]?.disabled && _TAB[activeIndex]?.content}
                </Box>
            </Box>

            {!!isOpen && (
                <Modal
                    onClose={handleClose}
                    open={true}
                    title={'Logout'}
                    actions={actions}
                >
                    <Typography
                        style={
                            {
                                // textAlign: 'center',
                            }
                        }
                    >
                        Do you want to logout ?
                    </Typography>
                </Modal>
            )}
        </div>
    );
}

const mapStateToProps = (state) => ({
    configs: state.user.config,
    isEnabledXUtil: state.user.config?.isEnabledXUtil,
});

const mapDispatchToProps = (dispatch) => ({
    dispatchFetchAppConfig: (onSuccess, onError) =>
        dispatch(fetchAppConfig(onSuccess, onError)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
