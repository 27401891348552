import * as constants from '../constants';

const defaultState = {};

export default function leadReducer(state = defaultState, action) {
    switch (action.type) {
        case constants.SET_ALL_LEAD_INFO:
            return { ...state, ...action.payload };
        default:
            return state;
    }
}
