import React, { useState, useEffect } from 'react';
import { Box, Typography, makeStyles } from '@material-ui/core';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

// import { Document } from 'react-pdf';
import ReactPlayer from 'react-player';
import ImageDownloader from '../../ImageDownloader';
const useStyles = makeStyles((theme) => ({
    imageContainer: {
        width: ' 100%',
        overflowX: 'auto',
        whiteSpace: 'nowrap',
        maxWidth: '150px',
        '&::-webkit-scrollbar': {
            width: '0.2em',
            height: '0.2em',
        },
        '&::-webkit-scrollbar-track': {
            '-webkit-box-shadow': 'inset 0 0 2px rgba(0,0,0,0.00)',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#ddd',
            outline: '1px solid grey',
            borderRadius: '6px',
        },
    },
    imageWrapper: {
        display: 'flex',
    },
    imageThumbnail: {
        width: '64px' /* Adjust width as needed */,
        height: '64px' /* Adjust height as needed */,
        margin: '5px',
        objectFit: 'cover' /* Optional: Maintain aspect ratio */,
    },
    imageTitle: {
        color: '#fff',
        fontWeight: '700',
        fontSize: '18px',
    },
}));
export const LightBoxImage = ({
    source,
    imageStyles = {},
    containerStyles = {},
    type = 'image',
    download = false,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <Box flexDirection={'row'} display={'flex'}>
            {!!download && !!source && <ImageDownloader s3Url={source} />}
            <Box
                onClick={() => setIsOpen(true)}
                flex={1}
                style={{
                    width: '100%',
                    height: '100%',
                    cursor: 'pointer',
                    ...containerStyles,
                }}
            >
                {type == 'image' ? (
                    <>
                        {isOpen && (
                            <Lightbox
                                mainSrc={source}
                                onCloseRequest={() => setIsOpen(false)}
                                reactModalStyle={{ overlay: { zIndex: 1400 } }}
                            />
                        )}
                        <img
                            style={{
                                height: '150px',
                                width: '100%',
                                margin: 'auto',
                                borderRadius: '16px',
                                objectFit: 'contain',
                                ...imageStyles,
                            }}
                            src={source}
                            alt={'uploaded-url'}
                        />
                    </>
                ) : type == 'video' && !!source ? (
                    <>
                        <ReactPlayer
                            controls={true}
                            url={source}
                            width={'100%'}
                        />
                    </>
                ) : (
                    <>{/* {isOpen && <Document file={source}/>} */}</>
                )}
            </Box>
        </Box>
    );
};
export const LightBoxImages = ({
    imageSources,
    containerStyles = {},
    selectedIndex = 0,
    imageKey,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(selectedIndex);
    const [images, setImages] = useState([]);
    const classes = useStyles();

    useEffect(() => {
        if (!!imageSources && !!imageSources?.length) setImages(imageSources);
        return () => {};
    }, []);

    return !!imageSources && !!imageSources?.length ? (
        <Box flexDirection={'row'} display={'flex'}>
            <Box
                onClick={() => setIsOpen(true)}
                flex={1}
                style={{
                    width: '100%',
                    height: '100%',
                    ...containerStyles,
                }}
            >
                <Box className={classes.imageContainer}>
                    <Box className={classes.imageWrapper}>
                        {images?.map((image, index) => (
                            <img
                                key={`${imageKey}image${image?.type}-${index}`}
                                src={image?.image}
                                alt={image?.title}
                                className={classes.imageThumbnail}
                                onClick={() => {
                                    setPhotoIndex(index);
                                    setIsOpen(true);
                                }}
                            />
                        ))}
                    </Box>
                </Box>
                {isOpen && (
                    <Lightbox
                        reactModalStyle={{ overlay: { zIndex: 1400 } }}
                        mainSrc={images[photoIndex]?.image}
                        nextSrc={
                            images[(photoIndex + 1) % images.length]?.image
                        }
                        imageTitle={
                            <Box
                                display={'flex'}
                                flexDirection={'row'}
                                alignItems={'center'}
                                paddingLeft={'12px'}
                                paddingRight={'12px'}
                                backgroundColor={'#fff'}
                            >
                                <Typography className={classes.imageTitle}>
                                    {images?.[photoIndex]?.title}
                                </Typography>
                                {!!images?.[photoIndex]?.image && (
                                    <ImageDownloader
                                        s3Url={images?.[photoIndex]?.image}
                                        iconColor={'green'}
                                    />
                                )}
                            </Box>
                        }
                        imageCaption={images[photoIndex]?.desc}
                        prevSrc={
                            images[
                                (photoIndex + images.length - 1) %
                                    images?.length
                            ]?.image
                        }
                        onCloseRequest={() => setIsOpen(false)}
                        onMovePrevRequest={() =>
                            setPhotoIndex(
                                (photoIndex + images.length - 1) %
                                    images.length,
                            )
                        }
                        onMoveNextRequest={() =>
                            setPhotoIndex((photoIndex + 1) % images.length)
                        }
                    />
                )}
            </Box>
        </Box>
    ) : null;
};
