import React, { useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import GetAppIcon from '@material-ui/icons/GetApp';
import CircularProgress from '@material-ui/core/CircularProgress';
import { showMessage } from '../../utils/helper';
function ImageDownloader({ s3Url, iconColor }) {
    const [isDownloading, setIsDownloading] = useState(false);
    const [downloadError, setDownloadError] = useState(null);

    const handleDownload = async () => {
        setIsDownloading(true);
        setDownloadError(null); // Clear any previous errors
        try {
            const response = await fetch(s3Url);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const blob = await response.blob();
            const filename = s3Url.split('/').pop(); // Extract filename from URL
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = filename;
            link.click();

            URL.revokeObjectURL(link.href); // Cleanup object URL
        } catch (error) {
            showMessage(error?.message ?? 'Failed to download', 'error');
            setDownloadError(error?.message);
        } finally {
            setIsDownloading(false);
        }
    };

    return (
        <div
            style={{
                width: '48px',
                height: '48px',
            }}
        >
            {!!isDownloading ? (
                <CircularProgress />
            ) : (
                <IconButton
                    onClick={handleDownload}
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                >
                    <GetAppIcon
                        style={{
                            color: !!iconColor ? iconColor : 'inherit',
                        }}
                    />
                </IconButton>
            )}
        </div>
    );
}
export default ImageDownloader;
