import React, { useEffect, useState } from 'react';
import { Box, Grid, Paper, Typography, makeStyles } from '@material-ui/core';
import {
    BarChart,
    CartesianGrid,
    XAxis,
    YAxis,
    Tooltip,
    Legend,
    Bar,
    PieChart,
    Pie,
    Cell,
} from 'recharts';
import {
    CustomMonthPicker,
    CustomMonthRangePicker,
} from '../../../components/DateTimePicker';
import { connect } from 'react-redux';
import {
    GetDasboardReport,
    setDashboardReportData,
} from '../../../store/actions/reportActionCreator';
import { Spinner } from '../../../components/UI';
import moment from 'moment';
import { EmptyIcon } from '../../../assets/svg';
import EmptyStates from '../../../components/EmptyStates';
import './styles.scss';

const useStyles = makeStyles((theme) => ({
    paperStyle: {
        display: 'flex',
        flexDirection: 'column',
        padding: '1rem',
        textAlign: 'center',
        justifyContent: 'space-evenly',
        minHeight: '150px',
    },
    title: {
        fontSize: '24px',
        fontWeight: 700,
        padding: `1rem 0`,
    },
    subtitle: {
        fontSize: '18px',
    },
}));

const COLORS = [
    'rgba(67,65,158,0.8)',
    'rgba(244, 67, 54,0.9)',
    'rgba(76, 175, 80, 0.8)',
];

const RADIAN = Math.PI / 180;

function DashboardCompenent({
    dashboard,
    dispatchGetDasboardReport,
    dispatchSetDashboardReportData,
    ...props
}) {
    const classes = useStyles();
    const [payload, setPayload] = useState({
        dateRange: {
            from: moment().startOf('M').valueOf(),
            to: moment().endOf('M').valueOf(),
        },
    });
    const [loading, toggleLoading] = useState(false);

    const fetchDashboardReport = async (filters = {}) => {
        await toggleLoading(true);
        dispatchGetDasboardReport(
            filters,
            (resp) => {
                toggleLoading(false);
            },
            (err) => {
                toggleLoading(false);
                dispatchSetDashboardReportData({});
            },
        );
    };

    const renderCustomizedLabel = ({
        cx,
        cy,
        midAngle,
        innerRadius,
        outerRadius,
        percent,
        index,
    }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
            <text
                x={x}
                y={y}
                fill="white"
                textAnchor={x > cx ? 'start' : 'end'}
                dominantBaseline="central"
            >
                {`${(percent * 100).toFixed(0)}%`}
            </text>
        );
    };

    const customizeLabel = ({ ...props }) => {
        const radius =
            props.innerRadius + (props.outerRadius - props.innerRadius) * 0.5;
        const x = props.cx + 2.25 * radius * Math.cos(-props.midAngle * RADIAN);
        const y = props.cy + 2.35 * radius * Math.sin(-props.midAngle * RADIAN);
        console.log('paramss', props);
        return (
            <text
                x={x}
                y={y}
                fill="black"
                textAnchor={x > props.cx ? 'start' : 'end'}
                dominantBaseline="central"
            >
                {`₹${props.payload.value}`}
            </text>
        );
    };

    useEffect(() => {
        fetchDashboardReport({ ...payload });
    }, []);

    return (
        <div className={`dashboard`}>
            <Spinner loading={loading}>
                <Box
                    style={{
                        width: '20%',
                        justifySelf: 'flex-start',
                        marginBottom: 16,
                    }}
                >
                    <CustomMonthPicker
                        onChange={async (e) => {
                            console.log('dateee', e);
                            await setPayload({
                                dateRange: {
                                    from: moment(e).startOf('M').valueOf(),
                                    to: moment(e).endOf('M').valueOf(),
                                },
                            });
                            fetchDashboardReport({
                                ...payload,
                                dateRange: {
                                    from: moment(e).startOf('M').valueOf(),
                                    to: moment(e).endOf('M').valueOf(),
                                },
                            });
                        }}
                    />
                    {/* <CustomMonthRangePicker
                    onChange={async (e) => {
                        console.log('dateee', e);
                        await setPayload(e);
                        fetchDashboardReport({
                            ...payload,
                            dateRange: {
                                from: moment(e.dateRange.from)
                                    .startOf('M')
                                    .valueOf(),
                                to: moment(e.dateRange.to).endOf('M').valueOf(),
                            },
                        });
                    }}
                /> */}
                </Box>
                {
                    dashboard?.totalUsers == 0 ? (
                        <Box
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <EmptyStates
                                type="Reports"
                                header1={'Add Your First Staff to Start using'}
                                header2={'Reports'}
                                buttonDisplay={true}
                            />
                        </Box>
                    ) : dashboard && Object?.keys(dashboard)?.length > 0 ? (
                        <>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Box
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            flexDirection: 'column',
                                            textAlign: 'center',
                                            backgroundColor: '#fff',
                                            borderRadius: 16,
                                            padding: '1rem 1rem 1.5rem 1rem',
                                        }}
                                    >
                                        <Typography className={classes.title}>
                                            Daily Attendance
                                        </Typography>

                                        <BarChart
                                            width={window.screen.width * 0.9}
                                            height={400}
                                            data={
                                                dashboard?.attendanceItems?.map(
                                                    (item) => ({
                                                        ...item,
                                                        Present:
                                                            item.presentCount,
                                                        Absent: item.absentCount,
                                                    }),
                                                ) ?? []
                                            }
                                        >
                                            <CartesianGrid strokeDasharray="1 1" />
                                            <XAxis dataKey="dateText" />
                                            <YAxis />
                                            <Tooltip />
                                            <Legend
                                                verticalAlign="bottom"
                                                wrapperStyle={{
                                                    position: 'relative',
                                                    margin: 0,
                                                    marginTop: '-10px',
                                                }}
                                            />
                                            <Bar
                                                dataKey="Present"
                                                stackId="P/A"
                                                fill="rgba(67,65,158,0.8)"
                                            />
                                            <Bar
                                                dataKey="Absent"
                                                stackId="P/A"
                                                fill="rgba(244, 67, 54,0.8)"
                                            />
                                        </BarChart>
                                    </Box>
                                </Grid>
                                {dashboard?.earningItems?.length > 0 ? (
                                    <Grid item xs={12}>
                                        <Box
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                flexDirection: 'column',
                                                textAlign: 'center',
                                                backgroundColor: '#fff',
                                                borderRadius: 16,
                                                padding:
                                                    '1rem 1rem 1.5rem 1rem',
                                            }}
                                        >
                                            <Typography
                                                className={classes.title}
                                            >
                                                Daily Earnings vs Payments
                                            </Typography>
                                            <BarChart
                                                width={
                                                    window.screen.width * 0.9
                                                }
                                                height={400}
                                                data={
                                                    dashboard?.earningItems?.map(
                                                        (item) => ({
                                                            Payment:
                                                                item.paymentAmount,
                                                            Earning:
                                                                item.earningAmount,
                                                            ...item,
                                                        }),
                                                    ) ?? []
                                                }
                                            >
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis
                                                    dataKey="dateText"
                                                    margin={{
                                                        top: 1000,
                                                    }}
                                                />
                                                <YAxis />
                                                <Tooltip />
                                                <Legend
                                                    verticalAlign="bottom"
                                                    wrapperStyle={{
                                                        position: 'relative',
                                                        marginTop: '-10px',
                                                    }}
                                                />
                                                <Bar
                                                    dataKey="Earning"
                                                    fill="rgba(67,65,158,0.8)"
                                                />
                                                <Bar
                                                    dataKey="Payment"
                                                    fill="rgba(244, 67, 54, 0.8)"
                                                />
                                            </BarChart>
                                        </Box>
                                    </Grid>
                                ) : (
                                    <></>
                                )}
                                {dashboard?.totalEarnings != 0 &&
                                dashboard?.totalAllowance != 0 &&
                                dashboard?.totalDeduction != 0 ? (
                                    <Grid item xs={12}>
                                        <Box
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                flexDirection: 'column',
                                                textAlign: 'center',
                                                backgroundColor: '#fff',
                                                borderRadius: 16,
                                                padding:
                                                    '1rem 1rem 1.5rem 1rem',
                                            }}
                                        >
                                            <Typography
                                                className={classes.title}
                                            >
                                                Expense types
                                            </Typography>

                                            <PieChart
                                                width={
                                                    window.screen.width * 0.9
                                                }
                                                height={420}
                                            >
                                                <Legend
                                                    verticalAlign="bottom"
                                                    wrapperStyle={{
                                                        position: 'relative',
                                                        marginTop: '-10px',
                                                    }}
                                                />
                                                <Tooltip />
                                                <Pie
                                                    data={[
                                                        {
                                                            name: 'Earnings',
                                                            value:
                                                                dashboard?.totalEarnings ??
                                                                0,
                                                        },
                                                        {
                                                            name: 'Allowance/Bonus',
                                                            value:
                                                                dashboard?.totalAllowance ??
                                                                0,
                                                        },
                                                        {
                                                            name: 'Deductions',
                                                            value:
                                                                dashboard?.totalDeduction ??
                                                                0,
                                                        },
                                                    ]}
                                                    label={customizeLabel}
                                                    // label={renderCustomizedLabel}
                                                    // labelLine={true}
                                                    isAnimationActive={false}
                                                >
                                                    <Cell
                                                        key={`cell-0`}
                                                        fill={COLORS[0]}
                                                    />
                                                    <Cell
                                                        key={`cell-2`}
                                                        fill={COLORS[2]}
                                                    />
                                                    <Cell
                                                        key={`cell-1`}
                                                        fill={COLORS[1]}
                                                    />
                                                </Pie>
                                            </PieChart>
                                        </Box>
                                    </Grid>
                                ) : (
                                    <></>
                                )}
                                <Grid item xs={3}></Grid>
                                {/* 
                <Grid item xs={6}>
                    <Paper
                        className={classes.paperStyle}
                        elevation={0}
                        onClick={() => {
                            alert('test1');
                        }}
                    >
                        <Typography className={classes.title}>
                            Table Showing User�s Payments Vs Closing Balance{' '}
                        </Typography>
                        <Typography className={classes.subtitle}>
                            Filter : Can choose specific month{' '}
                        </Typography>
                    </Paper>
                </Grid>
                <Grid item xs={6}>
                    <Paper
                        className={classes.paperStyle}
                        elevation={0}
                        onClick={() => {
                            alert('test1');
                        }}
                    >
                        <Typography className={classes.title}>
                            Bar Graph showing Month-wise Earnings / Payments{' '}
                        </Typography>
                        <Typography className={classes.subtitle}>
                            Filter : Can see it for an entire current year or
                            select for month ranges{' '}
                        </Typography>
                    </Paper>
                </Grid> */}
                            </Grid>
                        </>
                    ) : (
                        <Box
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <EmptyIcon />
                        </Box>
                    )

                    // )
                    // : (
                    //     <Box
                    //         style={{
                    //             display: 'flex',
                    //             alignItems: 'center',
                    //             justifyContent: 'center',
                    //         }}
                    //     >
                    //         <EmptyIcon />
                    //     </Box>
                    // )
                }
            </Spinner>
        </div>
    );
}

const mapStateToProps = (state) => ({
    dashboard: state.report.dashboard,
});

const mapDispatchToProps = (dispatch) => ({
    dispatchGetDasboardReport: (payload, onSuccess, onError) =>
        dispatch(GetDasboardReport(payload, onSuccess, onError)),

    dispatchSetDashboardReportData: (payload) =>
        dispatch(setDashboardReportData(payload)),
});

export const Dashboard = connect(
    mapStateToProps,
    mapDispatchToProps,
)(DashboardCompenent);
