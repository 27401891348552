import React, { useEffect, useState } from 'react';
import {
    Box,
    Paper,
    Typography,
    makeStyles,
    IconButton,
    Tooltip,
    MenuItem,
    withStyles,
} from '@material-ui/core';
import { CustomMonthPicker } from '../../../components/DateTimePicker';
import Searchbar from '../../../components/Searchbar';
import { Spinner } from '../../../components/UI';
import PayslipListContainer from './PayslipListContainer';
import { connect } from 'react-redux';
import { GetPayslipReport } from '../../../store/actions/reportActionCreator';
import { getHubList } from '../../../store/actions/staffActionCreator';
import { getLoginUser, JSONToCSVConvertor } from '../../../utils/helper';
import { CompactCustomTextField } from '../../../components/Textfield';
import moment from 'moment';
import InsertInvitationIcon from '@material-ui/icons/InsertInvitation';
import { EmptyIcon } from '../../../assets/svg';
import GetAppIcon from '@material-ui/icons/GetApp';
import { useDebounce } from '../../../hooks/useDebounce';

const useStyles = makeStyles((theme) => ({
    paymentsReportContainer: {
        padding: '1.5rem',
    },
    horizontalCardContents: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'spaceBetween',
    },
    searchBar: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
    },
    verticalCardContents: {
        flex: '1 1 auto',
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        justifyContent: 'center',
        border: 'solid 1px #ebedf8',
        borderRadius: '8px',
        backgroundColor: '#f6f7f9',
        paddingLeft: '16px',
        height: '88px',
        margin: '0 8px 0 0 ',
    },
    verticalCardContentsNoMargin: {
        margin: '0',
    },
    cardSubHeader: {
        fontWeight: 600,
    },
    cardHeader: {
        fontSize: '20px',
        fontWeight: 700,
    },
}));

const StyledMenuItem = withStyles((theme) => ({
    root: {
        margin: '0 8px',
        border: '0 !important',
        borderRadius: '8px',
        '&:hover': {
            backgroundColor: 'rgba(67, 65, 158, 0.08)',
            color: '#43419e',
            borderRadius: '8px',
        },
    },
}))(MenuItem);

export function PayslipReportComponent({
    staff,
    payslip,
    permissions,
    orgId,
    dispatchGetPayslipReport,
    dispatchGetHubList,
    ...props
}) {
    const { debounce, isBouncing } = useDebounce();

    const classes = useStyles();

    const [selectedMonth, setSelectedMonth] = useState();
    const [loading, toggleLoading] = useState(false);
    const [defaultList, setDefaultList] = useState(payslip?.lineItems ?? []);

    useEffect(() => {
        fetchPayslipReport({
            dateRange: {
                startTimeEpochMillis: moment().startOf('month').valueOf(),
                endTimeEpochMillis: moment().endOf('month').valueOf(),
            },
        });
        dispatchGetHubList(
            orgId,
            (resp) => {
                toggleLoading(false);
            },
            (resp) => {
                toggleLoading(false);
            },
        );
    }, []);

    useEffect(() => {
        setDefaultList(payslip?.lineItems);
    }, [payslip?.lineItems]);

    useEffect(() => {
        if (!selectedMonth) return;
        fetchPayslipReport({
            dateRange: {
                startTimeEpochMillis: moment(selectedMonth)
                    .startOf('month')
                    .valueOf(),
                endTimeEpochMillis: moment(selectedMonth)
                    .endOf('month')
                    .valueOf(),
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedMonth]);

    const fetchPayslipReport = (filters = {}) => {
        toggleLoading(true);
        const payload = {
            dateRange: {
                startTimeEpochMillis: moment().startOf('month').valueOf(),
                endTimeEpochMillis: moment().endOf('month').valueOf(),
            },
            ...filters,
        };
        dispatchGetPayslipReport(
            payload,
            (resp) => {
                console.log(resp);
                toggleLoading(false);
            },
            (err) => {
                console.log(err);
                toggleLoading(false);
            },
        );
    };

    return (
        <Paper className={classes.paymentsReportContainer} elevation={0}>
            <Spinner loading={loading}>
                <Box
                    style={{
                        marginBottom: '1rem',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'flex-end',
                    }}
                >
                    <Box className={classes.searchBar}>
                        <Box style={{ width: '20%' }}>
                            <CustomMonthPicker
                                onChange={(e) => {
                                    console.log(moment(e).valueOf());
                                    setSelectedMonth(e);
                                    // setSelectedDate(moment(e));
                                }}
                            />
                        </Box>
                        <CompactCustomTextField
                            label=""
                            select
                            fullWidth={true}
                            defaultValue={'0'}
                            onChange={(e) => {
                                let value = e.target.value;
                                console.log('blur', value);
                                setDefaultList(
                                    value === '0'
                                        ? payslip?.lineItems
                                        : payslip?.lineItems.filter((item) =>
                                              item?.hubName
                                                  ?.toLowerCase()
                                                  ?.includes(
                                                      value?.toLowerCase(),
                                                  ),
                                          ),
                                );
                            }}
                            style={{
                                marginLeft: 10,
                                marginRight: 10,
                                width: '20%',
                            }}
                        >
                            <StyledMenuItem value={'0'}>
                                Hub Name
                            </StyledMenuItem>
                            {staff?.hubs?.length &&
                                staff?.hubs.map((hub, index) => (
                                    <StyledMenuItem
                                        key={index}
                                        value={hub.name}
                                    >
                                        {hub.name}
                                    </StyledMenuItem>
                                ))}
                        </CompactCustomTextField>
                        <Searchbar
                            placeHolderText="Search by staff"
                            // placeHolderStyles={{
                            //     color: '#979797',
                            // }}
                            style={{
                                // border: '1px solid #f1f3f6',
                                // color: '#14112d',
                                width: '30%',
                            }}
                            dataSource={[]}
                            keys={['']}
                            fullWidth={false}
                            onFilter={async (filteredData) => {}}
                            onChange={(value) => {
                                console.log('blur', value);
                                setDefaultList(
                                    payslip?.lineItems.filter((item) =>
                                        item?.userName
                                            ?.toLowerCase()
                                            .includes(value?.toLowerCase()),
                                    ),
                                );
                            }}
                        />
                    </Box>
                    <Box
                        className={classes.searchBar}
                        style={{ justifyContent: 'flex-end', width: '20%' }}
                    >
                        <Tooltip title="Download Report" placement="top">
                            <IconButton
                                className={'inputbutton'}
                                variant="outlined"
                                style={{
                                    marginRight: 16,
                                    border: '1px solid #43419e',
                                    borderRadius: 8,
                                    padding: 6,
                                }}
                                color="primary"
                                onClick={(e) => {
                                    debounce(() => {
                                        JSONToCSVConvertor(
                                            'Payslip Report',
                                            defaultList.map((item) => ({
                                                'User Name': item.userName,
                                                'Hub Name': item.hubName,
                                                'Generated Date':
                                                    item.dateDisplay,
                                                Amount: item.amount,
                                                Notes: item.notes,
                                            })),
                                            'Payslip Report',
                                            true,
                                        );
                                    });
                                }}
                                disabled={isBouncing || !defaultList?.length}
                            >
                                <GetAppIcon size="small" />
                            </IconButton>
                        </Tooltip>
                    </Box>
                </Box>
                <Box
                    style={{
                        marginBottom: '1rem',
                    }}
                >
                    <Box className={classes.horizontalCardContents}>
                        <Box className={classes.verticalCardContents}>
                            <Typography className={classes.cardHeader}>
                                Total Payslips
                            </Typography>
                            <Typography className={classes.cardSubHeader}>
                                {payslip?.total ?? 0}
                            </Typography>
                        </Box>
                        <Box className={classes.verticalCardContents}>
                            <Typography className={classes.cardHeader}>
                                Pending Signature
                            </Typography>
                            <Typography className={classes.cardSubHeader}>
                                {payslip?.pendingSignature ?? 0}
                            </Typography>
                        </Box>
                        <Box className={classes.verticalCardContents}>
                            <Typography className={classes.cardHeader}>
                                Pending Approval
                            </Typography>
                            <Typography className={classes.cardSubHeader}>
                                {payslip?.pendingApproval ?? 0}
                            </Typography>
                        </Box>
                        <Box
                            className={`${classes.verticalCardContents} ${classes.verticalCardContentsNoMargin}`}
                        >
                            <Typography className={classes.cardHeader}>
                                Ready to Payment
                            </Typography>
                            <Typography className={classes.cardSubHeader}>
                                {payslip?.readyForPayment ?? 0}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
                <Box
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    {defaultList?.length ? (
                        <PayslipListContainer
                            rows={defaultList ?? []}
                            fetchPayslipReport={() =>
                                fetchPayslipReport({
                                    dateRange: {
                                        startTimeEpochMillis: moment(
                                            selectedMonth,
                                        )
                                            .startOf('month')
                                            .valueOf(),
                                        endTimeEpochMillis: moment(
                                            selectedMonth,
                                        )
                                            .endOf('month')
                                            .valueOf(),
                                    },
                                })
                            }
                            permissions={permissions}
                        />
                    ) : (
                        <EmptyIcon />
                    )}
                </Box>
            </Spinner>
            {/* {datePickerOpen && (
                <CustomMonthPicker
                    open={datePickerOpen}
                    onOpen={() => setDatePickerOpen(true)}
                    onClose={() => setDatePickerOpen(false)}
                    format="d MMM yyyy"
                    value={selectedMonth}
                    onChange={(e) => {
                        console.log(moment(e).valueOf());
                        setSelectedMonth(e);
                        // setSelectedDate(moment(e));
                    }}
                />
            )} */}
        </Paper>
    );
}

const mapStateToProps = (state) => ({
    staff: state.staff,
    payslip: state.report.payslip,
    orgId: state?.settings?.basicDetails?.id,
});

const mapDispatchToProps = (dispatch) => ({
    dispatchGetPayslipReport: (payload, onSuccess, onError) =>
        dispatch(GetPayslipReport(payload, onSuccess, onError)),
    dispatchGetHubList: (id, onSuccess, onError) =>
        dispatch(getHubList(id, onSuccess, onError)),
});

export const PayslipReport = connect(
    mapStateToProps,
    mapDispatchToProps,
)(PayslipReportComponent);
