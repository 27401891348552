import React from 'react';
import './styles.scss';
import { makeStyles } from '@material-ui/core/styles';
import { Menu, Button, MenuItem, IconButton } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { withStyles } from '@material-ui/core/styles';
import MoreVertIcon from '@material-ui/icons/MoreVert';

const CustomDropdown = ({
    title,
    options,
    optionStyles,
    expandMoreButtonStyles,
    styledMenuItemStyles,
    buttonStyles,
    buttonProps,
    ...props
}) => {
    const useStyles = makeStyles((theme) => ({
        options: optionStyles ?? {
            backgroundColor: 'white',
            color: theme.palette.primary.main,
            textTransform: 'none',
            padding: '4px 4px 4px 8px !important',
        },

        expandMoreButton: expandMoreButtonStyles ?? {
            padding: '0px !important',
        },
    }));

    const StyledMenuItem = withStyles((theme) => ({
        root: styledMenuItemStyles ?? {
            margin: '0 8px',
            color: '#767b84',
            minHeight: '36px',
            fontSize: '14px',
            border: '0 !important',
            borderRadius: '8px',
            '&:hover': {
                backgroundColor: 'rgba(67, 65, 158, 0.08)',
                color: '#43419e',
                borderRadius: '8px',
            },
        },
    }))(MenuItem);

    const StyledMenu = withStyles({})((props) => (
        <Menu
            style={{
                margin: '4px 0 0 0 ',
                padding: '0',
            }}
            // elevation={2}
            getContentAnchorEl={null}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            {...props}
        />
    ));
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (event) => {
        event.stopPropagation();
        setAnchorEl(null);
    };

    const classes = useStyles();
    return (
        <>
            {title ? (
                <Button
                    aria-controls="customized-menu"
                    aria-haspopup="true"
                    color="default"
                    className={classes.options}
                    onClick={handleClick}
                    style={{
                        fontWeight: 600,
                        border: '1px solid #95a0da',
                        ...(!!buttonStyles ? { ...buttonStyles } : {}),
                    }}
                    {...buttonProps}
                    disableElevation
                >
                    {title.hasOwnProperty('render') ? (
                        title.render
                    ) : (
                        <>
                            {title}
                            {!buttonProps?.disabled && (
                                <ExpandMoreIcon
                                    fontSize="small"
                                    style={{
                                        marginLeft: '4px',
                                    }}
                                />
                            )}
                        </>
                    )}
                </Button>
            ) : (
                <IconButton
                    onClick={handleClick}
                    className={'inputbutton'}
                    variant="outlined"
                    style={{
                        marginRight: 8,
                        border: '1px solid #43419e',
                        borderRadius: 8,
                        padding: 6,
                        ...(!!buttonStyles ? { ...buttonStyles } : {}),
                    }}
                    color="primary"
                >
                    <MoreVertIcon />
                </IconButton>
            )}
            <StyledMenu
                id="customized-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                {...props}
            >
                {options?.map(({ onSuccess, ...option }, index) => (
                    <StyledMenuItem
                        key={index}
                        button
                        onClick={(e) => {
                            handleClose(e);
                            onSuccess && onSuccess(e);
                        }}
                        {...option}
                    >
                        {!!option?.render
                            ? option?.render()
                            : option?.item ?? 'default'}
                    </StyledMenuItem>
                ))}
            </StyledMenu>
        </>
    );
};

export default CustomDropdown;
