import React, { useState, useEffect } from 'react';
import { Button, withStyles } from '@material-ui/core';

export const ConvertToStaffCTA = withStyles((theme) => ({
    root: {
        color: '#fff',
        marginRight: 16,
        backgroundColor: '#4CAF50',
        '&:hover': {
            backgroundColor: '#4CAF50',
        },
    },
    disabled: {
        backgroundColor: '#4CAF50 !important',
        color: '#fff !important',
        opacity: 0.5,
    },
}))(Button);
