import { makeStyles } from '@material-ui/core';

const useEVStyles = makeStyles((theme) => ({
    container: {
        height: 'calc(100vh - 90px)',
        overflow: 'auto',
        paddingBottom: '0px',
    },
    searchBar: {
        width: 'calc(100vw - 500px)',
    },
    filterCard: {
        padding: theme.spacing(2),
        color: '#14112D',
        borderRadius: '3.8px !important',
        backgroundColor: '#F6F7F9',
        boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.06)',
        border: '1px solid #EBEDF8',
        cursor: 'pointer',
    },
    permCard: {
        padding: '12px 16px',
        color: '#14112D',
        borderRadius: '4px !important',
        backgroundColor: '#E4F8E4',
        boxShadow: 'none',
        //border: '1px solid #EBEDF8',
        minWidth: 145,
    },
    permCardRed: {
        backgroundColor: '#FEEAE9',
        minWidth: 220,
    },
    filterTitle: {
        fontSize: '16px',
        fontWeight: 600,
        color: '#14112D',
        marginBottom: theme.spacing(1),
    },
    filterValue: {
        fontSize: '22px',
        fontWeight: 700,
        color: '#14112D',
    },
    permValue: {
        fontSize: '14px',
        fontWeight: 700,
        color: '#14112D',
    },
    permTitle: {
        fontSize: '12px',
        fontWeight: 700,
        color: '#14112D',
        marginBottom: theme.spacing(1),
    },
    permSubValue: {
        fontSize: '12px',
        fontWeight: 600,
        color: 'rgba(20, 17, 45, 0.50)',
    },
    filterSubValue: {
        fontSize: '22px',
        fontWeight: 700,
        color: 'rgba(20, 17, 45, 0.50)',
    },
    filterByValue: {
        fontSize: '18px',
        fontWeight: 700,
        color: '#14112D',
    },
    tableContainer: {
        width: '100%',
        height: '100%',
        overflowY: 'auto',
        paddingRight: '8px',
        minHeight: 'calc(100vh - 352px)',
        maxHeight: 'calc(100vh - 352px)',
        '&::-webkit-scrollbar': {
            width: '8px',
        },
        '&::-webkit-scrollbar-track': {
            background: '#f1f1f1',
            borderRadius: '8px',
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#888',
            borderRadius: '8px',
        },
        '&::-webkit-scrollbar-thumb:hover': {
            background: '#555',
        },
    },
    active: {
        backgroundColor: 'rgba(76, 175, 80, 0.16) !important',
        // border: '1px solid #4caf50 !important',
        color: '#4caf50 !important',
    },
    activeWO_BG: {
        backgroundColor: 'inherit !important',
        //border: '1px solid #4caf50 !important',
        color: '#4CAF50 !important',
        border: 'none !important',
    },
    pending: {
        backgroundColor: 'rgba(255, 152, 0, 0.16) !important',
        //border: '1px solid #ff9800 !important',
        color: '#ff9800 !important',
    },
    pendingWO_G: {
        backgroundColor: 'inherit !important',
        //border: '1px solid #ff9800 !important',
        color: '#ff9800 !important',
        border: 'none !important',
    },
    reject: {
        backgroundColor: 'rgba(244, 67, 54, 0.16) !important',
        //border: '1px solid #f44336 !important',
        color: '#f44336 !important',
    },
    rejectWO_BG: {
        backgroundColor: 'inherit !important',
        //border: '1px solid #f44336 !important',
        color: '#f44336 !important',
        border: 'none !important',
    },
    inactive: {
        backgroundColor: 'rgba(63, 81, 181, 0.16) !important',
        //border: '1px solid #3f51b5 !important',
        color: '#3f51b5 !important',
    },
    inactiveWO_BG: {
        backgroundColor: 'inherit !important',
        //border: '1px solid #3f51b5 !important',
        color: '#3f51b5 !important',
        border: 'none !important',
    },
    none: {
        backgroundColor: '#F4F4F4',
        //border: '1px solid #686868',
        color: '#686868',
    },
    evNumber: {
        fontSize: '16px',
        fontWeight: 600,
        color: '#14112D',
        lineHeight: '20px',
    },
    evVendor: {
        fontSize: '16px',
        fontWeight: 600,
        color: '#14112D',
        lineHeight: '20px',
        opacity: 0.72,
        marginRight: '16px',
    },
    vehicleIgnitionStatus: {
        fontSize: '16px',
        fontWeight: 500,
        color: '#14112D',
        lineHeight: '20px',
        marginLeft: '16px',
    },
    ON: {
        color: '#4CAF50',
    },
    ROFF: {
        color: '#ED0000',
    },
    OFF: {
        color: '#CE7E07',
    },
    directionCTA: {
        borderRadius: '8px',
        border: '1px solid #EAEAEA',
        backgroundColor: '#FFF',
        padding: '12px',
        fontSize: '16px',
    },
    directionIcon: {
        fontSize: '16px',
    },
    closeCTA: {
        fontSize: '16px',
        marginLeft: '24px',
        padding: 0,
        alignSelf: 'baseline',
    },
    closeIcon: {
        fontSize: '16px',
        color: '#1D1D1D',
    },
    remoteSwitch: {
        marginLeft: 2,
    },
    tableValue: {
        fontSize: '16px',
        fontWeight: 600,
        color: '#14112D',
        lineHeight: '20px',
        marginBottom: 0.5,
    },
    tableValueInRed: {
        fontSize: '16px',
        fontWeight: 600,
        color: '#ED0000',
        lineHeight: '20px',
        marginBottom: 0.5,
    },
    tableSubValue: {
        fontSize: '16px',
        fontWeight: 500,
        color: '#14112D',
        opacity: 0.72,
    },
    tableSubValueRed: {
        fontSize: '12px',
        fontWeight: 500,
        color: '#ED0000',
    },
    chip: {
        fontSize: 12,
        width: 'auto',
        color: ' #fff',
        border: '1px solid #fff',
        borderRadius: '8px',
        padding: '2px 12px',
        //margin: '0px 8px',
        textAlign: 'center',
        //backgroundColor: 'black',
    },
    chipText: {
        fontSize: 12,
        color: 'inherit',
        textTransform: 'uppercase',
        fontWeight: 700,
    },
    menu: {
        margin: '0 8px',
        color: '#000',
        minHeight: '36px',
        fontSize: '14px',
        border: '0 !important',
        borderRadius: '8px',
        '&:hover': {
            backgroundColor: 'rgba(67, 65, 158, 0.08)',
            color: '#43419e',
            borderRadius: '8px',
        },
    },
    taggedUsedGoal: {
        padding: '8px 16px',
        borderRadius: '8px',
        border: '1px solid #EAEAEA',
        cursor: 'pointer',
        //backgroundColor: '#fff',
    },
    popOver: {
        //marginTop: 180,
        borderRadius: '4px !important',
        //backgroundColor: '#FFF !important',
        boxShadow: '0px 1px 16px 0px rgba(0, 0, 0, 0.16) !important',
        padding: '16px',
    },
}));

export default useEVStyles;
