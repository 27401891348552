import React from 'react';
import { Route } from 'react-router-dom';
import { withRouter } from 'react-router-dom';

const RouteWithTitle = ({ component, path, name, exact }) => {
    document.title = getDocumentTitle(name);
    return (
        <Route component={withRouter(component)} path={path} exact={exact} />
    );
};
function getDocumentTitle(name) {
    let titleParts = document.title.split('|');
    titleParts[0] = 'StaffPay';
    titleParts[1] = ` ${name} `;
    return titleParts.join(' |');
}

export default RouteWithTitle;
