import * as constants from './../constants';
import { API_AUTH_SERVICE_PORT } from '../../config/env/env';
import { sourceSystems } from '../../utils/constants';

/**
 * Send OTP API Call - Login
 * @param {*} data
 * @param {*} onSuccess
 * @param {*} onError
 * @returns
 */
export const sendOTP = (data, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: `/auth/sendOtp/${data}?source=StaffPay&loginFrom=desktop`,
        method: 'GET',
        data,
        success: (response) => {
            return { type: constants.DO_NOTHING, payload: response };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_AUTH_SERVICE_PORT,
    },
});

/**
 * Validate OTP API call - on successful response, persist the user info in local storage and redux store
 * @param {*} data
 * @param {*} onSuccess
 * @param {*} onError
 * @returns
 */
export const validateOTP = (
    { overwriteHeaders, ...data },
    onSuccess,
    onError,
) => ({
    type: constants.API,
    payload: {
        url: '/auth/login',
        method: 'POST',
        data,
        success: (response) => setUserInfo(response),
        // success: (response) => {
        //     return { type: constants.DO_NOTHING, payload: response };
        // },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_AUTH_SERVICE_PORT,
        overwriteHeaders,
    },
});

export const refreshToken = (onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: '/auth/refresh-login',
        method: 'GET',
        success: (response) => setUserInfo(response),
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_AUTH_SERVICE_PORT,
    },
});

/**
 * On Valiate OTP success, we parse the response data and persist user info in local storage
 * @param {*} data
 * @param {*} loginFromWeb
 * @returns
 */
export const setUserInfo = (data) => {
    console.log('AuthActionCreator', 'the data is', data);
    let userInfo = {
        id: null,
        name: null,
        orgId: null,
        contactNumber: null,
    };

    // Response to have status 200 for all success cases
    if (data && data.status && data.status.code === 200) {
        if (
            data &&
            data.response &&
            data.response.token &&
            data.response.userBO
        ) {
            userInfo = {
                ...data.response.userBO,
                token: data.response.token,
            };
            console.log('login info', userInfo, window);
            console.log('login info2', window);
            localStorage.setItem(
                'USER_INFO',
                window.btoa(
                    unescape(encodeURIComponent(JSON.stringify(userInfo))),
                ),
            );
        }
        return { type: constants.SET_USER_INFO, payload: userInfo };
    }
    return { type: constants.SET_USER_INFO, payload: userInfo };
};

export const fetchAppConfig = (onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: `/common/configs`,
        method: 'GET',
        // success: response => {
        //     return {
        //         type: constants.SET_CONFIG,
        //         payload: response.response,
        //     };
        // },
        success: (response) => setConfigInfo(response),
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_AUTH_SERVICE_PORT,
    },
});

const setConfigInfo = (data, loginFromWeb) => {
    // logger.log('AuthActionCreator', 'the data is', data);
    let configInfo = {};

    console.log('DATAAAAAA:', data);

    // Response to have status 200 for all success cases
    if (data && data.status && data.status.code === 200) {
        if (data && data.response && data.response.configs) {
            configInfo = {
                ...data.response.configs,
            };

            console.log('VALLL', configInfo);
            // localStorage.setItem(
            //     'CONFIGS',
            //     window.btoa(JSON.stringify(configInfo)),
            // );

            // AsyncStorage.setItem("USER_INFO", JSON.stringify(userInfo));
        }
    }
    return { type: constants.SET_CONFIG, payload: configInfo };
};

export const getSourceSystemsList = (data, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        source: sourceSystems.Zappay,
        url: `/org/source-systems`,
        method: 'GET',
        success: (response) => {
            return {
                type: constants.SET_SOURCE_SYSTEMS_LIST_DATA,
                payload: response?.response?.sourceSystems,
            };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
    },
});

export const getSourceSystemsFiltersListData = (data, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        source: sourceSystems.Zappay,
        url: `/org/source-system/${data?.id}/filters`,
        method: 'GET',
        success: (response) => {
            return {
                type: constants.SET_SOURCE_SYSTEMS_FILTER_LIST_DATA,
                payload: response?.response?.filters,
            };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
    },
});

export const uploadPaymentIndents = (data, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        source: sourceSystems.Zappay,
        url: `/user/payment-indents/upload`,
        method: 'POST',
        data: data,
        success: (response) => {
            return {
                type: constants.DO_NOTHING,
            };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
    },
});

export const getAllPaymentIndentsListingData = (data, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        source: sourceSystems.Zappay,
        url: `/user/payment-indents`,
        method: 'POST',
        data: data,
        success: (response) => {
            return {
                type: constants.SET_ALL_PAYMENT_INDENTS_LIST_DATA,
                payload: response?.response,
            };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
    },
});

export const setFilterModalConfig = (data, onSuccess, onError) => {
    console.log('setFilterModalConfig:', data);
    return { type: constants.SET_FILTER_MODAL_CONFIG, payload: data };
};

export const setBulkReleaseFlag = (data) => {
    return { type: constants.SET_BULK_RELEASE, payload: data };
};

export const setSelectedPayments = (data) => {
    return {
        type: constants.SET_SELECTED_PAYMENTS,
        payload: data,
    };
};

export const getAllContactsListingData = (data, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        source: sourceSystems.Zappay,
        url: `/user/beneficiaries`,
        method: 'GET',
        success: (response) => {
            return {
                type: constants.SET_ALL_BENEFICIARIES_LIST_DATA,
                payload: response?.response,
            };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
    },
});

export const setIgnoreDateFilter = (data, onSuccess, onError) => {
    console.log('setIgnoreDateFilter:', data);
    return { type: constants.SET_IGNORE_DATE_FILTER_FLAG, payload: data };
};

// ====================== ZAPPAY PAYMENTS

export const sendSinglePayment = (data, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        source: sourceSystems.Zappay,
        url: `/user/payment`,
        method: 'POST',
        data: data,
        success: (response) => {
            return {
                type: constants.DO_NOTHING,
                // payload: response?.response,
            };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
    },
});

export const sendBatchPayment = (data, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        source: sourceSystems.Zappay,
        url: `/user/payment/batch`,
        method: 'POST',
        data: data,
        success: (response) => {
            return {
                type: constants.DO_NOTHING,
                // payload: response?.response,
            };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
    },
});

export const deleteSinglePayment = (data, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        source: sourceSystems.Zappay,
        url: `/user/payment-indent/${data}`,
        method: 'DELETE',
        success: (response) => {
            return {
                type: constants.DO_NOTHING,
                // payload: response?.response,
            };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
    },
});

export const deleteBatchPayment = (data, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        source: sourceSystems.Zappay,
        url: `/user/payment-indents`,
        method: 'DELETE',
        data: data,
        success: (response) => {
            return {
                type: constants.DO_NOTHING,
                // payload: response?.response,
            };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
    },
});

export const updatePaymentStatus = ({ id, data }, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        source: sourceSystems.Zappay,
        url: `/user/payment-indent/${id}/status`,
        method: 'PUT',
        data: data,
        success: (response) => {
            return {
                type: constants.DO_NOTHING,
                // payload: response?.response,
            };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
    },
});
