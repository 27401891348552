import { makeStyles } from '@material-ui/core';
import React from 'react';
const useRiderInfoModalStyles = makeStyles((theme: any) => ({
    cardSubHeader: {
        fontSize: '22px',
        fontWeight: 700,
    },
    cardHeader: {
        fontSize: '16px',
        fontWeight: 600,
    },
    cardHeaderFail: {
        fontSize: '16px',
        fontWeight: 600,
        color: '#FB8A06',
    },
    cardSubHeaderFail: {
        fontSize: '22px',
        fontWeight: 700,
        color: '#FB8A06',
    },
    cardHeaderActive: {
        fontWeight: 700,
    },
    verticalCardContentsNoMargin: {
        margin: 0,
    },
    iconButton: {
        padding: '0px',
    },
    modalTitle: {
        fontSize: '20px',
        fontWeight: 'bold',
        color: '#14112d',
        marginLeft: '14px',
    },
    modalTitleV2: {
        fontSize: '20px',
        fontWeight: 'bold',
        color: '#14112d',
    },
    modalSubTitle: {
        fontSize: '14px',
        fontWeight: 'normal',
        color: '#14112d',
        marginLeft: '14px',
    },
    dialogContent: {
        paddingLeft: 0,
        paddingRight: 0,
    },
    dialogActions: {
        padding: '16px 24px',
    },
    name: {
        fontSize: 16,
        fontWeight: 700,
        color: '#14112D',
    },
    backDropRoot: {
        position: 'absolute',
        zIndex: theme.zIndex.drawer - 1,
        opacity: 0.5,
    },
    workLocation: {
        fontWeight: 600,
        fontSize: 16,
        color: ' #43419E',
    },
    minimizeButton: {
        color: '#43419E',
        background: 'rgba(67, 65, 158, 0.2)',
        borderRadius: 8,
        padding: '6px 8px',
        fontSize: 16,
        fontWeight: 600,
        cursor: 'pointer',
    },
    disabled: {
        backgroundColor: '#cccccc !important',
        color: '#FFFFFF',
    },
    untagCTa: {
        backgroundColor: '#F44336',
    },
    bottomBorder: {
        borderBottom: '1px solid #EAEDF3 !important',
    },
    input: {
        padding: '12px 12px !important',
    },
    box: {
        border: '1px solid #43419E',
        color: '#43419E',
        //borderRadius: 8,
        marginRight: '12px',
        padding: '1px',
    },
    rider: {
        border: '1px solid #000000',
        color: ' #000000',
        //borderRadius: 8,
        //padding: '8px',
        marginRight: '12px',
        padding: '1px',
    },
    alertIcon: {
        color: '#ED837F',
        fontSize: 16,
        margin: '0px 2px',
    },
    chip: {
        fontSize: 12,
        width: 'auto',
        color: ' #fff',
        border: '1px solid #fff',
        borderRadius: '8px',
        padding: '2px 12px',
        //margin: '0px 8px',
        textAlign: 'center',
        backgroundColor: 'black',
    },
    tableContainer: {
        width: '100%',
        height: '100%',
        overflowY: 'auto',
        paddingRight: '8px',
        minHeight: 'calc(100vh - 348px)',
        maxHeight: 'calc(100vh - 348px)',
        '&::-webkit-scrollbar': {
            width: '8px',
        },
        '&::-webkit-scrollbar-track': {
            background: '#f1f1f1',
            borderRadius: '8px',
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#888',
            borderRadius: '8px',
        },
        '&::-webkit-scrollbar-thumb:hover': {
            background: '#555',
        },
    },
    iframeContainer: {
        width: '100%',
        height: '100%',
        overflowY: 'auto',
        paddingRight: '8px',
        minHeight: 'calc(100vh - 348px)',
        maxHeight: 'calc(100vh - 348px)',
        '&::-webkit-scrollbar': {
            width: '8px',
        },
        '&::-webkit-scrollbar-track': {
            background: '#f1f1f1',
            borderRadius: '8px',
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#888',
            borderRadius: '8px',
        },
        '&::-webkit-scrollbar-thumb:hover': {
            background: '#555',
        },
    },
}));
export default useRiderInfoModalStyles;
