import * as constants from './../constants';
import { API_USER_SERVICE_PORT } from '../../config/env/env';

export const addStaffBonus = ({ id, ...data }, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: `/org/transaction/${id}/allowance`,
        method: 'POST',
        data,
        success: (response) => {
            console.log('add allowance', response);
            return { type: constants.DO_NOTHING, payload: response };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
    },
});

export const editStaffBonus = (
    { id, allowanceId, ...data },
    onSuccess,
    onError,
) => ({
    type: constants.API,
    payload: {
        url: `/org/transaction/${id}/allowance/${allowanceId}`,
        method: 'PUT',
        data: data,
        success: (response) => {
            return { type: constants.DO_NOTHING, payload: response };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
    },
});

export const deleteStaffBonus = (
    { id, allowanceId, ...data },
    onSuccess,
    onError,
) => ({
    type: constants.API,
    payload: {
        url: `/org/transaction/${id}/allowance/${allowanceId}`,
        method: 'DELETE',
        success: (response) => {
            return { type: constants.DO_NOTHING, payload: response };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
    },
});
