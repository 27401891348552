import { isFunction } from 'lodash';
import { filterArrayEmptyValue } from '../../../utils/helper';
const images = {
    evBackImage:
        'https://earn-de-docs.s3.ap-south-1.amazonaws.com/images/USER_161923808962782_1716458945882.jpg',
    evLeftImage:
        'https://earn-de-docs.s3.ap-south-1.amazonaws.com/images/USER_161923808962782_1716458951185.jpg',
    evWithRider:
        'https://earn-de-docs.s3.ap-south-1.amazonaws.com/images/USER_161923808962782_1716458959147.jpg',
    evRightImage:
        'https://earn-de-docs.s3.ap-south-1.amazonaws.com/images/USER_161923808962782_1716458956061.jpg',
    evLicense:
        'https://earn-de-docs.s3.ap-south-1.amazonaws.com/images/USER_161923808962782_1716458962989.jpg',
    evFrontImage:
        'https://earn-de-docs.s3.ap-south-1.amazonaws.com/images/USER_161923808962782_1716458935846.jpg',
    evOthers:
        '[https://earn-de-docs.s3.ap-south-1.amazonaws.com/images/USER_161923808962782_1716458935846.jpg, https://earn-de-docs.s3.ap-south-1.amazonaws.com/images/USER_161923808962782_1716458935846.jpg]',
};
export const EV_IMAGE_TYPES = Object.freeze({
    evBackImage: 'evBackImage',
    evFrontImage: 'evFrontImage',
    evLeftImage: 'evLeftImage',
    evRightImage: 'evRightImage',
    evWithRider: 'evWithRider',
    evLicense: 'evLicense',
    evOthers: 'evOthers',
});
export const EV_IMAGE_TYPE_UI_PROPS = Object.freeze({
    evBackImage: {
        title: 'EV Back',
        type: 'evBackImage',
        image: '',
        ctaText: '',
    },
    evFrontImage: {
        title: 'EV Front',
        type: 'evFrontImage',
        image: '',
        ctaText: '',
    },
    evLeftImage: {
        title: 'EV Left',
        type: 'evLeftImage',
        image: '',
        ctaText: '',
    },
    evRightImage: {
        title: 'EV Right',
        type: 'evRightImage',
        image: '',
        ctaText: '',
    },
    evWithRider: {
        title: 'Rider with EV',
        type: 'evWithRider',
        image: '',
        ctaText: '',
    },
    evLicense: {
        title: 'License',
        type: 'evLicense',
        image: '',
        ctaText: '',
    },
    evOthers: {
        title: 'EV Others',
        type: 'evOthers',
        image: '',
        ctaText: '',
        preProcess: (values) => {
            let temp = [];
            let parsed = !!values
                ? filterArrayEmptyValue(
                      values?.replace(/\[|\]/g, '')?.trim()?.split(', '),
                  )
                : [];
            if (!!parsed && !!parsed?.length) {
                parsed?.forEach((img, index) => {
                    if (!!img) {
                        temp.push({
                            title: `Other ${index + 1}`,
                            type: `evOthers${index + 1}`,
                            image: img,
                        });
                    }
                });
            }
            return temp;
        },
    },
});

export const GenerateImageDataFromEVDocument = (docs = {}) => {
    let temp = [];
    if (!!docs) {
        Object.keys(docs).forEach((key) => {
            if (docs?.[key]?.length > 0 && !!EV_IMAGE_TYPE_UI_PROPS?.[key]) {
                const preProcess = EV_IMAGE_TYPE_UI_PROPS?.[key]?.preProcess;
                if (key == EV_IMAGE_TYPES.evOthers) {
                    const imageData =
                        !!preProcess && !!isFunction(preProcess)
                            ? preProcess(docs?.[key])
                            : [];
                    temp = [...temp, ...imageData];
                } else {
                    temp.push({
                        ...EV_IMAGE_TYPE_UI_PROPS?.[key],
                        image:
                            !!preProcess && !!isFunction(preProcess)
                                ? preProcess(docs?.[key])
                                : docs?.[key],
                    });
                }
            }
        });
    }
    return temp;
};
