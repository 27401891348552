import './styles.scss';
import {
    Box,
    Button,
    makeStyles,
    Paper,
    TablePagination,
    Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Searchbar from '../../components/Searchbar';
import Modal from '../../components/Modals/Modal';
import { Spinner } from '../../components/UI';
import { EmptyIcon } from '../../assets/svg';
import { connect } from 'react-redux';
import {
    deleteEvVehicle,
    getAllEVVehicles,
    getAllEVVendors,
    getAllEVCaptains,
    getAllEVProjects,
    getAllEVTeams,
    blockUnblockEV,
} from '../../store/actions/settingsActionCreator';
import EVModal from '../../components/Modals/EVModal';
import UploadEvVehiclesModal from '../../components/Modals/UploadEvVehiclesModal';
import FilterModal, {
    FILTER_INPUT_TYPE,
} from '../../components/Modals/FilterModal';
import EVVehicleListingContainer from './EVVehicleListingContainer';
import EvHistoryModal from './components/EvHistoryModal';
import { showMessage } from '../../utils/helper';
const useStyles = makeStyles((theme) => ({
    listItemHeader: {
        border: '0px',
        marginBottom: '8px',
        '& .MuiTypography-body1': {
            color: '#525050',
            fontSize: 16,
            fontWeight: 'bold',
        },
    },
    listItem: {
        padding: '16px',
        marginBottom: '8px ',
        border: '1px solid #ebedf8 ',
        '& .MuiTypography-body1': {
            fontSize: 16,
            fontWeight: 400,
        },
        '&:nth-child(odd)': {
            backgroundColor: '#f8f9fa',
        },
    },
    saveButton: {
        backgroundColor: theme.palette.error.main,
        color: '#fff',
    },
}));

export const EVVEHICLE_STATUS_LIST = [
    {
        id: 1,
        status: 'TAGGED',
        label: 'Tagged',
    },
    {
        id: 2,
        status: 'AVAILABLE',
        label: 'Available',
    },
    {
        id: 3,
        status: 'UNTAGGED',
        label: 'UnTagged',
    },
    {
        id: 4,
        status: 'RELEASED',
        label: 'Released',
    },
];
export const EVVEHICLE_STATUS_LIST_V2 = [
    {
        id: 1,
        status: 'All',
        label: 'All',
    },
    {
        id: 2,
        status: 'TAGGED',
        label: 'Tagged',
    },
    {
        id: 3,
        status: 'AVAILABLE',
        label: 'Available',
    },
    {
        id: 4,
        status: 'UNTAGGED',
        label: 'UnTagged',
    },
    {
        id: 5,
        status: 'RELEASED',
        label: 'Released',
    },
];

const EVVehiclesComponent = ({
    vendorList,
    evListData,
    permissions,
    dispatchGetEvVehiclesListData,
    dispatchDeleteEV,
    dispatchGetAllEVVendors,
    dispatchGetAllEVTeams,
    dispatchGetAllEVCaptains,
    dispatchGetAllEVProjects,
    dispatchBlockUnblockEV,
    ...props
}) => {
    const evList = evListData?.lineItems ?? [];
    const classes = useStyles();
    const [page, setPage] = React.useState(props.location?.state?.page ?? 0);
    const [rowsPerPage, setRowsPerPage] = React.useState(
        props?.location?.state?.rowsPerPage ?? 10,
    );
    const [evHistoryModalOpen, setEvHistoryModalOpen] = useState(false);
    const [selected, setSelected] = useState({});
    const [isOpen, toggleModal] = useState(false);
    const [loading, toggleLoading] = useState(false);
    const [selectedRow, setSelectedRow] = useState();
    const [isDeleteModalOpen, toggleDeleteModal] = useState(false);
    const [deleteLoading, toggleDeleteLoading] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [bulkEvVehicleUploadModalOpen, setBulkEvVehicleUploadModalOpen] =
        useState(false);
    const [firstRenderDone, setFirstRenderDone] = useState(false);
    const [filteredValues, setFilteredValue] = useState({
        status: '',
        vendorId: null,
    });

    const [evListingPayload, setEvListingPayload] = React.useState({
        pageNo: 1,
        pageSize: 10,
        searchText: '',
        captains: '',
        project: '',
        status: '',
        ...filteredValues,
    });

    const handleChangePage = (event, newPage) => {
        console.log('SETPAGE ', newPage);
        setPage(newPage);
        setEvListingPayload((prev) => {
            return {
                ...prev,
                pageNo: newPage + 1,
            };
        });
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
        setEvListingPayload((prev) => {
            return {
                ...prev,
                pageNo: 1,
                pageSize: event.target.value,
            };
        });
    };

    useEffect(() => {
        setFirstRenderDone(true);
        dispatchGetAllEVVendors({});
        dispatchGetAllEVTeams({});
        dispatchGetAllEVProjects({});
        dispatchGetAllEVCaptains({});
    }, []);

    useEffect(() => {
        fetchEvVehiclesListData(evListingPayload);
    }, [evListingPayload]);

    const handleClose = () => {
        // Setting form data to null before closing
        toggleModal(false);
    };
    const openEditModal = async (row) => {
        await setSelectedRow({
            ...row,
            evVehicleStatusId: EVVEHICLE_STATUS_LIST?.filter(
                (item) => item?.status == row?.status,
            )?.[0]?.id,
        });
        toggleModal(true);
    };
    const openViewHistoryModal = async (row) => {
        await setSelectedRow({
            ...row,
            evVehicleStatusId: EVVEHICLE_STATUS_LIST?.filter(
                (item) => item?.status == row?.status,
            )?.[0]?.id,
        });
        setEvHistoryModalOpen(true);
    };
    const openDeleteModal = async (row) => {
        await setSelectedRow({
            ...row,
            evVehicleStatusId: EVVEHICLE_STATUS_LIST?.filter(
                (item) => item?.status == row?.status,
            )?.[0]?.id,
        });
        toggleDeleteModal(true);
    };
    const onPressMobilize = async (row) => {
        if (!!row?.evNumber && !!row?.cutoffStatus) {
            toggleLoading(true);
            dispatchBlockUnblockEV(
                {
                    uniqueIdentifier: row?.evNumber,
                    action: row?.cutoffStatus?.toLowerCase(),
                    evId: row?.id,
                },
                (resp) => {
                    toggleLoading(false);
                    showMessage(
                        resp?.status?.message,
                        resp?.status?.code == 200 ? 'success' : 'error',
                    );
                    fetchEvVehiclesListData(evListingPayload);
                },
                () => {
                    toggleLoading(false);
                },
            );
        }
    };
    const handleDeleteClose = () => {
        // Setting form data to null before closing
        toggleDeleteModal(false);
        fetchEvVehiclesListData(evListingPayload);
    };

    const fetchEvVehiclesListData = async (
        payload = {
            pageNo: 1,
            pageSize: 10,
            searchText: '',
            captains: '',
            project: '',
            status: '',
        },
    ) => {
        await toggleLoading(true);
        dispatchGetEvVehiclesListData(
            payload,
            (resp) => {
                console.log(resp);
                toggleLoading(false);
            },
            (resp) => {
                console.log(resp);
                toggleLoading(false);
            },
        );
    };

    const deleteEV = async () => {
        await toggleDeleteLoading(true);
        dispatchDeleteEV(
            selectedRow.id,
            (resp) => {
                console.log(resp);
                toggleDeleteLoading(false);
                handleDeleteClose();
            },
            (err) => {
                console.log(err);
                toggleDeleteLoading(false);
                handleDeleteClose();
            },
        );
    };

    const actions = [
        <Button
            key={1}
            variant="contained"
            color="default"
            className={`cancelButton`}
            onClick={handleDeleteClose}
            disableElevation
        >
            {'No'}
        </Button>,
        <Button
            key={2}
            variant="contained"
            color="default"
            className={`${classes.saveButton}`}
            onClick={(e) => deleteEV()}
            disableElevation
        >
            {'Yes'}
        </Button>,
    ];

    const prepareFilterOptions = () => {
        return [
            {
                type: FILTER_INPUT_TYPE.SELECT,
                label: 'Vendor',
                name: 'vendor',
                value: !!filteredValues?.vendorId
                    ? vendorList?.filter(
                          (item) => item?.id == filteredValues?.vendorId,
                      )?.[0]
                    : null,
                options: vendorList?.map((item) => ({
                    label: item.name,
                    value: item.id,
                    ...item,
                })),
                onChange: (e) => {
                    setFilteredValue({
                        ...filteredValues,
                        vendorId: Number(e?.target?.value?.id) ?? null,
                    });
                },
            },
            {
                type: FILTER_INPUT_TYPE.SELECT,
                label: 'Status',
                name: 'status',
                value: !!filteredValues?.status?.length
                    ? EVVEHICLE_STATUS_LIST?.filter(
                          (item) => item?.status == filteredValues?.status,
                      )?.[0]
                    : null,
                options: EVVEHICLE_STATUS_LIST?.map((item) => ({
                    label: item.label,
                    value: item.id,
                    ...item,
                })),
                onChange: (e) => {
                    setFilteredValue({
                        ...filteredValues,
                        status: e?.target?.value?.value
                            ? EVVEHICLE_STATUS_LIST?.filter(
                                  (item) =>
                                      item?.id == Number(e.target.value.id),
                              )?.[0]?.status
                            : '',
                    });
                },
            },
        ];
    };

    const onFilterApplied = () => {
        setPage(0);
        setEvListingPayload((prev) => {
            return {
                ...prev,
                pageNo: 1,
                ...filteredValues,
            };
        });
    };

    React.useEffect(() => {
        if (firstRenderDone)
            setEvListingPayload((prev) => {
                return {
                    ...prev,
                    pageNo: 1,
                    searchText: searchText,
                };
            });
    }, [searchText]);

    return (
        <Paper style={{ padding: '1.5rem' }} elevation={0}>
            <Spinner loading={loading}>
                <Box
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'flex-start',
                        marginBottom: 16,
                    }}
                >
                    <Box
                        style={{
                            width: '50%',
                            display: 'flex',
                            flexDirection: 'row',
                        }}
                    >
                        <Searchbar
                            placeHolderText="Search by Vehicle Number"
                            style={{
                                color: '#14112d',
                            }}
                            onChange={(value) => {
                                setSearchText(value);
                            }}
                            onBackendSearch={(searchText) => {
                                setPage(0);
                                setSearchText(searchText);
                            }}
                            onFilter={() => {
                                console.log('search bar filtering');
                            }}
                        />

                        <FilterModal
                            filterOptions={prepareFilterOptions()}
                            filteredValues={filteredValues}
                            onFilterApplied={onFilterApplied}
                        />
                    </Box>
                    <Box>
                        <Button
                            style={{
                                marginRight: '1rem',
                                fontSize: 14,
                            }}
                            variant="contained"
                            color="primary"
                            onClick={(e) => toggleModal(true)}
                            disabled={!permissions.create}
                            disableElevation
                        >
                            {'Add EV Vehicle'}
                        </Button>

                        <Button
                            variant="contained"
                            color="primary"
                            className="btn-pos"
                            onClick={() => {
                                setBulkEvVehicleUploadModalOpen(true);
                            }}
                            disabled={!permissions.create}
                            disableElevation
                        >
                            Upload EVs
                        </Button>
                    </Box>
                </Box>

                {!evList?.length && (
                    <Box
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <EmptyIcon />
                    </Box>
                )}

                {!!isOpen && (
                    <EVModal
                        open={true}
                        defaultValue={selectedRow}
                        onClose={() => {
                            handleClose();
                            setSelectedRow(undefined);
                            fetchEvVehiclesListData(evListingPayload);
                        }}
                    />
                )}
                {!!isDeleteModalOpen && (
                    <Modal
                        onClose={handleDeleteClose}
                        open={isDeleteModalOpen}
                        title={'Delete'}
                        actions={actions}
                        loading={deleteLoading}
                    >
                        <Box>
                            <Typography
                                style={{
                                    textAlign: 'center',
                                }}
                            >
                                Do you want to delete
                                <strong>{` ${selectedRow?.evNumber} `}</strong>?
                            </Typography>
                            <Typography
                                style={{
                                    textAlign: 'center',
                                    marginTop: '8px',
                                    marginBottom: '24px',
                                }}
                            >
                                You can’t undo this action.
                            </Typography>
                        </Box>
                    </Modal>
                )}

                {bulkEvVehicleUploadModalOpen && (
                    <UploadEvVehiclesModal
                        open={bulkEvVehicleUploadModalOpen}
                        onClose={() => {
                            fetchEvVehiclesListData(evListingPayload);

                            setBulkEvVehicleUploadModalOpen(false);
                        }}
                    />
                )}
                {evHistoryModalOpen && (
                    <EvHistoryModal
                        open={evHistoryModalOpen}
                        evInfo={selectedRow}
                        onClose={() => {
                            setEvHistoryModalOpen(false);
                            setSelectedRow(undefined);
                            fetchEvVehiclesListData(evListingPayload);
                        }}
                    />
                )}
                <EVVehicleListingContainer
                    onPressViewHistory={(row) => {
                        openViewHistoryModal(row);
                    }}
                    onPressEdit={(row) => {
                        openEditModal(row);
                    }}
                    onPressDelete={(row) => {
                        openDeleteModal(row);
                    }}
                    onPressMobilize={(row) => {
                        onPressMobilize(row);
                    }}
                />
                <TablePagination
                    rowsPerPageOptions={[10, 25, 50, 100, 150]}
                    component="div"
                    count={evListData?.totalCount ?? 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Spinner>
        </Paper>
    );
};

const mapStateToProps = (state) => ({
    evListData: state.settings?.evList ?? {
        lineItems: [],
        totalCount: 0,
    },
    vendorList: state.settings?.evVendorList ?? [],
});

const mapDispatchToProps = (dispatch) => ({
    dispatchGetEvVehiclesListData: (payload, onSuccess, onError) =>
        dispatch(getAllEVVehicles(payload, onSuccess, onError)),
    dispatchDeleteEV: (id, onSuccess, onError) =>
        dispatch(deleteEvVehicle(id, onSuccess, onError)),
    dispatchBlockUnblockEV: (payload, onSuccess, onError) =>
        dispatch(blockUnblockEV(payload, onSuccess, onError)),
    dispatchGetAllEVVendors: (payload, onSuccess, onError) =>
        dispatch(getAllEVVendors(payload, onSuccess, onError)),
    dispatchGetAllEVTeams: (payload, onSuccess, onError) =>
        dispatch(getAllEVTeams(payload, onSuccess, onError)),
    dispatchGetAllEVProjects: (payload, onSuccess, onError) =>
        dispatch(getAllEVProjects(payload, onSuccess, onError)),
    dispatchGetAllEVCaptains: (payload, onSuccess, onError) =>
        dispatch(getAllEVCaptains(payload, onSuccess, onError)),
});

export const EVVehicles = connect(
    mapStateToProps,
    mapDispatchToProps,
)(EVVehiclesComponent);
