import * as constants from '../constants';
// import AsyncStorage from '@react-native-async-storage/async-storage';

const defaultState = {
    token: null,
    config: {},
    branchName: '',
};

// const userInfo = localStorage.getItem("USER_INFO");

export default function userReducer(state = defaultState, action) {
    switch (action.type) {
        case constants.SET_USER_INFO:
            return {
                ...state,
                ...action.payload,
            };
        case constants.SET_CONFIG:
            return {
                ...state,
                config: {
                    // permission: {
                    //     staff_screen: ['read', 'update', 'create', 'delete'],
                    //     attendence_screen: [
                    //         'read',
                    //         'create',
                    //         'update',
                    //         'delete',
                    //     ],
                    //     map_view_screen: [],
                    //     report_screen: ['read', 'update'],
                    //     settings_screen: ['read', 'update'],
                    // },
                    ...action.payload,
                },
            };
        case constants.SET_USER_BANK_DETAILS:
            return {
                ...state,
                branchName: action.payload,
            };
        case constants.SET_FILTER_MODAL_CONFIG:
            return {
                ...state,
                zappay: {
                    ...state?.zappay,
                    filterGroupModalConfig: action.payload,
                },
            };
        case constants.SET_BULK_RELEASE:
            return {
                ...state,
                zappay: {
                    ...state?.zappay,
                    bulkReleaseFlag: action.payload,
                },
            };
        case constants.SET_SELECTED_PAYMENTS:
            return {
                ...state,
                zappay: {
                    ...state?.zappay,
                    selectedPayments: action.payload,
                },
            };
        case constants.SET_IGNORE_DATE_FILTER_FLAG:
            return {
                ...state,
                zappay: {
                    ...state?.zappay,
                    ignoreDateFilterFlag: action.payload,
                },
            };

        //

        case constants.SET_SOURCE_SYSTEMS_LIST_DATA:
            return {
                ...state,
                zappay: {
                    ...state?.zappay,
                    sourceSystemsListData: action.payload,
                },
            };
        case constants.SET_SOURCE_SYSTEMS_FILTER_LIST_DATA:
            return {
                ...state,
                zappay: {
                    ...state?.zappay,
                    sourceSystemFilterListData: action.payload,
                },
            };
        case constants.SET_ALL_PAYMENT_INDENTS_LIST_DATA:
            return {
                ...state,
                zappay: {
                    ...state?.zappay,
                    listingData: action.payload,
                },
            };
        case constants.SET_ALL_BENEFICIARIES_LIST_DATA:
            return {
                ...state,
                zappay: {
                    ...state?.zappay,
                    beneficiariesListingData: action.payload,
                },
            };
        default:
            return state;
    }
}
