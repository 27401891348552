import {
    Icon,
    makeStyles,
    Menu,
    MenuItem,
    withStyles,
} from '@material-ui/core';
import React, { Component } from 'react';
import {
    BonusIcon,
    DailyMGIcon,
    LGSurgeIcon,
    OrderPayIcon,
    WeekBonusIcon,
    WeekIcon,
} from '../../assets/svg';
import './styles.css';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { Spinner } from '../UI';
import { CustomTextField, CompactCustomTextField } from '../Textfield';

const Icon_Set = Object.freeze({
    order_charges: <OrderPayIcon />,
    daily_mg: <DailyMGIcon />,
    weekly_mg: <WeekIcon />,
    daily_bonus: <BonusIcon />,
    Weekly_bonus: <WeekBonusIcon />,
    distance_surge: <LGSurgeIcon />,
});

const StyledMenuItem = withStyles((theme) => ({
    root: {
        margin: '0 8px',
        border: '0 !important',
        borderRadius: '8px',
        '&:hover': {
            backgroundColor: 'rgba(67, 65, 158, 0.08)',
            color: '#43419e',
            borderRadius: '8px',
        },
    },
}))(MenuItem);

const useStyles = makeStyles(() => ({
    root: {
        '& .MuiSelect-root': {
            backgroundColor: '#eeeef9',
        },
    },
}));

const rateCardData = {
    name: 'hl-Delhi-RateCard',
    version: '1.0',
    created_dt: '2021-10-05',
    updated_dt: '2021-10-05',
    updated_user_id: 'LSN2323',
    updated_user_name: 'Ashwin',
    rate_card_components: {
        base_cards: [
            {
                name: 'order_charges',
                main_text: 'Order Pay',
                sub_text: 'Earn Minimum Rs25 Per Order',
                conditions: [],
                list_view: [
                    {
                        name: 'base_order_pay',
                        main_text: 'Base Pay',
                        sub_text: 'Minimum Amount Paid Per Order',
                        amount: 'Rs40',
                    },
                    {
                        name: 'distance_surge',
                        main_text: 'LD Surge',
                        sub_text: 'Extra Earnings after 6KM',
                        amount: '+Rs5/KM',
                    },
                    {
                        name: 'Rain_surge',
                        main_text: 'Rain Surge',
                        sub_text: 'Extra Earnings if its a Rainy Day',
                        amount: '+Rs15',
                    },
                ],
            },
            {
                name: 'distance_surge',
                main_text: 'Daily Minimum Gurantee',
                sub_text: 'Earn Minimum of Rs200 Daily ',
                conditions: [
                    {
                        text: 'Make Sure you are Logged IN between 7:00pm - 11:59pm for atleast 2:20 Hours  ',
                    },
                ],
            },
            {
                name: 'daily_bonus',
                main_text: 'Daily Bonus',
                sub_text:
                    'Earn upto Rs500 Additionaly based on your Daily Performance',
                combo_list: [
                    {
                        name: 'Daily Incentive',
                        main_text: 'Daily Incentive ',
                        sub_text: '',
                        amount: 'Upto Rs300 ',
                        component_type: 'horizontal_stepper',
                        data: [
                            {
                                upper_text: 'Orders Completed',
                                lower_text: 'Target Pay',
                            },
                            {
                                upper_text: '0',
                                lower_text: 'Rs60',
                            },
                            {
                                upper_text: '8',
                                lower_text: 'Rs80',
                            },
                            {
                                upper_text: '10',
                                lower_text: 'Rs100',
                            },
                            {
                                upper_text: '12',
                                lower_text: 'Rs130',
                            },
                            {
                                upper_text: '15',
                                lower_text: 'Rs165',
                            },
                            {
                                upper_text: '18',
                                lower_text: 'Rs200',
                            },
                            {
                                upper_text: '21',
                                lower_text: 'Rs300',
                            },
                        ],
                    },
                    {
                        name: 'Dinner Surge',
                        main_text: 'Dinner Surge',
                        component_type: 'horizontal_stepper',
                        amount: 'upto +Rs7',
                        data: [
                            {
                                upper_text: 'Orders Completed',
                                lower_text: 'Extra Per Order',
                            },
                            {
                                upper_text: '0',
                                lower_text: 'Rs0',
                            },
                            {
                                upper_text: '6',
                                lower_text: '+Rs5',
                            },
                            {
                                upper_text: '9',
                                lower_text: '+Rs6',
                            },
                            {
                                upper_text: '12',
                                lower_text: '+Rs7',
                            },
                        ],
                        conditions: [
                            {
                                text: 'Applicable to Only Orders Delivered between 7:00pm - 11:59pm ',
                            },
                        ],
                    },
                ],
            },
            {
                name: 'Weekly_bonus',
                main_text: 'Weekly Bonus',
                sub_text:
                    'Earn upto Rs1000 Additionaly based on your Weekly Performance',
                combo_list: [
                    {
                        name: 'Weekly Incentive',
                        main_text: 'Weekly Incentive ',
                        sub_text: '',
                        amount: 'Upto Rs1000 ',
                        component_type: 'horizontal_stepper',
                        data: [
                            {
                                upper_text: 'Orders Completed',
                                lower_text: 'Target Pay',
                            },
                            {
                                upper_text: '30',
                                lower_text: 'Rs100',
                            },
                            {
                                upper_text: '40',
                                lower_text: 'Rs350',
                            },
                            {
                                upper_text: '65',
                                lower_text: 'Rs500',
                            },
                            {
                                upper_text: '80',
                                lower_text: 'Rs1000',
                            },
                        ],
                    },
                    {
                        name: 'Login Bonus',
                        main_text: 'Login Bonus',
                        sub_text: '',
                        amount: 'Rs 200 ',
                        component_type: 'list_item',
                        conditions: [
                            {
                                text: 'You should have completed Minimum 5 Shifts ',
                            },
                        ],
                    },
                ],
            },
        ],
    },
};

class RateCard extends Component {
    constructor(props) {
        super(props);
        console.log('ppppppp', props);
        this.state = {
            loading: false,
            openMenu: false,
            selectedTab: 0,
            rateCardData: props?.data?.rateCard,
        };
    }

    componentDidMount() {}

    render() {
        const { selectedTab, loading, rateCardData } = this.state;
        if (loading) {
            return (
                <div className="rate-card-container">
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            textAlign: 'center',
                            height: '100vh',
                            padding: '30px',
                        }}
                    >
                        <Spinner loading={true}></Spinner>
                    </div>
                </div>
            );
        }
        return (
            <div className="rate-card-container">
                {rateCardData && !!Object.keys(rateCardData)?.length ? (
                    rateCardData.rate_card_components.base_cards.map(
                        (item, index) => (
                            <div
                                key={index}
                                className="card"
                                onClick={(e) =>
                                    this.setState({ selectedTab: index })
                                }
                            >
                                <div className="card-flex">
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                        }}
                                    >
                                        {item?.name && Icon_Set[item.name]}
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'flex-start',
                                                alignItems: 'flex-start',
                                                marginLeft: 10,
                                            }}
                                        >
                                            <div className="title">
                                                {TextRender(item.main_text)}
                                            </div>
                                            {item?.sub_text && (
                                                <div className="description">
                                                    {TextRender(item.sub_text)}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    {index === selectedTab ? (
                                        <KeyboardArrowDownIcon />
                                    ) : (
                                        <ArrowForwardIosIcon />
                                    )}
                                </div>
                                {index === selectedTab && (
                                    <>
                                        {!!item?.list_view?.length && (
                                            <ListView data={item?.list_view} />
                                        )}
                                        {!!item?.combo_list?.length && (
                                            <ComboListView
                                                data={item?.combo_list}
                                            />
                                        )}
                                        {!!item?.conditions?.length && (
                                            <ConditionBlock
                                                data={item?.conditions}
                                            />
                                        )}
                                    </>
                                )}
                            </div>
                        ),
                    )
                ) : (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            textAlign: 'center',
                            height: '100vh',
                            padding: '30px',
                        }}
                    >
                        {/* <img
                            src={WarningIcon}
                            alt="WarningIcon"
                            style={{ width: '35%', marginBottom: 20 }}
                        /> */}
                        <h4>
                            Please Contact Support for any Queries on your Rate
                            Card
                        </h4>
                    </div>
                )}
            </div>
        );
    }
}

function ConditionBlock({ data, ...props }) {
    return (
        <div className="card card-conditions">
            <p>Conditions</p>
            {data.map((item, index) => (
                <p key={index}>{TextRender(item?.text)}</p>
            ))}
        </div>
    );
}

function ListView({ data, ...props }) {
    return data.map((item, index) => (
        <div key={index} className="card list-view-card">
            <div className="card-flex">
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                    }}
                >
                    <div className="title">{TextRender(item.main_text)}</div>
                    {!!item.sub_text && (
                        <div className="description">
                            {TextRender(item.sub_text)}
                        </div>
                    )}
                </div>
                <div className="title">{TextRender(item.amount)}</div>
            </div>
        </div>
    ));
}
function ComboListView({ data, ...props }) {
    const classes = useStyles();
    const [selectedCard, setSelectedCard] = React.useState(0);
    const [selectedItem, setSelectedItem] = React.useState(data[0]);

    return (
        <>
            <CompactCustomTextField
                select
                fullWidth={true}
                value={selectedCard}
                onChange={(e) => {
                    setSelectedCard(Number(e.target.value));
                    setSelectedItem(data[Number(e.target.value)]);
                }}
                defaultValue=""
                style={{
                    marginTop: '12px',
                    backgroundColor: '#eeeef9',
                }}
                className={classes.root}
            >
                {data?.length &&
                    data.map((type, index) => (
                        <StyledMenuItem key={index} value={index}>
                            {type.main_text}
                        </StyledMenuItem>
                    ))}
            </CompactCustomTextField>

            {data.map(
                (item, index) =>
                    selectedCard === index &&
                    componentRender(item?.component_type, item),
            )}

            {!!selectedItem?.conditions?.length && (
                <ConditionBlock data={selectedItem?.conditions} />
            )}
        </>
    );
}

function componentRender(type, item) {
    switch (type) {
        case 'list_item':
            return <ListView data={[item]} />;
        case 'horizontal_stepper':
            return (
                <Timeline
                    title={
                        item?.main_text
                            ? {
                                  main_text: item?.main_text,
                                  amount: item?.amount,
                              }
                            : false
                    }
                    data={item?.data}
                />
            );
        default:
            return false;
    }
}

function TextRender(text) {
    return text?.replace('Rs', '₹');
}

function Timeline({ title, data, ...props }) {
    return (
        <>
            {title && <ListView data={[title]} />}
            {!!data?.length && (
                <ul className="timeline" id="timeline">
                    {data.map((item, index) => {
                        const context = (
                            <li
                                key={index}
                                className={`li ${
                                    index !== 0 ? 'complete' : ''
                                }`}
                            >
                                <div className="timestamp">
                                    <h4>{TextRender(item.upper_text)}</h4>
                                </div>
                                <div className="status">
                                    <h4> {TextRender(item.lower_text)} </h4>
                                </div>
                            </li>
                        );
                        return context;
                    })}
                </ul>
            )}
        </>
    );
}

export default RateCard;
