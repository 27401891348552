import React, { useState } from 'react';
import './styles.scss';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { List, ListItem, Box, Typography, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CustomDropdown from '../../Dropdown';
import AddEditStaffModal from '../../Modals/AddEditStaffModal';
import DeleteStaffModal from '../../Modals/DeleteStaffModal';

import {
    SelfieAttendanceIcon,
    GeofenceAttendanceIcon,
    GeofenceAutoApprovalIcon,
    TrackingEnabledIcon,
    AadhaarIcon,
    AadhaarSuccessIcon,
    AadhaarFailureIcon,
    EmptyIcon,
    HyperlocalDEIcon,
} from '../../../assets/svg';
import {
    deleteStaff,
    setSingleStaffInfo,
    getSingleStaff,
    sendAppLink,
    updateStatus,
} from '../../../store/actions/staffActionCreator';
import { enqueueSnackbar } from '../../../store/actions/notificationActionCreator';
import AddPaymentModal from '../../Modals/AddPaymentModal';
import UpdateTrainingStatusModal from '../../Modals/UpdateTrainingStatusModal';
import { formatCurrency, getPermissions } from '../../../utils/helper';
import AddClientModal from '../../Modals/AddClientModal';
import AddVendorModal from '../../Modals/AddVendorModal';
import { Spinner } from '../../../components/UI';
import ShiftColumnItemPopper from '../ShiftColumnItemPopper';
import ThumbnailPreview from '../../ThumbnailPreview';
import { DocumentsPreview, HUB_TYPE_ENUM } from '../../../utils/constants';
import {
    CLASS_NAME_MAPPING,
    PENDING_STATUS,
    SUCCESS_STATUS,
    PROGRESS_STATUS,
    FAILED_STATUS,
    EV_CLASS_STATUS,
} from './helper';
import { fetchClientList } from '../../../store/actions/staffActionCreator';
const useStyles = makeStyles((theme) => ({
    listItem: {
        padding: '8px 16px 8px 16px !important',
        marginBottom: '8px !important',
        border: '1px solid #ebedf8 !important',
        borderRadius: '8px !important',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        '&:hover': {
            backgroundColor: 'rgba(67,65,158,.1) !important',
            cursor: 'pointer',
        },
        height: '70px !important',
    },
    listItemHeader: {
        border: '0px !important',
        // marginBottom: '2px !important',
        color: '#808080 !important',
        fontWeight: 'bold !important',
        fontSize: 16,
    },
    popperContainer: {
        border: '1px solid',
        padding: theme.spacing(1),
        backgroundColor: theme.palette.background.paper,
    },
}));

const AllStaffListContainer = ({
    isEnabledXUtil,
    renderHubFlow,
    data,
    getAllStaffInfo,
    deleteStaffInfo,
    permissions,
    staffListingPayload,
    // dispatchSetSingleStaffInfo,
    dispatchGetSingleStaff,
    dispatchSendAppLink,
    dispatchEnqueueSnackbar,
    dispatchUpdateStatus,
    dispatchFetchClientList,
    ...props
}) => {
    const classes = useStyles();

    const [editStaffModalOpen, setEditStaffModalOpen] = React.useState(false);
    const [editClientModalOpen, setEditClientModalOpen] = React.useState(false);
    const [editVendorModalOpen, setEditVendorModalOpen] = React.useState(false);
    const [paymentStaffModalOpen, setPaymentStaffModalOpen] =
        React.useState(false);
    const [deleteStaffModalOpen, setDeleteStaffModalOpen] =
        React.useState(false);
    const [updateTrainingStatusModalOpen, setUpdateTrainingStatusModalOpen] =
        React.useState(false);
    const [selectedItemData, setSelectedItemData] = React.useState({});
    const [loading, toggleloading] = useState(false);
    const [clientList, setClientList] = useState([]);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const openPopper = Boolean(anchorEl);
    const popperId = openPopper ? 'simple-popper' : undefined;
    const getClientName = (customerId) => {
        let clientDetails = clientList.find(
            (ele) => ele?.customerId == customerId,
        );
        return !!clientDetails ? `${clientDetails?.name}` : '';
    };
    const onRowClicked = (e, val) => {
        console.log(`dateilas`, val);

        //   dispatchSetSingleStaffInfo(val);
        dispatchGetSingleStaff(
            val.id,
            (response) => {
                console.log('Get single staff success');
                props.history.push(`/staff/details/${val.id}`, {
                    searchVal: staffListingPayload?.searchText,
                    page: staffListingPayload?.pageNo - 1,
                    rowsPerPage: staffListingPayload?.pageSize,
                });
            },
            () => {},
        );
    };

    const getShiftTimingsOptions = (val) => {
        let optionsArr = [];
        val?.shiftTimings?.map((shiftTiming, index) =>
            optionsArr.push({
                style:
                    index == shiftTiming?.length - 1
                        ? {
                              backgroundColor: 'rgba(67, 65, 158, 0.07)',
                              padding: '0px',
                              borderRadius: '8px',
                          }
                        : {
                              backgroundColor: 'rgba(67, 65, 158, 0.07)',
                              padding: '0 8px',
                              borderRadius: '8px',
                              marginBottom: 8,
                          },
                render: () => {
                    return (
                        <div
                            style={{
                                width: '100%',
                            }}
                        >
                            <>
                                <div
                                    style={{
                                        fontFamily: 'Nunito',
                                        fontSize: '12px',
                                        fontWeight: '600',
                                        color: '#252525',
                                    }}
                                >
                                    {shiftTiming?.name ?? ''}
                                </div>
                                <div
                                    style={{
                                        fontFamily: 'Nunito',
                                        fontSize: '10px',
                                        fontWeight: 'normal',
                                        color: '#252525',
                                    }}
                                >
                                    {`${shiftTiming?.startTime} - ${shiftTiming?.endTime}`}
                                </div>
                            </>
                        </div>
                    );
                },
                onSuccess: () => {},
                disabled: !permissions?.delete,
            }),
        );
        return optionsArr;
    };

    const actionDropDown = (val) => {
        let options = [];
        const EDIT = {
            item: 'Edit',
            onSuccess: () => {
                setEditStaffModalOpen(true);
                setSelectedItemData(val);
                // `/staff/${phoneNumber}/edit`,
            },
            disabled: !permissions?.update,
        };
        const CLIENT_DETAIL = {
            item:
                val?.customers?.length > 0
                    ? 'Edit Client Details'
                    : 'Add Client Details',
            onSuccess: () => {
                setEditClientModalOpen(true);
                setSelectedItemData(val);
                // `/staff/${phoneNumber}/edit`,
            },
            disabled:
                val?.customers?.length > 0
                    ? !permissions?.update
                    : !permissions?.create,
        };
        const VENDER_DETAIL = {
            item:
                val?.vendorName && val?.vendorId > 0
                    ? 'Edit Vendor Details'
                    : 'Add Vendor Details',
            onSuccess: () => {
                setEditVendorModalOpen(true);
                setSelectedItemData(val);
            },
            disabled:
                val?.vendorName && val?.vendorId > 0
                    ? !permissions?.update
                    : !permissions?.create,
        };
        const ADD_PAYMENTS = {
            item: 'Add Payments',
            onSuccess: async () => {
                await setSelectedItemData(val);
                setPaymentStaffModalOpen(true);
                // `/staff/${phoneNumber}/edit`,
            },
            disabled: !permissions?.create,
        };
        const VIEW_MAP = {
            item: 'View on Map',
            onSuccess: () => {
                props.history.push(`/map-view/detail/${val.id}`);
            },
            disabled: !getPermissions('map_view')?.read,
        };
        const SEND_APP_LINK = {
            item: 'Send App Link',
            onSuccess: () => {
                console.log('Send App Link Clicked!');

                dispatchSendAppLink(
                    val.id,
                    (response) => {
                        console.log('response success', response);

                        if (response.status.code == 200) {
                            dispatchEnqueueSnackbar({
                                message: response.status.message,
                                options: {
                                    key: new Date().getTime() + Math.random(),
                                    variant: 'success',
                                },
                            });
                        }
                    },
                    (response) => {
                        console.log('Send App Link Failed', response);
                    },
                );
            },
        };
        const ACTIVATE = {
            item: 'Activate',
            onSuccess: () => {
                toggleloading(true);
                dispatchUpdateStatus(
                    {
                        staffId: val.id,
                        status: 'APPROVED',
                    },
                    (success) => {
                        console.log(`Update Status success`);
                        toggleloading(false);
                        getAllStaffInfo && getAllStaffInfo({});
                    },
                    (failed) => {
                        console.log(`Update Status failed`);
                        toggleloading(false);
                        getAllStaffInfo && getAllStaffInfo({});
                    },
                );
            },
        };
        const UPDATE_TRAINING_STATUS = {
            item: 'Update Training Status',
            onSuccess: async () => {
                await setSelectedItemData(val);
                setUpdateTrainingStatusModalOpen(true);
            },
        };
        const DE_ACTIVATE = {
            item: 'DeActivate',
            onSuccess: () => {
                toggleloading(true);
                console.log(val);
                dispatchUpdateStatus(
                    {
                        staffId: val.id,
                        status: 'DEACTIVATED',
                    },
                    (success) => {
                        console.log(`Update Status success`);
                        toggleloading(false);
                        getAllStaffInfo && getAllStaffInfo({});
                    },
                    (failed) => {
                        console.log(`Update Status failed`);
                        toggleloading(false);
                        getAllStaffInfo && getAllStaffInfo({});
                    },
                );
            },
        };
        const DELETE = {
            item: 'Delete',
            onSuccess: () => {
                setDeleteStaffModalOpen(true);
                setSelectedItemData(val);

                // `/staff/${phoneNumber}/edit`,
            },
            disabled: !permissions?.delete,
        };
        if (val.status !== 'INACTIVE') {
            options = [
                EDIT,
                CLIENT_DETAIL,
                VENDER_DETAIL,
                ADD_PAYMENTS,
                VIEW_MAP,
                SEND_APP_LINK,
                UPDATE_TRAINING_STATUS,
                DE_ACTIVATE,
                DELETE,
            ];
            if (!!isEnabledXUtil) {
                options = [
                    EDIT,
                    ADD_PAYMENTS,
                    VIEW_MAP,
                    SEND_APP_LINK,
                    UPDATE_TRAINING_STATUS,
                    DE_ACTIVATE,
                    DELETE,
                ];
            }
        } else {
            options = [ACTIVATE];
        }
        return (
            <CustomDropdown
                title={!!renderHubFlow ? '' : 'Options'}
                options={options}
            />
        );
    };
    React.useEffect(() => {
        dispatchFetchClientList(
            async (resp) => {
                setClientList(resp?.response?.customers);
            },
            (err) => {},
        );
    }, []);
    return !!isEnabledXUtil ? (
        <Box className="staff-list">
            <Spinner loading={loading}>
                <List component="nav">
                    <ListItem className={classes.listItemHeader}>
                        <Box
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                width: '100%',
                            }}
                        >
                            <Box
                                style={{
                                    width: '22%',
                                }}
                            >
                                Name
                            </Box>
                            <Box
                                style={{
                                    width: '6%',
                                }}
                            ></Box>
                            <Box style={{ width: '11%' }}>Salary Info</Box>
                            <Box
                                style={{
                                    width: '13%',
                                }}
                            >
                                Status
                            </Box>
                            <Box
                                style={{
                                    width: '23.5%',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'baseline',
                                }}
                            >
                                Shift{' '}
                                <Typography
                                    className={`modal-subtitle`}
                                    variant="inherit"
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        fontSize: '10px',
                                        marginLeft: 8,
                                    }}
                                    component="span"
                                >
                                    {Object.values(HUB_TYPE_ENUM).map(
                                        (hub, idx) => {
                                            const showDot =
                                                idx <
                                                Object.values(HUB_TYPE_ENUM)
                                                    ?.length -
                                                    2;
                                            return (
                                                !!hub.value && (
                                                    <>
                                                        <span
                                                            style={{
                                                                color: hub?.color,
                                                            }}
                                                        >
                                                            {hub?.value ===
                                                            HUB_TYPE_ENUM.FOOD
                                                                .value
                                                                ? 'HYPERLOCAL'
                                                                : hub?.value}
                                                        </span>
                                                        {showDot && (
                                                            <Box
                                                                style={{
                                                                    padding: 7,
                                                                    paddingTop: 4.5,
                                                                }}
                                                            >
                                                                <Box
                                                                    style={{
                                                                        width: 4,
                                                                        height: 4,
                                                                        backgroundColor:
                                                                            'rgba(0, 0, 0, 0.16)',
                                                                        borderRadius: 50,
                                                                    }}
                                                                />
                                                            </Box>
                                                        )}{' '}
                                                    </>
                                                )
                                            );
                                        },
                                    )}
                                </Typography>
                            </Box>
                            <Box
                                style={{
                                    width: '15%',
                                }}
                            >
                                EV Status
                            </Box>
                            <Box style={{ width: '16%' }}>Documents</Box>

                            <Box style={{ width: '10%' }}></Box>
                        </Box>
                    </ListItem>
                    <>
                        {data?.length ? (
                            data?.map((val, index) => {
                                // console.log(key); // the name of the current key.
                                // console.log(val); // the value of the current key.
                                const EV_STATUS = PENDING_STATUS.includes(
                                    val?.evStatus,
                                )
                                    ? EV_CLASS_STATUS.PENDING_STATUS
                                    : PROGRESS_STATUS.includes(val?.evStatus)
                                    ? EV_CLASS_STATUS.PROGRESS_STATUS
                                    : SUCCESS_STATUS.includes(val?.evStatus)
                                    ? EV_CLASS_STATUS.SUCCESS_STATUS
                                    : FAILED_STATUS.includes(val?.evStatus)
                                    ? EV_CLASS_STATUS.FAILED_STATUS
                                    : EV_CLASS_STATUS.EMPTY;
                                const EV_STATUS_CLASS =
                                    CLASS_NAME_MAPPING[EV_STATUS];
                                return (
                                    <div
                                        className={`${classes.listItem} staff-list`}
                                        onClick={(e) => onRowClicked(e, val)}
                                        // href={`/staff/details/${phoneNumber}`}
                                        // href="#simple"
                                        key={val.id}
                                        style={
                                            index % 2
                                                ? {
                                                      backgroundColor:
                                                          'rgba(241, 243, 246, 1)',
                                                  }
                                                : {}
                                        }
                                    >
                                        <Box
                                            style={{
                                                width: '18%',
                                            }}
                                            // className={classes.tableCell}
                                        >
                                            <Box
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    flexWrap: 'wrap',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <Typography
                                                    style={{
                                                        wordBreak: 'break-all',
                                                        fontWeight: 600,
                                                        marginRight: 5,
                                                    }}
                                                >
                                                    {val.name ?? '-'}
                                                </Typography>
                                                {!!val?.uidaiKycRequired &&
                                                    !!val?.uidaiKycVerified && (
                                                        <Tooltip
                                                            title="UIDAI Verified"
                                                            placement="top"
                                                        >
                                                            <AadhaarSuccessIcon
                                                                style={{
                                                                    width: '20px',
                                                                    marginRight: 5,
                                                                }}
                                                            />
                                                        </Tooltip>
                                                    )}
                                                {!!val?.hyperlocalDE && (
                                                    <Tooltip
                                                        title="Enabled Hyperlocal Orders"
                                                        placement="top"
                                                    >
                                                        <HyperlocalDEIcon
                                                            style={{
                                                                height: '20px',
                                                            }}
                                                        />
                                                    </Tooltip>
                                                )}
                                            </Box>
                                            <Box
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    flexWrap: 'wrap',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <Typography
                                                    style={{
                                                        // width: '40%',
                                                        fontSize: '12px',
                                                        marginRight: '4px',
                                                    }}
                                                >
                                                    {val?.staffTypeName ?? '-'}
                                                </Typography>
                                                <Box style={{ padding: 7 }}>
                                                    <Box
                                                        style={{
                                                            width: 4,
                                                            height: 4,
                                                            backgroundColor:
                                                                'rgba(0, 0, 0, 0.16)',
                                                            borderRadius: 50,
                                                        }}
                                                    />
                                                </Box>{' '}
                                                <Typography
                                                    style={{
                                                        fontSize: '12px',
                                                        marginLeft: '4px',
                                                    }}
                                                >
                                                    {val.contactNumber ?? '-'}
                                                </Typography>
                                            </Box>
                                        </Box>
                                        <Box
                                            style={{
                                                width: '9.5%',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'flex-start',
                                                flexDirection: 'row',
                                            }}
                                            // className={classes.tableCell}
                                        >
                                            {val.selfieEnabled && (
                                                <Box
                                                    style={{
                                                        marginRight: '10px',
                                                    }}
                                                >
                                                    <Tooltip
                                                        title="Enabled Selfie"
                                                        placement="top"
                                                    >
                                                        <SelfieAttendanceIcon
                                                            width={20}
                                                            height={20}
                                                        />
                                                    </Tooltip>
                                                </Box>
                                            )}
                                            {/* {val.geofenceEnabled && (
                                          <Box
                                              style={{
                                                  marginRight: '10px',
                                              }}
                                          >
                                              <GeofenceAttendanceIcon
                                                  width={16}
                                                  height={16}
                                              />
                                          </Box>
                                      )} */}
                                            {val.geofenceAutoApproval && (
                                                <Box
                                                    style={{
                                                        marginRight: '10px',
                                                    }}
                                                >
                                                    <Tooltip
                                                        title="Enabled Geofence Auto Approval"
                                                        placement="top"
                                                    >
                                                        <GeofenceAutoApprovalIcon
                                                            width={16}
                                                            height={16}
                                                        />
                                                    </Tooltip>
                                                </Box>
                                            )}
                                            {val.trackAndTraceEnabled && (
                                                <Box
                                                    style={{
                                                        marginRight: '10px',
                                                    }}
                                                >
                                                    <Tooltip
                                                        title="Enabled Track and Trace"
                                                        placement="top"
                                                    >
                                                        <TrackingEnabledIcon
                                                            width={16}
                                                            height={16}
                                                        />
                                                    </Tooltip>
                                                </Box>
                                            )}
                                        </Box>
                                        <Box
                                            style={{
                                                width: '11%',
                                                alignItems: 'center',
                                            }}
                                            // className={classes.tableCell}
                                        >
                                            <Typography
                                                style={{
                                                    wordBreak: 'break-all',
                                                    fontWeight: 600,
                                                }}
                                            >
                                                {val?.salaryTypeName ??
                                                    'Monthly'}
                                            </Typography>
                                            <Box
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    flexWrap: 'wrap',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <Typography
                                                    style={{
                                                        fontSize: '12px',
                                                    }}
                                                >
                                                    {val?.vendorName ?? ' '}
                                                </Typography>
                                            </Box>
                                        </Box>
                                        <Box style={{ width: '12%' }}>
                                            <Typography
                                                style={{
                                                    minWidth: '100px',
                                                }}
                                                className={
                                                    val.status === 'KYC PENDING'
                                                        ? 'kyc-pen-chip'
                                                        : val.status ===
                                                          'KYC REJECTED'
                                                        ? 'kyc-rej-chip '
                                                        : val.status ===
                                                          'REJECTED'
                                                        ? 'kyc-rej-chip '
                                                        : val.status ===
                                                          'INACTIVE'
                                                        ? 'inactive-chip'
                                                        : val.status ===
                                                          'PENDING'
                                                        ? 'kyc-pen-chip'
                                                        : val.status ===
                                                          'Active'
                                                        ? 'active-chip'
                                                        : val.status ===
                                                          'VERIFIED'
                                                        ? 'active-chip'
                                                        : null
                                                }
                                            >
                                                {val.status ?? '-'}
                                            </Typography>
                                        </Box>
                                        <Box
                                            style={{
                                                width: '23%',
                                                alignItems: 'center',
                                            }}
                                            // className={classes.tableCell}
                                        >
                                            <ShiftColumnItemPopper
                                                limit={4}
                                                data={val}
                                                selectedItem={selectedItemData}
                                                setSelectedItem={
                                                    setSelectedItemData
                                                }
                                            />
                                        </Box>
                                        <Box style={{ width: '13%' }}>
                                            <Typography
                                                align="justify"
                                                style={{
                                                    minWidth: '100px',
                                                }}
                                                className={
                                                    EV_STATUS_CLASS ?? null
                                                }
                                                //component={'span'}
                                            >
                                                {val?.evStatus ?? '--'}
                                            </Typography>
                                        </Box>
                                        <Box
                                            style={{
                                                width: '18%',
                                                alignItems: 'center',
                                            }}
                                            // className={classes.tableCell}
                                        >
                                            <Box
                                                style={{
                                                    width: '90%',
                                                    marginLeft:
                                                        val?.documents
                                                            ?.length <= 4
                                                            ? 10
                                                            : 0,
                                                }}
                                            >
                                                <ThumbnailPreview
                                                    images={(function () {
                                                        var hello = [];
                                                        if (
                                                            val?.documents
                                                                ?.length > 0
                                                        ) {
                                                            if (
                                                                val.documents[0].hasOwnProperty(
                                                                    'documentType',
                                                                )
                                                            ) {
                                                                val.documents.forEach(
                                                                    (
                                                                        x,
                                                                        index,
                                                                    ) => {
                                                                        var tempDoc =
                                                                            DocumentsPreview.find(
                                                                                (
                                                                                    y,
                                                                                ) =>
                                                                                    y.type ===
                                                                                    x.documentType,
                                                                            );
                                                                        if (
                                                                            !tempDoc
                                                                        )
                                                                            val.documents.splice(
                                                                                index,
                                                                                1,
                                                                            );
                                                                        x.preview =
                                                                            tempDoc
                                                                                ? tempDoc.value
                                                                                : x.documentValue;
                                                                        x.link =
                                                                            x.documentValue;
                                                                    },
                                                                );
                                                            }
                                                            return val?.documents;
                                                        } else {
                                                            return [];
                                                        }
                                                    })()}
                                                />
                                            </Box>
                                        </Box>

                                        <Box
                                            style={{
                                                width: '8%',
                                                display: 'flex',
                                                justifyContent: 'flex-end',
                                            }}
                                        >
                                            {actionDropDown(val)}
                                        </Box>
                                    </div>
                                );
                            })
                        ) : (
                            <Box
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <EmptyIcon />
                            </Box>
                        )}
                    </>
                    {editStaffModalOpen && (
                        <AddEditStaffModal
                            open={editStaffModalOpen}
                            isEdit={true}
                            data={selectedItemData}
                            onClose={() => {
                                console.log('Closed');
                                getAllStaffInfo && getAllStaffInfo({});
                                setEditStaffModalOpen(false);
                            }}
                        />
                    )}
                    {editClientModalOpen && (
                        <AddClientModal
                            open={editClientModalOpen}
                            isEdit={!!selectedItemData?.customers?.length}
                            data={selectedItemData}
                            onClose={() => {
                                console.log('Closed');
                                getAllStaffInfo && getAllStaffInfo({});
                                setEditClientModalOpen(false);
                            }}
                        />
                    )}
                    {editVendorModalOpen && (
                        <AddVendorModal
                            open={editVendorModalOpen}
                            isEdit={
                                // selectedItemData?.staffCustCode &&
                                selectedItemData?.customerName
                            }
                            data={selectedItemData}
                            onClose={() => {
                                console.log('Closed');
                                getAllStaffInfo && getAllStaffInfo({});
                                setEditVendorModalOpen(false);
                            }}
                        />
                    )}

                    {deleteStaffModalOpen && (
                        <DeleteStaffModal
                            open={deleteStaffModalOpen}
                            deleteStaff={deleteStaffInfo}
                            data={selectedItemData}
                            onClose={() => {
                                console.log('Closed');
                                getAllStaffInfo && getAllStaffInfo({});
                                setDeleteStaffModalOpen(false);
                            }}
                        />
                    )}
                    {paymentStaffModalOpen && (
                        <AddPaymentModal
                            open={true}
                            staffInfo={selectedItemData}
                            onClose={() => {
                                getAllStaffInfo && getAllStaffInfo({});
                                setPaymentStaffModalOpen(false);
                            }}
                        />
                    )}
                    {updateTrainingStatusModalOpen && (
                        <UpdateTrainingStatusModal
                            open
                            staffInfo={selectedItemData}
                            onCloseWithoutRefresh={() => {
                                setUpdateTrainingStatusModalOpen(false);
                            }}
                            onClose={() => {
                                getAllStaffInfo && getAllStaffInfo({});
                                setUpdateTrainingStatusModalOpen(false);
                            }}
                        />
                    )}
                </List>
            </Spinner>
        </Box>
    ) : (
        <Box className="staff-list">
            <Spinner loading={loading}>
                <List component="nav">
                    <ListItem className={classes.listItemHeader}>
                        <Box
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                width: '100%',
                            }}
                        >
                            <Box
                                style={{
                                    width: '15%',
                                }}
                            >
                                NAME
                            </Box>
                            <Box
                                style={{
                                    width: '12.5%',
                                }}
                            ></Box>
                            <Box style={{ width: '12.5%' }}>HUB NAME</Box>
                            <Box
                                style={{
                                    width: '12.5%',
                                }}
                            >
                                Shift
                            </Box>
                            <Box style={{ width: '12.5%' }}>SALARY INFO</Box>
                            <Box style={{ width: '10%' }}>STATUS</Box>
                            <Box
                                style={{
                                    width: '15%',
                                }}
                            >
                                EV Status
                            </Box>
                            <Box style={{ width: '15%', textAlign: 'right' }}>
                                PENDING PAYMENTS
                            </Box>
                            <Box style={{ width: '10%' }}></Box>
                        </Box>
                    </ListItem>
                    <>
                        {data?.length ? (
                            data?.map((val, index) => {
                                // console.log(key); // the name of the current key.
                                // console.log(val); // the value of the current key.
                                const EV_STATUS = PENDING_STATUS.includes(
                                    val?.evStatus,
                                )
                                    ? EV_CLASS_STATUS.PENDING_STATUS
                                    : PROGRESS_STATUS.includes(val?.evStatus)
                                    ? EV_CLASS_STATUS.PROGRESS_STATUS
                                    : SUCCESS_STATUS.includes(val?.evStatus)
                                    ? EV_CLASS_STATUS.SUCCESS_STATUS
                                    : FAILED_STATUS.includes(val?.evStatus)
                                    ? EV_CLASS_STATUS.FAILED_STATUS
                                    : EV_CLASS_STATUS.EMPTY;
                                const EV_STATUS_CLASS =
                                    CLASS_NAME_MAPPING[EV_STATUS];
                                return (
                                    <div
                                        className={`${classes.listItem} staff-list`}
                                        onClick={(e) => onRowClicked(e, val)}
                                        // href={`/staff/details/${phoneNumber}`}
                                        // href="#simple"
                                        key={val.id}
                                        style={
                                            index % 2
                                                ? {
                                                      backgroundColor:
                                                          'rgba(241, 243, 246, 1)',
                                                  }
                                                : {}
                                        }
                                    >
                                        <Box
                                            style={{
                                                width: '15%',
                                            }}
                                            // className={classes.tableCell}
                                        >
                                            <Box
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    flexWrap: 'wrap',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <Typography
                                                    style={{
                                                        wordBreak: 'break-all',
                                                        fontWeight: 600,
                                                        marginRight: 5,
                                                    }}
                                                >
                                                    {val.name ?? '-'}
                                                </Typography>
                                                {!!val?.uidaiKycRequired &&
                                                    !!val?.uidaiKycVerified && (
                                                        <Tooltip
                                                            title="UIDAI Verified"
                                                            placement="top"
                                                        >
                                                            <AadhaarSuccessIcon
                                                                style={{
                                                                    width: '20px',
                                                                    marginRight: 5,
                                                                }}
                                                            />
                                                        </Tooltip>
                                                    )}
                                                {!!val?.hyperlocalDE && (
                                                    <Tooltip
                                                        title="Enabled Hyperlocal Orders"
                                                        placement="top"
                                                    >
                                                        <HyperlocalDEIcon
                                                            style={{
                                                                height: '20px',
                                                            }}
                                                        />
                                                    </Tooltip>
                                                )}
                                            </Box>
                                            <Box
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    flexWrap: 'wrap',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <Typography
                                                    style={{
                                                        width: '40%',
                                                        fontSize: '12px',
                                                        marginRight: '4px',
                                                    }}
                                                >
                                                    {val?.staffTypeName ?? '-'}
                                                </Typography>
                                                <Typography
                                                    style={{
                                                        fontSize: '12px',
                                                        // marginLeft: '4px',
                                                    }}
                                                >
                                                    {val.contactNumber ?? '-'}
                                                </Typography>
                                            </Box>
                                        </Box>
                                        <Box
                                            style={{
                                                width: '12.5%',
                                                display: 'flex',
                                                justifyContent: 'flex-start',
                                            }}
                                            // className={classes.tableCell}
                                        >
                                            <Box
                                                style={{
                                                    width: '40%',
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    justifyContent:
                                                        'flex-start',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                {val.selfieEnabled && (
                                                    <Box
                                                        style={{
                                                            marginRight: '10px',
                                                        }}
                                                    >
                                                        <Tooltip
                                                            title="Enabled Selfie"
                                                            placement="top"
                                                        >
                                                            <SelfieAttendanceIcon
                                                                width={20}
                                                                height={20}
                                                            />
                                                        </Tooltip>
                                                    </Box>
                                                )}
                                                {/* {val.geofenceEnabled && (
                                                  <Box
                                                      style={{
                                                          marginRight: '10px',
                                                      }}
                                                  >
                                                      <GeofenceAttendanceIcon
                                                          width={16}
                                                          height={16}
                                                      />
                                                  </Box>
                                              )} */}
                                                {val.geofenceAutoApproval && (
                                                    <Box
                                                        style={{
                                                            marginRight: '10px',
                                                        }}
                                                    >
                                                        <Tooltip
                                                            title="Enabled Geofence Auto Approval"
                                                            placement="top"
                                                        >
                                                            <GeofenceAutoApprovalIcon
                                                                width={16}
                                                                height={16}
                                                            />
                                                        </Tooltip>
                                                    </Box>
                                                )}
                                                {val.trackAndTraceEnabled && (
                                                    <Box
                                                        style={{
                                                            marginRight: '10px',
                                                        }}
                                                    >
                                                        <Tooltip
                                                            title="Enabled Track and Trace"
                                                            placement="top"
                                                        >
                                                            <TrackingEnabledIcon
                                                                width={16}
                                                                height={16}
                                                            />
                                                        </Tooltip>
                                                    </Box>
                                                )}
                                            </Box>
                                        </Box>
                                        <Box
                                            style={{
                                                width: '12%',
                                                alignItems: 'center',
                                            }}
                                            // className={classes.tableCell}
                                        >
                                            <Typography
                                                style={{
                                                    wordBreak: 'break-all',
                                                    fontWeight: 600,
                                                }}
                                            >
                                                {val?.hubName ?? '-'}
                                            </Typography>
                                            {!!val?.customers?.length && (
                                                <Box
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        flexWrap: 'wrap',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <Typography
                                                        style={{
                                                            fontSize: '12px',
                                                        }}
                                                    >
                                                        {val?.customers
                                                            .map((cus) => {
                                                                return !!cus?.customerId
                                                                    ? `${getClientName(
                                                                          cus?.customerId,
                                                                      )}${
                                                                          !!cus?.staffCustCode
                                                                              ? `(${cus?.staffCustCode})`
                                                                              : ''
                                                                      }`
                                                                    : '';
                                                            })
                                                            .join(`, `)}
                                                    </Typography>
                                                </Box>
                                            )}
                                        </Box>
                                        <Box
                                            style={{
                                                width: '13%',
                                                alignItems: 'center',
                                            }}
                                        >
                                            {!!val?.shiftTimings?.length >
                                                0 && (
                                                <CustomDropdown
                                                    title={val?.shiftTimings
                                                        ?.map(
                                                            (shift) =>
                                                                shift?.shortName,
                                                        )
                                                        ?.join(' | ')}
                                                    buttonStyles={{
                                                        border: 'none',
                                                        backgroundColor:
                                                            'transparent',
                                                        fontSize: 14,
                                                        textAlign: 'left',
                                                    }}
                                                    options={getShiftTimingsOptions(
                                                        val,
                                                    )}
                                                />
                                            )}
                                        </Box>
                                        <Box
                                            style={{
                                                width: '12.5%',
                                                alignItems: 'center',
                                            }}
                                            // className={classes.tableCell}
                                        >
                                            <Typography
                                                style={{
                                                    wordBreak: 'break-all',
                                                    fontWeight: 600,
                                                }}
                                            >
                                                {val?.salaryTypeName ??
                                                    'Monthly'}
                                            </Typography>
                                            <Box
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    flexWrap: 'wrap',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <Typography
                                                    style={{
                                                        fontSize: '12px',
                                                    }}
                                                >
                                                    {val?.vendorName ?? ' '}
                                                </Typography>
                                            </Box>
                                        </Box>
                                        <Box style={{ width: '10%' }}>
                                            <Typography
                                                style={{
                                                    width: '100%',
                                                }}
                                                className={
                                                    val.status === 'KYC PENDING'
                                                        ? 'kyc-pen-chip'
                                                        : val.status ===
                                                          'KYC REJECTED'
                                                        ? 'kyc-rej-chip '
                                                        : val.status ===
                                                          'REJECTED'
                                                        ? 'kyc-rej-chip '
                                                        : val.status ===
                                                          'INACTIVE'
                                                        ? 'inactive-chip'
                                                        : val.status ===
                                                          'PENDING'
                                                        ? 'kyc-pen-chip'
                                                        : val.status ===
                                                          'Active'
                                                        ? 'active-chip'
                                                        : val.status ===
                                                          'VERIFIED'
                                                        ? 'active-chip'
                                                        : null
                                                }
                                            >
                                                {val.status ?? '-'}
                                            </Typography>
                                        </Box>
                                        <Box
                                            style={{
                                                width: '15%',
                                                marginLeft: '15px',
                                            }}
                                        >
                                            <Typography
                                                align="justify"
                                                style={{
                                                    minWidth: '100px',
                                                }}
                                                className={
                                                    EV_STATUS_CLASS ?? null
                                                }
                                                //component={'span'}
                                            >
                                                {val?.evStatus ?? '--'}
                                            </Typography>
                                        </Box>
                                        <Box
                                            style={{
                                                width: '15%',
                                                textAlign: 'right',
                                            }}
                                        >
                                            <Typography>
                                                {formatCurrency(
                                                    val.pendingPayments,
                                                ) ?? '-'}
                                            </Typography>
                                        </Box>
                                        {/* <div
                                                    style={{
                                                        width: '12.5%',
                                                    }}
                                                    // className={classes.tableCell}
                                                > */}

                                        <Box
                                            style={{
                                                width: '10%',
                                                display: 'flex',
                                                justifyContent: 'flex-end',
                                            }}
                                        >
                                            {actionDropDown(val)}
                                        </Box>
                                    </div>
                                );
                            })
                        ) : (
                            <Box
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <EmptyIcon />
                            </Box>
                        )}
                    </>
                    {editStaffModalOpen && (
                        <AddEditStaffModal
                            open={editStaffModalOpen}
                            isEdit={true}
                            data={selectedItemData}
                            onClose={() => {
                                console.log('Closed');
                                getAllStaffInfo && getAllStaffInfo({});
                                setEditStaffModalOpen(false);
                            }}
                        />
                    )}
                    {editClientModalOpen && (
                        <AddClientModal
                            open={editClientModalOpen}
                            isEdit={!!selectedItemData?.customers?.length}
                            data={selectedItemData}
                            onClose={() => {
                                console.log('Closed');
                                getAllStaffInfo && getAllStaffInfo({});
                                setEditClientModalOpen(false);
                            }}
                        />
                    )}
                    {editVendorModalOpen && (
                        <AddVendorModal
                            open={editVendorModalOpen}
                            isEdit={
                                // selectedItemData?.staffCustCode &&
                                selectedItemData?.customerName
                            }
                            data={selectedItemData}
                            onClose={() => {
                                console.log('Closed');
                                getAllStaffInfo && getAllStaffInfo({});
                                setEditVendorModalOpen(false);
                            }}
                        />
                    )}

                    {deleteStaffModalOpen && (
                        <DeleteStaffModal
                            open={deleteStaffModalOpen}
                            deleteStaff={deleteStaffInfo}
                            data={selectedItemData}
                            onClose={() => {
                                console.log('Closed');
                                getAllStaffInfo && getAllStaffInfo({});
                                setDeleteStaffModalOpen(false);
                            }}
                        />
                    )}
                    {paymentStaffModalOpen && (
                        <AddPaymentModal
                            open={true}
                            staffInfo={selectedItemData}
                            onClose={() => {
                                getAllStaffInfo && getAllStaffInfo({});
                                setPaymentStaffModalOpen(false);
                            }}
                        />
                    )}
                    {updateTrainingStatusModalOpen && (
                        <UpdateTrainingStatusModal
                            open
                            staffInfo={selectedItemData}
                            onClose={() => {
                                getAllStaffInfo && getAllStaffInfo({});
                                setUpdateTrainingStatusModalOpen(false);
                            }}
                        />
                    )}
                </List>
            </Spinner>
        </Box>
    );
};

const mapStateToProps = (state) => ({
    isEnabledXUtil: state?.user?.config?.isEnabledXUtil,
});

const mapDispatchToProps = (dispatch) => ({
    deleteStaffInfo: (payload, onSuccess, onError) =>
        dispatch(deleteStaff(payload, onSuccess, onError)),

    dispatchGetSingleStaff: (payload, onSuccess, onError) =>
        dispatch(getSingleStaff(payload, onSuccess, onError)),

    dispatchSendAppLink: (payload, onSuccess, onError) =>
        dispatch(sendAppLink(payload, onSuccess, onError)),

    dispatchEnqueueSnackbar: (notification) =>
        dispatch(enqueueSnackbar(notification)),

    dispatchUpdateStatus: (data, onSuccess, onError) =>
        dispatch(updateStatus(data, onSuccess, onError)),
    dispatchFetchClientList: (onSuccess, onError) =>
        dispatch(fetchClientList(onSuccess, onError)),
});

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(AllStaffListContainer),
);
