import { Error404 } from './pages/errors';
import Home from './pages/home';
import Login from './pages/login';
import Settings from './pages/settings/Settings';
import Staff from './pages/staff';
import StaffDetail from './pages/staff-details/StaffDetail';
import Attendance from './pages/attendance';
import { RouteLayouts } from './utils/constants';
import Report from './pages/report';
import MapView from './pages/map-view';
import Contacts from './pages/contacts';
import PayOnline from './pages/pay-online';
import MapDetails from './pages/map-view/MapDetails';
import MapDetailsFiltered from './pages/map-view/MapDetailsFiltered';
import Terms from './pages/terms';
import Privacy from './pages/privacy';
import { Vivo, Oppo, Realme } from './pages/extras';
import Hire from './pages/hire';
import SingleMapView from './pages/map-view/MapDetailsV2';
import RiderMapView from './pages/map-view/MapDetailsV3';
import ControlCenterPage from './pages/control-center';
import EVPage from './pages/ev';
import SingleEV from './pages/ev/SingleEV';
const routes = [
    {
        path: '/login',
        component: Login,
        name: 'Login',
        layout: RouteLayouts.BlankLayout,
    },
    {
        path: '/terms',
        component: Terms,
        name: 'Terms',
        layout: RouteLayouts.BlankLayout,
    },
    {
        path: '/privacy',
        component: Privacy,
        name: 'Privacy',
        layout: RouteLayouts.BlankLayout,
    },
    {
        path: '/404',
        component: Error404,
        name: 'Error 404',
        layout: RouteLayouts.BlankLayout,
    },
    {
        path: '/settings',
        component: Settings,
        keepInNav: false,
        name: 'Settings',
        layout: RouteLayouts.MainLayout,
    },
    {
        path: '/staff/details/:id?',
        component: StaffDetail,
        keepInNav: false,
        name: 'Staff Details',
        layout: RouteLayouts.MainLayout,
    },
    {
        path: '/staff',
        component: Staff,
        keepInNav: true,
        name: 'Staff',
        order: 2,
        layout: RouteLayouts.MainLayout,
    },
    {
        path: '/ev-sp/details/:id?',
        component: SingleEV,
        keepInNav: false,
        name: 'EV Details',
        layout: RouteLayouts.MainLayout,
        containerStyles: {
            marginBottom: '4px',
        },
    },
    {
        path: '/ev-sp',
        component: EVPage,
        keepInNav: true,
        name: 'EV SP',
        routeName: 'EV',
        order: 1,
        layout: RouteLayouts.MainLayout,
        containerStyles: {
            marginBottom: '4px',
        },
    },
    {
        path: '/attendance',
        component: Attendance,
        keepInNav: true,
        name: 'Attendance',
        order: 3,
        layout: RouteLayouts.MainLayout,
    },
    {
        path: '/hire',
        component: Hire,
        keepInNav: true,
        name: 'Hire',
        order: 4,
        layout: RouteLayouts.MainLayout,
    },
    {
        path: '/map-view/detail/:id?',
        component: RiderMapView,
        keepInNav: false,
        name: 'Map Detail',
        order: 5,
        layout: RouteLayouts.MainLayout,
        containerStyles: {
            marginBottom: '4px',
        },
    },
    {
        path: '/map-view/detailV1/:id?',
        component: RiderMapView,
        keepInNav: false,
        name: 'Map Detail',
        order: 5,
        layout: RouteLayouts.MainLayout,
        containerStyles: {
            marginBottom: '4px',
        },
    },
    {
        path: '/map-view/detail-filtered/:id?',
        component: MapDetailsFiltered,
        keepInNav: false,
        name: 'Map Detail',
        order: 5,
        layout: RouteLayouts.MainLayout,
    },
    {
        path: '/map-view',
        component: MapView,
        keepInNav: true,
        name: 'Map View',
        order: 5,
        layout: RouteLayouts.MainLayout,
    },
    {
        path: '/report',
        component: Report,
        keepInNav: true,
        name: 'Report',
        order: 7,
        layout: RouteLayouts.MainLayout,
    },
    {
        path: '/pay-online',
        component: PayOnline,
        keepInNav: true,
        name: 'Pay Online',
        order: 8,
        layout: RouteLayouts.MainLayout,
    },
    {
        path: '/contacts',
        component: Contacts,
        keepInNav: true,
        name: 'Contacts',
        order: 9,
        layout: RouteLayouts.MainLayout,
    },
    {
        path: '/control-center',
        component: ControlCenterPage,
        keepInNav: true,
        name: 'Control Center',
        order: 8,
        layout: RouteLayouts.MainLayout,
        containerStyles: {
            marginBottom: '4px',
        },
    },
    {
        path: '/home',
        component: Home,
        keepInNav: false,
        name: 'Home',
        order: 1,
        layout: RouteLayouts.MainLayout,
    },
    {
        path: '/extras/Vivo',
        component: Vivo,
        name: 'Vivo',
        layout: RouteLayouts.BlankLayout,
    },
    {
        path: '/extras/oppo',
        component: Oppo,
        name: 'Oppo',
        layout: RouteLayouts.BlankLayout,
    },
    {
        path: '/extras/realme',
        component: Realme,
        name: 'Realme',
        layout: RouteLayouts.BlankLayout,
    },
];

export default routes;
