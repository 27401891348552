import React from 'react';
import { CustomTextField } from '../../components/Textfield';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { withRouter } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    privacyTitle: {
        fontSize: 20,
        fontWeight: 600,
        marginBottom: 22,
        marginTop: 22,
    },

    paragraphy: {
        marginBottom: 8,
    },
}));
const Terms = () => {
    const classes = useStyles();
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <Box
            style={{
                backgroundColor: '#fff',
                // marginBottom: '2rem',
            }}
        >
            <Box
                style={{
                    height: '15%',
                    padding: '14px 0 14px 100px',
                    boxShadow: '0 1px 1px 0 rgba(0, 0, 0, 0.07)',
                }}
            >
                <Typography style={{ fontSize: '32px', fontWeight: 600 }}>
                    StaffPay
                </Typography>
            </Box>

            <Box
                className="privacy-content"
                style={{
                    margin: '2rem 10rem 0 10rem',
                    textAlign: 'justify',
                    backgroundColor: '#f1f3f6',
                    padding: '2px 10px 2px 10px',
                }}
            >
                <Typography className={classes.privacyTitle}>
                    Terms & Conditions
                </Typography>
                <Typography className={classes.paragraphy}>
                    StaffPay Solutions Private Limited (the “Company”) operates
                    a web/mobile application, “StaffPay” (the “App”) available
                    on Google Play Store and other similar platforms and also
                    operates a website https://staffpay.in/ (the “Website”) .
                    The App and the Website shall be together referred to as the
                    “Platform”. These terms and conditions (“Terms”) govern the
                    use of or access to the Platform and the Services (as
                    defined below).
                </Typography>
                <Typography className={classes.paragraphy}>
                    These Terms constitute a binding and enforceable legal
                    contract between the Company and a User (as defined below)
                    or any end user of the Services (collectively, “you”). You
                    represent and warrant that you (a) have full legal capacity
                    and authority to agree and bind yourself to these Terms, (b)
                    are 18 (eighteen) years of age or older, and (c) are an
                    Indian resident. If you represent an entity, organisation,
                    or any other legal person, you confirm and represent that
                    you have the necessary power and authority to bind such
                    entity, organisation, or legal person to these Terms.
                </Typography>
                <Typography className={classes.paragraphy}>
                    These Terms also include our privacy policy, available at
                    (“Privacy Policy”) and any internal guidelines,
                    supplementary terms, policies, or disclaimers made available
                    or issued by us from time to time. By continuing to access
                    or use the Platform, or any Service on the Platform, you
                    signify your acceptance of the Terms. Further, you
                    understand that the Platform is intended for the use of
                    Indian residents only and by your continuing access and/use
                    of the Platform shall be construed as a deemed declaration
                    that you are an Indian resident.
                </Typography>
                <Typography className={classes.paragraphy}>
                    The Company reserves the right to make changes to these
                    Terms by posting the new/updated version and your continued
                    use and/or non-deletion of the Platform shall indicate your
                    agreement to such changes. Accordingly, we encourage you to
                    kindly continue to review the Terms whenever accessing or
                    using the Platform so as to be abreast with the changes that
                    we may be carrying out to these Terms.
                </Typography>
                <Typography className={classes.paragraphy}>
                    By using the Services, you agree that you have read,
                    understood, and are bound by these Terms, and that you
                    comply with the requirements listed herein. If you do not
                    agree to all of these Terms or comply with the requirements
                    herein, please do not access the Platform or use the
                    Services.
                </Typography>
                <Typography className={classes.privacyTitle}>
                    SERVICES
                </Typography>
                <Typography className={classes.paragraphy}>
                    The Platform (i) provides an online digital ledger book /
                    record book wherein the users (“Users”), can record their
                    trips and ledger entries with their respective customers and
                    (ii) also allows Users to generate an invoice summary which
                    can be sent it to their respective customers and the
                    customers could use the summary and make the payment of the
                    amount to the Users in settlement of their obligations
                    towards the Users (collectively, “Services”). For the
                    purposes of this clause, Services would include any other
                    future services the Company provides/proposes to provide.
                </Typography>
                <Typography className={classes.privacyTitle}>
                    ON-BOARDING
                </Typography>
                <Typography className={classes.paragraphy}>
                    To avail the Services, a User would be required to create a
                    profile/sign-up on the Platform (“Profile”) using his/her
                    email ID and phone number among other details. In addition
                    to setting up username and password to create the Profile,
                    the User will be required to furnish certain details,
                    including but not limited to phone numbers and details of
                    its customers and businesses. The User warrants that all
                    information furnished in connection with its Profile is and
                    shall remain accurate and true in all respects. The User
                    further agrees and undertakes to promptly update its details
                    on the Platform in the event of any change or modification
                    of such details.
                </Typography>
                <Typography className={classes.paragraphy}>
                    The User is solely responsible for maintaining the security
                    and confidentiality of its username and password, and agrees
                    to immediately notify the Company in writing to our office
                    of any disclosure or unauthorized use of its Profile or any
                    other breach of security with respect to its Profile.
                </Typography>
                <Typography className={classes.paragraphy}>
                    The User expressly agrees to be liable and accountable for
                    all activities that take place through its Profile in
                    furtherance of the use of Service or otherwise. The Company
                    expressly excludes any liability for any unauthorised access
                    to a User’s Profile.
                </Typography>
                <Typography className={classes.paragraphy}>
                    The User agrees to receive communications from the Company
                    regarding: (i) information relating to transactions recorded
                    on the Platform; (ii) requests for payment; (iii)
                    information about the Company and the Services; (iv)
                    promotional offers and services from the Company and its
                    third party partners, and (v) any other matter in relation
                    to the Services.
                </Typography>
                <Typography className={classes.privacyTitle}>
                    KNOW YOUR CUSTOMER POLICY
                </Typography>
                <Typography className={classes.paragraphy}>
                    The Company may require Users to upload certain information
                    and documents that may be necessary to ascertain their
                    eligibility to use certain features of the Services
                    including but not limited to their identification documents
                    (“KYC Documents”).
                </Typography>
                <Typography className={classes.paragraphy}>
                    The User hereby authorises the Company and any third-party
                    service provider it may engage with or interact with in
                    connection with using the Platform to process KYC Documents
                    and ascertain the User’s eligibility. Any processing
                    undertaken by the Company shall be in accordance with its
                    Privacy Policy and these Terms. It is hereby clarified that
                    as far as the permission of the KYC Documents by a third
                    party service provider is concerned, the same shall be
                    governed by the privacy policy of such third party service
                    provider.
                </Typography>
                <Typography className={classes.paragraphy}>
                    The User agrees that it may be required to submit additional
                    documents as and when required by the Company or any of its
                    third party service providers and if, in such an event, any
                    additional information, data, or documentation is required
                    (collectively, “Top-Up Documents”), the User hereby agrees
                    to share such Top-Up Documents promptly upon request, and
                    further, authorises the Company to process such Top-Up
                    Documents. The User agrees and warrants to provide valid,
                    true, complete, and up-to-date KYC Documents and Top-Up
                    Documents. The User further acknowledges that any incorrect
                    or misleading information provided shall constitute a
                    material breach of these Terms, and the User’s access to
                    certain features of the Services may be limited or denied in
                    such event.
                </Typography>
                <Typography className={classes.paragraphy}>
                    The list of the KYC Documents and Top-Up Documents may be
                    provided to the User at the time of creating the
                    Profile/signing-up or at a later stage.
                </Typography>
                <Typography className={classes.privacyTitle}>
                    TRANSACTION INFORMATION
                </Typography>
                <Typography className={classes.paragraphy}>
                    The Users may upload information relating to transactions
                    with their customers or relating to their businesses,
                    including the sale of goods or services, costs, amounts paid
                    and payable, and details of goods and services, on the
                    Platform (such information is referred to as “Transaction
                    Information”). Transaction Information may be exchanged
                    between the Users and their customer through telephonic
                    calls, text message, WhatsApp, email, or other electronic
                    mediums that would depend on the contact details of Users
                    and their customers provided on the Platform.
                </Typography>
                <Typography className={classes.paragraphy}>
                    At the time of creating or uploading the first Transaction
                    Information with respect to their customers, the User shall
                    inform such customers of its use of the Platform to record
                    such Transaction Information and Transaction Information
                    related to future transactions and seek such Customer’s
                    express consent in this regard and to: The creation of a
                    profile of the customer on the Platform, which will require
                    sharing such customer’s phone number and contact details
                    with the Company; receive communications from the Company
                    regarding: (A) information relating to their transactions
                    recorded on the Platform; (B) requests for payment; (C)
                    information about the Company and the Services; (D)
                    promotional offers and services from the Company and its
                    third party partners, and (E) any other matter in relation
                    to the Services. If such customers fail to provide consent,
                    or withdraw consent, the User shall immediately cease to use
                    the Services in relation to such customers. The User shall
                    be solely responsible for obtaining such consent from its
                    customers and the Company shall assume that such consent as
                    required under clause 4(b) above is sought and received by
                    the User if the User provides details of such Transaction
                    Information relating to any of such customers at any time
                    during the use of the Platform.
                </Typography>
                <Typography className={classes.privacyTitle}>
                    THIRD PARTY SERVICES
                </Typography>
                <Typography className={classes.paragraphy}>
                    The Services may include services, content, documents, and
                    information owned by, licensed to, or otherwise made
                    available by a third party (“Third Party Services”) or
                    contain links to Third Party Services. Users understand that
                    Third Party Services are the responsibility of the third
                    party that created or provided it and acknowledge that use
                    of such Third Party Services is solely at their own risk.
                </Typography>
                <Typography className={classes.paragraphy}>
                    The Company makes no representations and hereby expressly
                    excludes all warranties and liabilities arising out of or
                    pertaining to such Third Party Services, including their
                    accuracy or completeness. Further, all intellectual property
                    rights in and to Third Party Services are the property of
                    the respective third parties.
                </Typography>
                <Typography className={classes.privacyTitle}>
                    USER RESPONSIBILITIES
                </Typography>
                <Typography className={classes.paragraphy}>
                    The User hereby represents and warrants that all information
                    that is provided by the User through or in relation to the
                    Services is valid, complete, true, and correct on the date
                    of agreeing to these Terms and shall continue to be valid,
                    complete, true, and correct throughout the duration of the
                    User’s use of the Platform. The Company does not accept any
                    responsibility or liability for any loss or damage the User
                    may suffer or incur if any information, documentation,
                    material, or data provided to avail the Services is
                    incorrect, incomplete, inaccurate, or misleading, or if the
                    User fails to disclose any material fact.
                </Typography>
                <Typography className={classes.paragraphy}>
                    The User shall be solely responsible for ensuring compliance
                    with applicable laws, and shall be solely liable for any
                    liability that may arise due to a breach of its obligations
                    in this regard.
                </Typography>
                <Typography className={classes.paragraphy}>
                    The User shall extend all cooperation to the Company in its
                    defending of any proceedings that may be initiated against
                    it due to a breach of the User’s obligations or covenants
                    under these Terms.
                </Typography>
                <Typography className={classes.paragraphy}>
                    While the Company uses commercially reasonable efforts to
                    provide Users with a daily backup of their Transaction
                    Information, Users should regularly and independently save,
                    backup, and archive such Transaction Information.
                </Typography>
                <Typography className={classes.paragraphy}>
                    The User shall not use the Services in any manner except as
                    expressly permitted in these Terms. Without limiting the
                    generality of the preceding sentence, the User may not:
                    Infringe either directly or indirectly any third party
                    proprietary rights, including but not limited to copyrights,
                    patents, trademarks, or trade secrets, of any party; except
                    as may be provided hereunder, use in any manner including
                    copying, displaying, distributing, modifying, publishing,
                    reproducing, storing, transmitting, posting, translating,
                    creating any derivative works from, or license the Services;
                    use the Services to transmit any data or send or upload any
                    material that contains viruses, Trojan horses or any other
                    harmful programmes or similar computer code designed to
                    adversely affect the operation of any computer software or
                    hardware; use any robot, spider, other automated device, or
                    manual process to monitor or copy the Platform or Services
                    or any portion thereof; engage in the systematic retrieval
                    of content from the Platform or Services to create or
                    compile, directly or indirectly, a collection, compilation,
                    database or directory; or violate applicable laws in any
                    manner.
                </Typography>
                <Typography className={classes.privacyTitle}>
                    INTELLECTUAL PROPERTY
                </Typography>
                <Typography className={classes.paragraphy}>
                    All rights, title, and interest in and to the Platform and
                    Services, including all intellectual property rights arising
                    out of the Platform and Services, are owned by or otherwise
                    lawfully licensed by the Company. Subject to compliance with
                    these Terms, the Company grants the User a non-exclusive,
                    non-transferable, non-sub licensable, royalty-free,
                    revocable, and limited licence to use the Platform and
                    Services in accordance with these Terms and its written
                    instructions issued from time to time.
                </Typography>
                <Typography className={classes.paragraphy}>
                    The User should assume that everything the User sees or
                    reads on the Platform is protected under the Indian
                    Copyright Act, 1957 and other intellectual property laws of
                    India and may not be used except with the prior written
                    permission of the Company.
                </Typography>
                <Typography className={classes.paragraphy}>
                    The Company may freely use, copy, disclose, publish,
                    display, distribute without any payment of royalty,
                    acknowledgement, prior consent, or any other form of
                    restriction arising out of the User’s intellectual property
                    rights.
                </Typography>
                <Typography className={classes.paragraphy}>
                    Except as stated in these Terms, nothing in these Terms
                    should be construed as conferring any right in or license to
                    the Company’s or any third party’s intellectual rights.
                </Typography>
                <Typography className={classes.paragraphy}>
                    The contents of this Platform, including but not limited to
                    the text and images herein and their arrangements, unless
                    otherwise noted, are copyright-protected in the whole and
                    every part of this Platform and the same belongs to the
                    Company and may not be used, sold, licensed, copied or
                    reproduced in whole or in part in any manner or form or in
                    or on any media to any person without the prior written
                    consent of the Company.
                </Typography>
                <Typography className={classes.privacyTitle}>
                    TERM AND TERMINATION
                </Typography>
                <Typography className={classes.paragraphy}>
                    These Terms shall remain in effect unless terminated in
                    accordance with the terms hereunder.
                </Typography>
                <Typography className={classes.paragraphy}>
                    The Company may terminate a User’s access to or use of the
                    Services, or any portion thereof, immediately and at any
                    point, at its sole discretion, if the User violates or
                    breaches any of its obligations, responsibilities, or
                    covenants under these Terms.
                </Typography>
                <Typography className={classes.paragraphy}>
                    Upon termination these Terms shall terminate, except for
                    those clauses that expressly or are intended to survive
                    termination or expiry.
                </Typography>
                <Typography className={classes.paragraphy}>
                    Notwithstanding anything to the contrary contained in the
                    Terms, upon termination of a User’s access to or use of the
                    Services, all amounts or outstanding amount due by you in
                    relation to your use of or access to the Services shall
                    become immediately payable.
                </Typography>
                <Typography className={classes.privacyTitle}>
                    DISCLAIMERS AND WARRANTIES
                </Typography>
                <Typography className={classes.paragraphy}>
                    The use of the Services is at your sole risk.
                </Typography>
                <Typography className={classes.paragraphy}>
                    You acknowledge and agree that the Company is not engaged in
                    the provision, grant, or disbursement of any financial
                    product. Further the Company does not act as an intermediary
                    and does not collect or facilitate the collection of monies.
                    The Company is not and will not be responsible for any claim
                    or for any damages suffered, whether by the Users, the
                    customers of the Users or any other person or party, that
                    are related, directly or indirectly, to or arise out of the
                    same including any payments made by the User or by the
                    customers of the User. The User further agrees and
                    undertakes to retain proof of sale documentation (in
                    electronic or physical form) in connection with each payment
                    link it generates or sends to customers.
                </Typography>
                <Typography className={classes.paragraphy}>
                    The Company is only a technology platform service provider
                    and: is not registered with the Reserve Bank of India; does
                    not hold any license to engage in any activities relating to
                    financial products; is not a financial institution under the
                    Companies Act, 2013 or the Banking Regulation Act, 1949 or
                    any other laws for the time being in force in India; and is
                    not an intermediary under the applicable laws.
                </Typography>
                <Typography className={classes.paragraphy}>
                    To the extent permitted by applicable law, the Services are
                    provided on an “as is” and “as available” basis. The Company
                    does not warrant that operation of the Services will be
                    uninterrupted or error free or that the functions contained
                    in the Services will meet your requirements.
                </Typography>
                <Typography className={classes.paragraphy}>
                    To the fullest extent permissible under applicable law, the
                    Company expressly disclaims all warranties of any kind,
                    express or implied, arising out of the Services, including
                    warranties of merchantability, fitness for a particular
                    purpose, satisfactory quality, accuracy, title and
                    non-infringement, compatibility, applicability, usability,
                    appropriateness, and any warranty that may arise out of
                    course of performance, course of dealing, or usage of trade.
                </Typography>
                <Typography className={classes.paragraphy}>
                    You hereby accept full responsibility for any consequences
                    that may arise from your use of the Services, and expressly
                    agree and acknowledge that the Company shall have absolutely
                    no liability with respect to the same.
                </Typography>
                <Typography className={classes.paragraphy}>
                    To the fullest extent permissible by law, the Company, its
                    affiliates, and its related parties each disclaim all
                    liability to you for any loss or damage arising out of or
                    due to: your use of, inability to use, or availability or
                    unavailability of the Services, including any Third Party
                    Services; the occurrence or existence of any defect,
                    interruption, or delays in operation or transmission of
                    information to, from, or through the Services,
                    communications failure, theft, destruction or unauthorised
                    access to the Company’s records, programmes, services,
                    server, or other infrastructure relating to the Services; or
                    the failure of the Services to remain operational for any
                    period of time.
                </Typography>
                <Typography className={classes.paragraphy}>
                    Notwithstanding anything to the contrary contained herein,
                    neither the Company nor any of its affiliates or related
                    parties shall have any liability to you or any third party
                    for any indirect, incidental, special or consequential
                    damages or any loss of revenue or profits arising under,
                    directly or indirectly, or relating, in any manner
                    whatsoever, to these Terms or the Services. To the maximum
                    extent permitted by law, you agree to waive, release,
                    discharge, and hold harmless the Company, its affiliated and
                    subsidiary companies, its parent companies, and each of
                    their directors, officers, employees, and agents, from any
                    and all claims, losses, damages, liabilities, expenses and
                    causes of action arising out of the Services.
                </Typography>
                <Typography className={classes.privacyTitle}>
                    INDEMNITY
                </Typography>
                <Typography className={classes.paragraphy}>
                    You shall indemnify, defend at the Company’s option, and
                    hold the Company, its parent companies, subsidiaries,
                    affiliates, and their officers, associates successors,
                    assigns, licensors, employees, directors, agents, and
                    representatives, harmless from and against any claim,
                    demand, lawsuits, judicial proceeding, losses, liabilities,
                    damages and costs (including, without limitation, from all
                    damages, liabilities, settlements, costs and attorneys’
                    fees) due to or arising out of your access to the Services,
                    use of the Services, violation of these Terms or any
                    infringement by any third party who may use your account
                    with the Company, of these Terms.
                </Typography>
                <Typography className={classes.privacyTitle}>
                    CONSENT TO USE DATA
                </Typography>
                <Typography className={classes.paragraphy}>
                    You agree that the Company and any third party service
                    providers it engages, may, in accordance with its Privacy
                    Policy, collect and use your information and technical data
                    and related information.
                </Typography>
                <Typography className={classes.paragraphy}>
                    The Company may use information and data pertaining to your
                    use of the Services for analytics, trends’ identification,
                    and purposes of statistics to further enhance the
                    effectiveness and efficiency of the Platform.
                </Typography>
                <Typography className={classes.paragraphy}>
                    Subject to applicable laws, the Company may be directed by
                    law enforcement agencies or the government and related
                    bodies to disclose data in relation to Users in connection
                    with criminal proceedings. You understand and agree that in
                    such instances, the Company shall have the right to share
                    such data with relevant agencies or bodies.
                </Typography>
                <Typography className={classes.privacyTitle}>
                    MAINTENANCE OF RECORDS
                </Typography>
                <Typography className={classes.paragraphy}>
                    You shall maintain the records of all payment transactions
                    on the Platform independently of the Platform (by way of
                    physical copies et cetera) and the Company reserves the
                    right to seek copies of such records for their own use
                    including record keeping.
                </Typography>
                <Typography className={classes.privacyTitle}>
                    FEES/CHARGES
                </Typography>
                <Typography className={classes.paragraphy}>
                    The Company reserves the right to charge a convenience fee
                    for the Services and non-payment may result in denial of
                    Services.
                </Typography>
                <Typography className={classes.privacyTitle}>
                    MODIFICATION
                </Typography>
                <Typography className={classes.paragraphy}>
                    The Company reserves the right at any time to add, modify or
                    discontinue, temporarily or permanently, the Services (or
                    any part thereof) with or without cause. The Company shall
                    not be liable for any such addition, modification,
                    suspension or discontinuation of the Services.
                </Typography>
                <Typography className={classes.privacyTitle}>
                    JURISDICTION, GOVERNING LAWS, AND DISPUTE RESOLUTION
                </Typography>
                <Typography className={classes.paragraphy}>
                    These Terms shall be governed by and construed and enforced
                    in accordance with the laws of India. Subject to other
                    provisions in this Clause, courts in Bangalore shall have
                    exclusive jurisdiction over all issues arising out of these
                    Terms or the use of the Services.
                </Typography>
                <Typography className={classes.paragraphy}>
                    Any controversies, conflicts, disputes, or differences
                    arising out of these Terms shall be resolved by arbitration
                    in Bangalore in accordance with the Arbitration and
                    Conciliation Act, 1996 for the time being in force, which is
                    deemed to be incorporated by reference in this Clause. The
                    tribunal shall consist of 1 (one) arbitrator appointed by
                    the Company. The language of the arbitration shall be
                    English.
                </Typography>
                <Typography className={classes.paragraphy}>
                    The parties to the arbitration shall keep the arbitration
                    confidential and not disclose to any person, other than on a
                    need to basis or to legal advisors, unless required to do so
                    by law. The decision of the arbitrator shall be final and
                    binding on all the Parties hereto.
                </Typography>
                <Typography className={classes.paragraphy}>
                    Each party to the arbitration shall bear its own costs with
                    respect to any dispute.
                </Typography>
                <Typography className={classes.privacyTitle}>
                    MISCELLANEOUS PROVISIONS
                </Typography>
                Modification – The Company reserves the right at any time to
                modify these Terms and to add new or additional terms or
                conditions on the use of the Services. Such modifications and
                additional terms and conditions will be communicated to you and,
                unless expressly rejected (in which these Terms shall
                terminate), will be effective immediately and will be
                incorporated into these Terms. In the event you refuse to accept
                such changes, these Terms will terminate.
                <Typography className={classes.paragraphy}>
                    Severability - If any provision of these Terms is determined
                    by any court or other competent authority to be unlawful or
                    unenforceable, the other provisions of these Terms will
                    continue in effect. If any unlawful or unenforceable
                    provision would be lawful or enforceable if part of it were
                    deleted, that part will be deemed to be deleted, and the
                    rest of the provision will continue in effect (unless that
                    would contradict the clear intention of the clause, in which
                    case the entirety of the relevant provision will be deemed
                    to be deleted).
                </Typography>
                <Typography className={classes.paragraphy}>
                    Assignment - You shall not licence, sell, transfer or assign
                    your rights, obligations, or covenants under these Terms in
                    any manner without the Company’s prior written consent. The
                    Company may grant or withhold this consent in its sole
                    discretion and subject to any conditions it deems
                    appropriate. The Company may assign its rights to any of its
                    affiliates, subsidiaries, or parent companies, or to any
                    successor in interest of any business associated with the
                    Services without any prior notice to you.
                </Typography>
                <Typography className={classes.paragraphy}>
                    Notices - All notices, requests, demands, and determinations
                    for the Company under these Terms (other than routine
                    operational communications) shall be sent to dev@staffpay.in
                </Typography>
                <Typography className={classes.paragraphy}>
                    Third Party Rights - No third party shall have any right to
                    enforce any terms contained herein.
                </Typography>
                <Typography className={classes.paragraphy}>
                    Translations –The Company may provide you with translated
                    versions of these Terms solely to assist you with
                    understanding these Terms in greater detail. The English
                    version of these Terms shall be controlling in all respects.
                    In the event of any inconsistency between the English
                    version of these Terms and any translated version, the terms
                    of the English version shall prevail.
                </Typography>
                <a name="refund"></a>
                <Typography className={classes.privacyTitle}>
                    CANCELLATION/REFUND
                </Typography>
                <Typography className={classes.paragraphy}>
                    <div>
                        Cancellation
                        <Typography className={classes.paragraphy}>
                            (a) UPI Transactions can't be cancelled after
                            initiating the transaction and if user has entered
                            the wrong number/VPA ID then StaffPay is not a
                            liable party in the transaction.
                        </Typography>
                        <Typography className={classes.paragraphy}>
                            (b) All UPI transactions are realtime and if there
                            is any delay in the end of the transaction then user
                            can check with support team of StaffPay after 30
                            mins cool period.
                        </Typography>
                    </div>
                </Typography>
                <Typography className={classes.paragraphy}>
                    <div>
                        Debit/Credit Card
                        <Typography className={classes.paragraphy}>
                            (a) If user has any issues related to the
                            transaction related to transaction linked with
                            Debit/Credit card then we suggest user to report the
                            same to StaffPay Via mail with level of escalation.
                        </Typography>
                        <Typography className={classes.paragraphy}>
                            (b) StaffPay will respond and try to resolve the
                            issue in the given escalation matrix as per the
                            policy defined.
                        </Typography>
                        <Typography className={classes.paragraphy}>
                            (c) All refunds will be processed by following the
                            validation of the transaction from case to case
                            basis in the given legal framework.
                        </Typography>
                        <Typography className={classes.paragraphy}>
                            (d) Escalation matrix remains the same for the
                            Refunds/Cancellation for all the transactions.
                        </Typography>
                    </div>
                </Typography>
            </Box>
        </Box>
    );
};

export default withRouter(Terms);
