import React, { useEffect, useRef, useState } from 'react';
import useEVStyles from './useEVStyles';
import { useDebounce } from '../../hooks/useDebounce';
import { getActiveInitRoute, getPermissions } from '../../utils/permissions';
import { useDispatch, useSelector, DefaultRootState } from 'react-redux';
import { Spinner } from '../../components/UI';
import EmptyState from '../../components/EmptyState';
import {
    GetCities,
    blockUnblockEV,
    deleteEvVehicle,
    getAllEVCaptains,
    getAllEVProjects,
    getAllEVTeams,
    getAllEVVehicles,
    getAllEVVehiclesV2,
    getAllEVVendors,
    getAllEVTelematics,
} from '../../store/actions/settingsActionCreator';
import {
    Box,
    Divider,
    Grid,
    Paper,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Typography,
} from '@material-ui/core';
import FilterModal, {
    FILTER_INPUT_TYPE,
    FilteredState,
} from '../../components/Modals/FilterModal';
import Searchbar from '../../components/Searchbar';
import {
    EVVEHICLE_STATUS_LIST,
    EVVEHICLE_STATUS_LIST_V2,
} from '../settings/EVVehicles';
import { EV_FILTER_CARDS, removeKeysFromObject } from './helper';
import useEVTable from './useEVTable';
import { tableConfig } from './helper';
import io, { Socket } from 'socket.io-client';
import { v4 as uuidv4 } from 'uuid';
import { generateMockData, preProcessPayload } from './helper';
import moment from 'moment';
import { groupBy, showMessage } from '../../utils/helper';
import usePrevious from '../../hooks/usePrevious';
import ConfirmModal from '../../components/Modals/ConfirmModal';
const EVPage: React.FC = ({
    heliosTrackingData = [],
    staffTrackAndTrace,
    ...props
}: any) => {
    const { debounce, isBouncing } = useDebounce();
    const previousSelection = props?.location?.state?.selectedFilter ?? null;
    const SOCKET_SERVER_URL = 'https://www.beecon.in/webSocket'; // Replace with your server URL
    const dispatch = useDispatch();
    const dispatchGetEvVehiclesListData = (
            payload: any,
            onSuccess?: Function,
            onError?: Function,
        ) => dispatch(getAllEVVehiclesV2(payload, onSuccess, onError)),
        dispatchDeleteEV = (
            id: any,
            onSuccess?: Function,
            onError?: Function,
        ) => dispatch(deleteEvVehicle(id, onSuccess, onError)),
        dispatchBlockUnblockEV = (
            payload: any,
            onSuccess?: Function,
            onError?: Function,
        ) => dispatch(blockUnblockEV(payload, onSuccess, onError)),
        dispatchGetAllEVVendors = (
            payload: any,
            onSuccess?: Function,
            onError?: Function,
        ) => dispatch(getAllEVVendors(payload, onSuccess, onError)),
        dispatchGetAllEVTeams = (
            payload: any,
            onSuccess?: Function,
            onError?: Function,
        ) => dispatch(getAllEVTeams(payload, onSuccess, onError)),
        dispatchGetAllEVProjects = (
            payload: any,
            onSuccess?: Function,
            onError?: Function,
        ) => dispatch(getAllEVProjects(payload, onSuccess, onError)),
        dispatchGetAllEVCaptains = (
            payload: any,
            onSuccess?: Function,
            onError?: Function,
        ) => dispatch(getAllEVCaptains(payload, onSuccess, onError)),
        dispatchGetAllEVTelematics = (
            payload: any,
            onSuccess?: Function,
            onError?: Function,
        ) => dispatch(getAllEVTelematics(payload, onSuccess, onError)),
        dispatchGetCities = (
            payload: any,
            onSuccess?: Function,
            onError?: Function,
        ) => dispatch(GetCities(payload, onSuccess, onError));
    //store values
    const { user, mapview, staff, settings } = useSelector(
        (store: DefaultRootState | any) => store || {},
    );

    const evListData = settings?.evListV2 ?? {
        evLineItemList: [],
        totalCount: 0,
    };
    const vendorList = settings?.evVendorList ?? [],
        teamList = settings?.evTeamList ?? [],
        projectsList = settings?.evProjectList ?? [],
        captainList = settings?.evCaptainList ?? [],
        telematicsList = settings?.telematicsList ?? [],
        managerGroupList = !!captainList?.length
            ? groupBy(settings?.evCaptainList, 'manager')
            : {},
        managerList = Object.keys(managerGroupList).map((manager) => ({
            manager,
            values: managerGroupList[manager],
        })),
        cityList = settings?.cities ?? [];
    const classes = useEVStyles();
    const [loading, setLoading] = useState(false);
    const [routeState, setRouteState] = useState<any>(null);
    const [firstRenderDone, setFirstRenderDone] = useState(false);
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [confirmData, setConfirmData] = useState<any>(null);
    const [permissions, setPermissions] = useState(getPermissions('EV SP'));
    const [activeFilterCard, setActiveFilterCard] = useState(
        !!previousSelection?.activeFilterCard
            ? previousSelection?.activeFilterCard
            : 'TAGGED_FILTER',
    );
    const socketRef = useRef<any>(null);
    const [pagination, setPagination] = useState<any>({
        pageSize: 25,
        pageNo: 0,
        ...(!!previousSelection?.pagination
            ? previousSelection?.pagination
            : {}),
    });
    const [filteredValues, setFilteredValue] = useState<any>({
        evDate: {
            startDate: moment()?.subtract(30, 'days'),
            endDate: moment(),
        },
        startDate: moment()?.subtract(30, 'days')?.format('YYYY-MM-DD'),
        endDate: moment()?.format('YYYY-MM-DD'),
        evStatus: 'TAGGED',
        ...(!!previousSelection?.filteredValues
            ? previousSelection?.filteredValues
            : {}),
    });
    const prevFilterValues = usePrevious(filteredValues);
    const [searchText, setSearchText] = useState('');
    const [evListingPayload, setEvListingPayload] = React.useState({
        pageNo: props?.location?.state?.page ?? 1,
        pageSize: props?.location?.state?.rowsPerPage ?? 25,
        searchText: '',
        cityIds: [],
        captainsIds: [],
        projectIds: [],
        vendorIds: [],
        telematicIds: [],
        filter: 'TAGGED_FILTER',
        evDate: {
            startDate: moment()?.subtract(30, 'days')?.format('YYYY-MM-DD'),
            endDate: moment()?.format('YYYY-MM-DD'),
        },
        startDate: moment()?.subtract(30, 'days')?.format('YYYY-MM-DD'),
        endDate: moment()?.format('YYYY-MM-DD'),
        evStatus: 'TAGGED',
        ...filteredValues,
        ...(!!previousSelection?.evListingPayload
            ? previousSelection?.evListingPayload
            : {}),
    });
    const onPressMobilize = async (row: any) => {
        if (!!row?.evId && !!row?.remoteControlEnabled) {
            setLoading(true);
            dispatchBlockUnblockEV(
                {
                    evId: row?.evId,
                    action: !!row?.evTelematicInfo?.mobiliStatus
                        ? 'mobilize'
                        : 'immobilize',
                },
                (resp: any) => {
                    setLoading(false);
                    showMessage(
                        resp?.status?.message,
                        resp?.status?.code == 200 ? 'success' : 'error',
                    );
                    fetchEvVehiclesListData(evListingPayload);
                },
                () => {
                    setLoading(false);
                },
            );
        }
    };
    useEffect(() => {
        // Set the state from route state
        if (props?.location?.state?.selectedFilter) {
            setRouteState(props?.location?.state?.selectedFilter);
            /*let newState = {
                ...props?.location?.state,
            };*/
            //delete newState?.['selectedFilter'];
            //props?.history?.push(`/ev-sp`, newState);
        } else {
        }
    }, [props?.location.state]);
    const {
        getCardFilterView,
        StyledTableHeadCell,
        StyledTableCell,
        StyledTableRow,
        getColumnComponentValue,
        handleRowClick,
        handleConfirmYes,
        handleConfirmNo,
    } = useEVTable({
        filtered: filteredValues,
        history: props?.history,
        evListData: evListData,
        activeFilterCard,
        setActiveFilterCard,
        onPressMobilize,
        confirmModalOpen,
        setConfirmModalOpen,
        confirmData,
        setConfirmData,
        evListingPayload,
        pagination,
    });
    const fetchEvVehiclesListData = async (
        payload = {
            pageNo: 1,
            pageSize: 10,
            searchText: '',
            cityIds: [],
            captainsIds: [],
            projectIds: [],
            vendorIds: [],
            telematicIds: [],
        },
    ) => {
        await setLoading(true);
        dispatchGetEvVehiclesListData(
            payload,
            (resp: any) => {
                console.log(resp);
                setLoading(false);
            },
            (err: any) => {
                console.log(err);
                setLoading(false);
            },
        );
    };

    const startLiveDataUpdate = () => {
        const options = {
            forceNew: true,
            autoConnect: true,
            pingInterval: 6000,
            pingTimeout: 10000,
            // transports: ['websocket'],
            agent: false, // [2] Please don't set this to true
            query:
                'token=' +
                user?.config?.beeconWebSocketToken +
                // BEECON_TOKEN +
                '&deviceId=' +
                uuidv4(),
            // user?.config?.beeconOrgId,
        };
        // Initialize Socket connection
        socketRef.current = io(SOCKET_SERVER_URL, options);

        // Handle connection open
        socketRef.current.on('connect', () => {
            console.log('Socket connected!');
        });

        // Handle data updates from the server
        socketRef.current.on('updateData', (updatedData: any) => {});
        socketRef.current.on('vehicletrackingEvent', function (data: any) {
            if (data) {
                alert('vehicletrackingEvent' + JSON.stringify(data));
            }
        });
        socketRef.current.on('vehicleTelematicEvent', async (data: any) => {
            // console.log('vehicleTelematicEvent', data);
            if (data) {
                alert('vehicleTelematicEvent' + JSON.stringify(data));
            }
        });

        // Handle connection close
        socketRef.current.on('disconnect', () => {
            console.log('Socket disconnected!');
        });
    };
    useEffect(() => {
        setFirstRenderDone(true);
        /*if (Notification.permission !== 'denied') {
            Notification.requestPermission();
        }*/
        //startLiveDataUpdate();
        dispatchGetAllEVVendors({});
        dispatchGetAllEVTeams({});
        dispatchGetAllEVProjects({});
        dispatchGetAllEVCaptains({});
        dispatchGetCities({});
        dispatchGetAllEVTelematics({});
        // Cleanup on component unmount
        return () => {
            //socketRef.current?.disconnect();
        };
    }, []);
    useEffect(() => {
        fetchEvVehiclesListData(preProcessPayload(evListingPayload));
    }, [evListingPayload]);
    useEffect(() => {
        setEvListingPayload((prev: any) => {
            return {
                ...prev,
                pageNo: 1,
                filter: activeFilterCard,
            };
        });
        setPagination((prev: any) => {
            return {
                ...prev,
                pageNo: 0,
            };
        });
    }, [activeFilterCard]);
    const onFilterApplied = () => {
        setEvListingPayload((prev: any) => {
            return {
                ...prev,
                pageNo: 1,
                ...filteredValues,
            };
        });
        setPagination((prev: any) => {
            return {
                ...prev,
                pageNo: 0,
            };
        });
    };

    useEffect(() => {
        setPermissions(getPermissions('EV SP'));
        if (!permissions.read) {
            props.history.push(`/${getActiveInitRoute()}`);
        }
    }, [user?.configs]);
    const updateFilterValues = (keysToRemove: Array<any>, obj: Object) => {
        const updatedObject = removeKeysFromObject(obj, keysToRemove);
        setFilteredValue(updatedObject);
    };
    const prepareFilterOptions = () => {
        let sVendor = vendorList?.filter(
            (item: any) => item?.id == filteredValues?.vendorIds?.[0],
        )?.[0];
        let sCaptain = captainList?.filter(
            (item: any) => item?.id == filteredValues?.captainId,
        )?.[0];
        let sManager = managerList?.filter(
            (item: any) => item?.manager == filteredValues?.manager,
        )?.[0];
        let sTeam = teamList?.filter(
            (item: any) => item?.id == filteredValues?.teamId,
        )?.[0];
        let sProject = projectsList?.filter(
            (item: any) => item?.id == filteredValues?.projectIds?.[0],
        )?.[0];
        let sTelamatic = telematicsList?.filter(
            (item: any) => item?.id == filteredValues?.telematicIds?.[0],
        )?.[0];
        return [
            {
                type: FILTER_INPUT_TYPE.DATE_RANGE_START_END,
                label: 'Date',
                startDate: 'Start Date',
                endDate: 'End Date',
                name: 'evDate',
                deleteDisable: true,
                value: {
                    startDate: filteredValues?.startDate,
                    endDate: filteredValues?.endDate,
                },
                onStartDateChange: (e: any) => {
                    console.log('onStartDateChange', e);
                    setFilteredValue({
                        ...filteredValues,
                        //evDate: e,
                        startDate: moment(e).format('YYYY-MM-DD'),
                    });
                },
                onEndDateChange: (e: any) => {
                    console.log('onEndDateChange', e);
                    setFilteredValue({
                        ...filteredValues,
                        endDate: moment(e).format('YYYY-MM-DD'),
                    });
                },
                render: () => {
                    return `Date: ${filteredValues.startDate} to ${filteredValues.endDate}`;
                },
            },
            {
                type: FILTER_INPUT_TYPE.SELECT,
                label: 'EV Status',
                name: 'evStatus',
                deleteDisable: true,
                value: !!filteredValues?.evStatus?.length
                    ? EVVEHICLE_STATUS_LIST_V2?.filter(
                          (item) => item?.status == filteredValues?.evStatus,
                      )?.[0]
                    : null,
                options: EVVEHICLE_STATUS_LIST_V2?.map((item: any) => ({
                    label: item.label,
                    value: item.id,
                    ...item,
                })),
                onChange: (e: any) => {
                    if (!!e?.target?.value) {
                        setFilteredValue({
                            ...filteredValues,
                            evStatus: e?.target?.value?.value
                                ? EVVEHICLE_STATUS_LIST_V2?.filter(
                                      (item) =>
                                          item?.id == Number(e.target.value.id),
                                  )?.[0]?.status
                                : '',
                        });
                    } else {
                        updateFilterValues(['evStatus'], filteredValues);
                    }
                },
            },
            {
                type: FILTER_INPUT_TYPE.MULTISELECT,
                label: 'Cities',
                name: 'cityIds',
                value: filteredValues?.cityIds ?? [],
                options: cityList?.map(
                    (item: any) =>
                        ({
                            label: item?.name,
                            value: item?.id,
                        } ?? []),
                ),
                onChange: (e: any) => {
                    if (!!e?.target?.value) {
                        setFilteredValue({
                            ...filteredValues,
                            cityIds: e?.target?.value,
                        });
                    } else {
                        updateFilterValues(['cityIds'], filteredValues);
                    }
                },
            },
            {
                type: FILTER_INPUT_TYPE.SELECT,
                label: 'Business Unit',
                name: 'manager',
                value: !!filteredValues?.manager
                    ? filteredValues?.manager
                    : null,
                render: () => {
                    return `Business: ${filteredValues?.manager?.manager}`;
                },
                options: managerList?.map((item: any) => ({
                    label: item?.manager,
                    value: item?.manager,
                    ...item,
                })),
                onChange: (e: any) => {
                    if (!!e?.target?.value) {
                        setFilteredValue({
                            ...filteredValues,
                            captainsIds: e?.target?.value?.values ?? [],
                            manager: e?.target?.value ?? null,
                        });
                    } else {
                        updateFilterValues(
                            ['captainsIds', 'manager'],
                            filteredValues,
                        );
                    }
                },
            } /*,
            {
                type: FILTER_INPUT_TYPE.SELECT,
                label: 'Team',
                name: 'teamId',
                value: filteredValues?.teamId ? sTeam : null,
                render: () => {
                    return `Team: ${sTeam?.name}`;
                },
                options: teamList?.map((item: any) => ({
                    label: item?.name,
                    value: item?.id,
                    ...item,
                })),
                onChange: (e: any) => {
                    setFilteredValue({
                        ...filteredValues,
                        teamId: Number(e?.target?.value?.id) ?? null,
                    });
                },
            },*/,
            {
                type: FILTER_INPUT_TYPE.SELECT,
                label: 'Project',
                name: 'projectIds',
                value: filteredValues?.projectIds ? sProject : null,
                render: () => {
                    return `Project: ${sProject?.name}`;
                },
                options: projectsList?.map((item: any) => ({
                    label: item?.name,
                    value: item?.id,
                    ...item,
                })),
                onChange: (e: any) => {
                    if (!!e?.target?.value) {
                        setFilteredValue({
                            ...filteredValues,
                            projectIds: !!Number(e?.target?.value?.id)
                                ? [Number(e?.target?.value?.id)]
                                : [],
                        });
                    } else {
                        updateFilterValues(['projectIds'], filteredValues);
                    }
                },
            },
            {
                type: FILTER_INPUT_TYPE.SELECT,
                label: 'Vendor',
                name: 'vendorIds',
                value: filteredValues?.vendorIds ? sVendor : null,
                render: () => {
                    return `Vendor: ${sVendor?.name}`;
                },
                options: vendorList?.map((item: any) => ({
                    label: item?.name,
                    value: item?.id,
                    ...item,
                })),
                onChange: (e: any) => {
                    if (!!e?.target?.value) {
                        setFilteredValue({
                            ...filteredValues,
                            vendorIds: !!Number(e?.target?.value?.id)
                                ? [Number(e?.target?.value?.id)]
                                : [],
                        });
                    } else {
                        updateFilterValues(['vendorIds'], filteredValues);
                    }
                },
            },
            {
                type: FILTER_INPUT_TYPE.SELECT,
                label: 'Telematrics',
                name: 'telematicIds',
                value: !!filteredValues?.telematicIds?.length
                    ? sTelamatic
                    : null,
                render: () => {
                    return `Telematric: ${sTelamatic?.name}`;
                },
                options: telematicsList?.map((item: any) => ({
                    label: item?.name,
                    value: item?.id,
                    ...item,
                })),
                onChange: (e: any) => {
                    if (!!e?.target?.value) {
                        setFilteredValue({
                            ...filteredValues,
                            telematicIds: !!Number(e?.target?.value?.id)
                                ? [Number(e?.target?.value?.id)]
                                : [],
                        });
                    } else {
                        updateFilterValues(['telematicIds'], filteredValues);
                    }
                },
            },
        ];
    };
    const handleChangePage = (event: any, newPage: any) => {
        console.log('SETPAGE ', newPage);
        setPagination((prev: any) => {
            return {
                ...prev,
                pageNo: newPage,
            };
        });
        setEvListingPayload((prev: any) => {
            return {
                ...prev,
                pageNo: newPage + 1,
            };
        });
    };

    const handleChangeRowsPerPage = (event: any) => {
        setPagination((prev: any) => {
            return {
                ...prev,
                pageNo: 0,
                pageSize: +event.target.value,
            };
        });
        setEvListingPayload((prev: any) => {
            return {
                ...prev,
                pageNo: 1,
                pageSize: event.target.value,
            };
        });
    };
    React.useEffect(() => {
        if (firstRenderDone) {
            setEvListingPayload((prev: any) => {
                return {
                    ...prev,
                    pageNo: 1,
                    searchText: searchText,
                };
            });
            setPagination((prev: any) => {
                return {
                    ...prev,
                    pageNo: 0,
                };
            });
        }
    }, [searchText]);
    console.log('fdddddddddd filteredValues', filteredValues, managerList);
    return (
        <Spinner loading={loading}>
            <Paper
                style={{ padding: '1rem', paddingBottom: 0 }}
                className={classes.container}
                elevation={0}
            >
                <Box
                    display={'flex'}
                    flexDirection={'row'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                    mb={2}
                >
                    <Box
                        display={'flex'}
                        flexDirection={'row'}
                        alignItems={'center'}
                        flex={1}
                    >
                        <Searchbar
                            placeHolderText="Search by EV, Rider Name, Contact No , Chassis No."
                            // placeHolderStyles={{
                            //     color: '#979797',
                            // }}
                            style={{
                                width: 'calc(100vw - 77%)',
                            }}
                            dataSource={null}
                            backendSearchbar={true}
                            onBackendSearch={(searchText: any) => {
                                setPagination((prev: any) => {
                                    return {
                                        ...prev,
                                        pageNo: 0,
                                    };
                                });
                                setSearchText(searchText);
                            }}
                            value={searchText}
                            keys={['name', 'hubName']}
                            onFilter={async (filteredData: any) => {}}
                            onChange={(value: any) => {
                                console.log('blur', value);
                                setSearchText(value);
                            }}
                        />

                        <FilterModal
                            disabled={false}
                            filterOptions={prepareFilterOptions()}
                            filteredValues={filteredValues}
                            onFilterApplied={onFilterApplied}
                            onClose={null}
                            onRemoveAll={() => {
                                let tempfilteredValues = {
                                    ...filteredValues,
                                };
                                let tempEVListingPayload = {
                                    ...evListingPayload,
                                };
                                //const filterValues= prepareFilterOptions()?.filter((item)=>!item?.disableRemovable)
                                delete tempfilteredValues['captainsIds'];
                                delete tempEVListingPayload['captainsIds'];

                                delete tempfilteredValues['manager'];
                                delete tempEVListingPayload['manager'];

                                delete tempfilteredValues['cityIds'];
                                delete tempEVListingPayload['cityIds'];

                                delete tempfilteredValues['projectIds'];
                                delete tempEVListingPayload['projectIds'];

                                delete tempfilteredValues['vendorIds'];
                                delete tempEVListingPayload['vendorIds'];

                                delete tempfilteredValues['telematicIds'];
                                delete tempEVListingPayload['telematicIds'];
                                setFilteredValue(tempfilteredValues);
                                setEvListingPayload({
                                    ...tempEVListingPayload,
                                    ...tempfilteredValues,
                                });
                                setPagination((prev: any) => {
                                    return {
                                        ...prev,
                                        pageNo: 0,
                                    };
                                });
                            }}
                        />
                        <FilteredState
                            filterOptions={prepareFilterOptions()}
                            filteredValues={filteredValues}
                            onDelete={(filterKey: any) => {
                                let tempfilteredValues = {
                                    ...filteredValues,
                                };
                                delete tempfilteredValues?.[filterKey];
                                let tempEVListingPayload = {
                                    ...evListingPayload,
                                };
                                delete tempEVListingPayload?.[filterKey];
                                if (filterKey === 'evDate') {
                                    delete tempfilteredValues['startDate'];
                                    delete tempfilteredValues['endDate'];
                                    delete tempEVListingPayload['startDate'];
                                    delete tempEVListingPayload['endDate'];
                                }
                                if (filterKey === 'manager') {
                                    delete tempfilteredValues['captainsIds'];
                                    delete tempEVListingPayload['captainsIds'];
                                }
                                setFilteredValue(tempfilteredValues);
                                setEvListingPayload({
                                    ...tempEVListingPayload,
                                    ...tempfilteredValues,
                                });
                                setPagination((prev: any) => {
                                    return {
                                        ...prev,
                                        pageNo: 0,
                                    };
                                });
                            }}
                            count={null}
                            selectInfo={null}
                            selected={null}
                            maxShow={4}
                        />
                    </Box>
                    <Box
                        display={'flex'}
                        flexDirection={'row'}
                        alignItems={'center'}
                    ></Box>
                </Box>
                <Box flexGrow={1} mb={5}>
                    <Grid container spacing={2}>
                        {EV_FILTER_CARDS?.map((card: any, index: number) =>
                            getCardFilterView(card, EV_FILTER_CARDS?.length),
                        )}
                    </Grid>
                </Box>
                {evListData?.evLineItemList?.length ? (
                    <>
                        <TableContainer
                            component={Box}
                            className={classes.tableContainer}
                        >
                            <Table stickyHeader aria-label="Orders table">
                                <TableHead>
                                    <TableRow>
                                        {tableConfig?.columns?.map(
                                            (column: any, index: any) => {
                                                return (
                                                    <StyledTableHeadCell
                                                        key={`column${index}`}
                                                    >
                                                        {column?.name}
                                                    </StyledTableHeadCell>
                                                );
                                            },
                                        )}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {evListData?.evLineItemList?.map(
                                        (row: any, rowIndex: number) => (
                                            <>
                                                <StyledTableRow
                                                    key={row.name}
                                                    onClick={(e: any) => {
                                                        handleRowClick(
                                                            e,
                                                            row,
                                                            rowIndex,
                                                        );
                                                    }}
                                                >
                                                    {tableConfig?.columns?.map(
                                                        (
                                                            column: any,
                                                            index: any,
                                                        ) => {
                                                            return (
                                                                <StyledTableCell
                                                                    key={`row${index}`}
                                                                >
                                                                    {getColumnComponentValue(
                                                                        row,
                                                                        column,
                                                                        null,
                                                                        rowIndex,
                                                                    )}
                                                                </StyledTableCell>
                                                            );
                                                        },
                                                    )}
                                                </StyledTableRow>
                                                <Divider
                                                    style={{
                                                        margin: '4px 0',
                                                        backgroundColor: '#fff',
                                                    }}
                                                />
                                            </>
                                        ),
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={
                                evListData?.totalCount > 25 ? [25, 50, 75] : []
                            }
                            component="div"
                            count={evListData?.totalCount}
                            rowsPerPage={pagination?.pageSize}
                            page={pagination?.pageNo}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    </>
                ) : (
                    <Box
                        display={'flex'}
                        flexDirection={'column'}
                        alignItems={'center'}
                        justifyContent={'center'}
                    >
                        <Typography
                            component={'p'}
                            style={{
                                color: '#ED0000',
                                fontWeight: 700,
                            }}
                        >
                            No data found. Please retry filters and try again.
                        </Typography>
                        <Typography
                            component={'p'}
                            style={{
                                color: '#4D4AB5',
                                fontWeight: 700,
                            }}
                        >
                            (or)
                        </Typography>
                        <Typography
                            component={'p'}
                            style={{
                                color: '#4CAF50',
                                fontWeight: 700,
                            }}
                        >
                            Add EV to onboard your EVs
                        </Typography>
                    </Box>
                )}
            </Paper>
            {!!confirmModalOpen && !!confirmData && (
                <ConfirmModal
                    open={confirmModalOpen}
                    data={confirmData}
                    onClose={() => {
                        handleConfirmNo(confirmData);
                    }}
                    confirmationText={confirmData?.confirmText}
                    onAction={() => {
                        handleConfirmYes(confirmData);
                    }}
                />
            )}
        </Spinner>
    );
};

export default EVPage;
