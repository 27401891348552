import React from 'react';
import { useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import * as L from 'leaflet';
const MapResetButton = ({ text, onPress }) => {
    const map = useMap();

    React.useEffect(
        (onClick) => {
            const button = L.control({ position: 'topleft' });

            button.onAdd = () => {
                const div = L.DomUtil.create('div', 'custom-button');
                div.innerHTML = `<button>&#x21BB;</button>`;

                div.addEventListener('click', () => {
                    !!onPress && onPress();
                });

                return div;
            };

            button.addTo(map);

            return () => {
                map.removeControl(button);
            };
        },
        [map],
    );

    return null;
};

export default MapResetButton;
