import React, { useState, useEffect } from 'react';
import {
    Box,
    Button,
    IconButton,
    MenuItem,
    withStyles,
} from '@material-ui/core';
import { connect } from 'react-redux';
import {
    generatePayslip,
    savePayslip,
} from '../../store/actions/staffActionCreator';

import Modal, { FixedModal } from './Modal';
import { checkHttpStatus, showMessage } from '../../utils/helper';
import { pdfjs, Document, Page } from 'react-pdf';
import pdfFile from '../../samplePDF.pdf';
import invoicePdfFile from '../../sampleInvoicePDF.pdf';
import './styles.css';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

// const useStyles = makeStyles((theme) => ({
//     modal: {},
// }));

function PreviewPayslipModal({
    open,
    onClose,
    isEdit = false,
    data = {},
    dispatchGeneratePayslip,
    dispatchSavePayslip,
    getAllStaffPayslip,
    staffInfo,
    staff,
    payload,
    ...props
}) {
    // const classes = useStyles();
    const [isOpen, toggleModal] = useState(open);
    const [isloading, toggleloading] = useState(false);
    const [datePickerOpen, setDatePickerOpen] = useState(false);
    const handleClose = () => {
        onClose && onClose();
    };

    const actions = [
        <Button
            key={1}
            variant="contained"
            color="default"
            className={`cancelButton`}
            onClick={handleClose}
            disableElevation
        >
            {'Cancel'}
        </Button>,
        <Button
            key={2}
            variant="contained"
            color="primary"
            className={`saveButton`}
            disableElevation
            onClick={async () => {
                // alert(`${isEdit ? 'Edit Clicked' : 'Add Clicked'}`);
                await toggleloading(true);
                await dispatchSavePayslip(
                    staffInfo.id,
                    payload,
                    (resp) => {
                        if (checkHttpStatus(resp?.status)) {
                            toggleloading(false);
                            showMessage(
                                `${
                                    staff?.singleStaffInfo?.freelancer
                                        ? 'Invoice'
                                        : 'Payslip'
                                } Generated Successfully`,
                                'success',
                            );
                            handleClose();
                        } else {
                            toggleloading(false);
                        }
                        console.log(resp);
                    },
                    (err) => {
                        toggleloading(false);
                        console.log(err);
                    },
                );
            }}
        >
            {'Save'}
        </Button>,
    ];

    const StyledMenuItem = withStyles((theme) => ({
        root: {
            margin: '0 8px',
            border: '0 !important',
            borderRadius: '8px',
            '&:hover': {
                backgroundColor: 'rgba(67, 65, 158, 0.08)',
                color: '#43419e',
                borderRadius: '8px',
            },
        },
    }))(MenuItem);

    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    // TODO: Remove hardcoded file and replace with S3 link
    return (
        <FixedModal
            onClose={handleClose}
            open={isOpen}
            title={`${
                staff?.singleStaffInfo?.freelancer ? 'Invoice' : 'Payslip'
            } Preview`}
            actions={actions}
            loading={isloading}
            payslipPreviewModal={true}
            stickyActions={true}
            maxWidth={'sm'}
        >
            <Document
                file={
                    payload?.s3Link
                    // 'https://earn-de-docs.s3.ap-south-1.amazonaws.com/PaySlip_Sample_20211018.pdf'
                }
                // file={invoicePdfFile}
                onLoadSuccess={onDocumentLoadSuccess}
            >
                {Array.from(new Array(numPages), (el, index) => (
                    <Page
                        key={`page_${index + 1}`}
                        pageNumber={index + 1}
                        // width={size.width ? size.width : 1}
                    />
                ))}
                {/* {[1, 2].map((page) => (
                    <Page pageNumber={page} />
                ))} */}
            </Document>
        </FixedModal>
    );
}

const mapStateToProps = (state) => ({
    staff: state.staff,
});

const mapDispatchToProps = (dispatch) => ({
    dispatchGeneratePayslip: (id, data, onSuccess, onError) =>
        dispatch(generatePayslip(id, data, onSuccess, onError)),
    dispatchSavePayslip: (id, data, onSuccess, onError) =>
        dispatch(savePayslip(id, data, onSuccess, onError)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(PreviewPayslipModal);
