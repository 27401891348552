import {
    Box,
    Button,
    FormControlLabel,
    FormGroup,
    MenuItem,
    Radio,
    Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Modal from './Modal';
import { CustomTextField } from '../Textfield';
import { connect } from 'react-redux';
import { updateLeadStatus } from '../../store/actions/leadActionCreator';

function OnHoldHireModal({
    leadInfo,
    open,
    onClose,
    defaultValue = {},
    nonServiceableHireList,
    dispatchUpdateLeadStatus,
    ...props
}) {
    const [isOpen, toggleModal] = useState(open);
    const [loading, toggleLoading] = useState(false);
    const [nonServiceableDocList, setNonServiceableDocList] = useState([]);
    const [nonServiceabilityItem , setNonServiceabilityItem] = useState();

    const handleClose = (values) => {
        toggleModal(!isOpen);
        onClose && onClose(values);
    };

    const markOnHold = () => {
        toggleLoading(true);
        const payload = {
            status: 'On Hold',
            comments: '',
            nonServiceabilityItem,
        };
        // console.log(payload);
        dispatchUpdateLeadStatus(
            leadInfo.id,
            payload,
            (resp) => {
                toggleLoading(false);
                handleClose('');
            },
            (err) => {
                toggleLoading(false);
                handleClose('');
            },
        );
    };

    useEffect(() => {
        setNonServiceableDocList(nonServiceableHireList?.split(','));
    }, [nonServiceableHireList]);

    const isButtonDisbled = nonServiceabilityItem;
    const actions = [
        <Button
            key={1}
            variant="contained"
            color="default"
            className={`cancelButton`}
            onClick={() => handleClose(defaultValue)}
            style={{
                boxShadow: 'none',
            }}
            disableElevation
        >
            Cancel
        </Button>,
        <Button
            key={2}
            variant="contained"
            color="primary"
            className={`saveButton`}
            type="submit"
            onClick={markOnHold}
            style={{
                boxShadow: 'none',
            }}
            disabled={
                !isButtonDisbled?.length || isButtonDisbled.includes(false)
            }
            disableElevation
        >
            On Hold
        </Button>,
    ];
    return (
        <Modal
            onClose={() => handleClose(defaultValue)}
            open={isOpen}
            title={`On Hold`}
            actions={actions}
            loading={loading}
            isForm={false}
        >
            <Typography
                style={{
                    fontSize: '18px',
                    fontWeight: 600,
                }}
            >
                Select Non Serviceable Items
            </Typography>
            <FormGroup>
                {[...nonServiceableDocList]?.map((item) => (
                    <>
                        <FormControlLabel
                            control={ 
                                <Radio
                                    name={item}
                                    checked={nonServiceabilityItem === item}
                                    onChange={(event) => {
                                        setNonServiceabilityItem(item);
                                    }}
                                    color="primary"
                                />
                            }
                            label={item}
                        />
                    </>
                ))}
            </FormGroup>
        </Modal>
    );
}

const mapStateToProps = (state) => ({
    nonServiceableHireList: state?.user?.config?.nonServiceableList,
});
const mapDispatchToProps = (dispatch) => ({
    dispatchUpdateLeadStatus: (id, data, onSuccess, OnFailed) =>
        dispatch(updateLeadStatus(id, data, onSuccess, OnFailed)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(OnHoldHireModal);
