import React, { useState, useEffect } from 'react';
import { Button } from '@material-ui/core';
import Modal from '../Modal';
import { FileUploader } from '../../UI';
import { connect } from 'react-redux';
import {
    uploadPaymentIndents,
    setSelectedPayments,
    getAllPaymentIndentsListingData,
} from '../../../store/actions/authActionCreator';
import {
    showMessage,
    replaceTextWithCapsAndUnderscore,
} from '../../../utils/helper';

// const useStyles = makeStyles((theme) => ({
//     modal: {},
// }));

function UploadPaymentModal({
    open,
    onClose,
    enumType = 'payment',
    data = {},
    dispatchUploadPaymentIndents,
    source = 'staffPay',
    user,
    dispatchGetAllPaymentIndentsListingData,
    dispatchSetSelectedPaymentData,
    ...props
}) {
    // const classes = useStyles();
    const [isOpen, toggleModal] = useState(open);
    const [loading, toggleLoading] = useState(false);
    const handleClose = () => {
        // Setting form data to null before closing
        setState(null);

        toggleModal(!isOpen);
        onClose && onClose();
    };
    const [state, setState] = useState({});

    const actions = [
        <Button
            key={1}
            variant="contained"
            color="default"
            className={`cancelButton`}
            onClick={handleClose}
            disableElevation
        >
            {'Cancel'}
        </Button>,
        <Button
            key={2}
            variant="contained"
            color="primary"
            className={`saveButton`}
            disabled={!state?.s3FileUrl}
            disableElevation
            onClick={async () => {
                if (!state?.s3FileUrl) {
                    showMessage('Please upload file', 'error');
                    return false;
                }
                if (!state?.s3FileUrl?.endsWith('.xlsx')) {
                    showMessage('Invalid Format', 'error');
                    return false;
                }
                toggleLoading(true);
                // alert(`${isEdit ? 'Edit Clicked' : 'Add Clicked'}`);
                dispatchUploadPaymentIndents(
                    { fileName: state?.s3FileUrl },
                    (response) => {
                        console.log(
                            'dispatchUploadPaymentIndents SUCCESSs',
                            response,
                        );

                        toggleLoading(false);
                        console.log('s3FileUrl response', response);
                        if (response?.response?.s3Url) {
                            window.location.href = response?.response?.s3Url;
                        }
                        let selectedIgnoreDataFilters = Object.entries(
                            user?.zappay?.filterGroupModalConfig?.filters,
                        )?.filter((selectedFilter) =>
                            user?.zappay?.sourceSystemFilterListData
                                ?.filter(
                                    (item) => item?.ignoreDateFilter == true,
                                )
                                ?.map((selected) => selected?.key)
                                ?.includes(selectedFilter[0]),
                        );

                        let listingRequestPayload =
                            selectedIgnoreDataFilters?.length > 0
                                ? {
                                      filters:
                                          user?.zappay?.filterGroupModalConfig
                                              ?.filters ?? {},
                                      sourceSystemId:
                                          user?.zappay?.filterGroupModalConfig
                                              ?.sourceSystem?.id,
                                      paymentStatus:
                                          'ALL' ==
                                          user?.zappay?.filterGroupModalConfig?.paymentStatus.toUpperCase()
                                              ? null
                                              : replaceTextWithCapsAndUnderscore(
                                                    user?.zappay
                                                        ?.filterGroupModalConfig
                                                        ?.paymentStatus,
                                                ),
                                  }
                                : {
                                      dateRange:
                                          user?.zappay?.filterGroupModalConfig
                                              ?.dateRange,
                                      filters:
                                          user?.zappay?.filterGroupModalConfig
                                              ?.filters ?? {},
                                      sourceSystemId:
                                          user?.zappay?.filterGroupModalConfig
                                              ?.sourceSystem?.id,
                                      paymentStatus:
                                          'ALL' ==
                                          user?.zappay?.filterGroupModalConfig?.paymentStatus.toUpperCase()
                                              ? null
                                              : replaceTextWithCapsAndUnderscore(
                                                    user?.zappay
                                                        ?.filterGroupModalConfig
                                                        ?.paymentStatus,
                                                ),
                                  };
                        // dispatchSetSelectedPaymentData([]);
                        dispatchGetAllPaymentIndentsListingData(
                            listingRequestPayload ?? {},
                            (resp) => {
                                toggleLoading(false);
                            },
                            () => {
                                toggleLoading(false);
                            },
                        );

                        handleClose();
                    },
                    (resp) => {
                        console.log(
                            'dispatchUploadPaymentIndents FAILED',
                            resp,
                        );
                        toggleLoading(false);
                        handleClose();
                    },
                );
            }}
        >
            {source == 'zappay'
                ? 'Upload Payment'
                : enumType === 'payment'
                ? 'Upload Payment'
                : ''}
        </Button>,
    ];

    const handleInputChange = (value, key) => {
        setState((state) => ({
            ...state,
            [key]: value,
        }));
    };

    return (
        <Modal
            onClose={handleClose}
            open={isOpen}
            title={
                source == 'zappay'
                    ? 'Upload Payment'
                    : enumType === 'payment'
                    ? 'Upload Payment'
                    : ''
            }
            subtitle={
                source == 'zappay'
                    ? 'Upload Payment File'
                    : enumType === 'payment'
                    ? 'Upload Payments of your staff'
                    : ''
            }
            actions={actions}
            loading={loading}
            containerStyle={{
                width: '100%',
            }}
        >
            <FileUploader
                label={'Upload file'}
                placeholder={'Upload file'}
                onFileUploaded={(url) => {
                    console.log(url);
                    handleInputChange(url, 's3FileUrl');
                }}
                isRequired={true}
            />
        </Modal>
    );
}

const mapStateToProps = (state) => ({
    user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
    dispatchUploadPaymentIndents: (data, onSuccess, onError) =>
        dispatch(uploadPaymentIndents(data, onSuccess, onError)),

    dispatchGetAllPaymentIndentsListingData: (data, onSuccess, onError) =>
        dispatch(getAllPaymentIndentsListingData(data, onSuccess, onError)),

    dispatchSetSelectedPaymentData: (data) =>
        dispatch(setSelectedPayments(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadPaymentModal);
