import {
    Box,
    Button,
    Card,
    Grid,
    makeStyles,
    Paper,
    Typography,
    Divider,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    TablePagination,
    Toolbar,
    Checkbox,
    IconButton,
    FormControlLabel,
    Switch,
    List,
    ListItem,
    ListItemSecondaryAction,
    Chip,
    Tooltip,
} from '@material-ui/core';
import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { Spinner } from '../../components/UI';
import {
    fetchAppConfig,
    getAllContactsListingData,
} from '../../store/actions/authActionCreator';
import Searchbar from '../../components/Searchbar';
import zappayEmptyState from '../../assets/images/zappayEmptyState.png';

const useStyles = makeStyles((theme) => ({
    container: {
        '& .leaflet-container': {
            width: '100%',
            height: '85vh',
            flexGrow: 1,
            // borderRadius: 16,
            borderTopRightRadius: 16,
            borderBottomRightRadius: 16,
        },
    },
    card: {
        backgroundColor: '#f6f7f9',
        marginTop: 16,
        color: '#000',
        padding: 16,
    },
    listItem: {
        display: 'flex',
        flexDirection: 'column',
        flexDirection: 'column',
        alignItems: 'stretch',
        padding: '8px 16px',
        marginTop: '8px',
        color: '#000',
        backgroundColor: '#f6f7f9',
        listStyleType: 'none !important',
        ul: {
            listStyleType: 'none',
        },
    },
    horizontalCardContents: {
        // maxWidth: '25%',
        display: 'flex',
        flexDirection: 'row',
        margin: '16px 0',
        flexWrap: 'wrap',
    },
    verticalCardContents: {
        flex: '0 1 25%',
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        justifyContent: 'center',
        border: 'solid 1px #ebedf8',
        borderRadius: '8px',
        backgroundColor: '#d1d8ff',
        paddingLeft: '16px',
        height: '88px',
        margin: '0 8px 0 0 ',
    },
    cardSubHeader: {
        fontSize: '22px',
        fontWeight: 700,
    },
    cardHeader: {
        fontSize: '16px',
        fontWeight: 600,
    },
}));

function Contacts({
    configs,
    user,
    dispatchFetchAppConfig,
    dispatchGetAllContactsListingData,
    ...props
}) {
    const classes = useStyles();

    const [loading, setLoading] = useState(true);

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);
    const [searchedValue, setSearchedValue] = useState('');

    const [headCells, setHeadCells] = useState([
        {
            id: 'beneficiaryId',
            numeric: true,
            disablePadding: false,
            label: 'Beneficiary ID',
        },
        {
            id: 'accountName',
            numeric: true,
            disablePadding: false,
            label: 'Account Name',
        },
        {
            id: 'ifsc',
            numeric: true,
            disablePadding: false,
            label: 'IFSC Code',
        },
        {
            id: 'accountNumber',
            numeric: true,
            disablePadding: false,
            label: 'Account Number',
        },
        {
            id: 'bankName',
            numeric: true,
            disablePadding: false,
            label: 'Bank Name',
        },
    ]);

    const [contactsListing, setContactsListing] = useState(
        user?.zappay?.beneficiariesListingData?.lineItems,
    );

    useEffect(() => {
        setLoading(true);
        dispatchFetchAppConfig((resp) => {
            if (resp?.status?.code == 200) {
                dispatchGetAllContactsListingData(
                    {},
                    (resp) => {
                        console.log(
                            'dispatchGetAllContactsListingData success',
                            resp,
                        );
                    },
                    (err) => {
                        console.log(
                            'dispatchGetAllContactsListingData failed',
                            err,
                        );
                    },
                );
            }
            setLoading(false);
        });
    }, []);

    useEffect(() => {
        setContactsListing(user?.zappay?.beneficiariesListingData?.lineItems);
    }, [user?.zappay?.beneficiariesListingData]);

    useEffect(() => {
        if (searchedValue?.length) {
            setContactsListing(
                user?.zappay?.beneficiariesListingData?.lineItems?.filter(
                    (item) =>
                        item?.accountName
                            .toLowerCase()
                            .includes(searchedValue?.toLowerCase()),
                ),
            );
        } else {
            setContactsListing(
                user?.zappay?.beneficiariesListingData?.lineItems,
            );
        }
        setPage(0);
    }, [searchedValue]);

    const handleChangePage = (event, newPage) => {
        console.log('Inside handleChangePage');
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        console.log('Inside handleChangeRowsPerPage');
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <Spinner loading={loading}>
            {true ? (
                <Paper style={{ padding: '12px' }} elevation={0}>
                    <Box
                        style={{
                            margin: '4px 0',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Box
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                            }}
                        >
                            <Box
                                style={{
                                    width: '400px',
                                    display: 'flex',
                                    flexDirection: 'row',
                                }}
                            >
                                <Searchbar
                                    placeHolderText="Search by Account Name"
                                    value={searchedValue}
                                    keys={['name', 'hubName']}
                                    onFilter={async (filteredData) => {}}
                                    onChange={(value) => {
                                        console.log('blur', value);
                                        setSearchedValue(value);
                                    }}
                                />
                            </Box>
                        </Box>
                    </Box>

                    <Box className={classes.horizontalCardContents}>
                        <Box className={classes.verticalCardContents}>
                            <Typography className={classes.cardHeader}>
                                Total Contacts
                            </Typography>
                            <Typography className={classes.cardSubHeader}>
                                {user?.zappay?.beneficiariesListingData
                                    ?.lineItems?.length ?? 0}
                            </Typography>
                        </Box>
                        {/* <Box className={classes.verticalCardContents}>
                            <Typography className={classes.cardHeader}>
                                No. of Payments
                            </Typography>
                            <Typography className={classes.cardSubHeader}>
                                {payments?.noOfPayments ?? 0}
                            </Typography>
                        </Box> */}
                    </Box>

                    {!!contactsListing?.length ? (
                        <>
                            <TableContainer
                                style={{
                                    maxHeight: '30rem',
                                }}
                            >
                                <Table
                                    className={classes.table}
                                    aria-labelledby="tableTitle"
                                    size={'medium'}
                                    aria-label="enhanced table"
                                    stickyHeader
                                >
                                    {!!headCells?.length && (
                                        <TableHead>
                                            <TableRow>
                                                {headCells?.map((headCell) => (
                                                    <TableCell
                                                        key={headCell.id}
                                                        align={'left'}
                                                    >
                                                        {headCell.label}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                    )}
                                    {!!contactsListing?.length && (
                                        <TableBody>
                                            {contactsListing
                                                ?.slice(
                                                    page * rowsPerPage,
                                                    page * rowsPerPage +
                                                        rowsPerPage,
                                                )
                                                ?.map((row, index) => {
                                                    // const isItemSelected = isSelected(row.id);
                                                    return (
                                                        <TableRow
                                                            hover
                                                            // role="checkbox"
                                                            // aria-checked={isItemSelected}
                                                            tabIndex={-1}
                                                            key={index}
                                                            // selected={isItemSelected}
                                                        >
                                                            <TableCell
                                                                key={`${row?.id}-0`}
                                                                align="left"
                                                                // padding={'none'}
                                                            >
                                                                <Typography
                                                                    style={{
                                                                        minWidth:
                                                                            '80px',
                                                                    }}
                                                                >
                                                                    {
                                                                        row?.brokerBeneficiaryId
                                                                    }
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell
                                                                key={`${row?.id}-1`}
                                                                align="left"
                                                                // padding={'none'}
                                                            >
                                                                <Typography
                                                                    style={{
                                                                        minWidth:
                                                                            '150px',
                                                                    }}
                                                                >
                                                                    {
                                                                        row?.accountName
                                                                    }
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell
                                                                key={`${row?.id}-2`}
                                                                align="left"
                                                                // padding={'none'}
                                                            >
                                                                <Typography
                                                                    style={{
                                                                        minWidth:
                                                                            '150px',
                                                                    }}
                                                                >
                                                                    {
                                                                        row?.ifscCode
                                                                    }
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell
                                                                key={`${row?.id}-3`}
                                                                align="left"
                                                                // padding={'none'}
                                                            >
                                                                <Typography
                                                                    style={{
                                                                        minWidth:
                                                                            '150px',
                                                                    }}
                                                                >
                                                                    {
                                                                        row?.accountNumber
                                                                    }
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell
                                                                key={`${row?.id}-4`}
                                                                align="left"
                                                                // padding={'none'}
                                                            >
                                                                <Typography
                                                                    style={{
                                                                        minWidth:
                                                                            '150px',
                                                                    }}
                                                                >
                                                                    {
                                                                        row?.bankName
                                                                    }
                                                                </Typography>
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                        </TableBody>
                                    )}
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[25, 50, 100]}
                                component="div"
                                count={contactsListing?.length ?? 0}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                        </>
                    ) : (
                        <>
                            <Box
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    marginTop: '40px',
                                }}
                            >
                                <img
                                    src={zappayEmptyState}
                                    style={{
                                        margin: '0 auto auto auto',
                                        height: '350px',
                                        width: '100%',
                                        borderRadius: '8px',
                                        objectFit: 'contain',
                                    }}
                                />
                                <Typography
                                    style={{
                                        margin: '16px 0',
                                        fontSize: 20,
                                        fontWeight: 600,
                                    }}
                                >
                                    No Results Found
                                </Typography>
                            </Box>
                        </>
                    )}
                </Paper>
            ) : (
                !loading && (
                    <Box
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    ></Box>
                )
            )}
        </Spinner>
    );
}

const mapStateToProps = (state) => ({
    user: state?.user,
});

const mapDispatchToProps = (dispatch) => ({
    dispatchFetchAppConfig: (onSuccess, onError) =>
        dispatch(fetchAppConfig(onSuccess, onError)),

    dispatchGetAllContactsListingData: (data, onSuccess, onError) =>
        dispatch(getAllContactsListingData(data, onSuccess, onError)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Contacts);
