import React from 'react';
import './styles.scss';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    TablePagination,
    List,
    ListItem,
    ListItemSecondaryAction,
    Box,
    Typography,
    Tooltip,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import CustomDropdown from '../../Dropdown';
import {
    deletePayslip,
    approvePayslip,
    autoSignPayslip,
} from '../../../store/actions/staffActionCreator';
import {
    formatCurrency,
    getPermissions,
    showMessage,
    checkHttpStatus,
} from '../../../utils/helper';
import DeleteModal from '../../Modals/DeleteModal';
import ConfirmModal from '../../Modals/ConfirmModal';
import PDFViewerModal from '../../Modals/PdfViewerModal';

const useStyles = makeStyles((theme) => ({
    listItem: {
        padding: '8px 16px 8px 16px !important',
        marginBottom: '8px !important',
        border: '1px solid #ebedf8 !important',
        borderRadius: '8px !important',
        // height: '56px !important',÷
    },
    listItemHeader: {
        border: '0px !important',
        marginBottom: '8px !important',
        color: '#525050 !important',
        fontWeight: 'bold !important',
    },
}));

function ListItemLink(props) {
    return <ListItem button component="a" {...props} />;
}

const AllPayslipListContainer = ({
    data,
    searchVal,
    pageNo,
    onPageChange,
    rowsPerPageNo,
    onRowsPerPageNoChange,
    permissions,
    staffInfo,
    getAllStaffPayslip,
    deleteStaffPaysliip,
    approveStaffPaysliip,
    autoSignStaffPaysliip,
    staff,
    ...props
}) => {
    const classes = useStyles();

    const [page, setPage] = React.useState(pageNo);
    const [rowsPerPage, setRowsPerPage] = React.useState(rowsPerPageNo);
    const [selectedItemData, setSelectedItemData] = React.useState({});
    const [viewModalOpen, setViewModalOpen] = React.useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = React.useState(false);
    const [autoSignModalOpen, setAutoSignModalOpen] = React.useState(false);
    const [approveModalOpen, setApproveModalOpen] = React.useState(false);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        onPageChange(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        onRowsPerPageNoChange(+event.target.value);
        if (+event.target.value * page < data?.length) {
            setPage(pageNo);
            onPageChange(pageNo);
        } else {
            setPage(0);
            onPageChange(0);
        }
    };

    React.useEffect(() => {
        setPage(pageNo);
    }, [data]);

    return (
        <Box className="payslip-list">
            <List component="nav">
                {/* <ListItem className={classes.listItemHeader}>
                    <Box
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '100%',
                        }}
                    >
                        <Box style={{ width: '40%' }}>DATE RANGE</Box>
                        <Box style={{ width: '18%' }}>STATUS</Box>
                        <Box style={{ width: '20%', textAlign: 'right' }}>
                            PAYMENTS
                        </Box>
                        <Box style={{ width: '12.5%' }}></Box>
                    </Box>
                
                
                </ListItem>
                 */}
                <Box>
                    {data?.length
                        ? data
                              .slice(
                                  page * rowsPerPage,
                                  page * rowsPerPage + rowsPerPage,
                              )
                              .map((val, index) => {
                                  return (
                                      <ListItemLink
                                          // button
                                          className={`${classes.listItem} payslip-list`}
                                          key={val.id}
                                          style={
                                              index % 2
                                                  ? {
                                                        backgroundColor:
                                                            'rgba(241, 243, 246, 1)',
                                                    }
                                                  : {}
                                          }
                                      >
                                          <Box
                                              style={{
                                                  width: '22.5%',
                                              }}
                                              // className={classes.tableCell}
                                          >
                                              <Typography
                                                  style={{
                                                      wordBreak: 'break-all',
                                                      fontWeight: 600,
                                                  }}
                                              >
                                                  {'#' +
                                                      val?.id
                                                          .toString()
                                                          .padStart(6, '0') ??
                                                      ''}
                                              </Typography>
                                              <Box
                                                  style={{
                                                      display: 'flex',
                                                      flexDirection: 'row',
                                                      flexWrap: 'wrap',
                                                      alignItems: 'center',
                                                  }}
                                              >
                                                  <Typography
                                                      style={{
                                                          fontSize: '12px',
                                                          marginRight: '4px',
                                                      }}
                                                  >
                                                      {val?.description ?? '-'}
                                                  </Typography>
                                              </Box>
                                          </Box>
                                          <Box
                                              style={{
                                                  width: '17.5%',
                                              }}
                                              // className={classes.tableCell}
                                          >
                                              <Typography
                                                  style={{
                                                      wordBreak: 'break-all',
                                                      fontWeight: 600,
                                                  }}
                                              >
                                                  {val?.dateRangeText ?? '-'}
                                              </Typography>
                                          </Box>
                                          <Box style={{ width: '22%' }}>
                                              {val?.displayStatus && (
                                                  <Typography
                                                      className={`chip ${val?.status
                                                          ?.toLowerCase()
                                                          ?.replace(' ', '_')}`}
                                                  >
                                                      {val?.displayStatus ??
                                                          '-'}
                                                  </Typography>
                                              )}
                                          </Box>
                                          <Box
                                              style={{
                                                  width: '16%',
                                                  textAlign: 'right',
                                              }}
                                          >
                                              <Typography>
                                                  {formatCurrency(val.amount) ??
                                                      '-'}
                                              </Typography>
                                          </Box>
                                          <ListItemSecondaryAction>
                                              {/* <div
                                                    style={{
                                                        width: '12.5%',
                                                    }}
                                                    // className={classes.tableCell}
                                                > */}

                                              <Box
                                                  style={{
                                                      display: 'flex',
                                                      justifyContent:
                                                          'flex-end',
                                                  }}
                                              >
                                                  <CustomDropdown
                                                      title={'Options'}
                                                      options={[
                                                          {
                                                              item: 'View',
                                                              onSuccess: () => {
                                                                  setSelectedItemData(
                                                                      val,
                                                                  );
                                                                  setViewModalOpen(
                                                                      true,
                                                                  );
                                                              },
                                                              disabled:
                                                                  !permissions?.read,
                                                          },
                                                          {
                                                              item: 'Auto Sign',
                                                              onSuccess: () => {
                                                                  setSelectedItemData(
                                                                      val,
                                                                  );
                                                                  setAutoSignModalOpen(
                                                                      true,
                                                                  );
                                                              },
                                                              disabled:
                                                                  !staff
                                                                      ?.singleStaffInfo
                                                                      ?.freelancer ||
                                                                  !val?.status?.includes(
                                                                      'PENDING_SIGNATURE',
                                                                  ) ||
                                                                  !permissions?.read,
                                                          },
                                                          {
                                                              item: 'Approve',
                                                              onSuccess: () => {
                                                                  setSelectedItemData(
                                                                      val,
                                                                  );
                                                                  setApproveModalOpen(
                                                                      true,
                                                                  );
                                                              },
                                                              disabled:
                                                                  !val?.status?.includes(
                                                                      'PENDING_APPROVAL',
                                                                  ) ||
                                                                  !permissions?.read,
                                                          },
                                                          {
                                                              item: 'Delete',
                                                              onSuccess: () => {
                                                                  setSelectedItemData(
                                                                      val,
                                                                  );
                                                                  setDeleteModalOpen(
                                                                      true,
                                                                  );
                                                              },
                                                              disabled:
                                                                  !permissions?.delete,
                                                          },
                                                      ]}
                                                  />
                                              </Box>
                                          </ListItemSecondaryAction>
                                      </ListItemLink>
                                  );
                              })
                        : null}
                </Box>
            </List>

            {!!viewModalOpen && (
                <PDFViewerModal
                    open={viewModalOpen}
                    data={{ ...selectedItemData }}
                    onClose={() => {
                        setViewModalOpen(false);
                        getAllStaffPayslip();
                        setSelectedItemData({});
                    }}
                />
            )}
            {!!deleteModalOpen && (
                <DeleteModal
                    open={deleteModalOpen}
                    data={{ ...selectedItemData }}
                    onClose={() => {
                        setDeleteModalOpen(false);
                        getAllStaffPayslip();
                        setSelectedItemData({});
                    }}
                    deleteType={`${
                        staff?.singleStaffInfo?.freelancer
                            ? 'Invoice'
                            : 'Payslip'
                    }`}
                    onDeletion={async (toggleLoading) => {
                        deleteStaffPaysliip(
                            staffInfo.id,
                            selectedItemData.id,
                            (resp) => {
                                toggleLoading(false);
                                if (checkHttpStatus(resp.status)) {
                                    showMessage(
                                        `${
                                            staff?.singleStaffInfo?.freelancer
                                                ? 'Invoice'
                                                : 'Payslip'
                                        } Deleted.`,
                                        'success',
                                    );
                                    setDeleteModalOpen(false);
                                    getAllStaffPayslip();
                                    setSelectedItemData({});
                                }
                            },
                            (err) => {
                                toggleLoading(false);
                            },
                        );
                    }}
                />
            )}
            {!!autoSignModalOpen && (
                <ConfirmModal
                    open={autoSignModalOpen}
                    data={{ ...selectedItemData }}
                    onClose={() => {
                        setAutoSignModalOpen(false);
                        getAllStaffPayslip();
                        setSelectedItemData({});
                    }}
                    confirmationText={`sign this ${
                        staff?.singleStaffInfo?.freelancer
                            ? 'Invoice'
                            : 'Payslip'
                    }`}
                    onAction={async (toggleLoading) => {
                        autoSignStaffPaysliip(
                            staffInfo.id,
                            selectedItemData.id,
                            (resp) => {
                                toggleLoading(false);
                                if (checkHttpStatus(resp.status)) {
                                    showMessage(
                                        `${
                                            staff?.singleStaffInfo?.freelancer
                                                ? 'Invoice'
                                                : 'Payslip'
                                        } Signed.`,
                                        'success',
                                    );
                                    setAutoSignModalOpen(false);
                                    getAllStaffPayslip();
                                    setSelectedItemData({});
                                }
                            },
                            (err) => {
                                toggleLoading(false);
                            },
                        );
                    }}
                />
            )}
            {!!approveModalOpen && (
                <ConfirmModal
                    open={approveModalOpen}
                    data={{ ...selectedItemData }}
                    onClose={() => {
                        setApproveModalOpen(false);
                        getAllStaffPayslip();
                        setSelectedItemData({});
                    }}
                    confirmationText={`approve this ${
                        staff?.singleStaffInfo?.freelancer
                            ? 'Invoice'
                            : 'Payslip'
                    }`}
                    onAction={async (toggleLoading, data) => {
                        approveStaffPaysliip(
                            staffInfo.id,
                            {
                                payslipId: selectedItemData.id,
                                ...{ notes: '' },
                            },
                            (resp) => {
                                toggleLoading(false);
                                if (checkHttpStatus(resp.status)) {
                                    showMessage(
                                        `${
                                            staff?.singleStaffInfo?.freelancer
                                                ? 'Invoice'
                                                : 'Payslip'
                                        } Approved.`,
                                        'success',
                                    );
                                    setApproveModalOpen(false);
                                    getAllStaffPayslip();
                                    setSelectedItemData({});
                                }
                            },
                            (err) => {
                                toggleLoading(false);
                            },
                        );
                    }}
                />
            )}
            <TablePagination
                rowsPerPageOptions={[10, 50, 100]}
                component="div"
                count={data?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </Box>
    );
};

const mapStateToProps = (state) => ({
    staff: state.staff,
});

const mapDispatchToProps = (dispatch) => ({
    deleteStaffPaysliip: (id, payslipId, onSuccess, onError) =>
        dispatch(deletePayslip(id, payslipId, onSuccess, onError)),
    approveStaffPaysliip: (id, data, onSuccess, onError) =>
        dispatch(approvePayslip(id, data, onSuccess, onError)),
    autoSignStaffPaysliip: (id, payslipId, onSuccess, onError) =>
        dispatch(autoSignPayslip(id, payslipId, onSuccess, onError)),
});

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(AllPayslipListContainer),
);
