import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
    Box,
    Button,
    Paper,
    Typography,
    IconButton,
    Avatar,
} from '@material-ui/core';
import Modal from './Modal';
import { CustomTextField } from '../Textfield';
import { CustomDatePicker, CustomTimePicker } from '../DateTimePicker';
import marker from '../../assets/images/marker.png';
import moment from 'moment';
import { dateTimeToMilliseconds } from '../../utils/helper';
import {
    approvePunchIn,
    approvePunchOut,
} from '../../store/actions/attendanceActionCreator';
import InsertInvitationIcon from '@material-ui/icons/InsertInvitation';
import NumberFormat from 'react-number-format';
import { LightBoxImage } from '../UI';

function ApprovePunchModal({
    open,
    onClose,
    enumType = 'in',
    data = {},
    dispatchApprovePunchIn,
    dispatchApprovePunchOut,
    ...props
}) {
    const [isOpen, toggleModal] = useState(open);
    const [isloading, toggleLoading] = useState(false);
    const [state, setState] = useState({
        selectDate:
            enumType === 'in'
                ? data?.punchIn?.dateEpochMillis
                    ? moment(data?.punchIn?.dateEpochMillis)
                    : moment()
                : data?.punchOut?.dateEpochMillis
                ? moment(data?.punchOut?.dateEpochMillis)
                : moment(),
        selectTime:
            enumType === 'in'
                ? data?.punchIn?.dateEpochMillis
                    ? moment(data?.punchIn?.dateEpochMillis)
                    : moment()
                : data?.punchOut?.dateEpochMillis
                ? moment(data?.punchOut?.dateEpochMillis)
                : moment(),
        lateFee:
            enumType === 'in'
                ? data?.punchIn?.lateBy
                    ? data?.punchIn?.lateBy
                    : ''
                : data?.punchOut?.lateBy
                ? data?.punchOut?.lateBy
                : '',
        notes:
            enumType === 'in'
                ? data?.punchIn?.notes
                    ? data?.punchIn?.notes
                    : ''
                : data?.punchOut?.notes
                ? data?.punchOut?.notes
                : '',
        kmReading:
            enumType === 'in'
                ? data?.punchIn?.kmReading
                    ? data?.punchIn?.kmReading
                    : ''
                : data?.punchOut?.kmReading
                ? data?.punchOut?.kmReading
                : '',
        kmS3ImageUrl:
            enumType === 'in'
                ? data?.punchIn?.kmS3ImageUrl
                    ? data?.punchIn?.kmS3ImageUrl
                    : ''
                : data?.punchOut?.kmS3ImageUrl
                ? data?.punchOut?.kmS3ImageUrl
                : '',
    });

    const handleClose = () => {
        // Setting form data to null before closing
        setState(null);

        toggleModal(!isOpen);
        onClose && onClose();
    };

    useEffect(() => {
        console.log('all', state);
        console.log('data', data);
    }, [state]);

    const actions = [
        <Button
            key={1}
            variant="contained"
            color="default"
            className={`cancelButton`}
            onClick={handleClose}
            disableElevation
        >
            {'Cancel'}
        </Button>,
        <Button
            key={2}
            variant="contained"
            className={`saveButton`}
            style={{
                backgroundColor: '#f44336',
                color: '#fff',
            }}
            disableElevation
            onClick={async () => {
                await toggleLoading(true);
                if (enumType === 'in') {
                    console.log(
                        'dateEpochMillis',
                        dateTimeToMilliseconds(
                            state.selectDate,
                            state.selectTime,
                        ),
                        'lateBy',
                        state.lateFee,
                        'notes',
                        state.notes,
                    );

                    dispatchApprovePunchIn(
                        {
                            staffId: data.staffId,
                            id: data.id,
                            data: {
                                type: 'IN',
                                action: 'REJECT',
                                lateBy: state.lateFee,
                                notes: state.notes,
                                kmReading: state.kmReading,
                                kmS3ImageUrl: state.kmS3ImageUrl,
                            },
                        },
                        async () => {
                            console.log('Reject Punch In SUCCESS');
                            await toggleLoading(false);
                            handleClose();
                        },
                        async () => {
                            console.log('Reject Punch IN FAILED');
                            await toggleLoading(false);
                        },
                    );
                }
                if (enumType === 'out') {
                    dispatchApprovePunchOut(
                        {
                            staffId: data.staffId,
                            id: data.id,
                            data: {
                                type: 'OUT',
                                action: 'REJECT',
                                lateBy: state.lateFee,
                                notes: state.notes,
                                kmReading: state.kmReading,
                                kmS3ImageUrl: state.kmS3ImageUrl,
                            },
                        },
                        async () => {
                            console.log('Reject Punch Out SUCCESS');
                            await toggleLoading(false);
                            handleClose();
                        },
                        async () => {
                            console.log('Reject Punch Out FAILED');
                            await toggleLoading(false);
                        },
                    );
                }
            }}
        >
            Reject
        </Button>,
        <Button
            key={3}
            variant="contained"
            color="primary"
            className={`saveButton`}
            disableElevation
            onClick={async () => {
                // alert('Approve is clicked');
                if (enumType === 'in') {
                    console.log(
                        'dateEpochMillis',
                        dateTimeToMilliseconds(
                            state.selectDate,
                            state.selectTime,
                        ),
                        'lateBy',
                        state.lateFee,
                        'notes',
                        state.notes,
                    );

                    dispatchApprovePunchIn(
                        {
                            staffId: data.staffId,
                            id: data.id,
                            data: {
                                type: 'IN',
                                action: 'APPROVE',
                                lateBy: state.lateFee,
                                notes: state.notes,
                                kmReading: state.kmReading,
                                kmS3ImageUrl: state.kmS3ImageUrl,
                            },
                        },
                        async () => {
                            console.log('Approve Punch In SUCCESS');
                            await toggleLoading(false);
                            handleClose();
                        },
                        async () => {
                            console.log('Approve Punch IN FAILED');
                            await toggleLoading(false);
                        },
                    );
                }
                if (enumType === 'out') {
                    dispatchApprovePunchOut(
                        {
                            staffId: data.staffId,
                            id: data.id,
                            data: {
                                type: 'OUT',
                                action: 'APPROVE',
                                lateBy: state.lateFee,
                                notes: state.notes,
                                kmReading: state.kmReading,
                                kmS3ImageUrl: state.kmS3ImageUrl,
                            },
                        },
                        async () => {
                            console.log('Approve Punch Out SUCCESS');
                            await toggleLoading(false);
                            handleClose();
                        },

                        async () => {
                            console.log('Approve Punch Out FAILED');
                            await toggleLoading(false);
                        },
                    );
                }
            }}
        >
            {'Approve'}
        </Button>,
    ];

    const handleInputChange = (value, key) => {
        if (key == 'selectTime') {
            console.log(moment(value).format('H:mm:ss'));
        }
        if (key == 'selectDate') {
            console.log(moment(value).format('YYYY-MM-DD'));
        }
        setState((state) => ({
            ...state,
            [key]: value,
        }));

        // setStateStatus(null); // formValidator(key, value);
    };

    return (
        <Modal
            onClose={handleClose}
            open={isOpen}
            title={enumType === 'in' ? 'Add In Time' : 'Add Out Time'}
            subtitle={
                enumType === 'in'
                    ? 'Approve the Punch In details of staff'
                    : 'Approve the Punch Out details of staff'
            }
            actions={actions}
            fullWidth
            maxWidth="sm"
            loading={isloading}
        >
            <Box
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                }}
            >
                <Box
                    style={{
                        width: '45%',
                        marginRight: '24px',
                    }}
                >
                    <CustomDatePicker
                        value={state?.selectDate}
                        onChange={(e) => handleInputChange(e, 'selectDate')}
                        showInput={true}
                        InputProps={{
                            endAdornment: (
                                <IconButton>
                                    <InsertInvitationIcon size="small" />
                                </IconButton>
                            ),
                        }}
                        allowFutureDates={false}
                    />
                    <CustomTimePicker
                        value={state?.selectTime}
                        onChange={(e) => handleInputChange(e, 'selectTime')}
                    />

                    <CustomTextField
                        label={
                            enumType === 'in'
                                ? 'Start Km Reading'
                                : 'End Km Reading'
                        }
                        value={state?.kmReading}
                        InputProps={{
                            readOnly: false,
                            type: 'number',
                            inputMode: 'numeric',
                            min: 0,
                            endAdornment: state?.kmS3ImageUrl && (
                                <LightBoxImage
                                    containerStyles={{
                                        width: 40,
                                        height: 40,
                                        marginRight: 5,
                                    }}
                                    imageStyles={{
                                        width: 40,
                                        height: 40,
                                        margin: 'auto',
                                        borderRadius: '8px',
                                        objectFit: 'contain',
                                    }}
                                    source={state?.kmS3ImageUrl}
                                />
                            ),
                        }}
                        onChange={(e) => {
                            if (
                                !e.target.value ||
                                Number(e.target.value) <= 0 ||
                                isNaN(e.target.value)
                            ) {
                                handleInputChange('', 'kmReading');
                                return 0;
                            }
                            handleInputChange(e.target.value, 'kmReading');
                        }}
                        variant="outlined"
                        style={{
                            marginBottom: '16px',
                        }}
                    />

                    {enumType === 'in' ? (
                        // <CustomTextField
                        //     fullWidth={true}
                        //     label="Late Fee (hrs)"
                        //     value={state?.lateFee}
                        //     onChange={(e) =>
                        //         handleInputChange(e.target.value, 'lateFee')
                        //     }
                        //     style={{ marginBottom: '16px' }}

                        //     // onBlur={(e) => formValidator('amount', e.target.value)}
                        //     // error={stateStatus?.lateFee?.status}
                        //     // helperText={stateStatus?.lateFee?.error}
                        // />
                        <NumberFormat
                            value={state?.lateFee}
                            format="##:## hrs"
                            label="Late Fee (hrs)"
                            mask="_"
                            customInput={CustomTextField}
                            style={{ marginBottom: '16px' }}
                            onValueChange={(e) => {
                                handleInputChange(e.formattedValue, 'lateFee');
                            }}
                            onBlur={(e) => {
                                handleInputChange(
                                    e?.target?.value?.replace(/_/g, '0'),
                                    'lateFee',
                                );
                            }}
                        />
                    ) : null}
                    {enumType === 'in' ? (
                        <CustomTextField
                            fullWidth={true}
                            multiline={true}
                            label="Notes"
                            rows={6}
                            value={state?.notes}
                            onChange={(e) =>
                                handleInputChange(e.target.value, 'notes')
                            }
                            style={{ marginBottom: '16px' }}

                            // onBlur={(e) => formValidator('notes', e.target.value)}
                            // error={stateStatus?.notes?.status}
                            // helperText={stateStatus?.notes?.error}
                        />
                    ) : (
                        <CustomTextField
                            fullWidth={true}
                            multiline={true}
                            label="Notes"
                            rows={10}
                            value={state?.notes}
                            onChange={(e) =>
                                handleInputChange(e.target.value, 'notes')
                            }
                            style={{ marginBottom: '16px' }}

                            // onBlur={(e) => formValidator('notes', e.target.value)}
                            // error={stateStatus?.notes?.status}
                            // helperText={stateStatus?.notes?.error}
                        />
                    )}
                </Box>
                <Paper
                    style={{
                        width: '55%',
                        padding: '10px',
                        display: 'flex',
                        flexDirection: 'column',
                        marginBottom: '16px',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                    elevation={0}
                >
                    <img
                        src={
                            enumType === 'in'
                                ? data.punchIn?.s3ImageUrl
                                : enumType === 'out'
                                ? data.punchOut?.s3ImageUrl
                                : marker
                        }
                        style={{
                            margin: '0 auto auto auto',
                            height: '200px',
                            width: '100%',
                            borderRadius: '8px',
                            objectFit: 'contain',
                        }}
                    />
                    <Typography
                        style={{
                            fontSize: '18px',
                            fontWeight: 600,
                        }}
                    >
                        {data.name}
                    </Typography>

                    <Typography
                        style={{
                            fontSize: '14px',
                            marginBottom: '4px',
                        }}
                    >
                        {data.displayText}
                    </Typography>
                    <Typography
                        style={{
                            textAlign: 'center',
                            fontSize: '14px',
                            fontWeight: 700,
                            marginBottom: '8px',
                            color: '#f44336',
                        }}
                    >
                        {enumType === 'in'
                            ? data?.punchIn?.geofenceText
                            : data?.punchOut?.geofenceText}
                    </Typography>

                    <Typography
                        style={{
                            textAlign: 'center',
                            fontSize: '14px',
                            fontWeight: 600,
                        }}
                    >
                        {enumType === 'in'
                            ? data.punchIn.address
                            : data.punchOut.address}
                    </Typography>
                </Paper>
            </Box>
        </Modal>
    );
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({
    dispatchApprovePunchIn: (data, onSuccess, onError) =>
        dispatch(approvePunchIn(data, onSuccess, onError)),
    dispatchApprovePunchOut: (data, onSuccess, onError) => {
        dispatch(approvePunchOut(data, onSuccess, onError));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(ApprovePunchModal);
