import { Box, Button, Tooltip, Grid, Typography } from '@material-ui/core';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import {
    CheckMarkGreen,
    CrossMarkIcon,
    ExclaimIcon,
} from '../../../assets/svg';
import { CustomDatePicker } from '../../../components/DateTimePicker';
import { CustomTextField } from '../../../components/Textfield';
import { FileUploader } from '../../../components/UI';
import { showMessage } from '../../../utils/helper';
import LinkOutlined from '@material-ui/icons/LinkOutlined';
import LinkOffOutlined from '@material-ui/icons/LinkOffOutlined';
import CancelPresentationOutlinedIcon from '@material-ui/icons/CancelPresentationOutlined';
import DoneAllOutlinedIcon from '@material-ui/icons/DoneAllOutlined';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';

function VerifyKyc({
    leadInfo,
    toggleLoading,
    dispatchFetchBasicLeadkyc,
    onValueChange,
    ...props
}) {
    let dobFormatString = 'DD/MM/YYYY';
    // getSingleStaff
    const [kycInfo, setkycInfo] = useState({});
    const [rejectModalOpen, setRejectModalOpen] = useState(false);

    const [aadharPayload, setAadharPayload] = useState();
    const [panPayload, setPanPayload] = useState();
    const [rcPayload, setRcPayload] = useState();
    const [drivingPayload, setDrivingPayload] = useState();

    const [selectedItem, setSelectedItem] = useState({
        id: '',
        approvalStatus: '',
        reason: '',
    });

    const [isAadhaarEdited, setIsAadhaarEdited] = useState(false);
    const [isPanEdited, setIsPanEdited] = useState(false);
    const [isRcEdited, setIsRcEdited] = useState(false);
    const [isDrivingEdited, setIsDrivingEdited] = useState(false);

    const [isAadharDobMatching, setIsAadharDobMatching] = useState(false);
    const [isPanDobMatching, setIsPanDobMatching] = useState(false);
    const [isRcDobMatching, setIsRcDobMatching] = useState(false);
    const [isDrivingDobMatching, setIsDrivingDobMatching] = useState(false);

    const [isDocumentNumberMatching, setIsDocumentNumberMatching] =
        useState(null);

    const fetchLeadKycDetails = () => {
        toggleLoading(true);
        dispatchFetchBasicLeadkyc(
            leadInfo?.id,
            {},
            (resp) => {
                setkycInfo(
                    {
                        ...resp?.response,
                        dob: !!resp?.response?.dob ? resp?.response?.dob : null,
                    } ?? {},
                );
                toggleLoading(false);
            },
            (err) => {
                toggleLoading(false);
            },
        );
    };

    useEffect(() => {
        fetchLeadKycDetails();
    }, []);

    useEffect(() => {
        console.log(
            'PAYLOADDDD',
            aadharPayload,
            panPayload,
            rcPayload,
            drivingPayload,
        );
        onValueChange &&
            onValueChange({
                aadhar: aadharPayload,
                panCard: panPayload,
                rc: rcPayload,
                drivingLicense: drivingPayload,
            });
    }, [aadharPayload, panPayload, rcPayload, drivingPayload]);

    useEffect(() => {
        console.log('KYCINFO', kycInfo);
        let leadInfoDob = kycInfo?.dob;
        setAadharPayload({
            ...kycInfo?.aadhar,
            dob: leadInfoDob,
            manualVerificationRequired:
                kycInfo?.aadhar?.manualVerificationRequired ||
                (!!kycInfo?.aadhar?.autoKycDocumentDob &&
                    !!leadInfoDob &&
                    kycInfo?.aadhar?.autoKycDocumentDob != leadInfoDob),
        });
        setPanPayload({
            ...kycInfo?.panCard,
            dob: leadInfoDob,
            manualVerificationRequired:
                kycInfo?.panCard?.manualVerificationRequired ||
                (!!kycInfo?.panCard?.autoKycDocumentDob &&
                    !!leadInfoDob &&
                    kycInfo?.panCard?.autoKycDocumentDob != leadInfoDob),
        });
        setRcPayload({
            ...kycInfo?.rc,
            dob: leadInfoDob,
            manualVerificationRequired:
                kycInfo?.rc?.manualVerificationRequired ||
                (!!kycInfo?.rc?.autoKycDocumentDob &&
                    !!leadInfoDob &&
                    kycInfo?.rc?.autoKycDocumentDob != leadInfoDob),
        });
        setDrivingPayload({
            ...kycInfo?.drivingLicense,
            dob: leadInfoDob,
            manualVerificationRequired:
                kycInfo?.drivingLicense?.manualVerificationRequired ||
                (!!kycInfo?.drivingLicense?.autoKycDocumentDob &&
                    !!leadInfoDob &&
                    kycInfo?.drivingLicense?.autoKycDocumentDob != leadInfoDob),
        });

        setIsAadharDobMatching(
            !!kycInfo?.aadhar?.autoKycDocumentNo
                ? !!kycInfo?.aadhar?.autoKycDocumentDob && !!leadInfoDob
                    ? kycInfo?.aadhar?.autoKycDocumentDob == leadInfoDob
                    : false
                : true,
        );
        setIsPanDobMatching(
            !!kycInfo?.panCard?.autoKycDocumentNo
                ? !!kycInfo?.panCard?.autoKycDocumentDob && !!leadInfoDob
                    ? kycInfo?.panCard?.autoKycDocumentDob == leadInfoDob
                    : false
                : true,
        );
        setIsRcDobMatching(
            !!kycInfo?.rc?.autoKycDocumentNo
                ? !!kycInfo?.rc?.autoKycDocumentDob && !!leadInfoDob
                    ? kycInfo?.rc?.autoKycDocumentDob == leadInfoDob
                    : false
                : true,
        );
        setIsDrivingDobMatching(
            !!kycInfo?.drivingLicense?.autoKycDocumentNo
                ? !!kycInfo?.drivingLicense?.autoKycDocumentDob && !!leadInfoDob
                    ? kycInfo?.drivingLicense?.autoKycDocumentDob == leadInfoDob
                    : false
                : true,
        );
        setIsDocumentNumberMatching({
            aadhar:
                !!kycInfo?.aadhar?.autoKycDocumentNo &&
                !!kycInfo?.aadhar?.documentNo
                    ? kycInfo?.aadhar?.autoKycDocumentNo ==
                      kycInfo?.aadhar?.documentNo
                    : false,
            pan:
                !!kycInfo?.panCard?.autoKycDocumentNo &&
                !!kycInfo?.panCard?.documentNo &&
                kycInfo?.panCard?.autoKycDocumentNo ==
                    kycInfo?.panCard?.documentNo,
            rc:
                !!kycInfo?.rc?.autoKycDocumentNo &&
                !!kycInfo?.rc?.documentNo &&
                kycInfo?.rc?.autoKycDocumentNo == kycInfo?.rc?.documentNo,
            drivingLicense:
                !!kycInfo?.drivingLicense?.autoKycDocumentNo &&
                !!kycInfo?.drivingLicense?.documentNo &&
                kycInfo?.drivingLicense?.autoKycDocumentNo ==
                    kycInfo?.drivingLicense?.documentNo,
        });
    }, [kycInfo]);

    return (
        <Box style={{ padding: '20px 0 0 0' }} elevation={3}>
            <Grid container spacing={0} style={{ marginBottom: '12px' }}>
                <Grid
                    item
                    xs={6}
                    style={
                        !!aadharPayload?.manualVerificationRequired &&
                        aadharPayload?.approvalStatus != 'APPROVED' &&
                        aadharPayload?.approvalStatus != 'REJECTED' &&
                        (!isDocumentNumberMatching?.aadhar ||
                            !isAadharDobMatching)
                            ? styles.gridItemContainerWithErrorBorder
                            : styles.gridItemContainer
                    }
                >
                    <Box
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: '100%',
                        }}
                    >
                        <Box
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <Typography
                                style={{
                                    fontSize: '16px',
                                    fontWeight: 'Bold',
                                    color: '#14112d',
                                }}
                                variant="h6"
                            >
                                {'Aadhaar Details'}
                            </Typography>
                            <Box
                                style={{
                                    display: 'flex',
                                    alignItems: 'baseline',
                                    marginLeft: 2,
                                }}
                            >
                                {!!aadharPayload?.panVerified && (
                                    <>
                                        <Tooltip
                                            title={
                                                !!aadharPayload?.linked
                                                    ? 'Linked'
                                                    : 'Not Linked'
                                            }
                                            placement="top"
                                        >
                                            {!!aadharPayload?.linked ? (
                                                <LinkOutlined
                                                    style={{
                                                        width: 20,
                                                        color: 'green',
                                                        marginLeft: 4,
                                                    }}
                                                />
                                            ) : (
                                                <LinkOffOutlined
                                                    style={{
                                                        width: 20,
                                                        color: 'red',
                                                        marginLeft: 4,
                                                    }}
                                                />
                                            )}
                                        </Tooltip>
                                        <Tooltip
                                            title={
                                                !!aadharPayload?.verified
                                                    ? 'Verified'
                                                    : 'Not Verified'
                                            }
                                            placement="top"
                                        >
                                            {!!aadharPayload?.verified ? (
                                                <DoneAllOutlinedIcon
                                                    style={{
                                                        width: 20,
                                                        color: 'green',
                                                        marginLeft: 4,
                                                    }}
                                                />
                                            ) : (
                                                <CancelPresentationOutlinedIcon
                                                    style={{
                                                        width: 20,
                                                        color: 'red',
                                                        marginLeft: 4,
                                                    }}
                                                />
                                            )}
                                        </Tooltip>
                                        {!!aadharPayload?.centralDobMismatch
                                            ?.length && (
                                            <Tooltip
                                                title={`Verified DOB Mismatch - ${aadharPayload?.centralDobMismatch} `}
                                                placement="top"
                                            >
                                                <AccountCircleOutlinedIcon
                                                    style={{
                                                        width: 20,
                                                        color: 'red',
                                                        marginLeft: 4,
                                                    }}
                                                />
                                            </Tooltip>
                                        )}
                                    </>
                                )}

                                {!!aadharPayload?.ocrResponseCode &&
                                    ['435', '436']?.includes(
                                        aadharPayload?.ocrResponseCode,
                                    ) && (
                                        <Tooltip
                                            title={
                                                aadharPayload?.ocrResponseCode ===
                                                '435'
                                                    ? 'Flagged - Not a Live Document'
                                                    : 'Flaaged - Invalid Photo/Document'
                                            }
                                            placement="top"
                                        >
                                            <WarningRoundedIcon
                                                style={{
                                                    width: 20,
                                                    color: 'red',
                                                    marginLeft: 4,
                                                }}
                                            />
                                        </Tooltip>
                                    )}
                            </Box>
                        </Box>

                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <Typography
                                style={{
                                    fontWeight: 700,
                                    marginLeft: 5,
                                    color:
                                        aadharPayload?.approvalStatus ===
                                        'APPROVED'
                                            ? '#4caf50'
                                            : aadharPayload?.approvalStatus ===
                                              'REJECTED'
                                            ? '#f44336'
                                            : !!aadharPayload?.manualVerificationRequired &&
                                              (!isDocumentNumberMatching?.aadhar ||
                                                  !isAadharDobMatching)
                                            ? '#f44336'
                                            : '',
                                    fontSize: '14px',
                                }}
                                variant="h6"
                                component="span"
                                gutterBottom
                            >
                                {aadharPayload?.approvalStatus === 'APPROVED'
                                    ? 'Verified'
                                    : aadharPayload?.approvalStatus ===
                                      'REJECTED'
                                    ? 'Rejected'
                                    : !!aadharPayload?.manualVerificationRequired &&
                                      (!isDocumentNumberMatching?.aadhar ||
                                          !isAadharDobMatching)
                                    ? 'Manual Verification Required'
                                    : ''}
                            </Typography>

                            <div style={{ marginLeft: '0.2rem' }}>
                                {aadharPayload?.approvalStatus ===
                                'APPROVED' ? (
                                    <CheckMarkGreen style={{ width: '16px' }} />
                                ) : aadharPayload?.approvalStatus ===
                                  'REJECTED' ? (
                                    <CrossMarkIcon style={{ width: '16px' }} />
                                ) : (
                                    <></>
                                )}
                            </div>
                        </div>
                    </Box>

                    {aadharPayload?.approvalStatus !== 'APPROVED' &&
                    aadharPayload?.approvalStatus !== 'REJECTED' &&
                    !!(
                        aadharPayload?.manualVerificationRequired &&
                        (!isDocumentNumberMatching?.aadhar ||
                            !isAadharDobMatching)
                    ) ? (
                        <>
                            <Box
                                style={{
                                    width: '100%',
                                }}
                            >
                                <Box
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        marginBottom: '8px',
                                    }}
                                >
                                    <Box
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Typography
                                            style={{
                                                color: '#808080',
                                                fontSize: '14px',
                                                fontWeight: 500,
                                            }}
                                        >
                                            {!!aadharPayload?.autoKycDocumentNo
                                                ? `Scanned Id: `
                                                : 'Entered Id: '}
                                        </Typography>

                                        <Typography
                                            style={
                                                !isDocumentNumberMatching?.aadhar
                                                    ? {
                                                          color: '#f44336',
                                                          fontSize: '14px',
                                                          fontWeight: 700,
                                                          marginLeft: 4,
                                                      }
                                                    : {
                                                          color: '#263238',
                                                          fontSize: '14px',
                                                          fontWeight: 700,
                                                          marginLeft: 4,
                                                      }
                                            }
                                        >
                                            {`${
                                                !!aadharPayload?.autoKycDocumentNo
                                                    ? aadharPayload?.autoKycDocumentNo
                                                    : aadharPayload?.documentNo ??
                                                      ''
                                            }`}
                                        </Typography>

                                        {!isDocumentNumberMatching?.aadhar && (
                                            <ExclaimIcon
                                                style={{
                                                    width: '16px',
                                                    height: '16px',
                                                    marginLeft: 4,
                                                }}
                                            />
                                        )}
                                    </Box>

                                    {!isDocumentNumberMatching?.aadhar &&
                                        !!aadharPayload?.autoKycDocumentNo && (
                                            <Box
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    marginLeft: '12px',
                                                }}
                                            >
                                                <Typography
                                                    style={{
                                                        color: '#808080',
                                                        fontSize: '14px',
                                                        fontWeight: 500,
                                                    }}
                                                >
                                                    {'Edited:'}
                                                </Typography>

                                                <Typography
                                                    style={{
                                                        color: '#263238',
                                                        fontSize: '14px',
                                                        fontWeight: 700,
                                                        marginLeft: 4,
                                                    }}
                                                >
                                                    {aadharPayload?.documentNo}
                                                </Typography>
                                            </Box>
                                        )}
                                </Box>

                                <Box
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        marginBottom: 16,
                                    }}
                                >
                                    <Box
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Typography
                                            style={{
                                                color: '#808080',
                                                fontSize: '14px',
                                                fontWeight: 500,
                                            }}
                                        >
                                            {!!aadharPayload?.autoKycDocumentDob
                                                ? `Scanned DOB: `
                                                : 'Entered DOB: '}
                                        </Typography>

                                        <Typography
                                            style={
                                                !isAadharDobMatching
                                                    ? {
                                                          color: '#f44336',
                                                          fontSize: '14px',
                                                          fontWeight: 700,
                                                          marginLeft: 4,
                                                      }
                                                    : {
                                                          color: '#263238',
                                                          fontSize: '14px',
                                                          fontWeight: 700,
                                                          marginLeft: 4,
                                                      }
                                            }
                                        >
                                            {`${
                                                !!aadharPayload?.autoKycDocumentDob
                                                    ? moment(
                                                          aadharPayload?.autoKycDocumentDob,
                                                      )?.format(dobFormatString)
                                                    : !!aadharPayload?.dob
                                                    ? moment(
                                                          aadharPayload?.dob,
                                                      )?.format(dobFormatString)
                                                    : ''
                                            }`}
                                        </Typography>

                                        {!isAadharDobMatching &&
                                            !!aadharPayload?.dob && (
                                                <ExclaimIcon
                                                    style={{
                                                        width: '16px',
                                                        height: '16px',
                                                        marginLeft: 4,
                                                    }}
                                                />
                                            )}
                                    </Box>

                                    {!isAadharDobMatching &&
                                        !!aadharPayload?.autoKycDocumentDob && (
                                            <Box
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    marginLeft: '12px',
                                                }}
                                            >
                                                <Typography
                                                    style={{
                                                        color: '#808080',
                                                        fontSize: '14px',
                                                        fontWeight: 500,
                                                    }}
                                                >
                                                    {'Edited DOB:'}
                                                </Typography>

                                                <Typography
                                                    style={{
                                                        color: '#263238',
                                                        fontSize: '14px',
                                                        fontWeight: 700,
                                                        marginLeft: 4,
                                                    }}
                                                >
                                                    {` ${
                                                        // !!aadharPayload?.autoKycDocumentDob
                                                        //     ? moment(
                                                        //           aadharPayload?.autoKycDocumentDob,
                                                        //       )?.format(
                                                        //           dobFormatString,
                                                        //       ) :
                                                        !!aadharPayload?.dob
                                                            ? moment(
                                                                  aadharPayload?.dob,
                                                              )?.format(
                                                                  dobFormatString,
                                                              )
                                                            : ''
                                                    }`}
                                                </Typography>
                                            </Box>
                                        )}
                                </Box>
                            </Box>
                            {/* INPUT FIELDS
                            <Box
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    width: '100%',
                                }}
                            >
                                <CustomTextField
                                    value={aadharPayload?.documentNo ?? ''}
                                    onChange={(e) => {
                                        setAadharPayload((prev) => {
                                            return {
                                                ...prev,
                                                documentNo:
                                                    e.target.value?.trim(),
                                            };
                                        });
                                        setIsAadhaarEdited(true);
                                    }}
                                    label="Edited Number"
                                    variant="outlined"
                                    style={
                                        isAadharDobMatching
                                            ? {
                                                  width: '100%',
                                                  marginBottom: 16,
                                              }
                                            : {
                                                  width: '49%',
                                                  marginBottom: 16,
                                              }
                                    }
                                    InputProps={{
                                        readOnly: true,
                                        endAdornment: <ExclaimIcon />,
                                    }}
                                />

                                {!isAadharDobMatching && (
                                    <CustomDatePicker
                                        format="dd-MMM-yyyy"
                                        id="dateOfBirth"
                                        name="dateOfBirth"
                                        label="Edited DOB"
                                        variant="outlined"
                                        value={kycInfo?.dob ?? ''}
                                        disabled={true}
                                        onChange={(e) => {
                                            setkycInfo((prev) => {
                                                return {
                                                    ...prev,
                                                    dob:
                                                        moment(e)?.valueOf() > 0
                                                            ? moment(e)?.format(
                                                                  'DD-MMM-YYYY',
                                                              )
                                                            : null,
                                                };
                                            });
                                            setIsAadhaarEdited(true);
                                        }}
                                        showInput={true}
                                        style={{
                                            width: '49%',
                                            marginBottom: 16,
                                        }}
                                        InputProps={{
                                            readOnly: true,
                                            endAdornment: <ExclaimIcon />,
                                        }}
                                    />
                                )}
                            </Box> */}
                        </>
                    ) : (
                        <Box
                            style={{
                                width: '100%',
                            }}
                        >
                            <Box
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    width: '100%',
                                    marginBottom: '8px',
                                }}
                            >
                                <Typography
                                    style={{
                                        color: '#808080',
                                        fontSize: '14px',
                                        fontWeight: 500,
                                    }}
                                >
                                    {!!aadharPayload?.autoKycDocumentNo
                                        ? `Scanned Id: `
                                        : 'Entered Id: '}
                                </Typography>

                                <Typography
                                    style={{
                                        color: '#263238',
                                        fontSize: '14px',
                                        fontWeight: 700,
                                        marginLeft: 4,
                                    }}
                                >
                                    {` ${
                                        !!aadharPayload?.autoKycDocumentNo
                                            ? aadharPayload?.autoKycDocumentNo
                                            : aadharPayload?.documentNo ?? ''
                                    }`}
                                </Typography>
                            </Box>

                            <Box
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    marginBottom: 16,
                                }}
                            >
                                <Box
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'flex-start',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Typography
                                        style={{
                                            color: '#808080',
                                            fontSize: '14px',
                                            fontWeight: 500,
                                        }}
                                    >
                                        {!!aadharPayload?.autoKycDocumentDob
                                            ? `Scanned DOB: `
                                            : 'Entered DOB: '}
                                    </Typography>

                                    <Typography
                                        style={
                                            !isAadharDobMatching
                                                ? {
                                                      color: '#f44336',
                                                      fontSize: '14px',
                                                      fontWeight: 700,
                                                      marginLeft: 4,
                                                  }
                                                : {
                                                      color: '#263238',
                                                      fontSize: '14px',
                                                      fontWeight: 700,
                                                      marginLeft: 4,
                                                  }
                                        }
                                    >
                                        {`${
                                            !!aadharPayload?.autoKycDocumentDob
                                                ? moment(
                                                      aadharPayload?.autoKycDocumentDob,
                                                  )?.format(dobFormatString)
                                                : !!aadharPayload?.dob
                                                ? moment(
                                                      aadharPayload?.dob,
                                                  )?.format(dobFormatString)
                                                : ''
                                        }`}
                                    </Typography>

                                    {!isAadharDobMatching &&
                                        !!aadharPayload?.dob && (
                                            <ExclaimIcon
                                                style={{
                                                    width: '16px',
                                                    height: '16px',
                                                    marginLeft: 4,
                                                }}
                                            />
                                        )}
                                </Box>

                                {!isAadharDobMatching &&
                                    !!aadharPayload?.autoKycDocumentDob && (
                                        <Box
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                marginLeft: '12px',
                                            }}
                                        >
                                            <Typography
                                                style={{
                                                    color: '#808080',
                                                    fontSize: '14px',
                                                    fontWeight: 500,
                                                }}
                                            >
                                                {'Edited DOB:'}
                                            </Typography>

                                            <Typography
                                                style={{
                                                    color: '#263238',
                                                    fontSize: '14px',
                                                    fontWeight: 700,
                                                    marginLeft: 4,
                                                }}
                                            >
                                                {` ${
                                                    // !!aadharPayload?.autoKycDocumentDob
                                                    //     ? moment(
                                                    //           aadharPayload?.autoKycDocumentDob,
                                                    //       )?.format(
                                                    //           dobFormatString,
                                                    //       ) :
                                                    !!aadharPayload?.dob
                                                        ? moment(
                                                              aadharPayload?.dob,
                                                          )?.format(
                                                              dobFormatString,
                                                          )
                                                        : ''
                                                }`}
                                            </Typography>
                                        </Box>
                                    )}
                            </Box>
                        </Box>
                    )}

                    <Box
                        style={{
                            display: 'flex',
                            justifyContent: 'left',
                            alignItems: 'center',
                            width: '100%',
                        }}
                    >
                        <Grid
                            item
                            sm={6}
                            xs={12}
                            style={{
                                maxWidth: 'calc(50% - 4px)',
                                marginRight: '4px',
                            }}
                        >
                            <FileUploader
                                labelStyles={{
                                    fontSize: '14px',
                                    fontWeight: 600,
                                }}
                                defaultValue={aadharPayload?.imageFront}
                                placeholder={'Upload Aadhaar (Front)'}
                                onFileUploaded={(e) => {
                                    setIsAadhaarEdited(true);

                                    setAadharPayload((prev) => {
                                        return {
                                            ...prev,
                                            imageFront: e,
                                        };
                                    });
                                }}
                                onRemoved={(e) => {
                                    setIsAadhaarEdited(true);
                                    setAadharPayload((prev) => {
                                        return {
                                            ...prev,
                                            imageFront: null,
                                        };
                                    });

                                    // if (aadhaarFrontId) {
                                    //     dispatchDeleteImageAttachment(
                                    //         aadhaarFrontId,
                                    //         () => {
                                    //             console.log('REMOVE SUCCESS');
                                    //         },
                                    //         () => {
                                    //             console.log('REMOVE FAILED');
                                    //         },
                                    //     );
                                    // }
                                }}
                            />
                        </Grid>
                        <Grid
                            item
                            sm={6}
                            xs={12}
                            style={{
                                maxWidth: 'calc(50% - 4px)',
                                marginLeft: '4px',
                            }}
                        >
                            <FileUploader
                                labelStyles={{
                                    fontSize: '14px',
                                    fontWeight: 600,
                                }}
                                defaultValue={aadharPayload?.imageBack}
                                placeholder={'Upload Aadhaar (Back)'}
                                onFileUploaded={(e) => {
                                    setIsAadhaarEdited(true);

                                    setAadharPayload((prev) => {
                                        return {
                                            ...prev,
                                            imageBack: e,
                                        };
                                    });
                                }}
                                onRemoved={(e) => {
                                    setIsAadhaarEdited(true);
                                    setAadharPayload((prev) => {
                                        return {
                                            ...prev,
                                            imageBack: null,
                                        };
                                    });

                                    // if (aadhaarBackId) {
                                    //     dispatchDeleteImageAttachment(
                                    //         aadhaarBackId,
                                    //         () => {
                                    //             console.log('REMOVE SUCCESS');
                                    //         },
                                    //         () => {
                                    //             console.log('REMOVE FAILED');
                                    //         },
                                    //     );
                                    // }
                                }}
                            />
                        </Grid>
                    </Box>
                    {false && (
                        <>
                            <Box
                                style={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    width: '100%',
                                    marginTop: 16,
                                }}
                            >
                                <CustomTextField
                                    value={aadharPayload?.rejectReason}
                                    onChange={(e) => {
                                        setAadharPayload((prev) => {
                                            return {
                                                ...prev,
                                                rejectReason: e.target.value,
                                            };
                                        });
                                    }}
                                    label="Reject Reason"
                                    variant="outlined"
                                    style={{
                                        width: '100%',
                                        marginBottom: 0,
                                    }}
                                    disabled={
                                        aadharPayload?.approvalStatus ===
                                        'REJECTED'
                                    }
                                />
                            </Box>
                            <div
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    alignItems: 'flex-end',
                                    flexDirection: 'row',
                                    marginTop: 10,
                                }}
                            >
                                {aadharPayload?.approvalStatus ===
                                'PENDING_APPROVAL' ? (
                                    <>
                                        <Button
                                            disableElevation
                                            variant="outlined"
                                            style={
                                                !isAadhaarEdited
                                                    ? {
                                                          backgroundColor:
                                                              '#fff',
                                                          border: '1px solid #f44336',
                                                          color: '#f44336',
                                                          fontWeight: 600,
                                                          marginRight: 10,
                                                      }
                                                    : { marginRight: 10 }
                                            }
                                            disabled={
                                                isAadhaarEdited ||
                                                aadharPayload?.approvalStatus ===
                                                    'REJECTED'
                                            }
                                            onClick={(e) => {
                                                if (
                                                    !!aadharPayload?.rejectReason
                                                )
                                                    setAadharPayload((prev) => {
                                                        return {
                                                            ...prev,
                                                            approvalStatus:
                                                                'REJECTED',
                                                        };
                                                    });
                                                else {
                                                    showMessage(
                                                        'Rejection Reason is empty',
                                                        'error',
                                                    );
                                                }
                                            }}
                                        >
                                            Reject
                                        </Button>
                                        <Button
                                            disableElevation
                                            variant="outlined"
                                            style={
                                                isPanEdited ||
                                                panPayload?.approvalStatus ===
                                                    'APPROVED'
                                                    ? {
                                                          //   marginRight: 20,
                                                      }
                                                    : {
                                                          //   marginRight: 20,
                                                          fontSize: 14,
                                                          color: '#4caf50',
                                                          border: '1px solid #4caf50',
                                                          fontWeight: 700,
                                                      }
                                            }
                                            onClick={(e) => {
                                                setAadharPayload((prev) => {
                                                    return {
                                                        ...prev,
                                                        approvalStatus:
                                                            'APPROVED',
                                                    };
                                                });
                                                setRejectModalOpen(true);
                                            }}
                                            disabled={
                                                isAadhaarEdited ||
                                                aadharPayload?.approvalStatus ===
                                                    'APPROVED'
                                            }
                                        >
                                            Approve
                                        </Button>
                                    </>
                                ) : (
                                    <div></div>
                                )}
                            </div>
                        </>
                    )}
                </Grid>
                <Grid
                    item
                    xs={6}
                    style={
                        panPayload?.approvalStatus != 'APPROVED' &&
                        panPayload?.approvalStatus != 'REJECTED' &&
                        !!panPayload?.manualVerificationRequired &&
                        (!isDocumentNumberMatching?.pan || !isPanDobMatching)
                            ? styles.gridItemContainerWithErrorBorder
                            : styles.gridItemContainer
                    }
                >
                    <Box
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: '100%',
                        }}
                    >
                        <Box
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <Typography
                                style={{
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                    color: '#14112d',
                                }}
                                variant="h6"
                            >
                                {'PAN Details'}
                            </Typography>
                            <Box
                                style={{
                                    display: 'flex',
                                    alignItems: 'baseline',
                                    marginLeft: 2,
                                }}
                            >
                                {!!panPayload?.panVerified && (
                                    <>
                                        <Tooltip
                                            title={
                                                !!panPayload?.verified
                                                    ? 'Verified'
                                                    : 'Not Verified'
                                            }
                                            placement="top"
                                        >
                                            {!!panPayload?.verified ? (
                                                <DoneAllOutlinedIcon
                                                    style={{
                                                        width: 20,
                                                        color: 'green',
                                                        marginLeft: 4,
                                                    }}
                                                />
                                            ) : (
                                                <CancelPresentationOutlinedIcon
                                                    style={{
                                                        width: 20,
                                                        color: 'red',
                                                        marginLeft: 4,
                                                    }}
                                                />
                                            )}
                                        </Tooltip>
                                        {!!panPayload?.centralDobMismatch
                                            ?.length && (
                                            <Tooltip
                                                title={`Verified DOB Mismatch - ${panPayload?.centralDobMismatch} `}
                                                placement="top"
                                            >
                                                <AccountCircleOutlinedIcon
                                                    style={{
                                                        width: 20,
                                                        color: 'red',
                                                        marginLeft: 4,
                                                    }}
                                                />
                                            </Tooltip>
                                        )}
                                    </>
                                )}
                                {!!panPayload?.ocrResponseCode &&
                                    ['435', '436']?.includes(
                                        panPayload?.ocrResponseCode,
                                    ) && (
                                        <Tooltip
                                            title={
                                                panPayload?.ocrResponseCode ===
                                                '435'
                                                    ? 'Flagged - Not a Live Document'
                                                    : 'Flaaged - Invalid Photo/Document'
                                            }
                                            placement="top"
                                        >
                                            <WarningRoundedIcon
                                                style={{
                                                    width: 20,
                                                    color: 'red',
                                                    marginLeft: 4,
                                                }}
                                            />
                                        </Tooltip>
                                    )}
                            </Box>
                        </Box>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <Typography
                                style={{
                                    fontWeight: 700,
                                    marginLeft: 5,
                                    color:
                                        panPayload?.approvalStatus ===
                                        'APPROVED'
                                            ? '#4caf50'
                                            : panPayload?.approvalStatus ===
                                              'REJECTED'
                                            ? '#f44336'
                                            : !!panPayload?.manualVerificationRequired &&
                                              !isDocumentNumberMatching?.pan
                                            ? '#f44336'
                                            : '',
                                    fontSize: '14px',
                                }}
                                variant="h6"
                                component="span"
                                gutterBottom
                            >
                                {panPayload?.approvalStatus === 'APPROVED'
                                    ? 'Verified'
                                    : panPayload?.approvalStatus === 'REJECTED'
                                    ? 'Rejected'
                                    : !!panPayload?.manualVerificationRequired &&
                                      !isDocumentNumberMatching?.pan
                                    ? 'Manual Verification Required'
                                    : ''}
                            </Typography>

                            <div style={{ marginLeft: '0.2rem' }}>
                                {panPayload?.approvalStatus === 'APPROVED' ? (
                                    <CheckMarkGreen style={{ width: '16px' }} />
                                ) : panPayload?.approvalStatus ===
                                  'REJECTED' ? (
                                    <CrossMarkIcon style={{ width: '16px' }} />
                                ) : (
                                    <></>
                                )}
                            </div>
                        </div>
                    </Box>

                    {panPayload?.approvalStatus !== 'APPROVED' &&
                    panPayload?.approvalStatus !== 'REJECTED' &&
                    !!(
                        panPayload?.manualVerificationRequired &&
                        (!isDocumentNumberMatching?.pan || !isPanDobMatching)
                    ) ? (
                        <>
                            <Box
                                style={{
                                    width: '100%',
                                }}
                            >
                                <Box
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        marginBottom: '8px',
                                    }}
                                >
                                    <Box
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Typography
                                            style={{
                                                color: '#808080',
                                                fontSize: '14px',
                                                fontWeight: 500,
                                            }}
                                        >
                                            {!!panPayload?.autoKycDocumentNo
                                                ? `Scanned Id: `
                                                : 'Entered Id: '}
                                        </Typography>

                                        <Typography
                                            style={
                                                !isDocumentNumberMatching?.pan
                                                    ? {
                                                          color: '#f44336',
                                                          fontSize: '14px',
                                                          fontWeight: 700,
                                                          marginLeft: 4,
                                                      }
                                                    : {
                                                          color: '#263238',
                                                          fontSize: '14px',
                                                          fontWeight: 700,
                                                          marginLeft: 4,
                                                      }
                                            }
                                        >
                                            {`${
                                                !!panPayload?.autoKycDocumentNo
                                                    ? panPayload?.autoKycDocumentNo
                                                    : panPayload?.documentNo ??
                                                      ''
                                            }`}
                                        </Typography>

                                        {!isDocumentNumberMatching?.pan && (
                                            <ExclaimIcon
                                                style={{
                                                    width: '16px',
                                                    height: '16px',
                                                    marginLeft: 4,
                                                }}
                                            />
                                        )}
                                    </Box>

                                    {!isDocumentNumberMatching?.pan &&
                                        !!panPayload?.autoKycDocumentNo && (
                                            <Box
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    marginLeft: '12px',
                                                }}
                                            >
                                                <Typography
                                                    style={{
                                                        color: '#808080',
                                                        fontSize: '14px',
                                                        fontWeight: 500,
                                                    }}
                                                >
                                                    {'Edited:'}
                                                </Typography>

                                                <Typography
                                                    style={{
                                                        color: '#263238',
                                                        fontSize: '14px',
                                                        fontWeight: 700,
                                                        marginLeft: 4,
                                                    }}
                                                >
                                                    {panPayload?.documentNo}
                                                </Typography>
                                            </Box>
                                        )}
                                </Box>

                                <Box
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        marginBottom: 16,
                                    }}
                                >
                                    <Box
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Typography
                                            style={{
                                                color: '#808080',
                                                fontSize: '14px',
                                                fontWeight: 500,
                                            }}
                                        >
                                            {!!panPayload?.autoKycDocumentDob
                                                ? `Scanned DOB: `
                                                : 'Entered DOB: '}
                                        </Typography>

                                        <Typography
                                            style={
                                                !isPanDobMatching
                                                    ? {
                                                          color: '#f44336',
                                                          fontSize: '14px',
                                                          fontWeight: 700,
                                                          marginLeft: 4,
                                                      }
                                                    : {
                                                          color: '#263238',
                                                          fontSize: '14px',
                                                          fontWeight: 700,
                                                          marginLeft: 4,
                                                      }
                                            }
                                        >
                                            {`${
                                                !!panPayload?.autoKycDocumentDob
                                                    ? moment(
                                                          panPayload?.autoKycDocumentDob,
                                                      )?.format(dobFormatString)
                                                    : !!panPayload?.dob
                                                    ? moment(
                                                          panPayload?.dob,
                                                      )?.format(dobFormatString)
                                                    : ''
                                            }`}
                                        </Typography>

                                        {!isPanDobMatching &&
                                            !!panPayload?.dob && (
                                                <ExclaimIcon
                                                    style={{
                                                        width: '16px',
                                                        height: '16px',
                                                        marginLeft: 4,
                                                    }}
                                                />
                                            )}
                                    </Box>

                                    {!isPanDobMatching &&
                                        !!panPayload?.autoKycDocumentDob && (
                                            <Box
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    marginLeft: '12px',
                                                }}
                                            >
                                                <Typography
                                                    style={{
                                                        color: '#808080',
                                                        fontSize: '14px',
                                                        fontWeight: 500,
                                                    }}
                                                >
                                                    {'Edited DOB:'}
                                                </Typography>

                                                <Typography
                                                    style={{
                                                        color: '#263238',
                                                        fontSize: '14px',
                                                        fontWeight: 700,
                                                        marginLeft: 4,
                                                    }}
                                                >
                                                    {` ${
                                                        // !!panPayload?.autoKycDocumentDob
                                                        //     ? moment(
                                                        //           panPayload?.autoKycDocumentDob,
                                                        //       )?.format(
                                                        //           dobFormatString,
                                                        //       ) :
                                                        !!panPayload?.dob
                                                            ? moment(
                                                                  panPayload?.dob,
                                                              )?.format(
                                                                  dobFormatString,
                                                              )
                                                            : ''
                                                    }`}
                                                </Typography>
                                            </Box>
                                        )}
                                </Box>
                            </Box>

                            {/* INPUT FIELDS
                            <Box
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    width: '100%',
                                }}
                            >
                                <CustomTextField
                                    value={panPayload?.documentNo ?? ''}
                                    onChange={(e) => {
                                        setPanPayload((prev) => {
                                            return {
                                                ...prev,
                                                documentNo:
                                                    e.target.value?.trim(),
                                            };
                                        });
                                        setIsPanEdited(true);
                                    }}
                                    label="Edited Number"
                                    variant="outlined"
                                    style={
                                        isPanDobMatching
                                            ? {
                                                  width: '100%',
                                                  marginBottom: 16,
                                              }
                                            : {
                                                  width: '49%',
                                                  marginBottom: 16,
                                              }
                                    }
                                    InputProps={{
                                        readOnly: true,
                                        endAdornment: <ExclaimIcon />,
                                    }}
                                />

                                {!isPanDobMatching && (
                                    <CustomDatePicker
                                        format="dd-MMM-yyyy"
                                        id="dateOfBirth"
                                        name="dateOfBirth"
                                        label="Edited DOB"
                                        variant="outlined"
                                        disabled={true}
                                        value={kycInfo?.dob ?? ''}
                                        onChange={(e) => {
                                            setkycInfo((prev) => {
                                                return {
                                                    ...prev,
                                                    dob:
                                                        moment(e)?.valueOf() > 0
                                                            ? moment(e)?.format(
                                                                  'DD-MMM-YYYY',
                                                              )
                                                            : null,
                                                };
                                            });
                                            setIsPanEdited(true);
                                        }}
                                        showInput={true}
                                        style={{
                                            width: '49%',
                                            marginBottom: 16,
                                        }}
                                        InputProps={{
                                            readOnly: true,
                                            endAdornment: <ExclaimIcon />,
                                        }}
                                    />
                                )}
                            </Box> */}
                        </>
                    ) : (
                        <Box
                            style={{
                                width: '100%',
                            }}
                        >
                            <Box
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    width: '100%',
                                    marginBottom: '8px',
                                }}
                            >
                                <Typography
                                    style={{
                                        color: '#808080',
                                        fontSize: '14px',
                                        fontWeight: 500,
                                    }}
                                >
                                    {!!panPayload?.autoKycDocumentNo
                                        ? `Scanned Id: `
                                        : 'Entered Id: '}
                                </Typography>

                                <Typography
                                    style={{
                                        color: '#263238',
                                        fontSize: '14px',
                                        fontWeight: 700,
                                        marginLeft: 4,
                                    }}
                                >
                                    {` ${
                                        !!panPayload?.autoKycDocumentNo
                                            ? panPayload?.autoKycDocumentNo
                                            : panPayload?.documentNo ?? ''
                                    }`}
                                </Typography>
                            </Box>

                            <Box
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    marginBottom: 16,
                                }}
                            >
                                <Box
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'flex-start',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Typography
                                        style={{
                                            color: '#808080',
                                            fontSize: '14px',
                                            fontWeight: 500,
                                        }}
                                    >
                                        {!!panPayload?.autoKycDocumentDob
                                            ? `Scanned DOB: `
                                            : 'Entered DOB: '}
                                    </Typography>

                                    <Typography
                                        style={
                                            !isPanDobMatching
                                                ? {
                                                      color: '#f44336',
                                                      fontSize: '14px',
                                                      fontWeight: 700,
                                                      marginLeft: 4,
                                                  }
                                                : {
                                                      color: '#263238',
                                                      fontSize: '14px',
                                                      fontWeight: 700,
                                                      marginLeft: 4,
                                                  }
                                        }
                                    >
                                        {`${
                                            !!panPayload?.autoKycDocumentDob
                                                ? moment(
                                                      panPayload?.autoKycDocumentDob,
                                                  )?.format(dobFormatString)
                                                : !!panPayload?.dob
                                                ? moment(
                                                      panPayload?.dob,
                                                  )?.format(dobFormatString)
                                                : ''
                                        }`}
                                    </Typography>

                                    {!isPanDobMatching && !!panPayload?.dob && (
                                        <ExclaimIcon
                                            style={{
                                                width: '16px',
                                                height: '16px',
                                                marginLeft: 4,
                                            }}
                                        />
                                    )}
                                </Box>

                                {!isPanDobMatching &&
                                    !!panPayload?.autoKycDocumentDob && (
                                        <Box
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                marginLeft: '12px',
                                            }}
                                        >
                                            <Typography
                                                style={{
                                                    color: '#808080',
                                                    fontSize: '14px',
                                                    fontWeight: 500,
                                                }}
                                            >
                                                {'Edited DOB:'}
                                            </Typography>

                                            <Typography
                                                style={{
                                                    color: '#263238',
                                                    fontSize: '14px',
                                                    fontWeight: 700,
                                                    marginLeft: 4,
                                                }}
                                            >
                                                {` ${
                                                    // !!panPayload?.autoKycDocumentDob
                                                    //     ? moment(
                                                    //           panPayload?.autoKycDocumentDob,
                                                    //       )?.format(
                                                    //           dobFormatString,
                                                    //       ) :
                                                    !!panPayload?.dob
                                                        ? moment(
                                                              panPayload?.dob,
                                                          )?.format(
                                                              dobFormatString,
                                                          )
                                                        : ''
                                                }`}
                                            </Typography>
                                        </Box>
                                    )}
                            </Box>
                        </Box>
                    )}

                    <Box
                        style={{
                            width: '100%',
                        }}
                    >
                        <FileUploader
                            labelStyles={{
                                fontSize: '14px',
                                fontWeight: 600,
                            }}
                            placeholder={'Upload Pan card (Front)'}
                            onFileUploaded={(e) => {
                                setIsPanEdited(true);

                                setPanPayload((prev) => {
                                    return {
                                        ...prev,
                                        image: e,
                                    };
                                });
                            }}
                            defaultValue={panPayload?.image}
                            onRemoved={(e) => {
                                setIsPanEdited(true);
                                setPanPayload((prev) => {
                                    return {
                                        ...prev,
                                        image: null,
                                    };
                                });

                                // if (panFrontId) {
                                //     dispatchDeleteImageAttachment(
                                //         panFrontId,
                                //         () => {
                                //             console.log('REMOVE SUCCESS');
                                //         },
                                //         () => {
                                //             console.log('REMOVE FAILED');
                                //         },
                                //     );
                                // }
                            }}
                        />
                    </Box>
                    {false && (
                        <>
                            <Box
                                style={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    width: '100%',
                                    marginTop: 16,
                                }}
                            >
                                <CustomTextField
                                    value={panPayload?.rejectReason ?? ''}
                                    onChange={(e) => {
                                        setPanPayload((prev) => {
                                            return {
                                                ...prev,
                                                rejectReason: e.target.value,
                                            };
                                        });
                                    }}
                                    label="Reject Reason"
                                    variant="outlined"
                                    style={{
                                        width: '100%',
                                        marginBottom: 0,
                                    }}
                                    disabled={
                                        panPayload?.approvalStatus ===
                                        'REJECTED'
                                    }
                                />
                            </Box>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    alignItems: 'center',
                                    width: '100%',
                                    marginTop: 10,
                                }}
                            >
                                {panPayload?.approvalStatus ===
                                'PENDING_APPROVAL' ? (
                                    <>
                                        <Button
                                            disableElevation
                                            variant="outlined"
                                            style={
                                                !isPanEdited
                                                    ? {
                                                          backgroundColor:
                                                              '#fff',
                                                          border: '1px solid #f44336',
                                                          color: '#f44336',
                                                          fontWeight: 600,
                                                          marginRight: 10,
                                                      }
                                                    : { marginRight: 10 }
                                            }
                                            disabled={isPanEdited}
                                            onClick={(e) => {
                                                if (!!panPayload?.rejectReason)
                                                    setPanPayload((prev) => {
                                                        return {
                                                            ...prev,
                                                            approvalStatus:
                                                                'REJECTED',
                                                        };
                                                    });
                                                else {
                                                    showMessage(
                                                        'Rejection Reason is empty',
                                                        'error',
                                                    );
                                                }
                                                // setRejectModalOpen(true);
                                            }}
                                        >
                                            Reject
                                        </Button>
                                        <Button
                                            disableElevation
                                            variant="outlined"
                                            style={
                                                isPanEdited ||
                                                panPayload?.approvalStatus ===
                                                    'APPROVED'
                                                    ? {
                                                          //   marginRight: 20,
                                                      }
                                                    : {
                                                          //   marginRight: 20,
                                                          fontSize: 14,
                                                          color: '#4caf50',
                                                          border: '1px solid #4caf50',
                                                          fontWeight: 700,
                                                      }
                                            }
                                            onClick={(e) => {
                                                setPanPayload((prev) => {
                                                    return {
                                                        ...prev,
                                                        approvalStatus:
                                                            'APPROVED',
                                                    };
                                                });
                                            }}
                                            disabled={
                                                isPanEdited ||
                                                panPayload?.approvalStatus ===
                                                    'APPROVED'
                                            }
                                        >
                                            Approve
                                        </Button>
                                    </>
                                ) : (
                                    <div></div>
                                )}
                            </div>
                        </>
                    )}
                </Grid>
                {/* <Grid
                    item
                    xs={6}
                    style={
                        !!rcPayload?.manualVerificationRequired &&
                        rcPayload?.approvalStatus != 'APPROVED' &&
                        rcPayload?.approvalStatus != 'REJECTED'
                            ? styles.gridItemContainerWithErrorBorder
                            : styles.gridItemContainer
                    }
                >
                    <Box
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: '100%',
                        }}
                    >
                        <Typography
                            style={{
                                fontSize: '16px',
                                fontWeight: 700,
                            }}
                            variant="h6"
                            component="span"
                            gutterBottom
                        >
                            {'Vehicle RC Details'}
                        </Typography>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <div style={{ marginLeft: '1rem' }}>
                                {rcPayload?.approvalStatus === 'APPROVED' ? (
                                    <CheckMarkGreen style={{ width: '16px' }} />
                                ) : rcPayload?.approvalStatus === 'REJECTED' ? (
                                    <CrossMarkIcon style={{ width: '16px' }} />
                                ) : (
                                    <></>
                                )}
                            </div>
                            <Typography
                                style={{
                                    fontWeight: 700,
                                    marginLeft: 5,
                                    color:
                                        rcPayload?.approvalStatus === 'APPROVED'
                                            ? '#4caf50'
                                            : rcPayload?.approvalStatus ===
                                              'REJECTED'
                                            ? '#f44336'
                                            : !!rcPayload?.manualVerificationRequired
                                            ? '#f44336'
                                            : '',
                                    fontSize: '14px',
                                }}
                                variant="h6"
                                component="span"
                                gutterBottom
                            >
                                {rcPayload?.approvalStatus === 'APPROVED'
                                    ? 's'
                                    : rcPayload?.approvalStatus === 'REJECTED'
                                    ? 'Rejected'
                                    : !!rcPayload?.manualVerificationRequired
                                    ? 'Manual Verification Required'
                                    : ''}
                            </Typography>
                        </div>
                    </Box>

                    {rcPayload?.approvalStatus !== 'APPROVED' &&
                    rcPayload?.approvalStatus !== 'REJECTED' &&
                    !!rcPayload?.manualVerificationRequired ? (
                        <>
                            <Box
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    width: '100%',
                                    marginBottom: 16,
                                }}
                            >
                                <Typography
                                    style={{
                                        color: '#808080',
                                        fontSize: '14px',
                                        fontWeight: 500,
                                    }}
                                >
                                    {`Scanned Id : `}
                                </Typography>

                                <Typography
                                    style={{
                                        color: '#263238',
                                        fontSize: '14px',
                                        fontWeight: 700,
                                        marginLeft: 4,
                                    }}
                                >
                                    {` ${rcPayload?.autoKycDocumentNo}`}
                                </Typography>
                            </Box>

                            <Box
                                style={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    width: '100%',
                                }}
                            >
                                <CustomTextField
                                    value={rcPayload?.documentNo ?? ''}
                                    onChange={(e) => {
                                        setRcPayload((prev) => {
                                            return {
                                                ...prev,
                                                documentNo:
                                                    e.target.value?.trim(),
                                            };
                                        });
                                        setIsRcEdited(true);
                                    }}
                                    label="Edited Number"
                                    variant="outlined"
                                    style={{
                                        width: '100%',
                                        marginBottom: 16,
                                    }}
                                />
                            </Box>
                        </>
                    ) : (
                        <Box
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                width: '100%',
                                marginBottom: 16,
                            }}
                        >
                            <Typography
                                style={{
                                    color: '#808080',
                                    fontSize: '14px',
                                    fontWeight: 500,
                                }}
                            >
                                {`Scanned Id : `}
                            </Typography>

                            <Typography
                                style={{
                                    color: '#263238',
                                    fontSize: '14px',
                                    fontWeight: 700,
                                    marginLeft: 4,
                                }}
                            >
                                {` ${rcPayload?.autoKycDocumentNo}`}
                            </Typography>
                        </Box>
                    )}
                    <Box
                        style={{
                            width: '100%',
                        }}
                    >
                        <FileUploader
                            labelStyles={{
                                fontSize: '14px',
                                fontWeight: 600,
                            }}
                            placeholder={'Upload RC (front)'}
                            onFileUploaded={(e) => {
                                setIsRcEdited(true);
                                setRcPayload((prev) => {
                                    return {
                                        ...prev,
                                        image: e,
                                    };
                                });
                            }}
                            defaultValue={rcPayload?.image}
                            onRemoved={(e) => {
                                setRcPayload((prev) => {
                                    return {
                                        ...prev,
                                        image: null,
                                    };
                                });
                                setIsRcEdited(true);

                                // if (rcFrontId) {
                                //     dispatchDeleteImageAttachment(
                                //         rcFrontId,
                                //         () => {
                                //             console.log('REMOVE SUCCESS');
                                //         },
                                //         () => {
                                //             console.log('REMOVE FAILED');
                                //         },
                                //     );
                                // }
                            }}
                        />
                    </Box>

                    {false && (
                        <>
                            <Box
                                style={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    width: '100%',
                                    marginTop: 16,
                                }}
                            >
                                <CustomTextField
                                    value={rcPayload?.rejectReason}
                                    onChange={(e) => {
                                        if (!!rcPayload?.rejectReason)
                                            setRcPayload((prev) => {
                                                return {
                                                    ...prev,
                                                    rejectReason:
                                                        e.target.value,
                                                };
                                            });
                                        else {
                                            showMessage(
                                                'Rejection Reason is empty',
                                                'error',
                                            );
                                        }
                                    }}
                                    label="Reject Reason"
                                    variant="outlined"
                                    style={{
                                        width: '100%',
                                        marginBottom: 0,
                                    }}
                                    // disabled={!kycInfo?.aadharKycRequired}
                                />
                            </Box>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    alignItems: 'center',
                                    width: '100%',
                                    marginTop: 10,
                                }}
                            >
                                {rcPayload?.approvalStatus ===
                                'PENDING_APPROVAL' ? (
                                    <>
                                        <Button
                                            disableElevation
                                            variant="outlined"
                                            style={
                                                !isRcEdited
                                                    ? {
                                                          marginRight: 10,
                                                          backgroundColor:
                                                              '#fff',
                                                          border: '1px solid #f44336',
                                                          color: '#f44336',
                                                          fontWeight: 600,
                                                      }
                                                    : { marginRight: 10 }
                                            }
                                            disabled={
                                                isRcEdited ||
                                                rcPayload?.approvalStatus ===
                                                    'REJECTED'
                                            }
                                            onClick={(e) => {
                                                if (!!rcPayload?.rejectReason)
                                                    setRcPayload((prev) => {
                                                        return {
                                                            ...prev,
                                                            approvalStatus:
                                                                'REJECTED',
                                                        };
                                                    });
                                                else {
                                                    showMessage(
                                                        'Rejection Reason is empty',
                                                        'error',
                                                    );
                                                }
                                                // setRejectModalOpen(true);
                                            }}
                                        >
                                            Reject
                                        </Button>
                                        <Button
                                            disableElevation
                                            variant="outlined"
                                            style={
                                                isRcEdited ||
                                                rcPayload?.approvalStatus ===
                                                    'APPROVED'
                                                    ? {
                                                          //   marginRight: 20,
                                                      }
                                                    : {
                                                          //   marginRight: 20,
                                                          fontSize: 14,
                                                          color: '#4caf50',
                                                          border: '1px solid #4caf50',
                                                          fontWeight: 700,
                                                      }
                                            }
                                            onClick={(e) => {
                                                setRcPayload((prev) => {
                                                    return {
                                                        ...prev,
                                                        approvalStatus:
                                                            'APPROVED',
                                                    };
                                                });
                                                setRejectModalOpen(true);
                                            }}
                                            disabled={
                                                isRcEdited ||
                                                rcPayload?.approvalStatus ===
                                                    'APPROVED'
                                            }
                                        >
                                            Approve
                                        </Button>
                                    </>
                                ) : (
                                    <div></div>
                                )}
                            </div>
                        </>
                    )}
                </Grid> */}
                <Grid
                    item
                    xs={6}
                    style={
                        drivingPayload?.approvalStatus != 'APPROVED' &&
                        drivingPayload?.approvalStatus != 'REJECTED' &&
                        !!drivingPayload?.manualVerificationRequired &&
                        (!isDocumentNumberMatching?.drivingLicense ||
                            !isDrivingDobMatching)
                            ? styles.gridItemContainerWithErrorBorder
                            : styles.gridItemContainer
                    }
                >
                    <Box
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: '100%',
                        }}
                    >
                        <Typography
                            style={{
                                fontSize: '16px',
                                fontWeight: 'Bold',
                                color: '#14112d',
                            }}
                            variant="h6"
                            component="span"
                            gutterBottom
                        >
                            {'Driving License Details'}
                        </Typography>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <Typography
                                style={{
                                    fontWeight: 700,
                                    marginLeft: 5,
                                    color:
                                        drivingPayload?.approvalStatus ===
                                        'APPROVED'
                                            ? '#4caf50'
                                            : drivingPayload?.approvalStatus ===
                                              'REJECTED'
                                            ? '#f44336'
                                            : !!drivingPayload?.manualVerificationRequired &&
                                              !isDocumentNumberMatching?.drivingLicense
                                            ? '#f44336'
                                            : '',
                                    fontSize: '14px',
                                }}
                                variant="h6"
                                component="span"
                                gutterBottom
                            >
                                {drivingPayload?.approvalStatus === 'APPROVED'
                                    ? 'Verified'
                                    : drivingPayload?.approvalStatus ===
                                      'REJECTED'
                                    ? 'Rejected'
                                    : !!drivingPayload?.manualVerificationRequired &&
                                      !isDocumentNumberMatching?.drivingLicense
                                    ? 'Manual Verification Required'
                                    : ''}
                            </Typography>

                            <div style={{ marginLeft: '0.2rem' }}>
                                {drivingPayload?.approvalStatus ===
                                'APPROVED' ? (
                                    <CheckMarkGreen style={{ width: '16px' }} />
                                ) : drivingPayload?.approvalStatus ===
                                  'REJECTED' ? (
                                    <CrossMarkIcon style={{ width: '16px' }} />
                                ) : (
                                    <></>
                                )}
                            </div>
                        </div>
                    </Box>

                    {drivingPayload?.approvalStatus !== 'APPROVED' &&
                    drivingPayload?.approvalStatus !== 'REJECTED' &&
                    !!(
                        drivingPayload?.manualVerificationRequired &&
                        (!isDocumentNumberMatching?.drivingLicense ||
                            !isDrivingDobMatching)
                    ) ? (
                        <>
                            <Box
                                style={{
                                    width: '100%',
                                }}
                            >
                                <Box
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        marginBottom: '8px',
                                    }}
                                >
                                    <Box
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Typography
                                            style={{
                                                color: '#808080',
                                                fontSize: '14px',
                                                fontWeight: 500,
                                            }}
                                        >
                                            {!!drivingPayload?.autoKycDocumentNo
                                                ? `Scanned Id: `
                                                : 'Entered Id: '}
                                        </Typography>

                                        <Typography
                                            style={
                                                !isDocumentNumberMatching?.drivingLicense
                                                    ? {
                                                          color: '#f44336',
                                                          fontSize: '14px',
                                                          fontWeight: 700,
                                                          marginLeft: 4,
                                                      }
                                                    : {
                                                          color: '#263238',
                                                          fontSize: '14px',
                                                          fontWeight: 700,
                                                          marginLeft: 4,
                                                      }
                                            }
                                        >
                                            {`${
                                                !!drivingPayload?.autoKycDocumentNo
                                                    ? drivingPayload?.autoKycDocumentNo
                                                    : drivingPayload?.documentNo ??
                                                      ''
                                            }`}
                                        </Typography>

                                        {!isDocumentNumberMatching?.drivingLicense && (
                                            <ExclaimIcon
                                                style={{
                                                    width: '16px',
                                                    height: '16px',
                                                    marginLeft: 4,
                                                }}
                                            />
                                        )}
                                    </Box>

                                    {!isDocumentNumberMatching?.drivingLicense &&
                                        !!drivingPayload?.autoKycDocumentNo && (
                                            <Box
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    marginLeft: '12px',
                                                }}
                                            >
                                                <Typography
                                                    style={{
                                                        color: '#808080',
                                                        fontSize: '14px',
                                                        fontWeight: 500,
                                                    }}
                                                >
                                                    {'Edited:'}
                                                </Typography>

                                                <Typography
                                                    style={{
                                                        color: '#263238',
                                                        fontSize: '14px',
                                                        fontWeight: 700,
                                                        marginLeft: 4,
                                                    }}
                                                >
                                                    {drivingPayload?.documentNo}
                                                </Typography>
                                            </Box>
                                        )}
                                </Box>

                                <Box
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        marginBottom: 16,
                                    }}
                                >
                                    <Box
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Typography
                                            style={{
                                                color: '#808080',
                                                fontSize: '14px',
                                                fontWeight: 500,
                                            }}
                                        >
                                            {!!drivingPayload?.autoKycDocumentDob
                                                ? `Scanned DOB: `
                                                : 'Entered DOB: '}
                                        </Typography>

                                        <Typography
                                            style={
                                                !isDrivingDobMatching
                                                    ? {
                                                          color: '#f44336',
                                                          fontSize: '14px',
                                                          fontWeight: 700,
                                                          marginLeft: 4,
                                                      }
                                                    : {
                                                          color: '#263238',
                                                          fontSize: '14px',
                                                          fontWeight: 700,
                                                          marginLeft: 4,
                                                      }
                                            }
                                        >
                                            {`${
                                                !!drivingPayload?.autoKycDocumentDob
                                                    ? moment(
                                                          drivingPayload?.autoKycDocumentDob,
                                                      )?.format(dobFormatString)
                                                    : !!drivingPayload?.dob
                                                    ? moment(
                                                          drivingPayload?.dob,
                                                      )?.format(dobFormatString)
                                                    : ''
                                            }`}
                                        </Typography>

                                        {!isDrivingDobMatching &&
                                            !!drivingPayload?.dob && (
                                                <ExclaimIcon
                                                    style={{
                                                        width: '16px',
                                                        height: '16px',
                                                        marginLeft: 4,
                                                    }}
                                                />
                                            )}
                                    </Box>

                                    {!isDrivingDobMatching &&
                                        !!drivingPayload?.autoKycDocumentDob && (
                                            <Box
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    marginLeft: '12px',
                                                }}
                                            >
                                                <Typography
                                                    style={{
                                                        color: '#808080',
                                                        fontSize: '14px',
                                                        fontWeight: 500,
                                                    }}
                                                >
                                                    {'Edited DOB:'}
                                                </Typography>

                                                <Typography
                                                    style={{
                                                        color: '#263238',
                                                        fontSize: '14px',
                                                        fontWeight: 700,
                                                        marginLeft: 4,
                                                    }}
                                                >
                                                    {` ${
                                                        // !!drivingPayload?.autoKycDocumentDob
                                                        //     ? moment(
                                                        //           drivingPayload?.autoKycDocumentDob,
                                                        //       )?.format(
                                                        //           dobFormatString,
                                                        //       ) :
                                                        !!drivingPayload?.dob
                                                            ? moment(
                                                                  drivingPayload?.dob,
                                                              )?.format(
                                                                  dobFormatString,
                                                              )
                                                            : ''
                                                    }`}
                                                </Typography>
                                            </Box>
                                        )}
                                </Box>
                            </Box>

                            {/* INPUT FIELDS
                            <Box
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    width: '100%',
                                }}
                            >
                                <CustomTextField
                                    value={panPayload?.documentNo ?? ''}
                                    onChange={(e) => {
                                        setPanPayload((prev) => {
                                            return {
                                                ...prev,
                                                documentNo:
                                                    e.target.value?.trim(),
                                            };
                                        });
                                        setIsPanEdited(true);
                                    }}
                                    label="Edited Number"
                                    variant="outlined"
                                    style={
                                        isDrivingDobMatching
                                            ? {
                                                  width: '100%',
                                                  marginBottom: 16,
                                              }
                                            : {
                                                  width: '49%',
                                                  marginBottom: 16,
                                              }
                                    }
                                    InputProps={{
                                        readOnly: true,
                                        endAdornment: <ExclaimIcon />,
                                    }}
                                />

                                {!isDrivingDobMatching && (
                                    <CustomDatePicker
                                        format="dd-MMM-yyyy"
                                        id="dateOfBirth"
                                        name="dateOfBirth"
                                        label="Edited DOB"
                                        variant="outlined"
                                        disabled={true}
                                        value={kycInfo?.dob ?? ''}
                                        onChange={(e) => {
                                            setkycInfo((prev) => {
                                                return {
                                                    ...prev,
                                                    dob:
                                                        moment(e)?.valueOf() > 0
                                                            ? moment(e)?.format(
                                                                  'DD-MMM-YYYY',
                                                              )
                                                            : null,
                                                };
                                            });
                                            setIsPanEdited(true);
                                        }}
                                        showInput={true}
                                        style={{
                                            width: '49%',
                                            marginBottom: 16,
                                        }}
                                        InputProps={{
                                            readOnly: true,
                                            endAdornment: <ExclaimIcon />,
                                        }}
                                    />
                                )}
                            </Box> */}
                        </>
                    ) : (
                        <Box
                            style={{
                                width: '100%',
                            }}
                        >
                            <Box
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    width: '100%',
                                    marginBottom: '8px',
                                }}
                            >
                                <Typography
                                    style={{
                                        color: '#808080',
                                        fontSize: '14px',
                                        fontWeight: 500,
                                    }}
                                >
                                    {!!drivingPayload?.autoKycDocumentNo
                                        ? `Scanned Id: `
                                        : 'Entered Id: '}
                                </Typography>

                                <Typography
                                    style={{
                                        color: '#263238',
                                        fontSize: '14px',
                                        fontWeight: 700,
                                        marginLeft: 4,
                                    }}
                                >
                                    {` ${
                                        !!drivingPayload?.autoKycDocumentNo
                                            ? drivingPayload?.autoKycDocumentNo
                                            : drivingPayload?.documentNo ?? ''
                                    }`}
                                </Typography>
                            </Box>

                            <Box
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    marginBottom: 16,
                                }}
                            >
                                <Box
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'flex-start',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Typography
                                        style={{
                                            color: '#808080',
                                            fontSize: '14px',
                                            fontWeight: 500,
                                        }}
                                    >
                                        {!!drivingPayload?.autoKycDocumentDob
                                            ? `Scanned DOB: `
                                            : 'Entered DOB: '}
                                    </Typography>

                                    <Typography
                                        style={
                                            !isDrivingDobMatching
                                                ? {
                                                      color: '#f44336',
                                                      fontSize: '14px',
                                                      fontWeight: 700,
                                                      marginLeft: 4,
                                                  }
                                                : {
                                                      color: '#263238',
                                                      fontSize: '14px',
                                                      fontWeight: 700,
                                                      marginLeft: 4,
                                                  }
                                        }
                                    >
                                        {`${
                                            !!drivingPayload?.autoKycDocumentDob
                                                ? moment(
                                                      drivingPayload?.autoKycDocumentDob,
                                                  )?.format(dobFormatString)
                                                : !!drivingPayload?.dob
                                                ? moment(
                                                      drivingPayload?.dob,
                                                  )?.format(dobFormatString)
                                                : ''
                                        }`}
                                    </Typography>

                                    {!isDrivingDobMatching &&
                                        !!drivingPayload?.dob && (
                                            <ExclaimIcon
                                                style={{
                                                    width: '16px',
                                                    height: '16px',
                                                    marginLeft: 4,
                                                }}
                                            />
                                        )}
                                </Box>

                                {!isDrivingDobMatching &&
                                    !!drivingPayload?.autoKycDocumentDob && (
                                        <Box
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                marginLeft: '12px',
                                            }}
                                        >
                                            <Typography
                                                style={{
                                                    color: '#808080',
                                                    fontSize: '14px',
                                                    fontWeight: 500,
                                                }}
                                            >
                                                {'Edited DOB:'}
                                            </Typography>

                                            <Typography
                                                style={{
                                                    color: '#263238',
                                                    fontSize: '14px',
                                                    fontWeight: 700,
                                                    marginLeft: 4,
                                                }}
                                            >
                                                {` ${
                                                    // !!drivingPayload?.autoKycDocumentDob
                                                    //     ? moment(
                                                    //           drivingPayload?.autoKycDocumentDob,
                                                    //       )?.format(
                                                    //           dobFormatString,
                                                    //       ) :
                                                    !!drivingPayload?.dob
                                                        ? moment(
                                                              drivingPayload?.dob,
                                                          )?.format(
                                                              dobFormatString,
                                                          )
                                                        : ''
                                                }`}
                                            </Typography>
                                        </Box>
                                    )}
                            </Box>
                        </Box>
                    )}

                    <Box
                        style={{
                            display: 'flex',
                            justifyContent: 'left',
                            alignItems: 'center',
                            width: '100%',
                        }}
                    >
                        <Grid
                            item
                            sm={6}
                            xs={12}
                            style={{
                                maxWidth: 'calc(50% - 4px)',
                                marginRight: '4px',
                            }}
                        >
                            <FileUploader
                                labelStyles={{
                                    fontSize: '14px',
                                    fontWeight: 600,
                                }}
                                defaultValue={drivingPayload?.imageFront}
                                placeholder={'Upload license (Front)'}
                                onFileUploaded={(e) => {
                                    setDrivingPayload((prev) => {
                                        return {
                                            ...prev,
                                            imageFront: e,
                                        };
                                    });
                                }}
                                onRemoved={(e) => {
                                    setDrivingPayload((prev) => {
                                        return {
                                            ...prev,
                                            imageFront: null,
                                        };
                                    });

                                    // if (aadhaarFrontId) {
                                    //     dispatchDeleteImageAttachment(
                                    //         aadhaarFrontId,
                                    //         () => {
                                    //             console.log('REMOVE SUCCESS');
                                    //         },
                                    //         () => {
                                    //             console.log('REMOVE FAILED');
                                    //         },
                                    //     );
                                    // }
                                }}
                            />
                        </Grid>
                        <Grid
                            item
                            sm={6}
                            xs={12}
                            style={{
                                maxWidth: 'calc(50% - 4px)',
                                marginLeft: '4px',
                            }}
                        >
                            <FileUploader
                                labelStyles={{
                                    fontSize: '14px',
                                    fontWeight: 600,
                                }}
                                defaultValue={drivingPayload?.imageBack}
                                placeholder={'Upload license (Back)'}
                                onFileUploaded={(e) => {
                                    setDrivingPayload((prev) => {
                                        return {
                                            ...prev,
                                            imageBack: e,
                                        };
                                    });
                                }}
                                onRemoved={(e) => {
                                    setDrivingPayload((prev) => {
                                        return {
                                            ...prev,
                                            imageBack: null,
                                        };
                                    });

                                    // if (aadhaarBackId) {
                                    //     dispatchDeleteImageAttachment(
                                    //         aadhaarBackId,
                                    //         () => {
                                    //             console.log('REMOVE SUCCESS');
                                    //         },
                                    //         () => {
                                    //             console.log('REMOVE FAILED');
                                    //         },
                                    //     );
                                    // }
                                }}
                            />
                        </Grid>
                    </Box>
                    {false && (
                        <>
                            <Box
                                style={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    width: '100%',
                                    marginTop: 16,
                                }}
                            >
                                <CustomTextField
                                    value={drivingPayload?.rejectReason ?? ''}
                                    onChange={(e) => {
                                        setDrivingPayload((prev) => {
                                            return {
                                                ...prev,
                                                rejectReason: e.target.value,
                                            };
                                        });
                                    }}
                                    label="Reject Reason"
                                    variant="outlined"
                                    style={{
                                        width: '100%',
                                        marginBottom: 0,
                                    }}
                                    disabled={
                                        drivingPayload?.approvalStatus ===
                                        'REJECTED'
                                    }
                                    // disabled={!kycInfo?.aadharKycRequired}
                                />
                            </Box>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    alignItems: 'center',
                                    width: '100%',
                                    marginTop: 10,
                                }}
                            >
                                {drivingPayload?.approvalStatus ===
                                'PENDING_APPROVAL' ? (
                                    <>
                                        <Button
                                            disableElevation
                                            variant="outlined"
                                            style={
                                                !isDrivingEdited
                                                    ? {
                                                          marginRight: 10,
                                                          backgroundColor:
                                                              '#fff',
                                                          border: '1px solid #f44336',
                                                          color: '#f44336',
                                                          fontWeight: 600,
                                                      }
                                                    : { marginRight: 10 }
                                            }
                                            disabled={isDrivingEdited}
                                            onClick={(e) => {
                                                if (
                                                    !!drivingPayload?.rejectReason
                                                )
                                                    setDrivingPayload(
                                                        (prev) => {
                                                            return {
                                                                ...prev,
                                                                approvalStatus:
                                                                    'REJECTED',
                                                            };
                                                        },
                                                    );
                                                else {
                                                    showMessage(
                                                        'Rejection Reason is empty',
                                                        'error',
                                                    );
                                                }
                                            }}
                                        >
                                            Reject
                                        </Button>
                                        <Button
                                            disableElevation
                                            variant="outlined"
                                            style={
                                                !!isDrivingEdited ||
                                                !!drivingPayload?.approvalStatus ===
                                                    'APPROVED'
                                                    ? {
                                                          //   marginRight: 20,
                                                      }
                                                    : {
                                                          //   marginRight: 20,
                                                          fontSize: 14,
                                                          color: '#4caf50',
                                                          border: '1px solid #4caf50',
                                                          fontWeight: 700,
                                                      }
                                            }
                                            onClick={(e) => {
                                                setDrivingPayload((prev) => {
                                                    return {
                                                        ...prev,
                                                        approvalStatus:
                                                            'APPROVED',
                                                    };
                                                });
                                            }}
                                            disabled={
                                                isDrivingEdited ||
                                                drivingPayload?.approvalStatus ===
                                                    'APPROVED'
                                            }
                                        >
                                            Approve
                                        </Button>
                                    </>
                                ) : (
                                    <div></div>
                                )}
                            </div>
                        </>
                    )}
                </Grid>
            </Grid>
        </Box>
    );
}

const styles = {
    gridItemContainer: {
        display: 'flex',
        // justifyContent: 'space-around',
        alignItems: 'center',
        flexDirection: 'column',
        maxWidth: 'calc(50% - 12px)',
        marginRight: '12px',
        marginBottom: '12px',
        borderRadius: '16px',
        border: 'solid 1px rgba(38, 50, 56, 0.16)',
        backgroundColor: '#fff',
        padding: '12px 12px',
    },
    gridItemContainerWithErrorBorder: {
        display: 'flex',
        // justifyContent: 'space-around',
        alignItems: 'center',
        flexDirection: 'column',
        maxWidth: 'calc(50% - 12px)',
        marginRight: '12px',
        marginBottom: '12px',
        borderRadius: '16px',
        border: 'solid 2px #f44336',
        backgroundColor: '#fff',
        padding: '12px 12px',
    },
};

export { VerifyKyc };
