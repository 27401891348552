import * as constants from '../constants';

const defaultState = {
    all: {
        // dateDisplay: '16 June 2021',
        // totalCount: 4,
        // presentCount: 1,
        // absentCount: 3,
        // pendingApproval: 0,
        // lineItems: [
        //     {
        //         id: 200,
        //         staffId: 161923808380838,
        //         name: 'Avvaiyanban',
        //         displayText: 'Delivery Boy',
        //         hubName: 'Bangalore West',
        //         attendanceHours: '3:00 Hrs',
        //         status: 'Absent',
        //         punchIn: {
        //             // time: '06:19 pm',
        //             // status: 'REJECTED',
        //             // address:
        //             //     '3B, KG Eternia, KB Dasan Road, Alwarpet, Chennai - 600018',
        //             // dateEpochMillis: 1624190772305,
        //             // latitude: '10.0',
        //             // longitude: '14.5',
        //             // notes: 'DE logged in correctly',
        //         },
        //         punchOut: {
        //             // time: '09:19 pm',
        //             // status: 'APPROVED',
        //             // address:
        //             //     '3B, KG Eternia, KB Dasan Road, Alwarpet, Chennai - 600018',
        //             // dateEpochMillis: 1624190772305,
        //             // latitude: '10.0',
        //             // longitude: '14.5',
        //             // notes: 'DE logged in correctly',
        //         },
        //     },
        //     {
        //         id: 201,
        //         staffId: 161923808380839,
        //         name: '2',
        //         displayText: 'Delivery Boy',
        //         hubName: 'Bangalore West',
        //         attendanceHours: '3:00 Hrs',
        //         kycVerified: true,
        //         status: 'Present',
        //         punchIn: {
        //             time: '06:19 pm',
        //             status: 'APPROVED',
        //             address:
        //                 '3B, KG Eternia, KB Dasan Road, Alwarpet, Chennai - 600018',
        //             dateEpochMillis: 1624190772305,
        //             latitude: '10.0',
        //             longitude: '14.5',
        //             notes: 'DE logged in correctly',
        //         },
        //         punchOut: {
        //             time: '09:19 pm',
        //             status: 'REJECTED',
        //             address:
        //                 '3B, KG Eternia, KB Dasan Road, Alwarpet, Chennai - 600018',
        //             dateEpochMillis: 1624190772305,
        //             latitude: '10.0',
        //             longitude: '14.5',
        //             notes: 'DE logged in correctly',
        //         },
        //     },
        //     {
        //         id: 205,
        //         staffId: 161923808380840,
        //         name: '3',
        //         displayText: 'Delivery Boy',
        //         hubName: 'Bangalore West',
        //         attendanceHours: '3:00 Hrs',
        //         kycVerified: false,
        //         status: 'Present',
        //         punchIn: {
        //             time: '06:19 pm',
        //             status: 'APPROVED',
        //             address:
        //                 '3B, KG Eternia, KB Dasan Road, Alwarpet, Chennai - 600018',
        //             dateEpochMillis: 1624190772305,
        //             latitude: '10.0',
        //             longitude: '14.5',
        //             notes: 'DE logged in correctly',
        //         },
        //         punchOut: {
        //             time: '09:19 pm',
        //             status: 'APPROVED',
        //             address:
        //                 '3B, KG Eternia, KB Dasan Road, Alwarpet, Chennai - 600018',
        //             dateEpochMillis: 1624190772305,
        //             latitude: '10.0',
        //             longitude: '14.5',
        //             notes: 'DE logged in correctly',
        //         },
        //     },
        //     {
        //         id: 200,
        //         staffId: 161923808380841,
        //         name: '4',
        //         displayText: 'Delivery Boy',
        //         hubName: 'Bangalore West',
        //         attendanceHours: '3:00 Hrs',
        //         kycVerified: false,
        //         status: 'Absent',
        //         punchIn: {
        //             time: '06:19 pm',
        //             status: 'REJECTED',
        //             address:
        //                 '3B, KG Eternia, KB Dasan Road, Alwarpet, Chennai - 600018',
        //             dateEpochMillis: 1624190772305,
        //             latitude: '10.0',
        //             longitude: '14.5',
        //             notes: 'DE logged in correctly',
        //         },
        //         punchOut: {
        //             // time: '09:19 pm',
        //             // status: 'APPROVED',
        //             // address:
        //             //     '3B, KG Eternia, KB Dasan Road, Alwarpet, Chennai - 600018',
        //             // dateEpochMillis: 1624190772305,
        //             // latitude: '10.0',
        //             // longitude: '14.5',
        //             // notes: 'DE logged in correctly',
        //         },
        //     },
        // ],
    },
    timelineActivity: {
        // dateDisplay: '20 - June - 2021',
        // punchIn: {
        //     time: '07:36 am',
        //     address:
        //         '3B, KG Eternia, KB Dasan Road, Alwarpet, Chennai - 600018',
        //     lateBy: null,
        //     approvedBy: 'AdminAvvai',
        //     approvalTime: '05:47 pm',
        //     s3ImageUrl: null,
        // },
        // punchOut: {
        //     time: '05:36 pm',
        //     address:
        //         '3B, KG Eternia, KB Dasan Road, Alwarpet, Chennai - 600018',
        //     lateBy: null,
        //     approvedBy: 'AdminAvvai',
        //     approvalTime: '05:50 pm',
        //     s3ImageUrl: null,
        // },
    },
    punchInOut: {},
    approvePunchInOut: {},
};

export default function attendanceReducer(state = defaultState, action) {
    switch (action.type) {
        case constants.SET_ALL_ATTENDANCE_DATA:
            return {
                ...state,
                all: action.payload,
            };
        case constants.SET_TIMELINE_ACTIVITY_DATA:
            return {
                ...state,
                timelineActivity: action.payload,
            };
        case constants.SET_PUNCH_IN_OUT_DATA:
            return { ...state, punchInOut: action.payload };

        case constants.APPROVE_PUNCH_IN_OUT_DATA:
            return { ...state, approvePunchInOut: action.payload };

        case constants.REASON_STATIC_VALUES_DATA:
            return { ...state, attendanceReasons: action.payload };
        default:
            return state;
    }
}
