import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { Polyline, Marker, Popup, useMap } from 'react-leaflet';
import {
    redMarker,
    greenMarker,
    orangeFilledMarker,
    purpleMarker,
    blueGreenMarker,
} from '../../../../components/Map/MarkerIcons';
import moment from 'moment';
import { getTimeAsDuration, getTimeDifference } from '../../../../utils/helper';
import ReactMarkerClusterGroup from './../../ReactMarkerClusterGroup';
import IdlePointMarker from './IdlePointMarker';
interface IdlePointMarkerClusterProps {
    trackData?: any;
    aggregatedTrackData?: any;
    events?: any;
}

const IdlePointMarkerCluster: React.FC<IdlePointMarkerClusterProps> = ({
    aggregatedTrackData,
    events,
}) => {
    return (
        <>
            {aggregatedTrackData?.idleLocationPoints?.length > 0 && (
                <ReactMarkerClusterGroup
                    onMouseOver={(cluster?: any) => {
                        console.log(cluster);
                    }}
                >
                    {aggregatedTrackData?.idleLocationPoints
                        ?.filter((item?: any) => {
                            let idlePoint = item.split(',');
                            return (
                                parseInt(idlePoint[0]) >
                                    aggregatedTrackData?.locationPoints[0]
                                        ?.deviceTimeEpoch &&
                                parseInt(idlePoint[0]) <
                                    aggregatedTrackData?.locationPoints[
                                        aggregatedTrackData?.locationPoints
                                            ?.length - 1
                                    ]?.deviceTimeEpoch
                            );
                        })
                        ?.map((geo?: any, index?: number) => {
                            const data = geo.split(',');

                            return <IdlePointMarker data={data} />;
                        })}
                    {events?.userActivityLineItems?.length > 0 &&
                        events?.userActivityLineItems
                            ?.filter((item?: any) => {
                                console.log('geoidlePoint', item);
                                return (
                                    parseInt(item?.time) >
                                        aggregatedTrackData?.locationPoints?.[0]
                                            ?.deviceTimeEpoch &&
                                    parseInt(item?.time) <
                                        aggregatedTrackData?.locationPoints?.[
                                            aggregatedTrackData?.locationPoints
                                                ?.length - 1
                                        ]?.deviceTimeEpoch
                                );
                            })
                            ?.map((geo?: any) => {
                                let point: any = [
                                    geo?.latitude,
                                    geo?.longitude,
                                ];
                                let popupInfo = {
                                    Description: geo?.description ?? '',
                                    ...geo?.properties,
                                };

                                return (
                                    <Marker
                                        // if position changes, marker will drift its way to new position
                                        position={
                                            point[0] && point[1] ? point : {}
                                        }
                                        icon={
                                            geo?.type === 'ORDER_PICKED'
                                                ? purpleMarker
                                                : blueGreenMarker
                                        }
                                    >
                                        <Popup>
                                            {Object.keys(popupInfo)?.map(
                                                (key, index) => {
                                                    return (
                                                        <Box
                                                            style={{
                                                                width: 200,
                                                                marginTop:
                                                                    index === 0
                                                                        ? 20
                                                                        : 0,
                                                                display: 'flex',
                                                                flexDirection:
                                                                    'row',
                                                                alignItems:
                                                                    'center',
                                                                justifyContent:
                                                                    'space-between',
                                                            }}
                                                        >
                                                            <Typography
                                                                gutterBottom
                                                                variant="body2"
                                                                component="div"
                                                                style={{
                                                                    fontWeight: 700,
                                                                    fontSize: 14,
                                                                }}
                                                            >
                                                                {key} :{' '}
                                                            </Typography>
                                                            <Typography
                                                                gutterBottom
                                                                variant="body2"
                                                                component="div"
                                                                style={{
                                                                    fontSize: 14,
                                                                }}
                                                            >
                                                                {popupInfo[key]}
                                                            </Typography>
                                                        </Box>
                                                    );
                                                },
                                            )}
                                        </Popup>
                                    </Marker>
                                );
                            })}
                </ReactMarkerClusterGroup>
            )}
        </>
    );
};

export default IdlePointMarkerCluster;
