import { Backdrop, CircularProgress, makeStyles } from '@material-ui/core';
import React from 'react';
import './styles.scss';

const useStyles = makeStyles((theme) => ({
    backDropRoot: {
        position: 'absolute',
        zIndex: theme.zIndex.drawer - 1,
        background: 'rgba(0, 0, 0, 0.2)',
    },
    loader: {
        opacity: '1 !important',
    },
}));

export const Spinner = ({ children, isBackDrop = false, loading }) => {
    const classes = useStyles();
    if (isBackDrop)
        return (
            <div>
                {children}
                <Backdrop
                    invisible={false}
                    className={classes.backDropRoot}
                    open={loading}
                    onClick={null}
                >
                    <CircularProgress
                        className={classes.loader}
                        color="primary"
                    />
                </Backdrop>
            </div>
        );

    // old spinner
    return (
        <div className="jspin-nested-loading">
            {loading ? (
                <div className="jspin jspin-spinning">
                    <div className="jspin-dot jspin-dot-spin">
                        <CircularProgress color="primary" />
                    </div>
                </div>
            ) : null}
            <div className={`jspin-container ${loading ? 'jspin-blur' : ''}`}>
                {children}
            </div>
        </div>
    );
};
