import React from 'react';
import BatteryFullIcon from '@material-ui/icons/BatteryFull';
import Battery90 from '@material-ui/icons/Battery90';
import Battery80Icon from '@material-ui/icons/Battery80';
import Battery60Icon from '@material-ui/icons/Battery60';
import Battery50Icon from '@material-ui/icons/Battery50';
import Battery30Icon from '@material-ui/icons/Battery30';
import Battery20Icon from '@material-ui/icons/Battery20';
import BatteryAlertIcon from '@material-ui/icons/BatteryAlert';

interface BatteryIconProps {
    percentage: number;
    fontSize: number;
}

const BatteryIcon: React.FC<BatteryIconProps> = ({
    percentage,
    fontSize = 24,
}) => {
    const getBatteryIcon = () => {
        if (percentage > 90) {
            return (
                <BatteryFullIcon
                    style={{
                        color: '#4CAF50',
                        fontSize: fontSize,
                    }}
                />
            );
        } else if (percentage > 75) {
            return (
                <Battery90
                    style={{
                        color: '#4CAF50',
                        fontSize: fontSize,
                    }}
                />
            );
        } else if (percentage > 60) {
            return (
                <Battery80Icon
                    style={{
                        color: '#4CAF50',
                        fontSize: fontSize,
                    }}
                />
            );
        } else if (percentage > 45) {
            return (
                <Battery60Icon
                    style={{
                        color: 'orange',
                        fontSize: fontSize,
                    }}
                />
            );
        } else if (percentage > 30) {
            return (
                <Battery50Icon
                    style={{
                        color: 'orange',
                        fontSize: fontSize,
                    }}
                />
            );
        } else if (percentage > 15) {
            return (
                <Battery30Icon
                    style={{
                        color: 'red',
                        fontSize: fontSize,
                    }}
                />
            );
        } else if (percentage > 5) {
            return (
                <Battery20Icon
                    style={{
                        color: 'red',
                        fontSize: fontSize,
                    }}
                />
            );
        } else {
            return (
                <BatteryAlertIcon
                    style={{
                        color: 'red',
                        fontSize: fontSize,
                    }}
                />
            );
        }
    };

    return <div>{getBatteryIcon()}</div>;
};

export default BatteryIcon;
