import React, { useState } from 'react';
import { HubList } from './HubList';
import HubDetail from './HubDetail';
import { HubSwitchKey } from './Settings';

export default function HubAndShift({ ...props }) {
    const [hubNShiftScreenSwitch, setHubNShiftScreenSwitch] = useState(
        HubSwitchKey.HUB_VIEW,
    );
    const [selectedHub, setSelectedHub] = useState(null);
    switch (hubNShiftScreenSwitch) {
        case HubSwitchKey.HUB_VIEW:
            return (
                <HubList
                    {...{
                        ...props,
                        hubNShiftScreenSwitch,
                        setHubNShiftScreenSwitch,
                        setSelectedHub,
                    }}
                />
            );
        case HubSwitchKey.HUB_DETAIL_VIEW:
            return (
                <HubDetail
                    {...{
                        ...props,
                        hubNShiftScreenSwitch,
                        setHubNShiftScreenSwitch,
                        selectedHub,
                    }}
                />
            );
        case HubSwitchKey.HUB_CALENDAR_VIEW:
            return <></>;
        default:
            return (
                <HubList
                    {...{
                        ...props,
                        hubNShiftScreenSwitch,
                        setHubNShiftScreenSwitch,
                    }}
                />
            );
    }
}
