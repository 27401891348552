import { Box, Button, MenuItem } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Modal from './Modal';
import { connect } from 'react-redux';
import { updateLeadStatus } from '../../store/actions/leadActionCreator';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { CustomTextField } from '../Textfield';
import {
    fetchPreferredWorkLocations,
    GetOrgs,
} from '../../store/actions/settingsActionCreator';
import { createStaff } from '../../store/actions/staffActionCreator';
import moment from 'moment';

function ConvertLeadModal({
    leadInfo,
    open,
    onClose,
    defaultValue = {},
    orgList,
    preferredWorkLocationsList,
    dispatchGetOrganisations,
    dispatchfetchPreferredWorkLocations,
    dispatchCreateStaff,
    dispatchUpdateLeadStatus,
    ...props
}) {
    const [isOpen, toggleModal] = useState(open);
    const [loading, toggleLoading] = useState(false);
    const [isPreferredWorkLocRequired, setIsPreferredWorkLocRequired] =
        useState(false);

    const handleClose = () => {
        toggleModal(!isOpen);
        onClose && onClose();
    };

    const validationSchema = yup.lazy((values) => {
        return yup.object({
            orgId: yup.string().required('Organization cannot be empty'),
            zoneId: !isPreferredWorkLocRequired
                ? yup.string().notRequired()
                : yup
                      .string()
                      .required('Preferred Work Locations cannot be empty'),
        });
    });

    const actions = [
        <Button
            key={1}
            variant="contained"
            color="default"
            className={`cancelButton`}
            onClick={handleClose}
            style={{
                boxShadow: 'none',
            }}
            disableElevation
        >
            Cancel
        </Button>,
        <Button
            key={2}
            variant="contained"
            color="primary"
            className={`saveButton`}
            type="submit"
            style={{
                boxShadow: 'none',
            }}
            disableElevation
        >
            Convert
        </Button>,
    ];

    useEffect(() => {
        dispatchGetOrganisations();
        dispatchfetchPreferredWorkLocations(leadInfo.id, (resp) => {
            setIsPreferredWorkLocRequired(
                !!resp?.response?.preferredWorkLocationsList?.length,
            );
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const formik = useFormik({
        initialValues: {
            orgId: defaultValue?.orgId ?? '',
            zoneId: defaultValue?.zoneId ?? '',
        },
        validationSchema,
        onSubmit: async (values) => {
            await toggleLoading(true);
            console.log('ONSUBMIT', {
                ...formik.values,
            });
            dispatchUpdateLeadStatus(
                leadInfo.id,
                {
                    status: 'Converted',
                    staffRequest: {
                        name: leadInfo?.name,
                        contactNumber: leadInfo?.contactNumber,
                        vehicleTypeId: leadInfo?.vehicleTypeId,
                        vehicleNumber: leadInfo?.vehicleNumber,
                        hubId: null,
                        selfieEnabled: true,
                        trackAndTraceEnabled: true,
                        geofenceAutoApproval: true,
                        dateOfBirthEpochMillis: leadInfo?.dateOfBirthEpochMillis
                            ? moment(leadInfo?.dateOfBirthEpochMillis).valueOf()
                            : null,
                        doj: moment().valueOf(),
                        freelancer: false,
                        orgId: formik.values?.orgId ?? null,
                        zoneId: formik.values.zoneId ?? null,
                        isHireFlow: true,
                    },
                },
                async () => {
                    console.log('Add Staff SUCCESS');
                    await toggleLoading(false);
                    handleClose();
                },
                async () => {
                    await toggleLoading(false);
                    console.log('Add Staff FAILED');
                },
            );
        },
    });

    return (
        <Modal
            onClose={() => handleClose(defaultValue)}
            open={isOpen}
            title={'Convert to Staff'}
            actions={actions}
            loading={loading}
            isForm={true}
            onSubmit={formik.handleSubmit}
        >
            <Box
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <CustomTextField
                    label={
                        !!formik.values.orgId
                            ? 'Organization'
                            : 'Select Organization'
                    }
                    select
                    style={{
                        marginTop: '16px',
                        marginBottom: '16px',
                        width: '98%',
                        marginRight: 5,
                    }}
                    id="orgId"
                    name="orgId"
                    value={formik.values.orgId}
                    onChange={(e) => {
                        formik.handleChange(e);
                    }}
                    error={
                        formik.touched.orgId
                            ? Boolean(formik.errors.orgId)
                            : false
                    }
                    helperText={formik.touched.orgId && formik.errors.orgId}
                >
                    {orgList?.length === 0 && (
                        <MenuItem value={undefined}>No Data found</MenuItem>
                    )}
                    {orgList?.map((org) => (
                        <MenuItem value={org.id}>{org.name}</MenuItem>
                    ))}
                </CustomTextField>
                <CustomTextField
                    label={'Preferred Work Locations'}
                    select
                    style={{
                        marginBottom: '16px',
                        width: '98%',
                        marginRight: 5,
                    }}
                    id="zoneId"
                    name="zoneId"
                    value={formik.values.zoneId}
                    onChange={(e) => {
                        formik.handleChange(e);
                    }}
                    error={
                        formik.touched.zoneId
                            ? Boolean(formik.errors.zoneId)
                            : false
                    }
                    helperText={formik.touched.zoneId && formik.errors.zoneId}
                >
                    {preferredWorkLocationsList?.length === 0 && (
                        <MenuItem value={undefined}>No Data found</MenuItem>
                    )}
                    {preferredWorkLocationsList?.map((loc) => (
                        <MenuItem value={loc.id}>{loc.zoneName}</MenuItem>
                    ))}
                </CustomTextField>
            </Box>
        </Modal>
    );
}

const mapStateToProps = (state) => ({
    orgList: state?.settings?.orgs,
    preferredWorkLocationsList: state?.settings?.preferredWorkLocationsList,
});

const mapDispatchToProps = (dispatch) => ({
    dispatchUpdateLeadStatus: (id, data, onSuccess, OnFailed) =>
        dispatch(updateLeadStatus(id, data, onSuccess, OnFailed)),
    dispatchGetOrganisations: (onSuccess, OnFailed) =>
        dispatch(GetOrgs(onSuccess, OnFailed)),
    dispatchfetchPreferredWorkLocations: (leadId, onSuccess, OnFailed) =>
        dispatch(fetchPreferredWorkLocations(leadId, onSuccess, OnFailed)),
    dispatchCreateStaff: (data, onSuccess, onError) =>
        dispatch(createStaff(data, onSuccess, onError)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConvertLeadModal);
