import React, { useState } from 'react';
import {
    Box,
    Typography,
    Tooltip,
    Divider,
    Tabs,
    Tab,
    withStyles,
    AppBar,
    makeStyles,
    useTheme,
    Paper,
    IconButton,
    Grid,
    TablePagination,
    TableCell,
    TableRow,
    TableContainer,
    TableBody,
    TableHead,
    Table,
    Button,
} from '@material-ui/core';
import useControlsStyles from '../../styles/useControlsStyles';
import {
    getControlsTabProps,
    CONTROL_TYPES,
    preProcessThePayload,
    isFirstVisitToday,
} from '../../helper';
import FilterModal, {
    FilteredState,
    FILTER_INPUT_TYPE,
} from '../../../../components/Modals/FilterModal';
import {
    CONTROL_ORDERS_FILTER_CARDS,
    CONTROL_ORDERS_FILTERS,
    CONTROL_RIDERS_FILTER_CARDS,
} from '../../helper';
import useControlTable from '../useControlTable';
import Searchbar from '../../../../components/Searchbar';
import GetAppIcon from '@material-ui/icons/GetApp';
import CachedIcon from '@material-ui/icons/Cached';
import { callPhoneNumber } from '../../../../utils/helper';
import { usePolling } from '../../../../hooks/usePolling';
import clsx from 'clsx';
import RiderInfoModal from '../RiderInfoModal';

interface RidersProps {
    [key: string]: any;
}

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        padding: '8px 8px',
        borderTop: '1px solid #EBEDF8',
        borderBottom: '1px solid #EBEDF8',
        '&:first-child': {
            paddingLeft: '16px',
            borderTopLeftRadius: '4px',
            borderBottomLeftRadius: '4px',
            borderLeft: '1px solid #EBEDF8',
        },
        '&:last-child': {
            paddingRight: '16px',
            borderTopRightRadius: '4px',
            borderBottomRightRadius: '4px',
            borderRight: '1px solid #EBEDF8',
        },
    },
}))(TableCell);
const StyledTableHeadCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.white,
        color: '#525050',
        fontWeight: 700,
        fontSize: 16,
        letterSpacing: '0.144px',
        textTransform: 'capitalize',
        padding: '8px',
        borderBottom: 'none',
        //textAlign: 'center',
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow: any = withStyles((theme) => ({
    root: {
        marginBottom: '8px',
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.common.white,
        },
        border: '1px solid #EBEDF8',
        borderRadius: '4px',
    },
}))(TableRow);

const Riders: React.FC<RidersProps> = ({
    tabs,
    handleLoading,
    controlType,
    lastUpdated,
    setLastUpdated,

    ...props
}) => {
    const [isPolling, setIsPolling] = useState<boolean>(false); // State to control polling

    //State Values
    const {
        ridersTable,
        getColumnComponentValue,
        prepareFilterOptions,
        filteredValues,
        setFilteredValue,
        getAllStaffInfo,
        getAllRidersInfo,
        getRiderMissedOrders,
        getRiderCancelledOrders,
        getRiderCompletedOrders,
        getRiderEvents,
        getRiderPings,
        getRiderPingsFromMeta,
        riders,
        staff,
        configs,
        dispatchUpdateFilter,
        controlCenter,
        errors,
    } = useControlTable({
        handleLoading,
        controlType,
        lastUpdated,
        setLastUpdated,
    });
    const previousSelected: any =
        controlCenter?.filters?.[CONTROL_TYPES.COMMON_FILTER];
    const [rendered, setRendered] = React.useState(false);
    const [riderInfo, setRiderInfo] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);

    const [page, setPage] = React.useState(previousSelected?.page ?? 0);
    const [rowsPerPage, setRowsPerPage] = React.useState(
        previousSelected?.rowsPerPage ?? 25,
    );
    const [activeFilterCard, setActiveFilterCard] = useState<any>(
        previousSelected?.activeFilterCard ?? {
            type: 'Total',
            subType: '',
        },
    );
    const [searchVal, setSearchVal] = useState(
        previousSelected?.searchVal ?? '',
    );
    const [listingPayload, setListingPayload] = React.useState(
        !isFirstVisitToday() && !!previousSelected?.listingPayload
            ? previousSelected?.listingPayload
            : {
                  filters: activeFilterCard,
                  pageNo: page + 1,
                  pageSize: rowsPerPage,
                  searchText: searchVal,
                  /*hubCodes: staff?.hubs?.[0]?.id
                      ? [
                            {
                                label: staff?.hubs?.[0]?.name,
                                value: staff?.hubs?.[0]?.id,
                                storeId:
                                    staff?.hubs?.[0]?.hyperlocalStoreId ?? null,
                            },
                        ]
                      : [],*/
              },
    );

    //Hooks
    const classes = useControlsStyles();
    const theme = useTheme();

    React.useEffect(() => {
        setRendered(true);
        setIsPolling(true);
    }, []);
    const prepareFilterCard = (card: any) => {
        switch (card?.type) {
            case CONTROL_ORDERS_FILTERS.ONLINE_ON_DUTY_FREE:
                return (
                    <Box
                        display={'flex'}
                        flexDirection={'row'}
                        //alignItems={'center'}
                        justifyContent={'space-between'}
                    >
                        {card?.sections?.map(
                            (section: any, indexSec: number) => {
                                let subsFound = !!section?.subFilters?.length;
                                const alignHeader: any = subsFound
                                    ? 'center'
                                    : 'left';
                                const value =
                                    riders?.[section?.valueMappingKey] ?? '0';

                                return (
                                    <>
                                        <Box
                                            key={`section_${indexSec}`}
                                            width={
                                                !!subsFound ? '100%' : '56px'
                                            }
                                        >
                                            <Typography
                                                className={classes.cardHeader}
                                                align={alignHeader}
                                                style={
                                                    section?.filterValue
                                                        ?.type ==
                                                    activeFilterCard?.type
                                                        ? {
                                                              color: '#4D4AB5',
                                                          }
                                                        : {}
                                                }
                                            >
                                                {section?.name ?? ''}
                                                {!!subsFound && (
                                                    <Typography
                                                        component={'span'}
                                                        className={
                                                            classes.cardSubHeaderH
                                                        }
                                                    >
                                                        {` ${value}`}
                                                    </Typography>
                                                )}
                                            </Typography>
                                            {!subsFound && (
                                                <Typography
                                                    className={
                                                        classes.cardSubHeader
                                                    }
                                                >
                                                    {value}
                                                </Typography>
                                            )}
                                            {!!subsFound && (
                                                <Box
                                                    mt={'8px'}
                                                    display={'flex'}
                                                    flexDirection={'row'}
                                                    alignItems={'center'}
                                                    justifyContent={
                                                        'space-between'
                                                    }
                                                >
                                                    {section?.subFilters?.map(
                                                        (
                                                            subFilter: any,
                                                            index: number,
                                                        ) => {
                                                            const isNotFirstAndLast =
                                                                index != 0 &&
                                                                index !=
                                                                    card
                                                                        ?.subFilters
                                                                        ?.length -
                                                                        1;
                                                            return (
                                                                <Box
                                                                    m={
                                                                        isNotFirstAndLast
                                                                            ? '0px 12px'
                                                                            : '0px 0px'
                                                                    }
                                                                    onClick={(
                                                                        e,
                                                                    ) => {
                                                                        e?.preventDefault();
                                                                        e?.stopPropagation();
                                                                        setActiveFilterCard(
                                                                            subFilter?.filterValue,
                                                                        );
                                                                        setSearchVal(
                                                                            '',
                                                                        );

                                                                        setListingPayload(
                                                                            (
                                                                                prev: any,
                                                                            ) => {
                                                                                return {
                                                                                    ...prev,
                                                                                    pageNo: 1,
                                                                                    filters:
                                                                                        subFilter?.filterValue,
                                                                                };
                                                                            },
                                                                        );
                                                                        setPage(
                                                                            0,
                                                                        );
                                                                    }}
                                                                    style={{
                                                                        ...(!!subFilter?.mW
                                                                            ? {
                                                                                  minWidth:
                                                                                      subFilter?.mW,
                                                                              }
                                                                            : {}),
                                                                        ...(subFilter
                                                                            ?.filterValue
                                                                            ?.subType ==
                                                                            activeFilterCard?.subType &&
                                                                        subFilter
                                                                            ?.filterValue
                                                                            ?.type ==
                                                                            activeFilterCard?.type
                                                                            ? {
                                                                                  backgroundColor:
                                                                                      '#D1D8FF',
                                                                              }
                                                                            : {}),
                                                                    }}
                                                                    key={`subs_${index}`}
                                                                    className={
                                                                        classes.smallValueBox
                                                                    }
                                                                    display={
                                                                        'flex'
                                                                    }
                                                                    flexDirection={
                                                                        'column'
                                                                    }
                                                                    justifyContent={
                                                                        'center'
                                                                    }
                                                                    alignItems={
                                                                        'center'
                                                                    }
                                                                >
                                                                    <Typography
                                                                        className={
                                                                            classes.cardHeaderXs
                                                                        }
                                                                    >
                                                                        {riders?.[
                                                                            section
                                                                                ?.subValueMappingKey
                                                                        ]?.[
                                                                            subFilter
                                                                                ?.valueMappingKey
                                                                        ] ??
                                                                            '0'}
                                                                    </Typography>
                                                                    <Typography
                                                                        className={
                                                                            classes.cardSubHeaderXs
                                                                        }
                                                                    >
                                                                        {subFilter?.name ??
                                                                            ''}
                                                                    </Typography>
                                                                </Box>
                                                            );
                                                        },
                                                    )}
                                                </Box>
                                            )}
                                        </Box>
                                        {indexSec !=
                                            section?.subFilters?.length - 1 && (
                                            <Divider
                                                className={classes.verDivider}
                                                orientation="vertical"
                                                flexItem
                                            />
                                        )}
                                    </>
                                );
                            },
                        )}
                        {/* <Box>
                            <Typography className={classes.cardHeader}>
                                {'Online'}
                            </Typography>
                            <Typography className={classes.cardSubHeader}>
                                {riders?.onlineCount}
                            </Typography>
                        </Box>
                        <Divider
                            className={classes.verDivider}
                            orientation="vertical"
                            flexItem
                        />
                        <Box>
                            <Typography
                                align="center"
                                className={classes.cardHeader}
                            >
                                {'On Duty: '}
                                <Typography
                                    component={'span'}
                                    className={classes.cardSubHeaderH}
                                >
                                    {card?.value ?? 0}
                                </Typography>
                            </Typography>
                            <Box
                                mt={'8px'}
                                display={'flex'}
                                flexDirection={'row'}
                                alignItems={'center'}
                                justifyContent={'space-between'}
                            >
                                <Box
                                    className={classes.smallValueBox}
                                    display={'flex'}
                                    flexDirection={'column'}
                                    justifyContent={'center'}
                                    alignItems={'center'}
                                >
                                    <Typography
                                        className={classes.cardHeaderXs}
                                    >
                                        {riders?.onDutyCount?.inStoreCount ??
                                            '0'}
                                    </Typography>
                                    <Typography
                                        className={classes.cardSubHeaderXs}
                                    >
                                        {'In Queue'}
                                    </Typography>
                                </Box>
                                <Box
                                    className={classes.smallValueBox}
                                    display={'flex'}
                                    flexDirection={'column'}
                                    justifyContent={'center'}
                                    alignItems={'center'}
                                    m={'0 16px'}
                                >
                                    <Typography
                                        className={classes.cardHeaderXs}
                                    >
                                        {riders?.onDutyCount?.toCXCount ?? '0'}
                                    </Typography>
                                    <Typography
                                        className={classes.cardSubHeaderXs}
                                    >
                                        {'To CX'}
                                    </Typography>
                                </Box>
                                <Box
                                    className={classes.smallValueBox}
                                    display={'flex'}
                                    flexDirection={'column'}
                                    justifyContent={'center'}
                                    alignItems={'center'}
                                >
                                    <Typography
                                        className={classes.cardHeaderXs}
                                    >
                                        {riders?.onDutyCount?.cxHandoverCount ??
                                            '0'}
                                    </Typography>
                                    <Typography
                                        className={classes.cardSubHeaderXs}
                                    >
                                        {'CX handover'}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                        <Divider
                            className={classes.verDivider}
                            orientation="vertical"
                            flexItem
                        />
                        <Box>
                            <Typography
                                align="center"
                                className={classes.cardHeader}
                            >
                                {'Free: '}
                                <Typography
                                    component={'span'}
                                    className={classes.cardSubHeaderH}
                                >
                                    {card?.value ?? 0}
                                </Typography>
                            </Typography>
                            <Box
                                mt={'8px'}
                                display={'flex'}
                                flexDirection={'row'}
                                alignItems={'center'}
                                justifyContent={'space-between'}
                            >
                                <Box
                                    className={classes.smallValueBox}
                                    display={'flex'}
                                    flexDirection={'column'}
                                    justifyContent={'center'}
                                    alignItems={'center'}
                                >
                                    <Typography
                                        className={classes.cardHeaderXs}
                                    >
                                        {riders?.freeCount?.inStoreCount ?? '0'}
                                    </Typography>
                                    <Typography
                                        className={classes.cardSubHeaderXs}
                                    >
                                        {'In store'}
                                    </Typography>
                                </Box>
                                <Box
                                    className={classes.smallValueBox}
                                    display={'flex'}
                                    flexDirection={'column'}
                                    justifyContent={'center'}
                                    alignItems={'center'}
                                    m={'0 16px'}
                                >
                                    <Typography
                                        className={classes.cardHeaderXs}
                                    >
                                        {riders?.onDutyCount?.toCXCount ?? '0'}
                                    </Typography>
                                    <Typography
                                        className={classes.cardSubHeaderXs}
                                    >
                                        {'In Queue'}
                                    </Typography>
                                </Box>
                                <Box
                                    className={classes.smallValueBox}
                                    display={'flex'}
                                    flexDirection={'column'}
                                    justifyContent={'center'}
                                    alignItems={'center'}
                                    m={'0 16px'}
                                >
                                    <Typography
                                        className={classes.cardHeaderXs}
                                    >
                                        {riders?.freeCount?.toDXCount ?? '0'}
                                    </Typography>
                                    <Typography
                                        className={classes.cardSubHeaderXs}
                                    >
                                        {'To DX'}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box> */}
                    </Box>
                );
            default:
                return (
                    <>
                        <Typography className={classes.cardHeader}>
                            {card?.name}
                        </Typography>
                        <Typography className={classes.cardSubHeader}>
                            {riders?.[card?.valueMappingKey] ?? '0'}
                        </Typography>
                    </>
                );
                break;
        }
    };
    const handleChangePage = (event?: any, newPage?: any) => {
        setPage(newPage);
        setListingPayload((prev: any) => {
            return {
                ...prev,
                pageNo: newPage + 1,
            };
        });
    };
    const handleChangeRowsPerPage = (event?: any) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
        setListingPayload((prev: any) => {
            return {
                ...prev,
                pageNo: 1,
                pageSize: event?.target?.value,
            };
        });
    };
    const fetchRiders = (payload: any) => {
        getAllRidersInfo(preProcessThePayload(payload));
    };
    React.useEffect(() => {
        fetchRiders(listingPayload);
        dispatchUpdateFilter({
            [CONTROL_TYPES.COMMON_FILTER]: {
                activeFilterCard: activeFilterCard,
                page: page,
                rowsPerPage: rowsPerPage,
                searchVal: searchVal,
                listingPayload: listingPayload,
                filteredValues: filteredValues,
            },
        });
    }, [listingPayload]);

    const rows: any = riders?.lineItems ?? [];
    const handleAction = (type: any, row: any) => {
        if (type == 'more') {
            setRiderInfo(row);
            setModalOpen(true);
        } else if (type == 'call') {
            if (!!row?.callNumber) {
                //handleClose();
                callPhoneNumber(row?.callNumber);
            }
        }
    };
    React.useEffect(() => {
        if (rendered) {
            setListingPayload((prev: any) => {
                return {
                    ...prev,
                    pageNo: 1,
                    searchText: searchVal,
                };
            });
            setPage(0);
        }
    }, [searchVal]);
    usePolling(
        async () => {
            fetchRiders(listingPayload);
        },
        configs?.pollingTimeOut ?? 15000,
        isPolling,
        false,
    );
    return (
        <Box>
            <Box className={classes.filterContainer}>
                <Box
                    display={'flex'}
                    flexDirection={'row'}
                    alignItems={'center'}
                    flex={1}
                >
                    <FilterModal
                        disabled={false}
                        filterOptions={prepareFilterOptions()}
                        filteredValues={filteredValues}
                        onClose={null}
                        onRemoveAll={null}
                        onFilterApplied={() => {
                            setListingPayload((prev: any) => {
                                return {
                                    ...prev,
                                    pageNo: 1,
                                    ...filteredValues,
                                };
                            });
                            setPage(0);
                        }}
                    />
                    <FilteredState
                        filterOptions={prepareFilterOptions()}
                        filteredValues={filteredValues}
                        onDelete={(filterKey: any) => {
                            let newFilteredValues = { ...filteredValues };
                            delete newFilteredValues?.[filterKey];
                            let newPayload: any = { ...listingPayload };
                            delete newPayload?.[filterKey];
                            setFilteredValue(newFilteredValues);
                            setListingPayload({
                                pageNo: 1,
                                ...newPayload,
                            });
                            setPage(0);
                        }}
                        count={null}
                        selectInfo={null}
                        selected={null}
                    />
                    {errors?.[controlType]?.error && (
                        <Typography
                            component={'p'}
                            style={{
                                color: '#ED0000',
                                fontWeight: 700,
                            }}
                        >
                            {errors?.[controlType]?.error ?? ``}
                        </Typography>
                    )}
                </Box>
                <Box mt={2}>
                    <Grid container spacing={2}>
                        {CONTROL_RIDERS_FILTER_CARDS.map(
                            (value: any, index: number) => (
                                <Grid
                                    key={index}
                                    xs={value?.xs ? value?.xs : true}
                                    item
                                    style={{
                                        maxWidth: 'fit-content',
                                    }}
                                >
                                    <Paper
                                        className={clsx([
                                            classes.filterBoxRider,
                                            !!value?.filterDisabled
                                                ? classes.filterBoxDisabled
                                                : {},
                                        ])}
                                        style={
                                            value?.filterValue?.type ==
                                                activeFilterCard?.type &&
                                            !activeFilterCard?.subType
                                                ? {
                                                      backgroundColor:
                                                          '#D1D8FF',
                                                  }
                                                : {}
                                        }
                                        onClick={() => {
                                            if (!value?.filterDisabled) {
                                                setActiveFilterCard(
                                                    value?.filterValue ?? {},
                                                );
                                                setSearchVal('');

                                                setListingPayload(
                                                    (prev: any) => {
                                                        return {
                                                            ...prev,
                                                            pageNo: 1,
                                                            filters:
                                                                value?.filterValue ??
                                                                {},
                                                        };
                                                    },
                                                );
                                                setPage(0);
                                            }
                                        }}
                                    >
                                        {prepareFilterCard(value)}
                                    </Paper>
                                </Grid>
                            ),
                        )}
                    </Grid>
                </Box>
            </Box>
            <Divider style={{ margin: '12px 0', marginBottom: '16px' }} />
            <Box
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
                justifyContent={'space-between'}
                mb={'8px'}
            >
                <Searchbar
                    placeHolderText=" Search by Name"
                    // placeHolderStyles={{
                    //     color: '#979797',
                    // }}
                    style={{
                        maxWidth: '350px',
                    }}
                    dataSource={null}
                    backendSearchbar={true}
                    onBackendSearch={(searchText: any) => {
                        setPage(0);
                        setSearchVal(searchText);
                    }}
                    value={searchVal}
                    keys={['name', 'hubName']}
                    onFilter={async (filteredData: any) => {}}
                    onChange={(value: any) => {
                        console.log('blur', value);
                        setSearchVal(value);
                    }}
                />

                <Box>
                    {/* <Tooltip title="Download" placement="top">
                        <Button
                            className={classes.iconBtn}
                            color="primary"
                            onClick={(e) => {}}
                            disabled={false}
                        >
                            {`Download  `}
                            <GetAppIcon
                                    {...{
                                        color: 'primary',
                                        size: 'small',
                                        style: {
                                            color: '#fff',
                                        },
                                    }}
                                />
                        </Button>
                    </Tooltip> */}
                    <Tooltip title="Refresh" placement="top">
                        <Button
                            className={classes.iconBtn}
                            color="primary"
                            onClick={(e) => {
                                fetchRiders(listingPayload);
                            }}
                            disabled={false}
                        >
                            {`Refresh  `}
                            {/* <CachedIcon
                                    {...{
                                        color: 'primary',
                                        size: 'small',
                                        style: {
                                            color: '#fff',
                                        },
                                    }}
                                /> */}
                        </Button>
                    </Tooltip>
                </Box>
            </Box>
            {!!rows?.length ? (
                <TableContainer
                    component={Box}
                    className={classes.tableContainer}
                >
                    <Table stickyHeader aria-label="Orders table">
                        <TableHead>
                            <TableRow>
                                {ridersTable?.map((column: any, index: any) => {
                                    return (
                                        <StyledTableHeadCell
                                            key={`column${index}`}
                                            align={column?.align ?? 'default'}
                                        >
                                            {column?.name}
                                        </StyledTableHeadCell>
                                    );
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows?.map((row: any) => (
                                <>
                                    <StyledTableRow
                                        key={row.name}
                                        onClick={() => {
                                            !!handleAction &&
                                                handleAction('more', row);
                                        }}
                                        style={{
                                            cursor: 'pointer',
                                        }}
                                    >
                                        {ridersTable?.map(
                                            (column: any, index: any) => {
                                                return (
                                                    <StyledTableCell
                                                        key={`row${index}`}
                                                    >
                                                        {getColumnComponentValue(
                                                            row,
                                                            column,
                                                            handleAction,
                                                        )}
                                                    </StyledTableCell>
                                                );
                                            },
                                        )}
                                    </StyledTableRow>
                                    <Divider
                                        style={{
                                            margin: '4px 0',
                                            backgroundColor: '#fff',
                                        }}
                                    />
                                </>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : (
                <Box
                    display={'flex'}
                    flexDirection={'column'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    mt={3}
                >
                    <Typography
                        component={'p'}
                        style={{
                            color: '#ED0000',
                            fontWeight: 700,
                        }}
                    >
                        {errors?.[controlType]?.error ??
                            `No data found. Please retry filters and try again.`}
                    </Typography>
                </Box>
            )}
            <Box className={classes.paginationContainer}>
                {(!!riders?.totalFilteredCount || !!riders?.totalCount) && (
                    <TablePagination
                        rowsPerPageOptions={
                            riders?.totalFilteredCount > 25 ||
                            riders?.totalCount > 25
                                ? [25, 50, 75, 100]
                                : []
                        }
                        component="div"
                        count={riders?.totalFilteredCount ?? riders?.totalCount}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                )}
            </Box>
            {modalOpen && (
                <RiderInfoModal
                    open={modalOpen}
                    riderInfo={riderInfo}
                    selectedFilter={listingPayload}
                    action={{
                        getRiderMissedOrders,
                        getRiderCompletedOrders,
                        getRiderEvents,
                        getRiderPings,
                        getRiderCancelledOrders,
                        getRiderPingsFromMeta,
                    }}
                    onClose={() => {
                        setModalOpen(false);
                        fetchRiders(listingPayload);
                    }}
                />
            )}
        </Box>
    );
};

export default Riders;
