import { Button, IconButton } from '@material-ui/core';
import React, { useState } from 'react';
import Modal from './Modal';
import moment from 'moment';
import { FileUploader } from '../UI';
import { CustomTextField } from '../Textfield';
import { CustomDatePicker } from '../DateTimePicker';
import InsertInvitationIcon from '@material-ui/icons/InsertInvitation';
import { connect } from 'react-redux';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
    addStaffBonus,
    editStaffBonus,
} from '../../store/actions/bonusActionCreator';

const validationSchema = yup.object({
    dateEpochMillis: yup.string('Date is invalid').required('Date is required'),
    amount: yup.number('Amount is invalid').required('Amount is required'),
    notes: yup.string('Notes is invalid'),
    docLink: yup.string('file is invalid'),
});

function AddAllowanceBonusModal({
    open,
    onClose,
    staffInfo,
    data = {},
    isEdit,
    dispatchAddStaffBonus,
    dispatchEditStaffBonus,
    ...props
}) {
    const [isOpen, toggleModal] = useState(open);
    const [loading, toggleLoading] = useState(false);
    const [datePickerOpen, setDatePickerOpen] = useState(false);

    const formik = useFormik({
        initialValues: {
            dateEpochMillis: moment().valueOf(),
            amount: '',
            notes: '',
            docLink: '',
            ...data,
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            await toggleLoading(true);
            if (isEdit) {
                dispatchEditStaffBonus(
                    data.id,
                    { ...values, id: staffInfo.id },
                    async (resp) => {
                        await toggleLoading(false);
                        handleClose();
                    },
                    async (resp) => {
                        await toggleLoading(false);
                    },
                );
            } else {
                dispatchAddStaffBonus(
                    { id: staffInfo.id, ...values },
                    async (resp) => {
                        await toggleLoading(false);
                        handleClose();
                    },
                    async (resp) => {
                        await toggleLoading(false);
                    },
                );
            }
        },
    });

    const handleClose = () => {
        toggleModal(!isOpen);
        onClose && onClose();
    };

    const actions = [
        <Button
            key={2}
            variant="contained"
            color="primary"
            className={`saveButton`}
            type="submit"
            disableElevation
        >
            {isEdit ? 'Update' : 'Add'}
        </Button>,
    ];

    console.log(formik.errors);

    return (
        <Modal
            onClose={handleClose}
            open={isOpen}
            title={`${isEdit ? 'Edit' : 'Add'} Allowance/Bonus`}
            subtitle={`${
                isEdit ? 'Edit' : 'Add'
            } Allowance/Bonus of your staff`}
            actions={actions}
            loading={loading}
            isForm={true}
            onSubmit={formik.handleSubmit}
        >
            <CustomTextField
                label="Date"
                InputProps={{
                    readOnly: true,
                    endAdornment: (
                        <IconButton
                            className={'inputbutton'}
                            onClick={(e) => setDatePickerOpen(true)}
                        >
                            <InsertInvitationIcon size="small" />
                        </IconButton>
                    ),
                }}
                onClick={(e) => setDatePickerOpen(true)}
                id="dateEpochMillis"
                name="dateEpochMillis"
                value={moment(formik.values.dateEpochMillis).format(
                    'yyyy-MM-DD',
                )}
                onChange={formik.handleChange}
                error={
                    formik.touched.dateEpochMillis
                        ? Boolean(formik.errors.dateEpochMillis)
                        : false
                }
                helperText={
                    formik.touched.dateEpochMillis &&
                    formik.errors.dateEpochMillis
                }
                variant="outlined"
                style={{ marginBottom: '16px' }}
                isRequired={true}
            />
            <CustomTextField
                fullWidth={true}
                label="Amount"
                variant="outlined"
                inputProps={{
                    type: 'number',
                    min: 1,
                }}
                id="amount"
                name="amount"
                value={formik.values.amount}
                onChange={formik.handleChange}
                error={
                    formik.touched.amount
                        ? Boolean(formik.errors.amount)
                        : false
                }
                helperText={formik.touched.amount && formik.errors.amount}
                style={{ marginBottom: '16px' }}
                isRequired={true}
            />
            <CustomTextField
                multiline={true}
                rows={4}
                fullWidth={true}
                label="Notes"
                variant="outlined"
                id="notes"
                name="notes"
                value={formik.values.notes}
                onChange={formik.handleChange}
                error={
                    formik.touched.notes ? Boolean(formik.errors.notes) : false
                }
                helperText={formik.touched.notes && formik.errors.notes}
            />
            <FileUploader
                label={'Upload Support Document'}
                placeholder={'Upload Support Document'}
                onFileUploaded={(url) => {
                    console.log(url);
                    formik.setFieldValue('docLink', url, true);
                }}
                error={
                    formik.touched.docLink
                        ? Boolean(formik.errors.docLink)
                        : false
                }
                helperText={formik.touched.docLink && formik.errors.docLink}
            />

            {datePickerOpen && (
                <CustomDatePicker
                    open={datePickerOpen}
                    onOpen={() => setDatePickerOpen(true)}
                    onClose={() => setDatePickerOpen(false)}
                    format="d MMM yyyy"
                    value={formik.values.dateEpochMillis}
                    onChange={(e) => {
                        console.log(moment(e).valueOf());
                        formik.setFieldValue(
                            'dateEpochMillis',
                            moment(e).valueOf(),
                            true,
                        );
                        // setSelectedDate(moment(e));
                    }}
                    allowFutureDates={false}
                />
            )}
        </Modal>
    );
}
const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
    dispatchAddStaffBonus: (payload, onSuccess, onError) =>
        dispatch(addStaffBonus(payload, onSuccess, onError)),
    dispatchEditStaffBonus: (id, payload, onSuccess, onError) =>
        dispatch(
            editStaffBonus({ ...payload, allowanceId: id }, onSuccess, onError),
        ),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AddAllowanceBonusModal);
