import { Box, Paper, Typography } from '@material-ui/core';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { EmptyIcon, ProcessingIcon, WarningIcon } from '../../../assets/svg';

const ViewHistory = ({
    leadInfo,
    dispatchFetchLeadEvents,
    toggleLoading,
    ...props
}) => {
    const [data, setData] = useState([]);

    useEffect(() => {
        fetchHistory();
    }, []);

    const fetchHistory = () => {
        toggleLoading(true);
        dispatchFetchLeadEvents(
            leadInfo.id,
            {},
            (resp) => {
                setData(resp.response?.lineItems ?? []);
                toggleLoading(false);
            },
            (err) => {
                toggleLoading(false);
            },
        );
    };

    return (
        <Box
            className="hire-item"
            style={{
                marginTop: '24px',
            }}
        >
            {data?.length ? (
                data.map((row, index) => (
                    <Box
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            marginBottom: '8px',
                        }}
                    >
                        <Box
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            {row.leadEvent === 'Rejected' ? (
                                <WarningIcon />
                            ) : (
                                <ProcessingIcon />
                            )}
                            <Box
                                style={{
                                    backgroundColor: 'rgba(67, 65, 158, 0.54)',
                                    width: '2px',
                                    height: '100%',
                                    marginLeft: '15px',
                                }}
                            />
                        </Box>
                        <Box
                            style={{
                                border:
                                    row.leadEvent === 'Rejected'
                                        ? '1px solid #f44336'
                                        : row.leadEvent === 'Incomplete'
                                        ? '1px solid #3f51b5'
                                        : '0px',
                                paddingLeft: '16px',
                                paddingRight: '16px',
                                paddingTop: '8px',
                                paddingBottom: '8px',
                                marginBottom: '8px',
                                borderRadius: '8px',
                                width: '100%',
                                backgroundColor:
                                    row.leadEvent === 'Incomplete'
                                        ? 'rgba(63, 81, 181, 0.16)'
                                        : row.leadEvent === 'Rejected'
                                        ? 'rgba(244, 67, 54, 0.08)'
                                        : '#f6f7f9',
                            }}
                        >
                            <Box
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                }}
                            >
                                <Typography
                                    style={{
                                        fontWeight: '600',
                                        fontFamily: 'Nunito',
                                        fontSize: '12px',
                                    }}
                                >
                                    {!!row?.eventDateEpochMillis &&
                                        moment(row.eventDateEpochMillis).format(
                                            'DD MMM YYYY',
                                        )}
                                </Typography>
                                <Typography
                                    style={{
                                        fontWeight: '600',
                                        fontFamily: 'Nunito',
                                        fontSize: '12px',
                                        marginLeft: '8px',
                                        marginRight: '8px',
                                    }}
                                >
                                    {!!row?.eventDateEpochMillis &&
                                        moment(row.eventDateEpochMillis).format(
                                            'hh:mm A',
                                        )}
                                </Typography>
                                {!!row.leadEvent && (
                                    <Box
                                        style={{
                                            backgroundColor: 'transparent',
                                            minWidth: '100px',
                                            height: '24px',
                                            justifyContent: 'center',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            textAlign: 'center',
                                            borderRadius: '8px',
                                            alignContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Typography
                                            style={{ width: '100%' }}
                                            className={
                                                row.leadEvent === 'Open'
                                                    ? 'open-chip'
                                                    : row.leadEvent ===
                                                      'Completed'
                                                    ? 'completed-chip'
                                                    : row.leadEvent ===
                                                      'Rejected'
                                                    ? 'rejected-chip'
                                                    : row.leadEvent ===
                                                      'Incomplete'
                                                    ? 'incomplete-chip'
                                                    : row.leadEvent ===
                                                      'Marked Incomplete'
                                                    ? 'markedincomplete-chip'
                                                    : row.leadEvent ===
                                                      'Verified'
                                                    ? 'verified-chip'
                                                    : row.leadEvent ===
                                                      'Converted'
                                                    ? 'converted-chip'
                                                    : row.leadEvent ===
                                                      'On Hold'
                                                    ? 'onhold-chip'
                                                    : 'markedincomplete-chip'
                                            }
                                        >
                                            {row.leadEvent?.toUpperCase() ??
                                                '-'}
                                        </Typography>
                                    </Box>
                                )}
                            </Box>
                            <Typography
                                style={{
                                    fontWeight: '600',
                                    fontFamily: 'Nunito',
                                    fontSize: '14px',
                                    marginTop: '8px',
                                    whiteSpace: 'pre-line',
                                }}
                            >
                                {row?.eventComments}
                            </Typography>
                        </Box>
                    </Box>
                ))
            ) : (
                <Box
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <EmptyIcon />
                </Box>
            )}
        </Box>
    );
};

export { ViewHistory };
