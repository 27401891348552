import { Grid, makeStyles, Paper } from '@material-ui/core';
import React from 'react';
import { Marker } from 'react-leaflet';
import Map from '../../../components/Map';
import * as L from 'leaflet';
import MapDetails from '../../map-view/MapDetails';
import { connect } from 'react-redux';

const useStyles = makeStyles((theme) => ({
    container: {
        '& .leaflet-container': {
            width: '100%',
            height: '83vh',
            borderRadius: 16,
        },
    },
}));

const myIcon = L.icon({
    iconUrl:
        'http://www.pngall.com/wp-content/uploads/2017/05/Map-Marker-PNG-File.png',
    iconSize: [40, 40],
    iconAnchor: [32, 64],
    popupAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
});

const TrackNTraceComp = ({ staff, user, ...props }) => {
    const classes = useStyles();

    return (
        <Paper style={{ padding: '.1rem' }} elevation={0}>
            <div className={classes.container}>
                <Grid item xs={12}>
                    <MapDetails
                        staffTrackAndTrace={{
                            id: staff?.singleStaffInfo?.id ?? 161923808380917,
                            name: staff?.singleStaffInfo?.name ?? 'Steve',
                            contactNumber:
                                staff?.singleStaffInfo?.contactNumber ??
                                '9786330985',
                            vehicleNumber:
                                staff?.singleStaffInfo?.vehicleNumber ??
                                'TN80YN6793',
                            hubName:
                                staff?.singleStaffInfo?.hubName ??
                                'Koramangala Hub',
                            tracking: {
                                id:
                                    staff?.singleStaffInfo?.beeconVehicleId ??
                                    'profile_vehicle_KQNQJ7DJ8702',
                                org: {
                                    id:
                                        user?.config?.beeconOrgId ??
                                        'profile_organization_KPZJLPF38387',
                                },
                            },
                        }}
                        // staffTrackAndTrace={data}
                    />
                </Grid>
                {/* </Grid> */}
            </div>
        </Paper>
    );
};

const mapStateToProps = (state) => ({
    user: state.user,
    staff: state.staff,
});

const mapDispatchToProps = (dispatch) => ({});

export const TrackNTrace = connect(
    mapStateToProps,
    mapDispatchToProps,
)(TrackNTraceComp);
