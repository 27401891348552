export const getBounds = (routes: any) => {
    let bounds: any = [];
    console.log('thee', routes);
    routes.forEach((route: any) => {
        bounds = [...bounds, ...(route?.coordinates ?? [])];
    });
    return filterDuplicateLatLng(bounds);
};
export function filterDuplicateLatLng(latLngArray: any) {
    const uniqueLatLngMap = new Map();
    latLngArray?.forEach((latLong: any) => {
        const key = `${latLong?.[0]},${latLong?.[1]}`;
        if (!uniqueLatLngMap.has(key)) {
            uniqueLatLngMap.set(key, [latLong?.[0], latLong?.[1]]);
        }
    });

    return Array.from(uniqueLatLngMap.values());
}

export const getPolyLineMarkerData = (route: any, index: number) => {
    console.log(
        'getPolyLineMarkerData',
        route?.coordinates?.[index],
        route?.playBackData?.[index],
    );
    return {
        polyLinePosition: [],
        markerPosition: [],
    };
};

export function getTabsProps(index: any, tab?: any) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
        label: tab?.name,
    };
}
export function eventStyleFlag(event?: any) {
    if (
        event?.name?.includes('BATTERY_OPTIMIZATION_ON') ||
        event?.name?.includes('POWER_SAVE_MODE_ON') ||
        event?.name?.includes('LOCATION_PERMISSION_OFF') ||
        event?.name?.includes('DEVICE_LOCATION_OFF') ||
        event?.description?.toLowerCase()?.includes('terminated')
    )
        return true;
    else return false;
}
