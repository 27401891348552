import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Button,
    Card,
    Grid,
    makeStyles,
    Paper,
    Typography,
    IconButton,
    List,
    ListItem,
    ListItemSecondaryAction,
    Divider,
    TablePagination,
    Tooltip as MUITooltip,
} from '@material-ui/core';
import {
    BatteryIcon,
    LocateIcon,
    LocationMobileOffIcon,
    LocationMobileOnIcon,
    LocationPermissionOffIcon,
    LocationPermissionOnIcon,
    MobileIcon,
    NewTabIcon,
} from '../../assets/svg';
import moment from 'moment';
import BatteryOptimizationAlert from '../../assets/images/alert.png';
import EVInfo from './MapDetailsV3/components/EVInfo';
const ListCard = ({ row, setFocusLatLong, setFocusMarker, evs = [] }) => {
    useEffect(() => {
        console.log('Component mounted');

        return () => {
            console.log('Component unmounted');
        };
    }, []);
    const currentEVData = evs?.filter(
        (ev) =>
            !!ev &&
            ev?.evMapViewInfo?.beeconVehicleId ==
                row?.evMapViewInfo?.beeconVehicleId,
    )?.[0];
    return (
        <>
            <Box
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                }}
            >
                <Box
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                >
                    <Typography
                        // variant="body1"
                        // component="div"
                        style={{
                            fontWeight: 'bold',
                            fontSize: 16,
                            marginRight: 8,
                        }}
                    >
                        {row.name}
                    </Typography>
                    {!!row?.batteryOptimization && (
                        <MUITooltip
                            title="Battery Optimization ON"
                            placement="top"
                        >
                            <Box
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <img
                                    src={BatteryOptimizationAlert}
                                    style={{
                                        width: '16px',
                                        height: '16px',
                                    }}
                                    alt="batteryAlert"
                                />
                            </Box>
                        </MUITooltip>
                    )}
                </Box>
                <Typography
                    // variant="h6"
                    // component="div"
                    // style={{
                    //     fontWeight: 'bold',
                    //     fontSize: 14,
                    // }}

                    style={
                        row?.tracking?.telematicData?.gps?.speed > 0 &&
                        (moment().valueOf() -
                            moment(
                                row?.tracking?.telematicData
                                    ?.staticStateChangeTime,
                            ).valueOf()) /
                            60000 <
                            30
                            ? {
                                  fontSize: 14,
                                  color: 'green',
                                  fontWeight: 600,
                              }
                            : row?.tracking?.telematicData?.gps?.speed == 0 &&
                              (moment().valueOf() -
                                  moment(
                                      row?.tracking?.telematicData
                                          ?.staticStateChangeTime,
                                  ).valueOf()) /
                                  60000 <
                                  30
                            ? {
                                  fontSize: 14,
                                  fontWeight: 600,
                                  color: 'red',
                              }
                            : {
                                  fontSize: 14,
                                  fontWeight: 600,
                                  color: 'black',
                              }
                    }
                >
                    {row?.tracking?.telematicData?.gps?.speed > 0 &&
                    (moment().valueOf() -
                        moment(
                            row?.tracking?.telematicData?.staticStateChangeTime,
                        ).valueOf()) /
                        60000 <
                        30
                        ? 'MOVING'
                        : row?.tracking?.telematicData?.gps?.speed == 0 &&
                          (moment().valueOf() -
                              moment(
                                  row?.tracking?.telematicData
                                      ?.staticStateChangeTime,
                              ).valueOf()) /
                              60000 <
                              30
                        ? 'IDLE'
                        : (moment().valueOf() -
                              moment(
                                  row?.tracking?.telematicData
                                      ?.staticStateChangeTime,
                              ).valueOf()) /
                              60000 >=
                          30
                        ? 'OFFLINE'
                        : ''}
                </Typography>
            </Box>
            <Box
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                }}
            >
                <Typography
                    // gutterBottom
                    // variant="body2"
                    // component="div"
                    style={{
                        fontSize: 14,
                        fontWeight: 600,
                    }}
                >
                    {row.hubName ?? '-'} | {row.contactNumber ?? '-'}
                </Typography>
                <Typography
                    // gutterBottom
                    // variant="body2"
                    // component="div"
                    style={{
                        fontSize: 14,
                    }}
                >
                    {row?.vehicleNumber?.slice(
                        0,
                        row?.vehicleNumber.length - 4,
                    ) ?? ''}
                    <b>
                        {row?.vehicleNumber?.slice(
                            row?.vehicleNumber.length - 4,
                        ) ?? ''}
                    </b>
                </Typography>
            </Box>
            <Box
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                }}
            >
                <Box
                    style={{
                        marginBotton: 8,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                >
                    <Box
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Typography
                            style={{
                                fontSize: 12,
                                marginRight: 1,
                            }}
                        >
                            {`Speed`}
                        </Typography>
                        <Typography
                            style={{
                                fontSize: 12,
                                marginRight: 1,
                            }}
                        >
                            {`Updated`}
                        </Typography>
                    </Box>
                    <Box
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Typography
                            style={{
                                fontWeight: 600,
                                fontSize: 12,
                            }}
                        >
                            {` : ${row?.tracking?.telematicData?.gps?.speed?.toFixed(
                                2,
                            )}`}
                            {` Km/Hr (${moment(
                                row?.tracking?.telematicData
                                    ?.staticStateChangeTime,
                            ).fromNow()})`}
                        </Typography>

                        <Typography
                            style={{
                                fontWeight: 600,
                                fontSize: 12,
                            }}
                        >
                            {` : ${moment(
                                row?.tracking?.telematicData?.unixTimeStamp,
                            ).fromNow()}`}
                        </Typography>
                    </Box>
                </Box>
                <ListItemSecondaryAction
                    style={{
                        // border: '1px solid black',
                        top: '4.5rem',
                    }}
                >
                    <MUITooltip title="Open Details" placement="top">
                        <IconButton
                            key={3}
                            style={{
                                // position: 'absolute',
                                // top: '12px',
                                // right: '0px',
                                width: 55,
                                height: 30,
                            }}
                            onClick={(e) => {
                                e.stopPropagation();
                                // alert(listRefs.current[row.beeconVehicleId]);
                                window.open(`/map-view/detail/${row.id}`);
                            }}
                        >
                            <NewTabIcon />
                        </IconButton>
                    </MUITooltip>
                    <MUITooltip title="Locate Vehicle" placement="top">
                        <IconButton
                            key={4}
                            style={{
                                // position: 'absolute',
                                // top: '12px',
                                // right: '0px',
                                border: '1px solid #4d4ab5',
                                borderRadius: '8px',
                                padding: '4px 8px',
                            }}
                            onClick={(e) => {
                                // alert(listRefs.current[row.beeconVehicleId]);
                                e.stopPropagation();
                                // console.log('YYYYYYYYY', e);
                                // console.log(
                                //     'LatLONGGG',
                                //     row?.tracking?.telematicData?.gps
                                //         ?.location?.lat,
                                //     row?.tracking?.telematicData?.gps
                                //         ?.location?.long,
                                // );
                                if (
                                    row?.tracking?.telematicData?.gps?.location
                                        ?.lat &&
                                    row?.tracking?.telematicData?.gps?.location
                                        ?.long
                                )
                                    setFocusLatLong({
                                        lat: row?.tracking?.telematicData?.gps
                                            ?.location?.lat,
                                        long: row?.tracking?.telematicData?.gps
                                            ?.location?.long,
                                    });

                                setFocusMarker(true);
                            }}
                        >
                            <LocateIcon />
                        </IconButton>
                    </MUITooltip>
                </ListItemSecondaryAction>
            </Box>
            <Divider
                style={{
                    border: '1px dashed #f0f0f0',
                    margin: '10px 0px',
                }}
            />
            {!!row?.deviceBrand && (
                <Box
                    style={{
                        marginBotton: 8,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <MUITooltip title="Device Info" placement="top">
                        <Box
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                                maxWidth: '37.5%',
                            }}
                        >
                            <MobileIcon />
                            <Typography
                                style={{
                                    fontFamily: 'Nunito',
                                    fontSize: 12,
                                    fontWeight: 600,
                                    paddingLeft: 4,
                                    textAlign: 'left',
                                }}
                            >
                                {!!row?.deviceBrand
                                    ? `${row?.deviceBrand?.toUpperCase()} ${row?.deviceModel.toUpperCase()} (v${
                                          row?.appVersion
                                      })`
                                    : '-'}
                            </Typography>
                        </Box>
                    </MUITooltip>
                    <>
                        <Box
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                            }}
                        >
                            <MUITooltip
                                title="Location Permission"
                                placement="top"
                            >
                                {!!row.locationPermission ? (
                                    <LocationPermissionOnIcon />
                                ) : (
                                    <LocationPermissionOffIcon />
                                )}
                            </MUITooltip>
                        </Box>
                        <Box
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                            }}
                        >
                            <MUITooltip
                                title="Location Service"
                                placement="top"
                            >
                                {!!row.locationService ? (
                                    <LocationMobileOnIcon />
                                ) : (
                                    <LocationMobileOffIcon />
                                )}
                            </MUITooltip>
                        </Box>
                    </>
                    {!!row?.tracking?.telematicData?.powerVoltage && (
                        <MUITooltip title="Battery Info" placement="top">
                            <Box
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <BatteryIcon
                                    color={
                                        row?.tracking?.telematicData
                                            ?.powerVoltage < 20
                                            ? 'red'
                                            : row?.tracking?.telematicData
                                                  ?.powerVoltage > 60
                                            ? 'green'
                                            : 'orange'
                                    }
                                />
                                <Typography
                                    style={{
                                        fontFamily: 'Nunito',
                                        fontSize: 12,
                                        fontWeight: 600,
                                        marginLeft: 5,
                                    }}
                                >
                                    {row?.tracking?.telematicData?.powerVoltage}
                                    %
                                </Typography>
                            </Box>
                        </MUITooltip>
                    )}
                </Box>
            )}
            {row?.evMapViewInfo?.beeconVehicleId && (
                <>
                    <Divider
                        style={{
                            border: '1px dashed #f0f0f0',
                            margin: '10px 0px',
                        }}
                    />
                    <EVInfo
                        selectedStaff={row}
                        tracking={currentEVData?.tracking}
                        evInfo={row?.evMapViewInfo}
                        locate={true}
                        onLocate={(e) => {
                            e.stopPropagation();
                            if (
                                currentEVData?.tracking?.telematicData?.gps
                                    ?.location?.lat &&
                                currentEVData?.tracking?.telematicData?.gps
                                    ?.location?.long
                            )
                                setFocusLatLong({
                                    lat: currentEVData?.tracking?.telematicData
                                        ?.gps?.location?.lat,
                                    long: currentEVData?.tracking?.telematicData
                                        ?.gps?.location?.long,
                                });

                            setFocusMarker(true);
                        }}
                    />
                </>
            )}
        </>
    );
};

// Prop types for type checking
ListCard.propTypes = {
    row: PropTypes.any,
    setFocusLatLong: PropTypes.any,
    setFocusMarker: PropTypes.any,
};

// Default props
ListCard.defaultProps = {
    row: null,
};

export default ListCard;
