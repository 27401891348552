import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { removeSnackbar } from '../../../store/actions/notificationActionCreator';
import { Button } from '@material-ui/core';

let displayed = [];

export const Notifier = () => {
    const dispatch = useDispatch();
    const notifications = useSelector((store) => store.notifications || []);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const storeDisplayed = (id) => {
        displayed = [...displayed, id];
    };

    const removeDisplayed = (id) => {
        displayed = [
            ...displayed.filter((key) => {
                return id !== key;
            }),
        ];
    };

    React.useEffect(() => {
        notifications.forEach(
            ({ key, message, options = {}, dismissed = false }) => {
                if (dismissed) {
                    // dismiss snackbar using notistack
                    closeSnackbar(key);
                    return;
                }

                // do nothing if snackbar is already displayed
                if (displayed.includes(key)) return;

                // display snackbar using notistack
                enqueueSnackbar(message, {
                    key,
                    // action: (key) => (
                    //     <Button
                    //         style={{ color: '#fff' }}
                    //         variant="outlined"
                    //         onClick={() => closeSnackbar(key)}
                    // disableElevation
                    //     >
                    //         dismiss
                    //     </Button>
                    // ),
                    ...options,
                    onClose: (event, reason, myKey) => {
                        if (options.onClose) {
                            options.onClose(event, reason, myKey);
                        }
                    },
                    onExited: (event, myKey) => {
                        // remove this snackbar from redux store
                        dispatch(removeSnackbar(myKey));
                        removeDisplayed(myKey);
                    },
                });

                // keep track of snackbars that we've displayed
                storeDisplayed(key);
            },
        );
    }, [notifications, closeSnackbar, enqueueSnackbar, dispatch]);

    return null;
};
