import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Paper, Box } from '@material-ui/core';
import Tabbar from '../../components/Tabbar';
import {
    PaymentsIcon,
    DashboardIcon,
    AttendanceIcon,
    DistanceReportIcon,
    ExpenseIcon,
    EarningsIcon,
    PayslipIcon,
} from '../../assets/svg';
import {
    Dashboard,
    PaymentsReport,
    EarningsReport,
    ExpensesReport,
    DistanceReport,
    AttendanceReport,
    PayslipReport,
} from '.';
import { Spinner } from '../../components/UI';
import { getAllStaffs,getHubList } from '../../store/actions/staffActionCreator';
import { getActiveInitRoute, getPermissions } from '../../utils/helper';
import { fetchAppConfig } from '../../store/actions/authActionCreator';

// eslint-disable-next-line no-unused-vars
let lastIndex = 0;

const Report = ({
    staff,
    dashboard,
    configs,
    orgId,
    dispatchFetchAppConfig,
    dispatchGetHubList,
    ...props
}) => {
    const [tabIndex, settabIndex] = useState(
        props?.location?.state?.tabIndex ?? 0,
    );
    const [modalTabIndex, setModalTabIndex] = useState(-1);
    const [permissions, setPermissions] = useState(getPermissions('report'));

    React.useEffect(() => {
        if (!permissions.read) {
            props.history.push(`/${getActiveInitRoute()}`);
        }
        dispatchFetchAppConfig();
        dispatchGetHubList(
            orgId,
            (resp) => {
                console.log('dispatchGetHubList success', resp);
            },
            (err) => {
                console.log('dispatchGetHubList error', err);
            },
        );
    }, []);

    useEffect(async () => {
        // await setPermissions(getPermissions('report'));
        if (!permissions.read) {
            props.history.push(`/${getActiveInitRoute()}`);
        }
    }, [configs]);

    const tabs = [
        {
            label: 'Dashboard',
            icon: <DashboardIcon />,
            content: <Dashboard permissions={permissions} />,
            disabled: !permissions?.read,
        },
        {
            label: 'Earnings',
            icon: <EarningsIcon />,
            content: <EarningsReport permissions={permissions} />,
            disabled: !permissions?.read,
        },
        {
            label: 'Expense',
            icon: <ExpenseIcon />,
            content: <ExpensesReport permissions={permissions} />,
            disabled: !permissions?.read,
        },
        {
            label: 'Payments',
            icon: <PaymentsIcon />,
            content: <PaymentsReport permissions={permissions} />,
            disabled: !permissions?.read,
        },
        {
            label: 'Attendance',
            icon: <AttendanceIcon />,
            content: <AttendanceReport permissions={permissions} />,
            disabled: !permissions?.read,
        },
        {
            label: 'Payslip',
            icon: <PayslipIcon />,
            content: <PayslipReport permissions={permissions} />,
            disabled: !permissions?.read,
        },
        {
            label: 'Distance Report',
            icon: <DistanceReportIcon />,
            content: <DistanceReport permissions={permissions} />,
            disabled: !permissions?.read,
        },
    ];
    console.log('render report');
    return (
        <div>
            <Paper
                style={{
                    padding: '1.5rem',
                    marginTop: '1rem',
                    marginBottom: '1rem',
                }}
                elevation={0}
            >
                <Tabbar
                    tabs={tabs}
                    tabIndex={tabIndex}
                    onChange={(index, isModal) => {
                        lastIndex = tabIndex;
                        if (isModal) {
                            setModalTabIndex(index);
                        } else {
                            settabIndex(index);
                            setModalTabIndex(-1);
                        }
                    }}
                />
                {/* <Divider style={{ margin: '1rem 0' }} /> */}
            </Paper>

            {tabs[tabIndex].isModal && tabs[lastIndex].content}
            {!tabs[tabIndex]?.disabled && tabs[tabIndex].content}
        </div>
    );
};

const mapStateToProps = (state) => ({
    staff: state.staff,
    dashboard: state.report.dashboard,
    configs: state.user.config,
    orgId: state?.settings?.basicDetails?.id,
});

const mapDispatchToProps = (dispatch) => ({
    getAllStaffs: (payload, onSuccess, onError) =>
        dispatch(getAllStaffs(payload, onSuccess, onError)),
    dispatchFetchAppConfig: (onSuccess, onError) =>
        dispatch(fetchAppConfig(onSuccess, onError)),
    dispatchGetHubList: (id, onSuccess, onError) =>
        dispatch(getHubList(id, onSuccess, onError)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Report));
