import { makeStyles } from '@material-ui/core';
import React from 'react';
const useControlCenterStyles = makeStyles((theme: any) => ({
    container: (props: any) => ({
        height: 'calc(100vh - 90px)',
        //overflowY: 'auto',
        //overflowX: 'auto',
        overflow: 'auto',
        '&::-webkit-scrollbar': {
            width: '8px',
        },
        '&::-webkit-scrollbar-track': {
            background: '#f1f1f1',
            borderRadius: '8px',
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#888',
            borderRadius: '8px',
        },
        '&::-webkit-scrollbar-thumb:hover': {
            background: '#555',
        },
    }),
    card: {
        backgroundColor: '#f6f7f9',
        marginTop: 16,
        color: '#000',
        padding: 16,
    },
    routeContainer: {
        padding: '12px 12px',
        paddingBottom: 0,
    },
    backIcon: {
        minWidth: '0',
        color: '#000000',
        cursor: 'pointer',
        marginRight: '8px',
    },
    scrollableBox: {
        width: '100%',
        height: '38vh',
        overflowY: 'auto',
        minHeight: '38vh',
        maxHeight: '38vh',
        marginTop: 12,
        borderRadius: 16,
    },
}));
export default useControlCenterStyles;
