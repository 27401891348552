import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { Polyline, Marker, Popup, useMap } from 'react-leaflet';
import {
    redMarker,
    greenMarker,
    orangeFilledMarker,
    purpleMarker,
    blueGreenMarker,
} from '../../../../components/Map/MarkerIcons';
import moment from 'moment';
import { getTimeAsDuration, getTimeDifference } from '../../../../utils/helper';

interface EndPointMarkerProps {
    trackData?: any;
    aggregatedTrackData?: any;
    events?: any;
}

const EndPointMarker: React.FC<EndPointMarkerProps> = ({
    trackData,
    aggregatedTrackData,
    events,
}) => {
    return (
        <Marker
            // if position changes, marker will drift its way to new position
            position={
                aggregatedTrackData?.coordinates?.[
                    aggregatedTrackData?.coordinates.length - 1
                ] &&
                aggregatedTrackData?.coordinates?.[
                    aggregatedTrackData?.coordinates.length - 1
                ]?.[0] &&
                aggregatedTrackData?.coordinates?.[
                    aggregatedTrackData?.coordinates.length - 1
                ]?.[1]
                    ? aggregatedTrackData?.coordinates?.[
                          aggregatedTrackData?.coordinates.length - 1
                      ]
                    : {}
            }
            icon={redMarker}
        >
            <Popup>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        textAlign: 'center',
                        flexDirection: 'column',
                    }}
                >
                    {trackData?.locationPoints?.[
                        trackData?.locationPoints?.length - 1
                    ]?.deviceTimeEpoch ==
                    aggregatedTrackData?.selectedEndStoppagePoint ? (
                        trackData?.endAddress ? (
                            <>
                                <Typography
                                    style={{
                                        fontSize: 14,
                                        fontWeight: 700,
                                    }}
                                    variant="subtitle1"
                                >
                                    {events?.startEventDescription?.length > 0
                                        ? events?.startEventDescription
                                        : trackData?.endAddress ?? ''}
                                </Typography>
                                <Typography
                                    style={{
                                        fontWeight: 400,
                                        fontSize: 16,
                                    }}
                                    variant="subtitle1"
                                >
                                    {moment(
                                        parseInt(
                                            trackData?.locationPoints?.[
                                                trackData?.locationPoints
                                                    ?.length - 1
                                            ]?.deviceTimeEpoch,
                                        ),
                                    ).format(
                                        'hh:mm A',
                                        // 'D MMM YYYY hh:mm A',
                                    )}
                                </Typography>
                            </>
                        ) : (
                            <></>
                        )
                    ) : (
                        aggregatedTrackData?.selectedEndStoppagePointLocationData &&
                        (['ORDER_PICKED', 'ORDER_DELIVERED'].includes(
                            aggregatedTrackData
                                ?.selectedEndStoppagePointLocationData?.type,
                        ) ? (
                            <>
                                {(function () {
                                    let popupInfo = {
                                        Description:
                                            aggregatedTrackData
                                                ?.selectedEndStoppagePointLocationData
                                                ?.title ?? '',
                                        ...aggregatedTrackData
                                            ?.selectedEndStoppagePointLocationData
                                            ?.properties,
                                    };
                                    return Object.keys(popupInfo)?.map(
                                        (key, index) => {
                                            return (
                                                <Box
                                                    style={{
                                                        width: 200,
                                                        marginTop:
                                                            index === 0
                                                                ? 20
                                                                : 0,
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                        justifyContent:
                                                            'space-between',
                                                    }}
                                                >
                                                    <Typography
                                                        gutterBottom
                                                        variant="body2"
                                                        component="div"
                                                        style={{
                                                            fontWeight: 700,
                                                            fontSize: 14,
                                                        }}
                                                    >
                                                        {key} :{' '}
                                                    </Typography>
                                                    <Typography
                                                        gutterBottom
                                                        variant="body2"
                                                        component="div"
                                                        style={{
                                                            fontSize: 14,
                                                        }}
                                                    >
                                                        {popupInfo[key]}
                                                    </Typography>
                                                </Box>
                                            );
                                        },
                                    );
                                })()}
                            </>
                        ) : (
                            <>
                                <Typography
                                    style={{
                                        fontWeight: 700,
                                        fontSize: 14,
                                    }}
                                    variant="subtitle1"
                                >
                                    {aggregatedTrackData
                                        ?.selectedEndStoppagePointLocationData
                                        ?.description?.length > 0 &&
                                    isNaN(
                                        Number(
                                            aggregatedTrackData
                                                ?.selectedEndStoppagePointLocationData
                                                ?.description,
                                        ),
                                    )
                                        ? aggregatedTrackData?.selectedEndStoppagePointLocationData?.description
                                              .split('@!@')
                                              .join(',')
                                        : 'No Location Available'}
                                </Typography>
                                <Typography
                                    style={{
                                        fontWeight: 400,
                                        fontSize: 16,
                                    }}
                                    variant="subtitle1"
                                >
                                    {moment(
                                        parseInt(
                                            aggregatedTrackData
                                                ?.selectedEndStoppagePointLocationData
                                                ?.startTime ??
                                                aggregatedTrackData
                                                    ?.selectedEndStoppagePointLocationData
                                                    ?.time,
                                        ),
                                    ).format(
                                        'hh:mm A',
                                        // 'D MMM YYYY hh:mm A',
                                    )}{' '}
                                    -{' '}
                                    {moment(
                                        parseInt(
                                            aggregatedTrackData
                                                ?.selectedEndStoppagePointLocationData
                                                ?.endTime ??
                                                aggregatedTrackData
                                                    ?.selectedEndStoppagePointLocationData
                                                    ?.time,
                                        ),
                                    ).format(
                                        'hh:mm A',
                                        // 'D MMM YYYY hh:mm A',
                                    )}{' '}
                                    (
                                    {getTimeAsDuration(
                                        getTimeDifference(
                                            parseInt(
                                                aggregatedTrackData
                                                    ?.selectedEndStoppagePointLocationData
                                                    ?.endTime ??
                                                    aggregatedTrackData
                                                        ?.selectedEndStoppagePointLocationData
                                                        ?.time,
                                            ),
                                            parseInt(
                                                aggregatedTrackData
                                                    ?.selectedEndStoppagePointLocationData
                                                    ?.startTime ??
                                                    aggregatedTrackData
                                                        ?.selectedEndStoppagePointLocationData
                                                        ?.time,
                                            ),
                                        ),
                                        'small',
                                    )}
                                    )
                                </Typography>
                            </>
                        ))
                    )}
                </div>
            </Popup>
        </Marker>
    );
};

export default EndPointMarker;
