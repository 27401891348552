import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { markAbsent } from '../../store/actions/attendanceActionCreator';
import { Button, Box, Typography } from '@material-ui/core';
import Modal from './Modal';

// const useStyles = makeStyles((theme) => ({
//     modal: {},
// }));

function MarkAbsentModal({
    open,
    onClose,
    data,
    dispatchMarkAbsent,
    ...props
}) {
    // const classes = useStyles();
    const [isOpen, toggleModal] = useState(open);
    const [loading, toggleLoading] = useState(false);
    const handleClose = () => {
        toggleModal(!isOpen);
        onClose && onClose();
    };

    useEffect(() => {
        console.log('DATAA123123123', data);
    }, []);

    const actions = [
        <Button
            key={1}
            variant="contained"
            color="default"
            className={`cancelButton`}
            onClick={handleClose}
            disableElevation
        >
            {'No'}
        </Button>,
        <Button
            key={2}
            variant="contained"
            className={`saveButton`}
            style={{
                backgroundColor: '#f44336',
                color: '#fff',
            }}
            disableElevation
            onClick={async () => {
                // alert(`${isEdit ? 'Edit Clicked' : 'Add Clicked'}`);
                await toggleLoading(true);

                dispatchMarkAbsent(
                    {
                        staffId: data?.staffId,
                        id: data?.id,
                    },
                    async () => {
                        console.log('Mark Absent SUCCESS');
                        await toggleLoading(false);
                        handleClose();
                    },
                    async () => {
                        console.log('Mark Absent Failed');
                        await toggleLoading(false);
                    },
                );
            }}
        >
            {'Yes'}
        </Button>,
    ];

    return (
        <Modal
            onClose={handleClose}
            open={isOpen}
            title={'Mark Absent'}
            actions={actions}
            loading={loading}
        >
            <Box>
                <Typography
                    style={{
                        textAlign: 'center',
                    }}
                >
                    Do you want to mark
                    <strong>{` ${data?.name} `}</strong>absent?
                </Typography>
                <Typography
                    style={{
                        textAlign: 'center',
                        marginTop: '8px',
                        marginBottom: '24px',
                    }}
                >
                    You can’t undo this action.
                </Typography>
            </Box>
        </Modal>
    );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
    dispatchMarkAbsent: (payload, onSuccess, onError) =>
        dispatch(markAbsent(payload, onSuccess, onError)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MarkAbsentModal);
