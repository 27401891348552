import React, { useEffect } from 'react';
import {
    makeStyles,
    withStyles,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    Box,
    TablePagination,
    Tooltip,
    IconButton,
} from '@material-ui/core/';
import EditIcon from '@material-ui/icons/Edit';
import ThumbnailPreview from '../../../components/ThumbnailPreview';
import { formatCurrency } from '../../../utils/helper';
import AddPaymentModal from '../../../components/Modals/AddPaymentModal';
import Currency from '../../../components/UI/Currency/Currency';

const StyledTableCell = withStyles((theme) => ({
    head: {
        color: theme.palette.common.white,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '& .MuiTableCell-body': {
            fontWeight: 600,
            color: '#000000',
            fontSize: 14,
        },
        '&:nth-of-type(even)': {
            boxShadow: 'inset 0 -1px 0 0 #ebedf8',
            backgroundColor: '#f2f4f8',
        },
    },
}))(TableRow);

const StyledTableHeader = withStyles((theme) => ({
    root: {
        boxShadow: 'inset 0 -1px 0 0 #ebedf8',
        backgroundColor: '#f2f4f8',
    },
}))(TableHead);

const useStyles = makeStyles({
    // table: {
    //     minWidth: 700,
    // },
    tableContainer: {
        border: '1px solid #ebedf8',
        borderRadius: 5,
    },
    tableHeaderText: {
        fontWeight: 700,
    },
    tableHeader: {
        background: '#f2f4f8',
    },
});

export default function PaymentsListContainer({
    fetchPaymentReport,
    permissions,
    ...props
}) {
    const classes = useStyles();
    const [paymentStaffModalOpen, setPaymentStaffModalOpen] =
        React.useState(false);
    const [selectedItemData, setSelectedItemData] = React.useState({});

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    useEffect(() => {
        setPage(0);
    }, [props?.rows]);

    return (
        <>
            <Box
                style={{
                    width: '100%',
                }}
            >
                <TableContainer
                    className={classes.tableContainer}
                    component={Box}
                >
                    <Table
                        size="small"
                        className={classes.table}
                        aria-label="customized table"
                    >
                        <StyledTableHeader>
                            <TableRow
                            // style={{
                            //     width: '100%',
                            // }}
                            >
                                <TableCell
                                // style={{
                                //     width: '17.5%',
                                // }}
                                >
                                    <Typography
                                        className={classes.tableHeaderText}
                                    >
                                        User Name
                                    </Typography>
                                </TableCell>
                                <TableCell
                                // style={{
                                //     width: '16%',
                                // }}
                                >
                                    <Typography
                                        className={classes.tableHeaderText}
                                    >
                                        Payment Date
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography
                                        className={classes.tableHeaderText}
                                    >
                                        Ref No.
                                    </Typography>
                                </TableCell>
                                <TableCell
                                // style={{
                                //     width: '10%',
                                // }}
                                >
                                    <Typography
                                        className={classes.tableHeaderText}
                                        style={{
                                            textAlign: 'right',
                                            paddingRight: 55,
                                        }}
                                    >
                                        Amount
                                    </Typography>
                                </TableCell>
                                <TableCell
                                // style={{
                                //     width: '18%',
                                // }}
                                >
                                    <Typography
                                        className={classes.tableHeaderText}
                                    >
                                        Notes
                                    </Typography>
                                </TableCell>
                                <TableCell
                                // style={{
                                //     width: '15.5%',
                                // }}
                                >
                                    <Typography
                                        className={classes.tableHeaderText}
                                    >
                                        Uploads
                                    </Typography>
                                </TableCell>
                                <TableCell
                                // style={{
                                //     width: '2%',
                                // }}
                                ></TableCell>
                            </TableRow>
                        </StyledTableHeader>
                        <TableBody>
                            {props?.rows
                                ?.slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage,
                                )
                                .map((row, index) => (
                                    <StyledTableRow key={index}>
                                        <TableCell
                                            component="th"
                                            scope="row"
                                            // style={{
                                            //     width: '17.5%',
                                            // }}
                                        >
                                            {row.userName ?? '-'}
                                        </TableCell>
                                        <TableCell
                                        // style={{
                                        //     width: '16%',
                                        // }}
                                        >
                                            {row.dateString ?? '-'}
                                        </TableCell>
                                        <TableCell
                                            style={{
                                                width: '100px',
                                            }}
                                        >
                                            {row?.paymentData ?? '-'}
                                        </TableCell>
                                        <TableCell
                                            style={{
                                                textAlign: 'right',
                                                paddingRight: 80,
                                            }}
                                        >
                                            <Currency amount={row.amount} />
                                        </TableCell>
                                        <TableCell
                                        // style={{
                                        //     width: '18%',
                                        // }}
                                        >
                                            {row.notes}
                                        </TableCell>
                                        <TableCell
                                            style={{
                                                minWidth: '5vw',
                                                maxWidth: '5vw',
                                                // border: '1px solid black',
                                            }}
                                        >
                                            <ThumbnailPreview
                                                images={
                                                    row?.docLink
                                                        ? [row?.docLink]
                                                        : []
                                                }
                                            />
                                        </TableCell>
                                        <TableCell
                                            style={{
                                                color: 'rgba(43, 43, 43, 0.54)',
                                            }}
                                        >
                                            <Tooltip
                                                title="Edit"
                                                placement="top"
                                            >
                                                <IconButton
                                                    color="inherit"
                                                    onClick={async () => {
                                                        await setSelectedItemData(
                                                            row,
                                                        );
                                                        setPaymentStaffModalOpen(
                                                            true,
                                                        );
                                                    }}
                                                    disabled={
                                                        !permissions.update
                                                    }
                                                >
                                                    <EditIcon
                                                        fontSize={'small'}
                                                    />
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell>
                                    </StyledTableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                <TablePagination
                    rowsPerPageOptions={[10, 50, 100]}
                    component="div"
                    count={props?.rows?.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
                {paymentStaffModalOpen && (
                    <AddPaymentModal
                        open={true}
                        staffInfo={{ id: selectedItemData.userId }}
                        data={selectedItemData}
                        onClose={() => {
                            fetchPaymentReport && fetchPaymentReport();
                            setPaymentStaffModalOpen(false);
                        }}
                    />
                )}
            </Box>
        </>
    );
}
