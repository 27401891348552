export const SINGLE_EV_ACTION_TYPES = Object.freeze({
    REMOTE_ON_OFF: 'REMOTE_ON_OFF',
    LOCATION: 'LOCATION',
    EDIT: 'EDIT',
});
export const historyTableConfig = {
    columns: [
        {
            name: 'Previous Riders',
            mappingKey: 'prevRider',
        },
        {
            name: 'Phone No.',
            mappingKey: 'phoneNumber',
        },
        {
            name: 'Captain',
            mappingKey: 'captain',
        },
        {
            name: 'Manager',
            mappingKey: 'manager',
        },
        {
            name: 'Tagged on',
            mappingKey: 'taggedOn',
        },
        {
            name: 'Untagged on',
            mappingKey: 'unTaggedOn',
        },
    ],
    columnsCurrentRider: [
        {
            name: 'Current Rider',
            mappingKey: 'curRider',
        },
        {
            name: 'Phone No.',
            mappingKey: 'phoneNumber',
        },
        {
            name: 'Captain',
            mappingKey: 'captain',
        },
        {
            name: 'Manager',
            mappingKey: 'manager',
        },
        {
            name: 'Tagged on',
            mappingKey: 'taggedOn',
        },
        {
            name: 'Untagged on',
            mappingKey: 'unTaggedOn',
        },
        {
            name: 'Order Status',
            mappingKey: 'orderStatus',
        },
    ],
};
export function generateMockTaggingHistory(count = 10) {
    // Helper function to generate random dates between two dates
    function getRandomDate(start: any, end: any) {
        return new Date(
            start.getTime() + Math.random() * (end.getTime() - start.getTime()),
        );
    }

    // Helper function to generate random names and order status
    function getRandomName() {
        const names = [
            'barjraj',
            'ramdin verma',
            'sharat chandran',
            'birender mandal',
            'amit',
            'kushal',
            'kasid',
            'shiv prakash',
            'vikram singh',
            'sanjay',
            'abhi',
            'ram dutt gupta',
            'khadak singh',
            'gurmit singh',
            'chanderpal',
            'aman',
            'khursid',
            'rajeev',
            'durgesh',
            'nahar singh',
            'Prathees',
            'sunder paal',
            'maansingh aswal',
            'rohit',
        ];
        return names[Math.floor(Math.random() * names.length)];
    }

    function getRandomOrderStatus() {
        const statuses = ['Completed', 'Pending', 'Cancelled', 'Live'];
        return statuses[Math.floor(Math.random() * statuses.length)];
    }

    function getRandomPhoneNumber() {
        const prefix = '+1';
        const number = Math.floor(Math.random() * 9000000000) + 1000000000;
        return `88${Math.floor(Math.random() * 1000000000)
            .toString()
            .padStart(8, '0')}`;
    }
    const tDate = getRandomDate(new Date(2023, 0, 1), new Date());

    const mockData: Array<any> = [
        {
            name: 'Prathees',
            contactNumber: getRandomPhoneNumber(),
            captain: getRandomName(),
            manager: getRandomName(),
            taggedDate: tDate.toISOString().split('T')[0],
            untaggedDate: null,
            orderStatus: getRandomOrderStatus(),
        },
    ];
    for (let i = 0; i < count; i++) {
        const taggedDate = getRandomDate(new Date(2023, 0, 1), new Date());
        const untaggedDate = getRandomDate(taggedDate, new Date());

        mockData.push({
            name: getRandomName(),
            contactNumber: getRandomPhoneNumber(),
            captain: getRandomName(),
            manager: getRandomName(),
            taggedDate: taggedDate.toISOString().split('T')[0],
            untaggedDate: untaggedDate.toISOString().split('T')[0],
            orderStatus: getRandomOrderStatus(),
        });
    }

    return mockData;
}

// Example usage
