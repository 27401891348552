import { Box, Button, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import Modal from './Modal';

function ConfirmModal({ open, onClose, confirmationText, onAction, ...props }) {
    const [isOpen, toggleModal] = useState(open);
    const [loading, toggleLoading] = useState(false);
    const handleClose = () => {
        // Setting form data to null before closing

        toggleModal(!isOpen);
        onClose && onClose();
    };

    const actions = [
        <Button
            key={1}
            variant="contained"
            color="default"
            className={`cancelButton`}
            onClick={handleClose}
            disableElevation
        >
            {'No'}
        </Button>,
        <Button
            key={2}
            variant="contained"
            className={`saveButton`}
            color="primary"
            disableElevation
            onClick={async () => {
                // alert(`${isEdit ? 'Edit Clicked' : 'Add Clicked'}`);
                await toggleLoading(true);
                onAction(toggleLoading);
            }}
        >
            Yes
        </Button>,
    ];
    return (
        <Modal
            onClose={handleClose}
            open={isOpen}
            title={'Action'}
            actions={actions}
            loading={loading}
        >
            <Box>
                <Typography
                    style={{
                        textAlign: 'center',
                    }}
                >
                    Do you want to {confirmationText.toLowerCase()}?
                </Typography>
            </Box>
        </Modal>
    );
}

export default ConfirmModal;
