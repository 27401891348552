import React from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    icon: {
        width: 192,
        height: 192,
        color: theme.palette.secondary.main,
    },
    container: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: `100vh`,
    },
    paper: {
        backgroundColor: theme.palette.background.default,
        margin: 0,
        height: `calc(100vh - 64px)`,
    },
    button: {
        marginTop: 20,
    },
}));

function Error404(props) {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <Typography variant="h4">Error 404</Typography>
            <Typography variant="h4">Page Not Found</Typography>
            <Button
                style={{
                    marginRight: '1rem',
                    fontSize: 14,
                    fontWeight: 700,
                    marginTop: 20,
                }}
                disableElevation
                variant="contained"
                color="primary"
                onClick={(e) => {
                    if (caches) {
                        // Service worker cache should be cleared with caches.delete()
                        caches.keys().then(function (names) {
                            for (let name of names) caches.delete(name);
                        });
                    }
                    localStorage.clear();
                    props.history.push('/login');
                }}
            >
                Go Home
            </Button>
        </div>
    );
}

export default Error404;
