import React, { Component } from 'react';
import Script from 'react-load-script';
import { MAP_KEY } from '../../../config/env/env';
import { CustomTextField } from '../../Textfield';

export class GooglePlaceAutoComplete extends Component {
    constructor(props) {
        super(props);

        // Declare State
        this.state = {
            pincode: '',
        };
    }

    componentDidMount() {
        this.setState({
            selectedAddress: this.props.selectedAddress,
        });
    }

    componentDidUpdate(oldProps) {
        if (
            this.props.selectedAddress &&
            oldProps.selectedAddress !== this.props.selectedAddress
        ) {
            this.setState({
                selectedAddress: this.props.selectedAddress,
            });
        }
    }

    handleScriptLoad = () => {
        // Declare Options For Autocomplete
        const options = {
            componentRestrictions: { country: 'in' },
        };

        // Initialize Google Autocomplete
        /*global google*/ // To disable any eslint 'google not defined' errors
        this.autocomplete = new google.maps.places.Autocomplete(
            document.getElementById('googleautocomplete'),
            options,
        );

        // Avoid paying for data that you don't need by restricting the set of
        // place fields that are returned to just the address components and formatted
        // address.
        this.autocomplete.setFields([
            'address_components',
            'formatted_address',
            'geometry.location',
        ]);

        // Fire Event when a suggested name is selected
        this.autocomplete.addListener('place_changed', this.handlePlaceSelect);
    };

    handlePlaceSelect = () => {
        //https://maps.googleapis.com/maps/api/geocode/json?address=1600+Amphitheatre+Parkway,+Mountain+View,+CA&key=AIzaSyBV_gUgzZyRxqspe6gNnFxIrK7GElIDMxY

        // Extract City From Address Object
        const addressObject = this.autocomplete.getPlace();
        const address = addressObject.address_components;
        // Check if address is valid
        if (this.props.location === 'locationSet') {
            let postal_code = '';
            let cityName;
            let cityCode;
            let stateName;
            let country;
            address.forEach((component) => {
                if (component.types.indexOf('postal_code') > -1) {
                    postal_code = component.long_name;
                }
                if (component.types.indexOf('locality') > -1) {
                    cityName = component.long_name;
                    cityCode = component.short_name;
                }
                if (
                    component.types.indexOf('administrative_area_level_1') > -1
                ) {
                    stateName = component.long_name;
                }
                if (component.types.indexOf('country') > -1) {
                    country = component.long_name;
                }
            });
            this.setState(
                {
                    selectedAddress: addressObject.formatted_address,
                    pincode: postal_code,
                    lat: addressObject.geometry.location.lat(),
                    lng: addressObject.geometry.location.lng(),
                    city: cityName,
                    cityCode: cityCode,
                    stateName: stateName,
                    country: country,
                },
                () => {
                    document.getElementById('googleautocomplete').value =
                        addressObject.formatted_address;
                    this.props.onSelection(
                        this.state.pincode,
                        this.state.selectedAddress,
                        this.state.lat,
                        this.state.lng,
                        this.state.city,
                        this.state.cityCode,
                        this.state.stateName,
                        this.state.country,
                    );
                },
            );
        } else if (address) {
            // Set State
            this.setState(
                {
                    selectedAddress: addressObject.formatted_address,
                    pincode: address[address.length - 1].long_name,
                },
                () => {
                    if (this.props.addressMode || !isNaN(this.state.pincode)) {
                        document.getElementById('googleautocomplete').value =
                            addressObject.formatted_address;
                        this.props.onSelection(
                            this.state.pincode,
                            this.state.selectedAddress,
                        );
                    } else {
                        console.log('No pincode found for selected address.');
                        // alert('No pincode found for selected address.');
                    }
                },
            );
        }
    };

    inputChange = (e) => {
        this.setState({
            selectedAddress: e.target.value,
        });
        this.props.onChange(e.target.value);
    };

    render() {
        return (
            <>
                <Script
                    url={`https://maps.googleapis.com/maps/api/js?key=${MAP_KEY}&components=country:in&libraries=geometry,places`}
                    onLoad={this.handleScriptLoad}
                />
                <CustomTextField
                    name={this.props.name}
                    fullWidth={true}
                    label="Address"
                    variant="outlined"
                    disabled={this.props.disabled}
                    onChange={(val) => this.inputChange(val)}
                    value={this.props.selectedAddress}
                    id="googleautocomplete"
                    style={{ marginBottom: '16px' }}
                />
            </>
        );
    }
}
