import React, { useState, useEffect } from 'react';
import {
    Button,
    Box,
    Divider,
    Typography,
    MenuItem,
    withStyles,
    IconButton,
    Tabs,
    Tab,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormLabel,
    FormControl,
    Checkbox,
} from '@material-ui/core';
import { connect } from 'react-redux';

import Modal from '../Modal';
import { CustomTextField } from '../../Textfield';
import { CustomMonthRangePickerDefault } from '../../DateTimePicker';
import {
    setIgnoreDateFilter,
    setFilterModalConfig,
    getSourceSystemsList,
    getSourceSystemsFiltersListData,
} from '../../../store/actions/authActionCreator';
import moment from 'moment';

// const useStyles = makeStyles((theme) => ({
//     modal: {},
// }));

function ComboViewModal({
    open,
    onClose,
    isEdit = false,
    data = {},
    user,
    setSelected,
    setPage,
    dispatchGetSourceSystemsList,
    dispatchGetSourceSystemsFiltersListData,
    dispatchSetFilterModalConfig,
    dispatchSetIgnoreDateFilter,
    ...props
}) {
    // const classes = useStyles();
    const [isOpen, toggleModal] = useState(open);
    const [isloading, toggleloading] = useState(false);
    const handleClose = () => {
        toggleModal(!isOpen);
        onClose && onClose(state);
    };

    const [paymentStatusList, setPaymentStatusList] = useState([
        {
            id: 1,
            name: 'All',
        },
        {
            id: 2,
            name: 'On Hold',
        },
        {
            id: 3,
            name: 'Ready For Payment',
        },
        {
            id: 4,
            name: 'In Progress',
        },
        {
            id: 5,
            name: 'Released',
        },
        {
            id: 6,
            name: 'Failed',
        },
    ]);

    const [state, setState] = useState({
        dateRange: user?.zappay?.filterGroupModalConfig?.dateRange,
        sourceSystem: user?.zappay?.filterGroupModalConfig?.sourceSystem,
        paymentStatus:
            user?.zappay?.filterGroupModalConfig?.paymentStatus ?? 'All',
        holdStatus: user?.zappay?.filterGroupModalConfig?.holdStatus ?? false,
        filters: user?.zappay?.filterGroupModalConfig?.filters ?? {},
    });

    const [sourceSystemList, setSourceSystemList] = useState(
        user?.zappay?.sourceSystemsListData ?? [],
    );

    const [groups, setGroups] = useState(
        user?.zappay?.sourceSystemFilterListData ?? [],
    );

    const [ignoreDataFilterKeys, setIgnoreDataFilterKeys] = useState([]);

    useEffect(() => {
        setGroups(user?.zappay?.sourceSystemFilterListData);
        setIgnoreDataFilterKeys(
            user?.zappay?.sourceSystemFilterListData
                ?.filter((item) => item?.ignoreDateFilter == true)
                ?.map((selected) => selected?.key),
        );
    }, [user?.zappay?.sourceSystemFilterListData]);

    useEffect(() => {
        console.log('defauly source System', state?.sourceSystem);

        console.log('sourceSystemList', sourceSystemList);

        dispatchGetSourceSystemsFiltersListData(
            {
                id: state?.sourceSystem?.id,
            },
            (resp) => {
                console.log(
                    'SOURCE SYSTEM FILTERS LIST Success',
                    resp?.filters,
                );
            },
            () => {
                console.log('SOURCE SYSTEM FILTERS LIST FAILED');
            },
        );
    }, []);

    // useEffect(() => {
    //     setSourceSystemList([
    //         {
    //             id: 1,
    //             name: 'StaffPay',
    //             default: true,
    //         },
    //         {
    //             id: 2,
    //             name: 'Atlas Earn',
    //             default: false,
    //         },
    //     ]);
    //     // dispatchGetSourceSystemsList(
    //     //     {},
    //     //     (resp) => {
    //     //         console.log('SOURCE SYSTEM LIST Success', resp?.sourceSystems);
    //     //     },
    //     //     (err) => {
    //     //         console.log('SOURCE SYSTEM LIST failed', err);
    //     //         setSourceSystemList([
    //     //             {
    //     //                 id: 1,
    //     //                 name: 'StaffPay',
    //     //                 default: true,
    //     //             },
    //     //             {
    //     //                 id: 2,
    //     //                 name: 'Atlas Earn',
    //     //                 default: false,
    //     //             },
    //     //         ]);
    //     //     },
    //     // );
    // }, []);

    // useEffect(() => {
    //     if (sourceSystemList) {
    //         console.log('sourceSystemLsit', sourceSystemList);

    //         let filteredDefaultSourceSystem = sourceSystemList.filter(
    //             (item) => item.default,
    //         );

    //         let defaultSourceSystem = filteredDefaultSourceSystem[0];

    //         setState((prev) => {
    //             return {
    //                 ...prev,
    //                 sourceSystem:
    //                     user?.zappay?.filterGroupModalConfig?.sourceSystem ??
    //                     defaultSourceSystem,
    //             };
    //         });

    //         if (defaultSourceSystem) {
    //             // dispatchGetSourceSystemsFiltersListData(
    //             //     {
    //             //         id: defaultSourceSystem?.id,
    //             //     },
    //             //     (resp) => {
    //             //         console.log(
    //             //             'SOURCE SYSTEM FILTERS LIST Success',
    //             //             resp?.filters,
    //             //         );
    //             //     },
    //             //     () => {
    //             //         console.log('SOURCE SYSTEM FILTERS LIST FAILED');
    //             //     },
    //             // );

    //             setGroups([
    //                 {
    //                     key: 'name',
    //                     displayName: 'Name',
    //                     values: ['Gokul', 'Avvai'],
    //                 },
    //                 {
    //                     key: 'hubName',
    //                     displayName: 'Hub Name',
    //                     values: ['GGG', 'AAA'],
    //                 },
    //                 {
    //                     key: 'weekNumber',
    //                     displayName: 'Week Name',
    //                     values: ['10', '20'],
    //                 },
    //             ]);
    //         }
    //     }
    // }, [sourceSystemList]);

    const actions = [
        <Button
            key={1}
            variant="contained"
            color="default"
            className={`cancelButton`}
            disableElevation
            onClick={() => {
                console.log(
                    'user?.filterGroupModalConfig',
                    user?.filterGroupModalConfig,
                );

                console.log('state', state);
                handleClose();
            }}
        >
            {'Cancel'}
        </Button>,
        <Button
            key={2}
            variant="contained"
            color="primary"
            className={`saveButton`}
            disableElevation
            onClick={() => {
                // alert(`${isEdit ? 'Edit Clicked' : 'Add Clicked'}`);
                // toggleloading(true);
                // 1. populate payload with state.filters values
                // 2. API call to filter
                console.log('stateeee', state);

                // SetIgnoreDateFilter
                let selectedIgnoreDataFilters = Object.entries(
                    state?.filters,
                )?.filter((selectedFilter) =>
                    ignoreDataFilterKeys?.includes(selectedFilter[0]),
                );

                if (selectedIgnoreDataFilters?.length > 0) {
                    console.log('setting it true');
                    dispatchSetIgnoreDateFilter(true);
                } else {
                    dispatchSetIgnoreDateFilter(false);
                }

                dispatchSetFilterModalConfig(
                    state,
                    () => {
                        console.log('dispatchSetFilterModalConfig success');
                    },
                    () => {
                        console.log('dispatchSetFilterModalConfig failed');
                    },
                );
                setSelected([]);
                setPage(0);
                handleClose();
            }}
        >
            Save
        </Button>,
    ];

    const handleInputChange = (value, key, filterKey = null) => {
        console.log('value', value, key);
        if (key == 'filters') {
            setState((state) => ({
                ...state,
                filters: {
                    ...state?.filters,
                    [filterKey]: value,
                },
            }));
        } else if (key == 'sourceSystem') {
            setState((state) => ({
                ...state,
                [key]: value,
                filters: {},
            }));
        } else {
            setState((state) => ({
                ...state,
                [key]: value,
            }));
        }
        if (key == 'sourceSystem') {
            console.log('CALLING GET FILTERS');

            dispatchGetSourceSystemsFiltersListData(
                {
                    id: value?.id,
                },
                (resp) => {
                    console.log(
                        'SOURCE SYSTEM FILTERS LIST Success',
                        resp?.filters,
                    );
                },
                () => {
                    console.log('SOURCE SYSTEM FILTERS LIST FAILED');
                },
            );
        }
    };

    useEffect(() => {
        console.log('STATE', state);
    }, [state]);

    useEffect(() => {}, [groups]);

    const StyledMenuItem = withStyles((theme) => ({
        root: {
            margin: '0 8px',
            border: '0 !important',
            borderRadius: '8px',
            '&:hover': {
                backgroundColor: 'rgba(67, 65, 158, 0.08)',
                color: '#43419e',
                borderRadius: '8px',
            },
        },
    }))(MenuItem);

    return (
        <Modal
            onClose={handleClose}
            open={isOpen}
            title={'Payment Indent Filter'}
            // subtitle={
            //     isEdit
            //         ? 'Edit Staff Details'
            //         : 'Add New Staff to your team and start recording their progress and earnings.'
            // }
            actions={actions}
            loading={isloading}
            maxWidth={'sm'}
            containerStyle={{
                height: 'auto',
            }}
        >
            <CustomTextField
                label="Source System"
                select
                fullWidth={true}
                value={state?.sourceSystem?.id ?? ''}
                onChange={(e) => {
                    let selectedSourceSystem = sourceSystemList?.filter(
                        (item) => item.id == e.target.value,
                    );
                    handleInputChange(selectedSourceSystem[0], 'sourceSystem');
                }}
                defaultValue=""
                style={{ margin: '0 12px 16px 0' }}
            >
                {sourceSystemList?.length &&
                    sourceSystemList?.map((type, index) => (
                        <StyledMenuItem key={index} value={type.id}>
                            {type.name}
                        </StyledMenuItem>
                    ))}
            </CustomTextField>

            <CustomMonthRangePickerDefault
                value={state?.dateRange}
                defaultValue={state?.dateRange}
                onChange={async (e) => {
                    console.log('dateee', e);
                    handleInputChange(e.dateRange, 'dateRange');
                    // await handleInputChange(e, 'dateRange');
                    // fetchEarningReport({
                    //     ...payload,
                    //     dateRange: e,
                    // });
                }}
            />
            <Box
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    height: '90%',
                }}
            >
                <Box style={{ width: '100%' }}>
                    <CustomTextField
                        label="Payment Status"
                        select
                        fullWidth={true}
                        value={state?.paymentStatus ?? ''}
                        onChange={(e) =>
                            handleInputChange(e.target.value, 'paymentStatus')
                        }
                        defaultValue=""
                        style={{ margin: '16px 12px 8px 0' }}
                    >
                        {paymentStatusList?.length &&
                            paymentStatusList?.map((type, index) => (
                                <StyledMenuItem key={index} value={type.name}>
                                    {type.name}
                                </StyledMenuItem>
                            ))}
                    </CustomTextField>
                </Box>
            </Box>
            <Typography
                style={{
                    fontSize: '16px',
                    fontWeight: 700,
                    marginTop: '4px',
                }}
                variant="body1"
            >
                {`${state?.sourceSystem?.name} Filters`}
            </Typography>

            {groups?.map((group, index) => (
                <CustomTextField
                    key={group?.key}
                    label={group?.displayName}
                    select
                    fullWidth={true}
                    value={state?.filters[group?.key] ?? ''}
                    onChange={(e) => {
                        console.log(
                            'e.target.value',
                            e.target.value,
                            group?.key,
                            state?.filters?.key,
                        );
                        handleInputChange(
                            e.target.value,
                            'filters',
                            group?.key,
                        );
                    }}
                    defaultValue=""
                    style={{ margin: '8px 12px 8px 0' }}
                >
                    {group?.values?.length &&
                        group?.values?.map((val, index) => (
                            <StyledMenuItem key={index} value={val}>
                                {val}
                            </StyledMenuItem>
                        ))}
                </CustomTextField>
            ))}
        </Modal>
    );
}

const mapStateToProps = (state) => ({
    user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
    dispatchGetSourceSystemsList: (data, onSuccess, onError) =>
        dispatch(getSourceSystemsList(data, onSuccess, onError)),

    dispatchGetSourceSystemsFiltersListData: (data, onSuccess, onError) =>
        dispatch(getSourceSystemsFiltersListData(data, onSuccess, onError)),

    // ---------------------------------------------------------------------------------

    dispatchSetFilterModalConfig: (data, onSuccess, onError) =>
        dispatch(setFilterModalConfig(data, onSuccess, onError)),

    dispatchSetIgnoreDateFilter: (data, onSuccess, onError) =>
        dispatch(setIgnoreDateFilter(data, onSuccess, onError)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ComboViewModal);
