import React from 'react';
import {
    withStyles,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Box,
    TablePagination,
    Tooltip,
    IconButton,
} from '@material-ui/core/';
import EditIcon from '@material-ui/icons/Edit';
import ThumbnailPreview from '../../../components/ThumbnailPreview';
import AddWorkModal from '../../../components/Modals/AddWorkModal';
import AddAllowanceBonusModal from '../../../components/Modals/AddAllowanceBonusModal';
import AddDeductionModal from '../../../components/Modals/AddDeductionModal';
import Currency from '../../../components/UI/Currency/Currency';

const StyledTableCell = withStyles((theme) => ({
    head: {
        color: theme.palette.common.white,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '& .MuiTableCell-body': {
            fontWeight: 600,
            color: '#000000',
            fontSize: 14,
        },
        '&:nth-of-type(even)': {
            boxShadow: 'inset 0 -1px 0 0 #ebedf8',
            backgroundColor: '#f2f4f8',
        },
    },
}))(TableRow);

const StyledTableHeader = withStyles((theme) => ({
    root: {
        boxShadow: 'inset 0 -1px 0 0 #ebedf8',
        backgroundColor: '#f2f4f8',
    },
}))(TableHead);

const useStyles = makeStyles({
    // table: {
    //     minWidth: 700,
    // },
    tableContainer: {
        border: '1px solid #ebedf8',
        borderRadius: 5,
    },
    tableHeaderText: {
        fontWeight: 700,
    },
    tableHeader: {
        background: '#f2f4f8',
    },
});

export default function ExpensesListContainer({
    rows,
    permissions,
    fetchExpenseReport,
    ...props
}) {
    const classes = useStyles();
    const [selectedRow, setSelectedRow] = React.useState({});
    const [workStaffModalOpen, setWorkStaffModalOpen] = React.useState(false);
    const [bonusStaffModalOpen, setBonusStaffModalOpen] = React.useState(false);
    const [deductionStaffModalOpen, setDeductionStaffModalOpen] =
        React.useState(false);

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    React.useEffect(() => {
        setPage(0);
    }, [rows]);

    return (
        <>
            <Box style={{ width: '100%' }}>
                <TableContainer
                    className={classes.tableContainer}
                    component={Box}
                >
                    <Table
                        size="small"
                        className={classes.table}
                        aria-label="customized table"
                    >
                        <StyledTableHeader>
                            <TableRow
                            // style={{
                            //     width: '100%',
                            // }}
                            >
                                <TableCell
                                // style={{
                                //     width: '17.5%',
                                // }}
                                >
                                    <Typography
                                        className={classes.tableHeaderText}
                                    >
                                        User Name
                                    </Typography>
                                </TableCell>
                                <TableCell
                                // style={{
                                //     width: '16%',
                                // }}
                                >
                                    <Typography
                                        className={classes.tableHeaderText}
                                    >
                                        Date
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography
                                        className={classes.tableHeaderText}
                                    >
                                        Expense Type
                                    </Typography>
                                </TableCell>
                                <TableCell
                                // style={{
                                //     width: '10%',
                                // }}
                                >
                                    <Typography
                                        className={classes.tableHeaderText}
                                        style={{
                                            textAlign: 'right',
                                            paddingRight: 55,
                                        }}
                                    >
                                        Amount
                                    </Typography>
                                </TableCell>
                                <TableCell
                                // style={{
                                //     width: '18%',
                                // }}
                                >
                                    <Typography
                                        className={classes.tableHeaderText}
                                    >
                                        Notes
                                    </Typography>
                                </TableCell>
                                <TableCell
                                // style={{
                                //     width: '15.5%',
                                // }}
                                >
                                    <Typography
                                        className={classes.tableHeaderText}
                                    >
                                        Uploads
                                    </Typography>
                                </TableCell>
                                <TableCell
                                // style={{
                                //     width: '2%',
                                // }}
                                ></TableCell>
                            </TableRow>
                        </StyledTableHeader>
                        <TableBody>
                            {rows?.map((row, index) => (
                                <StyledTableRow key={index}>
                                    <TableCell
                                        component="th"
                                        scope="row"
                                        // style={{
                                        //     width: '17.5%',
                                        // }}
                                    >
                                        {row.userName ?? '-'}
                                    </TableCell>
                                    <TableCell
                                    // style={{
                                    //     width: '16%',
                                    // }}
                                    >
                                        {row.dateString ?? '-'}
                                    </TableCell>
                                    <TableCell
                                        style={{
                                            width: '150px',
                                        }}
                                    >
                                        {row?.expenseType ?? '-'}
                                    </TableCell>
                                    <TableCell
                                        style={{
                                            textAlign: 'right',
                                            paddingRight: 80,
                                        }}
                                    >
                                        <Currency amount={row.amount} />
                                    </TableCell>
                                    <TableCell
                                    // style={{
                                    //     width: '18%',
                                    // }}
                                    >
                                        {row.notes}
                                    </TableCell>
                                    <TableCell
                                        style={{
                                            minWidth: '5vw',
                                            maxWidth: '5vw',
                                            // border: '1px solid black',
                                        }}
                                    >
                                        <ThumbnailPreview
                                            images={row?.docLink ?? []}
                                        />
                                    </TableCell>
                                    <TableCell
                                        style={{
                                            color: 'rgba(43, 43, 43, 0.54)',
                                        }}
                                    >
                                        <Tooltip title="Edit" placement="top">
                                            <IconButton
                                                color="inherit"
                                                onClick={async () => {
                                                    await setSelectedRow(row);
                                                    switch (row.expenseType) {
                                                        case 'DELIVERY':
                                                            await setWorkStaffModalOpen(
                                                                true,
                                                            );
                                                            break;
                                                        case 'ALLOWANCE':
                                                            await setBonusStaffModalOpen(
                                                                true,
                                                            );
                                                            break;
                                                        case 'DEDUCTION':
                                                            await setDeductionStaffModalOpen(
                                                                true,
                                                            );
                                                            break;
                                                        default:
                                                            break;
                                                    }
                                                }}
                                                disabled={!permissions.update}
                                            >
                                                <EditIcon fontSize={'small'} />
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
            {workStaffModalOpen && (
                <AddWorkModal
                    open={true}
                    staffInfo={{ id: selectedRow.userId }}
                    isEdit={Boolean(Object.keys(selectedRow).length)}
                    data={{ ...selectedRow }}
                    onClose={() => {
                        setWorkStaffModalOpen(false);
                        fetchExpenseReport();
                    }}
                />
            )}
            {bonusStaffModalOpen && (
                <AddAllowanceBonusModal
                    open={true}
                    staffInfo={{ id: selectedRow.userId }}
                    isEdit={Boolean(Object.keys(selectedRow).length)}
                    data={{
                        ...selectedRow,
                        docLink:
                            selectedRow?.docLink?.length !== 0
                                ? selectedRow.docLink[0]
                                : '',
                    }}
                    onClose={() => {
                        setBonusStaffModalOpen(false);
                        fetchExpenseReport();
                    }}
                />
            )}
            {deductionStaffModalOpen && (
                <AddDeductionModal
                    open={true}
                    staffInfo={{ id: selectedRow.userId }}
                    isEdit={Boolean(Object.keys(selectedRow).length)}
                    data={{
                        ...selectedRow,
                        docLink:
                            selectedRow?.docLink?.length !== 0
                                ? selectedRow.docLink[0]
                                : '',
                    }}
                    onClose={() => {
                        setDeductionStaffModalOpen(false);
                        fetchExpenseReport();
                    }}
                />
            )}
        </>
    );
}
