import React from 'react';
import {
    Box,
    Typography,
    Switch,
    Tooltip,
    withStyles,
    Grid,
} from '@material-ui/core';
import { showMessage } from '../../../../utils/helper';
import ConfirmModal from '../../../../components/Modals/ConfirmModal';
interface StaffInfoProps {
    name?: string;
    contactNumber?: number;
    hubName?: string;
    vehicleNumber?: any;
    [key: string]: any;
}
const IOSSwitch = withStyles((theme) => ({
    root: {
        width: 42,
        height: 24,
        padding: 0,
        margin: 0,
    },
    switchBase: {
        padding: 1,
        '&$checked': {
            transform: 'translateX(16px)',
            color: theme.palette.common.white,
            '& + $track': {
                backgroundColor: '#52d869',
                opacity: 1,
                border: 'none',
            },
        },
        '&$focusVisible $thumb': {
            color: '#52d869',
            border: '6px solid #fff',
        },
    },
    thumb: {
        width: 22,
        height: 22,
    },
    track: {
        borderRadius: 26 / 2,
        border: `1px solid ${theme.palette.grey[400]}`,
        backgroundColor: theme.palette.grey[50],
        opacity: 1,
        transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
}))(({ classes, ...props }: any) => {
    return (
        <Switch
            focusVisibleClassName={classes.focusVisible}
            disableRipple
            classes={{
                root: classes.root,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
            }}
            {...props}
        />
    );
});

const StaffInfo: React.FC<StaffInfoProps> = ({
    name,
    contactNumber,
    hubName,
    vehicleNumber,
    ...props
}) => {
    const [checked, setChecked] = React.useState(true);
    const [confirmModalOpen, setConfirmModalOpen] = React.useState<any>(false);
    const handleChange = () => {
        if (checked) {
            showMessage('Remote Power off Command Sent', 'success');
        } else {
            showMessage('Remote Power on Command Sent', 'success');
        }
        setChecked(!checked);
    };
    return (
        <Box
            style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
            }}
        >
            <Tooltip title="Hub Name & Contact Number" placement="top">
                <Typography
                    gutterBottom
                    // variant="body2"
                    // component="div"
                    style={{
                        flexGrow: 0,
                        textAlign: 'left',
                        fontWeight: 600,
                        margin: 0,
                    }}
                >
                    {hubName ? `${hubName} | ` : ''}
                    {contactNumber ?? ''}
                </Typography>
            </Tooltip>

            {!!vehicleNumber && (
                <Tooltip title="Vehicle Number" placement="top">
                    <Typography
                        gutterBottom
                        // variant="body2"
                        // component="div"
                        style={{
                            flexGrow: 0,
                            color: '#14112d',
                            fontWeight: 600,
                            margin: 0,
                        }}
                    >
                        {vehicleNumber?.slice(0, vehicleNumber.length - 4) ??
                            ''}
                        <b style={{ color: 'black' }}>
                            {vehicleNumber?.slice(vehicleNumber.length - 4) ??
                                ''}
                        </b>
                    </Typography>
                </Tooltip>
            )}
            {!!props?.ev?.evId && !!props?.ev?.remoteControlEnabled && (
                <Typography component="div">
                    <Grid
                        component="label"
                        container
                        alignItems="center"
                        spacing={1}
                    >
                        <Grid item>EV Power : Off</Grid>
                        <Grid item>
                            <IOSSwitch
                                checked={
                                    props?.ev?.evTelematicInfo
                                        ?.vehicleIgnitionStatus
                                }
                                onChange={() => {
                                    setConfirmModalOpen(true);
                                }}
                                name="checkedC"
                            />
                        </Grid>
                        <Grid item>On</Grid>
                    </Grid>
                </Typography>
            )}
            {!!confirmModalOpen && !!props?.ev && (
                <ConfirmModal
                    open={confirmModalOpen}
                    data={props?.ev}
                    onClose={() => {
                        setConfirmModalOpen(false);
                    }}
                    confirmationText={`remotely switch ${
                        props?.ev?.evTelematicInfo?.vehicleIgnitionStatus
                            ? 'OFF'
                            : 'ON'
                    } the EV`}
                    onAction={() => {
                        setConfirmModalOpen(false);
                        let evDetailsTemp = {
                            ...props?.ev,
                        };
                        delete evDetailsTemp?.['onPressMobilize'];
                        !!props?.ev?.onPressMobilize &&
                            props?.ev?.onPressMobilize(evDetailsTemp);
                    }}
                />
            )}
        </Box>
    );
};

export default StaffInfo;
