import React, { useState, useEffect } from 'react';
import {
    Box,
    Paper,
    Typography,
    makeStyles,
    IconButton,
    Tooltip,
    TablePagination,
} from '@material-ui/core';
import { CustomMonthRangePicker } from '../../../components/DateTimePicker';
import Searchbar from '../../../components/Searchbar';
import EarningsListContainer from './EarningsListContainer';
import CustomSwitch from '../../../components/Switch';
import { Spinner } from '../../../components/UI';
import { GetEarningReport } from '../../../store/actions/reportActionCreator';
import { connect } from 'react-redux';
import {
    downloadData,
    formatCurrency,
    getLoginUser,
    JSONToCSVConvertor,
} from '../../../utils/helper';
import { EmptyIcon } from '../../../assets/svg';
import moment from 'moment';
import GetAppIcon from '@material-ui/icons/GetApp';
import { withRouter } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import '../../../components/DateTimePicker/react-datepicker.css';
import { CompactCustomTextField } from '../../../components/Textfield';
import InsertInvitationIcon from '@material-ui/icons/InsertInvitation';
import { useDebounce } from '../../../hooks/useDebounce';

const ENUM_GROUPBY = Object.freeze({
    DAILY: 'DAILY',
    MONTHLY: 'MONTHLY',
});

const useStyles = makeStyles((theme) => ({
    paymentsReportContainer: {
        padding: '1.5rem',
    },
    horizontalCardContents: {
        width: '60%',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'spaceBetween',
    },
    searchBar: {
        width: '50%',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
    },
    verticalCardContents: {
        flex: '1 1 25%',
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        justifyContent: 'center',
        border: 'solid 1px #ebedf8',
        borderRadius: '8px',
        backgroundColor: '#f6f7f9',
        paddingLeft: '16px',
        height: '88px',
        margin: '0 8px 0 0 ',
    },
    cardSubHeader: {
        fontWeight: 600,
    },
    cardHeader: {
        fontSize: '20px',
        fontWeight: 700,
    },
}));

function EarningsReportComponent({
    earnings,
    dispatchGetEarningReport,
    settings,
    ...props
}) {
    const { debounce, isBouncing } = useDebounce();
    const classes = useStyles();
    const [page, setPage] = React.useState(
        props?.location?.state?.payload?.pageNo ?? 0,
    );
    const [rowsPerPage, setRowsPerPage] = React.useState(
        props?.location?.state?.payload?.pageSize ?? 10,
    );
    const [searchVal, setSearchVal] = useState(
        props?.location?.state?.payload?.searchText ?? '',
    );
    const [openDateRangePicker, setOpenDateRangePicker] = useState(false);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    const [payload, setPayload] = useState(
        props?.location?.state?.payload ?? {
            pageNo: page + 1,
            pageSize: rowsPerPage,
            searchText: searchVal,
            groupBy: ENUM_GROUPBY.DAILY,
            dateRange: {
                from: moment(startDate)?.startOf('day')?.valueOf(),
                to: moment(endDate)?.endOf('day')?.valueOf(),
            },
            organisationId:
                settings?.basicDetails?.id ?? getLoginUser().organizationId,
            transactionPaymentTypes: ['EARNING'],
            earningTypes: ['ALLOWANCE', 'DELIVERY'],
        },
    );
    const [loading, toggleLoading] = useState(false);
    const [defaultList, setDefaultList] = useState(
        earnings?.earningSummaryList ?? [],
    );

    const onDateRangeChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
        if (start && end) {
            setPayload({
                ...payload,
                dateRange: {
                    from: moment(start)?.startOf('day')?.valueOf(),
                    to: moment(end)?.endOf('day')?.valueOf(),
                },
            });
            setOpenDateRangePicker(false);
        }
    };

    const handleChangePage = (event, newPage) => {
        console.log('SETPAGE ', newPage);
        setPage(newPage);
        setPayload((prev) => {
            return {
                ...prev,
                pageNo: newPage + 1,
            };
        });
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
        setPayload((prev) => {
            return {
                ...prev,
                pageNo: 1,
                pageSize: event.target.value,
            };
        });
    };

    useEffect(() => {
        setDefaultList(earnings?.earningSummaryList);
    }, [earnings?.earningSummaryList]);

    useEffect(() => {
        if (payload) fetchEarningReport({ ...payload });
    }, [payload]);

    const fetchEarningReport = (filters = {}) => {
        toggleLoading(true);
        const payload = {
            ...filters,
            organisationId:
                settings?.basicDetails?.id ?? getLoginUser().organizationId,
        };
        dispatchGetEarningReport(
            payload,
            (resp) => {
                console.log(resp);
                toggleLoading(false);
            },
            (err) => {
                console.log(err);
                toggleLoading(false);
            },
        );
    };

    const handleInputChange = async (value, key) => {
        if (key === 'groupBy') {
            setPage(0);
            await setPayload((prevState) => ({
                ...prevState,
                [key]: value,
                pageNo: 1,
            }));
        } else {
            await setPayload((prevState) => ({
                ...prevState,
                [key]: value,
            }));
        }
    };

    return (
        <Paper className={classes.paymentsReportContainer} elevation={0}>
            <Spinner loading={loading}>
                <Box style={{ marginBottom: '1rem' }}>
                    <Box
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Box
                            style={{
                                width: '20.2%',
                            }}
                        >
                            <CompactCustomTextField
                                label="Date Range"
                                InputProps={{
                                    readOnly: true,
                                    endAdornment: (
                                        <IconButton className={'inputbutton'}>
                                            <InsertInvitationIcon size="small" />
                                        </IconButton>
                                    ),
                                    style: { fontSize: 16, fontWeight: 600 },
                                }}
                                onClick={(e) =>
                                    setOpenDateRangePicker((prev) => !prev)
                                }
                                value={`${moment(startDate).format(
                                    'DD MMM YY',
                                )} to ${
                                    endDate
                                        ? moment(endDate).format('DD MMM YY')
                                        : ''
                                }`}
                                variant="outlined"
                            />
                            {openDateRangePicker && (
                                <DatePicker
                                    selected={startDate}
                                    onChange={onDateRangeChange}
                                    startDate={startDate}
                                    endDate={endDate}
                                    selectsRange
                                    inline
                                />
                            )}
                        </Box>
                        <Box
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                marginLeft: '1.5rem',
                            }}
                        >
                            <Box
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                }}
                            >
                                <Typography
                                    style={{
                                        marginRight: '0.5rem',
                                    }}
                                >
                                    Day Wise
                                </Typography>

                                <CustomSwitch
                                    checked={
                                        payload.groupBy === ENUM_GROUPBY.DAILY
                                    }
                                    onChange={async (e) => {
                                        await handleInputChange(
                                            ENUM_GROUPBY.DAILY,
                                            'groupBy',
                                        );
                                    }}
                                    name="dayWise"
                                />
                            </Box>
                            <Box
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    marginLeft: '1.5rem',
                                }}
                            >
                                <Typography
                                    style={{
                                        marginRight: '0.5rem',
                                    }}
                                >
                                    Month Wise
                                </Typography>
                                <CustomSwitch
                                    checked={
                                        payload.groupBy === ENUM_GROUPBY.MONTHLY
                                    }
                                    onChange={async (e) => {
                                        await handleInputChange(
                                            ENUM_GROUPBY.MONTHLY,
                                            'groupBy',
                                        );
                                    }}
                                    name="monthWise"
                                />
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginBottom: '1.5rem',
                    }}
                >
                    <Box className={classes.horizontalCardContents}>
                        <Box className={classes.verticalCardContents}>
                            <Typography className={classes.cardHeader}>
                                Total Amount
                            </Typography>
                            <Typography className={classes.cardSubHeader}>
                                {formatCurrency(earnings?.totalAmount)}
                            </Typography>
                        </Box>
                        <Box className={classes.verticalCardContents}>
                            <Typography className={classes.cardHeader}>
                                Total Deliveries
                            </Typography>
                            <Typography className={classes.cardSubHeader}>
                                {earnings?.totalDeliveries ?? 0}
                            </Typography>
                        </Box>
                    </Box>
                    <Box className={classes.searchBar}>
                        <Tooltip title="Download Report" placement="top">
                            <IconButton
                                className={'inputbutton'}
                                variant="outlined"
                                style={{
                                    marginRight: 16,
                                    border: '1px solid #43419e',
                                    borderRadius: 8,
                                    padding: 6,
                                }}
                                color="primary"
                                onClick={(e) => {
                                    debounce((e) => {
                                        toggleLoading(true);
                                        dispatchGetEarningReport(
                                            {
                                                ...payload,
                                                download: true,
                                            },
                                            (resp) => {
                                                if (
                                                    resp?.response
                                                        ?.earningsResponse
                                                        ?.s3DownloadLink
                                                ) {
                                                    downloadData(
                                                        resp?.response
                                                            ?.earningsResponse
                                                            ?.s3DownloadLink,
                                                        'Earnings Report',
                                                    );
                                                }
                                                console.log(resp);
                                                toggleLoading(false);
                                            },
                                            (err) => {
                                                console.log(err);
                                                toggleLoading(false);
                                            },
                                        );
                                    });
                                }}
                                disabled={
                                    isBouncing || !defaultList?.length
                                    // || payload?.groupBy === ENUM_GROUPBY.MONTHLY
                                }
                            >
                                <GetAppIcon size="small" />
                            </IconButton>
                        </Tooltip>
                        <Searchbar
                            placeHolderText="Search"
                            // placeHolderStyles={{
                            //     color: '#979797',
                            // }}
                            style={{
                                // border: '1px solid #f1f3f6',
                                // color: '#14112d',
                                width: '50%',
                            }}
                            dataSource={[]}
                            keys={['']}
                            fullWidth={false}
                            onFilter={async (filteredData) => {}}
                            onBackendSearch={(searchText) => {
                                setPage(0);
                                setSearchVal(searchText);
                                setPayload((prev) => {
                                    return {
                                        ...prev,
                                        pageNo: 1,
                                        searchText,
                                    };
                                });
                            }}
                            onChange={(value) => {
                                console.log('blur', value);
                                setSearchVal(value);
                            }}
                        />
                    </Box>
                </Box>
                {defaultList?.length ? (
                    <>
                        <EarningsListContainer
                            rows={defaultList ?? []}
                            isMonthly={payload.groupBy === ENUM_GROUPBY.MONTHLY}
                            fetchEarningsReport={() =>
                                fetchEarningReport({ ...payload })
                            }
                            payload={payload}
                        />
                        <TablePagination
                            rowsPerPageOptions={[10, 50, 100]}
                            component="div"
                            count={earnings?.totalFilteredCount}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    </>
                ) : (
                    <Box
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <EmptyIcon />
                    </Box>
                )}
            </Spinner>
        </Paper>
    );
}

const mapStateToProps = (state) => ({
    earnings: state.report.earnings,
    settings: state.settings,
});

const mapDispatchToProps = (dispatch) => ({
    dispatchGetEarningReport: (payload, onSuccess, onError) =>
        dispatch(GetEarningReport(payload, onSuccess, onError)),
});

export const EarningsReport = withRouter(
    connect(mapStateToProps, mapDispatchToProps)(EarningsReportComponent),
);
