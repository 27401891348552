import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
    Button,
    Box,
    Typography,
    withStyles,
    makeStyles,
    Dialog,
    Slide,
    IconButton,
    DialogTitle,
    Divider,
    DialogContent,
    List,
    ListItem,
    ListItemSecondaryAction,
    Collapse,
} from '@material-ui/core';

import {
    CloseIcon,
    OnlineStateIcon,
    PersonTickIcon,
    RightArrowIcon,
} from '../../assets/svg';
import { LightBoxImage, Spinner } from '../UI';
import ShiftColumnItemPopper from '../Containers/ShiftColumnItemPopper';
import { showMessage } from '../../utils/helper';
import moment from 'moment';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { HUB_TYPE_ENUM } from '../../utils/constants';
import { sortTime } from './AssignStaffToHubModal';

const useStyles = makeStyles((theme) => ({
    listItem: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        padding: '8px 16px',
        marginBottom: '8px',
        border: '1px solid #ebedf8 ',
        borderRadius: '8px',
        // height: '56px !important',÷
    },
    listItemHeader: {
        border: '0px',
        marginBottom: '2px',
        color: '#808080',
        fontWeight: 'bold',
        fontSize: 14,
    },
    modal: {
        '& .modal-head': {
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
        },
        '& .modal-title': {
            fontSize: '20px',
            fontWeight: 'bold',
            color: '#14112d',
        },
        '& .modal-subtitle': {
            fontSize: '14px',
            fontWeight: 'bold',
            color: '#14112d',
        },
    },
    oppositeContent: {
        flex: 0,
        padding: 0,
    },
}));

function AttendanceShiftActivityModal({
    open,
    onClose,
    data,
    activity,
    ...props
}) {
    const classes = useStyles();
    const [isOpen, toggleModal] = useState(open);
    const [expandedIndex, setExpandedIndex] = useState();
    const [loading, toggleLoader] = useState(false);
    const handleClose = () => {
        toggleModal(!isOpen);
        onClose && onClose();
    };

    useEffect(() => {
        console.log('activity modal', data);
    }, []);

    function ListItemLink({ ...props }) {
        // if (flag) {
        //     return <ListItem {...props} />;
        // } else {
        return <ListItem button component="a" {...props} />;
        // }
    }

    const PunchInOutItemUI = (isPunchIn, value, item) => {
        return (
            <Box
                className={classes.listItemHeader}
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: '100%',
                    color: '#303030',
                    border: '1px solid #ebedf8',
                    borderBottomWidth: '0.5px',
                    padding: '8px 16px',
                    fontSize: '12px',
                    margin: 0,
                }}
            >
                <Box
                    style={{
                        width: '18%',
                    }}
                >
                    <span
                        style={{
                            color: '#898896',
                        }}
                    >
                        {isPunchIn ? 'IN: ' : 'OUT: '}
                    </span>
                    {item?.time ? moment(item?.time).format('HH:mm A') : '-'}
                </Box>

                <Box
                    style={{
                        width: '14%',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'baseline',
                    }}
                >
                    {item?.lateBy ?? '-'}
                </Box>
                <Box
                    style={{
                        width: '60%',
                    }}
                >
                    {item?.address ?? '-'}
                </Box>
                <Box
                    style={{
                        width: '10%',
                    }}
                >
                    {item?.s3ImageUrl ? (
                        <LightBoxImage
                            containerStyles={{
                                width: 32,
                                height: 32,
                                marginRight: 5,
                            }}
                            imageStyles={{
                                width: 32,
                                height: 32,
                                margin: 'auto',
                                borderRadius: '6px',
                                objectFit: 'fill',
                                border: '1px solid rgba(38, 50, 56, 0.32)',
                            }}
                            source={item?.s3ImageUrl}
                        />
                    ) : (
                        '-'
                    )}
                </Box>
                <Box
                    style={{
                        width: '16%',
                    }}
                >
                    {!!item?.kmReading ? (
                        <Box
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                            }}
                        >
                            {item?.kmS3ImageUrl && (
                                <LightBoxImage
                                    containerStyles={{
                                        width: 32,
                                        height: 32,
                                        marginRight: 5,
                                    }}
                                    imageStyles={{
                                        width: 32,
                                        height: 32,
                                        margin: 'auto',
                                        borderRadius: '6px',
                                        objectFit: 'fill',
                                        border: '1px solid rgba(38, 50, 56, 0.32)',
                                    }}
                                    source={item?.kmS3ImageUrl}
                                />
                            )}
                            <Box
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    marginLeft: 6,
                                }}
                            >
                                <span
                                    style={{
                                        color: '#14112D',
                                        opacity: 0.6,
                                        fontWeight: 'normal',
                                    }}
                                >
                                    {isPunchIn ? 'Start' : 'End'} Km
                                </span>
                                <span>{item?.kmReading ?? '-'}</span>
                            </Box>
                        </Box>
                    ) : (
                        '-'
                    )}
                </Box>
                {value?.primaryOperation === HUB_TYPE_ENUM.FOOD.value ? (
                    <Box
                        style={{
                            width: '20%',
                        }}
                    >
                        Auto-Approved
                    </Box>
                ) : !!item?.approvalTime && !!item?.approvedBy ? (
                    <Box
                        style={{
                            width: '20%',
                        }}
                    >
                        {item?.approvedBy ?? '-'}
                        {' at '}
                        {item?.approvalTime ?? '-'}
                    </Box>
                ) : !!item?.approvedBy ? (
                    <Box
                        style={{
                            width: '20%',
                        }}
                    >
                        Auto-Approved
                    </Box>
                ) : (
                    <Box
                        style={{
                            width: '20%',
                        }}
                    >
                        -
                    </Box>
                )}
            </Box>
        );
    };

    const PunchInOutItemEmptyUI = () => {
        return (
            <Box
                className={classes.listItemHeader}
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: '100%',
                    color: '#303030',
                    border: '1px solid #ebedf8',
                    borderBottomWidth: '0.5px',
                    padding: '8px 16px',
                    fontSize: '12px',
                    margin: 0,
                    borderBottomLeftRadius: '8px',
                    borderBottomRightRadius: '8px',
                }}
            >
                <Box
                    style={{
                        width: '18%',
                    }}
                >
                    --
                </Box>

                <Box
                    style={{
                        width: '14%',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'baseline',
                    }}
                >
                    --
                </Box>
                <Box
                    style={{
                        width: '60%',
                    }}
                >
                    --
                </Box>
                <Box
                    style={{
                        width: '10%',
                    }}
                >
                    --
                </Box>
                <Box
                    style={{
                        width: '16%',
                    }}
                >
                    --
                </Box>
                <Box
                    style={{
                        width: '20%',
                    }}
                >
                    --
                </Box>
            </Box>
        );
    };

    const handleRowExpansion = (index) => {
        setExpandedIndex(expandedIndex === index ? null : index);
    };

    return (
        <Dialog
            open={isOpen}
            keepMounted
            onClose={handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            className={classes.modal}
            fullWidth={true}
            maxWidth={'md'}
        >
            <Spinner loading={loading}>
                <DialogTitle
                    id="alert-dialog-slide-title"
                    style={{
                        top: 0,
                        overflow: 'hidden',
                        position: 'sticky',
                        background: 'white',
                        zIndex: 999,
                        paddingBottom: 0,
                    }}
                >
                    <Box className={`modal-head`}>
                        <div>
                            <Typography
                                className={`modal-title`}
                                variant="body2"
                            >
                                {data?.name}
                            </Typography>
                            <Box
                                className={`modal-head`}
                                style={{ alignItems: 'center' }}
                            >
                                <Typography
                                    className={`modal-subtitle`}
                                    variant="body2"
                                >
                                    {data?.dateDisplay}
                                </Typography>
                                <Box
                                    style={{
                                        borderLeft: '2px solid #14112d',
                                        height: 16,
                                        marginLeft: '6px',
                                        borderRadius: 50,
                                        opacity: 0.4,
                                    }}
                                />
                                <Box style={{ marginTop: 5 }}>
                                    <ShiftColumnItemPopper
                                        data={activity}
                                        limit={2}
                                    />
                                </Box>
                            </Box>
                        </div>
                        <div
                            style={{
                                // alignSelf: 'center',
                                justifySelf: 'flex-end',
                            }}
                        >
                            <IconButton
                                aria-label="delete"
                                className={classes.iconButton}
                                onClick={handleClose}
                            >
                                <CloseIcon />
                            </IconButton>
                        </div>
                    </Box>
                    <Divider style={{ marginTop: 12, marginBottom: 5 }} />
                </DialogTitle>
                <DialogTitle
                    id="alert-dialog-slide-title"
                    style={{ paddingTop: 0 }}
                >
                    <List component="nav">
                        <ListItem className={classes.listItemHeader}>
                            <Box
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    width: '100%',
                                    color: '#818181',
                                    fontSize: '14px',
                                }}
                            >
                                <Box
                                    style={{
                                        width: '25%',
                                    }}
                                >
                                    Shift Details
                                </Box>

                                <Box
                                    style={{
                                        width: '30%',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'baseline',
                                    }}
                                >
                                    Hub
                                </Box>

                                <Box style={{ width: '20%' }}>Total Hrs</Box>
                                <Box style={{ width: '25%' }}>
                                    Approve Status
                                </Box>
                            </Box>
                        </ListItem>
                        {sortTime(activity?.lineItemList ?? []).map(
                            (value, index) => {
                                const currentTime = moment();
                                let currentShift = {};
                                activity?.hubShiftMappings.forEach((hub) => {
                                    currentShift = hub.shiftLineItems.find(
                                        (shift) => shift?.id === value?.shiftId,
                                    );
                                });
                                const isShiftActive = moment(
                                    currentTime,
                                ).isBetween(
                                    currentShift?.startTimeEpochMillis,
                                    currentShift?.endTimeEpochMillis,
                                    'HH:mm',
                                );
                                let color = '#f44336';
                                color = !!value?.primaryOperation
                                    ? HUB_TYPE_ENUM[
                                          value?.primaryOperation?.toUpperCase() ??
                                              ''
                                      ]?.color
                                    : '#f44336';
                                console.log('timing', value, isShiftActive);
                                return (
                                    <>
                                        <ListItemLink
                                            className={classes.listItem}
                                            onClick={() => {
                                                handleRowExpansion(index);
                                            }}
                                            key={index}
                                            style={{
                                                fontSize: '14px',
                                                ...(index % 2
                                                    ? {
                                                          backgroundColor:
                                                              'rgba(241, 243, 246, 1)',
                                                      }
                                                    : {}),
                                                ...(expandedIndex === index && {
                                                    borderBottomLeftRadius: 0,
                                                    borderBottomRightRadius: 0,
                                                    borderBottom: 'none',
                                                    marginBottom: 0,
                                                }),
                                            }}
                                        >
                                            <Box
                                                style={{
                                                    width: '25%',
                                                }}
                                                // className={classes.tableCell}
                                            >
                                                <Box
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        flexWrap: 'wrap',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <Typography
                                                        style={{
                                                            wordBreak:
                                                                'break-all',
                                                            fontWeight: 600,
                                                            marginRight: 5,
                                                            fontSize: '16px',
                                                        }}
                                                    >
                                                        {value.shiftName ?? '-'}
                                                    </Typography>
                                                    {isShiftActive && (
                                                        <OnlineStateIcon
                                                            fill={'#4CAF50'}
                                                            style={{
                                                                marginLeft: 4,
                                                            }}
                                                        />
                                                    )}
                                                </Box>
                                                <Box
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        flexWrap: 'wrap',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <Box
                                                        style={{
                                                            border: '1px solid',
                                                            borderColor: color,
                                                            color,
                                                            padding: '0 8px',
                                                            // paddingLeft: 8,
                                                            borderRadius: '4px',
                                                            margin: '4px',
                                                            marginLeft: 0,
                                                            display: 'flex',
                                                            alignItems:
                                                                'center',
                                                            fontSize: 12,
                                                            ...(isShiftActive
                                                                ? {
                                                                      color: '#fff',
                                                                      backgroundColor:
                                                                          color,
                                                                  }
                                                                : {
                                                                      opacity: 0.6,
                                                                  }),
                                                        }}
                                                    >
                                                        {!!isShiftActive && (
                                                            <OnlineStateIcon
                                                                style={{
                                                                    marginRight:
                                                                        '2px',
                                                                }}
                                                            />
                                                        )}
                                                        {!!value?.startTimeEpochMillis &&
                                                        !!value?.endTimeEpochMillis
                                                            ? `${moment(
                                                                  value?.startTimeEpochMillis,
                                                              ).format(
                                                                  'HH:mm',
                                                              )} - ${moment(
                                                                  value?.endTimeEpochMillis,
                                                              ).format(
                                                                  'HH:mm',
                                                              )}`
                                                            : ''}
                                                    </Box>
                                                    {/* <Typography
                                                    style={{
                                                        // width: '40%',
                                                        fontSize: '12px',
                                                        marginRight: '4px',
                                                    }}
                                                >
                                                    {value?.startTime ?? '-'}
                                                </Typography>
                                                {' - '}
                                                <Typography
                                                    style={{
                                                        fontSize: '12px',
                                                        marginLeft: '4px',
                                                    }}
                                                >
                                                    {value.endTime ?? '-'}
                                                </Typography> */}
                                                </Box>
                                            </Box>
                                            <Box
                                                style={{
                                                    width: '30%',
                                                }}
                                                // className={classes.tableCell}
                                            >
                                                <Typography
                                                    style={{
                                                        wordBreak: 'break-all',
                                                        fontWeight: 600,
                                                        marginRight: 5,
                                                        fontSize: '16px',
                                                    }}
                                                >
                                                    {value.hubName ?? '-'}
                                                </Typography>
                                            </Box>

                                            <Box
                                                style={{
                                                    width: '20%',
                                                }}
                                                // className={classes.tableCell}
                                            >
                                                <Typography
                                                    style={{
                                                        wordBreak: 'break-all',
                                                        fontWeight: 600,
                                                        marginRight: 5,
                                                        fontSize: '14px',
                                                    }}
                                                >
                                                    {value?.totalHrs ?? '-'}
                                                </Typography>
                                                {!!value?.online &&
                                                    !!value?.offline && (
                                                        <Typography
                                                            style={{
                                                                wordBreak:
                                                                    'break-all',
                                                                fontWeight: 600,
                                                                marginRight: 5,
                                                                fontSize:
                                                                    '12px',
                                                                color: 'rgba(20, 17, 45, 0.7)',
                                                            }}
                                                        >
                                                            {`${
                                                                !!value?.online
                                                                    ? moment(
                                                                          value?.online,
                                                                      ).format(
                                                                          'HH:mm',
                                                                      )
                                                                    : '-'
                                                            }
                                                    to
                                                    ${
                                                        !!value?.offline
                                                            ? moment(
                                                                  value?.offline,
                                                              ).format('HH:mm')
                                                            : '-'
                                                    }`}
                                                        </Typography>
                                                    )}
                                            </Box>
                                            <Box
                                                style={{
                                                    width: '12%',
                                                    textAlign: 'right',
                                                }}
                                                // className={classes.tableCell}
                                            >
                                                {!value?.attendaceActivity
                                                    ?.length ? (
                                                    <Button
                                                        style={{
                                                            width: '88px',
                                                            backgroundColor:
                                                                'rgba(244, 67, 54, 0.16)',
                                                            color: '#F44336',
                                                        }}
                                                    >
                                                        Absent
                                                    </Button>
                                                ) : (
                                                    <IconButton
                                                        style={{
                                                            ...(value?.punchIn
                                                                ?.status ===
                                                                'PENDING_APPROVAL' ||
                                                            value?.punchOut
                                                                ?.status ===
                                                                'PENDING_APPROVAL'
                                                                ? {
                                                                      padding: 0,
                                                                  }
                                                                : {
                                                                      opacity: 0.3,
                                                                      padding: 0,
                                                                  }),
                                                        }}
                                                        disabled={
                                                            !(
                                                                value?.punchIn
                                                                    ?.status ===
                                                                    'PENDING_APPROVAL' ||
                                                                value?.punchOut
                                                                    ?.status ===
                                                                    'PENDING_APPROVAL'
                                                            )
                                                        }
                                                    >
                                                        <PersonTickIcon size="small" />
                                                    </IconButton>
                                                )}
                                            </Box>
                                            <ListItemSecondaryAction>
                                                <IconButton
                                                    onClick={() => {
                                                        handleRowExpansion(
                                                            index,
                                                        );
                                                    }}
                                                >
                                                    {expandedIndex !== index ? (
                                                        <ExpandMoreIcon
                                                            color="rgba(0, 0, 0, 0.48)"
                                                            size="small"
                                                        />
                                                    ) : (
                                                        <ExpandLessIcon
                                                            color="rgba(0, 0, 0, 0.48)"
                                                            size="small"
                                                        />
                                                    )}
                                                </IconButton>
                                            </ListItemSecondaryAction>
                                        </ListItemLink>
                                        <Collapse
                                            style={{
                                                ...(expandedIndex === index && {
                                                    marginBottom: 8,
                                                }),
                                            }}
                                            in={expandedIndex === index}
                                        >
                                            <Box
                                                className={
                                                    classes.listItemHeader
                                                }
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    width: '100%',
                                                    color: '#303030',
                                                    border: '1px solid #ebedf8',
                                                    backgroundColor: '#EFEFEF',
                                                    padding: '8px 16px',
                                                    fontSize: '12px',
                                                    margin: 0,
                                                }}
                                            >
                                                <Box
                                                    style={{
                                                        width: '18%',
                                                    }}
                                                >
                                                    {'In & Out Time'}
                                                </Box>

                                                <Box
                                                    style={{
                                                        width: '14%',
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        alignItems: 'baseline',
                                                    }}
                                                >
                                                    Late By
                                                </Box>
                                                <Box style={{ width: '60%' }}>
                                                    Location
                                                </Box>
                                                <Box style={{ width: '10%' }}>
                                                    Selfie
                                                </Box>
                                                <Box style={{ width: '16%' }}>
                                                    KM Reading
                                                </Box>
                                                <Box style={{ width: '20%' }}>
                                                    {'Approved By & Time'}
                                                </Box>
                                            </Box>
                                            {value?.attendaceActivity
                                                ?.length ? (
                                                value?.attendaceActivity?.map(
                                                    (item) => {
                                                        return (
                                                            <>
                                                                {/* punchIn  data */}
                                                                {item?.punchIn
                                                                    ?.time &&
                                                                    PunchInOutItemUI(
                                                                        true,
                                                                        value,
                                                                        item?.punchIn ??
                                                                            {},
                                                                    )}
                                                                {/* punchOut  data */}
                                                                {item?.punchOut
                                                                    ?.time &&
                                                                    PunchInOutItemUI(
                                                                        false,
                                                                        value,
                                                                        item?.punchOut ??
                                                                            {},
                                                                    )}
                                                            </>
                                                        );
                                                    },
                                                )
                                            ) : (
                                                <>{PunchInOutItemEmptyUI()}</>
                                            )}
                                        </Collapse>
                                    </>
                                );
                            },
                        )}
                    </List>
                </DialogTitle>
            </Spinner>
        </Dialog>
    );
}
const mapStateToProps = (state) => ({
    activity: state.attendance.timelineActivity,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AttendanceShiftActivityModal);
