import React, { useState, useEffect } from 'react';
import { Button, Checkbox, withStyles } from '@material-ui/core';
export const SelectCheckBox = withStyles({
    root: {
        color: '#807E8E',
        '&$checked': {
            color: '#4260FF',
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);
