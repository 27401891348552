import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector, DefaultRootState } from 'react-redux';
import moment from 'moment';
import {
    Box,
    Typography,
    Tooltip,
    Divider,
    Tabs,
    Tab,
    withStyles,
    AppBar,
    makeStyles,
    useTheme,
    Paper,
    IconButton,
    Grid,
    TablePagination,
    TableCell,
    TableRow,
    TableContainer,
    TableBody,
    TableHead,
    Table,
} from '@material-ui/core';
import tableConfig from './table-config.json';
import OrdersData from './orders-data.json';
import WarningIcon from '@material-ui/icons/Warning';
import { FILTER_INPUT_TYPE } from '../../../components/Modals/FilterModal';
import {
    getShiftTimingsList,
    getHubList,
    getAllStaffs,
    getV2AllStaffs,
    getAllAlerts,
    getAllRiders,
    getAllOrders,
    updateAlerts,
    getAlertsHistory,
    getPingsRider,
    getCompletedOrdersRider,
    getEventsRider,
    getMissedOrdersRider,
    getCancelledOrdersRider,
    getPingsRiderFromMeta,
    getEventsForOrder,
    getClusterList,
} from '../../../store/actions/staffActionCreator';
import { updateFilterData } from '../../../store/actions/controlCenterActionCreator';
import useControlsStyles from '../styles/useControlsStyles';
import {
    CHIPS_CLASS_TYPE_MAPPING,
    CHIPS_CLASS_TYPE_ORDERS_STATUS_MAPPING,
    CHIPS_CLASS_TYPE_RIDERS_STATUS_MAPPING,
    CONTROL_TYPES,
    getShiftLabel,
    isFirstVisitToday,
    removeKeysFromObject,
    replaceWithSpace,
    replaceWithUnderscore,
} from '../helper';
import clsx from 'clsx';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import CallIcon from '@material-ui/icons/Call';
import { AlertDelivery, AlertRider } from '../../../assets/svg';
import { showMessage } from '../../../utils/helper';
const useControlTable = ({
    handleLoading,
    controlType,
    lastUpdated,
    setLastUpdated,
    onMountFilterUpdate,
}: any) => {
    const dispatch = useDispatch();
    const dispatchGetShiftTimingsList = (
            data: any,
            onSuccess: Function,
            onError: Function,
        ) => dispatch(getShiftTimingsList(data, onSuccess, onError)),
        dispatchGetHubList = (
            id: any,
            onSuccess: Function,
            onError: Function,
        ) => dispatch(getHubList(id, onSuccess, onError)),
        dispatchGetClustersList = (
            id: any,
            onSuccess: Function,
            onError: Function,
        ) => dispatch(getClusterList(id, onSuccess, onError)),
        dispatchGetAllStaffs = (
            payload: any,
            onSuccess: Function,
            onError: Function,
        ) => dispatch(getAllStaffs(payload, onSuccess, onError)),
        dispatchGetV2AllStaffs = (
            payload: any,
            onSuccess: Function,
            onError: Function,
        ) => dispatch(getV2AllStaffs(payload, onSuccess, onError)),
        dispatchGetAllOrders = (
            payload: any,
            onSuccess: Function,
            onError: Function,
        ) => dispatch(getAllOrders(payload, onSuccess, onError)),
        dispatchGetAllRiders = (
            payload: any,
            onSuccess: Function,
            onError: Function,
        ) => dispatch(getAllRiders(payload, onSuccess, onError)),
        dispatchGetAllAlerts = (
            payload: any,
            onSuccess: Function,
            onError: Function,
        ) => dispatch(getAllAlerts(payload, onSuccess, onError)),
        dispatchUpdateAlertStatus = (
            payload: any,
            onSuccess: Function,
            onError: Function,
        ) => dispatch(updateAlerts(payload, onSuccess, onError)),
        dispatchGetAlertHistory = (
            payload: any,
            onSuccess: Function,
            onError: Function,
        ) => dispatch(getAlertsHistory(payload, onSuccess, onError)),
        dispatchGetPingsRider = (
            payload: any,
            onSuccess: Function,
            onError: Function,
        ) => dispatch(getPingsRider(payload, onSuccess, onError)),
        dispatchGetPingsRiderFromMeta = (
            payload: any,
            onSuccess: Function,
            onError: Function,
        ) => dispatch(getPingsRiderFromMeta(payload, onSuccess, onError)),
        dispatchGetRiderCompletedOrders = (
            payload: any,
            onSuccess: Function,
            onError: Function,
        ) => dispatch(getCompletedOrdersRider(payload, onSuccess, onError)),
        dispatchGetRiderPings = (
            payload: any,
            onSuccess: Function,
            onError: Function,
        ) => dispatch(getEventsForOrder(payload, onSuccess, onError)),
        dispatchGetRiderEvents = (
            payload: any,
            onSuccess: Function,
            onError: Function,
        ) => dispatch(getEventsRider(payload, onSuccess, onError)),
        dispatchGetRiderMissedOrders = (
            payload: any,
            onSuccess: Function,
            onError: Function,
        ) => dispatch(getMissedOrdersRider(payload, onSuccess, onError)),
        dispatchGetRiderCancelledOrders = (
            payload: any,
            onSuccess: Function,
            onError: Function,
        ) => dispatch(getCancelledOrdersRider(payload, onSuccess, onError)),
        dispatchUpdateFilter = (payload: any) =>
            dispatch(updateFilterData(payload));
    //store values
    const { user, mapview, staff, settings, controlCenter } = useSelector(
        (store: DefaultRootState | any) => store || {},
    );
    const isPinCodeOrg =
        settings?.basicDetails?.vendorName == 'PINCODE' ?? false;
    const pinCodeLocationIds =
        !!isPinCodeOrg && !!settings?.basicDetails?.locationId
            ? [Number(settings?.basicDetails?.locationId)]
            : [];
    const previousSelected: any =
        controlCenter?.filters?.[CONTROL_TYPES.COMMON_FILTER];
    const configs: any = user?.config ?? {};

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const classes: any = useControlsStyles();
    const orgId = settings?.basicDetails?.id,
        isEnabledXUtil = user?.config?.isEnabledXUtil;
    const [orders, setOrders] = useState<any>({});
    const [riders, setRiders] = useState<any>({});
    const [alerts, setAlerts] = useState<any>({});
    const [errors, setErrors] = useState<any>({});
    const [filteredValues, setFilteredValue] = useState<any>(
        !isFirstVisitToday() && !!previousSelected?.filteredValues
            ? previousSelected?.filteredValues
            : {
                  // ...externalFiltered,
                  selectedDate: moment().valueOf(),
                  orgIds: !!orgId ? [orgId] : [],
                  /*hubCodes: staff?.hubs?.[0]?.id
                      ? [
                            {
                                label: staff?.hubs?.[0]?.name,
                                value: staff?.hubs?.[0]?.id,
                                storeId:
                                    staff?.hubs?.[0]?.hyperlocalStoreId ?? null,
                            },
                        ]
                      : [],*/
              },
    );
    const [filteredRidersValues, setFilteredRidersValue] = useState<any>(
        !isFirstVisitToday() && !!previousSelected?.filteredValues
            ? previousSelected?.filteredValues
            : {
                  selectedDate: moment().valueOf(),
                  /*hubCodes: staff?.hubs?.[0]?.id
                      ? [
                            {
                                label: staff?.hubs?.[0]?.name,
                                value: staff?.hubs?.[0]?.id,
                                storeId:
                                    staff?.hubs?.[0]?.hyperlocalStoreId ?? null,
                            },
                        ]
                      : [],*/
              },
    );
    const [filteredAlertsValues, setFilteredAlertsValue] = useState<any>(
        !isFirstVisitToday() && !!previousSelected?.filteredValues
            ? previousSelected?.filteredValues
            : {
                  // ...externalFiltered,
                  selectedDate: moment().valueOf(),
                  /*hubCodes: staff?.hubs?.[0]?.id
                      ? [
                            {
                                label: staff?.hubs?.[0]?.name,
                                value: staff?.hubs?.[0]?.id,
                                storeId:
                                    staff?.hubs?.[0]?.hyperlocalStoreId ?? null,
                            },
                        ]
                      : [],*/
              },
    );
    const { ordersTable, ridersTable, alertsTable } = tableConfig;

    const getColumnComponentValue = (
        row: any,
        column: any,
        handleAction: any,
    ) => {
        let compValue: any = row?.[column?.valueKey] ?? '-/-';
        console.log('the getColumnComponentValue', row, column);
        const classStatus: any = replaceWithUnderscore(row?.status)
            ?.toUpperCase()
            ?.trim();
        const orderStatus: any = replaceWithUnderscore(row?.orderStatus)
            ?.toUpperCase()
            ?.trim();
        const riderStatus: any = replaceWithUnderscore(row?.riderStatus)
            ?.toUpperCase()
            ?.trim();
        let chipClass: any =
            classes?.[
                `${CHIPS_CLASS_TYPE_ORDERS_STATUS_MAPPING?.[classStatus]}`
            ];
        let chipRiderClass: any =
            classes?.[
                `${CHIPS_CLASS_TYPE_RIDERS_STATUS_MAPPING?.[riderStatus]}`
            ];
        let chipOrderClass: any =
            classes?.[`${CHIPS_CLASS_TYPE_MAPPING?.[orderStatus]}`];
        const riderStatusColor: any = !!row?.riderStatusColor
            ? {
                  backgroundColor: row?.riderStatusColor,
              }
            : {};
        const orderStatusColor: any = !!row?.orderStatusColor
            ? {
                  backgroundColor: row?.orderStatusColor,
              }
            : {};
        const onAlert: any = !!row?.onAlert
            ? {
                  color: '#F30900',
              }
            : {};

        switch (column?.mappingKey) {
            case 'orderId':
                compValue = (
                    <Box>
                        <Typography className={classes.tableValue}>
                            {row?.orderId ?? '-'}
                        </Typography>
                        <Typography className={classes.tableSubValue}>
                            {row?.storeName ?? '-'}
                        </Typography>
                    </Box>
                );
                break;
            case 'store':
                compValue = (
                    <Box>
                        <Typography className={classes.tableValue}>
                            {row?.store ?? '-'}
                        </Typography>
                    </Box>
                );
                break;

            case 'alertName':
                compValue = (
                    <Box>
                        <Typography className={classes.tableValue}>
                            {row?.name ?? '-'}
                        </Typography>
                    </Box>
                );
                break;
            case 'alertType':
                compValue = (
                    <Box display={'flex'} flexDirection={'column'}>
                        <Typography className={classes.tableValueBold}>
                            {row?.type == 'Order Alert' ? (
                                <IconButton
                                    className={classes.box}
                                    color="primary"
                                >
                                    <AlertDelivery />
                                </IconButton>
                            ) : (
                                <IconButton
                                    className={classes.rider}
                                    color="primary"
                                >
                                    <AlertRider />
                                </IconButton>
                            )}
                            {row?.type ?? '-'}
                        </Typography>
                        <Typography className={classes.tableSubValue}>
                            {row?.typeText ?? '-'}
                        </Typography>
                    </Box>
                );
                break;
            case 'riderId':
                compValue = (
                    <Box>
                        <Typography
                            style={onAlert}
                            className={classes.tableValue}
                        >
                            {row?.riderId ?? '-'}
                        </Typography>
                        <Typography
                            style={onAlert}
                            className={classes.tableSubValue}
                        >
                            {row?.riderName ?? '-'}
                        </Typography>
                    </Box>
                );
                break;
            case 'id':
                compValue = (
                    <Box>
                        <Typography className={classes.tableValueBold}>
                            {row?.id ?? '-'}
                        </Typography>
                    </Box>
                );
                break;
            case 'ordersCompleted':
                compValue = (
                    <Box>
                        <Typography
                            style={onAlert}
                            className={classes.tableValue}
                        >
                            {row?.ordersCompleted}
                        </Typography>
                        <Typography
                            style={onAlert}
                            className={classes.tableSubValue}
                        >
                            {row?.orderCountText ?? '-'}
                        </Typography>
                    </Box>
                );
                break;
            case 'description':
                compValue =
                    !!row?.description &&
                    !!row?.riderLatitude &&
                    !!row?.riderLongitude &&
                    !!row?.storeLatitude &&
                    !!row?.storeLongitude ? (
                        <Box>
                            <Typography
                                component={'a'}
                                className={classes.tableValue}
                                target={'_blank'}
                                href={`https://www.google.com/maps/dir/?api=1&origin=${row?.storeLatitude},${row?.storeLongitude}&destination=${row?.riderLatitude},${row?.riderLongitude}`}
                                style={{
                                    color: '#0061FE',
                                    textDecoration: 'underline',
                                    backgroundColor: 'none',
                                }}
                            >
                                {row?.description ?? ''}
                            </Typography>
                        </Box>
                    ) : (
                        ''
                    );
                break;
            case 'averageWaitTimeMins':
                compValue = (
                    <Box
                        display={'flex'}
                        flexDirection={'column'}
                        alignItems={'flex-start'}
                        justifyContent={'flex-start'}
                    >
                        <Typography
                            style={onAlert}
                            className={classes.tableValue}
                        >
                            {row?.averageWaitTimeMins ?? '-/-'}
                        </Typography>
                    </Box>
                );
                break;
            case 'backToStoreTime':
                compValue = (
                    <Box
                        display={'flex'}
                        flexDirection={'column'}
                        alignItems={'flex-start'}
                        justifyContent={'flex-start'}
                    >
                        <Typography
                            style={onAlert}
                            className={classes.tableValue}
                        >
                            {row?.backToStoreTime ?? '-/-'}
                        </Typography>
                    </Box>
                );
                break;
            case 'loginHours':
                compValue = (
                    <Box
                        display={'flex'}
                        flexDirection={'column'}
                        alignItems={'center'}
                        justifyContent={'flex-start'}
                    >
                        <Typography
                            style={onAlert}
                            className={classes.tableValue}
                        >
                            {row?.loginHours}
                        </Typography>
                        <Typography
                            style={onAlert}
                            className={classes.tableSubValue}
                        >
                            {row?.loginHoursText}
                        </Typography>
                    </Box>
                );
                break;
            case 'rider':
                compValue = (
                    <Box>
                        <Typography className={classes.tableValue}>
                            {row?.riderName ?? '-'}
                        </Typography>
                        <Typography className={classes.tableSubValue}>
                            {row?.riderNumber ?? '-'}
                        </Typography>
                    </Box>
                );
                break;
            case 'pickupTAT':
                compValue = (
                    <Box>
                        <Typography
                            style={
                                !!row?.pickupTAT?.textColor
                                    ? {
                                          color: row?.pickupTAT?.textColor,
                                      }
                                    : {}
                            }
                            className={classes.tableValue}
                        >
                            {row?.pickupTAT?.text ?? '-'}
                        </Typography>
                        <Typography className={classes.tableSubValue}>
                            {row?.pickupTAT?.timeRange ?? '-'}
                        </Typography>
                    </Box>
                );
                break;
            case 'packagingTAT':
                compValue = (
                    <Box>
                        <Typography
                            style={
                                !!row?.packagingTAT?.textColor
                                    ? {
                                          color: row?.packagingTAT?.textColor,
                                      }
                                    : {}
                            }
                            className={classes.tableValue}
                        >
                            {row?.packagingTAT?.text ?? '-'}
                        </Typography>
                        <Typography className={classes.tableSubValue}>
                            {row?.packagingTAT?.timeRange ?? '-'}
                        </Typography>
                    </Box>
                );
                break;
            case 'deliveryTAT':
                compValue = (
                    <Box>
                        <Typography
                            style={
                                !!row?.deliveryTAT?.textColor
                                    ? {
                                          color: row?.deliveryTAT?.textColor,
                                      }
                                    : {}
                            }
                            className={classes.tableValue}
                        >
                            {row?.deliveryTAT?.text ?? '-'}
                        </Typography>
                        <Typography className={classes.tableSubValue}>
                            {row?.deliveryTAT?.timeRange ?? '-'}
                        </Typography>
                    </Box>
                );
                break;
            case 'totalTAT':
                compValue = (
                    <Box>
                        <Typography
                            component={'span'}
                            style={
                                !!row?.totalTAT?.textColor
                                    ? {
                                          color: row?.totalTAT?.textColor,
                                      }
                                    : {}
                            }
                            className={classes.tableValue}
                        >
                            {row?.totalTAT?.text ?? '-'}
                        </Typography>
                        <Typography className={classes.tableSubValue}>
                            {row?.totalTAT?.timeRange ?? '-'}
                        </Typography>
                    </Box>
                );
                break;
            case 'status':
                compValue = row?.status ? (
                    <Box
                        display={'flex'}
                        flexDirection={'column'}
                        alignItems={'center'}
                        justifyContent={'center'}
                    >
                        <Box
                            className={clsx([classes.chip, chipClass])}
                            minWidth={140}
                        >
                            <Typography
                                component={'span'}
                                className={classes.chipText}
                            >
                                {row?.status ?? '-'}
                            </Typography>
                        </Box>
                        <Typography className={classes.tableSubValue}>
                            {row?.statusText ?? '-'}
                        </Typography>
                    </Box>
                ) : (
                    '-'
                );
                break;
            case 'riderStatus':
                compValue = (
                    <Box
                        display={'flex'}
                        flexDirection={'column'}
                        alignItems={'flex-start'}
                        justifyContent={'flex-start'}
                    >
                        {row?.riderStatusList?.length ? (
                            <Box
                                display={'flex'}
                                flexDirection={'row'}
                                alignItems={'center'}
                            >
                                {row?.riderStatusList?.map(
                                    (item: any, index: number) => {
                                        let sts =
                                            replaceWithUnderscore(
                                                item,
                                            )?.toUpperCase();
                                        sts =
                                            index != 0 &&
                                            replaceWithUnderscore(
                                                row?.riderStatusList?.[0],
                                            )?.toUpperCase() == 'FREE'
                                                ? sts + '_FREE'
                                                : sts;
                                        let chipListRiderClass: any =
                                            classes?.[
                                                `${CHIPS_CLASS_TYPE_RIDERS_STATUS_MAPPING?.[sts]}`
                                            ];
                                        return !!item ? (
                                            <Box
                                                className={clsx([
                                                    classes.chip,
                                                    chipListRiderClass,
                                                ])}
                                                ml={index != 0 ? '4px' : '0px'}
                                                minWidth={index != 0 ? 110 : 78}
                                            >
                                                <Typography
                                                    component={'span'}
                                                    className={classes.chipText}
                                                >
                                                    {replaceWithSpace(
                                                        item,
                                                    )?.toUpperCase() ?? ''}
                                                </Typography>
                                            </Box>
                                        ) : null;
                                    },
                                )}
                            </Box>
                        ) : (
                            <Box
                                className={clsx([classes.chip, chipRiderClass])}
                                minWidth={140}
                            >
                                <Typography
                                    component={'span'}
                                    className={classes.chipText}
                                >
                                    {replaceWithSpace(
                                        row?.riderStatus,
                                    )?.toUpperCase() ?? '-'}
                                </Typography>
                            </Box>
                        )}
                        <Typography
                            style={onAlert}
                            className={classes.tableSubValue}
                        >
                            {row?.riderStatusText ?? '-'}
                        </Typography>
                    </Box>
                );
                break;
            case 'orderStatus':
                compValue = (
                    <Box
                        display={'flex'}
                        flexDirection={'column'}
                        alignItems={'center'}
                        justifyContent={'flex-start'}
                    >
                        <Box
                            className={clsx([classes.chip, chipOrderClass])}
                            minWidth={140}
                        >
                            <Typography
                                component={'span'}
                                className={classes.chipText}
                            >
                                {replaceWithSpace(
                                    row?.orderStatus,
                                )?.toUpperCase()}
                            </Typography>
                        </Box>
                        <Typography
                            style={onAlert}
                            className={classes.tableSubValue}
                        >
                            {row?.orderStatusText ?? '-'}
                        </Typography>
                    </Box>
                );
                break;
            case 'alertStatus':
                compValue = (
                    <Box
                        display={'flex'}
                        flexDirection={'column'}
                        alignItems={'flex-start'}
                        justifyContent={'flex-start'}
                    >
                        <Box
                            className={clsx([classes.chip, chipClass])}
                            minWidth={140}
                        >
                            <Typography
                                component={'span'}
                                className={classes.chipText}
                            >
                                {replaceWithSpace(row?.status)?.toUpperCase() ??
                                    '-'}
                            </Typography>
                        </Box>
                    </Box>
                );
                break;
            case 'assignedAt':
                compValue = (
                    <Box
                        display={'flex'}
                        flexDirection={'column'}
                        alignItems={'flex-start'}
                        justifyContent={'flex-start'}
                    >
                        <Typography className={classes.tableValueBold}>
                            {row?.assignedAt}
                        </Typography>
                    </Box>
                );
                break;
            case 'rtoAt':
                compValue = (
                    <Box
                        display={'flex'}
                        flexDirection={'column'}
                        alignItems={'flex-start'}
                        justifyContent={'flex-start'}
                    >
                        <Typography className={classes.tableValueBold}>
                            {row?.rtoAtText ?? row?.rtoAt ?? '-/-'}
                        </Typography>
                    </Box>
                );
                break;
            case 'createdAt':
                let alertCounts = new Array(row?.alertCount ?? 0).fill(
                    row?.alertCount ?? 0,
                );
                compValue = (
                    <Box
                        display={'flex'}
                        flexDirection={'column'}
                        alignItems={'flex-start'}
                        justifyContent={'flex-start'}
                    >
                        <Typography className={classes.tableValueBold}>
                            {row?.createdAt ?? ''}
                        </Typography>
                        {row?.alertCount > 0 && (
                            <Box
                                mt={'6px'}
                                display={'flex'}
                                flexDirection={'row'}
                                alignItems={'flex-start'}
                                justifyContent={'flex-start'}
                            >
                                {alertCounts?.map(() => {
                                    return (
                                        <WarningIcon
                                            className={classes.alertIcon}
                                            key={Math.random()}
                                        />
                                    );
                                })}
                            </Box>
                        )}
                    </Box>
                );
                break;
            case 'orderAction':
                compValue = (
                    <Box
                        display={'flex'}
                        flexDirection={'row'}
                        alignItems={'center'}
                        justifyContent={'center'}
                    >
                        {!!row?.latitude && row?.longitude && (
                            <IconButton
                                target={'_blank'}
                                className={clsx(classes.location, classes.mr)}
                                href={`https://www.google.com/maps?q=${row?.latitude},${row?.longitude}`}
                                color="primary"
                            >
                                <LocationOnIcon />
                            </IconButton>
                        )}
                        {/* <IconButton
                            className={classes.menu}
                            aria-label="more"
                            aria-controls="long-menu"
                            aria-haspopup="true"
                            onClick={() => {
                                !!handleAction && handleAction('more', row);
                            }}
                            color="primary"
                        >
                            <MoreVertIcon />
                        </IconButton> */}
                    </Box>
                );
                break;
            case 'riderAction':
                compValue = (
                    <Box
                        display={'flex'}
                        flexDirection={'row'}
                        alignItems={'center'}
                        justifyContent={'center'}
                    >
                        {!!row?.riderLatitude && row?.riderLongitude && (
                            <IconButton
                                target={'_blank'}
                                className={clsx(classes.location, classes.mr)}
                                href={`https://www.google.com/maps?q=${row?.riderLatitude},${row?.riderLongitude}`}
                                aria-label="location"
                                color="primary"
                            >
                                <LocationOnIcon />
                            </IconButton>
                        )}
                        {/* <IconButton
                            className={clsx(classes.menu, classes.mr)}
                            onClick={() => {
                                !!handleAction && handleAction('call', row);
                            }}
                            color="primary"
                        >
                            <CallIcon />
                        </IconButton> */}
                        {/* <IconButton
                            className={classes.menu}
                            aria-label="more"
                            aria-controls="long-menu"
                            aria-haspopup="true"
                            onClick={() => {
                                !!handleAction && handleAction('more', row);
                            }}
                            color="primary"
                        >
                            <MoreVertIcon />
                        </IconButton> */}
                    </Box>
                );
                break;
            case 'alertAction':
                compValue = (
                    <Box
                        display={'flex'}
                        flexDirection={'row'}
                        alignItems={'center'}
                        justifyContent={'center'}
                    >
                        {/* <IconButton
                            className={clsx(classes.menu, classes.mr)}
                            onClick={() => {
                                !!handleAction && handleAction('call', row);
                            }}
                            color="primary"
                        >
                            <CallIcon />
                        </IconButton> */}
                        <IconButton
                            className={classes.menu}
                            aria-label="more"
                            aria-controls="long-menu"
                            aria-haspopup="true"
                            onClick={() => {
                                !!handleAction && handleAction('more', row);
                            }}
                            color="primary"
                        >
                            <MoreVertIcon />
                        </IconButton>
                    </Box>
                );
                break;
            default:
                break;
        }
        console.log('the getColumnComponentValue', compValue);
        return compValue;
    };
    const updateFilterValues = (keysToRemove: Array<any>, obj: Object) => {
        const updatedObject = removeKeysFromObject(obj, keysToRemove);
        setFilteredValue(updatedObject);
    };
    const updateFilterRiderValues = (keysToRemove: Array<any>, obj: Object) => {
        const updatedObject = removeKeysFromObject(obj, keysToRemove);
        setFilteredRidersValue(updatedObject);
    };
    const updateFilterAlertValues = (keysToRemove: Array<any>, obj: Object) => {
        const updatedObject = removeKeysFromObject(obj, keysToRemove);
        setFilteredAlertsValue(updatedObject);
    };
    const prepareFilterOptions = () => {
        let list: any = [
            {
                type: FILTER_INPUT_TYPE.DATE,
                label: 'Date',
                name: 'selectedDate',
                value: filteredValues?.selectedDate ?? null,
                deleteDisable: true,
                options: [],
                render: () => {
                    return `Date: ${moment(filteredValues?.selectedDate).format(
                        'MMMM Do YYYY',
                    )}`;
                },
                onChange: async (e: any) => {
                    setFilteredValue({
                        ...filteredValues,
                        selectedDate: e,
                    });
                },
            },
            {
                type: FILTER_INPUT_TYPE.SELECT,
                label: 'Cluster',
                name: 'clusterIds',
                value: filteredValues?.clusterIds?.[0],
                deleteDisable: true,
                hide: !isPinCodeOrg,
                options: staff?.clusters?.map(
                    (item: any) =>
                        ({
                            label: `${item?.name}`,
                            value: item?.id,
                            storeId: item?.hyperlocalStoreId ?? null,
                        } ?? []),
                ),
                onChange: async (e: any) => {
                    if (e?.target?.value) {
                        setFilteredValue({
                            ...filteredValues,
                            clusterIds: [e?.target?.value],
                        });
                    } else {
                        updateFilterValues(['clusterIds'], filteredValues);
                    }
                },
            },
            {
                type: FILTER_INPUT_TYPE.SELECT,
                label: 'Hub',
                name: 'hubCodes',
                value: filteredValues?.hubCodes?.[0],
                deleteDisable: true,
                hide: !!isPinCodeOrg,
                options: staff?.hubs?.map(
                    (item: any) =>
                        ({
                            label: `${item?.name}`,
                            value: item?.id,
                            storeId: item?.hyperlocalStoreId ?? null,
                        } ?? []),
                ),
                onChange: async (e: any) => {
                    if (e?.target?.value) {
                        setFilteredValue({
                            ...filteredValues,
                            hubCodes: [e?.target?.value],
                        });
                    } else {
                        updateFilterValues(['hubCodes'], filteredValues);
                    }
                },
            },
            {
                type: FILTER_INPUT_TYPE.SELECT,
                label: 'Shift',
                name: 'shiftId',
                hide: !!isPinCodeOrg,
                value: filteredValues?.shiftId?.[0],
                options: staff?.shifts?.map(
                    (item: any) =>
                        ({
                            ...item,
                            label: getShiftLabel(item),
                            value: item?.id,
                        } ?? []),
                ),
                onChange: (e: any) => {
                    if (e?.target?.value) {
                        setFilteredValue({
                            ...filteredValues,
                            shiftId: [e?.target?.value],
                        });
                    } else {
                        updateFilterValues(['shiftId'], filteredValues);
                    }
                },
            },
        ];

        return list;
    };
    const prepareFilterOptionsRiders = () => {
        let list: any = [
            {
                type: FILTER_INPUT_TYPE.DATE,
                label: 'Date',
                name: 'selectedDate',
                deleteDisable: true,
                value: filteredRidersValues?.selectedDate ?? null,
                options: [],
                render: () => {
                    return `Date: ${moment(
                        filteredRidersValues?.selectedDate,
                    ).format('MMMM Do YYYY')}`;
                },
                onChange: async (e: any) => {
                    setFilteredRidersValue({
                        ...filteredRidersValues,
                        selectedDate: e,
                    });
                },
            },
            {
                type: FILTER_INPUT_TYPE.SELECT,
                label: 'Hub',
                name: 'hubCodes',
                deleteDisable: true,
                value: filteredRidersValues?.hubCodes?.[0],
                options: staff?.hubs?.map(
                    (item: any) =>
                        ({
                            label: item.name,
                            value: item.id,
                            storeId: item?.hyperlocalStoreId ?? null,
                        } ?? []),
                ),
                onChange: async (e: any) => {
                    if (e?.target?.value) {
                        setFilteredRidersValue({
                            ...filteredRidersValues,
                            hubCodes: [e?.target?.value],
                        });
                    } else {
                        updateFilterRiderValues(
                            ['hubCodes'],
                            filteredRidersValues,
                        );
                    }
                },
            },
            {
                type: FILTER_INPUT_TYPE.SELECT,
                label: 'Shift',
                name: 'shiftId',
                value: filteredRidersValues?.shiftId?.[0],
                options: staff?.shifts?.map(
                    (item: any) =>
                        ({
                            ...item,
                            label: getShiftLabel(item),
                            value: item?.id,
                        } ?? []),
                ),
                onChange: (e: any) => {
                    if (e?.target?.value) {
                        setFilteredRidersValue({
                            ...filteredRidersValues,
                            shiftId: [e?.target?.value],
                        });
                    } else {
                        updateFilterRiderValues(
                            ['shiftId'],
                            filteredRidersValues,
                        );
                    }
                },
            },
        ];

        return list;
    };
    const prepareFilterOptionsAlerts = () => {
        let list: any = [
            {
                type: FILTER_INPUT_TYPE.DATE,
                label: 'Date',
                name: 'selectedDate',
                deleteDisable: true,
                value: filteredAlertsValues?.selectedDate ?? null,
                options: [],
                render: () => {
                    return `Date: ${moment(
                        filteredAlertsValues?.selectedDate,
                    ).format('MMMM Do YYYY')}`;
                },
                onChange: async (e: any) => {
                    setFilteredAlertsValue({
                        ...filteredAlertsValues,
                        selectedDate: e,
                    });
                },
            },
            {
                type: FILTER_INPUT_TYPE.SELECT,
                label: 'Hub',
                name: 'hubCodes',
                deleteDisable: true,
                value: filteredAlertsValues?.hubCodes?.[0],
                options: staff?.hubs?.map(
                    (item: any) =>
                        ({
                            label: item.name,
                            value: item.id,
                            storeId: item?.hyperlocalStoreId ?? null,
                        } ?? []),
                ),
                onChange: async (e: any) => {
                    if (e?.target?.value) {
                        setFilteredAlertsValue({
                            ...filteredAlertsValues,
                            hubCodes: [e?.target?.value],
                        });
                    } else {
                        updateFilterAlertValues(
                            ['hubCodes'],
                            filteredAlertsValues,
                        );
                    }
                },
            },
            {
                type: FILTER_INPUT_TYPE.SELECT,
                label: 'Shift',
                name: 'shiftId',
                deleteDisable: false,
                value: filteredAlertsValues?.shiftId?.[0],
                options: staff?.shifts?.map(
                    (item: any) =>
                        ({
                            ...item,
                            label: getShiftLabel(item),
                            value: item?.id,
                        } ?? []),
                ),
                onChange: (e: any) => {
                    if (e?.target?.value) {
                        setFilteredAlertsValue({
                            ...filteredAlertsValues,
                            shiftId: [e?.target?.value],
                        });
                    } else {
                        updateFilterAlertValues(
                            ['shiftId'],
                            filteredAlertsValues,
                        );
                    }
                },
            },
        ];

        return list;
    };
    useEffect(() => {
        dispatchGetHubList(
            orgId,
            (resp: any) => {
                console.log('dispatchGetHubList success', resp);
                if (onMountFilterUpdate) {
                    onMountFilterUpdate(
                        resp?.response?.hubs ?? [],
                        previousSelected,
                    );
                }
            },
            (err: any) => {
                console.log('dispatchGetHubList error', err);
            },
        );
        dispatchGetClustersList(
            {
                locationIds: pinCodeLocationIds,
                orgIds: [orgId],
            },
            (resp: any) => {
                if (onMountFilterUpdate) {
                    onMountFilterUpdate(
                        resp?.response?.lineItems ?? [],
                        previousSelected,
                    );
                }
            },
            (err: any) => {
                console.log('dispatchGetClustersList error', err);
            },
        );
    }, []);
    const checkValidation = (data: any) => {
        if (!isPinCodeOrg && !data?.hubCodes?.length) {
            return {
                valid: false,
                error: 'Please choose the hub to see the data!',
            };
        } else if (!!isPinCodeOrg && !data?.clusterIds?.length) {
            return {
                valid: false,
                error: 'Please choose the cluster to see the data!',
            };
        } else {
            return {
                valid: true,
                error: null,
            };
        }
    };
    const getAllStaffInfo = async (payload = {}) => {
        await handleLoading(true);
        const fetchStaff = isEnabledXUtil
            ? dispatchGetV2AllStaffs
            : dispatchGetAllStaffs;
        fetchStaff(
            payload,
            (resp: any) => {
                handleLoading(false);
                setOrders(resp);
                console.log('staff data', resp);
            },
            (resp: any) => {
                handleLoading(false);
                setOrders({});
                console.log('staff data err', resp);
            },
        );
    };
    const getAllOrderInfo = async (payload = {}) => {
        //await handleLoading(true);
        //setOrders(OrdersData);
        let validation = checkValidation(payload);
        if (!!validation?.valid) {
            setErrors((prev: any) => {
                return {
                    ...prev,
                    [CONTROL_TYPES.ORDERS]: null,
                };
            });
            dispatchGetAllOrders(
                payload,
                (resp: any) => {
                    handleLoading(false);
                    setOrders(resp);
                    setLastUpdated((prev: any) => {
                        return {
                            ...prev,
                            [CONTROL_TYPES.ORDERS]: moment().valueOf(),
                        };
                    });
                    console.log('getAllOrderInfo data', resp);
                },
                (resp: any) => {
                    handleLoading(false);
                    console.log('staff data err', resp);
                },
            );
        } else {
            setErrors((prev: any) => {
                return {
                    ...prev,
                    [CONTROL_TYPES.ORDERS]: validation,
                };
            });
        }
    };
    const getAllRidersInfo = async (payload = {}) => {
        //await handleLoading(true);
        //setOrders(OrdersData);
        let validation = checkValidation(payload);
        if (!!validation?.valid) {
            setErrors((prev: any) => {
                return {
                    ...prev,
                    [CONTROL_TYPES.RIDERS]: null,
                };
            });
            dispatchGetAllRiders(
                payload,
                (resp: any) => {
                    handleLoading(false);
                    setRiders(resp);
                    setLastUpdated((prev: any) => {
                        return {
                            ...prev,
                            [CONTROL_TYPES.RIDERS]: moment().valueOf(),
                        };
                    });
                    console.log('getAllOrderInfo data', resp);
                },
                (resp: any) => {
                    handleLoading(false);
                    console.log('staff data err', resp);
                },
            );
        } else {
            setErrors((prev: any) => {
                return {
                    ...prev,
                    [CONTROL_TYPES.RIDERS]: validation,
                };
            });
        }
    };
    const getAllAlertsInfo = async (payload = {}) => {
        //await handleLoading(true);
        //setOrders(OrdersData);
        let validation = checkValidation(payload);
        if (!!validation?.valid) {
            setErrors((prev: any) => {
                return {
                    ...prev,
                    [CONTROL_TYPES.ALERTS]: null,
                };
            });
            dispatchGetAllAlerts(
                payload,
                (resp: any) => {
                    handleLoading(false);
                    setAlerts(resp);
                    setLastUpdated((prev: any) => {
                        return {
                            ...prev,
                            [CONTROL_TYPES.ALERTS]: moment().valueOf(),
                        };
                    });
                    console.log('getAllOrderInfo data', resp);
                },
                (resp: any) => {
                    handleLoading(false);
                    console.log('staff data err', resp);
                },
            );
        } else {
            setErrors((prev: any) => {
                return {
                    ...prev,
                    [CONTROL_TYPES.ALERTS]: validation,
                };
            });
        }
    };
    const updateAlertsStatus = async (payload = {}, onSuccess?: Function) => {
        //await handleLoading(true);
        //setOrders(OrdersData);
        dispatchUpdateAlertStatus(
            payload,
            (resp: any) => {
                handleLoading(false);
                showMessage('Updated Successfully', 'success');
                if (!!onSuccess) {
                    onSuccess();
                }
            },
            (err: any) => {
                handleLoading(false);
                showMessage(
                    err?.status?.message ?? 'Failed to Update',
                    err?.status?.code == 200 ? 'success' : 'error',
                );
            },
        );
    };
    const getAlertHistory = async (payload = {}, onSuccess?: Function) => {
        dispatchGetAlertHistory(
            payload,
            (resp: any) => {
                handleLoading(false);
                if (!!onSuccess) {
                    onSuccess(resp);
                }
            },
            (err: any) => {
                handleLoading(false);
            },
        );
    };
    const getRiderPings = async (payload = {}, onSuccess?: Function) => {
        dispatchGetPingsRider(
            payload,
            (resp: any) => {
                handleLoading(false);
                if (!!onSuccess) {
                    onSuccess(resp);
                }
            },
            (err: any) => {
                handleLoading(false);
            },
        );
    };
    const getRiderPingsFromMeta = async (
        payload = {},
        onSuccess?: Function,
    ) => {
        dispatchGetPingsRiderFromMeta(
            payload,
            (resp: any) => {
                handleLoading(false);
                if (!!onSuccess) {
                    onSuccess(resp);
                }
            },
            (err: any) => {
                handleLoading(false);
            },
        );
    };
    const getRiderCompletedOrders = async (
        payload = {},
        onSuccess?: Function,
    ) => {
        dispatchGetRiderCompletedOrders(
            payload,
            (resp: any) => {
                handleLoading(false);
                if (!!onSuccess) {
                    onSuccess(resp);
                }
            },
            (err: any) => {
                handleLoading(false);
            },
        );
    };
    const getOrderEvents = async (payload = {}, onSuccess?: Function) => {
        dispatchGetRiderPings(
            payload,
            (resp: any) => {
                handleLoading(false);
                if (!!onSuccess) {
                    onSuccess(resp);
                }
            },
            (err: any) => {
                handleLoading(false);
            },
        );
    };
    const getRiderEvents = async (payload = {}, onSuccess?: Function) => {
        dispatchGetRiderEvents(
            payload,
            (resp: any) => {
                handleLoading(false);
                if (!!onSuccess) {
                    onSuccess(resp);
                }
            },
            (err: any) => {
                handleLoading(false);
            },
        );
    };
    const getRiderMissedOrders = async (payload = {}, onSuccess?: Function) => {
        dispatchGetRiderMissedOrders(
            payload,
            (resp: any) => {
                handleLoading(false);
                if (!!onSuccess) {
                    onSuccess(resp);
                }
            },
            (err: any) => {
                handleLoading(false);
            },
        );
    };
    const getRiderCancelledOrders = async (
        payload = {},
        onSuccess?: Function,
    ) => {
        dispatchGetRiderCancelledOrders(
            payload,
            (resp: any) => {
                handleLoading(false);
                if (!!onSuccess) {
                    onSuccess(resp);
                }
            },
            (err: any) => {
                handleLoading(false);
            },
        );
    };
    React.useEffect(() => {
        if (CONTROL_TYPES.ORDERS == controlType) {
            let hubCodes = filteredValues?.hubCodes?.map(
                (hub: any, index: number) => hub.value,
            );
            if (hubCodes?.length > 0) {
                dispatchGetShiftTimingsList(
                    {
                        hubCodes: hubCodes?.length > 0 ? hubCodes : null,
                    },
                    (resp: any) => {
                        console.log('resp', resp);
                    },
                    (err: any) => {
                        console.log('err', err);
                    },
                );
            }
        }
    }, [filteredValues?.hubCodes]);
    React.useEffect(() => {
        if (CONTROL_TYPES.RIDERS == controlType) {
            let hubCodes = filteredRidersValues?.hubCodes?.map(
                (hub: any, index: number) => hub.value,
            );
            if (hubCodes?.length > 0) {
                dispatchGetShiftTimingsList(
                    {
                        hubCodes: hubCodes?.length > 0 ? hubCodes : null,
                    },
                    (resp: any) => {
                        console.log('resp', resp);
                    },
                    (err: any) => {
                        console.log('err', err);
                    },
                );
            }
        }
    }, [filteredRidersValues?.hubCodes]);
    React.useEffect(() => {
        if (CONTROL_TYPES.ALERTS == controlType) {
            let hubCodes = filteredAlertsValues?.hubCodes?.map(
                (hub: any, index: number) => hub.value,
            );
            if (hubCodes?.length > 0) {
                dispatchGetShiftTimingsList(
                    {
                        hubCodes: hubCodes?.length > 0 ? hubCodes : [],
                    },
                    (resp: any) => {
                        console.log('resp', resp);
                    },
                    (err: any) => {
                        console.log('err', err);
                    },
                );
            }
        }
    }, [filteredAlertsValues?.hubCodes]);
    return {
        ordersTable,
        getColumnComponentValue,
        filteredValues,
        filteredRidersValues,
        filteredAlertsValues,
        setFilteredRidersValue,
        setFilteredAlertsValue,
        prepareFilterOptions,
        prepareFilterOptionsRiders,
        prepareFilterOptionsAlerts,
        setFilteredValue,
        getAllStaffInfo,
        staff,
        ridersTable,
        alertsTable,
        dispatchGetAllOrders,
        dispatchGetAllAlerts,
        dispatchGetAllRiders,
        orders,
        getAllOrderInfo,
        getAllRidersInfo,
        getAllAlertsInfo,
        riders,
        alerts,
        updateAlertsStatus,
        configs,
        dispatchUpdateFilter,
        controlCenter,
        setErrors,
        errors,
        getAlertHistory,
        getRiderMissedOrders,
        getRiderCompletedOrders,
        getRiderEvents,
        getRiderPings,
        getRiderCancelledOrders,
        getRiderPingsFromMeta,
        getOrderEvents,
        orgId,
    };
};
export default useControlTable;
