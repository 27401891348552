import {
    AppBar,
    Avatar,
    Box,
    Button,
    Icon,
    Tab,
    Tabs,
    Toolbar,
    Typography,
} from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import './styles.scss';
import { withRouter } from 'react-router-dom';
import routes from '../../routes';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { connect } from 'react-redux';
import SwitchHubModal from '../Modals/SwitchHub';
import { canAllow, getPermissions } from '../../utils/helper';

const useStyles = makeStyles((theme) => ({
    main: {
        color: theme.palette.background.default,
        backgroundColor: theme.palette.primary.main,
        fontSize: '32px',
    },
    settings: {
        backgroundColor: 'white',
        color: theme.palette.primary.main,
        textTransform: 'none',
        border: '1px solid',
        borderColor: 'rgba(67,65,158,.24)',
        '&:hover': {
            backgroundColor: 'rgba(67,65,158,.1) !important',
            color: theme.palette.primary.main,
        },
    },
    activeSettings: {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        textTransform: 'none',
    },
    nav: {
        flexGrow: 1,
        alignSelf: 'stretch',
        marginTop: '10px',
        textTransform: 'none',
    },
    navLink: {
        color: theme.palette.text.secondary,
        textAlign: 'center',
        cursor: 'pointer',
        alignItems: 'center',
        justifyItems: 'center',
    },
    activeNav: {
        color: theme.palette.primary.main,
        borderBottom: `3px ${theme.palette.primary.main} solid`,
    },
}));

function a11yProps(index) {
    return {
        id: `scrollable-force-tab-${index}`,
        'aria-controls': `scrollable-force-tabpanel-${index}`,
    };
}

function Navbar({ orgInfo, user, ...props }) {
    const classes = useStyles();
    const [value, setValue] = React.useState(
        routes.find((r) => props.location.pathname.includes(r.path)).path,
    );
    const [isSwitchHubModalOpen, setisSwitchHubModalOpen] =
        React.useState(false);
    const handleChange = (event, path) => {
        setValue(path);
        props.history.push(path);
    };

    console.log(
        'route',
        routes.filter((nav) => nav.keepInNav).sort((a, b) => a.order - b.order),
    );
    const ORG_NAME = localStorage.getItem('org') ?? orgInfo?.name ?? 'Guest';
    let OrgName = ORG_NAME;

    OrgName = OrgName?.length > 15 ? OrgName?.slice(0, 15) + '...' : OrgName;
    return (
        <div className="appbar" style={{ marginBottom: 80 }}>
            <AppBar
                color="default"
                position="fixed"
                style={{
                    padding: '0px 48px',
                    boxShadow: '0 1px 1px 0 rgba(0, 0, 0, 0.07)',
                    backgroundColor: '#fff',
                }}
            >
                <Toolbar disableGutters={true}>
                    {/* <Avatar className={classes.main}>.</Avatar> */}
                    <Box style={{ width: '10%' }}>
                        <Button
                            variant="contained"
                            color="default"
                            className={`${classes.settings} ${
                                props.location.pathname.includes('/settings') &&
                                classes.activeSettings
                            }`}
                            startIcon={<Icon>settings</Icon>}
                            onClick={(e) => props.history.push('/settings')}
                            disabled={!getPermissions('settings')?.read}
                            disableElevation
                        >
                            Settings
                        </Button>
                    </Box>

                    <Tabs
                        value={value}
                        onChange={handleChange}
                        variant="scrollable"
                        scrollButtons="on"
                        indicatorColor="primary"
                        textColor="primary"
                        aria-label="scrollable force tabs example"
                        className={classes.nav}
                    >
                        {routes
                            .filter((nav) => nav.keepInNav)
                            .sort((a, b) => a.order - b.order)
                            .map((nav, index) => {
                                return (
                                    getPermissions(nav.name)?.read && (
                                        <Tab
                                            key={index}
                                            label={
                                                !!nav?.routeName
                                                    ? nav?.routeName
                                                    : nav?.name
                                            }
                                            value={nav.path}
                                            disabled={
                                                !getPermissions(nav.name)?.read
                                            }
                                            {...a11yProps(index)}
                                        />
                                    )
                                );
                            })}
                    </Tabs>
                    {/* <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="stretch"
                        spacing={5}
                        className={classes.nav}
                    >
                        {
                            routes.filter(nav => nav.keepInNav).sort((a,b) => a.order - b.order).map(nav => {
                                return <Grid
                                    key={nav.name}
                                    onClick={e => props.history.push(nav.path)}
                                    className={`${classes.navLink} ${props.location.pathname === nav.path && classes.activeNav}`}
                                    item
                                    lg={1}
                                    xs={12}
                                >
                                    {nav.name}
                                </Grid>
                            })
                        }
                    </Grid> */}
                    <Box
                        style={{
                            width: '15%',
                            display: 'flex',
                            justifyContent: 'flex-end',
                        }}
                    >
                        <Typography
                            style={{
                                color: '#43419e',
                                fontSize: '18px',
                                fontWeight: 700,
                                display: 'flex',
                                alignItems: 'center',
                                cursor: 'pointer',
                            }}
                            title={ORG_NAME}
                            onClick={(e) => {
                                if (user?.config?.isMultiOrgUser == 'true') {
                                    setisSwitchHubModalOpen(true);
                                }
                            }}
                        >
                            {OrgName}
                            {user?.config?.isMultiOrgUser == 'true' && (
                                <ExpandMoreIcon />
                            )}
                        </Typography>
                    </Box>
                </Toolbar>
            </AppBar>
            {isSwitchHubModalOpen && (
                <SwitchHubModal
                    open={isSwitchHubModalOpen}
                    onClose={() => {
                        setisSwitchHubModalOpen(false);
                    }}
                    {...props}
                />
            )}
        </div>
    );
}

const mapStateToProps = (state) => ({
    orgInfo: state.settings.basicDetails,
    user: state.user,
});

export default withRouter(connect(mapStateToProps, null)(Navbar));
