import {
    Box,
    Button,
    IconButton,
    List,
    ListItem,
    ListItemSecondaryAction,
    makeStyles,
    Paper,
    TablePagination,
    Typography,
    withStyles,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Searchbar from '../../components/Searchbar';
import Modal from '../../components/Modals/Modal';
import { Spinner } from '../../components/UI';
import { EmptyIcon } from '../../assets/svg';
import { connect } from 'react-redux';
import {
    deleteShiftTiming,
    fetchShiftfilterList,
} from '../../store/actions/settingsActionCreator';
import AddShiftTimingModal from '../../components/Modals/AddShiftTimingModal';
import moment from 'moment';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ShiftDetailModal from '../../components/Modals/ShiftDetailModal';
import FilterModal, {
    FilteredState,
    FILTER_INPUT_TYPE,
} from '../../components/Modals/FilterModal';

const useStyles = makeStyles((theme) => ({
    listItemHeader: {
        border: '0px',
        // marginBottom: '8px',
        '& .MuiTypography-body1': {
            color: '#808080',
            fontSize: 16,
            fontWeight: 'bold',
        },
    },
    listItem: {
        padding: '16px 16px 16px 16px ',
        marginBottom: '8px ',
        border: '1px solid #ebedf8 ',
        borderRadius: '8px',
        '& .MuiTypography-body1': {
            fontSize: 16,
            fontWeight: 400,
        },
        '&:nth-child(odd)': {
            backgroundColor: '#f8f9fa',
        },
        '&:hover': {
            backgroundColor: 'rgba(67,65,158,.1) !important',
            cursor: 'pointer',
        },
    },
    saveButton: {
        backgroundColor: theme.palette.error.main,
        color: '#fff',
    },
}));
const ShiftManagerComponent = ({
    loading: loader,
    hubInfo,
    renderHubFlow,
    externalFiltered,
    shiftList,
    permissions,
    dispatchfetchShiftTimingList,
    dispatchDeleteShiftTiming,
    ...props
}) => {
    const classes = useStyles();
    const [isOpen, toggleModal] = useState(false);
    const [openDetailModal, setOpenDetailModal] = useState(false);
    const [loading, toggleLoading] = useState(loader ?? false);
    const [selectedRow, setSelectedRow] = useState();
    const [isDeleteModalOpen, toggleDeleteModal] = useState(false);
    const [deleteLoading, toggleDeleteLoading] = useState(false);
    const [searchText, setSearchText] = useState('');

    const [page, setPage] = React.useState(props.location?.state?.page ?? 0);
    const [rowsPerPage, setRowsPerPage] = React.useState(
        props?.location?.state?.rowsPerPage ?? 10,
    );
    const [searchVal, setSearchVal] = useState(
        props.location?.state?.searchVal ?? '',
    );
    const [shiftListPayload, setshiftListPayload] = React.useState({
        pageNo: page + 1,
        pageSize: rowsPerPage,
        searchText: searchVal,
        ...externalFiltered,
    });
    const [filteredValues, setFilteredValue] = useState({});

    useEffect(() => {
        fetchShiftList(shiftListPayload);
    }, [shiftListPayload]);

    const handleClose = () => {
        // Setting form data to null before closing

        toggleModal(false);
    };
    const openEditModal = async (row) => {
        await setSelectedRow(row);
        toggleModal(true);
    };
    const openDeleteModal = async (row) => {
        await setSelectedRow(row);
        toggleDeleteModal(true);
    };
    const handleDeleteClose = () => {
        // Setting form data to null before closing
        toggleDeleteModal(false);
        fetchShiftList();
    };

    const fetchShiftList = async (filters = {}) => {
        await toggleLoading(true);
        const payload = {
            ...shiftListPayload,
            ...filters,
        };
        dispatchfetchShiftTimingList(
            payload,
            (resp) => {
                console.log(resp);
                toggleLoading(false);
            },
            (resp) => {
                console.log(resp);
                toggleLoading(false);
            },
        );
    };

    const handleChangePage = (event, newPage) => {
        console.log('SETPAGE ', newPage);
        setPage(newPage);
        setshiftListPayload((prev) => {
            return {
                ...prev,
                pageNo: newPage + 1,
            };
        });
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
        setshiftListPayload((prev) => {
            return {
                ...prev,
                pageNo: 1,
                pageSize: event.target.value,
            };
        });
    };

    const prepareFilterOptions = () => {
        return [
            {
                type: FILTER_INPUT_TYPE.TIMERANGE,
                label: 'Filter by Time Period',
                name: 'timePeriod',
                value: filteredValues?.times ?? undefined,
                onChange: (e) => {
                    console.log('eeeeeeeee', e);
                    setFilteredValue({
                        ...filteredValues,
                        times: e,
                        startTime: moment(e.startDate).format('YYYY-MM-DD'),
                        endTime: moment(e.endDate).format('YYYY-MM-DD'),
                    });
                },
            },
        ];
    };

    const deleteShiftList = async () => {
        await toggleDeleteLoading(true);
        dispatchDeleteShiftTiming(
            selectedRow.id,
            (resp) => {
                console.log(resp);
                toggleDeleteLoading(false);
                handleDeleteClose();
            },
            (err) => {
                console.log(err);
                toggleDeleteLoading(false);
                handleDeleteClose();
            },
        );
    };

    const actions = [
        <Button
            key={1}
            variant="contained"
            color="default"
            className={`cancelButton`}
            onClick={handleDeleteClose}
            disableElevation
        >
            {'No'}
        </Button>,
        <Button
            key={2}
            variant="contained"
            color="default"
            className={`${classes.saveButton}`}
            onClick={(e) => deleteShiftList()}
            disableElevation
        >
            {'Yes'}
        </Button>,
    ];

    return (
        <Paper style={{ padding: '1.5rem', paddingTop: 0 }} elevation={0}>
            <Spinner loading={loading}>
                <Box
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'flex-start',
                        marginBottom: '4px',
                    }}
                >
                    <Box
                        className="horiz-end"
                        style={{
                            width: '50%',
                        }}
                    >
                        <Searchbar
                            placeHolderText="Search by Shift Name"
                            style={{
                                color: '#14112d',
                            }}
                            onChange={setSearchText}
                            onFilter={() => {
                                console.log('search bar filtering');
                            }}
                        />
                        {/* <FilterModal
                            filterOptions={prepareFilterOptions()}
                            filteredValues={filteredValues}
                            onFilterApplied={() => {
                                setshiftListPayload((prev) => {
                                    return {
                                        ...prev,
                                        cityIds:
                                            filteredValues?.cities?.map(
                                                (city) => city?.value,
                                            ) ?? null,
                                    };
                                });
                            }}
                            disabled={true}
                        /> */}
                    </Box>
                    {!renderHubFlow && (
                        <Button
                            style={{
                                marginRight: '1rem',
                                fontSize: 14,
                            }}
                            variant="contained"
                            color="primary"
                            onClick={(e) => toggleModal(true)}
                            disabled={!permissions.create}
                            disableElevation
                        >
                            {'Add Shift'}
                        </Button>
                    )}
                </Box>
                <FilteredState
                    filterOptions={prepareFilterOptions()}
                    filteredValues={filteredValues}
                    onDelete={(filterKey) => {
                        let newFilteredValues = { ...filteredValues };
                        delete newFilteredValues?.[filterKey];
                        setFilteredValue(newFilteredValues);
                        setshiftListPayload((prev) => {
                            return {
                                ...prev,
                                cityIds:
                                    newFilteredValues?.cities?.map(
                                        (city) => city?.value,
                                    ) ?? null,
                            };
                        });
                    }}
                />
                {!!shiftList?.filter((row) =>
                    searchText
                        ? row?.name
                              ?.toLowerCase()
                              ?.includes(searchText?.toLowerCase()) ||
                          row?.city
                              ?.toLowerCase()
                              ?.includes(searchText?.toLowerCase())
                        : true,
                )?.length ? (
                    <List component="nav">
                        <ListItem className={classes.listItemHeader}>
                            <Box
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    alignItems: 'flex-start',
                                    width: '100%',
                                    color: '#808080',
                                }}
                            >
                                <Box
                                    style={{
                                        width: '14%',
                                        justifySelf: 'flex-start',
                                    }}
                                >
                                    <Typography
                                        style={{
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        Shift Name
                                    </Typography>
                                </Box>
                                <Box
                                    style={{
                                        width: '14%',
                                        justifySelf: 'flex-start',
                                    }}
                                >
                                    <Typography
                                        style={{
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        Short Name
                                    </Typography>
                                </Box>
                                {/* <Box
                                    style={{
                                        width: '14%',
                                        justifySelf: 'flex-start',
                                    }}
                                >
                                    <Typography
                                        style={{
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        HUB NAME
                                    </Typography>
                                </Box> */}
                                <Box
                                    style={{
                                        width: '14%',
                                        justifySelf: 'flex-start',
                                    }}
                                >
                                    <Typography
                                        style={{
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        Start Time
                                    </Typography>
                                </Box>
                                <Box
                                    style={{
                                        width: '14%',
                                        justifySelf: 'flex-start',
                                    }}
                                >
                                    <Typography
                                        style={{
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        End Time
                                    </Typography>
                                </Box>
                                <Box
                                    style={{
                                        width: '14%',
                                        justifySelf: 'flex-start',
                                    }}
                                >
                                    <Typography
                                        style={{
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        Staff Count
                                    </Typography>
                                </Box>
                                <Box
                                    style={{
                                        width: '15%',
                                        justifySelf: 'flex-start',
                                    }}
                                >
                                    {' '}
                                </Box>
                            </Box>
                        </ListItem>

                        {shiftList
                            ?.filter((row) =>
                                searchText
                                    ? row?.name
                                          ?.toLowerCase()
                                          ?.includes(
                                              searchText?.toLowerCase(),
                                          ) ||
                                      row?.shortName
                                          ?.toLowerCase()
                                          ?.includes(searchText?.toLowerCase())
                                    : true,
                            )
                            ?.map((row, index) => (
                                <ListItem
                                    // button
                                    key={index}
                                    className={classes.listItem}
                                    onClick={async () => {
                                        await setSelectedRow(row);
                                        await setOpenDetailModal(true);
                                    }}
                                >
                                    <Box
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'space-between',
                                            alignItems: 'flex-start',
                                            width: '100%',
                                        }}
                                    >
                                        <Box
                                            style={{
                                                width: '14%',
                                                justifySelf: 'flex-start',
                                            }}
                                        >
                                            <Typography
                                                title={row.name}
                                                style={{
                                                    fontSize: '16px',
                                                    fontWeight: 600,
                                                }}
                                            >
                                                {(() => {
                                                    let name = row.name;
                                                    name =
                                                        name?.length > 22
                                                            ? name?.slice(
                                                                  0,
                                                                  19,
                                                              ) + '...'
                                                            : name;
                                                    return name;
                                                })()}
                                            </Typography>
                                        </Box>
                                        <Box
                                            style={{
                                                width: '14%',
                                                justifySelf: 'flex-start',
                                            }}
                                        >
                                            <Typography
                                                style={{
                                                    fontSize: '16px',
                                                    fontWeight: 600,
                                                }}
                                            >
                                                {row?.shortName}
                                            </Typography>
                                        </Box>
                                        {/* <Box
                                            style={{
                                                width: '14%',
                                                justifySelf: 'flex-start',
                                            }}
                                        >
                                            <Typography style={{fontSize: '16px',
                                                fontWeight: 600,}}>
                                                {row?.hubName}
                                            </Typography>
                                        </Box> */}
                                        <Box
                                            style={{
                                                width: '14%',
                                                justifySelf: 'flex-start',
                                            }}
                                        >
                                            <Typography
                                                style={{
                                                    fontSize: '16px',
                                                    fontWeight: 600,
                                                }}
                                            >
                                                {moment(
                                                    row?.startTimeEpochMillis,
                                                ).format('HH:mm') ?? '-'}
                                            </Typography>
                                        </Box>
                                        <Box
                                            style={{
                                                width: '14%',
                                                justifySelf: 'flex-start',
                                            }}
                                        >
                                            <Typography
                                                style={{
                                                    fontSize: '16px',
                                                    fontWeight: 600,
                                                }}
                                            >
                                                {moment(
                                                    row?.endTimeEpochMillis,
                                                ).format('HH:mm') ?? '-'}
                                            </Typography>
                                        </Box>
                                        <Box
                                            style={{
                                                width: '14%',
                                                justifySelf: 'flex-start',
                                            }}
                                        >
                                            <Typography
                                                style={{
                                                    fontSize: '16px',
                                                    fontWeight: 600,
                                                }}
                                            >
                                                {row?.staffCount ?? 0}
                                            </Typography>
                                        </Box>
                                        <Box
                                            style={{
                                                width: '15%',
                                                justifySelf: 'flex-start',
                                            }}
                                        >
                                            <ListItemSecondaryAction>
                                                <Box
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent:
                                                            'flex-end',
                                                    }}
                                                >
                                                    {(() => {
                                                        const Icon = withStyles(
                                                            () => ({
                                                                root: {
                                                                    border: '1px solid transparent',
                                                                    '&:hover': {
                                                                        backgroundColor:
                                                                            '#fff !important',
                                                                        border: '1px solid #c7c6e2 !important',
                                                                    },
                                                                },
                                                                rootDelete: {
                                                                    color: 'red',
                                                                    border: '1px solid transparent',
                                                                    '&:hover': {
                                                                        border: '1px solid #c7c6e2',
                                                                        backgroundColor:
                                                                            'red',
                                                                        color: '#fff',
                                                                    },
                                                                },
                                                            }),
                                                        )(
                                                            ({
                                                                classes,
                                                                isDelete,
                                                                useButton = false,
                                                                ...props
                                                            }) =>
                                                                !!useButton ? (
                                                                    <Button
                                                                        classes={{
                                                                            root: !!isDelete
                                                                                ? classes.rootDelete
                                                                                : classes.root,
                                                                        }}
                                                                        {...props}
                                                                    >
                                                                        {
                                                                            props.children
                                                                        }
                                                                    </Button>
                                                                ) : (
                                                                    <IconButton
                                                                        classes={{
                                                                            root: !!isDelete
                                                                                ? classes.rootDelete
                                                                                : classes.root,
                                                                        }}
                                                                        {...props}
                                                                    >
                                                                        {
                                                                            props.children
                                                                        }
                                                                    </IconButton>
                                                                ),
                                                        );
                                                        return (
                                                            <>
                                                                <Icon
                                                                    variant="outlined"
                                                                    color="primary"
                                                                    useButton={
                                                                        true
                                                                    }
                                                                    style={{
                                                                        fontSize: 14,
                                                                        fontWeight: 600,
                                                                        marginRight: 8,
                                                                        borderColor:
                                                                            '#EFEFEF',
                                                                        backgroundColor:
                                                                            '#EFEFEF',
                                                                    }}
                                                                    onClick={async () => {
                                                                        await setSelectedRow(
                                                                            row,
                                                                        );
                                                                        await setOpenDetailModal(
                                                                            true,
                                                                        );
                                                                    }}
                                                                >
                                                                    View Staffs
                                                                </Icon>
                                                                <Icon
                                                                    style={{
                                                                        marginRight: 8,
                                                                        borderRadius: 8,
                                                                        padding: 6,
                                                                    }}
                                                                    color="primary"
                                                                    onClick={(
                                                                        event,
                                                                    ) => {
                                                                        event.stopPropagation();
                                                                        openEditModal(
                                                                            row,
                                                                        );
                                                                    }}
                                                                >
                                                                    <EditIcon size="small" />
                                                                </Icon>
                                                                <Icon
                                                                    style={{
                                                                        marginRight: 8,
                                                                        borderRadius: 8,
                                                                        padding: 6,
                                                                    }}
                                                                    color="error"
                                                                    isDelete={
                                                                        true
                                                                    }
                                                                    onClick={(
                                                                        event,
                                                                    ) => {
                                                                        event.stopPropagation();
                                                                        openDeleteModal(
                                                                            row,
                                                                        );
                                                                    }}
                                                                >
                                                                    <DeleteIcon size="small" />
                                                                </Icon>
                                                            </>
                                                        );
                                                    })()}
                                                    {/* <CustomDropdown
                                                        title={'Options'}
                                                        options={[
                                                            {
                                                                item: 'Edit',
                                                                onSuccess:
                                                                    () => {
                                                                        openEditModal(
                                                                            row,
                                                                        );
                                                                    },
                                                                disabled:
                                                                    !permissions?.update,
                                                            },
                                                            {
                                                                item: 'Delete',
                                                                onSuccess:
                                                                    () => {
                                                                        openDeleteModal(
                                                                            row,
                                                                        );
                                                                    },
                                                                disabled:
                                                                    !permissions?.delete,
                                                            },
                                                        ]}
                                                    /> */}
                                                </Box>
                                            </ListItemSecondaryAction>
                                        </Box>
                                    </Box>
                                </ListItem>
                            ))}
                    </List>
                ) : (
                    <Box
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <EmptyIcon />
                    </Box>
                )}

                {/* <TablePagination
                    rowsPerPageOptions={[10, 50, 100]}
                    component="div"
                    count={100}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                /> */}

                {!!isOpen && (
                    <AddShiftTimingModal
                        open={isOpen}
                        data={{ ...selectedRow, hubId: hubInfo?.id }}
                        isEdit={!!selectedRow}
                        onClose={() => {
                            handleClose();
                            setSelectedRow(undefined);
                            fetchShiftList();
                        }}
                    />
                )}
                {!!openDetailModal && (
                    <ShiftDetailModal
                        open={openDetailModal}
                        hubInfo={hubInfo}
                        data={{ ...selectedRow }}
                        isEdit={!!selectedRow}
                        openEditModal={() => {
                            toggleModal(true);
                        }}
                        openDeleteModal={() => {
                            toggleDeleteModal(true);
                        }}
                        onClose={() => {
                            setOpenDetailModal(false);
                            // setSelectedRow(undefined);
                            fetchShiftList();
                        }}
                    />
                )}
                {!!isDeleteModalOpen && (
                    <Modal
                        onClose={handleDeleteClose}
                        open={isDeleteModalOpen}
                        title={'Delete'}
                        actions={actions}
                        loading={deleteLoading}
                    >
                        <Box>
                            <Typography
                                style={{
                                    textAlign: 'center',
                                }}
                            >
                                Do you want to delete
                                <strong>{` ${selectedRow?.name} `}</strong>?
                            </Typography>
                            <Typography
                                style={{
                                    textAlign: 'center',
                                    marginTop: '8px',
                                    marginBottom: '24px',
                                }}
                            >
                                You can’t undo this action.
                            </Typography>
                        </Box>
                    </Modal>
                )}
            </Spinner>
        </Paper>
    );
};

const mapStateToProps = (state) => ({
    shiftList: state.settings?.shifts ?? [],
});

const mapDispatchToProps = (dispatch) => ({
    dispatchfetchShiftTimingList: (payload, onSuccess, onError) =>
        dispatch(fetchShiftfilterList(payload, onSuccess, onError)),
    dispatchDeleteShiftTiming: (id, onSuccess, onError) =>
        dispatch(deleteShiftTiming(id, onSuccess, onError)),
});

export const HubShiftsDetails = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ShiftManagerComponent);
