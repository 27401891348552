/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
    Box,
    Paper,
    makeStyles,
    IconButton,
    Typography,
    TablePagination,
    Tooltip,
} from '@material-ui/core';
import ChevronLeftRoundedIcon from '@material-ui/icons/ChevronLeftRounded';
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import DateRangeIcon from '@material-ui/icons/DateRange';
import { CustomDatePicker } from '../../components/DateTimePicker';

import AttendanceListContainer from '../../components/Containers/AttendanceListContainer';
import Searchbar from '../../components/Searchbar';

import { getAllAttendanceData } from '../../store/actions/attendanceActionCreator';
import {
    getAllStaffs,
    getShiftTimingsList,
} from '../../store/actions/staffActionCreator';
import {
    getSingleStaffAttendanceData,
    getReasonMenuList,
} from '../../store/actions/attendanceActionCreator';
import { fetchAppConfig } from '../../store/actions/authActionCreator';
import { Spinner } from '../../components/UI';
import EmptyStates from '../../components/EmptyStates';
import { EmptyIcon } from '../../assets/svg';

import moment from 'moment';
import { getActiveInitRoute, getPermissions } from '../../utils/helper';

import GetAppIcon from '@material-ui/icons/GetApp';
import { downloadData } from '../../utils/helper';
import FilterModal, {
    FilteredState,
    FILTER_INPUT_TYPE,
} from '../../components/Modals/FilterModal';
import { useDebounce } from '../../hooks/useDebounce';

const useStyles = makeStyles((theme) => ({
    paperStyles: {
        padding: '24px',
    },
    iconButton: {
        color: '#000',
        padding: '5px',
        borderRadius: '8px',
        border: '1px solid #4d4ab5',
        backgroundColor: '#f1f3f6',
    },
    horizContainer: {
        display: 'flex',
        flexDirection: 'row',
    },
    calendarIconButton: {
        color: '#4d4ab5',
        padding: '5px',
        borderRadius: '8px',
        border: '1px solid #4d4ab5',
        marginLeft: '8px',
    },
    horizontalCardContents: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: '16px',
    },
    cardSubHeader: {
        fontSize: '22px',
        fontWeight: 700,
    },

    cardHeader: {
        fontSize: '16px',
        fontWeight: 600,
    },
    verticalCardContents: {
        flex: '1 1 20%',
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        justifyContent: 'center',
        border: 'solid 1px #ebedf8',
        borderRadius: '8px',
        backgroundColor: '#ebedf8',
        paddingLeft: '16px',
        height: '88px',
        margin: '0 8px 0 0 ',
    },
    verticalCardContentsActive: {
        backgroundColor: '#d1d8ff',
    },
    verticalCardContentsNoMargin: {
        margin: 0,
    },
    searchBar: {
        width: '25%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
    },
}));

const FILTER_STATUS = Object.freeze({
    ABSENT: 'ABSENT',
    PENDING_APPROVAL: 'PENDING APPROVAL',
    PRESENT: 'PRESENT',
    WEEK_OFF: 'WEEK OFF',
});

const Attendance = ({
    staff,
    attendanceAll,
    configs,
    isEnabledXUtil,
    dispatchGetAllAttendanceData,
    dispatchGetSingleStaffAttendanceData,
    dispatchFetchAppConfig,
    dispatchGetShiftTimingsList,
    attendanceReasonList,
    ...props
}) => {
    const { debounce, isBouncing } = useDebounce();
    const classes = useStyles();

    const [selectedDate, setSelectedDate] = useState(moment());
    const [loading, setLoading] = useState(false);
    const [defaultList, setDefaultList] = useState(attendanceAll?.lineItems);
    const [searchVal, setSearchVal] = useState('');
    const [permissions, setPermissions] = useState(
        getPermissions('attendance'),
    );
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(
        !!props.flowType ? 50 : 10,
    );
    const [filteredValues, setFilteredValue] = useState({});

    useEffect(async () => {
        await setPermissions(getPermissions('attendance'));
        if (!permissions.read) {
            props.history.push(`/${getActiveInitRoute()}`);
        }
    }, [configs]);

    useEffect(() => {
        setDefaultList(attendanceAll?.lineItems);
    }, [attendanceAll?.lineItems]);
    // const [filteredData, setFilteredData] = useState(attendanceAll.lineItems);

    const [datePickerOpen, setDatePickerOpen] = useState(false);
    const [activeCard, setActiveCard] = useState(0);
    const [attendanceListPayload, setAttendanceListPayload] = React.useState({
        filterStatus: activeCard === 0 ? '' : activeCard,
        pageNo: page + 1,
        pageSize: rowsPerPage,
        searchText: searchVal,
        selectedDate,
        isDownload: false,
    });

    useEffect(() => {
        if (!permissions.read) {
            props.history.push(`/${getActiveInitRoute()}`);
        }
        dispatchFetchAppConfig();
        // callFn();
    }, []);

    const getAllAttendanceInfo = async ({
        selectedDate,
        ...payload
    } = attendanceListPayload) => {
        await setLoading(true);
        if (props.flowType) {
            dispatchGetSingleStaffAttendanceData(
                {
                    staffId: props.staffInfo.id,
                    date: selectedDate.format('MMM-YYYY'),
                    data: {
                        ...payload,
                    },
                },
                () => {
                    setLoading(false);
                    console.log('getSingleStaffAttendanceData SUCCESS');
                },
                () => {
                    setLoading(false);
                    console.log('getSingleStaffAttendanceData FAILED');
                },
            );
        } else {
            dispatchGetAllAttendanceData(
                {
                    date: selectedDate.format('YYYYMMDD'),
                    data: {
                        filterStatus: activeCard === 0 ? '' : activeCard,
                        ...payload,
                    },
                },
                (resp) => {
                    setLoading(false);
                    if (resp?.response?.s3FileUrl) {
                        downloadData(
                            resp?.response?.s3FileUrl,
                            'Attendance Report',
                        );
                    }
                    console.log('getAllAttendanceData SUCCESS');
                },
                () => {
                    setLoading(false);
                    console.log('getAllAttendanceData FAILED');
                },
            );
        }
    };

    React.useEffect(() => {
        console.log('attendanceListPayload changed', attendanceListPayload);
        getAllAttendanceInfo(attendanceListPayload);
    }, [attendanceListPayload]);

    React.useEffect(() => {
        console.log('filteredValues changed', filteredValues);
        let hubIds = filteredValues?.hubIds?.map((hub, index) => hub.value);
        dispatchGetShiftTimingsList(
            {
                hubIds: hubIds?.length > 0 ? hubIds : null,
            },
            (resp) => {
                console.log('resp', resp);
            },
            (err) => {
                console.log('err', err);
            },
        );
    }, [filteredValues?.hubIds]);

    const handleChangePage = (event, newPage) => {
        console.log('SETPAGE ', newPage);
        setPage(newPage);
        setAttendanceListPayload((prev) => {
            return {
                ...prev,
                pageNo: newPage + 1,
            };
        });
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
        setAttendanceListPayload((prev) => {
            return {
                ...prev,
                pageNo: 1,
                pageSize: event.target.value,
            };
        });
    };

    const prepareFilterOptions = () => {
        return [
            {
                type: FILTER_INPUT_TYPE.MULTISELECT,
                label: 'Hubs',
                name: 'hubIds',
                value: filteredValues?.hubIds,
                options: staff?.hubs?.map((item) => ({
                    label: item.name,
                    value: item.id,
                })),
                onChange: async (e) => {
                    setFilteredValue({
                        ...filteredValues,
                        hubIds: e?.target?.value,
                    });
                },
            },
            {
                type: FILTER_INPUT_TYPE.MULTISELECT,
                label: 'Shifts',
                name: 'shifts',
                value: filteredValues?.shifts,
                options: staff?.shifts?.map((item) => ({
                    label: item?.shortName,
                    value: item?.id,
                })),
                onChange: (e) => {
                    setFilteredValue({
                        ...filteredValues,
                        shifts: e?.target?.value,
                    });
                },
            },
        ];
    };

    return (
        <div>
            <Spinner loading={loading}>
                {/* {!props.flowType && (
                    <TitleHeader
                        title={'Manage Attendance'}
                        isBackButton={false}
                    />
                )} */}

                {attendanceAll?.absentCount > 0 ||
                attendanceAll?.pendingApproval > 0 ||
                attendanceAll?.presentCount > 0 ||
                attendanceAll?.totalCount > 0 ||
                attendanceAll?.filterStatus ? (
                    <Paper
                        className={classes.paperStyles}
                        style={{ padding: '1.5rem' }}
                        elevation={0}
                    >
                        <Box
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                marginBottom: '16px',
                            }}
                        >
                            <Box
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                }}
                            >
                                <IconButton
                                    className={classes.iconButton}
                                    onClick={() => {
                                        if (!props.flowType) {
                                            setSelectedDate(
                                                moment(selectedDate).add(
                                                    -1,
                                                    'days',
                                                ),
                                            );
                                            setSearchVal('');
                                            setPage(0);
                                            setAttendanceListPayload((prev) => {
                                                return {
                                                    ...prev,
                                                    pageNo: 1,
                                                    selectedDate: moment(
                                                        selectedDate,
                                                    ).add(-1, 'days'),
                                                };
                                            });
                                        } else {
                                            setSelectedDate(
                                                moment(selectedDate).add(
                                                    -1,
                                                    'months',
                                                ),
                                            );
                                            setSearchVal('');
                                            setPage(0);
                                            setAttendanceListPayload((prev) => {
                                                return {
                                                    ...prev,
                                                    pageNo: 1,
                                                    selectedDate: moment(
                                                        selectedDate,
                                                    ).add(-1, 'months'),
                                                };
                                            });
                                        }
                                    }}
                                >
                                    <ChevronLeftRoundedIcon />
                                </IconButton>
                                <Typography
                                    style={{
                                        padding: '0px 16px',
                                        color: '#4d4ab5',
                                        fontWeight: 600,
                                    }}
                                >
                                    {!props.flowType
                                        ? selectedDate.format('DD MMMM YYYY')
                                        : selectedDate.format('MMMM YYYY')}
                                </Typography>
                                <IconButton
                                    className={classes.iconButton}
                                    disabled={
                                        moment().format('DD/MM/YYYY') ===
                                        moment(selectedDate).format(
                                            'DD/MM/YYYY',
                                        )
                                    }
                                    onClick={() => {
                                        if (!props.flowType) {
                                            setSelectedDate(
                                                moment(selectedDate).add(
                                                    +1,
                                                    'days',
                                                ),
                                            );
                                            setSearchVal('');
                                            setPage(0);
                                            setAttendanceListPayload((prev) => {
                                                return {
                                                    ...prev,
                                                    pageNo: 1,
                                                    selectedDate: moment(
                                                        selectedDate,
                                                    ).add(+1, 'days'),
                                                };
                                            });
                                        } else {
                                            setSelectedDate(
                                                moment(selectedDate).add(
                                                    +1,
                                                    'months',
                                                ),
                                            );
                                            setSearchVal('');
                                            setPage(0);
                                            setAttendanceListPayload((prev) => {
                                                return {
                                                    ...prev,
                                                    pageNo: 1,
                                                    selectedDate: moment(
                                                        selectedDate,
                                                    ).add(+1, 'months'),
                                                };
                                            });
                                        }
                                    }}
                                >
                                    <ChevronRightRoundedIcon />
                                </IconButton>
                                <IconButton
                                    className={classes.calendarIconButton}
                                    onClick={() => {
                                        setDatePickerOpen(true);
                                    }}
                                >
                                    <DateRangeIcon size="small" />
                                </IconButton>
                                <FilterModal
                                    filterOptions={prepareFilterOptions()}
                                    filteredValues={filteredValues}
                                    onFilterApplied={() => {
                                        setAttendanceListPayload((prev) => {
                                            return {
                                                ...prev,
                                                hubIds:
                                                    filteredValues?.hubIds?.map(
                                                        (hub) => hub?.value,
                                                    ) ?? null,
                                                shiftIds:
                                                    filteredValues?.shifts?.map(
                                                        (shift) => shift?.value,
                                                    ) ?? null,
                                            };
                                        });
                                    }}
                                />
                                <FilteredState
                                    filterOptions={prepareFilterOptions()}
                                    filteredValues={filteredValues}
                                    onDelete={(filterKey) => {
                                        let newFilteredValues = {
                                            ...filteredValues,
                                        };
                                        delete newFilteredValues?.[filterKey];
                                        setFilteredValue(newFilteredValues);
                                        setAttendanceListPayload((prev) => {
                                            return {
                                                ...prev,
                                                hubIds:
                                                    newFilteredValues?.hubIds?.map(
                                                        (hub) => hub?.value,
                                                    ) ?? null,
                                                shiftIds:
                                                    newFilteredValues?.shifts?.map(
                                                        (shift) => shift?.value,
                                                    ) ?? null,
                                            };
                                        });
                                    }}
                                />
                            </Box>
                            {!props?.flowType && (
                                <Box className={classes.searchBar}>
                                    <Tooltip
                                        title="Download Report"
                                        placement="top"
                                    >
                                        <IconButton
                                            className={'inputbutton'}
                                            variant="outlined"
                                            style={{
                                                marginRight: 16,
                                                border: '1px solid #43419e',
                                                borderRadius: 8,
                                                padding: 6,
                                            }}
                                            color="primary"
                                            onClick={(e) => {
                                                debounce(() => {
                                                    getAllAttendanceInfo({
                                                        ...attendanceListPayload,
                                                        isDownload: true,
                                                    });
                                                });
                                            }}
                                            disabled={
                                                isBouncing ||
                                                (!defaultList?.length &&
                                                    !permissions.read)
                                            }
                                        >
                                            <GetAppIcon size="small" />
                                        </IconButton>
                                    </Tooltip>
                                    <Searchbar
                                        placeHolderText="Search"
                                        dataSource={[]}
                                        keys={['']}
                                        fullWidth={false}
                                        value={searchVal}
                                        onFilter={async (filteredData) => {}}
                                        backendSearchbar={true}
                                        onBackendSearch={(searchText) => {
                                            setPage(0);
                                            setSearchVal(searchText);
                                            setAttendanceListPayload((prev) => {
                                                return {
                                                    ...prev,
                                                    pageNo: 1,
                                                    searchText,
                                                };
                                            });
                                        }}
                                        onChange={async (value) => {
                                            console.log('blur', value);
                                            setSearchVal(value);
                                        }}
                                    />
                                </Box>
                            )}
                        </Box>
                        {/* <Box
                    style={{
                        marginBottom: '16px',
                        display: 'flex',
                        flex: '1 1 auto',
                    }}
                > */}
                        <Box className={classes.horizontalCardContents}>
                            {!props.flowType && (
                                <Box
                                    className={`${
                                        classes.verticalCardContents
                                    } ${
                                        activeCard === 0 &&
                                        classes.verticalCardContentsActive
                                    }`}
                                    onClick={() => {
                                        setActiveCard(0);
                                        setPage(0);
                                        setAttendanceListPayload((prev) => {
                                            return {
                                                ...prev,
                                                pageNo: 1,
                                                filterStatus: '',
                                            };
                                        });
                                    }}
                                >
                                    <Typography className={classes.cardHeader}>
                                        Total Staffs
                                    </Typography>
                                    <Typography
                                        className={classes.cardSubHeader}
                                    >
                                        {attendanceAll.totalCount}
                                    </Typography>
                                </Box>
                            )}
                            <Box
                                className={`${classes.verticalCardContents} ${
                                    activeCard === FILTER_STATUS.PRESENT &&
                                    classes.verticalCardContentsActive
                                }`}
                                onClick={() => {
                                    setActiveCard(FILTER_STATUS.PRESENT);
                                    setPage(0);
                                    setAttendanceListPayload((prev) => {
                                        return {
                                            ...prev,
                                            pageNo: 1,
                                            filterStatus: FILTER_STATUS.PRESENT,
                                        };
                                    });
                                }}
                            >
                                <Typography className={classes.cardHeader}>
                                    Present
                                </Typography>
                                <Typography className={classes.cardSubHeader}>
                                    {attendanceAll.presentCount}
                                </Typography>
                            </Box>
                            <Box
                                className={`${classes.verticalCardContents} ${
                                    activeCard === FILTER_STATUS.WEEK_OFF &&
                                    classes.verticalCardContentsActive
                                }`}
                                onClick={() => {
                                    setActiveCard(FILTER_STATUS.WEEK_OFF);
                                    setPage(0);
                                    setAttendanceListPayload((prev) => {
                                        return {
                                            ...prev,
                                            pageNo: 1,
                                            filterStatus:
                                                FILTER_STATUS.WEEK_OFF,
                                        };
                                    });
                                }}
                            >
                                <Typography className={classes.cardHeader}>
                                    Week Off
                                </Typography>
                                <Typography className={classes.cardSubHeader}>
                                    {attendanceAll.weekOffCount}
                                </Typography>
                            </Box>
                            <Box
                                className={`${classes.verticalCardContents} ${
                                    activeCard === FILTER_STATUS.ABSENT &&
                                    classes.verticalCardContentsActive
                                }`}
                                onClick={() => {
                                    setActiveCard(FILTER_STATUS.ABSENT);
                                    setPage(0);
                                    setAttendanceListPayload((prev) => {
                                        return {
                                            ...prev,
                                            pageNo: 1,
                                            filterStatus: FILTER_STATUS.ABSENT,
                                        };
                                    });
                                }}
                            >
                                <Typography className={classes.cardHeader}>
                                    Absent
                                </Typography>
                                <Typography className={classes.cardSubHeader}>
                                    {attendanceAll.absentCount}
                                </Typography>
                            </Box>
                            <Box
                                className={`${classes.verticalCardContents} ${
                                    classes.verticalCardContentsNoMargin
                                } ${
                                    activeCard ===
                                        FILTER_STATUS.PENDING_APPROVAL &&
                                    classes.verticalCardContentsActive
                                }`}
                                onClick={() => {
                                    setActiveCard(
                                        FILTER_STATUS.PENDING_APPROVAL,
                                    );
                                    setPage(0);
                                    setAttendanceListPayload((prev) => {
                                        return {
                                            ...prev,
                                            pageNo: 1,
                                            filterStatus:
                                                FILTER_STATUS.PENDING_APPROVAL,
                                        };
                                    });
                                }}
                            >
                                <Typography className={classes.cardHeader}>
                                    Pending Approval
                                </Typography>
                                <Typography className={classes.cardSubHeader}>
                                    {attendanceAll.pendingApproval}
                                </Typography>
                            </Box>
                        </Box>
                        {/* </Box> */}
                        {attendanceAll?.lineItems.length > 0 ? (
                            <Box>
                                <AttendanceListContainer
                                    flowType={props?.flowType}
                                    data={defaultList}
                                    selectedDate={selectedDate}
                                    staffInfo={props?.staffInfo}
                                    getAllAttendanceInfo={() => {
                                        getAllAttendanceInfo(
                                            attendanceListPayload,
                                        );
                                    }}
                                    {...{ isEnabledXUtil, permissions }}
                                />
                                {!props?.flowType && (
                                    <TablePagination
                                        rowsPerPageOptions={[10, 50, 100]}
                                        component="div"
                                        count={
                                            !!props.flowType
                                                ? attendanceAll?.lineItems
                                                      ?.length
                                                : attendanceAll?.totalFilteredCount
                                        }
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onChangePage={handleChangePage}
                                        onChangeRowsPerPage={
                                            handleChangeRowsPerPage
                                        }
                                    />
                                )}
                            </Box>
                        ) : (
                            <Box
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <EmptyIcon />
                            </Box>
                        )}
                        {datePickerOpen && (
                            <CustomDatePicker
                                open={datePickerOpen}
                                onOpen={() => setDatePickerOpen(true)}
                                onClose={() => setDatePickerOpen(false)}
                                format="d MMM yyyy"
                                views={
                                    props.flowType
                                        ? ['year', 'month']
                                        : ['year', 'month', 'date']
                                }
                                value={selectedDate}
                                onChange={(e) => {
                                    setSelectedDate(moment(e));
                                    setPage(0);
                                    setAttendanceListPayload((prev) => {
                                        return {
                                            ...prev,
                                            pageNo: 1,
                                            selectedDate: moment(e),
                                        };
                                    });
                                }}
                            />
                        )}
                    </Paper>
                ) : (
                    !loading && (
                        <Box
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <EmptyStates
                                type="Attendance"
                                header1={'Add Your First Staff to Manage'}
                                header2={'Attendance'}
                                buttonDisplay={true}
                            />
                        </Box>
                    )
                )}
            </Spinner>
        </div>
    );
};

const mapStateToProps = (state) => ({
    staff: state.staff,
    attendanceAll: state.attendance.all,
    configs: state.user.config,
    isEnabledXUtil: state?.user?.config?.isEnabledXUtil,
});

const mapDispatchToProps = (dispatch) => ({
    dispatchGetAllAttendanceData: (payload, onSuccess, onError) =>
        dispatch(getAllAttendanceData(payload, onSuccess, onError)),
    dispatchGetSingleStaffAttendanceData: (payload, onSuccess, onError) =>
        dispatch(getSingleStaffAttendanceData(payload, onSuccess, onError)),
    getAllStaffs: (payload, onSuccess, onError) =>
        dispatch(getAllStaffs(payload, onSuccess, onError)),
    dispatchFetchAppConfig: (onSuccess, onError) =>
        dispatch(fetchAppConfig(onSuccess, onError)),
    dispatchGetShiftTimingsList: (data, onSuccess, onError) =>
        dispatch(getShiftTimingsList(data, onSuccess, onError)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Attendance);
