import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import {
    Box,
    Button,
    MenuItem,
    Paper,
    makeStyles,
    Typography,
    Dialog,
    DialogTitle,
    IconButton,
    Slide,
    useMediaQuery,
    useTheme,
    Avatar,
    Divider,
    DialogContent,
    DialogActions,
    Tooltip,
    List,
    ListItem,
    ListItemSecondaryAction,
    Backdrop,
    ListItemText,
    TextField,
    Grid,
    Tab,
    Tabs,
    Menu,
    withStyles,
    TablePagination,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableCell,
    TableRow,
} from '@material-ui/core';
import moment from 'moment';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';
import useAlertInfoModalStyles from '../styles/useAlertInfoModalStyles';
import { CustomTextField } from '../../../components/Textfield';
import { AlertDelivery, AlertRider } from '../../../assets/svg';
import WarningIcon from '@material-ui/icons/Warning';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import CallIcon from '@material-ui/icons/Call';
import MapIcon from '@material-ui/icons/Map';
import { CHIPS_CLASS_TYPE_MAPPING } from '../helper';
import clsx from 'clsx';
import useControlsStyles from '../styles/useControlsStyles';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import { TimelineOppositeContent } from '@material-ui/lab';
import CommentModal from '../../../components/Modals/CommentModal';
import {
    ALERT_STATUS_UPDATE,
    STATUS_TYPES,
    STATUS_TYPES_CONFIG,
} from '../helper';
import { callPhoneNumber } from '../../../utils/helper';
import useRiderInfoModalStyles from '../styles/useRiderInfoModalStyles';
import EmbeddedIframe from './EmbeddedIframe';
interface RiderInfoModalProps {
    [key: string]: any;
}
const Transition: any = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface TabConfig {
    label: string;
    columns: any[];
    fetchData: (apiPayload: any) => Promise<any>;
    embedded?: boolean;
    embeddedURL?: string;
    embeddedOrigin?: string;
}

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        padding: '8px 8px',
        borderTop: '1px solid #EBEDF8',
        borderBottom: '1px solid #EBEDF8',
        '&:first-child': {
            paddingLeft: '16px',
            borderTopLeftRadius: '4px',
            borderBottomLeftRadius: '4px',
            borderLeft: '1px solid #EBEDF8',
        },
        '&:last-child': {
            paddingRight: '16px',
            borderTopRightRadius: '4px',
            borderBottomRightRadius: '4px',
            borderRight: '1px solid #EBEDF8',
        },
    },
}))(TableCell);
const StyledTableHeadCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.white,
        color: '#525050',
        fontWeight: 700,
        fontSize: 16,
        letterSpacing: '0.144px',
        textTransform: 'capitalize',
        padding: '8px',
        borderBottom: 'none',
        //textAlign: 'center',
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow: any = withStyles((theme) => ({
    root: {
        marginBottom: '8px',
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.common.white,
        },
        border: '1px solid #EBEDF8',
        borderRadius: '4px',
    },
}))(TableRow);

const RiderInfoModal: React.FC<RiderInfoModalProps> = ({
    riderInfo,
    open,
    onClose,
    action,
    selectedFilter,
    ...props
}) => {
    const currentDate = moment(
            selectedFilter?.selectedDate ?? moment().valueOf(),
        ).format('YYYY-MM-DD'),
        nextDate = moment(selectedFilter?.selectedDate ?? moment().valueOf())
            .add(1, 'days')
            .format('YYYY-MM-DD');
    const tabConfigs: TabConfig[] = [
        {
            label: 'Completed Orders',
            columns: [
                { key: 'orderId', label: 'Order ID' },
                { key: 'status', label: 'Status' },
                { key: 'completedTime', label: 'Completed Time' },
                { key: 'remarks', label: 'Remarks' },
            ],
            fetchData: async (payload: any) => {
                toggleLoading(true);
                const result = await new Promise((resolve) => {
                    action?.getRiderCompletedOrders(payload, (resp: any) => {
                        toggleLoading(false);
                        resolve(resp);
                    });
                });

                return result;
            },
        },
        {
            label: 'Missed Orders',
            columns: [
                { key: 'orderId', label: 'Order ID' },
                { key: 'missedTime', label: 'Missed Time' },
                { key: 'remarks', label: 'Remarks' },
            ],
            fetchData: async (payload: any) => {
                toggleLoading(true);
                const result = await new Promise((resolve) => {
                    action?.getRiderMissedOrders(payload, (resp: any) => {
                        toggleLoading(false);
                        resolve(resp);
                    });
                });

                return result;
            },
        },
        {
            label: 'Cancelled Orders',
            columns: [
                { key: 'orderId', label: 'Order ID' },
                { key: 'cancelledTime', label: 'Cancelled Time' },
                { key: 'status', label: 'Status' },
                { key: 'remarks', label: 'Remarks' },
            ],
            fetchData: async (payload: any) => {
                toggleLoading(true);
                const result = await new Promise((resolve) => {
                    action?.getRiderCancelledOrders(payload, (resp: any) => {
                        toggleLoading(false);
                        resolve(resp);
                    });
                });

                return result;
            },
        },
        {
            label: 'Ping Rejection History',
            columns: [
                { key: 'pingTime', label: 'Ping Time' },
                { key: 'waybillno', label: 'Waybill No' },
                { key: 'rejectionReason', label: 'Rejection Reason' },
                { key: 'expectedFM', label: 'Expected FM' },
                { key: 'actualFM', label: 'Actual FM' },
            ],
            embedded: true,
            embeddedOrigin: 'https://bis.loadshare.net/',
            embeddedURL: `https://bis.loadshare.net/public/question/7cc47bde-cdf4-441f-a514-a8230bd6a8bc?start_date=${currentDate}&end_date=${nextDate}&field_executive_id=${riderInfo?.id}`,
            fetchData: async (payload: any) => {
                toggleLoading(true);
                let request = {
                    ...payload,
                    startDate: '2024-11-12',
                    endDate: '2024-11-13',
                    riderId: '393203',
                };
                const result = await new Promise((resolve) => {
                    action?.getRiderPingsFromMeta(request, (resp: any) => {
                        toggleLoading(false);
                        resolve(resp);
                    });
                });

                return result;
            },
        },
        {
            label: 'Rider Events',
            columns: [
                { key: 'event', label: 'Event' },
                { key: 'eventTime', label: 'Event Time' },
                { key: 'remarks', label: 'Remarks' },
            ],
            embedded: true,
            embeddedOrigin: 'https://bis.loadshare.net/',
            embeddedURL: `https://bis.loadshare.net/public/question/a39f3aed-11ac-4eb7-a03d-3c3ba9357bd0?start_date=${currentDate}&end_date=${nextDate}&field_executive_id=${riderInfo?.id}`,
            fetchData: async (payload: any) => {
                toggleLoading(true);
                const result = await new Promise((resolve) => {
                    action?.getRiderEvents(payload, (resp: any) => {
                        toggleLoading(false);
                        resolve(resp);
                    });
                });

                return result;
            },
        },
    ];
    const classes: any = useRiderInfoModalStyles();
    const classesControls: any = useControlsStyles({
        status: '',
    });
    const [isOpen, toggleModal] = useState(open);
    const [loading, toggleLoading] = useState(false);
    const [tabValue, setTabValue] = useState(0);
    const [tabIndex, setTabIndex] = useState(0);
    const [data, setData] = useState<any>(null);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const fetchTabData = async (tabIndex: number) => {
        let apiPayload = {
            dateRange: {
                fromEpochMillis: moment(
                    selectedFilter?.selectedDate ?? moment().valueOf(),
                )
                    ?.startOf('day')
                    ?.valueOf(),
                toEpochMillis: moment(
                    selectedFilter?.selectedDate ?? moment().valueOf(),
                )
                    ?.endOf('day')
                    ?.valueOf(),
            },
            riderIds: [riderInfo?.id],
            pageNo: page + 1,
            pageSize: rowsPerPage,
        };
        const fetchedData = await tabConfigs?.[tabIndex].fetchData(apiPayload);
        setData(fetchedData);
    };

    useEffect(() => {
        if (!tabConfigs?.[tabIndex].embedded) {
            fetchTabData(tabIndex);
        }
    }, [tabIndex, page, rowsPerPage]);

    const handleChangeTab = (
        event: React.ChangeEvent<{}>,
        newValue: number,
    ) => {
        setTabIndex(newValue);
        setPage(0); // Reset page when switching tabs
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleClose = () => {
        toggleModal(!isOpen);
        onClose && onClose();
    };

    const actions = [
        <Button
            key={2}
            variant="contained"
            color="primary"
            disabled={false}
            size="small"
            type="submit"
            onClick={() => {
                onClose();
            }}
            classes={{
                disabled: classes.disabled,
                //root: classes.untagCTa,
            }}
            style={{
                boxShadow: 'none',
                backgroundColor: '#F44336',
                width: '171px',
            }}
            disableElevation
        >
            Close
        </Button>,
    ];
    useEffect(() => {
        if (!!riderInfo?.id) {
        }
    }, []);
    const handleChange = (event: any, newValue: number) => {
        setTabValue(newValue);
    };
    const classStatus: any = riderInfo?.status
        ?.toUpperCase()
        ?.replace(' ', '_')
        ?.trim();
    let chipClass: any =
        classesControls?.[`${CHIPS_CLASS_TYPE_MAPPING?.[classStatus]}`];
    console.log('The chip class', CHIPS_CLASS_TYPE_MAPPING?.[classStatus]);
    return (
        <Dialog
            onClose={(event) => {
                handleClose();
            }}
            open={isOpen}
            disableBackdropClick={true}
            TransitionComponent={Transition}
            aria-labelledby="riders-info-dialog-slide-title"
            aria-describedby="alerts-info-dialog-slide-description"
            disableEscapeKeyDown
            fullWidth={true}
            scroll={'paper'}
            maxWidth={'lg'}
        >
            <DialogTitle
                id="alerts-info-dialog-slide-title"
                classes={{
                    root: classes.bottomBorder,
                }}
            >
                <Box
                    width={'100%'}
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                >
                    <>
                        <Box
                            width={'75%'}
                            display={'flex'}
                            alignItems={'center'}
                        >
                            <IconButton
                                aria-label="close"
                                disabled={false}
                                className={classes.iconButton}
                                onClick={handleClose}
                            >
                                <ArrowBackIcon style={{ color: '#000' }} />
                            </IconButton>
                            <Box>
                                <Typography
                                    className={classes.modalTitle}
                                    variant="h6"
                                >
                                    {riderInfo?.riderName}
                                    {` `}
                                    {/* <Box
                                        component={'span'}
                                        className={clsx([
                                            classes.chip,
                                            chipClass,
                                        ])}
                                    >
                                        <Typography
                                            component={'span'}
                                            className={classesControls.chipText}
                                        >
                                            {riderInfo?.riderStatus ?? '-'}
                                        </Typography>
                                    </Box> */}
                                </Typography>
                                <Box
                                    display={'flex'}
                                    alignItems={'center'}
                                    flexDirection={'row'}
                                >
                                    {!!riderInfo?.contactNumber && (
                                        <Typography
                                            className={classes.modalSubTitle}
                                            variant="h6"
                                        >
                                            {`${riderInfo?.contactNumber}`}
                                        </Typography>
                                    )}

                                    {!!riderInfo?.riderId && (
                                        <Typography
                                            className={classes.modalSubTitle}
                                            variant="h6"
                                        >
                                            {` | ${riderInfo?.riderId}`}
                                        </Typography>
                                    )}
                                    {!!riderInfo?.createdAt && (
                                        <Typography
                                            className={classes.modalSubTitle}
                                            variant="h6"
                                        >
                                            {` | ${riderInfo?.createdAt}`}
                                        </Typography>
                                    )}
                                </Box>
                            </Box>
                        </Box>
                        <IconButton
                            aria-label="close"
                            disabled={false}
                            className={classes.iconButton}
                            onClick={handleClose}
                        >
                            <CloseIcon style={{ color: '#000' }} />
                        </IconButton>
                    </>
                </Box>
            </DialogTitle>
            <DialogContent style={{ paddingTop: '0px' }}>
                <Box width={'100%'} pt={'0px'} pb={'16px'}>
                    <Tabs
                        value={tabIndex}
                        onChange={handleChangeTab}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="fullWidth"
                    >
                        {tabConfigs?.map((tab, index) => (
                            <Tab key={index} label={tab?.label} />
                        ))}
                    </Tabs>

                    {!!tabConfigs?.[tabIndex] &&
                        !tabConfigs?.[tabIndex]?.embedded && (
                            <Box mt={'12px'}>
                                <TableContainer
                                    component={Box}
                                    className={classes.tableContainer}
                                >
                                    <Table
                                        stickyHeader
                                        aria-label={
                                            tabConfigs?.[tabIndex].label
                                        }
                                    >
                                        <TableHead>
                                            <TableRow>
                                                {tabConfigs?.[
                                                    tabIndex
                                                ]?.columns.map(
                                                    (column, index) => (
                                                        <StyledTableHeadCell
                                                            key={index}
                                                        >
                                                            {column.label}
                                                        </StyledTableHeadCell>
                                                    ),
                                                )}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {data?.lineItems?.map(
                                                (row: any, index: number) => (
                                                    <StyledTableRow key={index}>
                                                        {tabConfigs[
                                                            tabIndex
                                                        ].columns.map(
                                                            (
                                                                column,
                                                                colIndex,
                                                            ) => (
                                                                <StyledTableCell
                                                                    key={
                                                                        colIndex
                                                                    }
                                                                >
                                                                    {column?.format
                                                                        ? column?.format(
                                                                              row?.[
                                                                                  column
                                                                                      ?.key
                                                                              ],
                                                                          )
                                                                        : row?.[
                                                                              column
                                                                                  ?.key
                                                                          ]}
                                                                </StyledTableCell>
                                                            ),
                                                        )}
                                                    </StyledTableRow>
                                                ),
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25, 50]}
                                    component="div"
                                    count={data?.totalCount ?? 0}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onChangePage={handleChangePage}
                                    onChangeRowsPerPage={
                                        handleChangeRowsPerPage
                                    }
                                />
                            </Box>
                        )}
                    {!!tabConfigs?.[tabIndex] &&
                        !!tabConfigs?.[tabIndex]?.embedded &&
                        !!tabConfigs?.[tabIndex]?.embeddedURL && (
                            <Box
                                mt={'12px'}
                                className={classes.iframeContainer}
                            >
                                <EmbeddedIframe
                                    url={tabConfigs?.[tabIndex]?.embeddedURL}
                                    origin={
                                        tabConfigs?.[tabIndex]?.embeddedOrigin
                                    }
                                />
                            </Box>
                        )}
                </Box>
            </DialogContent>
            <Divider />
            <DialogActions className={classes.dialogActions}>
                {actions.map((action, index) => (
                    <Box key={index}>{action}</Box>
                ))}
            </DialogActions>
            <Backdrop
                className={classes.backDropRoot}
                open={loading}
                onClick={() => {}}
            >
                <CircularProgress color="primary" />
            </Backdrop>
            {/* {isCommentOpen && updateAction && (
                <CommentModal
                    open={isCommentOpen}
                    onClose={handleCommentModalClose}
                    data={updateAction}
                    onSave={handleCommentSave}
                />
            )} */}
        </Dialog>
    );
};
export default RiderInfoModal;
