import {
    Avatar,
    Box,
    Divider,
    Grid,
    Paper,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import useSingleEVStyles from '../SingleEV/useSingleEVStyles';
import {
    StyledTableCell,
    StyledTableHeadCell,
    StyledTableRow,
} from '../useEVTable';
import { generateMockTaggingHistory, historyTableConfig } from './helper';
import useSingleEVTable from './useSingleEVtable';
import { getEvVehicleHistory } from '../../../store/actions/settingsActionCreator';
import { useDispatch } from 'react-redux';
interface EVTaggingHistoryProps {
    historyData: any;
    fetchEVHistoryListData: any;
    [key: string]: any;
}

const EVTaggingHistory: React.FC<EVTaggingHistoryProps> = ({
    historyData,
    fetchEVHistoryListData,
    ...props
}: any) => {
    const classes: any = useSingleEVStyles();
    const [pagination, setPagination] = useState<any>({
        pageSize: props?.location?.state?.rowsPerPage ?? 10,
        pageNo: props.location?.state?.page ?? 0,
    });
    const [filteredValues, setFilteredValue] = useState<any>({
        status: '',
        vendorId: null,
    });
    const [searchText, setSearchText] = useState('');
    const [historyListingPayload, setHistoryListingPayload] = React.useState({
        pageNo: props?.location?.state?.page ?? 1,
        pageSize: props?.location?.state?.rowsPerPage ?? 50,
        searchText: '',
        captains: '',
        project: '',
        ...filteredValues,
    });

    useEffect(() => {
        fetchEVHistoryListData(historyListingPayload);
    }, [historyListingPayload]);
    const { getColumnComponentValue } = useSingleEVTable({
        filteredValues: filteredValues,
        history: props?.history,
    });
    const handleChangePage = (event: any, newPage: any) => {
        console.log('SETPAGE ', newPage);
        setPagination((prev: any) => {
            return {
                ...prev,
                pageNo: newPage + 1,
            };
        });
        setHistoryListingPayload((prev: any) => {
            return {
                ...prev,
                pageNo: newPage + 1,
            };
        });
    };

    const handleChangeRowsPerPage = (event: any) => {
        setPagination((prev: any) => {
            return {
                ...prev,
                pageNo: 1,
                pageSize: event.target.value,
            };
        });
        setHistoryListingPayload((prev: any) => {
            return {
                ...prev,
                pageNo: 1,
                pageSize: event.target.value,
            };
        });
    };
    let currentActiveRider = historyData?.evHistoryLineItems?.filter(
        (item: any) => !item.returnedDateEpochMillis,
    );
    let filteredRow = historyData?.evHistoryLineItems?.filter(
        (item: any) => !!item.returnedDateEpochMillis,
    );
    return (
        <Box component={'div'}>
            {!!currentActiveRider?.length && (
                <Box
                    display={'flex'}
                    flexDirection={'row'}
                    alignItems={'center'}
                    justifyContent={'flex-start'}
                    width={'100%'}
                    p={`12px 24px`}
                    borderRadius={'8px'}
                    bgcolor={'#F6F7F9'}
                    border={'1px #E1E1E1'}
                    mb={2}
                >
                    <Avatar className={classes.nameAvatar}>
                        {currentActiveRider?.[0]?.name?.[0]}
                    </Avatar>
                    <Box flexGrow={1} ml={3}>
                        <Grid container spacing={1}>
                            {historyTableConfig?.columnsCurrentRider?.map(
                                (column: any, index: any) => {
                                    return (
                                        <Grid item xs key={`column${index}`}>
                                            <Paper
                                                className={classes.columnPaper}
                                                elevation={0}
                                            >
                                                <Typography
                                                    component={'p'}
                                                    style={{
                                                        marginBottom: '8px',
                                                    }}
                                                >
                                                    {column?.name}
                                                </Typography>
                                                {getColumnComponentValue(
                                                    currentActiveRider?.[0],
                                                    column,
                                                    null,
                                                    0,
                                                )}
                                            </Paper>
                                        </Grid>
                                    );
                                },
                            )}
                        </Grid>
                    </Box>
                </Box>
            )}
            <TableContainer component={Box} className={classes.tableContainer}>
                <Table stickyHeader aria-label="Orders table">
                    <TableHead>
                        <TableRow>
                            {historyTableConfig?.columns?.map(
                                (column: any, index: any) => {
                                    return (
                                        <StyledTableHeadCell
                                            key={`column${index}`}
                                        >
                                            {column?.name}
                                        </StyledTableHeadCell>
                                    );
                                },
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredRow?.map((row: any, rowIndex: number) => (
                            <>
                                <StyledTableRow
                                    key={row.name}
                                    onClick={(e: any) => {}}
                                >
                                    {historyTableConfig?.columns?.map(
                                        (column: any, index: any) => {
                                            return (
                                                <StyledTableCell
                                                    key={`row${index}`}
                                                >
                                                    {getColumnComponentValue(
                                                        row,
                                                        column,
                                                        null,
                                                        rowIndex,
                                                    )}
                                                </StyledTableCell>
                                            );
                                        },
                                    )}
                                </StyledTableRow>
                                <Divider
                                    style={{
                                        margin: '4px 0',
                                        backgroundColor: '#fff',
                                    }}
                                />
                            </>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {/* <TablePagination
                rowsPerPageOptions={[10, 25, 50, 100, 150]}
                component="div"
                count={historyData?.totalCount ?? 0}
                rowsPerPage={pagination?.pageSize}
                page={pagination?.pageNo}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            /> */}
        </Box>
    );
};

export default EVTaggingHistory;
