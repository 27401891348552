import * as constants from './../constants';

const defaultState = {};

export default function reportReducer(state = defaultState, action) {
    switch (action.type) {
        case constants.SET_REPORT_DASHBOARD:
            return { ...state, dashboard: action.payload };
        case constants.SET_REPORT_PAYMENTS:
            return { ...state, payments: action.payload };
        case constants.SET_REPORT_EARNINGS:
            return { ...state, earnings: action.payload };
        case constants.SET_REPORT_ATTENDENCE:
            return { ...state, attendence: action.payload };
        case constants.SET_REPORT_EXPENSES:
            return { ...state, expenses: action.payload };
        case constants.SET_REPORT_DISTANCE:
            return { ...state, distance: action.payload };
        case constants.SET_REPORT_PAYSLIP:
            return { ...state, payslip: action.payload };
        // case constants.SET_DISTANCE_REPORT_FROM_BEECON:
        //     return {
        //         ...state,
        //         distanceReport: {
        //             ...state.distanceReport,
        //             beecon: action.payload,
        //         },
        //     };
        default:
            return state;
    }
}
