import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
    getTimelineActivityData,
    markAbsent,
    getAllAttendanceData,
    getSingleStaffAttendanceData,
    weekOff,
    getAttendanceActivityData,
} from '../../../store/actions/attendanceActionCreator';
import './styles.scss';
import { withRouter } from 'react-router-dom';
import {
    List,
    ListItem,
    ListItemSecondaryAction,
    Box,
    Typography,
    IconButton,
    Button,
    Tooltip,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import {
    PersonTickIcon,
    PunchInIcon,
    PunchInV2Icon,
    PunchOutIcon,
} from '../../../assets/svg';
import CustomDropdown from '../../Dropdown';
import AddInOutTimeModal from '../../Modals/AddInOutTimeModal';
import AttendanceActivityModal from '../../Modals/AttendanceActivityModal';
import ApprovePunchModal from '../../Modals/ApprovePunchModal';
import MarkAbsentModal from '../../Modals/MarkAbsentModal';
import moment from 'moment';
import { showMessage } from '../../../utils/helper';
import UpdateDistanceModal from '../../Modals/UpdateDistanceModal';
import ShiftColumnItemPopper, {
    checkCurrentAvailableShift,
} from '../ShiftColumnItemPopper';
import { HUB_TYPE_ENUM } from '../../../utils/constants';
import AttendanceShiftActivityModal from '../../Modals/AttendanceShiftActivityModal';

const useStyles = makeStyles((theme) => ({
    listItem: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        padding: '16px',
        marginBottom: '8px',
        border: '1px solid #ebedf8 ',
        borderRadius: '8px !important',
        // height: '56px !important',÷
    },
    listItemHeader: {
        border: '0px',
        marginBottom: '2px',
        color: '#808080',
        fontWeight: 'bold',
        fontSize: 16,
    },

    buttonDefaultStyles: {
        border: '1px solid #2b2b2b',
        borderRadius: '8px',
        width: '120px',
        height: '40px',
    },
    buttonText: {
        color: '#2b2b2b',
        fontSize: '14px',
        fontWeight: 700,
    },
}));

function ListItemLink({ ...props }) {
    // if (flag) {
    //     return <ListItem {...props} />;
    // } else {
    return <ListItem button component="a" {...props} />;
    // }
}

const AttendanceListContainer = ({
    permissions,
    data,
    dispatchGetTimelineActivity,
    dispatchMarkAbsent,
    dispatchGetSingleStaffAttendanceData,
    dispatchGetAllAttendanceData,
    getAllAttendanceInfo,
    dispatchMarkWeekOff,
    ...props
}) => {
    const classes = useStyles();

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [addInTimeModalOpen, setAddInTimeModalOpen] = useState(false);
    const [addOutTimeModalOpen, setAddOutTimeModalOpen] = useState(false);
    const [updateDistanceModalOpen, setUpdateDistanceModalOpen] =
        useState(false);

    const [selectedItemData, setSelectedItemData] = useState({
        data: {
            dateEpochMillis: moment(props.selectedDate).valueOf(),
        },
    });

    const [activityModalOpen, setActivityModalOpen] = useState(false);
    const [reapproveModalOpen, setReapproveModalOpen] = useState(false);
    const [absentModalOpen, setAbsentModalOpen] = useState(false);

    const [scrollPosition, setScrollPosition] = useState(0);
    const handleScroll = () => {
        const position = window.pageYOffset;
        setScrollPosition(position);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        console.log('scroll:', scrollPosition);
    }, [scrollPosition]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleUpdateDistance = (value) => {
        setSelectedItemData(
            value
                ? {
                      dateEpochMillis:
                          value.dateEpochMillis?.valueOf() ??
                          moment(props.selectedDate).valueOf(),
                      name: value?.name,
                      hubName: value?.hubName,
                      gpsReading: value.userDistance?.distance ?? 0,
                      correctedGpsReading:
                          value.userDistance?.correctedDistance,
                      punchIn: {
                          kmReading: value.punchIn?.kmReading,
                          kmS3ImageUrl: value.punchIn?.kmS3ImageUrl,
                      },
                      punchOut: {
                          kmReading: value.punchOut?.kmReading,
                          kmS3ImageUrl: value.punchOut?.kmS3ImageUrl,
                      },
                      staffId: value.staffId ?? props.staffInfo.id ?? null,
                      reason: value.userDistance?.reason ?? null,
                      notes: value.userDistance?.notes ?? null,
                  }
                : {
                      dateEpochMillis: moment(props.selectedDate).valueOf(),
                      name: null,
                      hubName: null,
                      gpsReading: 0,
                      odometerReading: 0,
                      staffId: props.staffInfo.id ?? null,
                      reason: null,
                      notes: null,
                  },
        );
        setUpdateDistanceModalOpen(true);
    };

    React.useEffect(() => {
        setPage(0);
    }, [data]);

    const getShiftTimingsOptions = (val) => {
        let optionsArr = [];
        val?.shiftTimings?.map((shiftTiming, index) =>
            optionsArr.push({
                style:
                    index == shiftTiming?.length - 1
                        ? {
                              backgroundColor: 'rgba(67, 65, 158, 0.07)',
                              padding: '0px',
                              borderRadius: '8px',
                          }
                        : {
                              backgroundColor: 'rgba(67, 65, 158, 0.07)',
                              padding: '0 8px',
                              borderRadius: '8px',
                              marginBottom: 8,
                          },
                render: () => {
                    return (
                        <div
                            style={{
                                width: '100%',
                            }}
                        >
                            <>
                                <div
                                    style={{
                                        fontFamily: 'Nunito',
                                        fontSize: '12px',
                                        fontWeight: '600',
                                        color: '#252525',
                                    }}
                                >
                                    {shiftTiming?.name ?? ''}
                                </div>
                                <div
                                    style={{
                                        fontFamily: 'Nunito',
                                        fontSize: '10px',
                                        fontWeight: 'normal',
                                        color: '#252525',
                                    }}
                                >
                                    {`${shiftTiming?.startTime} - ${shiftTiming?.endTime}`}
                                </div>
                            </>
                        </div>
                    );
                },
                onSuccess: () => {},
                disabled: !permissions?.delete,
            }),
        );
        return optionsArr;
    };

    return (
        <Box>
            <List component="nav">
                <Box>
                    {data?.map((value, index) => {
                        return (
                            <ListItemLink
                                // flag={props.flowType}
                                className={classes.listItem}
                                onClick={() => {
                                    // if (!props.flowType) {
                                    if (value?.weekOff) {
                                        showMessage(
                                            'Staff is Marked for Week Off',
                                            'error',
                                        );
                                    } else {
                                        if (value.id) {
                                            const dispatchAPICall =
                                                dispatchGetTimelineActivity(
                                                    {
                                                        staffId:
                                                            value?.staffId ??
                                                            props.staffInfo.id,
                                                        attendanceId: value?.id,
                                                    },
                                                    () => {
                                                        console.log(
                                                            'TimelineActivity SUCCESS',
                                                        );
                                                        setActivityModalOpen(
                                                            true,
                                                        );
                                                        setSelectedItemData({
                                                            ...value,
                                                            staffId:
                                                                value?.staffId ??
                                                                props.staffInfo
                                                                    .id,
                                                            dateEpochMillis:
                                                                value?.dateEpochMillis ??
                                                                props.selectedDate,
                                                        });
                                                    },
                                                    () => {
                                                        console.log(
                                                            'TimelineActivity FAILED',
                                                        );
                                                    },
                                                );
                                        } else {
                                            showMessage(
                                                'Please Record the Activity',
                                                'error',
                                            );
                                        }
                                    }
                                    // }
                                    // props.history.push(
                                    //     `/staff/details/${phoneNumber}`,
                                    // );
                                }}
                                // href={`/staff/details/${phoneNumber}`}
                                // href="#simple"
                                key={index}
                                style={
                                    index % 2
                                        ? {
                                              backgroundColor:
                                                  'rgba(241, 243, 246, 1)',
                                          }
                                        : {}
                                }
                            >
                                <Box
                                    style={
                                        props.flowType
                                            ? {
                                                  display: 'flex',
                                                  flex: '0 1 50%',
                                                  flexDirection: 'row',
                                                  alignItems: 'center',
                                                  padding: '8px',
                                              }
                                            : {
                                                  display: 'flex',
                                                  flex: '0 1 60%',
                                                  flexDirection: 'row',
                                                  alignItems: 'center',
                                              }
                                    }
                                >
                                    {props.flowType ? (
                                        <Typography
                                            style={{
                                                fontWeight: 600,
                                                display: 'flex',
                                                flex: '1 1 25%',
                                            }}
                                        >
                                            {value.dateDisplay
                                                ? value.dateDisplay
                                                : ''}
                                        </Typography>
                                    ) : (
                                        <>
                                            <Box
                                                style={{
                                                    display: 'flex',
                                                    flex: '0 0 30%',
                                                    flexDirection: 'column',
                                                }}
                                            >
                                                <Typography
                                                    style={{
                                                        wordBreak: 'break-all',
                                                        fontWeight: 700,
                                                        fontSize: '18px',
                                                        paddingRight: '1rem',
                                                    }}
                                                >
                                                    {value?.name ?? ' '}
                                                </Typography>
                                                <Box
                                                    style={{
                                                        display: 'flex',
                                                        flex: '0 0 40%',
                                                        flexDirection: 'row',
                                                    }}
                                                >
                                                    <Typography
                                                        style={{
                                                            fontSize: '14px',
                                                            marginRight: '8px',
                                                        }}
                                                    >
                                                        {value?.displayText ??
                                                            ' '}
                                                    </Typography>
                                                    <Typography
                                                        style={{
                                                            fontSize: '14px',
                                                        }}
                                                    >
                                                        {value?.contactNumber ??
                                                            ' '}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                            <Typography
                                                style={{
                                                    fontWeight: 600,
                                                    display: 'flex',
                                                    flex: '1 1 20%',
                                                    fontSize: '15px',
                                                }}
                                            >
                                                {value?.hubName ?? ' '}
                                            </Typography>
                                        </>
                                    )}
                                    {!props.flowType &&
                                        !!value?.shiftTimings?.length > 0 && (
                                            <Box
                                                style={{
                                                    display: 'flex',
                                                    flex: '1 1 20%',
                                                }}
                                            >
                                                <CustomDropdown
                                                    title={value?.shiftTimings
                                                        ?.map(
                                                            (shift) =>
                                                                shift?.shortName,
                                                        )
                                                        ?.join(' | ')}
                                                    buttonStyles={{
                                                        border: 'none',
                                                        backgroundColor:
                                                            'transparent',
                                                        fontSize: 14,
                                                        textAlign: 'left',
                                                    }}
                                                    options={getShiftTimingsOptions(
                                                        value,
                                                    )}
                                                />
                                            </Box>
                                        )}

                                    <Typography
                                        style={{
                                            fontWeight: 600,
                                            display: 'flex',
                                            flex: '0 1 12.5%',
                                            fontSize: '15px',
                                        }}
                                    >
                                        {value?.weekOff
                                            ? ''
                                            : value?.attendanceHours &&
                                              value?.punchIn?.lateBy
                                            ? `${value?.attendanceHours} [${value?.punchIn?.lateBy}]`
                                            : value?.attendanceHours
                                            ? value?.attendanceHours
                                            : ' '}
                                    </Typography>
                                    <Box
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        {!!value?.updatedKm ? (
                                            <Typography
                                                style={{
                                                    fontWeight: 700,
                                                    fontSize: '10px',
                                                    border: '1px solid black',
                                                    borderRadius: 8,
                                                    minWidth: '70px',
                                                    color: '#525050',
                                                    textAlign: 'center',
                                                    padding: '2px',
                                                    backgroundColor:
                                                        'rgba(82, 80, 80, 0.04)',
                                                }}
                                            >
                                                KM Updated
                                            </Typography>
                                        ) : (
                                            <Typography
                                                style={{
                                                    fontWeight: 700,
                                                    fontSize: '10px',
                                                    border: '0px',
                                                    borderRadius: 8,
                                                    minWidth: '70px',
                                                    color: '#525050',
                                                    textAlign: 'center',
                                                    padding: '2px',
                                                }}
                                            ></Typography>
                                        )}
                                    </Box>
                                </Box>

                                {/* <Box
                                            style={{
                                                display: 'flex',
                                                flex: '1 1 45%',
                                                flexDirection: 'row',
                                                // justifyContent: 'space-around',
                                            }}
                                        > */}
                                <ListItemSecondaryAction
                                    style={{
                                        width: '38%',
                                    }}
                                >
                                    <Box
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Tooltip
                                            title="Approval"
                                            placement="top"
                                        >
                                            <IconButton
                                                style={
                                                    permissions.auth &&
                                                    (value?.punchIn?.status ===
                                                        'PENDING_APPROVAL' ||
                                                        value?.punchOut
                                                            ?.status ===
                                                            'PENDING_APPROVAL')
                                                        ? {
                                                              padding: 0,
                                                          }
                                                        : {
                                                              opacity: 0.3,
                                                              padding: 0,
                                                          }
                                                }
                                                onClick={() => {
                                                    setReapproveModalOpen(true);

                                                    setSelectedItemData({
                                                        ...value,
                                                        staffId:
                                                            value?.staffId ??
                                                            props.staffInfo.id,
                                                    });
                                                }}
                                                disabled={
                                                    !!permissions.auth
                                                        ? !(
                                                              value?.punchIn
                                                                  ?.status ===
                                                                  'PENDING_APPROVAL' ||
                                                              value?.punchOut
                                                                  ?.status ===
                                                                  'PENDING_APPROVAL'
                                                          )
                                                        : !permissions.auth
                                                }
                                            >
                                                <PersonTickIcon size="small" />
                                            </IconButton>
                                        </Tooltip>

                                        <Button
                                            startIcon={
                                                <PunchInIcon
                                                    style={{
                                                        width: '24px',
                                                        height: '24px',
                                                    }}
                                                />
                                            }
                                            className={
                                                classes.buttonDefaultStyles
                                            }
                                            onClick={async () => {
                                                await setSelectedItemData({
                                                    ...value,
                                                    staffId:
                                                        value?.staffId ??
                                                        props.staffInfo.id,
                                                    dateEpochMillis:
                                                        value?.dateEpochMillis ??
                                                        props.selectedDate,
                                                });
                                                setAddInTimeModalOpen(true);
                                            }}
                                            style={
                                                !!value?.punchIn?.timeDisplay &&
                                                !value?.weekOff
                                                    ? {
                                                          // CHECK : AVAIL - Add BgColor,
                                                          backgroundColor:
                                                              'rgba(76, 175, 80, 0.16)',
                                                          border: '0px',
                                                      }
                                                    : !permissions.create ||
                                                      value?.weekOff
                                                    ? {
                                                          // CHECK: Disabled - add opacity
                                                          opacity: 0.3,
                                                          cursor: 'not-allowed',
                                                      }
                                                    : {}
                                            }
                                            disabled={
                                                true
                                                // !permissions.create ||
                                                // value?.weekOff
                                            }
                                            disableElevation
                                        >
                                            <Typography
                                                className={classes.buttonText}
                                                style={
                                                    value?.punchIn
                                                        ?.timeDisplay &&
                                                    !value?.weekOff
                                                        ? {
                                                              // CHECK: AVAIL - Add color
                                                              color: '#4caf50',
                                                          }
                                                        : {}
                                                }
                                            >
                                                {value?.punchIn?.timeDisplay &&
                                                !value?.weekOff
                                                    ? value?.punchIn
                                                          ?.timeDisplay
                                                    : 'Punch In'}
                                            </Typography>
                                        </Button>

                                        <Button
                                            className={
                                                classes.buttonDefaultStyles
                                            }
                                            startIcon={
                                                <PunchOutIcon
                                                    style={{
                                                        width: '24px',
                                                        height: '24px',
                                                    }}
                                                />
                                            }
                                            onClick={() => {
                                                setSelectedItemData({
                                                    ...value,
                                                    staffId:
                                                        value?.staffId ??
                                                        props.staffInfo.id,
                                                    dateEpochMillis:
                                                        value?.dateEpochMillis ??
                                                        props.selectedDate,
                                                });
                                                setAddOutTimeModalOpen(true);
                                            }}
                                            disabled={
                                                true
                                                // (permissions.create
                                                //     ? !value?.punchIn
                                                //           ?.timeDisplay
                                                //     : !permissions.create) ||
                                                // value?.weekOff
                                            }
                                            style={
                                                !permissions.create ||
                                                value?.status === 'Absent' ||
                                                value?.weekOff
                                                    ? {
                                                          // CHECK: Disabled - add opacity
                                                          opacity: 0.3,
                                                          cursor: 'not-allowed',
                                                      }
                                                    : !!value?.punchOut
                                                          ?.timeDisplay
                                                    ? {
                                                          // CHECK : AVAIL - Add BgColor,
                                                          border: '0px',
                                                          backgroundColor:
                                                              'rgba(51, 77, 110, 0.16)',
                                                      }
                                                    : {}
                                            }
                                            disableElevation
                                        >
                                            <Typography
                                                className={classes.buttonText}
                                                style={{
                                                    color: '#334d6e',
                                                }}
                                            >
                                                {(value?.punchOut?.status ===
                                                    'APPROVED' ||
                                                    value?.punchOut?.status ===
                                                        'PENDING_APPROVAL') &&
                                                !value?.weekOff
                                                    ? value?.punchOut
                                                          ?.timeDisplay
                                                    : 'Punch Out'}
                                                {/* Punch Out */}
                                                {/* CHECK: AVAIL - Add timeDisplay */}
                                                {/* 09:00 PM */}
                                            </Typography>
                                        </Button>

                                        <Box
                                            className={
                                                classes.buttonDefaultStyles
                                            }
                                            style={
                                                value?.status === 'Absent'
                                                    ? {
                                                          display: 'flex',
                                                          alignItems: 'center',
                                                          justifyContent:
                                                              'center',
                                                          border: '0px',
                                                          //   backgroundColor:
                                                          //       'rgba(244, 67, 54, 0.16)',
                                                      }
                                                    : permissions.delete
                                                    ? {
                                                          display: 'flex',
                                                          alignItems: 'center',
                                                          justifyContent:
                                                              'center',
                                                          border: '0px',

                                                          // CHECK: if(absent then remove border and add this)
                                                      }
                                                    : {
                                                          opacity: 0.3,
                                                          cursor: 'not-allowed',
                                                          zIndex: 10,
                                                          border: '0px',
                                                      }
                                            }
                                            disabled={
                                                permissions.delete
                                                    ? value?.status === 'Absent'
                                                    : !permissions.delete
                                            }
                                        >
                                            <CustomDropdown
                                                title={
                                                    value?.weekOff
                                                        ? 'Week Off'
                                                        : 'Absent'
                                                }
                                                optionStyles={
                                                    value?.status === 'Absent'
                                                        ? {
                                                              backgroundColor:
                                                                  'rgba(244, 67, 54, 0.16)',
                                                              color: '#f44336',
                                                              textTransform:
                                                                  'none',
                                                              //   padding:
                                                              //       '4px 4px 4px 8px !important',
                                                          }
                                                        : value?.weekOff
                                                        ? {
                                                              backgroundColor:
                                                                  'rgba(255, 152, 0, 0.16)',
                                                              color: '#ff9800',
                                                              textTransform:
                                                                  'none',
                                                          }
                                                        : {}
                                                }
                                                expandMoreButtonStyles={{}}
                                                styledMenuItemStyles={{
                                                    margin: '0 8px',
                                                    color: '#767b84',
                                                    height: '36px !important',
                                                    width: '100px',
                                                    fontSize: '14px',
                                                    border: '0 !important',
                                                    borderRadius: '8px',
                                                    '&:hover': {
                                                        backgroundColor:
                                                            'rgba(244, 67, 54, 0.08)',
                                                        color: '#f44336',
                                                        borderRadius: '8px',
                                                    },
                                                }}
                                                buttonStyles={
                                                    value?.status === 'Absent'
                                                        ? {
                                                              fontWeight: 600,
                                                              border: '1px solid #F44336',
                                                              color: '#F44336',
                                                              padding:
                                                                  '0px !important',
                                                              width: '120px',
                                                          }
                                                        : value?.weekOff
                                                        ? {
                                                              fontWeight: 600,
                                                              border: '1px solid #FF9800',
                                                              color: '#FF9800',
                                                              padding:
                                                                  '0px !important',
                                                              width: '120px',
                                                          }
                                                        : {
                                                              fontWeight: 600,
                                                              border: '1px solid #2B2B2B',
                                                              color: '#2B2B2B',
                                                              padding:
                                                                  '0px !important',
                                                              width: '120px',
                                                          }
                                                }
                                                options={[
                                                    {
                                                        item: 'Absent',
                                                        onSuccess: () => {
                                                            // On absent click
                                                            // alert('Absent Clicked');

                                                            setSelectedItemData(
                                                                {
                                                                    ...value,
                                                                    staffId:
                                                                        value?.staffId ??
                                                                        props
                                                                            .staffInfo
                                                                            .id,
                                                                    name:
                                                                        value?.name ??
                                                                        props
                                                                            .staffInfo
                                                                            .name,

                                                                    dateEpochMillis:
                                                                        value?.dateEpochMillis ??
                                                                        props.selectedDate,
                                                                },
                                                            );
                                                            setAbsentModalOpen(
                                                                true,
                                                            );
                                                        },

                                                        disabled:
                                                            permissions?.delete
                                                                ? value?.status ===
                                                                  'Absent'
                                                                : !permissions.delete,
                                                    },
                                                    {
                                                        item: 'Week Off',
                                                        onSuccess: () => {
                                                            dispatchMarkWeekOff(
                                                                {
                                                                    staffId:
                                                                        value?.staffId ??
                                                                        props
                                                                            .staffInfo
                                                                            .id,
                                                                    data: {
                                                                        dateEpochMillis:
                                                                            value?.dateEpochMillis?.valueOf() ??
                                                                            moment(
                                                                                props.selectedDate,
                                                                            ).valueOf(),
                                                                    },
                                                                },
                                                                (resp) => {
                                                                    console.log(
                                                                        'response',
                                                                        resp,
                                                                    );
                                                                    getAllAttendanceInfo();
                                                                },
                                                                (err) => {
                                                                    console.log(
                                                                        'error',
                                                                        err,
                                                                    );
                                                                },
                                                            );
                                                        },
                                                        disabled:
                                                            value?.weekOff,
                                                    },
                                                ]}
                                            />
                                        </Box>

                                        <Box
                                            style={{
                                                display: 'flex',
                                                justifySelf: 'flex-end',
                                            }}
                                        >
                                            <CustomDropdown
                                                // style={{
                                                //     width: '500px',
                                                // }}
                                                options={[
                                                    {
                                                        item: 'View Activity',
                                                        onSuccess: () => {
                                                            if (value.id) {
                                                                dispatchGetTimelineActivity(
                                                                    {
                                                                        staffId:
                                                                            value?.staffId ??
                                                                            props
                                                                                .staffInfo
                                                                                .id,
                                                                        attendanceId:
                                                                            value?.id,
                                                                    },
                                                                    () => {
                                                                        console.log(
                                                                            'TimelineActivity SUCCESS',
                                                                        );
                                                                        // TODO: Move these two inside onSuccess of getTimelineActivity
                                                                        setActivityModalOpen(
                                                                            true,
                                                                        );
                                                                        setSelectedItemData(
                                                                            {
                                                                                ...value,
                                                                                staffId:
                                                                                    value?.staffId ??
                                                                                    props
                                                                                        .staffInfo
                                                                                        .id,
                                                                            },
                                                                        );
                                                                    },
                                                                    () => {
                                                                        console.log(
                                                                            'TimelineActivity FAILED',
                                                                        );
                                                                    },
                                                                );
                                                            } else {
                                                                showMessage(
                                                                    'Please Record the Activity',
                                                                    'error',
                                                                );
                                                            }
                                                        },
                                                        disabled:
                                                            value?.weekOff ||
                                                            !value?.id,
                                                    },
                                                    {
                                                        item: 'Update Distance',
                                                        disabled:
                                                            !(
                                                                value?.punchIn
                                                                    ?.timeDisplay &&
                                                                value?.punchOut
                                                                    ?.timeDisplay
                                                            ) || value?.weekOff,
                                                        onSuccess: () =>
                                                            handleUpdateDistance(
                                                                value,
                                                            ),
                                                    },
                                                ]}
                                            />
                                        </Box>
                                    </Box>
                                </ListItemSecondaryAction>
                                {/* </Box> */}
                            </ListItemLink>
                        );
                    })}
                </Box>
                {addInTimeModalOpen && (
                    <AddInOutTimeModal
                        open={addInTimeModalOpen}
                        enumType={'in'}
                        data={selectedItemData}
                        onClose={() => {
                            console.log('Closed');
                            setAddInTimeModalOpen(false);
                            getAllAttendanceInfo();
                        }}
                        flowType={props?.flowType}
                    />
                )}
                {addOutTimeModalOpen && (
                    <AddInOutTimeModal
                        open={addOutTimeModalOpen}
                        enumType={'out'}
                        data={selectedItemData}
                        onClose={() => {
                            console.log('Closed');
                            setAddOutTimeModalOpen(false);
                            getAllAttendanceInfo();
                        }}
                        flowType={props?.flowType}
                    />
                )}

                {activityModalOpen && (
                    <AttendanceActivityModal
                        open={activityModalOpen}
                        enumType={'in'}
                        data={selectedItemData}
                        onClose={() => {
                            setActivityModalOpen(false);
                            // getAllAttendanceInfo();
                        }}
                    />
                )}

                {reapproveModalOpen && (
                    <ApprovePunchModal
                        open={reapproveModalOpen}
                        data={selectedItemData}
                        enumType={
                            selectedItemData.punchIn.status ===
                            'PENDING_APPROVAL'
                                ? 'in'
                                : 'out'
                        }
                        onClose={() => {
                            setReapproveModalOpen(false);
                            getAllAttendanceInfo();
                        }}
                    />
                )}

                {absentModalOpen && (
                    <MarkAbsentModal
                        open={absentModalOpen}
                        data={selectedItemData}
                        onClose={() => {
                            setAbsentModalOpen(false);
                            getAllAttendanceInfo();
                        }}
                    />
                )}

                {updateDistanceModalOpen && (
                    <UpdateDistanceModal
                        open={updateDistanceModalOpen}
                        data={selectedItemData}
                        onClose={() => {
                            setUpdateDistanceModalOpen(false);
                            getAllAttendanceInfo();
                        }}
                    />
                )}
            </List>
        </Box>
    );
};

const mapStateToProps = (state) => ({
    isEnabledXUtil: state?.user?.config?.isEnabledXUtil,
});

const mapDispatchToProps = (dispatch) => ({
    dispatchGetTimelineActivity: (payload, onSuccess, onError) =>
        dispatch(getTimelineActivityData(payload, onSuccess, onError)),
    dispatchGetAttendanceActivityData: (payload, onSuccess, onError) =>
        dispatch(getAttendanceActivityData(payload, onSuccess, onError)),

    dispatchMarkAbsent: (payload, onSuccess, onError) =>
        dispatch(markAbsent(payload, onSuccess, onError)),

    dispatchGetAllAttendanceData: (payload, onSuccess, onError) =>
        dispatch(getAllAttendanceData(payload, onSuccess, onError)),

    dispatchGetSingleStaffAttendanceData: (payload, onSuccess, onError) =>
        dispatch(getSingleStaffAttendanceData(payload, onSuccess, onError)),

    dispatchMarkWeekOff: (payload, onSuccess, onError) =>
        dispatch(weekOff(payload, onSuccess, onError)),
});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )((props) =>
        props?.isEnabledXUtil
            ? AttendanceList(props)
            : AttendanceListContainer(props),
    ),
);

export const AttendanceList = ({
    permissions,
    data,
    isEnabledXUtil,
    dispatchGetTimelineActivity,
    dispatchGetAttendanceActivityData,
    dispatchMarkAbsent,
    dispatchGetSingleStaffAttendanceData,
    dispatchGetAllAttendanceData,
    getAllAttendanceInfo,
    dispatchMarkWeekOff,
    ...props
}) => {
    const classes = useStyles();

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [addInTimeModalOpen, setAddInTimeModalOpen] = useState(false);
    const [addOutTimeModalOpen, setAddOutTimeModalOpen] = useState(false);
    const [updateDistanceModalOpen, setUpdateDistanceModalOpen] =
        useState(false);

    const [selectedItemData, setSelectedItemData] = useState({
        data: {
            dateEpochMillis: moment(props.selectedDate).valueOf(),
        },
    });

    const [activityModalOpen, setActivityModalOpen] = useState(false);
    const [reapproveModalOpen, setReapproveModalOpen] = useState(false);
    const [absentModalOpen, setAbsentModalOpen] = useState(false);

    const [scrollPosition, setScrollPosition] = useState(0);
    const handleScroll = () => {
        const position = window.pageYOffset;
        setScrollPosition(position);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, {
            passive: true,
        });
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        console.log('scroll:', scrollPosition);
    }, [scrollPosition]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleUpdateDistance = (value) => {
        setSelectedItemData(
            value
                ? {
                      dateEpochMillis:
                          value.dateEpochMillis?.valueOf() ??
                          moment(props.selectedDate).valueOf(),
                      name: value?.name,
                      hubName: value?.hubName,
                      gpsReading: value.userDistance?.distance ?? 0,
                      correctedGpsReading:
                          value.userDistance?.correctedDistance,
                      punchIn: {
                          kmReading: value.punchIn?.kmReading,
                          kmS3ImageUrl: value.punchIn?.kmS3ImageUrl,
                      },
                      punchOut: {
                          kmReading: value.punchOut?.kmReading,
                          kmS3ImageUrl: value.punchOut?.kmS3ImageUrl,
                      },
                      staffId: value.staffId ?? props.staffInfo.id ?? null,
                      reason: value.userDistance?.reason ?? null,
                      notes: value.userDistance?.notes ?? null,
                  }
                : {
                      dateEpochMillis: moment(props.selectedDate).valueOf(),
                      name: null,
                      hubName: null,
                      gpsReading: 0,
                      odometerReading: 0,
                      staffId: props.staffInfo.id ?? null,
                      reason: null,
                      notes: null,
                  },
        );
        setUpdateDistanceModalOpen(true);
    };

    React.useEffect(() => {
        setPage(0);
    }, [data]);

    const getShiftTimingsOptions = (val) => {
        let optionsArr = [];
        val?.shiftTimings?.map((shiftTiming, index) =>
            optionsArr.push({
                style:
                    index == shiftTiming?.length - 1
                        ? {
                              backgroundColor: 'rgba(67, 65, 158, 0.07)',
                              padding: '0px',
                              borderRadius: '8px',
                          }
                        : {
                              backgroundColor: 'rgba(67, 65, 158, 0.07)',
                              padding: '0 8px',
                              borderRadius: '8px',
                              marginBottom: 8,
                          },
                render: () => {
                    return (
                        <div
                            style={{
                                width: '100%',
                            }}
                        >
                            <>
                                <div
                                    style={{
                                        fontFamily: 'Nunito',
                                        fontSize: '12px',
                                        fontWeight: '600',
                                        color: '#252525',
                                    }}
                                >
                                    {shiftTiming?.name ?? ''}
                                </div>
                                <div
                                    style={{
                                        fontFamily: 'Nunito',
                                        fontSize: '10px',
                                        fontWeight: 'normal',
                                        color: '#252525',
                                    }}
                                >
                                    {`${shiftTiming?.startTime} - ${shiftTiming?.endTime}`}
                                </div>
                            </>
                        </div>
                    );
                },
                onSuccess: () => {},
                disabled: !permissions?.delete,
            }),
        );
        return optionsArr;
    };

    const handleActivityAction = (value) => {
        if (value?.weekOff) {
            showMessage('Staff is Marked for Week Off', 'error');
        } else {
            // if (value.hubShiftMappings?.length) {
            dispatchGetAttendanceActivityData(
                {
                    staffId: value?.staffId ?? props.staffInfo.id,
                    // attendanceId: value?.id,
                    attendanceId: moment(
                        !!props.flowType
                            ? value?.dateEpochMillis ?? props.selectedDate
                            : props.selectedDate,
                    ).format('YYYYMMDD'),
                },
                () => {
                    console.log('TimelineActivity SUCCESS');
                    setActivityModalOpen(true);
                    setSelectedItemData({
                        ...value,
                        staffId: value?.staffId ?? props.staffInfo.id,
                        dateEpochMillis:
                            value?.dateEpochMillis ?? props.selectedDate,
                    });
                },
                () => {
                    console.log('TimelineActivity FAILED');
                },
            );
            // } else {
            //     showMessage(
            //         'Please Record the Activity',
            //         'error',
            //     );
            // }
        }
        // }
        // props.history.push(
        //     `/staff/details/${phoneNumber}`,
        // );
    };

    return (
        <List component="nav">
            {!props?.flowType && (
                <ListItem className={classes.listItemHeader}>
                    <Box
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '100%',
                            color: '#818181',
                        }}
                    >
                        <Box
                            style={{
                                width: '21%',
                            }}
                        >
                            Name
                        </Box>

                        <Box
                            style={{
                                width: '26%',
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'baseline',
                            }}
                        >
                            Shift{' '}
                            <Typography
                                className={`modal-subtitle`}
                                variant="inherit"
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    fontSize: '10px',
                                    marginLeft: 8,
                                }}
                                component="span"
                            >
                                {Object.values(HUB_TYPE_ENUM).map(
                                    (hub, idx) => {
                                        const showDot =
                                            idx <
                                            Object.values(HUB_TYPE_ENUM)
                                                ?.length -
                                                2;
                                        return (
                                            !!hub.value && (
                                                <>
                                                    <span
                                                        style={{
                                                            color: hub?.color,
                                                        }}
                                                    >
                                                        {hub?.value ===
                                                        HUB_TYPE_ENUM.FOOD.value
                                                            ? 'HYPERLOCAL'
                                                            : hub.value}
                                                    </span>
                                                    {showDot && (
                                                        <Box
                                                            style={{
                                                                padding: 7,
                                                                paddingTop: 4.5,
                                                            }}
                                                        >
                                                            <Box
                                                                style={{
                                                                    width: 4,
                                                                    height: 4,
                                                                    backgroundColor:
                                                                        'rgba(0, 0, 0, 0.16)',
                                                                    borderRadius: 50,
                                                                }}
                                                            />
                                                        </Box>
                                                    )}{' '}
                                                </>
                                            )
                                        );
                                    },
                                )}
                            </Typography>
                        </Box>
                        {/* <Box
                            style={{
                                width: '19%',
                            }}
                        >
                            {'Hubs & Shifts'}
                        </Box> */}
                        <Box style={{ width: '14.5%' }}>Total Work Hrs</Box>
                        {/* <Box style={{ width: '10%' }}>Km</Box> */}
                        {/* <Box style={{ width: '25%' }}>Action</Box> */}
                    </Box>
                </ListItem>
            )}
            <Box>
                {data?.map((value, index) => {
                    let isPunchInActionDisabled = false;
                    let isHyperlocalShiftActive = true;
                    if (!!isEnabledXUtil) {
                        const currentTime = moment();
                        const currentShift = value?.hubShiftMappings?.find(
                            (hub) => {
                                return hub.shiftLineItems.find((shift) => {
                                    return moment(currentTime).isBetween(
                                        shift.startTimeEpochMillis,
                                        shift.endTimeEpochMillis,
                                        'HH:mm',
                                    );
                                });
                            },
                        );
                        console.log('currentShift', currentShift);
                        if (!!currentShift) {
                            // value.totalHubCount = 1;
                            // value.totalShiftCount = 1;
                            // value.hubShiftMappings = [currentShift];
                            if (
                                currentShift?.primaryOperation ===
                                HUB_TYPE_ENUM.ECOM.value
                            ) {
                                isHyperlocalShiftActive = false;
                            }
                            // action enabled
                            isPunchInActionDisabled = false;
                        } else {
                            // action disabled
                            isPunchInActionDisabled = true;
                            isHyperlocalShiftActive = true;
                        }
                    } else {
                        isPunchInActionDisabled =
                            !permissions.create || value?.weekOff;
                    }
                    return (
                        <ListItemLink
                            className={classes.listItem}
                            onClick={() => {
                                if (!!value.hubShiftMappings?.length)
                                    handleActivityAction(value);
                                else {
                                    showMessage('No Shift Assigned', 'error');
                                }
                            }}
                            key={index}
                            style={{
                                ...(index % 2 && {
                                    backgroundColor: 'rgba(241, 243, 246, 1)',
                                }),
                            }}
                        >
                            {props?.flowType ? (
                                <Box
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        width: '20%',
                                    }}
                                >
                                    <Typography
                                        style={{
                                            fontWeight: 600,
                                            display: 'flex',
                                        }}
                                    >
                                        {value.dateDisplay
                                            ? value.dateDisplay
                                            : ''}
                                    </Typography>
                                </Box>
                            ) : (
                                <>
                                    <Box
                                        style={{
                                            width: '20.5%',
                                        }}
                                        // className={classes.tableCell}
                                    >
                                        <Box
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                flexWrap: 'wrap',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Typography
                                                style={{
                                                    wordBreak: 'break-all',
                                                    fontWeight: 600,
                                                    marginRight: 5,
                                                }}
                                            >
                                                {value.name ?? '-'}
                                            </Typography>
                                        </Box>
                                        <Box
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                flexWrap: 'wrap',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Typography
                                                style={{
                                                    // width: '40%',
                                                    fontSize: '12px',
                                                    marginRight: '4px',
                                                }}
                                            >
                                                {value?.displayText ?? '-'}
                                            </Typography>
                                            <Box style={{ padding: 7 }}>
                                                <Box
                                                    style={{
                                                        width: 4,
                                                        height: 4,
                                                        backgroundColor:
                                                            'rgba(0, 0, 0, 0.16)',
                                                        borderRadius: 50,
                                                    }}
                                                />
                                            </Box>{' '}
                                            <Typography
                                                style={{
                                                    fontSize: '12px',
                                                    marginLeft: '4px',
                                                }}
                                            >
                                                {value.contactNumber ?? '-'}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </>
                            )}
                            <Box
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-start',
                                    width: '27%',
                                }}
                            >
                                <ShiftColumnItemPopper
                                    data={value}
                                    selectedItem={selectedItemData}
                                    setSelectedItem={setSelectedItemData}
                                    limit={6}
                                />
                            </Box>
                            <Box
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    width: props.flowType ? '7%' : '5.5%',
                                }}
                            >
                                <Typography
                                    style={{
                                        fontWeight: 600,
                                        fontSize: '15px',
                                        width: '100%',
                                    }}
                                >
                                    {value?.weekOff
                                        ? ''
                                        : !!(
                                              value?.attendanceHours &&
                                              value?.punchIn?.lateBy
                                          )
                                        ? `${value?.attendanceHours} [${value?.punchIn?.lateBy}]`
                                        : value?.attendanceHours
                                        ? value?.attendanceHours
                                        : ' '}
                                </Typography>
                            </Box>
                            <Box
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    width: '10%',
                                }}
                            >
                                {!!value?.updatedKm ? (
                                    <Typography
                                        style={{
                                            fontWeight: 700,
                                            fontSize: '10px',
                                            border: '1px solid black',
                                            borderRadius: 8,
                                            minWidth: '70px',
                                            color: '#525050',
                                            textAlign: 'center',
                                            padding: '2px',
                                            backgroundColor:
                                                'rgba(82, 80, 80, 0.04)',
                                        }}
                                    >
                                        KM Updated
                                    </Typography>
                                ) : (
                                    <Typography
                                        style={{
                                            fontWeight: 700,
                                            fontSize: '10px',
                                            border: '0px',
                                            borderRadius: 8,
                                            minWidth: '70px',
                                            color: '#525050',
                                            textAlign: 'center',
                                            padding: '2px',
                                        }}
                                    ></Typography>
                                )}
                            </Box>
                            <ListItemSecondaryAction
                                style={{
                                    width: 'auto',
                                }}
                            >
                                <Box
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        flexFlow: 'wrap',
                                    }}
                                >
                                    <Tooltip title="Approval" placement="top">
                                        <IconButton
                                            style={
                                                !!permissions.auth &&
                                                ((!!value?.punchIn?.status &&
                                                    value?.punchIn?.status ===
                                                        'PENDING_APPROVAL') ||
                                                    (!!value?.punchOut
                                                        ?.status &&
                                                        value?.punchOut
                                                            ?.status ===
                                                            'PENDING_APPROVAL'))
                                                    ? {
                                                          padding: 0,
                                                          marginRight: '8px',
                                                      }
                                                    : {
                                                          opacity: 0.3,
                                                          padding: 0,
                                                          marginRight: '8px',
                                                      }
                                            }
                                            onClick={() => {
                                                setReapproveModalOpen(true);

                                                setSelectedItemData({
                                                    ...value,
                                                    staffId:
                                                        value?.staffId ??
                                                        props.staffInfo.id,
                                                });
                                            }}
                                            disabled={
                                                !!permissions.auth
                                                    ? !(
                                                          !!value?.punchIn
                                                              ?.status &&
                                                          value?.punchIn
                                                              ?.status ===
                                                              'PENDING_APPROVAL'
                                                      ) ||
                                                      !(
                                                          !!value?.punchOut
                                                              ?.status &&
                                                          value?.punchOut
                                                              ?.status ===
                                                              'PENDING_APPROVAL'
                                                      )
                                                    : !permissions.auth
                                            }
                                        >
                                            <PersonTickIcon size="small" />
                                        </IconButton>
                                    </Tooltip>

                                    <Button
                                        startIcon={
                                            !(
                                                isPunchInActionDisabled ||
                                                isHyperlocalShiftActive
                                            ) && (
                                                <PunchInV2Icon
                                                    style={{
                                                        width: '24px',
                                                        height: '24px',
                                                    }}
                                                    color={'#4CAF50'}
                                                />
                                            )
                                        }
                                        className={classes.buttonDefaultStyles}
                                        onClick={async () => {
                                            await setSelectedItemData({
                                                ...value,
                                                ...checkCurrentAvailableShift(
                                                    value,
                                                ),
                                                staffId:
                                                    value?.staffId ??
                                                    props.staffInfo.id,
                                                dateEpochMillis:
                                                    value?.dateEpochMillis ??
                                                    props.selectedDate,
                                            });
                                            setAddInTimeModalOpen(true);
                                        }}
                                        style={
                                            !!value?.punchIn?.timeDisplay &&
                                            !value?.weekOff
                                                ? {
                                                      // CHECK : AVAIL - Add BgColor,
                                                      backgroundColor:
                                                          'rgba(76, 175, 80, 0.16)',
                                                      border: '0px',
                                                      marginRight: '8px',
                                                  }
                                                : !!(
                                                      isPunchInActionDisabled ||
                                                      isHyperlocalShiftActive
                                                  )
                                                ? {
                                                      // CHECK: Disabled - add opacity
                                                      opacity: 0.3,
                                                      cursor: 'not-allowed',
                                                      marginRight: '8px',
                                                  }
                                                : { marginRight: '8px' }
                                        }
                                        disabled={
                                            !!(
                                                isPunchInActionDisabled ||
                                                isHyperlocalShiftActive
                                            )
                                        }
                                        disableElevation
                                    >
                                        <Typography
                                            className={classes.buttonText}
                                            style={
                                                value?.punchIn?.timeDisplay &&
                                                !value?.weekOff
                                                    ? {
                                                          // CHECK: AVAIL - Add color
                                                          color: '#4caf50',
                                                      }
                                                    : {}
                                            }
                                        >
                                            {!!value?.punchIn?.timeDisplay &&
                                            !value?.weekOff
                                                ? value?.punchIn?.timeDisplay
                                                : !!(
                                                      isPunchInActionDisabled ||
                                                      isHyperlocalShiftActive
                                                  )
                                                ? 'In Time'
                                                : 'Punch In'}
                                        </Typography>
                                    </Button>

                                    <Button
                                        className={classes.buttonDefaultStyles}
                                        startIcon={
                                            !(
                                                (permissions.create
                                                    ? !value?.punchIn
                                                          ?.timeDisplay
                                                    : !permissions.create) ||
                                                value?.weekOff ||
                                                isHyperlocalShiftActive
                                            ) && (
                                                <PunchOutIcon
                                                    style={{
                                                        width: '24px',
                                                        height: '24px',
                                                    }}
                                                />
                                            )
                                        }
                                        onClick={() => {
                                            setSelectedItemData({
                                                ...value,
                                                ...checkCurrentAvailableShift(
                                                    value,
                                                ),
                                                staffId:
                                                    value?.staffId ??
                                                    props.staffInfo.id,
                                                dateEpochMillis:
                                                    value?.dateEpochMillis ??
                                                    props.selectedDate,
                                            });
                                            setAddOutTimeModalOpen(true);
                                        }}
                                        disabled={
                                            (permissions.create
                                                ? !value?.punchIn?.timeDisplay
                                                : !permissions.create) ||
                                            value?.weekOff ||
                                            isHyperlocalShiftActive
                                        }
                                        style={
                                            !permissions.create ||
                                            value?.status === 'Absent' ||
                                            value?.weekOff
                                                ? {
                                                      // CHECK: Disabled - add opacity
                                                      opacity: 0.3,
                                                      cursor: 'not-allowed',
                                                      marginRight: '8px',
                                                  }
                                                : !!value?.punchOut?.timeDisplay
                                                ? {
                                                      // CHECK : AVAIL - Add BgColor,
                                                      border: '0px',
                                                      backgroundColor:
                                                          'rgba(51, 77, 110, 0.16)',
                                                      marginRight: '8px',
                                                  }
                                                : { marginRight: '8px' }
                                        }
                                        disableElevation
                                    >
                                        <Typography
                                            className={classes.buttonText}
                                            style={{
                                                color: '#334d6e',
                                            }}
                                        >
                                            {!value?.weekOff &&
                                            !!value?.punchOut?.timeDisplay
                                                ? value?.punchOut?.timeDisplay
                                                : (permissions.create
                                                      ? !value?.punchIn
                                                            ?.timeDisplay
                                                      : !permissions.create) ||
                                                  value?.weekOff
                                                ? 'Out Time'
                                                : 'Punch Out'}
                                            {/* Punch Out */}
                                            {/* CHECK: AVAIL - Add timeDisplay */}
                                            {/* 09:00 PM */}
                                        </Typography>
                                    </Button>

                                    <Box
                                        className={classes.buttonDefaultStyles}
                                        style={
                                            value?.status === 'Absent'
                                                ? {
                                                      display: 'flex',
                                                      alignItems: 'center',
                                                      justifyContent: 'center',
                                                      border: '0px',
                                                      marginRight: '8px',
                                                      //   backgroundColor:
                                                      //       'rgba(244, 67, 54, 0.16)',
                                                  }
                                                : permissions.delete
                                                ? {
                                                      display: 'flex',
                                                      alignItems: 'center',
                                                      justifyContent: 'center',
                                                      border: '0px',
                                                      marginRight: '8px',
                                                      // CHECK: if(absent then remove border and add this)
                                                  }
                                                : {
                                                      opacity: 0.3,
                                                      cursor: 'not-allowed',
                                                      zIndex: 10,
                                                      border: '0px',
                                                      marginRight: '8px',
                                                  }
                                        }
                                    >
                                        <CustomDropdown
                                            title={
                                                value?.weekOff
                                                    ? 'Week Off'
                                                    : 'Absent'
                                            }
                                            disabled={
                                                !!permissions.delete
                                                    ? !!(
                                                          isHyperlocalShiftActive ||
                                                          value?.status ===
                                                              'Absent'
                                                      )
                                                    : !permissions.delete
                                            }
                                            optionStyles={
                                                value?.status === 'Absent'
                                                    ? {
                                                          backgroundColor:
                                                              'rgba(244, 67, 54, 0.16)',
                                                          color: '#f44336',
                                                          textTransform: 'none',
                                                          //   padding:
                                                          //       '4px 4px 4px 8px !important',
                                                      }
                                                    : value?.weekOff
                                                    ? {
                                                          backgroundColor:
                                                              'rgba(255, 152, 0, 0.16)',
                                                          color: '#ff9800',
                                                          textTransform: 'none',
                                                      }
                                                    : {}
                                            }
                                            expandMoreButtonStyles={{}}
                                            styledMenuItemStyles={{
                                                margin: '0 8px',
                                                color: '#767b84',
                                                height: '36px !important',
                                                width: '100px',
                                                fontSize: '14px',
                                                border: '0 !important',
                                                borderRadius: '8px',
                                                '&:hover': {
                                                    backgroundColor:
                                                        'rgba(244, 67, 54, 0.08)',
                                                    color: '#f44336',
                                                    borderRadius: '8px',
                                                },
                                            }}
                                            buttonProps={{
                                                disabled:
                                                    !!isHyperlocalShiftActive,
                                            }}
                                            buttonStyles={
                                                value?.status === 'Absent'
                                                    ? {
                                                          fontWeight: 600,
                                                          //   border: '1px solid #F44336',
                                                          border: 'none',
                                                          color: '#F44336',
                                                          padding:
                                                              '0px !important',
                                                          width: '120px',
                                                      }
                                                    : value?.weekOff
                                                    ? {
                                                          fontWeight: 600,
                                                          border: '1px solid #FF9800',
                                                          color: '#FF9800',
                                                          padding:
                                                              '0px !important',
                                                          width: '120px',
                                                      }
                                                    : {
                                                          fontWeight: 600,
                                                          border: '1px solid #2B2B2B',
                                                          color: '#2B2B2B',
                                                          padding:
                                                              '0px !important',
                                                          width: '120px',
                                                          opacity:
                                                              isHyperlocalShiftActive
                                                                  ? 0.6
                                                                  : 1,
                                                      }
                                            }
                                            options={[
                                                {
                                                    item: 'Absent',
                                                    onSuccess: () => {
                                                        // On absent click
                                                        // alert('Absent Clicked');

                                                        setSelectedItemData({
                                                            ...value,
                                                            ...checkCurrentAvailableShift(
                                                                value,
                                                            ),
                                                            staffId:
                                                                value?.staffId ??
                                                                props.staffInfo
                                                                    .id,
                                                            name:
                                                                value?.name ??
                                                                props.staffInfo
                                                                    .name,

                                                            dateEpochMillis:
                                                                value?.dateEpochMillis ??
                                                                props.selectedDate,
                                                        });
                                                        setAbsentModalOpen(
                                                            true,
                                                        );
                                                    },

                                                    disabled:
                                                        !!permissions.delete
                                                            ? !!(
                                                                  isHyperlocalShiftActive ||
                                                                  value?.status ===
                                                                      'Absent'
                                                              )
                                                            : !permissions.delete,
                                                },
                                                {
                                                    item: 'Week Off',
                                                    onSuccess: () => {
                                                        dispatchMarkWeekOff(
                                                            {
                                                                staffId:
                                                                    value?.staffId ??
                                                                    props
                                                                        .staffInfo
                                                                        .id,
                                                                data: {
                                                                    dateEpochMillis:
                                                                        value?.dateEpochMillis?.valueOf() ??
                                                                        moment(
                                                                            props.selectedDate,
                                                                        ).valueOf(),
                                                                },
                                                            },
                                                            (resp) => {
                                                                console.log(
                                                                    'response',
                                                                    resp,
                                                                );
                                                                getAllAttendanceInfo();
                                                            },
                                                            (err) => {
                                                                console.log(
                                                                    'error',
                                                                    err,
                                                                );
                                                            },
                                                        );
                                                    },
                                                    disabled:
                                                        isHyperlocalShiftActive &&
                                                        value?.weekOff,
                                                },
                                            ]}
                                        />
                                    </Box>

                                    <Box
                                        style={{
                                            display: 'flex',
                                            justifySelf: 'flex-end',
                                        }}
                                    >
                                        <CustomDropdown
                                            // style={{
                                            //     width: '500px',
                                            // }}
                                            buttonStyles={{
                                                borderColor: '#2B2B2B',
                                                color: '#2B2B2B',
                                            }}
                                            options={[
                                                {
                                                    item: 'View Activity',
                                                    onSuccess: () => {
                                                        handleActivityAction(
                                                            value,
                                                        );
                                                    },
                                                    disabled: value?.weekOff,
                                                },
                                                {
                                                    item: 'Update Distance',
                                                    disabled:
                                                        isHyperlocalShiftActive ||
                                                        !!(
                                                            !(
                                                                value?.punchIn
                                                                    ?.timeDisplay &&
                                                                value?.punchOut
                                                                    ?.timeDisplay
                                                            ) || value?.weekOff
                                                        ),
                                                    onSuccess: () =>
                                                        handleUpdateDistance(
                                                            value,
                                                        ),
                                                },
                                            ]}
                                        />
                                    </Box>
                                </Box>
                            </ListItemSecondaryAction>
                        </ListItemLink>
                    );
                })}
            </Box>
            {addInTimeModalOpen && (
                <AddInOutTimeModal
                    open={addInTimeModalOpen}
                    enumType={'in'}
                    data={selectedItemData}
                    onClose={() => {
                        console.log('Closed');
                        setAddInTimeModalOpen(false);
                        getAllAttendanceInfo();
                    }}
                    isEnabledXUtil={isEnabledXUtil}
                    flowType={props?.flowType}
                />
            )}
            {addOutTimeModalOpen && (
                <AddInOutTimeModal
                    open={addOutTimeModalOpen}
                    enumType={'out'}
                    data={selectedItemData}
                    onClose={() => {
                        console.log('Closed');
                        setAddOutTimeModalOpen(false);
                        getAllAttendanceInfo();
                    }}
                    isEnabledXUtil={isEnabledXUtil}
                    flowType={props?.flowType}
                />
            )}

            {activityModalOpen && (
                <AttendanceShiftActivityModal
                    open={activityModalOpen}
                    enumType={'in'}
                    data={{
                        ...selectedItemData,
                        dateDisplay: moment(
                            !!props.flowType
                                ? selectedItemData?.dateEpochMillis
                                : props.selectedDate,
                        ).format('DD MMMM YYYY'),
                    }}
                    onClose={() => {
                        setActivityModalOpen(false);
                        // getAllAttendanceInfo();
                    }}
                />
            )}

            {reapproveModalOpen && (
                <ApprovePunchModal
                    open={reapproveModalOpen}
                    data={selectedItemData}
                    enumType={
                        selectedItemData.punchIn.status === 'PENDING_APPROVAL'
                            ? 'in'
                            : 'out'
                    }
                    onClose={() => {
                        setReapproveModalOpen(false);
                        getAllAttendanceInfo();
                    }}
                />
            )}

            {absentModalOpen && (
                <MarkAbsentModal
                    open={absentModalOpen}
                    data={selectedItemData}
                    onClose={() => {
                        setAbsentModalOpen(false);
                        getAllAttendanceInfo();
                    }}
                />
            )}

            {updateDistanceModalOpen && (
                <UpdateDistanceModal
                    open={updateDistanceModalOpen}
                    data={selectedItemData}
                    onClose={() => {
                        setUpdateDistanceModalOpen(false);
                        getAllAttendanceInfo();
                    }}
                />
            )}
        </List>
    );
};
