import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {
    Typography,
    Box,
    TablePagination,
    IconButton,
} from '@material-ui/core/';
import EditIcon from '@material-ui/icons/Edit';
import ThumbnailPreview from '../../../components/ThumbnailPreview';
import { formatCurrency } from '../../../utils/helper';
import moment from 'moment';
import Currency from '../../../components/UI/Currency/Currency';
import CustomDropdown from '../../../components/Dropdown';
import { getSingleStaff } from '../../../store/actions/staffActionCreator';
import AddWorkModal from '../../../components/Modals/AddWorkModal';
import AddAllowanceBonusModal from '../../../components/Modals/AddAllowanceBonusModal';
import AddDeductionModal from '../../../components/Modals/AddDeductionModal';

const StyledTableCell = withStyles((theme) => ({
    head: {
        color: theme.palette.common.white,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '& .MuiTableCell-body': {
            fontWeight: 600,
            fontSize: 14,
            color: '#000000',
        },
        '&:nth-of-type(even)': {
            boxShadow: 'inset 0 -1px 0 0 #ebedf8',
            backgroundColor: '#f2f4f8',
        },
    },
}))(TableRow);

const StyledTableHeader = withStyles((theme) => ({
    root: {
        boxShadow: 'inset 0 -1px 0 0 #ebedf8',
        backgroundColor: '#f2f4f8',
    },
}))(TableHead);

const useStyles = makeStyles({
    tableContainer: {
        border: '1px solid #ebedf8',
        borderRadius: 5,
    },
    table: {
        minWidth: 700,
    },

    tableHeaderText: {
        fontWeight: 'bold',
    },
    tableHeader: {
        background: '#f2f4f8',
    },
    textAlignCenter: {
        textAlign: 'center'
    },
});

const EarningsListContainer = ({
    rows,
    permissions,
    isMonthly,
    dispatchGetSingleStaff,
    fetchEarningsReport,
    payload,
    ...props
}) => {
    const classes = useStyles();
    const [selectedRow, setSelectedRow] = React.useState({});
    const [workStaffModalOpen, setWorkStaffModalOpen] = React.useState(false);
    const [bonusStaffModalOpen, setBonusStaffModalOpen] = React.useState(false);
    const [deductionStaffModalOpen, setDeductionStaffModalOpen] =
        React.useState(false);

    let routeParams = {
        payload,
        source: 'reports',
        tabIndex: 2,
        detailsTabIndex: 1,
    };
    const viewStaffSuccessCallback = (row) => {
        dispatchGetSingleStaff(
            row?.userId,
            (response) => {
                console.log('Get single staff success');
                props.history.push(
                    `/staff/details/${row?.userId}`,
                    routeParams,
                );
            },
            () => {},
        );
    };

    return (
        <Box
            style={{
                width: '100%',
            }}
        >
            <TableContainer className={classes.tableContainer} component={Box}>
                <Table
                    size="small"
                    className={classes.table}
                    aria-label="customized table"
                >
                    <StyledTableHeader>
                        <TableRow>
                            <TableCell style={{}}>
                                <Typography className={classes.tableHeaderText}>
                                    Date
                                </Typography>
                            </TableCell>
                            <TableCell style={{
                                whiteSpace: 'nowrap'
                            }}>
                                <Typography className={classes.tableHeaderText}>
                                    User Name
                                </Typography>
                            </TableCell>
                            <TableCell style={{}}>
                                <Typography className={classes.tableHeaderText}>
                                    Phone Number
                                </Typography>
                            </TableCell>
                            <TableCell style={{}}>
                                <Typography className={classes.tableHeaderText}>
                                    Hub Name
                                </Typography>
                            </TableCell>
                            <TableCell style={{ maxWidth: '100px' }}>
                                <Typography className={classes.tableHeaderText}>
                                    No of Deliveries
                                </Typography>
                            </TableCell>
                            <TableCell style={{ maxWidth: '100px' }}>
                                <Typography
                                    className={classes.tableHeaderText}
                                    style={{
                                        textAlign: 'right',
                                    }}
                                >
                                    Delivery Earnings
                                </Typography>
                            </TableCell>
                            <TableCell style={{}}>
                                <Typography
                                    className={classes.tableHeaderText}
                                    style={{
                                        textAlign: 'right',
                                    }}
                                >
                                    Deduction
                                </Typography>
                            </TableCell>
                            <TableCell style={{}}>
                                <Typography
                                    className={classes.tableHeaderText}
                                    style={{
                                        textAlign: 'right',
                                    }}
                                >
                                    Bonus
                                </Typography>
                            </TableCell>
                            <TableCell style={{}}>
                                <Typography
                                    className={classes.tableHeaderText}
                                    style={{
                                        textAlign: 'right',
                                    }}
                                >
                                    Amount Payable
                                </Typography>
                            </TableCell>
                            <TableCell style={{}}>
                                <Typography
                                    className={classes.tableHeaderText}
                                >
                                    Distance
                                </Typography>
                            </TableCell>
                            {!isMonthly && (
                                <TableCell style={{}}>
                                    <Typography
                                        className={classes.tableHeaderText}
                                    >
                                        Login Minutes
                                    </Typography>
                                </TableCell>
                            )}
                            <TableCell
                            // style={{
                            //     width: '11%',
                            // }}
                            >
                                <Typography className={classes.tableHeaderText}>
                                    Uploads
                                </Typography>
                            </TableCell>
                            <TableCell
                                style={{
                                    width: '1px',
                                }}
                            ></TableCell>
                        </TableRow>
                    </StyledTableHeader>
                    <TableBody>
                        {rows?.map((row, index) => (
                            <StyledTableRow key={index}>
                                <TableCell style={{}}>
                                    {isMonthly
                                        ? moment(row.dateString).format(
                                              'MMMM YYYY',
                                          )
                                        : row.dateString}
                                </TableCell>
                                <TableCell className={classes.textAlignCenter}
                                    component="th"
                                    scope="row"
                                    style={{}}
                                >
                                    {row.userName}
                                </TableCell>
                                <TableCell className={classes.textAlignCenter}
                                    component="th"
                                    scope="row"
                                    style={{}}
                                >
                                    {row.phoneNumber}
                                </TableCell>
                                <TableCell className={classes.textAlignCenter}
                                    component="th"
                                    scope="row"
                                    style={{}}
                                >
                                    {row.hubName}
                                </TableCell>
                                <TableCell className={classes.textAlignCenter} style={{}}>
                                    {row.noOfDeliveries}
                                </TableCell>
                                <TableCell className={classes.textAlignCenter}
                                >
                                    <Currency amount={row.deliveryEarnings} />
                                </TableCell>
                                <TableCell className={classes.textAlignCenter}
                                    component="th"
                                    scope="row"
                                >
                                    <Currency amount={row.deduction} />
                                </TableCell>
                                <TableCell className={classes.textAlignCenter}
                                    style={{
                                        wordWrap: 'break-word',
                                    }}
                                >
                                    <Currency amount={row.bonus} />
                                </TableCell>
                                <TableCell className={classes.textAlignCenter}
                                >
                                    <Currency className={classes.textAlignCenter} amount={row.amountPayable} />
                                </TableCell>
                                <TableCell className={classes.textAlignCenter}
                                >
                                    {row?.distanceKm
                                        ? `${row?.distanceKm} KM`
                                        : '-'}
                                </TableCell>
                                {!isMonthly && (
                                    <TableCell className={classes.textAlignCenter}
                                    >
                                        {`${row?.loginMinutes ?? '-'} `}
                                    </TableCell>
                                )}
                                <TableCell
                                    style={{
                                        minWidth: '11.5vw',
                                        maxWidth: '11.5vw',
                                        // border: '1px solid black',
                                    }}
                                >
                                    <Box>
                                        <ThumbnailPreview
                                            images={row?.docLink ?? []}
                                        />
                                    </Box>
                                </TableCell>
                                {/* <TableCell
                                style={{
                                    padding: 0,
                                    color: 'rgba(43, 43, 43, 0.54)',
                                }}
                                >
                                <EditIcon
                                fontSize={'small'}
                                onClick={() => {
                                    alert('Clicked');
                                }}
                                />
                            </TableCell> */}
                                <TableCell
                                    style={{
                                        width: '100px',
                                    }}
                                >
                                    <CustomDropdown
                                        options={[
                                            {
                                                item: 'Add Work',
                                                onSuccess: async () => {
                                                    await setSelectedRow({
                                                        ...row,
                                                        id: row?.deliveryId,
                                                        amount: row?.deliveryEarnings,
                                                        notes: row?.deliveryNotes,
                                                    });
                                                    await setWorkStaffModalOpen(
                                                        true,
                                                    );
                                                },
                                                // disabled: !permissions?.update,
                                            },
                                            {
                                                item: 'Add Allowance',
                                                onSuccess: async () => {
                                                    console.log(
                                                        'dataaaaaaaaa',
                                                        row,
                                                    );
                                                    await setSelectedRow({
                                                        ...row,
                                                        amount: row?.bonus,
                                                        notes: row?.allowanceNotes,
                                                    });
                                                    await setBonusStaffModalOpen(
                                                        true,
                                                    );
                                                },
                                                // disabled: !permissions?.update,
                                            },
                                            {
                                                item: 'Add Deduction',
                                                onSuccess: async () => {
                                                    await setSelectedRow(row);
                                                    await setDeductionStaffModalOpen(
                                                        true,
                                                    );
                                                },
                                                // disabled: !permissions?.update,
                                            },
                                            {
                                                item: 'View Staff',
                                                onSuccess: () => {
                                                    viewStaffSuccessCallback(
                                                        row,
                                                    );
                                                },
                                            },
                                        ]}
                                    />
                                </TableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {workStaffModalOpen && (
                <AddWorkModal
                    open={true}
                    staffInfo={{ id: selectedRow?.userId }}
                    isEdit={false}
                    onClose={() => {
                        setWorkStaffModalOpen(false);
                        fetchEarningsReport();
                    }}
                />
            )}
            {bonusStaffModalOpen && (
                <AddAllowanceBonusModal
                    open={true}
                    staffInfo={{ id: selectedRow?.userId }}
                    isEdit={false}
                    onClose={() => {
                        setBonusStaffModalOpen(false);
                        fetchEarningsReport();
                    }}
                />
            )}
            {deductionStaffModalOpen && (
                <AddDeductionModal
                    open={true}
                    staffInfo={{ id: selectedRow?.userId }}
                    isEdit={false}
                    onClose={() => {
                        setDeductionStaffModalOpen(false);
                        fetchEarningsReport();
                    }}
                />
            )}
        </Box>
    );
};

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({
    dispatchGetSingleStaff: (payload, onSuccess, onError) =>
        dispatch(getSingleStaff(payload, onSuccess, onError)),
});

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(EarningsListContainer),
);
