import React from 'react';
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';
import { CustomTextField } from '../../Textfield';
import Autocomplete from '@material-ui/lab/Autocomplete';

export class PlaceAutoComplete extends React.Component {
    constructor(props) {
        super(props);
        this.state = { address: props.defaultValue.address ?? '' };
    }

    handleChange = (address) => {
        this.setState({ address });
    };

    handleSelect = (address) => {
        geocodeByAddress(address)
            .then((results) => {
                console.log('results', results);
                getLatLng(results[0]).then((latLng) => {
                    this.props.onChange &&
                        this.props.onChange({
                            address: results[0]?.formatted_address,
                            latLng,
                            results,
                        });
                });
                return getLatLng(results[0]);
            })
            .then((latLng) => console.log('Success', latLng))
            .catch((error) => console.error('Error', error));
    };

    render() {
        return (
            <PlacesAutocomplete
                value={this.state.address}
                onChange={this.handleChange}
                onSelect={this.handleSelect}
                searchOptions={{
                    componentRestrictions: { country: 'in' },
                }}
            >
                {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                }) => (
                    <Autocomplete
                        options={
                            loading
                                ? [{ description: 'Loading...' }]
                                : suggestions
                        }
                        getOptionLabel={(option) => option.description}
                        style={{ width: '100%' }}
                        renderOption={(suggestion) => {
                            const className = suggestion.active
                                ? 'suggestion-item--active'
                                : 'suggestion-item';
                            // inline style for demonstration purpose
                            const style = suggestion.active
                                ? {
                                      cursor: 'pointer',
                                      width: '100%',
                                  }
                                : {
                                      cursor: 'pointer',
                                      width: '100%',
                                  };
                            return (
                                <div
                                    {...getSuggestionItemProps(suggestion, {
                                        className,
                                        style,
                                    })}
                                >
                                    <span>{suggestion.description}</span>
                                </div>
                            );
                        }}
                        renderInput={(params) => (
                            <CustomTextField
                                {...params}
                                style={{
                                    marginBottom: '16px',
                                    '& input': { color: '#14112d' },
                                }}
                                {...getInputProps({
                                    placeholder:
                                        this.props?.defaultValue?.address ??
                                        'Search Address...*',
                                    className: 'location-search-input',
                                })}
                                {...(!this.props?.defaultValue?.address
                                    ? {
                                          label: this?.props?.label,
                                          isRequired: this.props?.isRequired,
                                      }
                                    : {})}
                            />
                        )}
                    />
                )}
            </PlacesAutocomplete>
        );
    }
}
