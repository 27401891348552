import {
    Box,
    Divider,
    Grid,
    Paper,
    Typography,
    makeStyles,
    Button,
} from '@material-ui/core';
import { Spinner } from '../../../components/UI';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
    AadhaarFailureIcon,
    AadhaarIcon,
    AadhaarSuccessIcon,
    CheckMarkGreen,
    CrossMarkIcon,
} from '../../../assets/svg';
import { CustomTextField } from '../../../components/Textfield';
import { FileUploader, LightBoxImage } from '../../../components/UI';
import {
    getKycDetails,
    postKycDetails,
    // deleteImageAttachment,
    getSingleStaff,
} from '../../../store/actions/staffActionCreator';
import RejectKycModal from '../../../components/Modals/RejectKycModal';
import { enqueueSnackbar } from '../../../store/actions/notificationActionCreator';

const useStyles = makeStyles((theme) => ({
    container: {},
}));

export const VerifyKycComp = ({
    staffInfo,
    kycInfo,
    dispatchGetKycDetails,
    dispatchStaffKycApproveReject,
    dispatchPostKycDetails,
    showMessage,
    getSingleStaff,
    ...props
}) => {
    useEffect(() => {
        fetchKycInfo();
    }, []);

    const classes = useStyles();

    const [loading, toggleLoading] = useState(true);
    // getSingleStaff
    const [rejectModalOpen, setRejectModalOpen] = useState(false);

    const [aadharPayload, setAadharPayload] = useState();
    const [panPayload, setPanPayload] = useState();
    const [rcPayload, setRcPayload] = useState();

    const [selectedItem, setSelectedItem] = useState({
        id: '',
        approvalStatus: '',
        reason: '',
    });

    const [isAadhaarEdited, setIsAadhaarEdited] = useState(false);
    const [isPanEdited, setIsPanEdited] = useState(false);
    const [isRcEdited, setIsRcEdited] = useState(false);

    useEffect(() => {
        console.log(aadharPayload, panPayload, rcPayload);
    }, [aadharPayload, panPayload, rcPayload]);

    useEffect(() => {
        setAadharPayload(kycInfo?.aadhar);
        setPanPayload(kycInfo?.panCard);
        setRcPayload(kycInfo?.rc);
    }, [kycInfo]);

    // useEffect(() => {
    //     setAadhaarId(kycInfo?.documents[0] ? kycInfo?.documents[0]?.id : '');

    //     setAadhaarNo(
    //         kycInfo?.documents[0] ? kycInfo?.documents[0]?.documentNo : '',
    //     );
    //     setAadhaarFront(
    //         kycInfo?.documents[0]?.images?.[0]
    //             ? kycInfo?.documents[0]?.images?.[0]?.s3FileUrl
    //             : '',
    //     );
    //     setAadhaarBack(
    //         kycInfo?.documents[0]?.images?.[1]
    //             ? kycInfo?.documents[0].images?.[1]?.s3FileUrl
    //             : '',
    //     );

    //     setAadhaarStatus(
    //         kycInfo?.documents[0] ? kycInfo?.documents[0]?.approvalStatus : '',
    //     );

    //     setAadhaarRejectReason(
    //         kycInfo?.documents[0] ? kycInfo?.documents[0]?.rejectReason : '',
    //     );

    //     setPanId(kycInfo?.documents[1] ? kycInfo?.documents[1]?.id : '');

    //     setPanNo(
    //         kycInfo?.documents[1] ? kycInfo?.documents[1]?.documentNo : '',
    //     );
    //     setPanFront(
    //         kycInfo?.documents[1]?.images?.[0]
    //             ? kycInfo?.documents[1]?.images?.[0]?.s3FileUrl
    //             : '',
    //     );

    //     setPanStatus(
    //         kycInfo?.documents[1] ? kycInfo?.documents[1]?.approvalStatus : '',
    //     );

    //     setPanRejectReason(
    //         kycInfo?.documents[1] ? kycInfo?.documents[1]?.rejectReason : '',
    //     );

    //     setRcId(kycInfo?.documents[2] ? kycInfo?.documents[2]?.id : '');

    //     setRcNo(kycInfo?.documents[2] ? kycInfo?.documents[2]?.documentNo : '');
    //     setRcFront(
    //         kycInfo?.documents[2]?.images?.[0]
    //             ? kycInfo?.documents[2]?.images[0]?.s3FileUrl
    //             : '',
    //     );
    //     setRcStatus(
    //         kycInfo?.documents[2] ? kycInfo?.documents[2].approvalStatus : '',
    //     );

    //     setRcRejectReason(
    //         kycInfo?.documents[2] ? kycInfo?.documents[2].rejectReason : '',
    //     );

    //     setAadhaarFrontId(
    //         kycInfo?.documents[0]?.images?.[0]
    //             ? kycInfo?.documents[0].images?.[0]?.id
    //             : '',
    //     );
    //     setAadhaarBackId(
    //         kycInfo?.documents[0]?.images?.[1]
    //             ? kycInfo?.documents[0].images?.[1]?.id
    //             : '',
    //     );
    //     setPanFrontId(
    //         kycInfo?.documents[1]?.images?.[0]
    //             ? kycInfo?.documents[1].images?.[0]?.id
    //             : '',
    //     );
    //     setRcFrontId(
    //         kycInfo?.documents[2]?.images?.[0]
    //             ? kycInfo?.documents[2].images?.[0]?.id
    //             : '',
    //     );
    // }, [kycInfo]);

    const fetchKycInfo = async () => {
        await toggleLoading(true);
        dispatchGetKycDetails(
            staffInfo.id,
            (resp) => {
                toggleLoading(false);
            },
            (resp) => {
                toggleLoading(false);
            },
        );
    };
    const isRCAutoVerified =
        !!kycInfo?.autoVerified && rcPayload?.documentNo && rcPayload?.image;
    const isPANAutoVerified =
        !!kycInfo?.autoVerified && panPayload?.documentNo && panPayload?.image;
    const isAadhaarAutoVerified =
        !!kycInfo?.autoVerified &&
        aadharPayload?.documentNo &&
        aadharPayload?.imageFront &&
        aadharPayload?.imageBack;
    const hideRC = !!kycInfo?.autoVerified
        ? !!rcPayload?.documentNo && !!rcPayload?.image
            ? false
            : true
        : false;
    const hidePAN = !!kycInfo?.autoVerified
        ? !!panPayload?.documentNo && !!panPayload?.image
            ? false
            : true
        : false;
    const hideAADHAR = !!kycInfo?.autoVerified
        ? !!aadharPayload?.documentNo &&
          aadharPayload?.imageFront &&
          aadharPayload?.imageBack
            ? false
            : true
        : false;
    return (
        <Spinner loading={loading}>
            <Paper
                style={{ marginBottom: '16px' }}
                elevation={0}
                className={classes.container}
            >
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Box
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                            }}
                        >
                            <Typography
                                style={{
                                    fontSize: '20px',
                                    fontWeight: 700,
                                    color: '#14112d',
                                }}
                                variant="h6"
                                component="span"
                                gutterBottom
                            >
                                {'DE Source Details'}
                            </Typography>
                            <div style={{ flexGrow: 1 }}>
                                <Divider
                                    style={{ width: '98%', marginLeft: 10 }}
                                />
                            </div>
                        </Box>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Box
                                style={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    direction: 'column',
                                    border: '1px solid rgba(38, 50, 56, 0.16)',
                                    borderRadius: '8px',
                                    paddingLeft: '16px',
                                    paddingTop: '10px',
                                    paddingRight: '16px',
                                    paddingBottom: '10px',
                                    flexDirection: 'column',
                                }}
                            >
                                <Typography
                                    style={{
                                        fontFamily: 'Nunito',
                                        fontSize: '14px',
                                        fontWeight: 400,
                                        color: '#14112d',
                                    }}
                                    variant="body2"
                                    component="span"
                                    gutterBottom
                                >
                                    {'Source'}
                                </Typography>
                                <Typography
                                    style={{
                                        fontFamily: 'Nunito',
                                        fontSize: '14px',
                                        fontWeight: 700,
                                        color: '#14112d',
                                    }}
                                    variant="body2"
                                    component="span"
                                    gutterBottom
                                >
                                    {staffInfo.sourceDetails.source
                                        ? staffInfo.sourceDetails.source
                                        : '-'}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Box
                                style={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    direction: 'column',
                                    border: '1px solid rgba(38, 50, 56, 0.16)',
                                    borderRadius: '8px',
                                    paddingLeft: '16px',
                                    paddingTop: '10px',
                                    paddingRight: '16px',
                                    paddingBottom: '10px',
                                    flexDirection: 'column',
                                }}
                            >
                                <Typography
                                    style={{
                                        fontFamily: 'Nunito',
                                        fontSize: '14px',
                                        fontWeight: 400,
                                        color: '#14112d',
                                    }}
                                    variant="body2"
                                    component="span"
                                    gutterBottom
                                >
                                    {'Remarks'}
                                </Typography>
                                <Typography
                                    style={{
                                        fontFamily: 'Nunito',
                                        fontSize: '14px',
                                        fontWeight: 700,
                                        color: '#14112d',
                                    }}
                                    variant="body2"
                                    component="span"
                                    gutterBottom
                                >
                                    {staffInfo.sourceDetails.remarks
                                        ? staffInfo.sourceDetails.remarks
                                        : '-'}
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
            {!hideAADHAR && (
                <>
                    {(!!kycInfo?.aadharKycRequired ||
                        !!kycInfo?.uidaiKycRequired) && (
                        <Paper
                            style={{ marginBottom: 20 }}
                            elevation={0}
                            className={classes.container}
                        >
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Typography
                                        style={{
                                            fontSize: '20px',
                                            fontWeight: 700,
                                            color: '#14112d',
                                        }}
                                        variant="h6"
                                        component="span"
                                        gutterBottom
                                    >
                                        {'Selfie'}
                                    </Typography>
                                    {kycInfo?.s3ProfileLink ||
                                    kycInfo?.selfie?.image ? (
                                        <LightBoxImage
                                            containerStyles={{
                                                width: 120,
                                                height: 120,
                                                marginRight: 5,
                                            }}
                                            imageStyles={{
                                                width: 120,
                                                height: 120,
                                                margin: 'auto',
                                                borderRadius: '6px',
                                                objectFit: 'fill',
                                                border: '1px solid rgba(38, 50, 56, 0.32)',
                                            }}
                                            source={
                                                kycInfo?.selfie?.image
                                                    ? kycInfo?.selfie?.image
                                                    : kycInfo?.s3ProfileLink
                                            }
                                        />
                                    ) : (
                                        '-'
                                    )}
                                </Grid>
                                <Grid item xs={12}>
                                    <Box
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Typography
                                            style={{
                                                fontSize: '20px',
                                                fontWeight: 700,
                                                color: '#14112d',
                                            }}
                                            variant="h6"
                                            component="span"
                                            gutterBottom
                                        >
                                            {'Aadhaar Details'}
                                        </Typography>
                                        {!!kycInfo?.uidaiKycRequired && (
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        marginLeft: '1rem',
                                                    }}
                                                >
                                                    {!!kycInfo?.uidaiKycVerified ? (
                                                        <AadhaarSuccessIcon
                                                            style={{
                                                                width: '20px',
                                                            }}
                                                        />
                                                    ) : (
                                                        <AadhaarFailureIcon
                                                            style={{
                                                                width: '20px',
                                                            }}
                                                        />
                                                    )}
                                                </div>
                                                <Typography
                                                    style={{
                                                        fontSize: '18px',
                                                        fontWeight: 700,
                                                        marginLeft: 5,
                                                        color: kycInfo?.uidaiKycVerified
                                                            ? '#4caf50'
                                                            : '#f44336',
                                                    }}
                                                    variant="h6"
                                                    component="span"
                                                    gutterBottom
                                                >
                                                    {kycInfo?.uidaiKycVerified
                                                        ? 'UIDAI Verified'
                                                        : 'UIDAI Verification Pending'}
                                                </Typography>
                                            </div>
                                        )}
                                        {!!kycInfo?.aadharKycRequired && (
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        marginLeft: '1rem',
                                                    }}
                                                >
                                                    {!!isAadhaarAutoVerified ? (
                                                        <CheckMarkGreen
                                                            style={{
                                                                width: '20px',
                                                            }}
                                                        />
                                                    ) : aadharPayload?.approvalStatus ===
                                                      'APPROVED' ? (
                                                        <CheckMarkGreen
                                                            style={{
                                                                width: '20px',
                                                            }}
                                                        />
                                                    ) : aadharPayload?.approvalStatus ===
                                                      'REJECTED' ? (
                                                        <CrossMarkIcon
                                                            style={{
                                                                width: '20px',
                                                            }}
                                                        />
                                                    ) : (
                                                        <></>
                                                    )}
                                                </div>
                                                <Typography
                                                    style={{
                                                        fontSize: '18px',
                                                        fontWeight: 700,
                                                        marginLeft: 5,
                                                        color: !!isAadhaarAutoVerified
                                                            ? '#4caf50'
                                                            : aadharPayload?.approvalStatus ===
                                                              'APPROVED'
                                                            ? '#4caf50'
                                                            : '#f44336',
                                                    }}
                                                    variant="h6"
                                                    component="span"
                                                    gutterBottom
                                                >
                                                    {!!isAadhaarAutoVerified
                                                        ? 'Verified'
                                                        : aadharPayload?.approvalStatus ===
                                                          'APPROVED'
                                                        ? 'Manually Verified'
                                                        : aadharPayload?.approvalStatus ===
                                                          'REJECTED'
                                                        ? 'Manually Rejected'
                                                        : ''}
                                                </Typography>
                                            </div>
                                        )}
                                        <div style={{ flexGrow: 1 }}>
                                            <Divider
                                                style={{
                                                    width: '98%',
                                                    marginLeft: 10,
                                                }}
                                            />
                                        </div>
                                    </Box>
                                </Grid>
                                {!!kycInfo?.aadharKycRequired && (
                                    <Grid item xs={12}>
                                        <Box
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'flex-start',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Typography
                                                style={{
                                                    fontFamily: 'Nunito',
                                                    fontSize: '16px',
                                                    fontWeight: 600,
                                                    color: '#14112d',
                                                }}
                                                variant="body2"
                                                component="span"
                                                gutterBottom
                                            >
                                                {'Enter Aadhaar Number *'}
                                            </Typography>
                                            <CustomTextField
                                                value={
                                                    aadharPayload?.documentNo
                                                }
                                                onChange={(e) => {
                                                    setAadharPayload((prev) => {
                                                        return {
                                                            ...prev,
                                                            documentNo:
                                                                e.target.value?.trim(),
                                                        };
                                                    });
                                                    setIsAadhaarEdited(true);
                                                }}
                                                // label="Enter Aadhaar Number"
                                                variant="outlined"
                                                style={{
                                                    width: '30%',
                                                    marginLeft: 20,
                                                    marginBottom: 0,
                                                }}
                                                disabled={
                                                    !!isAadhaarAutoVerified ||
                                                    !kycInfo?.aadharKycRequired
                                                }
                                            />
                                            {!isAadhaarAutoVerified &&
                                                !!kycInfo?.aadharKycRequired && (
                                                    <>
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            style={{
                                                                marginLeft: 20,
                                                                marginRight: 20,
                                                            }}
                                                            size="large"
                                                            disabled={
                                                                !!isAadhaarAutoVerified ||
                                                                !isAadhaarEdited ||
                                                                (isAadhaarEdited &&
                                                                    !aadharPayload
                                                                        ?.documentNo
                                                                        ?.length >
                                                                        0) ||
                                                                !aadharPayload?.imageFront ||
                                                                !aadharPayload?.imageBack
                                                            }
                                                            disableElevation
                                                            onClick={async () => {
                                                                let needToReturn =
                                                                    [
                                                                        false,
                                                                        false,
                                                                        false,
                                                                    ];
                                                                if (
                                                                    !aadharPayload?.documentNo.trim()
                                                                ) {
                                                                    showMessage(
                                                                        'Invalid Aadhaar Number',
                                                                        'error',
                                                                    );
                                                                    needToReturn[0] = true;
                                                                }
                                                                if (
                                                                    !aadharPayload?.imageFront
                                                                ) {
                                                                    showMessage(
                                                                        'Upload Aadhaar Front Image',
                                                                        'error',
                                                                    );
                                                                    needToReturn[1] = true;
                                                                }
                                                                if (
                                                                    !aadharPayload?.imageBack
                                                                ) {
                                                                    showMessage(
                                                                        'Upload Aadhaar Back Image',
                                                                        'error',
                                                                    );
                                                                    needToReturn[2] = true;
                                                                }
                                                                if (
                                                                    needToReturn.includes(
                                                                        true,
                                                                    )
                                                                ) {
                                                                    return false;
                                                                }
                                                                await toggleLoading(
                                                                    true,
                                                                );
                                                                dispatchPostKycDetails(
                                                                    {
                                                                        staffId:
                                                                            staffInfo.id,
                                                                        data: {
                                                                            aadhar: aadharPayload,
                                                                        },
                                                                    },
                                                                    () => {
                                                                        console.log(
                                                                            'Edit/Post KYC SUCCESS',
                                                                        );
                                                                        setIsAadhaarEdited(
                                                                            false,
                                                                        );
                                                                        dispatchGetKycDetails(
                                                                            staffInfo.id,
                                                                        );
                                                                        toggleLoading(
                                                                            false,
                                                                        );
                                                                    },
                                                                    () => {
                                                                        toggleLoading(
                                                                            false,
                                                                        );
                                                                        console.log(
                                                                            'Edit/Post KYC FAILED',
                                                                        );
                                                                    },
                                                                );
                                                            }}
                                                        >
                                                            Save
                                                        </Button>

                                                        <Button
                                                            variant="outlined"
                                                            color="primary"
                                                            style={
                                                                isAadhaarEdited ||
                                                                aadharPayload?.approvalStatus ==
                                                                    'APPROVED'
                                                                    ? {
                                                                          marginRight: 20,
                                                                      }
                                                                    : {
                                                                          marginRight: 20,
                                                                          fontSize: 14,
                                                                          color: '#4caf50',
                                                                          border: '1px solid #4caf50',
                                                                          fontWeight: 700,
                                                                      }
                                                            }
                                                            onClick={(e) => {
                                                                setSelectedItem(
                                                                    {
                                                                        id: aadharPayload?.id,
                                                                        approvalStatus:
                                                                            'APPROVED',
                                                                        reason: '',
                                                                    },
                                                                );
                                                                setRejectModalOpen(
                                                                    true,
                                                                );
                                                            }}
                                                            disabled={
                                                                !!isAadhaarAutoVerified ||
                                                                isAadhaarEdited ||
                                                                aadharPayload?.approvalStatus ==
                                                                    'APPROVED'
                                                            }
                                                            disableElevation
                                                        >
                                                            Approve
                                                        </Button>

                                                        <Button
                                                            variant="outlined"
                                                            style={
                                                                !isAadhaarEdited
                                                                    ? {
                                                                          marginRight: 20,
                                                                          backgroundColor:
                                                                              '#fff',
                                                                          border: '1px solid #f44336',
                                                                          color: '#f44336',
                                                                          fontWeight: 600,
                                                                      }
                                                                    : {}
                                                            }
                                                            disabled={
                                                                !!isAadhaarAutoVerified ||
                                                                isAadhaarEdited
                                                            }
                                                            disableElevation
                                                            onClick={(e) => {
                                                                setSelectedItem(
                                                                    {
                                                                        id: aadharPayload?.id,
                                                                        approvalStatus:
                                                                            'REJECTED',
                                                                        reason: '',
                                                                    },
                                                                );
                                                                setRejectModalOpen(
                                                                    true,
                                                                );
                                                            }}
                                                        >
                                                            Reject
                                                        </Button>
                                                    </>
                                                )}
                                        </Box>
                                        {!!aadharPayload?.faceMatchConfidence ? (
                                            <Box
                                                display={'flex'}
                                                alignItems={'center'}
                                                flexDirection={'row'}
                                            >
                                                <Typography
                                                    style={{
                                                        margin: '12px 0px',
                                                        fontFamily: 'Nunito',
                                                        fontSize: '16px',
                                                        fontWeight: 600,
                                                        color: '#14112d',
                                                        marginRight: '8px',
                                                    }}
                                                >
                                                    {'Face match: '}
                                                </Typography>
                                                <Typography
                                                    style={{
                                                        color: '#4caf50',
                                                        fontFamily: 'Nunito',
                                                        fontSize: '16px',
                                                        fontWeight: 600,
                                                        color: '#14112d',
                                                    }}
                                                >
                                                    {aadharPayload?.faceMatchConfidence ??
                                                        '-/-'}
                                                </Typography>
                                            </Box>
                                        ) : (
                                            <></>
                                        )}
                                    </Grid>
                                )}
                                {!!kycInfo?.aadharKycRequired && (
                                    <Grid
                                        item
                                        sm={kycInfo?.uidaiKycRequired ? 4 : 6}
                                        xs={12}
                                    >
                                        <FileUploader
                                            label={'Upload Aadhaar (Front)'}
                                            labelStyles={{
                                                fontSize: '16px',
                                                fontWeight: 600,
                                            }}
                                            disabled={!!isAadhaarAutoVerified}
                                            defaultValue={
                                                aadharPayload?.imageFront
                                            }
                                            placeholder={
                                                'Upload Aadhaar (Front)'
                                            }
                                            onFileUploaded={(e) => {
                                                setIsAadhaarEdited(true);

                                                setAadharPayload((prev) => {
                                                    return {
                                                        ...prev,
                                                        imageFront: e,
                                                    };
                                                });
                                            }}
                                            onRemoved={(e) => {
                                                setIsAadhaarEdited(true);
                                                setAadharPayload((prev) => {
                                                    return {
                                                        ...prev,
                                                        imageFront: null,
                                                    };
                                                });

                                                // if (aadhaarFrontId) {
                                                //     dispatchDeleteImageAttachment(
                                                //         aadhaarFrontId,
                                                //         () => {
                                                //             console.log('REMOVE SUCCESS');
                                                //         },
                                                //         () => {
                                                //             console.log('REMOVE FAILED');
                                                //         },
                                                //     );
                                                // }
                                            }}
                                        />
                                    </Grid>
                                )}
                                {!!kycInfo?.aadharKycRequired && (
                                    <Grid
                                        item
                                        sm={kycInfo?.uidaiKycRequired ? 4 : 6}
                                        xs={12}
                                    >
                                        <FileUploader
                                            label={'Upload Aadhaar (Back)'}
                                            labelStyles={{
                                                fontSize: '16px',
                                                fontWeight: 600,
                                            }}
                                            disabled={!!isAadhaarAutoVerified}
                                            defaultValue={
                                                aadharPayload?.imageBack
                                            }
                                            placeholder={
                                                'Upload Aadhaar (Back)'
                                            }
                                            onFileUploaded={(e) => {
                                                setIsAadhaarEdited(true);

                                                setAadharPayload((prev) => {
                                                    return {
                                                        ...prev,
                                                        imageBack: e,
                                                    };
                                                });
                                            }}
                                            onRemoved={(e) => {
                                                setIsAadhaarEdited(true);
                                                setAadharPayload((prev) => {
                                                    return {
                                                        ...prev,
                                                        imageBack: null,
                                                    };
                                                });

                                                // if (aadhaarBackId) {
                                                //     dispatchDeleteImageAttachment(
                                                //         aadhaarBackId,
                                                //         () => {
                                                //             console.log('REMOVE SUCCESS');
                                                //         },
                                                //         () => {
                                                //             console.log('REMOVE FAILED');
                                                //         },
                                                //     );
                                                // }
                                            }}
                                        />
                                    </Grid>
                                )}
                                {!!kycInfo?.uidaiKycRequired && (
                                    <Grid
                                        item
                                        sm={
                                            kycInfo?.aadharKycRequired
                                                ? 4
                                                : kycInfo?.uidaiKycVerified
                                                ? 4
                                                : 12
                                        }
                                        xs={12}
                                    >
                                        <Typography
                                            variant="body1"
                                            style={{
                                                fontSize: '16px',
                                                fontWeight: 600,
                                                marginTop: 10,
                                                marginBottom: 0,
                                                padding: 0,
                                                color: '#14112d',
                                            }}
                                        >
                                            Aadhaar User Info
                                        </Typography>
                                        {kycInfo?.uidaiKycVerified ? (
                                            <Box
                                                style={{
                                                    border: 'solid 1px rgba(38, 50, 56, 0.32)',
                                                    borderRadius: 16,
                                                    display: 'flex',
                                                    justifyContent:
                                                        'space-between',
                                                    alignItems: 'flex-start',
                                                    height: '160px',
                                                    padding: 8,
                                                    paddingRight: 0,
                                                    overflowY: 'auto',
                                                }}
                                            >
                                                <LightBoxImage
                                                    source={
                                                        kycInfo?.uidaiAadhar
                                                            ?.kycPhotoS3Url
                                                    }
                                                    containerStyles={{
                                                        width: '40%',
                                                        borderRadius: 10,
                                                        border: 'solid 1px rgba(38, 50, 56, 0.32)',
                                                    }}
                                                    imageStyles={{
                                                        objectFit: 'fill',
                                                        borderRadius: 10,
                                                        margin: 0,
                                                        height: '100%',
                                                    }}
                                                />
                                                <Box
                                                    style={{
                                                        width: '60%',
                                                        marginLeft: 12,
                                                    }}
                                                >
                                                    <Typography
                                                        variant="body1"
                                                        style={{
                                                            fontSize: '14px',
                                                            fontWeight: 600,
                                                            color: 'rgba(20, 17, 45, 0.64)',
                                                        }}
                                                    >
                                                        Name:{' '}
                                                        <b
                                                            style={{
                                                                color: '#14112d',
                                                            }}
                                                        >
                                                            {
                                                                kycInfo
                                                                    ?.uidaiAadhar
                                                                    ?.name
                                                            }
                                                        </b>
                                                    </Typography>
                                                    <Typography
                                                        variant="body1"
                                                        style={{
                                                            fontSize: '14px',
                                                            fontWeight: 400,
                                                            color: 'rgba(20, 17, 45, 0.64)',
                                                        }}
                                                    >
                                                        DOB:{' '}
                                                        <b
                                                            style={{
                                                                color: '#14112d',
                                                            }}
                                                        >
                                                            {
                                                                kycInfo
                                                                    ?.uidaiAadhar
                                                                    ?.dateOfBirth
                                                            }
                                                        </b>
                                                    </Typography>
                                                    <Typography
                                                        variant="body1"
                                                        style={{
                                                            fontSize: '14px',
                                                            fontWeight: 400,
                                                            color: 'rgba(20, 17, 45, 0.64)',
                                                        }}
                                                    >
                                                        Address: <br />{' '}
                                                        <b
                                                            style={{
                                                                color: '#14112d',
                                                            }}
                                                        >
                                                            {
                                                                kycInfo
                                                                    ?.uidaiAadhar
                                                                    ?.address
                                                            }
                                                        </b>
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        ) : (
                                            <Box
                                                style={{
                                                    border: 'solid 1px rgba(38, 50, 56, 0.32)',
                                                    borderRadius: 16,
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    flexDirection: 'column',
                                                    height: '160px',
                                                    padding: 8,
                                                }}
                                            >
                                                <AadhaarIcon
                                                    style={{ width: 100 }}
                                                />
                                                <Typography
                                                    style={{
                                                        fontSize: '18px',
                                                        fontWeight: 700,
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    UIDAI Verification is
                                                    pending
                                                </Typography>
                                                <Typography
                                                    style={{
                                                        fontSize: '12px',
                                                        fontWeight: 400,
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    Please Ask the Employee to
                                                    Complete Verification
                                                </Typography>
                                            </Box>
                                        )}
                                    </Grid>
                                )}
                            </Grid>

                            {aadharPayload?.rejectReason && (
                                <>
                                    <Typography
                                        style={{
                                            margin: '8px 0',
                                        }}
                                    >
                                        Reject Reason
                                    </Typography>
                                    <Typography
                                        style={{
                                            color: '#f44336',
                                        }}
                                    >
                                        {aadharPayload?.rejectReason}
                                    </Typography>
                                </>
                            )}
                        </Paper>
                    )}
                </>
            )}

            {!hidePAN && (
                <Paper
                    style={{ marginBottom: 20 }}
                    elevation={0}
                    className={classes.container}
                >
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Box
                                style={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                }}
                            >
                                <Typography
                                    style={{
                                        fontSize: '20px',
                                        fontWeight: 700,
                                        color: '#14112d',
                                    }}
                                    variant="h6"
                                    component="span"
                                    gutterBottom
                                >
                                    {'Pan Card Details'}
                                </Typography>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <div style={{ marginLeft: '1rem' }}>
                                        {!!isPANAutoVerified ? (
                                            <CheckMarkGreen
                                                style={{ width: '20px' }}
                                            />
                                        ) : panPayload?.approvalStatus ===
                                          'APPROVED' ? (
                                            <CheckMarkGreen
                                                style={{ width: '20px' }}
                                            />
                                        ) : panPayload?.approvalStatus ===
                                          'REJECTED' ? (
                                            <CrossMarkIcon
                                                style={{ width: '20px' }}
                                            />
                                        ) : (
                                            <></>
                                        )}
                                    </div>
                                    <Typography
                                        style={{
                                            fontSize: '18px',
                                            fontWeight: 700,
                                            marginLeft: 5,
                                            color: !!isPANAutoVerified
                                                ? '#4caf50'
                                                : panPayload?.approvalStatus ===
                                                  'APPROVED'
                                                ? '#4caf50'
                                                : '#f44336',
                                        }}
                                        variant="h6"
                                        component="span"
                                        gutterBottom
                                    >
                                        {!!isPANAutoVerified
                                            ? 'Verified'
                                            : panPayload?.approvalStatus ===
                                              'APPROVED'
                                            ? 'Manually Verified'
                                            : panPayload?.approvalStatus ===
                                              'REJECTED'
                                            ? 'Manually Rejected'
                                            : ''}
                                    </Typography>
                                </div>
                                <div style={{ flexGrow: 1 }}>
                                    <Divider
                                        style={{ width: '98%', marginLeft: 10 }}
                                    />
                                </div>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box
                                style={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                }}
                            >
                                <Typography
                                    style={{
                                        fontSize: '16px',
                                        fontWeight: 600,
                                    }}
                                    variant="body2"
                                    component="span"
                                    gutterBottom
                                >
                                    {'Enter Pan Number *'}
                                </Typography>
                                <CustomTextField
                                    value={panPayload?.documentNo}
                                    onChange={(e) => {
                                        setPanPayload((prev) => {
                                            return {
                                                ...prev,
                                                documentNo:
                                                    e.target.value?.trim(),
                                            };
                                        });
                                        setIsPanEdited(true);
                                    }}
                                    // label="Enter Pan Number"
                                    disabled={!!isPANAutoVerified}
                                    variant="outlined"
                                    style={{
                                        width: '30%',
                                        marginLeft: 20,
                                        marginBottom: 0,
                                    }}
                                />
                                {!isPANAutoVerified && (
                                    <>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            style={{
                                                marginLeft: 20,
                                                marginRight: 20,
                                            }}
                                            size="large"
                                            disabled={
                                                !!isPANAutoVerified ||
                                                !isPanEdited ||
                                                (isPanEdited &&
                                                    !panPayload?.documentNo
                                                        ?.length > 0) ||
                                                !panPayload?.image
                                            }
                                            disableElevation
                                            onClick={async () => {
                                                let needToReturn = [
                                                    false,
                                                    false,
                                                ];
                                                if (
                                                    !panPayload?.documentNo.trim()
                                                ) {
                                                    showMessage(
                                                        'Invalid PAN Number',
                                                        'error',
                                                    );
                                                    needToReturn[0] = true;
                                                }
                                                if (!panPayload?.image) {
                                                    showMessage(
                                                        'Upload PAN Image',
                                                        'error',
                                                    );
                                                    needToReturn[1] = true;
                                                }
                                                if (
                                                    needToReturn.includes(true)
                                                ) {
                                                    return false;
                                                }
                                                await toggleLoading(true);
                                                dispatchPostKycDetails(
                                                    {
                                                        staffId: staffInfo.id,
                                                        data: {
                                                            panCard: panPayload,
                                                        },
                                                    },
                                                    () => {
                                                        console.log(
                                                            'Edit/Post KYC SUCCESS',
                                                        );
                                                        setIsPanEdited(false);
                                                        dispatchGetKycDetails(
                                                            staffInfo.id,
                                                        );
                                                        toggleLoading(false);
                                                    },
                                                    () => {
                                                        toggleLoading(false);
                                                        console.log(
                                                            'Edit/Post KYC FAILED',
                                                        );
                                                    },
                                                );
                                            }}
                                        >
                                            Save
                                        </Button>

                                        <Button
                                            variant="outlined"
                                            style={
                                                isPanEdited ||
                                                panPayload?.approvalStatus ==
                                                    'APPROVED'
                                                    ? {
                                                          marginRight: 20,
                                                      }
                                                    : {
                                                          marginRight: 20,
                                                          fontSize: 14,
                                                          color: '#4caf50',
                                                          border: '1px solid #4caf50',
                                                          fontWeight: 700,
                                                      }
                                            }
                                            onClick={(e) => {
                                                setSelectedItem({
                                                    id: panPayload?.id,
                                                    approvalStatus: 'APPROVED',
                                                    reason: '',
                                                });
                                                setRejectModalOpen(true);
                                            }}
                                            disabled={
                                                !!isPANAutoVerified ||
                                                isPanEdited ||
                                                panPayload?.approvalStatus ==
                                                    'APPROVED'
                                            }
                                            disableElevation
                                        >
                                            Approve
                                        </Button>
                                        <Button
                                            variant="outlined"
                                            style={
                                                !isPanEdited
                                                    ? {
                                                          marginRight: 20,
                                                          backgroundColor:
                                                              '#fff',
                                                          border: '1px solid #f44336',
                                                          color: '#f44336',
                                                          fontWeight: 600,
                                                      }
                                                    : {}
                                            }
                                            disabled={
                                                !!isPANAutoVerified ||
                                                isPanEdited
                                            }
                                            disableElevation
                                            onClick={(e) => {
                                                setSelectedItem({
                                                    id: panPayload?.id,
                                                    approvalStatus: 'REJECTED',
                                                    reason: '',
                                                });
                                                setRejectModalOpen(true);
                                            }}
                                        >
                                            Reject
                                        </Button>
                                    </>
                                )}
                            </Box>
                            {!!panPayload?.faceMatchConfidence ? (
                                <Box
                                    display={'flex'}
                                    alignItems={'center'}
                                    flexDirection={'row'}
                                >
                                    <Typography
                                        style={{
                                            margin: '12px 0px',
                                            fontFamily: 'Nunito',
                                            fontSize: '16px',
                                            fontWeight: 600,
                                            color: '#14112d',
                                            marginRight: '8px',
                                        }}
                                    >
                                        {'Face match: '}
                                    </Typography>
                                    <Typography
                                        style={{
                                            color: '#4caf50',
                                            fontFamily: 'Nunito',
                                            fontSize: '16px',
                                            fontWeight: 600,
                                            color: '#14112d',
                                        }}
                                    >
                                        {panPayload?.faceMatchConfidence ??
                                            '-/-'}
                                    </Typography>
                                </Box>
                            ) : (
                                <></>
                            )}
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <FileUploader
                                label={'Upload Pan card (front)'}
                                labelStyles={{
                                    fontSize: '16px',
                                    fontWeight: 600,
                                }}
                                placeholder={'Upload Pan card (Front)'}
                                onFileUploaded={(e) => {
                                    setIsPanEdited(true);

                                    setPanPayload((prev) => {
                                        return {
                                            ...prev,
                                            image: e,
                                        };
                                    });
                                }}
                                disabled={!!isPANAutoVerified}
                                defaultValue={panPayload?.image}
                                onRemoved={(e) => {
                                    setIsPanEdited(true);
                                    setPanPayload((prev) => {
                                        return {
                                            ...prev,
                                            image: null,
                                        };
                                    });

                                    // if (panFrontId) {
                                    //     dispatchDeleteImageAttachment(
                                    //         panFrontId,
                                    //         () => {
                                    //             console.log('REMOVE SUCCESS');
                                    //         },
                                    //         () => {
                                    //             console.log('REMOVE FAILED');
                                    //         },
                                    //     );
                                    // }
                                }}
                            />
                        </Grid>
                    </Grid>

                    {panPayload?.rejectReason ? (
                        <>
                            <Typography
                                style={{
                                    margin: '8px 0',
                                }}
                            >
                                Reject Reason
                            </Typography>
                            <Typography
                                style={{
                                    color: '#f44336',
                                }}
                            >
                                {panPayload?.rejectReason}
                            </Typography>
                        </>
                    ) : (
                        <></>
                    )}
                </Paper>
            )}

            {!hideRC && (
                <Paper
                    style={{ marginBottom: 20 }}
                    elevation={0}
                    className={classes.container}
                >
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Box
                                style={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                }}
                            >
                                <Typography
                                    style={{
                                        fontSize: '20px',
                                        fontWeight: 700,
                                    }}
                                    variant="h6"
                                    component="span"
                                    gutterBottom
                                >
                                    {'Vehicle RC Details'}
                                </Typography>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <div style={{ marginLeft: '1rem' }}>
                                        {!!isRCAutoVerified ? (
                                            <CheckMarkGreen
                                                style={{ width: '20px' }}
                                            />
                                        ) : rcPayload?.approvalStatus ===
                                          'APPROVED' ? (
                                            <CheckMarkGreen
                                                style={{ width: '20px' }}
                                            />
                                        ) : rcPayload?.approvalStatus ===
                                          'REJECTED' ? (
                                            <CrossMarkIcon
                                                style={{ width: '20px' }}
                                            />
                                        ) : (
                                            <></>
                                        )}
                                    </div>
                                    <Typography
                                        style={{
                                            fontSize: '18px',
                                            fontWeight: 700,
                                            marginLeft: 5,
                                            color: !!isRCAutoVerified
                                                ? '#4caf50'
                                                : rcPayload?.approvalStatus ===
                                                  'APPROVED'
                                                ? '#4caf50'
                                                : '#f44336',
                                        }}
                                        variant="h6"
                                        component="span"
                                        gutterBottom
                                    >
                                        {!!isRCAutoVerified
                                            ? 'Verified'
                                            : rcPayload?.approvalStatus ===
                                              'APPROVED'
                                            ? 'Manually Verified'
                                            : rcPayload?.approvalStatus ===
                                              'REJECTED'
                                            ? 'Manually Rejected'
                                            : ''}
                                    </Typography>
                                </div>
                                <div style={{ flexGrow: 1 }}>
                                    <Divider
                                        style={{ width: '98%', marginLeft: 10 }}
                                    />
                                </div>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box
                                style={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                }}
                            >
                                <Typography
                                    style={{
                                        fontSize: '16px',
                                        fontWeight: 600,
                                    }}
                                    variant="body2"
                                    component="span"
                                    gutterBottom
                                >
                                    {'Enter RC Number *'}
                                </Typography>
                                <CustomTextField
                                    value={rcPayload?.documentNo}
                                    onChange={(e) => {
                                        setRcPayload((prev) => {
                                            return {
                                                ...prev,
                                                documentNo:
                                                    e.target.value?.trim(),
                                            };
                                        });
                                        setIsRcEdited(true);
                                    }}
                                    // label="Enter RC Number"
                                    variant="outlined"
                                    disabled={!!isRCAutoVerified}
                                    style={{
                                        width: '30%',
                                        marginLeft: 20,
                                        marginBottom: 0,
                                    }}
                                />
                                <Button
                                    variant="contained"
                                    color="primary"
                                    style={{ marginLeft: 20, marginRight: 20 }}
                                    size="large"
                                    disabled={
                                        !!isRCAutoVerified ||
                                        !isRcEdited ||
                                        (isRcEdited &&
                                            !rcPayload?.documentNo?.length >
                                                0) ||
                                        !rcPayload?.image
                                    }
                                    disableElevation
                                    onClick={async () => {
                                        let needToReturn = [false, false];
                                        if (!rcPayload?.documentNo.trim()) {
                                            showMessage(
                                                'Invalid RC Number',
                                                'error',
                                            );
                                            needToReturn[0] = true;
                                        }
                                        if (!rcPayload?.image) {
                                            showMessage(
                                                'Upload RC Image',
                                                'error',
                                            );
                                            needToReturn[1] = true;
                                        }
                                        if (needToReturn.includes(true)) {
                                            return false;
                                        }
                                        await toggleLoading(true);

                                        let data = {
                                            staffId: staffInfo.id,
                                            data: {
                                                rc: rcPayload,
                                            },
                                        };

                                        dispatchPostKycDetails(
                                            data,
                                            () => {
                                                console.log(
                                                    'Edit/Post KYC SUCCESS',
                                                );
                                                setIsRcEdited(false);
                                                dispatchGetKycDetails(
                                                    staffInfo.id,
                                                );
                                                toggleLoading(false);
                                            },
                                            () => {
                                                toggleLoading(false);
                                                console.log(
                                                    'Edit/Post KYC FAILED',
                                                );
                                            },
                                        );
                                    }}
                                >
                                    Save
                                </Button>
                                <Button
                                    variant="outlined"
                                    style={
                                        isRcEdited ||
                                        rcPayload?.approvalStatus == 'APPROVED'
                                            ? {
                                                  marginRight: 20,
                                              }
                                            : {
                                                  marginRight: 20,
                                                  fontSize: 14,
                                                  color: '#4caf50',
                                                  border: '1px solid #4caf50',
                                                  fontWeight: 700,
                                              }
                                    }
                                    disabled={
                                        !!isRCAutoVerified ||
                                        isRcEdited ||
                                        rcPayload?.approvalStatus == 'APPROVED'
                                    }
                                    disableElevation
                                    onClick={(e) => {
                                        setSelectedItem({
                                            id: rcPayload?.id,
                                            approvalStatus: 'APPROVED',
                                            reason: '',
                                        });
                                        setRejectModalOpen(true);
                                    }}
                                >
                                    Approve
                                </Button>
                                <Button
                                    variant="outlined"
                                    style={
                                        !isRcEdited
                                            ? {
                                                  marginRight: 20,
                                                  backgroundColor: '#fff',
                                                  border: '1px solid #f44336',
                                                  color: '#f44336',
                                                  fontWeight: 600,
                                              }
                                            : {}
                                    }
                                    disabled={!!isRCAutoVerified || isRcEdited}
                                    disableElevation
                                    onClick={(e) => {
                                        setSelectedItem({
                                            id: rcPayload?.id,
                                            approvalStatus: 'REJECTED',
                                            reason: '',
                                        });
                                        setRejectModalOpen(true);
                                    }}
                                >
                                    Reject
                                </Button>
                            </Box>
                            {!!rcPayload?.faceMatchConfidence ? (
                                <Box
                                    display={'flex'}
                                    alignItems={'center'}
                                    flexDirection={'row'}
                                >
                                    <Typography
                                        style={{
                                            margin: '12px 0px',
                                            fontFamily: 'Nunito',
                                            fontSize: '16px',
                                            fontWeight: 600,
                                            color: '#14112d',
                                            marginRight: '8px',
                                        }}
                                    >
                                        {'Face match: '}
                                    </Typography>
                                    <Typography
                                        style={{
                                            color: '#4caf50',
                                            fontFamily: 'Nunito',
                                            fontSize: '16px',
                                            fontWeight: 600,
                                            color: '#14112d',
                                        }}
                                    >
                                        {rcPayload?.faceMatchConfidence ??
                                            '-/-'}
                                    </Typography>
                                </Box>
                            ) : (
                                <></>
                            )}
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <FileUploader
                                label={'Upload RC (front)'}
                                labelStyles={{
                                    fontSize: '16px',
                                    fontWeight: 600,
                                }}
                                placeholder={'Upload RC (front)'}
                                disabled={!!isRCAutoVerified}
                                onFileUploaded={(e) => {
                                    setIsRcEdited(true);
                                    setRcPayload((prev) => {
                                        return {
                                            ...prev,
                                            image: e,
                                        };
                                    });
                                }}
                                defaultValue={rcPayload?.image}
                                onRemoved={(e) => {
                                    setRcPayload((prev) => {
                                        return {
                                            ...prev,
                                            image: null,
                                        };
                                    });
                                    setIsRcEdited(true);

                                    // if (rcFrontId) {
                                    //     dispatchDeleteImageAttachment(
                                    //         rcFrontId,
                                    //         () => {
                                    //             console.log('REMOVE SUCCESS');
                                    //         },
                                    //         () => {
                                    //             console.log('REMOVE FAILED');
                                    //         },
                                    //     );
                                    // }
                                }}
                            />
                        </Grid>
                    </Grid>
                    {rcPayload?.rejectReason ? (
                        <>
                            <Typography
                                style={{
                                    margin: '8px 0',
                                }}
                            >
                                Reject Reason
                            </Typography>
                            <Typography
                                style={{
                                    color: '#f44336',
                                }}
                            >
                                {rcPayload?.rejectReason}
                            </Typography>
                        </>
                    ) : (
                        <></>
                    )}
                    {/* <Box
                    style={{
                        marginTop: '16px',
                    }}
                ></Box>
                {rejectModalOpen && (
                    <RejectKycModal
                        open={rejectModalOpen}
                        onClose={() => {
                            // console.log('Closed');
                            setRejectModalOpen(false);
                            
                        }}
                        data={selectedItem}
                        staffInfo={staffInfo}
                    />
                )} */}
                </Paper>
            )}

            {rejectModalOpen && (
                <RejectKycModal
                    open={rejectModalOpen}
                    onClose={() => {
                        // console.log('Closed');
                        setRejectModalOpen(false);
                        console.log('staffinfooooooooo', staffInfo);
                        getSingleStaff(
                            staffInfo.id,
                            () => {
                                console.log('Get single staff call success');
                            },
                            () => {
                                console.log('Get single staff call failed');
                            },
                        );
                    }}
                    data={selectedItem}
                    staffInfo={staffInfo}
                />
            )}
        </Spinner>
    );
};

const mapStateToProps = (state) => ({
    kycInfo: state.staff?.kycInfo,
});

const mapDispatchToProps = (dispatch) => ({
    getSingleStaff: (payload, onSuccess, onError) =>
        dispatch(getSingleStaff(payload, onSuccess, onError)),

    dispatchGetKycDetails: (payload, onSuccess, onError) =>
        dispatch(getKycDetails(payload, onSuccess, onError)),

    dispatchPostKycDetails: (payload, onSuccess, onError) =>
        dispatch(postKycDetails(payload, onSuccess, onError)),
    showMessage: (msg, type) =>
        dispatch(
            enqueueSnackbar({
                message: msg,
                options: {
                    key: new Date().getTime() + Math.random(),
                    variant: type,
                },
            }),
        ),
    // dispatchDeleteImageAttachment: (id, onSuccess, onError) =>
    //     dispatch(deleteImageAttachment(id, onSuccess, onError)),
});
export const VerifyKyc = connect(
    mapStateToProps,
    mapDispatchToProps,
)(VerifyKycComp);
