import {
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    FormGroup,
    MenuItem,
    Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Modal from './Modal';
import { CustomTextField } from '../Textfield';
import { connect } from 'react-redux';
import { updateLeadStatus } from '../../store/actions/leadActionCreator';

function InCompleteHireModal({
    leadInfo,
    open,
    onClose,
    defaultValue = {},
    markIncompleteHireList,
    dispatchUpdateLeadStatus,
    ...props
}) {
    const [isOpen, toggleModal] = useState(open);
    const [loading, toggleLoading] = useState(false);
    const [reason, setReason] = useState({});
    const [markIncompleteDocList, setMarkIncompleteDocList] = useState([]);
    const [checkList, setCheckList] = useState({});

    const handleClose = (values) => {
        toggleModal(!isOpen);
        onClose && onClose(values);
    };

    const markInComplete = () => {
        toggleLoading(true);
        const payload = {
            status: 'Incomplete',
            comments: '',
            incompleteHireReasonList: Object.keys(checkList)
                ?.filter((i) => !!checkList[i])
                ?.map((i) => ({
                    name: i,
                    reason: reason[i],
                })),
        };
        dispatchUpdateLeadStatus(
            leadInfo.id,
            payload,
            (resp) => {
                toggleLoading(false);
                handleClose('');
            },
            (err) => {
                toggleLoading(false);
                handleClose('');
            },
        );
    };

    useEffect(() => {
        setMarkIncompleteDocList(markIncompleteHireList?.split(','));
    }, [markIncompleteHireList]);

    const isButtonDisbled = Object.keys(checkList)
        ?.filter((i) => !!checkList[i])
        ?.map((i) => !!reason[i]);
    const actions = [
        <Button
            key={1}
            variant="contained"
            color="default"
            className={`cancelButton`}
            onClick={() => handleClose(defaultValue)}
            style={{
                boxShadow: 'none',
            }}
            disableElevation
        >
            Cancel
        </Button>,
        <Button
            key={2}
            variant="contained"
            color="primary"
            className={`saveButton`}
            type="submit"
            onClick={markInComplete}
            style={{
                boxShadow: 'none',
            }}
            disabled={
                !isButtonDisbled?.length || isButtonDisbled.includes(false)
            }
            disableElevation
        >
            Incomplete
        </Button>,
    ];
    return (
        <Modal
            onClose={() => handleClose(defaultValue)}
            open={isOpen}
            title={`Mark Incomplete`}
            actions={actions}
            loading={loading}
            isForm={false}
        >
            <Typography
                style={{
                    fontSize: '18px',
                    fontWeight: 600,
                }}
            >
                Select Document which have issue
            </Typography>
            <FormGroup>
                {[...markIncompleteDocList]?.map((item) => (
                    <>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name={item}
                                    checked={checkList[item]}
                                    onChange={(event) => {
                                        setCheckList({
                                            ...checkList,
                                            [event.target.name]:
                                                event.target.checked,
                                        });
                                        !!reason[item] &&
                                            setReason({
                                                ...reason,
                                                [item]: event.target?.nodata,
                                            });
                                    }}
                                    color="primary"
                                />
                            }
                            label={item}
                        />
                        {!!checkList[item] && (
                            <CustomTextField
                                fullWidth={true}
                                label="Reason"
                                variant="outlined"
                                id="reason"
                                name="reason"
                                value={reason[item]}
                                onChange={(event) => {
                                    setReason({
                                        ...reason,
                                        [item]: event.target.value,
                                    });
                                }}
                                error={
                                    !reason[item]
                                        ? Boolean(!reason[item])
                                        : false
                                }
                            />
                        )}
                    </>
                ))}
            </FormGroup>
        </Modal>
    );
}

const mapStateToProps = (state) => ({
    markIncompleteHireList: state?.user?.config?.markIncompleteHireList,
});
const mapDispatchToProps = (dispatch) => ({
    dispatchUpdateLeadStatus: (id, data, onSuccess, OnFailed) =>
        dispatch(updateLeadStatus(id, data, onSuccess, OnFailed)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(InCompleteHireModal);
