import { Box, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React from 'react';
import CustomDropdown from '../Dropdown';
import moment from 'moment';
import { HUB_TYPE_ENUM } from '../../utils/constants';
import { OnlineStateIcon, RightArrowIcon } from '../../assets/svg';
import { sortTime } from '../Modals/AssignStaffToHubModal';

const LimitTag = ({ children, ...props }) => (
    <Box
        style={{
            border: '1px solid',
            backgroundColor: '#14112d',
            color: '#fff',
            padding: '0 8px',
            borderRadius: '8px',
            marginRight: '4px',
            marginBottom: '4px',
            display: 'flex',
            alignItems: 'center',
        }}
        {...props}
    >
        {children}
    </Box>
);

export const checkCurrentAvailableShift = (value) => {
    const data = { ...value };
    const currentTime = moment();
    if (!data?.hubShiftMappings?.length) return { ...data };
    const currentShift = data?.hubShiftMappings?.find((hub) => {
        return hub.shiftLineItems.find((shift) => {
            return moment(currentTime).isBetween(
                shift.startTimeEpochMillis,
                shift.endTimeEpochMillis,
                'HH:mm',
            );
        });
    });

    if (!!currentShift) {
        data.totalHubCount = 1;
        data.totalShiftCount = 1;
        data.hubShiftMappings = [currentShift];
    } else {
        data.totalHubCount = 0;
        data.totalShiftCount = 0;
        data.hubShiftMappings = [];
    }
    return { ...data };
};

export default function ShiftColumnItemPopper({
    limit = 1,
    inline = false,
    setSelectedItem,
    data = {},
    selectedItem = {},
    labelStyle,
    ...props
}) {
    const {
        totalHubCount = 0,
        totalShiftCount = 0,
        hubShiftMappings = [],
        presentShiftIds = [],
    } = data ?? {};

    let rowItem = [];
    if (!hubShiftMappings?.length) {
        rowItem = [
            {
                item: 'No Data',
                value: '',
            },
        ];
    } else {
        rowItem = [...hubShiftMappings];
    }
    const isDisabled = !totalHubCount && !totalShiftCount;
    let shiftList = [];
    if (!!hubShiftMappings?.length) {
        hubShiftMappings?.forEach((hub) => {
            if (!!hub?.shiftLineItems?.length) {
                shiftList = sortTime([...shiftList, ...hub?.shiftLineItems]);
            }
        });
    }
    const isShiftGreaterThanLimit = shiftList?.length > limit;
    const tagLength = isShiftGreaterThanLimit
        ? limit ?? shiftList?.length
        : shiftList?.length;

    return (
        <CustomDropdown
            buttonProps={{
                disabled: isDisabled,
            }}
            title={{
                render: (
                    <Box
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            justifyContent: 'flex-start',
                        }}
                    >
                        {inline ? (
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    fontSize: 16,
                                }}
                            >
                                {totalHubCount} Hub {totalShiftCount} shifts
                                {!!isShiftGreaterThanLimit ? (
                                    <LimitTag>+{totalShiftCount - 2}</LimitTag>
                                ) : (
                                    <ExpandMoreIcon
                                        fontSize="small"
                                        style={{
                                            marginLeft: '4px',
                                        }}
                                    />
                                )}
                            </div>
                        ) : (
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    fontSize: 12,
                                    flexWrap: 'wrap',
                                    flexFlow: 'wrap',
                                }}
                            >
                                {!!totalShiftCount ? (
                                    Array(tagLength)
                                        .fill(tagLength)
                                        .map((_, index) => {
                                            const currentTime = moment();
                                            const shift = shiftList[index];
                                            let color = '#f44336';
                                            color = !!shift?.primaryOperation
                                                ? HUB_TYPE_ENUM[
                                                      shift?.primaryOperation?.toUpperCase() ??
                                                          ''
                                                  ]?.color
                                                : '#f44336';
                                            console.log('shift 1', shift);
                                            const isShiftActive = moment(
                                                currentTime,
                                            ).isBetween(
                                                shift?.startTimeEpochMillis,
                                                shift?.endTimeEpochMillis,
                                                'HH:mm',
                                            );

                                            const isPresentShiftActive =
                                                presentShiftIds?.includes(
                                                    shift?.id,
                                                );

                                            // switch (shift.primaryOperation) {
                                            //     case HUB_TYPE_ENUM.ECOMMERCE:
                                            //         color = '#43419e';
                                            //         break;
                                            //     case HUB_TYPE_ENUM.HYPERLOCAL:
                                            //         color = '#f44336';
                                            //         break;
                                            //     case HUB_TYPE_ENUM.STORE:
                                            //         color = '#579b5a';
                                            //         break;

                                            //     default:
                                            //         color = '#f44336';
                                            //         break;
                                            // }
                                            return (
                                                <Box
                                                    style={{
                                                        border: '1px solid',
                                                        borderColor: color,
                                                        color,
                                                        padding: '0 4px',
                                                        paddingLeft: 8,
                                                        borderRadius: '4px',
                                                        marginRight: '4px',
                                                        marginBottom: '4px',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        ...(isPresentShiftActive
                                                            ? {
                                                                  color: '#fff',
                                                                  backgroundColor:
                                                                      color,
                                                              }
                                                            : { opacity: 0.6 }),
                                                    }}
                                                >
                                                    {!!isShiftActive && (
                                                        <OnlineStateIcon
                                                            style={{
                                                                marginRight:
                                                                    '2px',
                                                            }}
                                                            fill={
                                                                isPresentShiftActive
                                                                    ? '#fff'
                                                                    : '#4CAF50'
                                                            }
                                                        />
                                                    )}
                                                    {!!shift?.startTimeEpochMillis &&
                                                    !!shift?.endTimeEpochMillis
                                                        ? `${moment(
                                                              shift?.startTimeEpochMillis,
                                                          ).format(
                                                              'HH:mm',
                                                          )} - ${moment(
                                                              shift?.endTimeEpochMillis,
                                                          ).format('HH:mm')}`
                                                        : ''}{' '}
                                                    <RightArrowIcon
                                                        fill={
                                                            isPresentShiftActive
                                                                ? '#fff'
                                                                : color
                                                        }
                                                        // fontSize="small"
                                                        style={
                                                            {
                                                                // marginLeft: '4px',
                                                            }
                                                        }
                                                    />
                                                </Box>
                                            );
                                        })
                                ) : (
                                    <Box
                                        style={{
                                            border: '1px solid #c2c2c2',
                                            padding: '0 8px',
                                            borderRadius: '4px',
                                            marginRight: '8px',
                                            marginBottom: '4px',
                                            color: '#c2c2c2',
                                        }}
                                    >
                                        {'No Shift Assigned'}
                                    </Box>
                                )}
                                {!!totalShiftCount &&
                                    totalShiftCount > 1 &&
                                    !!isShiftGreaterThanLimit && (
                                        <LimitTag>
                                            +{totalShiftCount - limit}
                                        </LimitTag>
                                    )}
                            </div>
                        )}
                    </Box>
                ),
            }}
            disabled={true}
            buttonStyles={{
                border: 'none',
                backgroundColor: 'transparent',
                fontSize: 14,
                textAlign: 'left',
            }}
            options={rowItem?.map((hub) =>
                !!hub?.item
                    ? { ...hub }
                    : {
                          style: {
                              minHeight: '100px',
                              padding: 0,
                              minWidth: '300px',
                          },
                          render: () => {
                              return (
                                  <Box
                                      style={{
                                          borderRadius: 8,
                                          backgroundColor: '#fff',
                                          width: '150%',
                                          padding: 8,
                                          paddingBottom: 4,
                                      }}
                                  >
                                      <Box
                                          style={{
                                              backgroundColor:
                                                  HUB_TYPE_ENUM[
                                                      hub?.primaryOperation?.toUpperCase()
                                                  ]?.bgColor,
                                              display: 'flex',
                                              alignItems: 'center',
                                              justifyContent: 'space-between',
                                              padding: '6px 12px',
                                              borderTopLeftRadius: 8,
                                              borderTopRightRadius: 8,
                                              border: 'solid 1px #f0e7e6',
                                              borderBottom: 'none',
                                          }}
                                      >
                                          <Typography
                                              variant="inherit"
                                              style={{
                                                  color: '#252525',
                                                  fontSize: 14,
                                              }}
                                          >
                                              Hub Name:{' '}
                                              <Typography
                                                  variant="inherit"
                                                  style={{
                                                      color: '#252525',
                                                      fontWeight: 600,
                                                      fontSize: 14,
                                                  }}
                                              >
                                                  {hub?.hubName}
                                              </Typography>
                                          </Typography>
                                      </Box>
                                      {!!hub?.shiftLineItems?.length &&
                                          hub?.shiftLineItems?.map(
                                              (shift, index) => {
                                                  const border =
                                                      'solid 1px #efefef';
                                                  return (
                                                      <Box
                                                          style={{
                                                              width: '100%',
                                                              //   padding: '8px',
                                                              ...(index ===
                                                              hub
                                                                  ?.shiftLineItems
                                                                  ?.length -
                                                                  1
                                                                  ? {
                                                                        borderBottomLeftRadius: 8,
                                                                        borderBottomRightRadius: 8,
                                                                        border: border,
                                                                        borderBottom:
                                                                            border,
                                                                        borderTop:
                                                                            'none',
                                                                    }
                                                                  : {
                                                                        borderLeft:
                                                                            border,
                                                                        borderRight:
                                                                            border,
                                                                    }),
                                                          }}
                                                      >
                                                          <div
                                                              style={{
                                                                  width: '100%',
                                                                  //   backgroundColor:
                                                                  //       'rgba(67, 65, 158, 0.07)',
                                                                  ...(index ===
                                                                  hub
                                                                      ?.shiftLineItems
                                                                      ?.length -
                                                                      1
                                                                      ? {
                                                                            borderBottomLeftRadius:
                                                                                '8px',
                                                                            borderBottomRightRadius:
                                                                                '8px',
                                                                        }
                                                                      : {
                                                                            borderBottom:
                                                                                '1px solid #efefef',
                                                                        }),
                                                                  padding:
                                                                      '6px 8px',
                                                              }}
                                                          >
                                                              <div
                                                                  style={{
                                                                      fontFamily:
                                                                          'Nunito',
                                                                      fontSize:
                                                                          '12px',
                                                                      fontWeight:
                                                                          '600',
                                                                      color: '#252525',
                                                                  }}
                                                              >
                                                                  {shift?.name ??
                                                                      ''}
                                                              </div>
                                                              <div
                                                                  style={{
                                                                      fontFamily:
                                                                          'Nunito',
                                                                      fontSize:
                                                                          '10px',
                                                                      fontWeight: 400,
                                                                      color: '#252525',
                                                                  }}
                                                              >
                                                                  {`${
                                                                      !!shift?.startTimeEpochMillis
                                                                          ? moment(
                                                                                shift?.startTimeEpochMillis,
                                                                            ).format(
                                                                                'HH:mm',
                                                                            )
                                                                          : ''
                                                                  } - ${
                                                                      shift?.endTimeEpochMillis
                                                                          ? moment(
                                                                                shift?.endTimeEpochMillis,
                                                                            ).format(
                                                                                'HH:mm',
                                                                            )
                                                                          : ''
                                                                  }`}
                                                              </div>
                                                          </div>
                                                      </Box>
                                                  );
                                              },
                                          )}
                                  </Box>
                              );
                          },
                      },
            )}
            {...props}
        />
    );
}
