import {
    Box,
    Button,
    Dialog,
    DialogTitle,
    IconButton,
    makeStyles,
    Slide,
    Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Searchbar from '../Searchbar';
import { connect } from 'react-redux';
import { fetchShiftUsersList } from '../../store/actions/settingsActionCreator';
import { Spinner } from '../UI';
import InfiniteScroll from 'react-infinite-scroll-component';
import ShiftColumnItemPopper from '../Containers/ShiftColumnItemPopper';
import PersonIcon from '@material-ui/icons/Person';
import AssignStaffToHubModal from './AssignStaffToHubModal';
import { CloseIcon } from '../../assets/svg';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
    modal: {
        '& .modal-head': {
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
        },
        '& .modal-title': {
            fontSize: '18px',
            fontWeight: 600,
            color: '#14112d',
            marginBottom: 6,
        },
        '& .modal-subtitle': {
            fontSize: '14px',
            fontWeight: 600,
            color: '#14112d',
        },

        '& .cancelButton': {
            color: theme.palette.text.primary,
        },
        '& .leaflet-container': {
            width: '100%',
            height: '510px',
            // borderRadius: 16,
            borderTopRightRadius: 16,
            borderBottomRightRadius: 16,
        },
    },
    iconButton: {
        padding: '0px',
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function ShiftDetailModal({
    hubInfo,
    shiftUsers,
    open,
    onClose,
    data,
    openEditModal,
    openDeleteModal,
    dispatchfetchShiftUsersList,
    ...props
}) {
    const classes = useStyles();
    const [isOpen, toggleModal] = useState(open);
    const [staffModalOpen, setStaffModalOpen] = useState(false);
    const [shiftUserPayload, setShiftUserPayload] = useState({
        shiftIds: [data?.id],
        searchText: '',
        pageNo: 1,
        pageSize: 10,
    });
    const [loading, toggleLoading] = useState(false);
    const [userList, setUserList] = useState([]);

    const fetchUsersForShift = async (payload) => {
        await toggleLoading(true);
        await dispatchfetchShiftUsersList(
            { ...payload },
            (resp) => {
                setUserList(
                    !!shiftUserPayload?.searchText
                        ? [...resp?.response?.lineItems]
                        : [...userList, ...resp?.response?.lineItems],
                );
                toggleLoading(false);
            },
            (err) => {
                toggleLoading(false);
            },
        );
    };
    const handleClose = () => {
        // Setting form data to null before closing

        toggleModal(!isOpen);
        onClose && onClose();
    };

    useEffect(() => {
        fetchUsersForShift({ ...shiftUserPayload });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shiftUserPayload]);

    return (
        <>
            <Dialog
                open={isOpen}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
                className={classes.modal}
                // fullScreen={fullScreen}
                fullWidth={true}
                maxWidth={'sm'}
            >
                <Spinner loading={loading}>
                    <DialogTitle
                        style={{
                            height: '80vh',
                            overflow: 'hidden',
                            padding: 0,
                        }}
                    >
                        <Box
                            className={`modal-head`}
                            style={{ padding: '16px 24px' }}
                        >
                            <div>
                                <Typography className={`modal-title`}>
                                    {data?.name}
                                </Typography>
                                <Typography
                                    className={`modal-subtitle`}
                                    variant="body2"
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        // fontSize: '14px',
                                    }}
                                >
                                    {hubInfo?.name}{' '}
                                    <Box style={{ padding: 7 }}>
                                        <Box
                                            style={{
                                                width: 4,
                                                height: 4,
                                                backgroundColor:
                                                    'rgba(0, 0, 0, 0.16)',
                                                borderRadius: 50,
                                            }}
                                        />
                                    </Box>{' '}
                                    {moment(data?.startTimeEpochMillis).format(
                                        'HH:mm',
                                    )}{' '}
                                    to{' '}
                                    {moment(data?.endTimeEpochMillis).format(
                                        'HH:mm',
                                    )}
                                    <Box style={{ padding: 7 }}>
                                        <Box
                                            style={{
                                                width: 4,
                                                height: 4,
                                                backgroundColor:
                                                    'rgba(0, 0, 0, 0.16)',
                                                borderRadius: 50,
                                            }}
                                        />
                                    </Box>{' '}
                                    {'Staff Count: '}
                                    <span
                                        style={{
                                            fontWeight: 600,
                                            marginLeft: 2,
                                        }}
                                    >
                                        {data?.staffCount}
                                    </span>
                                </Typography>
                            </div>
                            <div
                                style={{
                                    alignSelf: 'center',
                                    justifySelf: 'flex-end',
                                }}
                            >
                                <IconButton
                                    aria-label="delete"
                                    className={classes.iconButton}
                                    onClick={handleClose}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </div>
                        </Box>
                        <Box
                            style={{
                                margin: '0 24px',
                                marginTop: 0,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                            }}
                        >
                            <Searchbar
                                placeHolderText="Search FEs"
                                style={{
                                    color: '#14112d',
                                    width: '74%',
                                    // margin: '8px 0',
                                }}
                                onChange={(val) => {
                                    setUserList([]);
                                    setShiftUserPayload({
                                        ...shiftUserPayload,
                                        searchText: val,
                                    });
                                }}
                                onFilter={() => {
                                    console.log('search bar filtering');
                                }}
                            />
                            <Button
                                variant="outlined"
                                style={{ fontSize: 14 }}
                                color="primary"
                                onClick={(e) => {
                                    setStaffModalOpen(true);
                                }}
                                startIcon={<PersonIcon fontSize="small" />}
                                disableElevation
                                disabled={data?.slotShift}
                            >
                                {'Assign Staff'}
                            </Button>
                        </Box>
                        {/* <Box
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                                padding: '11px 16px',
                                paddingBottom: 0,
                                // margin: '8px 0',
                                width: '100%',
                                margin: '0 24px',
                                // boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.12)',
                            }}
                        >
                            <Box
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    width: '56%',
                                }}
                            >
                                <Typography
                                    variant="body1"
                                    style={{
                                        color: '#808080',
                                        fontWeight: 600,
                                        fontSize: 12,
                                    }}
                                >
                                    {'Name'}
                                </Typography>
                                <Box style={{ padding: 7 }}>
                                    <Box
                                        style={{
                                            width: 4,
                                            height: 4,
                                            backgroundColor:
                                                'rgba(0, 0, 0, 0.16)',
                                            borderRadius: 50,
                                        }}
                                    />
                                </Box>
                                <Typography
                                    variant="body1"
                                    style={{
                                        color: '#808080',
                                        fontWeight: 600,
                                        fontSize: 12,
                                    }}
                                >
                                    {'Phone Number'}
                                </Typography>
                            </Box>
                            <Box
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    width: '35%',
                                }}
                            >
                                <Typography
                                    variant="body1"
                                    style={{
                                        color: '#808080',
                                        fontWeight: 600,
                                        fontSize: 12,
                                    }}
                                >
                                    {'Hubs & Shifts'}
                                </Typography>
                            </Box>
                        </Box> */}
                        <div
                            id="scrollableDiv"
                            style={{
                                height: '60vh',
                                overflow: 'auto',
                                width: '100%',
                                padding: '16px 24px',
                                paddingTop: 0,
                            }}
                        >
                            <InfiniteScroll
                                scrollableTarget="scrollableDiv"
                                dataLength={userList.length} //This is important field to render the next data
                                next={() => {
                                    setShiftUserPayload({
                                        ...shiftUserPayload,
                                        pageNo: shiftUserPayload?.pageNo + 1,
                                    });
                                }}
                                hasMore={
                                    userList?.length < shiftUsers?.totalCount
                                }
                                loader={<h6>Loading...</h6>}
                                endMessage={
                                    <p style={{ textAlign: 'center' }}>
                                        {/* <b>Yay! You have seen it all</b> */}
                                    </p>
                                }
                            >
                                {userList?.length ? (
                                    userList.map((item, idx) => {
                                        return (
                                            <Box
                                                key={`${item?.userName}${idx}`}
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    alignItems: 'center',
                                                    justifyContent:
                                                        'space-between',
                                                    padding: '6px 16px',
                                                    border: '1px solid #efefef',
                                                    borderRadius: 8,
                                                    marginTop: 8,
                                                    // margin: '8px 0',
                                                    width: '100%',
                                                }}
                                            >
                                                <Box
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                        width: '60%',
                                                    }}
                                                >
                                                    <Typography
                                                        variant="body1"
                                                        style={{
                                                            fontSize: 14,
                                                            fontWeight: 600,
                                                            color: '#14112d',
                                                        }}
                                                    >
                                                        {item?.name}
                                                    </Typography>
                                                    <Box style={{ padding: 7 }}>
                                                        <Box
                                                            style={{
                                                                width: 4,
                                                                height: 4,
                                                                backgroundColor:
                                                                    'rgba(0, 0, 0, 0.16)',
                                                                borderRadius: 50,
                                                            }}
                                                        />
                                                    </Box>
                                                    <Typography
                                                        variant="body1"
                                                        style={{
                                                            fontSize: 14,
                                                            fontWeight: 600,
                                                            color: '#808080',
                                                        }}
                                                    >
                                                        {item?.contactNumber}
                                                    </Typography>
                                                </Box>
                                                <Box
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                        width: '40%',
                                                        // paddingTop: 2,
                                                    }}
                                                >
                                                    <ShiftColumnItemPopper
                                                        limit={1}
                                                        data={{
                                                            ...item,
                                                        }}
                                                        selectedItem={{}}
                                                    />
                                                </Box>
                                            </Box>
                                        );
                                    })
                                ) : (
                                    <Box
                                        style={{
                                            flex: 1,
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            padding: '11px 16px',
                                            border: '1px solid #efefef',
                                            borderRadius: 8,
                                            margin: '8px 0',
                                            width: '100%',
                                            minHeight: '50vh',
                                        }}
                                    >
                                        <Typography
                                            variant="body1"
                                            style={{ color: '#14112d' }}
                                        >
                                            FE not Assigned yet
                                        </Typography>
                                    </Box>
                                )}
                            </InfiniteScroll>
                        </div>
                    </DialogTitle>
                </Spinner>
            </Dialog>
            {staffModalOpen && (
                <AssignStaffToHubModal
                    open={staffModalOpen}
                    data={{
                        ...hubInfo,
                        shift: data,
                    }}
                    onClose={() => {
                        console.log('Closed');
                        // getAllStaffInfo();
                        setStaffModalOpen(false);
                        // getAllStaffInfo(staffListingPayload);
                    }}
                />
            )}
        </>
    );
}

const mapStateToProps = (state) => ({
    shiftUsers: state.settings?.shiftUsers ?? {},
});

const mapDispatchToProps = (dispatch) => ({
    dispatchfetchShiftUsersList: (payload, onSuccess, onError) =>
        dispatch(fetchShiftUsersList(payload, onSuccess, onError)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ShiftDetailModal);
