import React from 'react';
import { connect } from 'react-redux';
import {
    makeStyles,
    TableContainer,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Table,
    withStyles,
    Typography,
    TablePagination,
    Box,
    Tooltip,
} from '@material-ui/core';
import moment from 'moment';
import UpdateDistanceModal from '../../../components/Modals/UpdateDistanceModal';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        // boxShadow: 'inset 0 -1px 0 0 #ebedf8',
    },
    tableContainer: {
        maxHeight: '600px',
        border: '1px solid #ebedf8',

        '& .topLeft': {
            left: 0,
            top: 0,
            zIndex: 5,
        },
        '& .topEVLeft': {
            left: '250px',
            top: 0,
            zIndex: 5,
        },
        '& .stickyCol': {
            left: 0,
            position: 'sticky',
            zIndex: 1,
        },
        '& .stickyRowCell': {
            left: 0,
            position: 'sticky',
            zIndex: 2,
            top: 0,
        },
        '& .stickyRowCell2': {
            left: '250px',
            position: 'sticky',
            zIndex: 1,
        },
        '& .sticky-header-first': {
            position: 'sticky',
            top: 0,
            boxShadow: 'inset -2px -2px 0 0 #ebedf8 !Important',
        },
        '& .sticky-header-second': {
            position: 'sticky',
            top: 62,
        },
        '& .head': {
            border: '0px',
            backgroundColor: '#fff',
            // minWidth: 'auto',
            boxShadow: 'inset 0 -2px 0 0 #ebedf8',
        },
        '& .headName': {
            padding: '0.5rem 0 0.5rem 1rem',
            backgroundColor: '#fff',
            // width: 'auto',
            boxShadow: 'inset -2px 0 0 0 #ebedf8',
        },
        '& .cellName': {
            opacity: 1,
            //display: 'block',
            padding: '0.5rem 0 0.5rem 1rem',
            minWidth: '250px',
            maxWidth: '250px',
            boxShadow: 'inset -2px 0 0 0 #ebedf8, inset 0 -1px 0 0 #ebedf8',
        },
        '& .dataCellName': {
            minWidth: '100px',
            maxWidth: '100px',
            padding: '0.5rem 0 0.5rem 1rem',
            boxShadow: 'inset -2px 0 0 0 #ebedf8, inset 0 -1px 0 0 #ebedf8',
        },
    },
    tableCell: {
        '&:hover': {
            cursor: 'pointer',
        },
    },
}));

const DistanceListContainer = ({
    selectedMonth,
    rows,
    distance,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    getDistanceReportData,
    ...props
}) => {
    const classes = useStyles();

    const [updateDistanceModalOpen, setUpdateDistanceModalOpen] =
        React.useState(false);
    const [selectedItemData, setSelectedItemData] = React.useState({
        data: {
            dateEpochMillis: moment(selectedMonth).valueOf(),
        },
    });

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    React.useEffect(() => {
        console.log('ROWS changed', rows);
    }, [rows]);

    const handleDistanceClick = (user, item, index) => {
        var selectedDateEpochMillis = moment(selectedMonth);
        selectedDateEpochMillis.subtract(
            selectedDateEpochMillis.date() - (index + 1),
            'days',
        );
        setSelectedItemData(
            item
                ? {
                      dateEpochMillis: selectedDateEpochMillis.valueOf(),
                      staffId: user?.id,
                      name: user?.name,
                      hubName: user?.hubName,
                      gpsReading: item.userDistanceBo?.distance,
                      correctedGpsReading:
                          item.userDistanceBo?.correctedDistance,
                      odometerReading: item.userAttendanceBo?.odometerReading,
                      punchIn: {
                          kmReading: item.userAttendanceBo?.startKmReading,
                          kmS3ImageUrl:
                              item.userAttendanceBo?.startKmS3ImageUrl,
                      },
                      punchOut: {
                          kmReading: item.userAttendanceBo?.endKmReading,
                          kmS3ImageUrl: item.userAttendanceBo?.endKmS3ImageUrl,
                      },
                      reason: item.userDistanceBo?.reason,
                      notes: item.userDistanceBo?.notes,
                  }
                : {
                      dateEpochMillis: selectedDateEpochMillis.valueOf(),
                      staffId: user.id,
                      name: null,
                      gpsReading: null,
                      odometerReading: null,
                      punchIn: {
                          kmReading: null,
                          kmS3ImageUrl: null,
                      },
                      punchOut: {
                          kmReading: null,
                          kmS3ImageUrl: null,
                      },
                      reason: null,
                      notes: null,
                  },
        );
        setUpdateDistanceModalOpen(true);
    };

    function getDaysArrayByMonth(month) {
        var daysInMonth = moment(month).daysInMonth();
        var arrDays = [];

        while (daysInMonth) {
            var current = moment(month).date(daysInMonth).startOf('day');
            arrDays.push(current);
            daysInMonth--;
        }

        return arrDays;
    }

    return (
        <div>
            <TableContainer className={classes.tableContainer}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell
                                rowSpan={2}
                                className={`headName topLeft`}
                            >
                                <Typography
                                    style={{
                                        fontSize: '18px',
                                        fontWeight: 700,
                                        color: '#43419e',
                                    }}
                                >
                                    {moment(selectedMonth).format('MMM YYYY')}
                                </Typography>
                            </TableCell>
                            <TableCell
                                rowSpan={2}
                                className={`headName topEVLeft`}
                            >
                                <Typography
                                    style={{
                                        fontSize: '18px',
                                        fontWeight: 700,
                                        color: '#43419e',
                                    }}
                                >
                                    {'Data From'}
                                </Typography>
                            </TableCell>
                            {getDaysArrayByMonth(selectedMonth)
                                .reverse()
                                .map((val, index) => (
                                    <TableCell
                                        colSpan={2}
                                        className={`head sticky-header-first`}
                                        align="center"
                                    >
                                        <Typography
                                            style={{
                                                fontSize: '10px',
                                                fontWeight: 600,
                                            }}
                                        >
                                            {moment(val).format('DD')}
                                            <br />
                                            {moment(val).format('ddd')}
                                        </Typography>
                                    </TableCell>
                                ))}
                        </TableRow>
                        <TableRow>
                            {getDaysArrayByMonth(selectedMonth)
                                .reverse()
                                .map((val, index) => (
                                    <>
                                        <TableCell
                                            className={`head sticky-header-second`}
                                            align="center"
                                            style={{
                                                backgroundColor: '#fff',
                                            }}
                                        >
                                            <Typography
                                                style={{
                                                    fontSize: '10px',
                                                    fontWeight: 600,
                                                }}
                                            >
                                                {'ODO'}
                                            </Typography>
                                        </TableCell>
                                        <TableCell
                                            className={`head sticky-header-second`}
                                            align="center"
                                            style={{
                                                backgroundColor: '#f2f4f8',
                                            }}
                                        >
                                            <Typography
                                                style={{
                                                    fontSize: '10px',
                                                    fontWeight: 600,
                                                }}
                                            >
                                                {'GPS'}
                                            </Typography>
                                        </TableCell>
                                    </>
                                ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows?.staffs?.map((value, index) => {
                            let evEnabled = value?.evDistances?.length > 0;
                            return (
                                <>
                                    <TableRow
                                        key={index}
                                        style={
                                            index % 2
                                                ? {
                                                      backgroundColor:
                                                          '#f2f4f8',
                                                  }
                                                : {}
                                        }
                                    >
                                        <>
                                            <TableCell
                                                rowSpan={!!evEnabled ? 2 : 1}
                                                className={`cellName stickyRowCell`}
                                                style={
                                                    index % 2
                                                        ? {
                                                              backgroundColor:
                                                                  '#f2f4f8',
                                                          }
                                                        : {
                                                              backgroundColor:
                                                                  '#fff',
                                                          }
                                                }
                                            >
                                                <Typography
                                                    style={{
                                                        fontSize: '14px',
                                                        fontWeight: 700,
                                                    }}
                                                >
                                                    {value.name}
                                                    <br></br>
                                                </Typography>

                                                <Box
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <Typography
                                                        style={{
                                                            fontSize: '12px',
                                                            fontWeight: 600,
                                                            marginRight: 8,
                                                        }}
                                                    >
                                                        {value.vehicleNumber}
                                                    </Typography>

                                                    <Typography
                                                        style={{
                                                            fontSize: '12px',
                                                            color: '#5c5c5c',
                                                            fontWeight: 600,
                                                        }}
                                                    >
                                                        {value.hubName}
                                                    </Typography>
                                                </Box>
                                            </TableCell>
                                            <TableCell
                                                className={`dataCellName stickyRowCell2`}
                                                style={
                                                    index % 2
                                                        ? {
                                                              backgroundColor:
                                                                  '#f2f4f8',
                                                          }
                                                        : {
                                                              backgroundColor:
                                                                  '#fff',
                                                          }
                                                }
                                            >
                                                <Typography
                                                    style={{
                                                        fontSize: '14px',
                                                        fontWeight: 700,
                                                    }}
                                                >
                                                    {'DEVICE'}
                                                    <br></br>
                                                </Typography>
                                            </TableCell>
                                            {value?.distances?.map(
                                                (item, idx3) => {
                                                    {
                                                        return item ? (
                                                            <>
                                                                <TableCell
                                                                    className={`head ${classes.tableCell}`}
                                                                    align="center"
                                                                    style={
                                                                        item
                                                                            ?.userDistanceBo
                                                                            ?.correctedDistance
                                                                            ? {
                                                                                  backgroundColor:
                                                                                      'rgba(244,67,54,0.15)',
                                                                              }
                                                                            : {
                                                                                  backgroundColor:
                                                                                      '#fff',
                                                                              }
                                                                    }
                                                                    onClick={() =>
                                                                        handleDistanceClick(
                                                                            value,
                                                                            item,
                                                                            idx3,
                                                                        )
                                                                    }
                                                                >
                                                                    <Typography
                                                                        style={{
                                                                            fontSize:
                                                                                '10px',
                                                                            fontWeight: 600,
                                                                        }}
                                                                    >
                                                                        {item
                                                                            ?.userAttendanceBo
                                                                            ?.odometerReading
                                                                            ? `${item?.userAttendanceBo?.odometerReading}`
                                                                            : '-'}
                                                                    </Typography>
                                                                </TableCell>

                                                                <TableCell
                                                                    className={`head ${classes.tableCell}`}
                                                                    align="center"
                                                                    style={
                                                                        item
                                                                            ?.userDistanceBo
                                                                            ?.correctedDistance
                                                                            ? {
                                                                                  backgroundColor:
                                                                                      'rgba(244,67,54,0.15)',
                                                                              }
                                                                            : {
                                                                                  backgroundColor:
                                                                                      '#f2f4f8',
                                                                              }
                                                                    }
                                                                    onClick={() =>
                                                                        handleDistanceClick(
                                                                            value,
                                                                            item,
                                                                            idx3,
                                                                        )
                                                                    }
                                                                >
                                                                    <Typography
                                                                        style={{
                                                                            fontSize:
                                                                                '10px',
                                                                            fontWeight: 600,
                                                                        }}
                                                                    >
                                                                        {item
                                                                            ?.userDistanceBo
                                                                            ?.distanceData
                                                                            ? `${item?.userDistanceBo?.distanceData}`
                                                                            : '-'}
                                                                    </Typography>
                                                                </TableCell>
                                                            </>
                                                        ) : (
                                                            [1, 2].map(
                                                                (
                                                                    item,
                                                                    idx4,
                                                                ) => (
                                                                    <TableCell
                                                                        className={`head`}
                                                                        align="center"
                                                                        style={
                                                                            idx4 %
                                                                            2
                                                                                ? {
                                                                                      backgroundColor:
                                                                                          '#f2f4f8',
                                                                                  }
                                                                                : {
                                                                                      backgroundColor:
                                                                                          '#fff',
                                                                                  }
                                                                        }
                                                                    >
                                                                        <Typography
                                                                            style={{
                                                                                fontSize:
                                                                                    '10px',
                                                                                fontWeight: 600,
                                                                            }}
                                                                        >
                                                                            {
                                                                                '-'
                                                                            }
                                                                        </Typography>
                                                                    </TableCell>
                                                                ),
                                                            )
                                                        );
                                                    }
                                                },
                                            )}
                                        </>
                                    </TableRow>
                                    {!!evEnabled && (
                                        <TableRow
                                            key={index}
                                            style={
                                                index % 2
                                                    ? {
                                                          backgroundColor:
                                                              '#f2f4f8',
                                                      }
                                                    : {}
                                            }
                                        >
                                            <>
                                                <TableCell
                                                    className={`dataCellName stickyRowCell2`}
                                                    style={
                                                        index % 2
                                                            ? {
                                                                  backgroundColor:
                                                                      '#f2f4f8',
                                                              }
                                                            : {
                                                                  backgroundColor:
                                                                      '#fff',
                                                              }
                                                    }
                                                >
                                                    <Typography
                                                        style={{
                                                            fontSize: '14px',
                                                            fontWeight: 700,
                                                        }}
                                                    >
                                                        {'EV'}
                                                        <br></br>
                                                    </Typography>
                                                </TableCell>
                                                {value?.evDistances?.map(
                                                    (item, idx3) => {
                                                        {
                                                            return item ? (
                                                                <>
                                                                    <TableCell
                                                                        className={`head ${classes.tableCell}`}
                                                                        align="center"
                                                                        style={
                                                                            item
                                                                                ?.userDistanceBo
                                                                                ?.correctedDistance
                                                                                ? {
                                                                                      backgroundColor:
                                                                                          'rgba(244,67,54,0.15)',
                                                                                  }
                                                                                : {
                                                                                      backgroundColor:
                                                                                          '#fff',
                                                                                  }
                                                                        }
                                                                        onClick={() =>
                                                                            handleDistanceClick(
                                                                                value,
                                                                                item,
                                                                                idx3,
                                                                            )
                                                                        }
                                                                    >
                                                                        <Typography
                                                                            style={{
                                                                                fontSize:
                                                                                    '10px',
                                                                                fontWeight: 600,
                                                                            }}
                                                                        >
                                                                            {item
                                                                                ?.userAttendanceBo
                                                                                ?.odometerReading
                                                                                ? `${item?.userAttendanceBo?.odometerReading}`
                                                                                : '-'}
                                                                        </Typography>
                                                                    </TableCell>

                                                                    <TableCell
                                                                        className={`head ${classes.tableCell}`}
                                                                        align="center"
                                                                        style={
                                                                            item
                                                                                ?.userDistanceBo
                                                                                ?.correctedDistance
                                                                                ? {
                                                                                      backgroundColor:
                                                                                          'rgba(244,67,54,0.15)',
                                                                                  }
                                                                                : {
                                                                                      backgroundColor:
                                                                                          '#f2f4f8',
                                                                                  }
                                                                        }
                                                                        onClick={() =>
                                                                            handleDistanceClick(
                                                                                value,
                                                                                item,
                                                                                idx3,
                                                                            )
                                                                        }
                                                                    >
                                                                        <Typography
                                                                            style={{
                                                                                fontSize:
                                                                                    '10px',
                                                                                fontWeight: 600,
                                                                            }}
                                                                        >
                                                                            {item
                                                                                ?.userDistanceBo
                                                                                ?.distanceData
                                                                                ? `${item?.userDistanceBo?.distanceData}`
                                                                                : '-'}
                                                                        </Typography>
                                                                    </TableCell>
                                                                </>
                                                            ) : (
                                                                [1, 2].map(
                                                                    (
                                                                        item,
                                                                        idx4,
                                                                    ) => (
                                                                        <TableCell
                                                                            className={`head`}
                                                                            align="center"
                                                                            style={
                                                                                idx4 %
                                                                                2
                                                                                    ? {
                                                                                          backgroundColor:
                                                                                              '#f2f4f8',
                                                                                      }
                                                                                    : {
                                                                                          backgroundColor:
                                                                                              '#fff',
                                                                                      }
                                                                            }
                                                                        >
                                                                            <Typography
                                                                                style={{
                                                                                    fontSize:
                                                                                        '10px',
                                                                                    fontWeight: 600,
                                                                                }}
                                                                            >
                                                                                {
                                                                                    '-'
                                                                                }
                                                                            </Typography>
                                                                        </TableCell>
                                                                    ),
                                                                )
                                                            );
                                                        }
                                                    },
                                                )}
                                            </>
                                        </TableRow>
                                    )}
                                </>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 50, 100]}
                component="div"
                count={rows?.totalCount}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />
            {updateDistanceModalOpen && (
                <UpdateDistanceModal
                    open={updateDistanceModalOpen}
                    data={selectedItemData}
                    onClose={() => {
                        setUpdateDistanceModalOpen(false);
                        getDistanceReportData(selectedMonth);
                    }}
                />
            )}
        </div>
    );
};

const mapStateToProps = (state) => ({
    distance: state.report.distanceReport,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(DistanceListContainer);
