import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
    Button,
    Box,
    Typography,
    withStyles,
    makeStyles,
} from '@material-ui/core';

import {
    Timeline,
    TimelineItem,
    TimelineSeparator,
    TimelineConnector,
    TimelineContent,
    TimelineDot,
    TimelineOppositeContent,
} from '@material-ui/lab';

import { InMarker, OutMarker, DefaultProfileIcon } from '../../assets/svg';

import Modal from './Modal';
import { LightBoxImage } from '../UI';

const StyledTimelineDot = withStyles((theme) => ({
    root: {
        display: 'flex',
        alignSelf: 'baseline',
        borderStyle: 'solid',
        borderWidth: 1,
        borderRadius: '50%',
        boxShadow: theme.shadows[2],
        width: 20,
        height: 20,
        padding: 0,
        margin: '8px 0 0 0 ',
    },
    defaultPrimary: {
        borderColor: '#fff',
        color: '#1bb55c',
        backgroundColor: '#1bb55c',
    },
    defaultSecondary: {
        borderColor: '#fff',
        color: '#e74c3c',
        backgroundColor: '#e74c3c',
    },
}))(TimelineDot);

const StyledTimelineConnector = withStyles((theme) => ({
    root: {
        width: 2,
        backgroundColor: '#eaedf3',
        flexGrow: 1,
    },
}))(TimelineConnector);

const useStyles = makeStyles((theme) => ({
    oppositeContent: {
        flex: 0,
        padding: 0,
    },
}));

function AttendanceActivityModal({ open, onClose, data, activity, ...props }) {
    const classes = useStyles();
    const [isOpen, toggleModal] = useState(open);
    const handleClose = () => {
        toggleModal(!isOpen);
        onClose && onClose();
    };

    useEffect(() => {
        console.log('activity modal', data);
    }, []);

    const actions = [
        <Button
            key={1}
            variant="contained"
            color="primary"
            className={`saveButton`}
            onClick={handleClose}
            disableElevation
        >
            {'Close'}
        </Button>,
    ];

    return (
        <Modal
            onClose={handleClose}
            open={isOpen}
            title={`Attendance - ${activity?.dateDisplay}`}
            actions={actions}
            className={classes.modal}
            containerStyle={{
                padding: '0px 24px 8px 24px',
            }}
        >
            <Typography
                style={{
                    fontSize: '18px',
                    fontWeight: 700,
                }}
            >
                {data?.name}
            </Typography>
            <Typography
                style={{
                    fontSize: '12px',
                    fontWeight: 600,
                }}
            >
                {data?.displayText}
            </Typography>

            <Timeline
                style={{
                    padding: '0px 16px',
                }}
            >
                <TimelineItem>
                    <TimelineOppositeContent
                        className={classes.oppositeContent}
                    ></TimelineOppositeContent>
                    <TimelineSeparator className={classes.mainContent}>
                        <StyledTimelineDot color="primary" />
                        <StyledTimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                        <Box
                            style={{
                                margin: '0 0 0 16px',
                            }}
                        >
                            <Typography
                                style={{
                                    marginBottom: '16px',
                                    fontSize: '18px',
                                    fontWeight: 700,
                                }}
                            >
                                {`In Time - ${
                                    activity?.punchIn?.time
                                        ? activity?.punchIn?.time
                                        : ''
                                }`}
                            </Typography>
                            <Box>
                                <Box
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        marginBottom: '16px',
                                    }}
                                >
                                    {activity?.punchIn?.s3ImageUrl ? (
                                        <img
                                            src={activity?.punchIn?.s3ImageUrl}
                                            style={{
                                                width: '64px',
                                                height: '64px',
                                                objectFit: 'contain',
                                            }}
                                        />
                                    ) : (
                                        <DefaultProfileIcon />
                                    )}

                                    <Box
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            margin: '0 0 0 12px',
                                        }}
                                    >
                                        <InMarker
                                            style={{
                                                width: '32px',
                                                height: '32px',
                                                margin: '0 8px 0 0',
                                            }}
                                        />
                                        {/* 
                                        <img
                                            src={InMarker}
                                            style={{
                                                width: '24px',
                                                height: '24px',
                                                margin: '0 8px 0 0',
                                            }} */}
                                        <Typography
                                            style={{
                                                fontWeight: 600,
                                            }}
                                        >
                                            {activity?.punchIn?.address
                                                ? activity?.punchIn?.address
                                                : '-'}
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box>
                                    {activity?.punchIn?.lateBy ? (
                                        <Box
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                margin: '0 0 12px 0',
                                            }}
                                        >
                                            <Typography
                                                style={{
                                                    display: 'flex',
                                                    flex: '0 1 40%',
                                                }}
                                            >
                                                Late By
                                            </Typography>
                                            <Typography
                                                style={{
                                                    fontWeight: 600,
                                                }}
                                            >
                                                {': '}
                                                {activity?.punchIn?.lateBy
                                                    ? activity?.punchIn?.lateBy
                                                    : ' - '}
                                            </Typography>
                                        </Box>
                                    ) : (
                                        <></>
                                    )}
                                    <Box
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            margin: '0 0 12px 0',
                                        }}
                                    >
                                        <Typography
                                            style={{
                                                display: 'flex',
                                                flex: '0 1 40%',
                                            }}
                                        >
                                            Approved By
                                        </Typography>
                                        <Typography
                                            style={{
                                                fontWeight: 600,
                                            }}
                                        >
                                            {': '}
                                            {activity?.punchIn?.approvedBy
                                                ? activity?.punchIn?.approvedBy
                                                : ' - '}
                                        </Typography>
                                    </Box>
                                    <Box
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            margin: '0 0 16px 0',
                                        }}
                                    >
                                        <Typography
                                            style={{
                                                display: 'flex',
                                                flex: '0 1 40%',
                                            }}
                                        >
                                            Approval Time
                                        </Typography>
                                        <Typography
                                            style={{
                                                fontWeight: 600,
                                            }}
                                        >
                                            {': '}
                                            {activity?.punchIn?.approvalTime
                                                ? activity?.punchIn
                                                      ?.approvalTime
                                                : ' - '}
                                        </Typography>
                                    </Box>
                                    <Box
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            margin: '0 0 16px 0',
                                        }}
                                    >
                                        <Typography
                                            style={{
                                                display: 'flex',
                                                flex: '0 1 40%',
                                            }}
                                        >
                                            Notes
                                        </Typography>
                                        <Typography
                                            style={{
                                                fontWeight: 600,
                                            }}
                                        >
                                            {': '}
                                            {activity?.punchIn?.notes
                                                ? activity?.punchIn?.notes
                                                : ' - '}
                                        </Typography>
                                    </Box>
                                    {!!activity?.punchIn?.kmS3ImageUrl &&
                                        !!activity?.punchIn?.kmReading && (
                                            <Box
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    margin: '0 0 16px 0',
                                                }}
                                            >
                                                <LightBoxImage
                                                    containerStyles={{
                                                        width: 40,
                                                        height: 40,
                                                        marginRight: 5,
                                                    }}
                                                    imageStyles={{
                                                        width: 40,
                                                        height: 40,
                                                        margin: 'auto',
                                                        borderRadius: '16px',
                                                        objectFit: 'contain',
                                                    }}
                                                    source={
                                                        activity?.punchIn
                                                            ?.kmS3ImageUrl
                                                    }
                                                />
                                                {/* <img
                                                    src={
                                                        activity?.punchIn
                                                            ?.kmS3ImageUrl
                                                    }
                                                    style={{
                                                        width: '40px',
                                                        height: '40px',
                                                        objectFit: 'contain',
                                                    }}
                                                /> */}
                                                <Box
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        justifyContent:
                                                            'center',
                                                        marginLeft: 10,
                                                    }}
                                                >
                                                    <Typography
                                                        style={{
                                                            fontSize: 14,
                                                            fontWeight: 400,
                                                        }}
                                                    >
                                                        Start Km Reading
                                                    </Typography>
                                                    <Typography
                                                        style={{
                                                            fontWeight: 600,
                                                        }}
                                                    >
                                                        {
                                                            activity?.punchIn
                                                                ?.kmReading
                                                        }
                                                        {' km'}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        )}
                                </Box>
                            </Box>
                        </Box>
                    </TimelineContent>
                </TimelineItem>
                {!!activity?.punchOut?.time && (
                    <TimelineItem>
                        <TimelineOppositeContent
                            className={classes.oppositeContent}
                        ></TimelineOppositeContent>
                        <TimelineSeparator>
                            <StyledTimelineDot color="secondary" />
                        </TimelineSeparator>
                        <TimelineContent>
                            <Box
                                style={{
                                    margin: '0 0 0 8px',
                                }}
                            >
                                <Typography
                                    style={{
                                        marginBottom: '16px',
                                        fontSize: '18px',
                                        fontWeight: 700,
                                    }}
                                >
                                    {`Out Time - ${
                                        activity?.punchOut?.time
                                            ? activity?.punchOut?.time
                                            : ''
                                    }`}
                                </Typography>
                                <Box>
                                    <Box
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                        }}
                                    >
                                        {activity?.punchOut?.s3ImageUrl ? (
                                            <img
                                                src={
                                                    activity?.punchOut
                                                        ?.s3ImageUrl
                                                }
                                                style={{
                                                    width: '64px',
                                                    height: '64px',
                                                    objectFit: 'contain',
                                                }}
                                            />
                                        ) : (
                                            <DefaultProfileIcon />
                                        )}

                                        <Box
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                margin: '0 0 0 12px',
                                            }}
                                        >
                                            <OutMarker
                                                style={{
                                                    width: '32px',
                                                    height: '32px',
                                                    margin: '0 8px 0 0',
                                                }}
                                            />
                                            {/* <img
                                            src={OutMarker}
                                            style={{
                                                width: '24px',
                                                height: '24px',
                                                margin: '0 8px 0 0',
                                            }}
                                        /> */}
                                            <Typography
                                                style={{
                                                    fontWeight: 600,
                                                }}
                                            >
                                                {activity?.punchOut?.address
                                                    ? activity?.punchOut
                                                          ?.address
                                                    : '-'}
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Box>
                                        {/* <Box
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            margin: '8px 0 8px 0',
                                        }}
                                    >
                                        <Typography
                                            style={{
                                                display: 'flex',
                                                flex: '0 1 40%',
                                            }}
                                        >
                                            Late By
                                        </Typography>
                                        <Typography>: 04:00 Hrs</Typography>
                                    </Box> */}
                                        <Box
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                margin: '8px 0 8px 0',
                                            }}
                                        >
                                            <Typography
                                                style={{
                                                    display: 'flex',
                                                    flex: '0 1 40%',
                                                }}
                                            >
                                                Approved By
                                            </Typography>
                                            <Typography
                                                style={{
                                                    fontWeight: 600,
                                                }}
                                            >
                                                {': '}
                                                {activity?.punchOut?.approvedBy
                                                    ? activity?.punchOut
                                                          ?.approvedBy
                                                    : ' - '}
                                            </Typography>
                                        </Box>
                                        <Box
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                margin: '0 0 8px 0',
                                            }}
                                        >
                                            <Typography
                                                style={{
                                                    display: 'flex',
                                                    flex: '0 1 40%',
                                                }}
                                            >
                                                Approval Time
                                            </Typography>
                                            <Typography
                                                style={{
                                                    fontWeight: 600,
                                                }}
                                            >
                                                {': '}
                                                {activity?.punchOut
                                                    ?.approvalTime
                                                    ? activity?.punchOut
                                                          ?.approvalTime
                                                    : ' - '}
                                            </Typography>
                                        </Box>
                                        <Box
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                margin: '0 0 8px 0',
                                            }}
                                        >
                                            <Typography
                                                style={{
                                                    display: 'flex',
                                                    flex: '0 1 40%',
                                                }}
                                            >
                                                Notes
                                            </Typography>
                                            <Typography
                                                style={{
                                                    fontWeight: 600,
                                                }}
                                            >
                                                {': '}
                                                {activity?.punchOut?.notes
                                                    ? activity?.punchOut?.notes
                                                    : ' - '}
                                            </Typography>
                                        </Box>
                                        {!!activity?.punchOut?.kmS3ImageUrl &&
                                            !!activity?.punchOut?.kmReading && (
                                                <Box
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        margin: '0 0 16px 0',
                                                    }}
                                                >
                                                    <LightBoxImage
                                                        containerStyles={{
                                                            width: 40,
                                                            height: 40,
                                                            marginRight: 5,
                                                        }}
                                                        imageStyles={{
                                                            width: 40,
                                                            height: 40,
                                                            margin: 'auto',
                                                            borderRadius: '8px',
                                                            objectFit:
                                                                'contain',
                                                        }}
                                                        source={
                                                            activity?.punchOut
                                                                ?.kmS3ImageUrl
                                                        }
                                                    />
                                                    {/* <img
                                                        src={
                                                            activity?.punchOut
                                                                ?.kmS3ImageUrl
                                                        }
                                                        style={{
                                                            width: '40px',
                                                            height: '40px',
                                                            objectFit:
                                                                'contain',
                                                        }}
                                                    /> */}
                                                    <Box
                                                        style={{
                                                            display: 'flex',
                                                            flexDirection:
                                                                'column',
                                                            justifyContent:
                                                                'center',
                                                            marginLeft: 10,
                                                        }}
                                                    >
                                                        <Typography
                                                            style={{
                                                                fontSize: 14,
                                                                fontWeight: 400,
                                                            }}
                                                        >
                                                            End Km Reading
                                                        </Typography>
                                                        <Typography
                                                            style={{
                                                                fontWeight: 600,
                                                            }}
                                                        >
                                                            {
                                                                activity
                                                                    ?.punchOut
                                                                    ?.kmReading
                                                            }
                                                            {' km'}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            )}
                                    </Box>
                                </Box>
                            </Box>
                        </TimelineContent>
                    </TimelineItem>
                )}
            </Timeline>
        </Modal>
    );
}
const mapStateToProps = (state) => ({
    activity: state.attendance.timelineActivity,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AttendanceActivityModal);
