import { Box, Typography } from '@material-ui/core';
import moment from 'moment';
import React from 'react';

export default function LocationPointCard({ title, dates, address, timeDiff }) {
    return (
        <Box
            elevation={2}
            style={{
                background: '#f6f7f9',
                padding: 6,
                borderRadius: 8,
            }}
        >
            <Box
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginBottom: 8,
                }}
            >
                <Typography
                    style={{
                        fontSize: 14,
                        fontWeight: 700,
                    }}
                >
                    {title}
                </Typography>
                <Typography
                    style={{
                        fontSize: 12,
                        fontWeight: 700,
                        // margin: '4px 0',
                        // opacity: 0.8,
                    }}
                >
                    {dates}
                </Typography>
            </Box>

            <Typography
                style={{
                    marginBottom: 8,
                }}
            >
                {address}
            </Typography>
            {!!timeDiff && (
                <Typography
                    style={{
                        fontSize: 14,
                        fontWeight: 600,
                    }}
                >
                    {timeDiff}
                </Typography>
            )}
        </Box>
    );
}
