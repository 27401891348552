import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { Polyline, Marker, Popup, useMap } from 'react-leaflet';
import {
    redMarker,
    greenMarker,
    orangeFilledMarker,
    purpleMarker,
    blueGreenMarker,
} from '../../../../components/Map/MarkerIcons';
import moment from 'moment';
import { getTimeAsDuration, getTimeDifference } from '../../../../utils/helper';
import { createCustomMarkerHtml } from '../../../../components/Map/BikeIcon';
interface TrackingMarkerProps {
    playbackPosition?: any;
    label?: any;
    type?: any;
}
interface LatLngLiteral {
    lat: number;
    lng: number;
}
const TrackingMarker: React.FC<TrackingMarkerProps> = ({
    playbackPosition,
    label = '',
    type,
}) => {
    const position: any =
        playbackPosition?.lat && playbackPosition?.lng
            ? {
                  lat: playbackPosition?.lat,
                  lng: playbackPosition?.lng,
              }
            : {
                  lat: 0,
                  lng: 0,
              };
    return position ? (
        <Marker
            // if position changes, marker will drift its way to new position
            position={position}
            icon={createCustomMarkerHtml(
                playbackPosition && playbackPosition?.angle
                    ? playbackPosition?.angle
                    : '0',
                'ON',
                label,
                true,
            )}
        ></Marker>
    ) : (
        <></>
    );
};

export default TrackingMarker;
