import React from 'react';
import { Box, Typography } from '@material-ui/core';
import moment from 'moment';
interface TimerCardProps {
    routes: any;
    currentIndices: any;
}

const TimerCard: React.FC<TimerCardProps> = ({
    routes = [],
    currentIndices,
}) => {
    return (
        <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
            flexDirection={'row'}
            style={{
                position: 'absolute',
                top: '0%',
                left: '40%',
                zIndex: 9999999999,
            }}
        >
            {routes?.map((route: any, i: number) => {
                const markerPos = route?.playBackData?.[currentIndices?.[i]];
                return !!currentIndices?.[i] &&
                    !!markerPos &&
                    !!markerPos?.time ? (
                    <Box
                        style={{
                            backgroundColor: 'white',
                            borderRadius: '12px',
                            boxShadow: '0 0 10px rgba(0,0,0,0.5)',
                            padding: '4px 8px',
                            margin: 5,
                        }}
                    >
                        <Typography
                            style={{
                                fontWeight: 800,
                                backgroundColor: 'white',
                                color: route?.ui?.polyLineColor ?? 'black',
                            }}
                        >
                            {moment(markerPos?.time).format('hh:mm A')}
                        </Typography>
                    </Box>
                ) : (
                    <></>
                );
            })}
        </Box>
    );
};

export default TimerCard;
