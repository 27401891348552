import React from 'react';

export function OnlineStateIcon({ fill = 'white', style }) {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...{ style }}
        >
            <path
                d="M14.6663 8.0024C14.6663 6.1624 13.9197 4.49573 12.713 3.28906L12.0063 3.99573C13.033 5.0224 13.6663 6.4424 13.6663 8.0024C13.6663 9.5624 13.033 10.9824 12.0063 12.0091L12.713 12.7157C13.9197 11.5091 14.6663 9.8424 14.6663 8.0024ZM2.33301 8.0024C2.33301 6.4424 2.96634 5.0224 3.99301 3.99573L3.28634 3.28906C2.07967 4.49573 1.33301 6.1624 1.33301 8.0024C1.33301 9.8424 2.07967 11.5091 3.28634 12.7157L3.99301 12.0091C2.96634 10.9824 2.33301 9.5624 2.33301 8.0024ZM11.6663 8.0024C11.6663 9.01573 11.253 9.92906 10.593 10.5957L11.2997 11.3024C12.1463 10.4557 12.6663 9.28906 12.6663 8.0024C12.6663 6.71573 12.1463 5.54906 11.2997 4.7024L10.593 5.40906C11.253 6.07573 11.6663 6.98906 11.6663 8.0024ZM4.69967 11.3024L5.40634 10.5957C4.73967 9.92906 4.33301 9.01573 4.33301 8.0024C4.33301 6.98906 4.74634 6.07573 5.40634 5.40906L4.69967 4.7024C3.85301 5.54906 3.33301 6.71573 3.33301 8.0024C3.33301 9.28906 3.85301 10.4557 4.69967 11.3024Z"
                fill={fill}
            />
            <circle cx="8" cy="8" r="2" fill={fill} />
        </svg>
    );
}
