import React from 'react';
import { CustomTextField } from '../../components/Textfield';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { withRouter } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    privacyTitle: {
        fontSize: 20,
        fontWeight: 600,
        marginBottom: 22,
        marginTop: 22,
    },

    paragraphy: {
        marginBottom: 8,
    },
}));
const Privacy = () => {
    const classes = useStyles();

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <Box
            style={{
                backgroundColor: '#fff',
                // marginBottom: '2rem',
            }}
        >
            <Box
                style={{
                    height: '15%',
                    padding: '14px 0 14px 100px',
                    boxShadow: '0 1px 1px 0 rgba(0, 0, 0, 0.07)',
                }}
            >
                <Typography style={{ fontSize: '32px', fontWeight: 600 }}>
                    StaffPay
                </Typography>
                <Typography style={{ fontSize: '12px' }}>
                    Powered by StaffPay
                </Typography>
                <Typography style={{ fontSize: '12px' }}>
                    Solutions Private Limited.
                </Typography>
            </Box>

            <Box
                className="privacy-content"
                style={{
                    margin: '2rem 10rem 0 10rem',
                    textAlign: 'justify',
                    backgroundColor: '#f1f3f6',
                    padding: '2px 10px 2px 10px',
                }}
            >
                <Typography className={classes.privacyTitle}>
                    Privacy Policy
                </Typography>
                <Typography className={classes.paragraphy}>
                    This privacy policy (“<strong>Policy</strong>”) explains our
                    policy regarding the collection, use, disclosure and
                    transfer of your information by StaffPay Solutions Private
                    Limited and/or its subsidiary(ies) and/or affiliate(s)
                    (collectively referred to as the “<strong>Company</strong>”
                    / “<strong>We</strong>”), which operates the website
                    https://staffpay.in ("<strong>Site</strong>"), “
                    <strong>StaffPay</strong>” (the “<strong>App</strong>”)
                    available on Google Play Store, iOS and other similar
                    platforms and other services including but not limited to
                    recording credit/payment, communicating dues/balances via
                    the site and application, any mobile or internet connected
                    device or otherwise (collectively the "
                    <strong>StaffPay App Service</strong>"). This Privacy Policy
                    forms part and parcel of the Terms of Use for the StaffPay
                    App Services. Capitalized terms which have been used here
                    but are undefined shall have the same meaning as attributed
                    to them in the Terms of Use.
                </Typography>
                <Typography className={classes.paragraphy}>
                    As we update, improve and expand the StaffPay App Service,
                    this Policy may change, so please refer back to it
                    periodically. By accessing the Site, App or otherwise using
                    the StaffPay App Services, you consent to the collection,
                    storage, and use of the personal information you provide
                    (including any changes thereto as provided by you) for any
                    of the services that we offer.
                </Typography>
                <Typography className={classes.paragraphy}>
                    The Company respects the privacy of the Users of the
                    StaffPay App Services and is committed to reasonably protect
                    it in all respects. The information about the User as
                    collected by the Company is: (a) information supplied by
                    users and (b) information automatically tracked while using
                    a mobile device having StaffPay App Services enabled
                    (collectively referred to as “<strong>Information</strong>
                    ”).
                </Typography>
                <Typography className={classes.privacyTitle}>
                    Information supplied by users
                </Typography>
                <Typography className={classes.paragraphy}>
                    To avail certain Services on the StaffPay App, Users are
                    required to provide certain personally identifiable
                    information for the registration process which may include:
                    a) their name, b) email address, c) phone number, d)
                    business entity information and any other such information
                    as required. The Information as supplied by the users
                    enables us to improve the StaffPay App Services and provide
                    you the most user-friendly experience. In some
                    cases/provision of certain service(s) or utility(ies), we
                    may require your contact address as well. Further, upon
                    downloading the App, Users are requested to share access to
                    their contact list (as stored on their mobile device on
                    which the App is downloaded), so as to enable the User to
                    share payment/credit updates with his customers (who might
                    be or might not be Users). We also do not share this data
                    with any third party.
                </Typography>
                <Typography className={classes.paragraphy}>
                    We may access a user's inventory of installed apps and treat
                    this data as personal or sensitive data subject to the
                    Privacy Policy, Secure Transmission, and Prominent
                    Disclosure requirements of User.
                </Typography>
                <Typography className={classes.paragraphy}>
                    All required Information is service dependent and the
                    Company may use the above said User Information to,
                    maintain, protect, and improve the StaffPay App Services,
                    and for developing new services. We may also use your email
                    address and phone number without further consent for
                    non-marketing or administrative purposes (such as notifying
                    you of major changes, for customer service purposes,
                    providing information about updates to StaffPay App
                    Services, billing, etc.).
                </Typography>
                <Typography className={classes.paragraphy}>
                    Any personally identifiable Information provided by you will
                    not be considered as sensitive if it is freely available
                    and/or accessible in the public domain. Further, any
                    reviews, comments, messages, blogs posted / uploaded /
                    conveyed / communicated by Users on the public sections of
                    the Site or an Application Store (like the App Store or Play
                    Store) becomes published content and is not considered
                    personally identifiable Information which is subject to this
                    Privacy Policy.
                </Typography>
                <Typography className={classes.paragraphy}>
                    In case you choose to decline to submit personally
                    identifiable Information on the StaffPay App/Site, we may
                    not be able to provide certain services on the App/Site to
                    you. We will make reasonable efforts to notify you of the
                    same at the appropriate time. In any case, we will not be
                    liable and or responsible for the denial of certain services
                    to you for lack of you providing the necessary personal
                    Information.
                </Typography>
                <Typography className={classes.paragraphy}>
                    When you register with the StaffPay App Services, we may
                    contact you from time to time about updation of your
                    personal Information to provide the Users such features that
                    we believe may benefit/interest you.
                </Typography>
                <Typography className={classes.privacyTitle}>
                    Information automatically tracked while using the app or
                    site
                </Typography>
                <Typography className={classes.paragraphy}>
                    Credit/Payment Records: We store all your credit/payment
                    records in a cloud-based environment using the services of
                    3rd party infrastructure service providers.
                </Typography>
                <Typography className={classes.paragraphy}>
                    Customer Information: We store the contact information of
                    your customers. We also keep a log of all the credit/payment
                    related communication to the customers.
                </Typography>
                <Typography className={classes.paragraphy}>
                    Demographic and Related Information: We may reference other
                    sources of demographic and other information in order to
                    provide you with more targeted communications and
                    promotions. We use Google Analytics, among others, to track
                    the User behaviour on our website.
                </Typography>
                <Typography className={classes.paragraphy}>
                    Log File Information: Our Servers automatically collect
                    limited Information about your device's connection to the
                    internet, including your IP address, when you visit our Site
                    or use the App. We automatically receive and log Information
                    from the App and/or your browser including but not limited
                    to IP address, your device or computer's name, and your
                    operating system, We may also collect log Information from
                    your device, including but not limited to your location, IP
                    address, your device's name, device's serial number or
                    unique identification number (e.g. UDID on your iOS device,
                    Android ID or ADID on your Android Device), your device
                    operating system, browser type and version, CPU speed, and
                    connection speed etc.
                </Typography>
                <Typography className={classes.paragraphy}>
                    Cookies: To improve the responsiveness of the Site for our
                    Users, we may use "<strong>cookies</strong>", or similar
                    electronic tools to collect information to assign each
                    visitor a unique, random number as a User Identification
                    (User ID) to understand the User's individual interests
                    using the identified computer. Our partners may also assign
                    their own cookies to your browser, a process that we do not
                    control.
                </Typography>
                <Typography className={classes.privacyTitle}>
                    Location Information
                </Typography>
                <Typography className={classes.paragraphy}>
                    When you use our App, we may collect and store information
                    about your location by converting your IP address into a
                    rough geo-location or by accessing your mobile device’s GPS
                    coordinates or course location if you enable location
                    services on your device. We may use location information to
                    improve and personalize our Services for you. If you do not
                    want us to collect location information, you may disable
                    that feature on your mobile device, but you will not be able
                    to access all of our Services if you disable that feature on
                    your mobile device.
                </Typography>
                <Typography className={classes.privacyTitle}>
                    Link to third party sites/ad-servers
                </Typography>
                <Typography className={classes.paragraphy}>
                    The Site may include links to other websites. Such websites
                    are governed by their respective privacy policies, which are
                    beyond our control. Once you leave our servers (you can tell
                    where you are by checking the URL in the location bar on
                    your browser), use of any information you provide is
                    governed by the privacy policy of the operator of the site
                    you are visiting. That policy may differ from ours. If you
                    can't find the privacy policy of any of these sites via a
                    link from the website's homepage, you should contact the
                    website directly for more information.
                </Typography>
                <Typography className={classes.privacyTitle}>
                    Information sharing
                </Typography>
                <Typography className={classes.paragraphy}>
                    The Company may share the Information (including sensitive
                    personal information) with any third party without obtaining
                    the prior consent of the User in the following limited
                    circumstances:
                </Typography>
                <Typography className={classes.paragraphy}>
                    When it is requested or required by law or by any court or
                    governmental agency or authority to disclose, for the
                    purpose of verification of identity, or for the prevention,
                    detection, investigation including cyber incidents, or for
                    prosecution and punishment of offences. These disclosures
                    are made in good faith and belief that such disclosure is
                    reasonably necessary for enforcing the Terms of Use or for
                    complying with the applicable laws and regulations. No
                    personally identifiable data of an individual User such as
                    name, phone number, email address, spends data, card details
                    etc. would be shared with any other User and/or third party
                    unless explicitly approved by the concerned individual User
                    himself/herself. However, if this conflicts with conditions
                    mentioned in the immediate point above, this condition would
                    become null and void. The Company may also present
                    information related to credit records, User spends, patterns
                    and User data tracked by the company only in the form of
                    aggregated statistics on data such as overall app usage by
                    date, time, balances, etc. within our app/site or to our
                    partners.
                </Typography>
                <Typography className={classes.privacyTitle}>
                    Accessing and updating personal information
                </Typography>
                <Typography className={classes.paragraphy}>
                    When you use the Site (or any of its sub sites), we make
                    good faith efforts to provide you, as and when requested by
                    you, with access to your personal Information and shall
                    further ensure that any personal Information or sensitive
                    personal data or Information found to be inaccurate or
                    deficient shall be corrected or amended as feasible, subject
                    to any requirement for such personal Information or
                    sensitive personal data or Information to be retained by law
                    or for legitimate business purposes. We ask individual Users
                    to identify themselves and the Information requested to be
                    accessed, corrected or removed before processing such
                    requests, and we may decline to process requests that are
                    unreasonably repetitive or systematic, require
                    disproportionate technical effort, jeopardize the privacy of
                    others, or would be extremely impractical (for instance,
                    requests concerning Information residing on backup tapes),
                    or for which access is not otherwise required. In any case,
                    where we provide Information access and correction, we
                    perform this service free of charge, except if doing so
                    would require a disproportionate effort. Because of the way
                    we maintain certain services, after you delete your
                    Information, residual copies may take a period of time
                    before they are deleted from our active servers and may
                    remain in our backup systems.
                </Typography>
                <Typography className={classes.privacyTitle}>
                    Information storage and backup
                </Typography>
                <Typography className={classes.paragraphy}>
                    From time to time, we take backup of your data on StaffPay
                    App on our cloud database. This is done for the purpose of
                    enabling Users to get their data back in case their phone's
                    data becomes unusable, or phone gets lost, or the User moves
                    to a new phone device. We also use this backup to provide
                    useful insights and Information related to their spends such
                    as weekly spends in a month, insights related to spend
                    behaviour, etc., and to provide Information related to User
                    spends, patterns and User data in the form of aggregated and
                    anonymized statistics on data such as User spends by
                    category, date, time, bank balances, etc.
                </Typography>
                <Typography className={classes.paragraphy}>
                    We shall keep your information in our records till the
                    period of time where we can potentially offer the StaffPay
                    App Services to you. After such period of time where
                    StaffPay App Services are no more relevant to you, we remove
                    all the Information from our records.
                </Typography>
                <Typography className={classes.privacyTitle}>
                    Information security
                </Typography>
                <Typography className={classes.paragraphy}>
                    We take appropriate security measures to protect against
                    unauthorized access to or unauthorized alteration,
                    disclosure or destruction of data. These include internal
                    reviews of our data collection, storage and processing
                    practices and security measures, including appropriate
                    encryption and physical security measures to guard against
                    unauthorized access to systems where we store personal data.
                    All Information gathered on the StaffPay App is securely
                    stored within the controlled database. Access to the servers
                    is password-protected and is strictly limited.
                </Typography>
                <Typography className={classes.privacyTitle}>
                    Updates/Changes
                </Typography>
                <Typography className={classes.paragraphy}>
                    We may alter our Privacy Policy from time to time to
                    incorporate necessary changes in technology, applicable law
                    or any other variant. In any case, we reserve the right to
                    change (at any point of time) the terms of this Privacy
                    Policy or the Terms of Use. Any changes we make will be
                    effective immediately on notice, which we may give by
                    posting the new policy on the Site. Your use of the StaffPay
                    App Services after such notice will be deemed acceptance of
                    such changes. We may also make reasonable efforts to inform
                    you via electronic mail. In any case, you are advised to
                    review this Privacy Policy periodically on the Site to
                    ensure that you are aware of the latest version.
                </Typography>
                <Typography className={classes.privacyTitle}>
                    Questions/Grievance Redressal
                </Typography>
                <Typography className={classes.paragraphy}>
                    In the event you have any grievance relating to the
                    processing of Information provided by you, you may contact
                    our Grievance Office at feedback@staffpay.in or write to us
                    at the following address:
                </Typography>
                <Typography className={classes.paragraphy}>
                    <Typography>StaffPay Solutions Private Limited,</Typography>
                    <Typography>Bengaluru, Karnataka</Typography>
                </Typography>
            </Box>
        </Box>
    );
};

export default withRouter(Privacy);
