import { useEffect, useState, useRef } from 'react';

export const usePolling = (
    fetchFunction: () => Promise<void>,
    delay: number,
    startPolling: boolean,
    immediateCall: boolean,
) => {
    const savedCallback = useRef<() => Promise<void>>();

    // Remember the latest fetchFunction in a ref
    useEffect(() => {
        savedCallback.current = fetchFunction;
    }, [fetchFunction]);

    useEffect(() => {
        if (!startPolling) return;

        const tick = async () => {
            if (savedCallback.current) {
                await savedCallback.current();
            }
        };

        // Polling starts
        if (!!immediateCall) {
            tick();
        }
        const interval = setInterval(tick, delay);

        return () => clearInterval(interval); // Cleanup on component unmount or when polling stops
    }, [delay, startPolling]);
};
