import React, { useEffect, useState } from 'react';
import {
    Box,
    Paper,
    Typography,
    makeStyles,
    IconButton,
    Tooltip,
} from '@material-ui/core';
import { CustomMonthPicker } from '../../../components/DateTimePicker';
import Searchbar from '../../../components/Searchbar';
import { Spinner } from '../../../components/UI';
import PaymentsListContainer from './PaymentsListContainer';
import { connect } from 'react-redux';
import { GetPaymentReport } from '../../../store/actions/reportActionCreator';
import {
    formatCurrency,
    getLoginUser,
    JSONToCSVConvertor,
} from '../../../utils/helper';
import { EmptyIcon } from '../../../assets/svg';
import { CustomTextField } from '../../../components/Textfield';
import moment from 'moment';
import InsertInvitationIcon from '@material-ui/icons/InsertInvitation';
import GetAppIcon from '@material-ui/icons/GetApp';
import { useDebounce } from '../../../hooks/useDebounce';

const useStyles = makeStyles((theme) => ({
    paymentsReportContainer: {
        padding: '1.5rem',
    },
    horizontalCardContents: {
        width: '60%',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'spaceBetween',
    },
    searchBar: {
        width: '50%',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
    },
    verticalCardContents: {
        flex: '1 1 25%',
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        justifyContent: 'center',
        border: 'solid 1px #ebedf8',
        borderRadius: '8px',
        boxShadow: 'inset 0 -1px 0 0 #ebedf8',
        backgroundColor: '#f2f4f8',
        paddingLeft: '16px',
        height: '88px',
        margin: '0 8px 0 0 ',
    },
    cardSubHeader: {
        fontWeight: 600,
    },
    cardHeader: {
        fontSize: '20px',
        fontWeight: 700,
    },
}));

function PaymentsReportComponent({
    payments,
    permissions,
    settings,
    dispatchGetPaymentReport,
    ...props
}) {
    const { debounce, isBouncing } = useDebounce();
    const classes = useStyles();
    const [selectedMonth, setSelectedMonth] = useState();
    const [loading, toggleLoading] = useState(false);
    const [defaultList, setDefaultList] = useState(
        payments?.paymentSummaryList ?? [],
    );

    useEffect(() => {
        setDefaultList(payments?.paymentSummaryList);
    }, [payments?.paymentSummaryList]);

    useEffect(() => {
        // if (!selectedMonth) return;
        console.log('seelctedMonth ', selectedMonth);
        fetchPaymentReport({
            dateRange: {
                from: moment(selectedMonth).startOf('month').valueOf(),
                to: moment(selectedMonth).endOf('month').valueOf(),
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedMonth]);

    const fetchPaymentReport = (filters = {}) => {
        toggleLoading(true);
        const payload = {
            ...filters,
            organisationId:
                settings?.basicDetails?.id ?? getLoginUser().organizationId,
            transactionPaymentTypes: ['PAYMENT'],
        };
        dispatchGetPaymentReport(
            payload,
            (resp) => {
                console.log(resp);
                toggleLoading(false);
            },
            (err) => {
                console.log(err);
                toggleLoading(false);
            },
        );
    };

    return (
        <Paper className={classes.paymentsReportContainer} elevation={0}>
            <Spinner loading={loading}>
                <Box style={{ marginBottom: '1rem' }}>
                    <Box
                        style={{
                            width: '20%',
                        }}
                    >
                        {/* <CustomMonthRangePicker
                            value={state?.date}
                            onChange={(e) => {
                                console.log('dateee', e);
                                handleInputChange(e, 'date');
                            }}
                            
                        /> */}
                        {/* <CustomTextField
                            label="Month"
                            InputProps={{
                                readOnly: true,
                                endAdornment: (
                                    <IconButton
                                        className={'inputbutton'}
                                        onClick={(e) => setDatePickerOpen(true)}
                                    >
                                        <InsertInvitationIcon size="small" />
                                    </IconButton>
                                ),
                            }}
                            onClick={(e) => setDatePickerOpen(true)}
                            id="dateEpochMillis"
                            name="dateEpochMillis"
                            value={moment(selectedMonth).format('MMMM YYYY')}
                            variant="outlined"
                        /> */}

                        <CustomMonthPicker
                            onChange={(e) => {
                                console.log(moment(e).valueOf());
                                setSelectedMonth(e);
                                // setSelectedDate(moment(e));
                            }}
                        />
                    </Box>
                </Box>
                <Box
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginBottom: '1rem',
                    }}
                >
                    <Box className={classes.horizontalCardContents}>
                        <Box className={classes.verticalCardContents}>
                            <Typography className={classes.cardHeader}>
                                Total Amount
                            </Typography>
                            <Typography className={classes.cardSubHeader}>
                                {formatCurrency(payments?.totalAmount ?? 0)}
                            </Typography>
                        </Box>
                        <Box className={classes.verticalCardContents}>
                            <Typography className={classes.cardHeader}>
                                No. of Payments
                            </Typography>
                            <Typography className={classes.cardSubHeader}>
                                {payments?.noOfPayments ?? 0}
                            </Typography>
                        </Box>
                    </Box>
                    <Box className={classes.searchBar}>
                        <Tooltip title="Download Report" placement="top">
                            <IconButton
                                className={'inputbutton'}
                                variant="outlined"
                                style={{
                                    marginRight: 16,
                                    border: '1px solid #43419e',
                                    borderRadius: 8,
                                    padding: 6,
                                }}
                                color="primary"
                                onClick={(e) => {
                                    debounce(() => {
                                        JSONToCSVConvertor(
                                            'Payment Report',
                                            defaultList.map((item) => ({
                                                'User Name': item.userName,
                                                'Payment Date': item.date,
                                                'Payment Ref No.':
                                                    item.paymentData,
                                                Amount: item.amount,
                                                Notes: item.notes,
                                            })),
                                            'Payment Report',
                                            true,
                                        );
                                    });
                                }}
                                disabled={
                                    isBouncing ||
                                    (!defaultList?.length && permissions.read)
                                }
                            >
                                <GetAppIcon size="small" />
                            </IconButton>
                        </Tooltip>
                        {/* <Box
                            style={{
                                display: 'flex',
                                alignItems: 'flex-end',
                                width: '50%',
                            }}
                        > */}
                        <Searchbar
                            placeHolderText="Search"
                            // placeHolderStyles={{
                            //     color: '#979797',
                            // }}
                            style={{
                                // border: '1px solid #f1f3f6',
                                // color: '#14112d',
                                width: '50%',
                            }}
                            dataSource={[]}
                            keys={['']}
                            fullWidth={false}
                            onFilter={async (filteredData) => {}}
                            onChange={(value) => {
                                console.log('blur', value);
                                setDefaultList(
                                    payments?.paymentSummaryList?.filter(
                                        (item) =>
                                            item?.userName
                                                ?.toLowerCase()
                                                .includes(value?.toLowerCase()),
                                    ),
                                );
                            }}
                        />
                        {/* </Box> */}
                    </Box>
                </Box>
                <Box
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    {defaultList?.length ? (
                        <PaymentsListContainer
                            rows={defaultList ?? []}
                            fetchPaymentReport={() =>
                                fetchPaymentReport({
                                    dateRange: {
                                        from: moment(selectedMonth)
                                            .startOf('month')
                                            .valueOf(),
                                        to: moment(selectedMonth)
                                            .endOf('month')
                                            .valueOf(),
                                    },
                                })
                            }
                            permissions={permissions}
                        />
                    ) : (
                        <EmptyIcon />
                    )}
                </Box>
            </Spinner>
        </Paper>
    );
}

const mapStateToProps = (state) => ({
    modals: state.modals,
    payments: state.report.payments,
    settings: state.settings,
});

const mapDispatchToProps = (dispatch) => ({
    dispatchGetPaymentReport: (payload, onSuccess, onError) =>
        dispatch(GetPaymentReport(payload, onSuccess, onError)),
});

export const PaymentsReport = connect(
    mapStateToProps,
    mapDispatchToProps,
)(PaymentsReportComponent);
