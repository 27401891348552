import * as constants from '../constants';

const defaultState = {
    vehicles: {},
    allStaffVehicles: [],
};

// const userInfo = localStorage.getItem("USER_INFO");

export default function mapViewReducer(state = defaultState, action) {
    switch (action.type) {
        case constants.SET_TRACKING_VEHICLES_LIST:
            console.log('SET_TRACKING_VEHICLES_LIST', action.payload);
            return {
                ...state,
                vehicles: action.payload,
            };
        case constants.SET_TRACKING_DATA:
            // console.log('SET_TRACKING_DATA', action);
            return {
                ...state,
                vehicles: {
                    ...state.vehicles,
                    [action.id]: {
                        ...action.payload,
                    },
                },
            };
        case constants.SET_TRACKING_TOGGLE_EVENTS:
            console.log('SET_TRACKING_TOGGLE_EVENTS', action);
            return { ...state, events: action.payload };
        case constants.SET_MAPVIEW_ALL_STAFF:
            console.log('SET_MAPVIEW_ALL_STAFF', action);
            return { ...state, allStaffVehicles: action.payload };
        default:
            return state;
    }
}
