import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
    Box,
    Button,
    MenuItem,
    Paper,
    makeStyles,
    Typography,
    Dialog,
    DialogTitle,
    IconButton,
    Slide,
    useMediaQuery,
    useTheme,
    Avatar,
    Divider,
    DialogContent,
    DialogActions,
    Tooltip,
    List,
    ListItem,
    ListItemSecondaryAction,
    Backdrop,
    ListItemText,
    TextField,
} from '@material-ui/core';
import Modal from '../../../components/Modals/Modal';
import { connect, useDispatch, useSelector } from 'react-redux';
import { updateLeadStatus } from '../../../store/actions/leadActionCreator';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
    createStaff,
    fetchConvertStaffList,
    covertToStaff,
} from '../../../store/actions/staffActionCreator';
import moment from 'moment';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CloseIcon from '@material-ui/icons/Close';
import { Spinner } from '../../../components/UI';
import CircularProgress from '@material-ui/core/CircularProgress';
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';
import HeightIcon from '@material-ui/icons/Height';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import InfoIcon from '@material-ui/icons/Info';
import LeadModal from '../../../components/Modals/LeadModal';
import EditIcon from '@material-ui/icons/Edit';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { SelectCheckBox } from './Checkboxes';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const useStyles = makeStyles((theme) => ({
    cardSubHeader: {
        fontSize: '22px',
        fontWeight: 700,
    },
    cardHeader: {
        fontSize: '16px',
        fontWeight: 600,
    },
    cardHeaderFail: {
        fontSize: '16px',
        fontWeight: 600,
        color: '#FB8A06',
    },
    cardSubHeaderFail: {
        fontSize: '22px',
        fontWeight: 700,
        color: '#FB8A06',
    },
    verticalCardContents: {
        flex: '1 1 auto',
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        justifyContent: 'center',
        border: 'solid 1px #ebedf8',
        borderRadius: '3.8px',
        backgroundColor: '#ebedf8',
        paddingLeft: '16px',
        height: '88px',
        margin: '0 8px 0 0 ',
    },
    verticalCardContentsActive: {
        backgroundColor: '#d1d8ff',
    },
    cardHeaderActive: {
        fontWeight: 700,
    },
    verticalCardContentsNoMargin: {
        margin: 0,
    },
    iconButton: {
        padding: '0px',
    },
    modalTitle: {
        fontSize: '24px',
        fontWeight: 'bold',
        color: '#14112d',
        marginLeft: '14px',
    },
    dialogContent: {
        paddingLeft: 0,
        paddingRight: 0,
    },
    dialogActions: {
        padding: '16px 24px',
    },
    listItemHeader: {
        border: '0px !important',
        marginBottom: '8px !important',
        color: '#808080 !important',
        fontWeight: '700 !important',
        textTransform: 'uppercase',
        padding: 0,
        paddingLeft: 16,
    },
    listItem: {
        padding: 16,
        border: '1px solid #ebedf8',
        borderRadius: 4,
        marginBottom: 8,
        '&:last-child': {
            marginBottom: 0,
        },
    },
    name: {
        fontSize: 16,
        fontWeight: 700,
        color: '#14112D',
    },
    phone: {
        fontSize: 16,
        fontWeight: 600,
        color: '#14112D',
    },
    org: {
        fontSize: 16,
        fontWeight: 600,
        color: '#14112D',
    },
    selfOnBoard: {
        backgroundColor: 'rgba(82, 80, 80, 0.04)',
        borderRadius: 4,
        padding: '4px 6px',
        color: '#949494',
        fontWeight: 600,
        fontSize: 10,
        width: 84,
        textAlign: 'center',
    },
    referal: {
        backgroundColor: '#EBEBFF',
        borderRadius: 4,
        padding: '4px 6px',
        color: '#43419E',
        fontWeight: 600,
        fontSize: 10,
        width: 72,
        textAlign: 'center',
    },
    backDropRoot: {
        position: 'absolute',
        zIndex: theme.zIndex.drawer - 1,
        opacity: 0.5,
    },
    workLocation: {
        fontWeight: 600,
        fontSize: 16,
        color: ' #43419E',
    },
    minimizeButton: {
        color: '#43419E',
        background: 'rgba(67, 65, 158, 0.2)',
        borderRadius: 8,
        padding: '6px 8px',
        fontSize: 16,
        fontWeight: 600,
        cursor: 'pointer',
    },
    covertedTitle: {
        background: '#4CAF50',
        padding: '12px 14px',
        color: '#fff',
    },
    covertingTitle: {
        background: '#303030',
        padding: '12px 14px',
        color: '#fff',
    },
    scrollPaperMini: {
        alignItems: 'end !important',
        justifyContent: 'end !important',
    },
    modal: {
        width: 400,
        marginLeft: 'auto',
        position: 'sticky !important',
        '& .MuiBackdrop-root': {
            display: 'none',
        },
    },
    editBtn: {
        background: '#FFFFFF',
        border: '1px solid #EBEBFF',
        borderRadius: 8,
    },
    failureBox: {
        padding: '6px 16px',
        background: '#FCF2E6',
        border: '1px solid #FADEBE',
        borderRadius: 6,
        marginLeft: 'auto',
    },
    failureText: {
        color: 'red',
        fontSize: 12,
        fontWeight: 600,
    },
    warningText: {
        color: '#14112D',
        fontSize: 12,
        fontWeight: 600,
    },
}));
function ConvertToStaffModal({
    leadInfo,
    open,
    onClose,
    defaultValue = {},
    orgList,
    preferredWorkLocationsList,
    selected,
    ...props
}) {
    const dispatch = useDispatch();
    const classes = useStyles();
    //const notifications = useSelector((store) => store.notifications || []);
    const [isOpen, toggleModal] = useState(open);
    const [loading, toggleLoading] = useState(false);
    const [loadingStateStaff, setLoadingStateStaff] = useState(null);
    const [staffs, setStaffs] = useState(false);
    const [converting, setConverting] = useState(false);
    const [converted, setConverted] = useState(false);
    const [minimized, setMinimized] = useState(false);
    const [convertedData, setConvertedData] = useState(null);
    const [currentStatus, setCurrentStatus] = useState('totalCount');
    const [leadModalOpen, setLeadModalOpen] = React.useState(false);
    const [selectedItemData, setSelectedItemData] = React.useState(null);
    const [isPreferredWorkLocRequired, setIsPreferredWorkLocRequired] =
        useState(false);
    const LeadStatuses = [
        { label: 'Total No of Leads', key: 'all', value: 'totalCount' },
        { label: 'Converted', key: 'converted', value: 'convertedCount' },
        { label: 'Failed to Convert', key: 'failed', value: 'failedCount' },
    ];
    const handleClose = () => {
        toggleModal(!isOpen);
        onClose && onClose();
    };
    function bulkApi(element) {
        let payload = {
            allLeadIds: [element.id],
        };
        if (element && element.comment) {
            payload.comment = element.comment;
        }
        return new Promise((resolve, reject) => {
            dispatch(covertToStaff(payload))
                .then((data) => {
                    //setLoadingState(data, element);
                    resolve(data);
                })
                .catch((data) => {
                    //setLoadingState(data, element);
                    resolve(data);
                });
        });
    }
    const setLoadingState = (data, element) => {
        let statusData = {};
        if (
            data &&
            data.status &&
            data.status.code &&
            data.status.code == 200 &&
            !data.status.customErrorCode
        ) {
            statusData[element.id] = {
                loading: false,
                converted: true,
            };
            setLoadingStateStaff((prevState) => ({
                ...prevState,
                ...statusData,
            }));
        } else {
            statusData[element.id] = {
                loading: false,
                converted: false,
            };
            setLoadingStateStaff((prevState) => ({
                ...prevState,
                ...statusData,
            }));
        }
    };
    const createStaff = async () => {
        if (getData()) {
            // const data = getData().map((el) => bulkApi(el));
            // const output = await Promise.all(data);
            // loadStaffs(true);
            let datas = getData();
            if (converted && currentStatus && currentStatus === 'failedCount') {
                datas = getData().filter(
                    (val) => val && val.checked && val.checked === true,
                );
            }
            /**toggleLoading(true);
            console.log('start time', new Date());
            const data = datas.map((el) => bulkApi(el));
            Promise.all(data)
                .then(() => {
                    console.log('end time', new Date());

                    loadStaffs(true);
                    toggleLoading(false);
                })
                .catch((e) => {
                    console.log('end time', new Date());
                    loadStaffs(true);
                    toggleLoading(false);
                });*/
            //console.log('Start');
            toggleLoading(true);
            for (let index = 0; index < datas.length; index++) {
                const element = datas[index];
                await bulkApi(element)
                    .then((data) => {
                        setLoadingState(data, element);
                    })
                    .catch((e) => {
                        console.log('the event is', e);
                        setLoadingState(e, element);
                    });
            }
            loadStaffs(true);
            toggleLoading(false);
        }
    };
    const handleConvertStaff = () => {
        if (getData()) {
            setConverted(false);
            setConverting(true);
            let datas = getData();
            if (converted && currentStatus && currentStatus === 'failedCount') {
                datas = getData().filter(
                    (val) => val && val.checked && val.checked === true,
                );
            }
            let statusData = {};
            for (let index = 0; index < datas.length; index++) {
                const element = datas[index];
                statusData[element.id] = {
                    loading: true,
                    converted: false,
                };
            }
            setLoadingStateStaff({
                ...loadingStateStaff,
                ...statusData,
            });
            createStaff();
        }
    };
    const getData = (status) => {
        let currentValue = status ? status : currentStatus;
        if (staffs && staffs.lineItems && staffs.lineItems.length > 0) {
            if (currentValue && currentValue != 'totalCount') {
                if (currentValue && currentValue === 'convertedCount') {
                    return staffs.lineItems.filter(
                        (data) => data && data.status === 'SUCCESS',
                    );
                }
                if (currentValue && currentValue === 'failedCount') {
                    return staffs.lineItems.filter(
                        (data) =>
                            data &&
                            (data.status === 'FAILURE' ||
                                data.status === 'WARNING'),
                    );
                }
            } else {
                return staffs.lineItems;
            }
        }
        return [];
    };
    const onTABChange = (value) => {
        setCurrentStatus(value);
    };
    const validationSchema = yup.lazy((values) => {
        return yup.object({
            orgId: yup.string().required('Organization cannot be empty'),
            zoneId: !isPreferredWorkLocRequired
                ? yup.string().notRequired()
                : yup
                      .string()
                      .required('Preferred Work Locations cannot be empty'),
        });
    });
    const disableAction = (close) => {
        if (converting) {
            return true;
        } else {
            if (converted && currentStatus === 'failedCount') {
                let isWarningThere = getData().some(
                    (val) =>
                        val.responseMessage &&
                        val.responseMessage === 'Duplicate Documents Found',
                );
                if (isWarningThere) {
                    let isChecked = getData().some((val) => val && val.checked);
                    if (isChecked) {
                        if (
                            !close &&
                            !getData()
                                .filter((val) => val && val.checked)
                                .every((val) => val && val.comment)
                        )
                            return true;
                        else return false;
                    } else {
                        return close ? false : true;
                    }
                } else {
                    return close ? false : true;
                }
            } else if (
                close &&
                converted &&
                (currentStatus === 'convertedCount' ||
                    currentStatus === 'totalCount')
            ) {
                return false;
            } else if (close && !converted && !converting) {
                return false;
            } else if (!converted && !converting) {
                return false;
            }
        }

        return true;
    };
    const actions = [
        <Button
            key={1}
            variant="contained"
            color="default"
            className={`cancelButton`}
            onClick={handleClose}
            disabled={disableAction(true)}
            style={{
                boxShadow: 'none',
            }}
            disableElevation
        >
            Cancel
        </Button>,
        <Button
            key={2}
            variant="contained"
            color="primary"
            disabled={disableAction()}
            className={`saveButton`}
            type="submit"
            onClick={() => {
                handleConvertStaff();
            }}
            style={{
                boxShadow: 'none',
            }}
            disableElevation
        >
            Convert
        </Button>,
    ];
    const loadStaffs = (afterConvert) => {
        toggleLoading(true);
        dispatch(
            fetchConvertStaffList(
                {
                    filterStatus: 'All',
                    allLeadIds: selected,
                },
                (data) => {
                    toggleLoading(false);
                    if (
                        data &&
                        data.status &&
                        data.status.code &&
                        data.status.code == 200 &&
                        !data.status.customErrorCode
                    ) {
                        if (data && data.response) {
                            setStaffs(data.response);
                            if (afterConvert) {
                                /**let mod = [];
                                data.response.lineItems.forEach((val) =>
                                    mod.push({
                                        ...val,
                                        responseMessage:
                                            'Duplicate Documents Found',
                                    }),
                                );
                                setStaffs({
                                    ...data.response,
                                    lineItems: mod,
                                });*/
                                setConverted(true);
                                setConverting(false);
                            }
                        }
                    }
                },
                (err) => {
                    toggleLoading(false);
                },
            ),
        );
    };
    useEffect(() => {
        loadStaffs();
    }, []);
    const handleComment = (e, val) => {
        let modified = [];
        if (staffs && staffs.lineItems && staffs.lineItems.length > 0) {
            staffs.lineItems.forEach((element) => {
                if (
                    element &&
                    element.id &&
                    val &&
                    val.id &&
                    element.id === val.id
                ) {
                    element.comment = e.target.value;
                }
                modified.push(element);
            });
        }
        setStaffs((prevState) => ({
            ...prevState,
            lineItems: modified,
        }));
    };
    const handleSelect = (e, val) => {
        let modified = [];
        if (staffs && staffs.lineItems && staffs.lineItems.length > 0) {
            staffs.lineItems.forEach((element) => {
                if (
                    element &&
                    element.id &&
                    val &&
                    val.id &&
                    element.id === val.id
                ) {
                    element.checked = e.target.checked;
                }
                modified.push(element);
            });
        }
        setStaffs((prevState) => ({
            ...prevState,
            lineItems: modified,
        }));
    };

    const formik = useFormik({
        initialValues: {
            orgId: defaultValue?.orgId ?? '',
            zoneId: defaultValue?.zoneId ?? '',
        },
        validationSchema,
        onSubmit: async (values) => {
            await toggleLoading(true);
        },
    });
    let failed =
        currentStatus && currentStatus === 'failedCount' ? true : false;
    return (
        <Dialog
            onClose={(event) => {
                handleClose(defaultValue);
            }}
            open={isOpen}
            disableBackdropClick={true}
            Transition={Transition}
            aria-labelledby="convert-to-staff-dialog-slide-title"
            aria-describedby="convert-to-staff-dialog-slide-description"
            className={minimized ? classes.modal : {}}
            disableEscapeKeyDown
            fullWidth={true}
            scroll={'paper'}
            classes={
                minimized
                    ? {
                          scrollPaper: classes.scrollPaperMini,
                      }
                    : {}
            }
            maxWidth={minimized ? 'xs' : 'lg'}
        >
            <DialogTitle
                id="convert-to-staff-dialog-slide-title"
                classes={
                    minimized
                        ? {
                              root: converted
                                  ? classes.covertedTitle
                                  : classes.covertingTitle,
                              scrollPaper: classes.scrollPaperMini,
                          }
                        : {}
                }
            >
                <Box
                    width={'100%'}
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                >
                    {minimized ? (
                        <>
                            <Typography
                                style={{
                                    color: '#fff',
                                    fontSize: 18,
                                    fontWeight: 600,
                                }}
                            >
                                {converted
                                    ? 'Completed'
                                    : 'Converting Leads...'}
                            </Typography>
                            <Box display={'flex'} alignItems={'center'}>
                                <Typography
                                    style={{
                                        color: '#fff',
                                        fontSize: 18,
                                        fontWeight: 600,
                                    }}
                                >
                                    {/* {`(${}/${})`} */}
                                </Typography>
                                <HeightIcon
                                    color="inherit"
                                    onClick={() => {
                                        setMinimized(false);
                                    }}
                                    style={{
                                        color: '#fff',
                                        transform: 'rotate(45deg)',
                                        cursor: 'pointer',
                                    }}
                                />
                            </Box>
                        </>
                    ) : (
                        <>
                            <Box
                                width={'45%'}
                                display={'flex'}
                                alignItems={'center'}
                            >
                                <IconButton
                                    aria-label="close"
                                    disabled={disableAction(true)}
                                    className={classes.iconButton}
                                    onClick={handleClose}
                                >
                                    <ArrowBackIcon style={{ color: '#000' }} />
                                </IconButton>
                                <Typography
                                    className={classes.modalTitle}
                                    variant="h6"
                                >
                                    {'Convert to Staff'}
                                </Typography>
                            </Box>
                            {/* {converting && (
                                <Box
                                    display={'flex'}
                                    alignItems={'center'}
                                    justifyContent={'center'}
                                    className={classes.minimizeButton}
                                    onClick={() => {
                                        setMinimized(true);
                                    }}
                                >
                                    <CompareArrowsIcon
                                        style={{ transform: 'rotate(134deg)' }}
                                    />
                                    &nbsp;Minimize
                                </Box>
                            )} */}
                            <IconButton
                                aria-label="close"
                                disabled={disableAction(true)}
                                className={classes.iconButton}
                                onClick={handleClose}
                            >
                                <CloseIcon style={{ color: '#000' }} />
                            </IconButton>
                        </>
                    )}
                </Box>
            </DialogTitle>
            <DialogContent className={minimized ? classes.dialogContent : {}}>
                {!minimized ? (
                    <Box width={'100%'}>
                        <Box display={'flex'}>
                            {LeadStatuses.map((status, index) => (
                                <Box
                                    className={`${
                                        classes.verticalCardContents
                                    } ${
                                        currentStatus === status.value &&
                                        classes.verticalCardContentsActive
                                    }`}
                                    style={{
                                        maxWidth: 172,
                                    }}
                                    onClick={() => {
                                        if (
                                            converted &&
                                            status &&
                                            status.value &&
                                            staffs &&
                                            staffs[status.value] != 0
                                        ) {
                                            onTABChange(status.value);
                                        }
                                    }}
                                >
                                    <Typography
                                        className={`${
                                            converted &&
                                            status &&
                                            status.value === 'failedCount'
                                                ? classes.cardHeaderFail
                                                : classes.cardHeader
                                        } ${
                                            currentStatus === status.value &&
                                            classes.cardHeaderActive
                                        }`}
                                    >
                                        {status.label}
                                    </Typography>
                                    <Typography
                                        className={
                                            converted &&
                                            status &&
                                            status.value === 'failedCount'
                                                ? classes.cardSubHeaderFail
                                                : classes.cardSubHeader
                                        }
                                    >
                                        {status.value === 'totalCount'
                                            ? staffs && staffs[status.value]
                                                ? staffs[status.value]
                                                : '--'
                                            : converted && getData(status.value)
                                            ? getData(status.value).length
                                            : '--'}
                                    </Typography>
                                </Box>
                            ))}
                        </Box>
                        <Box mt={'32px'}>
                            <List component="nav">
                                <ListItem className={classes.listItemHeader}>
                                    <Box
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            width: '100%',
                                        }}
                                        alignItems={'center'}
                                    >
                                        {failed && (
                                            <Box width={'6%'}>
                                                {/* <SelectCheckBox /> */}
                                            </Box>
                                        )}
                                        <Box width={failed ? '19%' : '19%'}>
                                            {failed
                                                ? 'Name & Phone Number'
                                                : 'Name'}
                                        </Box>
                                        <Box width={failed ? '20%' : '17%'}>
                                            {failed
                                                ? 'Org & Work Location'
                                                : 'Phone Number'}
                                        </Box>
                                        <Box width={failed ? '26%' : '27%'}>
                                            {failed
                                                ? 'Add Comment'
                                                : 'Organization'}
                                        </Box>
                                        <Box width={failed ? '27%' : '27%'}>
                                            {failed ? (
                                                <Typography
                                                    style={{
                                                        color: '#808080',
                                                        fontSize: 14,
                                                    }}
                                                    align="right"
                                                >
                                                    {(converted ||
                                                        converting) && (
                                                        <>
                                                            <span
                                                                style={{
                                                                    color: '#4CAF50',
                                                                }}
                                                            >
                                                                {
                                                                    staffs.convertedCount
                                                                }
                                                            </span>
                                                            /
                                                            <span
                                                                style={{
                                                                    color: '#FB8A06',
                                                                }}
                                                            >
                                                                {
                                                                    staffs.failedCount
                                                                }
                                                            </span>
                                                        </>
                                                    )}
                                                </Typography>
                                            ) : (
                                                'Work Location'
                                            )}
                                        </Box>
                                        {!failed && (
                                            <Box width={failed ? '11%' : '11%'}>
                                                <Typography
                                                    style={{
                                                        color: '#808080',
                                                        fontSize: 14,
                                                    }}
                                                    align="right"
                                                >
                                                    {(converted ||
                                                        converting) && (
                                                        <>
                                                            <span
                                                                style={{
                                                                    color: '#4CAF50',
                                                                }}
                                                            >
                                                                {
                                                                    staffs.convertedCount
                                                                }
                                                            </span>
                                                            /
                                                            <span
                                                                style={{
                                                                    color: '#FB8A06',
                                                                }}
                                                            >
                                                                {
                                                                    staffs.failedCount
                                                                }
                                                            </span>
                                                        </>
                                                    )}
                                                </Typography>
                                            </Box>
                                        )}
                                    </Box>
                                </ListItem>
                                <>
                                    {getData() && getData()?.length
                                        ? getData()?.map((val, index) => {
                                              let isWarning =
                                                  val &&
                                                  val.responseMessage &&
                                                  val.responseMessage ===
                                                      'Duplicate Documents Found'
                                                      ? true
                                                      : false;
                                              return (
                                                  <ListItem
                                                      key={val.id}
                                                      button
                                                      onClick={() => {
                                                          setSelectedItemData({
                                                              ...val,
                                                              status: val.leadStatus,
                                                          });
                                                          setLeadModalOpen(
                                                              true,
                                                          );
                                                      }}
                                                      classes={{
                                                          root: classes.listItem,
                                                      }}
                                                  >
                                                      {failed && (
                                                          <Box width={'6%'}>
                                                              <SelectCheckBox
                                                                  disabled={
                                                                      isWarning
                                                                          ? false
                                                                          : true
                                                                  }
                                                                  checked={
                                                                      val &&
                                                                      val.checked
                                                                          ? true
                                                                          : false
                                                                  }
                                                                  onClick={(
                                                                      e,
                                                                  ) => {
                                                                      e.stopPropagation();
                                                                      e.preventDefault();
                                                                  }}
                                                                  onChange={(
                                                                      e,
                                                                  ) => {
                                                                      handleSelect(
                                                                          e,
                                                                          val,
                                                                      );
                                                                  }}
                                                              />
                                                          </Box>
                                                      )}
                                                      <Box width={'20%'}>
                                                          <Typography
                                                              className={
                                                                  classes.name
                                                              }
                                                          >
                                                              {val && val.name
                                                                  ? val.name
                                                                  : ''}
                                                          </Typography>
                                                          <Box
                                                              display={'flex'}
                                                              alignItems={
                                                                  'center'
                                                              }
                                                          >
                                                              {!!val?.leadSourceName && (
                                                                  <Box
                                                                      mt={'4px'}
                                                                      className={
                                                                          val?.leadSourceName ===
                                                                          'Referral'
                                                                              ? classes.referal
                                                                              : classes.selfOnBoard
                                                                      }
                                                                  >
                                                                      {
                                                                          val?.leadSourceName
                                                                      }
                                                                  </Box>
                                                              )}
                                                              {failed &&
                                                                  !!val?.leadSourceName && (
                                                                      <FiberManualRecordIcon
                                                                          style={{
                                                                              fontSize: 8,
                                                                              color: '#CFCFCF',
                                                                              margin: '0px 8px',
                                                                          }}
                                                                      />
                                                                  )}
                                                              {failed && (
                                                                  <Typography
                                                                      className={
                                                                          classes.phone
                                                                      }
                                                                  >
                                                                      {val &&
                                                                      val.phoneNumber
                                                                          ? val.phoneNumber
                                                                          : ''}
                                                                  </Typography>
                                                              )}
                                                          </Box>
                                                      </Box>
                                                      <Box
                                                          width={
                                                              failed
                                                                  ? '20%'
                                                                  : '18%'
                                                          }
                                                      >
                                                          <Typography
                                                              className={
                                                                  classes.phone
                                                              }
                                                          >
                                                              {!failed &&
                                                              val &&
                                                              val.phoneNumber
                                                                  ? val.phoneNumber
                                                                  : ''}
                                                              {failed &&
                                                              val &&
                                                              val.city
                                                                  ? val.city
                                                                  : ''}
                                                          </Typography>
                                                          {failed && (
                                                              <>
                                                                  {val
                                                                      ?.preferredLocations
                                                                      ?.length <=
                                                                  3 ? (
                                                                      <Typography
                                                                          className={
                                                                              classes.workLocation
                                                                          }
                                                                      >
                                                                          {val?.preferredLocations?.join(
                                                                              ' | ',
                                                                          )}
                                                                      </Typography>
                                                                  ) : val
                                                                        ?.preferredLocations
                                                                        ?.length ? (
                                                                      <Typography
                                                                          className={
                                                                              classes.workLocation
                                                                          }
                                                                      >
                                                                          {val?.preferredLocations
                                                                              .slice(
                                                                                  0,
                                                                                  3,
                                                                              )
                                                                              ?.join(
                                                                                  ' | ',
                                                                              )}
                                                                          {val
                                                                              ?.preferredLocations
                                                                              ?.length
                                                                              ? `+${
                                                                                    val
                                                                                        .preferredLocations
                                                                                        .length -
                                                                                    3
                                                                                }`
                                                                              : ''}
                                                                      </Typography>
                                                                  ) : (
                                                                      <Box></Box>
                                                                  )}
                                                              </>
                                                          )}
                                                      </Box>
                                                      {!failed && (
                                                          <>
                                                              <Box width="29%">
                                                                  <Typography
                                                                      className={
                                                                          classes.org
                                                                      }
                                                                  >
                                                                      {val &&
                                                                      val.city
                                                                          ? val.city
                                                                          : ''}
                                                                  </Typography>
                                                              </Box>
                                                              <Box width="27%">
                                                                  {val
                                                                      ?.preferredLocations
                                                                      ?.length <=
                                                                  3 ? (
                                                                      <Typography
                                                                          className={
                                                                              classes.workLocation
                                                                          }
                                                                      >
                                                                          {val?.preferredLocations?.join(
                                                                              ' | ',
                                                                          )}
                                                                      </Typography>
                                                                  ) : val
                                                                        ?.preferredLocations
                                                                        ?.length ? (
                                                                      <Typography
                                                                          className={
                                                                              classes.workLocation
                                                                          }
                                                                      >
                                                                          {val?.preferredLocations
                                                                              .slice(
                                                                                  0,
                                                                                  3,
                                                                              )
                                                                              ?.join(
                                                                                  ' | ',
                                                                              )}
                                                                          {val
                                                                              ?.preferredLocations
                                                                              ?.length
                                                                              ? `+${
                                                                                    val
                                                                                        .preferredLocations
                                                                                        .length -
                                                                                    3
                                                                                }`
                                                                              : ''}
                                                                      </Typography>
                                                                  ) : (
                                                                      <Box></Box>
                                                                  )}
                                                              </Box>
                                                              <Box
                                                                  width="11%"
                                                                  style={{
                                                                      color: '#43419E',
                                                                  }}
                                                              >
                                                                  {converting &&
                                                                      val &&
                                                                      val.id &&
                                                                      loadingStateStaff &&
                                                                      loadingStateStaff[
                                                                          `${val.id}`
                                                                      ] &&
                                                                      loadingStateStaff[
                                                                          `${val.id}`
                                                                      ]
                                                                          .loading && (
                                                                          <CircularProgress color="inherit" />
                                                                      )}
                                                                  {(converting ||
                                                                      converted) && (
                                                                      <>
                                                                          {val &&
                                                                              val.id &&
                                                                              loadingStateStaff &&
                                                                              loadingStateStaff[
                                                                                  `${val.id}`
                                                                              ] &&
                                                                              !loadingStateStaff[
                                                                                  `${val.id}`
                                                                              ]
                                                                                  .loading &&
                                                                              loadingStateStaff[
                                                                                  `${val.id}`
                                                                              ]
                                                                                  .converted && (
                                                                                  <CheckCircleIcon
                                                                                      color="inherit"
                                                                                      style={{
                                                                                          color: '#4CAF50',
                                                                                      }}
                                                                                  />
                                                                              )}{' '}
                                                                          {val &&
                                                                              val.id &&
                                                                              loadingStateStaff &&
                                                                              loadingStateStaff[
                                                                                  `${val.id}`
                                                                              ] &&
                                                                              !loadingStateStaff[
                                                                                  `${val.id}`
                                                                              ]
                                                                                  .loading &&
                                                                              !loadingStateStaff[
                                                                                  `${val.id}`
                                                                              ]
                                                                                  .converted && (
                                                                                  <InfoIcon
                                                                                      color="inherit"
                                                                                      style={{
                                                                                          color: '#FB8A06',
                                                                                      }}
                                                                                  />
                                                                              )}
                                                                      </>
                                                                  )}
                                                              </Box>
                                                          </>
                                                      )}
                                                      {failed && (
                                                          <ListItemSecondaryAction
                                                              style={{
                                                                  width: '53%',
                                                              }}
                                                          >
                                                              <Box
                                                                  display={
                                                                      'flex'
                                                                  }
                                                                  alignItems={
                                                                      'center'
                                                                  }
                                                                  justifyContent={
                                                                      'space-between'
                                                                  }
                                                              >
                                                                  {isWarning && (
                                                                      <TextField
                                                                          id={`outlined-basic-${val.id}`}
                                                                          label="Add comment to convert*"
                                                                          variant="outlined"
                                                                          value={
                                                                              val &&
                                                                              val.comment
                                                                                  ? val.comment
                                                                                  : ''
                                                                          }
                                                                          multiline={
                                                                              true
                                                                          }
                                                                          style={{
                                                                              minWidth: 280,
                                                                          }}
                                                                          onChange={(
                                                                              e,
                                                                          ) => {
                                                                              handleComment(
                                                                                  e,
                                                                                  val,
                                                                              );
                                                                          }}
                                                                      />
                                                                  )}
                                                                  <Box
                                                                      width={
                                                                          '210px'
                                                                      }
                                                                      display={
                                                                          'flex'
                                                                      }
                                                                      alignItems={
                                                                          'center'
                                                                      }
                                                                      justifyContent={
                                                                          'space-between'
                                                                      }
                                                                      className={
                                                                          classes.failureBox
                                                                      }
                                                                  >
                                                                      <Typography
                                                                          className={
                                                                              isWarning
                                                                                  ? classes.warningText
                                                                                  : classes.failureText
                                                                          }
                                                                      >
                                                                          {
                                                                              val.responseMessage
                                                                          }
                                                                      </Typography>
                                                                      <InfoIcon
                                                                          color="inherit"
                                                                          style={{
                                                                              color: isWarning
                                                                                  ? '#FB8A06'
                                                                                  : 'red',
                                                                          }}
                                                                      />
                                                                  </Box>
                                                              </Box>
                                                          </ListItemSecondaryAction>
                                                      )}
                                                  </ListItem>
                                              );
                                          })
                                        : null}
                                </>
                            </List>
                        </Box>
                    </Box>
                ) : (
                    <Box width={'100%'} height={'240px'}>
                        <List component="nav">
                            {getData() && getData()?.length
                                ? getData()?.map((val, index) => {
                                      return (
                                          <ListItem
                                              divider
                                              className={
                                                  classes.minimizeListItem
                                              }
                                          >
                                              <ListItemText
                                                  primary={
                                                      val && val.name
                                                          ? val.name
                                                          : ''
                                                  }
                                              />
                                              <ListItemSecondaryAction>
                                                  {val &&
                                                  val.id &&
                                                  loadingStateStaff &&
                                                  loadingStateStaff[
                                                      `${val.id}`
                                                  ] &&
                                                  !loadingStateStaff[
                                                      `${val.id}`
                                                  ].loading &&
                                                  loadingStateStaff[`${val.id}`]
                                                      .converted ? (
                                                      <CheckCircleIcon
                                                          color="inherit"
                                                          style={{
                                                              color: '#4CAF50',
                                                          }}
                                                      />
                                                  ) : (
                                                      <InfoIcon
                                                          color="inherit"
                                                          style={{
                                                              color: '#FB8A06',
                                                          }}
                                                      />
                                                  )}
                                              </ListItemSecondaryAction>
                                          </ListItem>
                                      );
                                  })
                                : null}
                        </List>
                    </Box>
                )}
            </DialogContent>
            {!minimized && (
                <>
                    <Divider />
                    <DialogActions className={classes.dialogActions}>
                        {actions.map((action, index) => (
                            <Box key={index}>{action}</Box>
                        ))}
                    </DialogActions>
                </>
            )}
            <Backdrop
                className={classes.backDropRoot}
                open={loading}
                onClick={null}
            >
                <CircularProgress color="primary" />
            </Backdrop>
            {leadModalOpen && (
                <LeadModal
                    open={leadModalOpen}
                    leadInfo={selectedItemData}
                    shouldShowActionBar={
                        selectedItemData?.shouldShowActionBar ?? false
                    }
                    isEdit={true}
                    onClose={() => {
                        //loadStaffs();
                        setLeadModalOpen(false);
                    }}
                />
            )}
        </Dialog>
    );
}

export default ConvertToStaffModal;
