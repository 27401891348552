import React, { useState, useEffect } from 'react';
import './styles.scss';
import { connect } from 'react-redux';
import {
    getAllStaffs,
    getSingleStaff,
} from '../../store/actions/staffActionCreator';
import { getAllPayment } from '../../store/actions/paymentActionCreator';
import {
    Box,
    Button,
    Divider,
    Paper,
    Typography,
    ListItemIcon,
    IconButton,
    Tooltip,
} from '@material-ui/core';
import Tabbar from '../../components/Tabbar';
import {
    AddWorkIcon,
    AllowanceBonusIcon,
    AttendanceIcon,
    CheckMarkGreen,
    DeductionsIcon,
    GeofenceAttendanceIcon,
    PaymentsIcon,
    PaymentSummaryIcon,
    TrackTraceIcon,
    VerifyKycIcon,
    StaffEvIcon,
    TrackingEnabledIcon,
    GeofenceAutoApprovalIcon,
    PayslipIcon,
} from '../../assets/svg';
import { PaymentSummary, TrackNTrace, VerifyKyc, EVehicle } from '.';
import AddWorkModal from '../../components/Modals/AddWorkModal';
import AddPaymentModal from '../../components/Modals/AddPaymentModal';
import AddAllowanceBonusModal from '../../components/Modals/AddAllowanceBonusModal';
import AddDeductionModal from '../../components/Modals/AddDeductionModal';
import Attendance from '../attendance/Attendance';
import AddEditStaffModal from '../../components/Modals/AddEditStaffModal';
import AddBankDetailsModal from '../../components/Modals/AddBankDetailsModal';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import EditIcon from '@material-ui/icons/Edit';
import AddClientModal from '../../components/Modals/AddClientModal';
import AddVendorModal from '../../components/Modals/AddVendorModal';
import moment from 'moment';
import { getActiveInitRoute, getPermissions } from '../../utils/helper';
import Payslips from './payslips/Payslips';
import ShiftColumnItemPopper from '../../components/Containers/ShiftColumnItemPopper';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { fetchClientList } from '../../store/actions/staffActionCreator';
let lastIndex = 0;

function StaffDetail({
    isEnabledXUtil,
    getAllStaffs,
    getSingleStaff,
    staff,
    configs,
    dispatchGetAllPayment,
    dispatchFetchClientList,
    ...props
}) {
    const [tabIndex, settabIndex] = useState(
        props?.location?.state?.detailsTabIndex ?? 0,
    );
    const [modalTabIndex, setModalTabIndex] = useState(-1);
    const [staffInfo, setStaffInfo] = useState(staff?.singleStaffInfo ?? {});
    const [staffModalOpen, setStaffModalOpen] = useState(false);
    const [bankDetailsModalOpen, setBankDetailsModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [searchVal] = useState(props.location?.state?.searchVal);
    const [page] = useState(props.location?.state?.page);
    const [rowsPerPage] = useState(props.location?.state?.rowsPerPage);
    const [editVendorModalOpen, setEditVendorModalOpen] = React.useState(false);
    const [editClientModalOpen, setEditClientModalOpen] = React.useState(false);
    const [viewClientModalOpen, setViewClientModalOpen] = React.useState(false);
    const [clientList, setClientList] = useState([]);
    const [permissions, setPermissions] = useState(getPermissions('staff'));
    const getClientName = (customerId) => {
        let clientDetails = clientList.find(
            (ele) => ele?.customerId == customerId,
        );
        return !!clientDetails ? `${clientDetails?.name}` : '';
    };
    React.useEffect(() => {
        let x = staffInfo.id;
        for (let i = 0; i < staff?.lineItems?.length; i++) {
            if (staff?.lineItems?.[i].id === x) {
                setStaffInfo(staff?.lineItems?.[i]);
            }
        }
    }, [staff?.lineItems]);

    useEffect(() => {
        if (!permissions.read) {
            props.history.push(`/${getActiveInitRoute()}`);
        }
        dispatchFetchClientList(
            async (resp) => {
                setClientList(resp?.response?.customers);
            },
            (err) => {},
        );
    }, []);

    useEffect(() => {
        setPermissions(getPermissions('staff'));
    }, [configs]);

    React.useEffect(() => {
        setStaffInfo(staff?.singleStaffInfo);
    }, [staff?.singleStaffInfo]);

    const tabs = [
        {
            label: 'Verify KYC',
            icon: <VerifyKycIcon />,
            content: (
                <VerifyKyc permissions={permissions} staffInfo={staffInfo} />
            ),
            disabled: !permissions?.read,
        },
        {
            label: 'Payment Summary',
            icon: <PaymentSummaryIcon />,
            content: (
                <PaymentSummary
                    permissions={permissions}
                    staffInfo={staffInfo}
                />
            ),
            disabled: !permissions?.read,
        },
        {
            label: `${staffInfo?.freelancer ? 'Invoices' : 'Payslips'}`,
            icon: <PayslipIcon />,
            content: <Payslips staffInfo={staffInfo} />,
            disabled: !permissions?.read,
        },
        {
            label: 'Attendance',
            icon: <AttendanceIcon />,
            content: (
                <Attendance staffInfo={staffInfo} flowType={'STAFF_DETAILS'} />
            ),
            disabled: !getPermissions('attendance')?.read,
        },
        // {
        //     label: 'Track & Trace',
        //     icon: <TrackTraceIcon />,
        //     content: <TrackNTrace staffInfo={staffInfo} />,
        //     disabled: !getPermissions('map_view')?.read,
        // },
        {
            label: 'Add Work',
            icon: <AddWorkIcon />,
            isModal: true,
            content: (
                <AddWorkModal
                    staffInfo={staffInfo}
                    open={true}
                    onClose={() => {
                        setModalTabIndex(-1);
                        getAllPayments();
                    }}
                />
            ),
            disabled: !permissions.create,
        },
        {
            label: 'Add Payment',
            icon: <PaymentsIcon />,
            isModal: true,
            content: (
                <AddPaymentModal
                    open={true}
                    staffInfo={staffInfo}
                    onClose={() => {
                        setModalTabIndex(-1);
                        getAllPayments();
                    }}
                />
            ),
            disabled: !permissions.create,
        },
        {
            label: 'Add Allowance',
            icon: <AllowanceBonusIcon />,
            isModal: true,
            content: (
                <AddAllowanceBonusModal
                    open={true}
                    staffInfo={staffInfo}
                    onClose={() => {
                        setModalTabIndex(-1);
                        getAllPayments();
                    }}
                />
            ),
            disabled: !permissions.create,
        },
        {
            label: 'Add Deduction',
            icon: <DeductionsIcon />,
            isModal: true,
            content: (
                <AddDeductionModal
                    open={true}
                    staffInfo={staffInfo}
                    onClose={() => {
                        setModalTabIndex(-1);
                        getAllPayments();
                    }}
                />
            ),
            disabled: !permissions.create,
        },
        {
            label: 'EV',
            icon: <StaffEvIcon />,
            content: (
                <EVehicle permissions={permissions} staffInfo={staffInfo} />
            ),
            disabled: !permissions?.read,
        },
    ];
    console.log('last', props);

    const getAllPayments = async (filter = {}) => {
        await setLoading(true);
        dispatchGetAllPayment(
            { id: staffInfo.id },
            async (resp) => {
                console.log(resp);
                await setLoading(false);
            },
            async (resp) => {
                console.log(resp);
                await setLoading(false);
            },
        );
    };

    console.log('staff permissions', permissions);
    return (
        <div className="staff-details">
            {/* <TitleHeader title={'Staff Details'} isBackButton={true} /> */}

            <Paper style={{ padding: '18px 24px 16px 18px' }} elevation={0}>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    <Box
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Box>
                            <Tooltip title="Back" placement="top">
                                <ListItemIcon
                                    onClick={(e) => {
                                        console.log('propsssss', props);
                                        if (
                                            !!props?.location?.state &&
                                            !!props?.location?.state?.source &&
                                            props?.location?.state?.source?.includes(
                                                'reports',
                                            )
                                        )
                                            props.history.push('/report', {
                                                ...props?.location?.state,
                                            });
                                        else
                                            props.history.push('/staff', {
                                                searchVal,
                                                page,
                                                rowsPerPage,
                                            });
                                    }}
                                    style={{
                                        minWidth: '0',
                                        color: '#000000',
                                        cursor: 'pointer',
                                        marginRight: '8px',
                                    }}
                                >
                                    <ArrowBackIcon
                                        style={{ fontSize: '1.5rem' }}
                                    />
                                </ListItemIcon>
                            </Tooltip>
                        </Box>
                        <Typography
                            style={{
                                fontFamily: 'Nunito',
                                fontSize: '24px',
                                fontWeight: 'bold',
                                color: '#14112d',
                            }}
                            variant="h6"
                            component="span"
                            gutterBottom
                        >
                            {staffInfo?.name}
                        </Typography>

                        {!!staffInfo?.kycVerified && (
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <Typography
                                    style={{
                                        fontFamily: 'Nunito',
                                        fontSize: '18px',
                                        fontWeight: 'bold',
                                        color: staffInfo?.kycVerified
                                            ? '#4caf50'
                                            : '',
                                        marginLeft: '1rem',
                                    }}
                                    variant="body1"
                                    component="span"
                                    gutterBottom
                                >
                                    KYC Verified
                                </Typography>
                                <div style={{ marginLeft: '.5rem' }}>
                                    <CheckMarkGreen />

                                    {/* {staffInfo?.kycVerified ? (
                                        <CheckMarkGreen />
                                    ) : (
                                        <HighlightOffIcon
                                            style={{ color: 'red' }}
                                        />
                                    )} */}
                                </div>
                            </div>
                        )}

                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                padding: 8,
                                paddingTop: 4,
                                paddingBottom: 4,
                                border: staffInfo.trainingCompleted
                                    ? '1px solid #4caf50'
                                    : '1px solid #FB8A06',
                                borderRadius: 8,
                                marginLeft: '1rem',
                            }}
                        >
                            <Typography
                                style={{
                                    fontFamily: 'Nunito',
                                    fontSize: '12px',
                                    fontWeight: 600,
                                    // color: staffInfo?.kycVerified
                                    //     ? '#4caf50'
                                    //     : '#FB8A06',
                                    color: staffInfo.trainingCompleted
                                        ? '#4caf50'
                                        : '#FB8A06',
                                }}
                                variant="body1"
                                component="span"
                            >
                                {staffInfo.trainingCompleted
                                    ? 'Training Completed'
                                    : 'Training Pending'}
                            </Typography>
                        </div>
                    </Box>
                    <Box
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        {staffInfo?.bankDetailsVerified ? (
                            <Button
                                style={{
                                    marginRight: '1rem',
                                    fontSize: 14,
                                    color: '#4caf50',
                                    border: '1px solid #4caf50',
                                    fontWeight: 700,
                                }}
                                variant="outlined"
                                color="primary"
                                onClick={(e) => setBankDetailsModalOpen(true)}
                                startIcon={<CheckMarkGreen />}
                                disabled={true || !permissions.update}
                                disableElevation
                            >
                                Edit Bank Details
                            </Button>
                        ) : (
                            <Button
                                style={{ marginRight: '1rem', fontSize: 14 }}
                                variant="outlined"
                                color="primary"
                                onClick={(e) => setBankDetailsModalOpen(true)}
                                disabled={!permissions.create}
                                disableElevation
                            >
                                Add Bank Details
                            </Button>
                        )}

                        <Button
                            variant="contained"
                            style={{ fontSize: 14 }}
                            color="primary"
                            onClick={(e) => setStaffModalOpen(true)}
                            disabled={!permissions.update}
                            disableElevation
                        >
                            {'Edit Info'}
                        </Button>
                    </Box>
                </div>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        justifyContent: 'space-between',
                    }}
                >
                    <Box
                        style={{
                            marginLeft: '2rem',
                        }}
                    >
                        <Typography
                            style={{
                                fontFamily: 'Nunito',
                                fontSize: '18px',
                                color: '#14112d',
                                fontWeight: 'bold',
                            }}
                            variant="body1"
                            gutterBottom
                        >
                            {/* {`${staffInfo?.hubName ?? '-'} | ${
                                staffInfo?.contactNumber
                            } | DOJ : 26 Jan 2015`} */}
                            {!!isEnabledXUtil ? (
                                <ShiftColumnItemPopper
                                    data={staffInfo}
                                    selectedItem={{
                                        ...staffInfo,
                                    }}
                                    limit={2}
                                />
                            ) : (
                                <b>
                                    {staffInfo?.hubName
                                        ? `${staffInfo?.hubName}`
                                        : ''}
                                </b>
                            )}

                            <span style={{ fontWeight: 600 }}>
                                {/* {' | '} */}
                                {staffInfo?.staffUniqueId
                                    ? ` | ${staffInfo?.staffUniqueId}`
                                    : ''}
                            </span>
                            <span style={{ fontWeight: 600 }}>
                                {/* {' | '} */}
                                {staffInfo?.contactNumber
                                    ? ` | ${staffInfo?.contactNumber}`
                                    : ''}
                            </span>
                            <span style={{ fontWeight: 600 }}>
                                {/* {' | '} */}
                                {staffInfo?.vehicleTypeName
                                    ? ` | ${staffInfo?.vehicleTypeName}`
                                    : ''}
                            </span>
                            <span style={{ fontWeight: 600 }}>
                                {/* {' | '} */}
                                {staffInfo?.dateOfBirthEpochMillis > 0
                                    ? ` | DOB : ${moment(
                                          staffInfo?.dateOfBirthEpochMillis,
                                      ).format('Do MMM YYYY')}`
                                    : ''}
                            </span>
                            {staffInfo?.customers?.length > 0 && (
                                <span
                                    style={{
                                        fontWeight: 600,
                                        alignItems: 'center',
                                    }}
                                >
                                    {!!staffInfo?.customers?.[0]?.customerId
                                        ? ` | ${getClientName(
                                              staffInfo?.customers?.[0]
                                                  ?.customerId,
                                          )} `
                                        : ' | '}

                                    {!!staffInfo?.customers?.[0]?.staffCustCode
                                        ? `(${staffInfo?.customers?.[0]?.staffCustCode})`
                                        : ''}

                                    {staffInfo?.customers?.length > 1 && (
                                        <>
                                            <span
                                                style={{
                                                    fontWeight: 700,
                                                    fontSize: 12,
                                                }}
                                            >
                                                ...+
                                                {staffInfo?.customers?.length -
                                                    1}
                                            </span>
                                            <Tooltip
                                                title="View Clients"
                                                placement="top"
                                            >
                                                <IconButton
                                                    size={'small'}
                                                    style={{
                                                        marginLeft: 5,
                                                        fontSize: 12,
                                                        padding: 0,
                                                        color: 'green',
                                                    }}
                                                    onClick={() => {
                                                        setEditClientModalOpen(
                                                            false,
                                                        );
                                                        setViewClientModalOpen(
                                                            true,
                                                        );
                                                    }}
                                                    disabled={
                                                        !permissions.update
                                                    }
                                                >
                                                    <VisibilityIcon
                                                        fontSize={'small'}
                                                    />
                                                </IconButton>
                                            </Tooltip>
                                        </>
                                    )}
                                    <Tooltip
                                        title="Edit Clients"
                                        placement="top"
                                    >
                                        <IconButton
                                            size={'small'}
                                            color="primary"
                                            style={{
                                                marginLeft: 5,
                                                fontSize: 12,
                                                padding: 0,
                                            }}
                                            onClick={() => {
                                                setViewClientModalOpen(false);
                                                setEditClientModalOpen(true);
                                            }}
                                            disabled={!permissions.update}
                                        >
                                            <EditIcon fontSize={'small'} />
                                        </IconButton>
                                    </Tooltip>
                                </span>
                            )}

                            {staffInfo?.vendorId > 0 && (
                                <span
                                    style={{
                                        fontWeight: 600,
                                        alignItems: 'center',
                                    }}
                                >
                                    {/* {' | '} */}
                                    {staffInfo?.vendorName
                                        ? ` | ${staffInfo?.vendorName} `
                                        : ''}
                                    <Tooltip title="Edit" placement="top">
                                        <IconButton
                                            size={'small'}
                                            color="primary"
                                            style={{
                                                marginLeft: 5,
                                                fontSize: 12,
                                                padding: 0,
                                            }}
                                            onClick={() => {
                                                setEditVendorModalOpen(true);
                                            }}
                                            disabled={!permissions.update}
                                        >
                                            <EditIcon fontSize={'small'} />
                                        </IconButton>
                                    </Tooltip>
                                </span>
                            )}
                        </Typography>
                    </Box>
                    <Box
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                        }}
                    >
                        {staffInfo?.trackAndTraceEnabled && (
                            <>
                                <div
                                    style={{
                                        marginRight: '.5rem',
                                        marginLeft: '.5rem',
                                    }}
                                >
                                    <TrackingEnabledIcon />
                                </div>
                                <Typography
                                    style={{
                                        fontFamily: 'Nunito',
                                        fontSize: '14px',
                                        fontWeight: 'bold',
                                        color: '#14112d',
                                    }}
                                    className="tracking-text"
                                    variant="body1"
                                    gutterBottom
                                >
                                    {`Tracking Enabled`}
                                    {staffInfo?.geofenceAutoApproval
                                        ? ' | '
                                        : ''}
                                </Typography>
                            </>
                        )}
                        {staffInfo?.geofenceAutoApproval ? (
                            <>
                                <div
                                    style={{
                                        marginRight: '.5rem',
                                        marginLeft: '.5rem',
                                    }}
                                >
                                    <GeofenceAutoApprovalIcon />
                                </div>
                                <Typography
                                    style={{
                                        fontFamily: 'Nunito',
                                        fontSize: '14px',
                                        fontWeight: 'bold',
                                        color: '#14112d',
                                    }}
                                    className="tracking-text"
                                    variant="body1"
                                    gutterBottom
                                >
                                    {`Geofenced Auto Approval`}
                                </Typography>
                            </>
                        ) : (
                            <> </>
                        )}
                    </Box>
                </div>
            </Paper>
            <Paper
                style={{
                    padding: '1.5rem',
                    marginTop: '1rem',
                    marginBottom: '0.5rem',
                }}
                elevation={0}
            >
                <Tabbar
                    tabs={tabs}
                    tabIndex={tabIndex}
                    onChange={(index, isModal) => {
                        lastIndex = tabIndex;
                        if (isModal) {
                            setModalTabIndex(index);
                        } else {
                            settabIndex(index);
                            setModalTabIndex(-1);
                        }
                    }}
                />
                <Divider style={{ margin: '1rem 0' }} />
                {tabs[modalTabIndex]?.isModal && tabs[modalTabIndex].content}
                {!tabs[tabIndex].disabled && tabs[tabIndex].content}
                {staffModalOpen && (
                    <AddEditStaffModal
                        open={staffModalOpen}
                        onClose={() => {
                            // console.log('Closed');
                            setStaffModalOpen(false);
                            getSingleStaff(
                                staffInfo.id,
                                () => {
                                    console.log('getSingleSTaff SUCCESS');
                                },
                                () => {
                                    console.log('getSingleSTaff FAILED');
                                },
                            );
                        }}
                        isEdit={true}
                        data={staffInfo}
                    />
                )}
                {bankDetailsModalOpen && (
                    <AddBankDetailsModal
                        open={bankDetailsModalOpen}
                        onClose={() => {
                            // console.log('Closed');
                            setBankDetailsModalOpen(false);
                            getSingleStaff(
                                staffInfo.id,
                                () => {
                                    console.log('getSingleSTaff SUCCESS');
                                },
                                () => {
                                    console.log('getSingleSTaff FAILED');
                                },
                            );
                        }}
                        isEdit={staffInfo?.bankDetailsVerified ? true : false}
                        data={staffInfo}
                    />
                )}
                {(editClientModalOpen || viewClientModalOpen) && (
                    <AddClientModal
                        open={
                            editClientModalOpen || viewClientModalOpen
                                ? true
                                : false
                        }
                        isEdit={true}
                        data={staffInfo}
                        onClose={() => {
                            console.log('Closed');
                            getSingleStaff(
                                staffInfo.id,
                                () => {
                                    console.log('getSingleSTaff SUCCESS');
                                },
                                () => {
                                    console.log('getSingleSTaff FAILED');
                                },
                            );
                            setEditClientModalOpen(false);
                            setViewClientModalOpen(false);
                        }}
                    />
                )}
                {editVendorModalOpen && (
                    <AddVendorModal
                        open={editVendorModalOpen}
                        isEdit={true}
                        data={staffInfo}
                        onClose={() => {
                            console.log('Closed');
                            getSingleStaff(
                                staffInfo.id,
                                () => {
                                    console.log('getSingleSTaff SUCCESS');
                                },
                                () => {
                                    console.log('getSingleSTaff FAILED');
                                },
                            );
                            setEditVendorModalOpen(false);
                        }}
                    />
                )}
            </Paper>
        </div>
    );
}

const mapStateToProps = (state) => ({
    staff: state.staff,
    configs: state.user.config,
    isEnabledXUtil: state?.user?.config?.isEnabledXUtil,
});

const mapDispatchToProps = (dispatch) => ({
    getAllStaffs: (payload, onSuccess, onError) =>
        dispatch(getAllStaffs(payload, onSuccess, onError)),

    getSingleStaff: (payload, onSuccess, onError) =>
        dispatch(getSingleStaff(payload, onSuccess, onError)),

    dispatchGetAllPayment: (payload, onSuccess, onError) =>
        dispatch(getAllPayment(payload, onSuccess, onError)),
    dispatchFetchClientList: (onSuccess, onError) =>
        dispatch(fetchClientList(onSuccess, onError)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StaffDetail);
