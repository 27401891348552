import * as constants from './../constants';

const defaultState = {};

export default function controlCenterReducer(state = defaultState, action) {
    switch (action.type) {
        case constants.SET_FILTER_UPDATE:
            return {
                ...state,
                filters: !!action?.payload
                    ? {
                          ...state.filters,
                          ...action.payload,
                      }
                    : null,
            };
        default:
            return state;
    }
}
