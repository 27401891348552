import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
    Button,
    Box,
    Typography,
    withStyles,
    makeStyles,
    IconButton,
    List,
    ListItem,
    ListItemSecondaryAction,
} from '@material-ui/core';

import Modal from '../../../components/Modals/Modal';
import { CustomDatePicker } from '../../../components/DateTimePicker';
import moment from 'moment';

import ChevronLeftRoundedIcon from '@material-ui/icons/ChevronLeftRounded';
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import DateRangeIcon from '@material-ui/icons/DateRange';
import { PersonTickIcon, PunchInIcon, PunchOutIcon } from '../../../assets/svg';
import { getSingleStaffAttendanceData } from '../../../store/actions/attendanceActionCreator';

const useStyles = makeStyles((theme) => ({
    iconButton: {
        color: '#000',
        padding: '5px',
        borderRadius: '8px',
        border: '1px solid #4d4ab5',
        backgroundColor: '#f1f3f6',
    },
    calendarIconButton: {
        color: '#4d4ab5',
        padding: '5px',
        borderRadius: '8px',
        border: '1px solid #4d4ab5',
        marginLeft: '24px',
    },
    listItem: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        padding: '16px',
        marginBottom: '8px ',
        border: '1px solid #ebedf8 ',
        // borderRadius: '8px !important',
        // height: '56px !important',÷
    },
    listItemHeader: {
        border: '0px',
        marginBottom: '8px',
        color: '#525050',
        fontWeight: 'bold',
    },

    buttonDefaultStyles: {
        border: '1px solid #2b2b2b',
        borderRadius: '8px',
        width: '120px',
        height: '40px',
    },
    buttonText: {
        color: '#2b2b2b',
        fontSize: '14px',
        fontWeight: 700,
    },
}));

function AttendanceModal({
    open,
    onClose,
    attendanceAll,
    dispatchGetSingleStaffAttendanceData,
    ...props
}) {
    const classes = useStyles();
    const [isOpen, toggleModal] = useState(open);

    const [selectedDate, setSelectedDate] = useState(
        moment(attendanceAll?.dateDisplay),
    );
    const [loading, setLoading] = useState(false);
    const [defaultList, setDefaultList] = useState(
        attendanceAll?.lineItems ?? [],
    );

    useEffect(() => {
        setDefaultList(attendanceAll?.lineItems);
    }, [attendanceAll?.lineItems]);

    const [datePickerOpen, setDatePickerOpen] = useState(false);

    const handleClose = () => {
        toggleModal(!isOpen);
        onClose && onClose();
    };

    const getAllAttendanceInfo = async (payload = {}) => {
        await setLoading(true);
        dispatchGetSingleStaffAttendanceData(
            {
                staffId: props.staffInfo.id,
                date: selectedDate.format('MMM-YYYY'),
                data: payload,
            },
            () => {
                setLoading(false);
                console.log('getSingleStaffAttendanceData SUCCESS');
            },
            () => {
                setLoading(false);
                console.log('getSingleStaffAttendanceData FAILED');
            },
        );
    };

    useEffect(() => {
        getAllAttendanceInfo();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDate]);

    return (
        <Modal
            onClose={handleClose}
            open={isOpen}
            title={`${props?.staffInfo?.name ?? '-'}`}
            subtitle={`${props?.staffInfo?.hubName ?? '-'} | ${
                props?.staffInfo?.contactNumber ?? '-'
            }`}
            className={classes.modal}
            maxWidth="md"
            subtitleStyle={{
                fontSize: 20,
                fontWeight: 400,
            }}
            loading={loading}
        >
            <Box
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginBottom: '16px',
                }}
            >
                <IconButton
                    className={classes.iconButton}
                    onClick={() => {
                        setSelectedDate(moment(selectedDate).add(-1, 'months'));
                    }}
                >
                    <ChevronLeftRoundedIcon />
                </IconButton>
                <Typography
                    style={{
                        padding: '0px 16px',
                        color: '#4d4ab5',
                        fontWeight: 600,
                    }}
                >
                    {selectedDate.format('MMMM YYYY')}
                </Typography>
                <IconButton
                    className={classes.iconButton}
                    onClick={() => {
                        setSelectedDate(moment(selectedDate).add(+1, 'months'));
                    }}
                >
                    <ChevronRightRoundedIcon />
                </IconButton>
                <IconButton
                    className={classes.calendarIconButton}
                    onClick={() => {
                        setDatePickerOpen(true);
                    }}
                >
                    <DateRangeIcon size="small" />
                </IconButton>
            </Box>
            {datePickerOpen && (
                <CustomDatePicker
                    open={datePickerOpen}
                    onOpen={() => setDatePickerOpen(true)}
                    onClose={() => setDatePickerOpen(false)}
                    format="d MMM yyyy"
                    views={['year', 'month']}
                    value={selectedDate}
                    onChange={(e) => {
                        setSelectedDate(moment(e));
                    }}
                />
            )}

            <Box>
                <List component="nav">
                    <Box>
                        {defaultList.map((value, index) => {
                            return (
                                <ListItem
                                    className={classes.listItem}
                                    // onClick={() => {
                                    //     alert('list item clicked');
                                    // }}
                                    key={index}
                                    style={
                                        index % 2
                                            ? {
                                                  backgroundColor:
                                                      'rgba(241, 243, 246, 1)',
                                              }
                                            : {}
                                    }
                                >
                                    <Box
                                        style={{
                                            display: 'flex',
                                            flex: '0 1 50%',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Typography
                                            style={{
                                                display: 'flex',
                                                flex: '1 1 25%',
                                                wordBreak: 'break-all',
                                                fontWeight: 600,
                                            }}
                                        >
                                            {value?.dateDisplay ?? '-'}
                                        </Typography>
                                        <Typography
                                            style={{
                                                fontWeight: 600,
                                                display: 'flex',
                                                flex: '1 1 25%',
                                            }}
                                        >
                                            {value?.attendanceHours ?? '-'}
                                        </Typography>
                                    </Box>
                                    <ListItemSecondaryAction
                                        style={{
                                            width: '45%',
                                        }}
                                    >
                                        <Box
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Button
                                                startIcon={
                                                    <PunchInIcon
                                                        style={{
                                                            width: '24px',
                                                            height: '24px',
                                                        }}
                                                    />
                                                }
                                                className={
                                                    classes.buttonDefaultStyles
                                                }
                                                style={
                                                    value?.punchIn?.status ===
                                                    'APPROVED'
                                                        ? {
                                                              // CHECK : AVAIL - Add BgColor,
                                                              backgroundColor:
                                                                  'rgba(76, 175, 80, 0.16)',
                                                              border: '0px',
                                                          }
                                                        : {
                                                              // CHECK: Disabled - add opacity
                                                              // opacity: 0.3,
                                                          }
                                                }
                                                disabled={true}
                                                disableElevation
                                            >
                                                <Typography
                                                    className={
                                                        classes.buttonText
                                                    }
                                                    style={
                                                        value?.punchIn
                                                            ?.status ===
                                                        'APPROVED'
                                                            ? {
                                                                  // CHECK: AVAIL - Add color
                                                                  color: '#4caf50',
                                                              }
                                                            : {}
                                                    }
                                                >
                                                    {value?.punchIn?.status ===
                                                    'APPROVED'
                                                        ? value?.punchIn
                                                              ?.timeDisplay
                                                        : 'Punch In'}
                                                </Typography>
                                            </Button>

                                            <Button
                                                className={
                                                    classes.buttonDefaultStyles
                                                }
                                                startIcon={
                                                    <PunchOutIcon
                                                        style={{
                                                            width: '24px',
                                                            height: '24px',
                                                        }}
                                                    />
                                                }
                                                disabled={
                                                    true
                                                    // value?.punchOut
                                                    //     ?.status ===
                                                    // 'APPROVED'
                                                }
                                                disableElevation
                                                style={{
                                                    border: '0px',
                                                    backgroundColor:
                                                        'rgba(51, 77, 110, 0.16)',
                                                }}
                                            >
                                                <Typography
                                                    className={
                                                        classes.buttonText
                                                    }
                                                    style={{
                                                        color: '#334d6e',
                                                    }}
                                                >
                                                    {value?.punchOut?.status ===
                                                    'APPROVED'
                                                        ? value?.punchOut
                                                              ?.timeDisplay
                                                        : 'Punch Out'}
                                                    {/* Punch Out */}
                                                    {/* CHECK: AVAIL - Add time */}
                                                    {/* 09:00 PM */}
                                                </Typography>
                                            </Button>

                                            <Button
                                                className={
                                                    classes.buttonDefaultStyles
                                                }
                                                disabled={true}
                                                disableElevation
                                                style={
                                                    value?.status === 'Absent'
                                                        ? {
                                                              // CHECK: if(absent then change color)
                                                              display: 'flex',
                                                              alignItems:
                                                                  'center',
                                                              justifyContent:
                                                                  'center',
                                                              border: '0px',
                                                              backgroundColor:
                                                                  'rgba(244, 67, 54, 0.16)',
                                                          }
                                                        : {
                                                              display: 'flex',
                                                              alignItems:
                                                                  'center',
                                                              justifyContent:
                                                                  'center',
                                                              // CHECK: if(absent then remove border and add this)
                                                          }
                                                }
                                            >
                                                <Typography
                                                    className={
                                                        classes.buttonText
                                                    }
                                                    style={
                                                        value?.status ===
                                                        'Absent'
                                                            ? {
                                                                  // CHECK: if(absent then change color)
                                                                  color: '#f44336',
                                                              }
                                                            : {}
                                                    }
                                                >
                                                    {value?.status}
                                                </Typography>
                                            </Button>
                                        </Box>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            );
                        })}
                    </Box>
                </List>
            </Box>
        </Modal>
    );
}
const mapStateToProps = (state) => ({
    staff: state.staff,
    attendanceAll: state.attendance.all,
});

const mapDispatchToProps = (dispatch) => ({
    dispatchGetSingleStaffAttendanceData: (payload, onSuccess, onError) =>
        dispatch(getSingleStaffAttendanceData(payload, onSuccess, onError)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AttendanceModal);
