import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';

const CustomSwitch = withStyles((theme) => ({
    root: {
        width: 50,
        height: 24,
        padding: 0,
    },
    switchBase: {
        padding: 1,
        '&$checked': {
            transform: 'translateX(26px)',
            color: theme.palette.common.white,
            '& + $track': {
                backgroundColor: '#1AB55C',
                opacity: 1,
                border: 'none',
            },
        },
        '&$focusVisible $thumb': {
            color: '#1AB55C',
            border: '6px solid #fff',
            opacity: 1,
        },
    },
    thumb: {
        width: 22,
        height: 22,
    },
    track: {
        borderRadius: 24 / 2,
        backgroundColor: '#979797',
        opacity: 1,
        transition: theme.transitions.create(['background-color']),
    },
    disabledTrack: {
        borderRadius: 24 / 2,
        backgroundColor: '#F2F2F3',
        opacity: 1,
    },
    checked: {},
    focusVisible: {},
}))(({ classes, ...props }) => {
    return (
        <Switch
            focusVisibleClassName={classes.focusVisible}
            disableRipple
            classes={{
                root: classes.root,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: props?.disabled ? classes.disabledTrack : classes.track,
                checked: classes.checked,
            }}
            {...props}
        />
    );
});

export default CustomSwitch;
