import React, { useState } from 'react';
import {
    Box,
    Typography,
    Tooltip,
    Divider,
    Tabs,
    Tab,
    withStyles,
    AppBar,
    makeStyles,
    useTheme,
} from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import {
    Timeline,
    TimelineItem,
    TimelineSeparator,
    TimelineConnector,
    TimelineContent,
    TimelineDot,
    TimelineOppositeContent,
} from '@material-ui/lab';
import { eventStyleFlag, getTabsProps } from '../helper';
import moment from 'moment';
import LocationPointCard from './LocationPointCard';
import EventCard from './EventCard';
interface EndTimelineItemProps {
    startPoint: any;
    endPoint: any;
    selectedPoint: any;
    events: any;
    aggUserEvents: any;
    onClick: Function;
    endAddress: any;
    stoppageReportData: any;
}

const useStyles = makeStyles((theme) => ({
    separator: {
        backgroundColor: '#e74c3c',
        border: '1px solid #fff',
        width: 20,
        height: 20,
        margin: 0,
    },
    separatorSelected: {
        backgroundColor: '#e74c3c',
        border: '2px solid #000',
        width: 20,
        height: 20,
        margin: 0,
    },
}));
const EndTimelineItem: React.FC<EndTimelineItemProps> = ({
    startPoint = {},
    endPoint = {},
    events = {},
    aggUserEvents,
    selectedPoint,
    onClick,
    endAddress,
    stoppageReportData,
}) => {
    const classes = useStyles();
    const theme = useTheme();
    const selected =
        endPoint?.deviceTimeEpoch ==
            selectedPoint?.selectedStartStoppagePoint ||
        endPoint?.deviceTimeEpoch == selectedPoint?.selectedEndStoppagePoint;
    return !!endPoint ? (
        <TimelineItem>
            <TimelineOppositeContent
                style={{
                    flex: 0,
                    padding: '6px',
                }}
            ></TimelineOppositeContent>
            <TimelineSeparator>
                <TimelineDot
                    className={
                        selected ? classes.separatorSelected : classes.separator
                    }
                    {...{
                        onClick: () => {
                            onClick(endPoint);
                        },
                    }}
                />
            </TimelineSeparator>
            <TimelineContent
                style={{
                    padding: 0,
                    margin: '0 0 0 8px',
                }}
            >
                {events?.events
                    ?.filter((event: any) => {
                        if (aggUserEvents?.length > 0) {
                            return (
                                (stoppageReportData?.length > 0
                                    ? event?.time >
                                      parseInt(
                                          stoppageReportData[
                                              stoppageReportData?.length - 1
                                          ]?.startTime ??
                                              stoppageReportData[
                                                  stoppageReportData?.length - 1
                                              ]?.time,
                                      )
                                    : event?.time >
                                      startPoint?.deviceTimeEpoch) &&
                                event?.time <= endPoint?.deviceTimeEpoch
                            );
                        } else {
                            return (
                                event?.time > startPoint?.deviceTimeEpoch &&
                                event?.time < endPoint?.deviceTimeEpoch
                            );
                        }
                    })
                    ?.map((event: any) => {
                        return (
                            <EventCard
                                {...{
                                    event,
                                }}
                            />
                        );
                    })}
                <Box
                    style={{
                        background: '#f6f7f9',
                        padding: 6,
                        borderRadius: 8,
                    }}
                >
                    <Box
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            marginBottom: 4,
                        }}
                    >
                        <Typography
                            style={{
                                fontSize: 12,
                                fontWeight: 700,
                            }}
                        >
                            Stopped
                        </Typography>
                        <Typography
                            style={{
                                fontSize: 12,
                                fontWeight: 700,
                                // margin: '4px 0',
                                // opacity: 0.8,
                            }}
                        >
                            {moment(parseInt(endPoint?.deviceTimeEpoch)).format(
                                // 'hh:mm A',
                                'D MMM YYYY hh:mm A',
                            )}
                        </Typography>
                    </Box>
                    <Typography
                        style={{
                            fontSize: 15,
                            fontWeight: 600,
                        }}
                    >
                        {endAddress ?? ''}
                    </Typography>
                </Box>
                {events?.events
                    ?.filter((event: any) => {
                        if (aggUserEvents?.length > 0) {
                            return event?.time >= endPoint?.deviceTimeEpoch;
                        } else {
                            return event?.time >= endPoint?.deviceTimeEpoch;
                        }
                    })
                    ?.map((event: any) => {
                        return (
                            <EventCard
                                {...{
                                    event,
                                }}
                            />
                        );
                    })}
            </TimelineContent>
        </TimelineItem>
    ) : (
        <></>
    );
};

export default EndTimelineItem;
