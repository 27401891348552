import 'date-fns';
import React, { useState } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
    DatePicker,
    DateTimePicker,
} from '@material-ui/pickers';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import {
    withStyles,
    TextField,
    Box,
    Button,
    IconButton,
    Typography,
} from '@material-ui/core';
import { CompactCustomTextField, CustomTextField } from '../Textfield';
import moment from 'moment';
import ReactMonthPicker from 'react-month-picker';
import InsertInvitationIcon from '@material-ui/icons/InsertInvitation';
import './styles.css';
import { showMessage } from '../../utils/helper';

export const CustomTimePicker = withStyles({
    root: {
        '& label.Mui-focused': {
            color: '#14112d',
        },
        marginBottom: '16px',

        '& .MuiOutlinedInput-root': {
            // '& fieldset': {
            //     borderColor: 'red',
            // },
            '&:hover fieldset': {
                borderColor: '#1bb55c',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#1bb55c',
            },
        },
        '& .MuiOutlinedInput-input': {
            padding: '16px',
            color: '#14112d',
        },
    },
})(({ classes, value, label, isRequired, onChange, ...props }) => {
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardTimePicker
                fullWidth={true}
                inputVariant="outlined"
                value={value}
                onChange={onChange}
                ampm={true}
                showTodayButton={true}
                todayLabel="Now"
                {...props}
                label={
                    !!label ? (
                        isRequired ? (
                            <>
                                {label}
                                <span style={{ color: 'red' }}>*</span>
                            </>
                        ) : (
                            label
                        )
                    ) : (
                        'Time'
                    )
                }
                classes={{
                    root: classes.root,
                }}
                keyboardIcon={<AccessTimeIcon />}
            />
        </MuiPickersUtilsProvider>
    );
});

export const CustomDatePicker = withStyles({
    root: {
        '& label.Mui-focused': {
            color: '#14112d',
        },
        marginBottom: '16px',

        '& .MuiOutlinedInput-root': {
            // '& fieldset': {
            //     borderColor: 'red',
            // },
            '&:hover fieldset': {
                borderColor: '#1bb55c',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#1bb55c',
            },
        },
        '& .MuiOutlinedInput-input': {
            padding: '16px',
            color: '#14112d',
        },
    },
})(
    ({
        classes,
        value,
        onChange,
        showInput = false,
        disabled,
        allowFutureDates = true,
        allowAge18 = true,
        minDate,
        maxDate,
        ...props
    }) => {
        return (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                    label="Date"
                    format="dd MMM yyyy"
                    fullWidth={true}
                    inputVariant="outlined"
                    value={value}
                    onChange={(...arg) => {
                        if (
                            !allowFutureDates &&
                            moment().diff(moment(arg[0])) < 0
                        ) {
                            showMessage(
                                'Future dates are not allowed',
                                'error',
                            );
                        } else if (
                            !allowAge18 &&
                            moment().diff(moment(arg?.[0]), 'years') < 18
                        ) {
                            showMessage('Age below 18 not allowed', 'error');
                        } else onChange(...arg);
                    }}
                    showTodayButton={true}
                    style={{
                        marginBottom: '16px',
                    }}
                    {...props}
                    classes={{
                        root: classes.root,
                    }}
                    disabled={disabled}
                    minDate={minDate}
                    maxDate={
                        allowFutureDates && !!maxDate
                            ? moment(maxDate)
                            : moment()
                    }
                    TextFieldComponent={
                        !showInput
                            ? (textProp) => {
                                  return (
                                      <CustomTextField
                                          {...textProp}
                                          style={{
                                              display: 'none',
                                          }}
                                          disabled={disabled}
                                      />
                                  );
                              }
                            : ({ ...props }) => {
                                  return (
                                      <CustomTextField
                                          {...props}
                                          disabled={disabled}
                                      />
                                  );
                              }
                    }
                />
            </MuiPickersUtilsProvider>
        );
    },
);
export const CustomDateTimePicker = withStyles({
    root: {
        '& label.Mui-focused': {
            color: '#14112d',
        },
        marginBottom: '16px',

        '& .MuiOutlinedInput-root': {
            // '& fieldset': {
            //     borderColor: 'red',
            // },
            '&:hover fieldset': {
                borderColor: '#1bb55c',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#1bb55c',
            },
        },
        '& .MuiOutlinedInput-input': {
            padding: '16px',
            color: '#14112d',
        },
    },
})(
    ({
        classes,
        value,
        onChange,
        showInput = false,
        disabled,
        allowFutureDates = true,
        minDate,
        maxDate,
        ...props
    }) => {
        return (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DateTimePicker
                    label="Date"
                    format="dd MMM yyyy"
                    fullWidth={true}
                    inputVariant="outlined"
                    value={value}
                    onChange={(...arg) => {
                        if (
                            !allowFutureDates &&
                            moment().diff(moment(arg[0])) < 0
                        ) {
                            showMessage(
                                'Future dates are not allowed',
                                'error',
                            );
                        } else onChange(...arg);
                    }}
                    showTodayButton={true}
                    style={{
                        marginBottom: '16px',
                    }}
                    {...props}
                    classes={{
                        root: classes.root,
                    }}
                    disabled={disabled}
                    minDate={minDate}
                    maxDate={maxDate ?? moment()}
                    TextFieldComponent={
                        !showInput
                            ? (textProp) => {
                                  return (
                                      <CustomTextField
                                          {...textProp}
                                          style={{
                                              display: 'none',
                                          }}
                                          disabled={disabled}
                                      />
                                  );
                              }
                            : ({ ...props }) => {
                                  return (
                                      <CustomTextField
                                          {...props}
                                          disabled={disabled}
                                      />
                                  );
                              }
                    }
                />
            </MuiPickersUtilsProvider>
        );
    },
);

export const CustomMonthPicker = ({ defaultValue = moment(), onChange }) => {
    const [isVisible, setVisibility] = useState(false);
    const [monthYear, setMonthYear] = useState({
        year: moment(defaultValue).year() ?? moment().year(),
        month: moment(defaultValue).month() + 1 ?? moment().month() + 1,
    });

    const handleOnDismiss = () => {
        console.log('monthhhh', monthYear);
        setVisibility(false);
    };

    const handleOnChange = (year, month) => {
        setMonthYear({ year, month });
        onChange &&
            onChange(
                moment().set({
                    year: year,
                    month: month - 1,
                }),
            );
        setVisibility(false);
    };

    // React.useEffect(() => {
    //     console.log('monthhhh', monthYear);
    //     console.log(
    //         'monthYear',
    //         moment().set({
    //             year: monthYear?.year,
    //             month: monthYear?.month - 1,
    //         }),
    //     );
    //     onChange &&
    //         onChange(
    //             moment().set({
    //                 year: monthYear?.year,
    //                 month: monthYear?.month - 1,
    //             }),
    //         );
    // }, [monthYear]);

    return (
        // <div className="MonthPicker">
        //     <button onClick={showMonthPicker}>{getMonthValue()}</button>
        <>
            <CompactCustomTextField
                label="Month"
                InputProps={{
                    readOnly: true,
                    endAdornment: (
                        <IconButton
                            className={'inputbutton'}
                            onClick={(e) => setVisibility(true)}
                        >
                            <InsertInvitationIcon size="small" />
                        </IconButton>
                    ),
                    style: { fontSize: 16, fontWeight: 600 },
                }}
                onClick={(e) => setVisibility(true)}
                // id="dateEpochMillis"
                // name="dateEpochMillis"
                value={
                    monthYear?.year || monthYear?.month
                        ? `${moment()
                              .set({
                                  year: monthYear?.year,
                                  month: monthYear?.month - 1,
                              })
                              .format('MMMM YYYY')}`
                        : `${moment().format('MMMM YYYY')}`
                }
                variant="outlined"
            />

            {isVisible && (
                <ReactMonthPicker
                    show={isVisible}
                    lang={{
                        months: [
                            'Jan',
                            'Feb',
                            'Mar',
                            'Apr',
                            'May',
                            'Jun',
                            'Jul',
                            'Aug',
                            'Sep',
                            'Oct',
                            'Nov',
                            'Dec',
                        ],
                    }}
                    years={{
                        min: { year: 2020, month: 1 },
                        max: {
                            year: moment().format('YYYY'),
                            month: moment().format('MM'),
                        },
                    }}
                    value={monthYear}
                    onChange={handleOnChange}
                    onDismiss={handleOnDismiss}
                />
            )}
        </>
    );
};

// export const CustomMonthPicker = withStyles({
//     root: {
//         '& label.Mui-focused': {
//             color: '#14112d',
//         },
//         marginBottom: '16px',

//         '& .MuiOutlinedInput-root': {
//             // '& fieldset': {
//             //     borderColor: 'red',
//             // },
//             '&:hover fieldset': {
//                 borderColor: '#1bb55c',
//             },
//             '&.Mui-focused fieldset': {
//                 borderColor: '#1bb55c',
//             },
//         },
//         '& .MuiOutlinedInput-input': {
//             padding: '16px',
//             color: '#14112d',
//         },
//     },
// })(({ classes, value, onChange, showInput = false, ...props }) => {
//     return (
//         <MuiPickersUtilsProvider utils={DateFnsUtils}>
//             <DatePicker
//                 label="Date"
//                 format="MMM"
//                 fullWidth={true}
//                 inputVariant="outlined"
//                 views={['month', 'year']}
//                 value={value}
//                 onChange={onChange}
//                 showTodayButton={true}
//                 style={{
//                     marginBottom: '16px',
//                 }}
//                 {...props}
//                 classes={{
//                     root: classes.root,
//                 }}
//                 TextFieldComponent={
//                     !showInput
//                         ? (textProp) => {
//                               return (
//                                   <CustomTextField
//                                       {...textProp}
//                                       style={{
//                                           display: 'none',
//                                       }}
//                                   />
//                               );
//                           }
//                         : ({ ...props }) => {
//                               return <CustomTextField {...props} />;
//                           }
//                 }
//             />
//         </MuiPickersUtilsProvider>
//     );
// });

export const CustomMonthRangePicker = ({
    onChange,
    displayFormat = 'MMM YYYY',
}) => {
    const [isVisible, setVisibility] = useState(false);
    const [monthYear, setMonthYear] = useState({
        from: {
            month: parseInt(moment().date(0).format('M')),
            year: parseInt(moment().date(0).format('YYYY')),
        },
        to: { year: moment().year(), month: moment().month() + 1 },
    });

    const handleRangeChange = (value, text, listIndex) => {
        console.log('RangeChange', value, text, listIndex);
    };
    const handleRangeDissmis = (value) => {
        // console.log('Dismiss', value);
        setMonthYear({ ...value });
        setVisibility(false);
    };

    React.useEffect(() => {
        console.log('monthYear', monthYear);
        onChange &&
            onChange({
                dateRange: {
                    from: moment()
                        .set({
                            year: monthYear?.from?.year,
                            month: monthYear?.from?.month - 1,
                        })
                        .startOf('M')
                        .valueOf(),
                    to: moment()
                        .set({
                            year: monthYear?.to?.year,
                            month: monthYear?.to?.month - 1,
                        })
                        .endOf('M')
                        .valueOf(),
                },
            });
    }, [monthYear]);

    return (
        // <div className="MonthYearPicker">
        //     <button onClick={showMonthPicker}>{getMonthValue()}</button>
        <>
            <CompactCustomTextField
                label="Month Range"
                InputProps={{
                    readOnly: true,
                    endAdornment: (
                        <IconButton
                            className={'inputbutton'}
                            onClick={(e) => setVisibility(true)}
                        >
                            <InsertInvitationIcon size="small" />
                        </IconButton>
                    ),
                    style: { fontSize: 16, fontWeight: 600 },
                }}
                onClick={(e) => setVisibility(true)}
                // id="dateEpochMillis"
                // name="dateEpochMillis"
                value={
                    monthYear?.from || monthYear?.to
                        ? `${moment()
                              .set({
                                  year: monthYear?.from?.year,
                                  month: monthYear?.from?.month - 1,
                              })
                              .format('MMMM YYYY')} to ${moment()
                              .set({
                                  year: monthYear?.to?.year,
                                  month: monthYear?.to?.month - 1,
                              })
                              .format(displayFormat)}`
                        : `${moment().format(
                              displayFormat,
                          )} to ${moment().format(displayFormat)}`
                }
                variant="outlined"
            />
            <ReactMonthPicker
                show={isVisible}
                lang={{
                    months: [
                        'Jan',
                        'Feb',
                        'Mar',
                        'Apr',
                        'May',
                        'Jun',
                        'Jul',
                        'Aug',
                        'Sep',
                        'Oct',
                        'Nov',
                        'Dec',
                    ],
                    from: 'From',
                    to: 'To',
                }}
                years={{
                    min: { year: 2020, month: 1 },
                    max: {
                        year: moment().format('YYYY'),
                        month: moment().format('MM'),
                    },
                }}
                value={monthYear}
                onChange={handleRangeChange}
                onDismiss={handleRangeDissmis}
            />
        </>
        // </div>
    );
};

export const CustomMonthRangePickerDefault = ({
    onChange,
    displayFormat = 'MMM YYYY',
    defaultValue,
}) => {
    const [isVisible, setVisibility] = useState(false);
    const [monthYear, setMonthYear] = useState({
        from: {
            month: defaultValue
                ? parseInt(
                      moment(defaultValue?.startTimeEpochMillis)
                          .date(0)
                          .format('M'),
                  ) + 1
                : parseInt(moment().date(0).format('M')),
            year: defaultValue
                ? parseInt(
                      moment(defaultValue?.startTimeEpochMillis)
                          .date(0)
                          .format('YYYY'),
                  )
                : parseInt(moment().date(0).format('YYYY')),
        },
        to: {
            year: defaultValue
                ? moment(defaultValue?.endTimeEpochMillis).year()
                : moment().year(),
            month: defaultValue
                ? moment(defaultValue?.endTimeEpochMillis).month() + 1
                : moment().month() + 1,
        },
    });

    const handleRangeChange = (value, text, listIndex) => {
        console.log('RangeChange', value, text, listIndex);
    };
    const handleRangeDissmis = (value) => {
        // console.log('Dismiss', value);
        setMonthYear({ ...value });
        setVisibility(false);
    };

    React.useEffect(() => {
        console.log('defaultValue', defaultValue);
    }, []);

    React.useEffect(() => {
        console.log('monthYear', monthYear);
        // console.log('defaultValue', defaultValue);
        onChange &&
            onChange({
                dateRange: {
                    startTimeEpochMillis: moment()
                        .set({
                            year: monthYear?.from?.year,
                            month: monthYear?.from?.month - 1,
                        })
                        .startOf('M')
                        .valueOf(),
                    endTimeEpochMillis: moment()
                        .set({
                            year: monthYear?.to?.year,
                            month: monthYear?.to?.month - 1,
                        })
                        .endOf('M')
                        .valueOf(),
                },
            });
    }, [monthYear]);

    return (
        // <div className="MonthYearPicker">
        //     <button onClick={showMonthPicker}>{getMonthValue()}</button>
        <>
            <CustomTextField
                label="Month Range"
                InputProps={{
                    readOnly: true,
                    endAdornment: (
                        <IconButton
                            className={'inputbutton'}
                            onClick={(e) => setVisibility(true)}
                        >
                            <InsertInvitationIcon size="small" />
                        </IconButton>
                    ),
                    style: { fontSize: 16, fontWeight: 600 },
                }}
                onClick={(e) => setVisibility(true)}
                // id="dateEpochMillis"
                // name="dateEpochMillis"
                value={
                    monthYear?.from || monthYear?.to
                        ? `${moment()
                              .set({
                                  year: monthYear?.from?.year,
                                  month: monthYear?.from?.month - 1,
                              })
                              .format(displayFormat)} to ${moment()
                              .set({
                                  year: monthYear?.to?.year,
                                  month: monthYear?.to?.month - 1,
                              })
                              .format(displayFormat)}`
                        : `${moment().format(
                              displayFormat,
                          )} to ${moment().format(displayFormat)}`
                }
                variant="outlined"
            />
            <ReactMonthPicker
                show={isVisible}
                lang={{
                    months: [
                        'Jan',
                        'Feb',
                        'Mar',
                        'Apr',
                        'May',
                        'Jun',
                        'Jul',
                        'Aug',
                        'Sep',
                        'Oct',
                        'Nov',
                        'Dec',
                    ],
                    from: 'From',
                    to: 'To',
                }}
                years={{
                    min: { year: 2020, month: 1 },
                    max: { year: 2030, month: 12 },
                }}
                value={monthYear}
                onChange={handleRangeChange}
                onDismiss={handleRangeDissmis}
            />
        </>
        // </div>
    );
};

// export const CustomMonthRangePicker = withStyles({
//     root: {
//         '& label.Mui-focused': {
//             color: '#14112d',
//         },

//         '& .MuiOutlinedInput-root': {
//             // '& fieldset': {
//             //     borderColor: 'red',
//             // },
//             '&:hover fieldset': {
//                 borderColor: '#1bb55c',
//             },
//             '&.Mui-focused fieldset': {
//                 borderColor: '#1bb55c',
//             },
//         },
//         '& .MuiOutlinedInput-input': {
//             padding: '16px',
//             color: '#14112d',
//         },
//     },
// })(({ classes, value, onChange, ...props }) => {
//     const [fromVal, setFromVal] = useState();
//     const [toVal, setToVal] = useState();
//     const [next, setNext] = useState(false);

//     const [range, setRange] = useState(value);

//     React.useEffect(() => {
//         // console.log(fromVal, 'fromVal');
//         if (fromVal) {
//             setNext(true);
//         }
//     }, [fromVal]);

//     React.useEffect(() => {
//         // console.log(toVal, 'toVal');
//         if (toVal) {
//             setRange({
//                 from: fromVal.valueOf(),
//                 to: toVal.valueOf(),
//             });
//         }
//     }, [toVal]);

//     React.useEffect(() => {
//         // console.log(range, 'range');
//         onChange(range);
//     }, [range]);

//     return (
//         <MuiPickersUtilsProvider utils={DateFnsUtils}>
//             <KeyboardDatePicker
//                 label="Month Range"
//                 format="MMM yyyy"
//                 fullWidth={true}
//                 inputVariant="outlined"
//                 value={fromVal}
//                 views={['year', 'month']}
//                 onChange={(e) => {
//                     setFromVal(e);
//                 }}
//                 showTodayButton={false}
//                 {...props}
//                 classes={{
//                     root: classes.root,
//                 }}
//                 TextFieldComponent={(textProp) => {
//                     return (
//                         <TextField
//                             {...textProp}
//                             value={
//                                 range
//                                     ? `${moment(range?.from).format(
//                                           'MMM-YYYY',
//                                       )} to ${moment(range?.to).format(
//                                           'MMM-YYYY',
//                                       )}`
//                                     : ''
//                             }
//                             error={false}
//                             helperText={''}
//                         />
//                     );
//                 }}
//             />
//             <KeyboardDatePicker
//                 label="To"
//                 title="To"
//                 format="dd MMM yyyy"
//                 fullWidth={true}
//                 open={next}
//                 inputVariant="outlined"
//                 value={toVal}
//                 views={['year', 'month']}
//                 onChange={(e) => {
//                     setToVal(e);
//                     setNext(false);
//                 }}
//                 showTodayButton={false}
//                 {...props}
//                 classes={{
//                     root: classes.root,
//                 }}
//                 TextFieldComponent={(textProp) => {
//                     return (
//                         <CustomTextField
//                             {...textProp}
//                             style={{
//                                 display: 'none',
//                             }}
//                         />
//                     );
//                 }}
//             />
//         </MuiPickersUtilsProvider>
//     );
// });
