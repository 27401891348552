import React from 'react';
import {
    Box,
    Button,
    Divider,
    Grid,
    IconButton,
    ListItemIcon,
    makeStyles,
    Paper,
    Tooltip,
    Typography,
    Avatar,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { CompactCustomTextField } from '../../../../components/Textfield';
import InsertInvitationIcon from '@material-ui/icons/InsertInvitation';
import { useHistory, useLocation } from 'react-router-dom';
import { CustomDatePicker } from '../../../../components/DateTimePicker';
import moment from 'moment';
import StaffInfo from './StaffInfo';
interface HeaderProps {
    staffName: any;
    backEnabled: boolean;
    selectedDate: any;
    onSelectDate: any;
    contactNumber?: number;
    hubName?: string;
    vehicleNumber?: any;
    [key: string]: any;
}

const Header: React.FC<HeaderProps> = ({
    staffName,
    backEnabled,
    selectedDate,
    onSelectDate,
    contactNumber,
    hubName,
    vehicleNumber,
    ...props
}) => {
    const history = useHistory();
    let location = useLocation();
    const [date, setDate] = React.useState(moment().format('DD MMM YYYY'));
    const [datePickerOpen, setDatePickerOpen] = React.useState(false);
    React.useEffect(() => {
        setDate(selectedDate);
    }, [selectedDate]);

    return (
        <>
            <Box
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
                justifyContent={'space-between'}
            >
                <Box
                    display={'flex'}
                    flexDirection={'row'}
                    alignItems={'center'}
                    flex={1}
                >
                    {backEnabled && (
                        <ListItemIcon
                            onClick={(e) =>
                                history.push('/map-view', {
                                    payload: location?.state?.payload,
                                })
                            }
                            style={{
                                minWidth: '0',
                                color: '#000000',
                                cursor: 'pointer',
                                marginRight: '12px',
                            }}
                        >
                            <ArrowBackIcon
                                style={{
                                    fontSize: '1.5rem',
                                }}
                            />
                        </ListItemIcon>
                    )}
                    <Typography
                        variant="body2"
                        component="div"
                        style={{
                            fontWeight: 'bold',
                            fontSize: 18,
                        }}
                    >
                        {staffName ?? '-'}
                    </Typography>
                </Box>
                <Box m={0} p={0} alignItems={'center'} width={'37%'}>
                    <CompactCustomTextField
                        label="Date"
                        InputProps={{
                            readOnly: true,
                            endAdornment: (
                                <IconButton
                                    className={'inputbutton'}
                                    onClick={(e) => setDatePickerOpen(true)}
                                    style={{
                                        padding: '1px 4px 1px 1px ',
                                    }}
                                >
                                    <InsertInvitationIcon />
                                </IconButton>
                            ),
                        }}
                        onClick={(e?: any) => setDatePickerOpen(true)}
                        variant="outlined"
                        value={date}
                    />
                </Box>
                {!!datePickerOpen && (
                    <CustomDatePicker
                        open={datePickerOpen}
                        onClose={() => setDatePickerOpen(false)}
                        format="d MMM yyyy"
                        value={date}
                        onChange={(e?: any) => {
                            // console.log(moment(e).valueOf());
                            onSelectDate(moment(e).format('DD MMM YYYY'));
                        }}
                    />
                )}
            </Box>
            <Divider style={{ margin: '12px 0' }} />
            <StaffInfo
                contactNumber={contactNumber}
                hubName={hubName}
                vehicleNumber={vehicleNumber}
                ev={props?.singleEVMapDetails}
            />
            <Divider style={{ margin: '12px 0' }} />
        </>
    );
};

export default Header;
