import React, { forwardRef } from "react";
import { SnackbarContent } from "notistack";
import { Typography, Card, CardActions, makeStyles } from "@material-ui/core";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const useStyles = makeStyles(() => ({
  card: {
    backgroundColor: "#FBB400",
    borderRadius: "12px",
    padding: 10
  },
  typography: {
    fontWeight: 700,
    color: "#000000",
    marginLeft: "16px",
    fontSize: "14px"
  },
  icon: {
    color: "#000000"
  }
}));

const ReportComplete = forwardRef((props, ref) => {
  const classes = useStyles();

  return (
    <SnackbarContent ref={ref} className={classes.root}>
      <Card className={classes.card}>
        <CardActions classes={{ root: classes.actionRoot }}>
          <CheckCircleIcon className={classes.icon} />
          <Typography variant="subtitle2" className={classes.typography}>
            {props.message}
          </Typography>
        </CardActions>
      </Card>
    </SnackbarContent>
  );
});

export default ReportComplete;
