import React from 'react';
import {
    withStyles,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Box,
    TablePagination,
    Tooltip,
    IconButton,
} from '@material-ui/core/';
import EditIcon from '@material-ui/icons/Edit';
import ThumbnailPreview from '../../../components/ThumbnailPreview';
import AddWorkModal from '../../../components/Modals/AddWorkModal';
import AddAllowanceBonusModal from '../../../components/Modals/AddAllowanceBonusModal';
import AddDeductionModal from '../../../components/Modals/AddDeductionModal';
import Currency from '../../../components/UI/Currency/Currency';
import CustomDropdown from '../../../components/Dropdown';
import PDFViewerModal from '../../../components/Modals/PdfViewerModal';
import DeleteModal from '../../../components/Modals/DeleteModal';
import ConfirmModal from '../../../components/Modals/ConfirmModal';
import { checkHttpStatus, showMessage } from '../../../utils/helper';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
    approvePayslip,
    autoSignPayslip,
    deletePayslip,
} from '../../../store/actions/staffActionCreator';

const StyledTableCell = withStyles((theme) => ({
    head: {
        color: theme.palette.common.white,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '& .MuiTableCell-body': {
            fontWeight: 600,
            color: '#000000',
            fontSize: 14,
        },
        '&:nth-of-type(even)': {
            boxShadow: 'inset 0 -1px 0 0 #ebedf8',
            backgroundColor: '#f2f4f8',
        },
    },
}))(TableRow);

const StyledTableHeader = withStyles((theme) => ({
    root: {
        boxShadow: 'inset 0 -1px 0 0 #ebedf8',
        backgroundColor: '#f2f4f8',
    },
}))(TableHead);

const useStyles = makeStyles({
    // table: {
    //     minWidth: 700,
    // },
    tableContainer: {
        border: '1px solid #ebedf8',
        borderRadius: 5,
    },
    tableHeaderText: {
        fontWeight: 700,
    },
    tableHeader: {
        background: '#f2f4f8',
    },
});

function PayslipListContainer({
    rows,
    permissions,
    fetchPayslipReport,
    deleteStaffPaysliip,
    approveStaffPaysliip,
    autoSignStaffPaysliip,
    ...props
}) {
    const classes = useStyles();
    const [selectedRow, setSelectedRow] = React.useState({});
    const [workStaffModalOpen, setWorkStaffModalOpen] = React.useState(false);
    const [bonusStaffModalOpen, setBonusStaffModalOpen] = React.useState(false);
    const [deductionStaffModalOpen, setDeductionStaffModalOpen] =
        React.useState(false);

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const [selectedItemData, setSelectedItemData] = React.useState({});

    const [viewModalOpen, setViewModalOpen] = React.useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = React.useState(false);
    const [autoSignModalOpen, setAutoSignModalOpen] = React.useState(false);
    const [approveModalOpen, setApproveModalOpen] = React.useState(false);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    React.useEffect(() => {
        setPage(0);
    }, [rows]);

    return (
        <>
            <Box style={{ width: '100%' }}>
                <TableContainer
                    className={classes.tableContainer}
                    component={Box}
                >
                    <Table
                        size="small"
                        className={classes.table}
                        aria-label="customized table"
                    >
                        <StyledTableHeader>
                            <TableRow
                            // style={{
                            //     width: '100%',
                            // }}
                            >
                                <TableCell
                                // style={{
                                //     width: '17.5%',
                                // }}
                                >
                                    <Typography
                                        className={classes.tableHeaderText}
                                    >
                                        User Name
                                    </Typography>
                                </TableCell>
                                <TableCell
                                // style={{
                                //     width: '16%',
                                // }}
                                >
                                    <Typography
                                        className={classes.tableHeaderText}
                                    >
                                        Hub Name
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography
                                        className={classes.tableHeaderText}
                                    >
                                        Generated Date
                                    </Typography>
                                </TableCell>
                                <TableCell
                                // style={{
                                //     width: '10%',
                                // }}
                                >
                                    <Typography
                                        className={classes.tableHeaderText}
                                        style={{
                                            textAlign: 'right',
                                            paddingRight: 55,
                                        }}
                                    >
                                        Amount
                                    </Typography>
                                </TableCell>
                                <TableCell
                                // style={{
                                //     width: '18%',
                                // }}
                                >
                                    <Typography
                                        className={classes.tableHeaderText}
                                    >
                                        Notes
                                    </Typography>
                                </TableCell>
                                <TableCell
                                // style={{
                                //     width: '15.5%',
                                // }}
                                >
                                    <Typography
                                        className={classes.tableHeaderText}
                                    >
                                        Uploads
                                    </Typography>
                                </TableCell>
                                <TableCell
                                // style={{
                                //     width: '2%',
                                // }}
                                ></TableCell>
                            </TableRow>
                        </StyledTableHeader>
                        <TableBody>
                            {rows
                                ?.slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage,
                                )
                                .map((row, index) => (
                                    <StyledTableRow key={index}>
                                        <TableCell
                                            component="th"
                                            scope="row"
                                            // style={{
                                            //     width: '17.5%',
                                            // }}
                                        >
                                            {row.userName ?? '-'}
                                        </TableCell>
                                        <TableCell
                                        // style={{
                                        //     width: '16%',
                                        // }}
                                        >
                                            {row.hubName ?? '-'}
                                        </TableCell>
                                        <TableCell
                                            style={{
                                                width: '200px',
                                            }}
                                        >
                                            {row?.dateDisplay ?? '-'}
                                        </TableCell>
                                        <TableCell
                                            style={{
                                                textAlign: 'right',
                                                paddingRight: 80,
                                            }}
                                        >
                                            <Currency amount={row.amount} />
                                        </TableCell>
                                        <TableCell
                                        // style={{
                                        //     width: '18%',
                                        // }}
                                        >
                                            {row.notes}
                                        </TableCell>
                                        <TableCell
                                            style={{
                                                minWidth: '5vw',
                                                maxWidth: '5vw',
                                                // border: '1px solid black',
                                            }}
                                        >
                                            <ThumbnailPreview
                                                images={row?.uploads ?? []}
                                            />
                                        </TableCell>
                                        <TableCell
                                            style={{
                                                color: 'rgba(43, 43, 43, 0.54)',
                                            }}
                                        >
                                            <CustomDropdown
                                                title={'Options'}
                                                options={[
                                                    {
                                                        item: 'View',
                                                        onSuccess: () => {
                                                            setSelectedItemData(
                                                                row,
                                                            );
                                                            setViewModalOpen(
                                                                true,
                                                            );
                                                        },
                                                        disabled:
                                                            !permissions?.read,
                                                    },
                                                    {
                                                        item: 'Auto Sign',
                                                        onSuccess: () => {
                                                            setSelectedItemData(
                                                                row,
                                                            );
                                                            setAutoSignModalOpen(
                                                                true,
                                                            );
                                                        },
                                                        disabled:
                                                            !row?.freelancer ||
                                                            !row?.status?.includes(
                                                                'PENDING_SIGNATURE',
                                                            ) ||
                                                            !permissions?.read,
                                                    },
                                                    {
                                                        item: 'Approve',
                                                        onSuccess: () => {
                                                            setSelectedItemData(
                                                                row,
                                                            );
                                                            setApproveModalOpen(
                                                                true,
                                                            );
                                                        },
                                                        disabled:
                                                            !row?.status?.includes(
                                                                'PENDING_APPROVAL',
                                                            ) ||
                                                            !permissions?.read,
                                                    },
                                                    {
                                                        item: 'Delete',
                                                        onSuccess: () => {
                                                            setSelectedItemData(
                                                                row,
                                                            );
                                                            setDeleteModalOpen(
                                                                true,
                                                            );
                                                        },
                                                        disabled:
                                                            !permissions?.delete,
                                                    },
                                                ]}
                                            />
                                        </TableCell>
                                    </StyledTableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                {!!viewModalOpen && (
                    <PDFViewerModal
                        open={viewModalOpen}
                        data={{
                            ...selectedItemData,
                            docLink: selectedItemData?.paySlipLink,
                        }}
                        onClose={() => {
                            setViewModalOpen(false);
                            fetchPayslipReport();
                            setSelectedItemData({});
                        }}
                    />
                )}
                {!!deleteModalOpen && (
                    <DeleteModal
                        open={deleteModalOpen}
                        data={{
                            ...selectedItemData,
                        }}
                        onClose={() => {
                            setDeleteModalOpen(false);
                            fetchPayslipReport();
                            setSelectedItemData({});
                        }}
                        deleteType={'payslip'}
                        onDeletion={async (toggleLoading) => {
                            deleteStaffPaysliip(
                                selectedItemData.userId,
                                selectedItemData.id,
                                (resp) => {
                                    toggleLoading(false);
                                    if (checkHttpStatus(resp.status)) {
                                        showMessage(
                                            'Payslip Deleted.',
                                            'success',
                                        );
                                        setDeleteModalOpen(false);
                                        fetchPayslipReport();
                                        setSelectedItemData({});
                                    }
                                },
                                (err) => {
                                    toggleLoading(false);
                                },
                            );
                        }}
                    />
                )}
                {!!autoSignModalOpen && (
                    <ConfirmModal
                        open={autoSignModalOpen}
                        data={{ ...selectedItemData }}
                        onClose={() => {
                            setAutoSignModalOpen(false);
                            fetchPayslipReport();
                            setSelectedItemData({});
                        }}
                        confirmationText={'sign this payslip'}
                        onAction={async (toggleLoading) => {
                            autoSignStaffPaysliip(
                                selectedItemData.userId,
                                selectedItemData.id,
                                (resp) => {
                                    toggleLoading(false);
                                    if (checkHttpStatus(resp.status)) {
                                        showMessage(
                                            'Payslip Signed.',
                                            'success',
                                        );
                                        setAutoSignModalOpen(false);
                                        fetchPayslipReport();
                                        setSelectedItemData({});
                                    }
                                },
                                (err) => {
                                    toggleLoading(false);
                                },
                            );
                        }}
                    />
                )}
                {!!approveModalOpen && (
                    <ConfirmModal
                        open={approveModalOpen}
                        data={{ ...selectedItemData }}
                        onClose={() => {
                            setApproveModalOpen(false);
                            fetchPayslipReport();
                            setSelectedItemData({});
                        }}
                        confirmationText={'approve this payslip'}
                        onAction={async (toggleLoading, data) => {
                            approveStaffPaysliip(
                                selectedItemData.userId,
                                {
                                    payslipId: selectedItemData.id,
                                    ...{ notes: '' },
                                },
                                (resp) => {
                                    toggleLoading(false);
                                    if (checkHttpStatus(resp.status)) {
                                        showMessage(
                                            'Payslip Approved.',
                                            'success',
                                        );
                                        setApproveModalOpen(false);
                                        fetchPayslipReport();
                                        setSelectedItemData({});
                                    }
                                },
                                (err) => {
                                    toggleLoading(false);
                                },
                            );
                        }}
                    />
                )}

                <TablePagination
                    rowsPerPageOptions={[10, 50, 100]}
                    component="div"
                    count={rows?.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Box>
        </>
    );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
    deleteStaffPaysliip: (id, payslipId, onSuccess, onError) =>
        dispatch(deletePayslip(id, payslipId, onSuccess, onError)),
    approveStaffPaysliip: (id, data, onSuccess, onError) =>
        dispatch(approvePayslip(id, data, onSuccess, onError)),
    autoSignStaffPaysliip: (id, payslipId, onSuccess, onError) =>
        dispatch(autoSignPayslip(id, payslipId, onSuccess, onError)),
});

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(PayslipListContainer),
);
