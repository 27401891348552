import React, { useState, useEffect } from 'react';
import { Button, MenuItem, withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import {
    editStaffVendor,
    fetchVendorList,
} from '../../store/actions/staffActionCreator';

import Modal from './Modal';
import { CustomTextField } from '../Textfield';

// const useStyles = makeStyles((theme) => ({
//     modal: {},
// }));

function AddVendorModal({
    open,
    onClose,
    isEdit = false,
    data = {},
    dispatchEditStaffVendor,
    dispatchFetchVendorList,
    staff,
    ...props
}) {
    // const classes = useStyles();
    const [isOpen, toggleModal] = useState(open);
    const [isloading, toggleloading] = useState(true);
    const [vendorList, setVendorList] = useState([]);

    const handleClose = () => {
        // Setting form data to null before closing
        setState({
            vendorId: '',
        });

        toggleModal(!isOpen);
        onClose && onClose();
    };
    const [state, setState] = useState({
        vendorId: data?.vendorId ?? '',
        id: data?.id,
    });

    useEffect(() => {
        dispatchFetchVendorList(
            async (resp) => {
                await toggleloading(false);
                console.log('vendorssss', resp);
                setVendorList(resp?.response?.vendors);
            },
            (err) => {
                toggleloading(false);
            },
        );
        if (isEdit) {
            console.log('dattest', data);
            setState({
                vendorId: data?.vendorId ?? '',
                id: data?.id,
            });
        }
    }, []);

    const actions = [
        <Button
            key={1}
            variant="contained"
            color="default"
            className={`cancelButton`}
            onClick={handleClose}
            disableElevation
        >
            {'Cancel'}
        </Button>,
        <Button
            key={2}
            variant="contained"
            color="primary"
            className={`saveButton`}
            disabled={!state?.vendorId ? true : false}
            disableElevation
            onClick={async () => {
                await toggleloading(true);
                if (isEdit) {
                    dispatchEditStaffVendor(
                        {
                            id: state?.id,
                            value: {
                                vendorId: state?.vendorId,
                            },
                        },
                        async () => {
                            console.log('Edit Vendor SUCCESS');
                            await toggleloading(false);
                            handleClose();
                        },
                        async () => {
                            await toggleloading(false);
                            console.log('Edit Vendor FAILED');
                        },
                    );
                } else {
                    dispatchEditStaffVendor(
                        {
                            id: state?.id,
                            value: {
                                vendorId: state?.vendorId,
                            },
                        },
                        async () => {
                            console.log('Add Vendor SUCCESS');
                            await toggleloading(false);
                            handleClose();
                        },
                        async (err) => {
                            await toggleloading(false);
                            console.log('Add Vendor FAILED', err);
                        },
                    );
                }
            }}
        >
            {isEdit ? 'Save' : 'Add'}
        </Button>,
    ];

    const handleInputChange = (value, key) => {
        setState((state) => ({
            ...state,
            [key]: value,
        }));
    };

    const StyledMenuItem = withStyles((theme) => ({
        root: {
            margin: '0 8px',
            border: '0 !important',
            borderRadius: '8px',
            '&:hover': {
                backgroundColor: 'rgba(67, 65, 158, 0.08)',
                color: '#43419e',
                borderRadius: '8px',
            },
        },
    }))(MenuItem);

    return (
        <Modal
            onClose={handleClose}
            open={isOpen}
            title={isEdit ? 'Edit Vendor Details' : 'Add Vendor Details'}
            actions={actions}
            loading={isloading}
        >
            {vendorList?.length ? (
                <CustomTextField
                    label="Vendor Name"
                    select
                    fullWidth={true}
                    value={state?.vendorId}
                    onChange={(e) =>
                        handleInputChange(e.target.value, 'vendorId')
                    }
                    defaultValue=""
                    style={{ marginBottom: '16px' }}
                >
                    {vendorList.map((vendor, index) => (
                        <StyledMenuItem key={index} value={vendor?.vendorId}>
                            {vendor?.name}
                        </StyledMenuItem>
                    ))}
                </CustomTextField>
            ) : (
                <CustomTextField
                    label="Vendor Name"
                    select
                    fullWidth={true}
                    value={state?.vendorId}
                    onChange={(e) =>
                        handleInputChange(e.target.value, 'vendorId')
                    }
                    defaultValue=""
                    style={{ marginBottom: '16px' }}
                >
                    <StyledMenuItem key={1} value={'vendor?.vendorId'}>
                        No Data Found
                    </StyledMenuItem>
                </CustomTextField>
            )}
            {/* <CustomTextField
                fullWidth={true}
                label="Staff Reference Number"
                value={state?.staffCustCode}
                onChange={(e) =>
                    handleInputChange(e.target.value, 'staffCustCode')
                }
                error={stateStatus?.staffCustCode?.status}
                helperText={stateStatus?.staffCustCode?.error}
                style={{ marginBottom: '16px' }}
            /> */}
        </Modal>
    );
}

const mapStateToProps = (state) => ({
    staff: state.staff,
});

const mapDispatchToProps = (dispatch) => ({
    dispatchEditStaffVendor: (data, onSuccess, onError) =>
        dispatch(editStaffVendor(data, onSuccess, onError)),
    dispatchFetchVendorList: (onSuccess, onError) =>
        dispatch(fetchVendorList(onSuccess, onError)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddVendorModal);
