export const ALL_EV_STATUS = Object.freeze({
    MANUAL_TAG: 'MANUAL_TAG',
    MANUAL_INFO: 'MANUAL_INFO',
    EV_REQUESTED: 'EV_REQUESTED',
    EV_REQUEST_REJECTED: 'EV_REQUEST_REJECTED',
    EV_REQUEST_APPROVED: 'EV_REQUEST_APPROVED',
    EV_TAG_REQUEST_APPROVED: 'EV_TAG_REQUEST_APPROVED',
    EV_UN_TAG_REQUEST_APPROVED: 'EV_UN_TAG_REQUEST_APPROVED',
    EV_INFO_SAVED: 'EV_INFO_SAVED',
    EV_TAG_REQUEST_REJECTED: 'EV_TAG_REQUEST_REJECTED',
    EV_TAGGED: 'EV_TAGGED',
    EV_UNTAG_REQUESTED: 'EV_UNTAG_REQUESTED',
    EV_UNTAG_REQUEST_REJECTED: 'EV_UNTAG_REQUEST_REJECTED',
    EV_UNTAGGED: 'EV_UNTAGGED',
    EV_TAG_REQUESTED: 'EV_TAG_REQUESTED',
    UNTAG_REQUESTED: 'UNTAG_REQUESTED',
    UNTAG_REQUEST_REJECTED: 'UNTAG_REQUEST_REJECTED',
    UNTAG_REQUEST_APPROVED: 'UNTAG_REQUEST_APPROVED',
});
export const EV_STATUS_UI_PROPS = Object.freeze({
    MANUAL_TAG: {
        title: 'Tag EV to user',
        subTitle:
            'User’s Request to Rent an EV will show up here. You can also Tag EV manually to a user from here',
        ctaText: '',
    },
    EV_REQUESTED: {
        title: 'User is requesting to Rent EV',
        subTitle2:
            'Approve Request to Manually Tag him to an EV or to approve his Tag request later on.',
        subTitle: 'Approve the Request to allocate an EV to Rider',
        ctaText: '',
    },
    EV_REQUEST_REJECTED: {
        title: 'Tag EV to user',
        subTitle:
            'User’s Request to Rent an EV will show up here. You can also Tag EV manually to a user from here',
        ctaText: '',
    },
    EV_REQUEST_APPROVED: {
        title: '',
        subTitle: '',
        ctaText: '',
    },
    EV_INFO_SAVED: {
        title: '',
        subTitle: '',
        ctaText: '',
    },
    EV_TAG_REQUEST_REJECTED: {
        title: 'Tag EV to user',
        subTitle:
            'User’s Request to Rent an EV will show up here. You can also Tag EV manually to a user from here',
        ctaText: '',
    },
    EV_TAGGED: {
        title: '',
        subTitle: '',
        ctaText: '',
    },
    EV_UNTAG_REQUESTED: {
        title: '',
        subTitle: '',
        ctaText: '',
    },
    EV_UNTAG_REQUEST_REJECTED: {
        title: 'Untag EV to user',
        subTitle:
            'User’s Request to Rent an EV will show up here. You can also Tag EV manually to a user from here',
        ctaText: '',
    },
    EV_UN_TAG_REQUEST_APPROVED: {
        title: 'Untag EV to user',
        subTitle: '',
        ctaText: '',
    },
    EV_UNTAGGED: {
        title: 'Untag EV to user',
        subTitle:
            'User’s Request to Rent an EV will show up here. You can also Tag EV manually to a user from here',
        ctaText: '',
    },
});
export const CRUD_STATUS = [
    ALL_EV_STATUS.EV_INFO_SAVED,
    ALL_EV_STATUS.EV_REQUEST_APPROVED,
    ALL_EV_STATUS.EV_TAGGED,
    ALL_EV_STATUS.EV_TAG_REQUESTED,
    ALL_EV_STATUS.EV_UNTAG_REQUESTED,
    ALL_EV_STATUS.UNTAG_REQUESTED,
    ALL_EV_STATUS.UNTAG_REQUEST_APPROVED,
    ALL_EV_STATUS.UNTAG_REQUEST_REJECTED,
    ALL_EV_STATUS.MANUAL_INFO,
    ALL_EV_STATUS.EV_TAG_REQUEST_REJECTED,
    ALL_EV_STATUS.EV_UNTAG_REQUEST_REJECTED,
    ALL_EV_STATUS.EV_TAG_REQUEST_APPROVED,
    ALL_EV_STATUS.EV_UN_TAG_REQUEST_APPROVED,
];
export const SAVE_EDIT_DISABLED_STATUS = [
    ALL_EV_STATUS.EV_TAGGED,
    ALL_EV_STATUS.EV_TAG_REQUESTED,
    ALL_EV_STATUS.EV_TAG_REQUEST_APPROVED,
    ALL_EV_STATUS.EV_UNTAG_REQUESTED,
    ALL_EV_STATUS.EV_UN_TAG_REQUEST_APPROVED,
];
export const TAG_BUTTON_STATUS = [
    ALL_EV_STATUS.EV_INFO_SAVED,
    ALL_EV_STATUS.EV_REQUEST_APPROVED,
    ALL_EV_STATUS.EV_TAG_REQUEST_APPROVED,
    ALL_EV_STATUS.MANUAL_INFO,
];
export const UN_TAG_BUTTON_STATUS = [
    ALL_EV_STATUS.EV_TAGGED,
    ALL_EV_STATUS.EV_UN_TAG_REQUEST_APPROVED,
    ALL_EV_STATUS.UNTAG_REQUEST_APPROVED,
];
export const REJECT_BUTTON_STATUS = [
    ALL_EV_STATUS.EV_TAG_REQUESTED,
    ALL_EV_STATUS.EV_UNTAG_REQUESTED,
    ALL_EV_STATUS.UNTAG_REQUESTED,
    ALL_EV_STATUS.EV_TAG_REQUEST_REJECTED,
    ALL_EV_STATUS.EV_UNTAG_REQUEST_REJECTED,
];
export const APPROVE_BUTTON_STATUS = [
    ALL_EV_STATUS.EV_TAG_REQUESTED,
    ALL_EV_STATUS.EV_UNTAG_REQUESTED,
    ALL_EV_STATUS.EV_TAG_REQUEST_REJECTED,
    ALL_EV_STATUS.EV_UNTAG_REQUEST_REJECTED,
    ALL_EV_STATUS.UNTAG_REQUESTED,
];
export const REJECT_BUTTON_DISABLE_STATUS = [
    ALL_EV_STATUS.EV_TAG_REQUEST_REJECTED,
    ALL_EV_STATUS.EV_UNTAG_REQUEST_REJECTED,
    ALL_EV_STATUS.UNTAG_REQUEST_REJECTED,
];
export const APPROVE_BUTTON_DISABLE_STATUS = [
    ALL_EV_STATUS.EV_TAG_REQUEST_REJECTED,
    ALL_EV_STATUS.EV_UNTAG_REQUEST_REJECTED,
];
export const MANUAL_BOX_STATUS = [
    ALL_EV_STATUS.MANUAL_TAG,
    ALL_EV_STATUS.EV_REQUEST_REJECTED,
    ALL_EV_STATUS.EV_REQUESTED,
    //ALL_EV_STATUS.EV_UNTAG_REQUEST_REJECTED,
    //ALL_EV_STATUS.EV_TAG_REQUEST_REJECTED,
    ALL_EV_STATUS.EV_UNTAGGED,
];
export const TITLE_BOX_NOT_VISIBLE_STATUS = [
    ALL_EV_STATUS.MANUAL_TAG,
    ALL_EV_STATUS.EV_REQUEST_REJECTED,
    ALL_EV_STATUS.EV_UNTAG_REQUEST_REJECTED,
    ALL_EV_STATUS.EV_TAG_REQUEST_REJECTED,
];
export const MANUAL_BUTTON_STATUS = [
    ALL_EV_STATUS.MANUAL_TAG,
    ALL_EV_STATUS.EV_REQUEST_REJECTED,
    ALL_EV_STATUS.EV_UNTAG_REQUEST_REJECTED,
    ALL_EV_STATUS.EV_TAG_REQUEST_REJECTED,
    ALL_EV_STATUS.EV_UNTAGGED,
];
export const APPROVAL = [ALL_EV_STATUS.EV_TAG_REQUESTED];
export const UN_TAG_APPROVAL = [
    ALL_EV_STATUS.EV_UNTAG_REQUESTED,
    ALL_EV_STATUS.UNTAG_REQUESTED,
];
export const UN_TAG_APPROVED = [
    ALL_EV_STATUS.UNTAG_REQUEST_APPROVED,
    ALL_EV_STATUS.EV_UN_TAG_REQUEST_APPROVED,
];
export const REQUEST = [ALL_EV_STATUS.EV_REQUESTED];
export const COMPARE_IMAGE_SECTION = [
    ALL_EV_STATUS.EV_UNTAG_REQUESTED,
    ALL_EV_STATUS.EV_TAGGED,
    ALL_EV_STATUS.EV_UN_TAG_REQUEST_APPROVED,
    ALL_EV_STATUS.EV_UNTAG_REQUEST_REJECTED,
];
