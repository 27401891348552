import './App.scss';
import { ThemeProvider } from '@material-ui/styles';
import { CssBaseline } from '@material-ui/core';
import theme from './utils/theme';
import AppRoute from './components/AppRoute';
import { Provider } from 'react-redux';
import store from './store/configureStore';
import { SnackbarProvider } from 'notistack';
import Slide from '@material-ui/core/Slide';

const MAX_SNACKBAR = 3;
//Set the auto hide time, the default value is 5 seconds, which is 5000 milliseconds
const AUTO_HIDE_DURATION = 3000;
//Set the position of the message bar, the default value is the bottom left
const POSITION = {
    vertical: 'bottom',
    horizontal: 'right',
};

function App() {
    return (
        <Provider store={store}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <SnackbarProvider
                    maxSnack={MAX_SNACKBAR}
                    autoHideDuration={AUTO_HIDE_DURATION}
                    anchorOrigin={POSITION}
                    TransitionComponent={Slide}
                >
                    <AppRoute />
                </SnackbarProvider>
            </ThemeProvider>
        </Provider>
    );
}

export default App;
