export const sampleData = {
    org: {
        id: 'profile_organization_KPZJLPF38387',
    },
    vehicle: {
        id: 'profile_vehicle_L9ZVOPKA8665',
    },
    tripStart: {
        startTime: '1719426660000',
    },
    tripClosure: {
        endTime: '1719512999999',
    },
    startTime: '1719426660000',
    id: 'profile_vehicle_L9ZVOPKA8665',
    orgId: 'profile_organization_KPZJLPF38387',
    totalVehicleIdleTime: 0,
    locationPoints: [
        {
            deviceTimeEpoch: 1719487002000,
            latitude: 12.9011,
            longitude: 77.623299,
            altitude: 0,
            angle: 0,
            speed: 0,
            deviceOdometerDistance: 0,
            ignition: 1,
            movement: 0,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719487124000,
            latitude: 12.9011,
            longitude: 77.623299,
            altitude: 0,
            angle: 0,
            speed: 0,
            deviceOdometerDistance: 0,
            ignition: 1,
            movement: 0,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719487140000,
            latitude: 12.9011,
            longitude: 77.623299,
            altitude: 0,
            angle: 0,
            speed: 0,
            deviceOdometerDistance: 0,
            ignition: 1,
            movement: 0,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719487217000,
            latitude: 12.9011,
            longitude: 77.623299,
            altitude: 0,
            angle: 0,
            speed: 0,
            deviceOdometerDistance: 0,
            ignition: 1,
            movement: 0,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719487248000,
            latitude: 12.9011,
            longitude: 77.623299,
            altitude: 0,
            angle: 0,
            speed: 0,
            deviceOdometerDistance: 0,
            ignition: 1,
            movement: 0,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719487264000,
            latitude: 12.9011,
            longitude: 77.623299,
            altitude: 0,
            angle: 0,
            speed: 0,
            deviceOdometerDistance: 0,
            ignition: 1,
            movement: 0,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719487432000,
            latitude: 12.9011,
            longitude: 77.623299,
            altitude: 0,
            angle: 0,
            speed: 0,
            deviceOdometerDistance: 0,
            ignition: 1,
            movement: 0,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719487494000,
            latitude: 12.9033,
            longitude: 77.625603,
            altitude: 0,
            angle: 0,
            speed: 54,
            deviceOdometerDistance: 0.0003489406458547771,
            ignition: 1,
            movement: 1,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719487509000,
            latitude: 12.9032,
            longitude: 77.625,
            altitude: 0,
            angle: 0,
            speed: 72,
            deviceOdometerDistance: 0.00006637007134691669,
            ignition: 1,
            movement: 1,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719487540000,
            latitude: 12.9023,
            longitude: 77.623901,
            altitude: 0,
            angle: 0,
            speed: 61.2,
            deviceOdometerDistance: 0.00015536762199544528,
            ignition: 1,
            movement: 1,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719487571000,
            latitude: 12.9014,
            longitude: 77.623398,
            altitude: 0,
            angle: 0,
            speed: 79.2,
            deviceOdometerDistance: 0.00011354957469465786,
            ignition: 1,
            movement: 1,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719487587000,
            latitude: 12.901,
            longitude: 77.623299,
            altitude: 0,
            angle: 0,
            speed: 68.4,
            deviceOdometerDistance: 0.00004553751657442014,
            ignition: 1,
            movement: 1,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719487602000,
            latitude: 12.901,
            longitude: 77.6231,
            altitude: 0,
            angle: 0,
            speed: 21.6,
            deviceOdometerDistance: 0.000021596991505579413,
            ignition: 1,
            movement: 1,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719487617000,
            latitude: 12.901,
            longitude: 77.6231,
            altitude: 0,
            angle: 0,
            speed: 3.6,
            deviceOdometerDistance: 0,
            ignition: 1,
            movement: 0,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719487633000,
            latitude: 12.9009,
            longitude: 77.623199,
            altitude: 0,
            angle: 0,
            speed: 28.8,
            deviceOdometerDistance: 0.000015421665082065535,
            ignition: 1,
            movement: 1,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719487649000,
            latitude: 12.9009,
            longitude: 77.623299,
            altitude: 0,
            angle: 0,
            speed: 10.8,
            deviceOdometerDistance: 0.000010852763862156049,
            ignition: 1,
            movement: 1,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719487679000,
            latitude: 12.9009,
            longitude: 77.623299,
            altitude: 0,
            angle: 0,
            speed: 3.6,
            deviceOdometerDistance: 0,
            ignition: 1,
            movement: 0,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719487695000,
            latitude: 12.9008,
            longitude: 77.623199,
            altitude: 0,
            angle: 0,
            speed: 7.2,
            deviceOdometerDistance: 0.00001549747450812078,
            ignition: 1,
            movement: 1,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719487710000,
            latitude: 12.9008,
            longitude: 77.623299,
            altitude: 0,
            angle: 0,
            speed: 18,
            deviceOdometerDistance: 0.000010852768173259198,
            ignition: 1,
            movement: 1,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719487741000,
            latitude: 12.9008,
            longitude: 77.623299,
            altitude: 0,
            angle: 0,
            speed: 0,
            deviceOdometerDistance: 0,
            ignition: 1,
            movement: 0,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719487757000,
            latitude: 12.9008,
            longitude: 77.623398,
            altitude: 0,
            angle: 0,
            speed: 7.2,
            deviceOdometerDistance: 0.000010744240488578027,
            ignition: 1,
            movement: 1,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719487772000,
            latitude: 12.9008,
            longitude: 77.623497,
            altitude: 0,
            angle: 0,
            speed: 14.4,
            deviceOdometerDistance: 0.000010744240490750895,
            ignition: 1,
            movement: 1,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719487803000,
            latitude: 12.9008,
            longitude: 77.623199,
            altitude: 0,
            angle: 0,
            speed: 14.4,
            deviceOdometerDistance: 0.00003234124915255571,
            ignition: 1,
            movement: 1,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719487819000,
            latitude: 12.9008,
            longitude: 77.623199,
            altitude: 0,
            angle: 0,
            speed: 7.2,
            deviceOdometerDistance: 0,
            ignition: 1,
            movement: 1,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719487834000,
            latitude: 12.9009,
            longitude: 77.623299,
            altitude: 0,
            angle: 0,
            speed: 10.8,
            deviceOdometerDistance: 0.00001549747450812078,
            ignition: 1,
            movement: 1,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719487880000,
            latitude: 12.9009,
            longitude: 77.6231,
            altitude: 0,
            angle: 0,
            speed: 28.8,
            deviceOdometerDistance: 0.000021597000084027732,
            ignition: 1,
            movement: 1,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719487927000,
            latitude: 12.9009,
            longitude: 77.6231,
            altitude: 0,
            angle: 0,
            speed: 0,
            deviceOdometerDistance: 0,
            ignition: 1,
            movement: 0,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719487958000,
            latitude: 12.9009,
            longitude: 77.6231,
            altitude: 0,
            angle: 0,
            speed: 0,
            deviceOdometerDistance: 0,
            ignition: 1,
            movement: 0,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719487973000,
            latitude: 12.9009,
            longitude: 77.6231,
            altitude: 0,
            angle: 0,
            speed: 0,
            deviceOdometerDistance: 0,
            ignition: 1,
            movement: 0,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719488004000,
            latitude: 12.9009,
            longitude: 77.6231,
            altitude: 0,
            angle: 0,
            speed: 0,
            deviceOdometerDistance: 0,
            ignition: 1,
            movement: 0,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719488020000,
            latitude: 12.9007,
            longitude: 77.623398,
            altitude: 0,
            angle: 0,
            speed: 25.2,
            deviceOdometerDistance: 0.00003918562410477553,
            ignition: 1,
            movement: 1,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719488066000,
            latitude: 12.9007,
            longitude: 77.623398,
            altitude: 0,
            angle: 0,
            speed: 0,
            deviceOdometerDistance: 0,
            ignition: 1,
            movement: 0,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719488081000,
            latitude: 12.9007,
            longitude: 77.623398,
            altitude: 0,
            angle: 0,
            speed: 0,
            deviceOdometerDistance: 0,
            ignition: 1,
            movement: 0,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719488097000,
            latitude: 12.9007,
            longitude: 77.623398,
            altitude: 0,
            angle: 0,
            speed: 0,
            deviceOdometerDistance: 0,
            ignition: 1,
            movement: 0,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719488112000,
            latitude: 12.9007,
            longitude: 77.623398,
            altitude: 0,
            angle: 0,
            speed: 0,
            deviceOdometerDistance: 0,
            ignition: 1,
            movement: 0,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719488128000,
            latitude: 12.9007,
            longitude: 77.623398,
            altitude: 0,
            angle: 0,
            speed: 0,
            deviceOdometerDistance: 0,
            ignition: 1,
            movement: 0,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719488442000,
            latitude: 12.9007,
            longitude: 77.623398,
            altitude: 0,
            angle: 0,
            speed: 0,
            deviceOdometerDistance: 0,
            ignition: 1,
            movement: 0,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719488457000,
            latitude: 12.8992,
            longitude: 77.622002,
            altitude: 0,
            angle: 0,
            speed: 32.4,
            deviceOdometerDistance: 0.0002247028595414521,
            ignition: 1,
            movement: 1,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719488488000,
            latitude: 12.8983,
            longitude: 77.621696,
            altitude: 0,
            angle: 0,
            speed: 50.4,
            deviceOdometerDistance: 0.00010495907668191796,
            ignition: 1,
            movement: 1,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719488550000,
            latitude: 12.8957,
            longitude: 77.620598,
            altitude: 0,
            angle: 0,
            speed: 100.8,
            deviceOdometerDistance: 0.0003113444784216918,
            ignition: 1,
            movement: 1,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719488596000,
            latitude: 12.8937,
            longitude: 77.620003,
            altitude: 0,
            angle: 0,
            speed: 64.8,
            deviceOdometerDistance: 0.00023048997400964985,
            ignition: 1,
            movement: 1,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719488627000,
            latitude: 12.8922,
            longitude: 77.619797,
            altitude: 0,
            angle: 0,
            speed: 64.8,
            deviceOdometerDistance: 0.00016744368288413965,
            ignition: 1,
            movement: 1,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719488643000,
            latitude: 12.8922,
            longitude: 77.619797,
            altitude: 0,
            angle: 0,
            speed: 3.6,
            deviceOdometerDistance: 0,
            ignition: 1,
            movement: 0,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719488658000,
            latitude: 12.8917,
            longitude: 77.619698,
            altitude: 0,
            angle: 0,
            speed: 36,
            deviceOdometerDistance: 0.00005634866733094443,
            ignition: 1,
            movement: 1,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719488689000,
            latitude: 12.8915,
            longitude: 77.618797,
            altitude: 0,
            angle: 0,
            speed: 68.4,
            deviceOdometerDistance: 0.0001002589449071363,
            ignition: 1,
            movement: 1,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719488735000,
            latitude: 12.8928,
            longitude: 77.618301,
            altitude: 0,
            angle: 0,
            speed: 54,
            deviceOdometerDistance: 0.00015356296384512285,
            ignition: 1,
            movement: 1,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719488751000,
            latitude: 12.8931,
            longitude: 77.617104,
            altitude: 0,
            angle: 0,
            speed: 100.8,
            deviceOdometerDistance: 0.00013408410464057873,
            ignition: 1,
            movement: 1,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719488782000,
            latitude: 12.8926,
            longitude: 77.616096,
            altitude: 0,
            angle: 0,
            speed: 68.4,
            deviceOdometerDistance: 0.00012258851968343363,
            ignition: 1,
            movement: 1,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719488797000,
            latitude: 12.8923,
            longitude: 77.6157,
            altitude: 0,
            angle: 0,
            speed: 57.6,
            deviceOdometerDistance: 0.00005430140887490139,
            ignition: 1,
            movement: 1,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719488844000,
            latitude: 12.8928,
            longitude: 77.613197,
            altitude: 0,
            angle: 0,
            speed: 21.6,
            deviceOdometerDistance: 0.00027722815712890756,
            ignition: 1,
            movement: 1,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719488859000,
            latitude: 12.8929,
            longitude: 77.612503,
            altitude: 0,
            angle: 0,
            speed: 79.2,
            deviceOdometerDistance: 0.00007612870772152198,
            ignition: 1,
            movement: 1,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719488906000,
            latitude: 12.8929,
            longitude: 77.612503,
            altitude: 0,
            angle: 0,
            speed: 3.6,
            deviceOdometerDistance: 0,
            ignition: 1,
            movement: 0,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719488921000,
            latitude: 12.892,
            longitude: 77.611099,
            altitude: 0,
            angle: 0,
            speed: 79.2,
            deviceOdometerDistance: 0.00018202346290021576,
            ignition: 1,
            movement: 1,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719488936000,
            latitude: 12.8914,
            longitude: 77.610703,
            altitude: 0,
            angle: 0,
            speed: 61.2,
            deviceOdometerDistance: 0.00007907690739915323,
            ignition: 1,
            movement: 1,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719488998000,
            latitude: 12.8918,
            longitude: 77.611504,
            altitude: 0,
            angle: 0,
            speed: 79.2,
            deviceOdometerDistance: 0.00009754854311724583,
            ignition: 1,
            movement: 1,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719489029000,
            latitude: 12.8918,
            longitude: 77.612297,
            altitude: 0,
            angle: 0,
            speed: 61.2,
            deviceOdometerDistance: 0.00008606552722860864,
            ignition: 1,
            movement: 1,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719489045000,
            latitude: 12.8926,
            longitude: 77.612602,
            altitude: 0,
            angle: 0,
            speed: 86.4,
            deviceOdometerDistance: 0.00009449150658333737,
            ignition: 1,
            movement: 1,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719489060000,
            latitude: 12.8928,
            longitude: 77.613098,
            altitude: 0,
            angle: 0,
            speed: 43.2,
            deviceOdometerDistance: 0.00005820122323798356,
            ignition: 1,
            movement: 1,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719489076000,
            latitude: 12.8926,
            longitude: 77.613998,
            altitude: 0,
            angle: 0,
            speed: 61.2,
            deviceOdometerDistance: 0.00010015267647457014,
            ignition: 1,
            movement: 1,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719489122000,
            latitude: 12.8948,
            longitude: 77.614601,
            altitude: 0,
            angle: 0,
            speed: 75.6,
            deviceOdometerDistance: 0.0002520302032411132,
            ignition: 1,
            movement: 1,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719489138000,
            latitude: 12.8957,
            longitude: 77.614502,
            altitude: 0,
            angle: 0,
            speed: 82.8,
            deviceOdometerDistance: 0.00010014469221429016,
            ignition: 1,
            movement: 1,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719489184000,
            latitude: 12.897,
            longitude: 77.615402,
            altitude: 0,
            angle: 0,
            speed: 79.2,
            deviceOdometerDistance: 0.00017385190297042772,
            ignition: 1,
            movement: 1,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719489230000,
            latitude: 12.8984,
            longitude: 77.615097,
            altitude: 0,
            angle: 0,
            speed: 32.4,
            deviceOdometerDistance: 0.00015837919151726987,
            ignition: 1,
            movement: 1,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719489246000,
            latitude: 12.8985,
            longitude: 77.615601,
            altitude: 0,
            angle: 0,
            speed: 50.4,
            deviceOdometerDistance: 0.000055806011319253613,
            ignition: 1,
            movement: 1,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719489261000,
            latitude: 12.8985,
            longitude: 77.616302,
            altitude: 0,
            angle: 0,
            speed: 61.2,
            deviceOdometerDistance: 0.00007607859986861614,
            ignition: 1,
            movement: 1,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719489292000,
            latitude: 12.8985,
            longitude: 77.6175,
            altitude: 0,
            angle: 0,
            speed: 72,
            deviceOdometerDistance: 0.0001300173504142354,
            ignition: 1,
            movement: 1,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719489308000,
            latitude: 12.8982,
            longitude: 77.617996,
            altitude: 0,
            angle: 0,
            speed: 18,
            deviceOdometerDistance: 0.00006323921641136604,
            ignition: 1,
            movement: 1,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719489354000,
            latitude: 12.8982,
            longitude: 77.619301,
            altitude: 0,
            angle: 0,
            speed: 43.2,
            deviceOdometerDistance: 0.00014163008717622442,
            ignition: 1,
            movement: 1,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719489370000,
            latitude: 12.8981,
            longitude: 77.620102,
            altitude: 0,
            angle: 0,
            speed: 90,
            deviceOdometerDistance: 0.00008763270011992463,
            ignition: 1,
            movement: 1,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719489416000,
            latitude: 12.8982,
            longitude: 77.621498,
            altitude: 0,
            angle: 0,
            speed: 36,
            deviceOdometerDistance: 0.00015190960922275263,
            ignition: 1,
            movement: 1,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719489447000,
            latitude: 12.899,
            longitude: 77.621902,
            altitude: 0,
            angle: 0,
            speed: 36,
            deviceOdometerDistance: 0.00009876911922340402,
            ignition: 1,
            movement: 1,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719489463000,
            latitude: 12.8994,
            longitude: 77.622002,
            altitude: 0,
            angle: 0,
            speed: 43.2,
            deviceOdometerDistance: 0.00004556325882989312,
            ignition: 1,
            movement: 1,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719489493000,
            latitude: 12.9004,
            longitude: 77.622597,
            altitude: 0,
            angle: 0,
            speed: 50.4,
            deviceOdometerDistance: 0.00012809662098815252,
            ignition: 1,
            movement: 1,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719489509000,
            latitude: 12.9009,
            longitude: 77.623001,
            altitude: 0,
            angle: 0,
            speed: 46.8,
            deviceOdometerDistance: 0.00007058421886919924,
            ignition: 1,
            movement: 1,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719489524000,
            latitude: 12.9015,
            longitude: 77.623398,
            altitude: 0,
            angle: 0,
            speed: 54,
            deviceOdometerDistance: 0.00007913510248908769,
            ignition: 1,
            movement: 1,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719489540000,
            latitude: 12.902,
            longitude: 77.623596,
            altitude: 0,
            angle: 0,
            speed: 32.4,
            deviceOdometerDistance: 0.000059342071056903975,
            ignition: 1,
            movement: 1,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719489555000,
            latitude: 12.9024,
            longitude: 77.623901,
            altitude: 0,
            angle: 0,
            speed: 54,
            deviceOdometerDistance: 0.000055261990675895497,
            ignition: 1,
            movement: 1,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719489571000,
            latitude: 12.903,
            longitude: 77.624199,
            altitude: 0,
            angle: 0,
            speed: 28.8,
            deviceOdometerDistance: 0.00007383733841310872,
            ignition: 1,
            movement: 1,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719489602000,
            latitude: 12.9032,
            longitude: 77.624901,
            altitude: 0,
            angle: 0,
            speed: 25.2,
            deviceOdometerDistance: 0.00007933362056909864,
            ignition: 1,
            movement: 1,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719489617000,
            latitude: 12.9037,
            longitude: 77.624901,
            altitude: 0,
            angle: 0,
            speed: 21.6,
            deviceOdometerDistance: 0.00005531483401010929,
            ignition: 1,
            movement: 1,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719489633000,
            latitude: 12.904,
            longitude: 77.625,
            altitude: 0,
            angle: 0,
            speed: 39.6,
            deviceOdometerDistance: 0.000034884653985864656,
            ignition: 1,
            movement: 1,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719489679000,
            latitude: 12.9052,
            longitude: 77.625504,
            altitude: 0,
            angle: 0,
            speed: 68.4,
            deviceOdometerDistance: 0.00014358213130847143,
            ignition: 1,
            movement: 1,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719489694000,
            latitude: 12.9051,
            longitude: 77.6259,
            altitude: 0,
            angle: 0,
            speed: 10.8,
            deviceOdometerDistance: 0.000044377299224801226,
            ignition: 1,
            movement: 1,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719489710000,
            latitude: 12.9051,
            longitude: 77.626297,
            altitude: 0,
            angle: 0,
            speed: 36,
            deviceOdometerDistance: 0.00004308475360823821,
            ignition: 1,
            movement: 1,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719489725000,
            latitude: 12.905,
            longitude: 77.626999,
            altitude: 0,
            angle: 0,
            speed: 72,
            deviceOdometerDistance: 0.00007698419166459565,
            ignition: 1,
            movement: 1,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719489741000,
            latitude: 12.9053,
            longitude: 77.627403,
            altitude: 0,
            angle: 0,
            speed: 25.2,
            deviceOdometerDistance: 0.00005498942680357289,
            ignition: 1,
            movement: 1,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719489756000,
            latitude: 12.9055,
            longitude: 77.627502,
            altitude: 0,
            angle: 0,
            speed: 18,
            deviceOdometerDistance: 0.000024596576348351737,
            ignition: 1,
            movement: 1,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719489803000,
            latitude: 12.9064,
            longitude: 77.6278,
            altitude: 0,
            angle: 0,
            speed: 64.8,
            deviceOdometerDistance: 0.00010468736967649972,
            ignition: 1,
            movement: 1,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719489834000,
            latitude: 12.907,
            longitude: 77.628502,
            altitude: 0,
            angle: 0,
            speed: 21.6,
            deviceOdometerDistance: 0.00010104511376604179,
            ignition: 1,
            movement: 1,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719489849000,
            latitude: 12.907,
            longitude: 77.628502,
            altitude: 0,
            angle: 0,
            speed: 0,
            deviceOdometerDistance: 0,
            ignition: 1,
            movement: 0,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719489896000,
            latitude: 12.907,
            longitude: 77.6287,
            altitude: 0,
            angle: 0,
            speed: 7.2,
            deviceOdometerDistance: 0.000021487951649384146,
            ignition: 1,
            movement: 1,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719489911000,
            latitude: 12.9067,
            longitude: 77.628799,
            altitude: 0,
            angle: 0,
            speed: 21.6,
            deviceOdometerDistance: 0.00003488462177003428,
            ignition: 1,
            movement: 1,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719489988000,
            latitude: 12.9035,
            longitude: 77.630798,
            altitude: 0,
            angle: 0,
            speed: 79.2,
            deviceOdometerDistance: 0.00041519986642027677,
            ignition: 1,
            movement: 1,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719490019000,
            latitude: 12.9024,
            longitude: 77.6315,
            altitude: 0,
            angle: 0,
            speed: 18,
            deviceOdometerDistance: 0.00014357356858954898,
            ignition: 1,
            movement: 1,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719490050000,
            latitude: 12.9008,
            longitude: 77.632401,
            altitude: 0,
            angle: 0,
            speed: 54,
            deviceOdometerDistance: 0.00020222061165723278,
            ignition: 1,
            movement: 1,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719490066000,
            latitude: 12.9,
            longitude: 77.633003,
            altitude: 0,
            angle: 0,
            speed: 82.8,
            deviceOdometerDistance: 0.00011000640265821527,
            ignition: 1,
            movement: 1,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719490143000,
            latitude: 12.8961,
            longitude: 77.6353,
            altitude: 0,
            angle: 0,
            speed: 54,
            deviceOdometerDistance: 0.0004982968782297871,
            ignition: 1,
            movement: 1,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719490158000,
            latitude: 12.8958,
            longitude: 77.635498,
            altitude: 0,
            angle: 0,
            speed: 18,
            deviceOdometerDistance: 0.00003953826567419869,
            ignition: 1,
            movement: 1,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719490174000,
            latitude: 12.8958,
            longitude: 77.635498,
            altitude: 0,
            angle: 0,
            speed: 0,
            deviceOdometerDistance: 0,
            ignition: 1,
            movement: 0,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719490189000,
            latitude: 12.8957,
            longitude: 77.635201,
            altitude: 0,
            angle: 0,
            speed: 61.2,
            deviceOdometerDistance: 0.00003407901257222913,
            ignition: 1,
            movement: 1,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719490205000,
            latitude: 12.8956,
            longitude: 77.634598,
            altitude: 0,
            angle: 0,
            speed: 46.8,
            deviceOdometerDistance: 0.00006637201803413294,
            ignition: 1,
            movement: 1,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719490220000,
            latitude: 12.8953,
            longitude: 77.634102,
            altitude: 0,
            angle: 0,
            speed: 46.8,
            deviceOdometerDistance: 0.00006323974022143225,
            ignition: 1,
            movement: 1,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719490236000,
            latitude: 12.8952,
            longitude: 77.633598,
            altitude: 0,
            angle: 0,
            speed: 93.6,
            deviceOdometerDistance: 0.000055806692041783316,
            ignition: 1,
            movement: 1,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719490329000,
            latitude: 12.8993,
            longitude: 77.629799,
            altitude: 0,
            angle: 0,
            speed: 68.4,
            deviceOdometerDistance: 0.0006129677314012348,
            ignition: 1,
            movement: 1,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719490360000,
            latitude: 12.8996,
            longitude: 77.628502,
            altitude: 0,
            angle: 0,
            speed: 64.8,
            deviceOdometerDistance: 0.00014462090453318634,
            ignition: 1,
            movement: 1,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719490375000,
            latitude: 12.8997,
            longitude: 77.627899,
            altitude: 0,
            angle: 0,
            speed: 61.2,
            deviceOdometerDistance: 0.00006637099360402279,
            ignition: 1,
            movement: 1,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719490406000,
            latitude: 12.8999,
            longitude: 77.626999,
            altitude: 0,
            angle: 0,
            speed: 28.8,
            deviceOdometerDistance: 0.00010014999346026272,
            ignition: 1,
            movement: 1,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719490421000,
            latitude: 12.8991,
            longitude: 77.626701,
            altitude: 0,
            angle: 0,
            speed: 90,
            deviceOdometerDistance: 0.00009422777447072185,
            ignition: 1,
            movement: 1,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719490437000,
            latitude: 12.8989,
            longitude: 77.626099,
            altitude: 0,
            angle: 0,
            speed: 86.4,
            deviceOdometerDistance: 0.00006897902103426818,
            ignition: 1,
            movement: 1,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719490452000,
            latitude: 12.899,
            longitude: 77.624901,
            altitude: 0,
            angle: 0,
            speed: 115.2,
            deviceOdometerDistance: 0.00013048693473421724,
            ignition: 1,
            movement: 1,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719490530000,
            latitude: 12.899,
            longitude: 77.624901,
            altitude: 0,
            angle: 0,
            speed: 3.6,
            deviceOdometerDistance: 0,
            ignition: 1,
            movement: 0,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719490545000,
            latitude: 12.8999,
            longitude: 77.622299,
            altitude: 0,
            angle: 0,
            speed: 28.8,
            deviceOdometerDistance: 0.0002994293575319783,
            ignition: 1,
            movement: 1,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719490561000,
            latitude: 12.9005,
            longitude: 77.622704,
            altitude: 0,
            angle: 0,
            speed: 64.8,
            deviceOdometerDistance: 0.00007961123150980856,
            ignition: 1,
            movement: 1,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719490576000,
            latitude: 12.901,
            longitude: 77.6231,
            altitude: 0,
            angle: 0,
            speed: 7.2,
            deviceOdometerDistance: 0.00007004819479166746,
            ignition: 1,
            movement: 1,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719490592000,
            latitude: 12.901,
            longitude: 77.623199,
            altitude: 0,
            angle: 0,
            speed: 18,
            deviceOdometerDistance: 0.000010744231954135413,
            ignition: 1,
            movement: 1,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719492578000,
            latitude: 12.901,
            longitude: 77.623199,
            altitude: 0,
            angle: 0,
            speed: 0,
            deviceOdometerDistance: 0,
            ignition: 1,
            movement: 0,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719492609000,
            latitude: 12.901,
            longitude: 77.623199,
            altitude: 0,
            angle: 0,
            speed: 0,
            deviceOdometerDistance: 0,
            ignition: 1,
            movement: 0,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719492687000,
            latitude: 12.901,
            longitude: 77.623199,
            altitude: 0,
            angle: 0,
            speed: 0,
            deviceOdometerDistance: 0,
            ignition: 1,
            movement: 0,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719492718000,
            latitude: 12.901,
            longitude: 77.623199,
            altitude: 0,
            angle: 0,
            speed: 0,
            deviceOdometerDistance: 0,
            ignition: 1,
            movement: 0,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719492749000,
            latitude: 12.901,
            longitude: 77.623199,
            altitude: 0,
            angle: 0,
            speed: 0,
            deviceOdometerDistance: 0,
            ignition: 1,
            movement: 0,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719492764000,
            latitude: 12.901,
            longitude: 77.623199,
            altitude: 0,
            angle: 0,
            speed: 0,
            deviceOdometerDistance: 0,
            ignition: 1,
            movement: 0,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719492780000,
            latitude: 12.901,
            longitude: 77.623199,
            altitude: 0,
            angle: 0,
            speed: 0,
            deviceOdometerDistance: 0,
            ignition: 1,
            movement: 0,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719492811000,
            latitude: 12.901,
            longitude: 77.623199,
            altitude: 0,
            angle: 0,
            speed: 0,
            deviceOdometerDistance: 0,
            ignition: 1,
            movement: 0,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719492841000,
            latitude: 12.901,
            longitude: 77.623199,
            altitude: 0,
            angle: 0,
            speed: 0,
            deviceOdometerDistance: 0,
            ignition: 1,
            movement: 0,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719492857000,
            latitude: 12.901,
            longitude: 77.623199,
            altitude: 0,
            angle: 0,
            speed: 0,
            deviceOdometerDistance: 0,
            ignition: 1,
            movement: 0,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719492888000,
            latitude: 12.901,
            longitude: 77.623199,
            altitude: 0,
            angle: 0,
            speed: 0,
            deviceOdometerDistance: 0,
            ignition: 1,
            movement: 0,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719492903000,
            latitude: 12.901,
            longitude: 77.623199,
            altitude: 0,
            angle: 0,
            speed: 0,
            deviceOdometerDistance: 0,
            ignition: 1,
            movement: 0,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719492919000,
            latitude: 12.901,
            longitude: 77.623199,
            altitude: 0,
            angle: 0,
            speed: 0,
            deviceOdometerDistance: 0,
            ignition: 1,
            movement: 0,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719492950000,
            latitude: 12.901,
            longitude: 77.623199,
            altitude: 0,
            angle: 0,
            speed: 0,
            deviceOdometerDistance: 0,
            ignition: 1,
            movement: 0,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719494445000,
            latitude: 12.901,
            longitude: 77.623199,
            altitude: 0,
            angle: 0,
            speed: 0,
            deviceOdometerDistance: 0,
            ignition: 1,
            movement: 0,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719498490000,
            latitude: 12.901,
            longitude: 77.623199,
            altitude: 0,
            angle: 0,
            speed: 0,
            deviceOdometerDistance: 0,
            ignition: 1,
            movement: 0,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719498521000,
            latitude: 12.901,
            longitude: 77.623199,
            altitude: 0,
            angle: 0,
            speed: 0,
            deviceOdometerDistance: 0,
            ignition: 1,
            movement: 0,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719498568000,
            latitude: 12.901,
            longitude: 77.623199,
            altitude: 0,
            angle: 0,
            speed: 0,
            deviceOdometerDistance: 0,
            ignition: 1,
            movement: 0,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719498583000,
            latitude: 12.901,
            longitude: 77.623199,
            altitude: 0,
            angle: 0,
            speed: 0,
            deviceOdometerDistance: 0,
            ignition: 1,
            movement: 0,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719498599000,
            latitude: 12.901,
            longitude: 77.623199,
            altitude: 0,
            angle: 0,
            speed: 0,
            deviceOdometerDistance: 0,
            ignition: 1,
            movement: 0,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719498645000,
            latitude: 12.901,
            longitude: 77.623199,
            altitude: 0,
            angle: 0,
            speed: 0,
            deviceOdometerDistance: 0,
            ignition: 1,
            movement: 0,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719498691000,
            latitude: 12.901,
            longitude: 77.623199,
            altitude: 0,
            angle: 0,
            speed: 0,
            deviceOdometerDistance: 0,
            ignition: 1,
            movement: 0,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719498738000,
            latitude: 12.901,
            longitude: 77.623199,
            altitude: 0,
            angle: 0,
            speed: 0,
            deviceOdometerDistance: 0,
            ignition: 1,
            movement: 0,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719498769000,
            latitude: 12.901,
            longitude: 77.623199,
            altitude: 0,
            angle: 0,
            speed: 0,
            deviceOdometerDistance: 0,
            ignition: 1,
            movement: 0,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719498784000,
            latitude: 12.901,
            longitude: 77.623199,
            altitude: 0,
            angle: 0,
            speed: 0,
            deviceOdometerDistance: 0,
            ignition: 1,
            movement: 0,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719498815000,
            latitude: 12.901,
            longitude: 77.623199,
            altitude: 0,
            angle: 0,
            speed: 0,
            deviceOdometerDistance: 0,
            ignition: 1,
            movement: 0,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719498831000,
            latitude: 12.901,
            longitude: 77.623199,
            altitude: 0,
            angle: 0,
            speed: 0,
            deviceOdometerDistance: 0,
            ignition: 1,
            movement: 0,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719498846000,
            latitude: 12.901,
            longitude: 77.623199,
            altitude: 0,
            angle: 0,
            speed: 0,
            deviceOdometerDistance: 0,
            ignition: 1,
            movement: 0,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719498892000,
            latitude: 12.901,
            longitude: 77.623199,
            altitude: 0,
            angle: 0,
            speed: 0,
            deviceOdometerDistance: 0,
            ignition: 1,
            movement: 0,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719498908000,
            latitude: 12.901,
            longitude: 77.623199,
            altitude: 0,
            angle: 0,
            speed: 0,
            deviceOdometerDistance: 0,
            ignition: 1,
            movement: 0,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719498923000,
            latitude: 12.901,
            longitude: 77.623199,
            altitude: 0,
            angle: 0,
            speed: 0,
            deviceOdometerDistance: 0,
            ignition: 1,
            movement: 0,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719498939000,
            latitude: 12.901,
            longitude: 77.623199,
            altitude: 0,
            angle: 0,
            speed: 0,
            deviceOdometerDistance: 0,
            ignition: 1,
            movement: 0,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719498970000,
            latitude: 12.901,
            longitude: 77.623199,
            altitude: 0,
            angle: 0,
            speed: 0,
            deviceOdometerDistance: 0,
            ignition: 1,
            movement: 0,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719498985000,
            latitude: 12.901,
            longitude: 77.623199,
            altitude: 0,
            angle: 0,
            speed: 0,
            deviceOdometerDistance: 0,
            ignition: 1,
            movement: 0,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719499001000,
            latitude: 12.901,
            longitude: 77.623199,
            altitude: 0,
            angle: 0,
            speed: 0,
            deviceOdometerDistance: 0,
            ignition: 1,
            movement: 0,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719499016000,
            latitude: 12.901,
            longitude: 77.623199,
            altitude: 0,
            angle: 0,
            speed: 0,
            deviceOdometerDistance: 0,
            ignition: 1,
            movement: 0,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719499047000,
            latitude: 12.901,
            longitude: 77.623199,
            altitude: 0,
            angle: 0,
            speed: 0,
            deviceOdometerDistance: 0,
            ignition: 1,
            movement: 0,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719499125000,
            latitude: 12.901,
            longitude: 77.623199,
            altitude: 0,
            angle: 0,
            speed: 0,
            deviceOdometerDistance: 0,
            ignition: 1,
            movement: 0,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719499140000,
            latitude: 12.901,
            longitude: 77.623199,
            altitude: 0,
            angle: 0,
            speed: 0,
            deviceOdometerDistance: 0,
            ignition: 1,
            movement: 0,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719499155000,
            latitude: 12.901,
            longitude: 77.623199,
            altitude: 0,
            angle: 0,
            speed: 0,
            deviceOdometerDistance: 0,
            ignition: 1,
            movement: 0,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719499217000,
            latitude: 12.901,
            longitude: 77.623199,
            altitude: 0,
            angle: 0,
            speed: 0,
            deviceOdometerDistance: 0,
            ignition: 1,
            movement: 0,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719499233000,
            latitude: 12.901,
            longitude: 77.623199,
            altitude: 0,
            angle: 0,
            speed: 0,
            deviceOdometerDistance: 0,
            ignition: 1,
            movement: 0,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719499279000,
            latitude: 12.901,
            longitude: 77.623199,
            altitude: 0,
            angle: 0,
            speed: 0,
            deviceOdometerDistance: 0,
            ignition: 1,
            movement: 0,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719499310000,
            latitude: 12.901,
            longitude: 77.623199,
            altitude: 0,
            angle: 0,
            speed: 0,
            deviceOdometerDistance: 0,
            ignition: 1,
            movement: 0,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719499341000,
            latitude: 12.901,
            longitude: 77.623199,
            altitude: 0,
            angle: 0,
            speed: 0,
            deviceOdometerDistance: 0,
            ignition: 1,
            movement: 0,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719499372000,
            latitude: 12.901,
            longitude: 77.623199,
            altitude: 0,
            angle: 0,
            speed: 0,
            deviceOdometerDistance: 0,
            ignition: 1,
            movement: 0,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719499403000,
            latitude: 12.901,
            longitude: 77.623199,
            altitude: 0,
            angle: 0,
            speed: 0,
            deviceOdometerDistance: 0,
            ignition: 1,
            movement: 0,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719499418000,
            latitude: 12.901,
            longitude: 77.623199,
            altitude: 0,
            angle: 0,
            speed: 0,
            deviceOdometerDistance: 0,
            ignition: 1,
            movement: 0,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719499449000,
            latitude: 12.901,
            longitude: 77.623199,
            altitude: 0,
            angle: 0,
            speed: 0,
            deviceOdometerDistance: 0,
            ignition: 1,
            movement: 0,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719499496000,
            latitude: 12.901,
            longitude: 77.623199,
            altitude: 0,
            angle: 0,
            speed: 0,
            deviceOdometerDistance: 0,
            ignition: 1,
            movement: 0,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719499542000,
            latitude: 12.901,
            longitude: 77.623199,
            altitude: 0,
            angle: 0,
            speed: 0,
            deviceOdometerDistance: 0,
            ignition: 1,
            movement: 0,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719499573000,
            latitude: 12.901,
            longitude: 77.623199,
            altitude: 0,
            angle: 0,
            speed: 0,
            deviceOdometerDistance: 0,
            ignition: 1,
            movement: 0,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719499620000,
            latitude: 12.901,
            longitude: 77.623199,
            altitude: 0,
            angle: 0,
            speed: 0,
            deviceOdometerDistance: 0,
            ignition: 1,
            movement: 0,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719499697000,
            latitude: 12.901,
            longitude: 77.623199,
            altitude: 0,
            angle: 0,
            speed: 0,
            deviceOdometerDistance: 0,
            ignition: 1,
            movement: 0,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719500480000,
            latitude: 12.901,
            longitude: 77.623199,
            altitude: 0,
            angle: 0,
            speed: 0,
            deviceOdometerDistance: 0,
            ignition: 1,
            movement: 0,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
        {
            deviceTimeEpoch: 1719500629000,
            latitude: 12.901,
            longitude: 77.623199,
            altitude: 0,
            angle: 0,
            speed: 0,
            deviceOdometerDistance: 0,
            ignition: 1,
            movement: 0,
            dataMode: 0,
            powerVoltage: 0,
            gsm: 0,
            mileage: 0,
            temperature: 0,
            fuelData: {},
        },
    ],
    fuelSensorDisconnectLocationPoints: [],
    idleLocationPoints: [
        '1719487002000,1719487494000,12.9033,77.625603,0.09254369561884523,0.0,0.0',
        '1719488066000,1719488457000,12.8992,77.622002,0.9340969224262068,0.0,0.0',
    ],
    refuellingPoints: [],
    theftPoints: [],
    deviceDistanceList: [
        0.41531071720169377, 0.37782360822244504, 0.10177697289729234,
        0.039185624104775524, 1.1955476837769319, 1.0789942321938353,
        1.1208654665848465, 0.85064595258567, 0.6357904510574202,
        0.5933468624005726, 1.465208166648678, 1.2378348603390061,
        0.6592989715560752, 0, 0, 0, 0, 0, 0,
    ],
    vehicleOdoDistanceList: [],
    latLongDistanceList: [
        0.41531071720169377, 0.37782360822244504, 0.10177697289729234,
        0.039185624104775524, 1.1955476837769319, 1.0789942321938353,
        1.1208654665848465, 0.85064595258567, 0.6357904510574202,
        0.5933468624005726, 1.465208166648678, 1.2378348603390061,
        0.6592989715560752, 0, 0, 0, 0, 0, 0,
    ],
    aggLatLongDistance: 9.771629569569242,
    aggDeviceOdoDistance: 9.771629569569242,
    aggVehicleOdoDistance: 0,
};
