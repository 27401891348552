import { Box, Button, MenuItem } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Modal from './Modal';
import { CustomTextField } from '../Textfield';
import { connect } from 'react-redux';
import {
    GetCityByState,
    GetStates,
} from '../../store/actions/settingsActionCreator';
import { useFormik } from 'formik';
import * as yup from 'yup';

const validationSchema = yup.object({
    addressLine1: yup
        .string('Address is invalid')
        .required('Address is required'),
    addressLine2: yup
        .string('Address is invalid')
        .required('Address is required'),
    zoneId: yup.string('City is invalid').required('City is required'),
    stateId: yup.string('State is invalid').required('State is required'),
});

function AddressModal({
    open,
    onClose,
    defaultValue = {},
    statesList,
    cityList,
    dispatchGetStates,
    dispatchGetCityByState,
    ...props
}) {
    // const classes = useStyles();
    const [isOpen, toggleModal] = useState(open);
    const [loading, toggleLoading] = useState(false);

    useEffect(() => {
        console.log('defaultValue', defaultValue);
        if (!statesList?.length) fetchStates();
        if (defaultValue.stateId) fetchCities(defaultValue.stateId);
    }, []);

    const handleClose = (values) => {
        toggleModal(!isOpen);
        onClose && onClose(values);
    };
    const actions = [
        <Button
            key={1}
            variant="contained"
            color="default"
            className={`cancelButton`}
            onClick={() => handleClose(defaultValue)}
            disableElevation
        >
            {'Cancel'}
        </Button>,
        <Button
            key={2}
            variant="contained"
            color="primary"
            className={`saveButton`}
            type="submit"
            disableElevation
        >
            {'Add'}
        </Button>,
    ];

    const formik = useFormik({
        initialValues: {
            addressLine1: '',
            addressLine2: '',
            zoneId: '',
            stateId: '',
            ...defaultValue,
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            await toggleLoading(true);
            await handleClose(values);
        },
    });

    const fetchStates = async () => {
        await toggleLoading(true);
        dispatchGetStates(
            {},
            (resp) => {
                toggleLoading(false);
            },
            (err) => {
                toggleLoading(false);
            },
        );
    };
    const fetchCities = async (stateid) => {
        await toggleLoading(true);
        dispatchGetCityByState(
            stateid,
            (resp) => {
                toggleLoading(false);
            },
            (err) => {
                toggleLoading(false);
            },
        );
    };

    return (
        <Modal
            onClose={() => handleClose(defaultValue)}
            open={isOpen}
            title={'Add Address'}
            subtitle={'Add your Company address'}
            actions={actions}
            loading={loading}
            isForm={true}
            onSubmit={formik.handleSubmit}
        >
            <CustomTextField
                id="addressLine1"
                name="addressLine1"
                value={formik.values.addressLine1}
                onChange={(e) => {
                    formik.handleChange(e);
                }}
                error={
                    formik.touched.addressLine1
                        ? Boolean(formik.errors.addressLine1)
                        : false
                }
                helperText={
                    formik.touched.addressLine1 && formik.errors.addressLine1
                }
                fullWidth={true}
                label="Address Line 1"
                variant="outlined"
                style={{ marginBottom: '16px' }}
            />
            <CustomTextField
                id="addressLine2"
                name="addressLine2"
                value={formik.values.addressLine2}
                onChange={(e) => {
                    formik.handleChange(e);
                }}
                error={
                    formik.touched.addressLine2
                        ? Boolean(formik.errors.addressLine2)
                        : false
                }
                helperText={
                    formik.touched.addressLine2 && formik.errors.addressLine2
                }
                fullWidth={true}
                label="Address Line 2"
                variant="outlined"
                style={{ marginBottom: '16px' }}
            />
            <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                <CustomTextField
                    label="State"
                    select
                    style={{ marginBottom: '16px', marginRight: 10 }}
                    id="stateId"
                    name="stateId"
                    value={formik.values.stateId}
                    onChange={(e) => {
                        formik.handleChange(e);
                        fetchCities(e.target.value);
                    }}
                    error={
                        formik.touched.stateId
                            ? Boolean(formik.errors.stateId)
                            : false
                    }
                    helperText={formik.touched.stateId && formik.errors.stateId}
                >
                    {statesList?.length === 0 && (
                        <MenuItem value={undefined}>No Data found</MenuItem>
                    )}
                    {statesList?.map((state) => (
                        <MenuItem value={state.id}>{state.name}</MenuItem>
                    ))}
                </CustomTextField>
                <CustomTextField
                    label="City"
                    select
                    style={{ marginBottom: '16px' }}
                    id="zoneId"
                    name="zoneId"
                    value={formik.values.zoneId}
                    onChange={formik.handleChange}
                    error={
                        formik.touched.zoneId
                            ? Boolean(formik.errors.zoneId)
                            : false
                    }
                    helperText={formik.touched.zoneId && formik.errors.zoneId}
                >
                    {cityList?.length === 0 && (
                        <MenuItem value={undefined}>No Data found</MenuItem>
                    )}
                    {cityList?.map((city) => (
                        <MenuItem value={city.id}>{city.name}</MenuItem>
                    ))}
                </CustomTextField>
            </Box>
        </Modal>
    );
}

const mapStateToProps = (state) => ({
    statesList: state.settings?.statesList ?? [],
    cityList: state.settings?.cities ?? [],
});

const mapDispatchToProps = (dispatch) => ({
    dispatchGetStates: (payload, onSuccess, onError) =>
        dispatch(GetStates(payload, onSuccess, onError)),
    dispatchGetCityByState: (stateId, onSuccess, onError) =>
        dispatch(GetCityByState(stateId, onSuccess, onError)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddressModal);
