import * as constants from './../constants';
import { API_USER_SERVICE_PORT } from '../../config/env/env';

export const getMapViewData = (data, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: `/org/staff/mapview`,
        method: 'POST',
        data: data,
        success: (response) => {
            if (response && response.status && response.status.code == 200) {
                if (response.response && response.response.lineItems) {
                    return {
                        type: constants.SET_MAPVIEW_ALL_STAFF,
                        payload: response.response.lineItems,
                    };
                }
            }
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
    },
});

export const GetMapVechicleList = ({ token, data }, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: `https://beecon.in/api/trade/fleet/entity`,
        method: 'POST',
        data,
        success: (response) => {
            console.log('map', response);
            let vehicleList = {};
            if (response && response.status && response.status == 200) {
                if (response.data) {
                    vehicleList = { ...response.data };
                }
            }
            return {
                type: constants.SET_TRACKING_VEHICLES_LIST,
                payload: vehicleList,
            };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
        thirdParty: true,
        overwriteHeaders: {
            Authorization: token,
        },
    },
});
export const GetVehicles = ({ token, data }, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: `https://beecon.in/api/trade/fleet/entity`,
        method: 'POST',
        data,
        success: (response) => {
            return {
                type: constants.DO_NOTHING,
                payload: null,
            };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
        thirdParty: true,
        overwriteHeaders: {
            Authorization: token,
        },
    },
});
export const setTrackingData = ({ id, data }, onSuccess, onError) => {
    return {
        type: constants.SET_TRACKING_DATA,
        payload: data,
        id: id,
    };
};

export const getTripRouteDetails = ({ token, data }, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: `https://beecon.in/api/trade/fleet/transaction`,
        method: 'POST',
        data,
        success: (response) => {
            // console.log('TRANSACTIONNNN', response);
            // let vehicleList = {};
            // if (response && response.status && response.status == 200) {
            //     if (response.data) {
            //         vehicleList = { ...response.data };
            //     }
            // }
            return {
                type: constants.DO_NOTHING,
                // payload: vehicleList,
            };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
        thirdParty: true,
        overwriteHeaders: {
            Authorization: token,
        },
    },
});

export const getTrackingToggleEvents = (
    { staffId, data },
    onSuccess,
    onError,
) => ({
    type: constants.API,
    payload: {
        url: `/org/staff/${staffId}/events`,
        method: 'POST',
        data,
        success: (response) => {
            let events = [];
            if (response && response.status && response.status.code == 200) {
                if (response.response && response.response.events) {
                    events = response.response.events;
                }
            }
            return {
                type: constants.SET_TRACKING_TOGGLE_EVENTS,
                payload: events,
            };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
    },
});

export const getAllTrackingEvents = (
    { staffId, date },
    onSuccess,
    onError,
) => ({
    type: constants.API,
    payload: {
        url: `/org/staff/${staffId}/mapview/${date}`,
        method: 'GET',
        data: {},
        success: (response) => {
            return {
                type: constants.DO_NOTHING,
            };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
    },
});

export const resetMapviewVehiclesData = (data = {}) => ({
    type: constants.SET_TRACKING_VEHICLES_LIST,
    payload: data,
});

export const resetMapviewAllStaffVehiclesData = (data = {}) => ({
    type: constants.SET_MAPVIEW_ALL_STAFF,
    payload: data,
});
