import React, { useState } from 'react';
import { Box, Typography, Tooltip, Divider, Avatar } from '@material-ui/core';
import {
    BatteryIcon,
    LocationMobileOffIcon,
    LocationMobileOnIcon,
    LocationPermissionOffIcon,
    LocationPermissionOnIcon,
    MobileIcon,
} from '../../../../assets/svg';
import OdometerViewModal from '../../../../components/Modals/OdometerViewModal';
import { green } from '@material-ui/core/colors';
interface DistanceInfoProps {
    tracking: any;
    evTracking: any;
    events: any;
    havingEV: boolean;
    playComponent?: any;
}

const DistanceInfo: React.FC<DistanceInfoProps> = ({
    tracking,
    evTracking,
    events,
    havingEV,
    playComponent,
}) => {
    const [odometerViewModalOpen, setOdometerViewModalOpen] =
        useState<any>(false);
    const [odometerViewModalType, setOdometerViewModalType] =
        useState<any>('IN');
    return (
        <Box
            flexDirection={'row'}
            display={'flex'}
            //alignItems={'center'}
            justifyContent={'space-between'}
        >
            <Box width={!!havingEV ? '50%' : '100%'}>
                <Box
                    flexDirection={'row'}
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                >
                    <Box>
                        <Typography
                            gutterBottom
                            style={{
                                fontSize: 12,
                                opacity: 0.8,
                            }}
                        >
                            GPS (Device)
                        </Typography>
                        <Typography
                            variant="body2"
                            component="div"
                            style={{
                                fontSize: 14,
                                fontWeight: 700,
                            }}
                        >
                            {tracking?.aggDeviceOdoDistance
                                ? `${parseFloat(
                                      tracking?.aggDeviceOdoDistance,
                                  ).toFixed(2)} KM`
                                : '0.00 KM'}
                        </Typography>
                    </Box>
                    {playComponent}
                    <Box>
                        <Typography
                            gutterBottom
                            style={{
                                fontSize: 12,
                                opacity: 0.8,
                            }}
                        >
                            Odo
                        </Typography>
                        <Typography
                            variant="body2"
                            component="div"
                            style={{
                                fontSize: 14,
                                fontWeight: 700,
                            }}
                        >
                            {events?.startKmReading && events?.endKmReading
                                ? `${parseFloat(
                                      `${
                                          events?.endKmReading -
                                          events?.startKmReading
                                      }`,
                                  ).toPrecision(
                                      parseFloat(tracking?.aggDeviceOdoDistance)
                                          ?.toFixed(2)
                                          ?.toString()?.length - 1,
                                  )} KM`
                                : `${parseFloat('0').toPrecision(
                                      parseFloat(tracking?.aggDeviceOdoDistance)
                                          ?.toFixed(2)
                                          ?.toString()?.length - 1,
                                  )} KM`}
                        </Typography>
                    </Box>
                </Box>
                <Box
                    flexDirection={'row'}
                    display={'flex'}
                    alignItems={'center'}
                    mt={'4px'}
                >
                    {events?.startKmS3ImageUrl && (
                        <Avatar
                            style={{
                                height: 16,
                                width: 16,
                                marginLeft: '0.2rem',
                            }}
                            variant="rounded"
                            src={events?.startKmS3ImageUrl}
                            onClick={() => {
                                setOdometerViewModalType('START');
                                setOdometerViewModalOpen(true);
                            }}
                        />
                    )}
                    {events?.endKmS3ImageUrl && (
                        <Avatar
                            style={{
                                height: 16,
                                width: 16,
                                marginLeft: '0.2rem',
                            }}
                            variant="rounded"
                            src={events?.endKmS3ImageUrl}
                            onClick={() => {
                                setOdometerViewModalType('END');
                                setOdometerViewModalOpen(true);
                            }}
                        />
                    )}
                </Box>
            </Box>
            {!!havingEV && (
                <>
                    <Divider
                        style={{ margin: '0 12px' }}
                        orientation="vertical"
                        flexItem
                    />
                    <Box width={'50%'}>
                        <Box
                            flexDirection={'row'}
                            display={'flex'}
                            alignItems={'center'}
                            justifyContent={'space-between'}
                        >
                            <Box>
                                <Typography
                                    gutterBottom
                                    style={{
                                        fontSize: 12,
                                        opacity: 0.8,
                                        color: 'green',
                                    }}
                                >
                                    GPS (EV)
                                </Typography>
                                <Typography
                                    variant="body2"
                                    component="div"
                                    style={{
                                        fontSize: 14,
                                        fontWeight: 700,
                                        color: 'green',
                                    }}
                                >
                                    {evTracking?.aggDeviceOdoDistance
                                        ? `${parseFloat(
                                              evTracking?.aggDeviceOdoDistance,
                                          ).toFixed(2)} KM`
                                        : '0.00 KM'}
                                </Typography>
                            </Box>
                            <Box>
                                <Typography
                                    gutterBottom
                                    style={{
                                        fontSize: 12,
                                        opacity: 0.8,
                                        color: 'green',
                                    }}
                                >
                                    Odo (EV)
                                </Typography>
                                <Typography
                                    variant="body2"
                                    component="div"
                                    style={{
                                        fontSize: 14,
                                        fontWeight: 700,
                                        color: 'green',
                                    }}
                                >
                                    {events?.startKmReading &&
                                    events?.endKmReading
                                        ? `${parseFloat(
                                              `${
                                                  events?.endKmReading -
                                                  events?.startKmReading
                                              }`,
                                          ).toPrecision(
                                              parseFloat(
                                                  evTracking?.aggDeviceOdoDistance,
                                              )
                                                  ?.toFixed(2)
                                                  ?.toString()?.length - 1,
                                          )} KM`
                                        : `${parseFloat('0').toPrecision(
                                              parseFloat(
                                                  evTracking?.aggDeviceOdoDistance,
                                              )
                                                  ?.toFixed(2)
                                                  ?.toString()?.length - 1,
                                          )} KM`}
                                </Typography>
                            </Box>
                        </Box>
                        <Box
                            flexDirection={'row'}
                            display={'flex'}
                            alignItems={'center'}
                        ></Box>
                    </Box>
                </>
            )}
            {!!odometerViewModalOpen && (
                <OdometerViewModal
                    open={true}
                    onClose={() => {
                        setOdometerViewModalOpen(false);
                    }}
                    viewType={odometerViewModalType}
                    kmReading={
                        odometerViewModalType == 'START'
                            ? events?.startKmReading
                            : events?.endKmReading
                    }
                    kmS3Url={
                        odometerViewModalType == 'START'
                            ? events?.startKmS3ImageUrl
                            : events?.endKmS3ImageUrl
                    }
                />
            )}
        </Box>
    );
};

export default DistanceInfo;
