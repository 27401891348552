import {
    Box,
    Button,
    Divider,
    Grid,
    IconButton,
    ListItemIcon,
    makeStyles,
    Paper,
    Tooltip,
    Typography,
    Avatar,
} from '@material-ui/core';
import SingleMapView from '../MapDetailsV2';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Map from '../../../components/Map';
import { createCustomMarkerHtml } from '../../../components/Map/BikeIcon';
import axios from 'axios';
import 'react-leaflet-markercluster/dist/styles.min.css';
import {
    GetMapVechicleList,
    getTripRouteDetails,
    getTrackingToggleEvents,
    getAllTrackingEvents,
} from '../../../store/actions/mapViewActionCreator';
import moment from 'moment';
import { Spinner } from '../../../components/UI';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import StopIcon from '@material-ui/icons/Stop';
import FastForwardIcon from '@material-ui/icons/FastForward';
import FastRewindIcon from '@material-ui/icons/FastRewind';
import {
    Timeline,
    TimelineItem,
    TimelineSeparator,
    TimelineConnector,
    TimelineContent,
    TimelineDot,
    TimelineOppositeContent,
} from '@material-ui/lab';
import { CompactCustomTextField } from '../../../components/Textfield';
import InsertInvitationIcon from '@material-ui/icons/InsertInvitation';
import { CustomDatePicker } from '../../../components/DateTimePicker';
import { Polyline, Marker, Popup, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { getTimeAsDuration, getTimeDifference } from '../../../utils/helper';
import {
    redMarker,
    greenMarker,
    orangeFilledMarker,
    purpleMarker,
    blueGreenMarker,
} from '../../../components/Map/MarkerIcons';
import '../styles.scss';
import Slider from '@material-ui/core/Slider';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import { getSingleStaff } from '../../../store/actions/staffActionCreator';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import {
    BatteryIcon,
    LocationMobileOffIcon,
    LocationMobileOnIcon,
    LocationPermissionOffIcon,
    LocationPermissionOnIcon,
    MobileIcon,
} from '../../../assets/svg';
import OdometerViewModal from '../../../components/Modals/OdometerViewModal';
import EventCard from './components/EventCard';
import LocationPointCard from './components/LocationPointCard';
import { sampleData } from './components/sample';
const useStyles = makeStyles((theme) => ({
    container: {
        '& .leaflet-container': {
            width: '100%',
            height: '85vh',
            flexGrow: 1,
            // borderRadius: 16,
            borderTopRightRadius: 16,
            borderBottomRightRadius: 16,
        },
    },
    card: {
        backgroundColor: '#f6f7f9',
        marginTop: 16,
        color: '#000',
        padding: 16,
    },
}));

let indexPosition = 0;
let playbackInterval;
function MapDetail({
    user,
    events,
    staffList,
    dispatchGetMapVechicleList,
    heliosTrackingData = [],
    trackingData,
    dispatchGetTripRouteDetails,
    dispatchGetTrackingToggleEvents,
    staffTrackAndTrace,
    dispatchGetSingleStaff,
    dispatchGetAllTrackingEvents,
    ...props
}) {
    const classes = useStyles();
    const [loading, toggleLoading] = useState(true);
    const [datePickerOpen, setDatePickerOpen] = useState(false);
    const [selectedStaff, setSelectedStaff] = useState();
    const [selectedDate, setSelectedDate] = useState(
        moment().format('DD MMM YYYY'),
    );

    const [initialConfig, setInitialConfig] = useState(false);

    // All of trip data
    const [defaultTripData, setDefaultTripData] = useState({});
    const [tripData, setTripData] = useState({});

    const [stoppageReportData, setStoppageReportData] = useState({});

    const [playbackData, setPlaybackData] = useState([]);
    const [playbackPosition, setPlaybackPosition] = useState({});
    const [playbackPolylineData, setPlaybackPolylineData] = useState([]);
    const [speedFactor, setSpeedFactor] = useState(1);

    const [sliderValue, setSliderValue] = useState(indexPosition);

    const [showPlaybackButton, setShowPlaybackButton] = useState(true);

    const [disablePlayButton, setDisablePlayButton] = useState(true);

    const [selectedStartStoppagePoint, setSelectedStartStoppagePoint] =
        useState(null);

    const [selectedEndStoppagePoint, setSelectedEndStoppagePoint] =
        useState(null);
    const [
        selectedStartStoppagePointLocationData,
        setSelectedStartStoppagePointLocationData,
    ] = useState(null);
    const [
        selectedEndStoppagePointLocationData,
        setSelectedEndStoppagePointLocationData,
    ] = useState(null);

    const [showCloseButton, setShowCloseButton] = useState(false);

    const [eventsData, setEventsData] = useState([]);
    const [userActivityLineItems, setUserActivityLineItems] = useState([]);
    const [originalEventsData, setOriginalEventsData] = useState([]);
    const [startEventDescription, setStartEventDescription] = useState('');
    const [startKm, setStartKm] = useState();
    const [endKm, setEndKm] = useState();
    const [startKmS3ImageUrl, setStartKmS3ImageUrl] = useState();
    const [endKmS3ImageUrl, setEndKmS3ImageUrl] = useState();

    const [odometerViewModalOpen, setOdometerViewModalOpen] = useState(false);
    const [odometerViewModalType, setOdometerViewModalType] = useState('IN');
    let initalFlag = true;

    useEffect(() => {
        resetState();
        toggleLoading(false);

        return () => {
            clearInterval(playbackInterval);
        };
    }, []);

    const resetState = () => {
        setStartAddress(null);
        setEndAddress(null);

        setSelectedStartStoppagePoint(null);
        setSelectedEndStoppagePoint(null);
        setSelectedStartStoppagePointLocationData(null);
        setSelectedEndStoppagePointLocationData(null);

        clearInterval(playbackInterval);
        setPlaybackData([]);
        setPlaybackPosition({});
        setPlaybackPolylineData([]);
        setShowPlaybackButton(true);
        setDisablePlayButton(true);
    };

    useEffect(() => {
        console.log('datechanged', selectedDate);
        // getTripRouteData(moment(selectedDate));
        resetState();
        toggleLoading(true);

        fetchStaffDetials();
    }, [selectedDate]);

    const fetchStaffDetials = () => {
        dispatchGetSingleStaff(
            props?.match?.params?.id
                ? props?.match?.params?.id
                : staffTrackAndTrace?.id,
            async (resp) => {
                await setSelectedStaff({
                    ...resp.response,
                });
                resetState();
            },
            (errr) => {
                toggleLoading(false);
            },
        );
    };

    const [latLongPoints, setLatLongPoints] = useState();

    const getTripRouteData = async (
        startTime,
        endTime,
        notInitCall = false,
    ) => {
        await toggleLoading(true);
        await setTripData({});
        let tripStart =
            startTime &&
            moment(startTime)
                .add(1, 'minutes')
                .utc()
                .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
        let tripEnd =
            endTime &&
            moment(endTime).utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');

        console.log('getTripRouteData', startTime, tripStart, endTime, tripEnd);

        const payload = {
            action: 'getTripRouteDetails',
            data: {
                type: 'contract',
                subtype: 'trip',

                vehicle: {
                    id: 'profile_vehicle_TEST_SESEAG03202300751', //selectedStaff?.beeconVehicleId,
                },
                org: {
                    id: 'profile_organization_LODXW8483080', //user?.config?.beeconOrgId,
                },

                tripStart: {
                    startTime: tripStart,
                },
                tripClosure: { endTime: tripEnd },
            },
            timeOffset: 330,
            resolveDeps: false,
        };

        console.log('payloaddddddd', payload);

        !!selectedStaff?.beeconVehicleId &&
            dispatchGetTripRouteDetails(
                {
                    token: `Bearer ${user?.config?.beeconAPIToken}`,
                    data: payload,
                },
                (response) => {
                    console.log('getTripRouteDetails Success', response);
                    initalFlag = false;
                    if (notInitCall) {
                        setTripData(response);
                    } else {
                        setDefaultTripData(response);
                    }
                    // toggleLoading(false);
                },
                (response) => {
                    console.log('FAILED ROUTEE', response);
                    // toggleLoading(false);
                },
            );
    };

    useEffect(() => {
        toggleLoading(true);
        setTripData(defaultTripData);

        console.log(
            'setStoppageReportData',
            defaultTripData?.idleLocationPoints,
            userActivityLineItems,
        );

        let idlePoints = [];

        if (userActivityLineItems?.length) {
            idlePoints = [
                ...idlePoints,
                ...userActivityLineItems?.map((item) => ({
                    ...item,
                    title: item?.description,
                    description: item?.properties?.['Token number']
                        ? `Token number - ${item?.properties?.['Token number']}`
                        : '',
                })),
            ];
        }

        if (defaultTripData && defaultTripData?.idleLocationPoints?.length) {
            idlePoints = [
                ...idlePoints,
                ...defaultTripData?.idleLocationPoints?.map((item) => {
                    const idlePoint = item?.split(',');
                    return {
                        kmReading: idlePoint[4],
                        description: idlePoint[idlePoint?.length - 1],
                        latitude: idlePoint[2],
                        longitude: idlePoint[3],
                        startTime: idlePoint[0],
                        endTime: idlePoint[1],
                        time: idlePoint[1],
                        type: 'IDLE_POINT',
                    };
                }),
            ];
        }

        setStoppageReportData(
            idlePoints
                ?.sort((a, b) => a?.time - b?.time)
                ?.filter((idlePoint) => {
                    console.log('idlePoints', idlePoint);
                    return (
                        parseInt(idlePoint?.time) >=
                            defaultTripData?.locationPoints?.[0]
                                ?.deviceTimeEpoch &&
                        parseInt(idlePoint?.time) <=
                            defaultTripData?.locationPoints?.[
                                defaultTripData?.locationPoints?.length - 1
                            ]?.deviceTimeEpoch
                    );
                }),
        );

        if (defaultTripData?.locationPoints?.length > 0) {
            setSelectedStartStoppagePoint(
                defaultTripData?.locationPoints[0]?.deviceTimeEpoch,
            );

            setSelectedStartStoppagePointLocationData(
                defaultTripData?.locationPoints[0],
            );

            setSelectedEndStoppagePoint(
                defaultTripData?.locationPoints[
                    defaultTripData?.locationPoints?.length - 1
                ]?.deviceTimeEpoch,
            );
            setSelectedEndStoppagePointLocationData(
                defaultTripData?.locationPoints[
                    defaultTripData?.locationPoints?.length - 1
                ],
            );

            getAddressfromLatLong(
                defaultTripData?.locationPoints[0]?.latitude,
                defaultTripData?.locationPoints?.length > 0 &&
                    defaultTripData?.locationPoints[0]?.longitude,
                'start',
            );
            getAddressfromLatLong(
                defaultTripData?.locationPoints[
                    defaultTripData?.locationPoints?.length - 1
                ]?.latitude,
                defaultTripData?.locationPoints?.length > 0 &&
                    defaultTripData?.locationPoints[
                        defaultTripData?.locationPoints?.length - 1
                    ]?.longitude,
                'end',
            );
        }
    }, [defaultTripData, userActivityLineItems]);

    useEffect(() => {
        if (defaultTripData?.locationPoints?.length > 0) {
            clearInterval(playbackInterval);
            stopPlayback();

            console.log(
                'selectedStartStoppagePoint',
                selectedStartStoppagePoint,
            );
            console.log('selectedEndStoppagePoint', selectedEndStoppagePoint);
        }
    }, [selectedEndStoppagePoint]);

    async function stepperSelectorHandler(point) {
        if (
            selectedStartStoppagePoint == null ||
            (selectedStartStoppagePoint && selectedEndStoppagePoint)
        ) {
            setSelectedEndStoppagePoint(null);
            setSelectedEndStoppagePointLocationData(null);

            console.log('START', point);
            setShowCloseButton(true);
            setSelectedStartStoppagePoint(
                point?.deviceTimeEpoch ?? point?.startTime ?? point?.time,
            );
            setSelectedStartStoppagePointLocationData(point);
        } else if (
            selectedStartStoppagePoint &&
            selectedEndStoppagePoint == null
        ) {
            console.log('STOP', point);
            const timestamp =
                point?.deviceTimeEpoch ?? point?.endTime ?? point?.time;
            await setShowCloseButton(false);
            if (parseInt(selectedStartStoppagePoint) < parseInt(timestamp)) {
                await setSelectedEndStoppagePoint(timestamp);
                await setSelectedEndStoppagePointLocationData(point);
                getTripRouteData(
                    parseInt(selectedStartStoppagePoint),
                    parseInt(timestamp),
                    true,
                );
            } else {
                if (defaultTripData?.locationPoints?.length > 0) {
                    setSelectedStartStoppagePoint(
                        defaultTripData?.locationPoints[0]?.deviceTimeEpoch,
                    );
                    setSelectedStartStoppagePointLocationData(
                        defaultTripData?.locationPoints[0],
                    );

                    setSelectedEndStoppagePoint(
                        defaultTripData?.locationPoints[
                            defaultTripData?.locationPoints?.length - 1
                        ]?.deviceTimeEpoch,
                    );
                    setSelectedEndStoppagePointLocationData(
                        defaultTripData?.locationPoints[
                            defaultTripData?.locationPoints?.length - 1
                        ],
                    );

                    getTripRouteData(
                        parseInt(
                            defaultTripData?.locationPoints[0]?.deviceTimeEpoch,
                        ),
                        parseInt(
                            defaultTripData?.locationPoints[
                                defaultTripData?.locationPoints?.length - 1
                            ]?.deviceTimeEpoch,
                        ),
                        true,
                    );
                }
            }
        }
    }
    useEffect(() => {
        // Set Lat-Long points for plotting polyline
        toggleLoading(true);

        console.log('tripDATA CHANGED', tripData);
        if (tripData.locationPoints) {
            setLatLongPoints(
                tripData?.locationPoints?.map(({ latitude, longitude }) => [
                    latitude,
                    longitude,
                ]),
            );

            setPlaybackData([]);
            setInitialConfig(true);

            tripData?.locationPoints.forEach((element) => {
                let play = {
                    status: 1,
                    time: element.deviceTimeEpoch,
                    course: element.angle,
                    lat: element.latitude,
                    lng: element.longitude,
                    angle: element.angle,
                };
                setPlaybackData((prev) => {
                    return [...prev, play];
                });
            });

            if (startAddress && endAddress) {
                toggleLoading(false);
            }

            if (!tripData?.locationPoints?.length) {
                toggleLoading(false);
            }
        } else {
            toggleLoading(false);
        }
    }, [tripData]);

    useEffect(() => {
        console.log('eventsDataaa', eventsData);
    }, [eventsData]);

    useEffect(() => {
        console.log('selectedStaff', selectedStaff);
        !!selectedStaff &&
            dispatchGetAllTrackingEvents(
                {
                    staffId: props?.match?.params?.id
                        ? props?.match?.params?.id
                        : staffTrackAndTrace?.id,
                    date: moment(selectedDate).format('YYYYMMDD'),
                },
                (response) => {
                    console.log('response', response);

                    setStartEventDescription(
                        response?.response?.startEventDescription,
                    );
                    setStartKm(
                        response?.response?.startKmReading > 0
                            ? response?.response?.startKmReading
                            : null,
                    );
                    setEndKm(
                        response?.response?.endKmReading > 0
                            ? response?.response?.endKmReading
                            : null,
                    );
                    setStartKmS3ImageUrl(
                        response?.response?.startKmS3ImageUrl?.length > 0
                            ? response?.response?.startKmS3ImageUrl
                            : null,
                    );
                    setEndKmS3ImageUrl(
                        response?.response?.endKmS3ImageUrl?.length > 0
                            ? response?.response?.endKmS3ImageUrl
                            : null,
                    );

                    if (
                        moment(selectedDate).valueOf() >=
                        moment('2021-09-16').valueOf()
                    ) {
                        setOriginalEventsData(response?.response?.events);
                        setUserActivityLineItems(
                            response?.response?.userActivityLineItems,
                        );
                        // setEventsData(response?.response?.events)
                        setEventsData(
                            response?.response?.events?.filter((item) => {
                                if (
                                    response?.response?.outTimeEpochMillis >
                                        0 &&
                                    response?.response?.inTimeEpochMillis > 0
                                ) {
                                    return (
                                        item?.time >=
                                            response?.response
                                                ?.inTimeEpochMillis &&
                                        item?.time <=
                                            response?.response
                                                ?.outTimeEpochMillis
                                    );
                                } else if (
                                    response?.response?.inTimeEpochMillis > 0
                                ) {
                                    return (
                                        item?.time >=
                                        response?.response?.inTimeEpochMillis
                                    );
                                } else {
                                    return item;
                                }
                            }),
                        );
                    } else {
                        setOriginalEventsData([]);
                        setEventsData([]);
                    }

                    // Here In And Out time will always be present if the user has punched in.
                    // outTimeEpochMillis will either be 0(iff inTimeEpochMillis ==0) OR punched-out time OR maxPunchOutTime
                    getTripRouteData(
                        response.response?.inTimeEpochMillis > 0
                            ? response.response?.inTimeEpochMillis
                            : moment(selectedDate).startOf('day').valueOf(),

                        response.response?.outTimeEpochMillis > 0
                            ? moment(response.response?.outTimeEpochMillis)
                                  .add(5, 'minutes')
                                  .valueOf()
                            : moment(selectedDate).endOf('day').valueOf(),
                    );
                },
                () => {
                    toggleLoading(false);
                    console.log('Get Tracking Toggle FAILED');
                },
            );
    }, [selectedStaff]);

    const [startAddress, setStartAddress] = useState(null);
    const [endAddress, setEndAddress] = useState(null);

    function getAddressfromLatLong(lat, long, type) {
        var url =
            'https://nominatim.openstreetmap.org/reverse?lat=' +
            parseFloat(lat) +
            '&lon=' +
            parseFloat(long) +
            '&addressdetails=1&format=json';
        let display_name;
        axios
            .get(url)
            .then((response) => {
                if (response && response.data && response.data.display_name) {
                    display_name = response.data.display_name;
                    // console.log('adddddddddressssss', display_name);
                    if (type === 'start') {
                        setStartAddress(display_name);
                    }
                    if (type === 'end') {
                        setEndAddress(display_name);
                    }
                    toggleLoading(false);
                }
            })
            .catch((e) => {
                if (type === 'start') {
                    setStartAddress('');
                }
                if (type === 'end') {
                    setEndAddress('');
                }
                toggleLoading(false);
            });
    }

    function startPlayback() {
        // console.log('playbackData', playbackData);

        playbackInterval = setInterval(() => {
            setPlaybackPosition({
                lat: playbackData[indexPosition]?.lat,
                lng: playbackData[indexPosition]?.lng,
                angle: playbackData[indexPosition]?.angle,
            });

            setPlaybackPolylineData(latLongPoints?.slice(0, indexPosition));
            // console.log('indexPosition', indexPosition, playbackData?.length);
            if (indexPosition + 1 < playbackData.length - 1) {
                indexPosition = indexPosition + 1;
                setSliderValue((indexPosition / playbackData?.length) * 100);
            } else if (indexPosition + 1 >= playbackData.length - 1) {
                clearInterval(playbackInterval);
                indexPosition = playbackData.length - 1;
                setSliderValue((indexPosition / playbackData?.length) * 100);
                stopPlayback();
            }
        }, 1000 / (16 * speedFactor));
    }

    function pausePlayback() {
        console.log('Entered Pause Playback');
        clearInterval(playbackInterval);

        setShowPlaybackButton(false);
        setDisablePlayButton(false);
    }

    function stopPlayback() {
        console.log('Entered Stop Playback');
        clearInterval(playbackInterval);
        indexPosition = 0;
        setSliderValue(0);
        setSpeedFactor(1);
        setPlaybackPosition({});
        setPlaybackPolylineData([]);
        setShowPlaybackButton(true);
        setDisablePlayButton(true);
    }

    function handleSliderMove(event, newValue) {
        indexPosition = Math.ceil((newValue / 100) * playbackData?.length);
        setSliderValue((indexPosition / playbackData?.length) * 100);
        // console.log('indexPosition', indexPosition);
    }

    useEffect(() => {
        console.log('stoppageReportData', stoppageReportData);
    }, [stoppageReportData]);

    useEffect(() => {
        if (!showPlaybackButton && disablePlayButton && indexPosition) {
            console.log('speedFactor', speedFactor);
            startPlayback();
        }
    }, [speedFactor]);

    function SetBounds() {
        const map = useMap();
        if (latLongPoints && latLongPoints.length > 0) {
            map.fitBounds(latLongPoints);
        }
        setInitialConfig(false);
        return null;
    }

    function eventStyleFlag(event) {
        if (
            event?.name?.includes('BATTERY_OPTIMIZATION_ON') ||
            event?.name?.includes('POWER_SAVE_MODE_ON') ||
            event?.name?.includes('LOCATION_PERMISSION_OFF') ||
            event?.name?.includes('DEVICE_LOCATION_OFF') ||
            event?.description?.toLowerCase()?.includes('terminated')
        )
            return true;
        else return false;
    }

    function getStartPointMarker() {
        return (
            <Marker
                // if position changes, marker will drift its way to new position
                position={
                    latLongPoints[0] &&
                    latLongPoints[0][0] &&
                    latLongPoints[0][1]
                        ? latLongPoints[0]
                        : {}
                }
                icon={greenMarker}
            >
                <Popup direction="bottom" opacity={1}>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            textAlign: 'center',
                            flexDirection: 'column',
                        }}
                    >
                        {defaultTripData?.locationPoints?.[0]
                            ?.deviceTimeEpoch == selectedStartStoppagePoint ? (
                            startAddress ? (
                                <>
                                    <Typography
                                        style={{
                                            fontSize: 14,
                                            fontWeight: 700,
                                        }}
                                        variant="subtitle1"
                                    >
                                        {startEventDescription?.length > 0
                                            ? startEventDescription
                                            : startAddress ?? ''}
                                    </Typography>
                                    <Typography
                                        style={{
                                            fontWeight: 400,
                                            fontSize: 16,
                                        }}
                                        variant="subtitle1"
                                    >
                                        {moment(
                                            parseInt(
                                                defaultTripData
                                                    ?.locationPoints[0]
                                                    ?.deviceTimeEpoch,
                                            ),
                                        ).format(
                                            'hh:mm A',
                                            // 'D MMM YYYY hh:mm A',
                                        )}
                                    </Typography>
                                </>
                            ) : (
                                <></>
                            )
                        ) : (
                            selectedStartStoppagePointLocationData &&
                            (['ORDER_PICKED', 'ORDER_DELIVERED'].includes(
                                selectedStartStoppagePointLocationData?.type,
                            ) ? (
                                <>
                                    {(function () {
                                        let popupInfo = {
                                            Description:
                                                selectedStartStoppagePointLocationData?.title ??
                                                '',
                                            ...selectedStartStoppagePointLocationData?.properties,
                                        };
                                        return Object.keys(popupInfo)?.map(
                                            (key, index) => {
                                                return (
                                                    <Box
                                                        style={{
                                                            width: 200,
                                                            marginTop:
                                                                index === 0
                                                                    ? 20
                                                                    : 0,
                                                            display: 'flex',
                                                            flexDirection:
                                                                'row',
                                                            alignItems:
                                                                'center',
                                                            justifyContent:
                                                                'space-between',
                                                        }}
                                                    >
                                                        <Typography
                                                            gutterBottom
                                                            variant="body2"
                                                            component="div"
                                                            style={{
                                                                fontWeight: 700,
                                                                fontSize: 14,
                                                            }}
                                                        >
                                                            {key} :{' '}
                                                        </Typography>
                                                        <Typography
                                                            gutterBottom
                                                            variant="body2"
                                                            component="div"
                                                            style={{
                                                                fontSize: 14,
                                                            }}
                                                        >
                                                            {popupInfo[key]}
                                                        </Typography>
                                                    </Box>
                                                );
                                            },
                                        );
                                    })()}
                                </>
                            ) : (
                                <>
                                    <Typography
                                        style={{
                                            fontWeight: 700,
                                            fontSize: 14,
                                        }}
                                        variant="subtitle1"
                                    >
                                        {selectedStartStoppagePointLocationData
                                            ?.description?.length > 0 &&
                                        isNaN(
                                            Number(
                                                selectedStartStoppagePointLocationData?.description,
                                            ),
                                        )
                                            ? selectedStartStoppagePointLocationData?.description
                                                  .split('@!@')
                                                  .join(',')
                                            : 'No Location Available'}
                                    </Typography>
                                    <Typography
                                        style={{
                                            fontWeight: 400,
                                            fontSize: 16,
                                        }}
                                        variant="subtitle1"
                                    >
                                        {moment(
                                            parseInt(
                                                selectedStartStoppagePointLocationData?.startTime ??
                                                    selectedStartStoppagePointLocationData?.time,
                                            ),
                                        ).format(
                                            'hh:mm A',
                                            // 'D MMM YYYY hh:mm A',
                                        )}{' '}
                                        -{' '}
                                        {moment(
                                            parseInt(
                                                selectedStartStoppagePointLocationData?.endTime ??
                                                    selectedStartStoppagePointLocationData?.time,
                                            ),
                                        ).format(
                                            'hh:mm A',
                                            // 'D MMM YYYY hh:mm A',
                                        )}{' '}
                                        (
                                        {getTimeAsDuration(
                                            getTimeDifference(
                                                parseInt(
                                                    selectedStartStoppagePointLocationData?.endTime ??
                                                        selectedStartStoppagePointLocationData?.time,
                                                ),
                                                parseInt(
                                                    selectedStartStoppagePointLocationData?.startTime ??
                                                        selectedStartStoppagePointLocationData?.time,
                                                ),
                                            ),
                                            'small',
                                        )}
                                        )
                                    </Typography>
                                </>
                            ))
                        )}
                    </div>
                </Popup>
            </Marker>
        );
    }

    function getEndPointMarker() {
        return (
            <Marker
                // if position changes, marker will drift its way to new position
                position={
                    latLongPoints[latLongPoints.length - 1] &&
                    latLongPoints[latLongPoints.length - 1][0] &&
                    latLongPoints[latLongPoints.length - 1][1]
                        ? latLongPoints[latLongPoints.length - 1]
                        : {}
                }
                icon={redMarker}
            >
                <Popup direction="bottom" opacity={1}>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            textAlign: 'center',
                            flexDirection: 'column',
                        }}
                    >
                        {defaultTripData?.locationPoints[
                            defaultTripData?.locationPoints?.length - 1
                        ]?.deviceTimeEpoch == selectedEndStoppagePoint ? (
                            endAddress ? (
                                <>
                                    <Typography
                                        style={{
                                            fontSize: 14,
                                            fontWeight: 700,
                                        }}
                                        variant="subtitle1"
                                    >
                                        {startEventDescription?.length > 0
                                            ? startEventDescription
                                            : endAddress ?? ''}
                                    </Typography>
                                    <Typography
                                        style={{
                                            fontWeight: 400,
                                            fontSize: 16,
                                        }}
                                        variant="subtitle1"
                                    >
                                        {moment(
                                            parseInt(
                                                defaultTripData?.locationPoints[
                                                    defaultTripData
                                                        ?.locationPoints
                                                        ?.length - 1
                                                ]?.deviceTimeEpoch,
                                            ),
                                        ).format(
                                            'hh:mm A',
                                            // 'D MMM YYYY hh:mm A',
                                        )}
                                    </Typography>
                                </>
                            ) : (
                                <></>
                            )
                        ) : (
                            selectedEndStoppagePointLocationData &&
                            (['ORDER_PICKED', 'ORDER_DELIVERED'].includes(
                                selectedEndStoppagePointLocationData?.type,
                            ) ? (
                                <>
                                    {(function () {
                                        let popupInfo = {
                                            Description:
                                                selectedEndStoppagePointLocationData?.title ??
                                                '',
                                            ...selectedEndStoppagePointLocationData?.properties,
                                        };
                                        return Object.keys(popupInfo)?.map(
                                            (key, index) => {
                                                return (
                                                    <Box
                                                        style={{
                                                            width: 200,
                                                            marginTop:
                                                                index === 0
                                                                    ? 20
                                                                    : 0,
                                                            display: 'flex',
                                                            flexDirection:
                                                                'row',
                                                            alignItems:
                                                                'center',
                                                            justifyContent:
                                                                'space-between',
                                                        }}
                                                    >
                                                        <Typography
                                                            gutterBottom
                                                            variant="body2"
                                                            component="div"
                                                            style={{
                                                                fontWeight: 700,
                                                                fontSize: 14,
                                                            }}
                                                        >
                                                            {key} :{' '}
                                                        </Typography>
                                                        <Typography
                                                            gutterBottom
                                                            variant="body2"
                                                            component="div"
                                                            style={{
                                                                fontSize: 14,
                                                            }}
                                                        >
                                                            {popupInfo[key]}
                                                        </Typography>
                                                    </Box>
                                                );
                                            },
                                        );
                                    })()}
                                </>
                            ) : (
                                <>
                                    <Typography
                                        style={{
                                            fontWeight: 700,
                                            fontSize: 14,
                                        }}
                                        variant="subtitle1"
                                    >
                                        {selectedEndStoppagePointLocationData
                                            ?.description?.length > 0 &&
                                        isNaN(
                                            Number(
                                                selectedEndStoppagePointLocationData?.description,
                                            ),
                                        )
                                            ? selectedEndStoppagePointLocationData?.description
                                                  .split('@!@')
                                                  .join(',')
                                            : 'No Location Available'}
                                    </Typography>
                                    <Typography
                                        style={{
                                            fontWeight: 400,
                                            fontSize: 16,
                                        }}
                                        variant="subtitle1"
                                    >
                                        {moment(
                                            parseInt(
                                                selectedEndStoppagePointLocationData?.startTime ??
                                                    selectedEndStoppagePointLocationData?.time,
                                            ),
                                        ).format(
                                            'hh:mm A',
                                            // 'D MMM YYYY hh:mm A',
                                        )}{' '}
                                        -{' '}
                                        {moment(
                                            parseInt(
                                                selectedEndStoppagePointLocationData?.endTime ??
                                                    selectedEndStoppagePointLocationData?.time,
                                            ),
                                        ).format(
                                            'hh:mm A',
                                            // 'D MMM YYYY hh:mm A',
                                        )}{' '}
                                        (
                                        {getTimeAsDuration(
                                            getTimeDifference(
                                                parseInt(
                                                    selectedEndStoppagePointLocationData?.endTime ??
                                                        selectedEndStoppagePointLocationData?.time,
                                                ),
                                                parseInt(
                                                    selectedEndStoppagePointLocationData?.startTime ??
                                                        selectedEndStoppagePointLocationData?.time,
                                                ),
                                            ),
                                            'small',
                                        )}
                                        )
                                    </Typography>
                                </>
                            ))
                        )}
                    </div>
                </Popup>
            </Marker>
        );
    }

    function getIdlePointMarker(data) {
        let point = [data[2], data[3]];
        return (
            <Marker
                // if position changes, marker will drift its way to new position
                position={point[0] && point[1] ? point : {}}
                icon={
                    tripData.tripClosure.endTime === data[1]
                        ? redMarker
                        : orangeFilledMarker
                }
            >
                <Popup direction="bottom" opacity={1}>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            textAlign: 'center',
                            flexDirection: 'column',
                        }}
                    >
                        <Typography
                            style={{
                                fontWeight: 700,
                                fontSize: 14,
                            }}
                            variant="subtitle1"
                        >
                            {data[data?.length - 1]?.length > 0 &&
                            isNaN(Number(data[data?.length - 1]))
                                ? data[data?.length - 1].split('@!@').join(',')
                                : 'No Location Available'}
                        </Typography>
                        <Typography
                            style={{
                                fontWeight: 400,
                                fontSize: 16,
                            }}
                            variant="subtitle1"
                        >
                            {moment(parseInt(data[0])).format(
                                'hh:mm A',
                                // 'D MMM YYYY hh:mm A',
                            )}{' '}
                            -{' '}
                            {moment(parseInt(data[1])).format(
                                'hh:mm A',
                                // 'D MMM YYYY hh:mm A',
                            )}{' '}
                            (
                            {getTimeAsDuration(
                                getTimeDifference(
                                    parseInt(data[1]),
                                    parseInt(data[0]),
                                ),
                                'small',
                            )}
                            )
                        </Typography>
                    </div>
                </Popup>
            </Marker>
        );
    }

    function getUserActivityMarker() {
        return '';
    }

    return (
        <>
            <Spinner loading={loading}>
                <Paper style={{ padding: '.1rem' }} elevation={0}>
                    <div className={classes.container}>
                        <Grid container spacing={1}>
                            <Grid
                                item
                                md={4}
                                xs={12}
                                style={{ padding: '18px 12px' }}
                            >
                                <Box
                                    style={
                                        showCloseButton ? { opacity: 0.4 } : {}
                                    }
                                >
                                    <Box
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                            marginBottom: 8,
                                        }}
                                    >
                                        <Box
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                            }}
                                        >
                                            {props?.match?.params?.id && (
                                                <ListItemIcon
                                                    onClick={(e) =>
                                                        props.history.push(
                                                            '/map-view',
                                                            {
                                                                payload:
                                                                    props
                                                                        ?.location
                                                                        ?.state
                                                                        ?.payload,
                                                            },
                                                        )
                                                    }
                                                    style={{
                                                        minWidth: '0',
                                                        color: '#000000',
                                                        cursor: 'pointer',
                                                        marginRight: '8px',
                                                    }}
                                                >
                                                    <ArrowBackIcon
                                                        style={{
                                                            fontSize: '1.5rem',
                                                        }}
                                                    />
                                                </ListItemIcon>
                                            )}
                                            <Typography
                                                variant="body2"
                                                component="div"
                                                style={{
                                                    fontWeight: 'bold',
                                                    fontSize: 18,
                                                }}
                                            >
                                                {selectedStaff?.name ?? '-'}
                                            </Typography>
                                        </Box>

                                        <Box
                                            style={{
                                                width: '37%',
                                                alignItems: 'center',
                                                margin: '0',
                                                padding: 0,
                                            }}
                                        >
                                            <CompactCustomTextField
                                                label="Date"
                                                InputProps={{
                                                    readOnly: true,
                                                    endAdornment: (
                                                        <IconButton
                                                            className={
                                                                'inputbutton'
                                                            }
                                                            onClick={(e) =>
                                                                setDatePickerOpen(
                                                                    true,
                                                                )
                                                            }
                                                            style={{
                                                                padding:
                                                                    '1px 4px 1px 1px ',
                                                            }}
                                                        >
                                                            <InsertInvitationIcon size="small" />
                                                        </IconButton>
                                                    ),
                                                }}
                                                onClick={(e) =>
                                                    setDatePickerOpen(true)
                                                }
                                                variant="outlined"
                                                value={selectedDate}
                                            />
                                        </Box>
                                    </Box>
                                    <Divider style={{ margin: '12px 0' }} />
                                    <Box
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'space-between',
                                            alignItems: 'baseline',
                                            marginBottom: 8,
                                        }}
                                    >
                                        <Typography
                                            gutterBottom
                                            // variant="body2"
                                            // component="div"
                                            style={{
                                                flexGrow: 0,
                                                margin: '0 6px 0 0',
                                                textAlign: 'left',
                                            }}
                                        >
                                            {selectedStaff?.hubName ?? '-'}{' '}
                                            {' | '}
                                            {selectedStaff?.contactNumber ??
                                                '-'}
                                        </Typography>
                                        <Typography
                                            gutterBottom
                                            // variant="body2"
                                            // component="div"
                                            style={{
                                                flexGrow: 0,
                                                margin: '0 6px 0 0',
                                                textAlign: 'left',
                                                color: '#14112d',
                                            }}
                                        >
                                            {selectedStaff?.vehicleNumber?.slice(
                                                0,
                                                selectedStaff?.vehicleNumber
                                                    .length - 4,
                                            ) ?? ''}
                                            <b>
                                                {selectedStaff?.vehicleNumber?.slice(
                                                    selectedStaff?.vehicleNumber
                                                        .length - 4,
                                                ) ?? ''}
                                            </b>
                                        </Typography>
                                    </Box>
                                    <Divider style={{ margin: '12px 0' }} />
                                    <Box
                                        style={{
                                            marginBotton: 8,
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                        }}
                                    >
                                        {!!selectedStaff?.deviceBrand && (
                                            <Tooltip
                                                title="Device Info"
                                                placement="top"
                                            >
                                                <Box
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                        justifyContent:
                                                            'space-between',
                                                    }}
                                                >
                                                    <MobileIcon />
                                                    <Typography
                                                        style={{
                                                            fontFamily:
                                                                'Nunito',
                                                            fontSize: 12,
                                                            fontWeight: 600,
                                                        }}
                                                    >
                                                        {!!selectedStaff?.deviceBrand
                                                            ? `${selectedStaff?.deviceBrand.toUpperCase()} ${selectedStaff?.deviceModel.toUpperCase()} (v${
                                                                  selectedStaff?.appVersion
                                                              })`
                                                            : ' '}
                                                    </Typography>
                                                </Box>
                                            </Tooltip>
                                        )}
                                        {props.location?.state?.data?.tracking
                                            ?.telematicData?.powerVoltage && (
                                            <Tooltip
                                                title="Battery Info"
                                                placement="top"
                                            >
                                                <Box
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                        justifyContent:
                                                            'space-between',
                                                    }}
                                                >
                                                    <BatteryIcon
                                                        color={
                                                            props.location
                                                                ?.state?.data
                                                                ?.tracking
                                                                ?.telematicData
                                                                ?.powerVoltage <
                                                            20
                                                                ? 'red'
                                                                : props.location
                                                                      ?.state
                                                                      ?.data
                                                                      ?.tracking
                                                                      ?.telematicData
                                                                      ?.powerVoltage >
                                                                  60
                                                                ? 'green'
                                                                : 'orange'
                                                        }
                                                    />
                                                    <Typography
                                                        style={{
                                                            fontFamily:
                                                                'Nunito',
                                                            fontSize: 12,
                                                            fontWeight: 600,
                                                            marginLeft: 5,
                                                        }}
                                                    >
                                                        {
                                                            props.location
                                                                ?.state?.data
                                                                ?.tracking
                                                                ?.telematicData
                                                                ?.powerVoltage
                                                        }
                                                        %
                                                    </Typography>
                                                </Box>
                                            </Tooltip>
                                        )}

                                        <>
                                            <Box
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    alignItems: 'center',
                                                    justifyContent:
                                                        'space-between',
                                                }}
                                            >
                                                <Tooltip
                                                    title="Location Permission"
                                                    placement="top"
                                                >
                                                    {!!selectedStaff?.locationPermission ? (
                                                        <LocationPermissionOnIcon />
                                                    ) : (
                                                        <LocationPermissionOffIcon />
                                                    )}
                                                </Tooltip>
                                            </Box>
                                            <Box
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    alignItems: 'center',
                                                    justifyContent:
                                                        'space-between',
                                                }}
                                            >
                                                <Tooltip
                                                    title="Location Service"
                                                    placement="top"
                                                >
                                                    {!!selectedStaff?.locationService ? (
                                                        <LocationMobileOnIcon />
                                                    ) : (
                                                        <LocationMobileOffIcon />
                                                    )}
                                                </Tooltip>
                                            </Box>
                                        </>
                                    </Box>

                                    <Divider style={{ margin: '12px 0' }} />
                                    <Box
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Box
                                            style={
                                                startKmS3ImageUrl &&
                                                endKmS3ImageUrl
                                                    ? {
                                                          width: '54%',
                                                          display: 'flex',
                                                          flexDirection:
                                                              'column',
                                                      }
                                                    : startKmS3ImageUrl ||
                                                      endKmS3ImageUrl
                                                    ? {
                                                          width: '50%',
                                                          display: 'flex',
                                                          flexDirection:
                                                              'column',
                                                      }
                                                    : {
                                                          width: '48%',
                                                          display: 'flex',
                                                          flexDirection:
                                                              'column',
                                                      }
                                            }
                                        >
                                            <Box
                                                style={
                                                    startKmS3ImageUrl &&
                                                    endKmS3ImageUrl
                                                        ? {
                                                              display: 'flex',
                                                              flexDirection:
                                                                  'row',
                                                              justifyContent:
                                                                  'space-between',
                                                              paddingRight: 38,
                                                          }
                                                        : startKmS3ImageUrl ||
                                                          endKmS3ImageUrl
                                                        ? {
                                                              display: 'flex',
                                                              flexDirection:
                                                                  'row',
                                                              justifyContent:
                                                                  'space-between',
                                                              paddingRight: 19,
                                                          }
                                                        : {
                                                              display: 'flex',
                                                              flexDirection:
                                                                  'row',
                                                              justifyContent:
                                                                  'space-between',
                                                          }
                                                }
                                            >
                                                <Typography
                                                    gutterBottom
                                                    variant="body2"
                                                    component="div"
                                                    style={{
                                                        fontWeight: 700,
                                                        fontSize: 14,
                                                    }}
                                                >
                                                    GPS Distance
                                                </Typography>
                                                <Box
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent:
                                                            'flex-end',
                                                    }}
                                                >
                                                    <Typography
                                                        gutterBottom
                                                        variant="body2"
                                                        component="div"
                                                        style={{
                                                            fontSize: 14,
                                                        }}
                                                    >
                                                        {tripData?.aggDeviceOdoDistance
                                                            ? `: ${parseFloat(
                                                                  tripData?.aggDeviceOdoDistance,
                                                              ).toFixed(2)} KM`
                                                            : ': 0.00 KM'}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                            <Box
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    alignItems: 'flex-start',
                                                    justifyContent:
                                                        'space-between',
                                                }}
                                            >
                                                <Typography
                                                    gutterBottom
                                                    variant="body2"
                                                    component="div"
                                                    style={{
                                                        fontSize: 14,
                                                        fontWeight: 700,
                                                    }}
                                                >
                                                    Meter Distance
                                                </Typography>
                                                <Box
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent:
                                                            'flex-start',
                                                    }}
                                                >
                                                    <Typography
                                                        gutterBottom
                                                        variant="body2"
                                                        component="div"
                                                        style={{
                                                            fontSize: 14,
                                                        }}
                                                    >
                                                        {startKm && endKm
                                                            ? `: ${parseFloat(
                                                                  endKm -
                                                                      startKm,
                                                              ).toPrecision(
                                                                  parseFloat(
                                                                      tripData?.aggDeviceOdoDistance,
                                                                  )
                                                                      ?.toFixed(
                                                                          2,
                                                                      )
                                                                      ?.toString()
                                                                      ?.length -
                                                                      1,
                                                              )} KM`
                                                            : `: ${parseFloat(
                                                                  0,
                                                              ).toPrecision(
                                                                  parseFloat(
                                                                      tripData?.aggDeviceOdoDistance,
                                                                  )
                                                                      ?.toFixed(
                                                                          2,
                                                                      )
                                                                      ?.toString()
                                                                      ?.length -
                                                                      1,
                                                              )} KM`}
                                                    </Typography>
                                                    {startKmS3ImageUrl && (
                                                        <Avatar
                                                            style={{
                                                                height: 16,
                                                                width: 16,
                                                                marginLeft:
                                                                    '0.2rem',
                                                            }}
                                                            variant="rounded"
                                                            src={
                                                                startKmS3ImageUrl
                                                            }
                                                            onClick={() => {
                                                                setOdometerViewModalType(
                                                                    'START',
                                                                );
                                                                setOdometerViewModalOpen(
                                                                    true,
                                                                );
                                                            }}
                                                        />
                                                    )}
                                                    {endKmS3ImageUrl && (
                                                        <Avatar
                                                            style={{
                                                                height: 16,
                                                                width: 16,
                                                                marginLeft:
                                                                    '0.2rem',
                                                            }}
                                                            variant="rounded"
                                                            src={
                                                                endKmS3ImageUrl
                                                            }
                                                            onClick={() => {
                                                                setOdometerViewModalType(
                                                                    'END',
                                                                );
                                                                setOdometerViewModalOpen(
                                                                    true,
                                                                );
                                                            }}
                                                        />
                                                    )}
                                                </Box>
                                            </Box>
                                        </Box>
                                        {showPlaybackButton && (
                                            <Button
                                                startIcon={<PlayArrowIcon />}
                                                style={{
                                                    padding: '6px 8px',
                                                }}
                                                disableElevation
                                                variant="contained"
                                                color="primary"
                                                onClick={() => {
                                                    if (
                                                        playbackData.length > 0
                                                    ) {
                                                        indexPosition = 0;
                                                        setShowPlaybackButton(
                                                            false,
                                                        );
                                                        setPlaybackPolylineData(
                                                            [],
                                                        );
                                                        setPlaybackPosition([]);
                                                        startPlayback();
                                                    }
                                                }}
                                            >
                                                Play Back
                                            </Button>
                                        )}
                                        {!showPlaybackButton && (
                                            <>
                                                <Box
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        alignItems:
                                                            'space-between',
                                                        flex: '0 0 30%',
                                                    }}
                                                >
                                                    <Box
                                                        style={{
                                                            display: 'flex',
                                                            justifyContent:
                                                                'space-between',
                                                        }}
                                                    >
                                                        <IconButton
                                                            variant="contained"
                                                            color="primary"
                                                            style={{
                                                                padding:
                                                                    '0.1rem',
                                                            }}
                                                            onClick={() => {
                                                                if (
                                                                    speedFactor >
                                                                    1
                                                                ) {
                                                                    pausePlayback();
                                                                    setSpeedFactor(
                                                                        (
                                                                            prev,
                                                                        ) => {
                                                                            if (
                                                                                prev ==
                                                                                16
                                                                            ) {
                                                                                return 8;
                                                                            } else if (
                                                                                prev ==
                                                                                8
                                                                            ) {
                                                                                return 4;
                                                                            } else if (
                                                                                prev ==
                                                                                4
                                                                            ) {
                                                                                return 2;
                                                                            } else if (
                                                                                prev ==
                                                                                2
                                                                            ) {
                                                                                return 1;
                                                                            }
                                                                        },
                                                                    );
                                                                    setDisablePlayButton(
                                                                        true,
                                                                    );
                                                                }
                                                            }}
                                                        >
                                                            <FastRewindIcon size="small" />
                                                        </IconButton>

                                                        <IconButton
                                                            variant="contained"
                                                            color="primary"
                                                            style={{
                                                                padding:
                                                                    '0.1rem',
                                                            }}
                                                            disabled={
                                                                !disablePlayButton
                                                            }
                                                            onClick={() => {
                                                                if (
                                                                    playbackData.length >
                                                                    0
                                                                ) {
                                                                    pausePlayback();
                                                                }
                                                            }}
                                                        >
                                                            <PauseIcon size="small" />
                                                        </IconButton>
                                                        <IconButton
                                                            variant="contained"
                                                            color="primary"
                                                            disabled={
                                                                disablePlayButton
                                                            }
                                                            style={{
                                                                padding:
                                                                    '0.1rem',
                                                            }}
                                                            onClick={() => {
                                                                if (
                                                                    playbackData.length >
                                                                    0
                                                                ) {
                                                                    setShowPlaybackButton(
                                                                        false,
                                                                    );
                                                                    setDisablePlayButton(
                                                                        true,
                                                                    );
                                                                    startPlayback();
                                                                }
                                                            }}
                                                        >
                                                            <PlayArrowIcon size="small" />
                                                        </IconButton>
                                                        <IconButton
                                                            variant="contained"
                                                            color="primary"
                                                            style={{
                                                                padding:
                                                                    '0.1rem',
                                                            }}
                                                            onClick={() => {
                                                                if (
                                                                    playbackData.length >
                                                                    0
                                                                ) {
                                                                    clearInterval(
                                                                        playbackInterval,
                                                                    );
                                                                    stopPlayback();
                                                                }
                                                            }}
                                                        >
                                                            <StopIcon size="small" />
                                                        </IconButton>

                                                        <IconButton
                                                            variant="contained"
                                                            color="primary"
                                                            style={{
                                                                padding:
                                                                    '0.1rem',
                                                            }}
                                                            onClick={() => {
                                                                if (
                                                                    speedFactor <
                                                                    16
                                                                ) {
                                                                    pausePlayback();
                                                                    setSpeedFactor(
                                                                        (
                                                                            prev,
                                                                        ) => {
                                                                            if (
                                                                                prev ==
                                                                                1
                                                                            ) {
                                                                                return 2;
                                                                            } else if (
                                                                                prev ==
                                                                                2
                                                                            ) {
                                                                                return 4;
                                                                            } else if (
                                                                                prev ==
                                                                                4
                                                                            ) {
                                                                                return 8;
                                                                            } else if (
                                                                                prev ==
                                                                                8
                                                                            ) {
                                                                                return 16;
                                                                            }
                                                                        },
                                                                    );

                                                                    setDisablePlayButton(
                                                                        true,
                                                                    );
                                                                }
                                                            }}
                                                        >
                                                            <FastForwardIcon size="small" />
                                                        </IconButton>
                                                    </Box>

                                                    <Slider
                                                        value={sliderValue}
                                                        onChange={
                                                            handleSliderMove
                                                        }
                                                        aria-labelledby="continuous-slider"
                                                    />
                                                </Box>
                                            </>
                                        )}
                                    </Box>
                                </Box>
                                <Box
                                    boxShadow={showCloseButton ? 15 : 0}
                                    style={
                                        showCloseButton
                                            ? {
                                                  minHeight: '56vh',
                                                  maxHeight: '56vh',
                                                  overflowY: 'auto',
                                                  //   backgroundColor: '#f6f7f9',
                                                  marginTop: 12,
                                                  borderRadius: 16,
                                                  //   boxShadow:
                                                  //       '0 2px 3px rgba(255,0,0,0.3)',
                                              }
                                            : {
                                                  minHeight: '56vh',
                                                  maxHeight: '56vh',
                                                  overflowY: 'auto',
                                                  //   backgroundColor: '#f6f7f9',
                                                  marginTop: 12,
                                                  borderRadius: 16,
                                                  //   boxShadow: '5px 10px black',
                                                  //   border: '1px solid black',
                                              }
                                    }
                                >
                                    {showCloseButton && (
                                        <Box
                                            style={{
                                                position: 'absolute',
                                                left: '0px',
                                                // display: 'flex',
                                                // justifyContent: 'flex-end',
                                            }}
                                        >
                                            <IconButton
                                                className={'inputbutton'}
                                                onClick={(e) => {
                                                    setShowCloseButton(false);
                                                    setSelectedStartStoppagePoint(
                                                        null,
                                                    );
                                                    setSelectedEndStoppagePoint(
                                                        null,
                                                    );
                                                }}
                                                style={{
                                                    padding: '0px',
                                                    color: 'red',
                                                    // display: 'flex',
                                                    // justifyContent: 'flex-end',
                                                }}
                                            >
                                                <CancelRoundedIcon
                                                    style={{
                                                        width: 30,
                                                        height: 30,
                                                    }}
                                                    size="medium"
                                                />
                                            </IconButton>
                                        </Box>
                                    )}

                                    {defaultTripData?.locationPoints?.length >
                                    0 ? (
                                        <>
                                            <Timeline
                                                align="left"
                                                style={{
                                                    padding: 0,
                                                }}
                                            >
                                                {defaultTripData?.locationPoints
                                                    ?.length > 0 ? (
                                                    <TimelineItem>
                                                        <TimelineOppositeContent
                                                            style={{
                                                                flex: 0,
                                                                padding: '6px',
                                                            }}
                                                        ></TimelineOppositeContent>
                                                        <TimelineSeparator>
                                                            <TimelineDot
                                                                style={
                                                                    defaultTripData
                                                                        ?.locationPoints[0]
                                                                        ?.deviceTimeEpoch ==
                                                                        selectedStartStoppagePoint ||
                                                                    defaultTripData
                                                                        ?.locationPoints[0]
                                                                        ?.deviceTimeEpoch ==
                                                                        selectedEndStoppagePoint
                                                                        ? {
                                                                              backgroundColor:
                                                                                  '#1bb55c',
                                                                              border: '3px solid #000',
                                                                              width: 20,
                                                                              height: 20,
                                                                              margin: 0,
                                                                          }
                                                                        : {
                                                                              backgroundColor:
                                                                                  '#1bb55c',
                                                                              border: '1px solid #fff',
                                                                              width: 20,
                                                                              height: 20,
                                                                              margin: 0,
                                                                          }
                                                                }
                                                                onClick={() => {
                                                                    console.log(
                                                                        'eeeee',
                                                                        defaultTripData
                                                                            ?.locationPoints[0]
                                                                            ?.deviceTimeEpoch,
                                                                    );
                                                                    stepperSelectorHandler(
                                                                        defaultTripData
                                                                            ?.locationPoints[0],
                                                                    );
                                                                }}
                                                            />
                                                            <TimelineConnector
                                                                style={{
                                                                    backgroundColor:
                                                                        '#1bb55c',
                                                                }}
                                                            />
                                                        </TimelineSeparator>
                                                        <TimelineContent
                                                            style={{
                                                                padding: 0,
                                                                margin: '0 0 0 8px',
                                                            }}
                                                        >
                                                            {originalEventsData?.length >
                                                                0 &&
                                                                eventsData?.length >
                                                                    0 &&
                                                                originalEventsData
                                                                    ?.filter(
                                                                        (
                                                                            event,
                                                                        ) => {
                                                                            return (
                                                                                event?.time <=
                                                                                defaultTripData
                                                                                    ?.locationPoints[0]
                                                                                    ?.deviceTimeEpoch
                                                                            );
                                                                        },
                                                                    )
                                                                    ?.map(
                                                                        (
                                                                            event,
                                                                        ) => {
                                                                            console.log(
                                                                                'stopppp first',
                                                                                event,
                                                                                event?.time,
                                                                            );
                                                                            return (
                                                                                <EventCard
                                                                                    {...{
                                                                                        event,
                                                                                    }}
                                                                                />
                                                                            );
                                                                        },
                                                                    )}
                                                            <LocationPointCard
                                                                title={
                                                                    'Started'
                                                                }
                                                                dates={moment(
                                                                    parseInt(
                                                                        defaultTripData
                                                                            ?.locationPoints[0]
                                                                            ?.deviceTimeEpoch,
                                                                    ),
                                                                ).format(
                                                                    // 'hh:mm A',
                                                                    'D MMM YYYY hh:mm A',
                                                                )}
                                                                address={
                                                                    startEventDescription?.length >
                                                                    0
                                                                        ? startEventDescription
                                                                        : startAddress ??
                                                                          ''
                                                                }
                                                            />
                                                            {stoppageReportData?.length >
                                                            0 ? (
                                                                <>
                                                                    {eventsData
                                                                        ?.filter(
                                                                            (
                                                                                event,
                                                                            ) => {
                                                                                if (
                                                                                    defaultTripData
                                                                                        ?.locationPoints[0]
                                                                                        ?.deviceTimeEpoch >=
                                                                                        parseInt(
                                                                                            stoppageReportData?.[0]
                                                                                                ?.startTime ??
                                                                                                stoppageReportData?.[0]
                                                                                                    ?.time,
                                                                                        ) &&
                                                                                    defaultTripData
                                                                                        ?.locationPoints?.[0]
                                                                                        ?.deviceTimeEpoch <
                                                                                        parseInt(
                                                                                            stoppageReportData?.[0]
                                                                                                ?.endTime ??
                                                                                                stoppageReportData?.[0]
                                                                                                    ?.time,
                                                                                        )
                                                                                ) {
                                                                                    return (
                                                                                        event?.time >=
                                                                                            defaultTripData
                                                                                                ?.locationPoints[0]
                                                                                                ?.deviceTimeEpoch &&
                                                                                        event?.time <=
                                                                                            parseInt(
                                                                                                stoppageReportData?.[0]
                                                                                                    ?.endTime ??
                                                                                                    stoppageReportData?.[0]
                                                                                                        ?.time,
                                                                                            )
                                                                                    );
                                                                                } else {
                                                                                    return (
                                                                                        event?.time >=
                                                                                            defaultTripData
                                                                                                ?.locationPoints[0]
                                                                                                ?.deviceTimeEpoch &&
                                                                                        stoppageReportData[0] &&
                                                                                        event?.time <
                                                                                            parseInt(
                                                                                                stoppageReportData?.[0]
                                                                                                    ?.startTime ??
                                                                                                    stoppageReportData?.[0]
                                                                                                        ?.time,
                                                                                            )
                                                                                    );
                                                                                }
                                                                            },
                                                                        )
                                                                        ?.map(
                                                                            (
                                                                                event,
                                                                            ) => {
                                                                                // console.log(
                                                                                //     'ggggggg',
                                                                                //     event,
                                                                                //     event?.time,
                                                                                // );
                                                                                return (
                                                                                    <EventCard
                                                                                        {...{
                                                                                            event,
                                                                                        }}
                                                                                    />
                                                                                );
                                                                            },
                                                                        )}
                                                                </>
                                                            ) : (
                                                                <></>
                                                            )}
                                                        </TimelineContent>
                                                    </TimelineItem>
                                                ) : (
                                                    <></>
                                                )}

                                                <>
                                                    {stoppageReportData &&
                                                    stoppageReportData?.length >
                                                        0 ? (
                                                        stoppageReportData?.map(
                                                            (
                                                                idlePoint,
                                                                index,
                                                            ) => {
                                                                return (
                                                                    <TimelineItem
                                                                        key={
                                                                            index
                                                                        }
                                                                        style={{
                                                                            marginTop:
                                                                                '2px',

                                                                            marginBottom:
                                                                                '2px',
                                                                        }}
                                                                    >
                                                                        <TimelineOppositeContent
                                                                            style={{
                                                                                flex: 0,
                                                                                padding:
                                                                                    '6px',
                                                                            }}
                                                                        ></TimelineOppositeContent>
                                                                        <TimelineSeparator>
                                                                            <TimelineDot
                                                                                style={{
                                                                                    width: 20,
                                                                                    height: 20,
                                                                                    margin: 0,
                                                                                    backgroundColor:
                                                                                        idlePoint?.type ===
                                                                                        'ORDER_PICKED'
                                                                                            ? '#43419e'
                                                                                            : idlePoint?.type ===
                                                                                              'ORDER_DELIVERED'
                                                                                            ? '#05c792'
                                                                                            : '#fb8a06',
                                                                                    ...(idlePoint?.time ===
                                                                                        selectedStartStoppagePoint ||
                                                                                    idlePoint?.time ===
                                                                                        selectedEndStoppagePoint
                                                                                        ? {
                                                                                              border: '3px solid #000',
                                                                                          }
                                                                                        : {
                                                                                              border: '1px solid #fff',
                                                                                          }),
                                                                                }}
                                                                                onClick={() => {
                                                                                    console.log(
                                                                                        'eeeee',
                                                                                        idlePoint?.time,
                                                                                    );
                                                                                    stepperSelectorHandler(
                                                                                        idlePoint,
                                                                                    );
                                                                                }}
                                                                            />
                                                                            <TimelineConnector
                                                                                style={{
                                                                                    backgroundColor:
                                                                                        '#fb8a06',
                                                                                }}
                                                                            />
                                                                        </TimelineSeparator>
                                                                        <TimelineContent
                                                                            style={{
                                                                                padding: 0,
                                                                                margin: '0 0 0 8px',
                                                                            }}
                                                                        >
                                                                            {[
                                                                                'ORDER_PICKED',
                                                                                'ORDER_DELIVERED',
                                                                            ]?.includes(
                                                                                idlePoint?.type,
                                                                            ) ? (
                                                                                <LocationPointCard
                                                                                    title={
                                                                                        idlePoint?.title
                                                                                    }
                                                                                    dates={`${moment(
                                                                                        parseInt(
                                                                                            idlePoint?.time,
                                                                                        ),
                                                                                    ).format(
                                                                                        'hh:mm A',
                                                                                        // 'D MMM YYYY hh:mm A',
                                                                                    )}`}
                                                                                    address={
                                                                                        idlePoint
                                                                                            ?.description
                                                                                            ?.length >
                                                                                            0 &&
                                                                                        isNaN(
                                                                                            Number(
                                                                                                idlePoint?.description,
                                                                                            ),
                                                                                        )
                                                                                            ? idlePoint?.description
                                                                                                  .split(
                                                                                                      '@!@',
                                                                                                  )
                                                                                                  .join(
                                                                                                      ',',
                                                                                                  )
                                                                                            : 'No Location Available'
                                                                                    }
                                                                                />
                                                                            ) : (
                                                                                <LocationPointCard
                                                                                    title={
                                                                                        idlePoint?.kmReading &&
                                                                                        `${parseFloat(
                                                                                            idlePoint?.kmReading,
                                                                                        ).toFixed(
                                                                                            2,
                                                                                        )} Km`
                                                                                    }
                                                                                    dates={`${moment(
                                                                                        parseInt(
                                                                                            idlePoint?.startTime ??
                                                                                                idlePoint?.time,
                                                                                        ),
                                                                                    ).format(
                                                                                        'hh:mm A',
                                                                                        // 'D MMM YYYY hh:mm A',
                                                                                    )} - ${moment(
                                                                                        parseInt(
                                                                                            idlePoint?.endTime ??
                                                                                                idlePoint?.time,
                                                                                        ),
                                                                                    ).format(
                                                                                        'hh:mm A',
                                                                                        // 'D MMM YYYY hh:mm A',
                                                                                    )}`}
                                                                                    address={
                                                                                        idlePoint
                                                                                            ?.description
                                                                                            ?.length >
                                                                                            0 &&
                                                                                        isNaN(
                                                                                            Number(
                                                                                                idlePoint?.description,
                                                                                            ),
                                                                                        )
                                                                                            ? idlePoint?.description
                                                                                                  .split(
                                                                                                      '@!@',
                                                                                                  )
                                                                                                  .join(
                                                                                                      ',',
                                                                                                  )
                                                                                            : 'No Location Available'
                                                                                    }
                                                                                    timeDiff={getTimeAsDuration(
                                                                                        getTimeDifference(
                                                                                            parseInt(
                                                                                                idlePoint?.endTime ??
                                                                                                    idlePoint?.time,
                                                                                            ),
                                                                                            parseInt(
                                                                                                idlePoint?.startTime ??
                                                                                                    idlePoint?.time,
                                                                                            ),
                                                                                        ),
                                                                                        'small',
                                                                                    )}
                                                                                />
                                                                            )}

                                                                            {eventsData
                                                                                ?.filter(
                                                                                    (
                                                                                        event,
                                                                                    ) => {
                                                                                        if (
                                                                                            index ==
                                                                                            0
                                                                                        ) {
                                                                                            if (
                                                                                                defaultTripData
                                                                                                    ?.locationPoints[0]
                                                                                                    ?.deviceTimeEpoch >=
                                                                                                    parseInt(
                                                                                                        stoppageReportData[0]
                                                                                                            ?.startTime ??
                                                                                                            stoppageReportData[0]
                                                                                                                ?.time,
                                                                                                    ) &&
                                                                                                defaultTripData
                                                                                                    ?.locationPoints[0]
                                                                                                    ?.deviceTimeEpoch <
                                                                                                    parseInt(
                                                                                                        stoppageReportData[0]
                                                                                                            ?.endTime ??
                                                                                                            stoppageReportData[0]
                                                                                                                ?.time,
                                                                                                    )
                                                                                            ) {
                                                                                                return (
                                                                                                    event?.time >=
                                                                                                        parseInt(
                                                                                                            idlePoint[1],
                                                                                                        ) &&
                                                                                                    stoppageReportData[
                                                                                                        index +
                                                                                                            1
                                                                                                    ] &&
                                                                                                    event?.time <=
                                                                                                        parseInt(
                                                                                                            stoppageReportData[
                                                                                                                index +
                                                                                                                    1
                                                                                                            ]
                                                                                                                ?.startTime ??
                                                                                                                stoppageReportData[
                                                                                                                    index +
                                                                                                                        1
                                                                                                                ]
                                                                                                                    ?.time,
                                                                                                        )
                                                                                                );
                                                                                            }
                                                                                        }
                                                                                        return (
                                                                                            event?.time >=
                                                                                                parseInt(
                                                                                                    idlePoint?.startTime ??
                                                                                                        idlePoint?.time,
                                                                                                ) &&
                                                                                            stoppageReportData[
                                                                                                index +
                                                                                                    1
                                                                                            ] &&
                                                                                            event?.time <=
                                                                                                parseInt(
                                                                                                    stoppageReportData[
                                                                                                        index +
                                                                                                            1
                                                                                                    ]
                                                                                                        ?.startTime ??
                                                                                                        stoppageReportData[
                                                                                                            index +
                                                                                                                1
                                                                                                        ]
                                                                                                            ?.time,
                                                                                                )
                                                                                        );
                                                                                    },
                                                                                )
                                                                                ?.map(
                                                                                    (
                                                                                        event,
                                                                                    ) => {
                                                                                        console.log(
                                                                                            'stopppp',
                                                                                            event,
                                                                                            event?.time,
                                                                                        );
                                                                                        return (
                                                                                            <EventCard
                                                                                                {...{
                                                                                                    event,
                                                                                                }}
                                                                                            />
                                                                                        );
                                                                                    },
                                                                                )}
                                                                        </TimelineContent>
                                                                    </TimelineItem>
                                                                );
                                                            },
                                                        )
                                                    ) : (
                                                        <></>
                                                    )}
                                                </>

                                                {defaultTripData?.locationPoints
                                                    ?.length ? (
                                                    <TimelineItem
                                                        style={{
                                                            marginTop: 8,
                                                        }}
                                                    >
                                                        <TimelineOppositeContent
                                                            style={{
                                                                flex: 0,
                                                                padding: '6px',
                                                            }}
                                                        ></TimelineOppositeContent>
                                                        <TimelineSeparator>
                                                            <TimelineDot
                                                                style={
                                                                    defaultTripData
                                                                        ?.locationPoints?.[
                                                                        defaultTripData
                                                                            ?.locationPoints
                                                                            ?.length -
                                                                            1
                                                                    ]
                                                                        ?.deviceTimeEpoch ==
                                                                        selectedStartStoppagePoint ||
                                                                    defaultTripData
                                                                        ?.locationPoints?.[
                                                                        defaultTripData
                                                                            ?.locationPoints
                                                                            ?.length -
                                                                            1
                                                                    ]
                                                                        ?.deviceTimeEpoch ==
                                                                        selectedEndStoppagePoint
                                                                        ? {
                                                                              backgroundColor:
                                                                                  '#e74c3c',
                                                                              border: '2px solid #000',
                                                                              width: 20,
                                                                              height: 20,
                                                                              margin: 0,
                                                                          }
                                                                        : {
                                                                              backgroundColor:
                                                                                  '#e74c3c',
                                                                              border: '1px solid #fff',
                                                                              width: 20,
                                                                              height: 20,
                                                                              margin: 0,
                                                                          }
                                                                }
                                                                onClick={() => {
                                                                    stepperSelectorHandler(
                                                                        defaultTripData
                                                                            ?.locationPoints[
                                                                            defaultTripData
                                                                                ?.locationPoints
                                                                                ?.length -
                                                                                1
                                                                        ],
                                                                    );
                                                                }}
                                                            />
                                                        </TimelineSeparator>
                                                        <TimelineContent
                                                            style={{
                                                                padding: 0,
                                                                margin: '0 0 0 8px',
                                                            }}
                                                        >
                                                            {originalEventsData
                                                                ?.filter(
                                                                    (event) => {
                                                                        if (
                                                                            eventsData?.length >
                                                                            0
                                                                        ) {
                                                                            return (
                                                                                (stoppageReportData?.length >
                                                                                0
                                                                                    ? event?.time >
                                                                                      parseInt(
                                                                                          stoppageReportData[
                                                                                              stoppageReportData?.length -
                                                                                                  1
                                                                                          ]
                                                                                              ?.startTime ??
                                                                                              stoppageReportData[
                                                                                                  stoppageReportData?.length -
                                                                                                      1
                                                                                              ]
                                                                                                  ?.time,
                                                                                      )
                                                                                    : event?.time >
                                                                                      defaultTripData
                                                                                          ?.locationPoints[0]
                                                                                          ?.deviceTimeEpoch) &&
                                                                                event?.time <=
                                                                                    defaultTripData
                                                                                        ?.locationPoints[
                                                                                        defaultTripData
                                                                                            ?.locationPoints
                                                                                            ?.length -
                                                                                            1
                                                                                    ]
                                                                                        ?.deviceTimeEpoch
                                                                            );
                                                                        } else {
                                                                            return (
                                                                                event?.time >
                                                                                    defaultTripData
                                                                                        ?.locationPoints[0]
                                                                                        ?.deviceTimeEpoch &&
                                                                                event?.time <
                                                                                    defaultTripData
                                                                                        ?.locationPoints[
                                                                                        defaultTripData
                                                                                            ?.locationPoints
                                                                                            ?.length -
                                                                                            1
                                                                                    ]
                                                                                        ?.deviceTimeEpoch
                                                                            );
                                                                        }
                                                                    },
                                                                )
                                                                ?.map(
                                                                    (event) => {
                                                                        console.log(
                                                                            'stopppp before last',
                                                                            event,
                                                                            event?.time,
                                                                        );
                                                                        return (
                                                                            <EventCard
                                                                                {...{
                                                                                    event,
                                                                                }}
                                                                            />
                                                                        );
                                                                    },
                                                                )}
                                                            <Box
                                                                elevation={2}
                                                                style={{
                                                                    background:
                                                                        '#f6f7f9',
                                                                    padding: 6,
                                                                    borderRadius: 8,
                                                                }}
                                                            >
                                                                <Box
                                                                    style={{
                                                                        display:
                                                                            'flex',
                                                                        flexDirection:
                                                                            'row',
                                                                        justifyContent:
                                                                            'space-between',
                                                                        marginBottom: 4,
                                                                    }}
                                                                >
                                                                    <Typography
                                                                        style={{
                                                                            fontSize: 12,
                                                                            fontWeight: 700,
                                                                        }}
                                                                    >
                                                                        Stopped
                                                                    </Typography>
                                                                    <Typography
                                                                        style={{
                                                                            fontSize: 12,
                                                                            fontWeight: 700,
                                                                            // margin: '4px 0',
                                                                            // opacity: 0.8,
                                                                        }}
                                                                    >
                                                                        {moment(
                                                                            parseInt(
                                                                                defaultTripData
                                                                                    ?.locationPoints[
                                                                                    defaultTripData
                                                                                        ?.locationPoints
                                                                                        ?.length -
                                                                                        1
                                                                                ]
                                                                                    ?.deviceTimeEpoch,
                                                                            ),
                                                                        ).format(
                                                                            // 'hh:mm A',
                                                                            'D MMM YYYY hh:mm A',
                                                                        )}
                                                                    </Typography>
                                                                </Box>
                                                                <Typography
                                                                    style={{
                                                                        fontSize: 15,
                                                                        fontWeight: 600,
                                                                    }}
                                                                >
                                                                    {endAddress ??
                                                                        ''}
                                                                </Typography>
                                                            </Box>
                                                            {originalEventsData
                                                                ?.filter(
                                                                    (event) => {
                                                                        if (
                                                                            eventsData?.length >
                                                                            0
                                                                        ) {
                                                                            return (
                                                                                event?.time >=
                                                                                defaultTripData
                                                                                    ?.locationPoints[
                                                                                    defaultTripData
                                                                                        ?.locationPoints
                                                                                        ?.length -
                                                                                        1
                                                                                ]
                                                                                    ?.deviceTimeEpoch
                                                                            );
                                                                        } else {
                                                                            return (
                                                                                event?.time >=
                                                                                defaultTripData
                                                                                    ?.locationPoints[
                                                                                    defaultTripData
                                                                                        ?.locationPoints
                                                                                        ?.length -
                                                                                        1
                                                                                ]
                                                                                    ?.deviceTimeEpoch
                                                                            );
                                                                        }
                                                                    },
                                                                )
                                                                ?.map(
                                                                    (event) => {
                                                                        console.log(
                                                                            'stopppp',
                                                                            event,
                                                                            event?.time,
                                                                        );
                                                                        return (
                                                                            <EventCard
                                                                                {...{
                                                                                    event,
                                                                                }}
                                                                            />
                                                                        );
                                                                    },
                                                                )}
                                                        </TimelineContent>
                                                    </TimelineItem>
                                                ) : (
                                                    <></>
                                                )}
                                            </Timeline>
                                        </>
                                    ) : (
                                        <>
                                            {originalEventsData?.length > 0 && (
                                                <Timeline
                                                    align="left"
                                                    style={{
                                                        padding: 0,
                                                    }}
                                                >
                                                    {originalEventsData?.map(
                                                        (event) => {
                                                            // console.log(
                                                            //     'ggggggg',
                                                            //     event,
                                                            //     event?.time,
                                                            // );
                                                            return (
                                                                <Box
                                                                    elevation={
                                                                        2
                                                                    }
                                                                    style={{
                                                                        background:
                                                                            eventStyleFlag(
                                                                                event,
                                                                            )
                                                                                ? '#fef7f6'
                                                                                : '#f2fdf6',
                                                                        padding: 6,
                                                                        marginTop: 2,
                                                                        marginBottom: 2,
                                                                        borderRadius: 8,
                                                                    }}
                                                                >
                                                                    <Box
                                                                        style={{
                                                                            display:
                                                                                'flex',
                                                                            flexDirection:
                                                                                'row',
                                                                            justifyContent:
                                                                                'space-between',
                                                                        }}
                                                                    >
                                                                        <Typography
                                                                            style={{
                                                                                fontSize: 15,
                                                                                fontWeight: 600,
                                                                                color: eventStyleFlag(
                                                                                    event,
                                                                                )
                                                                                    ? '#e74c3c'
                                                                                    : '#1bb55c',
                                                                            }}
                                                                        >
                                                                            {
                                                                                event?.description
                                                                            }
                                                                        </Typography>
                                                                        <Typography
                                                                            style={{
                                                                                fontSize: 12,
                                                                                fontWeight: 700,
                                                                                // margin: '4px 0',
                                                                                // opacity: 0.8,
                                                                            }}
                                                                        >
                                                                            {moment(
                                                                                parseInt(
                                                                                    event?.time,
                                                                                ),
                                                                            ).format(
                                                                                'hh:mm A',
                                                                                // 'D MMM YYYY hh:mm A',
                                                                            )}{' '}
                                                                        </Typography>
                                                                    </Box>
                                                                </Box>
                                                            );
                                                        },
                                                    )}
                                                </Timeline>
                                            )}
                                        </>
                                    )}
                                </Box>
                            </Grid>
                            <Grid
                                item
                                md={8}
                                xs={12}
                                style={showCloseButton ? { opacity: 0.4 } : {}}
                            >
                                {true && (
                                    <>
                                        {!!playbackData &&
                                            !!playbackData?.length &&
                                            !showPlaybackButton && (
                                                <Box
                                                    style={{
                                                        position: 'absolute',
                                                        left: '65%',
                                                        zIndex: '9999999999',
                                                        padding: '2px',
                                                    }}
                                                >
                                                    <Typography
                                                        style={{
                                                            fontWeight: 800,
                                                            backgroundColor:
                                                                'white',
                                                            padding: '2px 4px',
                                                        }}
                                                    >
                                                        {moment(
                                                            playbackData[
                                                                indexPosition
                                                            ]?.time,
                                                        ).format('hh:mm A')}
                                                    </Typography>
                                                </Box>
                                            )}
                                        <Map
                                            center={
                                                playbackData.length > 0
                                                    ? [
                                                          playbackData[0]?.lat,
                                                          playbackData[0]?.lng,
                                                      ]
                                                    : [13.034259, 80.24893]
                                            }
                                            zoom={20}
                                            scrollWheelZoom={true}
                                        >
                                            {!!playbackPosition &&
                                                !!playbackPosition?.lat &&
                                                !!playbackPosition?.lng && (
                                                    <Marker
                                                        // if position changes, marker will drift its way to new position
                                                        position={
                                                            playbackPosition?.lat &&
                                                            playbackPosition?.lng
                                                                ? {
                                                                      lat: playbackPosition?.lat,
                                                                      lng: playbackPosition?.lng,
                                                                  }
                                                                : {
                                                                      //   lat: 13.034259,
                                                                      //   lng: 80.24893,
                                                                  }
                                                        }
                                                        icon={createCustomMarkerHtml(
                                                            playbackPosition &&
                                                                playbackPosition?.angle
                                                                ? playbackPosition?.angle
                                                                : '0',
                                                            'ON',
                                                            selectedStaff?.vehicleNumber,
                                                            true,
                                                        )}
                                                    ></Marker>
                                                )}

                                            {!!playbackPolylineData &&
                                                !!playbackPolylineData.length >
                                                    0 && (
                                                    <Polyline
                                                        // key={index}
                                                        positions={
                                                            playbackPolylineData
                                                                ? playbackPolylineData
                                                                : []
                                                        }
                                                        pathOptions={{
                                                            color: 'red',
                                                            // weight: 2,
                                                        }}
                                                    />
                                                )}
                                            {!!latLongPoints &&
                                                !!latLongPoints.length && (
                                                    <>
                                                        <Polyline
                                                            // key={index}
                                                            positions={
                                                                latLongPoints
                                                                    ? latLongPoints
                                                                    : []
                                                            }
                                                            pathOptions={{
                                                                color: 'black',
                                                                // weight: 1,
                                                            }}
                                                        />
                                                        {getEndPointMarker()}
                                                        {getStartPointMarker()}
                                                        {tripData
                                                            ?.idleLocationPoints
                                                            ?.length > 0 && (
                                                            <MarkerClusterGroup
                                                                onMouseOver={(
                                                                    cluster,
                                                                ) => {
                                                                    console.log(
                                                                        cluster,
                                                                    );
                                                                }}
                                                            >
                                                                {tripData?.idleLocationPoints
                                                                    ?.filter(
                                                                        (
                                                                            item,
                                                                        ) => {
                                                                            let idlePoint =
                                                                                item.split(
                                                                                    ',',
                                                                                );
                                                                            console.log(
                                                                                'idlePoint',
                                                                                idlePoint,
                                                                                tripData?.locationPoints,
                                                                            );
                                                                            return (
                                                                                parseInt(
                                                                                    idlePoint[0],
                                                                                ) >
                                                                                    tripData
                                                                                        ?.locationPoints[0]
                                                                                        ?.deviceTimeEpoch &&
                                                                                parseInt(
                                                                                    idlePoint[0],
                                                                                ) <
                                                                                    tripData
                                                                                        ?.locationPoints[
                                                                                        tripData
                                                                                            ?.locationPoints
                                                                                            ?.length -
                                                                                            1
                                                                                    ]
                                                                                        ?.deviceTimeEpoch
                                                                            );
                                                                        },
                                                                    )
                                                                    ?.map(
                                                                        (
                                                                            geo,
                                                                            index,
                                                                        ) => {
                                                                            const data =
                                                                                geo.split(
                                                                                    ',',
                                                                                );

                                                                            return getIdlePointMarker(
                                                                                data,
                                                                            );
                                                                        },
                                                                    )}
                                                                {userActivityLineItems?.length >
                                                                    0 &&
                                                                    userActivityLineItems
                                                                        ?.filter(
                                                                            (
                                                                                item,
                                                                            ) => {
                                                                                console.log(
                                                                                    'geoidlePoint',
                                                                                    item,
                                                                                );
                                                                                return (
                                                                                    parseInt(
                                                                                        item?.time,
                                                                                    ) >
                                                                                        tripData
                                                                                            ?.locationPoints?.[0]
                                                                                            ?.deviceTimeEpoch &&
                                                                                    parseInt(
                                                                                        item?.time,
                                                                                    ) <
                                                                                        tripData
                                                                                            ?.locationPoints?.[
                                                                                            tripData
                                                                                                ?.locationPoints
                                                                                                ?.length -
                                                                                                1
                                                                                        ]
                                                                                            ?.deviceTimeEpoch
                                                                                );
                                                                            },
                                                                        )
                                                                        ?.map(
                                                                            (
                                                                                geo,
                                                                            ) => {
                                                                                let point =
                                                                                    [
                                                                                        geo?.latitude,
                                                                                        geo?.longitude,
                                                                                    ];
                                                                                let popupInfo =
                                                                                    {
                                                                                        Description:
                                                                                            geo?.description ??
                                                                                            '',
                                                                                        ...geo?.properties,
                                                                                    };
                                                                                return (
                                                                                    <Marker
                                                                                        // if position changes, marker will drift its way to new position
                                                                                        position={
                                                                                            point[0] &&
                                                                                            point[1]
                                                                                                ? point
                                                                                                : {}
                                                                                        }
                                                                                        icon={
                                                                                            geo?.type ===
                                                                                            'ORDER_PICKED'
                                                                                                ? purpleMarker
                                                                                                : blueGreenMarker
                                                                                        }
                                                                                    >
                                                                                        <Popup
                                                                                            direction="bottom"
                                                                                            opacity={
                                                                                                1
                                                                                            }
                                                                                        >
                                                                                            {Object.keys(
                                                                                                popupInfo,
                                                                                            )?.map(
                                                                                                (
                                                                                                    key,
                                                                                                    index,
                                                                                                ) => {
                                                                                                    return (
                                                                                                        <Box
                                                                                                            style={{
                                                                                                                width: 200,
                                                                                                                marginTop:
                                                                                                                    index ===
                                                                                                                    0
                                                                                                                        ? 20
                                                                                                                        : 0,
                                                                                                                display:
                                                                                                                    'flex',
                                                                                                                flexDirection:
                                                                                                                    'row',
                                                                                                                alignItems:
                                                                                                                    'center',
                                                                                                                justifyContent:
                                                                                                                    'space-between',
                                                                                                            }}
                                                                                                        >
                                                                                                            <Typography
                                                                                                                gutterBottom
                                                                                                                variant="body2"
                                                                                                                component="div"
                                                                                                                style={{
                                                                                                                    fontWeight: 700,
                                                                                                                    fontSize: 14,
                                                                                                                }}
                                                                                                            >
                                                                                                                {
                                                                                                                    key
                                                                                                                }{' '}
                                                                                                                :{' '}
                                                                                                            </Typography>
                                                                                                            <Typography
                                                                                                                gutterBottom
                                                                                                                variant="body2"
                                                                                                                component="div"
                                                                                                                style={{
                                                                                                                    fontSize: 14,
                                                                                                                }}
                                                                                                            >
                                                                                                                {
                                                                                                                    popupInfo[
                                                                                                                        key
                                                                                                                    ]
                                                                                                                }
                                                                                                            </Typography>
                                                                                                        </Box>
                                                                                                    );
                                                                                                },
                                                                                            )}
                                                                                        </Popup>
                                                                                    </Marker>
                                                                                );
                                                                            },
                                                                        )}
                                                            </MarkerClusterGroup>
                                                        )}
                                                    </>
                                                )}
                                            {initialConfig ? (
                                                <SetBounds />
                                            ) : (
                                                <></>
                                            )}
                                        </Map>
                                    </>
                                )}
                            </Grid>
                        </Grid>
                    </div>
                    {!!datePickerOpen && (
                        <CustomDatePicker
                            open={datePickerOpen}
                            onClose={() => setDatePickerOpen(false)}
                            format="d MMM yyyy"
                            value={selectedDate}
                            onChange={(e) => {
                                // console.log(moment(e).valueOf());
                                setSelectedDate(
                                    moment(e).format('DD MMM YYYY'),
                                );
                            }}
                        />
                    )}

                    {!!odometerViewModalOpen && (
                        <OdometerViewModal
                            open={true}
                            onClose={() => {
                                setOdometerViewModalOpen(false);
                            }}
                            viewType={odometerViewModalType}
                            kmReading={
                                odometerViewModalType == 'START'
                                    ? startKm
                                    : endKm
                            }
                            kmS3Url={
                                odometerViewModalType == 'START'
                                    ? startKmS3ImageUrl
                                    : endKmS3ImageUrl
                            }
                        />
                    )}
                </Paper>
            </Spinner>
        </>
    );
}

const mapStateToProps = (state) => ({
    staffList: state.staff?.lineItems ?? [],
    trackingData: state.mapview?.vehicles,
    user: state.user,
    events: state.mapview?.events,
});

const mapDispatchToProps = (dispatch) => ({
    dispatchGetMapVechicleList: (payload, onSuccess, onError) =>
        dispatch(GetMapVechicleList(payload, onSuccess, onError)),

    dispatchGetTripRouteDetails: (payload, onSuccess, onError) =>
        dispatch(getTripRouteDetails(payload, onSuccess, onError)),

    dispatchGetTrackingToggleEvents: (payload, onSuccess, onError) =>
        dispatch(getTrackingToggleEvents(payload, onSuccess, onError)),

    dispatchGetAllTrackingEvents: (payload, onSuccess, onError) =>
        dispatch(getAllTrackingEvents(payload, onSuccess, onError)),

    dispatchGetSingleStaff: (payload, onSuccess, onError) =>
        dispatch(getSingleStaff(payload, onSuccess, onError)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MapDetail);
