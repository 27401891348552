import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { punchIn, punchOut } from '../../store/actions/attendanceActionCreator';

import { Button, IconButton } from '@material-ui/core';
import Modal from './Modal';
import { CustomTextField } from '../Textfield';
import { CustomDatePicker, CustomTimePicker } from '../DateTimePicker';
import { dateTimeToMilliseconds } from '../../utils/helper';
import InsertInvitationIcon from '@material-ui/icons/InsertInvitation';
import moment from 'moment';
import {
    getKycDetails,
    staffKycApproveReject,
} from '../../store/actions/staffActionCreator';

function RejectKycModal({
    open,
    onClose,
    data = {},
    staffInfo,
    dispatchGetKycDetails,
    dispatchStaffKycApproveReject,
    dispatchPostKycDetails,
    ...props
}) {
    const [isOpen, toggleModal] = useState(open);
    const [state, setState] = useState({
        rejectReason: '',
        id: data?.id ? data.id : '',
        approvalStatus: data?.approvalStatus ? data.approvalStatus : '',
    });
    const [isloading, toggleLoading] = useState(false);

    const handleClose = () => {
        // Setting form data to null before closing
        setState(null);

        toggleModal(!isOpen);
        onClose && onClose();
    };

    useEffect(() => {
        console.log('state', state);
    }, [state]);

    const actions = [
        <Button
            key={1}
            variant="contained"
            color="default"
            className={`cancelButton`}
            onClick={handleClose}
            disableElevation
        >
            {'Cancel'}
        </Button>,
        <Button
            key={2}
            variant="contained"
            color="primary"
            className={`saveButton`}
            disableElevation
            onClick={async () => {
                await toggleLoading(true);
                dispatchStaffKycApproveReject(
                    { staffId: staffInfo.id, data: { documents: [state] } },
                    () => {
                        console.log(`${state?.approvalStatus} SUCCESS`);

                        dispatchGetKycDetails(
                            staffInfo.id,
                            (resp) => {
                                console.log(`GET KYC Details SUCCESS`);
                                toggleLoading(false);
                            },
                            (resp) => {
                                toggleLoading(false);
                            },
                        );
                        handleClose();
                    },
                    () => {
                        toggleLoading(false);
                        handleClose();
                        console.log(`${state?.approvalStatus} FAILED`);
                    },
                );
            }}
        >
            {state?.approvalStatus === 'REJECTED' ? 'Reject' : 'Approve'}
        </Button>,
    ];

    const handleInputChange = (value, key) => {
        console.log(key, value);

        setState((state) => ({
            ...state,
            [key]: value,
        }));

        // setStateStatus(null); // formValidator(key, value);
    };

    return (
        <Modal
            onClose={handleClose}
            open={isOpen}
            title={state?.approvalStatus === 'REJECTED' ? 'Reject' : 'Approve'}
            actions={actions}
            loading={isloading}
        >
            {state?.approvalStatus === 'REJECTED' ? (
                <CustomTextField
                    fullWidth={true}
                    label="Enter reason"
                    value={state?.rejectReason}
                    onChange={(e) =>
                        handleInputChange(e.target.value, 'rejectReason')
                    }
                />
            ) : (
                <></>
            )}
        </Modal>
    );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
    dispatchGetKycDetails: (payload, onSuccess, onError) =>
        dispatch(getKycDetails(payload, onSuccess, onError)),

    dispatchStaffKycApproveReject: (payload, onSuccess, onError) =>
        dispatch(staffKycApproveReject(payload, onSuccess, onError)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RejectKycModal);
