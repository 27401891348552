import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
    Box,
    Button,
    MenuItem,
    Paper,
    makeStyles,
    Typography,
    Dialog,
    DialogTitle,
    IconButton,
    Slide,
    useMediaQuery,
    useTheme,
    Avatar,
    Divider,
    DialogContent,
    DialogActions,
    Tooltip,
    List,
    ListItem,
    ListItemSecondaryAction,
    Backdrop,
    ListItemText,
    TextField,
    Grid,
} from '@material-ui/core';
import EventIcon from '@material-ui/icons/Event';
import Modal from '../../../components/Modals/Modal';
import { connect, useDispatch, useSelector } from 'react-redux';
import { updateLeadStatus } from '../../../store/actions/leadActionCreator';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
    createStaff,
    fetchConvertStaffList,
    getStaffEvHistory,
    covertToStaff,
} from '../../../store/actions/staffActionCreator';
import moment from 'moment';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CloseIcon from '@material-ui/icons/Close';
import { Spinner } from '../../../components/UI';
import CircularProgress from '@material-ui/core/CircularProgress';
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';
import HeightIcon from '@material-ui/icons/Height';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import InfoIcon from '@material-ui/icons/Info';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { CustomTextField } from '../../../components/Textfield';
import { FileUploader, LightBoxImage } from '../../../components/UI';
import { MultiFileUploader } from '../../../components/UI/FileUploader/MultiFileUploader';
import { CustomDatePicker } from '../../../components/DateTimePicker';
import { getAllEVUntagReason } from '../../../store/actions/settingsActionCreator';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const useStyles = makeStyles((theme) => ({
    cardSubHeader: {
        fontSize: '22px',
        fontWeight: 700,
    },
    cardHeader: {
        fontSize: '16px',
        fontWeight: 600,
    },
    cardHeaderFail: {
        fontSize: '16px',
        fontWeight: 600,
        color: '#FB8A06',
    },
    cardSubHeaderFail: {
        fontSize: '22px',
        fontWeight: 700,
        color: '#FB8A06',
    },
    cardHeaderActive: {
        fontWeight: 700,
    },
    verticalCardContentsNoMargin: {
        margin: 0,
    },
    iconButton: {
        padding: '0px',
    },
    modalTitle: {
        fontSize: '20px',
        fontWeight: 'bold',
        color: '#14112d',
        marginLeft: '14px',
    },
    modalSubTitle: {
        fontSize: '14px',
        fontWeight: 'normal',
        color: '#14112d',
        fontWeight: 600,
        marginLeft: '14px',
    },
    dialogContent: {
        paddingLeft: 0,
        paddingRight: 0,
    },
    dialogActions: {
        padding: '16px 24px',
    },
    name: {
        fontSize: 16,
        fontWeight: 700,
        color: '#14112D',
    },
    backDropRoot: {
        position: 'absolute',
        zIndex: theme.zIndex.drawer - 1,
        opacity: 0.5,
    },
    workLocation: {
        fontWeight: 600,
        fontSize: 16,
        color: ' #43419E',
    },
    minimizeButton: {
        color: '#43419E',
        background: 'rgba(67, 65, 158, 0.2)',
        borderRadius: 8,
        padding: '6px 8px',
        fontSize: 16,
        fontWeight: 600,
        cursor: 'pointer',
    },
    covertedTitle: {
        background: '#4CAF50',
        padding: '12px 14px',
        color: '#fff',
    },
    covertingTitle: {
        background: '#303030',
        padding: '12px 14px',
        color: '#fff',
    },
    scrollPaperMini: {
        alignItems: 'end !important',
        justifyContent: 'end !important',
    },
    modal: {
        width: 400,
        marginLeft: 'auto',
        position: 'sticky !important',
        '& .MuiBackdrop-root': {
            display: 'none',
        },
    },
    editBtn: {
        background: '#FFFFFF',
        border: '1px solid #EBEBFF',
        borderRadius: 8,
    },
    failureBox: {
        padding: '6px 16px',
        background: '#FCF2E6',
        border: '1px solid #FADEBE',
        borderRadius: 6,
        marginLeft: 'auto',
    },
    failureText: {
        color: 'red',
        fontSize: 12,
        fontWeight: 600,
    },
    warningText: {
        color: '#14112D',
        fontSize: 12,
        fontWeight: 600,
    },
    bottomBorder: {
        borderBottom: '1px solid #EAEDF3 !important',
    },
    disabled: {
        backgroundColor: 'rgba(244, 67, 54, 0.50) !important',
        color: '#FFFFFF',
    },
    untagCTa: {
        backgroundColor: '#F44336',
    },
    input: {
        padding: '12px 12px !important',
    },
    dateInput: {
        padding: '12px 12px !important',
    },
}));
function StaffEvUnTagModal({
    staffInfo,
    evInfo,
    open,
    onClose,
    onUnTag,
    defaultValue = {},
    orgList,
    preferredWorkLocationsList,
    selected,
    dispatchGetUntagReason,
    evUntagReasons,
    ...props
}) {
    const dispatch = useDispatch();
    console.log('the evInfo', evInfo);
    const classes = useStyles();
    //const notifications = useSelector((store) => store.notifications || []);
    const [isOpen, toggleModal] = useState(open);
    const [loading, toggleLoading] = useState(false);
    const [reason, setReason] = useState(null);
    const [datePickerOpen, setDatePickerOpen] = useState(false);
    const [evPayload, setEvPayload] = useState({
        reasonId: '',
        remarks: '',
        evDocuments: {
            ...(evInfo?.evDetails?.untagEvDocuments ?? {}),
        },
        eventDateEpochMillis: moment().valueOf(),
    });
    const handleClose = () => {
        toggleModal(!isOpen);
        onClose && onClose();
    };
    const disableAction = () => {
        if (!!evPayload && !!evPayload?.reasonId && !!evPayload?.evDocuments) {
            let docs = evPayload?.evDocuments;
            if (
                !!docs?.evFrontImage &&
                !!docs?.evBackImage &&
                !!docs?.evLeftImage &&
                !!docs?.evRightImage &&
                !!docs?.evWithRider &&
                !!docs?.evLicense &&
                !!docs?.returnedTo
            ) {
                return false;
            }
        }
        return true;
    };
    const actions = [
        <Button
            key={1}
            variant="contained"
            color="secondary"
            size="small"
            className={`cancelButton`}
            onClick={handleClose}
            disabled={false}
            style={{
                boxShadow: 'none',
                width: '171px',
            }}
            disableElevation
        >
            Cancel
        </Button>,
        <Button
            key={2}
            variant="contained"
            color="primary"
            disabled={disableAction()}
            size="small"
            type="submit"
            onClick={() => {
                !!onUnTag && onUnTag(evPayload);
                handleClose();
            }}
            classes={{
                disabled: classes.disabled,
                root: classes.untagCTa,
            }}
            style={{
                boxShadow: 'none',
                //backgroundColor: '#F44336',
                width: '171px',
            }}
            disableElevation
        >
            Untag EV
        </Button>,
    ];
    useEffect(() => {
        dispatchGetUntagReason({});
    }, []);
    console.log('the ev payload', evPayload);
    return (
        <Dialog
            onClose={(event) => {
                handleClose();
            }}
            open={isOpen}
            disableBackdropClick={true}
            Transition={Transition}
            aria-labelledby="staff-ev-untag-dialog-slide-title"
            aria-describedby="staff-ev-untag-dialog-slide-description"
            disableEscapeKeyDown
            fullWidth={true}
            scroll={'paper'}
            maxWidth={'lg'}
        >
            <DialogTitle
                id="staff-ev-untag-dialog-slide-title"
                classes={{
                    root: classes.bottomBorder,
                }}
            >
                <Box
                    width={'100%'}
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                >
                    <>
                        <Box
                            width={'45%'}
                            display={'flex'}
                            alignItems={'center'}
                        >
                            <IconButton
                                aria-label="close"
                                disabled={false}
                                className={classes.iconButton}
                                onClick={handleClose}
                            >
                                <ArrowBackIcon style={{ color: '#000' }} />
                            </IconButton>
                            <Box>
                                <Typography
                                    className={classes.modalTitle}
                                    variant="h6"
                                >
                                    {'Untag vehicle'}
                                </Typography>
                                {!!staffInfo?.name && !!staffInfo?.name && (
                                    <Typography
                                        className={classes.modalSubTitle}
                                        variant="h6"
                                    >
                                        {`Vehicle no: ${evInfo?.evDetails?.evNumber}`}
                                    </Typography>
                                )}
                            </Box>
                        </Box>
                        <IconButton
                            aria-label="close"
                            disabled={false}
                            className={classes.iconButton}
                            onClick={handleClose}
                        >
                            <CloseIcon style={{ color: '#000' }} />
                        </IconButton>
                    </>
                </Box>
            </DialogTitle>
            <DialogContent>
                <Box width={'100%'} paddingTop={'40px'}>
                    <Grid container spacing={2} direction="row">
                        <Grid item xs={3}>
                            <CustomTextField
                                select
                                value={evPayload?.reasonId ?? ''}
                                classes={{}}
                                onChange={(e) => {
                                    setEvPayload((prev) => {
                                        return {
                                            ...prev,
                                            reasonId: e.target.value,
                                        };
                                    });
                                }}
                                variant="outlined"
                                style={{
                                    // width: '40%',
                                    marginBottom: 0,
                                }}
                                size="small"
                                label={'Reason'}
                                placeholder={'Select reason here'}
                                disabled={false}
                                InputProps={{
                                    classes: {
                                        input: classes.input,
                                    },
                                }}
                            >
                                {evUntagReasons?.length === 0 && (
                                    <MenuItem value={undefined}>
                                        No Data found
                                    </MenuItem>
                                )}
                                {evUntagReasons?.map((reason) => (
                                    <MenuItem value={reason.id}>
                                        {reason.name}
                                    </MenuItem>
                                ))}
                            </CustomTextField>
                        </Grid>
                        <Grid item xs={3}>
                            <CustomTextField
                                value={evPayload?.remarks ?? ''}
                                classes={{}}
                                onChange={(e) => {
                                    setEvPayload((prev) => {
                                        return {
                                            ...prev,
                                            remarks: e.target.value,
                                        };
                                    });
                                }}
                                variant="outlined"
                                style={{
                                    // width: '40%',
                                    marginBottom: 0,
                                }}
                                size="small"
                                label={'Remarks'}
                                placeholder={'Enter remarks here'}
                                disabled={false}
                                InputProps={{
                                    classes: {
                                        input: classes.input,
                                    },
                                }}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <Box
                                style={{
                                    width: '50%',
                                }}
                            >
                                <CustomTextField
                                    label="Return Date"
                                    InputProps={{
                                        readOnly: true,
                                        classes: {
                                            input: classes.dateInput,
                                        },
                                        endAdornment: (
                                            <IconButton
                                                style={{
                                                    padding: 0,
                                                    margin: 0,
                                                }}
                                                className={'inputbutton'}
                                                onClick={(e) =>
                                                    setDatePickerOpen(true)
                                                }
                                            >
                                                <EventIcon
                                                    size="small"
                                                    style={{
                                                        padding: 0,
                                                        margin: 0,
                                                    }}
                                                />
                                            </IconButton>
                                        ),
                                    }}
                                    onClick={(e) => setDatePickerOpen(true)}
                                    id="eventDateEpochMillis"
                                    name="eventDateEpochMillis"
                                    value={moment(
                                        evPayload?.eventDateEpochMillis,
                                    ).format('yyyy-MM-DD')}
                                    variant="outlined"
                                    style={{
                                        padding: 0,
                                        marginLeft: '8px',
                                    }}
                                    isRequired={true}
                                />
                            </Box>
                        </Grid>

                        <Grid
                            container
                            spacing={2}
                            item
                            xs={12}
                            direction="row"
                        >
                            <Grid item sm={3} xs={12}>
                                <FileUploader
                                    label={'Picture ( EV FRONT )'}
                                    labelStyles={{
                                        fontSize: '16px',
                                        fontWeight: 600,
                                    }}
                                    disabled={false}
                                    defaultValue={
                                        evPayload?.evDocuments?.evFrontImage
                                    }
                                    placeholder={'Picture ( EV FRONT )'}
                                    onFileUploaded={(e) => {
                                        setEvPayload((prev) => {
                                            return {
                                                ...prev,
                                                evDocuments: {
                                                    ...prev?.evDocuments,
                                                    evFrontImage: e,
                                                },
                                            };
                                        });
                                    }}
                                    onRemoved={(e) => {
                                        setEvPayload((prev) => {
                                            return {
                                                ...prev,
                                                evDocuments: {
                                                    ...prev?.evDocuments,
                                                    evFrontImage: null,
                                                },
                                            };
                                        });
                                    }}
                                />
                            </Grid>
                            <Grid item sm={3} xs={12}>
                                <FileUploader
                                    label={'Picture ( EV BACK )'}
                                    labelStyles={{
                                        fontSize: '16px',
                                        fontWeight: 600,
                                    }}
                                    disabled={false}
                                    defaultValue={
                                        evPayload?.evDocuments?.evBackImage
                                    }
                                    placeholder={'Picture ( EV BACK )'}
                                    onFileUploaded={(e) => {
                                        setEvPayload((prev) => {
                                            return {
                                                ...prev,
                                                evDocuments: {
                                                    ...prev?.evDocuments,
                                                    evBackImage: e,
                                                },
                                            };
                                        });
                                    }}
                                    onRemoved={(e) => {
                                        setEvPayload((prev) => {
                                            return {
                                                ...prev,
                                                evDocuments: {
                                                    ...prev?.evDocuments,
                                                    evBackImage: null,
                                                },
                                            };
                                        });
                                    }}
                                />
                            </Grid>
                            <Grid item sm={3} xs={12}>
                                <FileUploader
                                    label={'Picture ( EV LEFT )'}
                                    labelStyles={{
                                        fontSize: '16px',
                                        fontWeight: 600,
                                    }}
                                    disabled={false}
                                    defaultValue={
                                        evPayload?.evDocuments?.evLeftImage
                                    }
                                    placeholder={'Picture ( EV LEFT )'}
                                    onFileUploaded={(e) => {
                                        setEvPayload((prev) => {
                                            return {
                                                ...prev,
                                                evDocuments: {
                                                    ...prev?.evDocuments,
                                                    evLeftImage: e,
                                                },
                                            };
                                        });
                                    }}
                                    onRemoved={(e) => {
                                        setEvPayload((prev) => {
                                            return {
                                                ...prev,
                                                evDocuments: {
                                                    ...prev?.evDocuments,
                                                    evLeftImage: null,
                                                },
                                            };
                                        });
                                    }}
                                />
                            </Grid>
                            <Grid item sm={3} xs={12}>
                                <FileUploader
                                    label={'Picture ( EV RIGHT )'}
                                    labelStyles={{
                                        fontSize: '16px',
                                        fontWeight: 600,
                                    }}
                                    disabled={false}
                                    defaultValue={
                                        evPayload?.evDocuments?.evRightImage
                                    }
                                    placeholder={'Picture ( EV RIGHT )'}
                                    onFileUploaded={(e) => {
                                        setEvPayload((prev) => {
                                            return {
                                                ...prev,
                                                evDocuments: {
                                                    ...prev?.evDocuments,
                                                    evRightImage: e,
                                                },
                                            };
                                        });
                                    }}
                                    onRemoved={(e) => {
                                        setEvPayload((prev) => {
                                            return {
                                                ...prev,
                                                evDocuments: {
                                                    ...prev?.evDocuments,
                                                    evRightImage: null,
                                                },
                                            };
                                        });
                                    }}
                                />
                            </Grid>
                            <Grid item sm={3} xs={12}>
                                <FileUploader
                                    label={'Picture ( Rider with EV )'}
                                    labelStyles={{
                                        fontSize: '16px',
                                        fontWeight: 600,
                                    }}
                                    disabled={false}
                                    defaultValue={
                                        evPayload?.evDocuments?.evWithRider
                                    }
                                    placeholder={'Picture ( Rider with EV )'}
                                    onFileUploaded={(e) => {
                                        setEvPayload((prev) => {
                                            return {
                                                ...prev,
                                                evDocuments: {
                                                    ...prev?.evDocuments,
                                                    evWithRider: e,
                                                },
                                            };
                                        });
                                    }}
                                    onRemoved={(e) => {
                                        setEvPayload((prev) => {
                                            return {
                                                ...prev,
                                                evDocuments: {
                                                    ...prev?.evDocuments,
                                                    evWithRider: null,
                                                },
                                            };
                                        });
                                    }}
                                />
                            </Grid>
                            {/* <Grid item sm={3} xs={12}>
                                <FileUploader
                                    label={'EV Video ( 360)'}
                                    labelStyles={{
                                        fontSize: '16px',
                                        fontWeight: 600,
                                    }}
                                    disabled={false}
                                    defaultValue={
                                        evPayload?.evDocuments?.evVideo
                                    }
                                    placeholder={'EV Video ( 360)'}
                                    onFileUploaded={(e) => {
                                        setEvPayload((prev) => {
                                            return {
                                                ...prev,
                                                evDocuments: {
                                                    ...prev?.evDocuments,
                                                    evVideo: e,
                                                },
                                            };
                                        });
                                    }}
                                    onRemoved={(e) => {
                                        setEvPayload((prev) => {
                                            return {
                                                ...prev,
                                                evDocuments: {
                                                    ...prev?.evDocuments,
                                                    evVideo: null,
                                                },
                                            };
                                        });
                                    }}
                                />
                            </Grid> */}
                            <Grid item sm={3} xs={12}>
                                <FileUploader
                                    label={'EV (License plate)'}
                                    labelStyles={{
                                        fontSize: '16px',
                                        fontWeight: 600,
                                    }}
                                    disabled={false}
                                    defaultValue={
                                        evPayload?.evDocuments?.evLicense
                                    }
                                    placeholder={'EV (License plate)'}
                                    onFileUploaded={(e) => {
                                        setEvPayload((prev) => {
                                            return {
                                                ...prev,
                                                evDocuments: {
                                                    ...prev?.evDocuments,
                                                    evLicense: e,
                                                },
                                            };
                                        });
                                    }}
                                    onRemoved={(e) => {
                                        setEvPayload((prev) => {
                                            return {
                                                ...prev,
                                                evDocuments: {
                                                    ...prev?.evDocuments,
                                                    evLicense: null,
                                                },
                                            };
                                        });
                                    }}
                                />
                            </Grid>
                            <Grid item sm={3} xs={12}>
                                <FileUploader
                                    label={'EV (Chassis Number)'}
                                    labelStyles={{
                                        fontSize: '16px',
                                        fontWeight: 600,
                                    }}
                                    disabled={false}
                                    defaultValue={
                                        evPayload?.evDocuments?.evChassis
                                    }
                                    placeholder={'EV (Chassis Number)'}
                                    onFileUploaded={(e) => {
                                        setEvPayload((prev) => {
                                            return {
                                                ...prev,
                                                evDocuments: {
                                                    ...prev?.evDocuments,
                                                    evChassis: e,
                                                },
                                            };
                                        });
                                    }}
                                    onRemoved={(e) => {
                                        setEvPayload((prev) => {
                                            return {
                                                ...prev,
                                                evDocuments: {
                                                    ...prev?.evDocuments,
                                                    evChassis: null,
                                                },
                                            };
                                        });
                                    }}
                                />
                            </Grid>

                            <Grid item sm={3} xs={12}>
                                <FileUploader
                                    label={'EV (Returned to)'}
                                    labelStyles={{
                                        fontSize: '16px',
                                        fontWeight: 600,
                                    }}
                                    disabled={false}
                                    defaultValue={
                                        evPayload?.evDocuments?.returnedTo
                                    }
                                    placeholder={'EV (Returned to)'}
                                    onFileUploaded={(e) => {
                                        setEvPayload((prev) => {
                                            return {
                                                ...prev,
                                                evDocuments: {
                                                    ...prev?.evDocuments,
                                                    returnedTo: e,
                                                },
                                            };
                                        });
                                    }}
                                    onRemoved={(e) => {
                                        setEvPayload((prev) => {
                                            return {
                                                ...prev,
                                                evDocuments: {
                                                    ...prev?.evDocuments,
                                                    returnedTo: null,
                                                },
                                            };
                                        });
                                    }}
                                />
                            </Grid>
                            <Grid item sm={3} xs={12}>
                                <MultiFileUploader
                                    label={'Other Ev (Pictures)'}
                                    labelStyles={{
                                        fontSize: '16px',
                                        fontWeight: 600,
                                    }}
                                    multiple={true}
                                    disabled={false}
                                    defaultValue={
                                        evPayload?.evDocuments?.evOthers
                                    }
                                    placeholder={'Other Ev (Pictures)'}
                                    onFileUploaded={(val) => {
                                        setEvPayload((prev) => {
                                            return {
                                                ...prev,
                                                evDocuments: {
                                                    ...prev?.evDocuments,
                                                    evOthers: val,
                                                },
                                            };
                                        });
                                    }}
                                    onRemoved={(val) => {
                                        setEvPayload((prev) => {
                                            return {
                                                ...prev,
                                                evDocuments: {
                                                    ...prev?.evDocuments,
                                                    evOthers: val ?? [],
                                                },
                                            };
                                        });
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>
            <Divider />
            <DialogActions className={classes.dialogActions}>
                {actions.map((action, index) => (
                    <Box key={index}>{action}</Box>
                ))}
            </DialogActions>
            <Backdrop
                className={classes.backDropRoot}
                open={loading}
                onClick={null}
            >
                <CircularProgress color="primary" />
            </Backdrop>
            {datePickerOpen && (
                <CustomDatePicker
                    open={datePickerOpen}
                    onOpen={() => setDatePickerOpen(true)}
                    onClose={() => setDatePickerOpen(false)}
                    format="d MMM yyyy"
                    value={evPayload?.eventDateEpochMillis}
                    minDate={moment().valueOf()}
                    onChange={(e) => {
                        setEvPayload((prev) => {
                            return {
                                ...prev,
                                eventDateEpochMillis: moment(e).valueOf(),
                            };
                        });
                    }}
                />
            )}
        </Dialog>
    );
}
const mapStateToProps = (state) => ({
    evUntagReasons: state.settings?.evUntagReasons ?? [],
});

const mapDispatchToProps = (dispatch) => ({
    dispatchGetUntagReason: (payload, onSuccess, onError) =>
        dispatch(getAllEVUntagReason(payload, onSuccess, onError)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StaffEvUnTagModal);
