import React, { useState, useEffect } from 'react';
import '../../staff/styles.scss';
import { connect } from 'react-redux';
import { fetchAllPayslip } from '../../../store/actions/staffActionCreator';

import {
    Button,
    Paper,
    Box,
    Typography,
    makeStyles,
    IconButton,
    Tooltip,
} from '@material-ui/core';
// import { makeStyles } from '@material-ui/core/styles';

import Searchbar from '../../../components/Searchbar';
import { Spinner } from '../../../components/UI';
import EmptyStates from '../../../components/EmptyStates';
import { getActiveInitRoute, getPermissions } from '../../../utils/helper';
import { EmptyIcon } from '../../../assets/svg';
import AllPayslipListContainer from '../../../components/Containers/AllPayslipListContainer';
import GeneratePayslipModal from '../../../components/Modals/GeneratePayslipModal';
import moment from 'moment';
import { CustomMonthPicker } from '../../../components/DateTimePicker';

const useStyles = makeStyles((theme) => ({
    cardSubHeader: {
        fontSize: '22px',
        fontWeight: 700,
    },
    cardHeader: {
        fontSize: '16px',
        fontWeight: 600,
    },
    verticalCardContents: {
        flex: '1 1 auto',
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        justifyContent: 'center',
        border: 'solid 1px #ebedf8',
        borderRadius: '3.8px',
        backgroundColor: '#ebedf8',
        paddingLeft: '16px',
        height: '88px',
        margin: '0 8px 0 0 ',
    },
    verticalCardContentsActive: {
        backgroundColor: '#d1d8ff',
    },
    verticalCardContentsNoMargin: {
        margin: 0,
    },
    searchBar: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
    },
}));

const Payslips = ({
    payslips,
    staffInfo,
    configs,
    dispatchFetchAllPayslip,
    ...props
}) => {
    const classes = useStyles();

    const [activeCard, setActiveCard] = useState(0);
    const [generatePayslipModal, setGeneratePayslipModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [searchVal, setSearchVal] = useState(
        props.location?.state?.searchVal ?? '',
    );
    const [pageNo, setPageNo] = useState(props.location?.state?.page ?? 0);
    const [rowsPerPageNo, setRowsPerPageNo] = useState(
        props?.location?.state?.rowsPerPage ?? 10,
    );
    const [permissions, setPermissions] = useState(getPermissions('staff'));
    const [dateRange, setDateRange] = useState({
        startTimeEpochMillis: moment().startOf('M').valueOf(),
        endTimeEpochMillis: moment().endOf('M').valueOf(),
    });

    const onPageChange = (page) => setPageNo(page);
    const onRowsPerPageNoChange = (rows) => setRowsPerPageNo(rows);

    useEffect(() => {
        getAllStaffPayslip();
        // eslint-disable-next-line react-hooks/exhaustive-deps

        return () => {
            setDateRange({
                startTimeEpochMillis: moment().startOf('M').valueOf(),
                endTimeEpochMillis: moment().endOf('M').valueOf(),
            });
        };
    }, []);

    const getAllStaffPayslip = async (
        data = {
            dateRange,
        },
    ) => {
        await setLoading(true);
        dispatchFetchAllPayslip(
            staffInfo.id,
            data,
            (resp) => {
                setLoading(false);
                console.log('staff data', resp);
            },
            (resp) => {
                setLoading(false);
                console.log('staff data err', resp);
            },
        );
    };

    return (
        <div>
            {/* <TitleHeader title={'Manage Staff'} isBackButton={false} /> */}

            <Spinner loading={loading}>
                <Box
                    className="horiz-end"
                    style={{
                        margin: '24px 16px 16px 24px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <Box className={classes.searchBar} style={{ width: '75%' }}>
                        <Box>
                            <CustomMonthPicker
                                value={null}
                                onChange={async (e) => {
                                    console.log(moment(e).valueOf());
                                    // setSelectedMonth(e);
                                    // setSelectedDate(moment(e));
                                    await setDateRange({
                                        startTimeEpochMillis: moment(e)
                                            .startOf('M')
                                            .valueOf(),
                                        endTimeEpochMillis: moment(e)
                                            .endOf('M')
                                            .valueOf(),
                                    });
                                    getAllStaffPayslip({
                                        dateRange: {
                                            startTimeEpochMillis: moment(e)
                                                .startOf('M')
                                                .valueOf(),
                                            endTimeEpochMillis: moment(e)
                                                .endOf('M')
                                                .valueOf(),
                                        },
                                    });
                                }}
                            />
                        </Box>
                        <Box style={{ width: '300px', marginLeft: 10 }}>
                            <Searchbar
                                placeHolderText="Search by Generated date"
                                // placeHolderStyles={{
                                //     color: '#979797',
                                // }}
                                dataSource={payslips?.lineItems}
                                value={searchVal}
                                keys={['name', 'hubName']}
                                onFilter={async (filteredData) => {}}
                                onChange={(value) => {
                                    console.log('blur', value);
                                    setSearchVal(value);
                                    onPageChange(0);
                                }}
                                disabled={!payslips?.lineItems?.length}
                            />
                        </Box>
                    </Box>

                    <Box
                        className={classes.searchBar}
                        style={{ justifyContent: 'flex-end', width: '25%' }}
                    >
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                setGeneratePayslipModalOpen(true);
                                // toggleStaffModal();
                            }}
                            disabled={!permissions.create}
                            disableElevation
                        >
                            {`Generate ${
                                staffInfo?.freelancer ? 'Invoice' : 'Payslip'
                            }`}
                        </Button>
                    </Box>
                </Box>
                {payslips?.lineItems?.length ? (
                    <Paper elevation={0}>
                        {payslips?.lineItems?.length > 0 ? (
                            <Box style={{ margin: '0 24px 0 24px' }}>
                                <AllPayslipListContainer
                                    getAllStaffPayslip={getAllStaffPayslip}
                                    searchVal={searchVal}
                                    pageNo={pageNo}
                                    onPageChange={onPageChange}
                                    rowsPerPageNo={rowsPerPageNo}
                                    onRowsPerPageNoChange={
                                        onRowsPerPageNoChange
                                    }
                                    permissions={permissions}
                                    data={payslips?.lineItems?.filter(
                                        (payslip) =>
                                            payslip.description
                                                ?.toLowerCase()
                                                .includes(
                                                    searchVal?.toLowerCase(),
                                                ),
                                    )}
                                    staffInfo={staffInfo}
                                />
                            </Box>
                        ) : payslips?.filterStatus ? (
                            <Box
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <EmptyIcon />
                            </Box>
                        ) : (
                            <></>
                        )}
                    </Paper>
                ) : (
                    !loading && (
                        <Box
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <EmptyStates
                                type="Payslip"
                                header1={`Generate ${
                                    staffInfo?.freelancer
                                        ? 'Invoice'
                                        : 'Payslip'
                                }`}
                                subheader1={`Click on Generate ${
                                    staffInfo?.freelancer
                                        ? 'Invoice'
                                        : 'Payslip'
                                } Button to Create New ${
                                    staffInfo?.freelancer
                                        ? 'Invoice'
                                        : 'Payslip'
                                }.`}
                                getAllStaffPayslip={getAllStaffPayslip}
                                staffInfo={staffInfo}
                                buttonDisplay={true}
                            />
                        </Box>
                    )
                )}
                {generatePayslipModal && (
                    <GeneratePayslipModal
                        open={generatePayslipModal}
                        staffInfo={staffInfo}
                        onClose={() => {
                            // console.log('Closed');
                            getAllStaffPayslip();
                            setGeneratePayslipModalOpen(false);
                        }}
                        getAllStaffPayslip={getAllStaffPayslip}
                    />
                )}
            </Spinner>
        </div>
    );
};

const mapStateToProps = (state) => ({
    payslips: state.staff.payslips,
    user: state.user,
    configs: state.user.config,
});

const mapDispatchToProps = (dispatch) => ({
    dispatchFetchAllPayslip: (payload, data, onSuccess, onError) =>
        dispatch(fetchAllPayslip(payload, data, onSuccess, onError)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Payslips);
