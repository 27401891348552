import React, { useState, useEffect } from 'react';
import './styles.scss';
import { withRouter } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    TablePagination,
    Toolbar,
    Paper,
    Checkbox,
    IconButton,
    Button,
    FormControlLabel,
    Switch,
    List,
    ListItem,
    ListItemSecondaryAction,
    Box,
    Typography,
    Tooltip,
    Chip,
    Avatar,
} from '@material-ui/core';
import { lighten, makeStyles } from '@material-ui/core/styles';
import { enqueueSnackbar } from '../../../store/actions/notificationActionCreator';
import {
    formatCurrency,
    getPermissions,
    showMessage,
    replaceTextWithCapsAndUnderscore,
} from '../../../utils/helper';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import clsx from 'clsx';
import {
    setFilterModalConfig,
    setBulkReleaseFlag,
    setSelectedPayments,
    sendSinglePayment,
    getAllPaymentIndentsListingData,
    deleteSinglePayment,
    deleteBatchPayment,
    updatePaymentStatus,
} from '../../../store/actions/authActionCreator';
import DeleteModal from '../../Modals/Zappay/DeleteModal';
import BulkReleaseModal from '../../../components/Modals/Zappay/BulkReleaseModal';
import ComboViewModal from '../../../components/Modals/Zappay/ComboViewModal';
import ApproveOrHoldModal from '../../../components/Modals/Zappay/ApproveOrHoldModal';
import FilterListRoundedIcon from '@material-ui/icons/FilterListRounded';
import moment from 'moment';
import sendMoneyIcon from '../../../assets/images/sendMoneyIcon.png';
import holdMoneyIcon from '../../../assets/images/holdMoneyIcon.png';
import approveMoneyIcon from '../../../assets/images/approveMoneyIcon.png';
import SingleReleaseModal from '../../Modals/Zappay/SingleReleaseModal';
import zappayEmptyState from '../../../assets/images/zappayEmptyState.png';

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead({
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    listingData,
    onRequestSort,
    headCells,
}) {
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    const [availableToReleaseCount, setAvailableToReleaseCount] = useState(
        listingData?.filter(
            (n) =>
                !(
                    n?.paymentStatus == 'RELEASED' ||
                    n?.paymentStatus == 'IN_PROGRESS' ||
                    n?.paymentStatus == 'ON_HOLD'
                ),
        )?.length,
    );

    useEffect(() => {
        console.log('headCells', headCells);
    }, []);

    useEffect(() => {
        setAvailableToReleaseCount(
            listingData?.filter(
                (n) =>
                    !(
                        n?.paymentStatus == 'RELEASED' ||
                        n?.paymentStatus == 'IN_PROGRESS' ||
                        n?.paymentStatus == 'ON_HOLD'
                    ),
            )?.length,
        );
    }, [listingData]);

    const [permissions, setPermissions] = useState(
        getPermissions('pay_online'),
    );

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        indeterminate={
                            numSelected > 0 &&
                            numSelected < availableToReleaseCount
                        }
                        checked={
                            numSelected > 0 &&
                            (numSelected === listingData?.length ||
                                numSelected === availableToReleaseCount)
                        }
                        onChange={onSelectAllClick}
                        inputProps={{ 'aria-label': 'Select all payments' }}
                        style={
                            !permissions?.update
                                ? {}
                                : {
                                      color: '#525050',
                                  }
                        }
                        disabled={!permissions?.update}
                    />
                </TableCell>
                {headCells?.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={'left'}
                        padding={'none'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        style={{
                            fontSize: 14,
                            paddingLeft: 14,
                        }}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc'
                                        ? 'sorted descending'
                                        : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}
const useToolbarStyles = makeStyles((theme) => ({
    root: {
        minHeight: '50px',
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        borderRadius: 8,
    },
    highlight: {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
    },
    title: {
        flex: '1 1 100%',
        fontSize: 20,
    },
    chipRoot: {
        margin: '5px 8px 5px 0',
        // height: '100%',
        paddingLeft: 10,
        background: '#d1d8ff',
        color: 'black',
        '& .MuiChip-label': {
            fontWeight: 600,
            width: 'auto !important',
            fontSize: '12px !important',
        },
        '& .MuiChip-deleteIcon': {
            margin: 4,
        },
    },
    chipRootNoDelete: {
        margin: '5px 8px 5px 0',
        // height: '100%',
        padding: '0 12px',
        background: '#d1d8ff',
        color: 'black',
        '& .MuiChip-label': {
            fontWeight: 600,
            width: 'auto !important',
            fontSize: '12px !important',
        },
    },
}));

const EnhancedTableToolbar = ({
    setBulkReleaseModal,
    user,
    dispatchSetFilterModalConfig,
    numSelected,
    setSelected,
    setPage,
    comboViewModalOpen,
    setComboViewModalOpen,
    setDeletePaymentIndentModal,
    ...props
}) => {
    const classes = useToolbarStyles();

    return (
        <Toolbar
            className={clsx(classes.root, {
                [classes.highlight]: true,
            })}
        >
            <Box
                style={{
                    flexBasis: '70%',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                }}
            >
                <Box
                    style={{
                        marginRight: 8,
                    }}
                >
                    <Tooltip title="Filter" placement="top">
                        <IconButton
                            className={'inputbutton'}
                            variant="outlined"
                            style={{
                                border: '1px solid #43419e',
                                borderRadius: 8,
                                padding: 4,
                                background: '#43419e',
                                color: 'white',
                            }}
                            color="primary"
                            onClick={(e) => {
                                setComboViewModalOpen(true);
                            }}
                        >
                            <FilterListRoundedIcon size="small" />
                        </IconButton>
                    </Tooltip>
                </Box>
                <Box
                    style={{
                        // width: '70%',
                        // display: 'flex',
                        // flexDirection: 'row',
                        // overflowX: 'auto',
                        // backgroundColor: '#ebedf8',
                        borderRadius: 8,
                        alignItems: 'center',
                        padding: '0 8px',
                    }}
                >
                    {user?.zappay?.filterGroupModalConfig?.sourceSystem
                        ?.name && (
                        <Chip
                            key={
                                user?.zappay?.filterGroupModalConfig
                                    ?.sourceSystem?.name
                            }
                            variant="outlined"
                            color="primary"
                            label={`Source System: ${user?.zappay?.filterGroupModalConfig?.sourceSystem?.name}`}
                            className={classes.chipRootNoDelete}
                        />
                    )}
                    {!user?.zappay?.ignoreDateFilterFlag &&
                        user?.zappay?.filterGroupModalConfig?.dateRange && (
                            <Chip
                                key={
                                    user?.zappay?.filterGroupModalConfig
                                        ?.dateRange?.startTimeEpochMillis
                                }
                                variant="outlined"
                                color="primary"
                                label={`Indent Date: ${moment(
                                    user?.zappay?.filterGroupModalConfig
                                        ?.dateRange?.startTimeEpochMillis,
                                ).format('MMM YY')}-${moment(
                                    user?.zappay?.filterGroupModalConfig
                                        ?.dateRange?.endTimeEpochMillis,
                                ).format('MMM YY')}`}
                                className={classes.chipRootNoDelete}
                            />
                        )}
                    {user?.zappay?.filterGroupModalConfig?.paymentStatus && (
                        <Chip
                            key={
                                user?.zappay?.filterGroupModalConfig
                                    ?.paymentStatus
                            }
                            variant="outlined"
                            color="primary"
                            label={`Payment Status: ${user?.zappay?.filterGroupModalConfig?.paymentStatus}`}
                            className={classes.chipRootNoDelete}
                        />
                    )}
                    {user?.zappay?.filterGroupModalConfig?.filters &&
                        Object.entries(
                            user?.zappay?.filterGroupModalConfig?.filters,
                        )?.map((keyValue, index) => {
                            let key = keyValue[0];
                            let value = keyValue[1];
                            return (
                                value && (
                                    <>
                                        <Chip
                                            key={index}
                                            variant="outlined"
                                            color="primary"
                                            label={value}
                                            className={classes.chipRoot}
                                            onDelete={() => {
                                                let payload = {};
                                                if (
                                                    Object?.keys(
                                                        user?.zappay
                                                            ?.filterGroupModalConfig
                                                            ?.filters,
                                                    )?.length > 1
                                                ) {
                                                    payload = {
                                                        ...user?.zappay
                                                            ?.filterGroupModalConfig,
                                                    };
                                                    delete payload?.filters[
                                                        key
                                                    ];
                                                } else {
                                                    let defaultSourceSystem =
                                                        user?.zappay?.sourceSystemsListData?.filter(
                                                            (item) =>
                                                                item.default,
                                                        );

                                                    payload = {
                                                        ...user?.zappay
                                                            ?.filterGroupModalConfig,
                                                        sourceSystem:
                                                            defaultSourceSystem[0],
                                                        dateRange: {
                                                            startTimeEpochMillis:
                                                                moment()
                                                                    .add(
                                                                        -1,
                                                                        'months',
                                                                    )
                                                                    .startOf(
                                                                        'month',
                                                                    )
                                                                    .valueOf(),
                                                            endTimeEpochMillis:
                                                                moment()
                                                                    .endOf(
                                                                        'month',
                                                                    )
                                                                    .valueOf(),
                                                        },
                                                        filters: {},
                                                        paymentStatus:
                                                            'Ready For Payment',
                                                    };
                                                }

                                                console.log(
                                                    'payloadOnDelete',
                                                    payload,
                                                );
                                                dispatchSetFilterModalConfig(
                                                    payload,
                                                );
                                            }}
                                        />
                                    </>
                                )
                            );
                        })}
                </Box>
            </Box>

            {numSelected > 0 ? (
                <Box
                    style={{
                        paddingLeft: 8,
                    }}
                >
                    <Button
                        variant="contained"
                        color="primary"
                        className="btn-pos"
                        onClick={() => {
                            setBulkReleaseModal(true);
                        }}
                        style={{
                            height: '100%',
                            padding: '8px',
                            fontWeight: 600,
                        }}
                        disableElevation
                    >
                        {`Release ${numSelected} ${
                            numSelected > 1 ? 'Payments' : 'Payment'
                        }`}
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        className="btn-pos"
                        onClick={() => {
                            setDeletePaymentIndentModal(true);
                        }}
                        style={{
                            height: '100%',
                            padding: '8px',
                            backgroundColor: 'rgba(244, 67, 54, 0.15)',
                            color: '#f44336',
                            fontWeight: 600,
                        }}
                        disableElevation
                    >
                        {`Delete ${numSelected} ${
                            numSelected > 1 ? 'Payments' : 'Payment'
                        }`}
                    </Button>
                </Box>
            ) : (
                <></>
            )}
            {comboViewModalOpen && (
                <ComboViewModal
                    open={comboViewModalOpen}
                    setSelected={setSelected}
                    setPage={setPage}
                    onClose={(stateVal) => {
                        console.log('Closed', stateVal);
                        setComboViewModalOpen(false);
                        // dispatchSetFilterModalConfig(stateVal);
                    }}
                />
            )}
        </Toolbar>
    );
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        margin: '16px 0',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    tableCellTypographyStyles: {
        // display: 'flex',
        // justifyContent: 'center',
        minWidth: 140,
        fontSize: 13,
        fontWeight: 600,
    },
    tableCellStyles: {
        textAlign: 'left',
    },
}));

const AllPaymentIndentsListContainer = ({
    user,
    permissions,
    dispatchSetFilterModalConfig,
    dispatchSetBulkReleaseFlag,
    dispatchSetSelectedPaymentData,
    dispatchSendSinglePayment,
    dispatchGetAllPaymentIndentsListingData,
    sparklineFilteredlistingData,
    searchValue,
    dispatchDeleteSinglePayment,
    dispatchDeleteBatchPayment,
    dispatchUpdatePaymentStatus,
    ...props
}) => {
    const classes = useStyles();

    // const [data, setData] = useState([]);

    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('amount');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const [singleDeleteModalOpen, setSingleDeleteModalOpen] = useState(false);
    const [selectedRowAction, setSelectedRowAction] = useState();
    const [singleReleaseModal, setSingleReleaseModal] = useState(false);
    const [bulkReleaseModal, setBulkReleaseModal] = useState(false);
    const [deletePaymentIndentModal, setDeletePaymentIndentModal] =
        useState(false);
    const [comboViewModalOpen, setComboViewModalOpen] = useState(false);

    const [holdPaymentModal, setHoldPaymentModal] = useState(false);
    const [approvePaymentModal, setApprovePaymentModal] = useState(false);

    const [sourceSystemFiltersKeyList, setSourceSystemFiltersKeyList] =
        useState(
            user?.zappay?.sourceSystemFilterListData?.map(
                (item) => item?.key,
            ) ?? [],
        );

    const [listingData, setListingData] = useState(
        sparklineFilteredlistingData,
    );

    useEffect(() => {
        console.log('listingData, ', listingData);
        console.log('user, ', user);
    }, []);

    useEffect(() => {
        setSourceSystemFiltersKeyList(
            user?.zappay?.sourceSystemFilterListData?.map((item) => item?.key),
        );
    }, [user?.zappay?.sourceSystemFilterListData]);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            console.log('selecting');
            const newSelecteds = listingData
                ?.filter(
                    (n) =>
                        !(
                            n?.paymentStatus == 'RELEASED' ||
                            n?.paymentStatus == 'IN_PROGRESS' ||
                            n?.paymentStatus == 'ON_HOLD'
                        ),
                )
                ?.map((item) => item?.id);
            setSelected(newSelecteds);
        } else {
            console.log('unselecting');
            setSelected([]);
        }
    };

    const handleClick = (event, data) => {
        const selectedIndex = selected.indexOf(data);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, data);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const tableRowClickHandler = (index) => {
        // alert(`Clicked index ${index}`);
    };

    const handleChangePage = (event, newPage) => {
        console.log('Inside handleChangePage');
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        console.log('Inside handleChangeRowsPerPage');
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const isSelected = (name) => {
        return selected?.indexOf(name) !== -1;
    };

    const emptyRows =
        rowsPerPage -
        Math.min(rowsPerPage, listingData?.length - page * rowsPerPage);

    const [headCells, setHeadCells] = useState([]);

    useEffect(() => {
        let tempHeadCells = [
            {
                id: 'actions',
                numeric: false,
                disablePadding: false,
                label: 'Actions',
            },
            {
                id: 'accountName',
                numeric: true,
                disablePadding: false,
                label: 'Account Name',
            },
        ];

        user?.zappay?.listingData?.metaDataDisplayNameMappings &&
            Object.entries(
                user?.zappay?.listingData?.metaDataDisplayNameMappings,
            )?.map((keyVal, index) => {
                let key = keyVal[0];
                let val = keyVal[1];
                if (sourceSystemFiltersKeyList?.includes(key)) {
                    tempHeadCells.push({
                        id: key,
                        numeric: false,
                        disablePadding: false,
                        label: val,
                    });
                }
            });

        tempHeadCells = tempHeadCells.concat([
            {
                id: 'amount',
                numeric: true,
                disablePadding: false,
                label: 'Amount',
            },
            {
                id: 'utrNumber',
                numeric: false,
                disablePadding: false,
                label: 'UTR',
            },
            {
                id: 'paymentStatus',
                numeric: true,
                disablePadding: false,
                label: 'Status',
            },
            {
                id: 'payment Remarks',
                numeric: true,
                disablePadding: false,
                label: 'Payment Remarks',
            },
            {
                id: 'upiAddress',
                numeric: true,
                disablePadding: false,
                label: 'UPI Address',
            },
            {
                id: 'ifsc',
                numeric: true,
                disablePadding: false,
                label: 'IFSC Code',
            },
            {
                id: 'accountNumber',
                numeric: true,
                disablePadding: false,
                label: 'Account Number',
            },
            {
                id: 'bankName',
                numeric: true,
                disablePadding: false,
                label: 'Bank Name',
            },
            {
                id: 'expectedPaymentDate',
                numeric: true,
                disablePadding: false,
                label: 'Payment Date',
            },
            {
                id: 'remarks',
                numeric: true,
                disablePadding: false,
                label: 'Remarks',
            },
            {
                id: 'sourceTransferId',
                numeric: true,
                disablePadding: false,
                label: 'Transfer ID',
            },
        ]);

        user?.zappay?.listingData?.metaDataDisplayNameMappings &&
            Object.entries(
                user?.zappay?.listingData?.metaDataDisplayNameMappings,
            )?.map((keyVal, index) => {
                let key = keyVal[0];
                let val = keyVal[1];

                if (!sourceSystemFiltersKeyList?.includes(key)) {
                    tempHeadCells.push({
                        id: key,
                        numeric: false,
                        disablePadding: false,
                        label: val,
                    });
                }
            });
        tempHeadCells.push({
            id: 'cashFreeTransferId',
            numeric: false,
            disablePadding: false,
            label: 'Cashfree Transfer ID',
        });
        setHeadCells(tempHeadCells);

        // dispatchSetSelectedPaymentData([]);
    }, [user?.zappay?.listingData]);

    useEffect(() => {
        setSelected([]);
    }, []);

    useEffect(() => {
        setListingData(sparklineFilteredlistingData);
    }, [sparklineFilteredlistingData]);

    useEffect(() => {
        if (searchValue?.length) {
            setListingData(
                sparklineFilteredlistingData.filter(
                    (item) =>
                        item?.accountName
                            ?.toLowerCase()
                            ?.includes(searchValue?.toLowerCase()) ||
                        item?.utrNumber
                            ?.toLowerCase()
                            ?.includes(searchValue?.toLowerCase()) ||
                        item.accountNumber
                            ?.toLowerCase()
                            ?.includes(searchValue?.toLowerCase()) ||
                        item.metaData?.['userId']
                            ?.toLowerCase()
                            ?.includes(searchValue?.toLowerCase()),
                ),
            );
        } else {
            setListingData(sparklineFilteredlistingData);
        }
        setPage(0);
    }, [searchValue]);

    useEffect(() => {
        console.log('selected', selected);
        dispatchSetSelectedPaymentData(selected);
        if (selected?.length === 0) {
            dispatchSetBulkReleaseFlag(false);
        } else if (selected?.length === listingData?.length) {
            dispatchSetBulkReleaseFlag(true);
        }
    }, [selected]);

    return (
        <>
            <div className={classes.root}>
                <Paper className={classes.paper}>
                    <EnhancedTableToolbar
                        user={user}
                        setSelected={setSelected}
                        setPage={setPage}
                        comboViewModalOpen={comboViewModalOpen}
                        setComboViewModalOpen={setComboViewModalOpen}
                        setBulkReleaseModal={setBulkReleaseModal}
                        dispatchSetFilterModalConfig={
                            dispatchSetFilterModalConfig
                        }
                        setDeletePaymentIndentModal={
                            setDeletePaymentIndentModal
                        }
                        numSelected={selected?.length}
                    />
                    {!!listingData?.length ? (
                        <>
                            <TableContainer
                                style={{
                                    maxHeight: '600px',
                                }}
                            >
                                <Table
                                    className={classes.table}
                                    aria-labelledby="tableTitle"
                                    size={'medium'}
                                    aria-label="enhanced table"
                                    stickyHeader
                                >
                                    {!!(
                                        listingData?.length ||
                                        listingData?.length == 0
                                    ) && (
                                        <EnhancedTableHead
                                            classes={classes}
                                            numSelected={selected?.length}
                                            order={order}
                                            orderBy={orderBy}
                                            onSelectAllClick={
                                                handleSelectAllClick
                                            }
                                            onRequestSort={handleRequestSort}
                                            listingData={listingData}
                                            headCells={headCells}
                                        />
                                    )}
                                    {!!listingData?.length && (
                                        <TableBody>
                                            {stableSort(
                                                listingData,
                                                getComparator(order, orderBy),
                                            )
                                                .slice(
                                                    page * rowsPerPage,
                                                    page * rowsPerPage +
                                                        rowsPerPage,
                                                )
                                                .map((row, index) => {
                                                    const isItemSelected =
                                                        isSelected(row.id);
                                                    const labelId = `enhanced-table-checkbox-${index}`;
                                                    let disabledRelease =
                                                        row?.paymentStatus ==
                                                            'RELEASED' ||
                                                        row?.paymentStatus ==
                                                            'IN_PROGRESS' ||
                                                        row?.status ==
                                                            'ON_HOLD';

                                                    return (
                                                        <TableRow
                                                            hover
                                                            role="checkbox"
                                                            aria-checked={
                                                                isItemSelected
                                                            }
                                                            tabIndex={-1}
                                                            key={row?.id}
                                                            selected={
                                                                isItemSelected
                                                            }
                                                        >
                                                            <TableCell
                                                                key={`${row?.id}-0-0`}
                                                                padding="checkbox"
                                                            >
                                                                <Checkbox
                                                                    checked={
                                                                        isItemSelected
                                                                    }
                                                                    inputProps={{
                                                                        'aria-labelledby':
                                                                            labelId,
                                                                    }}
                                                                    style={
                                                                        disabledRelease ||
                                                                        !permissions?.update
                                                                            ? {}
                                                                            : {
                                                                                  color: '#43419E',
                                                                              }
                                                                    }
                                                                    onChange={(
                                                                        event,
                                                                    ) => {
                                                                        handleClick(
                                                                            event,
                                                                            row.id,
                                                                        );
                                                                        // onClick={(event) => handleClick(event, row.id)}
                                                                    }}
                                                                    disabled={
                                                                        disabledRelease ||
                                                                        !permissions?.update
                                                                    }
                                                                />
                                                            </TableCell>
                                                            <TableCell
                                                                key={`${row?.id}-0-1`}
                                                                align={'left'}
                                                                // className={
                                                                //     classes.tableCellStyles
                                                                // }
                                                                style={
                                                                    {
                                                                        // width: '150px !important',
                                                                    }
                                                                }
                                                            >
                                                                <Box
                                                                    style={{
                                                                        width: '150px',
                                                                        display:
                                                                            'flex',
                                                                        justifyContent:
                                                                            'flex-start',
                                                                        paddingLeft: 18,
                                                                        alignItems:
                                                                            'center',
                                                                    }}
                                                                >
                                                                    {/* <Tooltip
                                                                title="Edit"
                                                                placement="top"
                                                            > */}
                                                                    <IconButton
                                                                        color="inherit"
                                                                        onClick={(
                                                                            e,
                                                                        ) => {
                                                                            console.log(
                                                                                'Edit clicked',
                                                                            );
                                                                        }}
                                                                        style={{
                                                                            padding: 2,
                                                                        }}
                                                                        disabled={
                                                                            true
                                                                        }
                                                                    >
                                                                        <EditOutlinedIcon />
                                                                    </IconButton>
                                                                    {/* </Tooltip> */}

                                                                    <Tooltip
                                                                        title="Delete"
                                                                        placement="top"
                                                                    >
                                                                        <IconButton
                                                                            onClick={(
                                                                                e,
                                                                            ) => {
                                                                                setSelectedRowAction(
                                                                                    row,
                                                                                );
                                                                                setSingleDeleteModalOpen(
                                                                                    true,
                                                                                );
                                                                            }}
                                                                            style={
                                                                                disabledRelease ||
                                                                                !permissions?.delete ||
                                                                                selected?.length >
                                                                                    0
                                                                                    ? {
                                                                                          padding: 2,
                                                                                      }
                                                                                    : {
                                                                                          padding: 2,
                                                                                          color: 'red',
                                                                                      }
                                                                            }
                                                                            disabled={
                                                                                disabledRelease ||
                                                                                !permissions?.delete ||
                                                                                selected?.length >
                                                                                    0
                                                                            }
                                                                        >
                                                                            <DeleteOutlinedIcon />
                                                                        </IconButton>
                                                                    </Tooltip>

                                                                    {row?.paymentStatus ===
                                                                    'ON_HOLD' ? (
                                                                        <Tooltip
                                                                            title="Approve"
                                                                            placement="top"
                                                                        >
                                                                            <IconButton
                                                                                color="inherit"
                                                                                onClick={(
                                                                                    e,
                                                                                ) => {
                                                                                    setSelectedRowAction(
                                                                                        row,
                                                                                    );
                                                                                    setApprovePaymentModal(
                                                                                        true,
                                                                                    );
                                                                                }}
                                                                                style={{
                                                                                    padding:
                                                                                        '2px 2px 2px 4px',
                                                                                }}
                                                                                disabled={
                                                                                    !permissions?.update ||
                                                                                    selected?.length >
                                                                                        0
                                                                                }
                                                                            >
                                                                                <Avatar
                                                                                    style={{
                                                                                        borderRadius: 8,
                                                                                        width: 24,
                                                                                        height: 24,
                                                                                    }}
                                                                                    alt="Approve"
                                                                                    src={
                                                                                        approveMoneyIcon
                                                                                    }
                                                                                />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    ) : row?.paymentStatus ===
                                                                      'READY_FOR_PAYMENT' ? (
                                                                        <Tooltip
                                                                            title="Hold"
                                                                            placement="top"
                                                                        >
                                                                            <IconButton
                                                                                color="inherit"
                                                                                onClick={(
                                                                                    e,
                                                                                ) => {
                                                                                    setSelectedRowAction(
                                                                                        row,
                                                                                    );
                                                                                    setHoldPaymentModal(
                                                                                        true,
                                                                                    );
                                                                                }}
                                                                                style={{
                                                                                    padding:
                                                                                        '2px 2px 2px 4px',
                                                                                }}
                                                                                disabled={
                                                                                    !permissions?.update ||
                                                                                    selected?.length >
                                                                                        0
                                                                                }
                                                                            >
                                                                                <Avatar
                                                                                    style={{
                                                                                        borderRadius: 8,
                                                                                        width: 24,
                                                                                        height: 24,
                                                                                    }}
                                                                                    alt="Hold"
                                                                                    src={
                                                                                        holdMoneyIcon
                                                                                    }
                                                                                />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    ) : (
                                                                        <></>
                                                                    )}

                                                                    {!disabledRelease && (
                                                                        <Tooltip
                                                                            title="Release"
                                                                            placement="top"
                                                                        >
                                                                            <IconButton
                                                                                color="inherit"
                                                                                onClick={(
                                                                                    e,
                                                                                ) => {
                                                                                    setSelectedRowAction(
                                                                                        row,
                                                                                    );
                                                                                    setSingleReleaseModal(
                                                                                        true,
                                                                                    );
                                                                                }}
                                                                                style={{
                                                                                    padding:
                                                                                        '2px 2px 2px 4px',
                                                                                }}
                                                                                disabled={
                                                                                    !permissions?.update ||
                                                                                    selected?.length >
                                                                                        0
                                                                                }
                                                                            >
                                                                                <Avatar
                                                                                    style={{
                                                                                        borderRadius: 8,
                                                                                        width: 24,
                                                                                        height: 24,
                                                                                    }}
                                                                                    alt="sendMoney"
                                                                                    src={
                                                                                        sendMoneyIcon
                                                                                    }
                                                                                />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    )}
                                                                </Box>
                                                            </TableCell>

                                                            <TableCell
                                                                key={`${row?.id}-0-2`}
                                                                className={
                                                                    classes.tableCellStyles
                                                                }
                                                                align={'left'}
                                                                // onClick={() =>
                                                                //     tableRowClickHandler(
                                                                //         index,
                                                                //     )
                                                                // }
                                                            >
                                                                <Typography
                                                                    className={
                                                                        classes.tableCellTypographyStyles
                                                                    }
                                                                >
                                                                    {
                                                                        row?.accountName
                                                                    }
                                                                </Typography>
                                                            </TableCell>

                                                            {user?.zappay
                                                                ?.listingData
                                                                ?.metaDataDisplayNameMappings &&
                                                                Object.entries(
                                                                    user?.zappay
                                                                        ?.listingData
                                                                        ?.metaDataDisplayNameMappings,
                                                                )?.map(
                                                                    (
                                                                        keyVal,
                                                                        index,
                                                                    ) => {
                                                                        let key =
                                                                            keyVal[0];
                                                                        let val =
                                                                            keyVal[1];
                                                                        // console.log(
                                                                        //     'sourceSystemFiltersKeyList',
                                                                        //     key,
                                                                        //     val,
                                                                        //     sourceSystemFiltersKeyList,
                                                                        //     row?.metaData &&
                                                                        //         row
                                                                        //             ?.metaData[
                                                                        //             key
                                                                        //         ],
                                                                        // );
                                                                        if (
                                                                            sourceSystemFiltersKeyList?.includes(
                                                                                key,
                                                                            )
                                                                        ) {
                                                                            return (
                                                                                <TableCell
                                                                                    key={
                                                                                        key
                                                                                    }
                                                                                    className={
                                                                                        classes.tableCellStyles
                                                                                    }
                                                                                >
                                                                                    <Typography
                                                                                        className={
                                                                                            classes.tableCellTypographyStyles
                                                                                        }
                                                                                    >
                                                                                        {row?.metaData &&
                                                                                            row
                                                                                                ?.metaData[
                                                                                                key
                                                                                            ]}
                                                                                    </Typography>
                                                                                </TableCell>
                                                                            );
                                                                        } else {
                                                                            return (
                                                                                <>

                                                                                </>
                                                                            );
                                                                        }
                                                                    },
                                                                )}

                                                            <TableCell
                                                                key={`${row?.id}-1-0`}
                                                                className={
                                                                    classes.tableCellStyles
                                                                }
                                                            >
                                                                <Typography
                                                                    className={
                                                                        classes.tableCellTypographyStyles
                                                                    }
                                                                >
                                                                    {formatCurrency(
                                                                        row?.amount,
                                                                    )}
                                                                </Typography>
                                                            </TableCell>

                                                            <TableCell
                                                                key={`${row?.id}-1-1`}
                                                                className={
                                                                    classes.tableCellStyles
                                                                }
                                                            >
                                                                <Typography
                                                                    className={
                                                                        classes.tableCellTypographyStyles
                                                                    }
                                                                >
                                                                    {row?.paymentStatus !=
                                                                    'FAILED'
                                                                        ? row?.utrNumber
                                                                        : ''}
                                                                </Typography>
                                                            </TableCell>

                                                            <TableCell
                                                                key={`${row?.id}-1-2`}
                                                                className={
                                                                    classes.tableCellStyles
                                                                }
                                                            >
                                                                <Typography
                                                                    className={
                                                                        row?.paymentStatus ===
                                                                        'READY_FOR_PAYMENT'
                                                                            ? 'ready-for-payment-chip'
                                                                            : row?.paymentStatus ===
                                                                              'IN_PROGRESS'
                                                                            ? 'in-progress-chip'
                                                                            : row?.paymentStatus ===
                                                                              'FAILED'
                                                                            ? 'failed-chip'
                                                                            : row?.paymentStatus ===
                                                                              'RELEASED'
                                                                            ? 'released-chip'
                                                                            : row?.paymentStatus ===
                                                                              'ON_HOLD'
                                                                            ? 'on-hold-chip'
                                                                            : null
                                                                    }
                                                                    style={{
                                                                        width: '150px',
                                                                        // marginLeft: 32,
                                                                    }}
                                                                >
                                                                    {row?.paymentStatus ??
                                                                        '-'}
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell
                                                                key={`${row?.id}-1-2-1`}
                                                                className={
                                                                    classes.tableCellStyles
                                                                }
                                                            >
                                                                <Typography
                                                                    className={
                                                                        classes.tableCellTypographyStyles
                                                                    }
                                                                >
                                                                    {row?.paymentStatus ===
                                                                    'FAILED'
                                                                        ? row?.paymentRemarks
                                                                        : ''}
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell
                                                                key={`${row?.id}-1-3`}
                                                                className={
                                                                    classes.tableCellStyles
                                                                }
                                                            >
                                                                <Typography
                                                                    className={
                                                                        classes.tableCellTypographyStyles
                                                                    }
                                                                >
                                                                    {
                                                                        row?.upiAddress
                                                                    }
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell
                                                                key={`${row?.id}-1-3`}
                                                                className={
                                                                    classes.tableCellStyles
                                                                }
                                                            >
                                                                <Typography
                                                                    className={
                                                                        classes.tableCellTypographyStyles
                                                                    }
                                                                >
                                                                    {
                                                                        row?.ifscCode
                                                                    }
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell
                                                                key={`${row?.id}-1-4`}
                                                                className={
                                                                    classes.tableCellStyles
                                                                }
                                                            >
                                                                <Typography
                                                                    className={
                                                                        classes.tableCellTypographyStyles
                                                                    }
                                                                >
                                                                    {
                                                                        row?.accountNumber
                                                                    }
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell
                                                                key={`${row?.id}-1-5`}
                                                                className={
                                                                    classes.tableCellStyles
                                                                }
                                                            >
                                                                <Typography
                                                                    className={
                                                                        classes.tableCellTypographyStyles
                                                                    }
                                                                >
                                                                    {
                                                                        row?.bankName
                                                                    }
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell
                                                                key={`${row?.id}-1-6`}
                                                                className={
                                                                    classes.tableCellStyles
                                                                }
                                                            >
                                                                <Typography
                                                                    className={
                                                                        classes.tableCellTypographyStyles
                                                                    }
                                                                >
                                                                    {
                                                                        row?.expectedPaymentDate
                                                                    }
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell
                                                                key={`${row?.id}-1-6-1`}
                                                                className={
                                                                    classes.tableCellStyles
                                                                }
                                                            >
                                                                <Typography
                                                                    className={
                                                                        classes.tableCellTypographyStyles
                                                                    }
                                                                >
                                                                    {
                                                                        row?.remarks
                                                                    }
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell
                                                                key={`${row?.id}-1-6-2`}
                                                                className={
                                                                    classes.tableCellStyles
                                                                }
                                                            >
                                                                <Typography
                                                                    className={
                                                                        classes.tableCellTypographyStyles
                                                                    }
                                                                >
                                                                    {
                                                                        row?.transferId
                                                                    }
                                                                </Typography>
                                                            </TableCell>

                                                            {/* <TableCell
                                                        key={`${row?.id}-0-8`}
                                                        className={classes.tableCellStyles}
                                                    >
                                                        <Typography
                                                            style={{
                                                                // minWidth:
                                                                //     '150px',
                                                                fontSize: 13,
                                                                fontWeight: 600,
                                                            }}
                                                        >
                                                            {row?.transferId}
                                                        </Typography>
                                                    </TableCell> */}
                                                            {/* <TableCell
                                                        key={`${row?.id}-0-10`}
                                                        className={classes.tableCellStyles}
                                                    >
                                                        <Typography
                                                            style={{
                                                                minWidth:
                                                                    '150px',

                                                                fontSize: 13,
                                                                fontWeight: 600,
                                                            }}
                                                        >
                                                            {row?.paidAmount}
                                                        </Typography>
                                                    </TableCell> */}

                                                            {user?.zappay
                                                                ?.listingData
                                                                ?.metaDataDisplayNameMappings &&
                                                                Object.entries(
                                                                    user?.zappay
                                                                        ?.listingData
                                                                        ?.metaDataDisplayNameMappings,
                                                                )?.map(
                                                                    (
                                                                        keyVal,
                                                                        index,
                                                                    ) => {
                                                                        let key =
                                                                            keyVal[0];
                                                                        let val =
                                                                            keyVal[1];
                                                                        // console.log(
                                                                        //     'keyyyy',
                                                                        //     key,
                                                                        //     val,
                                                                        //     row?.metaData &&
                                                                        //         row
                                                                        //             ?.metaData[
                                                                        //             key
                                                                        //         ],
                                                                        // );

                                                                        if (
                                                                            !sourceSystemFiltersKeyList?.includes(
                                                                                key,
                                                                            )
                                                                        ) {
                                                                            return (
                                                                                <TableCell
                                                                                    key={
                                                                                        key
                                                                                    }
                                                                                    className={
                                                                                        classes.tableCellStyles
                                                                                    }
                                                                                >
                                                                                    <Typography
                                                                                        className={
                                                                                            classes.tableCellTypographyStyles
                                                                                        }
                                                                                    >
                                                                                        {row?.metaData &&
                                                                                            row
                                                                                                ?.metaData[
                                                                                                key
                                                                                            ]}
                                                                                    </Typography>
                                                                                </TableCell>
                                                                            );
                                                                        } else {
                                                                            return (
                                                                                <>

                                                                                </>
                                                                            );
                                                                        }
                                                                    },
                                                                )}
                                                            <TableCell
                                                                key={`${row?.id}-1-2-1`}
                                                                className={
                                                                    classes.tableCellStyles
                                                                }
                                                            >
                                                                <Typography
                                                                    className={
                                                                        classes.tableCellTypographyStyles
                                                                    }
                                                                >
                                                                    {row?.cashFreeTransferId ??
                                                                        ''}
                                                                </Typography>
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                            {/* {emptyRows > 0 && (
                                    <TableRow
                                        style={{ height: 30 * emptyRows }}
                                    >
                                        <TableCell colSpan={6} />
                                    </TableRow>
                                )} */}
                                        </TableBody>
                                    )}
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[10, 25, 50]}
                                component="div"
                                count={listingData?.length ?? 0}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                        </>
                    ) : (
                        <>
                            <Box
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    marginTop: '40px',
                                }}
                            >
                                <img
                                    src={zappayEmptyState}
                                    style={{
                                        margin: '0 auto auto auto',
                                        height: '350px',
                                        width: '100%',
                                        borderRadius: '8px',
                                        objectFit: 'contain',
                                    }}
                                />
                                <Typography
                                    style={{
                                        margin: '16px 0',
                                        fontSize: 20,
                                        fontWeight: 600,
                                    }}
                                >
                                    No Results Found
                                </Typography>
                            </Box>
                        </>
                    )}
                    {!!singleDeleteModalOpen && (
                        <DeleteModal
                            open={true}
                            data={selectedRowAction}
                            onClose={() => {
                                setSingleDeleteModalOpen(false);
                            }}
                            deleteType={'TRANSACTION'}
                            onDeletion={(toggleLoading) => {
                                dispatchDeleteSinglePayment(
                                    selectedRowAction?.id,
                                    (resp) => {
                                        showMessage(
                                            resp?.status?.message,
                                            resp?.status?.code == 200
                                                ? 'success'
                                                : 'error',
                                        );

                                        let selectedIgnoreDataFilters =
                                            Object.entries(
                                                user?.zappay
                                                    ?.filterGroupModalConfig
                                                    ?.filters,
                                            )?.filter((selectedFilter) =>
                                                user?.zappay?.sourceSystemFilterListData
                                                    ?.filter(
                                                        (item) =>
                                                            item?.ignoreDateFilter ==
                                                            true,
                                                    )
                                                    ?.map(
                                                        (selected) =>
                                                            selected?.key,
                                                    )
                                                    ?.includes(
                                                        selectedFilter[0],
                                                    ),
                                            );

                                        let listingRequestPayload =
                                            selectedIgnoreDataFilters?.length >
                                            0
                                                ? {
                                                      filters:
                                                          user?.zappay
                                                              ?.filterGroupModalConfig
                                                              ?.filters ?? {},
                                                      sourceSystemId:
                                                          user?.zappay
                                                              ?.filterGroupModalConfig
                                                              ?.sourceSystem
                                                              ?.id,
                                                      paymentStatus:
                                                          'ALL' ==
                                                          user?.zappay?.filterGroupModalConfig?.paymentStatus.toUpperCase()
                                                              ? null
                                                              : replaceTextWithCapsAndUnderscore(
                                                                    user?.zappay
                                                                        ?.filterGroupModalConfig
                                                                        ?.paymentStatus,
                                                                ),
                                                  }
                                                : {
                                                      dateRange:
                                                          user?.zappay
                                                              ?.filterGroupModalConfig
                                                              ?.dateRange,
                                                      filters:
                                                          user?.zappay
                                                              ?.filterGroupModalConfig
                                                              ?.filters ?? {},
                                                      sourceSystemId:
                                                          user?.zappay
                                                              ?.filterGroupModalConfig
                                                              ?.sourceSystem
                                                              ?.id,
                                                      paymentStatus:
                                                          'ALL' ==
                                                          user?.zappay?.filterGroupModalConfig?.paymentStatus.toUpperCase()
                                                              ? null
                                                              : replaceTextWithCapsAndUnderscore(
                                                                    user?.zappay
                                                                        ?.filterGroupModalConfig
                                                                        ?.paymentStatus,
                                                                ),
                                                  };

                                        dispatchGetAllPaymentIndentsListingData(
                                            listingRequestPayload ?? {},
                                            (resp) => {
                                                toggleLoading(false);
                                            },
                                            () => {
                                                toggleLoading(false);
                                            },
                                        );
                                        setSingleDeleteModalOpen(false);
                                        toggleLoading(false);
                                    },
                                    () => {
                                        toggleLoading(false);
                                    },
                                );
                            }}
                        />
                    )}
                </Paper>
                {bulkReleaseModal && (
                    <BulkReleaseModal
                        open={bulkReleaseModal}
                        onClose={() => {
                            // console.log('Closed');
                            setBulkReleaseModal(false);
                        }}
                        onSuccessActionCallback={(toggleLoading) => {
                            console.log('Calling onSuccessActionCallback');
                            setSelected([]);
                            let selectedIgnoreDataFilters = Object.entries(
                                user?.zappay?.filterGroupModalConfig?.filters,
                            )?.filter((selectedFilter) =>
                                user?.zappay?.sourceSystemFilterListData
                                    ?.filter(
                                        (item) =>
                                            item?.ignoreDateFilter == true,
                                    )
                                    ?.map((selected) => selected?.key)
                                    ?.includes(selectedFilter[0]),
                            );

                            let listingRequestPayload =
                                selectedIgnoreDataFilters?.length > 0
                                    ? {
                                          filters:
                                              user?.zappay
                                                  ?.filterGroupModalConfig
                                                  ?.filters ?? {},
                                          sourceSystemId:
                                              user?.zappay
                                                  ?.filterGroupModalConfig
                                                  ?.sourceSystem?.id,
                                          paymentStatus:
                                              'ALL' ==
                                              user?.zappay?.filterGroupModalConfig?.paymentStatus.toUpperCase()
                                                  ? null
                                                  : replaceTextWithCapsAndUnderscore(
                                                        user?.zappay
                                                            ?.filterGroupModalConfig
                                                            ?.paymentStatus,
                                                    ),
                                      }
                                    : {
                                          dateRange:
                                              user?.zappay
                                                  ?.filterGroupModalConfig
                                                  ?.dateRange,
                                          filters:
                                              user?.zappay
                                                  ?.filterGroupModalConfig
                                                  ?.filters ?? {},
                                          sourceSystemId:
                                              user?.zappay
                                                  ?.filterGroupModalConfig
                                                  ?.sourceSystem?.id,
                                          paymentStatus:
                                              'ALL' ==
                                              user?.zappay?.filterGroupModalConfig?.paymentStatus.toUpperCase()
                                                  ? null
                                                  : replaceTextWithCapsAndUnderscore(
                                                        user?.zappay
                                                            ?.filterGroupModalConfig
                                                            ?.paymentStatus,
                                                    ),
                                      };

                            dispatchGetAllPaymentIndentsListingData(
                                listingRequestPayload ?? {},
                                (resp) => {
                                    toggleLoading(false);
                                },
                                () => {
                                    toggleLoading(false);
                                },
                            );
                        }}
                    />
                )}
                {!!deletePaymentIndentModal && (
                    <DeleteModal
                        open={true}
                        onClose={() => {
                            setDeletePaymentIndentModal(false);
                        }}
                        bulkDelete={true}
                        deleteType={'TRANSACTION'}
                        onDeletion={(toggleLoading) => {
                            dispatchDeleteBatchPayment(
                                { indentIds: selected },
                                (resp) => {
                                    if (
                                        resp?.response?.failedIndents?.length >
                                        0
                                    ) {
                                        showMessage(
                                            `Failed to release ${resp?.response?.failedIndents?.toString()}`,
                                            'error',
                                        );
                                    } else {
                                        showMessage(
                                            resp?.status?.message,
                                            resp?.status?.code == 200
                                                ? 'success'
                                                : 'error',
                                        );
                                    }
                                    setSelected([]);
                                    let selectedIgnoreDataFilters =
                                        Object.entries(
                                            user?.zappay?.filterGroupModalConfig
                                                ?.filters,
                                        )?.filter((selectedFilter) =>
                                            user?.zappay?.sourceSystemFilterListData
                                                ?.filter(
                                                    (item) =>
                                                        item?.ignoreDateFilter ==
                                                        true,
                                                )
                                                ?.map(
                                                    (selected) => selected?.key,
                                                )
                                                ?.includes(selectedFilter[0]),
                                        );

                                    let listingRequestPayload =
                                        selectedIgnoreDataFilters?.length > 0
                                            ? {
                                                  filters:
                                                      user?.zappay
                                                          ?.filterGroupModalConfig
                                                          ?.filters ?? {},
                                                  sourceSystemId:
                                                      user?.zappay
                                                          ?.filterGroupModalConfig
                                                          ?.sourceSystem?.id,
                                                  paymentStatus:
                                                      'ALL' ==
                                                      user?.zappay?.filterGroupModalConfig?.paymentStatus.toUpperCase()
                                                          ? null
                                                          : replaceTextWithCapsAndUnderscore(
                                                                user?.zappay
                                                                    ?.filterGroupModalConfig
                                                                    ?.paymentStatus,
                                                            ),
                                              }
                                            : {
                                                  dateRange:
                                                      user?.zappay
                                                          ?.filterGroupModalConfig
                                                          ?.dateRange,
                                                  filters:
                                                      user?.zappay
                                                          ?.filterGroupModalConfig
                                                          ?.filters ?? {},
                                                  sourceSystemId:
                                                      user?.zappay
                                                          ?.filterGroupModalConfig
                                                          ?.sourceSystem?.id,
                                                  paymentStatus:
                                                      'ALL' ==
                                                      user?.zappay?.filterGroupModalConfig?.paymentStatus.toUpperCase()
                                                          ? null
                                                          : replaceTextWithCapsAndUnderscore(
                                                                user?.zappay
                                                                    ?.filterGroupModalConfig
                                                                    ?.paymentStatus,
                                                            ),
                                              };

                                    dispatchGetAllPaymentIndentsListingData(
                                        listingRequestPayload ?? {},
                                        (resp) => {
                                            toggleLoading(false);
                                        },
                                        () => {
                                            toggleLoading(false);
                                        },
                                    );
                                    setDeletePaymentIndentModal(false);
                                    toggleLoading(false);
                                },
                                () => {
                                    toggleLoading(false);
                                },
                            );
                        }}
                    />
                )}
                {!!singleReleaseModal && (
                    <SingleReleaseModal
                        open={true}
                        onClose={() => {
                            setSingleReleaseModal(false);
                        }}
                        onRelease={(toggleLoading) => {
                            setSelected([]);
                            let payload = {
                                indentId: selectedRowAction?.id,
                                amountToPay: selectedRowAction?.amount,
                            };
                            dispatchSendSinglePayment(
                                payload,
                                (resp) => {
                                    console.log(
                                        'SINGLE RELEASE RESPONSE',
                                        resp,
                                    );

                                    showMessage(
                                        resp?.status?.message,
                                        resp?.status?.code == 200
                                            ? 'success'
                                            : 'error',
                                    );
                                    // dispatchSetSelectedPaymentData([]);

                                    let selectedIgnoreDataFilters =
                                        Object.entries(
                                            user?.zappay?.filterGroupModalConfig
                                                ?.filters,
                                        )?.filter((selectedFilter) =>
                                            user?.zappay?.sourceSystemFilterListData
                                                ?.filter(
                                                    (item) =>
                                                        item?.ignoreDateFilter ==
                                                        true,
                                                )
                                                ?.map(
                                                    (selected) => selected?.key,
                                                )
                                                ?.includes(selectedFilter[0]),
                                        );

                                    let listingRequestPayload =
                                        selectedIgnoreDataFilters?.length > 0
                                            ? {
                                                  filters:
                                                      user?.zappay
                                                          ?.filterGroupModalConfig
                                                          ?.filters ?? {},
                                                  sourceSystemId:
                                                      user?.zappay
                                                          ?.filterGroupModalConfig
                                                          ?.sourceSystem?.id,
                                                  paymentStatus:
                                                      'ALL' ==
                                                      user?.zappay?.filterGroupModalConfig?.paymentStatus.toUpperCase()
                                                          ? null
                                                          : replaceTextWithCapsAndUnderscore(
                                                                user?.zappay
                                                                    ?.filterGroupModalConfig
                                                                    ?.paymentStatus,
                                                            ),
                                              }
                                            : {
                                                  dateRange:
                                                      user?.zappay
                                                          ?.filterGroupModalConfig
                                                          ?.dateRange,
                                                  filters:
                                                      user?.zappay
                                                          ?.filterGroupModalConfig
                                                          ?.filters ?? {},
                                                  sourceSystemId:
                                                      user?.zappay
                                                          ?.filterGroupModalConfig
                                                          ?.sourceSystem?.id,
                                                  paymentStatus:
                                                      'ALL' ==
                                                      user?.zappay?.filterGroupModalConfig?.paymentStatus.toUpperCase()
                                                          ? null
                                                          : replaceTextWithCapsAndUnderscore(
                                                                user?.zappay
                                                                    ?.filterGroupModalConfig
                                                                    ?.paymentStatus,
                                                            ),
                                              };

                                    dispatchGetAllPaymentIndentsListingData(
                                        listingRequestPayload ?? {},
                                        (resp) => {
                                            toggleLoading(false);
                                        },
                                        () => {
                                            toggleLoading(false);
                                        },
                                    );
                                    setSingleReleaseModal(false);
                                    toggleLoading(false);
                                },
                                (err) => {
                                    toggleLoading(false);
                                },
                            );
                        }}
                    />
                )}
                {!!approvePaymentModal && (
                    <ApproveOrHoldModal
                        open={true}
                        onClose={() => {
                            setApprovePaymentModal(false);
                        }}
                        onAction={(toggleLoading, remarks) => {
                            // toggleLoading(true);

                            dispatchUpdatePaymentStatus(
                                {
                                    id: selectedRowAction?.id,
                                    data: {
                                        status: 'READY_FOR_PAYMENT',
                                        remarks: remarks,
                                    },
                                },
                                (resp) => {
                                    showMessage(
                                        resp?.status?.message,
                                        resp?.status?.code == 200
                                            ? 'success'
                                            : 'error',
                                    );
                                    // dispatchSetSelectedPaymentData([]);
                                    if (resp?.status?.code == 200) {
                                        let selectedIgnoreDataFilters =
                                            Object.entries(
                                                user?.zappay
                                                    ?.filterGroupModalConfig
                                                    ?.filters,
                                            )?.filter((selectedFilter) =>
                                                user?.zappay?.sourceSystemFilterListData
                                                    ?.filter(
                                                        (item) =>
                                                            item?.ignoreDateFilter ==
                                                            true,
                                                    )
                                                    ?.map(
                                                        (selected) =>
                                                            selected?.key,
                                                    )
                                                    ?.includes(
                                                        selectedFilter[0],
                                                    ),
                                            );

                                        let listingRequestPayload =
                                            selectedIgnoreDataFilters?.length >
                                            0
                                                ? {
                                                      filters:
                                                          user?.zappay
                                                              ?.filterGroupModalConfig
                                                              ?.filters ?? {},
                                                      sourceSystemId:
                                                          user?.zappay
                                                              ?.filterGroupModalConfig
                                                              ?.sourceSystem
                                                              ?.id,
                                                      paymentStatus:
                                                          'ALL' ==
                                                          user?.zappay?.filterGroupModalConfig?.paymentStatus.toUpperCase()
                                                              ? null
                                                              : replaceTextWithCapsAndUnderscore(
                                                                    user?.zappay
                                                                        ?.filterGroupModalConfig
                                                                        ?.paymentStatus,
                                                                ),
                                                  }
                                                : {
                                                      dateRange:
                                                          user?.zappay
                                                              ?.filterGroupModalConfig
                                                              ?.dateRange,
                                                      filters:
                                                          user?.zappay
                                                              ?.filterGroupModalConfig
                                                              ?.filters ?? {},
                                                      sourceSystemId:
                                                          user?.zappay
                                                              ?.filterGroupModalConfig
                                                              ?.sourceSystem
                                                              ?.id,
                                                      paymentStatus:
                                                          'ALL' ==
                                                          user?.zappay?.filterGroupModalConfig?.paymentStatus.toUpperCase()
                                                              ? null
                                                              : replaceTextWithCapsAndUnderscore(
                                                                    user?.zappay
                                                                        ?.filterGroupModalConfig
                                                                        ?.paymentStatus,
                                                                ),
                                                  };

                                        dispatchGetAllPaymentIndentsListingData(
                                            listingRequestPayload ?? {},
                                            (resp) => {
                                                toggleLoading(false);
                                            },
                                            () => {
                                                toggleLoading(false);
                                            },
                                        );
                                        toggleLoading(false);
                                        setApprovePaymentModal(false);
                                    } else {
                                        toggleLoading(false);
                                    }
                                },
                                () => {},
                            );
                        }}
                        type={'Approve'}
                        confirmationText={'make this Ready For Payment?'}
                    />
                )}
                {!!holdPaymentModal && (
                    <ApproveOrHoldModal
                        open={true}
                        onClose={() => {
                            setHoldPaymentModal(false);
                        }}
                        onAction={(toggleLoading, remarks) => {
                            // toggleLoading(true);

                            dispatchUpdatePaymentStatus(
                                {
                                    id: selectedRowAction?.id,
                                    data: {
                                        status: 'ON_HOLD',
                                        remarks: remarks,
                                    },
                                },
                                (resp) => {
                                    showMessage(
                                        resp?.status?.message,
                                        resp?.status?.code == 200
                                            ? 'success'
                                            : 'error',
                                    );
                                    // dispatchSetSelectedPaymentData([]);
                                    if (resp?.status?.code == 200) {
                                        let selectedIgnoreDataFilters =
                                            Object.entries(
                                                user?.zappay
                                                    ?.filterGroupModalConfig
                                                    ?.filters,
                                            )?.filter((selectedFilter) =>
                                                user?.zappay?.sourceSystemFilterListData
                                                    ?.filter(
                                                        (item) =>
                                                            item?.ignoreDateFilter ==
                                                            true,
                                                    )
                                                    ?.map(
                                                        (selected) =>
                                                            selected?.key,
                                                    )
                                                    ?.includes(
                                                        selectedFilter[0],
                                                    ),
                                            );

                                        let listingRequestPayload =
                                            selectedIgnoreDataFilters?.length >
                                            0
                                                ? {
                                                      filters:
                                                          user?.zappay
                                                              ?.filterGroupModalConfig
                                                              ?.filters ?? {},
                                                      sourceSystemId:
                                                          user?.zappay
                                                              ?.filterGroupModalConfig
                                                              ?.sourceSystem
                                                              ?.id,
                                                      paymentStatus:
                                                          'ALL' ==
                                                          user?.zappay?.filterGroupModalConfig?.paymentStatus.toUpperCase()
                                                              ? null
                                                              : replaceTextWithCapsAndUnderscore(
                                                                    user?.zappay
                                                                        ?.filterGroupModalConfig
                                                                        ?.paymentStatus,
                                                                ),
                                                  }
                                                : {
                                                      dateRange:
                                                          user?.zappay
                                                              ?.filterGroupModalConfig
                                                              ?.dateRange,
                                                      filters:
                                                          user?.zappay
                                                              ?.filterGroupModalConfig
                                                              ?.filters ?? {},
                                                      sourceSystemId:
                                                          user?.zappay
                                                              ?.filterGroupModalConfig
                                                              ?.sourceSystem
                                                              ?.id,
                                                      paymentStatus:
                                                          'ALL' ==
                                                          user?.zappay?.filterGroupModalConfig?.paymentStatus.toUpperCase()
                                                              ? null
                                                              : replaceTextWithCapsAndUnderscore(
                                                                    user?.zappay
                                                                        ?.filterGroupModalConfig
                                                                        ?.paymentStatus,
                                                                ),
                                                  };

                                        dispatchGetAllPaymentIndentsListingData(
                                            listingRequestPayload ?? {},
                                            (resp) => {
                                                toggleLoading(false);
                                            },
                                            () => {
                                                toggleLoading(false);
                                            },
                                        );
                                        toggleLoading(false);
                                        setHoldPaymentModal(false);
                                    } else {
                                        toggleLoading(false);
                                    }
                                },
                                () => {},
                            );
                        }}
                        type={'Hold'}
                        confirmationText={'Hold this payment?'}
                    />
                )}
            </div>
        </>
    );
};

const mapStateToProps = (state) => ({
    user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
    dispatchSetFilterModalConfig: (data, onSuccess, onError) =>
        dispatch(setFilterModalConfig(data, onSuccess, onError)),

    dispatchEnqueueSnackbar: (notification) =>
        dispatch(enqueueSnackbar(notification)),

    dispatchSetBulkReleaseFlag: (data) => dispatch(setBulkReleaseFlag(data)),

    dispatchSetSelectedPaymentData: (data) =>
        dispatch(setSelectedPayments(data)),

    dispatchSendSinglePayment: (data, onSuccess, onError) =>
        dispatch(sendSinglePayment(data, onSuccess, onError)),

    dispatchGetAllPaymentIndentsListingData: (data, onSuccess, onError) =>
        dispatch(getAllPaymentIndentsListingData(data, onSuccess, onError)),

    dispatchDeleteSinglePayment: (data, onSuccess, onError) =>
        dispatch(deleteSinglePayment(data, onSuccess, onError)),

    dispatchDeleteBatchPayment: (data, onSuccess, onError) =>
        dispatch(deleteBatchPayment(data, onSuccess, onError)),

    dispatchUpdatePaymentStatus: (data, onSuccess, onError) =>
        dispatch(updatePaymentStatus(data, onSuccess, onError)),
});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(AllPaymentIndentsListContainer),
);
