import React, { useEffect, useState } from 'react';
import {
    Box,
    Paper,
    Typography,
    makeStyles,
    IconButton,
    Tooltip,
    TablePagination,
} from '@material-ui/core';
import { CustomMonthPicker } from '../../../components/DateTimePicker';
import Searchbar from '../../../components/Searchbar';
import { Spinner } from '../../../components/UI';
import ExpensesListContainer from './ExpensesListContainer';
import { connect } from 'react-redux';
import { GetExpenseReport } from '../../../store/actions/reportActionCreator';
import {
    downloadData,
    formatCurrency,
    getLoginUser,
    JSONToCSVConvertor,
} from '../../../utils/helper';
import { CustomTextField } from '../../../components/Textfield';
import moment from 'moment';
import InsertInvitationIcon from '@material-ui/icons/InsertInvitation';
import { EmptyIcon } from '../../../assets/svg';
import GetAppIcon from '@material-ui/icons/GetApp';
import { withRouter } from 'react-router-dom';
import { useDebounce } from '../../../hooks/useDebounce';

const useStyles = makeStyles((theme) => ({
    paymentsReportContainer: {
        padding: '1.5rem',
    },
    horizontalCardContents: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'spaceBetween',
    },
    searchBar: {
        width: '50%',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'flex-end',
    },
    verticalCardContents: {
        flex: '1 1 auto',
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        justifyContent: 'center',
        border: 'solid 1px #ebedf8',
        borderRadius: '8px',
        backgroundColor: '#f6f7f9',
        paddingLeft: '16px',
        height: '88px',
        margin: '0 8px 0 0 ',
    },
    verticalCardContentsNoMargin: {
        margin: '0',
    },
    cardSubHeader: {
        fontWeight: 600,
    },
    cardHeader: {
        fontSize: '20px',
        fontWeight: 700,
    },
}));

export function ExpensesReportComponent({
    expenses,
    permissions,
    settings,
    dispatchGetExpenseReport,
    ...props
}) {
    const { debounce, isBouncing } = useDebounce();

    const classes = useStyles();
    const [page, setPage] = React.useState(
        props?.location?.state?.payload?.pageNo ?? 0,
    );
    const [rowsPerPage, setRowsPerPage] = React.useState(
        props?.location?.state?.payload?.pageSize ?? 10,
    );
    const [searchVal, setSearchVal] = useState(
        props?.location?.state?.payload?.searchText ?? '',
    );
    const [payload, setPayload] = useState(
        props?.location?.state?.payload ?? {
            pageNo: page + 1,
            pageSize: rowsPerPage,
            searchText: searchVal,
            dateRange: {
                from: moment().startOf('month').valueOf(),
                to: moment().endOf('month').valueOf(),
            },
            organisationId:
                settings?.basicDetails?.id ?? getLoginUser().organizationId,
            transactionPaymentTypes: ['DEDUCTION'],
            earningTypes: ['ALLOWANCE', 'DELIVERY'],
        },
    );

    const [selectedMonth, setSelectedMonth] = useState(
        moment(props?.location?.state?.payload?.dateRange?.from) ?? moment(),
    );
    const [loading, toggleLoading] = useState(false);
    const [defaultList, setDefaultList] = useState(expenses?.expenseList ?? []);

    const handleChangePage = (event, newPage) => {
        console.log('SETPAGE ', newPage);
        setPage(newPage);
        setPayload((prev) => {
            return {
                ...prev,
                pageNo: newPage + 1,
            };
        });
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
        setPayload((prev) => {
            return {
                ...prev,
                pageNo: 1,
                pageSize: event.target.value,
            };
        });
    };

    useEffect(() => {
        setDefaultList(expenses?.expenseList);
    }, [expenses?.expenseList]);

    // useEffect(() => {
    //     // if (!selectedMonth) return;
    //     setPayload({
    //         ...payload,
    //         dateRange: {
    //             from: moment(selectedMonth).startOf('month').valueOf(),
    //             to: moment(selectedMonth).endOf('month').valueOf(),
    //         },
    //     });
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [selectedMonth]);

    useEffect(() => {
        fetchExpenseReport({ ...payload });
    }, [payload]);

    const fetchExpenseReport = (filters = {}) => {
        toggleLoading(true);
        const payload = {
            ...filters,
            organisationId:
                settings?.basicDetails?.id ?? getLoginUser().organizationId,
        };
        dispatchGetExpenseReport(
            payload,
            (resp) => {
                console.log(resp);
                toggleLoading(false);
            },
            (err) => {
                console.log(err);
                toggleLoading(false);
            },
        );
    };

    return (
        <Paper className={classes.paymentsReportContainer} elevation={0}>
            <Spinner loading={loading}>
                <Box
                    style={{
                        marginBottom: '1rem',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'flex-end',
                    }}
                >
                    <Box
                        style={{
                            width: '20%',
                        }}
                    >
                        {/* <CustomTextField
                            label="Month"
                            InputProps={{
                                readOnly: true,
                                endAdornment: (
                                    <IconButton
                                        className={'inputbutton'}
                                        onClick={(e) => setDatePickerOpen(true)}
                                    >
                                        <InsertInvitationIcon size="small" />
                                    </IconButton>
                                ),
                            }}
                            onClick={(e) => setDatePickerOpen(true)}
                            id="dateEpochMillis"
                            name="dateEpochMillis"
                            value={moment(selectedMonth).format('MMMM YYYY')}
                            variant="outlined"
                        /> */}
                        <CustomMonthPicker
                            defaultValue={moment(
                                props?.location?.state?.payload?.dateRange
                                    ?.from,
                            )}
                            onChange={(e) => {
                                console.log(
                                    'CustomMonthPicker',
                                    moment(e).valueOf(),
                                );
                                setSelectedMonth(e);
                                // setSelectedDate(moment(e));
                            }}
                        />
                    </Box>
                    <Box className={classes.searchBar}>
                        <Tooltip title="Download Report" placement="top">
                            <IconButton
                                className={'inputbutton'}
                                variant="outlined"
                                style={{
                                    marginRight: 16,
                                    border: '1px solid #43419e',
                                    borderRadius: 8,
                                    padding: 6,
                                }}
                                color="primary"
                                onClick={(e) => {
                                    debounce(() => {
                                        toggleLoading(true);
                                        dispatchGetExpenseReport(
                                            {
                                                ...payload,
                                                organisationId:
                                                    settings?.basicDetails?.id,
                                                download: true,
                                            },
                                            (resp) => {
                                                if (
                                                    resp?.response
                                                        ?.expenseReportsData
                                                        ?.s3DownloadLink
                                                ) {
                                                    downloadData(
                                                        resp?.response
                                                            ?.expenseReportsData
                                                            ?.s3DownloadLink,
                                                        'Expense Report',
                                                    );
                                                }
                                                console.log(resp);
                                                toggleLoading(false);
                                            },
                                            (err) => {
                                                console.log(err);
                                                toggleLoading(false);
                                            },
                                        );
                                    });
                                }}
                                disabled={isBouncing || !defaultList?.length}
                            >
                                <GetAppIcon size="small" />
                            </IconButton>
                        </Tooltip>
                        <Searchbar
                            placeHolderText="Search"
                            // placeHolderStyles={{
                            //     color: '#979797',
                            // }}
                            style={{
                                // border: '1px solid #f1f3f6',
                                // color: '#14112d',
                                width: '50%',
                            }}
                            dataSource={[]}
                            keys={['']}
                            fullWidth={false}
                            onFilter={async (filteredData) => {}}
                            onBackendSearch={(searchText) => {
                                setPage(0);
                                setSearchVal(searchText);
                                setPayload((prev) => {
                                    return {
                                        ...prev,
                                        pageNo: 1,
                                        searchText,
                                    };
                                });
                            }}
                            onChange={(value) => {
                                console.log('blur', value);
                                setSearchVal(value);
                            }}
                        />
                    </Box>
                </Box>
                <Box
                    style={{
                        marginBottom: '1rem',
                    }}
                >
                    <Box className={classes.horizontalCardContents}>
                        <Box className={classes.verticalCardContents}>
                            <Typography className={classes.cardHeader}>
                                Total Amount
                            </Typography>
                            <Typography className={classes.cardSubHeader}>
                                {formatCurrency(expenses?.totalAmount ?? 0)}
                            </Typography>
                        </Box>
                        <Box className={classes.verticalCardContents}>
                            <Typography className={classes.cardHeader}>
                                Total Earnings
                            </Typography>
                            <Typography className={classes.cardSubHeader}>
                                {formatCurrency(expenses?.totalEarnings ?? 0)}
                            </Typography>
                        </Box>
                        <Box className={classes.verticalCardContents}>
                            <Typography className={classes.cardHeader}>
                                Total Bonus
                            </Typography>
                            <Typography className={classes.cardSubHeader}>
                                {formatCurrency(expenses?.totalBonus ?? 0)}
                            </Typography>
                        </Box>
                        <Box
                            className={`${classes.verticalCardContents} ${classes.verticalCardContentsNoMargin}`}
                        >
                            <Typography className={classes.cardHeader}>
                                Total Deductions
                            </Typography>
                            <Typography className={classes.cardSubHeader}>
                                {formatCurrency(expenses?.totalDeductions ?? 0)}
                            </Typography>
                        </Box>
                    </Box>
                </Box>

                {defaultList?.length ? (
                    <>
                        <ExpensesListContainer
                            rows={defaultList ?? []}
                            fetchExpenseReport={() =>
                                fetchExpenseReport({ ...payload })
                            }
                            permissions={permissions}
                            payload={payload}
                        />

                        <TablePagination
                            rowsPerPageOptions={[10, 50, 100]}
                            component="div"
                            count={expenses?.totalFilteredCount}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    </>
                ) : (
                    <Box
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <EmptyIcon />
                    </Box>
                )}
            </Spinner>
            {/* {datePickerOpen && (
                <CustomMonthPicker
                    open={datePickerOpen}
                    onOpen={() => setDatePickerOpen(true)}
                    onClose={() => setDatePickerOpen(false)}
                    format="d MMM yyyy"
                    value={selectedMonth}
                    onChange={(e) => {
                        console.log(moment(e).valueOf());
                        setSelectedMonth(e);
                        // setSelectedDate(moment(e));
                    }}
                />
            )} */}
        </Paper>
    );
}

const mapStateToProps = (state) => ({
    expenses: state.report.expenses,
    settings: state.settings,
});

const mapDispatchToProps = (dispatch) => ({
    dispatchGetExpenseReport: (payload, onSuccess, onError) =>
        dispatch(GetExpenseReport(payload, onSuccess, onError)),
});

export const ExpensesReport = withRouter(
    connect(mapStateToProps, mapDispatchToProps)(ExpensesReportComponent),
);
