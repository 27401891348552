import React, { useEffect, useState } from 'react';
import { DefaultRootState, useDispatch, useSelector } from 'react-redux';
import { Spinner } from '../../components/UI';
import useControlCenterStyles from './useControlCenterStyles';
import {
    Button,
    Paper,
    Box,
    Typography,
    makeStyles,
    IconButton,
    Tooltip,
    TablePagination,
} from '@material-ui/core';
import { CONTROL_TABS } from './helper';
import Controls from './components/Controls';
interface ControlCenterPageProps {
    [key: string]: any;
}
const ControlCenterPage: React.FC = ({
    heliosTrackingData = [],
    staffTrackAndTrace,
    ...props
}: ControlCenterPageProps) => {
    //store values
    const { user, staff, settings } = useSelector(
        (store: DefaultRootState | any) => store || {},
    );

    //State values
    const [loading, setLoading] = useState(false);
    const [containerHeight, setContainerHeight] = useState(0);

    //hooks
    const dispatch = useDispatch();
    const containerRef = React.useRef<HTMLElement>(null);
    const classes = useControlCenterStyles({
        containerHeight: containerHeight,
    });
    useEffect(() => {
        if (!!containerRef?.current?.offsetHeight && !containerHeight) {
            const height = containerRef.current.offsetHeight;
            setContainerHeight(height);
        }
    }, [containerRef?.current?.offsetHeight]);
    const handleLoading = (value: boolean) => {
        setLoading(value);
    };
    return (
        <Spinner loading={loading}>
            <Paper
                ref={containerRef}
                style={{ padding: '.1rem' }}
                className={classes.container}
                elevation={0}
            >
                <Controls tabs={CONTROL_TABS} handleLoading={handleLoading} />
            </Paper>
        </Spinner>
    );
};
export default ControlCenterPage;
