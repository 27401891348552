import React, { useState } from 'react';
import {
    Box,
    Typography,
    Tooltip,
    Divider,
    Tabs,
    Tab,
    withStyles,
    AppBar,
    makeStyles,
    useTheme,
    IconButton,
} from '@material-ui/core';
import {
    BatteryIcon,
    LocationMobileOffIcon,
    LocationMobileOnIcon,
    LocationPermissionOffIcon,
    LocationPermissionOnIcon,
    MobileIcon,
} from '../../../../assets/svg';
import { green } from '@material-ui/core/colors';
import { getTabsProps } from '../helper';
import MapTimeline from './MapTimeline';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
interface MapTimelineTabsProps {
    showCloseButton: boolean;
    routes: any;
    aggRoutes: any;
    events: any;
    aggUserEvents: any;
    onDotClick: Function;
    onCloseClick: Function;
    havingEV: boolean;
}
const MapTabs = withStyles({
    root: {
        //borderBottom: '1px solid #e8e8e8',
    },
    indicator: {
        backgroundColor: '#1890ff',
    },
})(Tabs);

const MapTab = withStyles((theme) => ({
    root: {
        textTransform: 'none',
        //minWidth: 72,
        fontWeight: 600,
        marginRight: 0,
        fontSize: 14,
        fontFamily: ['Nunito'].join(','),
        '&:hover': {
            color: '#40a9ff',
            opacity: 1,
        },
        '&$selected': {
            color: '#1890ff',
            fontWeight: 700,
        },
        '&:focus': {
            color: '#40a9ff',
        },
    },
    selected: {},
}))((props) => <Tab disableRipple {...props} />);
function TabPanel(props: any) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && <Box>{children}</Box>}
        </div>
    );
}
const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
    },
    scrollableBox: {
        width: '100%',
        height: '38vh',
        overflowY: 'auto',
        minHeight: '38vh',
        maxHeight: '38vh',
        marginTop: 12,
    },
}));
const MapTimelineTabs: React.FC<MapTimelineTabsProps> = ({
    showCloseButton = false,
    routes = {},
    events = {},
    aggUserEvents,
    onDotClick,
    onCloseClick,
    aggRoutes,
    havingEV,
}) => {
    const classes = useStyles();
    const theme = useTheme();
    const [value, setValue] = React.useState(0);

    const handleChange = (event: any, newValue: any) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index: any) => {
        setValue(index);
    };
    return (
        <Box boxShadow={showCloseButton ? 15 : 0}>
            {showCloseButton && (
                <Box
                    style={{
                        position: 'absolute',
                        left: '-27px',
                        top: '60%',
                    }}
                >
                    <IconButton
                        className={'inputbutton'}
                        onClick={(e) => {
                            !!onCloseClick && onCloseClick();
                        }}
                        style={{
                            padding: '0px',
                            color: 'red',
                            // display: 'flex',
                            // justifyContent: 'flex-end',
                        }}
                    >
                        <CancelRoundedIcon
                            style={{
                                width: 30,
                                height: 30,
                            }}
                        />
                    </IconButton>
                </Box>
            )}
            {!!routes?.length ? (
                <div className={classes.root}>
                    {!!havingEV && (
                        <AppBar
                            position="static"
                            color="inherit"
                            style={{ boxShadow: 'none' }}
                        >
                            <MapTabs
                                value={value}
                                onChange={handleChange}
                                indicatorColor="primary"
                                textColor="primary"
                                variant="fullWidth"
                                aria-label="events timeline"
                            >
                                {routes?.map((tab: any, index: any) => {
                                    return (
                                        <MapTab
                                            key={index}
                                            {...getTabsProps(index, tab)}
                                        />
                                    );
                                })}
                            </MapTabs>
                        </AppBar>
                    )}
                    {routes?.map((tab: any, index: any) => {
                        return (
                            <TabPanel value={value} index={index}>
                                <MapTimeline
                                    showCloseButton={showCloseButton}
                                    trackData={tab}
                                    aggTrackData={aggRoutes?.[index]}
                                    events={events}
                                    aggUserEvents={aggUserEvents}
                                    onDotClick={(point: any) => {
                                        onDotClick(point, index);
                                    }}
                                    havingEV={havingEV}
                                />
                            </TabPanel>
                        );
                    })}
                </div>
            ) : (
                <></>
            )}
        </Box>
    );
};

export default MapTimelineTabs;
