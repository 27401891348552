import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
    Grid,
    Box,
    Paper,
    Typography,
    makeStyles,
    IconButton,
    Tooltip,
} from '@material-ui/core';
import {
    CustomMonthPicker,
    CustomMonthRangePicker,
} from '../../../components/DateTimePicker';
import Searchbar from '../../../components/Searchbar';
import DistanceListContainer from './DistanceListContainer';
import CustomSwitch from '../../../components/Switch';
import GetAppIcon from '@material-ui/icons/GetApp';
import moment from 'moment';
import { Spinner } from '../../../components/UI';
import FilterModal, {
    FilteredState,
    FILTER_INPUT_TYPE,
} from '../../../components/Modals/FilterModal';
import {
    GetDistanceReportFromBeecon,
    GetDistanceReport,
} from '../../../store/actions/reportActionCreator';
import { getMapViewData } from '../../../store/actions/mapViewActionCreator';
import { downloadData, JSONToCSVConvertor } from '../../../utils/helper';
import { useDebounce } from '../../../hooks/useDebounce';
const useStyles = makeStyles((theme) => ({
    paymentsReportContainer: {
        padding: '1.5rem',
    },
    horizontalCardContents: {
        width: '60%',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'spaceBetween',
    },
    searchBar: {
        width: '50%',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
    },
    verticalCardContents: {
        flex: '1 1 25%',
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        justifyContent: 'center',
        border: 'solid 1px #ebedf8',
        borderRadius: '8px',
        backgroundColor: '#f6f7f9',
        paddingLeft: '16px',
        height: '88px',
        margin: '0 8px 0 0 ',
    },
    cardSubHeader: {
        fontWeight: 600,
    },
    cardHeader: {
        fontSize: '20px',
        fontWeight: 700,
    },
}));

const DistanceReportComponent = ({
    staff,
    user,
    distance,
    dispatchGetDistanceReport,
    // dispatchGetDistanceReportFromBeecon,
    dispatchGetMapViewData,
    ...props
}) => {
    const { debounce, isBouncing } = useDebounce();
    const classes = useStyles();
    const [selectedMonth, setSelectedMonth] = useState(moment());
    const [loading, toggleLoading] = useState(true);
    const [defaultList, setDefaultList] = useState(distance ? distance : []);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = React.useState(0);
    const [filteredValues, setFilteredValue] = useState({});
    const [searchText, setSearchText] = React.useState('');
    const [filterDelete, setFilterDelete] = useState(true);

    useEffect(() => {
        console.log('selectedMonth', selectedMonth);
        if (!selectedMonth) return;
        // eslint-disable-next-line react-hooks/exhaustive-deps
        getDistanceReportData(selectedMonth);
    }, [selectedMonth, rowsPerPage, page, searchText, filterDelete]);

    function getDistanceReportData(selectedMonth, download = false) {
        let startDate = moment(selectedMonth).startOf('month').valueOf();
        let endDate = moment(selectedMonth).endOf('month').valueOf();
        toggleLoading(true);
        dispatchGetDistanceReport(
            {
                dateRange: {
                    from: startDate,
                    to: endDate,
                },
                pageNo: page,
                pageSize: rowsPerPage,
                searchText: searchText,
                download: download,
                hubId: filteredValues?.hubIds?.value,
            },
            (resp) => {
                console.log('getDistanceReportData Success');
                if (resp?.response?.s3FileUrl) {
                    downloadData(resp?.response?.s3FileUrl, 'Distance Report');
                }
                toggleLoading(false);
            },
            () => {
                console.log('getDistanceReportData Failed');
                toggleLoading(false);
            },
        );
    }

    // function getDataFromBeecon(month) {
    //     let payload = {
    //         action: 'getDistanceReport',
    //         bo: { id: 'bo_report_fleet_vehicle-distance', filters: [] },
    //         data: {
    //             type: 'profile',
    //             subtype: 'vehicle',
    //             org: {
    //                 id:
    //                     user?.config?.beeconOrgId ??
    //                     'profile_organization_KPZJLPF38387',
    //             },
    //             startDay: moment(month)
    //                 .startOf('month')
    //                 .utc()
    //                 .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
    //             endDay: moment(month)
    //                 .endOf('month')
    //                 .utc()
    //                 .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
    //         },
    //         resolveDeps: false,
    //     };
    //     // dispatchGetDistanceReportFromBeecon(
    //     //     { token: `Bearer ${user?.config?.beeconAPIToken}`, data: payload },
    //     //     () => {},
    //     // );
    // }

    // useEffect(() => {
    //     dispatchGetMapViewData(
    //         {},
    //         (resp) => {
    //             console.log('MapView API Call SUCCESS', resp);
    //             if (resp.response?.lineItems?.length > 0) {
    //                 let tempMapping = [];
    //                 resp.response?.lineItems?.map((data, index) => {
    //                     tempMapping.push({
    //                         name: data?.name,
    //                         id: data?.beeconVehicleId,
    //                     });
    //                 });

    //                 setRows(tempMapping);
    //             }
    //         },
    //         () => {
    //             console.log('MapView API Call FAILED');
    //         },
    //     );
    // }, []);

    useEffect(() => {
        setDefaultList(distance);
    }, [distance]);

    function getDaysArrayByMonth(month) {
        var daysInMonth = moment(month).daysInMonth();
        var arrDays = [];

        while (daysInMonth) {
            var current = moment(month).date(daysInMonth).startOf('day');
            arrDays.push(current);
            daysInMonth--;
        }

        return arrDays;
    }

    const prepareFilterOptions = () => {
        return [
            {
                type: FILTER_INPUT_TYPE.SELECT,
                label: 'Hubs',
                name: 'hubs',
                value: filteredValues?.hubIds,
                options: staff?.hubs?.map((item) => ({
                    label: item.name,
                    value: item.id,
                })),
                onChange: async (e) => {
                    setFilteredValue({
                        ...filteredValues,
                        hubs: e?.target?.value?.label,
                        hubIds: e?.target?.value,
                    });
                },
            },
        ];
    };

    return (
        <Paper className={classes.paymentsReportContainer} elevation={0}>
            <Spinner loading={loading}>
                <Box>
                    <Box style={{ marginBottom: '1rem' }}>
                        <Box
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                            }}
                        >
                            <Box
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <Box
                                    style={{
                                        width: '80%',
                                    }}
                                >
                                    <CustomMonthPicker
                                        onChange={(e) => {
                                            console.log(moment(e).valueOf());
                                            setSelectedMonth(e);
                                            // setSelectedDate(moment(e));
                                        }}
                                    />
                                </Box>
                                <FilterModal
                                    filterOptions={prepareFilterOptions()}
                                    filteredValues={filteredValues}
                                    onFilterApplied={getDistanceReportData}
                                />
                                <FilteredState
                                    filterOptions={prepareFilterOptions()}
                                    filteredValues={filteredValues}
                                    onDelete={(filterKey) => {
                                        let newFilteredValues = {
                                            ...filteredValues,
                                        };
                                        delete newFilteredValues?.[filterKey];
                                        delete newFilteredValues?.['hubIds'];
                                        setFilteredValue(newFilteredValues);
                                        filterDelete
                                            ? setFilterDelete(false)
                                            : setFilterDelete(true);
                                    }}
                                />
                            </Box>
                            <Box className={classes.searchBar}>
                                <Tooltip
                                    title="Download Report"
                                    placement="top"
                                >
                                    <IconButton
                                        className={'inputbutton'}
                                        variant="outlined"
                                        style={{
                                            marginRight: 16,
                                            border: '1px solid #43419e',
                                            borderRadius: 8,
                                            padding: 6,
                                        }}
                                        disabled={
                                            isBouncing ||
                                            !defaultList?.staffs
                                                ?.map((i) => i.distances)
                                                ?.flat()
                                                ?.filter((i) => i !== '-')
                                                ?.length
                                        }
                                        color="primary"
                                        onClick={(e) => {
                                            debounce(() => {
                                                getDistanceReportData(
                                                    selectedMonth,
                                                    true,
                                                );
                                            });
                                            // const newArr = [];
                                            // defaultList?.staffs?.map(
                                            //     (value) => {
                                            //         let obj = {
                                            //             [moment(
                                            //                 selectedMonth,
                                            //             ).format('MMM YYYY')]:
                                            //                 value.name,
                                            //             ['Vehicle No']:
                                            //                 value.vehicleNumber,
                                            //             ['Hub Name']:
                                            //                 value.hubName,
                                            //         };
                                            //         getDaysArrayByMonth(
                                            //             selectedMonth,
                                            //         )
                                            //             .reverse()
                                            //             .map((val, index) => {
                                            //                 obj = {
                                            //                     ...obj,
                                            //                     [`${moment(
                                            //                         val,
                                            //                     ).format(
                                            //                         'DD',
                                            //                     )} ${moment(
                                            //                         val,
                                            //                     ).format(
                                            //                         'ddd',
                                            //                     )}`]: {
                                            //                         odo: value
                                            //                             ?.distances[
                                            //                             index
                                            //                         ]
                                            //                             ?.userAttendanceBo
                                            //                             ?.odometerReading
                                            //                             ? value
                                            //                                   .distances[
                                            //                                   index
                                            //                               ]
                                            //                                   .userAttendanceBo
                                            //                                   .odometerReading
                                            //                             : '-',
                                            //                         GPS: value
                                            //                             ?.distances[
                                            //                             index
                                            //                         ]
                                            //                             ?.userDistanceBo
                                            //                             ?.distanceData
                                            //                             ? value
                                            //                                   .distances[
                                            //                                   index
                                            //                               ]
                                            //                                   .userDistanceBo
                                            //                                   .distanceData
                                            //                             : '-',
                                            //                     },
                                            //                     // value
                                            //                     //     ?.distances[
                                            //                     //     index
                                            //                     // ],
                                            //                 };
                                            //                 return '';
                                            //             });
                                            //         newArr.push(obj);
                                            //         return '';
                                            //     },
                                            // );
                                            // console.log(newArr);
                                            // JSONToCSVConvertor(
                                            //     'Distance Report',
                                            //     newArr,
                                            //     'Distance Report',
                                            //     true,
                                            //     true,
                                            // );
                                        }}
                                    >
                                        <GetAppIcon size="small" />
                                    </IconButton>
                                </Tooltip>
                                <Searchbar
                                    placeHolderText="Search"
                                    // placeHolderStyles={{
                                    //     color: '#979797',
                                    // }}
                                    style={{
                                        // border: '1px solid #f1f3f6',
                                        // color: '#14112d',
                                        width: '50%',
                                    }}
                                    dataSource={[]}
                                    keys={['']}
                                    fullWidth={false}
                                    onFilter={async (filteredData) => {}}
                                    backendSearchbar={true}
                                    onBackendSearch={(searchText) => {
                                        setPage(0);
                                        setSearchText(searchText);
                                        console.log('debounced');
                                    }}
                                />
                            </Box>
                        </Box>
                    </Box>

                    <Box>
                        <DistanceListContainer
                            selectedMonth={selectedMonth}
                            rows={defaultList ?? []}
                            rowsPerPage={rowsPerPage}
                            setRowsPerPage={setRowsPerPage}
                            page={page}
                            setPage={setPage}
                            getDistanceReportData={getDistanceReportData}
                        />
                    </Box>
                </Box>
            </Spinner>
        </Paper>
    );
};

const mapStateToProps = (state) => ({
    staff: state.staff,
    distance: state.report.distance,
    user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
    // dispatchGetDistanceReportFromBeecon: (payload, onSuccess, onError) =>
    //     dispatch(GetDistanceReportFromBeecon(payload, onSuccess, onError)),
    dispatchGetMapViewData: (payload, onSuccess, onError) =>
        dispatch(getMapViewData(payload, onSuccess, onError)),
    dispatchGetDistanceReport: (payload, onSuccess, onError) =>
        dispatch(GetDistanceReport(payload, onSuccess, onError)),
});

export const DistanceReport = connect(
    mapStateToProps,
    mapDispatchToProps,
)(DistanceReportComponent);
