import * as constants from './../constants';

const defaultState = {
    basicDetails: {},
};

export default function settingsReducer(state = defaultState, action) {
    switch (action.type) {
        case constants.SET_SETTINGS_HUBS:
            return { ...state, hubs: { ...action.payload } };
        case constants.SET_ORGANIZATION:
            return { ...state, ...action.payload };
        case constants.SET_SETTINGS_STATE:
            return { ...state, ...action.payload };
        case constants.SET_SETTINGS_CITIES:
            return { ...state, ...action.payload };
        case constants.SET_SETTINGS_CITY_ZONES:
            return { ...state, ...action.payload };
        case constants.SET_SETTINGS_BASIC_DATA:
            return { ...state, basicDetails: action.payload };
        case constants.SET_SETTINGS_VENDORS_LIST:
            return { ...state, vendorsList: action.payload };
        case constants.SET_SETTINGS_CLIENTS_LIST:
            return { ...state, clientsList: action.payload };
        case constants.SET_SETTINGS_TELEMATICS_LIST:
            return { ...state, telematicsList: action.payload };
        case constants.SET_SETTINGS_EV_VEHICLES_LIST:
            return { ...state, evList: action.payload };
        case constants.SET_SETTINGS_EV_VEHICLES_LIST_V2:
            return { ...state, evListV2: action.payload };
        case constants.SET_SETTINGS_EV_VENDOR_DATA:
            return {
                ...state,
                evVendorList: action.payload,
            };
        case constants.SET_SETTINGS_EV_CAPTAIN_DATA:
            return {
                ...state,
                evCaptainList: action.payload,
            };
        case constants.SET_SETTINGS_EV_TEAM_DATA:
            return {
                ...state,
                evTeamList: action.payload,
            };
        case constants.SET_SETTINGS_EV_PROJECTS_DATA:
            return {
                ...state,
                evProjectList: action.payload,
            };
        case constants.SET_SETTINGS_EV_UNTAG_REASONS_DATA:
            return {
                ...state,
                evUntagReasons: action.payload,
            };
        case constants.SET_SETTINGS_EV_HISTORY_DATA:
            return {
                ...state,
                evHistoryList: action.payload,
            };
        case constants.SET_SETTINGS_SHIFT_LIST:
            return { ...state, ...action.payload };
        case constants.SET_SETTINGS_SHIFT_USER_LIST:
            return { ...state, shiftUsers: { ...action.payload } };
        case constants.SET_PREFERRED_WORK_LOCATIONS:
            return { ...state, ...action.payload };
        default:
            return state;
    }
}
