import React, { useState } from 'react';
import {
    Box,
    Typography,
    Tooltip,
    Divider,
    Avatar,
    Button,
    makeStyles,
} from '@material-ui/core';
import TrackPlayer from './TrackPlayer';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
interface PlayBackControlProps {
    enableControl: any;
    onPlayBackClick: Function;
    playing: boolean;
    onPlayPress: Function;
    onPausePress: Function;
    onRestartPress: Function;
    onStopPress: Function;
    onForwardPress: Function;
    onRewindPress: Function;
    onSpeedChange: Function;
    onSliderChange: Function;
    speed: any;
    currentIndexes: any;
    routes: any;
}
const useStyles = makeStyles((theme) => ({
    button: {
        margin: theme.spacing(1),
    },
}));

const PlayBackControl: React.FC<PlayBackControlProps> = ({
    enableControl = false,
    onPlayBackClick,
    playing,
    onPlayPress,
    onPausePress,
    onRestartPress,
    onStopPress,
    onForwardPress,
    onRewindPress,
    onSpeedChange,
    onSliderChange,
    speed,
    currentIndexes,
    routes = [],
}) => {
    const classes = useStyles();
    const onPlay = () => {
        !!onPlayPress && onPlayPress();
    };
    const onPause = () => {
        !!onPausePress && onPausePress();
    };
    const onRestart = () => {
        !!onRestartPress && onRestartPress();
    };
    const onStop = () => {
        !!onStopPress && onStopPress();
    };
    const onForward = () => {
        !!onForwardPress && onForwardPress();
    };
    const onRewind = () => {
        !!onRewindPress && onRewindPress();
    };
    const onChangeSpeed = (speed: number) => {
        !!onSpeedChange && onSpeedChange(speed);
    };
    const onChangeSlider = (index: number, value: number) => {
        !!onSliderChange && onSliderChange(index, value);
    };
    let slideValue = routes?.map((route: any, routeIdx: number) => {
        return {
            max: route?.coordinates?.length - 1,
            value: currentIndexes?.[routeIdx],
            index: routeIdx,
            name: route?.ui?.sliderName,
            sliderNameColor: route?.ui?.sliderNameColor,
        };
    });
    return (
        <Box
            flexDirection={'row'}
            display={'flex'}
            //alignItems={'center'}
            justifyContent={'center'}
        >
            {!!enableControl ? (
                <TrackPlayer
                    onPlay={onPlay}
                    onPause={onPause}
                    onChangeSlider={onChangeSlider}
                    onChangeSpeed={onChangeSpeed}
                    onForward={onForward}
                    onRestart={onRestart}
                    onRewind={onRewind}
                    onStop={onStop}
                    sliderValue={slideValue}
                    speed={speed}
                    disablePause={!playing}
                    disablePlay={!!playing}
                />
            ) : (
                <Button
                    startIcon={<PlayArrowIcon />}
                    style={{
                        padding: '6px 12px',
                    }}
                    className={classes.button}
                    disableElevation
                    variant="contained"
                    //fullWidth
                    color="primary"
                    onClick={() => {
                        onPlayBackClick(true);
                    }}
                >
                    Play Back
                </Button>
            )}
        </Box>
    );
};

export default PlayBackControl;
