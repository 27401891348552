import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    componentDidMount() {}
    render() {
        return (
            <div>
                Home Page <br />
                {/* <button onClick={() => this.props.history.push('/login')}>cloc</button> */}
                <Link to={'/login'}>login</Link>
            </div>
        );
    }
}
