import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
interface useRiderMapHelpersReturnType {}

const useRiderMapHelpers = ({ user }: any) => {
    const playBackUI: any = {
        device: {
            polyLineColor: 'red',
            polyLineWidth: 5,
            polyLineOpacity: 1,
            polyLineDashArray: [],
            polyLineFillColor: 'red',
            polyLineFillOpacity: 0,
            markerColor: 'black',
            sliderName: 'Device',
            sliderNameColor: 'red',
        },
        ev: {
            polyLineColor: 'green',
            polyLineWidth: 2,
            polyLineOpacity: 1,
            polyLineDashArray: [],
            polyLineFillColor: 'green',
            polyLineFillOpacity: 0,
            markerColor: 'blue',
            sliderName: 'EV',
            sliderNameColor: 'green',
        },
    };
    const generateDeviceTrackingPayload = (
        staff: any,
        selectedDate: any,
        started?: any,
        ended?: any,
    ) => {
        const startTime = !!started
                ? started
                : staff?.eventDetails?.inTimeEpochMillis > 0
                ? staff?.eventDetails?.inTimeEpochMillis
                : moment(selectedDate).startOf('day').valueOf(),
            endTime = !!ended
                ? ended
                : staff?.eventDetails?.outTimeEpochMillis > 0
                ? moment(staff?.eventDetails?.outTimeEpochMillis)
                      .add(5, 'minutes')
                      .valueOf()
                : moment(selectedDate).endOf('day').valueOf();
        let tripStart =
            startTime &&
            moment(startTime)
                .add(1, 'minutes')
                .utc()
                .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
        let tripEnd =
            endTime &&
            moment(endTime).utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');

        const payload = {
            action: 'getTripRouteDetails',
            data: {
                type: 'contract',
                subtype: 'trip',

                vehicle: {
                    id: staff?.beeconVehicleId,
                },
                org: {
                    id: user?.config?.beeconOrgId,
                },

                tripStart: {
                    startTime: tripStart,
                },
                tripClosure: { endTime: tripEnd },
            },
            timeOffset: 330,
            resolveDeps: false,
        };
        return payload;
    };
    const generateEVTrackingPayload = (
        staff: any,
        selectedDate: any,
        started?: any,
        ended?: any,
    ) => {
        const startTime = !!started
                ? started
                : staff?.eventDetails?.inTimeEpochMillis > 0
                ? staff?.eventDetails?.inTimeEpochMillis
                : moment(selectedDate).startOf('day').valueOf(),
            endTime = !!ended
                ? ended
                : staff?.eventDetails?.outTimeEpochMillis > 0
                ? moment(staff?.eventDetails?.outTimeEpochMillis)
                      .add(5, 'minutes')
                      .valueOf()
                : moment(selectedDate).endOf('day').valueOf();
        let tripStart =
            startTime &&
            moment(startTime)
                .add(1, 'minutes')
                .utc()
                .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
        let tripEnd =
            endTime &&
            moment(endTime).utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');

        const payload = {
            action: 'getTripRouteDetails',
            data: {
                type: 'contract',
                subtype: 'trip',

                vehicle: {
                    id: `profile_vehicle_${staff?.eventDetails?.evMapViewInfo?.beeconVehicleImei?.toUpperCase()}`, //evMapViewInfo?.beeconVehicleId,
                },
                org: {
                    id: 'profile_organization_LODXW8483080', //user?.config?.beeconOrgId,
                },
                tripStart: {
                    startTime: tripStart,
                },
                tripClosure: { endTime: tripEnd },
            },
            timeOffset: 330,
            resolveDeps: false,
        };
        return payload;
    };

    const generateAllPayload = (
        routes: any,
        staff: any,
        startTime: any,
        endTime: any,
    ) => {
        let payloads: any = [];
        routes?.forEach((route: any) => {
            switch (route?.type) {
                case 'device':
                    payloads.push(
                        generateDeviceTrackingPayload(
                            staff,
                            null,
                            startTime,
                            endTime,
                        ),
                    );
                    break;
                case 'ev':
                    payloads.push(
                        generateEVTrackingPayload(
                            staff,
                            null,
                            startTime,
                            endTime,
                        ),
                    );
                    break;
                default:
                    break;
            }
        });
        return payloads;
    };
    const generateVehicleListPayload = (ids: any) => {
        return {
            action: 'getMultiVehicle',
            data: {
                type: 'profile',
                subtype: 'vehicle',
                org: {
                    id: user?.config?.beeconOrgId,
                },
                id: ids,
            },
            bo: { id: 'bo_trade_fleet_management' },
            resolveDeps: false,
        };
    };

    const generateIdlePoints = (trackData: any, events: any) => {
        let idlePoints: Array<[]> = [];
        if (events?.userActivityLineItems?.length) {
            idlePoints = [
                ...idlePoints,
                ...events?.userActivityLineItems?.map((item: any) => ({
                    ...item,
                    title: item?.description,
                    description: item?.properties?.['Token number']
                        ? `Token number - ${item?.properties?.['Token number']}`
                        : '',
                })),
            ];
        }
        if (trackData && trackData?.idleLocationPoints?.length) {
            idlePoints = [
                ...idlePoints,
                ...trackData?.idleLocationPoints?.map((item?: any) => {
                    const idlePoint = item?.split(',');
                    return {
                        kmReading: idlePoint[4],
                        description: idlePoint[idlePoint?.length - 1],
                        latitude: idlePoint[2],
                        longitude: idlePoint[3],
                        startTime: idlePoint[0],
                        endTime: idlePoint[1],
                        time: idlePoint[1],
                        type: 'IDLE_POINT',
                    };
                }),
            ];
        }
        return idlePoints;
    };
    const getPlayBackData = (locationPoints: any = []) => {
        let points: Array<[]> =
            locationPoints?.map((item: any) => {
                return {
                    status: 1,
                    time: item?.deviceTimeEpoch,
                    course: item?.angle,
                    lat: item?.latitude,
                    lng: item?.longitude,
                    angle: item?.angle,
                };
            }) ?? [];
        return points;
    };
    const getCoordinates = (locationPoints: any = []) => {
        let points: Array<[]> =
            locationPoints?.map(({ latitude, longitude }: any) => [
                latitude,
                longitude,
            ]) ?? [];
        return points;
    };

    const generateStoppageReport = (idlePoints: any = [], trackData: any) => {
        return idlePoints
            ?.sort((a?: any, b?: any) => a?.time - b?.time)
            ?.filter((idlePoint?: any) => {
                console.log('idlePoints', idlePoint);
                return (
                    parseInt(idlePoint?.time) >=
                        trackData?.locationPoints?.[0]?.deviceTimeEpoch &&
                    parseInt(idlePoint?.time) <=
                        trackData?.locationPoints?.[
                            trackData?.locationPoints?.length - 1
                        ]?.deviceTimeEpoch
                );
            });
    };
    const generateStartStopPointFromTrackData = (trackData: any) => {
        return {
            selectedStartStoppagePoint:
                trackData?.locationPoints?.[0]?.deviceTimeEpoch ?? null,
            selectedStartStoppagePointLocationData:
                trackData?.locationPoints?.[0] ?? null,
            selectedEndStoppagePoint:
                trackData?.locationPoints?.[
                    trackData?.locationPoints?.length - 1
                ]?.deviceTimeEpoch ?? null,
            selectedEndStoppagePointLocationData:
                trackData?.locationPoints?.[
                    trackData?.locationPoints?.length - 1
                ] ?? null,
        };
    };

    const generateRoutesData = (staff: any) => {
        let routes: any = [];
        if (staff?.trackingDetails) {
            routes.push({
                ...staff?.trackingDetails,
                name: 'Rider App Tracking',
                type: 'device',
                polyLineColor: 'black',
                playBackColor: 'red',
                polyLineWeight: 5,
                markerLabel: staff?.vehicleNumber ?? '',
                stoppageReportData: generateStoppageReport(
                    generateIdlePoints(
                        staff?.trackingDetails,
                        staff?.eventDetails,
                    ),
                    staff?.trackingDetails,
                ),
                ...generateStartStopPointFromTrackData(staff?.trackingDetails),
                coordinates: getCoordinates(
                    staff?.trackingDetails?.locationPoints,
                ),
            });
        }
        if (staff?.evTrackingDetails) {
            routes.push({
                ...staff?.evTrackingDetails,
                name: 'EV Tracking',
                type: 'ev',
                polyLineColor: 'blue',
                playBackColor: 'green',
                polyLineWeight: 3,
                markerLabel: staff?.eventDetails?.evMapViewInfo?.evNumber ?? '',
                stoppageReportData: generateStoppageReport(
                    generateIdlePoints(
                        staff?.evTrackingDetails,
                        staff?.eventDetails,
                    ),
                    staff?.evTrackingDetails,
                ),
                ...generateStartStopPointFromTrackData(
                    staff?.evTrackingDetails,
                ),
                coordinates: getCoordinates(
                    staff?.evTrackingDetails?.locationPoints,
                ),
            });
        }
        return routes;
    };
    const generateRoutePlayBackData = (routes: any = []) => {
        return routes?.map((route: any) => {
            return {
                coordinates: getCoordinates(route?.locationPoints),
                markerLabel: route?.markerLabel,
                ui:
                    !!route?.type && playBackUI?.[`${route?.type}`]
                        ? playBackUI?.[`${route?.type}`]
                        : {},
                ...generateStartStopPointFromTrackData(route),
                playBackData: getPlayBackData(route?.locationPoints),
            };
        });
    };
    useEffect(() => {
        return () => {};
    }, []);

    return {
        generateDeviceTrackingPayload,
        generateEVTrackingPayload,
        generateVehicleListPayload,
        generateIdlePoints,
        generateStoppageReport,
        generateRoutesData,
        generateRoutePlayBackData,
        generateAllPayload,
    };
};

export default useRiderMapHelpers;
