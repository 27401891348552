import {
    Box,
    Button,
    IconButton,
    List,
    ListItem,
    ListItemSecondaryAction,
    makeStyles,
    Paper,
    TablePagination,
    Typography,
    withStyles,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import CustomDropdown from '../../components/Dropdown';
import HubModal from '../../components/Modals/HubModal';
import Searchbar from '../../components/Searchbar';
import Modal from '../../components/Modals/Modal';
import { Spinner } from '../../components/UI';
import { EmptyIcon } from '../../assets/svg';
import { connect } from 'react-redux';
import {
    DeleteHub,
    GetCities,
    GetDeliveryHubs,
} from '../../store/actions/settingsActionCreator';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { HubSwitchKey } from './Settings';
import FilterModal, {
    FilteredState,
    FILTER_INPUT_TYPE,
} from '../../components/Modals/FilterModal';
import ShiftColumnItemPopper from '../../components/Containers/ShiftColumnItemPopper';
import { HUB_TYPE_ENUM } from '../../utils/constants';

const useStyles = makeStyles((theme) => ({
    listItemHeader: {
        border: '0px',
        // marginBottom: '8px',
        '& .MuiTypography-body1': {
            color: '#808080',
            fontSize: 16,
            fontWeight: 'bold',
        },
    },
    listItem: {
        padding: '16px 16px 16px 16px',
        marginBottom: '8px',
        border: '1px solid #ebedf8 ',
        borderRadius: '8px',
        '& .MuiTypography-body1': {
            fontSize: 16,
            fontWeight: 400,
        },
        '&:nth-child(odd)': {
            backgroundColor: '#f8f9fa',
        },
        '&:hover': {
            backgroundColor: 'rgba(67,65,158,.1) !important',
            // cursor: 'pointer',
        },
    },
    saveButton: {
        backgroundColor: theme.palette.error.main,
        color: '#fff',
    },
}));
const DeliveryHubComponent = ({
    cityList,
    hubInfo,
    permissions,
    hubNShiftScreenSwitch,
    setHubNShiftScreenSwitch,
    setSelectedHub,
    dispatchGetDeliveryHubs,
    dispatchDeleteHub,
    dispatchGetCities,
    ...props
}) => {
    const classes = useStyles();
    const [isOpen, toggleModal] = useState(false);
    const [loading, toggleLoading] = useState(false);
    const [selectedRow, setSelectedRow] = useState();
    const [isDeleteModalOpen, toggleDeleteModal] = useState(false);
    const [deleteLoading, toggleDeleteLoading] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [page, setPage] = React.useState(props.location?.state?.page ?? 0);
    const [rowsPerPage, setRowsPerPage] = React.useState(
        props?.location?.state?.rowsPerPage ?? 10,
    );
    const [searchVal, setSearchVal] = useState(
        props.location?.state?.searchVal ?? '',
    );
    const [hubListPayload, setHubListPayload] = React.useState({
        pageNo: page + 1,
        pageSize: rowsPerPage,
        searchText: searchVal,
    });
    const [filteredValues, setFilteredValue] = useState({});

    useEffect(() => {
        // fetchCities();
    }, []);
    useEffect(() => {
        fetchHubList({ ...hubListPayload });
    }, [hubListPayload]);
    useEffect(() => {
        fetchHubList({ ...hubListPayload, searchText: searchVal });
    }, [searchVal]);

    const handleClose = () => {
        // Setting form data to null before closing

        toggleModal(false);
    };
    const openEditModal = async (row) => {
        await setSelectedRow(row);
        toggleModal(true);
    };
    const openDeleteModal = async (row) => {
        await setSelectedRow(row);
        toggleDeleteModal(true);
    };
    const handleDeleteClose = () => {
        // Setting form data to null before closing
        toggleDeleteModal(false);
        fetchHubList({ ...hubListPayload });
    };

    const fetchCities = async () => {
        await toggleLoading(true);
        dispatchGetCities(
            {},
            (resp) => {
                toggleLoading(false);
            },
            (err) => {
                toggleLoading(false);
            },
        );
    };

    const fetchHubList = async (filters = {}) => {
        await toggleLoading(true);
        const payload = {
            ...filters,
        };
        dispatchGetDeliveryHubs(
            payload,
            (resp) => {
                console.log(resp);
                toggleLoading(false);
            },
            (resp) => {
                console.log(resp);
                toggleLoading(false);
            },
        );
    };

    const deleteHubList = async () => {
        await toggleDeleteLoading(true);
        dispatchDeleteHub(
            selectedRow.id,
            (resp) => {
                console.log(resp);
                toggleDeleteLoading(false);
                handleDeleteClose();
            },
            (err) => {
                console.log(err);
                toggleDeleteLoading(false);
                handleDeleteClose();
            },
        );
    };

    const actions = [
        <Button
            key={1}
            variant="contained"
            color="default"
            className={`cancelButton`}
            onClick={handleDeleteClose}
            disableElevation
        >
            {'No'}
        </Button>,
        <Button
            key={2}
            variant="contained"
            color="default"
            className={`${classes.saveButton}`}
            onClick={(e) => deleteHubList()}
            disableElevation
        >
            {'Yes'}
        </Button>,
    ];

    const FilteredHubList = hubInfo?.hubs;
    const handleChangePage = (event, newPage) => {
        console.log('SETPAGE ', newPage);
        setPage(newPage);
        setHubListPayload((prev) => {
            return {
                ...prev,
                pageNo: newPage + 1,
            };
        });
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
        setHubListPayload((prev) => {
            return {
                ...prev,
                pageNo: 1,
                pageSize: event.target.value,
            };
        });
    };

    const prepareFilterOptions = () => {
        return [
            {
                type: FILTER_INPUT_TYPE.MULTISELECT,
                label: filteredValues?.cities?.length ? 'City' : 'Select City',
                name: 'city',
                value: filteredValues?.cities,
                options: cityList?.map((city) => ({
                    label: city.name,
                    value: city.id,
                })),
                onChange: (e) => {
                    setFilteredValue({
                        ...filteredValues,
                        cities: e?.target?.value,
                    });
                },
            },
        ];
    };
    return (
        <Paper style={{ padding: '1.5rem' }} elevation={0}>
            <Spinner loading={loading}>
                <Box
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'flex-start',
                        marginBottom: '4px',
                    }}
                >
                    <Box
                        className="horiz-end"
                        style={{
                            width: '50%',
                        }}
                    >
                        <Searchbar
                            placeHolderText="Search by Hub Name"
                            style={{
                                color: '#14112d',
                            }}
                            onChange={setSearchVal}
                            onFilter={() => {
                                console.log('search bar filtering');
                            }}
                        />
                        {/* <FilterModal
                            filterOptions={prepareFilterOptions()}
                            filteredValues={filteredValues}
                            onFilterApplied={() => {
                                setHubListPayload((prev) => {
                                    return {
                                        ...prev,
                                        cityIds:
                                            filteredValues?.cities?.map(
                                                (city) => city?.value,
                                            ) ?? null,
                                    };
                                });
                            }}
                        /> */}
                    </Box>
                    <Box>
                        {false && (
                            <IconButton
                                className={'inputbutton'}
                                variant="outlined"
                                style={{
                                    marginRight: 8,
                                    border: '1px solid #43419e',
                                    borderRadius: 8,
                                    padding: 6,
                                }}
                                color="primary"
                                onClick={(e) => {}}
                            >
                                <ClearAllIcon
                                    size="small"
                                    style={{
                                        transform: 'skewX(180deg)',
                                    }}
                                />
                            </IconButton>
                        )}
                        <Button
                            style={{
                                marginRight: '1rem',
                                fontSize: 14,
                            }}
                            variant="contained"
                            color="primary"
                            onClick={(e) => toggleModal(true)}
                            disabled={!permissions.create}
                            startIcon={<AddIcon />}
                            disableElevation
                        >
                            {'Add Hub'}
                        </Button>
                    </Box>
                </Box>
                {/* <FilteredState
                    filterOptions={prepareFilterOptions()}
                    filteredValues={filteredValues}
                    onDelete={(filterKey) => {
                        let newFilteredValues = { ...filteredValues };
                        delete newFilteredValues?.[filterKey];
                        setFilteredValue(newFilteredValues);
                        setHubListPayload((prev) => {
                            return {
                                ...prev,
                                cityIds:
                                    newFilteredValues?.cities?.map(
                                        (city) => city?.value,
                                    ) ?? null,
                            };
                        });
                    }}
                /> */}

                {!!FilteredHubList?.length && (
                    <List component="nav">
                        <ListItem className={classes.listItemHeader}>
                            <Box
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    alignItems: 'flex-start',
                                    width: '100%',
                                }}
                            >
                                <Box
                                    style={{
                                        width: '25%',
                                        justifySelf: 'flex-start',
                                    }}
                                >
                                    <Typography
                                        style={{
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        Hub Name
                                    </Typography>
                                </Box>
                                <Box
                                    style={{
                                        width: '25%',
                                        justifySelf: 'flex-start',
                                    }}
                                >
                                    <Typography
                                        style={{
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        Business
                                    </Typography>
                                </Box>
                                <Box
                                    style={{
                                        width: '25%',
                                        justifySelf: 'flex-start',
                                    }}
                                >
                                    <Typography
                                        style={{
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        Zone
                                    </Typography>
                                </Box>
                                <Box
                                    style={{
                                        width: '20%',
                                        justifySelf: 'flex-start',
                                    }}
                                >
                                    <Typography
                                        style={{
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        Staff Count
                                    </Typography>
                                </Box>
                                {/* <Box
                                    style={{
                                        width: '15%',
                                        justifySelf: 'flex-start',
                                    }}
                                >
                                    <Typography
                                        style={{
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        Shift Count
                                    </Typography>
                                </Box> */}
                                <Box
                                    style={{
                                        width: '60%',
                                        justifySelf: 'flex-start',
                                    }}
                                >
                                    <Typography
                                        style={{
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        Shifts
                                    </Typography>
                                </Box>
                                <Box
                                    style={{
                                        width: '20%',
                                        justifySelf: 'flex-start',
                                    }}
                                >
                                    {' '}
                                </Box>
                            </Box>
                        </ListItem>

                        {FilteredHubList?.map((row, index) => (
                            <ListItem
                                // button
                                key={index}
                                className={classes.listItem}
                                onClick={async () => {
                                    await setSelectedHub(row);
                                    await setHubNShiftScreenSwitch(
                                        HubSwitchKey.HUB_DETAIL_VIEW,
                                    );
                                }}
                            >
                                <Box
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        width: '100%',
                                        fontWeight: 600,
                                        fontSize: 16,
                                    }}
                                >
                                    <Box
                                        style={{
                                            width: '25%',
                                            justifySelf: 'flex-start',
                                        }}
                                    >
                                        <Typography
                                            style={{
                                                fontWeight: 600,
                                                fontSize: 16,
                                            }}
                                        >
                                            {(() => {
                                                let name = row.name;
                                                name =
                                                    name?.length > 18
                                                        ? name?.slice(0, 15) +
                                                          '...'
                                                        : name;
                                                return name;
                                            })()}
                                        </Typography>
                                    </Box>
                                    <Box
                                        style={{
                                            width: '25%',
                                            justifySelf: 'flex-start',
                                        }}
                                    >
                                        {(() => {
                                            let color = '#f44336';
                                            color =
                                                HUB_TYPE_ENUM[
                                                    row?.primaryOperation?.toUpperCase()
                                                ]?.color ?? '#f44336';
                                            // switch (row?.primaryOperation) {
                                            //     case HUB_TYPE_ENUM.ECOMMERCE:
                                            //         color = '#43419e';
                                            //         break;
                                            //     case HUB_TYPE_ENUM.HYPERLOCAL:
                                            //         color = '#f44336';
                                            //         break;
                                            //     case HUB_TYPE_ENUM.STORE:
                                            //         color = '#579b5a';
                                            //         break;

                                            //     default:
                                            //         color = '#f44336';
                                            //         break;
                                            // }
                                            return (
                                                <Typography
                                                    style={{
                                                        color,
                                                        fontWeight: 'bold',
                                                        fontSize: 14,
                                                    }}
                                                >
                                                    {
                                                        HUB_TYPE_ENUM[
                                                            row?.primaryOperation?.toUpperCase()
                                                        ]?.label
                                                    }
                                                </Typography>
                                            );
                                        })()}
                                    </Box>
                                    <Box
                                        style={{
                                            width: '25%',
                                            justifySelf: 'flex-start',
                                        }}
                                    >
                                        <Typography style={{ fontWeight: 600 }}>
                                            {row?.cityName ?? '-'}
                                        </Typography>
                                    </Box>
                                    <Box
                                        style={{
                                            width: '20%',
                                            justifySelf: 'flex-start',
                                        }}
                                    >
                                        <Typography style={{ fontWeight: 600 }}>
                                            {row?.staffCount ?? 0}
                                        </Typography>
                                    </Box>
                                    {/* <Box
                                        style={{
                                            width: '15%',
                                            justifySelf: 'flex-start',
                                        }}
                                    >
                                        <Typography style={{}}>
                                            {row?.shiftCount ?? 0}
                                        </Typography>
                                    </Box> */}
                                    <Box
                                        style={{
                                            width: '60%',
                                            justifySelf: 'flex-start',
                                        }}
                                    >
                                        <ShiftColumnItemPopper
                                            limit={2}
                                            data={{
                                                hubShiftMappings: [row],
                                                totalHubCount: 1,
                                                totalShiftCount:
                                                    row?.shiftCount,
                                            }}
                                            selectedItem={{}}
                                        />
                                    </Box>
                                    <Box
                                        style={{
                                            width: '20%',
                                            display: 'flex',
                                            justifyContent: 'flex-end',
                                        }}
                                    >
                                        <ListItemSecondaryAction>
                                            <Box
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'flex-end',
                                                }}
                                            >
                                                {(() => {
                                                    const Icon = withStyles(
                                                        () => ({
                                                            root: {
                                                                border: '1px solid transparent',
                                                                '&:hover': {
                                                                    backgroundColor:
                                                                        '#fff !important',
                                                                    border: '1px solid #c7c6e2 !important',
                                                                },
                                                            },
                                                            rootDelete: {
                                                                color: 'red',
                                                                border: '1px solid transparent',
                                                                '&:hover': {
                                                                    border: '1px solid #c7c6e2',
                                                                    backgroundColor:
                                                                        'red',
                                                                    color: '#fff',
                                                                },
                                                            },
                                                        }),
                                                    )(
                                                        ({
                                                            classes,
                                                            isDelete,
                                                            useButton = false,
                                                            ...props
                                                        }) =>
                                                            !!useButton ? (
                                                                <Button
                                                                    classes={{
                                                                        root: !!isDelete
                                                                            ? classes.rootDelete
                                                                            : classes.root,
                                                                    }}
                                                                    {...props}
                                                                >
                                                                    {
                                                                        props.children
                                                                    }
                                                                </Button>
                                                            ) : (
                                                                <IconButton
                                                                    classes={{
                                                                        root: !!isDelete
                                                                            ? classes.rootDelete
                                                                            : classes.root,
                                                                    }}
                                                                    {...props}
                                                                >
                                                                    {
                                                                        props.children
                                                                    }
                                                                </IconButton>
                                                            ),
                                                    );
                                                    return (
                                                        <>
                                                            <Icon
                                                                variant="outlined"
                                                                color="primary"
                                                                useButton={true}
                                                                style={{
                                                                    fontSize: 14,
                                                                    fontWeight: 600,
                                                                    marginRight: 8,
                                                                    borderColor:
                                                                        '#EFEFEF',
                                                                    backgroundColor:
                                                                        '#EFEFEF',
                                                                }}
                                                                onClick={async () => {
                                                                    await setSelectedHub(
                                                                        row,
                                                                    );
                                                                    await setHubNShiftScreenSwitch(
                                                                        HubSwitchKey.HUB_DETAIL_VIEW,
                                                                    );
                                                                }}
                                                            >
                                                                View Shifts
                                                            </Icon>
                                                            <Icon
                                                                style={{
                                                                    marginRight: 8,
                                                                    borderRadius: 8,
                                                                    padding: 6,
                                                                }}
                                                                color="primary"
                                                                onClick={(
                                                                    e,
                                                                ) => {
                                                                    e.stopPropagation();
                                                                    openEditModal(
                                                                        row,
                                                                    );
                                                                }}
                                                            >
                                                                <EditIcon size="small" />
                                                            </Icon>
                                                            <Icon
                                                                style={{
                                                                    marginRight: 8,
                                                                    borderRadius: 8,
                                                                    padding: 6,
                                                                }}
                                                                color="error"
                                                                isDelete={true}
                                                                onClick={(
                                                                    e,
                                                                ) => {
                                                                    e.stopPropagation();
                                                                    openDeleteModal(
                                                                        row,
                                                                    );
                                                                }}
                                                            >
                                                                <DeleteIcon size="small" />
                                                            </Icon>
                                                        </>
                                                    );
                                                })()}
                                                {/* <CustomDropdown
                                                        title={'Options'}
                                                        options={[
                                                            {
                                                                item: 'Edit',
                                                                onSuccess:
                                                                    () => {
                                                                        openEditModal(
                                                                            row,
                                                                        );
                                                                    },
                                                                disabled:
                                                                    !permissions?.update,
                                                            },
                                                            {
                                                                item: 'Delete',
                                                                onSuccess:
                                                                    () => {
                                                                        openDeleteModal(
                                                                            row,
                                                                        );
                                                                    },
                                                                disabled:
                                                                    !permissions?.delete,
                                                            },
                                                        ]}
                                                    /> */}
                                            </Box>
                                        </ListItemSecondaryAction>
                                    </Box>
                                </Box>
                            </ListItem>
                        ))}
                    </List>
                )}

                {!FilteredHubList?.length && (
                    <Box
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <EmptyIcon />
                    </Box>
                )}

                <TablePagination
                    rowsPerPageOptions={[10, 50, 100]}
                    component="div"
                    count={hubInfo?.totalCount}
                    rowsPerPage={
                        hubInfo?.totalCount === FilteredHubList?.length
                            ? hubInfo?.totalCount
                            : rowsPerPage
                    }
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />

                {!!isOpen && (
                    <HubModal
                        open={true}
                        defaultValue={selectedRow}
                        onClose={() => {
                            handleClose();
                            setSelectedRow(undefined);
                            fetchHubList({ ...hubListPayload });
                        }}
                    />
                )}
                {!!isDeleteModalOpen && (
                    <Modal
                        onClose={handleDeleteClose}
                        open={isDeleteModalOpen}
                        title={'Delete'}
                        actions={actions}
                        loading={deleteLoading}
                    >
                        <Box>
                            <Typography
                                style={{
                                    textAlign: 'center',
                                }}
                            >
                                Do you want to delete
                                <strong>{` ${selectedRow?.name} `}</strong>?
                            </Typography>
                            <Typography
                                style={{
                                    textAlign: 'center',
                                    marginTop: '8px',
                                    marginBottom: '24px',
                                }}
                            >
                                You can’t undo this action.
                            </Typography>
                        </Box>
                    </Modal>
                )}
            </Spinner>
        </Paper>
    );
};

const mapStateToProps = (state) => ({
    hubInfo: state.settings?.hubs ?? {},
    cityList: state.settings?.cities ?? [],
});

const mapDispatchToProps = (dispatch) => ({
    dispatchGetDeliveryHubs: (payload, onSuccess, onError) =>
        dispatch(GetDeliveryHubs(payload, onSuccess, onError)),
    dispatchDeleteHub: (id, onSuccess, onError) =>
        dispatch(DeleteHub(id, onSuccess, onError)),
    dispatchGetCities: (payload, onSuccess, onError) =>
        dispatch(GetCities(payload, onSuccess, onError)),
});

export const HubList = connect(
    mapStateToProps,
    mapDispatchToProps,
)(DeliveryHubComponent);
