import {
    Box,
    Button,
    List,
    ListItem,
    ListItemSecondaryAction,
    makeStyles,
    Paper,
    Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import CustomDropdown from '../../components/Dropdown';
import Searchbar from '../../components/Searchbar';
import Modal from '../../components/Modals/Modal';
import { Spinner } from '../../components/UI';
import { EmptyIcon } from '../../assets/svg';
import { connect } from 'react-redux';
import {
    deleteVendor,
    getAllVendorsDetails,
} from '../../store/actions/settingsActionCreator';
import VendorModal from '../../components/Modals/VendorModal';
import ThumbnailPreview from '../../components/ThumbnailPreview';

const useStyles = makeStyles((theme) => ({
    listItemHeader: {
        border: '0px',
        marginBottom: '8px',
        '& .MuiTypography-body1': {
            color: '#525050',
            fontSize: 16,
            fontWeight: 'bold',
        },
    },
    listItem: {
        padding: '4px 16px 4px 16px ',
        marginBottom: '8px ',
        border: '1px solid #ebedf8 ',
        '& .MuiTypography-body1': {
            fontSize: 16,
            fontWeight: 400,
        },
        '&:nth-child(odd)': {
            backgroundColor: '#f8f9fa',
        },
    },
    saveButton: {
        backgroundColor: theme.palette.error.main,
        color: '#fff',
    },
}));

const VendorsComponent = ({
    vendorsList,
    permissions,
    dispatchGetVendorsListData,
    dispatchDeleteVendor,
    ...props
}) => {
    const classes = useStyles();
    const [isOpen, toggleModal] = useState(false);
    const [loading, toggleLoading] = useState(false);
    const [selectedRow, setSelectedRow] = useState();
    const [isDeleteModalOpen, toggleDeleteModal] = useState(false);
    const [deleteLoading, toggleDeleteLoading] = useState(false);
    const [searchText, setSearchText] = useState('');

    const [commonVendorList, setCommonVendorList] = useState([]);

    useEffect(() => {
        fetchVendorsListData();
    }, []);

    const handleClose = () => {
        // Setting form data to null before closing

        toggleModal(false);
    };
    const openEditModal = async (row) => {
        await setSelectedRow(row);
        toggleModal(true);
    };
    const openDeleteModal = async (row) => {
        await setSelectedRow(row);
        toggleDeleteModal(true);
    };
    const handleDeleteClose = () => {
        // Setting form data to null before closing
        toggleDeleteModal(false);
        fetchVendorsListData();
    };

    const fetchVendorsListData = async (filters = {}) => {
        await toggleLoading(true);
        const payload = {};
        dispatchGetVendorsListData(
            payload,
            (resp) => {
                console.log(resp);
                toggleLoading(false);
            },
            (resp) => {
                console.log(resp);
                toggleLoading(false);
            },
        );
    };

    const deleteHubList = async () => {
        await toggleDeleteLoading(true);
        dispatchDeleteVendor(
            selectedRow.vendorId,
            (resp) => {
                console.log(resp);
                toggleDeleteLoading(false);
                handleDeleteClose();
            },
            (err) => {
                console.log(err);
                toggleDeleteLoading(false);
                handleDeleteClose();
            },
        );
    };

    const actions = [
        <Button
            key={1}
            variant="contained"
            color="default"
            className={`cancelButton`}
            onClick={handleDeleteClose}
            disableElevation
        >
            {'No'}
        </Button>,
        <Button
            key={2}
            variant="contained"
            color="default"
            className={`${classes.saveButton}`}
            onClick={(e) => deleteHubList()}
            disableElevation
        >
            {'Yes'}
        </Button>,
    ];

    return (
        <Paper style={{ padding: '1.5rem' }} elevation={0}>
            <Spinner loading={loading}>
                <Box
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'flex-start',
                        marginBottom: 16,
                    }}
                >
                    <Box style={{ width: '50%' }}>
                        <Searchbar
                            placeHolderText="Search by Vendor Name"
                            style={{
                                color: '#14112d',
                            }}
                            onChange={setSearchText}
                            onFilter={() => {
                                console.log('search bar filtering');
                            }}
                        />
                    </Box>
                    <Button
                        style={{
                            marginRight: '1rem',
                            fontSize: 14,
                        }}
                        variant="contained"
                        color="primary"
                        onClick={(e) => toggleModal(true)}
                        disabled={!permissions.create}
                        disableElevation
                    >
                        {'Add Vendor'}
                    </Button>
                </Box>
                {!!vendorsList?.filter((row) =>
                    searchText
                        ? row?.name
                              ?.toLowerCase()
                              ?.includes(searchText?.toLowerCase()) ||
                          row?.cityName
                              ?.toLowerCase()
                              ?.includes(searchText?.toLowerCase())
                        : true,
                )?.length && (
                    <List component="nav">
                        <ListItem className={classes.listItemHeader}>
                            <Box
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    alignItems: 'flex-start',
                                    width: '100%',
                                }}
                            >
                                <Box
                                    style={{
                                        width: '25%',
                                        justifySelf: 'flex-start',
                                    }}
                                >
                                    <Typography
                                        style={{
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        Name
                                    </Typography>
                                </Box>
                                <Box
                                    style={{
                                        width: '25%',
                                        justifySelf: 'flex-start',
                                    }}
                                >
                                    <Typography
                                        style={{
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        City Name
                                    </Typography>
                                </Box>
                                <Box
                                    style={{
                                        width: '25%',
                                        justifySelf: 'flex-start',
                                    }}
                                >
                                    <Typography
                                        style={{
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        Staff Count
                                    </Typography>
                                </Box>
                                <Box
                                    style={{
                                        width: '15%',
                                        justifySelf: 'flex-start',
                                    }}
                                >
                                    <Typography
                                        style={{
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        Documents
                                    </Typography>
                                </Box>
                                <Box
                                    style={{
                                        width: '10%',
                                        justifySelf: 'flex-start',
                                    }}
                                >
                                    {' '}
                                </Box>
                            </Box>
                        </ListItem>

                        {vendorsList
                            ?.filter((row) =>
                                searchText
                                    ? row?.name
                                          ?.toLowerCase()
                                          ?.includes(
                                              searchText?.toLowerCase(),
                                          ) ||
                                      row?.cityName
                                          ?.toLowerCase()
                                          ?.includes(searchText?.toLowerCase())
                                    : true,
                            )
                            ?.map((row, index) => (
                                <ListItem
                                    // button
                                    key={index}
                                    className={classes.listItem}
                                >
                                    <Box
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            width: '100%',
                                        }}
                                    >
                                        <Box
                                            style={{
                                                width: '25%',
                                                justifySelf: 'flex-start',
                                            }}
                                        >
                                            <Typography style={{}}>
                                                {row.name}
                                            </Typography>
                                        </Box>
                                        <Box
                                            style={{
                                                width: '25%',
                                                justifySelf: 'flex-start',
                                            }}
                                        >
                                            <Typography style={{}}>
                                                {row?.cityName ?? '-'}
                                            </Typography>
                                        </Box>
                                        <Box
                                            style={{
                                                width: '25%',
                                                justifySelf: 'flex-start',
                                            }}
                                        >
                                            <Typography style={{}}>
                                                {row?.staffCount ?? 0}
                                            </Typography>
                                        </Box>
                                        <Box
                                            style={{
                                                width: '15%',
                                                justifySelf: 'flex-start',
                                            }}
                                        >
                                            <ThumbnailPreview
                                                images={
                                                    [
                                                        row?.panCardLink,
                                                        row?.contractLink,
                                                    ] ?? []
                                                }
                                            />
                                        </Box>
                                        <Box
                                            style={{
                                                width: '10%',
                                                justifySelf: 'flex-start',
                                            }}
                                        >
                                            <ListItemSecondaryAction>
                                                <Box
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent:
                                                            'flex-end',
                                                    }}
                                                >
                                                    <CustomDropdown
                                                        title={'Options'}
                                                        options={[
                                                            {
                                                                item: 'Edit',
                                                                onSuccess:
                                                                    () => {
                                                                        openEditModal(
                                                                            row,
                                                                        );
                                                                    },
                                                                disabled:
                                                                    !permissions?.update,
                                                            },
                                                            {
                                                                item: 'Delete',
                                                                onSuccess:
                                                                    () => {
                                                                        openDeleteModal(
                                                                            row,
                                                                        );
                                                                    },
                                                                disabled:
                                                                    !permissions?.delete,
                                                            },
                                                        ]}
                                                    />
                                                </Box>
                                            </ListItemSecondaryAction>
                                        </Box>
                                    </Box>
                                </ListItem>
                            ))}
                    </List>
                )}

                {!vendorsList?.filter((row) =>
                    searchText
                        ? row?.name
                              ?.toLowerCase()
                              ?.includes(searchText?.toLowerCase()) ||
                          row?.cityName
                              ?.toLowerCase()
                              ?.includes(searchText?.toLowerCase())
                        : true,
                )?.length && (
                    <Box
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <EmptyIcon />
                    </Box>
                )}

                {!!isOpen && (
                    <VendorModal
                        open={true}
                        defaultValue={selectedRow}
                        onClose={() => {
                            handleClose();
                            setSelectedRow(undefined);
                            fetchVendorsListData();
                        }}
                    />
                )}
                {!!isDeleteModalOpen && (
                    <Modal
                        onClose={handleDeleteClose}
                        open={isDeleteModalOpen}
                        title={'Delete'}
                        actions={actions}
                        loading={deleteLoading}
                    >
                        <Box>
                            <Typography
                                style={{
                                    textAlign: 'center',
                                }}
                            >
                                Do you want to delete
                                <strong>{` ${selectedRow?.name} `}</strong>?
                            </Typography>
                            <Typography
                                style={{
                                    textAlign: 'center',
                                    marginTop: '8px',
                                    marginBottom: '24px',
                                }}
                            >
                                You can’t undo this action.
                            </Typography>
                        </Box>
                    </Modal>
                )}
            </Spinner>
        </Paper>
    );
};

const mapStateToProps = (state) => ({
    vendorsList: state.settings?.vendorsList ?? [],
});

const mapDispatchToProps = (dispatch) => ({
    dispatchGetVendorsListData: (payload, onSuccess, onError) =>
        dispatch(getAllVendorsDetails(payload, onSuccess, onError)),
    dispatchDeleteVendor: (id, onSuccess, onError) =>
        dispatch(deleteVendor(id, onSuccess, onError)),
});

export const Vendors = connect(
    mapStateToProps,
    mapDispatchToProps,
)(VendorsComponent);
