import React, { useState, useEffect } from 'react';
import { Button, IconButton, MenuItem, withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import {
    generatePayslip,
    savePayslip,
} from '../../store/actions/staffActionCreator';

import Modal from './Modal';
import { CustomTextField } from '../Textfield';
import { CustomDatePicker } from '../DateTimePicker';
import InsertInvitationIcon from '@material-ui/icons/InsertInvitation';
import moment from 'moment';
import { checkHttpStatus, showMessage } from '../../utils/helper';
import PreviewPayslipModal from './PreviewPayslipModal';

// const useStyles = makeStyles((theme) => ({
//     modal: {},
// }));

function GeneratePayslipModal({
    open,
    onClose,
    isEdit = false,
    data = {},
    dispatchGeneratePayslip,
    getAllStaffPayslip,
    dispatchSavePayslip,
    staffInfo,
    staff,
    ...props
}) {
    // const classes = useStyles();
    const [isOpen, toggleModal] = useState(open);
    const [isloading, toggleloading] = useState(false);
    const [datePickerOpen, setDatePickerOpen] = useState(false);

    const [payout, setPayout] = useState({
        startTimeEpochMillis: moment().startOf('day').valueOf(),
        endTimeEpochMillis: moment().endOf('day').valueOf(),
    });

    const [previewPayslipModal, setPreviewPayslipModalOpen] = useState(false);
    const [previewPayslipPdfS3Link, setPreviewPayslipPdfS3Link] = useState('');

    const handleClose = () => {
        onClose && onClose();
    };

    const actions = [
        <Button
            key={1}
            variant="contained"
            color="default"
            className={`cancelButton`}
            onClick={handleClose}
            disableElevation
        >
            {'Cancel'}
        </Button>,
        <Button
            key={2}
            variant="contained"
            color="primary"
            className={`saveButton`}
            disabled={
                !payout?.startTimeEpochMillis || !payout?.startTimeEpochMillis
                    ? true
                    : false
            }
            disableElevation
            onClick={async () => {
                const payload = {
                    dateRange: {
                        ...payout,
                    },
                };
                await toggleloading(true);
                await dispatchGeneratePayslip(
                    staffInfo.id,
                    payload,
                    (resp) => {
                        if (checkHttpStatus(resp?.status)) {
                            setPreviewPayslipPdfS3Link(resp?.response?.s3Link);
                            setPreviewPayslipModalOpen(true);
                        }
                        toggleloading(false);
                        console.log(resp);
                    },
                    (err) => {
                        toggleloading(false);
                        console.log(err);
                    },
                );
                // handleClose();
            }}
        >
            {'Generate'}
        </Button>,
    ];

    const StyledMenuItem = withStyles((theme) => ({
        root: {
            margin: '0 8px',
            border: '0 !important',
            borderRadius: '8px',
            '&:hover': {
                backgroundColor: 'rgba(67, 65, 158, 0.08)',
                color: '#43419e',
                borderRadius: '8px',
            },
        },
    }))(MenuItem);

    return (
        <>
            <Modal
                onClose={handleClose}
                open={isOpen}
                title={`Generate ${
                    staff?.singleStaffInfo?.freelancer ? 'Invoice' : 'Payslip'
                }`}
                actions={actions}
                loading={isloading}
            >
                <CustomTextField
                    label="Start Date"
                    InputProps={{
                        readOnly: true,
                        endAdornment: (
                            <IconButton
                                className={'inputbutton'}
                                onClick={(e) =>
                                    setDatePickerOpen('startTimeEpochMillis')
                                }
                            >
                                <InsertInvitationIcon size="small" />
                            </IconButton>
                        ),
                    }}
                    onClick={(e) => setDatePickerOpen('startTimeEpochMillis')}
                    value={moment(payout.startTimeEpochMillis).format(
                        'yyyy-MM-DD',
                    )}
                    variant="outlined"
                    style={{ marginBottom: '16px' }}
                    isRequired={true}
                />
                <CustomTextField
                    label="End Date"
                    InputProps={{
                        readOnly: true,
                        endAdornment: (
                            <IconButton
                                className={'inputbutton'}
                                onClick={(e) =>
                                    setDatePickerOpen('endTimeEpochMillis')
                                }
                            >
                                <InsertInvitationIcon size="small" />
                            </IconButton>
                        ),
                    }}
                    onClick={(e) => setDatePickerOpen('endTimeEpochMillis')}
                    value={moment(payout.endTimeEpochMillis).format(
                        'yyyy-MM-DD',
                    )}
                    variant="outlined"
                    style={{ marginBottom: '16px' }}
                    isRequired={true}
                />
                {!!datePickerOpen && (
                    <CustomDatePicker
                        open={!!datePickerOpen}
                        onOpen={() => setDatePickerOpen(true)}
                        onClose={() => setDatePickerOpen(false)}
                        format="d MMM yyyy"
                        value={payout[datePickerOpen]}
                        onChange={(e) => {
                            console.log(moment(e).valueOf());
                            const startDate =
                                datePickerOpen === 'startTimeEpochMillis'
                                    ? moment(e).startOf('day')
                                    : moment(
                                          payout.startTimeEpochMillis,
                                      ).startOf('day');
                            const endDate =
                                datePickerOpen === 'endTimeEpochMillis'
                                    ? moment(e).startOf('day')
                                    : moment(payout.endTimeEpochMillis).startOf(
                                          'day',
                                      );
                            if (
                                moment(startDate).startOf('day').valueOf() >
                                moment(endDate).startOf('day').valueOf()
                            ) {
                                showMessage(
                                    'End date must be greater then start date',
                                    'error',
                                );
                            } else {
                                setPayout({
                                    ...payout,
                                    startTimeEpochMillis: startDate.valueOf(),
                                    endTimeEpochMillis: endDate.valueOf(),
                                });
                            }
                            // setSelectedDate(moment(e));
                        }}
                        allowFutureDates={false}
                    />
                )}
            </Modal>
            {previewPayslipModal && (
                <PreviewPayslipModal
                    open={previewPayslipModal}
                    staffInfo={staffInfo}
                    payload={{
                        dateRange: { ...payout },
                        s3Link: previewPayslipPdfS3Link,
                    }}
                    onClose={() => {
                        handleClose();
                        getAllStaffPayslip && getAllStaffPayslip();
                        setPreviewPayslipModalOpen(false);
                    }}
                    getAllStaffPayslip={getAllStaffPayslip}
                />
            )}
        </>
    );
}

const mapStateToProps = (state) => ({
    staff: state.staff,
});

const mapDispatchToProps = (dispatch) => ({
    dispatchGeneratePayslip: (id, data, onSuccess, onError) =>
        dispatch(generatePayslip(id, data, onSuccess, onError)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(GeneratePayslipModal);
