import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector, DefaultRootState } from 'react-redux';
import moment from 'moment';
import useRiderMapActions from './useRiderMapActions';
import useRiderMapHelpers from './useRiderMapHelpers';
const useRiderMapViewModal = ({ staffId }: any) => {
    const dispatch = useDispatch();
    //store values
    const { user, mapview, staff } = useSelector(
        (store: DefaultRootState | any) => store || {},
    );
    const {
        fetchRiderDetails,
        fetchRiderEventsDetails,
        fetchRiderTrackingDetails,
        fetchRiderVehicleDetails,
    } = useRiderMapActions({ user });
    const {
        generateDeviceTrackingPayload,
        generateEVTrackingPayload,
        generateVehicleListPayload,
        generateIdlePoints,
        generateStoppageReport,
        generateRoutesData,
        generateRoutePlayBackData,
        generateAllPayload,
    } = useRiderMapHelpers({ user });
    //States
    const [staffInformation, setStaffInformation] = useState<any>(null);
    const [loading, setLoading] = useState<any>(false);
    const [enableSetBounds, setEnableSetBounds] = useState<any>(false);
    const [showCloseButton, setShowCloseButton] = useState<any>(false);
    const [selectedDate, setSelectedDate] = useState<any>(
        moment().format('DD MMM YYYY'),
    );
    const [routes, setRoutes] = useState<any>([]);
    const [aggRoutes, setAggRoutes] = useState<any>([]);
    const [aggUserEvents, setAggUserEvents] = useState<any>([]);
    const [routesPlaybackData, setRoutesPlayBackData] = useState<any>([]);

    const fetchData = async () => {
        setLoading(true);
        let staffDetails = await fetchRiderDetails(staffId);
        if (!!staffDetails) {
            staffDetails.eventDetails = await fetchRiderEventsDetails(
                staffId,
                selectedDate,
            );
            const vehicles = [];
            if (!!staffDetails?.beeconVehicleId) {
                vehicles.push(staffDetails?.beeconVehicleId);
                staffDetails.trackingDetails = await fetchRiderTrackingDetails(
                    generateDeviceTrackingPayload(staffDetails, selectedDate),
                    true,
                );
            }
            if (!!staffDetails?.eventDetails?.evMapViewInfo?.beeconVehicleId) {
                staffDetails.havingEV = true;
                vehicles.push(
                    `profile_vehicle_${staffDetails?.eventDetails?.evMapViewInfo?.beeconVehicleImei?.toUpperCase()}`,
                );
                staffDetails.evTrackingDetails =
                    await fetchRiderTrackingDetails(
                        generateEVTrackingPayload(staffDetails, selectedDate),
                        true,
                        staffDetails?.eventDetails?.evMapViewInfo?.orgAuthToken,
                    );
            }
            if (vehicles?.length > 0) {
                staffDetails.helixVehicleDetails =
                    await fetchRiderVehicleDetails(
                        generateVehicleListPayload(vehicles),
                    );
            }
        }
        setStaffInformation(staffDetails);
        setLoading(false);
    };
    useEffect(() => {
        fetchData();
    }, []);
    useEffect(() => {
        fetchData();
    }, [selectedDate]);
    useEffect(() => {
        if (!!staffInformation) {
            setRoutes(generateRoutesData(staffInformation));
            setAggRoutes(generateRoutesData(staffInformation));
            if (
                moment(selectedDate).valueOf() >= moment('2021-09-16').valueOf()
            ) {
                setAggUserEvents(
                    staffInformation?.eventDetails?.events?.filter(
                        (item?: any) => {
                            if (
                                staffInformation?.eventDetails
                                    ?.outTimeEpochMillis > 0 &&
                                staffInformation?.eventDetails
                                    ?.inTimeEpochMillis > 0
                            ) {
                                return (
                                    item?.time >=
                                        staffInformation?.eventDetails
                                            ?.inTimeEpochMillis &&
                                    item?.time <=
                                        staffInformation?.eventDetails
                                            ?.outTimeEpochMillis
                                );
                            } else if (
                                staffInformation?.eventDetails
                                    ?.inTimeEpochMillis > 0
                            ) {
                                return (
                                    item?.time >=
                                    staffInformation?.eventDetails
                                        ?.inTimeEpochMillis
                                );
                            } else {
                                return item;
                            }
                        },
                    ),
                );
            } else {
                setAggUserEvents([]);
            }
        }
    }, [staffInformation]);

    useEffect(() => {
        if (aggRoutes?.length > 0) {
            setRoutesPlayBackData(generateRoutePlayBackData(aggRoutes));
            setEnableSetBounds(true);
        }
    }, [aggRoutes]);

    const clearSelection = () => {
        setShowCloseButton(false);
        fetchData();
        //setAggRoutes(generateRoutesData(staffInformation));
    };
    async function stepperSelectorHandler(point: any, index: number) {
        if (
            aggRoutes?.[index]?.selectedStartStoppagePoint == null ||
            (aggRoutes?.[index]?.selectedStartStoppagePoint &&
                aggRoutes?.[index]?.selectedEndStoppagePoint)
        ) {
            setShowCloseButton(true);
            let selected = {
                selectedEndStoppagePoint: null,
                selectedEndStoppagePointLocationData: null,
                selectedStartStoppagePoint:
                    point?.deviceTimeEpoch ?? point?.startTime ?? point?.time,
                selectedStartStoppagePointLocationData: point,
            };
            setAggRoutes((prevRoutes: any) =>
                prevRoutes?.map((item: any, i: number) =>
                    i === index ? { ...item, ...selected } : item,
                ),
            );
        } else if (
            aggRoutes?.[index]?.selectedStartStoppagePoint &&
            aggRoutes?.[index]?.selectedEndStoppagePoint == null
        ) {
            const timestamp =
                point?.deviceTimeEpoch ?? point?.endTime ?? point?.time;
            let payloads: any = [];
            if (
                parseInt(aggRoutes?.[index]?.selectedStartStoppagePoint) <
                parseInt(timestamp)
            ) {
                let selected = {
                    selectedEndStoppagePoint: timestamp,
                    selectedEndStoppagePointLocationData: point,
                };
                payloads = generateAllPayload(
                    aggRoutes,
                    staffInformation,
                    parseInt(aggRoutes?.[index]?.selectedStartStoppagePoint),
                    parseInt(timestamp),
                );
            } else {
                if (routes?.[index]?.locationPoints?.length > 0) {
                    let endPoint =
                        routes?.[index]?.locationPoints[
                            routes?.[index]?.locationPoints?.length - 1
                        ];
                    let startPoint = routes?.[index]?.locationPoints?.[0];
                    let selected = {
                        selectedEndStoppagePoint: endPoint?.deviceTimeEpoch,
                        selectedEndStoppagePointLocationData: endPoint,
                        selectedStartStoppagePoint:
                            routes?.[index]?.locationPoints?.[0]
                                ?.deviceTimeEpoch,
                        selectedStartStoppagePointLocationData:
                            routes?.[index]?.locationPoints?.[0],
                    };
                    payloads = generateAllPayload(
                        aggRoutes,
                        staffInformation,
                        Number(startPoint?.deviceTimeEpoch),
                        Number(endPoint?.deviceTimeEpoch),
                    );
                }
            }
            if (payloads?.length > 0) {
                setLoading(true);
                let selectionResponses = await Promise.all(
                    payloads?.map((payload: any) =>
                        fetchRiderTrackingDetails(payload, false),
                    ),
                );
                setLoading(false);
                console.log('thselectionResponse', selectionResponses);

                let tempStaffData: any = {};
                if (!!selectionResponses?.length) {
                    setShowCloseButton(false);
                    selectionResponses?.map((res: any) => {
                        if (
                            res?.vehicle?.id ==
                            staffInformation?.beeconVehicleId
                        ) {
                            tempStaffData['trackingDetails'] = res;
                        } else if (
                            res?.vehicle?.id ==
                            staffInformation?.eventDetails?.evMapViewInfo
                                ?.beeconVehicleId
                        ) {
                            tempStaffData['evTrackingDetails'] = res;
                        }
                    });
                    setAggRoutes(generateRoutesData(tempStaffData));
                } else {
                    clearSelection();
                }
            } else {
                clearSelection();
            }
        }
    }
    return {
        staffInformation,
        loading,
        setLoading,
        selectedDate,
        setSelectedDate,
        routes,
        aggRoutes,
        routesPlaybackData,
        enableSetBounds,
        setEnableSetBounds,
        aggUserEvents,
        showCloseButton,
        stepperSelectorHandler,
        clearSelection,
        fetchData,
    };
};
export default useRiderMapViewModal;
