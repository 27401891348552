import * as L from 'leaflet';

function getFormattedAngle(angle) {
    return angle || 0;
}
function getMarkerIconClass(status) {
    if (status == 'ON') {
        return 'svg-green';
    } else if (status == 'OFF') {
        return 'svg-red';
    }
}
function getMarginBetweenLabelAndIcon(angle, label) {
    var formattedAngleAbs = Math.abs(angle);
    var formattedAngleMod = formattedAngleAbs % 180;

    if (formattedAngleMod >= 0 && formattedAngleMod <= 30) {
        return -15;
    } else if (formattedAngleMod > 30 && formattedAngleMod <= 60) {
        return -20;
    } else if (formattedAngleMod > 60 && formattedAngleMod <= 90) {
        return -20;
    } else {
        return -25;
    }
}
export const createCustomMarkerHtml = (
    angle,
    status,
    label,
    playback = false,
) => {
    let innerHTML = '';
    // console.log("getMarkerIconClass", getMarkerIconClass(vehicle));
    if (playback) {
        innerHTML =
            innerHTML + '<div class="leaflet-playback-marker-icon-wrapper">';
    } else {
        innerHTML = innerHTML + '<div class="leaflet-marker-icon-wrapper">';
    }
    innerHTML =
        innerHTML +
        '<div name="icon-el" style="transform: rotate(' +
        getFormattedAngle(angle) +
        'deg); width: 35px"">';
    innerHTML =
        innerHTML +
        `
        <svg width="30" height="45" xmlns="http://www.w3.org/2000/svg" fill="none" x="0px" y="0px">
        <g>`;

    if (status == 'ON') {
        innerHTML =
            innerHTML +
            `
            <path id="svg_1" d="m26.11517,10.32278c-1.421,-0.407 -3.718,-0.46 -5.07,-0.428c-0.219,0.005 -0.338,0.252 -0.211,0.43c0.084,0.118 0.247,0.152 0.388,0.113c0.91,-0.253 3.17,0.049 4.62,0.31c0.317,0.057 0.63,0.283 0.883,0.483c0.098,0.077 0.204,0.154 0.303,0.222c0.145,0.1 0.32,0.141 0.496,0.125l2.558,-0.229c0.1,-0.009 0.21,-0.025 0.272,-0.103c0.1,-0.122 -0.026,-0.251 -0.12,-0.31c-0.819,-0.392 -2.387,-0.668 -3.069,-0.756c-0.347,-0.035 -0.64,0.093 -0.816,0.15c-0.076,0.026 -0.156,0.015 -0.234,-0.007zm-21.577,0c1.421,-0.407 3.717,-0.46 5.07,-0.428c0.219,0.005 0.338,0.252 0.211,0.43c-0.084,0.118 -0.247,0.152 -0.388,0.113c-0.91,-0.253 -3.17,0.049 -4.62,0.31c-0.317,0.057 -0.63,0.283 -0.883,0.483c-0.098,0.077 -0.205,0.154 -0.303,0.222c-0.145,0.1 -0.32,0.141 -0.496,0.125l-2.558,-0.229c-0.1,-0.009 -0.21,-0.025 -0.273,-0.103c-0.098,-0.122 0.027,-0.251 0.122,-0.31c0.818,-0.392 2.386,-0.668 3.068,-0.756c0.347,-0.035 0.64,0.093 0.815,0.15c0.077,0.026 0.157,0.015 0.235,-0.007z" fill="#FFC833"/>
            <path id="svg_2" d="m14.83217,0.36078c-1.455,0.907 -2.057,3.23 -1.936,4.51c0.051,0.533 0.569,0.774 1.104,0.774l2.351,0c0.841,0 1.642,-0.527 1.611,-1.367c-0.056,-1.545 -0.99,-3.154 -1.953,-3.873c-0.344,-0.258 -0.812,-0.273 -1.177,-0.045l0,0.001z" fill="#FBC120"/>
            <path id="svg_3" d="m14.68717,0.84078c-0.362,1.167 -0.504,2.86 -0.463,3.93c0.021,0.57 0.498,0.918 1.068,0.918c0.713,0 1.331,-0.512 1.33,-1.226c0,-1.242 -0.258,-2.618 -0.585,-3.591c-0.27,-0.805 -1.098,-0.843 -1.35,-0.032l0,0.001z" fill="#FFE80B"/>
            <path id="svg_4" d="m10.09617,12.18278c0.27,1.816 0.34,6.992 0.353,8.462c0.003,0.25 0.099,0.488 0.27,0.67l3.75,3.96c0.38,0.403 1.02,0.409 1.408,0.013l3.882,-3.953c0.19,-0.193 0.29,-0.455 0.286,-0.726c-0.03,-1.87 0.131,-5.895 0.228,-7.931c0.004,-0.094 0.022,-0.184 0.051,-0.273c0.566,-1.733 0.715,-4.282 0.748,-5.14c0.007,-0.186 -0.043,-0.37 -0.157,-0.52c-0.878,-1.149 -2.561,-1.978 -3.53,-2.307c-0.171,-0.057 -0.326,-0.155 -0.46,-0.276c-1.205,-1.09 -2.566,-0.497 -3.246,0.056c-0.108,0.087 -0.227,0.162 -0.357,0.21c-1.725,0.644 -2.898,1.608 -3.445,2.198c-0.154,0.165 -0.22,0.388 -0.21,0.613c0.06,1.204 0.204,3.433 0.43,4.944l-0.001,0z" fill="#03242D"/>
            <path id="svg_5" d="m18.07017,16.99278c-0.14,-0.342 -0.483,-3.62 -0.667,-5.534c-0.024,-0.246 -0.13,-0.483 -0.322,-0.64c-1.816,-1.489 -3.89,-0.234 -3.89,0.855c0,0.917 -0.345,4.05 -0.517,5.503c-0.035,0.96 -1.328,2.9 -2.097,3.924c-0.101,0.134 -0.172,0.289 -0.203,0.454l-0.651,3.38c-0.034,0.175 -0.114,0.336 -0.21,0.486c-0.948,1.483 -1.106,5.005 -1.062,6.66c0.188,10.088 4.802,12.09 7.085,11.83c5.105,0 6.57,-7.887 6.663,-11.83c0.194,-3.764 -0.574,-5.798 -1.15,-6.592c-0.148,-0.202 -0.281,-0.422 -0.325,-0.669l-0.622,-3.536c-0.04,-0.225 -0.154,-0.43 -0.31,-0.598c-1.326,-1.437 -1.545,-3.26 -1.722,-3.693z" fill="#F5B811"/>
            <path id="svg_6" d="m17.89017,16.99278c-0.131,-0.343 -0.454,-3.638 -0.627,-5.549c-0.022,-0.237 -0.119,-0.466 -0.298,-0.623c-1.705,-1.492 -3.654,-0.236 -3.654,0.853c0,0.917 -0.322,4.05 -0.484,5.503c-0.035,1.027 -1.424,3.18 -2.113,4.127c-0.848,1.096 2.547,2.665 3.729,3.168c0.252,0.107 0.534,0.104 0.787,0l2.57,-1.053c0.19,-0.078 0.354,-0.209 0.471,-0.377l0.96,-1.375c0.295,-0.423 0.236,-0.99 -0.06,-1.412c-0.949,-1.35 -1.13,-2.871 -1.28,-3.262l-0.001,0z" fill="#028238"/>
            <path id="svg_7" d="m10.31317,33.92678c-0.219,-2.132 0.039,-4.331 0.304,-5.733c0.103,-0.54 0.686,-0.816 1.186,-0.586l4.17,1.92c0.294,0.136 0.638,0.1 0.899,-0.09l2.23,-1.638c0.448,-0.328 1.05,-0.154 1.142,0.394c0.348,2.098 0.163,5.837 0.163,5.911c0,0.119 -0.027,0.334 -0.06,0.543c-0.04,0.269 -0.17,0.55 -0.284,0.798l-0.008,0.017c-0.243,0.527 -0.604,1.474 -0.804,2.022c-0.676,1.636 -3.216,1.927 -4.402,1.868c-2.49,-0.25 -3.41,-1.498 -3.558,-2.09c-0.059,-0.267 -0.258,-0.943 -0.578,-1.512c-0.32,-0.57 -0.4,-1.453 -0.4,-1.824z" fill="#013A4B"/>
            <path id="svg_8" d="m15.42817,44.64278c-1.551,0 -2.18,-2.37 -2.18,-3.647c1.351,0.887 3.407,0.324 4.266,0c0.068,2.991 -1.41,3.647 -2.086,3.647z" fill="#ED1B2F"/>
            <path id="svg_9" d="m4.50217,13.92078l-0.299,-0.707c-0.053,-0.124 0.047,-0.26 0.182,-0.246l0.1,0.01c0.03,0.003 0.06,-0.002 0.088,-0.014l2.937,-1.27c0.356,-0.108 0.445,-0.46 0.445,-0.623c-0.07,-0.99 1.14,-1.827 1.766,-2.129l0.027,-0.01c1.568,-0.458 2.664,-1.635 3.037,-2.193c0.012,-0.018 0.019,-0.037 0.025,-0.057c0.082,-0.286 0.401,-0.494 0.582,-0.578c0.021,-0.01 0.045,-0.014 0.068,-0.014l3.86,0c0.012,0 0.023,0.001 0.035,0.004c0.346,0.08 0.577,0.444 0.65,0.619c0.498,1.174 2.223,1.971 3.024,2.223c0.844,0.223 1.956,1.245 1.823,1.779c-0.1,0.401 0.132,0.808 0.283,0.99c0.018,0.022 0.042,0.038 0.068,0.048l3.117,1.185l0.13,0.038c0.099,0.028 0.153,0.132 0.12,0.229l-0.24,0.693c-0.043,0.126 -0.207,0.155 -0.292,0.053c-0.02,-0.022 -0.043,-0.04 -0.07,-0.05c-1.053,-0.39 -3.128,-1.15 -3.339,-1.185c-0.173,-0.029 -0.358,0.077 -0.47,0.166c-0.041,0.033 -0.095,0.053 -0.148,0.047l-4.22,-0.474c-0.035,-0.004 -0.07,0.002 -0.1,0.018c-0.28,0.137 -1.01,0.465 -2.265,0.465c-1.25,0 -2.084,-0.239 -2.382,-0.439c-0.043,-0.029 -0.094,-0.046 -0.146,-0.04l-4.052,0.464c-0.08,0.009 -0.158,-0.039 -0.222,-0.087c-0.147,-0.11 -0.427,-0.178 -0.596,-0.205c-0.019,-0.003 -0.037,-0.002 -0.056,0.002c-0.567,0.122 -2.285,0.92 -3.228,1.379c-0.092,0.045 -0.202,0.003 -0.242,-0.09l0,-0.001z" fill="#1BB55C"/>
            <path id="svg_10" d="m18.00417,6.71278l-5.203,0c0.036,-0.25 0.43,-0.519 0.623,-0.623l3.913,0c0.284,0 0.563,0.415 0.667,0.623z" fill="#000"/>
            <path id="svg_11" d="m23.91917,14.18078c-0.52,2.405 -2.416,4.87 -3.53,6.058c-0.17,0.18 -0.204,0.45 -0.076,0.661l0.577,0.955c0.182,0.3 0.6,0.347 0.843,0.092l0.452,-0.473c1.494,-1.53 2.816,-5.322 3.29,-7.026c0.307,-0.986 0.285,-1.753 0.23,-2.052c-0.004,-0.026 -0.012,-0.051 -0.022,-0.075c-0.37,-0.89 -1.444,-0.94 -1.942,-0.853c-0.223,0.032 -0.343,0.28 -0.387,0.438c-0.01,0.034 -0.013,0.07 -0.013,0.105c-0.005,0.431 -0.073,0.678 -0.158,0.81c-0.112,0.175 -0.465,0.194 -0.428,0.398c0.004,0.022 0.008,0.045 0.008,0.072c0,0.092 0.52,0.32 0.894,0.467c0.112,0.044 0.215,0.12 0.25,0.237c0.026,0.091 0.022,0.157 0.012,0.186zm-17.035,-0.016c0.52,2.406 2.416,4.87 3.531,6.058c0.17,0.18 0.203,0.45 0.075,0.662l-0.577,0.954c-0.182,0.301 -0.6,0.347 -0.843,0.093l-0.452,-0.474c-1.494,-1.53 -2.816,-5.321 -3.29,-7.026c-0.306,-0.986 -0.285,-1.752 -0.23,-2.051c0.004,-0.026 0.012,-0.052 0.022,-0.076c0.37,-0.89 1.445,-0.94 1.942,-0.852c0.223,0.032 0.344,0.28 0.388,0.438c0.01,0.034 0.012,0.07 0.012,0.104c0.006,0.432 0.073,0.678 0.158,0.81c0.113,0.176 0.466,0.194 0.428,0.399c-0.004,0.021 -0.008,0.045 -0.008,0.072c0,0.091 -0.52,0.32 -0.893,0.467c-0.112,0.044 -0.216,0.12 -0.25,0.236c-0.027,0.091 -0.023,0.158 -0.013,0.186zm7.422,3.528c-0.364,0.174 -0.562,0.444 -0.661,0.693c-0.11,0.272 0.078,0.543 0.342,0.67l1.17,0.558c0.225,0.107 0.488,0.104 0.71,-0.01l1.011,-0.518c0.28,-0.143 0.471,-0.449 0.339,-0.734c-0.177,-0.381 -0.507,-0.614 -0.734,-0.715c-0.052,-0.023 -0.108,-0.033 -0.162,-0.051c-0.27,-0.094 -0.583,-0.46 -0.718,-0.648c-0.06,-0.076 -0.135,-0.1 -0.207,-0.098c-0.17,0.007 -0.274,0.184 -0.394,0.304c-0.144,0.145 -0.357,0.32 -0.515,0.442c-0.056,0.043 -0.118,0.076 -0.181,0.107z" fill="#FFBD9A"/>
            <path id="svg_12" d="m15.29417,32.36578c-4.838,-0.036 -6.048,-6.448 -6.048,-9.65l1.379,-1.067l5.025,3.557l4.269,-3.557l1.6,1.067c-0.035,8.04 -4.165,9.783 -6.225,9.65z" fill="#03242D"/>
            <path id="svg_13" d="m19.91817,20.22678c-4.02,4.091 -7.782,1.705 -9.16,0c-1.21,0.25 -1.986,1.32 -2.223,1.824c0.07,0.284 1.511,1.482 2.223,2.045c0.534,0.249 0.786,1.023 0.845,1.379c0.142,0.462 0.326,2.801 0.4,3.913c2.775,2.099 5.662,0.874 6.76,0c0.035,-1.103 0.31,-3.069 0.444,-3.913c-0.071,-0.748 0.445,-1.35 0.711,-1.557c0.463,-0.107 1.705,-1.29 2.268,-1.867c-0.64,-1.494 -1.779,-1.838 -2.268,-1.824z" fill="#43419E"/>
            <rect id="svg_14" rx="1.645" fill="#C2C4C6" y="19.55878" x="11.91317" height="3.291" width="6.848"/>
            <ellipse id="svg_15" ry="3.38" rx="2.713" fill="black" cy="21.20378" cx="15.33817"/>
            <rect id="svg_16" rx="0.934" stroke-width="0.267" stroke="#000" fill="#1BB55C" y="32.50478" x="8.13417" height="9.249" width="14.408"/>
            <path id="svg_17" d="m18.77517,41.59078c0.057,0.214 0.276,0.341 0.49,0.284c0.213,-0.057 0.34,-0.276 0.283,-0.49l-0.773,0.206zm-0.369,-9.156c-0.075,1.14 -0.12,2.908 -0.078,4.644c0.04,1.723 0.166,3.46 0.447,4.512l0.773,-0.206c-0.253,-0.949 -0.38,-2.599 -0.42,-4.325c-0.04,-1.714 0.004,-3.459 0.077,-4.572l-0.799,-0.053zm-6.462,9.156c-0.057,0.214 -0.276,0.341 -0.49,0.284c-0.214,-0.057 -0.34,-0.276 -0.284,-0.49l0.774,0.206zm0.368,-9.156c0.075,1.14 0.12,2.908 0.079,4.644c-0.041,1.723 -0.167,3.46 -0.447,4.512l-0.774,-0.206c0.253,-0.949 0.38,-2.599 0.42,-4.325c0.041,-1.714 -0.003,-3.459 -0.076,-4.572l0.798,-0.053z" fill="#000"/>
            <path id="svg_18" d="m11.95717,37.26578l6.582,0" stroke-width="0.711" stroke="#000"/>
            <path id="svg_19" d="m14.22517,37.17578l2.134,0" stroke-width="1.334" stroke-linecap="round" stroke="#000"/>
           </g>`;
    } else if (status == 'IDLE') {
        innerHTML =
            innerHTML +
            `  <path id="svg_1" d="m25.85267,10.31832c-1.421,-0.407 -3.718,-0.46 -5.07,-0.428c-0.219,0.005 -0.338,0.252 -0.211,0.43c0.084,0.118 0.247,0.152 0.388,0.113c0.91,-0.253 3.17,0.049 4.62,0.31c0.317,0.057 0.63,0.283 0.883,0.483c0.098,0.077 0.204,0.154 0.303,0.222c0.145,0.1 0.32,0.141 0.496,0.125l2.558,-0.229c0.1,-0.009 0.21,-0.025 0.272,-0.103c0.1,-0.122 -0.026,-0.251 -0.12,-0.31c-0.819,-0.392 -2.387,-0.668 -3.069,-0.756c-0.347,-0.035 -0.64,0.093 -0.816,0.15c-0.076,0.026 -0.156,0.015 -0.234,-0.007zm-21.577,0c1.421,-0.407 3.717,-0.46 5.07,-0.428c0.219,0.005 0.338,0.252 0.211,0.43c-0.084,0.118 -0.247,0.152 -0.388,0.113c-0.91,-0.253 -3.17,0.049 -4.62,0.31c-0.317,0.057 -0.63,0.283 -0.883,0.483c-0.098,0.077 -0.205,0.154 -0.303,0.222c-0.145,0.1 -0.32,0.141 -0.496,0.125l-2.558,-0.229c-0.1,-0.009 -0.21,-0.025 -0.273,-0.103c-0.098,-0.122 0.027,-0.251 0.122,-0.31c0.818,-0.392 2.386,-0.668 3.068,-0.756c0.347,-0.035 0.64,0.093 0.815,0.15c0.077,0.026 0.157,0.015 0.235,-0.007z" fill="#FFC833"/>
            <path id="svg_2" d="m14.56967,0.35632c-1.455,0.907 -2.057,3.23 -1.936,4.51c0.051,0.533 0.569,0.774 1.104,0.774l2.351,0c0.841,0 1.642,-0.527 1.611,-1.367c-0.056,-1.545 -0.99,-3.154 -1.953,-3.873c-0.344,-0.258 -0.812,-0.273 -1.177,-0.045l0,0.001z" fill="#FBC120"/>
            <path id="svg_3" d="m14.42467,0.83632c-0.362,1.167 -0.504,2.86 -0.463,3.93c0.021,0.57 0.498,0.918 1.068,0.918c0.713,0 1.331,-0.512 1.33,-1.226c0,-1.242 -0.258,-2.618 -0.585,-3.591c-0.27,-0.805 -1.098,-0.843 -1.35,-0.032l0,0.001z" fill="#FFE80B"/>
            <path id="svg_4" d="m9.83367,12.17832c0.27,1.816 0.34,6.992 0.353,8.462c0.003,0.25 0.099,0.488 0.27,0.67l3.75,3.96c0.38,0.403 1.02,0.409 1.408,0.013l3.882,-3.953c0.19,-0.193 0.29,-0.455 0.286,-0.726c-0.03,-1.87 0.131,-5.895 0.228,-7.931c0.004,-0.094 0.022,-0.184 0.051,-0.273c0.566,-1.733 0.715,-4.282 0.748,-5.14c0.007,-0.186 -0.043,-0.37 -0.157,-0.52c-0.878,-1.149 -2.561,-1.978 -3.53,-2.307c-0.171,-0.057 -0.326,-0.155 -0.46,-0.276c-1.205,-1.09 -2.566,-0.497 -3.246,0.056c-0.108,0.087 -0.227,0.162 -0.357,0.21c-1.725,0.644 -2.898,1.608 -3.445,2.198c-0.154,0.165 -0.22,0.388 -0.21,0.613c0.06,1.204 0.204,3.433 0.43,4.944l-0.001,0z" fill="#03242D"/>
            <path id="svg_5" d="m17.80767,16.98832c-0.14,-0.342 -0.483,-3.62 -0.667,-5.534c-0.024,-0.246 -0.13,-0.483 -0.322,-0.64c-1.816,-1.489 -3.89,-0.234 -3.89,0.855c0,0.917 -0.345,4.05 -0.517,5.503c-0.035,0.96 -1.328,2.9 -2.097,3.924c-0.101,0.134 -0.172,0.289 -0.203,0.454l-0.651,3.38c-0.034,0.175 -0.114,0.336 -0.21,0.486c-0.948,1.483 -1.106,5.005 -1.062,6.66c0.188,10.088 4.802,12.09 7.085,11.83c5.105,0 6.57,-7.887 6.663,-11.83c0.194,-3.764 -0.574,-5.798 -1.15,-6.592c-0.148,-0.202 -0.281,-0.422 -0.325,-0.669l-0.622,-3.536c-0.04,-0.225 -0.154,-0.43 -0.31,-0.598c-1.326,-1.437 -1.545,-3.26 -1.722,-3.693z" fill="#F5B811"/>
            <path id="svg_6" d="m17.62767,16.98832c-0.131,-0.343 -0.454,-3.638 -0.627,-5.549c-0.022,-0.237 -0.119,-0.466 -0.298,-0.623c-1.705,-1.492 -3.654,-0.236 -3.654,0.853c0,0.917 -0.322,4.05 -0.484,5.503c-0.035,1.027 -1.424,3.18 -2.113,4.127c-0.848,1.096 2.547,2.665 3.729,3.168c0.252,0.107 0.534,0.104 0.787,0l2.57,-1.053c0.19,-0.078 0.354,-0.209 0.471,-0.377l0.96,-1.375c0.295,-0.423 0.236,-0.99 -0.06,-1.412c-0.949,-1.35 -1.13,-2.871 -1.28,-3.262l-0.001,0z" fill="#FF9C06"/>
            <path id="svg_7" d="m10.05067,33.92232c-0.219,-2.132 0.039,-4.331 0.304,-5.733c0.103,-0.54 0.686,-0.816 1.186,-0.586l4.17,1.92c0.294,0.136 0.638,0.1 0.899,-0.09l2.23,-1.638c0.448,-0.328 1.05,-0.154 1.142,0.394c0.348,2.098 0.163,5.837 0.163,5.911c0,0.119 -0.027,0.334 -0.06,0.543c-0.04,0.269 -0.17,0.55 -0.284,0.798l-0.008,0.017c-0.243,0.527 -0.604,1.474 -0.804,2.022c-0.676,1.636 -3.216,1.927 -4.402,1.868c-2.49,-0.25 -3.41,-1.498 -3.558,-2.09c-0.059,-0.267 -0.258,-0.943 -0.578,-1.512c-0.32,-0.57 -0.4,-1.453 -0.4,-1.824z" fill="#013A4B"/>
            <path id="svg_8" d="m15.16567,44.63832c-1.551,0 -2.18,-2.37 -2.18,-3.647c1.351,0.887 3.407,0.324 4.266,0c0.068,2.991 -1.41,3.647 -2.086,3.647z" fill="#ED1B2F"/>
            <path id="svg_9" d="m4.23967,13.91632l-0.299,-0.707c-0.053,-0.124 0.047,-0.26 0.182,-0.246l0.1,0.01c0.03,0.003 0.06,-0.002 0.088,-0.014l2.937,-1.27c0.356,-0.108 0.445,-0.46 0.445,-0.623c-0.07,-0.99 1.14,-1.827 1.766,-2.129l0.027,-0.01c1.568,-0.458 2.664,-1.635 3.037,-2.193c0.012,-0.018 0.019,-0.037 0.025,-0.057c0.082,-0.286 0.401,-0.494 0.582,-0.578c0.021,-0.01 0.045,-0.014 0.068,-0.014l3.86,0c0.012,0 0.023,0.001 0.035,0.004c0.346,0.08 0.577,0.444 0.65,0.619c0.498,1.174 2.223,1.971 3.024,2.223c0.844,0.223 1.956,1.245 1.823,1.779c-0.1,0.401 0.132,0.808 0.283,0.99c0.018,0.022 0.042,0.038 0.068,0.048l3.117,1.185l0.13,0.038c0.099,0.028 0.153,0.132 0.12,0.229l-0.24,0.693c-0.043,0.126 -0.207,0.155 -0.292,0.053c-0.019,-0.022 -0.043,-0.04 -0.07,-0.05c-1.053,-0.39 -3.128,-1.15 -3.339,-1.185c-0.173,-0.029 -0.358,0.077 -0.47,0.166c-0.041,0.033 -0.095,0.053 -0.148,0.047l-4.22,-0.474c-0.035,-0.004 -0.07,0.002 -0.1,0.018c-0.28,0.137 -1.01,0.465 -2.265,0.465c-1.25,0 -2.084,-0.239 -2.382,-0.439c-0.043,-0.029 -0.094,-0.046 -0.146,-0.04l-4.052,0.464c-0.08,0.009 -0.158,-0.039 -0.222,-0.087c-0.147,-0.11 -0.427,-0.178 -0.596,-0.205c-0.019,-0.003 -0.037,-0.002 -0.056,0.002c-0.567,0.122 -2.285,0.92 -3.228,1.379c-0.092,0.045 -0.202,0.003 -0.242,-0.09l0,-0.001z" fill="#F44336"/>
            <path id="svg_10" d="m17.74167,6.70832l-5.203,0c0.036,-0.25 0.43,-0.519 0.623,-0.623l3.913,0c0.284,0 0.563,0.415 0.667,0.623z" fill="#FBD254"/>
            <path id="svg_11" d="m23.65667,14.17632c-0.52,2.405 -2.416,4.87 -3.53,6.058c-0.17,0.18 -0.204,0.45 -0.076,0.661l0.577,0.955c0.182,0.3 0.6,0.347 0.843,0.092l0.452,-0.473c1.494,-1.53 2.816,-5.322 3.29,-7.026c0.307,-0.986 0.285,-1.753 0.23,-2.052c-0.004,-0.026 -0.012,-0.051 -0.022,-0.075c-0.37,-0.89 -1.444,-0.94 -1.942,-0.853c-0.223,0.032 -0.343,0.28 -0.387,0.438c-0.01,0.034 -0.013,0.07 -0.013,0.105c-0.005,0.431 -0.073,0.678 -0.158,0.81c-0.112,0.175 -0.465,0.194 -0.428,0.398c0.004,0.022 0.008,0.045 0.008,0.072c0,0.092 0.52,0.32 0.894,0.467c0.112,0.044 0.215,0.12 0.25,0.237c0.026,0.091 0.022,0.157 0.012,0.186zm-17.035,-0.016c0.52,2.406 2.416,4.87 3.531,6.058c0.17,0.18 0.203,0.45 0.075,0.662l-0.577,0.954c-0.182,0.301 -0.6,0.347 -0.843,0.093l-0.452,-0.474c-1.494,-1.53 -2.816,-5.321 -3.29,-7.026c-0.306,-0.986 -0.285,-1.752 -0.23,-2.051c0.004,-0.026 0.012,-0.052 0.022,-0.076c0.37,-0.89 1.445,-0.94 1.942,-0.852c0.223,0.032 0.344,0.28 0.388,0.438c0.01,0.034 0.012,0.07 0.012,0.104c0.006,0.432 0.073,0.678 0.158,0.81c0.113,0.176 0.466,0.194 0.428,0.399c-0.004,0.021 -0.008,0.045 -0.008,0.072c0,0.091 -0.52,0.32 -0.893,0.467c-0.112,0.044 -0.216,0.12 -0.25,0.236c-0.027,0.091 -0.023,0.158 -0.013,0.186zm7.422,3.528c-0.364,0.174 -0.562,0.444 -0.661,0.693c-0.11,0.272 0.078,0.543 0.342,0.67l1.17,0.558c0.225,0.107 0.488,0.104 0.71,-0.01l1.011,-0.518c0.28,-0.143 0.471,-0.449 0.339,-0.734c-0.177,-0.381 -0.507,-0.614 -0.734,-0.715c-0.052,-0.023 -0.108,-0.033 -0.162,-0.051c-0.27,-0.094 -0.583,-0.46 -0.718,-0.648c-0.06,-0.076 -0.135,-0.1 -0.207,-0.098c-0.17,0.007 -0.274,0.184 -0.394,0.304c-0.144,0.145 -0.357,0.32 -0.515,0.442c-0.056,0.043 -0.118,0.076 -0.181,0.107z" fill="#FFBD9A"/>
            <path id="svg_12" d="m15.03167,32.36132c-4.838,-0.036 -6.048,-6.448 -6.048,-9.65l1.379,-1.067l5.025,3.557l4.269,-3.557l1.6,1.067c-0.035,8.04 -4.165,9.783 -6.225,9.65z" fill="#03242D"/>
            <path id="svg_13" d="m19.65567,20.22232c-4.02,4.091 -7.782,1.705 -9.16,0c-1.21,0.25 -1.986,1.32 -2.223,1.824c0.07,0.284 1.511,1.482 2.223,2.045c0.534,0.249 0.786,1.023 0.845,1.379c0.142,0.462 0.326,2.801 0.4,3.913c2.775,2.099 5.662,0.874 6.76,0c0.035,-1.103 0.31,-3.069 0.444,-3.913c-0.071,-0.748 0.445,-1.35 0.711,-1.557c0.463,-0.107 1.705,-1.29 2.268,-1.867c-0.64,-1.494 -1.779,-1.838 -2.268,-1.824z" fill="#43419E"/>
            <rect id="svg_14" rx="1.645" fill="#C2C4C6" y="19.55432" x="11.65067" height="3.291" width="6.848"/>
            <ellipse id="svg_15" ry="3.38" rx="2.713" fill="black" cy="21.19932" cx="15.07567"/>
            <rect id="svg_16" rx="0.934" stroke-width="0.267" stroke="#000" fill="#DE3926" y="32.50032" x="7.87167" height="9.249" width="14.408"/>
            <path id="svg_17" d="m18.51267,41.58632c0.057,0.214 0.276,0.341 0.49,0.284c0.213,-0.057 0.34,-0.276 0.283,-0.49l-0.773,0.206zm-0.369,-9.156c-0.075,1.14 -0.12,2.908 -0.078,4.644c0.04,1.723 0.166,3.46 0.447,4.512l0.773,-0.206c-0.253,-0.949 -0.38,-2.599 -0.42,-4.325c-0.04,-1.714 0.004,-3.459 0.077,-4.572l-0.799,-0.053zm-6.462,9.156c-0.057,0.214 -0.276,0.341 -0.49,0.284c-0.214,-0.057 -0.34,-0.276 -0.284,-0.49l0.774,0.206zm0.368,-9.156c0.075,1.14 0.12,2.908 0.079,4.644c-0.041,1.723 -0.167,3.46 -0.447,4.512l-0.774,-0.206c0.253,-0.949 0.38,-2.599 0.42,-4.325c0.041,-1.714 -0.003,-3.459 -0.076,-4.572l0.798,-0.053z" fill="#000"/>
            <path id="svg_18" d="m11.69467,37.26132l6.582,0" stroke-width="0.711" stroke="#000"/>
            <path id="svg_19" d="m13.96267,37.17132l2.134,0" stroke-width="1.334" stroke-linecap="round" stroke="#000"/>
            <rect id="svg_20" height="0" width="0.18182" y="0" x="109.18131" stroke="#000" fill="#fff"/>
           </g>`;
    } else if (status == 'OFF') {
        innerHTML =
            innerHTML +
            `<path id="svg_1" d="m25.90603,10.30676c-1.421,-0.407 -3.718,-0.46 -5.07,-0.428c-0.219,0.005 -0.338,0.252 -0.211,0.43c0.084,0.118 0.247,0.152 0.388,0.113c0.91,-0.253 3.17,0.049 4.62,0.31c0.317,0.057 0.63,0.283 0.883,0.483c0.098,0.077 0.204,0.154 0.303,0.222c0.145,0.1 0.32,0.141 0.496,0.125l2.558,-0.229c0.1,-0.009 0.21,-0.025 0.272,-0.103c0.1,-0.122 -0.026,-0.251 -0.12,-0.31c-0.819,-0.392 -2.387,-0.668 -3.069,-0.756c-0.347,-0.035 -0.64,0.093 -0.816,0.15c-0.076,0.026 -0.156,0.015 -0.234,-0.007zm-21.577,0c1.421,-0.407 3.717,-0.46 5.07,-0.428c0.219,0.005 0.338,0.252 0.211,0.43c-0.084,0.118 -0.247,0.152 -0.388,0.113c-0.91,-0.253 -3.17,0.049 -4.62,0.31c-0.317,0.057 -0.63,0.283 -0.883,0.483c-0.098,0.077 -0.205,0.154 -0.303,0.222c-0.145,0.1 -0.32,0.141 -0.496,0.125l-2.558,-0.229c-0.1,-0.009 -0.21,-0.025 -0.273,-0.103c-0.098,-0.122 0.027,-0.251 0.122,-0.31c0.818,-0.392 2.386,-0.668 3.068,-0.756c0.347,-0.035 0.64,0.093 0.815,0.15c0.077,0.026 0.157,0.015 0.235,-0.007z" fill="#C9C9C9"/>
        <path id="svg_2" d="m14.62303,0.34476c-1.455,0.907 -2.057,3.23 -1.936,4.51c0.051,0.533 0.569,0.774 1.104,0.774l2.351,0c0.841,0 1.642,-0.527 1.611,-1.367c-0.056,-1.545 -0.99,-3.154 -1.953,-3.873c-0.344,-0.258 -0.812,-0.273 -1.177,-0.045l0,0.001z" fill="#C2C2C2"/>
        <path id="svg_3" d="m14.47803,0.82476c-0.362,1.167 -0.504,2.86 -0.463,3.93c0.021,0.57 0.498,0.918 1.068,0.918c0.713,0 1.331,-0.512 1.33,-1.226c0,-1.242 -0.258,-2.618 -0.585,-3.591c-0.27,-0.805 -1.098,-0.843 -1.35,-0.032l0,0.001z" fill="#DDD"/>
        <path id="svg_4" d="m9.88703,12.16676c0.27,1.816 0.34,6.992 0.353,8.462c0.003,0.25 0.099,0.488 0.27,0.67l3.75,3.96c0.38,0.403 1.02,0.409 1.408,0.013l3.882,-3.953c0.19,-0.193 0.29,-0.455 0.286,-0.726c-0.03,-1.87 0.131,-5.895 0.228,-7.931c0.004,-0.094 0.022,-0.184 0.051,-0.273c0.566,-1.733 0.715,-4.282 0.748,-5.14c0.007,-0.186 -0.043,-0.37 -0.157,-0.52c-0.878,-1.149 -2.561,-1.978 -3.53,-2.307c-0.171,-0.057 -0.326,-0.155 -0.46,-0.276c-1.205,-1.09 -2.566,-0.497 -3.246,0.056c-0.108,0.087 -0.227,0.162 -0.357,0.21c-1.725,0.644 -2.898,1.608 -3.445,2.198c-0.154,0.165 -0.22,0.388 -0.21,0.613c0.06,1.204 0.204,3.433 0.43,4.944l-0.001,0z" fill="#1E1E1E"/>
        <path id="svg_5" d="m17.86103,16.97676c-0.14,-0.342 -0.483,-3.62 -0.667,-5.534c-0.024,-0.246 -0.13,-0.483 -0.322,-0.64c-1.816,-1.489 -3.89,-0.234 -3.89,0.855c0,0.917 -0.345,4.05 -0.517,5.503c-0.035,0.96 -1.328,2.9 -2.097,3.924c-0.101,0.134 -0.172,0.289 -0.203,0.454l-0.651,3.38c-0.034,0.175 -0.114,0.336 -0.21,0.486c-0.948,1.483 -1.106,5.005 -1.062,6.66c0.188,10.088 4.802,12.09 7.085,11.83c5.105,0 6.57,-7.887 6.663,-11.83c0.194,-3.764 -0.574,-5.798 -1.15,-6.592c-0.148,-0.202 -0.281,-0.422 -0.325,-0.669l-0.622,-3.536c-0.04,-0.225 -0.154,-0.43 -0.31,-0.598c-1.326,-1.437 -1.545,-3.26 -1.722,-3.693z" fill="#B9B9B9"/>
        <path id="svg_6" d="m17.68103,16.97676c-0.131,-0.343 -0.454,-3.638 -0.627,-5.549c-0.022,-0.237 -0.119,-0.466 -0.298,-0.623c-1.705,-1.492 -3.654,-0.236 -3.654,0.853c0,0.917 -0.322,4.05 -0.484,5.503c-0.035,1.027 -1.424,3.18 -2.113,4.127c-0.848,1.096 2.547,2.665 3.729,3.168c0.252,0.107 0.534,0.104 0.787,0l2.57,-1.053c0.19,-0.078 0.354,-0.209 0.471,-0.377l0.96,-1.375c0.295,-0.423 0.236,-0.99 -0.06,-1.412c-0.949,-1.35 -1.13,-2.871 -1.28,-3.262l-0.001,0z" fill="#A6A6A6"/>
        <path id="svg_7" d="m10.10403,33.91076c-0.219,-2.132 0.039,-4.331 0.304,-5.733c0.103,-0.54 0.686,-0.816 1.186,-0.586l4.17,1.92c0.294,0.136 0.638,0.1 0.899,-0.09l2.23,-1.638c0.448,-0.328 1.05,-0.154 1.142,0.394c0.348,2.098 0.163,5.837 0.163,5.911c0,0.119 -0.027,0.334 -0.06,0.543c-0.04,0.269 -0.17,0.55 -0.284,0.798l-0.008,0.017c-0.243,0.527 -0.604,1.474 -0.804,2.022c-0.676,1.636 -3.216,1.927 -4.402,1.868c-2.49,-0.25 -3.41,-1.498 -3.558,-2.09c-0.059,-0.267 -0.258,-0.943 -0.578,-1.512c-0.32,-0.57 -0.4,-1.453 -0.4,-1.824z" fill="#2F2F2F"/>
        <path id="svg_8" d="m15.21903,44.62676c-1.551,0 -2.18,-2.37 -2.18,-3.647c1.351,0.887 3.407,0.324 4.266,0c0.068,2.991 -1.41,3.647 -2.086,3.647z" fill="#494949"/>
        <path id="svg_9" d="m4.29303,13.90476l-0.299,-0.707c-0.053,-0.124 0.047,-0.26 0.182,-0.246l0.1,0.01c0.03,0.003 0.06,-0.002 0.088,-0.014l2.937,-1.27c0.356,-0.108 0.445,-0.46 0.445,-0.623c-0.07,-0.99 1.14,-1.827 1.766,-2.129l0.027,-0.01c1.568,-0.458 2.664,-1.635 3.037,-2.193c0.012,-0.018 0.019,-0.037 0.025,-0.057c0.082,-0.286 0.401,-0.494 0.582,-0.578c0.021,-0.01 0.045,-0.014 0.068,-0.014l3.86,0c0.012,0 0.023,0.001 0.035,0.004c0.346,0.08 0.577,0.444 0.65,0.619c0.498,1.174 2.223,1.971 3.024,2.223c0.844,0.223 1.956,1.245 1.823,1.779c-0.1,0.401 0.132,0.808 0.283,0.99c0.018,0.022 0.042,0.038 0.068,0.048l3.117,1.185l0.13,0.038c0.099,0.028 0.153,0.132 0.12,0.229l-0.24,0.693c-0.043,0.126 -0.207,0.155 -0.292,0.053c-0.019,-0.022 -0.043,-0.04 -0.07,-0.05c-1.053,-0.39 -3.128,-1.15 -3.339,-1.185c-0.173,-0.029 -0.358,0.077 -0.47,0.166c-0.041,0.033 -0.095,0.053 -0.148,0.047l-4.22,-0.474c-0.035,-0.004 -0.07,0.002 -0.1,0.018c-0.28,0.137 -1.01,0.465 -2.265,0.465c-1.25,0 -2.084,-0.239 -2.382,-0.439c-0.043,-0.029 -0.094,-0.046 -0.146,-0.04l-4.052,0.464c-0.08,0.009 -0.158,-0.039 -0.222,-0.087c-0.147,-0.11 -0.427,-0.178 -0.596,-0.205c-0.019,-0.003 -0.037,-0.002 -0.056,0.002c-0.567,0.122 -2.285,0.92 -3.228,1.379c-0.092,0.045 -0.202,0.003 -0.242,-0.09l0,-0.001z" fill="#686868"/>
        <path id="svg_10" d="m17.79503,6.69676l-5.203,0c0.036,-0.25 0.43,-0.519 0.623,-0.623l3.913,0c0.284,0 0.563,0.415 0.667,0.623z" fill="#D2D2D2"/>
        <path id="svg_11" d="m23.71003,14.16476c-0.52,2.405 -2.416,4.87 -3.53,6.058c-0.17,0.18 -0.204,0.45 -0.076,0.661l0.577,0.955c0.182,0.3 0.6,0.347 0.843,0.092l0.452,-0.473c1.494,-1.53 2.816,-5.322 3.29,-7.026c0.307,-0.986 0.285,-1.753 0.23,-2.052c-0.004,-0.026 -0.012,-0.051 -0.022,-0.075c-0.37,-0.89 -1.444,-0.94 -1.942,-0.853c-0.223,0.032 -0.343,0.28 -0.387,0.438c-0.01,0.034 -0.013,0.07 -0.013,0.105c-0.005,0.431 -0.073,0.678 -0.158,0.81c-0.112,0.175 -0.465,0.194 -0.428,0.398c0.004,0.022 0.008,0.045 0.008,0.072c0,0.092 0.52,0.32 0.894,0.467c0.112,0.044 0.215,0.12 0.25,0.237c0.026,0.091 0.022,0.157 0.012,0.186zm-17.035,-0.016c0.52,2.406 2.416,4.87 3.531,6.058c0.17,0.18 0.203,0.45 0.075,0.662l-0.577,0.954c-0.182,0.301 -0.6,0.347 -0.843,0.093l-0.452,-0.474c-1.494,-1.53 -2.816,-5.321 -3.29,-7.026c-0.306,-0.986 -0.285,-1.752 -0.23,-2.051c0.004,-0.026 0.012,-0.052 0.022,-0.076c0.37,-0.89 1.445,-0.94 1.942,-0.852c0.223,0.032 0.344,0.28 0.388,0.438c0.01,0.034 0.012,0.07 0.012,0.104c0.006,0.432 0.073,0.678 0.158,0.81c0.113,0.176 0.466,0.194 0.428,0.399c-0.004,0.021 -0.008,0.045 -0.008,0.072c0,0.091 -0.52,0.32 -0.893,0.467c-0.112,0.044 -0.216,0.12 -0.25,0.236c-0.027,0.091 -0.023,0.158 -0.013,0.186zm7.422,3.528c-0.364,0.174 -0.562,0.444 -0.661,0.693c-0.11,0.272 0.078,0.543 0.342,0.67l1.17,0.558c0.225,0.107 0.488,0.104 0.71,-0.01l1.011,-0.518c0.28,-0.143 0.471,-0.449 0.339,-0.734c-0.177,-0.381 -0.507,-0.614 -0.734,-0.715c-0.052,-0.023 -0.108,-0.033 -0.162,-0.051c-0.27,-0.094 -0.583,-0.46 -0.718,-0.648c-0.06,-0.076 -0.135,-0.1 -0.207,-0.098c-0.17,0.007 -0.274,0.184 -0.394,0.304c-0.144,0.145 -0.357,0.32 -0.515,0.442c-0.056,0.043 -0.118,0.076 -0.181,0.107z" fill="#C9C9C9"/>
        <path id="svg_12" d="m15.08503,32.34976c-4.838,-0.036 -6.048,-6.448 -6.048,-9.65l1.379,-1.067l5.025,3.557l4.269,-3.557l1.6,1.067c-0.035,8.04 -4.165,9.783 -6.225,9.65z" fill="#1E1E1E"/>
        <path id="svg_13" d="m19.70903,20.21076c-4.02,4.091 -7.782,1.705 -9.16,0c-1.21,0.25 -1.986,1.32 -2.223,1.824c0.07,0.284 1.511,1.482 2.223,2.045c0.534,0.249 0.786,1.023 0.845,1.379c0.142,0.462 0.326,2.801 0.4,3.913c2.775,2.099 5.662,0.874 6.76,0c0.035,-1.103 0.31,-3.069 0.444,-3.913c-0.071,-0.748 0.445,-1.35 0.711,-1.557c0.463,-0.107 1.705,-1.29 2.268,-1.867c-0.64,-1.494 -1.779,-1.838 -2.268,-1.824z" fill="#484848"/>
        <rect id="svg_14" rx="1.645" fill="#C4C4C4" y="19.54276" x="11.70403" height="3.291" width="6.848"/>
        <ellipse id="svg_15" ry="3.38" rx="2.713" fill="black" cy="21.18776" cx="15.12903"/>
        <rect id="svg_16" rx="0.934" stroke-width="0.267" stroke="#000" fill="#5B5B5B" y="32.48876" x="7.92503" height="9.249" width="14.408"/>
        <path id="svg_17" d="m18.56603,41.57476c0.057,0.214 0.276,0.341 0.49,0.284c0.213,-0.057 0.34,-0.276 0.283,-0.49l-0.773,0.206zm-0.369,-9.156c-0.075,1.14 -0.12,2.908 -0.078,4.644c0.04,1.723 0.166,3.46 0.447,4.512l0.773,-0.206c-0.253,-0.949 -0.38,-2.599 -0.42,-4.325c-0.04,-1.714 0.004,-3.459 0.077,-4.572l-0.799,-0.053zm-6.462,9.156c-0.057,0.214 -0.276,0.341 -0.49,0.284c-0.214,-0.057 -0.34,-0.276 -0.284,-0.49l0.774,0.206zm0.368,-9.156c0.075,1.14 0.12,2.908 0.079,4.644c-0.041,1.723 -0.167,3.46 -0.447,4.512l-0.774,-0.206c0.253,-0.949 0.38,-2.599 0.42,-4.325c0.041,-1.714 -0.003,-3.459 -0.076,-4.572l0.798,-0.053z" fill="#000"/>
        <path id="svg_18" d="m11.74803,37.24976l6.582,0" stroke-width="0.711" stroke="#000"/>
        <path id="svg_19" d="m14.01603,37.15976l2.134,0" stroke-width="1.334" stroke-linecap="round" stroke="#000"/>
       </g>`;
    }
    innerHTML = innerHTML + '</div>';
    innerHTML =
        innerHTML +
        '<span name="label-el" class="map-label-with-text-shadow" style="visibility: ' +
        '' +
        '; margin-top: ' +
        getMarginBetweenLabelAndIcon(angle, label) +
        'px;"><b>' +
        label?.slice(label?.length - 4) +
        '</b>' +
        '</span>';

    innerHTML = innerHTML + '</div>';
    return new L.divIcon({
        className: '',
        html: innerHTML,
    });
};
