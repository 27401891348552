import { Button, IconButton } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import Modal from './Modal';
import moment from 'moment';
import { FileUploader } from '../UI';
import { CustomTextField } from '../Textfield';
import { CustomDatePicker } from '../DateTimePicker';
import InsertInvitationIcon from '@material-ui/icons/InsertInvitation';
import { connect } from 'react-redux';
import { useFormik } from 'formik';
import * as yup from 'yup';
// import { getBankDetailsFromIFSC } from '../../store/actions/authActionCreator';
import {
    getStaffBankDetails,
    postStaffBankDetails,
} from '../../store/actions/staffActionCreator';
import { CheckMarkGreen } from '../../assets/svg';
// import NumberFormat from 'react-number-format';

const validationSchema = yup.object({
    accountNumber: yup
        .string()
        // .number('Account Number is invalid')
        .matches(/^[0-9]+$/, 'Invalid format')
        .required('Account Number is required'),
    confirmAccountNumber: yup
        .string()
        // .number('Confirm Account Number is invalid')
        .oneOf([yup.ref('accountNumber'), null], 'Account Numbers do not match')
        .required('Confirm Account Number is required'),
    accountHolderName: yup
        .string('Name is invalid')
        .matches(/^[a-zA-Z\s]*$/, 'Name must contain only letter and space')
        .required('Name is required'),
    ifsc: yup.string('IFSC is invalid').required('IFSC is required'),
    docLink: yup.string('file is invalid'),
});

function AddBankDetailsModal({
    open,
    onClose,
    data = {},
    isEdit,
    staff,
    dispatchGetBankDetailFromIFSC,
    dispatchGetBankDetails,
    dispatchPostStaffBankDetails,
    ...props
}) {
    const [isOpen, toggleModal] = useState(open);
    const [loading, toggleLoading] = useState(false);

    const [branchName, setBranchName] = useState(
        staff?.bankDetails?.branchName ?? '',
    );

    useEffect(async () => {
        await toggleLoading(true);
        dispatchGetBankDetails(
            { staffId: data.id, data: {} },
            (data) => {
                console.log('Get User Bank details success');
                setBranchName(data?.response?.branchName ?? '');
                toggleLoading(false);
                formik.setFieldValue(
                    'docLink',
                    data?.response?.passbookImage,
                    true,
                );
                formik.setFieldValue(
                    'accountNumber',
                    data?.response?.accountNumber,
                    true,
                );
                formik.setFieldValue(
                    'confirmAccountNumber',
                    data?.response?.accountNumber,
                    true,
                );
                formik.setFieldValue(
                    'accountHolderName',
                    data?.response?.accountHolderName,
                    true,
                );
                formik.setFieldValue('ifsc', data?.response?.ifscCode, true);
            },
            () => {
                console.log('Get user bank details failed');
                toggleLoading(false);
            },
        );
    }, []);

    const formik = useFormik({
        initialValues: {
            accountNumber: staff?.bankDetails?.accountNumber ?? '',
            confirmAccountNumber: staff?.bankDetails?.accountNumber ?? '',
            accountHolderName: staff?.bankDetails?.accountHolderName ?? '',
            ifsc: staff?.bankDetails?.ifscCode ?? '',
            docLink: staff?.bankDetails?.passbookImage ?? '',
            ...data,
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            await toggleLoading(true);
            dispatchPostStaffBankDetails(
                {
                    staffId: data.id,
                    data: {
                        accountNumber: formik.values.accountNumber,
                        accountHolderName: formik.values.accountHolderName,
                        ifscCode: formik.values.ifsc,
                        branchName: branchName,
                        passbookImage: formik.values.docLink,
                    },
                },
                () => {
                    console.log('Post Bank details SUCCESS');
                    toggleLoading(false);
                    handleClose();
                },
                () => {
                    console.log('Post Bank details FAILED');
                    toggleLoading(false);
                },
            );
        },
    });

    const handleClose = () => {
        toggleModal(!isOpen);
        onClose && onClose();
    };

    const actions = [
        <Button
            key={2}
            variant="contained"
            color="primary"
            className={`saveButton`}
            type="submit"
            disableElevation
        >
            {isEdit ? 'Edit' : 'Add'}
        </Button>,
    ];

    console.log(formik.errors);

    return (
        <Modal
            onClose={handleClose}
            open={isOpen}
            title={`${isEdit ? 'Edit' : 'Add'} Bank Details`}
            subtitle={`${isEdit ? 'Edit' : 'Add'} Bank Details`}
            actions={actions}
            loading={loading}
            isForm={true}
            onSubmit={formik.handleSubmit}
        >
            <CustomTextField
                label="Account Number"
                id="accountNumber"
                name="accountNumber"
                value={formik.values.accountNumber}
                onChange={formik.handleChange}
                inputProps={{ maxLength: 18, minLength: 9 }}
                error={
                    formik.touched.accountNumber
                        ? Boolean(formik.errors.accountNumber)
                        : false
                }
                helperText={
                    formik.touched.accountNumber && formik.errors.accountNumber
                }
                variant="outlined"
                style={{ marginBottom: '16px' }}
                isRequired={true}
            />

            <CustomTextField
                label="Confirm Account Number"
                id="confirmAccountNumber"
                name="confirmAccountNumber"
                value={formik.values.confirmAccountNumber}
                onChange={formik.handleChange}
                inputProps={{ maxLength: 18, minLength: 9 }}
                error={
                    formik.touched.confirmAccountNumber
                        ? Boolean(formik.errors.confirmAccountNumber)
                        : false
                }
                helperText={
                    formik.touched.confirmAccountNumber &&
                    formik.errors.confirmAccountNumber
                }
                variant="outlined"
                style={{ marginBottom: '16px' }}
                isRequired={true}
            />

            {/* <NumberFormat
                value={
                    formik.values.confirmAccountNumber
                        ? formik.values.confirmAccountNumber
                        : ''
                }
                label="Confirm Account Number"
                id="confirmAccountNumber"
                name="confirmAccountNumber"
                mask="*"
                customInput={CustomTextField}
                onValueChange={(val) =>
                    // console.log(val)
                    formik.setFieldValue(
                        'confirmAccountNumber',
                        val ? val : '',
                        true,
                    )
                }
                inputProps={{ maxLength: 18, minLength: 9 }}
                error={
                    formik.touched.confirmAccountNumber
                        ? Boolean(formik.errors.confirmAccountNumber)
                        : false
                }
                helperText={
                    formik.touched.confirmAccountNumber &&
                    formik.errors.confirmAccountNumber
                }
                style={{ marginBottom: '16px' }}
            /> */}

            <CustomTextField
                label="Account Holder Name"
                id="accountHolderName"
                name="accountHolderName"
                value={formik.values.accountHolderName}
                onChange={formik.handleChange}
                inputProps={{ maxLength: 18 }}
                error={
                    formik.touched.accountHolderName
                        ? Boolean(formik.errors.accountHolderName)
                        : false
                }
                helperText={
                    formik.touched.accountHolderName &&
                    formik.errors.accountHolderName
                }
                variant="outlined"
                style={{ marginBottom: '16px' }}
                isRequired={true}
            />

            <CustomTextField
                label="IFSC"
                id="ifsc"
                name="ifsc"
                value={formik.values.ifsc}
                onChange={formik.handleChange}
                error={
                    formik.touched.ifsc ? Boolean(formik.errors.ifsc) : false
                }
                helperText={formik.touched.ifsc && formik.errors.ifsc}
                variant="outlined"
                style={{ marginBottom: '16px' }}
                inputProps={{ maxLength: 11 }}
                InputProps={{
                    endAdornment:
                        formik.values.ifsc &&
                        formik.values.ifsc?.length === 11 &&
                        branchName ? (
                            <CheckMarkGreen />
                        ) : (
                            <></>
                        ),
                }}
                onBlur={() => {
                    if (
                        formik.values.ifsc &&
                        formik.values.ifsc?.length === 11
                    ) {
                        fetch('https://ifsc.razorpay.com/' + formik.values.ifsc)
                            .then(function (response) {
                                if (!response.ok) {
                                    throw Error(response.statusText);
                                }
                                return response.json();
                            })
                            .then(
                                function (data) {
                                    console.log('IFSCCCC', data);
                                    setBranchName(
                                        `${data.BANK} (${data.BRANCH})`,
                                    );
                                },
                                (err) => {
                                    console.log('Fetch IFSC Error', err);
                                },
                            )
                            .catch(function (error) {
                                console.log('Caught fetch IFSC Error', error);
                            });
                        // dispatchGetBankDetailFromIFSC(
                        //     formik.values.ifsc,
                        //     () => {
                        //         setBranchName(branchName);
                        //         console.log('RazorPay SUCCESS');
                        //     },
                        //     () => {
                        //         setBranchName('');
                        //         console.log('RazorPay FAILED');
                        //     },
                        // );
                    } else {
                        setBranchName('');
                    }
                }}
                isRequired={true}
            />

            <CustomTextField
                label="Branch Name"
                id="branchName"
                name="branchName"
                value={formik.values.ifsc?.length === 11 ? branchName : ''}
                variant="outlined"
                style={{ marginBottom: '16px' }}
                disabled={true}
                isRequired={true}
            />

            <FileUploader
                label={'Upload Support Document'}
                placeholder={'Upload Passbook'}
                defaultValue={formik.values.docLink}
                onFileUploaded={(url) => {
                    console.log(url);
                    formik.setFieldValue('docLink', url, true);
                }}
                error={
                    formik.touched.docLink
                        ? Boolean(formik.errors.docLink)
                        : false
                }
                helperText={formik.touched.docLink && formik.errors.docLink}
                isRequired={true}
            />
        </Modal>
    );
}
const mapStateToProps = (state) => ({
    staff: state?.staff,
});

const mapDispatchToProps = (dispatch) => ({
    // dispatchGetBankDetailFromIFSC: (value, onSuccess, onError) => {
    //     dispatch(getBankDetailsFromIFSC(value, onSuccess, onError));
    // },

    dispatchGetBankDetails: (value, onSuccess, onError) => {
        dispatch(getStaffBankDetails(value, onSuccess, onError));
    },

    dispatchPostStaffBankDetails: (value, onSuccess, onError) => {
        dispatch(postStaffBankDetails(value, onSuccess, onError));
    },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AddBankDetailsModal);
