import React from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import StaffPayLogo from '../../assets/images/StaffPayLogo.png';

const useStyles = makeStyles((theme) => ({}));
const Vivo = () => {
    const classes = useStyles();
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <Box
            style={{
                backgroundColor: '#fff',
                // marginBottom: '2rem',
            }}
        >
            <Box
                style={{
                    height: '15%',
                    padding: '14px',
                    boxShadow: '0 1px 1px 0 rgba(67, 65, 158, 0.07)',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                }}
            >
                <img
                    src={StaffPayLogo}
                    style={{
                        height: '3rem',
                        borderRadius: '8px',
                        objectFit: 'contain',
                    }}
                />
                <Typography
                    style={{
                        fontSize: '28px',
                        fontWeight: 700,
                        color: '#43419e',
                        marginLeft: '1rem',
                    }}
                >
                    StaffPay
                </Typography>
            </Box>

            <Box
                className="privacy-content"
                style={{
                    textAlign: 'justify',
                    padding: '2px 10px 2px 10px',
                    paddingBottom: '2rem',
                }}
            >
                <Typography
                    style={{
                        fontSize: '20px',
                        fontWeight: 700,
                        textAlign: 'center',
                        marginBottom: '0.5rem',
                    }}
                >
                    Additional Settings Required for your Vivo phone for
                    Accurate Location Tracking
                </Typography>

                <Typography
                    style={{
                        fontSize: '16px',
                        fontWeight: 600,
                        textAlign: 'center',
                        marginBottom: '1rem',
                    }}
                >
                    Please follow the instructions in the below video
                </Typography>
                <div
                    className="video-responsive"
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    <iframe
                        height="480"
                        src={`https://www.youtube.com/embed/hgWZI2nYwew`}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        title="Embedded youtube"
                    />
                </div>
            </Box>
        </Box>
    );
};

export default withRouter(Vivo);
