import { Box, Button, MenuItem, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Modal from './Modal';
import { connect } from 'react-redux';
import { updateLeadStatus } from '../../store/actions/leadActionCreator';

function VerifiedHireModal({
    leadInfo,
    open,
    onClose,
    defaultValue = {},
    dispatchUpdateLeadStatus,
    ...props
}) {
    const [isOpen, toggleModal] = useState(open);
    const [loading, toggleLoading] = useState(false);

    const handleClose = (values) => {
        toggleModal(!isOpen);
        onClose && onClose(values);
    };

    const markVerified = () => {
        toggleLoading(true);
        const payload = {
            status: 'Verified',
            comments: '',
        };
        dispatchUpdateLeadStatus(
            leadInfo.id,
            payload,
            (resp) => {
                toggleLoading(false);
                handleClose('');
            },
            (err) => {
                toggleLoading(false);
                handleClose('');
            },
        );
    };
    const actions = [
        <Button
            key={1}
            variant="contained"
            color="default"
            className={`cancelButton`}
            onClick={() => handleClose(defaultValue)}
            style={{
                boxShadow: 'none',
            }}
            disableElevation
        >
            Cancel
        </Button>,
        <Button
            key={2}
            variant="contained"
            color="primary"
            className={`saveButton`}
            type="submit"
            onClick={markVerified}
            style={{
                boxShadow: 'none',
            }}
            disableElevation
        >
            Verified
        </Button>,
    ];

    return (
        <Modal
            onClose={() => handleClose(defaultValue)}
            open={isOpen}
            title={'Mark Verified'}
            actions={actions}
            loading={loading}
            isForm={false}
        >
            <Box
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Typography
                    style={{
                        // fontSize: '14px',
                        fontWeight: 600,
                    }}
                >
                    Mark
                </Typography>
                <Typography
                    style={{
                        fontWeight: 'bold',
                        fontSize: '16px',
                        marginLeft: '2px',
                        marginRight: '2px',
                    }}
                >
                    "{leadInfo?.name ?? ''}"
                </Typography>
                <Typography
                    style={{
                        // fontSize: '14px',
                        fontWeight: 600,
                    }}
                >
                    as verfied ?
                </Typography>
            </Box>
        </Modal>
    );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
    dispatchUpdateLeadStatus: (id, data, onSuccess, OnFailed) =>
        dispatch(updateLeadStatus(id, data, onSuccess, OnFailed)),
});

export default connect(mapStateToProps, mapDispatchToProps)(VerifiedHireModal);
