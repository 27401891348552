import React, { useEffect, useState } from 'react';
import './styles.scss';
import {
    Grid,
    makeStyles,
    Typography,
    Paper,
    Button,
    Box,
    Modal,
} from '@material-ui/core';
import ExpanedableTable from './ExpanedableTable';
import { Spinner } from '../../../components/UI';
import { connect } from 'react-redux';
import {
    deleteStaffPayment,
    editStaffPayment,
    getAllPayment,
} from '../../../store/actions/paymentActionCreator';
import AddPaymentModal from '../../../components/Modals/AddPaymentModal';
import { capitalize, formatCurrency, showMessage } from '../../../utils/helper';
import AddAllowanceBonusModal from '../../../components/Modals/AddAllowanceBonusModal';
import AddDeductionModal from '../../../components/Modals/AddDeductionModal';
import AddWorkModal from '../../../components/Modals/AddWorkModal';
import { deleteStaffWork } from '../../../store/actions/workActionCreator';
import DeleteModal from '../../../components/Modals/DeleteModal';
import {
    deleteStaffBonus,
    editStaffBonus,
} from '../../../store/actions/bonusActionCreator';
import { deleteStaffDeduction } from '../../../store/actions/deductionActionCreator';
import { EmptyIcon } from '../../../assets/svg';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        borderRadius: '5px',
        border: '2px #ebedf8 solid',
        backgroundColor: '#ebedf8',
    },
}));

const PaymentSummaryComp = ({
    staffInfo,
    paymentDue,
    monthItems,
    permissions,
    dispatchGetAllPayment,
    dispatchDeleteStaffWork,
    dispatchDeleteStaffPayment,
    dispatchDeleteStaffAllowance,
    dispatchDeleteStaffDeduction,
    ...props
}) => {
    const classes = useStyles();
    console.log(classes);
    const [loading, toggleLoading] = useState(false);
    const [selectedRow, setSelectedRow] = useState({});
    const [deleteType, setDeleteType] = useState('');
    const [deleteStaffModalOpen, setDeleteStaffModalOpen] =
        React.useState(false);
    const [paymentStaffModalOpen, setPaymentStaffModalOpen] =
        React.useState(false);
    const [bonusStaffModalOpen, setBonusStaffModalOpen] = React.useState(false);
    const [deductionStaffModalOpen, setDeductionStaffModalOpen] =
        React.useState(false);
    const [workStaffModalOpen, setWorkStaffModalOpen] = React.useState(false);

    useEffect(() => {
        getAllPayments();
    }, []);

    const getAllPayments = async (filter = {}) => {
        await toggleLoading(true);
        dispatchGetAllPayment(
            { id: staffInfo.id },
            async (resp) => {
                console.log(resp);
                await toggleLoading(false);
            },
            async (resp) => {
                console.log(resp);
                await toggleLoading(false);
            },
        );
    };
    const togglePaymentModal = (row) => {
        setSelectedRow(row);
        setPaymentStaffModalOpen(true);
    };
    const deleteModal = (type, row) => {
        setSelectedRow(row);
        setDeleteType(type);
        setDeleteStaffModalOpen(true);
    };
    const toggleWorkModal = (row) => {
        setSelectedRow(row);
        setWorkStaffModalOpen(true);
    };
    const toggleAllowanceModal = (row) => {
        setSelectedRow(row);
        setBonusStaffModalOpen(true);
    };
    const toggleDeductionModal = (row) => {
        setSelectedRow(row);
        setDeductionStaffModalOpen(true);
    };

    const deleteActions = [
        <Button
            key={1}
            variant="contained"
            color="default"
            className={`cancelButton`}
            onClick={(e) => setDeleteStaffModalOpen(false)}
            disableElevation
        >
            {'Cancel'}
        </Button>,
        <Button
            key={2}
            variant="contained"
            className={`saveButton`}
            style={{
                backgroundColor: '#f44336',
                color: '#fff',
            }}
            disableElevation
            onClick={async () => {
                // alert(`${isEdit ? 'Edit Clicked' : 'Add Clicked'}`);
                // await toggleLoading(true);
                getAllPayments();
            }}
        >
            Delete
        </Button>,
    ];
    return (
        <Box style={{ padding: '0.25rem 0.5rem' }} elevation={3}>
            <Spinner loading={loading}>
                {monthItems?.length ? (
                    <div className="payment-summary">
                        <Grid container spacing={1}>
                            <Grid
                                item
                                xs={12}
                                style={{
                                    display: 'flex',
                                    justifyContent: 'start',
                                    alignItems: 'center',
                                }}
                            >
                                <Typography
                                    variant="h6"
                                    style={{
                                        fontWeight: 600,
                                    }}
                                >
                                    Payment Due :{' '}
                                    <b>{formatCurrency(paymentDue)}</b>
                                </Typography>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={(e) =>
                                        setPaymentStaffModalOpen(true)
                                    }
                                    style={{ marginLeft: '24px' }}
                                    disabled={!permissions.create}
                                    disableElevation
                                >
                                    Clear Dues
                                </Button>
                            </Grid>

                            <Grid item xs={12}>
                                <ExpanedableTable
                                    rows={monthItems}
                                    togglePaymentModal={togglePaymentModal}
                                    toggleWorkModal={toggleWorkModal}
                                    toggleAllowanceModal={toggleAllowanceModal}
                                    toggleDeductionModal={toggleDeductionModal}
                                    deleteModal={deleteModal}
                                    permissions={permissions}
                                />
                            </Grid>
                        </Grid>
                        {paymentStaffModalOpen && (
                            <AddPaymentModal
                                open={true}
                                staffInfo={staffInfo}
                                isEdit={Boolean(
                                    Object.keys(selectedRow).length,
                                )}
                                data={{ amount: paymentDue, ...selectedRow }}
                                onClose={() => {
                                    setPaymentStaffModalOpen(false);
                                    getAllPayments();
                                    setSelectedRow({});
                                }}
                            />
                        )}
                        {workStaffModalOpen && (
                            <AddWorkModal
                                open={true}
                                staffInfo={staffInfo}
                                isEdit={Boolean(
                                    Object.keys(selectedRow).length,
                                )}
                                data={{ ...selectedRow }}
                                onClose={() => {
                                    setWorkStaffModalOpen(false);
                                    getAllPayments();
                                    setSelectedRow({});
                                }}
                            />
                        )}
                        {bonusStaffModalOpen && (
                            <AddAllowanceBonusModal
                                open={true}
                                staffInfo={staffInfo}
                                isEdit={Boolean(
                                    Object.keys(selectedRow).length,
                                )}
                                data={{ ...selectedRow }}
                                onClose={() => {
                                    setBonusStaffModalOpen(false);
                                    getAllPayments();
                                    setSelectedRow({});
                                }}
                            />
                        )}
                        {deductionStaffModalOpen && (
                            <AddDeductionModal
                                open={true}
                                staffInfo={staffInfo}
                                isEdit={Boolean(
                                    Object.keys(selectedRow).length,
                                )}
                                data={{ ...selectedRow }}
                                onClose={() => {
                                    setDeductionStaffModalOpen(false);
                                    getAllPayments();
                                    setSelectedRow({});
                                }}
                            />
                        )}
                        {!!deleteStaffModalOpen && (
                            <DeleteModal
                                open={true}
                                staffInfo={staffInfo}
                                data={{ ...selectedRow }}
                                onClose={() => {
                                    setDeductionStaffModalOpen(false);
                                    getAllPayments();
                                    setSelectedRow({});
                                }}
                                deleteType={deleteType}
                                onDeletion={async (toggleLoading) => {
                                    switch (deleteType) {
                                        case 'DEDUCTION':
                                            dispatchDeleteStaffDeduction(
                                                selectedRow.id,
                                                {
                                                    ...selectedRow,
                                                    id: staffInfo.id,
                                                },
                                                async (resp) => {
                                                    await toggleLoading(false);
                                                    showMessage(
                                                        'Deduction Deleted',
                                                        'success',
                                                    );
                                                    await setDeleteStaffModalOpen(
                                                        false,
                                                    );
                                                    getAllPayments();
                                                },
                                                async (resp) => {
                                                    await toggleLoading(false);
                                                },
                                            );
                                            break;
                                        case 'ALLOWANCE':
                                            dispatchDeleteStaffAllowance(
                                                selectedRow.id,
                                                {
                                                    ...selectedRow,
                                                    id: staffInfo.id,
                                                },
                                                async (resp) => {
                                                    await toggleLoading(false);
                                                    showMessage(
                                                        'Allowance Deleted',
                                                        'success',
                                                    );
                                                    await setDeleteStaffModalOpen(
                                                        false,
                                                    );
                                                    getAllPayments();
                                                },
                                                async (resp) => {
                                                    await toggleLoading(false);
                                                },
                                            );
                                            break;
                                        case 'EARNING':
                                            dispatchDeleteStaffWork(
                                                selectedRow.id,
                                                {
                                                    ...selectedRow,
                                                    id: staffInfo.id,
                                                },
                                                async (resp) => {
                                                    await toggleLoading(false);
                                                    showMessage(
                                                        'Earnings Deleted',
                                                        'success',
                                                    );
                                                    await setDeleteStaffModalOpen(
                                                        false,
                                                    );
                                                    getAllPayments();
                                                },
                                                async (resp) => {
                                                    await toggleLoading(false);
                                                },
                                            );
                                            break;
                                        case 'PAYMENT':
                                            dispatchDeleteStaffPayment(
                                                selectedRow.id,
                                                {
                                                    ...selectedRow,
                                                    id: staffInfo.id,
                                                },
                                                async (resp) => {
                                                    await toggleLoading(false);
                                                    showMessage(
                                                        'Payment Deleted',
                                                        'success',
                                                    );
                                                    await setDeleteStaffModalOpen(
                                                        false,
                                                    );
                                                    getAllPayments();
                                                },
                                                async (resp) => {
                                                    await toggleLoading(false);
                                                },
                                            );
                                            break;
                                        default:
                                            break;
                                    }
                                }}
                            />
                        )}
                    </div>
                ) : (
                    <Box
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <EmptyIcon />
                    </Box>
                )}
            </Spinner>
        </Box>
    );
};

const mapStateToProps = (state) => ({
    modals: state.modals,
    paymentDue: state.payment?.paymentDue,
    monthItems: state.payment?.monthItems,
});

const mapDispatchToProps = (dispatch) => ({
    dispatchGetAllPayment: (payload, onSuccess, onError) =>
        dispatch(getAllPayment(payload, onSuccess, onError)),
    dispatchDeleteStaffWork: (id, payload, onSuccess, onError) =>
        dispatch(
            deleteStaffWork({ ...payload, workId: id }, onSuccess, onError),
        ),
    dispatchDeleteStaffPayment: (id, payload, onSuccess, onError) =>
        dispatch(
            deleteStaffPayment(
                { ...payload, paymentId: id },
                onSuccess,
                onError,
            ),
        ),
    dispatchDeleteStaffAllowance: (id, payload, onSuccess, onError) =>
        dispatch(
            deleteStaffBonus(
                { ...payload, allowanceId: id },
                onSuccess,
                onError,
            ),
        ),
    dispatchDeleteStaffDeduction: (id, payload, onSuccess, onError) =>
        dispatch(
            deleteStaffDeduction(
                { ...payload, deductionId: id },
                onSuccess,
                onError,
            ),
        ),
});

export const PaymentSummary = connect(
    mapStateToProps,
    mapDispatchToProps,
)(PaymentSummaryComp);
