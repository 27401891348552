import { Button, IconButton } from '@material-ui/core';
import React, { useState } from 'react';
import Modal from './Modal';
import moment from 'moment';
import { CustomTextField } from '../Textfield';
import InsertInvitationIcon from '@material-ui/icons/InsertInvitation';
import {
    addStaffWork,
    editStaffWork,
} from '../../store/actions/workActionCreator';
import { connect } from 'react-redux';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { CustomDatePicker } from '../DateTimePicker';

const validationSchema = yup.object({
    dateEpochMillis: yup.string('Date is invalid').required('Date is required'),
    amount: yup.number('Amount is required').required('Amount is required'),
    noOfDeliveries: yup.number('No of Deliveries is invalid'),
    notes: yup.string('Notes is invalid'),
});

function AddWorkModal({
    staffInfo,
    data = {},
    isEdit,
    dispatchAddStaffWork,
    dispatchEditStaffWork,
    open,
    onClose,
    ...props
}) {
    // const classes = useStyles();
    const [isOpen, toggleModal] = useState(open);
    const [loading, toggleLoading] = useState(false);
    const [datePickerOpen, setDatePickerOpen] = useState(false);

    const handleClose = () => {
        toggleModal(!isOpen);
        onClose && onClose();
    };

    const formik = useFormik({
        initialValues: {
            dateEpochMillis: moment().valueOf(),
            amount: '',
            noOfDeliveries: '',
            notes: '',
            ...data,
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            await toggleLoading(true);
            if (isEdit) {
                dispatchEditStaffWork(
                    data.id,
                    { ...values, id: staffInfo.id },
                    async (resp) => {
                        await toggleLoading(false);
                        handleClose();
                    },
                    async (resp) => {
                        await toggleLoading(false);
                    },
                );
            } else {
                dispatchAddStaffWork(
                    { id: staffInfo.id, ...values },
                    async (resp) => {
                        await toggleLoading(false);
                        handleClose();
                    },
                    async (resp) => {
                        await toggleLoading(false);
                    },
                );
            }
        },
    });

    const actions = [
        <Button
            key={1}
            variant="contained"
            color="default"
            className={`cancelButton`}
            onClick={handleClose}
            disableElevation
        >
            {'Cancel'}
        </Button>,
        <Button
            key={2}
            variant="contained"
            color="primary"
            className={`saveButton`}
            type="submit"
            disableElevation
        >
            {isEdit ? 'Update Work' : 'Add Work'}
        </Button>,
    ];

    return (
        <Modal
            onClose={handleClose}
            open={isOpen}
            title={isEdit ? 'Edit Work' : 'Add Work'}
            subtitle={`${isEdit ? 'Edit' : 'Add'} missing work of your staff`}
            actions={actions}
            loading={loading}
            isForm={true}
            onSubmit={formik.handleSubmit}
        >
            <CustomTextField
                label="Date"
                InputProps={{
                    readOnly: true,
                    endAdornment: (
                        <IconButton
                            className={'inputbutton'}
                            onClick={(e) => setDatePickerOpen(true)}
                        >
                            <InsertInvitationIcon size="small" />
                        </IconButton>
                    ),
                }}
                onClick={(e) => setDatePickerOpen(true)}
                id="dateEpochMillis"
                name="dateEpochMillis"
                value={moment(formik.values.dateEpochMillis).format(
                    'yyyy-MM-DD',
                )}
                onChange={formik.handleChange}
                error={
                    formik.touched.dateEpochMillis
                        ? Boolean(formik.errors.dateEpochMillis)
                        : false
                }
                helperText={
                    formik.touched.dateEpochMillis &&
                    formik.errors.dateEpochMillis
                }
                variant="outlined"
                style={{
                    marginBottom: '16px',
                }}
                isRequired={true}
            />
            <CustomTextField
                fullWidth={true}
                inputProps={{
                    type: 'number',
                    min: 1,
                }}
                label="No of deliveries"
                variant="outlined"
                id="noOfDeliveries"
                name="noOfDeliveries"
                value={formik.values.noOfDeliveries}
                onChange={formik.handleChange}
                error={
                    formik.touched.noOfDeliveries
                        ? Boolean(formik.errors.noOfDeliveries)
                        : false
                }
                helperText={
                    formik.touched.noOfDeliveries &&
                    formik.errors.noOfDeliveries
                }
                style={{ marginBottom: '16px' }}
            />
            <CustomTextField
                fullWidth={true}
                inputProps={{
                    type: 'number',
                    min: 1,
                }}
                label="Total Delivery Earnings"
                variant="outlined"
                id="amount"
                name="amount"
                value={formik.values.amount}
                onChange={formik.handleChange}
                error={
                    formik.touched.amount
                        ? Boolean(formik.errors.amount)
                        : false
                }
                helperText={formik.touched.amount && formik.errors.amount}
                style={{ marginBottom: '16px' }}
                isRequired={true}
            />
            <CustomTextField
                multiline={true}
                rows={4}
                fullWidth={true}
                label="Notes"
                variant="outlined"
                id="notes"
                name="notes"
                value={formik.values.notes}
                onChange={formik.handleChange}
                error={
                    formik.touched.notes ? Boolean(formik.errors.notes) : false
                }
                helperText={formik.touched.notes && formik.errors.notes}
                style={{ marginBottom: '16px' }}
            />
            {datePickerOpen && (
                <CustomDatePicker
                    open={datePickerOpen}
                    onOpen={() => setDatePickerOpen(true)}
                    onClose={() => setDatePickerOpen(false)}
                    format="d MMM yyyy"
                    value={formik.values.dateEpochMillis}
                    onChange={(e) => {
                        console.log(moment(e).valueOf());
                        formik.setFieldValue(
                            'dateEpochMillis',
                            moment(e).valueOf(),
                            true,
                        );
                        // setSelectedDate(moment(e));
                    }}
                    allowFutureDates={false}
                />
            )}
        </Modal>
    );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
    dispatchAddStaffWork: (payload, onSuccess, onError) =>
        dispatch(addStaffWork(payload, onSuccess, onError)),
    dispatchEditStaffWork: (id, payload, onSuccess, onError) =>
        dispatch(editStaffWork({ ...payload, workId: id }, onSuccess, onError)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddWorkModal);
