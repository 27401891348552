import { Box, Button, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import Modal from './Modal';

function OdometerViewModal({
    open,
    onClose,
    viewType,
    kmReading,
    kmS3Url,
    ...props
}) {
    const [isOpen, toggleModal] = useState(open);
    const [loading, toggleLoading] = useState(false);
    const handleClose = () => {
        // Setting form data to null before closing
        toggleModal(!isOpen);
        onClose && onClose();
    };

    const actions = [
        <Button
            key={2}
            variant="contained"
            color="primary"
            className={`saveButton`}
            onClick={handleClose}
            disableElevation
        >
            Close
        </Button>,
    ];
    return (
        <Modal
            onClose={handleClose}
            open={isOpen}
            title={`${viewType == 'START' ? 'Start' : 'End'} KM Reading`}
            actions={actions}
            loading={loading}
        >
            <Box>
                <img
                    src={kmS3Url}
                    style={{
                        maxHeight: '420px',
                        width: '100%',
                        borderRadius: '8px',
                        objectFit: 'contain',
                    }}
                />
            </Box>
            <Typography
                style={{
                    // textAlign: 'center',
                    marginTop: 2,
                    marginBottom: 0,
                    fontSize: 16,
                }}
            >
                <b>
                    {`${viewType == 'START' ? 'Start' : 'End'} KM Reading :` ??
                        ''}
                </b>
                {` ${kmReading} KM` ?? ''}
            </Typography>
        </Modal>
    );
}

export default OdometerViewModal;
