import { Box, RadioGroup, Button, Radio, FormControlLabel, Typography, withStyles, FormLabel, FormControl } from "@material-ui/core";
import { useState } from "react";
import { connect } from 'react-redux';
import { editStaff } from '../../store/actions/staffActionCreator';
import { enqueueSnackbar } from '../../store/actions/notificationActionCreator';
import Modal from './Modal';
import TrainingPendingSnackbar from "../TrainingPendingSnackbar";
import { useSnackbar } from 'notistack';

const ListRadio = withStyles({
    root: {
      color: 'rgba(117, 117, 117, 1)',
      '&$checked': {
        color: 'rgba(67, 65, 158, 1)',
      },
    },
    checked: {},
  })((props) => <Radio color="default" {...props} />);

const RadioButton = withStyles({
    root: {
        width: '100%',
        checked: {}
    },
})((props) => (
    <Box
        style={{
            display: 'flex',
            flexDirection: 'column',
            padding: 8,
            background: props.background,
            borderRadius: '8px',
            marginTop: 12,
        }}
    >
        <FormControlLabel control={<ListRadio checked={props.checked}/>} {...props} />
        {/* <ListRadio checked={props.checked} {...props}/> */}
    </Box>
));

function UpdateTrainingStatusModal({
    open,
    onCloseWithoutRefresh,
    onClose,
    staffInfo,
    dispatchUpdateTrainingStatus,
    dispatchSnackbar,
    ...props
}) {
    const [isOpen, toggleModal] = useState(open);
    const [loading, toggleLoading] = useState(false);
    const [trainingCompleted, setTrainingCompleted] = useState(
        staffInfo.trainingCompleted != null ? 
        staffInfo.trainingCompleted.toString() :
        'false'
    );
    const { enqueueSnackbar: enqueueCustomSnackbar } = useSnackbar();

    const handleClose = () => {
        toggleModal(!isOpen);
        onCloseWithoutRefresh && onCloseWithoutRefresh();
    };
    const handleCloseWithRefresh = () => {
        toggleModal(!isOpen);
        onClose && onClose();
    };
    const actions = [
        <Button
            key={1}
            variant="contained"
            color="default"
            className={`cancelButton`}
            onClick={handleClose}
            disableElevation
            style={{
                width: '138px'
            }}
        >
            {'Cancel'}
        </Button>,
        <Button
            key={2}
            variant="contained"
            color="primary"
            className={`saveButton`}
            type="submit"
            onClick={async () => {
                await toggleLoading(true);
                let shiftTimingIds = [];
                staffInfo.hubShiftMappings?.forEach(hub => hub.shiftLineItems?.forEach(item => shiftTimingIds.push(item.id)));
                let payload = {
                    id: staffInfo?.id,
                    value: {
                        isTrainingCompleted: trainingCompleted === 'true',
                        shiftTimingIds
                    }
                };
                dispatchUpdateTrainingStatus(
                    payload,
                    async () => {
                        console.log('Update TrainingStatus SUCCESS');
                        if (trainingCompleted === 'true') {
                            dispatchSnackbar('Training Status Marked Completed', 'success');
                        } else {
                            enqueueCustomSnackbar('Training Status Marked Pending', {
                                key:
                                    new Date().getTime() +
                                    Math.random(),
                                content: (key, message) => (
                                    <TrainingPendingSnackbar id={key} message={message} />
                                )
                            });
                        }
                        await toggleLoading(false);
                        handleCloseWithRefresh();
                    },
                    async () => {
                        await toggleLoading(false);
                        dispatchSnackbar('Training Status Marked Failed' , 'error');
                        handleCloseWithRefresh();
                    },
                );
            }}
            disableElevation
            style={{
                width: '138px'
            }}
        >
            {'Confirm'}
        </Button>,
    ];
    return(
        <Modal
            onClose={handleClose}
            open={isOpen}
            title={'Training Status'}
            stickyActions
            actions={actions}
            loading={loading}
            maxWidth={340}
            fullWidth={false}
            dialogActionsCenterAligned={true}
            >
                <RadioGroup
                    value={trainingCompleted.toString()}
                    onChange={(e) => {
                        console.log('setTrainingCompleted: ', e.target.value);
                        setTrainingCompleted(e.target.value);
                    }}
                >
                    <RadioButton
                        value={'false'}
                        background={trainingCompleted === 'true' ? 'rgba(255, 255, 255, 1)' : 'rgba(67, 65, 158, 0.08)'}
                        label={
                            <Typography
                                variant="span"
                                style={{
                                    fontSize: 16,
                                    fontWeight: 600,
                                    color: trainingCompleted === 'true' ? 'rgba(20, 17, 45, 1)' : 'rgba(67, 65, 158, 1)'
                                }}
                            >
                                {'Pending'}
                            </Typography>
                        }
                    />
                    <RadioButton
                        value={'true'}
                        background={trainingCompleted === 'true' ? 'rgba(67, 65, 158, 0.08)' : 'rgba(255, 255, 255, 1)'}
                        label={
                            <Typography
                                variant="span"
                                style={{
                                    fontSize: 16,
                                    fontWeight: 600,
                                    color: trainingCompleted === 'true' ? 'rgba(67, 65, 158, 1)' : 'rgba(20, 17, 45, 1)'
                                }}
                            >
                                {'Completed'}
                            </Typography>
                        }
                    />
                </RadioGroup>
        </Modal>
    );
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({
    dispatchUpdateTrainingStatus: (payload, onSuccess, onError) =>
        dispatch(editStaff(payload, onSuccess, onError)),
    dispatchSnackbar: (message, variant) =>
        dispatch(
            enqueueSnackbar({
                message,
                options: {
                    key:
                        new Date().getTime() +
                        Math.random(),
                    variant,
                },
            })
        ),
});

export default connect(mapStateToProps, mapDispatchToProps)(UpdateTrainingStatusModal);