import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { punchIn, punchOut } from '../../store/actions/attendanceActionCreator';

import { Button, IconButton } from '@material-ui/core';
import Modal from './Modal';
import { CustomTextField } from '../Textfield';
import { CustomDatePicker, CustomTimePicker } from '../DateTimePicker';
import { dateTimeToMilliseconds } from '../../utils/helper';
import InsertInvitationIcon from '@material-ui/icons/InsertInvitation';
import moment from 'moment';
import {
    getKycDetails,
    staffKycApproveReject,
} from '../../store/actions/staffActionCreator';

function RejectEVModal({
    open,
    onClose,
    onConfirm,
    data = {},
    evInfo,
    ...props
}) {
    const [isOpen, toggleModal] = useState(open);
    const [state, setState] = useState({
        remarks: '',
    });
    const [isloading, toggleLoading] = useState(false);

    const handleClose = () => {
        // Setting form data to null before closing
        setState(null);
        toggleModal(!isOpen);
        onClose && onClose();
    };

    useEffect(() => {
        console.log('state', state);
    }, [state]);

    const actions = [
        <Button
            key={1}
            variant="contained"
            color="default"
            className={`cancelButton`}
            onClick={handleClose}
            disableElevation
        >
            {'Cancel'}
        </Button>,
        <Button
            key={2}
            variant="contained"
            color="primary"
            className={`saveButton`}
            disableElevation
            onClick={() => {
                !!onConfirm && onConfirm(state?.remarks);
                toggleModal(!open);
            }}
        >
            {'Reject'}
        </Button>,
    ];

    const handleInputChange = (value, key) => {
        setState((state) => ({
            ...state,
            [key]: value,
        }));
    };

    return (
        <Modal
            onClose={handleClose}
            open={isOpen}
            title={'Reject'}
            actions={actions}
            loading={isloading}
        >
            <CustomTextField
                fullWidth={true}
                label="Enter reason"
                value={state?.remarks}
                onChange={(e) => handleInputChange(e.target.value, 'remarks')}
            />
        </Modal>
    );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(RejectEVModal);
