import { makeStyles } from '@material-ui/core';
import React from 'react';
const useRiderMapStyles = makeStyles((theme: any) => ({
    container: (props: any) => ({
        height: 'calc(100vh - 105px)',
        '& .leaflet-container': {
            width: '100%',
            height: !!props?.containerHeight
                ? props?.containerHeight - 2
                : '84vh',
            flexGrow: 1,
            // borderRadius: 16,
            borderTopRightRadius: 16,
            borderBottomRightRadius: 16,
        },
    }),
    card: {
        backgroundColor: '#f6f7f9',
        marginTop: 16,
        color: '#000',
        padding: 16,
    },
    routeContainer: {
        padding: '12px 12px',
        paddingBottom: 0,
    },
    backIcon: {
        minWidth: '0',
        color: '#000000',
        cursor: 'pointer',
        marginRight: '8px',
    },
    scrollableBox: {
        width: '100%',
        height: '38vh',
        overflowY: 'auto',
        minHeight: '38vh',
        maxHeight: '38vh',
        marginTop: 12,
        borderRadius: 16,
    },
}));
export default useRiderMapStyles;
