import React, { useEffect } from 'react';
import { BrowserRouter, Switch, Redirect, Route } from 'react-router-dom';
import routes from '../../routes';
import { RouteLayouts } from '../../utils/constants';
import MainLayout from '../../layout/MainLayout';
import BlankLayout from '../../layout/BlankLayout';

function AppRoute(props) {
    useEffect(() => {
        if (!!localStorage?.USER_INFO && !!window?.clarity) {
            const user = JSON.parse(atob(localStorage?.USER_INFO));
            window.clarity('identify', user?.id + '');
            window.clarity('set', 'name', user?.name);
            window.clarity('set', 'number', user?.contactNumber);
            window.clarity('set', 'userOrgId', user?.organizationId);
            window.clarity('set', 'currentOrgName', localStorage?.org);
        }
    }, []);

    const getLayouts = (routes) => {
        let setRoutes = routes.map((prop, key) => {
            switch (prop.layout) {
                case RouteLayouts.MainLayout:
                    return (
                        <MainLayout
                            {...props}
                            key={key}
                            path={prop.path}
                            component={prop.component}
                            name={
                                !!prop.routeName ? prop?.routeName : prop?.name
                            }
                            containerStyles={prop?.containerStyles}
                        />
                    );
                case RouteLayouts.BlankLayout:
                    return (
                        <BlankLayout
                            {...props}
                            key={key}
                            path={prop.path}
                            component={prop.component}
                            name={prop.name}
                        />
                    );
                default:
                    return (
                        <MainLayout
                            {...props}
                            key={key}
                            path={prop.path}
                            component={prop.component}
                            name={
                                !!prop.routeName ? prop?.routeName : prop?.name
                            }
                            containerStyles={prop?.containerStyles}
                        />
                    );
            }
        });
        return setRoutes;
    };
    return (
        <div className="app-routes">
            <BrowserRouter>
                <Switch>
                    {getLayouts(routes)}
                    <Route
                        exact
                        path="/"
                        render={() => <Redirect to={'/login'} />}
                    />
                    <Route render={() => <Redirect to={'/404'} />} />
                </Switch>
            </BrowserRouter>
        </div>
    );
}

export default AppRoute;
