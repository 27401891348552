import {
    Avatar,
    FormHelperText,
    Typography,
    Box,
    LinearProgress,
    Grid,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import './styles.scss';
import { UploadIcon } from '../../../assets/svg';
import {
    AWS_BUCKET_REGION,
    AWS_BUCKET_POOL,
    AWS_BUCKET_NAME,
    AWS_BUCKET_ACL,
} from '../../../config/env/env';
import AWS from 'aws-sdk';
import { Spinner } from '../';
import { getFileExtension } from '../../../utils/helper';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { LightBoxImage } from '../index';
import { isVideoURL } from '../../../utils/helper';
const MAX_FILE_SIZE = 25600; // 5MB

// const IMAGE_FILES = ['.jpg', '.png', '.jpeg', '.bmp'];
const NON_IMAGE_FILES = ['.xlsx', '.csv', '.pdf', '.html'];
function LinearProgressWithLabel(props) {
    return (
        <Box display="flex" alignItems="center">
            <Box width="100%" mr={1}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box minWidth={35}>
                <Typography
                    variant="body2"
                    color="textSecondary"
                >{`${Math.round(props.value)}%`}</Typography>
            </Box>
        </Box>
    );
}
export const uploadImageOnS3 = async (file, onSuccess, onProgress) => {
    await AWS.config.update({
        region: AWS_BUCKET_REGION,
        credentials: new AWS.CognitoIdentityCredentials({
            IdentityPoolId: AWS_BUCKET_POOL,
        }),
    });

    // store.dispatch(setLoading());
    // store.dispatch(toggleFileModal({ isOpen: false }));

    const s3bucket = new AWS.S3({
        params: {
            Bucket: AWS_BUCKET_NAME,
        },
    });
    // console.log('file', file);

    let contentDeposition = 'inline;filename="' + file.name + '"';
    // const base64 = await fs.readFile(file.uri, 'base64');
    // const arrayBuffer = decode(base64);

    s3bucket.createBucket(() => {
        const params = {
            Key: file.name,
            Body: file,
            ContentDisposition: contentDeposition,
            ContentType: file.type,
            ACL: AWS_BUCKET_ACL,
            Bucket: AWS_BUCKET_NAME,
        };
        // console.log('params', params);
        s3bucket
            .upload(params, (err, data) => {
                if (err) {
                    console.log('res.err', err);
                }

                // toggleLoader();
                // fileUploadModalConfig.onFileUploaded &&
                //     fileUploadModalConfig.onFileUploaded(data?.Location);
                !!onSuccess && onSuccess(data?.Location);
            })
            .on('httpUploadProgress', (evt) => {
                console.log(
                    'Uploading ' +
                        parseInt((evt.loaded * 100) / evt.total) +
                        '%',
                );
                !!onProgress &&
                    onProgress(parseInt((evt.loaded * 100) / evt.total));
            });
    });
};

export function MultiFileUploader({
    render,
    label,
    labelStyles,
    placeholder,
    onFileUploaded,
    defaultValue,
    helperText,
    disabled,
    error,
    onRemoved,
    lightboxConfig = {},
    isRequired,
    ...props
}) {
    // const classes = useStyles();
    const [uploadedFiles, setUploadedFiles] = useState(
        !!defaultValue ? defaultValue : [],
    );
    const [loading, toggleLoading] = useState(false);
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        setUploadedFiles(!!defaultValue ? defaultValue : []);
    }, [defaultValue]);
    const uploadfile = async (e) => {
        if (e.target.files.length > 0) {
            const selectedFile = e.target.files[0];
            console.log(selectedFile.name);
            const fileSizeKiloBytes = selectedFile.size / 1024;
            console.log(fileSizeKiloBytes);
            if (fileSizeKiloBytes > MAX_FILE_SIZE) {
                alert(
                    `Maximum allowed size for a file is 25Mb.Upload less than 25Mb`,
                );
                return;
            }
            await toggleLoading(true);
            uploadImageOnS3(
                e.target.files[0],
                (file) => {
                    toggleLoading(false);
                    //setUploadedFiles((prev) => [...prev, ...[file]]);
                    onFileUploaded([...uploadedFiles, ...[file]]);
                    setProgress(100);
                },
                (value) => {
                    setProgress(value);
                },
            );
        } else {
        }
    };

    const removeImage = (indexToRemove) => {
        const newArray = [...uploadedFiles];
        if (indexToRemove >= 0 && indexToRemove < newArray?.length) {
            newArray?.splice(indexToRemove, 1);
        }
        setUploadedFiles(newArray);
        setProgress(null);
        onRemoved && onRemoved(newArray);
    };

    function getFileNameFromS3(file) {
        return file?.split('/')[file?.split('/')?.length - 1] ?? '';
    }

    return (
        <>
            {!!label && (
                <Typography
                    variant="body1"
                    style={
                        labelStyles
                            ? {
                                  fontSize: '14px',
                                  fontWeight: 'normal',
                                  marginTop: 10,
                                  marginBottom: 0,
                                  padding: 0,
                                  color: Boolean(error) ? '#F44336' : '#14112D',
                                  ...labelStyles,
                              }
                            : {
                                  fontSize: '14px',
                                  fontWeight: 'normal',
                                  marginTop: 10,
                                  marginBottom: 0,
                                  padding: 0,
                                  color: Boolean(error) ? '#F44336' : '#515f6d',
                              }
                    }
                >
                    {label}
                    {isRequired && <span style={{ color: 'red' }}>*</span>}
                </Typography>
            )}
            <Spinner loading={loading}>
                <div className="file-upload">
                    {!!uploadedFiles?.length ? (
                        <Grid container spacing={1}>
                            {uploadedFiles?.map((imageUrl, i) => {
                                return (
                                    <Grid item sm={6} xs={6}>
                                        <div
                                            className={`image-upload-wrap ${
                                                Boolean(error)
                                                    ? 'img-border-error'
                                                    : ''
                                            }`}
                                        >
                                            {!disabled && (
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        backgroundColor: 'red',
                                                        borderRadius: '50%',
                                                        position: 'absolute',
                                                        top: -10,
                                                        right: -10,
                                                        zIndex: 99,
                                                        color: '#fff',
                                                        width: 25,
                                                        height: 25,
                                                        alignItems: 'center',
                                                        justifyContent:
                                                            'center',
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={(e) =>
                                                        removeImage(i)
                                                    }
                                                >
                                                    {'X'}
                                                </div>
                                            )}
                                            {/* <div className="drag-text"> */}
                                            {!!imageUrl &&
                                            !NON_IMAGE_FILES.find((file) =>
                                                imageUrl
                                                    ?.toLowerCase()
                                                    .includes(file),
                                            ) ? (
                                                <div className="drag-text image-drag">
                                                    <LightBoxImage
                                                        {...lightboxConfig}
                                                        source={imageUrl}
                                                        type={
                                                            isVideoURL(imageUrl)
                                                                ? 'video'
                                                                : 'image'
                                                        }
                                                    />
                                                </div>
                                            ) : (
                                                <div className="drag-text">
                                                    <AttachFileIcon />
                                                    {
                                                        <Box
                                                            style={{
                                                                display: 'flex',
                                                                flexDirection:
                                                                    'row',
                                                                justifyContent:
                                                                    'center',
                                                                alignItems:
                                                                    'flex-end',
                                                            }}
                                                        >
                                                            {/* <AttachFileIcon /> */}
                                                            <Typography
                                                                // variant="body1"
                                                                style={{
                                                                    color: 'red',
                                                                    marginLeft:
                                                                        '2px',
                                                                    width: '100%',
                                                                    wordWrap:
                                                                        'break-word',
                                                                }}
                                                            >
                                                                {/* {`${uploadedFiles}`} */}
                                                                {`${getFileNameFromS3()}`}
                                                            </Typography>
                                                        </Box>
                                                    }
                                                </div>
                                            )}
                                            {/* </div> */}
                                        </div>
                                    </Grid>
                                );
                            })}
                            {!disabled && (
                                <Grid item sm={12}>
                                    <div
                                        className={`image-upload-wrap ${
                                            Boolean(error)
                                                ? 'img-border-error'
                                                : ''
                                        }`}
                                    >
                                        <input
                                            className="file-upload-input"
                                            type="file"
                                            onChange={uploadfile}
                                        />
                                        <div className="drag-text">
                                            <UploadIcon />
                                            {progress > 0 && progress < 100 && (
                                                <LinearProgressWithLabel
                                                    value={progress}
                                                />
                                            )}
                                            <Typography variant="body1">
                                                {'Upload'}
                                            </Typography>
                                        </div>
                                    </div>
                                </Grid>
                            )}
                        </Grid>
                    ) : !render ? (
                        <div
                            className={`image-upload-wrap ${
                                Boolean(error) ? 'img-border-error' : ''
                            }`}
                        >
                            <input
                                className="file-upload-input"
                                type="file"
                                onChange={uploadfile}
                            />
                            <div className="drag-text">
                                <UploadIcon />
                                {progress > 0 && progress < 100 && (
                                    <LinearProgressWithLabel value={progress} />
                                )}
                                {!!placeholder && (
                                    <Typography variant="body1">
                                        {placeholder}
                                    </Typography>
                                )}
                            </div>
                        </div>
                    ) : (
                        <div
                            className={` ${
                                Boolean(error) ? 'img-border-error' : ''
                            }`}
                        >
                            <input
                                className="file-upload-input"
                                type="file"
                                onChange={uploadfile}
                            />
                            {render()}
                        </div>
                    )}
                </div>
                {Boolean(error) && (
                    <FormHelperText className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error">
                        {helperText}
                    </FormHelperText>
                )}
            </Spinner>
        </>
    );
}
