import { Typography } from '@material-ui/core';
import React from 'react';
import { formatCurrency } from '../../../utils/helper';

function Currency({ isNegative = false, ...props }) {
    return (
        <Typography
            style={{
                textAlign: 'right',
                fontWeight: 'inherit',
                fontSize: 'inherit',
                color: 'inherit',
            }}
        >
            {isNegative && '-'}
            {formatCurrency(props.amount)}
        </Typography>
    );
}

export default Currency;
