import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import Modal from './Modal';
import { FileUploader } from '../UI';
import { connect } from 'react-redux';
import { showMessage } from '../../utils/helper';
import { useDebounce } from '../../hooks/useDebounce';
import { bulkUploadEvVehicles } from '../../store/actions/settingsActionCreator';
function UploadEVVehicleModal({
    open,
    onClose,
    dispatchBulkUploadEvVehicles,
    ...props
}) {
    const { debounce, isBouncing } = useDebounce();

    // const classes = useStyles();
    const [isOpen, toggleModal] = useState(open);
    const [loading, toggleLoading] = useState(false);
    const handleClose = () => {
        // Setting form data to null before closing
        setState(null);

        toggleModal(!isOpen);
        onClose && onClose();
    };
    const [state, setState] = useState({});

    const actions = [
        <Button
            key={1}
            variant="contained"
            color="default"
            className={`cancelButton`}
            onClick={handleClose}
            disableElevation
        >
            {'Cancel'}
        </Button>,
        <Button
            key={2}
            variant="contained"
            color="primary"
            className={`saveButton`}
            disabled={isBouncing || !state?.s3FileUrl}
            disableElevation
            onClick={() => {
                debounce(() => {
                    if (!state?.s3FileUrl) {
                        showMessage('Please upload file', 'error');
                        return false;
                    }
                    toggleLoading(true);
                    dispatchBulkUploadEvVehicles(
                        { fileName: state?.s3FileUrl },
                        (response) => {
                            toggleLoading(false);
                            console.log('s3FileUrl response', response);
                            if (response?.response?.s3FileUrl) {
                                window.location.href =
                                    response?.response?.s3FileUrl;
                            }
                            handleClose();
                        },
                        (resp) => {
                            toggleLoading(false);
                        },
                    );
                });
            }}
        >
            {'Upload EV Vehicles'}
        </Button>,
    ];

    const handleInputChange = (value, key) => {
        setState((state) => ({
            ...state,
            [key]: value,
        }));
    };

    return (
        <Modal
            onClose={handleClose}
            open={isOpen}
            title={'Upload EV Vehicles'}
            subtitle={'Bulk Upload EV Vehicles'}
            actions={actions}
            loading={loading}
        >
            <FileUploader
                label={'Upload Support Document'}
                placeholder={'Upload Support Document'}
                onFileUploaded={(url) => {
                    console.log(url);
                    handleInputChange(url, 's3FileUrl');
                }}
                isRequired={true}
            />
        </Modal>
    );
}

const mapDispatchToProps = (dispatch) => ({
    dispatchBulkUploadEvVehicles: (data, onSuccess, onError) =>
        dispatch(bulkUploadEvVehicles(data, onSuccess, onError)),
});

export default connect(null, mapDispatchToProps)(UploadEVVehicleModal);
