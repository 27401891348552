import React from 'react';
import { Box, Typography, Tooltip } from '@material-ui/core';
import {
    BatteryIcon,
    LocationMobileOffIcon,
    LocationMobileOnIcon,
    LocationPermissionOffIcon,
    LocationPermissionOnIcon,
    MobileIcon,
} from '../../../../assets/svg';
interface DeviceInfoProps {
    device: any;
    tracking?: any;
}

const DeviceInfo: React.FC<DeviceInfoProps> = ({ device, tracking }) => {
    return (
        <Box
            style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
            }}
        >
            {!!device?.deviceBrand && (
                <Tooltip title="Device Info" placement="top">
                    <Box
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        <MobileIcon />
                        <Typography
                            style={{
                                fontFamily: 'Nunito',
                                fontSize: 12,
                                fontWeight: 600,
                            }}
                        >
                            {!!device?.deviceBrand
                                ? `${device?.deviceBrand.toUpperCase()} ${device?.deviceModel.toUpperCase()} (v${
                                      device?.appVersion
                                  })`
                                : ' '}
                        </Typography>
                    </Box>
                </Tooltip>
            )}
            {tracking?.telematicData?.powerVoltage && (
                <Tooltip title="Battery Info" placement="top">
                    <Box
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        <BatteryIcon
                            color={
                                tracking?.telematicData?.powerVoltage < 20
                                    ? 'red'
                                    : tracking?.telematicData?.powerVoltage > 60
                                    ? 'green'
                                    : 'orange'
                            }
                        />
                        <Typography
                            style={{
                                fontFamily: 'Nunito',
                                fontSize: 12,
                                fontWeight: 600,
                                marginLeft: 5,
                            }}
                        >
                            {tracking?.telematicData?.powerVoltage}%
                        </Typography>
                    </Box>
                </Tooltip>
            )}
            <>
                <Box
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <Tooltip title="Location Permission" placement="top">
                        {!!device?.locationPermission ? (
                            <LocationPermissionOnIcon />
                        ) : (
                            <LocationPermissionOffIcon />
                        )}
                    </Tooltip>
                </Box>
                <Box
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <Tooltip title="Location Service" placement="top">
                        {!!device?.locationService ? (
                            <LocationMobileOnIcon />
                        ) : (
                            <LocationMobileOffIcon />
                        )}
                    </Tooltip>
                </Box>
            </>
        </Box>
    );
};

export default DeviceInfo;
