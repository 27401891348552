import {
    Box,
    Button,
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    Grid,
    IconButton,
    makeStyles,
    Paper,
    Switch,
    Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import AddressModal from '../../components/Modals/AddressModal';
import { CustomTextField } from '../../components/Textfield';
import { FileUploader, Spinner } from '../../components/UI';
import { KYCDocuments } from '../../utils/constants';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { connect } from 'react-redux';
import {
    GetOrganisations,
    SetOrganisations,
    StoreOrganisation,
} from '../../store/actions/settingsActionCreator';
import { getLoginUser, getLoginOrg } from '../../utils/helper';

const validationSchema = yup.object({
    name: yup
        .string('company name is invalid')
        .required('company name is required'),
    address: yup.string('address is invalid').required('address is required'),
    operatingCityId: yup.string('city is invalid').required('city is required'),
    contactNumber: yup
        .number('contact number is invalid')
        .required('contact number is required'),
    staffContractSign: yup
        .string('contract sign is invalid')
        .required('contract sign is required'),
    profilePic: yup
        .string('profile image is invalid')
        .required('profile image is required'),
    settings: yup.array('kyc type is invalid').required('kyc type is required'),
});

const useStyles = makeStyles((theme) => ({
    listItemHeader: {
        border: '0px',
        marginBottom: '8px',
        '& .MuiTypography-body1': {
            color: '#525050',
            fontSize: 16,
            fontWeight: 'bold',
        },
    },
    listItem: {
        padding: '8px 16px 8px 16px !important',
        marginBottom: '8px !important',
        border: '1px solid #ebedf8 !important',
        borderRadius: '8px !important',
        // height: '56px !important',÷
        '& .MuiTypography-body1': {
            fontSize: 16,
            fontWeight: 400,
        },
        '&:nth-child(odd)': {
            backgroundColor: '#ebedf8',
        },
    },
    settings: {
        '& button.tab-button': {
            justifyContent: 'flex-start',
            color: theme.palette.text.secondary,
            fontSize: '16px',
            fontWeight: 400,
        },
        '& button.active': {
            color: theme.palette.text.primary,
            fontWeight: 'bold',
            fontSize: '16px',
        },
        '& .inputbutton': {
            color: theme.palette.text.primary,
            fontWeight: 'bold',
            fontSize: '16px',
            padding: 5,
            border: `1px solid ${theme.palette.text.primary}`,
            borderRadius: 8,
        },
        '& .MuiGrid-container': {
            marginTop: 20,
        },
        '& .MuiGrid-spacing-xs-3 > .MuiGrid-item': {
            padding: `5px ${theme.spacing(2)}px !important`,
        },
        '& .switch-label': {
            '& .MuiTypography-body1': {
                fontSize: 16,
                fontWeight: 600,
            },
        },
    },
}));

const BasicDetailsComponent = ({
    dispatchGetOrganisations,
    dispatchSetOrganisations,
    dispatchStoreOrganisation,
    user,
    settings,
    cityList,
    statesList,
    permissions,
    ...props
}) => {
    const classes = useStyles();
    const [isOpen, toggleModal] = useState(false);
    const [loading, toggleLoading] = useState(false);
    const [addressObj, setAddressObj] = useState({});
    const [orgInfo, setOrgInfo] = useState({});

    const [checkBoxState, setCheckBoxState] = useState({
        AADHAR: false,
        AADHAR_UIDAI: false,
        PANCARD: false,
        RC: false,
    });
    const [staffContractState, setStaffContractState] = useState('');

    useEffect(() => {
        console.log('Configs changed', user?.config);
        fetchOrganisationInfo();
    }, [user?.config]);

    useEffect(() => {
        formik.setValues(settings?.basicDetails, true);
        setCheckBoxState({
            AADHAR: settings?.basicDetails?.settingsMap?.AADHAR === 'true',
            AADHAR_UIDAI:
                settings?.basicDetails?.settingsMap?.AADHAR_UIDAI === 'true',
            PANCARD: settings?.basicDetails?.settingsMap?.PANCARD === 'true',
            RC: settings?.basicDetails?.settingsMap?.RC === 'true',
        });
        setStaffContractState(
            settings?.basicDetails?.settingsMap?.staffContract,
        );
    }, [settings]);

    const formik = useFormik({
        initialValues: {
            name: '',
            userName: '',
            address: '',
            operatingCityId: '',
            contactNumber: '',
            staffContractSign: '',
            profilePic: '',
            settings: [],
            ...orgInfo,
        },
        // validationSchema: validationSchema,
        onSubmit: async (values) => {
            // await toggleLoading(true);
            // dispatchSetOrganisations(
            //     { ...orgInfo, ...values },
            //     (resp) => {
            //         console.log(resp);
            //         toggleLoading(false);
            //     },
            //     (err) => {
            //         console.log(err);
            //         toggleLoading(false);
            //     },
            // );
            // console.log(values);
        },
    });
    const fetchOrganisationInfo = async () => {
        console.log('getLoginUser', getLoginUser());
        await toggleLoading(true);
        dispatchGetOrganisations(
            settings?.basicDetails?.id
                ? settings?.basicDetails?.id
                : getLoginUser().organizationId,
            (resp) => {
                console.log(resp);
                setOrgInfo(resp);
                setAddressObj({
                    stateId: resp.stateId,
                    zoneId: resp.operatingCityId,
                    addressLine1: resp.addressLine1,
                    addressLine2: resp.addressLine2,
                });
                const stateName = resp.stateName;
                const cityName = resp.cityName;
                const address = `${cityName ?? ''}, ${stateName ?? ''}`;
                formik.setValues(resp, true);
                formik.setFieldValue('address', address?.trim() ?? null, true);
                toggleLoading(false);
            },
            (resp) => {
                console.log(resp);
                toggleLoading(false);
            },
        );
    };

    const handleClose = () => {
        // Setting form data to null before closing

        toggleModal(false);
    };

    // console.log('form value', formik.values);

    useEffect(() => {
        console.log('formik values changed', formik.values);
    }, [formik.values]);

    return (
        <Paper style={{ padding: '1.5rem' }} elevation={0}>
            <Spinner loading={loading}>
                <form onSubmit={formik.handleSubmit}>
                    <Box
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        <Typography
                            style={{
                                fontFamily: 'Nunito',
                                fontSize: '24px',
                                fontWeight: 'bold',
                                color: '#14112d',
                            }}
                            variant="h6"
                            component="span"
                            gutterBottom
                        >
                            Basic Details
                        </Typography>
                        <Button
                            style={{
                                marginRight: '1rem',
                                fontSize: 14,
                                width: '10%',
                            }}
                            disableElevation
                            variant="contained"
                            color="primary"
                            type="submit"
                            // disabled={!permissions.update}
                            disabled={true}
                            onClick={async () => {
                                await toggleLoading(true);
                                dispatchSetOrganisations(
                                    {
                                        id: settings?.basicDetails?.id
                                            ? settings?.basicDetails?.id
                                            : getLoginUser().organizationId,
                                        name: formik.values.name,
                                        userName: formik.values.userName,
                                        contactNumber:
                                            formik.values.contactNumber,
                                        addressLine1: addressObj.addressLine1,
                                        addressLine2: addressObj.addressLine2,
                                        operatingCityId: addressObj.zoneId,
                                        stateId: addressObj.stateId,
                                        settings: [
                                            ...KYCDocuments.map((doc) => ({
                                                settingsKey: doc.value,
                                                settingsValue:
                                                    !!checkBoxState?.[
                                                        doc.value
                                                    ],
                                            })),
                                            {
                                                settingsKey: 'staffContract',
                                                settingsValue:
                                                    staffContractState,
                                            },
                                        ],
                                    },
                                    async (resp) => {
                                        console.log(resp);
                                        toggleLoading(false);
                                        fetchOrganisationInfo();
                                    },
                                    (err) => {
                                        console.log(err);
                                        toggleLoading(false);
                                    },
                                );
                            }}
                        >
                            {'Save'}
                        </Button>
                    </Box>
                    <Divider style={{ margin: '16px 0', marginBottom: 16 }} />
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <CustomTextField
                                fullWidth={true}
                                label="Name"
                                variant="outlined"
                                style={{ marginBottom: '16px' }}
                                id="userName"
                                name="userName"
                                value={formik.values.userName}
                                onChange={formik.handleChange}
                                error={
                                    formik.touched.userName
                                        ? Boolean(formik.errors.userName)
                                        : false
                                }
                                helperText={
                                    formik.touched.userName &&
                                    formik.errors.userName
                                }
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <CustomTextField
                                fullWidth={true}
                                label="Phone Number"
                                variant="outlined"
                                style={{ marginBottom: '16px' }}
                                id="contactNumber"
                                name="contactNumber"
                                value={formik.values.contactNumber}
                                onChange={formik.handleChange}
                                error={
                                    formik.touched.contactNumber
                                        ? Boolean(formik.errors.contactNumber)
                                        : false
                                }
                                helperText={
                                    formik.touched.contactNumber &&
                                    formik.errors.contactNumber
                                }
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <CustomTextField
                                fullWidth={true}
                                label="Company Name"
                                variant="outlined"
                                style={{ marginBottom: '16px' }}
                                id="name"
                                name="name"
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                error={
                                    formik.touched.name
                                        ? Boolean(formik.errors.name)
                                        : false
                                }
                                helperText={
                                    formik.touched.name && formik.errors.name
                                }
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <CustomTextField
                                fullWidth={true}
                                label="Company Address"
                                variant="outlined"
                                InputProps={{
                                    readOnly: true,
                                    endAdornment: (
                                        <IconButton
                                            className={'inputbutton'}
                                            onClick={(e) => toggleModal(true)}
                                            disabled={
                                                !!formik.values.address
                                                    ? !permissions.update
                                                    : !permissions.create
                                            }
                                        >
                                            {formik.values.address ? (
                                                <EditIcon size="small" />
                                            ) : (
                                                <AddIcon size="small" />
                                            )}
                                        </IconButton>
                                    ),
                                }}
                                style={{ marginBottom: '16px' }}
                                id="address"
                                name="address"
                                value={formik.values.address}
                                onChange={formik.handleChange}
                                error={
                                    formik.touched.address
                                        ? Boolean(formik.errors.address)
                                        : false
                                }
                                helperText={
                                    formik.touched.address &&
                                    formik.errors.address
                                }
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography
                                style={{
                                    fontWeight: 700,
                                    color: '#43419e',
                                    fontSize: '18px',
                                }}
                            >
                                Required Staff KYC Documents
                            </Typography>
                            {/* <FormControlLabel
                                control={<Switch color="primary" />}
                                label="Enable KYC for Staff App"
                                className="switch-label"
                                checked={enableKyc}
                                onChange={(event) => {
                                    setEnableKyc(event.target.checked);
                                }}
                            /> */}
                        </Grid>
                        <Grid item xs={12}>
                            <Box
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    marginBottom: '6px',
                                }}
                            >
                                {KYCDocuments.map((doc, index) => (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                color="primary"
                                                checked={
                                                    checkBoxState?.[doc.value]
                                                }
                                                disabled={true}
                                                onChange={(e) => {
                                                    console.log(
                                                        'aadhaar',
                                                        e.target.checked,
                                                    );
                                                    setCheckBoxState((prev) => {
                                                        return {
                                                            ...prev,
                                                            [doc.value]:
                                                                e.target
                                                                    .checked,
                                                        };
                                                    });
                                                }}
                                            />
                                        }
                                        label={doc.label}
                                    />
                                ))}

                                {/* <Box
                                    style={{
                                        marginRight: '1rem',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Checkbox
                                        color="primary"
                                        checked={checkBoxState?.aadhaar}
                                        onChange={(e) => {
                                            console.log(
                                                'aadhaar',
                                                e.target.checked,
                                            );
                                            setCheckBoxState((prev) => {
                                                return {
                                                    ...prev,
                                                    aadhaar: e.target.checked,
                                                };
                                            });
                                        }}
                                    />
                                    <Typography>Aadhaar</Typography>
                                </Box>
                                <Box
                                    style={{
                                        marginRight: '1rem',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Checkbox
                                        color="primary"
                                        checked={checkBoxState?.pan}
                                        onChange={(e) => {
                                            console.log(
                                                'pan',
                                                e.target.checked,
                                            );
                                            setCheckBoxState((prev) => {
                                                return {
                                                    ...prev,
                                                    pan: e.target.checked,
                                                };
                                            });
                                        }}
                                    />
                                    <Typography>Pan Card</Typography>
                                </Box>

                                <Box
                                    style={{
                                        marginRight: '1rem',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Checkbox
                                        color="primary"
                                        checked={checkBoxState?.voterId}
                                        onChange={(e) => {
                                            console.log(
                                                'voterId',
                                                e.target.checked,
                                            );
                                            setCheckBoxState((prev) => {
                                                return {
                                                    ...prev,
                                                    voterId: e.target.checked,
                                                };
                                            });
                                        }}
                                    />
                                    <Typography>Voter ID</Typography>
                                </Box>

                                <Box
                                    style={{
                                        marginRight: '1rem',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Checkbox
                                        color="primary"
                                        checked={checkBoxState?.license}
                                        onChange={(e) => {
                                            console.log(
                                                'license',
                                                e.target.checked,
                                            );
                                            setCheckBoxState((prev) => {
                                                return {
                                                    ...prev,
                                                    license: e.target.checked,
                                                };
                                            });
                                        }}
                                    />
                                    <Typography>Driver License</Typography>
                                </Box>

                                <Box
                                    style={{
                                        marginRight: '1rem',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Checkbox
                                        color="primary"
                                        checked={checkBoxState?.rc}
                                        onChange={(e) => {
                                            console.log('rc', e.target.checked);
                                            setCheckBoxState((prev) => {
                                                return {
                                                    ...prev,
                                                    rc: e.target.checked,
                                                };
                                            });
                                        }}
                                    />
                                    <Typography>Vehicle RC</Typography>
                                </Box> */}
                            </Box>

                            {/* <FormControl
                                error={
                                    formik.touched.settings
                                        ? Boolean(formik.errors.settings)
                                        : false
                                }
                            > */}

                            {/* <FormGroup row>
                                    {KycInfoType.map((type) => (
                                        <FormControlLabel
                                            key={type.value}
                                            control={
                                                <Checkbox
                                                    color="primary"
                                                    value={type.value}
                                                    checked={
                                                        !!formik.values.settings.find(
                                                            (item) =>
                                                                item.settingsKey ===
                                                                type.value,
                                                        )
                                                    }
                                                    onChange={(e) => {
                                                        // if (e.target.checked) {
                                                            console.log(
                                                                'CHECK',
                                                                type.value,
                                                                e.target
                                                                    .checked,
                                                            );

                                                            formik.setFieldValue(
                                                                'settings',
                                                                [
                                                                    ...formik
                                                                        .values
                                                                        .settings,
                                                                    {
                                                                        settingsKey:
                                                                            type.value,
                                                                        settingsValue:
                                                                            e
                                                                                .target
                                                                                .checked,
                                                                    },
                                                                ],
                                                                // true,
                                                            );
                                                        // } else {
                                                        //     console.log(
                                                        //         'UNCHECK',
                                                        //         type.value,
                                                        //         e.target
                                                        //             .checked,
                                                        //     );
                                                        //     formik.setFieldValue(
                                                        //         'settings',
                                                        //         formik.values.settings.filter(
                                                        //             (item) =>
                                                        //                 item.settingsKey ===
                                                        //                 type.value,
                                                        //         ),
                                                        //         false,
                                                        //     );
                                                        // }
                                                    }}
                                                />
                                            }
                                            label={type.label}
                                        />
                                    ))}
                                </FormGroup> */}
                            {/* <FormHelperText>
                                    {formik.touched.settings &&
                                        formik.errors.settings}
                                </FormHelperText>
                            </FormControl> */}
                        </Grid>
                        <Grid item xs={12}>
                            <Typography
                                style={{
                                    fontWeight: 700,
                                    color: '#43419e',
                                    fontSize: '18px',
                                    marginBottom: '5px',
                                }}
                            >
                                Staff Onboarding Contract
                            </Typography>
                            {/* <FormControlLabel
                                control={<Switch color="primary" />}
                                label="Enable Staff Contract Signing"
                                className="switch-label"
                                checked={enableContract}
                                onChange={(event) => {
                                    setEnableContract(event.target.checked);
                                }}
                            /> */}
                        </Grid>
                        <Grid item xs={6}>
                            <FileUploader
                                label={''}
                                placeholder={'Upload Staff Contract'}
                                onFileUploaded={(url) => {
                                    console.log(url);
                                    setStaffContractState(url);
                                    // formik.setFieldValue(
                                    //     'staffContractSign',
                                    //     url,
                                    //     true,
                                    // );
                                }}
                                onRemoved={() => {
                                    setStaffContractState('');
                                }}
                                defaultValue={
                                    !!staffContractState
                                        ? staffContractState
                                        : ''
                                }
                                // error={
                                //     formik.touched.staffContractSign
                                //         ? Boolean(
                                //               formik.errors.staffContractSign,
                                //           )
                                //         : false
                                // }
                                // helperText={
                                //     formik.touched.staffContractSign &&
                                //     formik.errors.staffContractSign
                                // }
                            />
                        </Grid>
                        {/* <Grid item xs={12}>
                            <FormControlLabel
                                control={<Switch color="primary" />}
                                label="Upload Profile Pic"
                                className="switch-label"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FileUploader
                                label={''}
                                placeholder={'Upload Profile Picture'}
                                onFileUploaded={(url) => {
                                    console.log(url);
                                    formik.setFieldValue(
                                        'profilePic',
                                        url,
                                        true,
                                    );
                                }}
                                defaultValue={formik.values.profilePic}
                                error={
                                    formik.touched.profilePic
                                        ? Boolean(formik.errors.profilePic)
                                        : false
                                }
                                helperText={
                                    formik.touched.profilePic &&
                                    formik.errors.profilePic
                                }
                            />
                        </Grid> */}
                    </Grid>
                </form>
                {!!isOpen && (
                    <AddressModal
                        open={true}
                        defaultValue={addressObj}
                        onClose={(values) => {
                            handleClose();
                            setAddressObj(values);
                            const stateName = statesList.find(
                                (i) => i?.id === values?.stateId,
                            )?.name;
                            const cityName = cityList.find(
                                (i) => i?.id === values?.zoneId,
                            )?.name;
                            const address = `${cityName ?? ''}, ${
                                stateName ?? ''
                            }`;

                            formik.setFieldValue(
                                'address',
                                address?.trim() ?? null,
                                true,
                            );

                            formik.setFieldValue(
                                'addressLine1',
                                values.addressLine1 ?? null,
                                true,
                            );

                            formik.setFieldValue(
                                'addressLine2',
                                values.addressLine2 ?? null,
                                true,
                            );
                            formik.setFieldValue(
                                'operatingCityId',
                                values.zoneId,
                                true,
                            );
                        }}
                    />
                )}
            </Spinner>
        </Paper>
    );
};

const mapStateToProps = (state) => ({
    user: state.user,
    settings: state.settings,
    statesList: state.settings?.statesList ?? [],
    cityList: state.settings?.cities ?? [],
});

const mapDispatchToProps = (dispatch) => ({
    dispatchGetOrganisations: (id, onSuccess, onError) =>
        dispatch(GetOrganisations(id, onSuccess, onError)),
    dispatchSetOrganisations: (payload, onSuccess, onError) =>
        dispatch(SetOrganisations(payload, onSuccess, onError)),
    dispatchStoreOrganisation: (data) => dispatch(StoreOrganisation(data)),
});

export const BasicDetails = connect(
    mapStateToProps,
    mapDispatchToProps,
)(BasicDetailsComponent);
