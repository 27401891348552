import './styles.scss';
import RouteWithTitle from '../../components/AppRoute/RouteWithTitle';
import { withRouter } from 'react-router-dom';
import { Notifier } from '../../components/UI';
import React, { Component } from 'react';

class BlankLayout extends Component {
    render() {
        const { path, component, name } = this.props;
        return (
            <div className="blank-layout">
                <div className="container">
                    {path && component && (
                        <RouteWithTitle
                            key={name}
                            exact={true}
                            path={path}
                            component={component}
                            name={name}
                        />
                    )}
                </div>
                <Notifier />
            </div>
        );
    }
}

export default withRouter(BlankLayout);
