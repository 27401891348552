import {
    Box,
    Button,
    Divider,
    ListItemIcon,
    Paper,
    Tooltip,
    Typography,
} from '@material-ui/core';
import React, { useState } from 'react';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import PersonIcon from '@material-ui/icons/Person';
// import { Spinner } from '../../components/UI';
import { BackIcon, ShiftIcon } from '../../assets/svg';
import { HubShiftsDetails } from './HubShiftsDetails';
import Staff from '../staff';
import { HubSwitchKey } from './Settings';
import AddShiftTimingModal from '../../components/Modals/AddShiftTimingModal';
import { connect } from 'react-redux';
import { fetchShiftfilterList } from '../../store/actions/settingsActionCreator';
import AssignStaffToHubModal from '../../components/Modals/AssignStaffToHubModal';
import { HUB_TYPE_ENUM } from '../../utils/constants';

function HubDetail({
    shiftList,
    selectedHub,
    permissions,
    setHubNShiftScreenSwitch,
    dispatchfetchShiftTimingList,
    ...props
}) {
    const [activeTab, changeActiveTab] = useState(0);
    const [staffModalOpen, setStaffModalOpen] = useState(false);
    const [shiftModalOpen, setShiftModalOpen] = useState(false);
    const [shiftListPayload, setshiftListPayload] = React.useState({
        pageNo: 1,
        pageSize: 10,
        searchText: '',
    });
    const [loading, toggleLoading] = useState(false);

    const fetchShiftList = async (filters = {}) => {
        await toggleLoading(true);
        const payload = {
            ...shiftListPayload,
            ...filters,
        };
        dispatchfetchShiftTimingList(
            payload,
            (resp) => {
                console.log(resp);
                toggleLoading(false);
            },
            (resp) => {
                console.log(resp);
                toggleLoading(false);
            },
        );
    };

    const _TAB = [
        {
            icon: (
                <ShiftIcon
                    style={{ width: 30, height: 30 }}
                    fill={activeTab === 0 ? '#fff' : '#65656d'}
                />
            ),
            label: 'Shift',
            content: (
                <HubShiftsDetails
                    renderHubFlow={true}
                    permissions={permissions}
                    hubInfo={selectedHub}
                    externalFiltered={{ hubIds: [selectedHub?.id] }}
                    loading={loading}
                />
            ),
            disabled: !permissions?.read,
        },
        {
            icon: (
                <PersonIcon
                    fontSize="large"
                    style={{ width: 25, height: 25 }}
                />
            ),
            label: 'Staff',
            content: (
                <Staff
                    renderHubFlow={true}
                    externalFiltered={{
                        hubIds: [
                            {
                                label: selectedHub?.name,
                                value: selectedHub?.id,
                            },
                        ],
                    }}
                />
            ),
            disabled: !permissions?.read,
        },
    ];
    return (
        <>
            <Paper
                style={{ padding: '14px 24px 16px 14px', marginBottom: 20 }}
                elevation={0}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <Box
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start',
                        }}
                    >
                        <Box
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <Tooltip title="Back" placement="top">
                                <ListItemIcon
                                    onClick={(e) => {
                                        setHubNShiftScreenSwitch(
                                            HubSwitchKey.HUB_VIEW,
                                        );
                                    }}
                                    style={{
                                        minWidth: '0',
                                        color: '#000000',
                                        cursor: 'pointer',
                                        marginRight: '8px',
                                    }}
                                >
                                    <BackIcon style={{}} />
                                </ListItemIcon>
                            </Tooltip>
                            <Typography
                                style={{
                                    fontFamily: 'Nunito',
                                    fontSize: '22px',
                                    fontWeight: 'bold',
                                    color: '#14112d',
                                    margin: 0,
                                }}
                                variant="h6"
                                component="span"
                                gutterBottom
                            >
                                {selectedHub?.name}
                            </Typography>
                        </Box>
                        <Box
                            style={{
                                display: 'flex',
                                alignItems: 'flex-start',
                                justifyContent: 'space-between',
                                marginLeft: '2.5rem',
                                marginTop: 0,
                            }}
                        >
                            <Typography
                                style={{
                                    fontFamily: 'Nunito',
                                    fontSize: '18px',
                                    color: '#14112d',
                                    fontWeight: 'bold',
                                }}
                                variant="body1"
                                gutterBottom
                            >
                                {(() => {
                                    let color = '#f44336';
                                    color =
                                        HUB_TYPE_ENUM[
                                            selectedHub?.primaryOperation?.toUpperCase()
                                        ]?.color ?? '#f44336';
                                    // switch (selectedHub?.primaryOperation) {
                                    //     case HUB_TYPE_ENUM.ECOMMERCE:
                                    //         color = '#43419e';
                                    //         break;
                                    //     case HUB_TYPE_ENUM.HYPERLOCAL:
                                    //         color = '#f44336';
                                    //         break;
                                    //     case HUB_TYPE_ENUM.STORE:
                                    //         color = '#579b5a';
                                    //         break;

                                    //     default:
                                    //         color = '#f44336';
                                    //         break;
                                    // }
                                    return (
                                        <span
                                            style={{ color, fontSize: '16px' }}
                                        >{`${
                                            HUB_TYPE_ENUM?.[
                                                selectedHub?.primaryOperation?.toUpperCase()
                                            ]?.label ?? '-'
                                        }`}</span>
                                    );
                                })()}
                                <span style={{ color: '#D0CFD5' }}> | </span>
                                <span style={{ fontWeight: 600 }}>
                                    {selectedHub?.cityName}
                                </span>
                                <span style={{ fontWeight: 600 }}>
                                    <span style={{ color: '#D0CFD5' }}>
                                        {' '}
                                        |{' '}
                                    </span>
                                    {` Shifts: ${selectedHub?.shiftCount ?? 0}`}
                                </span>
                                <span style={{ fontWeight: 600 }}>
                                    <span style={{ color: '#D0CFD5' }}>
                                        {' '}
                                        |{' '}
                                    </span>
                                    {` Staffs: ${selectedHub?.staffCount ?? 0}`}
                                </span>
                            </Typography>
                        </Box>
                    </Box>
                    <Box
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <Button
                            variant="contained"
                            style={{ fontSize: 14, marginRight: 16 }}
                            color="primary"
                            onClick={(e) => setStaffModalOpen(true)}
                            disabled={!permissions.update || !shiftList?.length}
                            startIcon={<PersonIcon fontSize="small" />}
                            disableElevation
                        >
                            {'Assign Staff'}
                        </Button>
                        {staffModalOpen && (
                            <AssignStaffToHubModal
                                open={staffModalOpen}
                                data={{
                                    ...selectedHub,
                                }}
                                onClose={() => {
                                    console.log('Closed');
                                    // getAllStaffInfo();
                                    setStaffModalOpen(false);
                                    // getAllStaffInfo(staffListingPayload);
                                }}
                            />
                        )}
                        <Button
                            variant="contained"
                            style={{ fontSize: 14 }}
                            color="primary"
                            onClick={(e) => setShiftModalOpen(true)}
                            disabled={!permissions.update}
                            startIcon={<ShiftIcon />}
                            disableElevation
                        >
                            {'Add Shift'}
                        </Button>
                        {!!shiftModalOpen && (
                            <AddShiftTimingModal
                                open={shiftModalOpen}
                                data={{ hubId: selectedHub?.id }}
                                isEdit={false}
                                onClose={() => {
                                    setShiftModalOpen(false);
                                    fetchShiftList({
                                        hubIds: [selectedHub?.id],
                                    });
                                }}
                            />
                        )}
                    </Box>
                </div>
            </Paper>
            <Paper elevation={0}>
                <Box style={{ padding: '18px 24px 0px 18px', width: '100%' }}>
                    {false && (
                        <>
                            <Box
                                style={{
                                    width: '50%',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                }}
                            >
                                {_TAB.map((tab, index) => (
                                    <Button
                                        key={index}
                                        variant={
                                            activeTab === index
                                                ? 'contained'
                                                : 'outlined'
                                        }
                                        color="primary"
                                        size="large"
                                        disableElevation
                                        disabled={tab.disabled}
                                        startIcon={tab.icon}
                                        style={{
                                            padding: '20px 50px',
                                            opacity: !!tab.disabled ? 0.5 : 1,
                                            borderColor:
                                                !tab.disabled && '#65656d',
                                            marginRight: 16,
                                            color:
                                                activeTab === index
                                                    ? '#fff'
                                                    : '#65656d',
                                        }}
                                        onClick={() => {
                                            changeActiveTab(index);
                                        }}
                                    >
                                        {tab.label}
                                    </Button>
                                ))}
                            </Box>
                            <Divider style={{ margin: '16px 0' }} />{' '}
                        </>
                    )}
                </Box>
                {!_TAB[activeTab]?.disabled && _TAB[activeTab].content}
            </Paper>
        </>
    );
}

const mapStateToProps = (state) => ({
    shiftList: state.settings?.shifts ?? [],
});

const mapDispatchToProps = (dispatch) => ({
    dispatchfetchShiftTimingList: (payload, onSuccess, onError) =>
        dispatch(fetchShiftfilterList(payload, onSuccess, onError)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HubDetail);
