import { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { boolean } from 'yup';
import { Forward } from '@material-ui/icons';
interface useRiderMapPlayBackControlReturnType {}

const useRiderMapPlayBackControl = ({ aggRoutes }: any) => {
    const [currentIndices, setCurrentIndices] = useState<number[]>(
        Array(aggRoutes?.length).fill(0),
    );
    const [enablePlayBackControl, setEnablePlayBackControl] =
        useState<boolean>(false);
    const [playing, setPlaying] = useState<boolean>(false);
    const [speed, setSpeed] = useState<number>(0.5);
    const [slideValue, setSliderValue] = useState<number[]>(
        Array(aggRoutes?.length).fill(0),
    );

    const intervalRef = useRef<NodeJS.Timeout | null>(null);
    const maxIndices = aggRoutes.map(
        (route: any) => route?.coordinates?.length - 1,
    );

    useEffect(() => {
        if (playing) {
            intervalRef.current = setInterval(() => {
                setCurrentIndices((prevIndexes) =>
                    prevIndexes.map((index, routeIdx) =>
                        index < aggRoutes?.[routeIdx]?.coordinates.length - 1
                            ? index + 1
                            : index,
                    ),
                );
            }, 1000 / (16 * speed));
        } else {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
                intervalRef.current = null;
            }
        }
        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        };
    }, [playing, speed, enablePlayBackControl]);

    const handlePlay = (value: boolean) => {
        setPlaying(true);
    };
    const handlePause = (value: boolean) => {
        setPlaying(false);
    };
    const handleStop = (value: boolean) => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
            intervalRef.current = null;
        }
        setCurrentIndices(Array(aggRoutes?.length).fill(0));
        setPlaying(false);
    };
    const handlePlayBack = (value: boolean) => {
        setEnablePlayBackControl(value);
        if (!!value) {
            setPlaying(true);
        } else {
            setPlaying(false);
        }
    };

    const handleSpeedChange = (value: any) => {
        setSpeed(Number(value));
    };

    const handleSliderChange = (index: number, value: any) => {
        const newIndices = [...currentIndices];
        newIndices[index] = Number(value);
        setCurrentIndices(newIndices);
    };
    useEffect(() => {
        handleReset();
        return () => {};
    }, []);
    const handleReset = () => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
            intervalRef.current = null;
        }
        setEnablePlayBackControl(false);
        setCurrentIndices(Array(aggRoutes?.length).fill(0));
        setPlaying(false);
        setSliderValue(Array(aggRoutes?.length).fill(0));
    };
    useEffect(() => {
        const allFinished = currentIndices.every(
            (index, i) => index >= maxIndices[i],
        );
        if (!!playing && !!enablePlayBackControl && allFinished) {
            handleReset();
        }
    }, [currentIndices, maxIndices]);
    const handleForward = () => {
        setCurrentIndices((prevIndexes) =>
            prevIndexes.map((index, routeIdx) =>
                index < aggRoutes?.[routeIdx]?.coordinates?.length - 1
                    ? index + 1
                    : index,
            ),
        );
    };

    const handleRewind = () => {
        setCurrentIndices((prevIndexes) =>
            prevIndexes.map((index) => (index > 0 ? index - 1 : index)),
        );
    };
    return {
        currentIndices,
        enablePlayBackControl,
        setCurrentIndices,
        handlePlay,
        handlePause,
        handleSpeedChange,
        handleSliderChange,
        playing,
        setPlaying,
        speed,
        setSpeed,
        slideValue,
        handleReset,
        setEnablePlayBackControl,
        handlePlayBack,
        handleStop,
        handleForward,
        handleRewind,
    };
};

export default useRiderMapPlayBackControl;
