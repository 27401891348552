import React, { useState } from 'react';
import {
    Box,
    Typography,
    Tooltip,
    Divider,
    Tabs,
    Tab,
    withStyles,
    AppBar,
    makeStyles,
    useTheme,
} from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import {
    Timeline,
    TimelineItem,
    TimelineSeparator,
    TimelineConnector,
    TimelineContent,
    TimelineDot,
    TimelineOppositeContent,
} from '@material-ui/lab';
import { eventStyleFlag, getTabsProps } from '../helper';
import moment from 'moment';
import LocationPointCard from './LocationPointCard';
import EventCard from './EventCard';
interface StartTimelineItemProps {
    locationPoint: any;
    selectedPoint: any;
    events: any;
    aggUserEvents: any;
    onClick: Function;
    startAddress: any;
    stoppageReportData: any;
}

const useStyles = makeStyles((theme) => ({
    separator: {
        backgroundColor: '#1bb55c',
        border: '1px solid #fff',
        width: 20,
        height: 20,
        margin: 0,
        cursor: 'pointer',
    },
    separatorSelected: {
        backgroundColor: '#1bb55c',
        border: '3px solid #000',
        width: 20,
        height: 20,
        margin: 0,
        cursor: 'pointer',
    },
}));
const StartTimelineItem: React.FC<StartTimelineItemProps> = ({
    locationPoint = {},
    events = {},
    aggUserEvents,
    selectedPoint,
    onClick,
    startAddress,
    stoppageReportData,
}) => {
    const classes = useStyles();
    const theme = useTheme();
    const selected =
        locationPoint?.deviceTimeEpoch ==
            selectedPoint?.selectedStartStoppagePoint ||
        locationPoint?.deviceTimeEpoch ==
            selectedPoint?.selectedEndStoppagePoint;
    return !!locationPoint ? (
        <TimelineItem>
            <TimelineOppositeContent
                style={{
                    flex: 0,
                    padding: '6px',
                }}
            ></TimelineOppositeContent>
            <TimelineSeparator>
                <TimelineDot
                    className={
                        selected ? classes.separatorSelected : classes.separator
                    }
                    {...{
                        onClick: () => {
                            onClick(locationPoint);
                        },
                    }}
                />
                <TimelineConnector
                    style={{
                        backgroundColor: '#1bb55c',
                    }}
                />
            </TimelineSeparator>
            <TimelineContent
                style={{
                    padding: 0,
                    margin: '8px',
                }}
            >
                {events?.events?.length > 0 &&
                    aggUserEvents?.length > 0 &&
                    events?.events
                        ?.filter((event: any) => {
                            return (
                                event?.time <= locationPoint?.deviceTimeEpoch
                            );
                        })
                        ?.map((event: any) => {
                            return (
                                <EventCard
                                    {...{
                                        event,
                                    }}
                                />
                            );
                        })}
                <LocationPointCard
                    timeDiff={null}
                    title={'Started'}
                    dates={moment(
                        parseInt(locationPoint?.deviceTimeEpoch),
                    ).format(
                        // 'hh:mm A',
                        'D MMM YYYY hh:mm A',
                    )}
                    address={
                        events?.startEventDescription?.length > 0
                            ? events?.startEventDescription
                            : startAddress ?? ''
                    }
                />
                {stoppageReportData?.length > 0 ? (
                    <>
                        {aggUserEvents
                            ?.filter((event?: any) => {
                                if (
                                    locationPoint?.deviceTimeEpoch >=
                                        parseInt(
                                            stoppageReportData?.[0]
                                                ?.startTime ??
                                                stoppageReportData?.[0]?.time,
                                        ) &&
                                    locationPoint?.deviceTimeEpoch <
                                        parseInt(
                                            stoppageReportData?.[0]?.endTime ??
                                                stoppageReportData?.[0]?.time,
                                        )
                                ) {
                                    return (
                                        event?.time >=
                                            locationPoint?.deviceTimeEpoch &&
                                        event?.time <=
                                            parseInt(
                                                stoppageReportData?.[0]
                                                    ?.endTime ??
                                                    stoppageReportData?.[0]
                                                        ?.time,
                                            )
                                    );
                                } else {
                                    return (
                                        event?.time >=
                                            locationPoint?.deviceTimeEpoch &&
                                        stoppageReportData?.[0] &&
                                        event?.time <
                                            parseInt(
                                                stoppageReportData?.[0]
                                                    ?.startTime ??
                                                    stoppageReportData?.[0]
                                                        ?.time,
                                            )
                                    );
                                }
                            })
                            ?.map((event: any) => {
                                return (
                                    <EventCard
                                        {...{
                                            event,
                                        }}
                                    />
                                );
                            })}
                    </>
                ) : (
                    <></>
                )}
            </TimelineContent>
        </TimelineItem>
    ) : (
        <></>
    );
};

export default StartTimelineItem;
