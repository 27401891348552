import React, { useRef } from 'react';
interface IEmbeddedIframe {
    url: string | undefined;
    origin?: string | undefined;
}
const EmbeddedIframe: React.FC<IEmbeddedIframe> = ({ url }) => {
    const iframeRef = useRef<HTMLIFrameElement>(null);
    const hideElementInIframe = () => {
        const iframe = iframeRef.current;
        if (iframe && iframe.contentWindow) {
            iframe.contentWindow.postMessage(
                { action: 'hideElement', selector: '[data-hide="true"]' },
                origin, // Replace with the actual origin of the iframe
            );
        }
    };
    return !!url ? (
        <div style={{ width: '100%', height: '100vh', overflow: 'hidden' }}>
            <iframe
                src={url}
                ref={iframeRef}
                title="Embedded Content"
                style={{
                    width: '100%',
                    height: '100%',
                    border: 'none',
                    //pointerEvents: 'none',
                }}
                allowFullScreen
            ></iframe>
            {/* <div
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'rgba(255, 255, 255, 0)', // Transparent background
                    pointerEvents: 'auto', // Allows this div to capture all pointer events
                    //cursor: 'not-allowed', // Optional: show "not allowed" cursor
                }}
            ></div> */}
        </div>
    ) : null;
};

export default EmbeddedIframe;
