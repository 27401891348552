import React, { useState, useEffect } from 'react';
import {
    Button,
    MenuItem,
    withStyles,
    makeStyles,
    Grid,
} from '@material-ui/core';
import { connect } from 'react-redux';
import {
    editStaffClient,
    fetchClientList,
} from '../../store/actions/staffActionCreator';

import Modal from './Modal';
import { CustomTextField } from '../Textfield';
import { checkHttpStatus } from '../../utils/helper';
import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';
import TagFacesIcon from '@material-ui/icons/TagFaces';
import PersonIcon from '@material-ui/icons/Person';
import DeleteIcon from '@material-ui/icons/Delete';
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        listStyle: 'none',
        padding: theme.spacing(0.5),
        margin: 0,
        marginTop: '12px',
        marginBottom: '24px',
    },
    chip: {
        margin: theme.spacing(0.5),
        '&$deleteIconOutlinedColorPrimary': {
            color: 'red',
        },
    },
}));

function AddClientModal({
    open,
    onClose,
    isEdit = false,
    data = {},
    dispatchEditStaffClient,
    dispatchFetchClientList,
    staff,
    ...props
}) {
    const classes = useStyles();
    const [isOpen, toggleModal] = useState(open);
    const [isloading, toggleloading] = useState(true);
    const [clientList, setClientList] = useState([]);
    const handleClose = () => {
        // Setting form data to null before closing
        setState({
            clients: [],
        });
        setStateStatus({
            clients: {},
        });
        toggleModal(!isOpen);
        onClose && onClose();
    };
    const [state, setState] = useState({
        clients: data?.customers ?? [],
        customerId: '',
        staffCustCode: '',
        id: data?.id,
    });
    const [stateStatus, setStateStatus] = useState({
        clients: {},
        customerId: {},
        staffCustCode: {},
    });
    const getClientName = (customerId) => {
        let clientDetails = clientList.find(
            (ele) => ele?.customerId == customerId,
        );
        return !!clientDetails ? `${clientDetails?.name}` : '';
    };

    useEffect(() => {
        dispatchFetchClientList(
            async (resp) => {
                await toggleloading(false);
                setClientList(resp?.response?.customers);
            },
            (err) => {
                toggleloading(false);
            },
        );
        setState({
            clients: data?.customers ?? [],
            customerId: '',
            staffCustCode: '',
            id: data?.id,
        });
    }, []);

    const actions = [
        <Button
            key={1}
            variant="contained"
            color="default"
            className={`cancelButton`}
            onClick={handleClose}
            disableElevation
        >
            {'Cancel'}
        </Button>,
        <Button
            key={2}
            variant="contained"
            color="primary"
            className={`saveButton`}
            disabled={
                state?.clients?.length === 0 ||
                state?.clients?.filter((item) => !!item && !!item?.customerId)
                    ?.length === 0
            }
            onClick={async () => {
                // alert(`${isEdit ? 'Edit Clicked' : 'Add Clicked'}`);
                await toggleloading(true);
                if (isEdit) {
                    dispatchEditStaffClient(
                        {
                            id: state?.id,
                            value: {
                                customers:
                                    !!state?.clients?.length > 0
                                        ? state?.clients.map((client) => {
                                              return {
                                                  customerId:
                                                      client?.customerId,
                                                  staffCustCode:
                                                      client?.staffCustCode,
                                              };
                                          })
                                        : [],
                            },
                        },
                        async () => {
                            console.log('Edit Client SUCCESS');
                            await toggleloading(false);
                            handleClose();
                        },
                        async () => {
                            await toggleloading(false);
                            console.log('Edit Client FAILED');
                        },
                    );
                } else {
                    dispatchEditStaffClient(
                        {
                            id: state?.id,
                            value: {
                                customers:
                                    !!state?.clients?.length > 0
                                        ? state?.clients.map((client) => {
                                              return {
                                                  customerId:
                                                      client?.customerId,
                                                  staffCustCode:
                                                      client?.staffCustCode,
                                              };
                                          })
                                        : [],
                            },
                        },
                        async () => {
                            console.log('Add Client SUCCESS');
                            await toggleloading(false);
                            handleClose();
                        },
                        async (err) => {
                            await toggleloading(false);
                            console.log('Add Client FAILED', err);
                        },
                    );
                }
            }}
            disableElevation
        >
            {isEdit ? 'Save' : 'Add'}
        </Button>,
    ];

    const handleInputChange = (value, key) => {
        setState((state) => ({
            ...state,
            [key]: value,
        }));
    };

    const StyledMenuItem = withStyles((theme) => ({
        root: {
            margin: '0 8px',
            border: '0 !important',
            borderRadius: '8px',
            '&:hover': {
                backgroundColor: 'rgba(67, 65, 158, 0.08)',
                color: '#43419e',
                borderRadius: '8px',
            },
        },
    }))(MenuItem);
    const clientIds =
        !!state?.clients?.length > 0
            ? state?.clients.map((client) => client?.customerId)
            : [];
    const handleAddClient = (id, code) => {
        let tempCLients = [];
        setState((prev) => {
            tempCLients = [
                ...prev.clients,
                { customerId: id, staffCustCode: code },
            ];
            return {
                ...prev,
                clients: tempCLients,
                customerId: '',
                staffCustCode: '',
            };
        });
    };
    const handleDeleteClient = (index) => {
        let temp = [];
        setState((prev) => {
            temp = prev.clients.filter((customer, i) => i !== index);
            return {
                ...prev,
                clients: temp,
            };
        });
    };
    return (
        <Modal
            onClose={handleClose}
            open={isOpen}
            title={'Add/Edit Clients'}
            actions={actions}
            loading={isloading}
        >
            <Paper component="ul" className={classes.root}>
                {state?.clients?.map((item, index) => {
                    let icon;
                    icon = <PersonIcon color="primary" />;
                    return (
                        <li key={item?.customerId ?? `item-${index}`}>
                            <Chip
                                icon={icon}
                                label={`${getClientName(item?.customerId)}${
                                    !!item?.staffCustCode
                                        ? ` (${item?.staffCustCode})`
                                        : ''
                                }`}
                                onDelete={() => {
                                    handleDeleteClient(index);
                                }}
                                className={classes.chip}
                                variant="outlined"
                                //deleteIcon={<DeleteIcon color="error" />}
                                color="primary"
                            />
                        </li>
                    );
                })}
            </Paper>
            {clientList?.length ? (
                <CustomTextField
                    label="Client Name"
                    select
                    fullWidth={true}
                    value={state?.customerId ?? ''}
                    onChange={(e) =>
                        handleInputChange(e.target.value, 'customerId')
                    }
                    defaultValue=""
                    style={{ marginBottom: '16px' }}
                    isRequired={true}
                >
                    {clientList?.length == 0 && (
                        <StyledMenuItem value={null}>
                            No Data Found
                        </StyledMenuItem>
                    )}
                    {clientList
                        ?.filter(
                            (client) =>
                                !!client &&
                                !!client?.customerId &&
                                !clientIds.includes(client?.customerId),
                        )
                        .map((hub, index) => (
                            <StyledMenuItem key={index} value={hub.customerId}>
                                {hub.name}
                            </StyledMenuItem>
                        ))}
                </CustomTextField>
            ) : null}
            <CustomTextField
                fullWidth={true}
                label="Staff Reference Number"
                value={state?.staffCustCode}
                onChange={(e) =>
                    handleInputChange(e.target.value, 'staffCustCode')
                }
                error={stateStatus?.staffCustCode?.status}
                helperText={stateStatus?.staffCustCode?.error}
                style={{ marginBottom: '16px' }}
                isRequired={true}
            />
            <Grid
                item
                xs={12}
                style={{ paddingTop: '12px', paddingBottom: '24px' }}
            >
                <Button
                    key={'add-client'}
                    variant="contained"
                    color="primary"
                    className={`saveButton`}
                    onClick={() => {
                        handleAddClient(
                            state?.customerId,
                            state?.staffCustCode?.trim(),
                        );
                    }}
                    disabled={
                        !state?.customerId || !state?.staffCustCode?.trim()
                            ? true
                            : false
                    }
                    disableElevation
                >
                    {'Add Client'}
                </Button>
                {/* <Divider /> */}
            </Grid>
        </Modal>
    );
}

const mapStateToProps = (state) => ({
    staff: state.staff,
});

const mapDispatchToProps = (dispatch) => ({
    dispatchEditStaffClient: (data, onSuccess, onError) =>
        dispatch(editStaffClient(data, onSuccess, onError)),
    dispatchFetchClientList: (onSuccess, onError) =>
        dispatch(fetchClientList(onSuccess, onError)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddClientModal);
