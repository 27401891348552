import * as constants from './../constants';
import { API_USER_SERVICE_PORT } from '../../config/env/env';

export const GetDasboardReport = (data, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: `/reports/dashboard`,
        method: 'POST',
        data,
        success: (response) => {
            console.log(
                'SET_REPORT_DASHBOARD',
                response.response.dashboardReportsData,
            );
            return setDashboardReportData(
                response?.response?.dashboardReportsData,
            );
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
    },
});

export const setDashboardReportData = (data) => ({
    type: constants.SET_REPORT_DASHBOARD,
    payload: data,
});

export const GetPaymentReport = (data, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: `/reports/payments`,
        method: 'POST',
        data,
        success: (response) => {
            console.log('GetPaymentReport', response.response.paymentResponse);
            return {
                type: constants.SET_REPORT_PAYMENTS,
                payload: response?.response?.paymentResponse ?? {},
            };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
    },
});
export const GetEarningReport = (data, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: `/reports/earnings`,
        method: 'POST',
        data,
        success: (response) => {
            console.log('GetEarningReport', response.response.earningsResponse);

            if (response?.response?.earningsResponse?.s3DownloadLink) {
                return {
                    type: constants.DO_NOTHING,
                    payload: response?.response?.earningsResponse ?? {},
                };
            }
            return {
                type: constants.SET_REPORT_EARNINGS,
                payload: response?.response?.earningsResponse ?? {},
            };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
    },
});
export const GetAttendenceReport = (data, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: `/reports/attendence`,
        method: 'POST',
        data,
        success: (response) => {
            console.log(
                'GetattendenceReport',
                response.response.attendanceReportsData,
            );
            if (response?.response?.attendanceReportsData?.s3DownloadLink) {
                return {
                    type: constants.DO_NOTHING,
                    payload: response?.response?.attendanceReportsData ?? {},
                };
            }
            return {
                type: constants.SET_REPORT_ATTENDENCE,
                payload: response?.response?.attendanceReportsData ?? {},
            };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
    },
});
export const GetExpenseReport = (data, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: `/reports/expense`,
        method: 'POST',
        data,
        success: (response) => {
            console.log(
                'GetExpenseReport',
                response.response.expenseReportsData,
            );
            if (response?.response?.expenseReportsData?.s3DownloadLink) {
                return {
                    type: constants.DO_NOTHING,
                    payload: response?.response?.expenseReportsData ?? {},
                };
            }
            return {
                type: constants.SET_REPORT_EXPENSES,
                payload: response?.response?.expenseReportsData ?? {},
            };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
    },
});
export const GetPayslipReport = (data, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: `/reports/payslip/`,
        method: 'POST',
        data,
        success: (response) => {
            console.log('GetPayslipReport', response.response);
            return {
                type: constants.SET_REPORT_PAYSLIP,
                payload: response?.response ?? {},
            };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
    },
});

export const GetDistanceReport = (data, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: `/reports/distance`,
        method: 'POST',
        data,
        success: (response) => {
            if (response?.response?.s3FileUrl) {
                return {
                    type: constants.DO_NOTHING,
                    payload: response?.response ?? {},
                };
            }
            return {
                type: constants.SET_REPORT_DISTANCE,
                payload: response?.response ?? {},
            };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
    },
});

export const GetDistanceReportFromBeecon = (
    { token, data },
    onSuccess,
    onError,
) => ({
    type: constants.API,
    payload: {
        url: `https://beecon.in/api/trade/fleet/entity`,
        method: 'POST',
        data,
        success: (response) => {
            console.log('reponse', response);
            let vehicleDistance = {};
            if (response && response.status && response.status == 200) {
                if (response.data) {
                    vehicleDistance = { ...response.data };
                }
            }
            return {
                type: constants.SET_DISTANCE_REPORT_FROM_BEECON,
                payload: vehicleDistance,
            };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
        thirdParty: true,
        overwriteHeaders: {
            Authorization: token,
        },
    },
});
