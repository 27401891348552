import React from 'react';
import { Box, Typography, Tooltip, IconButton } from '@material-ui/core';
import {
    BatteryIcon,
    LocationMobileOffIcon,
    LocationMobileOnIcon,
    LocationPermissionOffIcon,
    LocationPermissionOnIcon,
    StaffEvIcon,
    EVVehicle,
    LocateIcon,
} from '../../../../assets/svg';
import EVImages from '../../../../assets/images/electric-motorcycle_green.png';
import EVLSNImage from '../../../../assets/images/ev-lsn-rider1.png';
interface EVInfoProps {
    tracking?: any;
    evInfo?: any;
    onLocate?: any;
    locate?: boolean;
}

const EVInfo: React.FC<EVInfoProps> = ({
    tracking,
    evInfo,
    onLocate,
    locate,
}) => {
    return (
        <Box
            style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
            }}
        >
            {!!evInfo?.evNumber && (
                <Tooltip title="EV Info" placement="top">
                    <Box
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        <img
                            src={EVLSNImage}
                            style={{
                                width: '24px',
                                height: '24px',
                                marginLeft: '4px',
                                marginRight: '4px',
                            }}
                            alt="de-app"
                        />
                        <Typography
                            style={{
                                fontFamily: 'Nunito',
                                fontSize: 14,
                                fontWeight: 700,
                                color: '#3326e9',
                            }}
                        >
                            {!!evInfo?.evNumber
                                ? `${evInfo?.evNumber.toUpperCase()}`
                                : ' '}
                            {!!evInfo?.evVendor
                                ? ` (${evInfo?.evVendor})`
                                : ' '}
                        </Typography>
                    </Box>
                </Tooltip>
            )}
            {!!tracking?.telematicData?.powerVoltage && (
                <Tooltip title="EV Battery Info" placement="top">
                    <Box
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        <BatteryIcon
                            color={
                                tracking?.telematicData?.powerVoltage < 20
                                    ? 'red'
                                    : tracking?.telematicData?.powerVoltage > 60
                                    ? 'green'
                                    : 'orange'
                            }
                        />
                        <Typography
                            style={{
                                fontFamily: 'Nunito',
                                fontSize: 12,
                                fontWeight: 600,
                                marginLeft: 5,
                            }}
                        >
                            {tracking?.telematicData?.powerVoltage}%
                        </Typography>
                    </Box>
                </Tooltip>
            )}
            {locate && (
                <Tooltip title="Locate Vehicle" placement="top">
                    <IconButton
                        key={4}
                        style={{
                            border: '1px solid #4d4ab5',
                            borderRadius: '8px',
                            padding: '4px 8px',
                        }}
                        onClick={onLocate}
                    >
                        <LocateIcon />
                    </IconButton>
                </Tooltip>
            )}
        </Box>
    );
};

export default EVInfo;
