module.exports = Object.freeze({
    API_URL: 'https://api-erp.staffpay.in',
    GOOGLE_MAP_ACTIVE: false,
    ENV_TYPE: 'PROD',
    // API_USER_SERVICE_PORT: 9002,
    // API_AUTH_SERVICE_PORT: 9002,
    AWS_BUCKET_REGION: 'ap-south-1',
    AWS_BUCKET_POOL: 'ap-south-1:a4f2ef60-205d-4ebf-a936-a73f90a023a7',
    AWS_BUCKET_NAME: 'earn-de-docs',
    AWS_BUCKET_ACL: 'public-read',
    MAP_KEY: 'AIzaSyDlZ-aV1Y0-C7bFx84BH98_e2qne3H-tWY',
    ZAPPAY_API_URL: 'https://api-zappay.staffpay.in',
    HERMES_API: 'https://api.loadshare.net',
    CONTROL_CENTER_API_URL: 'https://api-controlcenter.loadshare.net',
});
