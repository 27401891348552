import * as constants from './../constants';
import { API_USER_SERVICE_PORT } from '../../config/env/env';

export const addStaffDeduction = ({ id, ...data }, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: `/org/transaction/${id}/deduction`,
        method: 'POST',
        data,
        success: (response) => {
            console.log('add deduction', response);
            return { type: constants.DO_NOTHING, payload: response };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
    },
});

export const editStaffDeduction = (
    { id, deductionId, ...data },
    onSuccess,
    onError,
) => ({
    type: constants.API,
    payload: {
        url: `/org/transaction/${id}/deduction/${deductionId}`,
        method: 'PUT',
        data: data,
        success: (response) => {
            return { type: constants.DO_NOTHING, payload: response };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
    },
});

export const deleteStaffDeduction = (
    { id, deductionId, ...data },
    onSuccess,
    onError,
) => ({
    type: constants.API,
    payload: {
        url: `/org/transaction/${id}/deduction/${deductionId}`,
        method: 'DELETE',
        success: (response) => {
            return { type: constants.DO_NOTHING, payload: response };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
    },
});
