import {
    Avatar,
    FormHelperText,
    Typography,
    Box,
    LinearProgress,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import './styles.scss';
import { UploadIcon } from '../../../assets/svg';
import {
    AWS_BUCKET_REGION,
    AWS_BUCKET_POOL,
    AWS_BUCKET_NAME,
    AWS_BUCKET_ACL,
} from '../../../config/env/env';
import AWS from 'aws-sdk';
import { Spinner } from '../';
import { getFileExtension } from '../../../utils/helper';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { LightBoxImage } from '../index';
import { isVideoURL } from '../../../utils/helper';
const MAX_FILE_SIZE = 25600; // 5MB

// const IMAGE_FILES = ['.jpg', '.png', '.jpeg', '.bmp'];
const NON_IMAGE_FILES = ['.xlsx', '.csv', '.pdf', '.html'];
function LinearProgressWithLabel(props) {
    return (
        <Box display="flex" alignItems="center">
            <Box width="100%" mr={1}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box minWidth={35}>
                <Typography
                    variant="body2"
                    color="textSecondary"
                >{`${Math.round(props.value)}%`}</Typography>
            </Box>
        </Box>
    );
}
export const uploadImageOnS3 = async (file, onSuccess, onProgress) => {
    await AWS.config.update({
        region: AWS_BUCKET_REGION,
        credentials: new AWS.CognitoIdentityCredentials({
            IdentityPoolId: AWS_BUCKET_POOL,
        }),
    });

    // store.dispatch(setLoading());
    // store.dispatch(toggleFileModal({ isOpen: false }));

    const s3bucket = new AWS.S3({
        params: {
            Bucket: AWS_BUCKET_NAME,
        },
    });
    // console.log('file', file);

    let contentDeposition = 'inline;filename="' + file.name + '"';
    // const base64 = await fs.readFile(file.uri, 'base64');
    // const arrayBuffer = decode(base64);

    s3bucket.createBucket(() => {
        const params = {
            Key: file.name,
            Body: file,
            ContentDisposition: contentDeposition,
            ContentType: file.type,
            ACL: AWS_BUCKET_ACL,
            Bucket: AWS_BUCKET_NAME,
        };
        // console.log('params', params);
        s3bucket
            .upload(params, (err, data) => {
                if (err) {
                    console.log('res.err', err);
                }

                // toggleLoader();
                // fileUploadModalConfig.onFileUploaded &&
                //     fileUploadModalConfig.onFileUploaded(data?.Location);
                !!onSuccess && onSuccess(data?.Location);
            })
            .on('httpUploadProgress', (evt) => {
                console.log(
                    'Uploading ' +
                        parseInt((evt.loaded * 100) / evt.total) +
                        '%',
                );
                !!onProgress &&
                    onProgress(parseInt((evt.loaded * 100) / evt.total));
            });
    });
};

export function FileUploader({
    render,
    label,
    labelStyles,
    placeholder,
    onFileUploaded,
    defaultValue,
    helperText,
    disabled,
    error,
    onRemoved,
    lightboxConfig = {},
    isRequired,
    download = false,
    ...props
}) {
    // const classes = useStyles();
    const [uploadedFile, setuploadedFile] = useState(defaultValue ?? '');
    const [loading, toggleLoading] = useState(false);
    const [progress, setProgress] = useState(0);
    const [fileName, setFileName] = useState(defaultValue ?? '');

    useEffect(() => {
        setuploadedFile(defaultValue);
    }, [defaultValue]);

    const uploadfile = async (e) => {
        if (e.target.files.length > 0) {
            const selectedFile = e.target.files[0];
            setFileName(selectedFile.name);
            console.log(selectedFile.name);
            const fileSizeKiloBytes = selectedFile.size / 1024;
            console.log(fileSizeKiloBytes);
            if (fileSizeKiloBytes > MAX_FILE_SIZE) {
                alert(
                    `Maximum allowed size for a file is 25Mb.Upload less than 25Mb`,
                );
                return;
            }
            await toggleLoading(true);
            uploadImageOnS3(
                e.target.files[0],
                (file) => {
                    onFileUploaded(file);
                    toggleLoading(false);
                    setuploadedFile(file);
                    setProgress(100);
                },
                (value) => {
                    setProgress(value);
                },
            );
        } else {
        }
    };

    const removeimage = () => {
        setuploadedFile('');
        setProgress('');
        onRemoved && onRemoved();
    };

    function getFileNameFromS3() {
        return uploadedFile.split('/')[uploadedFile.split('/').length - 1];
    }

    return (
        <>
            {!!label && (
                <Typography
                    variant="body1"
                    style={
                        labelStyles
                            ? {
                                  fontSize: '14px',
                                  fontWeight: 'normal',
                                  marginTop: 10,
                                  marginBottom: 0,
                                  padding: 0,
                                  color: Boolean(error) ? '#F44336' : '#14112D',
                                  ...labelStyles,
                              }
                            : {
                                  fontSize: '14px',
                                  fontWeight: 'normal',
                                  marginTop: 10,
                                  marginBottom: 0,
                                  padding: 0,
                                  color: Boolean(error) ? '#F44336' : '#515f6d',
                              }
                    }
                >
                    {label}
                    {isRequired && <span style={{ color: 'red' }}>*</span>}
                </Typography>
            )}
            <Spinner loading={loading}>
                <div className="file-upload">
                    {!!uploadedFile ? (
                        <div
                            className={`image-upload-wrap ${
                                Boolean(error) ? 'img-border-error' : ''
                            }`}
                        >
                            {!disabled && (
                                <div
                                    style={{
                                        display: 'flex',
                                        backgroundColor: 'red',
                                        borderRadius: '50%',
                                        position: 'absolute',
                                        top: -10,
                                        right: -10,
                                        zIndex: 99,
                                        color: '#fff',
                                        width: 25,
                                        height: 25,
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        cursor: 'pointer',
                                    }}
                                    onClick={(e) => removeimage()}
                                >
                                    {'X'}
                                </div>
                            )}
                            {/* <div className="drag-text"> */}
                            {!!uploadedFile &&
                            !NON_IMAGE_FILES.find((file) =>
                                uploadedFile.toLowerCase().includes(file),
                            ) ? (
                                <div className="drag-text image-drag">
                                    <LightBoxImage
                                        {...lightboxConfig}
                                        source={uploadedFile}
                                        download={download}
                                        type={
                                            isVideoURL(uploadedFile)
                                                ? 'video'
                                                : 'image'
                                        }
                                    />
                                </div>
                            ) : (
                                <div className="drag-text">
                                    <AttachFileIcon />
                                    {
                                        <Box
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'center',
                                                alignItems: 'flex-end',
                                            }}
                                        >
                                            {/* <AttachFileIcon /> */}
                                            <Typography
                                                // variant="body1"
                                                style={{
                                                    color: 'red',
                                                    marginLeft: '2px',
                                                    width: '100%',
                                                    wordWrap: 'break-word',
                                                }}
                                            >
                                                {/* {`${uploadedFile}`} */}
                                                {`${getFileNameFromS3()}`}
                                            </Typography>
                                        </Box>
                                    }
                                </div>
                            )}
                            {/* </div> */}
                        </div>
                    ) : !render ? (
                        <div
                            className={`image-upload-wrap ${
                                Boolean(error) ? 'img-border-error' : ''
                            }`}
                        >
                            <input
                                className="file-upload-input"
                                type="file"
                                onChange={uploadfile}
                            />
                            <div className="drag-text">
                                <UploadIcon />
                                {progress > 0 && progress < 100 && (
                                    <LinearProgressWithLabel value={progress} />
                                )}
                                {!!placeholder && (
                                    <Typography variant="body1">
                                        {placeholder}
                                    </Typography>
                                )}
                            </div>
                        </div>
                    ) : (
                        <div
                            className={` ${
                                Boolean(error) ? 'img-border-error' : ''
                            }`}
                        >
                            <input
                                className="file-upload-input"
                                type="file"
                                onChange={uploadfile}
                            />
                            {render()}
                        </div>
                    )}
                </div>
                {Boolean(error) && (
                    <FormHelperText className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error">
                        {helperText}
                    </FormHelperText>
                )}
            </Spinner>
        </>
    );
}
