import * as constants from '../constants';

const defaultState = {};

// const userInfo = localStorage.getItem("USER_INFO");

export default function paymentReducer(state = defaultState, action) {
    switch (action.type) {
        case constants.SET_ALL_PAYMENTS:
            console.log('SET_ALL_STAFF_INFO', action.payload);
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
}
