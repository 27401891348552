import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
    Box,
    Button,
    MenuItem,
    Paper,
    makeStyles,
    Typography,
    Dialog,
    DialogTitle,
    IconButton,
    Slide,
    useMediaQuery,
    useTheme,
    Avatar,
    Divider,
    DialogContent,
    DialogActions,
    Tooltip,
    List,
    ListItem,
    ListItemSecondaryAction,
    Backdrop,
    ListItemText,
    TextField,
} from '@material-ui/core';
import Modal from '../../../components/Modals/Modal';
import { connect, useDispatch, useSelector } from 'react-redux';
import { updateLeadStatus } from '../../../store/actions/leadActionCreator';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
    createStaff,
    fetchConvertStaffList,
    getStaffEvHistory,
    covertToStaff,
} from '../../../store/actions/staffActionCreator';
import moment from 'moment';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CloseIcon from '@material-ui/icons/Close';
import { Spinner } from '../../../components/UI';
import CircularProgress from '@material-ui/core/CircularProgress';
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';
import HeightIcon from '@material-ui/icons/Height';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import InfoIcon from '@material-ui/icons/Info';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { LightBoxImages } from '../../../components/UI/LightboxImage/LightboxImage';
import { GenerateImageDataFromEVDocument } from './helper';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const useStyles = makeStyles((theme) => ({
    cardSubHeader: {
        fontSize: '22px',
        fontWeight: 700,
    },
    cardHeader: {
        fontSize: '16px',
        fontWeight: 600,
    },
    cardHeaderFail: {
        fontSize: '16px',
        fontWeight: 600,
        color: '#FB8A06',
    },
    cardSubHeaderFail: {
        fontSize: '22px',
        fontWeight: 700,
        color: '#FB8A06',
    },
    cardHeaderActive: {
        fontWeight: 700,
    },
    verticalCardContentsNoMargin: {
        margin: 0,
    },
    iconButton: {
        padding: '0px',
    },
    modalTitle: {
        fontSize: '20px',
        fontWeight: 'bold',
        color: '#14112d',
        marginLeft: '14px',
    },
    modalSubTitle: {
        fontSize: '14px',
        fontWeight: 'normal',
        color: '#14112d',
        fontWeight: 600,
        marginLeft: '14px',
    },
    dialogContent: {
        paddingLeft: 0,
        paddingRight: 0,
    },
    dialogActions: {
        padding: '16px 24px',
    },
    listItemHeader: {
        border: '0px !important',
        marginBottom: '8px !important',
        color: '#808080 !important',
        fontWeight: '600 !important',
        padding: 0,
        paddingLeft: 16,
        paddingRight: 22,
        paddingTop: 16,
        borderTop: '1px solid #EAEDF3 !important',
        fontSize: '14px',
    },
    listItem: {
        padding: '6px 16px',
        border: '1px solid #ebedf8',
        borderRadius: 4,
        marginBottom: 8,
        '&:last-child': {
            marginBottom: 0,
        },
    },
    name: {
        fontSize: 16,
        fontWeight: 700,
        color: '#14112D',
    },
    phone: {
        fontSize: 16,
        fontWeight: 600,
        color: '#14112D',
    },
    org: {
        fontSize: 16,
        fontWeight: 600,
        color: '#14112D',
    },
    selfOnBoard: {
        backgroundColor: 'rgba(82, 80, 80, 0.04)',
        borderRadius: 4,
        padding: '4px 6px',
        color: '#949494',
        fontWeight: 600,
        fontSize: 10,
        width: 84,
        textAlign: 'center',
    },
    IN_PROGRESS: {
        backgroundColor: '#EBEBFF',
        borderRadius: 4,
        border: '1px solid #579B5A',
        padding: '0px 8px',
        marginTop: 4,
        color: '#579B5A',
        fontWeight: 600,
        fontSize: 12,
        textAlign: 'center',
        maxWidth: 110,
    },
    IN_POSSESION: {
        backgroundColor: '#EBEBFF',
        borderRadius: 4,
        border: '1px solid #579B5A',
        padding: '0px 8px',
        marginTop: 4,
        color: '#579B5A',
        fontWeight: 600,
        fontSize: 12,
        textAlign: 'center',
        maxWidth: 110,
    },
    REJECTED: {
        backgroundColor: '#EBEBFF',
        borderRadius: 4,
        border: '1px solid #f44336',
        padding: '0px 8px',
        marginTop: 4,
        color: '#f44336',
        fontWeight: 600,
        fontSize: 12,
        textAlign: 'center',
        maxWidth: 110,
    },
    RETURNED: {
        backgroundColor: '#fff',
        borderRadius: 4,
        marginTop: 4,
        border: '1px solid #B9B9B9',
        padding: '0px 8px',
        color: '#828282',
        fontWeight: 600,
        fontSize: 12,
        textAlign: 'center',
        maxWidth: 91,
    },
    backDropRoot: {
        position: 'absolute',
        zIndex: theme.zIndex.drawer - 1,
        opacity: 0.5,
    },
    workLocation: {
        fontWeight: 600,
        fontSize: 16,
        color: ' #43419E',
    },
    minimizeButton: {
        color: '#43419E',
        background: 'rgba(67, 65, 158, 0.2)',
        borderRadius: 8,
        padding: '6px 8px',
        fontSize: 16,
        fontWeight: 600,
        cursor: 'pointer',
    },
    covertedTitle: {
        background: '#4CAF50',
        padding: '12px 14px',
        color: '#fff',
    },
    covertingTitle: {
        background: '#303030',
        padding: '12px 14px',
        color: '#fff',
    },
    scrollPaperMini: {
        alignItems: 'end !important',
        justifyContent: 'end !important',
    },
    modal: {
        width: 400,
        marginLeft: 'auto',
        position: 'sticky !important',
        '& .MuiBackdrop-root': {
            display: 'none',
        },
    },
    editBtn: {
        background: '#FFFFFF',
        border: '1px solid #EBEBFF',
        borderRadius: 8,
    },
    failureBox: {
        padding: '6px 16px',
        background: '#FCF2E6',
        border: '1px solid #FADEBE',
        borderRadius: 6,
        marginLeft: 'auto',
    },
    failureText: {
        color: 'red',
        fontSize: 12,
        fontWeight: 600,
    },
    warningText: {
        color: '#14112D',
        fontSize: 12,
        fontWeight: 600,
    },
}));
const statusMapping = {
    IN_POSSESION: 'IN POSSESSION',
    RETURNED: 'RETURNED',
};
function StaffEvHistoryModal({
    staffInfo,
    open,
    onClose,
    defaultValue = {},
    orgList,
    preferredWorkLocationsList,
    selected,
    ...props
}) {
    const dispatch = useDispatch();
    const classes = useStyles();
    //const notifications = useSelector((store) => store.notifications || []);
    const [isOpen, toggleModal] = useState(open);
    const [loading, toggleLoading] = useState(false);
    const [evHistory, setEvHistory] = useState({});
    const [currentStatus, setCurrentStatus] = useState('totalCount');
    const handleClose = () => {
        toggleModal(!isOpen);
        onClose && onClose();
    };
    const disableAction = () => {
        return false;
    };
    const actions = [];
    const loadEvHistory = () => {
        if (!!staffInfo.id) {
            toggleLoading(true);
            dispatch(
                getStaffEvHistory(
                    staffInfo?.id,
                    {},
                    (data) => {
                        toggleLoading(false);
                        if (
                            data &&
                            data.status &&
                            data.status.code &&
                            data.status.code == 200 &&
                            !data.status.customErrorCode
                        ) {
                            if (data && data.response) {
                                setEvHistory(data?.response);
                            }
                        }
                    },
                    (err) => {
                        toggleLoading(false);
                    },
                ),
            );
        }
    };
    useEffect(() => {
        loadEvHistory();
    }, []);
    let failed =
        currentStatus && currentStatus === 'failedCount' ? true : false;
    return (
        <Dialog
            onClose={(event) => {
                handleClose(defaultValue);
            }}
            open={isOpen}
            disableBackdropClick={true}
            Transition={Transition}
            aria-labelledby="staff-ev-history-dialog-slide-title"
            aria-describedby="staff-ev-history-dialog-slide-description"
            disableEscapeKeyDown
            fullWidth={true}
            scroll={'paper'}
            maxWidth={'lg'}
        >
            <DialogTitle id="staff-ev-history-dialog-slide-title">
                <Box
                    width={'100%'}
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                >
                    <>
                        <Box
                            width={'45%'}
                            display={'flex'}
                            alignItems={'center'}
                        >
                            <IconButton
                                aria-label="close"
                                disabled={disableAction(true)}
                                className={classes.iconButton}
                                onClick={handleClose}
                            >
                                <ArrowBackIcon style={{ color: '#000' }} />
                            </IconButton>
                            <Box>
                                <Typography
                                    className={classes.modalTitle}
                                    variant="h6"
                                >
                                    {'EV Payment History'}
                                </Typography>
                                {!!staffInfo?.name && !!staffInfo?.name && (
                                    <Typography
                                        className={classes.modalSubTitle}
                                        variant="h6"
                                    >
                                        {staffInfo?.name}
                                    </Typography>
                                )}
                            </Box>
                        </Box>
                        <IconButton
                            aria-label="close"
                            disabled={disableAction(true)}
                            className={classes.iconButton}
                            onClick={handleClose}
                        >
                            <CloseIcon style={{ color: '#000' }} />
                        </IconButton>
                    </>
                </Box>
            </DialogTitle>
            <DialogContent>
                <Box width={'100%'}>
                    <Box>
                        <List component="nav">
                            <ListItem className={classes.listItemHeader}>
                                <Box
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        width: '100%',
                                    }}
                                    alignItems={'center'}
                                >
                                    <Box width={'25%'}>{'EV Number'}</Box>
                                    <Box width={'25%'}>{'Rented on'}</Box>
                                    <Box width={'25%'}>{'Rented Images'}</Box>
                                    <Box width={'25%'}>{'Returned on'}</Box>
                                    <Box width={'25%'}>{'Returned Images'}</Box>
                                    <Box width={'23%'}>{'Total Rent Paid'}</Box>
                                    <Box width={'2%'}>{'  '}</Box>
                                </Box>
                            </ListItem>
                            <>
                                {evHistory &&
                                !!evHistory?.evHistoryLineItems?.length
                                    ? evHistory?.evHistoryLineItems?.map(
                                          (val, index) => {
                                              return (
                                                  <ListItem
                                                      key={val.id}
                                                      button
                                                      onClick={() => {}}
                                                      classes={{
                                                          root: classes.listItem,
                                                      }}
                                                  >
                                                      <Box width={'25%'}>
                                                          <Typography
                                                              className={
                                                                  classes.name
                                                              }
                                                          >
                                                              {val &&
                                                              val.evNumber
                                                                  ? val.evNumber
                                                                  : '--'}
                                                          </Typography>
                                                          {val &&
                                                              val.status && (
                                                                  <Typography
                                                                      className={
                                                                          classes[
                                                                              val
                                                                                  .status
                                                                          ]
                                                                      }
                                                                  >
                                                                      {
                                                                          statusMapping[
                                                                              val
                                                                                  .status
                                                                          ]
                                                                      }
                                                                  </Typography>
                                                              )}
                                                      </Box>
                                                      <Box width={'25%'}>
                                                          <Typography
                                                              className={
                                                                  classes.name
                                                              }
                                                          >
                                                              {val &&
                                                              val.rentedDateEpochMillis
                                                                  ? moment(
                                                                        val.rentedDateEpochMillis,
                                                                    ).format(
                                                                        'Do MMMM YYYY',
                                                                    )
                                                                  : '---'}
                                                          </Typography>
                                                      </Box>
                                                      <Box width={'25%'}>
                                                          <LightBoxImages
                                                              imageSources={GenerateImageDataFromEVDocument(
                                                                  val?.tagEvDocuments ??
                                                                      {},
                                                              )}
                                                              imageKey={`tag-images-${index}`}
                                                          />
                                                      </Box>

                                                      <Box width={'25%'}>
                                                          <Typography
                                                              className={
                                                                  classes.name
                                                              }
                                                          >
                                                              {val &&
                                                              val.returnedDateEpochMillis
                                                                  ? moment(
                                                                        val.returnedDateEpochMillis,
                                                                    ).format(
                                                                        'Do MMMM YYYY',
                                                                    )
                                                                  : '---'}
                                                          </Typography>
                                                      </Box>
                                                      <Box width={'25%'}>
                                                          <LightBoxImages
                                                              imageSources={GenerateImageDataFromEVDocument(
                                                                  val?.unTagEvDocuments ??
                                                                      {},
                                                              )}
                                                              imageKey={`untag-images-${index}`}
                                                          />
                                                      </Box>
                                                      <Box width={'23%'}>
                                                          <Typography
                                                              className={
                                                                  classes.name
                                                              }
                                                          >
                                                              &#8377;
                                                              {val &&
                                                              val.totalRentPaid
                                                                  ? val.totalRentPaid
                                                                  : '0'}
                                                          </Typography>
                                                      </Box>
                                                      <Box width={'2%'}></Box>
                                                  </ListItem>
                                              );
                                          },
                                      )
                                    : null}
                            </>
                        </List>
                    </Box>
                </Box>
            </DialogContent>
            <Backdrop
                className={classes.backDropRoot}
                open={loading}
                onClick={null}
            >
                <CircularProgress color="primary" />
            </Backdrop>
        </Dialog>
    );
}

export default StaffEvHistoryModal;
