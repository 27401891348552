import * as constants from '../constants';

const defaultState = {
    bankDetails: {},
    singleStaffInfo: {},
    rateCard: {},
    evInfo: {},
    clusters: [],
};

// const userInfo = localStorage.getItem("USER_INFO");

export default function staffReducer(state = defaultState, action) {
    switch (action.type) {
        case constants.SET_ALL_STAFF_INFO:
            console.log('SET_ALL_STAFF_INFO', action.payload);
            return {
                ...state,
                ...action.payload,
            };
        case constants.SET_KYC_STAFF_INFO:
            console.log('SET_KYC_STAFF_INFO', action.payload);
            return {
                ...state,
                kycInfo: { ...action.payload },
            };
        case constants.SET_STAFF_EV_INFO:
            console.log('SET_STAFF_EV_INFO', action.payload);
            return {
                ...state,
                evInfo: { ...action.payload },
            };
        case constants.SET_HUB_LIST:
            console.log('SET_HUB_LIST', action.payload);
            return {
                ...state,
                ...action.payload,
            };
        case constants.SET_CLUSTER_LIST:
            console.log('SET_CLUSTER_LIST', action.payload);
            return {
                ...state,
                clusters: action.payload ?? [],
            };
        case constants.SET_STAFF_CITY_ZONES:
            console.log('SET_STAFF_CITY_ZONES', action.payload);
            return {
                ...state,
                ...action.payload,
            };
        case constants.SET_SHIFT_TIMINGS_LIST:
            console.log('SET_SHIFT_TIMINGS_LIST', action.payload);
            return {
                ...state,
                ...action.payload,
            };
        case constants.SET_STAFF_TYPE_LIST:
            console.log('SET_STAFF_TYPE_LIST', action.payload);
            return {
                ...state,
                ...action.payload,
            };
        case constants.UPDATE_STAFF_BANK_DETAILS:
            console.log('UPDATE_STAFF_BANK_DETAILS', action.payload);
            return { ...state, ...action.payload };
        case constants.SET_SINGLE_STAFF_INFO:
            console.log('SET_SINGLE_STAFF_INFO', action.payload);
            return { ...state, singleStaffInfo: action.payload };
        case constants.SET_ALL_STAFF_PAYSLIP:
            console.log('SET_ALL_STAFF_PAYSLIP', action.payload);
            return { ...state, payslips: action.payload };
        case constants.SET_ALL_STAFF_EV_HISTORY:
            console.log('SET_ALL_STAFF_EV_HISTORY', action.payload);
            return { ...state, staffEvHistory: action.payload };
        case constants.SET_RATE_CARD:
            return { ...state, rateCard: action.payload };
        case constants.LEAD_SOURCE_STATIC_VALUES_DATA:
            return { ...state, leadSources: action.payload };
        default:
            return state;
    }
}
