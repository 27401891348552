import React, { useState, useEffect } from 'react';
import {
    Button,
    Box,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    TablePagination,
    Toolbar,
    Paper,
    Checkbox,
    IconButton,
    FormControlLabel,
    Switch,
    List,
    ListItem,
    ListItemSecondaryAction,
    Tooltip,
    Chip,
} from '@material-ui/core';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Modal from '../Modal';
import { CustomTextField } from '../../Textfield';
import moment from 'moment';
import { connect } from 'react-redux';
import {
    sendBatchPayment,
    getAllPaymentIndentsListingData,
    setSelectedPayments,
    setBulkReleaseFlag,
} from '../../../store/actions/authActionCreator';
import {
    showMessage,
    replaceTextWithCapsAndUnderscore,
} from '../../../utils/helper';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        margin: '16px 0',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
}));

function BulkReleaseModal({
    open,
    onClose,
    data = {},
    user,
    dispatchSendBatchPayment,
    dispatchGetAllPaymentIndentsListingData,
    dispatchSetSelectedPaymentData,
    dispatchSetBulkReleaseFlag,
    onSuccessActionCallback,
    ...props
}) {
    const classes = useStyles();
    const [isOpen, toggleModal] = useState(open);
    const [loading, toggleLoading] = useState(false);
    const handleClose = () => {
        // Setting form data to null before closing
        setState(null);

        toggleModal(!isOpen);
        onClose && onClose();
    };
    const [state, setState] = useState({});

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);

    const [headCells, setHeadCells] = useState([]);

    const [selectedPaymentIndents, setSelectedPaymentIndents] = useState(
        user?.zappay?.listingData?.lineItems?.filter((item) =>
            user?.zappay?.selectedPayments?.includes(item?.id),
        ),
    );

    const [totalAmount, setTotalAmount] = useState(
        selectedPaymentIndents?.reduce((n, { amount }) => n + amount, 0),
    );

    const handleChangePage = (event, newPage) => {
        console.log('Inside handleChangePage');
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        console.log('Inside handleChangeRowsPerPage');
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const actions = [
        <Button
            key={1}
            variant="contained"
            color="default"
            className={`cancelButton`}
            onClick={handleClose}
            disableElevation
        >
            {'Cancel'}
        </Button>,
        <Button
            key={2}
            variant="contained"
            color="primary"
            disableElevation
            // style={{
            //     backgroundColor: '#f44336',
            //     color: '#fff',
            // }}
            className={`saveButton`}
            style={{
                fontSize: '14px',
                backgroundColor: 'rgba(210,0,0,1)',
            }}
            onClick={async () => {
                toggleLoading(true);
                let transferPayload = [];

                selectedPaymentIndents?.forEach((pi) => {
                    transferPayload.push({
                        indentId: pi?.id,
                        amountToPay: pi?.amount,
                    });
                });

                dispatchSendBatchPayment(
                    { transfers: transferPayload },
                    async (resp) => {
                        console.log('BATCH RELEASE RESPONSE', resp);

                        if (resp?.response?.failedIndents?.length > 0) {
                            await showMessage(
                                `Failed to release ${resp?.response?.failedIndents?.toString()}`,
                                'error',
                            );
                        } else {
                            await showMessage('SUCCESS', 'success');
                        }
                        handleClose();
                        onSuccessActionCallback &&
                            onSuccessActionCallback(toggleLoading);
                    },
                    (err) => {
                        toggleLoading(false);
                    },
                );
            }}
        >
            {`Release Payments ( Rs. ${totalAmount} / ${selectedPaymentIndents?.length} )`}
        </Button>,
    ];

    useEffect(() => {
        let tempHeadCells = [
            {
                id: 'referenceId',
                numeric: true,
                disablePadding: false,
                label: 'Reference ID',
            },
            {
                id: 'sourceSystem',
                numeric: true,
                disablePadding: false,
                label: 'Source System',
            },
            {
                id: 'amount',
                numeric: true,
                disablePadding: false,
                label: 'Amount',
            },
            {
                id: 'accountName',
                numeric: true,
                disablePadding: false,
                label: 'Account Name',
            },
            {
                id: 'ifsc',
                numeric: true,
                disablePadding: false,
                label: 'IFSC Code',
            },
            {
                id: 'accountNumber',
                numeric: true,
                disablePadding: false,
                label: 'Account Number',
            },
            {
                id: 'bankName',
                numeric: true,
                disablePadding: false,
                label: 'Bank Name',
            },
            // {
            //     id: 'paymentStatus',
            //     numeric: true,
            //     disablePadding: false,
            //     label: 'Payment Status',
            // },
        ];

        // user?.zappay?.listingData?.metaDataDisplayNameMappings &&
        //     Object.entries(
        //         user?.zappay?.listingData?.metaDataDisplayNameMappings,
        //     )?.map((keyVal, index) => {
        //         let key = keyVal[0];
        //         let val = keyVal[1];

        //         tempHeadCells.push({
        //             id: key,
        //             numeric: false,
        //             disablePadding: false,
        //             label: val,
        //         });
        //     });
        setHeadCells(tempHeadCells);
    }, []);

    useEffect(() => {
        console.log('headCells', headCells);
    }, [headCells]);

    useEffect(() => {
        setSelectedPaymentIndents(
            user?.zappay?.listingData?.lineItems?.filter((item) =>
                user?.zappay?.selectedPayments?.includes(item?.id),
            ),
        );
    }, [user?.zappay?.listingData?.lineItems]);

    useEffect(() => {
        setTotalAmount(
            selectedPaymentIndents?.reduce((n, { amount }) => n + amount, 0),
        );
    }, [selectedPaymentIndents]);

    return (
        <Modal
            onClose={handleClose}
            open={isOpen}
            title={'Release Payments'}
            // subtitle={'Release all selected Payments'}
            actions={actions}
            loading={loading}
            maxWidth={'xl'}
            fullWidth={true}
            // containerStyle={{
            //     height: 'auto',
            // }}
        >
            <TableContainer
                style={{
                    maxHeight: '30rem',
                }}
            >
                <Table
                    className={classes.table}
                    aria-labelledby="tableTitle"
                    size={'medium'}
                    aria-label="enhanced table"
                    stickyHeader
                >
                    {!!headCells?.length && (
                        <TableHead>
                            <TableRow>
                                {headCells?.map((headCell) => (
                                    <TableCell
                                        key={headCell.id}
                                        align={'right'}
                                        padding={'default'}
                                    >
                                        {headCell.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                    )}
                    {!!selectedPaymentIndents?.length && (
                        <TableBody>
                            {selectedPaymentIndents
                                ?.slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage,
                                )
                                ?.map((row, index) => {
                                    // const isItemSelected = isSelected(row.id);
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    return (
                                        <TableRow
                                            hover
                                            // role="checkbox"
                                            // aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={row?.id}
                                            // selected={isItemSelected}
                                        >
                                            <TableCell
                                                key={`${row?.id}-0`}
                                                align="right"
                                                // padding={'none'}
                                            >
                                                <Typography
                                                    style={{
                                                        minWidth: '200px',
                                                        padding: '10px',
                                                    }}
                                                >
                                                    {row?.referenceId}
                                                </Typography>
                                            </TableCell>
                                            <TableCell
                                                key={`${row?.id}-1`}
                                                align="right"
                                                padding={'none'}
                                            >
                                                <Typography
                                                    style={{
                                                        minWidth: '200px',
                                                        padding: '10px',
                                                    }}
                                                >
                                                    {
                                                        user?.zappay
                                                            ?.filterGroupModalConfig
                                                            ?.sourceSystem?.name
                                                    }
                                                </Typography>
                                            </TableCell>
                                            <TableCell
                                                key={`${row?.id}-2`}
                                                align="right"
                                                padding={'none'}
                                            >
                                                <Typography
                                                    style={{
                                                        minWidth: '200px',
                                                        padding: '10px',
                                                    }}
                                                >
                                                    {row?.amount}
                                                </Typography>
                                            </TableCell>
                                            <TableCell
                                                key={`${row?.id}-3`}
                                                align="right"
                                                padding={'none'}
                                            >
                                                <Typography
                                                    style={{
                                                        minWidth: '200px',
                                                        padding: '10px',
                                                    }}
                                                >
                                                    {row?.accountName}
                                                </Typography>
                                            </TableCell>
                                            <TableCell
                                                key={`${row?.id}-4`}
                                                align="right"
                                                padding={'none'}
                                            >
                                                <Typography
                                                    style={{
                                                        minWidth: '200px',
                                                        padding: '10px',
                                                    }}
                                                >
                                                    {row?.ifscCode}
                                                </Typography>
                                            </TableCell>
                                            <TableCell
                                                key={`${row?.id}-5`}
                                                align="right"
                                                padding={'none'}
                                            >
                                                <Typography
                                                    style={{
                                                        minWidth: '200px',
                                                        padding: '10px',
                                                    }}
                                                >
                                                    {row?.accountNumber}
                                                </Typography>
                                            </TableCell>
                                            <TableCell
                                                key={`${row?.id}-6`}
                                                align="right"
                                                padding={'none'}
                                            >
                                                <Typography
                                                    style={{
                                                        minWidth: '200px',
                                                        padding: '10px',
                                                    }}
                                                >
                                                    {row?.bankName}
                                                </Typography>
                                            </TableCell>

                                            {/* {user?.zappay?.listingData
                                                ?.metaDataDisplayNameMappings &&
                                                Object.entries(
                                                    user?.zappay?.listingData
                                                        ?.metaDataDisplayNameMappings,
                                                )?.map((keyVal, index) => {
                                                    let key = keyVal[0];
                                                    let val = keyVal[1];
                                                    return (
                                                        <TableCell
                                                            key={key}
                                                            align="right"
                                                            padding={'none'}
                                                        >
                                                            <Typography
                                                                style={{
                                                                    minWidth:
                                                                        '200px',
                                                                    padding:
                                                                        '10px',
                                                                }}
                                                            >
                                                                {row?.metaData &&
                                                                    row
                                                                        ?.metaData[
                                                                        key
                                                                    ]}
                                                            </Typography>
                                                        </TableCell>
                                                    );
                                                })} */}
                                        </TableRow>
                                    );
                                })}
                            {/* {emptyRows > 0 && (
                                    <TableRow
                                        style={{ height: 30 * emptyRows }}
                                    >
                                        <TableCell colSpan={6} />
                                    </TableRow>
                                )} */}
                        </TableBody>
                    )}
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[25, 50, 100]}
                component="div"
                count={selectedPaymentIndents?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </Modal>
    );
}

const mapStateToProps = (state) => ({
    user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
    dispatchSendBatchPayment: (data, onSuccess, onError) =>
        dispatch(sendBatchPayment(data, onSuccess, onError)),

    dispatchGetAllPaymentIndentsListingData: (data, onSuccess, onError) =>
        dispatch(getAllPaymentIndentsListingData(data, onSuccess, onError)),

    dispatchSetSelectedPaymentData: (data) =>
        dispatch(setSelectedPayments(data)),

    dispatchSetBulkReleaseFlag: (data) => dispatch(setBulkReleaseFlag(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BulkReleaseModal);
