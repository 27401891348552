import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { Polyline, Marker, Popup, useMap } from 'react-leaflet';
import {
    redMarker,
    greenMarker,
    orangeFilledMarker,
    purpleMarker,
    blueGreenMarker,
} from '../../../../components/Map/MarkerIcons';
import moment from 'moment';
import { getTimeAsDuration, getTimeDifference } from '../../../../utils/helper';

interface IdlePointMarkerProps {
    data?: any;
    trackData?: any;
    aggregatedTrackData?: any;
    startAddress?: any;
    events?: any;
}

const IdlePointMarker: React.FC<IdlePointMarkerProps> = ({
    data,
    trackData,
    aggregatedTrackData,
    startAddress,
    events,
}) => {
    let point: any = [data[2], data[3]];
    return (
        <Marker
            // if position changes, marker will drift its way to new position
            position={!!point?.[0] && !!point?.[1] ? point : {}}
            icon={
                trackData?.tripClosure?.endTime === data[1]
                    ? redMarker
                    : orangeFilledMarker
            }
        >
            <Popup>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        textAlign: 'center',
                        flexDirection: 'column',
                    }}
                >
                    <Typography
                        style={{
                            fontWeight: 700,
                            fontSize: 14,
                        }}
                        variant="subtitle1"
                    >
                        {data[data?.length - 1]?.length > 0 &&
                        isNaN(Number(data[data?.length - 1]))
                            ? data[data?.length - 1].split('@!@').join(',')
                            : 'No Location Available'}
                    </Typography>
                    <Typography
                        style={{
                            fontWeight: 400,
                            fontSize: 16,
                        }}
                        variant="subtitle1"
                    >
                        {moment(parseInt(data[0])).format(
                            'hh:mm A',
                            // 'D MMM YYYY hh:mm A',
                        )}{' '}
                        -{' '}
                        {moment(parseInt(data[1])).format(
                            'hh:mm A',
                            // 'D MMM YYYY hh:mm A',
                        )}{' '}
                        (
                        {getTimeAsDuration(
                            getTimeDifference(
                                parseInt(data[1]),
                                parseInt(data[0]),
                            ),
                            'small',
                        )}
                        )
                    </Typography>
                </div>
            </Popup>
        </Marker>
    );
};

export default IdlePointMarker;
