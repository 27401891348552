import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { punchIn, punchOut } from '../../store/actions/attendanceActionCreator';

import { Avatar, Box, Button, IconButton } from '@material-ui/core';
import Modal from './Modal';
import { CustomTextField } from '../Textfield';
import { CustomDatePicker, CustomTimePicker } from '../DateTimePicker';
import { dateTimeToMilliseconds, showMessage } from '../../utils/helper';
import InsertInvitationIcon from '@material-ui/icons/InsertInvitation';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import { FileUploader, LightBoxImage } from '../UI';
import AddImage from '../../assets/images/add-image.png';
import ShiftColumnItemPopper from '../Containers/ShiftColumnItemPopper';
import { HUB_TYPE_ENUM } from '../../utils/constants';

function AddInOutTimeModal({
    isEnabledXUtil,
    open,
    onClose,
    enumType = 'in',
    data = {},
    dispatchPunchIn,
    dispatchPunchOut,
    maxPunchOutTime,
    ...props
}) {
    const [isOpen, toggleModal] = useState(open);
    const [state, setState] = useState({
        // date: moment(),
        date: data.dateEpochMillis ? moment(data.dateEpochMillis) : moment(),
        time: moment(),
        lateFee: '',
        notes: '',
    });
    const [isloading, toggleLoading] = useState(false);
    const [isValidPunch, setIsValidPunch] = useState(true);

    const [wasTimeChanged, setWasTimeChanged] = useState(false);

    const handleClose = () => {
        // Setting form data to null before closing
        setState(null);

        toggleModal(!isOpen);
        onClose && onClose();
    };

    useEffect(() => {
        console.log('data from AddInOutTimeModal', data);
        const { punchIn, punchOut } = data;
        const punch = enumType === 'in' ? punchIn : punchOut;
        setState({
            ...state,
            date: punch?.dateEpochMillis
                ? punch?.dateEpochMillis
                : data.dateEpochMillis
                ? moment(data.dateEpochMillis)
                : moment(),
            time: punch?.dateEpochMillis
                ? moment(punch?.dateEpochMillis)
                : moment(),
            lateFee: punch?.lateBy,
            kmReading: punch?.kmReading,
            kmS3ImageUrl: punch?.kmS3ImageUrl,
            notes: punch?.notes,
            ...punch,
        });
    }, [data]);

    useEffect(() => {
        console.log('state changed', state, data);
        if (state?.date || state?.time) {
            if (enumType === 'out') {
                if (
                    moment(state?.date)?.startOf('day')?.valueOf() ==
                    moment(data?.dateEpochMillis)?.startOf('day')?.valueOf()
                ) {
                    let selectedTime = moment(data?.dateEpochMillis)
                        .hours(moment(state?.time)?.hours())
                        .minutes(moment(state?.time)?.minutes())
                        .seconds(moment(state?.time)?.seconds());

                    console.log(
                        'PICNHHH2',
                        state?.time,
                        data,
                        moment(state?.time)?.valueOf(),
                        data?.punchIn?.dateEpochMillis,
                        moment(selectedTime)?.valueOf(),
                    );
                    if (
                        wasTimeChanged &&
                        moment(selectedTime)?.valueOf() <
                            data?.punchIn?.dateEpochMillis
                    ) {
                        showMessage(
                            'Punch Out Time cannot be Less than Punch In Time',
                            'error',
                        );
                        setIsValidPunch(false);
                        return false;
                    } else {
                        setIsValidPunch(true);
                    }
                } else if (maxPunchOutTime) {
                    let maxTimeFormat = moment(maxPunchOutTime, 'HH:mm:ss');
                    let maxPunchOutDateTime = moment(data?.dateEpochMillis)
                        ?.add(1, 'days')
                        .hours(maxTimeFormat.hours())
                        .minutes(maxTimeFormat.minutes())
                        .seconds(maxTimeFormat.seconds());

                    // console.log(
                    //     'maxPunchOutDateTime',
                    //     state.date,
                    //     data?.dateEpochMillis,
                    //     maxPunchOutDateTime.valueOf(),
                    //     dateTimeToMilliseconds(state.date, state.time),
                    // );
                    if (
                        maxPunchOutDateTime.valueOf() <
                        dateTimeToMilliseconds(state.date, state.time)
                    ) {
                        showMessage(
                            `Max Punch Out Time is ${moment(
                                maxPunchOutDateTime,
                            ).format('HH:mm:ss')}`,
                            'error',
                        );
                        setIsValidPunch(false);
                        return false;
                    } else {
                        setIsValidPunch(true);
                    }
                }
            } else if (enumType === 'in') {
                let selectedTime = moment(data?.dateEpochMillis)
                    .hours(moment(state?.time)?.hours())
                    .minutes(moment(state?.time)?.minutes())
                    .seconds(moment(state?.time)?.seconds());
                if (
                    wasTimeChanged &&
                    data?.punchOut?.dateEpochMillis &&
                    moment(selectedTime)?.valueOf() >
                        data?.punchOut?.dateEpochMillis
                ) {
                    showMessage(
                        'Punch In Time cannot be Greater than Punch Out time',
                        'error',
                    );
                    setIsValidPunch(false);
                    return false;
                } else {
                    setIsValidPunch(true);
                }
            }
            // console.log('timeeeee', data, maxPunchOutTime);
        }
    }, [state]);

    const actions =
        enumType === 'out'
            ? [
                  <Button
                      key={1}
                      variant="contained"
                      color="default"
                      className={`cancelButton`}
                      onClick={handleClose}
                      disableElevation
                  >
                      {'Cancel'}
                  </Button>,
                  <Button
                      key={2}
                      variant="contained"
                      color="default"
                      className={`cancelButton`}
                      disableElevation
                      onClick={async () => {
                          await toggleLoading(true);
                          dispatchPunchOut(
                              {
                                  staffId: data.staffId,
                                  data: {
                                      clearPunchOut: true,
                                      dateEpochMillis: dateTimeToMilliseconds(
                                          state.date,
                                          state.time,
                                      ),
                                  },
                              },
                              async (resp) => {
                                  console.log('response', resp);
                                  await toggleLoading(false);
                                  handleClose();
                              },
                              async (err) => {
                                  console.log('error', err);
                                  await toggleLoading(false);
                              },
                          );
                      }}
                  >
                      {'Clear'}
                  </Button>,
                  <Button
                      key={3}
                      variant="contained"
                      color="primary"
                      className={`saveButton`}
                      disabled={!isValidPunch}
                      disableElevation
                      onClick={async () => {
                          await toggleLoading(true);
                          if (enumType === 'out') {
                              dispatchPunchOut(
                                  {
                                      staffId: data.staffId,
                                      data: {
                                          dateEpochMillis:
                                              dateTimeToMilliseconds(
                                                  state.date,
                                                  state.time,
                                              ),
                                          address: '',
                                          latitude: '',
                                          longitude: '',
                                          kmReading: state?.kmReading,
                                          kmS3ImageUrl: state?.kmS3ImageUrl,
                                          notes: state?.notes,
                                          attendanceDateEpochMillis: moment(
                                              data.dateEpochMillis,
                                          )
                                              .startOf('day')
                                              .valueOf(),
                                      },
                                  },
                                  async () => {
                                      console.log('PunchOut Success');
                                      await toggleLoading(false);
                                      handleClose();
                                  },
                                  async () => {
                                      console.log('PunchOut Failed');
                                      await toggleLoading(false);
                                  },
                              );
                          }
                      }}
                  >
                      {'Add'}
                  </Button>,
              ]
            : [
                  <Button
                      key={1}
                      variant="contained"
                      color="default"
                      className={`cancelButton`}
                      onClick={handleClose}
                      disableElevation
                  >
                      {'Cancel'}
                  </Button>,
                  <Button
                      key={2}
                      variant="contained"
                      color="primary"
                      className={`saveButton`}
                      disabled={!isValidPunch}
                      disableElevation
                      onClick={async () => {
                          await toggleLoading(true);
                          if (enumType === 'in') {
                              dispatchPunchIn(
                                  {
                                      staffId: data.staffId,
                                      data: {
                                          dateEpochMillis:
                                              dateTimeToMilliseconds(
                                                  state.date,
                                                  state.time,
                                              ),
                                          address: '',
                                          latitude: '',
                                          longitude: '',
                                          lateBy: state?.lateFee,
                                          kmReading: state?.kmReading,
                                          kmS3ImageUrl: state?.kmS3ImageUrl,
                                          notes: state?.notes,
                                          attendanceDateEpochMillis: moment(
                                              state?.date,
                                          ).valueOf(),
                                      },
                                  },
                                  async () => {
                                      console.log('PunchIn Success');
                                      await toggleLoading(false);
                                      handleClose();
                                  },
                                  async () => {
                                      console.log('PunchIn Failed');
                                      await toggleLoading(false);
                                  },
                              );
                          }
                      }}
                  >
                      {'Add'}
                  </Button>,
              ];

    const handleInputChange = (value, key) => {
        console.log(key, value);

        setState((state) => ({
            ...state,
            [key]: value,
        }));

        // setStateStatus(null); // formValidator(key, value);
    };

    return (
        <Modal
            onClose={handleClose}
            open={isOpen}
            title={enumType === 'in' ? 'Add In Time' : 'Add Out Time'}
            subtitle={
                !!isEnabledXUtil ? (
                    <Box
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <Box
                            style={{
                                fontFamily: 'Nunito',
                                fontWeight: 'bold',
                                fontSize: '14px',
                            }}
                        >
                            {!!data?.hubShiftMappings?.[0]?.primaryOperation
                                ? HUB_TYPE_ENUM[
                                      data?.hubShiftMappings?.[0]
                                          ?.primaryOperation
                                  ].value
                                : '-'}
                        </Box>
                        <Box
                            style={{
                                marginLeft: 8,
                                marginRight: 2,
                                width: '4px',
                                height: '4px',
                                background: '#14112D',
                                opacity: 0.4,
                                borderRadius: '48px',
                            }}
                        />
                        <ShiftColumnItemPopper data={data} limit={1} />
                    </Box>
                ) : enumType === 'in' ? (
                    'Mark the Punch In time of Staff'
                ) : (
                    'Mark the Punch Out time of Staff'
                )
            }
            actions={actions}
            loading={isloading}
        >
            {/* <CustomDatePicker
                value={state?.date}
                onChange={(e) => handleInputChange(e, 'date')}
            /> */}

            <CustomDatePicker
                value={state?.date}
                onChange={(e) => {
                    setWasTimeChanged(true);
                    handleInputChange(e, 'date');
                }}
                showInput={true}
                InputProps={{
                    endAdornment: (
                        <IconButton>
                            <InsertInvitationIcon size="small" />
                        </IconButton>
                    ),
                }}
                disabled={enumType === 'in'}
                minDate={moment(data?.dateEpochMillis)}
                maxDate={
                    maxPunchOutTime
                        ? moment(data?.dateEpochMillis).add(1, 'days')
                        : moment(data?.dateEpochMillis)
                }
            />
            <CustomTimePicker
                value={state?.time}
                onChange={(e) => {
                    setWasTimeChanged(true);
                    handleInputChange(e, 'time');
                }}
            />

            {enumType === 'in' ? (
                <NumberFormat
                    value={state?.lateFee}
                    format="##:## hrs"
                    label="Late Fee (hrs)"
                    mask="_"
                    customInput={CustomTextField}
                    style={{ marginBottom: '16px' }}
                    onValueChange={(e) => {
                        handleInputChange(e.formattedValue, 'lateFee');
                    }}
                    onBlur={(e) => {
                        handleInputChange(
                            e?.target?.value?.replace(/_/g, '0'),
                            'lateFee',
                        );
                    }}
                />
            ) : // <CustomTextField
            //     fullWidth={true}
            //     label="Late Fee (hrs)"
            //     onChange={(e) =>
            //         handleInputChange(e.target.value, 'lateFee')
            //     }
            //     style={{ marginBottom: '16px' }}
            //     // onBlur={(e) => formValidator('amount', e.target.value)}
            //     // error={stateStatus?.lateFee?.status}
            //     // helperText={stateStatus?.lateFee?.error}
            // />
            null}
            <CustomTextField
                label={
                    enumType === 'in' ? 'Start Km Reading' : 'End Km Reading'
                }
                value={state?.kmReading}
                InputProps={{
                    readOnly: false,
                    type: 'number',
                    inputMode: 'numeric',
                    min: 0,
                    endAdornment: (
                        <FileUploader
                            placeholder={'Upload Km Image'}
                            onFileUploaded={(url) => {
                                console.log(url);
                                handleInputChange(url, 'kmS3ImageUrl');
                            }}
                            defaultValue={state?.kmS3ImageUrl}
                            lightboxConfig={{
                                containerStyles: {
                                    width: 40,
                                    height: 40,
                                    marginRight: 5,
                                },
                                imageStyles: {
                                    width: 40,
                                    height: 40,
                                    margin: 'auto',
                                    borderRadius: '16px',
                                    objectFit: 'contain',
                                },
                            }}
                            render={() => (
                                <Avatar
                                    style={{ borderRadius: 8 }}
                                    alt="capture"
                                    src={AddImage}
                                />
                            )}
                            isRequired={true}
                        />
                    ),
                }}
                onChange={(e) => {
                    if (
                        !e.target.value ||
                        Number(e.target.value) <= 0 ||
                        isNaN(e.target.value)
                    ) {
                        handleInputChange('', 'kmReading');
                        return 0;
                    }
                    handleInputChange(e.target.value, 'kmReading');
                }}
                variant="outlined"
                style={{
                    marginBottom: '16px',
                }}
            />
            <CustomTextField
                fullWidth={true}
                multiline={true}
                label="Notes"
                rows={3}
                value={state?.notes}
                onChange={(e) => handleInputChange(e.target.value, 'notes')}
                style={{ marginBottom: '16px' }}

                // onBlur={(e) => formValidator('notes', e.target.value)}
                // error={stateStatus?.notes?.status}
                // helperText={stateStatus?.notes?.error}
            />
        </Modal>
    );
}

const mapStateToProps = (state) => ({
    maxPunchOutTime: state.attendance?.all?.maxPunchOutTime,
});

const mapDispatchToProps = (dispatch) => ({
    dispatchPunchIn: (data, onSuccess, onError) =>
        dispatch(punchIn(data, onSuccess, onError)),

    dispatchPunchOut: (data, onSuccess, onError) =>
        dispatch(punchOut(data, onSuccess, onError)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddInOutTimeModal);
