import { useState } from 'react';

export const useDebounce = (options = {}) => {
    const { bounceDelay = 2000 } = options;
    const [busy, setbusy] = useState(false);
    const debounce = (callback) => {
        setTimeout(() => {
            setbusy(false);
        }, Number(bounceDelay));

        if (!busy) {
            setbusy(true);
            callback();
        }
    };
    return { debounce, isBouncing: busy };
};
