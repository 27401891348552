import React, { useState } from 'react';
import {
    Box,
    Typography,
    Tooltip,
    Divider,
    Tabs,
    Tab,
    withStyles,
    AppBar,
    makeStyles,
    useTheme,
} from '@material-ui/core';
import {
    BatteryIcon,
    LocationMobileOffIcon,
    LocationMobileOnIcon,
    LocationPermissionOffIcon,
    LocationPermissionOnIcon,
    MobileIcon,
} from '../../../../assets/svg';
import { green } from '@material-ui/core/colors';
import {
    Timeline,
    TimelineItem,
    TimelineSeparator,
    TimelineConnector,
    TimelineContent,
    TimelineDot,
    TimelineOppositeContent,
} from '@material-ui/lab';
import { eventStyleFlag, getTabsProps } from '../helper';
import moment from 'moment';
import StartTimelineItem from './StartTimelineItem';
import EndTimelineItem from './EndTimelineItem';
import IdleTimelineItem from './IdleTimelineItem';
interface MapTimelineProps {
    showCloseButton: boolean;
    trackData: any;
    aggTrackData: any;
    events: any;
    aggUserEvents: any;
    onDotClick: Function;
    havingEV: boolean;
}

const useStyles = makeStyles((theme) => ({
    scrollableBox: {
        width: '100%',
        height: '100%',
        overflowY: 'auto',
        minHeight: '32vh',
        maxHeight: '32vh',
        marginTop: 8,
        '&::-webkit-scrollbar': {
            width: '8px',
        },
        '&::-webkit-scrollbar-track': {
            background: '#f1f1f1',
            borderRadius: '8px',
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#888',
            borderRadius: '8px',
        },
        '&::-webkit-scrollbar-thumb:hover': {
            background: '#555',
        },
    },
}));
const MapTimeline: React.FC<MapTimelineProps> = ({
    showCloseButton = false,
    trackData = {},
    aggTrackData,
    events = {},
    aggUserEvents,
    onDotClick,
    havingEV,
}) => {
    console.log('thee trackData', trackData, events);
    const classes = useStyles();
    const theme = useTheme();
    const startPoint =
        aggTrackData?.locationPoints?.length > 0
            ? trackData?.locationPoints?.[0]
            : null;
    const endPoint =
        trackData?.locationPoints?.length > 0
            ? trackData?.locationPoints?.[trackData?.locationPoints?.length - 1]
            : null;
    return (
        <Box
            className={classes.scrollableBox}
            style={
                !havingEV
                    ? {
                          minHeight: '47vh',
                          maxHeight: '47vh',
                      }
                    : {}
            }
        >
            {trackData?.locationPoints?.length > 0 ? (
                <>
                    <Timeline
                        align="left"
                        style={{
                            padding: 0,
                        }}
                    >
                        <StartTimelineItem
                            onClick={onDotClick}
                            locationPoint={startPoint}
                            selectedPoint={{
                                selectedStartStoppagePoint:
                                    aggTrackData?.selectedStartStoppagePoint,
                                selectedEndStoppagePoint:
                                    aggTrackData?.selectedEndStoppagePoint,
                            }}
                            events={events}
                            aggUserEvents={aggUserEvents}
                            startAddress={trackData?.startAddress}
                            stoppageReportData={trackData?.stoppageReportData}
                        />
                        <IdleTimelineItem
                            onClick={onDotClick}
                            startPoint={startPoint}
                            endPoint={endPoint}
                            selectedPoint={{
                                selectedStartStoppagePoint:
                                    aggTrackData?.selectedStartStoppagePoint,
                                selectedEndStoppagePoint:
                                    aggTrackData?.selectedEndStoppagePoint,
                            }}
                            events={events}
                            aggUserEvents={aggUserEvents}
                            endAddress={trackData?.endAddress}
                            stoppageReportData={trackData?.stoppageReportData}
                        />
                        <EndTimelineItem
                            onClick={onDotClick}
                            startPoint={startPoint}
                            endPoint={endPoint}
                            selectedPoint={{
                                selectedStartStoppagePoint:
                                    aggTrackData?.selectedStartStoppagePoint,
                                selectedEndStoppagePoint:
                                    aggTrackData?.selectedEndStoppagePoint,
                            }}
                            events={events}
                            aggUserEvents={aggUserEvents}
                            endAddress={trackData?.endAddress}
                            stoppageReportData={trackData?.stoppageReportData}
                        />
                    </Timeline>
                </>
            ) : (
                <>
                    {events?.length > 0 && (
                        <Timeline
                            align="left"
                            style={{
                                padding: 0,
                            }}
                        >
                            {events?.map((event: any) => {
                                return (
                                    <Box
                                        style={{
                                            background: eventStyleFlag(event)
                                                ? '#fef7f6'
                                                : '#f2fdf6',
                                            padding: 6,
                                            marginTop: 2,
                                            marginBottom: 2,
                                            borderRadius: 8,
                                        }}
                                    >
                                        <Box
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'space-between',
                                            }}
                                        >
                                            <Typography
                                                style={{
                                                    fontSize: 15,
                                                    fontWeight: 600,
                                                    color: eventStyleFlag(event)
                                                        ? '#e74c3c'
                                                        : '#1bb55c',
                                                }}
                                            >
                                                {event?.description}
                                            </Typography>
                                            <Typography
                                                style={{
                                                    fontSize: 12,
                                                    fontWeight: 700,
                                                }}
                                            >
                                                {moment(
                                                    parseInt(event?.time),
                                                ).format('hh:mm A')}{' '}
                                            </Typography>
                                        </Box>
                                    </Box>
                                );
                            })}
                        </Timeline>
                    )}
                </>
            )}
        </Box>
    );
};

export default MapTimeline;
