import { createMuiTheme } from '@material-ui/core/styles';
import '@fontsource/nunito/300.css';
import '@fontsource/nunito/400.css';
import '@fontsource/nunito/600.css';
import '@fontsource/nunito/700.css';
import '@fontsource/nunito/800.css';

const theme = createMuiTheme({
    palette: {
        common: {
            black: 'rgba(0, 0, 0, 1)',
            white: 'rgba(255, 255, 255, 1)',
        },
        background: {
            paper: 'rgba(255, 255, 255, 1)',
            default: 'rgba(241, 243, 246, 1)',
        },
        primary: {
            light: '#7986cb',
            main: '#43419e',
            dark: '#3c3a8b',
            default: '#fff',
            contrastText: 'rgba(255, 255, 255, 1)',
        },
        secondary: {
            main: '#e8e8ee',
            dark: 'rgba(232, 232, 238, 0.8)',
            default: '#43419e',
            contrastText: '#43419e',
        },
        error: {
            light: '#e57373',
            main: '#f44336',
            dark: '#d32f2f',
            contrastText: '#fff',
        },
        text: {
            primary: '#43419e',
            error: '#f44336',
            secondary: '#515f6d',
            disabled: 'rgba(0, 0, 0, 0.38)',
            hint: 'rgba(0, 0, 0, 0.38)',
        },
    },
    typography: {
        fontFamily: ['Nunito'],
        allVariants: { color: '#14112d' },
    },
});
export default theme;
