// MyComponent.tsx
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from '../../../components/UI';
import {
    Box,
    Button,
    Divider,
    Grid,
    IconButton,
    ListItemIcon,
    makeStyles,
    Paper,
    Tooltip,
    Typography,
    Avatar,
} from '@material-ui/core';
import MapTimelineTabs from './components/MapTimelineTabs';
import L, { Polyline, useMap, Marker } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import '../styles.scss';
import useRiderMapStyles from './useRiderMapStyles';
import Map from '../../../components/Map';
import useRiderMapViewModal from './useRiderMapViewModal';
import Header from './components/Header';
import DeviceInfo from './components/DeviceInfo';
import EVInfo from './components/EVInfo';
import DistanceInfo from './components/DistanceInfo';
import PlayBackControl from './components/PlayBackControl';
import { getBounds, getPolyLineMarkerData } from './helper';
import StartPointMarker from './components/StartPointMarker';
import EndPointMarker from './components/EndPointMarker';
import IdlePointMarkerCluster from './components/IdlePointMarkerCluster';
import useRiderMapPlayBackControl from './useRiderMapPlayBackControl';
import TrackingMarker from './components/TrackingMarker';
import TimerCard from './components/TimerCard';
import MapResetButton from './components/MapResetButton';
interface RiderMapViewProps {
    [key: string]: any;
}
const RiderMapView: React.FC<RiderMapViewProps> = ({
    heliosTrackingData = [],
    staffTrackAndTrace,
    singleEVDetails,
    ...props
}) => {
    //global
    const activeControlIndex = 0;
    const staffId = props?.match?.params?.id
        ? props?.match?.params?.id
        : staffTrackAndTrace?.id;
    const singleEVMapDetails = singleEVDetails ?? null;
    const backEnabled = !!props?.match?.params?.id ?? false;
    const [containerHeight, setContainerHeight] = useState(0);
    const containerRef = React.useRef<HTMLElement>(null);
    const classes = useRiderMapStyles({
        containerHeight: containerHeight,
    });
    const {
        staffInformation,
        loading,
        selectedDate,
        setSelectedDate,
        routes,
        aggRoutes,
        routesPlaybackData,
        setEnableSetBounds,
        enableSetBounds,
        aggUserEvents,
        showCloseButton,
        stepperSelectorHandler,
        clearSelection,
        fetchData,
    } = useRiderMapViewModal({ staffId });
    const deviceDetails = {
        deviceBrand: staffInformation?.deviceBrand,
        appVersion: staffInformation?.appVersion,
        deviceModel: staffInformation?.deviceModel,
        locationPermission: staffInformation?.locationPermission,
        locationService: staffInformation?.locationService,
    };
    const havingEV = staffInformation?.havingEV ?? false;

    const evDetails = {
        ...staffInformation?.eventDetails?.evMapViewInfo,
    };
    const deviceBeeconId = staffInformation?.beeconVehicleId;
    const evBeeconId = `profile_vehicle_${staffInformation?.eventDetails?.evMapViewInfo?.beeconVehicleImei?.toUpperCase()}`;
    const latestDeviceTrackingData =
        !!deviceBeeconId &&
        !!staffInformation?.helixVehicleDetails?.[deviceBeeconId]
            ? staffInformation?.helixVehicleDetails?.[deviceBeeconId]
            : {};
    const latestEVTrackingData =
        !!evBeeconId && !!staffInformation?.helixVehicleDetails?.[evBeeconId]
            ? staffInformation?.helixVehicleDetails?.[evBeeconId]
            : {};
    useEffect(() => {
        if (!!containerRef?.current?.offsetHeight && !containerHeight) {
            const height = containerRef.current.offsetHeight;
            setContainerHeight(height);
        }
    }, [containerRef?.current?.offsetHeight]);
    const filteredRoutes = routesPlaybackData?.filter(
        (ar: any) => ar?.coordinates?.length > 1,
    );
    const {
        currentIndices,
        playing,
        enablePlayBackControl,
        handlePlayBack,
        setCurrentIndices,
        handlePause,
        handlePlay,
        handleSpeedChange,
        handleSliderChange,
        handleReset,
        setPlaying,
        speed,
        setSpeed,
        setEnablePlayBackControl,
        handleStop,
        handleForward,
        handleRewind,
    } = useRiderMapPlayBackControl({
        aggRoutes: filteredRoutes,
    });
    console.log('Thee staffInformation', currentIndices);
    function SetBounds({ latLongPoints }: any) {
        const map = useMap();
        if (latLongPoints && latLongPoints.length > 0) {
            map.fitBounds(latLongPoints);
        }
        setEnableSetBounds(false);
        return null;
    }
    return (
        <Spinner loading={loading}>
            <Paper
                ref={containerRef}
                style={{ padding: '.1rem' }}
                className={classes.container}
                elevation={0}
            >
                <Grid container spacing={1}>
                    <Grid item md={4} xs={12}>
                        <Box
                            className={classes.routeContainer}
                            style={
                                showCloseButton
                                    ? { opacity: 0.4, pointerEvents: 'none' }
                                    : {}
                            }
                        >
                            <Header
                                backEnabled={backEnabled}
                                staffName={staffInformation?.name}
                                selectedDate={selectedDate}
                                onSelectDate={(value: any) => {
                                    handleReset();
                                    setSelectedDate(value);
                                }}
                                contactNumber={
                                    staffInformation?.contactNumber ?? ''
                                }
                                hubName={staffInformation?.hubName ?? ''}
                                vehicleNumber={
                                    staffInformation?.vehicleNumber ?? ''
                                }
                                singleEVMapDetails={singleEVMapDetails}
                            />
                            {!!staffInformation && (
                                <>
                                    <DeviceInfo
                                        device={deviceDetails}
                                        tracking={latestDeviceTrackingData}
                                    />
                                    <Divider style={{ margin: '12px 0' }} />
                                </>
                            )}
                            {!!evDetails?.evNumber && (
                                <>
                                    <EVInfo
                                        tracking={latestEVTrackingData}
                                        evInfo={evDetails}
                                    />
                                    <Divider style={{ margin: '12px 0' }} />
                                </>
                            )}
                            {
                                <>
                                    <DistanceInfo
                                        tracking={
                                            staffInformation?.trackingDetails
                                        }
                                        evTracking={
                                            staffInformation?.evTrackingDetails
                                        }
                                        events={staffInformation?.eventDetails}
                                        havingEV={havingEV}
                                        playComponent={
                                            <React.Fragment>
                                                {!havingEV &&
                                                    !!aggRoutes?.filter(
                                                        (rte: any) =>
                                                            rte?.coordinates
                                                                ?.length > 1,
                                                    )?.length && (
                                                        <PlayBackControl
                                                            enableControl={
                                                                enablePlayBackControl
                                                            }
                                                            onPlayBackClick={
                                                                handlePlayBack
                                                            }
                                                            playing={playing}
                                                            onPlayPress={
                                                                handlePlay
                                                            }
                                                            onPausePress={
                                                                handlePause
                                                            }
                                                            onForwardPress={
                                                                handleForward
                                                            }
                                                            onRewindPress={
                                                                handleRewind
                                                            }
                                                            onRestartPress={() => {}}
                                                            onSpeedChange={
                                                                handleSpeedChange
                                                            }
                                                            onSliderChange={
                                                                handleSliderChange
                                                            }
                                                            onStopPress={
                                                                handleStop
                                                            }
                                                            speed={speed}
                                                            routes={
                                                                filteredRoutes
                                                            }
                                                            currentIndexes={
                                                                currentIndices
                                                            }
                                                        />
                                                    )}
                                            </React.Fragment>
                                        }
                                    />
                                    {!!havingEV &&
                                        !!aggRoutes?.filter(
                                            (rte: any) =>
                                                rte?.coordinates?.length > 1,
                                        )?.length && (
                                            <PlayBackControl
                                                enableControl={
                                                    enablePlayBackControl
                                                }
                                                onPlayBackClick={handlePlayBack}
                                                playing={playing}
                                                onPlayPress={handlePlay}
                                                onPausePress={handlePause}
                                                onForwardPress={handleForward}
                                                onRewindPress={handleRewind}
                                                onRestartPress={() => {}}
                                                onSpeedChange={
                                                    handleSpeedChange
                                                }
                                                onSliderChange={
                                                    handleSliderChange
                                                }
                                                onStopPress={handleStop}
                                                speed={speed}
                                                routes={filteredRoutes}
                                                currentIndexes={currentIndices}
                                            />
                                        )}
                                </>
                            }
                        </Box>
                        <Divider style={{ margin: 0 }} />
                        <MapTimelineTabs
                            showCloseButton={showCloseButton}
                            routes={routes}
                            events={staffInformation?.eventDetails}
                            aggUserEvents={aggUserEvents}
                            onDotClick={(point: any, index: number) => {
                                handleReset();
                                stepperSelectorHandler(point, index);
                            }}
                            onCloseClick={clearSelection}
                            aggRoutes={aggRoutes}
                            havingEV={havingEV}
                        />
                    </Grid>
                    <Grid
                        item
                        md={8}
                        xs={12}
                        style={
                            showCloseButton
                                ? { opacity: 0.4, pointerEvents: 'none' }
                                : {}
                        }
                    >
                        <Map
                            position={[13.034259, 80.24893]}
                            center={[13.034259, 80.24893]}
                            zoom={20}
                            scrollWheelZoom={true}
                        >
                            {!!enablePlayBackControl &&
                                routesPlaybackData.map(
                                    (route: any, i: number) => {
                                        const polyLineData =
                                            route?.coordinates?.slice(
                                                0,
                                                currentIndices?.[i],
                                            );
                                        const markerPos =
                                            route?.playBackData?.[
                                                currentIndices?.[i]
                                            ];
                                        return !!currentIndices?.[i] &&
                                            !!polyLineData &&
                                            !!markerPos ? (
                                            <React.Fragment
                                                key={`playback${i}`}
                                            >
                                                <Polyline
                                                    positions={polyLineData}
                                                    pathOptions={{
                                                        color:
                                                            route?.ui
                                                                ?.polyLineColor ??
                                                            'red',
                                                        weight:
                                                            route?.ui
                                                                ?.polyLineWeight ??
                                                            2,
                                                    }}
                                                />
                                                <TrackingMarker
                                                    label={route?.markerLabel}
                                                    playbackPosition={markerPos}
                                                ></TrackingMarker>
                                            </React.Fragment>
                                        ) : (
                                            <></>
                                        );
                                    },
                                )}
                            {!!enablePlayBackControl &&
                                !!routesPlaybackData?.length && (
                                    <TimerCard
                                        routes={routesPlaybackData}
                                        currentIndices={currentIndices}
                                    />
                                )}
                            {!!aggRoutes?.length &&
                                aggRoutes?.map((route: any, i: number) => {
                                    return !!route?.coordinates?.length ? (
                                        <>
                                            <Polyline
                                                positions={route?.coordinates}
                                                pathOptions={{
                                                    color:
                                                        route?.polyLineColor ??
                                                        'black',
                                                    weight:
                                                        route?.polyLineWeight ??
                                                        2,
                                                }}
                                            />
                                            <StartPointMarker
                                                trackData={routes?.[i]}
                                                aggregatedTrackData={route}
                                                events={
                                                    staffInformation?.eventDetails
                                                }
                                            />
                                            <EndPointMarker
                                                trackData={routes?.[i]}
                                                aggregatedTrackData={route}
                                                events={
                                                    staffInformation?.eventDetails
                                                }
                                            />
                                            <IdlePointMarkerCluster
                                                aggregatedTrackData={route}
                                                events={
                                                    staffInformation?.eventDetails
                                                }
                                            />
                                        </>
                                    ) : null;
                                })}

                            {!!enableSetBounds && (
                                <SetBounds
                                    latLongPoints={getBounds(aggRoutes)}
                                />
                            )}
                            <MapResetButton
                                text={'&#x21BB;'}
                                onPress={() => {
                                    fetchData();
                                }}
                            />
                        </Map>
                    </Grid>
                </Grid>
            </Paper>
        </Spinner>
    );
};

export default RiderMapView;
