import React, { useState, useEffect } from 'react';
import { Button, Paper, Box, Typography, makeStyles } from '@material-ui/core';
import AddEditStaffModal from '../Modals/AddEditStaffModal';
import { connect } from 'react-redux';
import {
    NewStaffEmptyState,
    InvoiceEmptyState,
    PayslipEmptyState,
} from '../../assets/svg';
import { AttendanceEmptyState } from '../../assets/svg';
import { MapViewEmptyState } from '../../assets/svg';
import { ReportsEmptyState } from '../../assets/svg';
import { getAllStaffs } from '../../store/actions/staffActionCreator';
import { getAllAttendanceData } from '../../store/actions/attendanceActionCreator';
import { GetDasboardReport } from '../../store/actions/reportActionCreator';

import moment from 'moment';
import GeneratePayslipModal from '../Modals/GeneratePayslipModal';

const EmptyStates = ({
    staff,
    type,
    header1,
    header2,
    subheader1,
    subheader2,
    buttonDisplay,
    buttonText,
    getAllStaffs,
    dispatchGetAllAttendanceData,
    dispatchGetDasboardReport,
    getAllStaffPayslip,
    staffInfo,
    buttonStyles,
    ...props
}) => {
    const [staffModalOpen, setStaffModalOpen] = useState(false);
    const [generatePayslipModal, setGeneratePayslipModalOpen] = useState(false);
    const [loading, setLoading] = useState(true);

    const getAllStaffInfo = async (payload = {}) => {
        await setLoading(true);
        getAllStaffs(
            payload,
            (resp) => {
                setLoading(false);
                console.log('staff data', resp);
            },
            (resp) => {
                setLoading(false);
                console.log('staff data err', resp);
            },
        );
    };

    return (
        <Box
            style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '40px',
            }}
        >
            {type === 'Staff' ? (
                <NewStaffEmptyState />
            ) : type === 'Attendance' ? (
                <AttendanceEmptyState />
            ) : type === 'MapView' ? (
                <MapViewEmptyState />
            ) : type === 'Reports' ? (
                <ReportsEmptyState />
            ) : type === 'Payslip' ? (
                staff?.singleStaffInfo?.freelancer ? (
                    <InvoiceEmptyState />
                ) : (
                    <PayslipEmptyState />
                )
            ) : (
                <></>
            )}
            {header1 ? (
                <Typography
                    style={{
                        fontSize: '24px',
                        fontWeight: 700,
                        marginTop: '16px',
                        textAlign: 'center',
                    }}
                >
                    {header1}
                </Typography>
            ) : (
                <></>
            )}
            {header2 ? (
                <Typography
                    style={{
                        fontSize: '24px',
                        fontWeight: 700,
                        textAlign: 'center',
                    }}
                >
                    {header2}
                </Typography>
            ) : (
                <></>
            )}
            {subheader1 ? (
                <Typography
                    style={{
                        fontSize: '14px',
                        marginTop: '14px',
                    }}
                >
                    {subheader1}
                </Typography>
            ) : (
                <></>
            )}
            {buttonDisplay ? (
                type === 'Staff' ? (
                    <Button
                        variant="contained"
                        color="primary"
                        className="btn-pos-end"
                        onClick={() => {
                            // toggleStaffModal();
                            setStaffModalOpen(true);
                        }}
                        style={{
                            width: '15%',
                            fontSize: '18px',
                            fontWeight: 600,
                            marginTop: '24px',
                        }}
                        disableElevation
                    >
                        {buttonText ? buttonText : '+ Add Staff'}
                    </Button>
                ) : type === 'Payslip' ? (
                    <Button
                        variant="contained"
                        color="primary"
                        className="btn-pos-end"
                        onClick={() => {
                            // toggleStaffModal();
                            setGeneratePayslipModalOpen(true);
                        }}
                        style={{
                            width: '15%',
                            fontSize: '18px',
                            fontWeight: 600,
                            marginTop: '24px',
                        }}
                        disableElevation
                    >
                        {buttonText
                            ? buttonText
                            : `+ Generate ${
                                  staff?.singleStaffInfo?.freelancer
                                      ? 'Invoice'
                                      : 'Payslip'
                              }`}
                    </Button>
                ) : (
                    <></>
                )
            ) : (
                <></>
            )}
            {staffModalOpen && (
                <AddEditStaffModal
                    open={staffModalOpen}
                    onClose={() => {
                        console.log('Closed');
                        setStaffModalOpen(false);
                        getAllStaffInfo({
                            filterStatus: '',
                            pageNo: 1,
                            pageSize: 10,
                            searchText: '',
                        });

                        dispatchGetAllAttendanceData(
                            { date: moment().format('YYYYMMDD'), data: {} },
                            () => {
                                console.log('getAllAttendanceData SUCCESS');
                            },
                            () => {
                                console.log('getAllAttendanceData FAILED');
                            },
                        );

                        dispatchGetDasboardReport(
                            {
                                dateRange: {
                                    from: moment().startOf('M').valueOf(),
                                    to: moment().endOf('M').valueOf(),
                                },
                            },
                            (resp) => {
                                console.log('getDashboardReport SUCCESS');
                            },
                            (err) => {
                                console.log('getDashboardReport FAILED');
                            },
                        );
                    }}
                />
            )}
            {generatePayslipModal && (
                <GeneratePayslipModal
                    open={generatePayslipModal}
                    staffInfo={staffInfo}
                    onClose={() => {
                        // console.log('Closed');
                        getAllStaffPayslip();
                        setGeneratePayslipModalOpen(false);
                    }}
                />
            )}
        </Box>
    );
};

const mapStateToProps = (state) => ({
    staff: state.staff,
});

const mapDispatchToProps = (dispatch) => ({
    getAllStaffs: (payload, onSuccess, onError) =>
        dispatch(getAllStaffs(payload, onSuccess, onError)),

    dispatchGetAllAttendanceData: (payload, onSuccess, onError) =>
        dispatch(getAllAttendanceData(payload, onSuccess, onError)),

    dispatchGetDasboardReport: (payload, onSuccess, onError) =>
        dispatch(GetDasboardReport(payload, onSuccess, onError)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EmptyStates);
