import React, { useEffect, useRef, useState } from 'react';
import useSingleEVStyles from './useSingleEVStyles';
import Avatar from '@material-ui/core/Avatar';
import { Box, Typography } from '@material-ui/core';
import { CHIPS_CLASS_TYPE_MAPPING } from '../helper';
import clsx from 'clsx';
import useEVStyles from '../useEVStyles';
import moment from 'moment';
interface useSingleEVTableReturn {
    getColumnComponentValue: Function;
}
const useSingleEVTable = ({
    filteredValues,
    history,
}: any): useSingleEVTableReturn => {
    const classes: any = useSingleEVStyles();
    const evClasses: any = useEVStyles();
    const getColumnComponentValue = (
        row: any,
        column: any,
        handleAction: any,
        index: number,
    ) => {
        let compValue: any = row?.[column?.valueKey] ?? '-/-';
        const orderStatus: any = row?.orderStatus
            ?.toUpperCase()
            ?.replace(' ', '_')
            ?.replace('-', '_')
            ?.trim();
        const evStatus: any = row?.evStatus
            ?.toUpperCase()
            ?.replace(' ', '_')
            ?.replace('-', '_')
            ?.trim();

        let chipEVClass: any =
            evClasses?.[`${CHIPS_CLASS_TYPE_MAPPING?.[evStatus]}`];
        let chipOrderClass: any =
            evClasses?.[`${CHIPS_CLASS_TYPE_MAPPING?.[orderStatus]}`];
        switch (column?.mappingKey) {
            case 'prevRider':
                compValue = (
                    <Box
                        display={'flex'}
                        flexDirection={'row'}
                        alignItems={'center'}
                        justifyContent={'flex-start'}
                    >
                        <Avatar className={classes.nameAvatar}>
                            {row?.riderName?.[0]}
                        </Avatar>
                        <Box ml={2}>
                            <Typography className={classes.tableValue}>
                                {row?.riderName ?? '-'}
                            </Typography>
                        </Box>
                    </Box>
                );
                break;
            case 'curRider':
                compValue = (
                    <Box
                        display={'flex'}
                        flexDirection={'row'}
                        alignItems={'center'}
                        justifyContent={'flex-start'}
                    >
                        <Typography className={classes.tableValue}>
                            {row?.riderName ?? '-'}
                        </Typography>
                    </Box>
                );
                break;
            case 'phoneNumber':
                compValue = (
                    <Box
                        display={'flex'}
                        flexDirection={'row'}
                        alignItems={'center'}
                        justifyContent={'flex-start'}
                    >
                        <Box>
                            <Typography className={classes.tableValue}>
                                {row?.riderContactNumber ?? '-'}
                            </Typography>
                        </Box>
                    </Box>
                );
                break;
            case 'captain':
                compValue = (
                    <Box
                        display={'flex'}
                        flexDirection={'row'}
                        alignItems={'center'}
                        justifyContent={'flex-start'}
                    >
                        <Box>
                            <Typography className={classes.tableValue}>
                                {row?.captain ?? '-'}
                            </Typography>
                        </Box>
                    </Box>
                );
                break;
            case 'manager':
                compValue = (
                    <Box
                        display={'flex'}
                        flexDirection={'row'}
                        alignItems={'center'}
                        justifyContent={'flex-start'}
                    >
                        <Box>
                            <Typography className={classes.tableValue}>
                                {row?.manager ?? '-'}
                            </Typography>
                        </Box>
                    </Box>
                );
                break;
            case 'taggedOn':
                compValue = (
                    <Box
                        display={'flex'}
                        flexDirection={'row'}
                        alignItems={'center'}
                        justifyContent={'flex-start'}
                    >
                        <Box>
                            <Typography className={classes.tableValue}>
                                {row && row.rentedDateEpochMillis
                                    ? moment(row.rentedDateEpochMillis).format(
                                          'Do MMMM YYYY',
                                      )
                                    : '--'}
                            </Typography>
                        </Box>
                    </Box>
                );
                break;
            case 'unTaggedOn':
                compValue = (
                    <Box
                        display={'flex'}
                        flexDirection={'row'}
                        alignItems={'center'}
                        justifyContent={'flex-start'}
                    >
                        <Box>
                            <Typography className={classes.tableValue}>
                                {row && row.returnedDateEpochMillis
                                    ? moment(
                                          row.returnedDateEpochMillis,
                                      ).format('Do MMMM YYYY')
                                    : '--'}
                            </Typography>
                        </Box>
                    </Box>
                );
                break;
            case 'orderStatus':
                compValue = !!row?.orderStatus ? (
                    <Box
                        display={'flex'}
                        flexDirection={'column'}
                        alignItems={'flex-start'}
                        justifyContent={'flex-start'}
                    >
                        <Box className={clsx([evClasses.chip, chipOrderClass])}>
                            <Typography
                                component={'span'}
                                className={evClasses.chipText}
                            >
                                {row?.orderStatus ?? '-'}
                            </Typography>
                        </Box>
                    </Box>
                ) : (
                    '--'
                );
                break;

            default:
                break;
        }
        return compValue;
    };
    return {
        getColumnComponentValue,
    };
};
export default useSingleEVTable;
