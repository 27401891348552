import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import AttendanceModal from './AttendanceModal';
const useRowStyles = makeStyles({
    root: {
        border: '1px solid #ebedf8',
        '& td': {
            fontSize: 16,
            fontWeight: 600,
            color: '#000000',
        },
    },
    // dailyAttendanceRow: {
    //     '& > .MuiTableCell-body': {
    //         '&:nth-child(odd)': {
    //             backgroundColor: '#f2f4f8',
    //         },
    //     },
    // },
});

function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);
    const classes = useRowStyles();
    console.log('row', row);

    const openModal = (row) => {
        props.setAttendanceModalData({
            isOpen: false,
            selectedRow: {
                ...row,
            },
        });
    };
    return (
        <React.Fragment>
            <TableRow
                className={classes.root}
                style={
                    row.index % 2
                        ? {
                              backgroundColor: '#f2f4f8',
                              cursor: 'pointer',
                          }
                        : { cursor: 'pointer' }
                }
            >
                <TableCell
                    style={{
                        maxWidth: '1vw',
                        width: '3%',
                    }}
                >
                    <IconButton size="small" onClick={() => setOpen(!open)}>
                        {open ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
                    </IconButton>
                </TableCell>
                <TableCell
                    onClick={(e) => openModal(row)}
                    style={{ width: '10%' }}
                >
                    {row.name}
                </TableCell>
                <TableCell
                    onClick={(e) => openModal(row)}
                    style={{ width: '5%' }}
                >
                    {row.hubName ?? 'N/A'}
                </TableCell>
                <TableCell
                    onClick={(e) => openModal(row)}
                    style={{ width: '10%' }}
                >
                    {row.contactNumber ?? '-'}
                </TableCell>
                <TableCell
                    onClick={(e) => openModal(row)}
                    style={{ width: '5%', color: '#37773d' }}
                    align="center"
                >
                    {row.presentText ?? 0}
                </TableCell>
                <TableCell
                    onClick={(e) => openModal(row)}
                    style={{ width: '5%', color: '#e74c3c' }}
                    align="center"
                >
                    {row.absentText ?? 0}
                </TableCell>
                <TableCell
                    onClick={(e) => openModal(row)}
                    style={{ width: '5%', color: '#ff9800' }}
                    align="center"
                >
                    {row.weekOffText ?? 0}
                </TableCell>
                <TableCell
                    onClick={(e) => openModal(row)}
                    style={{ width: '5%', color: '#e74c3c' }}
                    align="center"
                ></TableCell>
                <TableCell
                    onClick={(e) => openModal(row)}
                    style={{ width: '5%', color: '#e74c3c' }}
                    align="center"
                ></TableCell>
                <TableCell
                    onClick={(e) => openModal(row)}
                    style={{ width: '5%', color: '#e74c3c' }}
                    align="center"
                ></TableCell>
                <TableCell
                    onClick={(e) => openModal(row)}
                    style={{ width: '5%', color: '#e74c3c' }}
                    align="center"
                ></TableCell>
            </TableRow>
            <TableRow>
                <TableCell
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                    colSpan={12}
                >
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box
                            style={{
                                width: window.screen.width * 0.87,
                                overflow: 'scroll',
                            }}
                        >
                            <Table
                                size="small"
                                aria-label="purchases"
                                style={
                                    row.index % 2
                                        ? {
                                              backgroundColor: '#f2f4f8',
                                          }
                                        : {}
                                }
                            >
                                <TableBody>
                                    <TableRow>
                                        <TableCell
                                            align="center"
                                            component="th"
                                            scope="row"
                                            style={{
                                                boxShadow:
                                                    'inset 1px 0 0 0 #ebedf8',
                                                fontSize: 12,
                                                fontFamily: 600,
                                                color: '#000000',
                                            }}
                                        >
                                            Day
                                        </TableCell>
                                        {row.days.map((historyRow, index) => (
                                            <TableCell
                                                component="th"
                                                scope="row"
                                                key={index}
                                                align="center"
                                                style={{
                                                    boxShadow:
                                                        'inset 1px 0 0 0 #ebedf8',
                                                    fontSize: 12,
                                                    fontFamily: 600,
                                                    color: '#000000',
                                                }}
                                            >
                                                {historyRow.weekDay}
                                                <br />
                                                {historyRow.dateOfMonth}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                    <TableRow>
                                        <TableCell
                                            align="center"
                                            component="th"
                                            scope="row"
                                            style={{
                                                boxShadow:
                                                    'inset 1px 0 0 0 #ebedf8',
                                                fontSize: 14,
                                                fontWeight: 'bold',
                                                color: '#000000',
                                            }}
                                        >
                                            <span style={{ color: '#50ac59' }}>
                                                P
                                            </span>
                                            /
                                            <span style={{ color: '#e74c3c' }}>
                                                A
                                            </span>
                                        </TableCell>
                                        {row.days.map((historyRow, index) => (
                                            <TableCell
                                                align="center"
                                                key={index}
                                                style={{
                                                    boxShadow:
                                                        'inset -1px 0 0 0 #ebedf8',
                                                    maxWidth: '1px',
                                                    fontSize: 14,
                                                    fontWeight: 'bold',
                                                    color:
                                                        historyRow.attendance ===
                                                        'A'
                                                            ? '#e74c3c'
                                                            : historyRow.attendance ===
                                                        'W' ? '#ff9800' 
                                                            :historyRow.attendance ===
                                                        'NA' ?  '#e74c3c' :'#50ac59',
                                                }}
                                            >
                                                {historyRow.attendance}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

const useStyles = makeStyles({
    tableContainer: {
        border: '1px solid #ebedf8',
        borderRadius: 5,
    },
    table: {
        minWidth: 700,
    },

    tableHeaderText: {
        fontWeight: 'bold',
    },
    tableHeader: {
        background: '#f2f4f8',
    },
});

export default function AttendanceListContainer({ rows, ...props }) {
    const classes = useStyles();
    const [attendanceModalData, setAttendanceModalData] = useState({
        isOpen: false,
        selectedRow: {},
    });

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    React.useEffect(() => {
        setPage(0);
    }, [rows]);

    return (
        <>
            <Box
                style={{
                    width: '100%',
                }}
            >
                <TableContainer
                    className={classes.tableContainer}
                    component={Box}
                >
                    <Table size="small" aria-label="collapsible table">
                        <TableBody>
                            {rows.map((value, index) => {
                                return (
                                    <Row
                                        key={index}
                                        row={{
                                            ...value,
                                            index,
                                        }}
                                        setAttendanceModalData={
                                            setAttendanceModalData
                                        }
                                    />
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                {attendanceModalData.isOpen && (
                    <AttendanceModal
                        open={attendanceModalData.isOpen}
                        staffInfo={attendanceModalData.selectedRow}
                        onClose={() => {
                            setAttendanceModalData({
                                isOpen: false,
                                selectedRow: {},
                            });
                        }}
                    />
                )}
                {/* <TablePagination
                    rowsPerPageOptions={[10, 50, 100]}
                    component="div"
                    count={rows?.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                /> */}
            </Box>
        </>
    );
}
