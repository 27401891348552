import { Box, Button, makeStyles, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import PDFViewer from '../UI/PDFViewer';
import Modal from './Modal';

const useStyles = makeStyles((theme) => ({
    'pdf-view': {
        '& .MuiDialog-paperScrollPaper': {
            backgroundColor: 'transparent',
        },
        '& .modal-title': {
            color: '#fff',
            textAlign: 'center',
        },
        '& .close-icon': {
            color: '#fff',
        },
        '& .MuiDialogTitle-root': {
            padding: '0 16px !important',
        },
        '& .MuiDialogContent-root': {
            padding: '0 !important',
        },
    },
}));

function PDFViewerModal({ open, onClose, data, ...props }) {
    const classes = useStyles();

    const [isOpen, toggleModal] = useState(open);
    const handleClose = () => {
        // Setting form data to null before closing

        toggleModal(!isOpen);
        onClose && onClose();
    };
    return (
        <Modal
            onClose={handleClose}
            open={isOpen}
            fullScreen={true}
            className={classes['pdf-view']}
        >
            <Box
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <PDFViewer file={data?.docLink} />
            </Box>
        </Modal>
    );
}

export default PDFViewerModal;
