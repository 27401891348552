import { Box, Typography } from '@material-ui/core';
import moment from 'moment';
import React from 'react';

export default function EventCard({ event }) {
    function eventStyleFlag(event) {
        if (
            event?.name?.includes('BATTERY_OPTIMIZATION_ON') ||
            event?.name?.includes('POWER_SAVE_MODE_ON') ||
            event?.name?.includes('LOCATION_PERMISSION_OFF') ||
            event?.name?.includes('DEVICE_LOCATION_OFF') ||
            event?.description?.toLowerCase()?.includes('terminated')
        )
            return true;
        else return false;
    }

    return (
        <Box
            elevation={2}
            style={{
                background: eventStyleFlag(event) ? '#fef7f6' : '#f2fdf6',
                padding: 6,
                marginTop: 2,
                marginBottom: 2,
                borderRadius: 8,
            }}
        >
            <Box
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                }}
            >
                <Typography
                    style={{
                        fontSize: 15,
                        fontWeight: 600,
                        color: eventStyleFlag(event) ? '#e74c3c' : '#1bb55c',
                    }}
                >
                    {event?.description}
                </Typography>
                <Typography
                    style={{
                        fontSize: 12,
                        fontWeight: 700,
                        // margin: '4px 0',
                        // opacity: 0.8,
                    }}
                >
                    {moment(parseInt(event?.time)).format(
                        'hh:mm A',
                        // 'D MMM YYYY hh:mm A',
                    )}{' '}
                </Typography>
            </Box>
        </Box>
    );
}
