import { makeStyles } from '@material-ui/core';
import React from 'react';
const useSingleMapStyles = makeStyles((theme) => ({
    container: {
        '& .leaflet-container': {
            width: '100%',
            height: '84vh',
            flexGrow: 1,
            // borderRadius: 16,
            borderTopRightRadius: 16,
            borderBottomRightRadius: 16,
        },
    },
    card: {
        backgroundColor: '#f6f7f9',
        marginTop: 16,
        color: '#000',
        padding: 16,
    },
    routeContainer: {
        padding: '18px 12px',
    },
    backIcon: {
        minWidth: '0',
        color: '#000000',
        cursor: 'pointer',
        marginRight: '8px',
    },
    scrollableBox: {
        width: '100%',
        height: '38vh',
        overflowY: 'auto',
        minHeight: '38vh',
        maxHeight: '38vh',
        marginTop: 12,
        borderRadius: 16,
    },
}));
export default useSingleMapStyles;
