import React, { useEffect, useState } from 'react';
import {
    Box,
    Paper,
    makeStyles,
    IconButton,
    Tooltip,
    TablePagination,
} from '@material-ui/core';
import { CustomMonthPicker } from '../../../components/DateTimePicker';
import Searchbar from '../../../components/Searchbar';
import AttendanceListContainer from './AttendanceListContainer';
import AttendanceModal from './AttendanceModal';
import { Spinner } from '../../../components/UI';
import moment from 'moment';
import { EmptyIcon } from '../../../assets/svg';
import { connect } from 'react-redux';
import { GetAttendenceReport } from '../../../store/actions/reportActionCreator';
import {
    downloadData,
    getLoginUser,
    JSONToCSVConvertor,
    JSONTOCSVFormat,
} from '../../../utils/helper';
import FilterModal, {
    FilteredState,
    FILTER_INPUT_TYPE,
} from '../../../components/Modals/FilterModal';
import GetAppIcon from '@material-ui/icons/GetApp';
import { useDebounce } from '../../../hooks/useDebounce';

const useStyles = makeStyles((theme) => ({
    searchBar: {
        width: '50%',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
    },
    paymentsReportContainer: {
        padding: '1.5rem',
    },
}));

function AttendanceReportComponent({
    staff,
    attendence,
    dispatchGetAttendenceReport,
    settings,
    ...props
}) {
    const { debounce, isBouncing } = useDebounce();

    const classes = useStyles();

    // const [datePickerOpen, setDatePickerOpen] = useState(false);
    const [selectedMonth, setSelectedMonth] = useState();
    const [loading, toggleLoading] = useState(false);
    const [defaultList, setDefaultList] = useState(attendence?.lineItems ?? []);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [searchVal, setSearchVal] = useState('');
    const [filteredValues, setFilteredValue] = useState({});
    const [attendanceListPayload, setAttendanceListPayload] = React.useState({
        pageNo: page + 1,
        pageSize: rowsPerPage,
        searchText: searchVal,
        dateRange: {
            from: moment(selectedMonth).startOf('month').valueOf(),
            to: moment(selectedMonth).endOf('month').valueOf(),
        },
        organisationId: settings?.basicDetails?.id,
        transactionPaymentTypes: ['DEDUCTION'],
        earningTypes: ['ALLOWANCE', 'DELIVERY'],
    });
    useEffect(() => {
        setDefaultList(attendence?.lineItems);
    }, [attendence?.lineItems]);

    // useEffect(() => {
    //     // if (!selectedMonth) return;
    //     fetchAttendenceReport({
    //         ...attendanceListPayload,
    //         dateRange: {
    //             from: moment(selectedMonth).startOf('month').valueOf(),
    //             to: moment(selectedMonth).endOf('month').valueOf(),
    //         },
    //     });
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [selectedMonth]);

    const fetchAttendenceReport = (filters = {}) => {
        toggleLoading(true);
        const payload = {
            ...filters,
            organisationId: settings?.basicDetails?.id,
        };
        dispatchGetAttendenceReport(
            payload,
            (resp) => {
                console.log(resp);
                toggleLoading(false);
            },
            (err) => {
                console.log(err);
                toggleLoading(false);
            },
        );
    };
    const handleChangePage = (event, newPage) => {
        console.log('SETPAGE ', newPage);
        setPage(newPage);
        setAttendanceListPayload((prev) => {
            return {
                ...prev,
                pageNo: newPage + 1,
            };
        });
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
        setAttendanceListPayload((prev) => {
            return {
                ...prev,
                pageNo: 1,
                pageSize: event.target.value,
            };
        });
    };
    React.useEffect(() => {
        console.log('attendanceListPayload changed', attendanceListPayload);
        fetchAttendenceReport({ ...attendanceListPayload });
    }, [attendanceListPayload]);

    const prepareFilterOptions = () => {
        return [
            {
                type: FILTER_INPUT_TYPE.SELECT,
                label: 'Hubs',
                name: 'hubs',
                value: filteredValues?.hubId,
                options: staff?.hubs?.map((item) => ({
                    label: item.name,
                    value: item.id,
                })),
                onChange: async (e) => {
                    setFilteredValue({
                        ...filteredValues,
                        hubs: e?.target?.value?.label,
                        hubId: e?.target?.value,
                    });
                },
            },
        ];
    };

    return (
        <Paper className={classes.paymentsReportContainer} elevation={0}>
            <Spinner loading={loading}>
                <Box style={{ marginBottom: '1rem' }}>
                    <Box
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Box
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                            }}
                        >
                            <Box
                                style={{
                                    width: '80%',
                                }}
                            >
                                {/* <CustomTextField
                                    label="Month"
                                    InputProps={{
                                        readOnly: true,
                                        endAdornment: (
                                            <IconButton
                                                className={'inputbutton'}
                                                onClick={(e) =>
                                                    setDatePickerOpen(true)
                                                }
                                            >
                                                <InsertInvitationIcon size="small" />
                                            </IconButton>
                                        ),
                                    }}
                                    onClick={(e) => setDatePickerOpen(true)}
                                    id="dateEpochMillis"
                                    name="dateEpochMillis"
                                    value={moment(selectedMonth).format(
                                        'MMMM YYYY',
                                    )}
                                    variant="outlined"
                                    style={{ marginBottom: '16px' }}
                                /> */}
                                <CustomMonthPicker
                                    onChange={(e) => {
                                        console.log(moment(e).valueOf());
                                        setSelectedMonth(e);
                                        setPage(0);
                                        setAttendanceListPayload({
                                            ...attendanceListPayload,
                                            pageNo: 1,
                                            dateRange: {
                                                from: moment(e)
                                                    .startOf('month')
                                                    .valueOf(),
                                                to: moment(e)
                                                    .endOf('month')
                                                    .valueOf(),
                                            },
                                        });
                                        // setSelectedDate(moment(e));
                                    }}
                                />
                            </Box>
                            <FilterModal
                                filterOptions={prepareFilterOptions()}
                                filteredValues={filteredValues}
                                onFilterApplied={() => {
                                    setAttendanceListPayload((prev) => {
                                        return {
                                            ...prev,
                                            hubId:
                                                filteredValues?.hubId?.value ??
                                                null,
                                        };
                                    });
                                }}
                            />
                            <FilteredState
                                filterOptions={prepareFilterOptions()}
                                filteredValues={filteredValues}
                                onDelete={(filterKey) => {
                                    let newFilteredValues = {
                                        ...filteredValues,
                                    };
                                    delete newFilteredValues?.[filterKey];
                                    delete newFilteredValues?.['hubId'];
                                    setFilteredValue(newFilteredValues);
                                    setAttendanceListPayload((prev) => {
                                        return {
                                            ...prev,
                                            hubId:
                                                newFilteredValues?.hubs?.map(
                                                    (hub) => hub?.value,
                                                ) ?? null,
                                        };
                                    });
                                }}
                            />
                        </Box>
                        <Box className={classes.searchBar}>
                            <Tooltip title="Download Report" placement="top">
                                <IconButton
                                    className={'inputbutton'}
                                    variant="outlined"
                                    style={{
                                        marginRight: 16,
                                        border: '1px solid #43419e',
                                        borderRadius: 8,
                                        padding: 6,
                                    }}
                                    color="primary"
                                    onClick={(e) => {
                                        debounce((e) => {
                                            const payload = {
                                                ...attendanceListPayload,
                                                organisationId:
                                                    settings?.basicDetails?.id,
                                                download: true,
                                            };
                                            dispatchGetAttendenceReport(
                                                payload,
                                                (resp) => {
                                                    if (
                                                        resp?.response
                                                            ?.attendanceReportsData
                                                            ?.s3DownloadLink
                                                    ) {
                                                        downloadData(
                                                            resp?.response
                                                                ?.attendanceReportsData
                                                                ?.s3DownloadLink,
                                                            'Attendence Report',
                                                        );
                                                    }
                                                    console.log(resp);
                                                    toggleLoading(false);
                                                },
                                                (err) => {
                                                    console.log(err);
                                                    toggleLoading(false);
                                                },
                                            );
                                            // const newArr = [];
                                            // defaultList.map((item) => {
                                            //     let dataObj = {
                                            //         Name: item.name,
                                            //         'Hub Name': item.hubName,
                                            //         'Contact Number':
                                            //             item.contactNumber,
                                            //         'Total Present':
                                            //             item.presentText?.split(
                                            //                 '',
                                            //             )[0],
                                            //         'Total Absent':
                                            //             item.absentText?.split(
                                            //                 '',
                                            //             )[0],
                                            //     };
                                            //     item.days.map((i) => {
                                            //         dataObj[i.reportDateText] =
                                            //             i.attendance;
                                            //         return '';
                                            //     });
                                            //     newArr.push(dataObj);
                                            //     // newArr.push(
                                            //     //     item.days.map((i) => i.weekDay),
                                            //     // );
                                            //     // newArr.push(
                                            //     //     item.days.map((i) => i.dateOfMonth),
                                            //     // );
                                            //     // newArr.push(
                                            //     //     item.days.map((i) => i.attendance),
                                            //     // );
                                            //     return '';
                                            // });
                                            // JSONToCSVConvertor(
                                            //     'Attendence Report',
                                            //     newArr,
                                            //     'Attendence Report',
                                            //     true,
                                            // );
                                        });
                                    }}
                                    disabled={
                                        isBouncing || !defaultList?.length
                                    }
                                >
                                    <GetAppIcon size="small" />
                                </IconButton>
                            </Tooltip>
                            <Searchbar
                                placeHolderText="Search"
                                // placeHolderStyles={{
                                //     color: '#979797',
                                // }}
                                style={{
                                    // border: '1px solid #f1f3f6',
                                    // color: '#14112d',
                                    width: '50%',
                                }}
                                dataSource={[]}
                                keys={['']}
                                fullWidth={false}
                                onFilter={async (filteredData) => {}}
                                value={searchVal}
                                backendSearchbar={true}
                                onBackendSearch={(searchText) => {
                                    setPage(0);
                                    setSearchVal(searchText);
                                    setAttendanceListPayload((prev) => {
                                        return {
                                            ...prev,
                                            pageNo: 1,
                                            searchText,
                                        };
                                    });
                                }}
                                onChange={async (value) => {
                                    console.log('blur', value);
                                    setSearchVal(value);
                                }}
                            />
                        </Box>
                    </Box>
                    {/* <Box
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            margin: '1.5rem 0',
                            justifyContent: 'flex-end',
                        }}
                    >
                        <Typography
                            style={{
                                marginRight: '0.5rem',
                            }}
                        >
                            Aggregate View
                        </Typography>
                        <CustomSwitch
                            checked={state.monthWise}
                            onChange={(e) =>
                                handleInputChange(
                                    e.target.checked,
                                    'aggregateView',
                                )
                            }
                            name="aggregateView"
                        />
                    </Box> */}
                </Box>

                {defaultList?.length ? (
                    <>
                        <AttendanceListContainer rows={defaultList ?? []} />
                        <TablePagination
                            rowsPerPageOptions={[10, 50, 100]}
                            component="div"
                            count={attendence?.totalFilteredCount}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    </>
                ) : (
                    <Box
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <EmptyIcon />
                    </Box>
                )}
            </Spinner>
            {/* {datePickerOpen && (
                <CustomMonthPicker
                    open={datePickerOpen}
                    onOpen={() => setDatePickerOpen(true)}
                    onClose={() => setDatePickerOpen(false)}
                    format="d MMM yyyy"
                    value={selectedMonth}
                    onChange={(e) => {
                        console.log(moment(e).valueOf());
                        setSelectedMonth(e);
                        // setSelectedDate(moment(e));
                    }}
                />
            )} */}
        </Paper>
    );
}

const mapStateToProps = (state) => ({
    staff: state.staff,
    attendence: state.report?.attendence ?? {},
    settings: state.settings,
});

const mapDispatchToProps = (dispatch) => ({
    dispatchGetAttendenceReport: (payload, onSuccess, onError) =>
        dispatch(GetAttendenceReport(payload, onSuccess, onError)),
});

export const AttendanceReport = connect(
    mapStateToProps,
    mapDispatchToProps,
)(AttendanceReportComponent);
