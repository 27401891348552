export const PENDING_STATUS = ['EV INFO SAVED', 'EV Details Saved'];
export const PROGRESS_STATUS = [
    'REQUESTED FOR RENT',
    'EV REQUESTED',
    'EV In-Possession ',
    'EV In-Possession',
    'EV Return Requested',
    'EV_TAG_REQUESTED',
    'EV TAG REQUESTED',
    'UNTAG_REQUESTED',
    'UNTAG REQUESTED',
    'Untag Requested',
];
export const SUCCESS_STATUS = [
    'TAGGED TO EV',
    'REQUEST APPROVED',
    'EV TAG REQUEST APPROVED',
    'UN TAG REQUEST APPROVED',
    'EV_TAG_REQUEST_APPROVED',
    'UN_TAG_REQUEST_APPROVED',
    'EV_UN_TAG_REQUEST_APPROVED',
    'UNTAG_REQUEST_APPROVED',
    'EV UN TAG REQUEST APPROVED',
    'EV Return Approved',
    'TAG Request Approved',
    'EV Request Approved',
    'EV Returned ',
    'EV Returned',
];
export const FAILED_STATUS = [
    'UN-TAGGED TO EV',
    'UN-TAG REQUEST',
    'REQUEST REJECTED',
    'EV TAG REQUEST REJECTED',
    'EV TAG REQUEST REJECTED',
    'EV Request Rejected',
    'TAG Request Rejected',
    'EV Return Rejected',
    'UNTAG_REQUEST_REJECTED',
    'UNTAG REQUEST REJECTED',
    'Untag Request Rejected',
];
export const CLASS_NAME_MAPPING = {
    PENDING_STATUS: 'ev-pen-chip',
    PROGRESS_STATUS: 'request-rent',
    SUCCESS_STATUS: 'ev-active-chip',
    FAILED_STATUS: 'ev-rej-chip',
    EMPTY: null,
};
export const EV_CLASS_STATUS = {
    PENDING_STATUS: 'PENDING_STATUS',
    PROGRESS_STATUS: 'PROGRESS_STATUS',
    SUCCESS_STATUS: 'SUCCESS_STATUS',
    FAILED_STATUS: 'FAILED_STATUS',
    EMPTY: 'EMPTY',
};
