import { useState, useEffect } from 'react';
import moment from 'moment';

const useTimeAgo = ({ datetime }: any) => {
    const [timeAgo, setTimeAgo] = useState<string>(moment(datetime).fromNow());

    useEffect(() => {
        const interval = setInterval(() => {
            setTimeAgo(moment(datetime).fromNow());
            console.log('ddddd', datetime);
        }, 1000);

        return () => clearInterval(interval);
    }, [datetime]);

    return <>{timeAgo}</>;
};

export default useTimeAgo;
