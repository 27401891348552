import React, { useState, useEffect, useCallback, useRef } from 'react';
import './styles.scss';

import { InputBase, IconButton, fade, makeStyles } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { debounce } from 'lodash';

const useStyles = makeStyles((theme) => ({
    search: {
        display: 'flex',
        justifyContent: 'flex-start',
        border: '1px solid #4d4ab5',
        borderRadius: 8,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginLeft: 0,
        width: '100% !important',
        // [theme.breakpoints.up('sm')]: {
        //     marginLeft: theme.spacing(3),
        //     width: 'auto',
        // },
        paddingLeft: '8px',
    },
    inputRoot: {
        display: 'flex',
        justifyContent: 'flex-start',
        border: '1px solid #4d4ab5',
        borderRadius: 8,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginLeft: 0,
        // [theme.breakpoints.up('sm')]: {
        //     marginLeft: theme.spacing(3),
        //     width: 'auto',
        // },
        paddingLeft: '8px',
        width: '100%',
        color: '#14112d',
    },
    inputInput: {
        padding: theme.spacing(1, 0, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        transition: theme.transitions.create('width'),
        // width: '100% !important',
    },
}));

const Searchbar = ({
    dataSource,
    placeHolderText,
    onFilter,
    onChange,
    keys,
    value,
    backendSearchbar = false,
    onBackendSearch,
    ...props
}) => {
    const classes = useStyles();

    const [searchTerm, setSearchTerm] = useState(value ?? '');
    const [filteredData, setFilteredData] = useState([]);

    const debouceSearch = useRef(
        debounce((event) => {
            !!onBackendSearch && onBackendSearch(event.target.value);
        }, 250),
    ).current;

    const onFilterCb = (filteredData) => {
        onFilter(filteredData);
    };

    const handleChange = (event) => {
        console.log('value, nextvalue, ', event.target.value);
        setSearchTerm(event.target.value);
        onChange && onChange(event.target.value);
        onBackendSearch && debouceSearch(event);
    };

    useEffect(() => {
        // console.log('FILTER', filteredData);
        onFilterCb(filteredData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filteredData]);

    useEffect(() => {
        // console.log(searchTerm);
        if (searchTerm !== '') {
            let newList = [];

            //TODO: Replace this hardcoded check with logic, as in this case the key should NOT be nested.
            // newList = dataSource?.filter((data) => {
            //     return (
            //         data[keys?.[0]]
            //             ?.toLowerCase()
            //             ?.includes(searchTerm.toLowerCase()) ||
            //         data[keys?.[1]]
            //             ?.toLowerCase()
            //             ?.includes(searchTerm.toLowerCase()) ||
            //         data[keys?.[2]]
            //             ?.toLowerCase()
            //             ?.includes(searchTerm.toLowerCase()) ||
            //         data[keys?.[3]]
            //             ?.toLowerCase()
            //             ?.includes(searchTerm.toLowerCase()) ||
            //         data[keys?.[4]]
            //             ?.toLowerCase()
            //             ?.includes(searchTerm.toLowerCase())
            //     );
            // });

            // console.log(newList);
            setFilteredData(newList);
        } else {
            onFilter(dataSource);
        }

        // console.log('data is', filteredData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchTerm]);

    return backendSearchbar ? (
        <>
            <InputBase
                color="primary"
                placeholder={placeHolderText ? placeHolderText : 'Search…'}
                classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                }}
                value={value ?? searchTerm}
                inputProps={{ 'aria-label': 'search' }}
                onChange={handleChange}
                startAdornment={<SearchIcon />}
                {...props}
            />
        </>
    ) : (
        <InputBase
            color="primary"
            placeholder={placeHolderText ? placeHolderText : 'Search…'}
            classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
            }}
            value={value ?? searchTerm}
            inputProps={{ 'aria-label': 'search' }}
            onChange={handleChange}
            startAdornment={<SearchIcon />}
            {...props}
        />
    );
};

export default Searchbar;
