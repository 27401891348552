import React, { useEffect, useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
import DescriptionRoundedIcon from '@material-ui/icons/DescriptionRounded';

const ThumbnailPreview = withStyles({
    root: {
        width: '100%',
        '& .MuiTabScrollButton-root': {
            width: '10px !important',
            padding: '6px !important',
        },
        '& .MuiTabs-indicator': {
            display: 'none !important',
        },
    },
})(({ classes, children, images, customStyles, ...props }) => {
    function a11yProps(index) {
        return {
            id: `scrollable-auto-tab-${index}`,
            'aria-controls': `scrollable-auto-tabpanel-${index}`,
        };
    }

    const [value, setValue] = React.useState(0);

    const [isOpen, setIsOpen] = React.useState(false);
    const [photoIndex, setPhotoIndex] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [imagesObj, setImagesObj] = useState([]);

    useEffect(() => {
        if (images?.length > 0 && !!images[0]?.hasOwnProperty('link')) {
            setImagesObj(images);
        } else {
            setImagesObj(
                images.map((x) => {
                    return { link: x, preview: x };
                }),
            );
        }
    }, [images]);

    return (
        <>
            <Tabs
                classes={{
                    root: classes.root,
                }}
                disableRipple
                {...props}
                value={value}
                onClick={(e) => e.stopPropagation()}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
            >
                {imagesObj?.map((data, index) =>
                    data ? (
                        <Avatar
                            variant="rounded"
                            className={classes.rounded}
                            src={
                                data?.hasOwnProperty('preview')
                                    ? data.preview
                                    : data
                            }
                            key={index}
                            {...a11yProps(index)}
                            onClick={(e) => {
                                e.stopPropagation();
                                setPhotoIndex(index);
                                setIsOpen(true);
                            }}
                            style={
                                customStyles
                                    ? customStyles
                                    : {
                                          margin: '0.2rem',
                                      }
                            }
                        />
                    ) : (
                        <Avatar
                            variant="rounded"
                            className={classes.rounded}
                            key={index}
                            {...a11yProps(index)}
                            style={
                                customStyles
                                    ? customStyles
                                    : {
                                          margin: '0.2rem',
                                      }
                            }
                        >
                            <DescriptionRoundedIcon />
                        </Avatar>
                    ),
                )}
            </Tabs>
            {imagesObj[photoIndex] && isOpen && (
                <Lightbox
                    mainSrc={imagesObj[photoIndex].link}
                    nextSrc={
                        imagesObj[(photoIndex + 1) % imagesObj.length].link
                    }
                    prevSrc={
                        imagesObj[
                            (photoIndex + imagesObj.length - 1) %
                                imagesObj.length
                        ].link
                    }
                    reactModalProps={{
                        onRequestClose: (e) => {
                            e.stopPropagation();
                            setIsOpen(false);
                        },
                    }}
                    onCloseRequest={(e) => {
                        e.stopPropagation();
                        setIsOpen(false);
                    }}
                    onMovePrevRequest={(e) => {
                        e.stopPropagation();
                        setPhotoIndex(
                            (prevIndex) =>
                                (prevIndex + imagesObj.length - 1) %
                                imagesObj.length,
                        );
                    }}
                    onMoveNextRequest={(e) => {
                        e.stopPropagation();
                        setPhotoIndex(
                            (prevIndex) =>
                                (prevIndex + images.length + 1) % images.length,
                        );
                    }}
                />
            )}
        </>
    );
});

export default ThumbnailPreview;
