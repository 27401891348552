import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
    GetVehicles,
    getTripRouteDetails,
    getTrackingToggleEvents,
    getAllTrackingEvents,
} from '../../../store/actions/mapViewActionCreator';
import { getSingleStaff } from '../../../store/actions/staffActionCreator';
import axios from 'axios';
import moment from 'moment';
interface UseRiderMapActionsReturnType {
    value: string;
    setValue: React.Dispatch<React.SetStateAction<string>>;
    getAddressFromLatLong: (lat: string, long: string) => Promise<any>;
}

const useRiderMapActions = ({ user }: any) => {
    const dispatch = useDispatch();
    const [value, setValue] = useState<string>('');
    const dispatchGetVehicleList = (
            payload?: any,
            onSuccess?: Function,
            onError?: Function,
        ) => dispatch(GetVehicles(payload, onSuccess, onError)),
        dispatchGetTripRouteDetails = (
            payload?: any,
            onSuccess?: Function,
            onError?: Function,
        ) => dispatch(getTripRouteDetails(payload, onSuccess, onError)),
        dispatchGetTrackingToggleEvents = (
            payload?: any,
            onSuccess?: Function,
            onError?: Function,
        ) => dispatch(getTrackingToggleEvents(payload, onSuccess, onError)),
        dispatchGetAllTrackingEvents = (
            payload?: any,
            onSuccess?: Function,
            onError?: Function,
        ) => dispatch(getAllTrackingEvents(payload, onSuccess, onError)),
        dispatchGetSingleStaff = (
            payload?: any,
            onSuccess?: Function,
            onError?: Function,
        ) => dispatch(getSingleStaff(payload, onSuccess, onError));

    const fetchRiderDetails = (staffId: any): Promise<any> => {
        return new Promise((resolve, reject) => {
            dispatchGetSingleStaff(
                staffId,
                async (resp?: any) => {
                    resolve({
                        ...resp.response,
                    });
                },
                (err?: any) => {
                    resolve({});
                },
            );
        });
    };

    const fetchRiderEventsDetails = (
        staffId: any,
        selectedDate: any,
    ): Promise<any> => {
        return new Promise((resolve, reject) => {
            dispatchGetAllTrackingEvents(
                {
                    staffId: staffId,
                    date: moment(selectedDate).format('YYYYMMDD'),
                },
                (response?: any) => {
                    resolve(response?.response);
                },
                () => {
                    resolve({});
                },
            );
        });
    };

    const fetchRiderTrackingDetails = (
        payload: any,
        addressFetch: boolean,
        token?: any,
    ): Promise<any> => {
        return new Promise((resolve, reject) => {
            dispatchGetTripRouteDetails(
                {
                    token: `Bearer ${user?.config?.beeconAPIToken}`,
                    data: payload,
                },
                async (response?: any) => {
                    let finalRes = {
                        ...response,
                        startPoint: '',
                        endPoint: '',
                    };
                    if (
                        !!addressFetch &&
                        response?.locationPoints?.length > 1
                    ) {
                        finalRes.startAddress = await getAddressFromLatLong(
                            response?.locationPoints?.[0]?.latitude,
                            response?.locationPoints?.length > 0 &&
                                response?.locationPoints[0]?.longitude,
                        );
                        finalRes.endAddress = await getAddressFromLatLong(
                            response?.locationPoints?.[
                                response?.locationPoints?.length - 1
                            ]?.latitude,
                            response?.locationPoints?.length > 0 &&
                                response?.locationPoints[
                                    response?.locationPoints?.length - 1
                                ]?.longitude,
                        );
                        resolve(finalRes);
                    } else {
                        resolve(response);
                    }
                },
                (err?: any) => {
                    resolve({});
                },
            );
        });
    };
    const fetchRiderVehicleDetails = (payload: any): Promise<any> => {
        return new Promise((resolve, reject) => {
            dispatchGetVehicleList(
                {
                    token: `Bearer ${user?.config?.beeconAPIToken}`,
                    data: payload,
                },
                async (response?: any) => {
                    resolve(response);
                },
                (err?: any) => {
                    resolve({});
                },
            );
        });
    };
    const getAddressFromLatLong = (lat: string, long: string): Promise<any> => {
        return new Promise((resolve, reject) => {
            let url =
                'https://nominatim.openstreetmap.org/reverse?lat=' +
                parseFloat(lat) +
                '&lon=' +
                parseFloat(long) +
                '&addressdetails=1&format=json&accept-language=en';
            axios
                .get(url)
                .then((response) => {
                    resolve(response?.data?.display_name ?? '');
                })
                .catch((e) => {
                    resolve('');
                });
        });
    };
    useEffect(() => {
        return () => {};
    }, []);

    return {
        value,
        setValue,
        getAddressFromLatLong,
        fetchRiderDetails,
        fetchRiderEventsDetails,
        fetchRiderTrackingDetails,
        fetchRiderVehicleDetails,
    };
};

export default useRiderMapActions;
