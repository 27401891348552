import aadhaar_front from '../assets/svg/aadhar-front-preview-icon.svg';
import aadhaar_back from '../assets/svg/aadhar-back-preview-icon.svg';
import driving_license from '../assets/svg/driving-license-preview-icon.svg';
import profile_preview from '../assets/svg/profile-preview-icon.svg';
import signature from '../assets/svg/signature-preview-icon.svg';
import rc from '../assets/svg/rc-preview-icon.svg';
import pan from '../assets/svg/pan-preview-icon.svg';

export const sourceSystems = Object.freeze({
    StaffPay: 'STAFFPAY',
    Zappay: 'ZAPPAY',
});

export const RouteLayouts = Object.freeze({
    MainLayout: 'MAIN_LAYOUT',
    BlankLayout: 'BLANK_LAYOUT',
});

export const KYCDocuments = [
    {
        label: 'Aadhar',
        value: 'AADHAR',
    },
    {
        label: 'Uidai Aadhar',
        value: 'AADHAR_UIDAI',
    },
    {
        label: 'Pan Card',
        value: 'PANCARD',
    },
    {
        label: 'Vehicle RC',
        value: 'RC',
    },
];

export const HUB_TYPE_ENUM = Object.freeze({
    FOOD: {
        label: 'HYPERLOCAL',
        value: 'FOOD',
        color: '#f44336',
        product: 'Hermes',
        bgColor: 'rgba(236, 78, 43, 0.24)',
    },
    ECOM: {
        label: 'ECOMMERCE',
        value: 'ECOM',
        color: '#43419e',
        product: 'Log10',
        bgColor: 'rgba(67, 65, 158, 0.24)',
    },
    STORE: {
        label: 'STORE',
        product: 'Hydra',
        value: 'STORE',
        color: '#579b5a',
        bgColor: 'rgba(87, 155, 90, 0.24)',
    },
    '': { value: '', color: '#f44336' },
});

export const KYCDocumentsDisplayText = {
    AADHAR: 'Aadhar',
    AADHAR_UIDAI: 'Uidai Aadhar',
    PANCARD: 'Pan Card',
    RC: 'Vehicle RC',
};

export const DocumentsPreview = [
    {
        type: 'AADHAR_FRONT',
        value: aadhaar_front,
    },
    {
        type: 'AADHAR_BACK',
        value: aadhaar_back,
    },
    {
        type: 'PANCARD',
        value: pan,
    },
    {
        type: 'RC',
        value: rc,
    },
    {
        type: 'SIGNATURE',
        value: signature,
    },
    {
        type: 'DRIVING_LICENSE_FRONT',
        value: driving_license,
    },
    {
        type: 'DRIVING_LICENSE_BACK',
        value: driving_license,
    },
    {
        type: 'PROFILE_PIC',
        value: profile_preview,
    },
];
