import * as constants from './../constants';
import {
    API_AUTH_SERVICE_PORT,
    API_USER_SERVICE_PORT,
} from '../../config/env/env';

export const getAllAttendanceData = ({ date, data }, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: '/org/staff/attendance/' + date,
        method: 'POST',
        data: data,
        success: setAllAttendanceData,
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
    },
});

const setAllAttendanceData = (data) => {
    if (data && data.response) {
        console.log('AuthActionCreator', 'the data is', data);
        if (data.response.s3FileUrl) {
            return {
                type: constants.DO_NOTHING,
                payload: data?.response ?? {},
            };
        }
        return {
            type: constants.SET_ALL_ATTENDANCE_DATA,
            payload: data.response,
        };
    }
};

export const getTimelineActivityData = (
    { staffId, attendanceId },
    onSuccess,
    onError,
) => ({
    type: constants.API,
    payload: {
        url: `/org/staff/${staffId}/attendance/${attendanceId}`,
        method: 'GET',
        success: setActivityData,
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
    },
});
export const getAttendanceActivityData = (
    { staffId, attendanceId },
    onSuccess,
    onError,
) => ({
    type: constants.API,
    payload: {
        url: `/org/staff/${staffId}/attendance/v2/${attendanceId}`,
        method: 'GET',
        success: setActivityData,
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
    },
});

const setActivityData = (data) => {
    if (data && data.response) {
        console.log('AuthActionCreator', 'the data is', data);
        return {
            type: constants.SET_TIMELINE_ACTIVITY_DATA,
            payload: data.response,
        };
    }
};

export const punchIn = ({ staffId, data }, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: `/org/staff/${staffId}/attendance/punch-in`,
        method: 'POST',
        data: data,
        success: setPunchInOutData,
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
    },
});

export const punchOut = ({ staffId, data }, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: `/org/staff/${staffId}/attendance/punch-out`,
        method: 'POST',
        data: data,
        success: setPunchInOutData,
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
    },
});

const setPunchInOutData = (data) => {
    if (data && data.response) {
        console.log('AuthActionCreator', 'the data is', data);
        return {
            type: constants.SET_PUNCH_IN_OUT_DATA,
            payload: data.response,
        };
    }
};

export const getSingleStaffAttendanceData = (
    { staffId, date, data },
    onSuccess,
    onError,
) => ({
    type: constants.API,
    payload: {
        url: `/org/staff/${staffId}/attendance/${date}`,
        method: 'POST',
        data: data,
        success: setAllAttendanceData,
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
    },
});

export const approvePunchIn = ({ staffId, id, data }, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: `/org/staff/${staffId}/attendance/${id}`,
        method: 'PUT',
        data: data,
        success: approvePunchInOutData,
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
    },
});

export const approvePunchOut = ({ staffId, id, data }, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: `/org/staff/${staffId}/attendance/${id}`,
        method: 'PUT',
        data: data,
        success: approvePunchInOutData,
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
    },
});

const approvePunchInOutData = (data) => {
    if (data && data.response) {
        console.log('AuthActionCreator', 'the data is', data);
        return {
            type: constants.APPROVE_PUNCH_IN_OUT_DATA,
            payload: data.response,
        };
    }
};

export const markAbsent = ({ staffId, id }, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: `/org/staff/${staffId}/attendance/${id}`,
        method: 'DELETE',
        success: (response) => {
            return { type: constants.DO_NOTHING, payload: response };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
    },
});

export const getReasonMenuList = ({ type }, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: `/common/static-values/${type}`,
        method: 'GET',
        success: (data) => {
            return {
                type: constants.REASON_STATIC_VALUES_DATA,
                payload: data.response,
            };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
    },
});

export const fetchVehicleTypes = (onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: `/common/vehicleTypes`,
        method: 'GET',
        success: (data) => {
            return {
                type: constants.DO_NOTHING,
                payload: data.response,
            };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
    },
});

export const updateDistance = (payload, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: '/org/staff/update-distance',
        method: 'POST',
        data: payload,
        success: (response) => {
            return {
                type: constants.DO_NOTHING,
                payload: response,
            };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
    },
});

export const weekOff = ({ staffId, data }, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: `/org/staff/${staffId}/attendance/week-off`,
        method: 'POST',
        data: data,
        success: (response) => {
            return {
                type: constants.DO_NOTHING,
                payload: response,
            };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
    },
});
