import React, { useState } from 'react';
import { Button, Box, Typography } from '@material-ui/core';
import Modal from './Modal';

// const useStyles = makeStyles((theme) => ({
//     modal: {},
// }));

function DeleteStaffModal({ open, onClose, data, deleteStaff, ...props }) {
    // const classes = useStyles();
    const [isOpen, toggleModal] = useState(open);
    const [loading, toggleLoading] = useState(false);
    const handleClose = () => {
        // Setting form data to null before closing

        toggleModal(!isOpen);
        onClose && onClose();
    };

    const actions = [
        <Button
            key={1}
            variant="contained"
            color="default"
            className={`cancelButton`}
            onClick={handleClose}
            disableElevation
        >
            {'Cancel'}
        </Button>,
        <Button
            key={2}
            variant="contained"
            className={`saveButton`}
            style={{
                backgroundColor: '#f44336',
                color: '#fff',
            }}
            disableElevation
            onClick={async () => {
                // alert(`${isEdit ? 'Edit Clicked' : 'Add Clicked'}`);
                await toggleLoading(true);
                deleteStaff(
                    data,
                    async () => {
                        console.log('success');
                        await toggleLoading(false);
                        await handleClose();
                    },
                    async () => {
                        console.log('failed');
                        await toggleLoading(false);
                    },
                );
            }}
        >
            Delete
        </Button>,
    ];

    return (
        <Modal
            onClose={handleClose}
            open={isOpen}
            title={'Delete Staff'}
            actions={actions}
            loading={loading}
        >
            <Box>
                <Typography
                    style={{
                        textAlign: 'center',
                    }}
                >
                    Do you want to delete staff
                    <strong>{` ${data.name} `}</strong>?
                </Typography>
                <Typography
                    style={{
                        textAlign: 'center',
                        marginTop: '8px',
                        marginBottom: '24px',
                    }}
                >
                    You can’t undo this action.
                </Typography>
            </Box>
        </Modal>
    );
}

export default DeleteStaffModal;
