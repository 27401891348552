import { Box, Button, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import Modal from '../Modal';

function SingleReleaseModal({ open, onClose, onRelease, ...props }) {
    const [isOpen, toggleModal] = useState(open);
    const [loading, toggleLoading] = useState(false);
    const handleClose = () => {
        // Setting form data to null before closing

        toggleModal(!isOpen);
        onClose && onClose();
    };

    const actions = [
        <Button
            key={1}
            variant="contained"
            color="default"
            className={`cancelButton`}
            onClick={handleClose}
            disableElevation
        >
            {'Cancel'}
        </Button>,
        <Button
            key={2}
            variant="contained"
            className={`saveButton`}
            style={{
                backgroundColor: '#f44336',
                color: '#fff',
            }}
            disableElevation
            onClick={async () => {
                // alert(`${isEdit ? 'Edit Clicked' : 'Add Clicked'}`);
                toggleLoading(true);
                onRelease(toggleLoading);
                // toggleLoading(false);
            }}
        >
            Release
        </Button>,
    ];
    return (
        <Modal
            onClose={handleClose}
            open={isOpen}
            title={'Release Payment'}
            actions={actions}
            loading={loading}
        >
            <Box>
                <Typography
                    style={{
                        textAlign: 'center',
                    }}
                >
                    Do you want to Release this Payment?
                </Typography>
                <Typography
                    style={{
                        textAlign: 'center',
                        marginTop: '8px',
                        marginBottom: '24px',
                    }}
                >
                    You can’t undo this action.
                </Typography>
            </Box>
        </Modal>
    );
}

export default SingleReleaseModal;
