import { Box, Button, TextField } from '@material-ui/core';
import React, { useState } from 'react';
import Modal from './Modal';
import { CustomTextField, StyledMenuItem } from '../Textfield';
import {
    STATUS_TYPES,
    STATUS_TYPES_CONFIG,
} from '../../pages/control-center/helper';

function CommentModal({ open, onClose, data, onSave, title, ...props }: any) {
    const [isOpen, toggleModal] = useState(open);
    const [comment, setComment] = useState('');
    const [assignee, setAssignee] = useState('');
    const [loading, toggleLoading] = useState(false);
    const handleClose = () => {
        // Setting form data to null before closing

        toggleModal(!isOpen);
        onClose && onClose();
    };
    const handleChange = (event: any) => {
        setComment(event.target.value);
    };
    const handleAssigneeChange = (event: any) => {
        setAssignee(event.target.value);
    };

    const actions = [
        <Button
            key={1}
            variant="contained"
            color="default"
            className={`cancelButton`}
            onClick={handleClose}
            disableElevation
        >
            {'Cancel'}
        </Button>,
        <Button
            key={2}
            variant="contained"
            className={`saveButton`}
            disableElevation
            color={'primary'}
            onClick={async () => {
                await toggleLoading(true);
                onSave && onSave({ comment, assignee });
            }}
        >
            Save
        </Button>,
    ];
    return (
        <Modal
            onClose={handleClose}
            open={isOpen}
            title={data?.name ? `Mark ${data?.name}` : 'Add Comment'}
            actions={actions}
            loading={loading}
            subtitle={null}
            maxWidth={null}
            className={null}
            fullScreen={false}
            isForm={false}
            onSubmit={null}
            subtitleStyle={null}
            withBackDrop={false}
            multiOrgModal={false}
            stickyActions={false}
            payslipPreviewModal={false}
            fullWidth={false}
            containerStyle={{}}
            dialogActionsCenterAligned={false}
        >
            <Box>
                {data?.name ===
                    STATUS_TYPES_CONFIG[STATUS_TYPES.ASSIGN]?.name && (
                    <CustomTextField
                        label="Assginee"
                        select
                        fullWidth={true}
                        value={assignee ?? ''}
                        onChange={(e: any) => {
                            handleAssigneeChange(e);
                        }}
                        defaultValue=""
                        style={{
                            marginBottom: '16px',
                            width: '332px',
                        }}
                    >
                        <StyledMenuItem value={'Umang khattar'}>
                            Umang khattar
                        </StyledMenuItem>
                        <StyledMenuItem value={'Shivam singh'}>
                            Shivam singh
                        </StyledMenuItem>
                        <StyledMenuItem value={'Raushan Kumar'}>
                            Raushan Kumar
                        </StyledMenuItem>
                        <StyledMenuItem value={'Ajay Kamble'}>
                            Ajay Kamble
                        </StyledMenuItem>
                        <StyledMenuItem value={'Janmejaya Panda'}>
                            Janmejaya Panda
                        </StyledMenuItem>
                        <StyledMenuItem value={'Sunil kumar'}>
                            Sunil kumar
                        </StyledMenuItem>
                        <StyledMenuItem value={'Nikhil Kumkar'}>
                            Nikhil Kumkar
                        </StyledMenuItem>
                        <StyledMenuItem value={'Nikhil gaikwad'}>
                            Nikhil gaikwad
                        </StyledMenuItem>
                    </CustomTextField>
                )}
                <TextField
                    id="outlined-multiline-static"
                    label="Comment"
                    multiline
                    rows={4}
                    //defaultValue="Default Value"
                    variant="outlined"
                    value={comment}
                    style={{
                        width: '332px',
                    }}
                    onChange={handleChange}
                />
            </Box>
        </Modal>
    );
}

export default CommentModal;
