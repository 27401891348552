import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    IconButton,
    makeStyles,
    Slide,
    Typography,
    useMediaQuery,
    useTheme,
    Backdrop,
    CircularProgress,
} from '@material-ui/core';
import React, { useState } from 'react';
import { CloseIcon } from '../../assets/svg';
import { Spinner } from '../UI';
const useStyles = makeStyles((theme) => ({
    modal: {
        '& .modal-head': {
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
        },
        '& .modal-title': {
            fontSize: '24px',
            fontWeight: 'bold',
            color: '#14112d',
        },
        '& .modal-subtitle': {
            fontSize: '14px',
            fontWeight: 'normal',
            color: '#14112d',
        },

        '& .cancelButton': {
            color: theme.palette.text.primary,
        },
        '& .saveButton': {},
    },
    iconButton: {
        padding: '0px',
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function Modal({
    open,
    title,
    subtitle,
    children,
    actions,
    onClose,
    maxWidth,
    loading,
    className,
    fullScreen,
    isForm,
    onSubmit,
    subtitleStyle,
    withBackDrop = false,
    multiOrgModal = false,
    stickyActions = false,
    payslipPreviewModal = false,
    fullWidth = true,
    containerStyle = {},
    dialogActionsCenterAligned = false,
    ...props
}) {
    const classes = useStyles();
    const theme = useTheme();
    const fullScreens = useMediaQuery(
        theme.breakpoints.down(fullScreen ? 'lg' : 'sm'),
    );
    const [isOpen, toggleModal] = useState(open);

    const handleClose = () => {
        toggleModal(!isOpen);
        onClose && onClose();
    };

    const ActionBar = (
        <Box
            style={
                stickyActions
                    ? {
                          bottom: 0,
                          overflow: 'hidden',
                          position: 'sticky',
                          background: 'white',
                          zIndex: 999,
                      }
                    : {}
            }
        >
            {!!actions && <Divider />}
            {!!actions && (
                <DialogContent>
                    <DialogActions
                        style={
                            dialogActionsCenterAligned 
                            ? { 
                                justifyContent: 'space-between'
                            } 
                            : {}
                        }
                    >
                        {actions.map((action, index) => (
                            <Box key={index}>{action}</Box>
                        ))}
                    </DialogActions>
                </DialogContent>
            )}
        </Box>
    );

    return (
        <Dialog
            open={isOpen}
            // TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            className={`${classes.modal} ${className}`}
            fullScreen={fullScreens}
            fullWidth={fullWidth}
            maxWidth={maxWidth ?? 'xs'}
            disableBackdropClick={true}
            style={
                multiOrgModal
                    ? {
                          justifyContent: 'center',
                          top: '15%',
                          bottom: '17.5%',
                          height: '75%',
                      }
                    : payslipPreviewModal
                    ? {
                          justifyContent: 'center',
                          top: '0.25%',
                          bottom: '0.25%',
                      }
                    : {}
            }
        >
            <Spinner isBackDrop={withBackDrop} loading={loading}>
                <DialogTitle
                    id="alert-dialog-slide-title"
                    className={`modal-titlehead`}
                    style={
                        multiOrgModal
                            ? {
                                  top: 0,
                                  overflow: 'hidden',
                                  position: 'sticky',
                                  background: 'white',
                                  zIndex: 999,
                              }
                            : {
                                  padding: '16px 24px 8px 24px',
                              }
                    }
                >
                    <Box className={`modal-head`}>
                        <div>
                            {!!title && (
                                <Typography
                                    className={`modal-title`}
                                    variant="h6"
                                >
                                    {title}
                                </Typography>
                            )}
                            {!!subtitle && (
                                <Typography
                                    className={`modal-subtitle`}
                                    variant="body2"
                                    style={subtitleStyle ?? {}}
                                >
                                    {subtitle}
                                </Typography>
                            )}
                        </div>
                        <div
                            style={{
                                alignSelf: 'center',
                                justifySelf: 'flex-end',
                            }}
                        >
                            <IconButton
                                aria-label="delete"
                                className={`close-icon ${classes.iconButton}`}
                                onClick={handleClose}
                            >
                                <CloseIcon />
                            </IconButton>
                        </div>
                    </Box>
                </DialogTitle>
                <Divider />
                {!!isForm ? (
                    <form onSubmit={onSubmit}>
                        <DialogContent
                            style={
                                multiOrgModal
                                    ? {
                                          overflowY: 'unset',
                                          padding: '0px 24px 8px',
                                          paddingTop: 10,
                                          minHeight: '70vh',
                                      }
                                    : containerStyle
                            }
                        >
                            {children}
                        </DialogContent>
                        {ActionBar}
                    </form>
                ) : (
                    <>
                        <DialogContent
                            style={
                                multiOrgModal
                                    ? {
                                          overflowY: 'unset',
                                          padding: '0px 24px 8px',
                                          minHeight: '70vh',
                                      }
                                    : containerStyle
                            }
                        >
                            {children}
                        </DialogContent>
                        {ActionBar}
                    </>
                )}
            </Spinner>
        </Dialog>
    );
}

export default Modal;

export function FixedModal({
    open,
    title,
    subtitle,
    children,
    actions,
    onClose,
    maxWidth,
    loading,
    className,
    fullScreen,
    isForm,
    onSubmit,
    subtitleStyle,
    multiOrgModal = false,
    stickyActions = false,
    payslipPreviewModal = false,
    fullWidth = true,
    containerStyle = {},
    ...props
}) {
    const classes = useStyles();
    const theme = useTheme();
    const fullScreens = useMediaQuery(
        theme.breakpoints.down(fullScreen ? 'lg' : 'sm'),
    );
    const [isOpen, toggleModal] = useState(open);

    const handleClose = () => {
        toggleModal(!isOpen);
        onClose && onClose();
    };

    const descriptionElementRef = React.useRef(null);
    React.useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [isOpen]);
    return (
        <Dialog
            open={isOpen}
            // TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            scroll={'paper'}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            fullScreen={fullScreens}
            fullWidth={fullWidth}
            maxWidth={maxWidth ?? 'xs'}
            disableBackdropClick
            className={`${classes.modal} ${className}`}
        >
            <DialogTitle
                id="scroll-dialog-title"
                className={`modal-titlehead`}
                style={
                    multiOrgModal
                        ? {
                              top: 0,
                              overflow: 'hidden',
                              position: 'sticky',
                              background: 'white',
                              zIndex: 999,
                          }
                        : {
                              padding: '16px 24px 8px 24px',
                          }
                }
            >
                <Box className={`modal-head`}>
                    <div>
                        {!!title && (
                            <Typography className={`modal-title`} variant="h6">
                                {title}
                            </Typography>
                        )}
                        {!!subtitle && (
                            <Typography
                                className={`modal-subtitle`}
                                variant="body2"
                                style={subtitleStyle ?? {}}
                            >
                                {subtitle}
                            </Typography>
                        )}
                    </div>
                    <div
                        style={{
                            alignSelf: 'center',
                            justifySelf: 'flex-end',
                        }}
                    >
                        <IconButton
                            aria-label="delete"
                            className={`close-icon ${classes.iconButton}`}
                            onClick={handleClose}
                        >
                            <CloseIcon />
                        </IconButton>
                    </div>
                </Box>
            </DialogTitle>
            {isForm ? (
                <>
                    <form onSubmit={onSubmit}>
                        <DialogContent
                            dividers={true}
                            style={{
                                containerStyle,
                            }}
                        >
                            <DialogContentText
                                id="scroll-dialog-description"
                                ref={descriptionElementRef}
                                tabIndex={-1}
                            >
                                {children}
                            </DialogContentText>
                        </DialogContent>
                        {!!actions && (
                            <DialogActions>
                                {actions.map((action, index) => (
                                    <Box key={index}>{action}</Box>
                                ))}
                            </DialogActions>
                        )}
                    </form>
                </>
            ) : (
                <>
                    <DialogContent
                        // dividers={true}
                        style={{
                            containerStyle,
                        }}
                    >
                        <DialogContentText
                            id="scroll-dialog-description"
                            ref={descriptionElementRef}
                            tabIndex={-1}
                        >
                            {children}
                        </DialogContentText>
                    </DialogContent>
                    {!!actions && (
                        <DialogActions>
                            {actions.map((action, index) => (
                                <Box key={index}>{action}</Box>
                            ))}
                        </DialogActions>
                    )}
                </>
            )}
        </Dialog>
    );
}
