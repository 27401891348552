import React from 'react';
import { Box, Typography } from '@material-ui/core';
interface StaffInfoCardProps {
    name?: string;
    contactNumber?: number;
    hubName?: string;
    vehicleNumber?: any;
}

const StaffInfoCard: React.FC<StaffInfoCardProps> = ({
    name,
    contactNumber,
    hubName,
    vehicleNumber,
}) => {
    return (
        <Box
            style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'baseline',
                marginBottom: 8,
            }}
        >
            <Typography
                gutterBottom
                // variant="body2"
                // component="div"
                style={{
                    flexGrow: 0,
                    margin: '0 6px 0 0',
                    textAlign: 'left',
                }}
            >
                {!!hubName ?? `${hubName} | `}
                {contactNumber ?? ''}
            </Typography>
            <Typography
                gutterBottom
                // variant="body2"
                // component="div"
                style={{
                    flexGrow: 0,
                    margin: '0 6px 0 0',
                    textAlign: 'left',
                    color: '#14112d',
                }}
            >
                {vehicleNumber?.slice(0, vehicleNumber.length - 4) ?? ''}
                <b>{vehicleNumber?.slice(vehicleNumber.length - 4) ?? ''}</b>
            </Typography>
        </Box>
    );
};

export default StaffInfoCard;
