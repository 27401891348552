import {
    Box,
    Button,
    Card,
    Grid,
    makeStyles,
    Paper,
    Typography,
    IconButton,
    List,
    ListItem,
    ListItemSecondaryAction,
    Divider,
    TablePagination,
    Tooltip,
    Chip,
} from '@material-ui/core';
import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { Spinner } from '../../components/UI';
import {
    fetchAppConfig,
    setIgnoreDateFilter,
    setBulkReleaseFlag,
    setSelectedPayments,
    getSourceSystemsList,
    setFilterModalConfig,
    getAllPaymentIndentsListingData,
} from '../../store/actions/authActionCreator';
import AllPaymentIndentsListContainer from '../../components/Containers/AllPaymentIndentsListContainer';
import Searchbar from '../../components/Searchbar';
import ComboViewModal from '../../components/Modals/Zappay/ComboViewModal';
import FilterListRoundedIcon from '@material-ui/icons/FilterListRounded';
import GetAppIcon from '@material-ui/icons/GetApp';
import UploadPaymentModal from '../../components/Modals/Zappay/UploadPaymentModal';
import BulkReleaseModal from '../../components/Modals/Zappay/BulkReleaseModal';
import moment from 'moment';
import {
    formatCurrency,
    getPermissions,
    JSONToCSVConvertor,
    replaceTextWithCapsAndUnderscore,
} from '../../utils/helper';
import CachedIcon from '@material-ui/icons/Cached';
import { useDebounce } from '../../hooks/useDebounce';

const useStyles = makeStyles((theme) => ({
    horizontalCardContents: {
        display: 'flex',
        flexDirection: 'row',
        margin: '16px 0',
        flexWrap: 'wrap',
    },
    searchBar: {
        width: '50%',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
    },
    verticalCardContents: {
        flex: '1 1 20%',
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        justifyContent: 'center',
        border: 'solid 1px #ebedf8',
        borderRadius: '8px',
        backgroundColor: '#ebedf8',
        paddingLeft: '16px',
        height: '88px',
        margin: '0 8px 0 0 ',
    },
    verticalCardContentsActive: {
        backgroundColor: '#d1d8ff',
    },
    verticalCardContentsNoMargin: {
        margin: 0,
    },
    cardSubHeader: {
        fontSize: '22px',
        fontWeight: 700,
    },
    cardHeader: {
        fontSize: '16px',
        fontWeight: 600,
    },
    chipRoot: {
        marginRight: 4,
        height: '100%',
        paddingLeft: 10,
        background: '#d1d8ff',
        color: 'black',
        '& .MuiChip-label': {
            fontWeight: 600,
            width: 'auto !important',
            fontSize: '16px !important',
        },
        '& .MuiChip-deleteIcon': {
            margin: 4,
        },
    },
    chipRootNoDelete: {
        // height: '100%',
        marginRight: 8,
        padding: '0 12px',
        background: '#d1d8ff',
        color: 'black',
        '& .MuiChip-label': {
            fontWeight: 600,
            width: 'auto !important',
            fontSize: '16px !important',
        },
    },
}));

const FILTER_STATUS = Object.freeze({
    PENDING_AMOUNT: 'PENDING_AMOUNT',
    INPROGRESS_TRANSACTIONS: 'INPROGRESS_TRANSACTIONS',
    FAILED_TRANSACTIONS: 'FAILED_TRANSACTIONS',
});

function PayOnline({
    configs,
    user,
    dispatchFetchAppConfig,
    dispatchSetIgnoreDateFilter,
    dispatchSetBulkReleaseFlag,
    dispatchSetSelectedPaymentData,
    dispatchGetSourceSystemsList,
    dispatchSetFilterModalConfig,
    dispatchGetAllPaymentIndentsListingData,
    staff = {},
    ...props
}) {
    const { debounce, isBouncing } = useDebounce();
    const classes = useStyles();
    const [loading, setLoading] = useState(true);

    const [searchedValue, setSearchedValue] = useState('');
    const [activeCard, setActiveCard] = useState(0);

    const [comboViewModalOpen, setComboViewModalOpen] = useState(false);
    // const [bulkReleaseModal, setBulkReleaseModal] = useState(false);
    const [paymentModalOpen, setPaymentModalOpen] = useState(false);

    const [defaultSourceSystem, setDefaultSourceSystem] = useState(
        user?.zappay?.filterGroupModalConfig?.sourceSystem,
    );

    const [paymentIndentListingData, setPaymentIndentListingData] = useState(
        user?.zappay?.listingData?.lineItems ?? [],
    );

    const [filteredListingData, setFilteredListingData] = useState(
        user?.zappay?.listingData?.lineItems ?? [],
    );

    const [permissions, setPermissions] = useState(
        getPermissions('pay_online'),
    );

    useEffect(() => {
        setPaymentIndentListingData(user?.zappay?.listingData?.lineItems);
        setFilteredListingData(user?.zappay?.listingData?.lineItems);
    }, [user?.zappay?.listingData]);

    useEffect(() => {
        console.log('paymentIndentListingData', paymentIndentListingData);
        // dispatchSetSelectedPaymentData([]);
    }, [paymentIndentListingData]);

    useEffect(() => {
        setLoading(true);
        dispatchFetchAppConfig(
            (resp) => {
                if (resp?.status?.code == 200) {
                    console.log('defaultSourceSystem', defaultSourceSystem);
                    let sourceSys =
                        user?.zappay?.filterGroupModalConfig?.sourceSystem ??
                        null;

                    if (!sourceSys) {
                        dispatchGetSourceSystemsList(
                            {},
                            (resp) => {
                                console.log(
                                    'SOURCE SYSTEM LIST Success',
                                    resp?.response?.sourceSystems,
                                );
                                let filteredDefaultSourceSystem =
                                    resp?.response?.sourceSystems?.filter(
                                        (item) => item.default,
                                    );
                                setDefaultSourceSystem(
                                    filteredDefaultSourceSystem[0],
                                );
                                sourceSys = filteredDefaultSourceSystem[0];

                                let initialFilterModalConfig = {
                                    dateRange: {
                                        startTimeEpochMillis: moment()
                                            .add(-1, 'months')
                                            .startOf('month')
                                            .valueOf(),
                                        endTimeEpochMillis: moment()
                                            .endOf('month')
                                            .valueOf(),
                                    },
                                    sourceSystem: sourceSys,
                                    filters: {},
                                    paymentStatus: 'Ready For Payment',
                                };

                                console.log(
                                    'initialFilterModalConfig',
                                    initialFilterModalConfig,
                                );

                                dispatchSetFilterModalConfig(
                                    initialFilterModalConfig,
                                    () => {
                                        console.log(
                                            'dispatchSetFilterModalConfig success',
                                        );
                                    },
                                    () => {
                                        console.log(
                                            'dispatchSetFilterModalConfig failed',
                                        );
                                    },
                                );
                            },
                            (err) => {
                                console.log('SOURCE SYSTEM LIST failed', err);
                            },
                        );
                    }
                }
            },
            () => {},
        );
        console.log('permsss', permissions);
        return () => {
            // dispatchSetSelectedPaymentData([]);
        };
    }, []);

    // useEffect(() => {
    //     // dispatchFetchAppConfig();
    //     if (
    //         defaultSourceSystem != null &&
    //         Object.keys(defaultSourceSystem).length != 0
    //     ) {
    //         console.log('defaultSourceSystem', defaultSourceSystem);

    //         let listingRequestPayload = {
    //             dateRange: {
    //                 startTimeEpochMillis: moment()
    //                     .add(-1, 'months')
    //                     .startOf('month')
    //                     .valueOf(),
    //                 endTimeEpochMillis: moment().endOf('month').valueOf(),
    //             },
    //             filters: {},
    //             sourceSystemId: defaultSourceSystem?.id,
    //         };
    //         console.log('listingRequestPayload payload', listingRequestPayload);

    //         let initialFilterModalConfig = {
    //             ...user?.zappay?.filterGroupModalConfig,
    //             dateRange: {
    //                 startTimeEpochMillis: moment()
    //                     .add(-1, 'months')
    //                     .startOf('month')
    //                     .valueOf(),
    //                 endTimeEpochMillis: moment().endOf('month').valueOf(),
    //             },
    //             sourceSystem: defaultSourceSystem,
    //             filters: {},
    //             paymentStatus: 'All',
    //         };

    //         console.log('initialFilterModalConfig', initialFilterModalConfig);

    //         dispatchSetFilterModalConfig(
    //             initialFilterModalConfig,
    //             () => {
    //                 console.log('dispatchSetFilterModalConfig success');
    //             },
    //             () => {
    //                 console.log('dispatchSetFilterModalConfig failed');
    //             },
    //         );
    //     }
    // }, []);

    useEffect(() => {
        setLoading(true);
        if (
            user?.zappay?.filterGroupModalConfig != null &&
            Object.entries(user?.zappay?.filterGroupModalConfig).length != 0
        ) {
            console.log('changed', user?.zappay?.filterGroupModalConfig);

            // SetIgnoreDateFilter
            let selectedIgnoreDataFilters = Object.entries(
                user?.zappay?.filterGroupModalConfig?.filters,
            )?.filter((selectedFilter) =>
                user?.zappay?.sourceSystemFilterListData
                    ?.filter((item) => item?.ignoreDateFilter == true)
                    ?.map((selected) => selected?.key)
                    ?.includes(selectedFilter[0]),
            );

            if (selectedIgnoreDataFilters?.length > 0) {
                console.log('setting it true before calling listing API ');
                dispatchSetIgnoreDateFilter(true);
            } else {
                console.log('setting it false before calling listing API ');
                dispatchSetIgnoreDateFilter(false);
            }

            let listingRequestPayload =
                selectedIgnoreDataFilters?.length > 0
                    ? {
                          filters:
                              user?.zappay?.filterGroupModalConfig?.filters ??
                              {},
                          sourceSystemId:
                              user?.zappay?.filterGroupModalConfig?.sourceSystem
                                  ?.id,
                          paymentStatus:
                              'ALL' ==
                              user?.zappay?.filterGroupModalConfig?.paymentStatus.toUpperCase()
                                  ? null
                                  : replaceTextWithCapsAndUnderscore(
                                        user?.zappay?.filterGroupModalConfig
                                            ?.paymentStatus,
                                    ),
                      }
                    : {
                          dateRange:
                              user?.zappay?.filterGroupModalConfig?.dateRange,
                          filters:
                              user?.zappay?.filterGroupModalConfig?.filters ??
                              {},
                          sourceSystemId:
                              user?.zappay?.filterGroupModalConfig?.sourceSystem
                                  ?.id,
                          paymentStatus:
                              'ALL' ==
                              user?.zappay?.filterGroupModalConfig?.paymentStatus.toUpperCase()
                                  ? null
                                  : replaceTextWithCapsAndUnderscore(
                                        user?.zappay?.filterGroupModalConfig
                                            ?.paymentStatus,
                                    ),
                      };

            console.log('listingRequestPayload', listingRequestPayload);
            // dispatchSetSelectedPaymentData([]);
            // dispatchSetBulkReleaseFlag(false);
            dispatchGetAllPaymentIndentsListingData(
                listingRequestPayload ?? {},
                (resp) => {
                    setLoading(false);
                },
                () => {
                    setLoading(false);
                },
            );
        }
    }, [user?.zappay?.filterGroupModalConfig]);

    function getListingDataWithFilterParam(paymentFilterStatus) {
        let tempData = [...paymentIndentListingData];

        if (paymentFilterStatus == 'PENDING') {
            tempData = tempData?.filter(
                (data) => data?.paymentStatus != 'RELEASED',
            );
        }
        if (paymentFilterStatus == 'IN_PROGRESS') {
            tempData = tempData?.filter(
                (data) => data?.paymentStatus == 'IN_PROGRESS',
            );
        }
        if (paymentFilterStatus == 'FAILED') {
            tempData = tempData?.filter(
                (data) => data?.paymentStatus == 'FAILED',
            );
        }
        setFilteredListingData(tempData);
    }

    function searchData(data) {
        if (searchedValue) {
            return data?.filter((val) =>
                val?.accountName
                    ?.toLowerCase()
                    .includes(searchedValue?.toLowerCase()),
            );
        } else {
            return data;
        }
    }

    return (
        <Spinner loading={loading}>
            {true ? (
                <Paper style={{ padding: '12px' }} elevation={0}>
                    <Box
                        style={{
                            margin: '4px 0',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Box
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                            }}
                        >
                            <Box
                                style={{
                                    width: '400px',
                                    display: 'flex',
                                    flexDirection: 'row',
                                }}
                            >
                                <Searchbar
                                    placeHolderText="Search Account Name, UTR"
                                    value={searchedValue}
                                    keys={['name', 'hubName']}
                                    onFilter={async (filteredData) => {}}
                                    onChange={(value) => {
                                        console.log('blur', value);
                                        setSearchedValue(value);
                                    }}
                                />
                            </Box>

                            <Tooltip title="Download Report" placement="top">
                                <IconButton
                                    className={'inputbutton'}
                                    variant="outlined"
                                    style={{
                                        marginLeft: 10,
                                        marginRight: 10,
                                        border: '1px solid #43419e',
                                        borderRadius: 8,
                                        padding: 6,
                                    }}
                                    color="primary"
                                    onClick={(e) => {
                                        debounce(() => {
                                            let paymentIndentReportData = [];

                                            filteredListingData?.forEach(
                                                (item) => {
                                                    let sourceSystemFiltersKeyList =
                                                        user?.zappay?.sourceSystemFilterListData?.map(
                                                            (item) => item?.key,
                                                        );
                                                    let arrayItem = {
                                                        'Reference ID':
                                                            item?.referenceId,
                                                        'Source Sytem':
                                                            user?.zappay
                                                                ?.filterGroupModalConfig
                                                                ?.sourceSystem
                                                                ?.name,
                                                        'Account Name':
                                                            item?.accountName,
                                                    };

                                                    user?.zappay?.listingData
                                                        ?.metaDataDisplayNameMappings &&
                                                        Object.entries(
                                                            user?.zappay
                                                                ?.listingData
                                                                ?.metaDataDisplayNameMappings,
                                                        )?.forEach(
                                                            (keyVal, index) => {
                                                                let key =
                                                                    keyVal[0];
                                                                let val =
                                                                    keyVal[1];
                                                                if (
                                                                    sourceSystemFiltersKeyList?.includes(
                                                                        key,
                                                                    )
                                                                ) {
                                                                    arrayItem =
                                                                        {
                                                                            ...arrayItem,
                                                                            [val]: item
                                                                                ?.metaData[
                                                                                key
                                                                            ],
                                                                        };
                                                                }
                                                            },
                                                        );

                                                    arrayItem = {
                                                        ...arrayItem,
                                                        Amount: item?.amount,
                                                        'UTR Number':
                                                            item?.utrNumber ??
                                                            '',
                                                        'Payment Status':
                                                            item?.paymentStatus,
                                                        'UPI Address':
                                                            item?.upiAddress,
                                                        IFSC: item?.ifscCode,
                                                        'Account Number':
                                                            item?.accountNumber,
                                                        'Account Name':
                                                            item?.accountName,
                                                        'Bank Name':
                                                            item?.bankName ??
                                                            '',
                                                        'Payment Date':
                                                            item?.expectedPaymentDate,
                                                    };

                                                    user?.zappay?.listingData
                                                        ?.metaDataDisplayNameMappings &&
                                                        Object.entries(
                                                            user?.zappay
                                                                ?.listingData
                                                                ?.metaDataDisplayNameMappings,
                                                        )?.forEach(
                                                            (keyVal, index) => {
                                                                let key =
                                                                    keyVal[0];
                                                                let val =
                                                                    keyVal[1];

                                                                if (
                                                                    !sourceSystemFiltersKeyList?.includes(
                                                                        key,
                                                                    )
                                                                ) {
                                                                    arrayItem =
                                                                        {
                                                                            ...arrayItem,
                                                                            [val]: item
                                                                                ?.metaData[
                                                                                key
                                                                            ],
                                                                        };
                                                                }
                                                            },
                                                        );
                                                    arrayItem = {
                                                        ...arrayItem,
                                                        'Cashfree Transfer ID':
                                                            item?.cashFreeTransferId ??
                                                            '',
                                                    };
                                                    paymentIndentReportData.push(
                                                        arrayItem,
                                                    );
                                                },
                                            );
                                            JSONToCSVConvertor(
                                                'Payment Indent Report',
                                                paymentIndentReportData,
                                                'Payment Indent Report',
                                                true,
                                            );
                                        });
                                    }}
                                    disabled={
                                        isBouncing ||
                                        !paymentIndentListingData?.length ||
                                        !permissions?.read
                                    }
                                >
                                    <GetAppIcon size="small" />
                                </IconButton>
                            </Tooltip>

                            <Tooltip title="Reload" placement="top">
                                <IconButton
                                    style={{}}
                                    onClick={() => {
                                        setLoading(true);
                                        setActiveCard(0);

                                        dispatchGetSourceSystemsList(
                                            {},
                                            (resp) => {
                                                console.log(
                                                    'SOURCE SYSTEM LIST Success',
                                                    resp?.response
                                                        ?.sourceSystems,
                                                );

                                                let selectedIgnoreDataFilters =
                                                    Object.entries(
                                                        user?.zappay
                                                            ?.filterGroupModalConfig
                                                            ?.filters,
                                                    )?.filter(
                                                        (selectedFilter) =>
                                                            user?.zappay?.sourceSystemFilterListData
                                                                ?.filter(
                                                                    (item) =>
                                                                        item?.ignoreDateFilter ==
                                                                        true,
                                                                )
                                                                ?.map(
                                                                    (
                                                                        selected,
                                                                    ) =>
                                                                        selected?.key,
                                                                )
                                                                ?.includes(
                                                                    selectedFilter[0],
                                                                ),
                                                    );

                                                let listingRequestPayload =
                                                    selectedIgnoreDataFilters?.length >
                                                    0
                                                        ? {
                                                              filters:
                                                                  user?.zappay
                                                                      ?.filterGroupModalConfig
                                                                      ?.filters ??
                                                                  {},
                                                              sourceSystemId:
                                                                  user?.zappay
                                                                      ?.filterGroupModalConfig
                                                                      ?.sourceSystem
                                                                      ?.id,
                                                              paymentStatus:
                                                                  'ALL' ==
                                                                  user?.zappay?.filterGroupModalConfig?.paymentStatus.toUpperCase()
                                                                      ? null
                                                                      : replaceTextWithCapsAndUnderscore(
                                                                            user
                                                                                ?.zappay
                                                                                ?.filterGroupModalConfig
                                                                                ?.paymentStatus,
                                                                        ),
                                                          }
                                                        : {
                                                              dateRange:
                                                                  user?.zappay
                                                                      ?.filterGroupModalConfig
                                                                      ?.dateRange,
                                                              filters:
                                                                  user?.zappay
                                                                      ?.filterGroupModalConfig
                                                                      ?.filters ??
                                                                  {},
                                                              sourceSystemId:
                                                                  user?.zappay
                                                                      ?.filterGroupModalConfig
                                                                      ?.sourceSystem
                                                                      ?.id,
                                                              paymentStatus:
                                                                  'ALL' ==
                                                                  user?.zappay?.filterGroupModalConfig?.paymentStatus.toUpperCase()
                                                                      ? null
                                                                      : replaceTextWithCapsAndUnderscore(
                                                                            user
                                                                                ?.zappay
                                                                                ?.filterGroupModalConfig
                                                                                ?.paymentStatus,
                                                                        ),
                                                          };

                                                console.log(
                                                    'listingRequestPayload',
                                                    listingRequestPayload,
                                                );
                                                // dispatchSetSelectedPaymentData([]);
                                                dispatchSetBulkReleaseFlag(
                                                    false,
                                                );
                                                dispatchGetAllPaymentIndentsListingData(
                                                    listingRequestPayload ?? {},
                                                    (resp) => {
                                                        setLoading(false);
                                                    },
                                                    () => {
                                                        setLoading(false);
                                                    },
                                                );
                                            },
                                            (err) => {
                                                console.log(
                                                    'SOURCE SYSTEM LIST failed',
                                                    err,
                                                );
                                            },
                                        );
                                    }}
                                >
                                    <CachedIcon />
                                </IconButton>
                            </Tooltip>
                        </Box>
                        <Box
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                            }}
                        >
                            <Box
                                style={{
                                    backgroundColor: '#ebedf8',
                                    borderRadius: 8,
                                    marginRight: 8,
                                    padding: '0 16px 0 16px',
                                }}
                            >
                                <Typography
                                    style={{
                                        textAlign: 'center',
                                        fontWeight: 700,
                                        // color: '#43419E',
                                    }}
                                >
                                    Available Balance
                                </Typography>
                                <Typography
                                    style={{
                                        textAlign: 'center',
                                        fontWeight: 600,
                                        color: '#4caf50',
                                    }}
                                >
                                    {formatCurrency(
                                        user?.zappay?.listingData
                                            ?.availableBalance,
                                    )}
                                </Typography>
                            </Box>
                            <Box>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className="btn-pos"
                                    onClick={() => {
                                        setPaymentModalOpen(true);
                                    }}
                                    style={{
                                        height: '100%',
                                        padding: '0 12px',
                                        fontWeight: 600,
                                    }}
                                    disabled={!permissions.create}
                                    disableElevation
                                >
                                    Upload Payments
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                    <Box className={classes.horizontalCardContents}>
                        <Box
                            className={`${classes.verticalCardContents} ${
                                activeCard === 0 &&
                                classes.verticalCardContentsActive
                            }`}
                            onClick={() => {
                                setActiveCard(0);
                                getListingDataWithFilterParam(null);
                            }}
                        >
                            <Typography className={classes.cardHeader}>
                                Total Amount
                            </Typography>
                            <Typography className={classes.cardSubHeader}>
                                {/* {attendanceAll.totalCount} */}
                                {formatCurrency(
                                    user?.zappay?.listingData?.totalAmount,
                                )}
                            </Typography>
                        </Box>
                        <Box
                            className={`${classes.verticalCardContents} ${
                                activeCard === FILTER_STATUS.PENDING_AMOUNT &&
                                classes.verticalCardContentsActive
                            }`}
                            onClick={() => {
                                setActiveCard('PENDING_AMOUNT');
                                getListingDataWithFilterParam('PENDING');
                            }}
                        >
                            <Typography className={classes.cardHeader}>
                                Pending Amount
                            </Typography>
                            <Typography className={classes.cardSubHeader}>
                                {/* {attendanceAll.presentCount} */}
                                {formatCurrency(
                                    user?.zappay?.listingData?.pendingAmount,
                                )}
                            </Typography>
                        </Box>
                        <Box
                            className={`${classes.verticalCardContents} ${
                                activeCard ===
                                    FILTER_STATUS.FAILED_TRANSACTIONS &&
                                classes.verticalCardContentsActive
                            }`}
                            onClick={() => {
                                setActiveCard('FAILED_TRANSACTIONS');
                                getListingDataWithFilterParam('FAILED');
                            }}
                        >
                            <Typography className={classes.cardHeader}>
                                Failed Transactions
                            </Typography>
                            <Typography className={classes.cardSubHeader}>
                                {user?.zappay?.listingData?.failedTransactions}
                            </Typography>
                        </Box>
                        <Box
                            className={`${classes.verticalCardContents} ${
                                classes.verticalCardContentsNoMargin
                            } ${
                                activeCard ===
                                    FILTER_STATUS.INPROGRESS_TRANSACTIONS &&
                                classes.verticalCardContentsActive
                            }`}
                            onClick={() => {
                                setActiveCard('INPROGRESS_TRANSACTIONS');
                                getListingDataWithFilterParam('IN_PROGRESS');
                            }}
                        >
                            <Typography className={classes.cardHeader}>
                                In Progress Transactions
                            </Typography>
                            <Typography className={classes.cardSubHeader}>
                                {user?.zappay?.listingData
                                    ?.inProgressTransactions ?? 0}
                            </Typography>
                        </Box>
                    </Box>
                    <AllPaymentIndentsListContainer
                        permissions={permissions}
                        sparklineFilteredlistingData={filteredListingData}
                        searchValue={searchedValue}
                        // listingData={paymentIndentListingData}
                    />
                </Paper>
            ) : (
                !loading && (
                    <Box
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    ></Box>
                )
            )}
            {paymentModalOpen && (
                <UploadPaymentModal
                    source="zappay"
                    enumType="payment"
                    open={paymentModalOpen}
                    onClose={() => {
                        // console.log('Closed');
                        setPaymentModalOpen(false);
                    }}
                />
            )}
        </Spinner>
    );
}

const mapStateToProps = (state) => ({
    user: state?.user,
});

const mapDispatchToProps = (dispatch) => ({
    dispatchFetchAppConfig: (onSuccess, onError) =>
        dispatch(fetchAppConfig(onSuccess, onError)),

    dispatchGetAllPaymentIndentsListingData: (data, onSuccess, onError) =>
        dispatch(getAllPaymentIndentsListingData(data, onSuccess, onError)),

    dispatchSetFilterModalConfig: (data, onSuccess, onError) =>
        dispatch(setFilterModalConfig(data, onSuccess, onError)),

    dispatchGetSourceSystemsList: (data, onSuccess, onError) =>
        dispatch(getSourceSystemsList(data, onSuccess, onError)),

    dispatchSetBulkReleaseFlag: (data) => dispatch(setBulkReleaseFlag(data)),

    dispatchSetSelectedPaymentData: (data) =>
        dispatch(setSelectedPayments(data)),

    dispatchSetIgnoreDateFilter: (data, onSuccess, onError) =>
        dispatch(setIgnoreDateFilter(data, onSuccess, onError)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PayOnline);
