import React, { useState, useEffect } from 'react';
import './styles.scss';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    Paper,
    IconButton,
    Box,
    Typography,
    Tooltip,
    Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import BallotIcon from '@material-ui/icons/Ballot';
import moment from 'moment';
import { sortByMultipleProperties } from '../../../utils/helper';
const headCells = [
    {
        id: 'actions',
        numeric: false,
        disablePadding: false,
        label: 'Actions',
    },
    {
        id: 'evNo',
        numeric: false,
        disablePadding: false,
        label: 'EV Number',
        sortDirection: 'asc',
    },
    {
        id: 'evVehicleNo',
        numeric: false,
        disablePadding: false,
        label: 'EV Vehicle Number',
        sortDirection: 'asc',
    },
    {
        id: 'captain',
        numeric: false,
        disablePadding: false,
        label: 'Captain',
        sortDirection: 'asc',
    },
    {
        id: 'vendor',
        numeric: false,
        disablePadding: false,
        label: 'Vendor',
        sortDirection: 'asc',
    },
    {
        id: 'status',
        numeric: false,
        disablePadding: false,
        label: 'Status',
    },
    {
        id: 'project',
        numeric: false,
        disablePadding: false,
        label: 'Project',
    },
    {
        id: 'team',
        numeric: false,
        disablePadding: false,
        label: 'Team',
    },
    {
        id: 'deName',
        numeric: false,
        disablePadding: false,
        label: 'DE Name',
    },
    {
        id: 'dePhoneNumber',
        numeric: false,
        disablePadding: false,
        label: 'DE Phone Number',
    },
    {
        id: 'cityName',
        numeric: false,
        disablePadding: false,
        label: 'City',
    },
    {
        id: 'rentedDateEpochMillis',
        numeric: false,
        disablePadding: false,
        label: 'Rented Date',
    },
    {
        id: 'returnedDateEpochMillis',
        numeric: false,
        disablePadding: false,
        label: 'Return Date',
    },
    {
        id: 'iotVendor',
        numeric: false,
        disablePadding: false,
        label: 'IOT Vendor',
    },
    {
        id: 'cutoffEnabled',
        numeric: false,
        disablePadding: false,
        label: 'Cut-Off Enabled',
    },
    {
        id: 'cutoffStatus',
        numeric: false,
        disablePadding: false,
        label: 'Cut-Off Status',
    },
    // {
    //     id: 'history',
    //     numeric: false,
    //     disablePadding: false,
    //     label: ' ',
    // },
];

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        margin: '16px 0',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    tableCellTypographyStyles: {
        // display: 'flex',
        // justifyContent: 'center',
        minWidth: 140,
        fontSize: 13,
        fontWeight: 600,
    },
    tableCellStyles: {
        textAlign: 'left',
    },
}));

const EVVehicleListingContainer = ({
    evListData,
    vendorList,
    onPressEdit,
    onPressDelete,
    onPressViewHistory,
    onPressMobilize,
    teamList,
    projectsList,
    captainList,
    ...props
}) => {
    const getTableData = (data) => {
        return data != undefined && data.length > 0
            ? data?.map((row, index) => {
                  const vendorName = !!row?.vendorId
                      ? vendorList?.filter((v) => v?.id == row?.vendorId)?.[0]
                            ?.name
                      : '';
                  const teamName = !!row?.teamId
                      ? teamList?.filter((v) => v?.id == row?.teamId)?.[0]?.name
                      : '';
                  const projectName = !!row?.projectId
                      ? projectsList?.filter(
                            (v) => v?.id == row?.projectId,
                        )?.[0]?.name
                      : !!row?.project
                      ? projectsList?.filter((v) => v?.id == row?.project)?.[0]
                            ?.name ?? row?.project
                      : '';
                  const captainName = !!row?.captainId
                      ? captainList?.filter((v) => v?.id == row?.captainId)?.[0]
                            ?.name
                      : !!row?.captain
                      ? captainList?.filter((v) => v?.id == row?.captain)?.[0]
                            ?.name ?? row?.captain
                      : '';
                  return {
                      ...row,
                      vendorName: vendorName,
                      teamName: teamName,
                      projectName: projectName,
                      captainName: captainName,
                  };
              })
            : [];
    };

    let temp = getTableData(evListData?.lineItems);
    console.log('temp', JSON.stringify(temp));
    temp = sortByMultipleProperties(temp, [
        'captainName',
        'vendorName',
        'teamName',
        'evNumber',
    ]);
    //temp = sortByMultipleProperties(temp, ['teamName']);
    //temp = sortByMultipleProperties(temp, ['vendorName']);
    //temp = sortByMultipleProperties(temp, ['captainName']);
    const listingData = temp; //?.reverse() ?? [];
    const classes = useStyles();
    return (
        <>
            <div className={classes.root}>
                <Paper className={classes.paper}>
                    {!!listingData?.length ? (
                        <>
                            <TableContainer>
                                <Table
                                    className={classes.table}
                                    aria-labelledby="tableTitle"
                                    size={'medium'}
                                    aria-label="enhanced table"
                                    stickyHeader
                                >
                                    {!!(
                                        listingData?.length ||
                                        listingData?.length == 0
                                    ) && (
                                        <TableHead>
                                            <TableRow>
                                                {headCells?.map((headCell) => (
                                                    <TableCell
                                                        key={headCell.id}
                                                        align={'left'}
                                                        padding={'default'}
                                                        variant={'head'}
                                                        sortDirection={
                                                            headCell?.sortDirection ??
                                                            false
                                                        }
                                                        style={{
                                                            fontSize: 14,
                                                            paddingLeft: 14,
                                                        }}
                                                    >
                                                        {headCell.label}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                    )}
                                    {!!listingData?.length && (
                                        <TableBody>
                                            {listingData?.map((row, index) => {
                                                const vendorName =
                                                    row.vendorName;

                                                const teamName = row.teamName;
                                                const projectName =
                                                    row.projectName;

                                                const captainName =
                                                    row.captainName;

                                                return (
                                                    <TableRow
                                                        hover
                                                        key={row?.id}
                                                    >
                                                        <TableCell
                                                            key={`${row?.id}-0-1`}
                                                            align={'left'}
                                                            // className={
                                                            //     classes.tableCellStyles
                                                            // }
                                                            // style={{
                                                            //     width: '150px !important',
                                                            // }}
                                                        >
                                                            <Box
                                                                style={{
                                                                    width: '150px',
                                                                    display:
                                                                        'flex',
                                                                    justifyContent:
                                                                        'flex-start',
                                                                    alignItems:
                                                                        'center',
                                                                }}
                                                            >
                                                                <IconButton
                                                                    color="inherit"
                                                                    onClick={(
                                                                        e,
                                                                    ) => {
                                                                        !!onPressEdit &&
                                                                            onPressEdit(
                                                                                row,
                                                                            );
                                                                        console.log(
                                                                            'Edit clicked',
                                                                            row,
                                                                        );
                                                                    }}
                                                                    style={{
                                                                        padding: 2,
                                                                    }}
                                                                >
                                                                    <EditOutlinedIcon />
                                                                </IconButton>

                                                                {/* <Tooltip
                                                                    title="Delete"
                                                                    placement="top"
                                                                >
                                                                    <IconButton
                                                                        onClick={(
                                                                            e,
                                                                        ) => {
                                                                            !!onPressDelete &&
                                                                                onPressDelete(
                                                                                    row,
                                                                                );
                                                                        }}
                                                                        style={{
                                                                            padding: 2,
                                                                            color: 'red',
                                                                        }}
                                                                    >
                                                                        <DeleteOutlinedIcon />
                                                                    </IconButton>
                                                                </Tooltip> */}
                                                                <Tooltip
                                                                    title="View History"
                                                                    placement="top"
                                                                >
                                                                    <IconButton
                                                                        onClick={(
                                                                            e,
                                                                        ) => {
                                                                            !!onPressViewHistory &&
                                                                                onPressViewHistory(
                                                                                    row,
                                                                                );
                                                                        }}
                                                                        style={{
                                                                            padding: 2,
                                                                            color: 'orange',
                                                                        }}
                                                                    >
                                                                        <BallotIcon />
                                                                    </IconButton>
                                                                </Tooltip>
                                                                {!!row?.cutoffStatus && (
                                                                    <Tooltip
                                                                        title="Block/Unblock"
                                                                        placement="top"
                                                                    >
                                                                        <Button
                                                                            disableElevation
                                                                            onClick={(
                                                                                e,
                                                                            ) => {
                                                                                !!onPressMobilize &&
                                                                                    onPressMobilize(
                                                                                        row,
                                                                                    );
                                                                            }}
                                                                            style={{
                                                                                padding: 2,
                                                                                color: '#fff',
                                                                                backgroundColor:
                                                                                    row?.cutoffStatus ==
                                                                                        'Mobilize' ||
                                                                                    row?.cutoffStatus ==
                                                                                        'mobilize'
                                                                                        ? 'red'
                                                                                        : 'blue',
                                                                            }}
                                                                        >
                                                                            {row?.cutoffStatus ==
                                                                                'Mobilize' ||
                                                                            row?.cutoffStatus ==
                                                                                'mobilize'
                                                                                ? 'Block'
                                                                                : 'Unblock'}
                                                                        </Button>
                                                                    </Tooltip>
                                                                )}
                                                            </Box>
                                                        </TableCell>

                                                        <TableCell
                                                            key={`${row?.id}-0-2`}
                                                            className={
                                                                classes.tableCellStyles
                                                            }
                                                            align={'left'}
                                                        >
                                                            <Typography
                                                                className={
                                                                    classes.tableCellTypographyStyles
                                                                }
                                                            >
                                                                {row?.evNumber}
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell
                                                            key={`${row?.id}-0-2`}
                                                            className={
                                                                classes.tableCellStyles
                                                            }
                                                            align={'left'}
                                                        >
                                                            <Typography
                                                                className={
                                                                    classes.tableCellTypographyStyles
                                                                }
                                                            >
                                                                {row?.evVehicleNumber ??
                                                                    '-'}
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell
                                                            key={`${row?.id}-0-5`}
                                                            className={
                                                                classes.tableCellStyles
                                                            }
                                                        >
                                                            <Typography
                                                                className={
                                                                    classes.tableCellTypographyStyles
                                                                }
                                                            >
                                                                {captainName ??
                                                                    '-'}
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell
                                                            key={`${row?.id}-0-3`}
                                                            className={
                                                                classes.tableCellStyles
                                                            }
                                                        >
                                                            <Typography
                                                                className={
                                                                    classes.tableCellTypographyStyles
                                                                }
                                                            >
                                                                {vendorName ??
                                                                    '-'}
                                                            </Typography>
                                                        </TableCell>

                                                        <TableCell
                                                            key={`${row?.id}-0-4`}
                                                            className={
                                                                classes.tableCellStyles
                                                            }
                                                        >
                                                            <Typography
                                                                style={{
                                                                    minWidth:
                                                                        '120px',
                                                                }}
                                                                className={
                                                                    row.status ===
                                                                    'UNTAGGED'
                                                                        ? 'ev-untagged-chip'
                                                                        : row.status ===
                                                                          'TAGGED'
                                                                        ? 'ev-tagged-chip'
                                                                        : row.status ===
                                                                          'AVAILABLE'
                                                                        ? 'ev-available-chip'
                                                                        : row.status ==
                                                                          'RELEASED'
                                                                        ? 'ev-released-chip'
                                                                        : null
                                                                }
                                                            >
                                                                {row.status ??
                                                                    '-'}
                                                            </Typography>
                                                        </TableCell>

                                                        <TableCell
                                                            key={`${row?.id}-0-6`}
                                                            className={
                                                                classes.tableCellStyles
                                                            }
                                                        >
                                                            <Typography
                                                                className={
                                                                    classes.tableCellTypographyStyles
                                                                }
                                                            >
                                                                {projectName ??
                                                                    '-'}
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell
                                                            key={`${row?.id}-0-6`}
                                                            className={
                                                                classes.tableCellStyles
                                                            }
                                                        >
                                                            <Typography
                                                                className={
                                                                    classes.tableCellTypographyStyles
                                                                }
                                                            >
                                                                {teamName ??
                                                                    '-'}
                                                            </Typography>
                                                        </TableCell>

                                                        <TableCell
                                                            key={`${row?.id}-0-7`}
                                                            className={
                                                                classes.tableCellStyles
                                                            }
                                                        >
                                                            <Typography
                                                                className={
                                                                    classes.tableCellTypographyStyles
                                                                }
                                                            >
                                                                {row?.deName ??
                                                                    '-'}
                                                            </Typography>
                                                        </TableCell>

                                                        <TableCell
                                                            key={`${row?.id}-0-8`}
                                                            className={
                                                                classes.tableCellStyles
                                                            }
                                                        >
                                                            <Typography
                                                                className={
                                                                    classes.tableCellTypographyStyles
                                                                }
                                                            >
                                                                {row?.dePhoneNumber ??
                                                                    '-'}
                                                            </Typography>
                                                        </TableCell>

                                                        <TableCell
                                                            key={`${row?.id}-0-9`}
                                                            className={
                                                                classes.tableCellStyles
                                                            }
                                                        >
                                                            <Typography
                                                                className={
                                                                    classes.tableCellTypographyStyles
                                                                }
                                                            >
                                                                {row?.city ??
                                                                    '-'}
                                                            </Typography>
                                                        </TableCell>

                                                        <TableCell
                                                            key={`${row?.id}-1-0`}
                                                            className={
                                                                classes.tableCellStyles
                                                            }
                                                        >
                                                            <Typography
                                                                className={
                                                                    classes.tableCellTypographyStyles
                                                                }
                                                            >
                                                                {!!row?.rentedDateEpochMillis
                                                                    ? moment(
                                                                          row?.rentedDateEpochMillis,
                                                                      )?.format(
                                                                          'DD MMM YY',
                                                                      )
                                                                    : '-'}
                                                            </Typography>
                                                        </TableCell>

                                                        <TableCell
                                                            key={`${row?.id}-1-1`}
                                                            className={
                                                                classes.tableCellStyles
                                                            }
                                                        >
                                                            <Typography
                                                                className={
                                                                    classes.tableCellTypographyStyles
                                                                }
                                                            >
                                                                {!!row?.returnedDateEpochMillis
                                                                    ? moment(
                                                                          row?.returnedDateEpochMillis,
                                                                      )?.format(
                                                                          'DD MMM YY',
                                                                      )
                                                                    : '-'}
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell
                                                            key={`${row?.id}-1-1`}
                                                            className={
                                                                classes.tableCellStyles
                                                            }
                                                        >
                                                            <Typography
                                                                className={
                                                                    classes.tableCellTypographyStyles
                                                                }
                                                            >
                                                                {!!row?.iotVendor
                                                                    ? row?.iotVendor
                                                                    : '-'}
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell
                                                            key={`${row?.id}-1-1`}
                                                            className={
                                                                classes.tableCellStyles
                                                            }
                                                        >
                                                            <Typography
                                                                className={
                                                                    classes.tableCellTypographyStyles
                                                                }
                                                            >
                                                                {!!row?.cutoffEnabled
                                                                    ? 'Yes'
                                                                    : 'No'}
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell
                                                            key={`${row?.id}-1-1`}
                                                            className={
                                                                classes.tableCellStyles
                                                            }
                                                        >
                                                            <Typography
                                                                className={
                                                                    classes.tableCellTypographyStyles
                                                                }
                                                            >
                                                                {!!row?.cutoffStatus
                                                                    ? row?.cutoffStatus
                                                                    : '-'}
                                                            </Typography>
                                                        </TableCell>
                                                        {/* <TableCell
                                                            key={`${row?.id}-1-2`}
                                                            className={
                                                                classes.tableCellStyles
                                                            }
                                                        >
                                                            <Box
                                                                style={{
                                                                    width: '150px',
                                                                    display:
                                                                        'flex',
                                                                    justifyContent:
                                                                        'flex-start',
                                                                    alignItems:
                                                                        'center',
                                                                }}
                                                            >
                                                                <Button
                                                                    key={2}
                                                                    variant="contained"
                                                                    color="primary"
                                                                    disabled={
                                                                        false
                                                                    }
                                                                    size="small"
                                                                    onClick={() => {}}
                                                                    classes={{}}
                                                                    style={{
                                                                        boxShadow:
                                                                            'none',
                                                                        width: '171px',
                                                                    }}
                                                                    disableElevation
                                                                >
                                                                    View History
                                                                </Button>
                                                            </Box>
                                                        </TableCell> */}
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    )}
                                </Table>
                            </TableContainer>
                        </>
                    ) : (
                        <>
                            <Box
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    marginTop: '40px',
                                }}
                            >
                                <Typography
                                    style={{
                                        margin: '16px 0',
                                        fontSize: 20,
                                        fontWeight: 600,
                                    }}
                                >
                                    No Results Found
                                </Typography>
                            </Box>
                        </>
                    )}
                </Paper>
            </div>
        </>
    );
};

const mapStateToProps = (state) => ({
    evListData: state.settings?.evList ?? {
        lineItems: [],
        totalCount: 0,
    },
    vendorList: state.settings?.evVendorList ?? [],
    teamList: state.settings?.evTeamList ?? [],
    projectsList: state.settings?.evProjectList ?? [],
    captainList: state.settings?.evCaptainList ?? [],
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(EVVehicleListingContainer);
