import {
    Box,
    Checkbox,
    IconButton,
    Paper,
    Typography,
} from '@material-ui/core';
import { CheckBox, ZoomIn } from '@material-ui/icons';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { ZoomInIcon } from '../../../assets/svg';
import { CustomTextField } from '../../../components/Textfield';
import { LightBoxImage } from '../../../components/UI';
import PDFViewer from '../../../components/UI/PDFViewer';

function SignedContract({
    leadInfo,
    toggleLoading,
    dispatchFetchBasicLeadkyc,
}) {
    const [payload, setPayload] = useState({
        name: '',
        date: '',
        prefLocation: '',
        contractAccepted: true,
        pdfLink: '',
    });

    const handleInputChange = (value, key) => {
        setPayload((state) => ({
            ...state,
            [key]: value,
        }));
    };

    const fetchLeadKycDetails = () => {
        toggleLoading(true);
        dispatchFetchBasicLeadkyc(
            leadInfo?.id,
            {},
            (resp) => {
                let values = resp?.response;
                setPayload({
                    name: leadInfo.name,
                    date: moment(leadInfo?.dateEpochMillis).format(
                        'DD-MMM-YYYY',
                    ),
                    prefLocation: leadInfo.city,
                    contractAccepted: !!values?.pdp?.image,
                    pdfLink: values?.pdp?.image,
                    signature: values?.signature?.image,
                });
                toggleLoading(false);
            },
            (err) => {
                toggleLoading(false);
            },
        );
    };

    useEffect(() => {
        fetchLeadKycDetails();
    }, []);

    return (
        <Box
            style={{
                flex: 1,
                width: '100%',
                height: '100%',
                paddingTop: '24px',
                elevation: 0,
                display: 'flex',
            }}
        >
            <Box style={{ width: '50%', position: 'relative' }}>
                <Box
                    style={{
                        height: '75vh',
                        padding: '8px',
                        borderRadius: '8px',
                        borderWidth: '1px',
                        border: 'solid 1px rgba(38, 50, 56, 0.16)',
                        marginRight: '8px',
                        scrollBehavior: 'unset',
                        overflowY: 'scroll',
                        position: 'relative',
                    }}
                >
                    <PDFViewer file={payload.pdfLink} />
                </Box>
                <IconButton
                    className={'inputbutton'}
                    style={{
                        background: '#000',
                        opacity: '64%',
                        borderRadius: '8px',
                        padding: '8px',
                        width: '36px',
                        height: '36px',
                        objectFit: 'contain',
                        position: 'absolute',
                        bottom: '16px',
                        right: '24px',
                    }}
                    color="primary"
                    onClick={(e) => {
                        window.open(payload.pdfLink, '_blank');
                    }}
                    disabled={false}
                >
                    <ZoomInIcon />
                </IconButton>
            </Box>
            <Box
                style={{
                    width: '50%',
                    marginLeft: '8px',
                }}
            >
                <CustomTextField
                    fullWidth={true}
                    label="Name"
                    value={payload.name}
                    onChange={(e) => {
                        handleInputChange(e.target.value, 'name');
                    }}
                    error={''}
                    helperText={''}
                    style={{ marginBottom: '16px' }}
                    disabled={true}
                />
                <CustomTextField
                    fullWidth={true}
                    label="Date"
                    value={payload.date}
                    onChange={(e) => {
                        handleInputChange(e.target.value, 'date');
                    }}
                    error={''}
                    helperText={''}
                    style={{ marginBottom: '16px' }}
                    disabled={true}
                />
                <CustomTextField
                    fullWidth={true}
                    label="Preferred work Location"
                    value={payload.prefLocation}
                    onChange={(e) => {
                        handleInputChange(e.target.value, 'prefLocation');
                    }}
                    error={''}
                    helperText={''}
                    style={{ marginBottom: '16px' }}
                    disabled={true}
                />
                <Typography
                    style={{
                        color: '#14112d',
                        fontWeight: 'bold',
                        marginTop: '13px',
                        fontSize: '18px',
                        marginBottom: '9px',
                    }}
                >
                    Signature
                </Typography>
                {!!payload?.signature ? (
                    <LightBoxImage
                        containerStyles={{
                            width: '100%',
                            height: '243px',
                            marginRight: 5,
                            border: '1px solid #000',
                        }}
                        imageStyles={{
                            width: '100%',
                            height: '100%',
                            margin: 'auto',
                            borderRadius: '0px',
                        }}
                        source={payload?.signature}
                    />
                ) : (
                    <div
                        style={{
                            width: '100%',
                            height: '243px',
                            marginRight: 5,
                            border: '1px solid #000',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        No Signature
                    </div>
                )}

                <Box
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                >
                    <Checkbox
                        color="primary"
                        checked={payload.contractAccepted}
                        inputProps={{
                            'aria-labelledby': 'Test',
                            readOnly: true,
                        }}
                        onChange={(event) => {
                            console.log(event.target.value);
                            handleInputChange(
                                event.target.checked,
                                'contractAccepted',
                            );
                        }}
                        readOnly={true}
                        style={{ marginLeft: 0 }}
                    />
                    <Typography
                        style={{
                            color: '#263238',
                        }}
                    >
                        Contract Accepted
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
}

export { SignedContract };
